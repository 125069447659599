/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake } from '@fortawesome/fontawesome-free-solid';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { store } from '../../../App/store';
import { connect } from 'react-redux';
import moment from 'moment';

const nodataimg = `${process.env.PUBLIC_URL}/img/birthday-cake.png`;
const imgProfileDefault = `${process.env.PUBLIC_URL}/img/user-default-photo.png`;

class BirthdayTab extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      rows: [],
      birthday_emp: this?.props.user?.birthday?.length > 0,
    };
    this.getProfileData = this.getProfileData.bind(this);
    this.getBirthday = this.getBirthday.bind(this);
  }
  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState(
      { apiws: ls.get('ws_ip') },
      this.getProfileData(),
      // this.getBirthday(),
    );
    this.setState({ idUser: ls.get('user_cred').usr_id });
    if (!this?.props.user?.birthday?.length) {
      this.getBirthday();
    } else {
      setTimeout(() => {
        this.getBirthday();
      }, 200);
    }
  };
  getProfileData() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let getDataUser = true;
    if (idUser === 0 || idUser === '') {
      getDataUser = false;
    }
    if (getDataUser) {
      axios
        .post(`${ls.get('ws_ip')}/appuser/getAllItemById/${idUser}`, '', config)
        .then((response) => {
          let userName = '';
          if (response.data.data.length !== 0) {
            userName = response.data.data[0].emp_id;
            const checkUser = userName === null ? false : true;
            const arr = [];
            if (checkUser) {
              axios
                .post(
                  `${apiws}/Appnotification/getAllItemById/${userName}`,
                  '',
                  config,
                )
                .then((res) => {
                  if (res.data.status === 'ok') {
                    for (let i = 0; i < res.data.data.length; i += 1) {
                      arr.push({
                        ...res.data.data[i],
                      });
                    }
                    this.setState({ rows: arr });
                  }
                });
            } else {
              // console.log(checkUser);
            }
          }
        });
    }
  }

  getBirthday() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/employee/getbirthdayemployee`, '', config)
      .then((res) => {
        if (res?.data?.data?.length !== 0) {
          const response = res.data.data;
          const arr = [];
          response?.map((data) => {
            let path_photo;
            if (
              data.path_photo === null ||
              data.path_photo === '' ||
              data.path_photo === 'undefined'
            ) {
              path_photo = imgProfileDefault;
            } else {
              path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                data.path_photo
              }`}`;
            }
            arr.push({
              emp_name: data.emp_name,
              emp_code: data.emp_code,
              position_name: data.position_name,
              age: data.dob,
              photo: path_photo,
            });
          });
          // this.setState({ birthday_emp: arr });
          store.dispatch({ type: 'SET_USER_BIRTHDAY', data: arr });
          if (!this.state.birthday_emp) {
            this.setState({
              birthday_emp: true,
            });
          }
        } else {
          store.dispatch({ type: 'SET_USER_BIRTHDAY', data: [] });
          if (this.state.birthday_emp) {
            this.setState({
              birthday_emp: false,
            });
          }
        }
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="taskAlertPanel-scroll">
        {this?.props.user?.birthday?.length == 0 && (
          <div className="taskAlertPanel">
            <div className="d-flex align-items-center no-data-box mt-0">
              <div className="no-data-img-tasklist birthday-icon">
                <img
                  src={nodataimg}
                  alt="leave-icon"
                  style={{ height: '60px' }}
                />
              </div>
              <div className="w-100 text-center">
                <p>{t('LBL.NO_BIRTHDAY')} </p>
              </div>
            </div>
          </div>
        )}
        {this?.props.user?.birthday?.length > 0 && (
          <div className="taskAlertPanel">
            <span>
              <p className="title-alert mb8">
                <FontAwesomeIcon
                  icon={faBirthdayCake}
                  style={{ marginRight: '5px' }}
                />
                <b>{t('LBL.HAPPY_BIRTHDAY')}</b>
              </p>
            </span>
            <ul className="bd-list">
              {this?.props.user?.birthday?.map((data) => (
                <li>
                  <div className="d-flex align-items-center bd-list-card">
                    <div className="user-pic-bd">
                      <img src={data.photo} alt="user-bd-pic" />
                    </div>
                    <div className="emp-bd-desc w-100">
                      <p className="emp-name">{data.emp_name}</p>
                      <p className="emp-id">
                        {data.position_name} | {data.age}
                      </p>
                    </div>
                    <div className="bd-age">
                      {/* <b>{data.age}</b> {t('LBL.YEARS_OLD')} */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(BirthdayTab));
