import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import TopbarNav from './tobar_nav/TopbarNav';
// import TopbarSearch from './TopbarSearchForm';
import { Container } from 'reactstrap';
import config from '../../../shared/components/config/config';
// import TopbarShortcutBtn from './TopbarShortcutBtn';
// import TopbarNotification from './TopbarNotification';

export default class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility } = this.props;
    const env = config.environment;
    let logoDev = false;
    if (typeof env === 'undefined' || env === 'development' || env === 'dev') {
      logoDev = true;
    }
    return (
      <div className="topbar topbar--navigation">
        <div className="topbar__wrapper">
          <Container style={{ position: 'relative' }}>
            <div className="topbar__left">
              <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              />
              <Link className="topbar__logo" to="/dashboard" />
              {/* Developer version */}
              {logoDev ? <span className="dev-topbar_logo">Dev</span> : ''}
            </div>
            {/* <TopbarSearch /> */}
            <div className="topbar__right">
              {/* <TopbarShortcutBtn /> */}
              <TopbarLanguage />
              {/* <TopbarNotification /> */}
              <TopbarProfile />
            </div>
          </Container>
        </div>
        <TopbarNav />
      </div>
    );
  }
}
