/* eslint-disable array-callback-return */
/* eslint-disable max-len, react/jsx-boolean-value */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable quote-props, no-useless-escape */
/* eslint-disable camelcase, no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderMaskInput from '../../../../shared/components/form/InputMask';
// import renderFileInputField from '../../../../shared/components/form/FileInput';
// import renderDocumentInputField from '../../../../shared/components/form/DocumentInput';
import SunEditor from 'suneditor-react';
import utils from '../../../../utils';
import Spinner from 'react-bootstrap/Spinner';

class CompanySettingForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      button_disabled: false,
      status_edit: true,
      disabled_form: false,
      disabled_cancel: false,
      token: '',
      company: '',
      address: '',
      city: '',
      province: '',
      postal_code: '',
      subdistrict: '',
      village: '',
      telp: '',
      fax: '',
      company_npwp: '',
      provinces: '',
      cities: '',
      cmp_id: '',
      apiws: '',
      iduser: '',
      editcond: '',
      addcond: '',
      sunEditorButtonList: [
        ['bold', 'underline', 'italic'],
        ['outdent', 'indent', 'align'],
        ['list'],
      ],
      visiEditor: '',
      misiEditor: '',
      hideEditor: false,
      buttonSubmited: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.changeprov = this.changeprov.bind(this);
    this.changecity = this.changecity.bind(this);
    this.changedistrict = this.changedistrict.bind(this);
    this.handleVisiChange = this.handleVisiChange.bind(this);
    this.handleMisiChange = this.handleMisiChange.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState(
      {
        iduser: ls.get('user_cred').usr_id,
      },
      () => {
        this.checkCompany();
        this.getButton();
      },
    );
    this.getProvinces();
  };

  getProvinces() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbprovince/getAll`, '', config)
      .then((resProvince) => {
        const rpv = resProvince.data.data;
        const array = [];
        if (rpv.length === 0) {
          this.setState({ arrProvinces: array });
        } else {
          rpv.map((obj) =>
            array.push({ value: obj.prv_code, label: obj.prv_name }),
          );
          this.setState({ arrProvinces: array });
        }
      });
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
            }
          });
        }
      });
  }

  changeprov(x) {
    // reset
    if (!this.state.status_edit) {
      this.props.change('city', 0);
      this.props.change('subdistrict', 0);
      this.props.change('village', 0);
    }
    const id = x.value;
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/rtbcity/getallitembyid/${id}`, '', config)
      .then((resCity) => {
        const rct = resCity.data.data;
        const array = [];
        if (rct.length > 0) {
          rct.map((obj) =>
            array.push({ value: obj.cty_code, label: obj.cty_name }),
          );
        }
        this.setState({
          arrCity: array,
          provinces: id,
          arrSubdistrict: [],
          arrVillage: [],
        });
      });
  }

  changecity(x) {
    if (!this.state.status_edit) {
      this.props.change('subdistrict', 0);
      this.props.change('village', 0);
    }
    const id = x.value;
    const { apiws, token, provinces } = this.state;
    const procity = `${provinces}${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/rtbsubdistrict/getallitembyid/${procity}`, '', config)
      .then((resSubdistrict) => {
        const rbd = resSubdistrict.data.data;
        const array = [];
        if (rbd.length > 0) {
          rbd.map((obj) =>
            array.push({ value: obj.sbd_code, label: obj.sbd_name }),
          );
        }
        setTimeout(() => {
          this.setState({
            arrSubdistrict: array,
            cities: id,
            arrVillage: [],
          });
        }, 200);
      });
  }

  changedistrict(x) {
    if (!this.state.status_edit) {
      this.props.change('village', 0);
    }

    const id = x.value;
    const { apiws, token, provinces, cities } = this.state;
    const procitydistrict = `${provinces}${cities}${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/rtbvillage/getallitembyid/${procitydistrict}`, '', config)
      .then((resVillage) => {
        const rvl = resVillage.data.data;
        const array = [];
        if (rvl.length > 0) {
          rvl.map((obj) =>
            array.push({ value: obj.vlg_code, label: obj.vlg_name }),
          );
          setTimeout(() => {
            this.setState({ arrVillage: array });
          }, 200);
        }
      });
  }

  checkCompany() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/Orgcompany/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          if (dataResponse.length === 0) {
            this.setState({
              disabled_form: false,
              status_edit: false,
              hideEditor: false,
            });
          } else {
            this.props.destroy();
            const provinces = dataResponse[0].province;
            const cities = dataResponse[0].city;
            const subdistricts = dataResponse[0].subdistrict;
            this.setState({
              provinces: provinces,
              cities: cities,
            });
            if (
              dataResponse[0].vision === null ||
              dataResponse[0].vision === '<p><br></p>' ||
              dataResponse[0].vision.length === 0
            ) {
              this.setState({ visiEditor: '' });
            } else {
              this.setState({ visiEditor: dataResponse[0].vision });
            }
            if (
              dataResponse[0].mission === null ||
              dataResponse[0].mission === '<p><br></p>' ||
              dataResponse[0].mission.length === 0
            ) {
              this.setState({ misiEditor: '' });
            } else {
              this.setState({ misiEditor: dataResponse[0].mission });
            }
            setTimeout(() => {
              this.changeprov({ value: provinces });
              this.changecity({ value: cities });
              this.changedistrict({ value: subdistricts });
            }, 100);
            const bank =
              dataResponse[0].rtk_id === null ? '' : dataResponse[0].rtk_id;
            this.props.initialize({
              bank,
              ...dataResponse[0],
            });
            this.setState({
              disabled_form: true,
              status_edit: true,
              hideEditor: true,
              disabled_cancel: true,
            });
          }
        } else {
          this.setState({ button_disabled: false });
        }
      })
      .catch(() => {
        this.setState({ button_disabled: false });
      });
  }

  handleVisiChange(values) {
    this.setState({ visiEditor: values });
  }
  handleMisiChange(values) {
    this.setState({ misiEditor: values });
  }
  handleSubmit(values) {
    const { apiws, token, iduser } = this.state;
    let errors = true;
    if (!values.company) {
      errors = false;
    }
    if (!values.address) {
      errors = false;
    }
    if (!values.city) {
      errors = false;
    }
    if (!values.telp) {
      errors = false;
    }
    if (values.tax_person_npwp) {
      const otpn = values.tax_person_npwp;
      const patt1 = /[0-9]/g;
      const result = otpn.match(patt1);
      if (result.length < 15) {
        errors = false;
      }
    }
    if (!values.subdistrict) {
      errors = false;
    }
    if (!values.village) {
      errors = false;
    }
    if (!values.email) {
      errors = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors = false;
    }
    if (!values.province) {
      errors = false;
    }
    if (!values.postal_code) {
      errors = false;
    } else {
      const pc = values.postal_code;
      // if (!/^[0-9]*$/.test(pc)) {
      //   errors.postal_code = 'Invalid value postal code';
      // } else
      if (pc.length > 5) {
        errors = false;
      }
    }
    if (!values.company_npwp) {
      errors = false;
    } else {
      const ocp = values.company_npwp;
      const patt1 = /[0-9]/g;
      const result = ocp.match(patt1);
      if (result.length < 15) {
        errors = false;
      }
    }
    if (!values.tax_person_name) {
      errors = false;
    }
    if (!values.tax_person_npwp) {
      errors = false;
    }
    let path_logo;
    if (values.path_logo !== null) {
      path_logo =
        typeof values.path_logo === 'object'
          ? values.path_logo.file
          : values.path_logo;
    } else {
      path_logo = '';
    }
    let rtk_id;
    let url;
    let data;
    let provinces;
    let cities;
    let subdistricts;
    let villages;
    const id = values.cmp_id;
    if (values.bank !== null && typeof values.bank !== 'undefined') {
      rtk_id =
        typeof values.bank === 'object' ? values.bank.value : values.bank;
    } else {
      rtk_id = '';
    }
    if (values.province !== null && typeof values.province !== 'undefined') {
      provinces =
        typeof values.province === 'object'
          ? values.province.value
          : values.province;
    } else {
      provinces = '';
    }
    if (values.city !== 0 && typeof values.city !== 'undefined') {
      cities =
        typeof values.city === 'object' ? values.city.value : values.city;
    } else {
      cities = '';
    }
    if (values.subdistrict !== 0 && typeof values.subdistrict !== 'undefined') {
      subdistricts =
        typeof values.subdistrict === 'object'
          ? values.subdistrict.value
          : values.subdistrict;
    } else {
      subdistricts = '';
    }
    if (values.village !== 0 && typeof values.village !== 'undefined') {
      villages =
        typeof values.village === 'object'
          ? values.village.value
          : values.village;
    } else {
      villages = '';
    }
    if (id === undefined || id === '') {
      url = `${apiws}/Orgcompany/saveItem`;
      data = {
        ...values,
        cuser_id: iduser,
        province: provinces,
        city: cities,
        subdistrict: subdistricts,
        village: villages,
        visi: this.state.visiEditor,
        misi: this.state.misiEditor,
        bank: rtk_id,
        path_logo,
      };
    } else {
      url = `${apiws}/Orgcompany/updateItem/${id}`;
      data = {
        ...values,
        muser_id: iduser,
        province: provinces,
        city: cities,
        subdistrict: subdistricts,
        village: villages,
        visi: this.state.visiEditor,
        misi: this.state.misiEditor,
        bank: rtk_id,
        path_logo,
      };
    }
    if (errors) {
      this.setState({ buttonSubmited: false });
      this.setState({ button_disabled: true });
      const formBody = Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
      }, new FormData());
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };

      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            this.setState({
              button_disabled: false,
              disabled_form: true,
              status_edit: true,
              hideEditor: true,
            });
            showNotifications('Success', 'Save Success', 'success');
            this.checkCompany();
            this.setState({ buttonSubmited: true });
          } else {
            this.setState({ button_disabled: false });
            showNotifications('Fail', 'Save Failed', 'danger');
            this.setState({ buttonSubmited: true });
          }
        })
        .catch(() => {
          this.setState({ button_disabled: false });
          this.setState({ buttonSubmited: true });
        });
    }
  }

  handleCancel() {
    this.checkCompany();
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({
      disabled_form: false,
      status_edit: false,
      hideEditor: false,
      disabled_cancel: false,
    });
  }

  render() {
    const {
      disabled_form,
      status_edit,
      company,
      address,
      arrCity,
      arrProvinces,
      arrSubdistrict,
      arrVillage,
      buttonSubmited,
      // province,
      // postal_code,
      // telp,
    } = this.state;
    const { handleSubmit, t } = this.props;
    // if (this.state.redirect) {
    //   return <Redirect to="/dashboard/dashboard_crypto" />;
    // }
    let button_action;
    if (status_edit) {
      button_action = (
        <Button
          color="success"
          onClick={this.handleEdit}
          type="submit"
          disabled={this.state.button_disabled}
          style={{ display: this.state.editcond === '1' ? '' : 'none' }}
        >
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          style={{ display: this.state.addcond === '1' ? '' : 'none' }}
          color="primary"
          name="companyform"
          id="companyform"
          type="submit"
          disabled={this.state.button_disabled}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }

    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 20px 30px 20px' }}>
                <form
                  className="form form--horizontal mt15"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="companyform"
                  id="companyform"
                >
                  <Container>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.COMPANY')}
                          </span>
                          <div className="form__form-group-field">
                            <input type="hidden" name="cmp_id" />
                            <Field
                              name="company"
                              disabled={disabled_form}
                              value={company}
                              component={renderTextInput}
                              type="text"
                              maxLength="100"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PHONE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="telp"
                              // value={telp}
                              disabled={disabled_form}
                              component={renderTextInput}
                              type="number"
                              maxLength="12"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.EMAIL')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="email"
                              disabled={disabled_form}
                              component={renderTextInput}
                              type="text"
                              maxLength="30"
                            />
                          </div>
                        </div>
                        {/* <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BANK')}</span>
                          <div className="form__form-group-field">
                            <Field
                              id="bank"
                              name="bank"
                              disabled={disabled_form}
                              component={renderSelectField}
                              type="text"
                              options={arrBank}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BANK_ACCOUNT')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="bank_account"
                              disabled={disabled_form}
                              component={renderTextInput}
                              type="number"
                              maxLength="30"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.CURRENCY')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="currency"
                              disabled={disabled_form}
                              component={renderTextInput}
                              type="text"
                              maxLength="3"
                            />
                          </div>
                        </div> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.TAX_PERSON_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="tax_person_name"
                              disabled={disabled_form}
                              value={company}
                              component={renderTextInput}
                              type="text"
                              maxLength="100"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.TAX_PERSON_NPWP')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="tax_person_npwp"
                              disabled={disabled_form}
                              component={renderMaskInput}
                              mask={[
                                /[0-9]/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.COMPANY_NPWP')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '18px' }}
                          >
                            <Field
                              name="company_npwp"
                              disabled={disabled_form}
                              component={renderMaskInput}
                              mask={[
                                /[0-9]/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.VISION')}
                          </span>
                          <div className="form__form-group-field">
                            <SunEditor
                              setDefaultStyle="font-family: Roboto; font-size: 14px;"
                              height="150px"
                              disable={this.state.hideEditor}
                              onChange={this.handleVisiChange}
                              setContents={this.state.visiEditor}
                              setOptions={{
                                buttonList: this.state.sunEditorButtonList,
                              }}
                              placeholder="What is your vision?"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ADDRESS')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '-15px' }}
                          >
                            <Field
                              name="address"
                              value={address}
                              disabled={disabled_form}
                              component={renderTextInput}
                              type="textarea"
                              maxLength="255"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PROVINCE')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '-10px' }}
                          >
                            <Field
                              name="province"
                              component={renderSelectField}
                              type="text"
                              options={arrProvinces}
                              onChange={this.changeprov}
                              disabled={disabled_form}
                              // props={{ disableTouched: false }}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CITY')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '-10px' }}
                          >
                            <Field
                              name="city"
                              component={renderSelectField}
                              // props={{ disableTouched: true }}
                              type="text"
                              options={arrCity}
                              onChange={this.changecity}
                              disabled={disabled_form}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.SUBDISTRICT')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '-10px' }}
                          >
                            <Field
                              name="subdistrict"
                              type="text"
                              component={renderSelectField}
                              options={arrSubdistrict}
                              onChange={this.changedistrict}
                              // props={{ disableTouched: true }}
                              disabled={disabled_form}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.VILLAGE')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '-10px' }}
                          >
                            <Field
                              name="village"
                              type="text"
                              component={renderSelectField}
                              // props={{ disableTouched: true }}
                              options={arrVillage}
                              disabled={disabled_form}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.POSTAL_CODE')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ marginBottom: '-5px' }}
                          >
                            <Field
                              name="postal_code"
                              disabled={disabled_form}
                              component={renderTextInput}
                              type="number"
                              maxLength="5"
                            />
                          </div>
                        </div>
                        {/* <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.COMPANY_LOGO')}</span>
                          <div className="form__form-group-field" style={{ 'marginBottom': '44px' }}>
                            <Field
                              name="path_logo"
                              disabled={disabled_form}
                              component={renderFileInputField}
                              type="text"
                            />
                          </div>
                        </div> */}
                        {/* <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.PDF_TEMPLATES')}</span>
                          <div className="form__form-group-field" style={{ 'marginBottom': '44px' }}>
                            <Field
                              name="path_template"
                              disabled={disabled_form}
                              component={renderDocumentInputField}
                              type="text"
                            />
                          </div>
                        </div> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.MISSION')}
                          </span>
                          <div className="form__form-group-field">
                            <SunEditor
                              setDefaultStyle="font-family: Roboto; font-size: 14px;"
                              height="150px"
                              disable={this.state.hideEditor}
                              onChange={this.handleMisiChange}
                              setContents={this.state.misiEditor}
                              setOptions={{
                                buttonList: this.state.sunEditorButtonList,
                              }}
                              placeholder="What is your mission?"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <hr />
                        <div
                          style={{
                            width: '100%',
                            marginTop: '20px',
                            marginBottom: '-40px',
                            textAlign: 'center',
                          }}
                        >
                          <Button
                            onClick={this.handleCancel}
                            disabled={this.state.disabled_cancel}
                            type="button"
                          >
                            {' '}
                            {t('FRM.CANCEL')}
                          </Button>
                          {buttonSubmited ? (
                            button_action
                          ) : (
                            <Button color="primary">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '6px' }}
                              />
                              Loading...
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md={12} xs={12}>
                        {(this.state.visiEditor !== '' || this.state.misiEditor !== '') && this.state.hideEditor === true && (
                          <div className="note-file-upload">
                            <div style={{ 'fontSize': '14px' }}>
                              <p><strong>{t('LBL.VISION')}</strong><br /></p>
                              {parse(this.state.visiEditor)}
                              <p><strong>{t('LBL.MISSION')}</strong><br /></p>
                              {parse(this.state.misiEditor)}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row> */}
                  </Container>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    province: {
      fieldLabel: 'Province',
      required: true,
    },
    city: {
      fieldLabel: 'City',
      required: true,
    },
    village: {
      fieldLabel: 'Village',
      required: true,
    },
    subdistrict: {
      fieldLabel: 'Subdistrict',
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  if (!values.company) {
    errors.company = 'Company field shouldn’t be empty';
  }
  if (!values.address) {
    errors.address = 'Address field shouldn’t be empty';
  }
  if (!values.telp) {
    errors.telp = 'Phone Number field shouldn’t be empty';
  } else if (!/^[0-9]*$/i.test(values.telp)) {
    errors.telp = 'Invalid Phone Number';
  }
  if (values.tax_person_npwp) {
    const otpn = values.tax_person_npwp;
    const patt1 = /[0-9]/g;
    const result = otpn.match(patt1);
    if (result.length < 15) {
      errors.tax_person_npwp = 'Must have 15 digit';
    }
  }
  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.postal_code) {
    errors.postal_code = 'Postal Code shouldn’t be empty or value is not valid';
  } else {
    const pc = values.postal_code;
    if (pc.length > 5) {
      errors.postal_code = 'Must have 5 digit';
    }
  }
  if (!values.company_npwp) {
    errors.company_npwp = 'Company NPWP field shouldn’t be empty';
  } else {
    const ocp = values.company_npwp;
    const patt1 = /[0-9]/g;
    const result = ocp.match(patt1);
    if (result.length < 15) {
      errors.company_npwp = 'Must have 15 digit';
    }
  }
  if (!values.tax_person_name) {
    errors.tax_person_name = 'Tax person name field shouldn’t be empty';
  }
  if (!values.tax_person_npwp) {
    errors.tax_person_npwp = 'Tax person npwp field shouldn’t be empty';
  } else {
    const ocp = values.tax_person_npwp;
    const patt1 = /[0-9]/g;
    const result = ocp.match(patt1);
    if (result.length < 15) {
      errors.tax_person_npwp = 'Must have 15 digit';
    }
  }

  return errors;
};

export default reduxForm({
  form: 'orgcompanyform',
  validate,
  touchOnChange: true,
  touchOnBlur: true,
  destroyOnUnmount: false,
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
})(translate('global')(CompanySettingForm));
