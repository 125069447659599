import React, { PureComponent } from 'react';
import { Container } from 'reactstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ProfilePanel from '../../Dashboards/Crypto/components/ProfilePanel';
// import TaskList from '../../Dashboards/Crypto/components/TaskList';
import LeaveList from './Components/viewLeaveList';
import { deleteCryptoTableData } from '../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../shared/prop-types/TablesProps';

class Employees extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  onDeleteCryptoTableData = (index, e) => {
    e.preventDefault();
    const arrayCopy = [...this.props.cryptoTable];
    arrayCopy.splice(index, 1);
    this.props.dispatch(deleteCryptoTableData(arrayCopy));
  };

  render() {
    // const { t } = this.props;
    return (
      <Container className="dashboard">
        <LeaveList
          parmid={this.props.location}
        />
      </Container>
    );
  }
}

export default connect(state => ({
  cryptoTable: state.cryptoTable.items,
}))(translate('common')(Employees));
