/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable operator-assignment */
import React, { PureComponent } from 'react';
import { Card } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
// import { ResponsiveContainer } from 'recharts';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};

class AverageComponent extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    office: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      // idUser: '',
      token: '',
      year: '',
      month: '',
      office: '',
      // intervalId: null,
    };
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    // this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getComponentAvg();
    });
    this.setState({ year: this.props.year });
    this.setState({ month: this.props.month });
    this.setState({ office: this.props.office });
  }
  componentWillReceiveProps(nextprops) {
    this.setState({ year: nextprops.year });
    this.setState({ month: nextprops.month });
    this.setState({ office: nextprops.office });
    setTimeout(() => {
      this.getComponentAvg();
    }, 200);
  }

  getComponentAvg() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = { year: this.state.year, month: this.state.month, off_id: this.state.office };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/chart/getaveragecomponent/`,
      formBody,
      config,
    ).then((response) => {
      const labels = [];
      const datas = [];
      const colour = [];
      const len = response.data.data;
      if (typeof len !== 'undefined') {
        for (let i = 0; i < response.data.data.length; i += 1) {
          colour.push(response.data.data[i].fill);
          labels.push(response.data.data[i].name);
          datas.push(response.data.data[i].total);
        }
        const initialState = {
          labels: labels,
          datasets: [
            {
              label: 'Age Number',
              backgroundColor: colour,
              // borderColor: '#ff0066',
              borderWidth: 1,
              hoverBackgroundColor: colour,
              // hoverBorderColor: '#cc0052',
              data: datas,
            },
          ],
        };
        this.setState({ data: initialState });
      }
    });
  }

  getDetail() {
    const detailGender = true;
    const allmenu = false;
    setTimeout(() => {
    }, 200); this.props.getmenu(detailGender, allmenu);
  }

  render() {
    const { t } = this.props;

    return (
      <Card>
        <div className="dashboard__chart chart_border">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.PAYROLL_COMPONENT')}
              </div>
              {/* <div className="w-100" style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  onClick={() => this.getDetail()}
                >{t('LBL.DETAIL')}
                </Button>
              </div> */}
            </div>
          </div>
          <div className="chart-body">
            <Bar data={this.state.data} options={options} />
          </div>
        </div>
      </Card>
    );
  }
}

export default translate('global')(AverageComponent);
