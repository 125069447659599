/*
 * Page Grade
 * Notes: Master Data Grade
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';

class AttendanceDayOffPages extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DAY_OFF_TITLE') },
      { '2': t('LBL.DATE') },
      { '3': t('LBL.DESCRIPTION') },
      { '4': t('LBL.THR') },
      { '5': t('LBL.MASS_LEAVE') },
      { '6': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['2%', '25%', '10%', '25%', '5%', '8%', '10%'],
      urlData: [],
      statusEdit: false,
      arrGroupShift: [],
      arrShift: [],
      showofficeinput: false,
      arrOffice: [],
      disabledThr: false,
      editcond: '',
      addcond: '',
      deletecond: '',
      loading: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteDayOff = this.deleteDayOff.bind(this);
    this.editDayOff = this.editDayOff.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getOffice = this.getOffice.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ urlData: `${ls.get('ws_ip')}/attdayoffoffice/getAllitem` });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getOffice();
      this.getButton();
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DAY_OFF_TITLE') },
      { '2': t('LBL.DATE') },
      { '3': t('LBL.DESCRIPTION') },
      { '4': t('LBL.THR') },
      { '5': t('LBL.MASS_LEAVE') },
      { '6': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          // console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getTableData() {
    this.child.reload();
  }

  getOffice() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgoffice/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ dta_office: response.data.data });
          const arr = [];
          this.state.dta_office.map((data) => {
            arr.push({ value: data.off_id, label: data.office });
            return arr;
          });
          this.setState({
            arrOffice: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  showInputOffice() {
    this.setState({ showofficeinput: true });
  }

  hideInputOffice() {
    this.setState({ showofficeinput: false });
  }

  toggle(item) {
    if (item === 'add') {
      this.hideInputOffice();
    }
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
    this.props.initialize({ thr: false, leave: false });
  }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.atd_id;
    let error;
    let atdApplies;
    if (!values.atd_title) {
      error = false;
    }
    if (!values.atd_applies_to) {
      error = false;
    }
    if (!values.description) {
      error = false;
    }
    if (typeof values.atd_applies_to === 'object') {
      atdApplies = values.atd_applies_to.value;
      if (atdApplies === 'regional') {
        if (!values.off_id) {
          error = false;
        }
      }
    } else {
      atdApplies = values.atd_applies_to;
      if (atdApplies === 'regional') {
        if (!values.off_id) {
          error = false;
        }
      }
    }
    let dta_office;
    if (typeof values.off_id === 'object') {
      dta_office = JSON.stringify(values.off_id);
    }
    if (this.state.disabledThr === true) {
      if (values.thr === false) {
        error = true;
      } else {
        error = false;
        Swal.fire({
          title: 'Warning',
          text: 'THR options has been chosen this year!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
      }
    } else {
      error = true;
    }
    let url;
    let data = {
      off_id: dta_office,
      atd_date: values.atd_date,
      description: values.description,
      atd_title: values.atd_title,
      atd_applies_to: atdApplies,
      is_thr: values.thr === true ? '1' : 0,
      is_mass_leave: values.leave === true ? '1' : 0,
    };
    if (id === undefined || id === '') {
      url = `${apiws}/attdayoffoffice/saveItem`;
      data = { ...data, cuser_id: userid };
    } else {
      url = `${apiws}/attdayoffoffice/updateItem/${id}`;
      data = { ...data, muser_id: userid };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      this.setState({ loading: true });
      axios.post(url, formBody, config).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.setState({ modal: false });
          this.child.reload('addedit');
          this.props.destroy();
          this.hideInputOffice();
          this.setState({ loading: false });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
    }
  }

  handleChange(val) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${apiws}/attdayoffoffice/getYear/${val._d.getFullYear()}`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const count_component = response.data.data.length;
          const year = [];
          if (count_component === 0) {
            this.setState({ disabledThr: false });
          } else {
            for (let i = 0; i < count_component; i++) {
              year.push({ year: response.data.data[i].is_cuti });
            }
            const counter = year.filter((e) => {
              if (e.year === 'true') {
                return true;
              }
              return false;
            });
            if (counter.length === 1) {
              this.setState({ disabledThr: true });
            } else {
              this.setState({ disabledThr: false });
            }
          }
        }
      });
  }

  async editDayOff(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/attdayoffoffice/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const isGlobal =
            getResponseData[0].is_global === '1' ? 'global' : 'regional';
          const arrEditOffice = [];
          let is_thr;
          let is_mass_leave;
          if (getResponseData[0].is_thr === '1') {
            is_thr = true;
            this.setState({ disabledThr: false });
          } else {
            is_thr = false;
            this.handleChange({
              _d: new Date(`${getResponseData[0].atd_date}`),
            });
          }
          if (getResponseData[0].is_mass_leave === '1') {
            is_mass_leave = true;
          } else {
            is_mass_leave = false;
          }
          if (isGlobal === 'global') {
            this.hideInputOffice();
          } else if (isGlobal === 'regional') {
            await axios
              .post(
                `${apiws}/attdayoffoffice/getAllItemRegionalById/${id}`,
                '',
                config,
              )
              .then(async (resRegional) => {
                const count_component = resRegional.data.data.length;
                for (let i = 0; i < count_component; i++) {
                  arrEditOffice.push({
                    value: resRegional.data.data[i].off_id,
                    label: resRegional.data.data[i].office,
                  });
                }
              });
            this.showInputOffice();
          }
          const datamaping = {
            atd_id: getResponseData[0].atd_id,
            atd_title: getResponseData[0].atd_title,
            atd_applies_to: isGlobal,
            atd_date: moment(new Date(getResponseData[0].atd_date)),
            description: getResponseData[0].atd_description,
            off_id: arrEditOffice,
            thr: is_thr,
            leave: is_mass_leave,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }

  deleteDayOff(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attdayoffoffice/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { urlData, arrOffice, showofficeinput, loading } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.DAY_OFF')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    let officeinput;
    if (showofficeinput) {
      officeinput = (
        <div className="form__form-group">
          <span className="form__form-group-label">Office (if regional)</span>
          <div className="form__form-group-field">
            <Field
              name="off_id"
              component={renderSelectField}
              multi="true"
              className='custom-multi'
              type="text"
              options={arrOffice}
            />
          </div>
        </div>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="atd_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteDayOff}
                  updateFunc={this.editDayOff}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.DAY_OFF')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addjobnameform"
                  id="addjobnameform"
                >
                  <input type="hidden" name="atd_id" />
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DAY_OFF_TITLE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atd_title"
                        component={renderTextInput}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DATE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atd_date"
                        component={renderDatePickerField}
                        onChange={this.handleChange}
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.APPLIES_TO')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atd_applies_to"
                        id="atd_applies_to"
                        component={renderSelectField}
                        type="text"
                        options={[
                          { value: 'global', label: 'Global' },
                          { value: 'regional', label: 'Regional' },
                        ]}
                        onChange={(e) => {
                          if (e.value === 'regional') {
                            this.showInputOffice();
                          } else {
                            this.hideInputOffice();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {officeinput}
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.COMPONENT')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ paddingLeft: '5px' }}
                    >
                      <Col md={6} xs={6} lg={6} xl={6}>
                        <Field
                          name="thr"
                          component={renderCheckBoxField}
                          label={`${t('LBL.THR')}`}
                        />
                      </Col>
                      <Col md={6} xs={6} lg={6} xl={6}>
                        <Field
                          name="leave"
                          component={renderCheckBoxField}
                          label={`${t('LBL.MASS_LEAVE')}`}
                        />
                      </Col>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DESCRIPTION')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="description"
                        component={renderTextInput}
                        type="textarea"
                      />
                    </div>
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {t('FRM.CANCEL')}
                </Button>
                {loading ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    form="addjobnameform"
                    type="submit"
                    disabled={pristine}
                  >
                    {t('FRM.SAVE')}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.atd_title) {
    errors.atd_title = 'Day off title field shouldn’t be empty';
  }
  if (!values.atd_applies_to) {
    errors.atd_applies_to = 'Day off applies to field shouldn’t be empty';
  }
  if (typeof values.atd_applies_to === 'object') {
    const atdApplies = values.atd_applies_to.value;
    if (atdApplies === 'regional') {
      if (!values.off_id) {
        errors.off_id = 'Day off applies to office field shouldn’t be empty';
      }
    }
  }
  if (!values.description) {
    errors.description = 'Description name field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'dayoffForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AttendanceDayOffPages));
