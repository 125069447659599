/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable max-len, class-methods-use-this */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    CardBody,
    Col,
    Row,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderTextInput from '../../../../shared/components/form/TextInput';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import utils from '../../../../utils';

let translator = () => { };

class viewListOutOfEmployee extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        destroy: PropTypes.func.isRequired,
        pristine: PropTypes.func.isRequired,
        parmid: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
        const { t } = props;
        translator = t;
        this.heads = [
            { 0: t('LBL.NUMBER') },
            { 1: t('LBL.EMPLOYEE') },
            { 2: t('LBL.UNIT_ORGANIZATION') },
            { 3: t('LBL.POSITION') },
            { 4: t('LBL.OFFICE') },
            { 5: t('LBL.START_DATE') },
            { 6: t('LBL.END_DATE') },
            { 7: t('FRM.FUNCTION') },
        ];

        this.state = {
            token: '',
            apiws: '',
            urlData: '',
            dataWidth: ['5%', '20%', '20%', '20%', '15%', '10%', '10%', '15%'],
            emp_id: '',
            redirect: false,
            addcond: '',
            editcond: '1',
            deletecond: '',
            arrTypeStatus: [],
            arrOffice: [],
            arrUnitOrg: [],
            arrJobName: [],
            arrJobTitle: [],
            arrJobBand: [],
            arrJobGrade: [],
            arrEmpStatus: [],
            modal: false,
            joinDate: '',
            emp_name: '',
            emp_code: '',
            company: '',
            off_id_old: '',
            office_old: '',
            org_id_old: '',
            unit_organization_old: '',
            disabledForm: true,
            triger: false,
            isAdmin: false,
        };

        this.getTableData = this.getTableData.bind(this);
        this.getCurrentData = this.getCurrentData.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getEmployeeData = this.getEmployeeData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.linkBack = this.linkBack.bind(this);
        this.getJobName = this.getJobName.bind(this);
        this.getJobTitle = this.getJobTitle.bind(this);
        this.getJobGrade = this.getJobGrade.bind(this);
    }

    componentDidMount = () => {

        this.setState({ token: ls.get('token') });
        this.setState({ isAdmin: ls.get('user_cred').fga_id != '3' ? true : false })
        this.setState({ apiws: ls.get('ws_ip') }, () => {
            this.getTableData();
            // this.getEmployee();
            this.getOffice();
            this.getUnitOrg();
            // this.getJobName();
            // this.getJobTitle();
            this.getJobBand();
            // this.getJobGrade();
            // this.getEmploymentStatus();
            this.getTypeStatus();
        });
        this.setState({ urlData: `${ls.get('ws_ip')}/appnotification/getNearlyExpiredContract/` });
    }

    componentWillReceiveProps(newProps) {
        const { t } = newProps;
        this.heads = [
            { 0: t('LBL.NUMBER') },
            { 1: t('LBL.EMPLOYEE') },
            { 2: t('LBL.UNIT_ORGANIZATION') },
            { 3: t('LBL.POSITION') },
            { 4: t('LBL.OFFICE') },
            { 5: t('LBL.START_DATE') },
            { 6: t('LBL.END_DATE') },
            { 7: t('FRM.FUNCTION') },
        ];
    }

    getTableData() {
        this.child.reload();
    }

    getOffice() {
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/orgoffice/getAll`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            // alert(JSON.stringify(response));
            if (response.data.status === 'ok') {
                const off = response.data.data;
                const arr = [];
                off.map(obj => (
                    arr.push({ value: obj.off_id, label: obj.office })
                ));
                this.setState({
                    arrOffice: arr,
                });
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    getUnitOrg() {
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/orgorganization/getAll/`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const org = response.data.data;
                const arr = [];
                org.map(obj => (
                    arr.push({ value: obj.org_id, label: obj.unit_organization })
                ));
                this.setState({
                    arrUnitOrg: arr,
                });
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    getJobName(id) {
        // const { triger } = this.state;
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/orgjobname/getAllHierarchy/${id.value}`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const jbn = response.data.data;
                const arr = [];
                // this.setState({ triger: true });
                jbn.map(obj => (
                    arr.push({ value: obj.jbn_id, label: obj.job_name })
                ));
                // setTimeout(() => {
                //   if (triger) {
                this.setState({
                    arrJobName: arr,
                    org_id: id.value,
                    triger: false,
                });
                //   }
                // }, 500);
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    getJobTitle(id) {
        const { org_id } = this.state;
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/orgposition/getAllHierarchy/${org_id}/${id.value}`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const pos = response.data.data;
                const arr = [];
                pos.map(obj => (
                    arr.push({ value: obj.pos_id, label: obj.position_nm })
                ));
                this.setState({
                    arrJobTitle: arr,
                });
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    getJobBand() {
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/orgjobband/getAll/`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const band = response.data.data;
                const arr = [];
                band.map(obj => (
                    arr.push({ value: obj.jbd_id, label: obj.job_band })
                ));
                this.setState({
                    arrJobBand: arr,
                });
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    getJobGrade(id) {
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/orgjobbandgrade/getAllHierarchy/${id.value}`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const grade = response.data.data;
                const arr = [];
                grade.map(obj => (
                    arr.push({ value: obj.jgd_id, label: obj.job_grade })
                ));
                this.setState({
                    arrJobGrade: arr,
                });
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    getTypeStatus() {
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        const url = `${ws_ip}/rtbemployeementtype/getAll/`;
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const off = response.data.data;
                const arr = [];
                off.map(obj => (
                    arr.push({ value: obj.ret_id, label: obj.type_name })
                ));
                this.setState({
                    arrTypeStatus: arr,
                });
            }
        }).catch((error) => {
            // this.setState({ button_disabled: false });
        });
    }

    // getEmployee() {
    //   const config = {
    //     headers: { Authorization: `Bearer ${ls.get('token')}` },
    //   };
    //   const empid = this.state.emp_id;
    //   axios.post(
    //     `${ls.get('ws_ip')}/Employee/getAllItemExceptedEmp/${empid}`,
    //     '',
    //     config,
    //   ).then((resemp) => {
    //     const emp = resemp.data.data;
    //     const array = [];
    //     if (emp.length === 0) {
    //       this.setState({ arrEmp: array });
    //     } else {
    //       emp.map(obj => (
    //         array.push({ value: obj.emp_id, label: obj.emp_name })
    //       ));
    //       this.setState({ arrEmp: array });
    //     }
    //   }).catch(() => {
    //     // console.log(error);
    //   });
    // }

    getEmpStatus(terminate) {
        const config = {
            headers: {
                Authorization: `bearer ${ls.get('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const ws_ip = ls.get('ws_ip');
        let url;
        if (terminate) {
            url = `${ws_ip}/rtbemployeementstatus/getAll/1`;
        } else {
            url = `${ws_ip}/rtbemployeementstatus/getAll/0`;
        }
        axios.post(
            url,
            '',
            config,
        ).then((response) => {
            if (response.data.status === 'ok') {
                const dataResponse = response.data.data;
                const Arr = [];
                dataResponse.map(obj => (
                    Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status })
                ));
                this.setState({ arrEmpStatus: Arr });
            }
        });
    }

    getEmployeeData(emp_id, config, apiws) {
        const url = `${apiws}/employee/getAllItemById/${emp_id}`;
        axios
            .post(url, '', config)
            .then((response) => {
                if (response.data.status === "ok") {
                    const dataResponse = response.data.data
                    const user = dataResponse.user[0]
                    this.setState({
                        emp_name: user.emp_name,
                        company: user.company,
                        emp_id: user.emp_id
                    })
                }
            })
            .catch((error) => {
                // console.log(error, "Error Fetch Employee Data")
            })
    }

    getCurrentData(id) {
        const { token, apiws } = this.state;
        const config = {
            headers: {
                Authorization: `bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        axios
            .post(`${apiws}/emppromotionmutation/getAllItemById/${id}`, '', config)
            .then((response) => {
                if (response.data.status === 'ok') {
                    this.setState({ statusEdit: true });
                    const getResponseData = response.data.data;
                    const emp_id = getResponseData[0].emp_id
                    this.getEmployeeData(emp_id, config, apiws)
                    const emp_code = getResponseData[0].emp_code
                    const offOld =
                        getResponseData[0].office_old === null
                            ? ''
                            : getResponseData[0].office_old;
                    const orgOld =
                        getResponseData[0].unit_organization_old === null
                            ? ''
                            : getResponseData[0].unit_organization_old;
                    const jbnOld =
                        getResponseData[0].job_name_old === null
                            ? ''
                            : getResponseData[0].job_name_old;
                    const jgdOld =
                        getResponseData[0].job_grade_old === null
                            ? ''
                            : getResponseData[0].job_grade_old;
                    const bandOld =
                        getResponseData[0].job_band_old === null
                            ? ''
                            : getResponseData[0].job_band_old;
                    const posOld =
                        getResponseData[0].position_old === null
                            ? ''
                            : getResponseData[0].position_old;
                    const emsOld =
                        getResponseData[0].employeement_status_old === null
                            ? ''
                            : getResponseData[0].employeement_status_old;
                    const offIdOld =
                        getResponseData[0].off_id_old === null
                            ? ''
                            : getResponseData[0].off_id_old;
                    const orgIdOld =
                        getResponseData[0].org_id_old === null
                            ? ''
                            : getResponseData[0].org_id_old;
                    const jbnIdOld =
                        getResponseData[0].jbn_id_old === null
                            ? ''
                            : getResponseData[0].jbn_id_old;
                    const jbdIdOld =
                        getResponseData[0].jbd_id_old === null
                            ? ''
                            : getResponseData[0].jbd_id_old;
                    const jgdIdOld =
                        getResponseData[0].jgd_id_old === null
                            ? ''
                            : getResponseData[0].jgd_id_old;
                    const posIdOld =
                        getResponseData[0].pos_id_old === null
                            ? ''
                            : getResponseData[0].pos_id_old;
                    const emsIdOld =
                        getResponseData[0].ems_id_old === null
                            ? ''
                            : getResponseData[0].ems_id_old;
                    const datamaping1 = {
                        office_old: offOld,
                        org_id: getResponseData[0].org_id,
                        emp_code: emp_code,
                        unit_organization_old: orgOld,
                        job_name_old: jbnOld,
                        job_grade_old: jgdOld,
                        job_band_old: bandOld,
                        position_old: posOld,
                        employeement_status_old: emsOld,
                        off_id_old: offIdOld,
                        org_id_old: orgIdOld,
                        jbn_id_old: jbnIdOld,
                        jbd_id_old: jbdIdOld,
                        jgd_id_old: jgdIdOld,
                        pos_id_old: posIdOld,
                        ems_id_old: emsIdOld,
                        join_date: getResponseData[0].join_date,
                        joinDate: moment(getResponseData[0].join_date).format('DD/MM/YYYY'),
                    };
                    const periodeEnd =
                        getResponseData[0].periode_end === null
                            ? ''
                            : moment(getResponseData[0].periode_end);
                    const description =
                        getResponseData[0].epm_description === null
                            ? ''
                            : getResponseData[0].epm_description;
                    const datamaping2 = {
                        epm_id: getResponseData[0].epm_id,
                        ret_id: getResponseData[0].ret_id,
                        off_id: getResponseData[0].off_id,
                        org_id: getResponseData[0].org_id,
                        jbn_id: getResponseData[0].jbn_id,
                        jgd_id: getResponseData[0].jgd_id,
                        jbd_id: getResponseData[0].jbd_id,
                        pos_id: getResponseData[0].pos_id,
                        ems_id: getResponseData[0].ems_id,
                        periode_start: moment(getResponseData[0].periode_start),
                        periode_end: periodeEnd,
                        epm_description: description,
                    };
                    if (getResponseData[0].ret_id === 5) {
                        this.setState({ lastday: true });
                        this.getEmpStatus(true);
                    } else {
                        this.setState({ lastday: false });
                        this.getEmpStatus(false);
                    }
                    this.setState(datamaping1);
                    this.setState({ disabledForm: false, modal: true }, () => {
                        this.props.destroy();
                        this.getJobName({ value: getResponseData[0].org_id });
                        this.getJobTitle({ value: getResponseData[0].jbn_id });
                        this.getJobGrade({ value: getResponseData[0].jbd_id });
                        this.getTypeStatus({ value: getResponseData[0].ret_id });
                        this.props.initialize(datamaping2);
                    });
                }
            })
            .catch(() => {
            });
    }

    handleSubmit(values) {
        const userid = ls.get('user_cred').usr_id;
        const { token, apiws, joinDate } = this.state;
        const id = values.epm_id;

        const join = `${joinDate.substring(6, 10)}-${joinDate.substring(3, 5)}-${joinDate.substring(0, 2)}`;
        const end = (typeof values.periode_end === 'undefined' || values.periode_end === '' || values.periode_end === null || values.periode_end === 'null') ? '' : moment(values.periode_end).format('YYYY-MM-DD');
        const data = {
            emp_id: this.state.emp_id,
            emp_code: this.state.emp_code,
            off_id_old: this.state.off_id_old,
            org_id_old: this.state.org_id_old,
            jbn_id_old: this.state.jbn_id_old,
            jbd_id_old: this.state.jbd_id_old,
            jgd_id_old: this.state.jgd_id_old,
            pos_id_old: this.state.pos_id_old,
            ems_id_old: this.state.ems_id_old,
            office_old: this.state.office_old,
            unit_organization_old: this.state.unit_organization_old,
            job_name_old: this.state.job_name_old,
            job_band_old: this.state.job_band_old,
            job_grade_old: this.state.job_grade_old,
            position_old: this.state.position_old,
            employeement_status_old: this.state.employeement_status_old,
            join_date: join,
            periodend: end,
        };

        const emsId = (typeof values.ems_id === 'object') ? values.ems_id.value : values.ems_id;
        const url = `${apiws}/emppromotionmutation/saveItem`;
        const dataBody = {
            ...data,
            ...values,
            cuser_id: userid,
            ems_id: emsId,
        };
        const formBody = Object.keys(dataBody).map(key =>
            `${encodeURIComponent(key)}=${encodeURIComponent(this.mapFormData(dataBody[key], key))}`).join('&');
        const config = {
            headers: {
                Authorization: `bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        this.setState({ displayLoad: true });
        axios.post(
            url,
            formBody,
            config,
        ).then((response) => {
            // alert(JSON.stringify(response));
            if (response.data.status === 'ok') {
                showNotifications('Success', 'Save Success', 'success');
                this.setState({ modal: false });
                this.child.reload('addedit');
                this.props.destroy();
            } else {
                showNotifications('Fail', 'Save Failed', 'danger');
            }
            this.setState({ displayLoad: false });
        });
    }

    mapFormData(b, a) {
        let val_b;
        if (b !== '') {
            if (typeof b === 'object') {
                if (b !== null) {
                    if (a.includes('periode', 'date')) {
                        val_b = moment(b).format('Y-M-D');
                    } else {
                        val_b = b.value;
                    }
                }
            } else {
                val_b = b;
            }
        } else {
            val_b = '';
        }
        return val_b;
    }

    toggle() {
        this.props.destroy();
        this.setState({
            modal: !this.state.modal,
            disabledForm: true,
        });
    }

    linkBack() {
        this.setState({ redirect: true });
    }

    changeType(x) {
        if (x.value === 5) {
            this.setState({ lastday: true });
            this.getEmpStatus(true);
        } else {
            this.setState({ lastday: false });
            this.getEmpStatus(false);
        }
        this.setState({
            type_name: x.value,
            disabledForm: false,
        });
    }

    render() {
        const { handleSubmit, t, pristine } = this.props;
        const {
            arrTypeStatus,
            arrEmpStatus,
            arrJobBand,
            arrJobGrade,
            arrJobName,
            arrJobTitle,
            arrOffice,
            arrUnitOrg,
        } = this.state;
        const backBtn = (
            <Button
                color="default"
                className="btn btn-secondary btn-sm btn_table_navbar"
                onClick={this.linkBack}
                style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
            >
                {t('FRM.BACK')}
            </Button>
        );
        const button_load = (
            <Button color="primary">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '6px' }}
                />
                Loading...
            </Button>
        );
        const button_action = (
            <Button
                color="primary"
                form="addpromotionmutation"
                type="submit"
                disabled={pristine}
            >{t('FRM.SAVE')}
            </Button>);
        if (this.state.redirect) {
            return (<Redirect to={{ pathname: '/dashboard' }} />);
        }
        const modalStyle = {
            maxWidth: '800px',
            textAlign: 'center',
        };
        return (
            <Col md={12} lg={12}>
                <Card>
                    <CardBody className="p0">
                        <Row className="m5" style={{ paddingLeft: '5px' }}>
                            <Col md={8}>
                                <h3 className="page-title">{t('LBL.EMPLOYEE_OUT_OF_CONTRACT')}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12} lg={12} xl={12}>
                                <Card>
                                    <div style={{ padding: '20px 5px 30px 10px' }}>
                                        <ViewListTable
                                            url={this.state.urlData}
                                            heads={this.heads}
                                            primaryKey="epm_id"
                                            widthTable={this.state.dataWidth}
                                            buttonAction={this.state.isAdmin ? ['add'] : ['view']}
                                            addFunc={this.getCurrentData}
                                            viewFunc={this.getCurrentData}
                                            displayStyleHead="none"
                                            onRef={ref => (this.child = ref)}
                                            backBtn={backBtn}
                                            editCond={this.state.editcond}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Modal
                                    isOpen={this.state.modal}
                                    toggle={this.toggle}
                                    className="modal-dialog modal-input"
                                    style={modalStyle}
                                >
                                    <ModalHeader toggle={this.toggle}> {t('FRM.ADD')} {t('LBL.PROMOTION_MUTATION')} </ModalHeader>
                                    <ModalBody>
                                        <form
                                            className="form form--horizontal form-promotion-mutation"
                                            onSubmit={handleSubmit(this.handleSubmit)}
                                            name="addpromotionmutation"
                                            id="addpromotionmutation"
                                        >
                                            <input type="hidden" name="emp_id" />
                                            <Container>
                                                <Row>
                                                    <Col sm="12" md="6" lg="6" xl="6">
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.EMPLOYEE')} {t('LBL.CODE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="emp_code"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.emp_code}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6" lg="6" xl="6" sm="12">
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOIN_DATE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="joinDateInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.joinDate}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12" md="6" lg="6" xl="6">
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.NAME')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="emp_name"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.emp_name}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" md="6" lg="6" xl="6">
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.TYPE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="ret_id"
                                                                    component={renderSelectField}
                                                                    options={arrTypeStatus}
                                                                    onChange={(x) => { this.changeType(x); }}
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr className="devider-line" />
                                                <Row>
                                                    <Col sm="12" md="6" lg="6" xl="6" className="col-separator">
                                                        <div className="text-center">
                                                            <h5 className="bold-text mb-2">{t('FRM.FROM')}</h5>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.company}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.office_old}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.unit_organization_old}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOB_NAME')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.job_name_old}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOB_TITLE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.position_old}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOB_BAND')} / {t('LBL.JOB_GRADE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.job_band_old}
                                                                    disabled
                                                                />
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.job_grade_old}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.STATUS')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.employeement_status_old}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" md="6" lg="6" xl="6">
                                                        <div className="text-center">
                                                            <h5 className="bold-text mb-2">{t('FRM.TO')}</h5>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="disableInput"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={this.state.company}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="off_id"
                                                                    component={renderSelectField}
                                                                    options={arrOffice}
                                                                    className="mb-0"
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="org_id"
                                                                    component={renderSelectField}
                                                                    options={arrUnitOrg}
                                                                    className="mb-0"
                                                                    onChange={this.getJobName}
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOB_NAME')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="jbn_id"
                                                                    component={renderSelectField}
                                                                    options={arrJobName}
                                                                    className="mb-0"
                                                                    onChange={this.getJobTitle}
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOB_TITLE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="pos_id"
                                                                    component={renderSelectField}
                                                                    options={arrJobTitle}
                                                                    className="mb-0"
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.JOB_BAND')} / {t('LBL.JOB_GRADE')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="jbd_id"
                                                                    component={renderSelectField}
                                                                    options={arrJobBand}
                                                                    className="mb-0"
                                                                    onChange={this.getJobGrade}
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                                <Field
                                                                    name="jgd_id"
                                                                    component={renderSelectField}
                                                                    options={arrJobGrade}
                                                                    className="mb-0"
                                                                    disabled={!this.state.isAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.STATUS')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="ems_id"
                                                                    component={renderSelectField}
                                                                    options={arrEmpStatus}
                                                                    className="mb-0"
                                                                    disabled={!this.state.isAdmin}
                                                                // onChange={(x) => { this.changeStatus(x); }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr className="devider-line" />
                                                <Row>
                                                    <Col sm="12" md="6" lg="6" xl="6">
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">
                                                                {(this.state.lastday) ? t('LBL.LAST_DAY') : t('LBL.START_DATE')}
                                                            </span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="periode_start"
                                                                    component={
                                                                        (this.state.disabledForm) ? renderTextInput : renderDatePickerField
                                                                    }
                                                                    disabled={!this.state.isAdmin}
                                                                    placeholder="dd/mm/yyyy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {
                                                        (this.state.lastday) ? '' :
                                                            <Col sm="12" md="6" lg="6" xl="6">
                                                                <div className="form__form-group">
                                                                    <span className="form__form-group-label">{t('LBL.END_DATE')}</span>
                                                                    <div className="form__form-group-field">
                                                                        <Field
                                                                            name="periode_end"
                                                                            component={
                                                                                (this.state.disabledForm) ? renderTextInput : renderDatePickerField
                                                                            }
                                                                            disabled={!this.state.isAdmin}
                                                                            placeholder="dd/mm/yyyy"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col sm="12" md="12" lg="12" xl="12" >
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t('LBL.DESCRIPTION')}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="epm_description"
                                                                    component={renderTextInput}
                                                                    type="textarea"
                                                                    disabled={this.state.disabledForm}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
                                        {(this.state.displayLoad) ? button_load : button_action}
                                    </ModalFooter>
                                </Modal>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
const validate = (values) => {
    const validation = {
        ret_id: {
            fieldLabel: 'Type',
            required: true,
        },
        org_id: {
            fieldLabel: 'Organization',
            required: true,
        },
        off_id: {
            fieldLabel: 'Office',
            required: true,
        },
        jbn_id: {
            fieldLabel: 'Job name',
            required: true,
        },
        jgd_id: {
            fieldLabel: 'Job grade',
            required: true,
        },
        jbd_id: {
            fieldLabel: 'Job band',
            required: true,
        },
        pos_id: {
            fieldLabel: 'Job title',
            required: true,
        },
        ems_id: {
            fieldLabel: 'Employeement status',
            required: true,
        },
        start_date: {
            fieldLabel: `Start ${translator('LBL.DATE')}`,
            required: true,
        },
        end_date: {
            fieldLabel: `End ${translator('LBL.DATE')}`,
            required: true,
            lockDateAfter: 'start_date',
            fieldLabelBefore: `start ${translator('LBL.DATE')}`,
        },
        leave_category: {
            fieldLabel: translator('LBL.LEAVE_TYPE'),
            required: true,
        },
    };
    const errors = utils.validate(validation, values);

    return errors;
};

export default reduxForm({
    form: 'leaveListForm', // a unique identifier for this form
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(translate('global')(viewListOutOfEmployee));
