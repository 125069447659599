/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import showNotifications from '../../../../Preferences/notificationMessages';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import renderRadioButtonField from '../../../../../shared/components/form/RadioButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';

// let compthr = '';
class Workflowactivity extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    setparam_order: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '20%', '20%', '20%', '10%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      payatt: [],
      displayPos: false,
      slcunit: [],
    };
    const { t } = props;

    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.ACTIVITY')} ${t('LBL.NAME')}`,
      `${t('FRM.NEXT')} ${t('LBL.ACTIVITY')}`,
      `${t('LBL.ORDER')} ${t('LBL.NUMBER')}`,
      // t('LBL.CONDITION'),
      t('LBL.ALTERNATE_ACTIVITY'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.getActivity = this.getActivity.bind(this);
    this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
    this.handleChangedistribution = this.handleChangedistribution.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.ACTIVITY')} ${t('LBL.NAME')}`,
      `${t('FRM.NEXT')} ${t('LBL.ACTIVITY')}`,
      `${t('LBL.ORDER')} ${t('LBL.NUMBER')}`,
      // t('LBL.CONDITION'),
      t('LBL.ALTERNATE_ACTIVITY'),
      // t('LBL.DETAIL'),
      t('FRM.FUNCTION'),
    ];
    this.setState({
      urlData: `${ls.get('ws_ip')}/wflactivityseq/getAllItem/${
        newProps.setparam_order
      }`,
    });
    this.timeout = setTimeout(() => {
      this.getTableData();
      this.getActivity(newProps.setparam_order);
      this.getButton();
    }, 300);
  }

  getTableData() {
    this.child.reload();
  }

  toggle(id) {
    const { t } = this.props;
    if (id === 'add') {
      this.props.destroy();
      this.setState({ titleModal: t('FRM.ADD') });
      this.setState({ status_edit: false });
    } else {
      this.setState({ status_edit: true });
      this.setState({ titleModal: t('FRM.EDIT') });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      // this.getActivity(this.props.setparam_order);
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getActivity(wf) {
    const { apiws, token } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${apiws}/Wflactivity/getAllwf/${wf}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          if (res.data.data.length > 0) {
            const dataResponse = res.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.wfa_id, label: obj.activity }),
            );
            this.setState({ arr_activity: Arr });
          }
        }
      });
  }

  search(values) {
    console.log(values);
  }

  editDetail(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/wflactivityseq/getAllItembyid/${id}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const dataResponse = res.data.data;
          // if (dataResponse[0].wfa_condition === 1) {
          //   this.setState({
          //     display_detail: '',
          //     display_approval: 'none',
          //   });
          // } else if (dataResponse[0].wfa_condition === 2) {
          //   this.setState({
          //     display_detail: 'none',
          //     display_approval: '',
          //   });
          // }
          this.toggle();
          this.props.initialize(dataResponse[0]);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteDetail(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const Body = [
      `wfl_id=${this.props.setparam_order}`,
      `duser_id=${userid}`,
    ].join('&');

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(`${apiws}/wflactivityseq/deleteItem/${id}`, Body, config)
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else if (response.data.status == 'already_exist') {
              showNotifications(
                'Cannot Edit/Delete',
                'Pending workflow request exists',
                'warning',
              );
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  handleChangedistribution(id) {
    if (id.value === '2') {
      this.setState({ displayPos: true });
    } else {
      this.setState({ displayPos: false });
    }
  }

  handleSubmitInput(values) {
    console.log(values);
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const error = true;
    const id = values.wfs_id;
    const wfl_id = this.props.setparam_order;
    const wfa_id =
      typeof values.wfa_id === 'object' ? values.wfa_id.value : values.wfa_id;
    const wfa_id_next =
      typeof values.wfa_id_next === 'object'
        ? values.wfa_id_next.value
        : values.wfa_id_next;
    // const wfa_condition = (typeof values.wfa_condition === 'object') ?
    //   values.wfa_condition.value : values.wfa_condition;
    const wfa_id_alternate =
      typeof values.wfa_id_alternate === 'object'
        ? values.wfa_id_alternate.value
        : values.wfa_id_alternate;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/wflactivityseq/saveItem`;
      data = {
        ...values,
        wfa_id,
        wfl_id,
        wfa_id_next,
        wfa_id_alternate,
        // wfa_condition,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/wflactivityseq/updateItem/${id}`;
      data = {
        ...values,
        wfa_id,
        wfl_id,
        wfa_id_next,
        wfa_id_alternate,
        // wfa_condition,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.props.destroy();
            this.setState({ modal: false });
            setTimeout(() => {
              this.child.reload();
            }, 2000);
          } else if (response.data.status == 'already_exist') {
            showNotifications(
              'Cannot Edit/Delete',
              'Pending workflow request exists',
              'warning',
            );
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  render() {
    const { handleSubmit, t, pristine, formatFunction } = this.props;
    const {
      status_edit,
      titleModal,
      urlData,
      wf_text_order,
      arr_activity,
      display_detail,
      display_approval,
      addcond,
      editcond,
      deletecond,
    } = this.state;
    let button_action;
    const addBtn = (
      <Button
        className="btn_table_navbar"
        color="primary"
        style={{ float: 'right', 'margin-bottom': '20px' }}
        onClick={() => this.toggle('add')}
      >
        {' '}
        {t('FRM.ADD')} {t('LBL.SEQUENCE')}
      </Button>
    );
    const backBtn = (
      <Button
        color="secondary"
        className="btn_table_navbar"
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
        onClick={() => this.props.onparam('')}
      >
        {' '}
        {t('FRM.BACK')} {t('LBL.WORKFLOW')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (status_edit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="activityseqforminput"
          disabled={pristine}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="activityseqforminput"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            {/* <div style={{ float: 'left', 'margin-left': '25px' }}>
              <h3>Format: </h3>
              <h5>Group Code: </h5>
              <h5>Group Name: </h5>
            </div> */}
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="wfs_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  updateFunc={this.editDetail}
                  deleteFunc={this.deleteDetail}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}> {titleModal} </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="activityseqforminput"
                  id="activityseqforminput"
                >
                  <Field
                    name="wfs_id"
                    component={renderTextInput}
                    type="hidden"
                  />
                  <Container>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ORDER')} {t('LBL.NUMBER')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="sequence_no"
                              component={renderTextInput}
                              type="number"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ACTIVITY')} {t('LBL.NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="wfa_id"
                              component={renderSelectField}
                              type="text"
                              options={arr_activity}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('FRM.NEXT')} {t('LBL.ACTIVITY')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="wfa_id_next"
                              component={renderSelectField}
                              type="text"
                              options={arr_activity}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Activity not approve
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="wfa_id_alternate"
                              component={renderSelectField}
                              type="text"
                              options={arr_activity}
                            />
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle('cancel');
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'reportadj',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Workflowactivity));
