/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faHome,
  faUserFriends,
  faUsers,
  faStreetView,
  faUser,
  faTable,
  faUserCog,
  // faArrowRight,
} from '@fortawesome/fontawesome-free-solid';
import JoblevelPage from './JobLevel';
import CompanyPage from './Company';
import OfficePage from './Office';
import PositionPage from './Position';
import ls from 'local-storage';
import UnitOrgPage from './UnitOrganization';
import UnitOrgLvlPage from './UnitOrganizationLevel';
import JobBand from './JobBand';
import GradePage from './Grade';
import JobName from './JobName';
// import JobBandGrade from './JobBandGrade';
import PropTypes from 'prop-types';
import axios from 'axios';

// const HeaderIcon = `${process.env.PUBLIC_URL}/img/preference_icon/icon-office.png`;
const HeaderIcon = '/img/preference_icon/icon-office.png';

class DefaultTabsBorderedTop extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      activeTab: '1',
      dataUpdate: false,
      menu: [],
      token: '',
      apiws: '',
      idUser: '',
      men_id: '',
      fga_id: '',
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
    this.getReference = this.getReference.bind(this);
  }
  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getReference(); });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({ men_id: location.state.men_id, fga_id: location.state.fga_id });
    }
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/menu/getreference/`,
      formBody,
      config,
    ).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map(obj => (
          array.push({ men_id: obj.men_id, label: obj.lbl_code })
        ));
        this.setState({
          menu: array,
        });
      }
    });
  }

  setUpdate = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = () => {
    this.setState({ dataUpdate: true });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Card>
          <CardBody className="p0">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="organization" />
                  <h3 className="page-title">{t('MEN.ORGANIZATION_SETTING')}</h3>
                  <h3 className="page-subhead subhead">{t('MEN.ORGANIZATION_SETTING')}</h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  {this.state.menu.map((data, index) => (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === (index + 1).toString() })}
                        onClick={() => {
                          this.toggle((index + 1).toString());
                        }}
                      >
                        <span className="navlink_tab-icon"><FontAwesomeIcon icon={faHome} /></span>
                        <span className="navlink_tab-title">{t(data.label)}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                  {/* <NavItem>
                   <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                          this.toggle('1');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faHome} /></span>
                      <span className="navlink_tab-title">{t('LBL.COMPANY')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                          this.toggle('2');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faBuilding} /></span>
                      <span className="navlink_tab-title">{t('LBL.OFFICE')}</span>
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '10' })}
                      onClick={() => {
                        this.toggle('10');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faUserFriends} /></span>
                      <span className="navlink_tab-title">
                        {t('LBL.UNIT_ORGANIZATION_LEVEL')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faUserFriends} /></span>
                      <span className="navlink_tab-title">
                        {t('LBL.UNIT_ORGANIZATION')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faUsers} /></span>
                      <span className="navlink_tab-title">{t('LBL.JOB_LEVEL')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '8' })}
                      onClick={() => {
                        this.toggle('8');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faUserCog} /></span>
                      <span className="navlink_tab-title">{t('LBL.JOB_NAME')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faStreetView} /></span>
                      <span className="navlink_tab-title">{t('LBL.JOB_TITLE')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '7' })}
                      onClick={() => {
                        this.toggle('7');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faTable} /></span>
                      <span className="navlink_tab-title">{t('LBL.JOB_GRADE')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => {
                        this.toggle('6');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faUser} /></span>
                      <span className="navlink_tab-title">{t('LBL.JOB_BAND')}</span>
                    </NavLink>
                  </NavItem> */}

                  { /* <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '9' })}
                      onClick={() => {
                        this.toggle('9');
                      }}
                    >
                      <span className="navlink_tab-icon"><FontAwesomeIcon icon={faUserCog} /></span>
                      <span className="navlink_tab-title">{t('LBL.JOB_BAND_GRADE')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="menu_scroll_icon">
                    <span className="navlink_tab-icon"><FontAwesomeIcon icon={faArrowRight} /></span>
                  </NavItem> */ }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.menu.map((data, index) => (
                    data.label === 'LBL.COMPANY' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <CompanyPage men_id={data.men_id} fga_id={this.state.fga_id} />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.OFFICE' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <OfficePage men_id={data.men_id} fga_id={this.state.fga_id} />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.UNIT_ORGANIZATION_LEVEL' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <UnitOrgLvlPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                          onUpdate={this.loadAfterSubmit}
                          onDelete={this.loadAfterSubmit}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.UNIT_ORGANIZATION' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <UnitOrgPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                          onUpdate={this.loadAfterSubmit}
                          inUpdate={this.state.dataUpdate}
                          onDelete={this.loadAfterSubmit}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.JOB_LEVEL' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <JoblevelPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                          onUpdate={this.loadAfterSubmit}
                          onDelete={this.loadAfterSubmit}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.JOB_NAME' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <JobName
                          onSubmitSuccess={this.loadAfterSubmit}
                          onDelete={this.loadAfterSubmit}
                          onUpdate={this.state.dataUpdate}
                          inUpdate={this.setUpdate}
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.JOB_TITLE' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <PositionPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                          onUpdate={this.state.dataUpdate}
                          inUpdate={this.setUpdate}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.JOB_GRADE' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <GradePage
                          onUpdate={this.loadAfterSubmit}
                          inUpdate={this.setUpdate}
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.JOB_BAND' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <JobBand
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                          onUpdate={this.state.dataUpdate}
                          inUpdate={this.setUpdate}
                        />
                      </TabPane>
                    )
                  ))}
                  { /* <TabPane tabId="9">
                    <JobBandGrade />
                    </TabPane> */ }
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container >
    );
  }
}

export default translate('global')(DefaultTabsBorderedTop);
