/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable quote-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Container, Row, Col, ButtonToolbar, Card, CardBody, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


let translator = () => { };

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class Attdispensation extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE'),
      t('LBL.JOB_TITLE'),
      t('LBL.CATEGORY'),
      t('LBL.STRATEGIC_OBJECTIVE'),
      //   {
      //     0: t('LBL.KPI_TARGETS'), 1: t('LBL.KPI'), 2: t('LBL.TARGET'), 3: t('LBL.ACHIEVEMENTS'),
      //   },
      t('LBL.KPI_TARGET'),
      t('LBL.KPI'),
      t('LBL.TARGET'),
      t('LBL.ACHIEVEMENTS'),
      t('LBL.PROBLEM_ISSUES'),
      t('LBL.COUNTER_ACTION'),
    ];
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      urlData: '',
      arrOffice: [],
      arrUnitOrganization: [],
      arrEmployee: [],
      arrPhase: [],
      condOffId: '',
      condEmployee: '',
      condYear: '',
      condOrgId: '',
      condStatus: '',
      condPeriod: '',
      type: '',
      displayTable: '',
      displaySave: '',
      displayLoad: 'none',
      filename: 'KPI Report',
      dataexcel: [],
      headerexcel: [],
      arrYear: [],
      disPeriod: true,
      arrPeriod: [],
      arrCategory: [],
      arrStrategic: [],
      trigger: false,
      dataWidth: ['5%', '10%', '20%', '30%', '40%'],
      org: '',
    };

    this.getOffice = this.getOffice.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getPhase = this.getPhase.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getYear = this.getYear.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount = () => {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getOffice();
      this.getUnitOrganization();
      this.getEmployee();
      this.getPhase();
      this.getPeriod();
      this.getCategory();
      this.getStrategic();
      this.getYear();
    });
  };

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/${org}`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getPhase() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/PfmPhase/getAllItem/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pph_id, label: obj.phase_name })
        ));
        this.setState({ arrPhase: array });
        console.log(array);
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getPeriod(year) {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const data = { 'year': year };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${ls.get('ws_ip')}/Pfmperiod/getByYear/`,
      formBody,
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrPeriod: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pfp_id, label: obj.period_name })
        ));
        this.setState({ arrPeriod: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getCategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/pfmcategoryreference/getAll/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrCategory: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pcr_id, label: obj.category_name })
        ));
        this.setState({ arrCategory: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getStrategic() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/pfmstrategic/getall`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrStrategic: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pso_id, label: obj.strategic_objective_name })
        ));
        this.setState({ arrStrategic: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport() {
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      'off_id': this.state.condOffId,
      'emp_id': this.state.condEmployee,
      'year': this.state.condYear,
      'org_id': this.state.condOrgId,
      'period': this.state.condPeriod,
      'category': this.state.condCategory,
      'strategic': this.state.condStrategic,
      'type': '2',
    };

    const bodyParameters = {
      cond: conds,
    };
    axios.post(
      `${apiws}/pfmkpiplan/getreportproblem`,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const { data, header } = res.data;
        const array = [];
        data.map((obj, index) => (
          array.push({ 'No': (index + 1), ...obj })
        ));
        this.setState({
          dataexcel: array, headerexcel: header, trigger: !this.state.trigger, displayTable: '', displayLoad: 'none', displaySave: '',
        });
        setTimeout(() => {
          showNotifications('Success', 'Load Success', 'success');
          this.setState({ type: '2' });
          // this.inputElement.click();
          this.handleDownload();
        }, 100);
      }
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  handleDownload() {
    if (!document) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Failed to access document object');
      }

      return null;
    }

    if (document.getElementById('table-to-xls').nodeType !== 1 || document.getElementById('table-to-xls').nodeName !== 'TABLE') {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Provided table property is not html table element');
      }

      return null;
    }

    const table = document.getElementById('table-to-xls').outerHTML;
    const sheet = String('KPI Report Problem and Issue');
    const filename = `${String('KPI Report Problem and Issue')}.xls`;

    const uri = 'data:application/vnd.ms-excel;base64,';
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{table}</body></html>';

    const context = {
      worksheet: sheet || 'Worksheet',
      table,
    };

    // If IE11
    if (window.navigator.msSaveOrOpenBlob) {
      const fileData = [
        // eslint-disable-next-line no-useless-concat
        `${'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' + 'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' + 'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' + 'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' + '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' + 'xml><![endif]--></head><body>'}${table}</body></html>`,
      ];
      const blobObject = new Blob(fileData);
      document.getElementById('react-html-table-to-excel').click()(() => {
        window.navigator.msSaveOrOpenBlob(blobObject, filename);
      });

      return true;
    }

    const element = window.document.createElement('a');
    element.href =
      uri +
      ReactHTMLTableToExcel.base64(ReactHTMLTableToExcel.format(template, context));
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    return true;
  }

  handleSubmit(values) {
    this.setState({ displayTable: 'none', displayLoad: '', displaySave: 'none' });
    const offid = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
    const orgId = (typeof values.organization === 'undefined' || values.organization === null) ? '' : values.organization.value;
    const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
    const year = (typeof values.period_year === 'undefined' || values.period_year === null) ? '' : values.period_year.value;
    const period = (typeof values.period === 'undefined' || values.period === null) ? '' : values.period.value;
    const phase = (typeof values.phase === 'undefined' || values.phase === null) ? '' : values.phase.value;
    const category = (typeof values.category === 'undefined' || values.category === null) ? '' : values.category.value;
    const strategic = (typeof values.strategic === 'undefined' || values.strategic === null) ? '' : values.strategic.value;
    const type_report = (typeof values.type_report === 'undefined' || values.type_report === null) ? '' : values.type_report.value;

    setTimeout(() => {
      this.setState({ condOffId: offid });
      this.setState({ condOrgId: orgId });
      this.setState({ condEmployee: empId });
      this.setState({ condPeriod: period });
      this.setState({ condYear: year });
      this.setState({ condPhase: phase });
      this.setState({ condCategory: category });
      this.setState({ condStrategic: strategic });
      this.setState({ type: type_report });
    }, 100);
    if (values.type_report.value === '1') {
      this.setState({
        displayTable: '', displayLoad: 'none', displaySave: '', urlData: `${ls.get('ws_ip')}/pfmkpiplan/getreportproblem`,
      });
      setTimeout(() => {
        this.getData();
      }, 200);
      showNotifications('Success', 'Load Success', 'success');
    } else {
      setTimeout(() => {
        this.getReport();
      }, 100);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      arrOffice,
      arrUnitOrganization,
      arrEmployee,
      arrPhase,
      displaySave,
      displayLoad,
      displayTable,
      condOffId,
      condOrgId,
      condEmployee,
      condPeriod,
      condYear,
      condStrategic,
      condCategory,
      type,
      arrYear,
      arrPeriod,
      condPhase,
      arrStrategic,
      arrCategory,
      data,
    } = this.state;
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0">
              {/* <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="leave" />
                  <h3 className="page-title">{t('LBL.LEAVE_REPORT')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.VIEW')} {t('LBL.LEAVE_REPORT')}</h3>
                </div>
              </Col> */}
              <Col md={12} lg={6} style={{ marginTop: '25px' }}>
                <form name="kpi_report" className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="office"
                        component={renderSelectField}
                        type="text"
                        options={arrOffice}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="organization"
                        component={renderSelectField}
                        type="text"
                        options={arrUnitOrganization}
                        onChange={(val) => {
                          if (typeof val.value === 'undefined') {
                            this.setState({ org: '' });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          } else {
                            this.setState({ org: val.value }); setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee"
                        component={renderSelectField}
                        type="text"
                        options={arrEmployee}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                    <div className="form__form-group-field form-flex">

                      <Field
                        name="period_year"
                        component={renderSelectField}
                        type="text"
                        placeholder="Year"
                        onChange={(val) => { this.getPeriod(val.value); }}
                        options={arrYear}
                      />
                      <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                      <Field
                        name="period"
                        component={renderSelectField}
                        type="text"
                        options={arrPeriod}
                        placeholder="Period"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.CATEGORY')}</span>
                    <div className="form__form-group-field form-flex">
                      <Field
                        name="category"
                        component={renderSelectField}
                        type="text"
                        placeholder="Category"
                        options={arrCategory}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.STRATEGIC_OBJECTIVE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="strategic"
                        component={renderSelectField}
                        type="text"
                        placeholder="Strategic Objective"
                        options={arrStrategic}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PHASE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phase"
                        component={renderSelectField}
                        type="text"
                        options={arrPhase}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="type_report"
                        component={renderSelectField}
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                  </div>
                  {(type === '2') && (
                    // <ExcelFile
                    //   element={
                    //     <div
                    //       onClick={this.handleClick}
                    //       onKeyPress={this.handleClick}
                    //       role="button"
                    //       tabIndex="0"
                    //     ><input type="hidden" ref={(ref) => { this.inputElement = ref; }} />
                    //     </div>}
                    //   filename={this.state.filename}
                    // >
                    //   <ExcelSheet data={this.state.dataexcel} name="Employees">
                    //     {
                    //       this.state.headerexcel.map(obj => (
                    //         <ExcelColumn label={obj} value={obj} />
                    //       ))
                    //     }
                    //   </ExcelSheet>
                    // </ExcelFile>

                    <div style={{ display: 'none' }}>
                      <div className="table-responsive">
                        <table id="table-to-xls" className="table data-grid-tbl responsive no-wrap table-no-btn">
                          <tr>
                            <th rowSpan="2">No</th>
                            <th rowSpan="2">Employee Name</th>
                            <th rowSpan="2">Job Title</th>
                            <th rowSpan="2">Category</th>
                            <th rowSpan="2">Strategic Objective</th>
                            <th colSpan="3">Kpi and Target</th>
                            <th rowSpan="2">Problem and Issue</th>
                            <th rowSpan="2">Counter Action</th>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>KPI</td>
                            <td style={{ fontWeight: 'bold' }}>Target</td>
                            <td style={{ fontWeight: 'bold' }}>Achievement</td>
                          </tr>
                          {this.state.dataexcel.map(dt => (
                            <tr>
                              <td>{dt.No}</td>
                              <td>{dt.emp_name}</td>
                              <td>{dt.position_nm}</td>
                              <td>{dt.category_name}</td>
                              <td>{dt.strategic_objective_name}</td>
                              <td>{dt.kpi_name}</td>
                              <td>{dt.target_name}</td>
                              <td>{dt.achievement_name}</td>
                              <td>{dt.problem_issue}</td>
                              <td>{dt.counter_action}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  )}
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                    <Button color="primary" style={{ display: displayLoad }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
            <Row style={{ display: displayTable }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <Row className="m5 table-nopadding">
                      <div className="card__title">
                        <h5 className="bold-text">{t('LBL.KPI_REPORT')}</h5>
                      </div>
                      <ViewListTable
                        url={urlData}
                        headerKpi="true"
                        arrHeaderKpi={this.heads}
                        // primaryKey="pyp_id"
                        widthTable={this.state.dataWidth}
                        buttonAction="noButton"
                        conds={{
                          'off_id': condOffId,
                          'emp_id': condEmployee,
                          'org_id': condOrgId,
                          'category': condCategory,
                          'strategic': condStrategic,
                          'period': condPeriod,
                          'year': condYear,
                          'phase': condPhase,
                          'type': '1',
                        }}
                        arrPhase={arrPhase}
                        onRef={ref => (this.child = ref)}
                        displayStyleHead="none"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    period_year: {
      fieldLabel: `${translator('LBL.YEAR')}`,
      required: true,
    },
    period: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      required: true,
    },
    type_report: {
      fieldLabel: `${translator('LBL.TYPE_REPORT')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'pni_report', // a unique identifier for this form
  validate,
})(translate('global')(Attdispensation));
