/* eslint-disable react/forbid-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len, no-unneeded-ternary */
/* eslint-disable no-return-assign, array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import NumberFormat from 'react-number-format';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import moment from 'moment';

let start;
let end;
class Performancemanagement extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    addFunc: PropTypes.func.isRequired,
    // comp_phase: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      arrPfmyear: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '15%', '15%', '25%', '15%'],
      statusEdit: false,
      urlData: '',
      pptvalue: '',
      editcond: '',
      addcond: '',
      deletecond: '',
      modalview: false,
      start_date: '',
      end_date: '',
      arrPhase: [],
      disabledView: false,
      trigger: false,
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERIOD_NAME') },
      { '2': t('LBL.PERIOD_DATE') },
      { '3': t('LBL.PHASE') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editPeriod = this.editPeriod.bind(this);
    this.editPhase = this.editPhase.bind(this);
    this.getDetail = this.getDetail.bind(this);
    this.submitPhase = this.submitPhase.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deletePeriod = this.deletePeriod.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getButton = this.getButton.bind(this);
    this.toggleview = this.toggleview.bind(this);
    this.togglephase = this.togglephase.bind(this);
    this.getPfmPhase = this.getPfmPhase.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERIOD_NAME') },
      { '2': t('LBL.PERIOD_DATE') },
      { '3': t('LBL.PHASE') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  // static getDerivedStateFromProps(props, state) {
  //   console.log(props);
  // }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.props.destroy();
    this.setState({ pptvalue: '' });
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
  }

  toggleview() {
    this.setState({ modalview: !this.state.modalview });
  }

  togglephase() {
    this.setState({ modalphase: !this.state.modalphase });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getYear();
      this.getButton();
    });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
      // arrPhase: this.props.comp_phase,
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/Pfmperiod/getAllItem/` });
  };

  async getPfmPhase() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/pfmphase/getAllItem`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const arr = [];
          await response.data.data.forEach((obj) => {
            arr.push({ pph_id: obj.pph_id, phase_name: obj.phase_name });
          });
          // this.setState({ arrPhase: [] });
          // arr.map((val) => {
          //   this.setState({ arrPhase: [...this.props.comp_phase, val], trigger: !this.state.trigger });
          // });
          this.setState({ arrPhase: arr, trigger: !this.state.trigger });
          // this.props.initialize({ comp_phase: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  handleSubmitInput(values) {
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.pfp_id;
    const start_date = moment(values.start_date).format('YYYY-MM-DD');
    const end_date = moment(values.end_date).format('YYYY-MM-DD');
    const userid = ls.get('user_cred').usr_id;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/pfmperiod/saveItem`;
      data = {
        period_name: values.period_name,
        start_date,
        end_date,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/pfmperiod/updateItem/${id}`;
      data = {
        period_name: values.period_name,
        start_date,
        end_date,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(url, formBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.setState({ modal: false });
        setTimeout(() => {
          this.child.reload();
        }, 2000);
      } else {
        showNotifications('Fail', response.data.status, 'danger');
      }
    });
  }

  editPeriod(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmperiod/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data[0];
          const datamaping = {
            period_name: getResponseData.period_name,
            start_date: moment(getResponseData.start_date),
            end_date: moment(getResponseData.end_date),
            pfp_id: getResponseData.pfp_id,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }

  editPhase(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmperiodphase/getAllPhaseById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const datamaping = [];
          const getResponseData = response.data.data;
          if (getResponseData.length > 0) {
            const arrPhase = [];
            getResponseData.forEach((obj) => {
              datamaping.push({
                ppp_id: obj.ppp_id,
                pph_id: obj.pph_id,
                phase: obj.active,
                start: obj.start_date === null ? null : moment(obj.start_date),
                end: obj.end_date === null ? null : moment(obj.end_date),
              });
              arrPhase.push({ phase_name: obj.phase_name, checked: true });
            });

            const data = datamaping.concat(response.data.pph);
            const arr = arrPhase.concat(response.data.pph);

            this.setState(
              {
                modalphase: true,
                statusEdit: true,
                disabledView: false,
                arrPhase: arr,
              },
              () => {
                this.props.initialize({ pfp_id: id, comp_phase: data });
              },
            );
          } else {
            // this.props.comp_phase.forEach((obj) => {
            //   this.state.arrPhase.push({
            //     pph_id: obj.pph_id, phase_name: obj.phase_name,
            //   });
            // });
            await this.getPfmPhase();
            this.props.initialize({
              pfp_id: id,
              comp_phase: this.state.arrPhase,
            });
            this.setState({
              modalphase: true,
              statusEdit: true,
              disabledView: false,
            });
          }
          start = response.data.pfp[0].start_date;
          end = response.data.pfp[0].end_date;
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }

  submitPhase(values) {
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { pfp_id } = values;
    const periodphase = [];
    values.comp_phase.forEach((element, index) => {
      if (element.ppp_id) {
        periodphase.push({
          ppp_id: element.ppp_id,
          pph_id: element.pph_id,
          label: element.phase_name,
          active:
            typeof element.phase === 'undefined' || element.phase === false
              ? '0'
              : '1',
          start_date:
            typeof element.start === 'undefined' || element.start === null
              ? ''
              : moment(element.start).format('YYYY-MM-DD'),
          end_date:
            typeof element.end === 'undefined' || element.end === null
              ? ''
              : moment(element.end).format('YYYY-MM-DD'),
        });
      } else {
        periodphase.push({
          pph_id: element.pph_id,
          label: element.phase_name,
          active:
            typeof element.phase === 'undefined' || element.phase === false
              ? '0'
              : '1',
          start_date:
            typeof element.start === 'undefined' || element.start === ''
              ? ''
              : moment(element.start).format('YYYY-MM-DD'),
          end_date:
            typeof element.end === 'undefined' || element.end === ''
              ? ''
              : moment(element.end).format('YYYY-MM-DD'),
        });
      }
    });
    const phase = JSON.stringify(periodphase);
    const userid = ls.get('user_cred').usr_id;
    const url = `${apiws}/pfmperiodphase/saveItem`;
    const data = {
      phase,
      pfp_id,
      cuser_id: userid,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(url, formBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.setState({ modalphase: false });
        this.child.reload();
      } else {
        showNotifications('Fail', response.data.status, 'danger');
      }
    });
  }

  getYear() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/pfmYear/getAll`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        const arr = [];
        response.data.data.map((obj) =>
          arr.push({ value: obj.pfy_id, label: obj.pfm_year }),
        );
        this.setState({ arrPfmyear: arr });
      }
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledinput: false });
    this.setState({ statusedit: false });
    this.setState({ buttondisabled: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusedit: true });
  }

  deletePeriod(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/pfmperiod/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  getDetail(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmperiod/getphaseperiodbyid/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datacompphase = [];
          const arrPhase = [];
          if (getResponseData.phase.length > 0) {
            getResponseData.phase.forEach((obj) => {
              if (obj.active === true) {
                datacompphase.push({
                  ppp_id: obj.ppp_id,
                  pph_id: obj.pph_id,
                  phase: obj.active,
                  start:
                    obj.start_date === null ? null : moment(obj.start_date),
                  end: obj.end_date === null ? null : moment(obj.end_date),
                });
                this.state.arrPhase.push({
                  pph_id: obj.pph_id,
                  phase_name: obj.phase_name,
                });
              }
            });
            const arr = arrPhase.concat(response.data.pph);
          }
          const datamaping = {
            period_name: getResponseData.period[0].period_name,
            start_date: moment(getResponseData.period[0].start_date),
            end_date: moment(getResponseData.period[0].end_date),
            reviewer: `${getResponseData.period[0].prv_id}`,
          };
          this.setState(
            {
              modalview: true,
              statusEdit: true,
              disabledView: true,
            },
            () => {
              setTimeout(() => {
                this.props.initialize({
                  comp_phase: datacompphase,
                  ...datamaping,
                });
              }, 200);
            },
          );
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }

  renderPhase = ({ fields, meta: { error }, t }) => (
    <div>
      {fields.map((item, index) => {
        // console.log(this.state.arrPhase[index].phase_name);
        const phaseName =
          typeof this.state.arrPhase[index].phase_name !== 'undefined'
            ? this.state.arrPhase[index].phase_name
            : '';
        const checked =
          typeof this.state.arrPhase[index].checked !== 'undefined'
            ? this.state.arrPhase[index].checked
            : false;
        return (
          <div>
            <Row>
              <input type="hidden" name={`${item}.ppp_id`} />
              <input type="hidden" name={`${item}.pph_id`} />
              <Col md="12" lg="12" xl="12" sm="12">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.PHASE')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name={`${item}.phase`}
                      component={renderCheckBoxField}
                      label={this.state.arrPhase ? phaseName : ''}
                      disabled={this.state.disabledView}
                      defaultChecked={checked}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6} sm={12} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.START_DATE')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name={`${item}.start`}
                      component={renderDatePickerField}
                      placeholder="Start Date"
                      disabled={this.state.disabledView}
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.END_DATE')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name={`${item}.end`}
                      component={renderDatePickerField}
                      placeholder="Start Date"
                      disabled={this.state.disabledView}
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
      {error && <li className="error">{error}</li>}
    </div>
  );

  render() {
    const { handleSubmit, t, pristine, addFunc } = this.props;
    const { statusEdit, urlData } = this.state;

    let button_action;
    const addBtn = (
      <ButtonToolbar style={{ float: 'right' }}>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={() => this.toggle()}
          style={{ display: this.state.addcond === '1' ? '' : 'none' }}
        >
          {t('FRM.ADD')} {t('LBL.PERIOD')}
        </Button>
      </ButtonToolbar>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button color="success" type="submit" form="periodform">
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="periodform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pfp_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editPeriod}
                  deleteFunc={this.deletePeriod}
                  viewFunc={this.getDetail}
                  setphaseFunc={this.editPhase}
                  buttonAction={['view', 'setphase', 'edit', 'delete']}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {t('LBL.PERIOD')} {t('LBL.FORM')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  // name="periodform"
                  id="periodform"
                >
                  <Container>
                    {/* <Row>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <input type="hidden" name="pfp_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="pfm_year"
                              component={renderSelectField}
                              options={arrPfmyear}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col xs={12} md={8} lg={8} xl={8}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PERIOD_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="period_name"
                              component={renderTextInput}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.START_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="start_date"
                              component={renderDatePickerField}
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.END_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="end_date"
                              component={renderDatePickerField}
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modalview}
              toggle={this.toggleview}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader>
                {t('LBL.VIEW')} {t('LBL.PERIOD')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  name="periodform"
                  id="periodform"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={8} lg={8} xl={8}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PERIOD_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="period_name"
                              component={renderTextInput}
                              disabled={this.state.disabledView}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.START_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <input type="hidden" name="epk_id" />
                            <Field
                              name="start_date"
                              component={renderDatePickerField}
                              placeholder="Start Date"
                              disabled={this.state.disabledView}
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.END_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="end_date"
                              component={renderDatePickerField}
                              placeholder="Start Date"
                              disabled={this.state.disabledView}
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12}>
                        <FieldArray
                          name="comp_phase"
                          component={this.renderPhase}
                          props={{
                            t,
                          }}
                        />
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggleview();
                  }}
                >
                  {t('FRM.BACK')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modalphase}
              toggle={this.togglephase}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader>
                {t('LBL.PHASE')} {t('LBL.FORM')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.submitPhase)}
                  name="periodform"
                  id="periodform"
                >
                  <Container>
                    <input type="hidden" name="pfp_id" />
                    <Col md={12} sm={12}>
                      <FieldArray
                        name="comp_phase"
                        component={this.renderPhase}
                        props={{
                          t,
                        }}
                      />
                    </Col>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.togglephase();
                  }}
                >
                  {t('FRM.BACK')}
                </Button>
                <Button color="success" type="submit" form="periodform">
                  {' '}
                  {t('FRM.EDIT')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  // console.log(values);
  // const valid = {
  //   component_payroll: {
  //     dynamicField: true,
  //     children: [
  //       { name: 'pec_value', label: 'Value', required: true },
  //     ],
  //   },
  // };

  const errors = {};
  if (typeof values.comp_phase !== 'undefined') {
    errors.comp_phase = [];
    values.comp_phase.map((item, index) => {
      errors.comp_phase.push({});
      if (typeof item.phase !== 'undefined' && item.phase === true) {
        // console.log(errors.comp_phase);
        if (!item.start) {
          errors.comp_phase[index].start = "Value shouldn't be empty";
        }
        if (!item.end) {
          errors.comp_phase[index].end = "Value shouldn't be empty";
        }
      }
    });
  }
  if (!values.period_name) {
    errors.period_name = "Period Name shouldn't be empty";
  }
  if (!values.start_date) {
    errors.start_date = "Start Date shouldn't be empty";
  }
  if (!values.end_date) {
    errors.end_date = "End Date shouldn't be empty";
  }
  if (values.end_date !== null) {
    if (
      typeof values.start_date === 'object' &&
      values.start_date.isAfter(values.end_date)
    ) {
      errors.end_date = 'End date shouldn’t less than Start date';
    }
  }

  if (moment(values.start_date).format('YYYYY') < new Date().getFullYear()) {
    errors.start_date = 'Start Date should be in current year or more';
  }
  if (moment(values.end_date).format('YYYYY') < new Date().getFullYear()) {
    errors.end_date = 'Start Date should be in current year or more';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'Performancemanagement',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Performancemanagement));
