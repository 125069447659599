/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-return-assign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
import React, { PureComponent } from 'react';
import { Container, Row, Col, ButtonToolbar, Card, CardBody, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
import ViewListTable from '../../../shared/components/table/viewListTable';
import showNotifications from '../../../containers/Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../../utils';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';

let translator = () => { };

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class PromotionMutationReport extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.TYPE'),
      `${t('LBL.OFFICE')} ${t('LBL.BEFORE')}`,
      `${t('LBL.UNIT_ORGANIZATION')} ${t('LBL.BEFORE')}`,
      `${t('LBL.JOB_NAME')} ${t('LBL.BEFORE')}`,
      `${t('LBL.JOB_TITLE')} ${t('LBL.BEFORE')}`,
      `${t('LBL.JOB_BAND')} ${t('LBL.BEFORE')}`,
      `${t('LBL.GRADE')} ${t('LBL.BEFORE')}`,
      `${t('LBL.STATUS')} ${t('LBL.BEFORE')}`,
      `${t('LBL.OFFICE')} ${t('LBL.AFTER')}`,
      `${t('LBL.UNIT_ORGANIZATION')} ${t('LBL.AFTER')}`,
      `${t('LBL.JOB_NAME')} ${t('LBL.AFTER')}`,
      `${t('LBL.JOB_TITLE')} ${t('LBL.AFTER')}`,
      `${t('LBL.JOB_BAND')} ${t('LBL.AFTER')}`,
      `${t('LBL.GRADE')} ${t('LBL.AFTER')}`,
      `${t('LBL.STATUS')} ${t('LBL.AFTER')}`,
    ];
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      urlData: '',
      arrCompany: [],
      arrUnitOrganization: [],
      arrEmployee: [],
      arrTypeStatus: [],
      arrEmpStatus: [],
      condCmpId: '',
      condEmployee: '',
      condEnd: '',
      condOrgId: '',
      condRetId: '',
      condEmsId: '',
      condStart: '',
      type: '',
      displayTable: '',
      displaySave: '',
      displayLoad: 'none',
      filename: 'Prommotion Mutation Report',
      dataexcel: [],
      headerexcel: [],
      org: '',
    };

    this.getCompany = this.getCompany.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getTypeStatus = this.getTypeStatus.bind(this);
    this.getEmpStatus = this.getEmpStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getReport = this.getReport.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount = () => {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getCompany();
      this.getUnitOrganization();
      this.getEmployee();
      this.getTypeStatus();
      this.getEmpStatus();
      this.getData();
    });
    // this.setState({ urlData: `${ls.get('ws_ip')}/reportpromotionmutation/getAllItem` });
  };

  getCompany() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgcompany/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.cmp_id, label: obj.company })
        ));
        this.setState({ arrCompany: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/${org}`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getTypeStatus() {
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/rtbemployeementtype/getAll/`;
    axios.post(
      url,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const off = response.data.data;
        const arr = [];
        off.map(obj => (
          arr.push({ value: obj.ret_id, label: obj.type_name })
        ));
        this.setState({
          arrTypeStatus: arr,
        });
      }
    }).catch((error) => {
      console.log(error);
      // this.setState({ button_disabled: false });
    });
  }

  getEmpStatus() {
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    axios.post(
      `${ws_ip}/rtbemployeementstatus/getAll`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status })
        ));
        this.setState({ arrEmpStatus: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport() {
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      // 'cmp_id': condCmpId,
      'emp_id': this.state.condEmployee,
      'periode_end': this.state.condEnd,
      'org_id': this.state.condOrgId,
      'ret_id': this.state.condRetId,
      'ems_id': this.state.condEmsId,
      'periode_start': this.state.condStart,
      'type': '2',
    };
    const bodyParameters = {
      cond: conds,
    };
    axios.post(
      `${apiws}/reportpromotionmutation/getAllItem`,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const { data, header } = res.data;
        const array = [];
        data.map((obj, index) => (
          array.push({ 'No': (index + 1), ...obj })
        ));
        this.setState({ dataexcel: array, headerexcel: header });
        this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
        setTimeout(() => {
          showNotifications('Success', 'Load Success', 'success');
          this.setState({ type: '2' });
          this.inputElement.click();
        }, 500);
      }
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  handleSubmit(values) {
    this.setState({ displayTable: 'none', displayLoad: '', displaySave: 'none' });
    const cmpId = (typeof values.company === 'undefined' || values.company === null) ? '' : values.company.value;
    const orgId = (typeof values.organization === 'undefined' || values.organization === null) ? '' : values.organization.value;
    const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
    const start = (typeof values.period_start === 'undefined' || values.period_start === null) ? '' : values.period_start.format('YYYY-MM-DD');
    const end = (typeof values.period_end === 'undefined' || values.period_end === null) ? '' : values.period_end.format('YYYY-MM-DD');
    const retId = (typeof values.ret_id === 'undefined' || values.ret_id === null) ? '' : values.ret_id.value;
    const emsId = (typeof values.ems_id === 'undefined' || values.ems_id === null) ? '' : values.ems_id.value;
    const type_report = (typeof values.type_report === 'undefined' || values.type_report === null) ? '' : values.type_report.value;

    setTimeout(() => {
      this.setState({ condCmpId: cmpId });
      this.setState({ condOrgId: orgId });
      this.setState({ condEmployee: empId });
      this.setState({ condStart: start });
      this.setState({ condEnd: end });
      this.setState({ condRetId: retId });
      this.setState({ condEmsId: emsId });
      this.setState({ type: type_report });
    }, 100);
    if (values.type_report.value === '1') {
      setTimeout(() => {
        this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
        showNotifications('Success', 'Load Success', 'success');
        this.setState({ urlData: `${ls.get('ws_ip')}/reportpromotionmutation/getAllItem` });
        this.getData();
      }, 100);
    } else {
      setTimeout(() => {
        this.getReport();
      }, 100);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      arrCompany,
      arrUnitOrganization,
      arrEmployee,
      arrTypeStatus,
      arrEmpStatus,
      // condCmpId,
      condEmployee,
      condEnd,
      condOrgId,
      condRetId,
      condEmsId,
      condStart,
      type,
      displayLoad,
      displaySave,
      displayTable,
    } = this.state;
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="leave" />
                  <h3 className="page-title">{t('LBL.PROMOTION_MUTATION')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.VIEW')} {t('LBL.PROMOTION_MUTATION_REPORT')}</h3>
                </div>
              </Col>
              <Col md={12} lg={6} style={{ marginTop: '25px' }}>
                <form className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="company"
                        component={renderSelectField}
                        type="text"
                        options={arrCompany}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="organization"
                        component={renderSelectField}
                        type="text"
                        options={arrUnitOrganization}
                        onChange={(val) => {
                          if (typeof val.value === 'undefined') {
                            this.setState({ org: '' });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          } else {
                            this.setState({ org: val.value }); setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee"
                        component={renderSelectField}
                        type="text"
                        options={arrEmployee}
                      />
                    </div>
                  </div>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                    <div className="form__form-group-field" >
                      <Field
                        name="period_date"
                        component={renderIntervalDatePickerField}
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                  </div> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                    <div className="form__form-group-field" >
                      <Field
                        name="period_start"
                        component={renderDatePickerField}
                        placeholder="Start Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                      <MinusIcon className="date-picker__svg" />
                      <Field
                        name="period_end"
                        component={renderDatePickerField}
                        type="text"
                        placeholder="End Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TYPE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="ret_id"
                        component={renderSelectField}
                        options={arrTypeStatus}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="ems_id"
                        component={renderSelectField}
                        options={arrEmpStatus}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="type_report"
                        component={renderSelectField}
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                  </div>
                  {(type === '2') && (
                    <ExcelFile
                      element={
                        <div
                          onClick={this.handleClick}
                          onKeyPress={this.handleClick}
                          role="button"
                          tabIndex="0"
                        ><input type="hidden" ref={(ref) => { this.inputElement = ref; }} />
                        </div>}
                      filename={this.state.filename}
                    >
                      <ExcelSheet data={this.state.dataexcel} name="Employees">
                        {
                          this.state.headerexcel.map(obj => (
                            <ExcelColumn label={obj} value={obj} />
                          ))
                        }
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                    <Button color="primary" style={{ display: displayLoad }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
            <Row style={{ display: displayTable }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <Row className="m5 table-nopadding">
                      <div className="card__title">
                        <h5 className="bold-text">{t('LBL.PROMOTION_MUTATION_REPORT')}</h5>
                      </div>
                      <ViewListTable
                        url={urlData}
                        heads={this.heads}
                        // primaryKey="pyp_id"
                        // widthTable={this.state.dataWidth}
                        buttonAction="noButton"
                        conds={{
                          // 'cmp_id': condCmpId,
                          'emp_id': condEmployee,
                          'periode_end': condEnd,
                          'org_id': condOrgId,
                          'ret_id': condRetId,
                          'ems_id': condEmsId,
                          'periode_start': condStart,
                          'type': type,
                        }}
                        onRef={ref => (this.child = ref)}
                        displayStyleHead="none"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12} lg={12}>
                <div className="table-responsive" style={{ marginLeft: '15px', marginRight: '15px', width: '97.5%' }}>
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th rowSpan="2">No</th>
                        <th rowSpan="2">Employee Name</th>
                        <th rowSpan="2">Employee Code</th>
                        <th rowSpan="2">PMK Type</th>
                        <th colSpan="7" className="text-center">Before</th>
                        <th colSpan="7" className="text-center">After</th>
                      </tr>
                      <tr>
                        <th>Company</th>
                        <th>Office</th>
                        <th>Organization</th>
                        <th>Job Name</th>
                        <th>Job Title</th>
                        <th>Job Band/Grade</th>
                        <th>Status</th>
                        <th>Company</th>
                        <th>Office</th>
                        <th>Organization</th>
                        <th>Job Name</th>
                        <th>Job Title</th>
                        <th>Job Band/Grade</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Rosi Sekiro</td>
                        <td>S00001</td>
                        <td>Promotion</td>
                        <td>PT. Spasi Indonesia</td>
                        <td>Head Office</td>
                        <td>Accounting Section</td>
                        <td>Section Head</td>
                        <td>Accounting Supervisor</td>
                        <td>Supervisor/Grade 6</td>
                        <td>Kontrak</td>
                        <td>PT. Spasi Indonesia</td>
                        <td>Head Office</td>
                        <td>Accounting Department</td>
                        <td>Department</td>
                        <td>Accounting Manager</td>
                        <td>Manager/Grade 7</td>
                        <td>Tetap</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row> */}
          </div>
        </Card>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    type_report: {
      fieldLabel: `${translator('LBL.TYPE_REPORT')}`,
      required: true,
    },
    period_start: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      required: true,
    },
    period_end: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      required: true,
    },

  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'promotion_mutation_form', // a unique identifier for this form
  validate,
})(translate('global')(PromotionMutationReport));
