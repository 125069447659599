/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable prefer-destructuring */
import React, { PureComponent } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import Swal from 'sweetalert2';
import { text } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/fontawesome-free-solid';

class DocumentInputField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    value: null,
  };

  constructor() {
    super();
    this.state = {
      fileDoc: '',
      showModal: false,
    };

    this.handleDocPreview = this.handleDocPreview.bind(this);
  }

  handleDocPreview(e) {
    const {
      onChange, name, value,
    } = this.props;
    e.preventDefault();
    const reader = new FileReader();
    // convert files to an array
    const files = [...e.target.files];
    reader.onloadend = () => {
      this.setState({ fileDoc: reader.result });
    };

    const fileType = files[0].type;
    const validImageTypes = ['application/pdf'];
    if (!validImageTypes.includes(fileType)) {
      Swal.fire({
        title: 'Warning',
        text: 'Wrong type of file!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      onChange({ file: files[0], name: files[0].name });
      reader.readAsDataURL(files[0]);
    }
  }
  render() {
    const {
      onChange, name, value, disabled,
    } = this.props;
    const { fileDoc, showModal } = this.state;
    const toggle = () => this.setState({ showModal: !showModal });
    const closeButton = {
      position: 'absolute',
      top: '15px',
      right: '15px',
      color: 'white',
      fontSize: '50px',
    };
    const externalCloseBtn = <button className="close" style={closeButton} onClick={toggle}>&times;</button>;
    let $docPreview = null;
    let filename;
    let src = null;

    if (value) {
      if (typeof value.name !== 'undefined') {
        src = fileDoc;
        filename = value.name;
      } else {
        const str = value.split('/company/');
        src = `${`${ls.get('ws_ip')}/upload/overviews${value}`}`;
        filename = str[1];
      }
      $docPreview = (<Button style={{ marginBottom: '0px' }} color="primary" type="button" onClick={toggle} ><FontAwesomeIcon icon={faFilePdf} />{filename}</Button>);
    }
    return (
      <div className="form__form-group-file">
        {$docPreview}
        {disabled
          ? ''
          : <label htmlFor={name} style={{ padding: '10px 25px' }}>Choose the file</label>
        }
        <div>
          <input
            type="file"
            name={name}
            id={name}
            disabled={disabled}
            onChange={this.handleDocPreview}
          />
        </div>

        <Modal
          isOpen={showModal}
          className="modal-dialog modal-input"
          style={{
            maxWidth: '80%',
            textAlign: 'center',
          }}
          toggle={toggle}
          external={externalCloseBtn}
        >
          <ModalBody style={{ height: '90vh' }}>
            <iframe title="Payslip" width="100%" height="100%" src={src} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const renderDocInputField = props => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <DocumentInputField
      {...props.input}
      disabled={props.disabled}
    />
    {props.type === 'textemployee' && props.meta.error &&
      <span className="form__form-group-error">{props.meta.error}
      </span>}
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderDocInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

renderDocInputField.defaultProps = {
  meta: null,
  type: text,
  disabled: false,
};

export default renderDocInputField;
