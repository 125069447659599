const CryptoJS = require('crypto-js');

const salt = 'pH1R0Go-PH1134K4';
const iv = 'b24af491ab32f2bc';
const iterations = 999;

export const security = {
  getKey(passphrase, salt) {
    var key = CryptoJS.PBKDF2(passphrase, salt, {
      hasher: CryptoJS.algo.SHA256,
      keySize: 32 / 8,
      iterations,
    });
    return key.toString();
  },
  encrypt(passphrase, plainText) {
    let encrypted = '';
    var key = this.getKey(passphrase, salt);
    const cipher = CryptoJS.AES.encrypt(
      plainText,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    encrypted = this.base64ToHex(cipher.toString()).toLocaleLowerCase();
    return encrypted;
  },

  decrypt(passphrase, encryptedText) {
    var key = this.getKey(passphrase, salt);
    const decrypted = CryptoJS.AES.decrypt(
      this.hexToBase64(encryptedText),
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
  },

  base64ToHex(str) {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += hex.length === 2 ? hex : '0' + hex;
    }
    return result.toUpperCase();
  },
  hexToBase64(hexstring) {
    return btoa(
      hexstring
        .match(/\w{2}/g)
        .map(function(a) {
          return String.fromCharCode(parseInt(a, 16));
        })
        .join(''),
    );
  },
};
