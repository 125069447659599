import React, { PureComponent } from 'react';
import {
  Row,
  Col,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import TaskTab from './TaskTab';
import AlertNotifTab from './AlertNotifTab';
import BirthdayTab from './BirthdayTab';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { store } from '../../../App/store';
import { connect } from 'react-redux';
// import ShortCut from './MiniShortcut';
// import showResults from './Show';
// import ProfileSettings from './ProfileSettings';

class ProfileTabs extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      badgeTask: false,
      badgeNotif: false,
      badgeBirthday: false,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { t } = this.props;
    let { badgeTask, badgeBirthday, badgeNotif } = this.state;
    return (
      <Card className="card--not-full-height mt-2 pt-2 mt-n3">
        <div className="profile__card tabs tabs--bordered-bottom">
          <Row style={{ height: '180px' }}>
            <Col md="12">
              <div className="tabs__wrap navtab-tasklist">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        {t('LBL.TASK')}
                        {this?.props.user?.tasklist?.length > 0 && (
                          <span
                            class="badge"
                            style={{
                              width: '13px',
                              height: '13px',
                              borderRadius: '7px',
                              padding: '0px 5px',
                              position: 'absolute',
                              top: '-0.4rem',
                              right: '-1.2rem',
                              backgroundColor: '#F06262',
                            }}
                          >
                            &nbsp;
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2',
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        {t('LBL.NOTIFICATION')}
                        {this?.props.user?.notification?.length > 0 && (
                          <span
                            class="badge"
                            style={{
                              width: '13px',
                              height: '13px',
                              borderRadius: '7px',
                              padding: '0px 5px',
                              position: 'absolute',
                              top: '-0.4rem',
                              right: '-1.2rem',
                              backgroundColor: '#F06262',
                            }}
                          >
                            &nbsp;
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '3',
                      })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        {t('LBL.BIRTHDAY')}
                        {this?.props.user?.birthday?.length > 0 && (
                          <span
                            class="badge"
                            style={{
                              width: '13px',
                              height: '13px',
                              borderRadius: '7px',
                              padding: '0px 5px',
                              position: 'absolute',
                              top: '-0.4rem',
                              right: '-1.2rem',
                              backgroundColor: '#F06262',
                            }}
                          >
                            &nbsp;
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <TaskTab badgeTask={badgeTask} />
                  </TabPane>
                  <TabPane tabId="2">
                    <AlertNotifTab badge={badgeNotif} />
                  </TabPane>
                  <TabPane tabId="3">
                    <BirthdayTab badge={badgeBirthday} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(ProfileTabs));
