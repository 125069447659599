/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/extensions */
/* eslint-disable no-tabs, object-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unused-state,object-shorthand,arrow-parens,comma-dangle,jsx-a11y/anchor-is-valid,no-script-url,react/style-prop-object,no-console,no-trailing-spaces,space-in-parens,space-infix-ops,react/no-unescaped-entities,camelcase,no-plusplus,max-len,quote-props,no-unused-vars */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-underscore-dangle
// eslint-disable-next-line react/sort-comp
/* eslint-disable no-const-assign */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
import React, { PureComponent } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCheckCircle,
  faCheckSquare,
  faSearch,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid';
import '../../../containers/Tables/DataTable/assets/css/style.css';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';
import { Field } from 'redux-form';
import renderSelectField from '../../../shared/components/form/Select';
import moment from 'moment';
import parse from 'html-react-parser';
import showNotifications from '../../../containers/Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import { OSM, Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Fill, Icon, Style, Text } from 'ol/style';
import { Map, View } from 'ol';
import ws from '../../../shared/components/config/config';
import Spinner from 'react-bootstrap/Spinner';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

//Abort
let abortController = new AbortController();
let requestCompleted = true;

export default translate('global')(
  class ViewListTable extends PureComponent {
    static propTypes = {
      primaryKey: PropTypes.string.isRequired,
      atokey: PropTypes.string.isRequired,
      customKey: PropTypes.string.isRequired,
      flagKey: PropTypes.string.isRequired,
      heads: PropTypes.func.isRequired,
      conds: PropTypes.func.isRequired,
      url: PropTypes.string.isRequired,
      approveFunc: PropTypes.func.isRequired,
      rejectFunc: PropTypes.func.isRequired,
      deleteFunc: PropTypes.func.isRequired,
      surveyFunc: PropTypes.func.isRequired,
      updateFunc: PropTypes.func.isRequired,
      detailFunc: PropTypes.func.isRequired,
      actionFunc: PropTypes.func.isRequired,
      getDocumentId: PropTypes.func.isRequired,
      getLink: PropTypes.func.isRequired,
      infoFunc: PropTypes.func.isRequired,
      duplicateFunc: PropTypes.func.isRequired,
      setphaseFunc: PropTypes.func.isRequired,
      viewFunc: PropTypes.func.isRequired,
      addFunc: PropTypes.func.isRequired,
      fileFunc: PropTypes.func.isRequired,
      paymentFunc: PropTypes.func.isRequired,
      componentFunc: PropTypes.func.isRequired,
      processFunc: PropTypes.func.isRequired,
      onRef: PropTypes.func.isRequired,
      addBtn: PropTypes.string.isRequired,
      backBtn: PropTypes.string.isRequired,
      widthTable: PropTypes.string.isRequired,
      wrap: PropTypes.bool.isRequired,
      buttonAction: PropTypes.string.isRequired,
      t: PropTypes.func.isRequired,
      displayStyleHead: PropTypes.string.isRequired,
      textHead: PropTypes.string.isRequired,
      displayTitle: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
      pageSubTitle: PropTypes.string.isRequired,
      component: PropTypes.bool.isRequired,
      arrPeriod: PropTypes.arrayOf.isRequired,
      arrComponent: PropTypes.arrayOf.isRequired,
      arrHeaderKpi: PropTypes.arrayOf.isRequired,
      headerKpi: PropTypes.bool.isRequired,
      onChangeComponent: PropTypes.func.isRequired,
      onChangePeriod: PropTypes.func.isRequired,
      deleteCond: PropTypes.string.isRequired,
      editCond: PropTypes.string.isRequired,
      searching: PropTypes.string.isRequired,
      yearFilter: PropTypes.string.isRequired,
      monthFilter: PropTypes.string.isRequired,
      workflowFilter: PropTypes.string.isRequired,
      essMonitoringFilter: PropTypes.string.isRequired,
      AttandanceManualFilter: PropTypes.string.isRequired,
      ColorTable: PropTypes.string.isRequired,
      PeriodStart: PropTypes.string.isRequired,
      PeriodEnd: PropTypes.string.isRequired,
      EmpFilter: PropTypes.string.isRequired,
    };

    constructor(props) {
      super(props);
      this.idx_start = 0;
      this.idx_end = 0;
      this.timeout = 0;
      this.state = {
        rows: [],
        limit: 10, // default rows
        offset: 0,
        page: 1,
        disable_preview: '',
        disable_next: '',
        modal_show: false,
        search: '',
        last5Year: [],
        activeWorkflow: [],
        arrPeriod: [],
        arrEmployee: [],
        wf: 0,
        Emp: 0,
        total: 0,
        total_pagination: 0,
        current_pagination: 0,
        label_idx_start: 0,
        label_idx_end: 0,
        heads: this.props.heads,
        rowHead: 10,
        displayHead: 'none',
        textHead: '',
        displayTitle: 'none',
        pageTitle: '',
        pageSubTitle: '',
        classNoBtn: '',
        headerBackend: false,
        arrHeaderBackend: [],
        codecomp: [],
        editCond: '',
        deleteCond: '',
        arrHeaderKpi: [],
        dataexcel: [],
        headerexcel: [],
        trigger: false,
        wrap: false,
        modal: false,
        geolocation: [],
        loading: false,
      };
      this.requestCompleted = React.createRef();
      this.abortController = React.createRef();
      this.abortController.current = new AbortController();
      this.requestCompleted.current = true;
      this.reload = this.reload.bind(this);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
      this.changePage = this.changePage.bind(this);
      this.search = this.search.bind(this);
      this.searchYear = this.searchYear.bind(this);
      this.searchMonth = this.searchMonth.bind(this);
      this.searchWorkflow = this.searchWorkflow.bind(this);
      this.searchPeriodStart = this.searchPeriodStart.bind(this);
      this.searchPeriodEnd = this.searchPeriodEnd.bind(this);
      this.searchEmp = this.searchEmp.bind(this);
      this.createButtonPagination = this.createButtonPagination.bind(this);
      this.goto = this.goto.bind(this);
      this.getComponent = this.getComponent.bind(this);
      this.getWorkflow = this.getWorkflow.bind(this);
      this.getPeriod = this.getPeriod.bind(this);
      this.getEmployee = this.getEmployee.bind(this);
      this.monitoringExportExcel = this.monitoringExportExcel.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
      if (props.displayStyleHead !== state.displayHead) {
        return {
          displayHead: props.displayStyleHead,
        };
      }
      if (props.displayTitle !== state.displayTitle) {
        return {
          displayTitle: props.displayTitle,
        };
      }
      if (props.textHead !== state.textHead) {
        return {
          textHead: props.textHead,
        };
      }
      if (props.heads !== state.heads) {
        return {
          heads: props.heads,
        };
      }
      if (props.arrHeaderKpi !== state.arrHeaderKpi) {
        return {
          arrHeaderKpi: props.arrHeaderKpi,
        };
      }
      return null;
    }

    componentWillMount() {
      this.timer = null;
      setTimeout(() => {
        this.next();
      }, 500);
    }

    componentDidMount() {
      /* eslint-disable-next-line react/no-did-mount-set-state */
      const a = [];
      for (
        let year = new Date().getFullYear() - 4;
        year <= new Date().getFullYear();
        year++
      ) {
        a.push({ value: year, label: year });
      }
      this.setState({ last5Year: a });
      this.requestCompleted.current = true;
      this.props.onRef(this);
      this.getComponent();
      if (this.props.workflowFilter) {
        this.getWorkflow();
      }
      if (this.props.EmpFilter) {
        this.getEmployee();
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const geo = this.state.geolocation;
      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [
            new Feature({
              geometry: new Point(fromLonLat(geo)),
            }),
          ],
        }),
        style: (feature) => {
          // Icon style
          const iconStyle = new Style({
            image: new Icon({
              src: '/img/map-marker.svg',
            }),
          });

          // Text style
          const textStyle = new Style({
            text: new Text({
              font: '12px Arial',
              offsetY: 0, // Adjust this value to change the vertical position of the number relative to the icon
              fill: new Fill({ color: '#fff' }),
              padding: [4, 4, 4, 4], // Add some padding to the background
            }),
          });

          // Use an array to combine both styles
          return [iconStyle, textStyle];
        },
      });

      // Create the map
      const map = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          vectorLayer,
        ],
        view: new View({
          center: fromLonLat(geo),
          zoom: 13,
        }),
      });
      map.on('click', (evt) => {
        // open new tab google maps
        window.open(
          `https://www.google.com/maps/search/${Number.parseFloat(
            geo[1],
          ).toFixed(5)},${Number.parseFloat(geo[0]).toFixed(5)}`,
        );
      });
    }
    getComponent() {
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const ws_ip = ls.get('ws_ip');
      const url = `${ws_ip}/paycomponent/getAll/`;
      axios
        .post(url, '', config)
        .then((response) => {
          const res = response.data.data;
          const arr = [];
          res.map((data) => {
            this.state.codecomp.push(data.component);
          });
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    }

    getWorkflow() {
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const ws_ip = ls.get('ws_ip');
      const url = `${ws_ip}/workflow/getActive/`;
      axios
        .post(url, '', config)
        .then((response) => {
          const res = response.data.data;
          let actWfl = [];
          res.map((data) => {
            actWfl.push({
              label: data.workflow,
              value: data.wfl_id,
            });
          });
          this.setState({ activeWorkflow: actWfl });
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    }

    getPeriod() {
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const ws_ip = ls.get('ws_ip');
      const url = `${ws_ip}/payperiod/getAll/`;
      axios
        .post(url, '', config)
        .then((response) => {
          const res = response.data.data;
          res.map((data) => {
            this.state.arrPeriod.push({
              label: data.period_code,
              value: data.pyp_id,
            });
          });
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    }

    getEmployee() {
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const ws_ip = ls.get('ws_ip');
      const url = `${ws_ip}/employee/getAllItemOrg/`;
      axios
        .post(url, '', config)
        .then((response) => {
          const res = response.data.data;
          let employees = [];
          res.map((data) => {
            employees.push({
              label: data.employee,
              label2: data.emp_code,
              value: data.emp_id,
            });
          });
          this.setState({ arrEmployee: employees });
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    }

    paginationNumber() {
      const total_pagination = this.state.total / this.state.limit;
      this.setState({
        total_pagination: Math.ceil(total_pagination),
      });
      const page_start = Number.parseInt(this.state.offset, 10) + 1;
      const page_end =
        Number.parseInt(this.state.limit, 10) +
        Number.parseInt(this.state.offset, 10);
      this.setState({
        label_idx_start: this.state.total ? page_start : 0,
        label_idx_end:
          page_end > this.state.total ? this.state.total : page_end,
      });
    }

    changePage(event) {
      this.setState(
        {
          limit: event.target.value,
        },
        () => {
          setTimeout(() => {
            this.reload();
          }, 50);
        },
      );
    }

    search(event) {
      this.setState({ search: event.target.value });
      this.reload();
    }

    searchYear(target) {
      this.setState({ year: target.value });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reload();
      }, 300);
    }

    searchMonth(target) {
      this.setState({ month: target.value });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reload();
      }, 300);
    }

    searchWorkflow(target) {
      this.setState({ wf: target.value });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reload();
      }, 300);
    }

    searchEmp(target) {
      this.setState({ Emp: target.value });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reload();
      }, 300);
    }

    searchPeriodStart(event) {
      this.setState({ periodstart: event.target.value });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reload();
      }, 300);
    }

    searchPeriodEnd(event) {
      this.setState({ periodend: event.target.value });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reload();
      }, 300);
    }

    previous() {
      const { url, conds, workflowFilter, EmpFilter } = this.props;
      let state_offset = this.state.offset - this.state.limit;
      this.setState({ modal_show: true });
      const nextState = this;
      const rows = [];
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      let bodyParameters;
      if (typeof conds === 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            query: this.state.search,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      } else if (typeof conds !== 'undefined') {
        if (EmpFilter) {
          // console.log('masuk sini1');
          bodyParameters = {
            cond: conds,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            cond: conds,
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      }
      let finalUrl = url;
      if (workflowFilter) {
        finalUrl = `${finalUrl}/${this.state.wf}`;
      }

      if (EmpFilter) {
        finalUrl = `${finalUrl}/${this.state.Emp}`;
      }

      state_offset = state_offset < 1 ? 0 : state_offset;
      axios
        .post(
          `${finalUrl}/${this.state.limit}/${state_offset}`,
          bodyParameters,
          config,
        )
        .then((response) => {
          if (typeof response.data.header === 'undefined') {
            nextState.setState({
              headerBackend: false,
              arrHeaderBackend: [],
            });
          } else {
            nextState.setState({
              headerBackend: true,
              arrHeaderBackend: response.data.header,
            });
          }
          if (response.data.data.length > 0) {
            let no_index = state_offset;
            this.setState({
              rowHead: Object.keys(response.data.data[0]).length + 1,
            });
            for (let i = 0; i < response.data.data.length; i += 1) {
              no_index += 1;
              rows.push({
                no: no_index,
                ...response.data.data[i],
              });
            }
            // simpan state limit offset
            nextState.setState({
              rows: rows,
              total: response.data.total,
              page: this.state.page - 1,
              offset: state_offset,
              modal_show: false,
              disable_preview: state_offset < 1 ? 'disabled' : '',
              disable_next: response.data.total > no_index ? '' : 'disabled',
            });
            this.paginationNumber();
          } else {
            // sembunyikan modal loading data jika data kosong
            this.setState({ modal_show: false });
          }
        })
        .catch(() => {
          // sembunyikan modal loading data
          this.setState({ modal_show: false });
        });
    }

    reload(item) {
      const { url, conds, workflowFilter, EmpFilter } = this.props;
      requestCompleted = false;
      const reloadState = this;
      // reset page on reload
      let state_offset = 0;
      this.setState({ modal_show: true });
      const rows = [];
      let bodyParameters;
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
        signal: abortController.signal,
      };
      if (typeof conds === 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            query: this.state.search,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      } else if (typeof conds !== 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            cond: conds,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            cond: conds,
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      }
      let finalUrl = url;
      if (workflowFilter) {
        finalUrl = `${finalUrl}/${this.state.wf}`;
      }

      if (EmpFilter) {
        finalUrl = `${finalUrl}/${this.state.Emp}`;
      }
      state_offset = state_offset < 1 ? 0 : state_offset;
      axios
        .post(
          `${finalUrl}/${this.state.limit}/${state_offset}`,
          bodyParameters,
          config,
        )
        .then((response) => {
          // prompt('', JSON.stringify(response));
          requestCompleted = true;
          if (typeof response.data.header === 'undefined') {
            reloadState.setState({
              headerBackend: false,
              arrHeaderBackend: [],
            });
          } else {
            reloadState.setState({
              headerBackend: true,
              arrHeaderBackend: response.data.header,
            });
          }
          const numPage = typeof item !== 'undefined' ? this.state.page : 2;
          const nulPage = (response.data.total / state_offset) % 1;
          const numPages = nulPage === 0 ? numPage - 1 : numPage;
          this.setState({ modal_show: false });
          if (response.data.data.length === 0) {
            if (nulPage === 0) {
              axios
                .post(
                  `${finalUrl}/${this.state.limit}/${state_offset -
                    this.state.limit}`,
                  bodyParameters,
                  config,
                )
                .then((res) => {
                  if (res.data.data.length > 0) {
                    this.setState({
                      rowHead: Object.keys(res.data.data[0]).length + 1,
                    });
                    let no_index = state_offset - this.state.limit;
                    for (let i = 0; i < res.data.data.length; i += 1) {
                      no_index += 1;
                      rows.push({
                        no: no_index,
                        ...res.data.data[i],
                      });
                    }
                    reloadState.setState({
                      rows: rows,
                      total: res.data.total,
                      page: numPages,
                      offset: state_offset - this.state.limit,
                      modal_show: false,
                      disable_next:
                        no_index >= res.data.total ? 'disabled' : '',
                      disable_preview:
                        state_offset - this.state.limit < 1 ? 'disabled' : '',
                    });
                    this.paginationNumber();
                  }
                });
            } else {
              reloadState.setState({
                rows: [],
                total: 0,
                disable_next: 'disabled',
                disable_preview: 'disabled',
                page: 1,
                total_pagination: 0,
                current_pagination: 0,
              });
              this.paginationNumber();
            }
          }
          if (response.data.data.length > 0) {
            this.setState({
              rowHead: Object.keys(response.data.data[0]).length + 1,
            });
            let no_index = state_offset;
            for (let i = 0; i < response.data.data.length; i += 1) {
              no_index += 1;
              rows.push({
                no: no_index,
                ...response.data.data[i],
              });
            }
            reloadState.setState({
              rows: rows,
              total: response.data.total,
              page: numPage,
              offset: state_offset,
              modal_show: false,
              disable_next: no_index >= response.data.total ? 'disabled' : '',
              disable_preview: state_offset < 1 ? 'disabled' : '',
            });
            this.paginationNumber();
          }
        })
        .catch(() => {
          this.setState({ modal_show: false });
        });
    }

    iconData(pars) {
      const spanIcon = [];
      if (pars === '1') {
        spanIcon.push([
          <span style={{ color: '#15DD96' }}>
            <FontAwesomeIcon icon={faCheckCircle} /> Yes
          </span>,
        ]);
      }
      if (pars === '0') {
        spanIcon.push([
          <span style={{ color: '#dc3545' }}>
            <FontAwesomeIcon icon={faTimesCircle} /> No
          </span>,
        ]);
      }
      return spanIcon;
    }

    monitoringExportExcel() {
      this.setState({ type: '2', loading: true });
      const { url, conds, workflowFilter } = this.props;
      const { apiws, token } = this.state;
      const state_offset = (this.state.page - 1) * this.state.limit;

      let bodyParameters;
      if (typeof conds === 'undefined') {
        bodyParameters = {
          query: this.state.search,
          year: this.state.year,
          month: this.state.month,
          type: '2',
        };
      } else {
        bodyParameters = {
          cond: conds,
          query: this.state.search,
          year: this.state.year,
          month: this.state.month,
          type: '2',
        };
      }
      let finalUrl = url;
      if (workflowFilter) {
        finalUrl = `${finalUrl}/${this.state.wf}`;
      }
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      axios.post(`${finalUrl}`, bodyParameters, config).then((res) => {
        if (res.data.status === 'ok') {
          const { data, headerexcel } = res.data;
          const array = [];
          data.map((obj, index) =>
            array.push({
              No: index + 1,
              Employee: obj.Employee,
              Position: obj.Position,
              'Request Date': obj['Request Date'],
              Status: obj.Status,
              Detail: obj.Detail,
            }),
          );
          this.setState({
            dataexcel: array,
            headerexcel: headerexcel,
            trigger: !this.state.trigger,
          });
          this.setState({
            displayTable: '',
            displayLoad: 'none',
            displaySave: '',
          });
          setTimeout(() => {
            showNotifications('Success', 'Load Success', 'success');
            this.setState({ type: '2', loading: false });
            this.inputElement.click();
          }, 1000);
        }
      });
    }

    buttonAction(act, flag, index, cases) {
      const {
        buttonAction,
        updateFunc,
        surveyFunc,
        approveFunc,
        rejectFunc,
        deleteFunc,
        detailFunc,
        actionFunc,
        processFunc,
        infoFunc,
        duplicateFunc,
        addFunc,
        componentFunc,
        fileFunc,
        paymentFunc,
        setphaseFunc,
        viewFunc,
      } = this.props;
      let disabled_flag;
      if (
        typeof flag === 'object' ||
        typeof flag === 'undefined' ||
        flag === true
      ) {
        disabled_flag = '';
      } else {
        disabled_flag = 'disabled';
      }

      if (this.props.editCond === '1') {
        this.setState({ editCond: '1' });
      } else if (typeof this.props.editCond === 'undefined') {
        this.setState({ editCond: '1' });
      } else {
        this.setState({ editCond: '' });
      }

      if (this.props.deleteCond === '1') {
        this.setState({ deleteCond: '1' });
      } else if (typeof this.props.deleteCond === 'undefined') {
        this.setState({ deleteCond: '1' });
      } else {
        this.setState({ deleteCond: '' });
      }

      const btnAction = [];
      if (typeof buttonAction === 'object') {
        buttonAction.forEach((element) => {
          if (element === 'survey') {
            btnAction.push([
              <button
                id={`question${index}`}
                type="button"
                onClick={() => surveyFunc(act)}
                className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
                className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
              >
                <i className="fas fa-question" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`question${index}`}>
                Questions
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'edit') {
            btnAction.push([
              <button
                id={`edit${index}`}
                type="button"
                style={{ display: this.state.editCond === '1' ? '' : 'none' }}
                onClick={() => updateFunc(act)}
                className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
                className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
              >
                <i className="fas fa-pencil-alt" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`edit${index}`}>
                Edit
              </UncontrolledTooltip>,
            ]);
          }
          if (cases !== 'Finish') {
            if (element === 'approve') {
              btnAction.push([
                <button
                  id={`approve${index}`}
                  type="button"
                  onClick={() => approveFunc(act)}
                  className="btn btn-sm btn-outline-success btn-data-table"
                >
                  Approve
                </button>,
              ]);
            }
            if (element === 'reject') {
              btnAction.push([
                <button
                  id={`reject${index}`}
                  type="button"
                  onClick={() => rejectFunc(act)}
                  className="btn btn-sm btn-outline-danger btn-data-table"
                >
                  Reject
                </button>,
              ]);
            }
          }
          if (element === 'delete') {
            btnAction.push([
              <button
                id={`delete${index}`}
                type="button"
                style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
                onClick={() => deleteFunc(act)}
                className={`btn btn-sm btn-outline-danger btn-data-table ${disabled_flag}`}
                className={`btn btn-sm btn-outline-danger btn-data-table ${disabled_flag}`}
              >
                <i className="fas fa-trash-alt" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`delete${index}`}>
                Delete
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'detail') {
            btnAction.push([
              <button
                id={`detail${index}`}
                type="button"
                onClick={() => detailFunc(act)}
                className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
              >
                <i className="fas fa-align-justify" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`detail${index}`}>
                Detail
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'process') {
            btnAction.push([
              <button
                id={`process${index}`}
                type="button"
                onClick={() => processFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-play" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`process${index}`}>
                Run Process
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'duplicate') {
            btnAction.push([
              <button
                id={`duplicate${index}`}
                type="button"
                onClick={() => duplicateFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-copy" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`duplicate${index}`}>
                Duplicate
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'component') {
            btnAction.push([
              <button
                id={`component${index}`}
                type="button"
                onClick={() => componentFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-atom" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`component${index}`}>
                Component
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'setphase') {
            btnAction.push([
              <button
                id={`setphase${index}`}
                type="button"
                onClick={() => setphaseFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-calendar-alt" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`setphase${index}`}>
                Set phase
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'view') {
            btnAction.push([
              <button
                id={`view${index}`}
                type="button"
                onClick={() => viewFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-eye" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`view${index}`}>
                View
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'add') {
            btnAction.push([
              <button
                id={`add${index}`}
                type="button"
                onClick={() => addFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-plus" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`add${index}`}>
                Add
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'addkpi') {
            btnAction.push([
              <button
                id={`duplicate${index}`}
                type="button"
                onClick={() => addFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-plus" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`duplicate${index}`}>
                Add KPI
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'addstrategic') {
            btnAction.push([
              <button
                id={`duplicate${index}`}
                type="button"
                onClick={() => addFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-plus" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`duplicate${index}`}>
                Add Strategic Objective
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'file') {
            btnAction.push([
              <button
                id={`file${index}`}
                type="button"
                onClick={() => fileFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-file" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`file${index}`}>
                Document
              </UncontrolledTooltip>,
            ]);
          }
          if (element === 'payment') {
            btnAction.push([
              <button
                id={`payment${index}`}
                type="button"
                onClick={() => paymentFunc(act)}
                className="btn btn-sm btn-outline-primary btn-data-table"
              >
                <i className="fas fa-dollar-sign" />
              </button>,
            ]);
            btnAction.push([
              <UncontrolledTooltip placement="top" target={`payment${index}`}>
                Payment
              </UncontrolledTooltip>,
            ]);
          }
        });
      }
      if (buttonAction === 'detailOnly') {
        btnAction.push([
          <button
            id={`detail${index}`}
            type="button"
            onClick={() => detailFunc(act)}
            className="btn btn-sm btn-outline-primary btn-data-table"
          >
            <i className="fas fa-align-justify" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target={`detail${index}`}>
            Detail
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'buttonAll') {
        btnAction.push([
          <button
            id={`edit${index}`}
            type="button"
            style={{ display: this.state.editCond === '1' ? '' : 'none' }}
            onClick={() => updateFunc(act)}
            className="btn btn-sm btn-outline-primary btn-data-table"
          >
            <i className="fas fa-pencil-alt" />
          </button>,
        ]);
        btnAction.push([
          <button
            id={`delete${index}`}
            type="button"
            style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
            onClick={() => deleteFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i className="fas fa-trash-alt" />
          </button>,
        ]);
        btnAction.push([
          <button
            id={`detail${index}`}
            type="button"
            onClick={() => detailFunc(act)}
            className="btn btn-sm btn-outline-warning btn-data-table"
          >
            <i className="fas fa-align-justify" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target={`edit${index}`}>
            Edit
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target={`delete${index}`}>
            Delete
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target={`detail${index}`}>
            Detail
          </UncontrolledTooltip>,
        ]);
      }
      if (
        buttonAction === 'updateOnly' ||
        buttonAction === '' ||
        typeof buttonAction === 'undefined'
      ) {
        btnAction.push([
          <button
            id="Edit1"
            type="button"
            style={{ display: this.state.editCond === '1' ? '' : 'none' }}
            onClick={() => updateFunc(act)}
            className="btn btn-sm btn-outline-primary btn-data-table"
          >
            <i className="fas fa-pencil-alt" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Edit1">
            Edit
          </UncontrolledTooltip>,
        ]);
      }
      if (
        buttonAction === 'deleteOnly' ||
        buttonAction === '' ||
        typeof buttonAction === 'undefined'
      ) {
        btnAction.push([
          <button
            id="Delete1"
            type="button"
            style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
            onClick={() => deleteFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i className="fas fa-trash-alt" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Delete1">
            Delete
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'deleteAndDetail') {
        btnAction.push([
          <button
            id="Delete2"
            type="button"
            style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
            onClick={() => deleteFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i className="fas fa-trash-alt" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Detail1"
            type="button"
            style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
            onClick={() => detailFunc(act)}
            className="btn btn-sm btn-outline-warning btn-data-table"
          >
            <i className="fas fa-align-justify" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Delete2">
            Delete
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Detail1">
            Detail
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'notifPmk') {
        btnAction.push([
          <button
            type="button"
            onClick={() => actionFunc(act)}
            className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
            id="Action"
          >
            <i className="fas fa-pencil" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Action">
            Update
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'actionAdd') {
        btnAction.push([
          <button
            type="button"
            onClick={() => actionFunc(act)}
            className={`btn btn-sm btn-outline-primary btn-data-table ${disabled_flag}`}
            id="Action"
          >
            <i className="fas fa-plus" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Action">
            Action
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'formatReport') {
        btnAction.push([
          <button
            id="Information"
            type="button"
            onClick={() => infoFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i className="fas fa-info-circle" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Detail1"
            type="button"
            onClick={() => detailFunc(act)}
            className="btn btn-sm btn-outline-warning btn-data-table"
          >
            <i className="fas fa-align-justify" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Edit1"
            type="button"
            style={{ display: this.state.editCond === '1' ? '' : 'none' }}
            onClick={() => updateFunc(act)}
            className="btn btn-sm btn-outline-primary btn-data-table"
          >
            <i className="fas fa-pencil-alt" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Delete2"
            type="button"
            style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
            onClick={() => deleteFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i class="fas fa-trash-alt" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Duplicate"
            type="button"
            onClick={() => duplicateFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i className="fas fa-copy" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Information">
            Information
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Detail1">
            Detail
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Edit1">
            Edit
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Delete2">
            Delete
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Duplicate">
            Duplicate
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'component') {
        btnAction.push([
          <button
            id="comp"
            type="button"
            onClick={() => componentFunc(act)}
            className="btn btn-sm btn-outline-warning btn-data-table"
          >
            <i className="fas fa-atom" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Edit1"
            type="button"
            style={{ display: this.state.editCond === '1' ? '' : 'none' }}
            onClick={() => updateFunc(act)}
            className="btn btn-sm btn-outline-primary btn-data-table"
          >
            <i className="fas fa-pencil-alt" />
          </button>,
        ]);
        btnAction.push([
          <button
            id="Delete2"
            type="button"
            style={{ display: this.state.deleteCond === '1' ? '' : 'none' }}
            onClick={() => deleteFunc(act)}
            className="btn btn-sm btn-outline-danger btn-data-table"
          >
            <i className="fas fa-trash-alt" />
          </button>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="comp">
            Component
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Edit1">
            Edit
          </UncontrolledTooltip>,
        ]);
        btnAction.push([
          <UncontrolledTooltip placement="top" target="Delete2">
            Delete
          </UncontrolledTooltip>,
        ]);
      }
      if (buttonAction === 'noButton') {
        btnAction.push([]);
        this.setState({ classNoBtn: 'table-no-btn' });
      }
      return btnAction;
    }

    next() {
      const { url, conds, workflowFilter, EmpFilter } = this.props;
      const state_offset = (this.state.page - 1) * this.state.limit;
      this.setState({ modal_show: true });
      const nextState = this;
      const rows = [];
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      let bodyParameters;
      if (typeof conds === 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            query: this.state.search,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      } else if (typeof conds !== 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            cond: conds,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            cond: conds,
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      }
      let finalUrl = url;
      if (workflowFilter) {
        finalUrl = `${finalUrl}/${this.state.wf}`;
      }

      if (EmpFilter) {
        finalUrl = `${finalUrl}/${this.state.Emp}`;
      }
      axios
        .post(
          `${finalUrl}/${this.state.limit}/${state_offset}`,
          bodyParameters,
          config,
        )
        .then((response) => {
          if (typeof response.data.header === 'undefined') {
            nextState.setState({
              headerBackend: false,
              arrHeaderBackend: [],
            });
          } else {
            nextState.setState({
              headerBackend: true,
              arrHeaderBackend: response.data.header,
            });
          }
          if (response.data.data.length > 0) {
            this.setState({
              rowHead: Object.keys(response.data.data[0]).length + 1,
            });
            let no_index = state_offset;
            for (let i = 0; i < response.data.data.length; i += 1) {
              no_index += 1;
              rows.push({
                no: no_index,
                ...response.data.data[i],
              });
            }
            nextState.setState({
              rows: rows,
              total: response.data.total,
              page: this.state.page + 1,
              offset: state_offset,
              modal_show: false,
              disable_preview: state_offset < 1 ? 'disabled' : '',
              disable_next: no_index >= response.data.total ? 'disabled' : '',
            });

            this.paginationNumber();
          }
        })
        .catch(() => {
          this.setState({ modal_show: false });
        });
    }

    goto(e) {
      e.preventDefault();
      const { url, conds, workflowFilter, EmpFilter } = this.props;
      const page = parseInt(e.target.innerHTML, 10);
      const state_offset = (page - 1) * this.state.limit;
      this.setState({ modal_show: true });
      const nextState = this;
      const rows = [];
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      let bodyParameters;

      if (typeof conds === 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            query: this.state.search,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      } else if (typeof conds !== 'undefined') {
        if (EmpFilter) {
          bodyParameters = {
            cond: conds,
            emp: this.state.Emp,
            periodstart: this.state.periodstart,
            periodend: this.state.periodend,
          };
        } else {
          bodyParameters = {
            cond: conds,
            query: this.state.search,
            year: this.state.year,
            month: this.state.month,
          };
        }
      }

      let finalUrl = url;
      if (workflowFilter) {
        finalUrl = `${finalUrl}/${this.state.wf}`;
      }

      if (EmpFilter) {
        finalUrl = `${finalUrl}/${this.state.Emp}`;
      }
      axios
        .post(
          `${finalUrl}/${this.state.limit}/${state_offset}`,
          bodyParameters,
          config,
        )
        .then((response) => {
          if (typeof response.data.header === 'undefined') {
            nextState.setState({
              headerBackend: false,
              arrHeaderBackend: [],
            });
          } else {
            nextState.setState({
              headerBackend: true,
              arrHeaderBackend: response.data.header,
            });
          }
          if (response.data.data.length > 0) {
            let no_index = state_offset;
            this.setState({
              rowHead: Object.keys(response.data.data[0]).length + 1,
            });
            for (let i = 0; i < response.data.data.length; i += 1) {
              no_index += 1;
              rows.push({
                no: no_index,
                ...response.data.data[i],
              });
            }
            nextState.setState({
              rows,
              total: response.data.total,
              page: page + 1,
              offset: state_offset,
              modal_show: false,
              disable_preview: state_offset < 1 ? 'disabled' : '',
              disable_next: no_index >= response.data.total ? 'disabled' : '',
            });

            this.paginationNumber();
          }
        })
        .catch(() => {
          this.setState({ modal_show: false });
        });
    }

    createButtonPagination() {
      const total_button = 5;
      const child_pagination = [];
      const state_page = this.state.page - 1;
      // set minimum index
      if (
        this.state.current_pagination < 1 ||
        state_page >= this.state.current_pagination
      ) {
        this.setState({
          current_pagination: state_page + total_button,
        });
      }
      // set maximum index
      if (state_page < this.state.current_pagination - total_button) {
        this.setState({
          current_pagination: this.state.current_pagination - total_button,
        });
      }
      for (let i = 1; i <= this.state.total_pagination; i++) {
        let selected = '';
        if (state_page === i) {
          selected = 'active';
        }
        if (
          i >= this.state.current_pagination - total_button - 1 &&
          i <= this.state.current_pagination
        ) {
          child_pagination.push(
            <li class={`page-item ${selected}`}>
              <a onClick={this.goto} class="page-link" href="#">
                {i}
              </a>
            </li>,
          );
        }
      }
      return child_pagination;
    }

    render() {
      const {
        primaryKey,
        atokey,
        addBtn,
        t,
        widthTable,
        customKey,
        backBtn,
        flagKey,
        getDocumentId,
        getLink,
        pageTitle,
        pageSubTitle,
        arrHeaderKpi,
        wrap,
      } = this.props;
      const toggle = () => this.setState({ modal: !this.state.modal });
      const customString = { ppr_id: 'Strategic Objective' };
      return (
        <div className="container-fluid">
          <div className="wrapper">
            {(typeof pageTitle !== 'undefined' ||
              typeof pageSubTitle !== 'undefined') && (
              <div className="row" style={{ display: this.state.displayTitle }}>
                <div className="col-md-6">
                  <h3 className="page-title"> {this.props.pageTitle} </h3>
                  <h3 className="page-subhead subhead">
                    {' '}
                    {this.props.pageSubTitle}{' '}
                  </h3>
                </div>
              </div>
            )}
            {this.props.essMonitoringFilter && (
              <div className="row workflow-filter-header">
                {this.props.workflowFilter && (
                  <div className="col-md-3">
                    <Field
                      name="wf"
                      component={renderSelectField}
                      options={this.state.activeWorkflow}
                      onChange={this.searchWorkflow}
                      placeholder="Workflow"
                    />
                  </div>
                )}
                {this.props.yearFilter && (
                  <div className="col-md-3">
                    <Field
                      name="tahun"
                      component={renderSelectField}
                      options={this.state.last5Year}
                      onChange={this.searchYear}
                      placeholder="Year"
                    />
                  </div>
                )}
                {this.props.monthFilter && (
                  <div className="col-md-3">
                    <Field
                      name="bulan"
                      component={renderSelectField}
                      options={[
                        { value: '01', label: 'January' },
                        { value: '02', label: 'February' },
                        { value: '03', label: 'March' },
                        { value: '04', label: 'April' },
                        { value: '05', label: 'May' },
                        { value: '06', label: 'June' },
                        { value: '07', label: 'July' },
                        { value: '08', label: 'August' },
                        { value: '09', label: 'September' },
                        { value: '10', label: 'October' },
                        { value: '11', label: 'November' },
                        { value: '12', label: 'December' },
                      ]}
                      onChange={this.searchMonth}
                      placeholder="Month"
                    />
                  </div>
                )}
                <div className="col-md-3">
                  {this.props.workflowFilter && (
                    <Button
                      className="btn-block btn-filter-custom"
                      type="button"
                      color="primary"
                      onClick={() => this.monitoringExportExcel()}
                      style={{ display: this.state.displayButton }}
                    >
                      {this.state.loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '6px' }}
                        />
                      ) : (
                        t('LBL.EXPORT_TO_EXCEL')
                      )}
                    </Button>
                  )}
                </div>
              </div>
            )}
            {/* INDRA */}
            {this.props.AttandanceManualFilter && (
              <div className="row workflow-filter-header">
                {this.props.EmpFilter && (
                  <div className="col-md-3">
                    <Field
                      name="emp"
                      component={renderSelectField}
                      options={this.state.arrEmployee}
                      onChange={this.searchEmp}
                      placeholder="Employee"
                    />
                  </div>
                )}
                <span className="form__form-group-label">
                  {t('LBL.PERIOD')}
                </span>
                {this.props.PeriodStart && (
                  <div className="col-md-3">
                    <input
                      value={this.state.periodstart}
                      onChange={this.searchPeriodStart}
                      type="date"
                      className="form-control"
                      placeholder="Period Start"
                      style={{ 'font-size': '0.8rem !important' }}
                    />
                  </div>
                )}
                <span className="form__form-group-label">-</span>
                {this.props.PeriodEnd && (
                  <div className="col-md-3">
                    <input
                      value={this.state.periodend}
                      onChange={this.searchPeriodEnd}
                      type="date"
                      className="form-control"
                      placeholder="Period End"
                      style={{ fontSize: '0.8rem !important' }}
                    />
                  </div>
                )}
              </div>
            )}
            {this.state.type === '2' && (
              <ExcelFile
                element={
                  <div role="button" tabIndex="0">
                    <input
                      type="hidden"
                      ref={(ref) => {
                        this.inputElement = ref;
                      }}
                    />
                  </div>
                }
                filename="Employee self service Monitoring Request"
              >
                <ExcelSheet data={this.state.dataexcel} name="Monitoring">
                  {this.state.headerexcel.map((obj) => (
                    <ExcelColumn label={obj} value={obj} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            )}
            {this.props.component === true ? (
              <div className="row">
                <div className="col-md-2">
                  <Field
                    name="period"
                    component={renderSelectField}
                    options={this.props.arrPeriod}
                    placeholder={t('LBL.PERIOD')}
                    onChange={this.props.onChangePeriod}
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    name="component"
                    component={renderSelectField}
                    options={this.props.arrComponent}
                    placeholder={t('LBL.COMPONENT')}
                    onChange={this.props.onChangeComponent}
                  />
                </div>
                <div className="col-md-3">
                  <div className="input-group mb-3">
                    <input
                      value={this.state.search}
                      onChange={this.search}
                      type="text"
                      className="form-control"
                      placeholder={t('LBL.SEARCHING_BY_KEYWORDS')}
                      style={{ fontSize: '0.8rem !important' }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-3">
                  <div className="input-group mb-3">
                    <input
                      style={{ display: this.props.searching }}
                      value={this.state.search}
                      onChange={this.search}
                      type="text"
                      className="form-control"
                      placeholder={t('LBL.SEARCHING_BY_KEYWORDS')}
                    />
                    <div className="input-group-append">
                      <span
                        style={{ display: this.props.searching }}
                        className="input-group-text"
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  {addBtn}
                  {backBtn}
                </div>
              </div>
            )}
            {this.props.ColorTable === true ? (
              <div className="table-responsive">
                <table
                  id="myTable"
                  className={`table data-grid1-tbl responsive ${
                    wrap ? 'wrap' : 'no-wrap'
                  } ${this.state.classNoBtn}`}
                >
                  <thead style={{ display: this.state.displayHead }}>
                    <th colSpan={this.state.rowHead}>{this.state.textHead}</th>
                  </thead>
                  <thead>
                    {this.state.headerBackend === false && !this.props.headerKpi
                      ? this.props.heads.map((data) => {
                          let x;
                          if (typeof data === 'object') {
                            x = Object.entries(data).map(([id, value]) => {
                              return (
                                <th style={{ width: `${widthTable[id]}` }}>
                                  {value}
                                </th>
                              );
                            });
                          } else {
                            x = <th>{data}</th>;
                          }
                          return x;
                        })
                      : this.state.arrHeaderBackend.map((data) => {
                          return <th>{data}</th>;
                        })}
                    {this.props.headerKpi && (
                      <>
                        <tr>
                          <th rowSpan="2">{arrHeaderKpi[0]}</th>
                          <th rowSpan="2">{arrHeaderKpi[1]}</th>
                          <th rowSpan="2">{arrHeaderKpi[2]}</th>
                          <th rowSpan="2">{arrHeaderKpi[3]}</th>
                          <th rowSpan="2">{arrHeaderKpi[4]}</th>
                          <th colSpan="3" style={{ textAlign: 'center' }}>
                            {arrHeaderKpi[5]}
                          </th>
                          <th rowSpan="2">{arrHeaderKpi[9]}</th>
                          <th rowSpan="2">{arrHeaderKpi[10]}</th>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: 'bold', color: '#646777' }}>
                            {arrHeaderKpi[6]}
                          </td>
                          <td style={{ fontWeight: 'bold', color: '#646777' }}>
                            {arrHeaderKpi[7]}
                          </td>
                          <td style={{ fontWeight: 'bold', color: '#646777' }}>
                            {arrHeaderKpi[8]}
                          </td>
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody>
                    {this.state.rows.map((data) => (
                      <tr>
                        {Object.entries(data).map(([name, value]) => {
                          if (name !== 'atr_id' && name !== 'ato_color') {
                            if (data.color !== 'null' || data.color !== '') {
                              return (
                                <td
                                  key={name}
                                  style={{ background: `${data.ato_color}` }}
                                >
                                  {value}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={name}
                                  style={{ background: '#ffffff' }}
                                >
                                  {value}
                                </td>
                              );
                            }
                          }

                          if (name === 'ato_color') {
                            return '';
                          }

                          if (name === primaryKey) {
                            return '';
                          }

                          if (name.toLowerCase().includes('date')) {
                            if (
                              value === 'null' ||
                              value === null ||
                              value === ''
                            ) {
                              return <td />;
                            } else {
                              return (
                                <td style={{ maxWidth: '100px' }}>
                                  {moment(value).format('DD/MM/YYYY')}
                                </td>
                              );
                            }
                          }
                          return <td key={name}>{value}</td>;
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  id="myTable"
                  className={`table data-grid-tbl responsive ${
                    wrap ? 'wrap' : 'no-wrap'
                  } ${this.state.classNoBtn}`}
                >
                  <thead style={{ display: this.state.displayHead }}>
                    <th colSpan={this.state.rowHead}>{this.state.textHead}</th>
                  </thead>
                  <thead>
                    {this.state.headerBackend === false && !this.props.headerKpi
                      ? this.props.heads.map((data) => {
                          let x;
                          if (typeof data === 'object') {
                            x = Object.entries(data).map(([id, value]) => {
                              return (
                                <th style={{ width: `${widthTable[id]}` }}>
                                  {value}
                                </th>
                              );
                            });
                          } else {
                            x = <th>{data}</th>;
                          }
                          return x;
                        })
                      : this.state.arrHeaderBackend.map((data) => {
                          return <th>{data}</th>;
                        })}
                    {this.props.headerKpi && (
                      <>
                        <tr>
                          <th rowSpan="2">{arrHeaderKpi[0]}</th>
                          <th rowSpan="2">{arrHeaderKpi[1]}</th>
                          <th rowSpan="2">{arrHeaderKpi[2]}</th>
                          <th rowSpan="2">{arrHeaderKpi[3]}</th>
                          <th rowSpan="2">{arrHeaderKpi[4]}</th>
                          <th colSpan="3" style={{ textAlign: 'center' }}>
                            {arrHeaderKpi[5]}
                          </th>
                          <th rowSpan="2">{arrHeaderKpi[9]}</th>
                          <th rowSpan="2">{arrHeaderKpi[10]}</th>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: 'bold', color: '#646777' }}>
                            {arrHeaderKpi[6]}
                          </td>
                          <td style={{ fontWeight: 'bold', color: '#646777' }}>
                            {arrHeaderKpi[7]}
                          </td>
                          <td style={{ fontWeight: 'bold', color: '#646777' }}>
                            {arrHeaderKpi[8]}
                          </td>
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody>
                    {this.state.rows.map((data) => (
                      <tr>
                        {Object.entries(data).map(([name, value]) => {
                          let styles = reactCSS({
                            default: {
                              color: {
                                width: '36px',
                                height: '14px',
                                borderRadius: '2px',
                                background: `${data.color}`,
                              },
                              swatch: {
                                padding: '5px',
                                background: '#fff',
                                borderRadius: '1px',
                                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                display: 'inline-block',
                                cursor: 'pointer',
                                marginTop: '5px',
                              },
                              popover: { position: 'absolute', zIndex: '2' },
                              cover: {
                                position: 'fixed',
                                top: '0px',
                                right: '0px',
                                bottom: '0px',
                                left: '0px',
                              },
                            },
                          });
                          if (name === primaryKey) {
                            return '';
                          }
                          if (name === customKey) {
                            return (
                              <td key={name}>
                                {this.iconData(data[`${customKey}`])}
                              </td>
                            );
                          }
                          if (name === flagKey) {
                            return '';
                          }
                          if (name === 'case_status') {
                            return '';
                          }
                          if (name.includes('color')) {
                            return (
                              <div style={styles.swatch}>
                                <div style={styles.color} />
                              </div>
                            );
                          }
                          if (name.includes('thr') || name.includes('mass')) {
                            if (value === '1') {
                              return (
                                <td>
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </td>
                              );
                            } else if (value === '0') {
                              return <td key={name}>{}</td>;
                            }
                          }
                          if (name.toLowerCase().includes('jumlah')) {
                            return (
                              <td style={{ textAlign: 'right' }}>{value}</td>
                            );
                          }
                          if (name.toLowerCase().includes('date')) {
                            if (
                              value === 'null' ||
                              value === null ||
                              value === ''
                            ) {
                              return <td />;
                            } else {
                              return (
                                <td style={{ width: '100px' }}>
                                  {moment(value).format('DD/MM/YYYY')}
                                </td>
                              );
                            }
                          }
                          if (name.toLowerCase().includes('reason')) {
                            return (
                              <td style={{ maxWidth: '300px' }} key={name}>
                                {value}
                              </td>
                            );
                          }
                          if (name.toLowerCase().includes('ctime')) {
                            if (
                              value === 'null' ||
                              value === null ||
                              value === ''
                            ) {
                              return <td />;
                            } else {
                              return (
                                <td style={{ width: '100px' }}>
                                  {moment(value).format('DD/MM/YYYY HH:mm')}
                                </td>
                              );
                            }
                          }
                          if (name.toLowerCase().includes('salary')) {
                            return (
                              <td style={{ textAlign: 'right' }}>{value}</td>
                            );
                          }
                          if (name.toLowerCase() === 'backpay') {
                            return (
                              <td style={{ textAlign: 'right' }}>{value}</td>
                            );
                          }
                          if (name.toLowerCase().includes('total')) {
                            return (
                              <td style={{ textAlign: 'right' }}>{value}</td>
                            );
                          }
                          if (this.state.codecomp.indexOf(name) !== -1) {
                            return (
                              <td style={{ textAlign: 'right' }}>{value}</td>
                            );
                          }
                          if (name.includes('file')) {
                            if (value !== null) {
                              return (
                                <td style={{ width: '15%' }}>
                                  <Link
                                    to="#"
                                    onClick={() => getDocumentId(data.dct_id)}
                                  >
                                    {' '}
                                    <FontAwesomeIcon icon={faBook} /> Download
                                    File
                                  </Link>
                                </td>
                              );
                            } else if (value === null) {
                              return <td>{}</td>;
                            }
                          }
                          if (name.includes('link')) {
                            if (value !== null) {
                              return (
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      getLink(data[`${primaryKey}`])
                                    }
                                  >
                                    {' '}
                                    {data.link} {customString[`${primaryKey}`]}
                                  </Link>
                                </td>
                              );
                            } else {
                              return <td>{}</td>;
                            }
                          }
                          if (name.includes('html')) {
                            if (value !== null) {
                              return <td key={name}>{parse(value)}</td>;
                            } else {
                              return <td>{}</td>;
                            }
                          }
                          if (name.includes('modal')) {
                            // center button
                            if (name === 'activity_proof_modal') {
                              return (
                                <td>
                                  {value.map((val, index) => {
                                    return (
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: 'unset',
                                          border: 'none',
                                          color: 'blue',
                                          padding: 0,
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            activity_proof_modal: val['path'],
                                            geolocation: [0, 0],
                                          });
                                          toggle();
                                        }}
                                      >
                                        {index < value.length - 1
                                          ? `Photo ${index + 1},`
                                          : `Photo ${index + 1}`}
                                      </button>
                                    );
                                  })}
                                </td>
                              );
                            }
                            return (
                              <td className={'d-flex justify-content-center'}>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-primary btn-data-table align-self-center"
                                  data-toggle="modal"
                                  data-target="activity_geolocation_modal"
                                  onClick={() => {
                                    this.setState({
                                      geolocation: value,
                                      activity_proof_modal: false,
                                    });
                                    toggle();
                                  }}
                                >
                                  <i className="fas fa-map marker" />
                                </button>
                              </td>
                            );
                          }
                          return <td key={name}>{value}</td>;
                        })}
                        <td>
                          {this.buttonAction(
                            data[`${primaryKey}`],
                            data[`${flagKey}`],
                            data.no,
                            data.case_status,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="paging-panel-bottom">
                  <div className="row">
                    <div className="col-md-5">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li
                            className={`page-item ${this.state.disable_preview}`}
                          >
                            <a
                              onClick={this.previous}
                              className="disabled page-link"
                              href="#"
                              tabIndex="-1"
                              aria-disabled="true"
                            >
                              {' '}
                              {t('FRM.PREVIOUS')}{' '}
                            </a>
                          </li>
                          {this.createButtonPagination()}
                          <li
                            className={`page-item ${this.state.disable_next}`}
                          >
                            <a
                              onClick={this.next}
                              className="page-link"
                              href="#"
                            >
                              {t('FRM.NEXT')}
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="col-md-7 ">
                      <form className="form-inline float-right">
                        <div className="form-group mb-2">
                          <label className="mr-3">{t('FRM.PER_PAGE')}</label>
                          <select
                            onChange={this.changePage}
                            className="form-control"
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                          </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                          <label>
                            {t('FRM.SHOWING')} {this.state.label_idx_start}{' '}
                            {t('FRM.TO')} {this.state.label_idx_end}{' '}
                            {t('FRM.OF')} {this.state.total}
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={toggle}
              className="modal-dialog modal-input"
              style={{
                maxWidth: '700px',
                marginTop: '95px',
                textAlign: 'center',
              }}
            >
              <ModalHeader toggle={toggle}>
                {this.state.activity_proof_modal
                  ? t('LBL.ACTIVITYPROOF')
                  : t('LBL.LOCATION')}
              </ModalHeader>
              <ModalBody>
                {this.state.activity_proof_modal ? (
                  <img
                    src={`${ws.ws.ip}/${this.state.activity_proof_modal}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <div id="map" className="modalmap-container" />
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  {t('FRM.CANCEL')}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
    }
  },
);
