/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, CardBody, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderTextInput from '../../../../../shared/components/form/TextInput';
// import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../notificationMessages';

let unitOrgExist = [];
const idOrgExist = [];
class OfficeSettingForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    editMode: PropTypes.func.isRequired,
    editModal: PropTypes.func.isRequired,
    slcUnit: PropTypes.func.isRequired,
    orglvl: PropTypes.func.isRequired,
    editUnitOrg: PropTypes.func.isRequired,
    addcond: PropTypes.string.isRequired,
    getTableData: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      button_disabled: false,
      orgparentslc: [],
      levelslc: [],
      // eslint-disable-next-line react/no-unused-state
      unitOrgExist: [],
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.editUnitOrg !== '') {
  //     const idxUnitOrg = unitOrgExist.indexOf(props.editUnitOrg);
  //     if (idxUnitOrg !== -1) {
  //       unitOrgExist.splice(idxUnitOrg, 1);
  //     }
  //     return '';
  //   }
  //   if (props.editMode !== state.modal) {
  //     return {
  //       modal: props.editMode,
  //     };
  //   }
  //   if (props.orglvl !== state.levelslc) {
  //     return {
  //       levelslc: props.orglvl,
  //       orgparentslc: props.slcUnit,
  //     };
  //   }
  //   console.log(props.slcUnit.label);
  //   // if (props.slcUnit !== state.orgparentslc) {
  //   //   return {
  //   //     orgparentslc: props.slcUnit,
  //   //   };
  //   // }
  //   return null;
  // }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => { this.getUnitOrganization(); });
  }


  componentWillReceiveProps(props) {
    if (props.editUnitOrg !== '') {
      const idxUnitOrg = unitOrgExist.indexOf(props.editUnitOrg);
      if (idxUnitOrg !== -1) {
        unitOrgExist.splice(idxUnitOrg, 1);
      }
    }
    if (props.editMode !== this.state.modal) {
      this.setState({ modal: props.editMode });
    }
    if (props.orglvl !== this.state.levelslc) {
      this.setState({ levelslc: props.orglvl, orgparentslc: props.slcUnit });
    }
    this.getUnitOrganization();
  }

  componentDidUpdate = () => {
    const { editMode, slcUnit, orglvl } = this.props;
    this.setState({ modal: editMode });
    this.setState({ levelslc: orglvl });
    this.setState({ orgparentslc: slcUnit });
  }

  getUnitOrganization() {
    const { token, apiws } = this.state;
    unitOrgExist = [];
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        for (let i = 0; i < dataResponse.length; i += 1) {
          unitOrgExist[i] = dataResponse[i].unit_organization;
          idOrgExist[i] = dataResponse[i].org_id;
        }
        this.setState(unitOrgExist);
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  toggle() {
    this.props.editModal();
  }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.org_id;
    const error = true;

    let url;
    const olv = (typeof values.olv_id === 'object') ? values.olv_id.value : values.olv_id;
    const org_parent = (typeof values.org_id_parent === 'object') ? values.org_id_parent.value : values.org_id_parent;
    // const start = (typeof values.start_date === 'undefined' || values.start_date === '' || values.start_date === null || values.start_date === 'null') ? '' : values.start_date.format('YYYY-MM-DD');
    // const end = (typeof values.end_date === 'undefined' || values.end_date === '' || values.end_date === null || values.end_date === 'null') ? '' : values.end_date.format('YYYY-MM-DD');

    if (id === undefined || id === '') {
      url = `${apiws}/orgorganization/saveItem`;
    } else {
      url = `${apiws}/orgorganization/updateItem/${id}`;
    }
    const dataBody = {
      ...values, org_id_parent: org_parent, cuser_id: userid, olv_id: olv,
    };
    const formBody = Object.keys(dataBody).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`).join('&');
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          unitOrgExist = [];
          axios.post(
            `${apiws}/orgorganization/getAll/`,
            '',
            config,
          ).then((res) => {
            if (res.data.status === 'ok') {
              const datares = res.data.data;
              for (let i = 0; i < datares.length; i += 1) {
                unitOrgExist[i] = datares[i].unit_organization;
              }
            }
          });
          this.props.getTableData();
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  render() {
    const {
      button_disabled, levelslc, orgparentslc, modal,
    } = this.state;
    const { handleSubmit, pristine, t } = this.props;
    if (modal) {
      return (
        <Card>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog modal-input"
            style={{ marginTop: '100px' }}
          >

            <ModalHeader toggle={this.toggle}>
              {t('FRM.EDIT')} {t('LBL.UNIT_ORGANIZATION')}
            </ModalHeader>
            <ModalBody>
              <form id="addjobname" className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.LEVEL')}</span>
                  <div className="form__form-group-field">
                    <input type="hidden" value="" name="org_id" />
                    <Field
                      name="olv_id"
                      component={renderSelectField}
                      type="text"
                      options={levelslc}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.PARENT_ORGANIZATION_UNIT')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="org_id_parent"
                      component={renderSelectField}
                      type="text"
                      options={orgparentslc}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.UNIT_ORGANIZATION_NAME')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="unit_organization"
                      component={renderTextInput}
                      type="text"
                      maxLength="80"
                    />
                  </div>
                </div>
                <div className="form__form-group" style={{ display: 'none' }}>
                  <span className="form__form-group-label">{t('LBL.SORTING_NUMBER')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="org_order"
                      component={renderTextInput}
                      type="number"
                      maxLength="3"
                    />
                  </div>
                </div>
                {/* <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.START_DATE')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="start_date"
                      component={renderDatePickerField}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.END_DATE')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="end_date"
                      component={renderDatePickerField}
                    />
                  </div>
                </div> */}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
              <Button
                color="primary"
                type="submit"
                form="addjobname"
                disabled={pristine}
              // onClick={this.toggle}
              > {t('FRM.SAVE')}
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </Card>
      );
    }

    return (
      <Card>
        <CardBody>
          <form id="addjobname" className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.LEVEL')}</span>
              <div className="form__form-group-field">
                <input type="hidden" value="" name="org_id" />
                <Field
                  name="olv_id"
                  component={renderSelectField}
                  type="text"
                  options={levelslc}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.PARENT_ORGANIZATION_UNIT')}</span>
              <div className="form__form-group-field">
                <Field
                  name="org_id_parent"
                  component={renderSelectField}
                  type="text"
                  options={orgparentslc}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION_NAME')}</span>
              <div className="form__form-group-field">
                <Field
                  name="unit_organization"
                  component={renderTextInput}
                  type="text"
                />
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.SORTING_NUMBER')}</span>
              <div className="form__form-group-field">
                <Field
                  name="org_order"
                  component={renderTextInput}
                  type="number"
                />
              </div>
            </div> */}
            {/* <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.START_DATE')}</span>
              <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component={renderDatePickerField}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.END_DATE')}</span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component={renderDatePickerField}
                />
              </div>
            </div> */}
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
                disabled={pristine || button_disabled}
                style={{ display: this.props.addcond === '1' ? '' : 'none' }}
              >
                {t('FRM.SAVE')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    );
  }
}

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// async function submit(values) {
//   // const { token, apiws, id } = this.props;
//   await sleep(500);
//   const config = {
//     headers: {
//       'Authorization': `bearer ${ls.get('token')}`,
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//   };
//   let url;
//   const apiws = ls.get('ws_ip');
//   const id = values.org_id;
//   const userid = ls.get('user_cred').usr_id;


//   let mappingData = {};
//   const org_id_parent = (typeof values.org_id_parent === 'object') ? values.org_id_parent.value :
// values.org_id_parent;

//   let modify = false;
//   if (id === undefined || id === '') {
//     url = `${apiws}/orgorganization/saveItem`;
//     mappingData = ({
//       'org_id_parent': org_id_parent,
//       'unit_organization': values.unit_organization,
//       'org_order': values.org_order,
//       'cuser_id': userid,
//     });
//   } else {
//     url = `${apiws}/orgorganization/updateItem/${id}`;
//     mappingData = ({
//       'org_id_parent': org_id_parent,
//       'unit_organization': values.unit_organization,
//       'org_order': values.org_order,
//       'muser_id': userid,
//     });
//     modify = true;
//   }

//   const formBody = Object.keys(mappingData).map(key =>
//     `${encodeURIComponent(key)}=${encodeURIComponent(mappingData[key])}`).join('&');
//   axios.post(
//     url,
//     formBody,
//     config,
//   ).then((response) => {
//     if (response.data.status === 'ok') {
//       showNotifications('Success', 'Save Success', 'success');
//       if (modify) {
//         unitOrgExist = [];
//         axios.post(
//           `${apiws}/orgorganization/getAll/`,
//           '',
//           config,
//         ).then((res) => {
//           if (res.data.status === 'ok') {
//             const datares = res.data.data;
//             for (let i = 0; i < datares.length; i += 1) {
//               unitOrgExist[i] = datares[i].unit_organization;
//             }
//           }
//         });
//       }
//     } else {
//       showNotifications('Fail', 'Save Failed', 'danger');
//     }
//   }).catch(() => {
//     // console.log(error);
//   });
// }

const validate = (values) => {
  console.log(values);
  const errors = {};
  const errorUnitOrg = unitOrgExist;
  // if (!values.olv_id) {
  //   errors.olv_id = 'Unit Organization Levelfield shouldn’t be empty';
  // }
  if (!values.org_id_parent) {
    errors.org_id_parent = 'Parent Unit Organization field shouldn’t be empty';
  }

  if (!values.unit_organization) {
    errors.unit_organization = 'Unit Organization Name field shouldn’t be empty';
  }

  // if (!values.org_order) {
  // errors.org_order = 'Sorting Unit Organization Name field shouldn’t be empty';
  // }

  // if (!values.start_date || values.start_date === '') {
  //   errors.start_date = 'Start Date field shouldn’t be empty';
  // }

  // if (values.end_date !== null) {
  //   if (typeof values.start_date === 'object' && values.start_date.isAfter(values.end_date)) {
  //     errors.end_date = 'End date shouldn’t less than Start date';
  //   }
  // }

  const indexExist = errorUnitOrg.indexOf(values.unit_organization);
  if (indexExist !== -1 && values.org_id !== idOrgExist[indexExist]) {
    errors.unit_organization = 'Unit organization name is already taken';
  }

  return errors;
};

export default reduxForm({
  form: 'unitOrg', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
})(translate('global')(OfficeSettingForm));
