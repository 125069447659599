export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY =
  'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export const SET_MENU_TREE = 'SET_MENU_TREE';
export const CLEAR_SIDEBAR = 'CLEAR_SIDEBAR';

export function changeSidebarVisibility() {
  return {
    type: CHANGE_SIDEBAR_VISIBILITY,
  };
}

export function clearUserMenu() {
  return {
    type: CLEAR_SIDEBAR,
  };
}

export function changeMobileSidebarVisibility() {
  return {
    type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  };
}
