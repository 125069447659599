/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import SearchWebIcon from 'mdi-react/SearchWebIcon';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderCheckboxField from '../../../shared/components/form/CheckBox';
import renderTextInput from '../../../shared/components/form/TextInput';
import Select from 'react-select';
import '../../../../node_modules/react-select2-wrapper/css/select2.css';
import ws from '../../../shared/components/config/config';
import ls from 'local-storage';
import Swal from 'sweetalert2';
import Select2 from 'react-select2-wrapper';

const stdStyle = {
  width: '400px',
};
const selectStyle = {
  width: '300px',
};

class AddFormMenu extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      hideButton: false,
      selectOptions: [],
      selectValue: 0,
    };
  }

  componentDidMount() {
    fetch(`${ws.ws.ip}/menu/getSearchItem`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'search=',
    }).then(res => res.json()).then(
      (result) => {
        const MenuArray = [];
        for (let i = 0; i < result.data.length; i += 1) {
          MenuArray.push({ text: result.data[i].menu, id: result.data[i].men_id });
        }
        this.setState({
          selectOptions: MenuArray,
        });
      },
      () => {
        // console.log(error);
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      // eslint-disable-next-line react/prop-types
      hideButton: nextProps.invalid,
    });
  }

  selectValue = (option) => {
    const selectId = option.target.value;
    this.setState({
      selectValue: selectId,
    });
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  mySubmitHandler = (values) => {
    const { reset } = this.props;
    /* upload using file */
    const data = new FormData();
    const ouser = ls.get('user_cred');
    // eslint-disable-next-line no-prototype-builtins
    if ('file' in values) {
      data.append('file', values.file.file);
    }
    data.append('is_favorite', values.isFavorite);
    data.append('is_show', values.isShow);
    data.append('lbl_code', values.lbl_code);
    data.append('menu', values.menu);
    data.append('ref_id', this.state.selectValue);
    data.append('url', values.url);
    data.append('weight', values.weight);
    data.append('cuser_id', ouser.usr_id);
    fetch(`${ws.ws.ip}/menu/saveItem`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    }).then(response => response.json()).then((result) => {
      Swal.fire(
        'Success',
        'Data is saved!',
        'success',
      );
      reset();
    }).catch((error) => {
      reset();
      Swal.fire(
        'Error Saved',
        error,
        'error',
      );
    });
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Form Add Menu</h5>
              <h5 className="subhead">Form for add menu item</h5>
            </div>
            <form name="form_add_menu" className="form form--horizontal" onSubmit={handleSubmit((values) => { this.mySubmitHandler(values); })}>
              <div className="form__form-group">
                <span className="form__form-group-label">Parent Menu</span>
                <div className="form__form-group-field">
                  <Select2
                    value={this.state.selectValue}
                    style={selectStyle}
                    onChange={this.selectValue}
                    data={this.state.selectOptions}
                    options={
                      {
                        placeholder: 'search by tags',
                      }
                    }
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Menu</span>
                <div style={stdStyle} className="form__form-group-field">
                  <Field
                    name="menu"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. Home, Settings ..."
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Weight</span>
                <div style={stdStyle} className="form__form-group-field">
                  <Field
                    name="weight"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. 1,2 etc.."
                    required
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">URL</span>
                <div style={stdStyle} className="form__form-group-field">
                  <Field
                    name="url"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. http://website-link.com/...."
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Icon
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="file"
                    component={renderFileInputField}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Show</span>
                <div style={stdStyle} className="form__form-group-field">
                  <Field
                    name="isShow"
                    component={renderCheckboxField}
                    type="text"
                    placeholder="Disabled Input"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Favorite</span>
                <div style={stdStyle} className="form__form-group-field">
                  <Field
                    name="isFavorite"
                    component={renderCheckboxField}
                    type="text"
                    placeholder="Disabled Input"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Locale Menu</span>
                <div style={stdStyle} className="form__form-group-field">
                  <Field
                    name="lbl_code"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. HOME.TOP_LABEL, HOME.SIDEBAR_LABEL"
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button disabled={this.state.hideButton} color="primary" type="submit">Submit</Button>
                <Button type="button" onClick={reset}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.menu) {
    errors.menu = 'Menu field shouldn’t be empty';
  }
  if (!values.weight) {
    errors.weight = 'Weight field shouldn’t be empty';
  }
  if (!values.url) {
    errors.url = 'Url field shouldn’t be empty';
  }
  if (!values.lbl_code) {
    errors.lbl_code = 'Label code menu field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'AddFormMenu', // a unique identifier for this form
  validate,
})(translate('global')(AddFormMenu));
