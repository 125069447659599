// export default {
//   ws: {
//     ip: 'https://servicehotfix.phirogo.com',
//   },
//   subdomain: window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false,
// };

export default {
  ws: {
    // ip: 'https://phirogo-backend.test',
    ip: "https://servicebeta.phirogo.com",
    // ip: 'https://service.phirogo.com',
  },
  subdomain: "phiraka",
  environment: "prod",
  // subdomain: window.location.host.split('.')[0],
  // environment: 'prod',
};
