/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faSync,
  faTimes,
  faClock,
  faFileImage,
  faFilePdf,
} from '@fortawesome/fontawesome-free-solid';
import { Document, Page } from 'react-pdf';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ls from 'local-storage';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderIntervalDatePickerField from '../../../../../shared/components/form/IntervalDatePicker';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { AlphaOBoxIcon } from 'mdi-react';
import { split } from 'lodash';

// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

class submissionForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    wfo_id: PropTypes.string.isRequired,
    emp_id: PropTypes.string.isRequired,
    // attachment: PropTypes.string.isRequired,
    initialize: PropTypes.string.isRequired,
    value: PropTypes.arrayOf.isRequired,
    getValue: PropTypes.func.isRequired,
    disabledField: PropTypes.bool.isRequired,
    zindextyp: PropTypes.string.isRequired,
    zindexdate: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '15%', '20%', '20%', '15%', '15%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      displayLoad: 'none',
      displaySave: '',
      arrleave: [],
      attachment: [],
      arrType: [],
      arrEmp: [],
      post: [],
      disabled: false,
      zindex: '9999',
      typeday: '',
      dataLeave: [],
      file_name: '',
      touchedFile: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getLeaveRequest = this.getLeaveRequest.bind(this);
    this.getLeaveType = this.getLeaveType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.dataLeave = this.dataLeave.bind(this);
    this.checkAttachment = this.checkAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.viewAttachment = this.viewAttachment.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getLeaveRequest();
      this.getLeaveType();
      // this.getButton();
    });
    if (typeof this.props.wfo_id !== 'undefined') {
      this.getLeaveRequest();
      this.setState({ disabled: this.props.disabledField });
      this.setState({ displaySave: 'none' });
    }
    console.log(this.state.typeday);
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 6: t('FRM.FUNCTION') },
    ];
    if (typeof this.props.wfo_id === 'undefined') {
      this.props.initialize(newProps.value);
      this.setState({ typeday: newProps.value.type });
      console.log(this.state.typeday);
    }
  }

  getLeaveRequest() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const wfo = this.props.wfo_id;
    axios
      .post(
        `${ls.get('ws_ip')}/empleaverequest/getEmpLeaveReqByWfo/${wfo}`,
        '',
        config,
      )
      .then((resemp) => {
        const emp = resemp.data.data;
        // const fileResponse = resemp.data.file;
        // console.log('fileeeeee', fileResponse);
        let totday1 = '';
        const totday = resemp.data.numberdays;
        // console.log('totday', totday);
        if (totday === '0') {
          totday1 = '1';
        } else {
          totday1 = totday;
        }
        const array = [];
        const datamapp = {
          leave_type: `${emp[0].atl_id}`,
          start_date: moment(emp[0].leave_start),
          end_date: moment(emp[0].leave_end),
          total_days: totday1,
          remarks: emp[0].ler_reason,
          leave_day_type: resemp.data.leave_taken_type,
        };
        this.setState({
          dataLeave: { leave_taken_type: resemp.data.leave_taken_type },
        });
        this.setState({ file_name: emp[0].file_name });
        let files = [];
        emp.map(async (f) => {
          try {
            const responseFile = await axios.get(
              `${ls.get('ws_ip')}/public/uploads${f.file_path}`,
              {
                headers: { Authorization: `Bearer ${ls.get('token')}` },
                responseType: 'blob',
              },
            );
            const attac = new File([responseFile.data], f.file_name);
            files.push(attac);
            // console.log(attac);
          } catch (error) {
            console.error(error);
          } finally {
            this.setState({ attachment: files });
          }
        });
        this.props.initialize(datamapp);
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getLeaveType() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const { emp_id } = ls.get('user_cred');
    axios
      .get(`${ls.get('ws_ip')}/attleave/getbyemp/${emp_id}`, config)
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrleave: array });
        } else {
          emp.map((obj) => {
            array.push({
              value: obj.atl_id,
              wfl: obj.wfl_id,
              label: obj.leave_name,
              lec_code: obj.lec_code,
              is_attachement_required: obj.is_attachement_required,
              leave_taken_type: obj.leave_taken_type,
            });
          });
          setTimeout(() => {
            this.setState({ arrleave: array });
          }, 200);
        }
      })
      .catch(() => {});
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    // console.log(values);
    this.setState({ displayLoad: '', displaySave: 'none' });
    const { apiws, token } = this.state;
    const userid = ls.get('user_cred').usr_id;
    // eslint-disable-next-line prefer-destructuring
    const emp_id = ls.get('user_cred').emp_id;
    // eslint-disable-next-line prefer-destructuring
    const wfl_id = values.leave_type.wfl;
    const atl_id = values.leave_type.value;
    // const start_date = moment(values.start_date).format('YYYY-MM-DD');
    const end_date = moment(values.end_date).format('YYYY-MM-DD');
    const attachmentVal = values.attachment;
    const error = true;
    const remarks = typeof values.remarks === 'undefined' ? '' : values.remarks;
    const lec_id =
      typeof values.leave_category === 'undefined' ||
      values.leave_category === null
        ? ''
        : values.leave_category.value || values.leave_category;
    const url = `${apiws}/workflow/generateleavecase`;
    const data = {
      wfl_id,
      emp_id,
      atl_id,
      remarks,
      // start_date,
      end_date,
      attachmentVal,
      cuser_id: userid,
    };

    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications(
              'Success',
              'Leave request has submitted',
              'success',
            );
            this.setState({
              displayLoad: 'none',
              displaySave: '',
              redirect: true,
            });
            setTimeout(() => {
              this.props.destroy();
            }, 200);
          } else {
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  dataLeave(e) {
    this.setState({ dataLeave: e });
  }

  downloadAttachment(pos) {
    const list = this.state.attachment;
    const objectURL =
      typeof list[pos].path === 'string'
        ? list[pos].path
        : URL.createObjectURL(list[pos]);
    const a = document.createElement('a');
    a.href = objectURL;
    a.target = '_blank';
    a.download = list[pos].name;
    a.click();
  }

  removeAttachment(pos) {
    const list = this.state.attachment;
    list.splice(pos, 1);
    setTimeout(() => {
      this.props.getValue(this.props.value, list, '', 'attachment');
    }, 200);
    this.setState({ attachment: list, trigger: !this.state.trigger });
  }

  viewAttachment(pos) {
    const list = this.state.attachment;
    if (list[pos].name.split('.')[1] === 'pdf') {
      let base64String;
      const reader = new FileReader();
      reader.readAsDataURL(list[pos]);
      reader.onloadend = () => {
        base64String = reader.result;
        this.setState({
          typeDoc: list[pos].name.split('.')[1],
          modalHeader: list[pos].name,
          modalContent:
            typeof list[pos].path === 'string'
              ? list[pos].path
              : base64String.substr(base64String.indexOf(',') + 1),
        });
      };
    } else {
      this.setState({
        typeDoc: list[pos].name.split('.')[1],
        modalHeader: list[pos].name,
        modalContent:
          typeof list[pos].path === 'string'
            ? list[pos].path
            : URL.createObjectURL(list[pos]),
      });
    }
    this.toggle();
  }

  checkAttachment(e) {
    const file = e.target.files[0];
    // console.log(file);
    const allowedType = [
      'application/pdf',
      'image/jpg',
      'image/jpeg',
      'image/png',
    ];
    const allowedSize = 5000; // in kilobytes
    if (!allowedType.includes(file?.type)) {
      Swal.fire({
        title: 'Warning',
        text: 'File type not allowed!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else if (Math.ceil(file?.size / 1000) > allowedSize) {
      Swal.fire({
        title: 'Warning',
        text: 'Maximum file size exceeded!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      this.props.getValue(
        '',
        [...this.state.attachment, file],
        '',
        'attachment',
      );
      this.setState({ attachment: [...this.state.attachment, file] });
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { handleSubmit, t } = this.props;
    // console.log(handleSubmit);
    const {
      arrEmp,
      leavecategory,
      emp_id,
      arrleave,
      displayLoad,
      displaySave,
      zindex,
      attachment,
      file_name,
      dataLeave,
    } = this.state;

    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.LEAVE_REQUEST')}
      </Button>
    );

    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );

    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row>
                  {/* {this.state.disabled === false && (
                  <Col md={8}>
                    <h3 className="page-title">{t('LBL.SUBMISSION_LEAVE')}</h3>
                  </Col>
                )} */}
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form__vertical"
                      name="addleavesubmission"
                      id="addleavesubmission"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Col
                            md={4}
                            xs={12}
                            style={{
                              padding: '0',
                              // zIndex: this.props.zindextyp,
                            }}
                          >
                            <span className="form__form-group-label">
                              {t('LBL.LEAVE_TYPE')}
                            </span>
                          </Col>
                          {dataLeave.leave_taken_type != 1 &&
                            dataLeave.leave_taken_type != null && (
                              <Col
                                md={4}
                                xs={12}
                                style={{
                                  padding: '0px 0px 0px 2vw',
                                  // zIndex: this.props.zindextyp,
                                }}
                              >
                                <span className="form__form-group-label">
                                  {t('LBL.LEAVE_DAY_TYPE')}
                                </span>
                              </Col>
                            )}
                        </div>
                        <div className="form__form-group-field">
                          <Col
                            md={4}
                            xs={12}
                            style={{
                              // paddingRight: '10px',
                              padding: '0',
                              // zIndex: this.props.zindextyp,
                            }}
                          >
                            <Field
                              name="leave_type"
                              component={renderSelectField}
                              type="text"
                              // options={leavecategory}
                              options={arrleave}
                              placeholder="Select leave type"
                              disabled={this.state.disabled}
                              func={this.dataLeave}
                              onChange={this.props.getValue}
                              props={{
                                disableTouched: this.props.disabled,
                              }}
                            />
                          </Col>
                          {dataLeave.leave_taken_type != 1 &&
                            dataLeave.leave_taken_type != null && (
                              <Col
                                md={4}
                                xs={12}
                                style={{
                                  marginLeft: '2.5em',
                                  zIndex: this.props.zindextyp,
                                  // paddingRight: '0.6em',
                                  padding: '0',
                                }}
                              >
                                <Field
                                  name="leave_day_type"
                                  component={renderSelectField}
                                  type="text"
                                  // options={leavecategory}
                                  options={[
                                    {
                                      value: '1',
                                      label: 'Full Day',
                                    },
                                    { value: '2', label: 'First Half Day' },
                                    { value: '3', label: 'Second Half Day' },
                                  ]}
                                  placeholder="Select leave day type"
                                  disabled={this.state.disabled}
                                  // func={this.dataLeave}
                                  onChange={this.props.getValue}
                                  props={{
                                    disableTouched: this.props.disabled,
                                  }}
                                />
                              </Col>
                            )}
                          {/* <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div> */}
                        </div>
                      </div>

                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Col
                            md={4}
                            xs={12}
                            style={{
                              padding: '0',
                              // zIndex: this.props.zindexdate,
                            }}
                          >
                            <span className="form__form-group-label">
                              {t('LBL.START_DATE')}
                            </span>
                          </Col>
                          <Col
                            md={4}
                            xs={12}
                            style={{
                              padding: '0px 0px 0px 2vw',
                              // zIndex: this.props.zindexdate,
                            }}
                          >
                            <span className="form__form-group-label">
                              {t('LBL.END_DATE')}
                            </span>
                          </Col>
                        </div>
                        <div className="form__form-group-field" id="form-leave">
                          <div className="date-picker date-picker--interval">
                            <Col
                              md={4}
                              xs={12}
                              style={{
                                padding: '0',
                                // zIndex: this.props.zindexdate,
                              }}
                            >
                              <Field
                                name="start_date"
                                component={renderDatePickerField}
                                placeholder="Start Date"
                                disabled={this.state.disabled}
                                onChange={this.props.getValue}
                                props={{
                                  disableTouched: this.props.disabled,
                                }}
                              />
                            </Col>
                            <MinusIcon className="date-picker__svg" />
                            <Col
                              md={4}
                              xs={12}
                              style={{
                                padding: '0',
                                // zIndex: this.props.zindexdate,
                              }}
                            >
                              <Field
                                name="end_date"
                                component={renderDatePickerField}
                                type="text"
                                placeholder="End Date"
                                disabled={this.state.disabled}
                                onChange={this.props.getValue}
                                props={{
                                  disableTouched: this.props.disabled,
                                }}
                              />
                            </Col>
                            {/* {typeof this.state.typeday !== 'undefined' && (
                              <Col md={2} xs={12}>
                                <Field
                                  name="total_days"
                                  component={renderTextInput}
                                  formatdate="HH:mm"
                                  disabled="true"
                                />
                              </Col>
                            )}
                            {typeof this.state.typeday !== 'undefined' && (
                              <span
                                className="form__form-group-label"
                                style={{
                                  marginBottom: '0px',
                                }}
                              >
                                {t(this.state.typeday)}
                              </span>
                            )} */}
                            {/* <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.REASON')}
                        </span>
                        <div className="form__form-group-field">
                          <Col md={12} xs={12} style={{ padding: '0' }}>
                            <Field
                              name="remarks"
                              component={renderTextInput}
                              type="textarea"
                              disabled={this.state.disabled}
                              onChange={this.props.getValue}
                              props={{
                                disableTouched: this.props.disabled,
                              }}
                              maxLength="350"
                            />
                          </Col>
                        </div>
                      </div>
                      {dataLeave.is_attachement_required == 't' && (
                        <div className="form__form-group">
                          {attachment.length === 0 && (
                            <>
                              {this.props.disabled == true ? (
                                <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                                  <span className="form__form-group-error">
                                    Attachment shouldn't be empty
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="form__form-group-label">
                                File Attachment
                              </span>
                              <div
                                className="form__form-group-file"
                                style={{
                                  paddingLeft: '0',
                                }}
                              >
                                <Col md={12} xs={12} style={{ padding: '0' }}>
                                  {this.props.emp_id === undefined && (
                                    <label htmlFor="attachment">
                                      Choose the file
                                    </label>
                                  )}
                                  {this.props.emp_id === undefined && (
                                    <div>
                                      <input
                                        type="file"
                                        name="attachment"
                                        id="attachment"
                                        // disabled={this.props.disabled}
                                        onChange={(e) => {
                                          this.checkAttachment(e);
                                          setTimeout(() => {
                                            this.props.getValue(
                                              this.props.value,
                                              this.state.attachment,
                                              '',
                                              'attachment',
                                            );
                                          }, 200);
                                        }}
                                      />
                                      <small>File type: png,jpg,jpeg,pdf</small>
                                      <br />
                                      <small>File Size Max: 5Mb</small>
                                    </div>
                                  )}
                                </Col>
                              </div>
                            </>
                          )}

                          <div
                            style={{
                              border: '1px solid #ddd',
                              borderRadius: '2pt',
                              padding: '5px 10px',
                            }}
                          >
                            {attachment.map((file, pos) => (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  borderTop: pos > 0 && '1px solid #ddd',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    file.type === 'application/pdf' ||
                                    file.name.split('.')[
                                      file.name.split('.').length - 1
                                    ] === 'pdf'
                                      ? faFilePdf
                                      : faFileImage
                                  }
                                  style={{
                                    marginTop: '5px',
                                    marginLeft: '3px',
                                  }}
                                />
                                <div style={{ width: '60%' }}>{file.name}</div>
                                <div>
                                  {file.type !== 'application/pdf' &&
                                    file.name.split('.')[
                                      file.name.split('.').length - 1
                                    ] !== 'pdf' && (
                                      <button
                                        type="button"
                                        onClick={() => this.viewAttachment(pos)}
                                        className="btn btn-sm btn-outline-secondary btn-data-table mx-1"
                                      >
                                        {t('LBL.VIEW')}
                                      </button>
                                    )}
                                  <button
                                    type="button"
                                    onClick={() => this.downloadAttachment(pos)}
                                    className="btn btn-sm btn-outline-success btn-data-table mx-1"
                                  >
                                    {t('FRM.DOWNLOAD')}
                                  </button>
                                  {this.props.emp_id === undefined && (
                                    <button
                                      type="button"
                                      onClick={() => this.removeAttachment(pos)}
                                      className="btn btn-sm btn-outline-danger btn-data-table mx-1"
                                    >
                                      <i className="fas fa-times" />
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {this.state.file_name !== null &&
                      this.state.file_name !== '' ? (
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {/* {t('LBL.SICKNOTE')} */}
                            File Attachment
                          </span>
                          <div
                            style={{
                              border: '1px solid #ddd',
                              borderRadius: '2pt',
                              padding: '5px 10px',
                            }}
                          >
                            {attachment.map((file, pos) => (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  borderTop: pos > 0 && '1px solid #ddd',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    file.type === 'application/pdf' ||
                                    file.name.split('.')[
                                      file.name.split('.').length - 1
                                    ] === 'pdf'
                                      ? faFilePdf
                                      : faFileImage
                                  }
                                  style={{
                                    marginTop: '5px',
                                    marginLeft: '3px',
                                  }}
                                />
                                <div style={{ width: '60%' }}>{file.name}</div>
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => this.viewAttachment(pos)}
                                    className="btn btn-sm btn-outline-secondary btn-data-table mx-1"
                                  >
                                    {t('LBL.VIEW')}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => this.downloadAttachment(pos)}
                                    className="btn btn-sm btn-outline-success btn-data-table mx-1"
                                  >
                                    {t('FRM.DOWNLOAD')}
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.modalHeader}
          </ModalHeader>
          <ModalBody>
            {this.state.typeDoc === 'pdf' ? (
              <iframe
                src={`data:application/pdf;base64,${this.state.modalContent}`}
                title={this.state.modalHeader}
                width={770}
                height={700}
              />
            ) : (
              <img src={this.state.modalContent} alt={this.state.modalHeader} />
            )}
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'approval', // a unique identifier for this form
})(translate('global')(submissionForm));
