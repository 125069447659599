/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  //   ButtonToolbar,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from 'react-router-dom';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
// import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { translate } from 'react-i18next';
import { Field, reduxForm, FieldArray } from 'redux-form';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import axios from 'axios';
import renderTextInput from '../../../../shared/components/form/TextInput';
// import EditTable from '../../../../shared/components/table/EditableTable';
// import Pagination from '../../../../shared/components/pagination/Pagination';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderDatePickerField from '../../../../shared/components/form/DatePicker';
// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import showNotifications from '../../notificationMessages';
import Swal from 'sweetalert2';
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect';
import utils from '../../../../utils';

// const FunctionFormater = ({ value }) => (
//   value === 'active' ? <span className="badge badge-success">Enabled</span> :
//     <span className="badge badge-disabled">Disabled</span>
// );

// FunctionFormater.propTypes = {
//   value: PropTypes.string.isRequired,
// };

class OvertimeSetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      modal: false,
      arrPayrollComponent: [],
      arr: [],
    };
    this.toggle = this.toggle.bind(this);
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('FRM.FUNCTION') },
    ];

    this.state = {
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '75%', '20%'],
      emp_id: '',
      redirect: false,
      addcond: '1',
      editcond: '1',
      deletecond: '1',
      trigger: false,
    };

    this.getTableData = this.getTableData.bind(this);
    this.editovertimecategory = this.editovertimecategory.bind(this);
    this.deleteovertimecategory = this.deleteovertimecategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlechange = this.handlechange.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getAllComponent();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/payovertimecategory/getAllItem/` });
  }


  componentWillReceiveProps(newProps) {
    // this.props.initialize({ multiplier: [{}] });
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('FRM.FUNCTION') },
    ];
  }


  getTableData() {
    this.child.reload();
  }

  getAllComponent() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/paycomponent/getAll/`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        const array = [];
        if (getResponseData.length > 0) {
          getResponseData.map(obj => (
            array.push({ value: obj.pyc_id, label: obj.component })
          ));
        }
        this.setState({ arrPayrollComponent: array });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  toggle() {
    this.props.destroy();
    this.setState({
      modal: !this.state.modal,
    }, () => { this.props.initialize({ multiplier: [{ hours: '', multiplier: '' }] }); });
  }

  async editovertimecategory(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios.post(
      `${apiws}/payovertimecategory/getAllItemById/${id}`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        const multiplier = [];
        const component = [];
        if (getResponseData.multiplier.length !== 0) {
          getResponseData.multiplier.forEach((element) => {
            multiplier.push({
              hours: element.overtime_hours,
              multiplier: element.overtime_multiplier,
            });
          });
        } else {
          multiplier.push({
            hours: '',
            multiplier: '',
          });
        }
        if (getResponseData.component.length !== 0) {
          getResponseData.component.forEach((element) => {
            component.push(element.pyc_id);
          });
        }
        const datamaping = {
          oct_id: getResponseData.overtime[0].oct_id,
          payrollComponent: component,
          overtime_name: getResponseData.overtime[0].name,
          overtimeCode: getResponseData.overtime[0].oct_code,
          minimalOvertime: getResponseData.overtime[0].min_minutes,
          overtime_type: getResponseData.overtime[0].ocl_id,
          multiplier,
        };

        this.setState({ statusEdit: true });
        this.setState({ modal: true }, () => {
          this.props.destroy();
          this.props.initialize(datamaping);
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  deleteovertimecategory(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payovertimecategory/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.child.reload('delete');
            }, 1000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }

  handleSubmit(values) {
    console.log(values);
    const {
      apiws,
      token,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const multiplierarr = [];

    values.multiplier.forEach((element) => {
      multiplierarr.push({
        hours: element.hours,
        factor: element.multiplier,
      });
    });

    const arrMultiplier = JSON.stringify(multiplierarr);
    const arrComponent = JSON.stringify(this.state.arr);
    const id = values.oct_id;
    // const calc_name = (typeof values.overtime_type.value === 'undefined') && values.overtime_type.value === null ? '' : values.overtime_type.value;
    let calc_name;
    if ((typeof values.overtime_type === 'undefined')) {
      calc_name = '';
    } else if (values.overtime_type === null && values.overtime_type === null === '') {
      calc_name = '';
    } else {
      calc_name = values.overtime_type;
    }
    const min_overtime = values.minimalOvertime;
    const oct_name = values.overtime_name;
    const oct_code = values.overtimeCode;
    const userid = ls.get('user_cred').usr_id;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/payovertimecategory/saveItem`;
      data = {
        arrMultiplier,
        arrComponent,
        calc_name,
        min_overtime,
        oct_name,
        oct_code,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/payovertimecategory/updateItem/${id}`;
      data = {
        arrMultiplier,
        arrComponent,
        calc_name,
        min_overtime,
        oct_code,
        oct_name,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.setState({ modal: false });
        setTimeout(() => {
          this.child.reload();
        }, 2000);
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }
  //   createRows = (numberOfRows) => {
  //     const rows = [];
  //     for (let i = 1; i < numberOfRows + 1; i += 1) {
  //       rows.push({
  //         no: i,
  //         user_name: [
  //           'Weekday Overtime',
  //           'Weekend Overtime'][Math.floor((Math.random() * 4))],
  //         table_function: [
  //           <ButtonToolbar className="btn-toolbar-function">
  //             <Link
  //               className="btn btn-sm btn-outline-primary btn-data-table"
  //               to="/"
  //             >
  //               <FontAwesomeIcon icon={faPencilAlt} />
  //             </Link>
  //             <Link
  //               className="btn btn-sm btn-outline-danger btn-data-table"
  //               to="/"
  //             >
  //               <FontAwesomeIcon icon={faTrashAlt} />
  //             </Link>
  //           </ButtonToolbar>],
  //       });
  //     }
  //     return rows;
  //   };

  handlechange(val) {
    const newarr = Object.values(val).slice(0, -1);
    this.setState({
      arr: newarr, trigger: !this.state.trigger,
    });
  }

  rendermultiplier = ({
    fields, meta: { error },
  }) => (
    <div>
      {fields.map((multiply, index) => (
        <Row >
          <div className="form-overtime-multiplier">
            <Col md={3} xs={12}>
              <Field
                name={`${multiply}.hours`}
                component={renderTextInput}
                type="number"
                placeholder="Hours"
              />
            </Col>
            <Col md={3} xs={12}>
              <Field
                name={`${multiply}.multiplier`}
                component={renderTextInput}
                type="number"
                placeholder="Factor"
              />
            </Col>
            <Col md={4} sm={12}>
              <Row>
                {(index === 0) ?
                  (
                    <Col md={4} sm={4}>
                      <Button
                        className="add_btn_multiplier"
                        onClick={() => fields.push({})}
                        color="primary"
                        size="sm"
                      // eslint-disable-next-line no-unneeded-ternary
                      // disabled={(fields.length > 2) ? true : false}
                      ><FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Col>
                  )
                  :
                  (
                    <Col md={4} sm={4}>
                      <Button
                        className="add_btn_multiplier"
                        onClick={() => fields.remove(index)}
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    </Col>
                  )
                }
              </Row>
            </Col>
          </div>
        </Row>
      ))
      }
      {error && <li className="error">{error}</li>}
    </div >
  );

  render() {
    const { handleSubmit, pristine, t } = this.props;
    const { arrPayrollComponent } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{ float: 'right', 'margin-bottom': '20px', display: this.state.addcond === '1' ? '' : 'none' }}
      >
        {t('FRM.ADD')}
      </Button>
    );
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={this.state.urlData}
                  heads={this.heads}
                  primaryKey="oct_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editovertimecategory}
                  deleteFunc={this.deleteovertimecategory}
                  displayStyleHead="none"
                  onRef={ref => (this.child = ref)}
                  addBtn={addBtn}
                  editCond={this.state.editcond}
                  addCond={this.state.addcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="m5">
          <Col md={12}>
            <div className="btn_action_table_navbar" style={{ marginTop: '15px' }}>
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className="modal-dialog
                        modal-input"
                style={{ marginTop: '100px', maxWidth: '750px' }}
              >
                <ModalHeader toggle={this.toggle}>{t('FRM.ADD')}  {t('LBL.OVERTIME')}</ModalHeader>
                <ModalBody>
                  <form
                    id="addovertimesetting"
                    name="addovertimesetting"
                    className="form form--horizontal"
                    onSubmit={handleSubmit(this.handleSubmit)}
                  >
                    <Container>
                      <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div className="form__form-group">
                            <input type="hidden" name="oct_id" />
                            <span className="form__form-group-label">{t('LBL.PAYROLL_COMPONENT')}</span>
                            <div className="form__form-group-field">
                              <Field
                                name="payrollComponent"
                                component={renderSelectField}
                                options={arrPayrollComponent}
                                onChange={this.handlechange}
                                placeholder="Search Payroll Component"
                                multi='true'
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.OVERTIME_CODE')}</span>
                            <div className="form__form-group-field">
                              <Field
                                name="overtimeCode"
                                component={renderTextInput}
                                type="text"
                                placeholder="Overtime Code"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.OVERTIME_NAME')}</span>
                            <div className="form__form-group-field">
                              <Field
                                name="overtime_name"
                                component={renderTextInput}
                                type="text"
                                placeholder="Overtime Name"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.MINIMAL_OVERTIME')}</span>
                            <div className="form__form-group-field">
                              <Row style={{ width: 'calc(100% + 30px)' }}>
                                <Col md={6} lg={6} xs={12}>
                                  <Field
                                    name="minimalOvertime"
                                    component={renderTextInput}
                                    type="text"
                                    placeholder="Minimal Overtime"
                                  // props={{ disableTouched: true }}
                                  />
                                </Col>
                                <Col md={6} lg={6} xs={12}>
                                  <div style={{ padding: '5px 0px' }}>
                                    {t('LBL.MINUTES')}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.OVERTIME_CALCULATION')}</span>
                            <div className="form__form-group-field">
                              <Field
                                name="overtime_type"
                                component={renderSelectField}
                                options={[
                                  // { value: '1', label: 'Overtime Request & Attendance' },
                                  { value: '2', label: 'Overtime Request' },
                                ]}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.MULTIPLIER_OVERTIME')}</span>
                            <div className="form__form-group-field">
                              <FieldArray
                                name="multiplier"
                                component={this.rendermultiplier}
                                props={{ t, disableTouched: true }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
                  <Button
                    color="primary"
                    type="submit"
                    form="addovertimesetting"
                    disabled={pristine}
                  > {t('FRM.SAVE')}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    payrollComponent: {
      fieldLabel: 'Component',
      required: true,
    },
    overtimeCode: {
      fieldLabel: 'Code',
      required: true,
    },
    overtime_type: {
      fieldLabel: 'Type',
      required: true,
    },
    minimalOvertime: {
      fieldLabel: 'Minimal',
      required: true,
    },
    multiplier: {
      dynamicField: true,
      children: [
        { name: 'hours', label: 'Hours', required: true },
        { name: 'factor', label: 'Factor', required: true },
      ],
    },
  };

  const errors = utils.validate(validation, values);

  console.log(errors);
  return errors;
};


export default reduxForm({
  form: 'settingovertime',
  //   // onSubmit: submit,
  //   enableReinitialize: true,
  //   keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(OvertimeSetting));
