/* eslint-disable no-unused-vars, quotes, no-lonely-if, prefer-destructuring, react/no-unused-prop-types */
/* eslint-disable array-callback-return, brace-style, object-curly-newline, no-unneeded-ternary */
/* eslint-disable max-len, class-methods-use-this, react/prop-types, jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-operators, react/jsx-closing-bracket-location, no-loop-func */
/* eslint-disable react/no-unused-state, no-prototype-builtins, no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-for, no-restricted-syntax, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable quote-props, jsx-a11y/anchor-is-valid, operator-assignment, no-return-assign */
/* eslint-disable camelcase, arrow-parens, no-plusplus, prefer-template, jsx-a11y/alt-text */
/* eslint-disable object-shorthand, react/jsx-curly-brace-presence, arrow-body-style */
/* eslint-disable react/no-did-mount-set-state, function-paren-newline, jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import showNotifications from '../Preferences/notificationMessages';
import renderTextInput from '../../shared/components/form/TextInput';
import moment from 'moment';
import { data } from 'browserslist';
import ViewListTable from '../../shared/components/table/viewListTable';
import Swal from 'sweetalert2';

const UserDefault = '/img/user-default-photo.png';

const arrKpiplan = [];
let arrWeightName = [];
const arrCategoryKpi = [];
const arrWeightVerify = [];
const arrCategoryId = [];
let formKpiPlan = false;

class KpiPlanForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      empid: '',
      empname: '',
      empcode: '',
      modal: false,
      displaySpinner: false,
      dataTree: '',
      disabledButtonSave: true,
      redirectUrl: false,
      redirectUrlTracking: false,
      arrPhase: [],
      phaseName: '',
      periodPhaseId: '',
      periodId: '',
      phaseId: '',
      kpiPlanData: [],
      kpiPlanCategory: [],
      kpiPlanStrategic: [],
      totalWeight: 0,
      reviewerId: '',
      arrEmployee: [],
      reviewer: '',
      approval: false,
      workflow: '',
      activity: '',
      showPickedItem: false,
      arrEmpPicked: [],
      showData: false,
      wflId: '',
      submitPlan: false,
      urlData: '',
      dataWidth: ['5%', '40%', '40%'],
      showTableIssue: true,
      issuePph: '',
      issuePpi: '',
      issueKpi: '',
      totalScore: 0,
      statusKpi: '',
      statusNameKpi: '',
      remarksKpi: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmitKpi = this.handleSubmitKpi.bind(this);
    this.changeWeight = this.changeWeight.bind(this);
    this.getKpiplan = this.getKpiplan.bind(this);
  }

  componentDidMount = () => {
    this.props.destroy();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    setTimeout(() => {
      this.getKpiCategory();
      this.getKpiplan();
      this.getFlow();
    }, 300);
    if (typeof this.props.location.state === 'undefined') {
      this.setState({ redirectUrl: true });
    } else {
      setTimeout(() => {
        this.setState({
          periodId: this.props.location.state.periodId,
          phaseId: this.props.location.state.phaseId,
          periodPhaseId: this.props.location.state.periodPhaseId,
          empid: this.props.location.state.empId,
          empname: this.props.location.state.empName,
          empcode: this.props.location.state.empCode,
          phaseName: this.props.location.state.phaseName,
        });
      }, 50);
    }
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
    ];
  }

  getEmployeePicked(x) {
    const { periodId, phaseId, periodPhaseId } = this.state;
    const kpiId = x.id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllemployeePlan/${kpiId}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const dataResponse = res.data.data;
          setTimeout(() => {
            this.setState({ arrEmpPicked: dataResponse });
          }, 100);
        }
      });
    this.setState({
      showPickedItem: true,
      showPickedTitle: x.title,
    });
  }

  getButton() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const btn = {};
    const formBody = Object.keys(btn)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(btn[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
            }
          });
        }
      });
  }

  getKpiCategory() {
    const { periodId, empid, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllitemcategorykpi/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const n = res.data.data.length;
          const d = res.data.data;
          for (let i = 0; i < n; i++) {
            arrCategoryKpi[d[i].ppi_id] = d[i].pcr_id;
          }
        }
      });
  }

  getKpiplan() {
    formKpiPlan = false;
    const { periodId, empid, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    arrWeightName = [];
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllitemcategorydetail/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          if (res.data.data.category.length > 0) {
            const category = res.data.data.category;
            const strategic = res.data.data.strategic;
            const kpi = res.data.data.kpi;
            const flow = res.data.data.flow;
            let total = 0;
            for (let a = 0; a < category.length; a++) {
              arrCategoryId[a] = category[a].pcr_id;
              total = total + parseInt(category[a].score_value, 0);
              for (let b = 0; b < strategic[category[a].pcr_id].length; b++) {
                for (
                  let c = 0;
                  c <
                  kpi[category[a].pcr_id][
                    strategic[category[a].pcr_id][b].pso_id
                  ].length;
                  c++
                ) {
                  const ppn =
                    kpi[category[a].pcr_id][
                      strategic[category[a].pcr_id][b].pso_id
                    ][c].ppn_id;
                  const pcr = category[a].pcr_id;
                  arrWeightName.push(`weight_${ppn}_${pcr}`);
                }
              }
            }
            this.setState({
              kpiPlanCategory: res.data.data.category,
              kpiPlanStrategic: res.data.data.strategic,
              kpiPlanData: res.data.data.kpi,
              showData: true,
              disabledButtonSave: false,
              totalScore: total,
              statusKpi: flow[0].approval_status,
              statusNameKpi: flow[0].opc_status,
              remarksKpi: flow[0].remarks,
            });
          }
        }
      });
  }

  getFlow() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/getWorkflowPerformance`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data.performance[0];
          this.setState({
            wflId: dataResponse.wfl_id,
            workflow: dataResponse.workflow,
            activity: dataResponse.activity,
          });
        }
      });
  }

  getTableData() {
    this.child.reload();
  }

  toggle(ppd_id, kpi_name, ppi_id) {
    this.setState({
      urlData: `${ls.get('ws_ip')}/pfmkpiplanissue/getAllitem`,
      modal: !this.state.modal,
      issuePpd: ppd_id,
      issuePpi: ppi_id,
      issuePph: this.state.phaseId,
      issueKpi: kpi_name,
    });
    setTimeout(() => {
      this.getTableData();
    }, 300);
  }

  checkedKpi(x, y) {
    if (!(typeof x.target === 'undefined')) {
      if (!(typeof x.target.checked === 'undefined')) {
        if (x.target.checked) {
          arrKpiplan.push(y);
          this.setState({ disabledButtonSave: false });
        } else {
          const a = arrKpiplan.indexOf(y);
          arrKpiplan.splice(a, 1);
          const n = arrKpiplan.length;
          if (n === 0) {
            this.setState({ disabledButtonSave: true });
          }
        }
      }
    }
  }

  changeWeight(x) {
    formKpiPlan = false;
    const name = x.currentTarget.name;
    const wname = name.split('_');
    const k = parseInt(wname[1], 0); // kpi
    const c = parseInt(wname[2], 0); // category
    const val =
      parseInt(x.currentTarget.value, 0) > 0
        ? parseInt(x.currentTarget.value, 0)
        : 0;
    this.getKpiplan(k, val, c);
  }

  handleSubmitKpi(values) {
    const {
      apiws,
      token,
      idUser,
      empid,
      wflId,
      periodId,
      phaseId,
      periodPhaseId,
    } = this.state;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const planWeight = [];
    const planTolerance = [];
    Object.keys(values).forEach((obj) => {
      if (obj.includes('weight')) {
        const split = obj.split('_');
        planWeight.push({
          ppn_id: split[1],
          ppi_id: split[2],
          weight: values[obj],
        });
      }
      if (obj.includes('pto')) {
        const split = obj.split('_');
        planTolerance.push({
          ppn_id: split[2],
          pto_id: split[1],
          pto_value: values[obj],
        });
      }
    });
    const action = 'POST';
    const link = `${apiws}/pfmkpiplandetail`;
    const body = [
      `dyn_plan_weight=${JSON.stringify(planWeight)}`,
      `dyn_plan_tolerance=${JSON.stringify(planTolerance)}`,
      `emp_id=${empid}`,
      `pfp_id=${periodId}`,
      `pph_id=${phaseId}`,
      `ppp_id=${periodPhaseId}`,
      `wfl_id=${wflId}`,
      `cuser_id=${idUser}`,
    ].join('&');
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    this.setState({ displaySpinner: true });
    axios(options).then(() => {
      this.setState({ displaySpinner: false });
      this.props.destroy();
      showNotifications('Success', 'Save Draft Success', 'success');
    });
  }

  gotoTracking() {
    const {
      apiws,
      token,
      idUser,
      empid,
      periodId,
      phaseId,
      periodPhaseId,
    } = this.state;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will add new kpi tracking request !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15DD96', // '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const config = {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        };
        const action = 'POST';
        const link = `${apiws}/pfmkpiplan/saveItemFromReject`;
        const body = [
          `emp_id=${empid}`,
          `pfp_id=${periodId}`,
          `pph_id=${phaseId}`,
          `ppp_id=${periodPhaseId}`,
          `cuser_id=${idUser}`,
        ].join('&');
        const options = {
          method: action,
          url: link,
          headers: config,
          data: body,
        };
        axios(options).then(() => {
          this.setState({ redirectUrlTracking: true });
          showNotifications('Success', 'Data reloaded', 'success');
        });
      } else {
        this.setState({ redirectUrlTracking: false });
      }
    });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      redirectUrl,
      redirectUrlTracking,
      kpiPlanData,
      kpiPlanCategory,
      kpiPlanStrategic,
      approval,
    } = this.state;

    if (redirectUrl) {
      const link = <Redirect to={{ pathname: '/performance/information' }} />;
      return link;
    }

    const issueStatus = this.state.statusKpi === '1' ? true : false;

    if (redirectUrlTracking) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpitracking',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empid,
              empName: this.state.empname,
              empCode: this.state.empcode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    if (approval) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpi',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empid,
              empName: this.state.empname,
              empCode: this.state.empcode,
            },
          }}
        />
      );
    }

    const arrDataKpiPlan = [];
    for (let i = 0; i < kpiPlanCategory.length; i++) {
      const category = kpiPlanCategory[i];
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.CATEGORY')}
          </span>
          <div className="form__form-group-field">
            <Col xs={5} md={5} lg={5} xl={5}>
              <input
                style={{ fontWeight: 'bold' }}
                name="category"
                disabled="true"
                value={category.category_name}
              />
            </Col>
          </div>
        </div>,
      );
      for (let j = 0; j < kpiPlanStrategic[`${category.pcr_id}`].length; j++) {
        const strategic = kpiPlanStrategic[`${category.pcr_id}`][j];
        arrDataKpiPlan.push(
          <div className="form__form-group">
            <span
              className="form__form-group-label"
              style={{ paddingLeft: '15px' }}
            >
              {t('LBL.STRATEGIC_OBJECTIVE')}
            </span>
            <div className="form__form-group-field">
              <Col xs={5} md={5} lg={5} xl={5}>
                <input
                  name="startegic"
                  disabled="true"
                  value={strategic.strategic_objective_name}
                />
              </Col>
            </div>
          </div>,
        );
        for (
          let k = 0;
          k < kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`].length;
          k++
        ) {
          const kpi =
            kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`][k];
          const satuan = kpi.target === '%' ? '%' : '';
          arrDataKpiPlan.push(
            <div className="form__form-group">
              <span
                className="form__form-group-label"
                style={{ paddingLeft: '15px' }}
              >
                {t('LBL.KPI')}
              </span>
              <div className="form__form-group-field">
                <Col xs={3} md={3} lg={3} xl={3}>
                  <textarea name="kpi" disabled="true" value={kpi.kpi_name} />
                </Col>
                <Col xs={1} md={1} lg={1} xl={1}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TARGET')}
                    </span>
                    <div
                      className="form__form-group-field number-form"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <input
                        name="target"
                        value={`${kpi.target_value} ${satuan}`}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.WEIGHT')}
                    </span>
                    <div
                      className="form__form-group-field number-form"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        name={`weight_${kpi.ppn_id}_${category.pcr_id}`}
                        component={renderTextInput}
                        type="text"
                        placeholder="%"
                        onChange={(x) => this.changeWeight(x)}
                        maxLength="3"
                        input={{ value: kpi.plan_weight }}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DIDNT_REACH')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_1_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.didnt_reach }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.REACH')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_2_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.reach }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EXCEED')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_3_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.exceed }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>,
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Col xs={2} md={2} lg={2} xl={2}>
                  <span className="form__form-group-label">
                    {t('LBL.ACHIEVMENT')}
                  </span>
                  <div
                    className="form__form-group-field"
                    style={{ width: '100%', padding: '0px' }}
                  >
                    <Field
                      component={renderTextInput}
                      name={`pra_${kpi.ppn_id}`}
                      type="text"
                      input={{ value: kpi.final_achievement }}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={8} md={8} lg={8} xl={8}>
                  <span className="form__form-group-label">
                    {t('LBL.NOTES')}
                  </span>
                  <div
                    className="form__form-group-field"
                    style={{ width: '100%', padding: '0px' }}
                  >
                    <Field
                      component={renderTextInput}
                      name={`notes_${kpi.ppn_id}`}
                      type="textarea"
                      classes="kpi-tracking"
                      input={{ value: kpi.notes }}
                      disabled
                    />
                  </div>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{ paddingLeft: '0px', paddingTop: '40px' }}
                >
                  <Button
                    // outline
                    color="secondary"
                    style={{ width: '150px' }}
                    onClick={() =>
                      this.toggle(kpi.ppd_id, kpi.kpi_name, kpi.ppi_id)
                    }
                    disabled={data.phase_disabled ? 'disabled' : ''}
                    className="btn_table_navbar"
                  >
                    {' '}
                    {t('LBL.PROBLEM_ISSUES')}
                  </Button>
                </Col>
              </div>
            </div>,
          );
        }
      }
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.WEIGHT')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.weight_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.RESULT')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`weightverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.plan_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.SCORE')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`score_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.score_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(<hr style={{ width: '100%' }} />);
    }
    const phaseNm = this.state.phaseName.toUpperCase();
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div className="header-no-bg">
                  <h4 className="title-label">
                    {t('LBL.KPI')} {phaseNm} {t('LBL.DETAIL').toUpperCase()}
                  </h4>
                  <p className="sub-label">Review your detail submission</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="card-login-info">
                        <div className="d-flex">
                          <div className="user-profile-login">
                            <img src={UserDefault} alt="user" />
                          </div>
                          <div className="w-100">
                            <h4 className="username-login">
                              {t('LBL.LOGIN_AS')}{' '}
                              {`${ls.get('user_cred').first_name} ${
                                ls.get('user_cred').last_name
                              }`}
                            </h4>
                            <p className="caption-label">
                              {t('LBL.REQUEST_DATE')} :{' '}
                              {moment(new Date()).format('DD/MM/YYYY')}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="sub-header-label">{t('LBL.SUBMISSION_DETAIL')}</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 20px 30px 20px' }}>
                {/* <Row style={{ margin: '0px !important', padding: '0px !important', textAlign: 'right' }}>
                  <Col style={{ margin: '0px !important', padding: '0px !important' }} />
                  <Col style={{ margin: '0px !important', padding: '0px !important' }} >
                    <Button
                      color="default"
                      className="btn btn-secondary btn_table_navbar"
                      onClick={() => this.setState({ redirectUrl: true })}
                      style={{ float: 'right', marginRight: '15px', marginBottom: '0px' }}
                    >
                      {t('FRM.BACK')}
                    </Button>
                  </Col>
                </Row> */}
                <div
                  className="profile-identity"
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    marginTop: '0px',
                    width: '100%',
                  }}
                >
                  {/* <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t('LBL.INFORMATION')}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <table id="myTable" className="table data-grid-tbl responsive no-wrap">
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUESTER')}</td>
                            <td>: {this.state.empname} ({this.state.empcode})</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUEST_DATE')}</td>
                            <td>: {moment(new Date()).format('YYYY-MM-DD')}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.STATUS')}</td>
                            <td>: Pengajuan Diterima</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.EFFECTIVE_DATE')}</td>
                            <td>: {moment(new Date()).format('YYYY-MM-DD')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faFileAlt} />
                        {t('LBL.KPI_TRACKING')}
                      </div>
                    </Col>
                  </Row> */}
                  {this.state.showData && (
                    <Row style={{ padding: '0px' }}>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <form
                            className="form form--horizontal"
                            onSubmit={handleSubmit(this.handleSubmitKpi)}
                            name="savedraftkpi"
                            id="savedraftkpi"
                          >
                            {arrDataKpiPlan}
                            <div
                              className="form__form-group"
                              style={{ marginTop: '12px' }}
                            >
                              <span
                                className="form__form-group-label"
                                style={{ paddingLeft: '15px' }}
                              >
                                <b>
                                  {t('LBL.SCORE')} {t('LBL.KPI_TRACKING')}
                                </b>
                              </span>
                              <div className="form__form-group-field number-form">
                                <Col xs={3} md={3} lg={3} xl={3}>
                                  <Field
                                    name="total_score"
                                    component={renderTextInput}
                                    type="text"
                                    disabled
                                    placeholder={this.state.totalScore}
                                  />
                                </Col>
                              </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div className="form__form-group">
                              <span
                                className="form__form-group-label"
                                style={{ paddingLeft: '15px' }}
                              >
                                {t('LBL.STATUS')}
                              </span>
                              <div className="form__form-group-field">
                                <Col xs={3} md={3} lg={3} xl={3}>
                                  <ButtonToolbar>
                                    {this.state.statusKpi === '1' ? (
                                      <Button
                                        style={{ cursor: 'default' }}
                                        color="success"
                                        onClick="#"
                                        size="sm"
                                      >
                                        {this.state.statusNameKpi}
                                      </Button>
                                    ) : (
                                      <Button
                                        style={{ cursor: 'default' }}
                                        color="danger"
                                        onClick="#"
                                        size="sm"
                                      >
                                        {this.state.statusNameKpi}
                                      </Button>
                                    )}
                                  </ButtonToolbar>
                                </Col>
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span
                                className="form__form-group-label"
                                style={{ paddingLeft: '15px' }}
                              >
                                {t('LBL.DESCRIPTION')}
                              </span>
                              <div className="form__form-group-field">
                                <Col xs={5} md={5} lg={5} xl={5}>
                                  <Field
                                    component={renderTextInput}
                                    type="textarea"
                                    input={{ value: this.state.remarksKpi }}
                                    disabled
                                  />
                                </Col>
                              </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <Button
                                color="secondary"
                                onClick={() =>
                                  this.setState({ redirectUrl: true })
                                }
                              >
                                {t('FRM.BACK')}
                              </Button>
                              {this.state.statusKpi === '2' && (
                                <Button
                                  color="primary"
                                  onClick={() => this.gotoTracking()}
                                >
                                  {' '}
                                  {t('FRM.NEW_REQUEST')}
                                </Button>
                              )}
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog modal-input"
            style={{ maxWidth: '1000px', marginTop: '100px' }}
          >
            <ModalHeader toggle={this.toggle}>
              {t('LBL.PROBLEM_ISSUES')}
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col>
                    <ViewListTable
                      url={this.state.urlData}
                      displayStyleHead=""
                      textHead={`KPI : ${this.state.issueKpi}`}
                      heads={this.heads}
                      primaryKey="pli_id"
                      buttonAction="noButton"
                      conds={{
                        ppi_id: this.state.issuePpi,
                        pph_id: this.state.issuePph,
                        emp_id: this.state.empid,
                        status: issueStatus,
                      }}
                      widthTable={this.state.dataWidth}
                      onRef={(ref) => (this.child = ref)}
                      deleteFunc={this.deleteRows}
                      updateFunc={this.editRows}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (formKpiPlan === false) {
    const weightN = arrWeightName;
    const uniqueW = new Set(weightN);
    const fixW = [...uniqueW];
    for (let m = 0; m < fixW.length; m++) {
      if (!values[fixW[m]]) {
        errors[fixW[m]] = 'Weight shouldn’t be empty';
      }
    }
    for (let n = 0; n < arrCategoryId.length; n++) {
      const x = `weightverify_${arrCategoryId[n]}`;
      if (arrWeightVerify[n] === false) {
        errors[x] = 'Result weight is not valid';
      }
    }
  }
  return errors;
};

export default reduxForm({
  form: 'kpiplan',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KpiPlanForm));
