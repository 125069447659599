/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Card, CardBody, Container, Row, Col, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { CSVReader } from 'react-papaparse';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import swal from 'sweetalert2';
import { faDownload } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SleepIcon } from 'mdi-react';
import ReactExport from 'react-export-excel';
// import csv from 'csv-validator';


// eslint-disable-next-line react/prefer-stateless-function
const buttonRef = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class HorizontalForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      message: [],
      token: '',
      apiws: '',
      arrdatacsv: [],
      file: '',
      emp_code: '',
      head: [],
      displayLoad: 'none',
      displayButton: '',
      headerexcel: [],
      templatevalue: [],
    };
    this.handleOnError = this.handleOnError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.downloadTemplate = this.downloadTemplate(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.getEmployee();
  }

  getEmployee() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/employee/getAll/`;
    axios.post(
      url,
      '',
      config,
    ).then((response) => {
      const res = response.data.data;
      const arr = [];
      res.map((data) => {
        arr.push(data.emp_code);
      });
      this.setState({ emp_code: arr });
    }).catch((error) => {
      // this.setState({ button_disabled: false });
    });
  }
  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  }

  fileLoad = (data, e) => {
    console.log(data);
    const array = [];
    const header = data[0].data;
    const headers = [];
    const errheader = [];
    header.map((item) => {
      headers.push(item.toLowerCase().replace(/\s/g, '_'));
    });
    const { emp_code, codecomp } = this.state;
    if (data.length > 0) {
      array.push({ header: data[0].data, value: data.slice(1) });
      // console.log(array);
    }
    const datacsv = data.slice(1);
    const dateReg = /^\d{2}([/])\d{2}([/])\d{4}$/;
    // const dateReg = /^\d{2}([- \/.])\d{2}([- \/.])\1\d{4}$/;
    const timeReg = /^\d{2}([:])\d{2}([-])\d{2}([:])\d{2}$/;
    const validdata = [];
    const errordata = [];
    const errormsg = [];
    const invalcomp = [];
    datacsv.map((item, index) => {
      const errorlog = [];
      let stsva;
      if (item.data.length > 1) {
        item.data.map((hea, key) => {
          if (header[key].toLowerCase().includes('no')) {
            if (Number.isNaN(hea) === null) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
          } else if (header[key].toLowerCase().includes('employee code')) {
            if (hea === '') {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
            if (emp_code.indexOf(hea) === -1) {
              stsva = false;
              errorlog.push(`employee code ${hea} not found`);
            }
          } else {
            if (hea === '-' || hea === '' || hea === null) {
              // eslint-disable-next-line no-param-reassign
              item.data[key] = null;
            } else {
              // eslint-disable-next-line no-lonely-if
              if (hea.match(timeReg) === null) {
                errorlog.push(`datetime '${hea}' invalid`);
              }
            }
            if (header[key].match(dateReg) === null) {
              errorlog.push(`date '${header[key]}' invalid`);
            }
          }
        });
      }
      if (errorlog.length === 0) {
        stsva = true;
      }

      if (stsva) {
        validdata.push(item);
        this.setState({ arrdatacsv: validdata, head: headers, file: e });
      } else {
        errormsg.push({ row: index + 1, log: errorlog });
        errordata.push({ item });
      }
    });

    if (errheader.length > 0) {
      errormsg.push({ row: 'header', log: errheader });
    }

    if (errormsg.length > 0) {
      this.setState({ error: true });
      this.setState({ message: errormsg, ...this.state.message });
    }
  }


  handleFirstChunk = (chunkc) => {
    const chunk = chunkc.split('\n');

    if (chunk[0].split(',').length <= 1 && chunk[0].split(';').length <= 1) {
      setTimeout(() => {
        this.handleOnRemoveFile();
      }, 2000);
      showNotifications('Fail', 'Format Not Supported', 'danger');
    }
    // return true;
  }

  handleOnError(err, file, inputElem, reason) {
    console.log(err);
    console.log(file);
    console.log(inputElem);
    console.log(reason);
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '' });
    this.setState({ displayButton: 'none' });
    const userid = ls.get('user_cred').usr_id;
    const {
      token, apiws, arrdatacsv, head, file,
    } = this.state;
    const error = true;
    const csvfile = file;

    const arr = [];
    arrdatacsv.map((elemet, key) => {
      if (elemet.data.length > 1) {
        const obj = {};
        elemet.data.map((value, index) => {
          obj[head[index]] = value;
        });
        arr.push(obj);
      }
    });
    console.log(arr);
    const array = (JSON.stringify(arr));
    let url;
    if (values.type.value === '1') {
      url = `${apiws}/attmanual/saveItemArray`;
    } else if (values.type.value === '2') {
      url = `${apiws}/attmanual/updateItemArray`;
    }

    const convertdata = { data: array, csvfile };

    // const body = [`data=${data}`, `file=${csvfile}`, `cuser_id=${userid}`].join('&');
    const body = { ...convertdata, cuser_id: userid };
    const getFormData = Object.keys(body).reduce((formData, key) => {
      formData.append(key, body[key]);
      return formData;
    }, new FormData());

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    setTimeout(() => {
      axios.post(
        url,
        getFormData,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.handleOnRemoveFile();
        } else {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
          this.handleOnRemoveFile();
        }
      }).catch(() => {
        this.setState({ displayLoad: 'none' });
        this.setState({ displayButton: '' });
        showNotifications('Fail', 'Save Failed', 'danger');
        this.handleOnRemoveFile();
      });
    });
  }

  submit(values) {
    this.setState({ displayLoad: '' });
    this.setState({ displayButton: 'none' });
    const userid = ls.get('user_cred').usr_id;
    const {
      token, apiws, arrdatacsv, head, file,
    } = this.state;
    const error = true;
    const csvfile = file;

    console.log(csvfile);
    const arr = [];
    arrdatacsv.map((elemet, key) => {
      let no; let emp_code; let attendance_status; let date_in; let date_out; let time_in; let
        time_out; let description;
      elemet.data.map((value, index) => {
        if (head[index].toLowerCase().includes('no')) {
          no = { no: value };
        }
        if (head[index].toLowerCase().includes('emp')) {
          emp_code = { emp_code: value };
        }
        // if (head[index].toLowerCase().includes('status')) {
        //   attendance_status = { attendance_status: value };
        // }
        if (head[index].toLowerCase().includes('date_in')) {
          date_in = { date_in: value };
        }
        if (head[index].toLowerCase().includes('date_out')) {
          date_out = { date_out: value };
        }
        if (head[index].toLowerCase().includes('time_in')) {
          time_in = { time_in: value };
        }
        if (head[index].toLowerCase().includes('time_out')) {
          time_out = { time_out: value };
        }
        if (head[index].toLowerCase().includes('desc')) {
          description = { description: value };
        }
      });
      const dat = Object.assign({}, no, emp_code, date_in, date_out, time_in, time_out, description);
      arr.push(dat);
    });
    const array = (JSON.stringify(arr));

    let url;
    if (values.type.value === '1') {
      url = `${apiws}/attmanual/saveItemArray`;
    } else if (values.type.value === '2') {
      url = `${apiws}/attmanual/updateItemArray`;
    }

    const convertdata = { data: array, csvfile };


    // const body = [`data=${data}`, `file=${csvfile}`, `cuser_id=${userid}`].join('&');
    const body = { ...convertdata, cuser_id: userid };
    const getFormData = Object.keys(body).reduce((formData, key) => {
      formData.append(key, body[key]);
      return formData;
    }, new FormData());

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    setTimeout(() => {
      axios.post(
        url,
        getFormData,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.handleOnRemoveFile();
        } else {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
          this.handleOnRemoveFile();
        }
      }).catch(() => {
        this.setState({ displayLoad: 'none' });
        this.setState({ displayButton: '' });
        showNotifications('Fail', 'Save Failed', 'danger');
        this.handleOnRemoveFile();
      });
    });
  }

  handleOnRemoveFile = (e) => {
    this.myFormRef.reset();
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    this.setState({ error: false });
    this.setState({ message: [] });
  }

  downloadTemplate() {
    const headrequire = ['No', 'Employee code', '03/02/2020', '04/02/2020', '05/02/2020'];
    const value =
      [{
        No: '1',
        'Employee code': 'S1090190',
        '03/02/2020': '08:15-17:00',
        '04/02/2020': '08:15-17:00',
        '05/02/2020': '08:15-17:00',
      }];
    setTimeout(() => {
      this.setState({ headerexcel: headrequire, templatevalue: value });
    }, 200);
  }

  render() {
    const { t, handleSubmit } = this.props;
    const ulstyle = { width: '300px', height: '200px', overflow: 'auto' };
    const notestyle = { 'column-count': '3', 'column-gap': '20px' };
    let button_action;
    if (this.state.error) {
      button_action = (
        <Button disabled color="primary" type="submit">{t('FRM.SUBMIT')}</Button>);
    } else {
      button_action = (
        <Button color="primary" type="submit" style={{ display: this.state.displayButton }}>{t('FRM.SUBMIT')}</Button>);
    }
    return (
      <Card>
        <CardBody>
          <Row className="m5">
            <Col sm="12" md={12} lg={12}>
              <form
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
                // eslint-disable-next-line no-return-assign
                ref={el => this.myFormRef = el}
                name="loaderatt"
                id="loaderatt"
              >
                <Container>
                  <Row>
                    <Col sm="12" md={6} lg={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.FILE')}</span>
                        <div className="form__form-group-field">
                          <CSVReader
                            onDrop={this.fileLoad}
                            onError={this.handleOnError}
                            noDrag
                            addRemoveButton
                            config={{
                              beforeFirstChunk: this.handleFirstChunk,
                              delimitersToGuess: [';', ','],
                            }}
                            onRemoveFile={this.handleOnRemoveFile}
                          >
                            <span> {t('LBL.CLICK_TO_UPLOAD')}</span>
                          </CSVReader>
                        </div>
                      </div>
                    </Col>
                    {(this.state.error === true) && (
                      <Col sm="12" md={6} lg={6}>
                        <span className="form__form-group-label" style={{ color: 'red' }}>{t('LBL.ERROR')}</span>
                        <ul style={ulstyle}>
                          {this.state.message.map(obj => (
                            <React.Fragment>
                              <li>  {t('LBL.ROW')} {obj.row}</li>
                              <ul> {obj.log.map(ob => <li>{ob}</li>)} </ul>
                            </React.Fragment>
                          ))
                          }
                        </ul>
                      </Col>
                    )}
                  </Row>
                  <Row >
                    <Col xs={12} md={6} >
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.TYPE_OPERATION')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="type"
                            component={renderSelectField}
                            options={[
                              { value: '1', label: t('LBL.SKIP_EXISTING_RECORD') },
                              { value: '2', label: t('LBL.UPDATE_EXISTING_RECORD') },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ display: this.state.displayLoad, textAlign: 'center' }} className="m5">
                    <Col xs={12} md={6} lg={6} xl={6}>
                      <Card>
                        <CardBody>
                          <div className="load__icon-wrap">
                            <svg className="load__icon">
                              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ButtonToolbar className="form__button-toolbar">
                  {button_action}
                  <Button type="button" onClick={() => this.handleOnRemoveFile()} style={{ display: this.state.displayButton }}>
                    {t('FRM.CANCEL')}
                  </Button>
                </ButtonToolbar>
              </form>
            </Col>
            <Col sm="12" md="12" lg="12" xl="12">
              <div className="note-file-upload">
                <ExcelFile
                  filename="Template Attendance loader"
                  element={
                    <Link
                      to="#"
                      // type="button"
                      onClick={this.downloadTemplate}
                    > <FontAwesomeIcon icon={faDownload} /> <h5><b>{t('LBL.DOWNLOAD')} {t('LBL.TEMPLATE')}</b></h5>
                    </Link>
                  }
                >
                  <ExcelSheet data={this.state.templatevalue} name="Attendance">
                    {
                      this.state.headerexcel.map(obj => (
                        <ExcelColumn label={obj} value={obj} />
                      ))
                    }
                  </ExcelSheet>
                </ExcelFile>
                <br />
                <h5><b>NOTE:</b></h5>
                <h5>Required file type (*.CSV)</h5>
                <h5>Field Order :</h5>
                <ol style={notestyle}>
                  <li>No</li>
                  <li>Employee Code</li>
                  <li>Date (Format: (dd/mm/yyyy)) </li>
                </ol>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card >
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.type) {
    errors.type = 'Type field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'loaderatt', // a unique identifier for this form
})(translate('global')(HorizontalForm));
