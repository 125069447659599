/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Range from './components/rangeSalary';
import Disbursement from './components/disbursementSalary';
import PaymentOrgLevel from './components/salarylevelorg/salarylevel';
import ComponentAverage from './components/componentAverage';
import DetailPaymentOrg from './components/salarylevelorg/detailsalarylevel';
import DetailSection from './components/salarylevelorg/detailsection';
import renderSelectField from '../../../../shared/components/form/Select';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faDollarSign, faPercentage, faPrint, faUser } from '@fortawesome/fontawesome-free-solid';
import Spinner from 'react-bootstrap/Spinner';
import Printer from 'react-pdf-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class PayrollDashboard extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    currentDate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('LBL.EFFECTIVE_DATE') },
      { 3: t('LBL.TYPE') },
      { 4: t('FRM.FUNCTION') },
    ];

    this.state = {
      token: '',
      apiws: '',
      arrOffice: [],
      arrCompGroup: [],
      arrYear: [],
      all: true,
      detailorg: false,
      year: '',
      month: '',
      office: '',
      compGroup: null,
      org_id: '',
      displaySpinner: 'none',
      displaySave: '',
    };
    this.getOffice = this.getOffice.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getCalculation = this.getCalculation.bind(this);
    this.getDetailSalaryLevel = this.getDetailSalaryLevel.bind(this);
    this.getDetailSection = this.getDetailSection.bind(this);
    this.setYear = this.setYear.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setOffice = this.setOffice.bind(this);
    this.setCompGroup = this.setCompGroup.bind(this);
    this.getCompGroup = this.getCompGroup.bind(this);
    this.getMaxPyp = this.getMaxPyp.bind(this);
    this.download = this.download.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getOffice();
      this.getYear();
      this.getMaxPyp();
      this.getCompGroup();
    });
  };

  getCompGroup() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/paycomponentgroup/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      const array = [];
      if (dataResponse.length === 0) {
        this.setState({ arrCompGroup: array });
      } else {
        dataResponse.map(obj => (
          array.push({ value: obj.pyg_id, label: obj.component_group })
        ));
        this.setState({ arrCompGroup: array });
      }
    });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getMaxPyp() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = { year: this.state.year, month: this.state.month, off_id: this.state.office };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/chart/getmaxpyp`,
      formBody,
      config,
    ).then((res) => {
      if (res.data.period !== null) {
        const rpv = (res.data.period).toString();
        const yea = rpv.substring(0, 4);
        const mnt = rpv.substring(4);
        this.props.initialize({ period_month: mnt, year: yea });
        this.setYear({ value: yea });
        this.setMonth({ value: mnt });
      }
    });
  }

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getallitem`,
      '',
      config,
    ).then((res) => {
      const off = res.data.data;
      const array = [];
      if (off.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        off.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    });
  }

  getDetailSalaryLevel(detailmenu, allmenu, year, month, off, detailorg) {
    const yea = (typeof year === 'undefined') ? '' : year;
    const mont = (typeof month === 'undefined') ? '' : month;
    const off_id = (typeof off === 'undefined') ? '' : off;
    setTimeout(() => {
      this.setState({
        all: allmenu, detailorg: detailmenu, year: yea, month: mont, office: off_id, detailsection: detailorg,
      });
    }, 200);
  }

  // eslint-disable-next-line class-methods-use-this
  getDetailSection(org) {
    this.setState({ org_id: org, detailsection: true, detailorg: false });
  }

  setYear(event) {
    const val = (typeof event.value === 'undefined') ? '' : event.value;
    this.setState({ year: val });
    setTimeout(() => {
      this.getCalculation();
    }, 300);
  }

  setMonth(event) {
    const val = (typeof event.value === 'undefined') ? '' : event.value;
    this.setState({ month: val });
    setTimeout(() => {
      this.getCalculation();
    }, 300);
  }

  setOffice(event) {
    const val = (typeof event.value === 'undefined') ? '' : event.value;
    this.setState({ office: val });
    setTimeout(() => {
      this.getCalculation();
    }, 300);
  }

  setCompGroup(event) {
    const val = (typeof event.value === 'undefined') ? '' : event.value;
    this.setState({ compGroup: val });
    console.log(val);
    setTimeout(() => {
      this.getCalculation();
    }, 300);
  }

  download() {
    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    const input = document.getElementById('payrolldashboard');
    window.scrollTo(0, 0);
    html2canvas(input, { scale: '1' })
      .then((canvas) => {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('p', 'pt', 'a4');
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const hratio = canvas.height / canvas.width;
        const widt = (pdf.internal.pageSize.width);
        const height = (hratio * widt);
        pdf.addImage(imgData, 'JPEG', 30, 30, (widt - 45), (height - 45));
        pdf.save('Payroll Dashboard');
        this.setState({
          displaySave: '',
          displaySpinner: 'none',
        });
      });
  }

  getCalculation() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      year: this.state.year,
      month: this.state.month,
      off_id: this.state.office,
      comp_group_id: this.state.compGroup ?? this.state.arrCompGroup[0]?.value ?? '',
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/chart/getcalculationpayroll`,
      formBody,
      config,
    ).then((res) => {
      const rpv = res.data.data;
      if (typeof rpv !== 'undefined' && rpv.length !== 0) {
        const ts = new Intl.NumberFormat('de-DE').format(Math.round(rpv.total_salary[0].disbursement_salary));
        const avg = new Intl.NumberFormat('de-DE').format(Math.round(rpv.average_salary));
        const tax = new Intl.NumberFormat('de-DE').format(Math.round(rpv.total_tax_paid[0].total_tax_paid));

        this.setState({ total_emp: rpv.total_employee });
        this.setState({ total_salary: ts });
        this.setState({ avg_salary: avg });
        this.setState({ total_tax: tax });
      }
    });
  }

  render() {
    const { t, currentDate } = this.props;
    const { displaySave, displaySpinner } = this.state;
    return (
      <Container className='dashboard'>
        <div style={{ textAlign: 'right' }}>
          <Button
            color='primary'
            style={{ display: displaySave, zIndex: '99' }}
            onClick={this.download}
          >
            <FontAwesomeIcon icon={faPrint} />
            {t('LBL.PRINT')} PDF
          </Button>
          <Button color='primary' style={{ display: displaySpinner, marginRight: '15px' }}>
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ marginRight: '6px' }}
            />
            {t('FRM.LOADING')}
          </Button>
        </div>
        {this.state.all && (
          <div style={{ padding: '15px' }}>
            <Printer>
              <div id='payrolldashboard'>
                <Card>
                  <Row style={{ marginTop: '65px' }}>
                    <Col md={12} lg={12} xs={12} className='text-center'>
                      <h4 className='title-dashboard-report'>
                        {t('LBL.PAYROLL')} {t('LBL.DASHBOARD')} {currentDate}
                      </h4>
                    </Col>
                  </Row>
                  <div className='dashboard__chart chart_border'>
                    <div className='chart-title'>
                      <div className='d-flex align-items-center' />
                    </div>
                    <div className='row justify-content-md-center'>
                      <div className='col-md-2'>
                        <Field
                          name='year'
                          component={renderSelectField}
                          placeholder={t('LBL.YEAR')}
                          options={this.state.arrYear}
                          onChange={this.setYear}
                        />
                      </div>
                      <div className='col-md-2'>
                        <Field
                          name='period_month'
                          component={renderSelectField}
                          options={[
                            { value: '01', label: `${t('LBL.JANUARY')}` },
                            { value: '02', label: `${t('LBL.FEBRUARY')}` },
                            { value: '03', label: `${t('LBL.MARCH')}` },
                            { value: '04', label: `${t('LBL.APRIL')}` },
                            { value: '05', label: `${t('LBL.MAY')}` },
                            { value: '06', label: `${t('LBL.JUNE')}` },
                            { value: '07', label: `${t('LBL.JULI')}` },
                            { value: '08', label: `${t('LBL.AUGUST')}` },
                            { value: '09', label: `${t('LBL.SEPTEMBER')}` },
                            { value: '10', label: `${t('LBL.OCTOBER')}` },
                            { value: '11', label: `${t('LBL.NOVEMBER')}` },
                            { value: '12', label: `${t('LBL.DESEMBER')}` },
                          ]}
                          placeholder={t('LBL.MONTH')}
                          onChange={this.setMonth}
                        />
                      </div>
                      <div className='col-md-2'>
                        <Field
                          name='office'
                          component={renderSelectField}
                          placeholder={t('LBL.OFFICE')}
                          options={this.state.arrOffice}
                        />
                      </div>
                      <div className='col-md-2'>
                        <Field
                          name='component'
                          component={renderSelectField}
                          placeholder={t('LBL.GROUP_COMPONENT')}
                          options={this.state.arrCompGroup}
                          input={{
                            onChange: this.setCompGroup,
                            value: this.state.compGroup ? this.state.compGroup : this.state.arrCompGroup[0]?.value,
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    <div style={{ padding: '15px' }}>
                      <Row>
                        <Col md={3} lg={3} sm={6} xs={12}>
                          <div className='panel-info-payroll'>
                            <div className='header-info'>
                              {t('LBL.TOTAL')} {t('LBL.EMPLOYEE')}
                            </div>
                            <div className='body-info'>
                              <div className='d-flex'>
                                <div className='w-100'>
                                  <h3 className='value-info'>
                                    {this.state.total_emp}
                                  </h3>
                                </div>
                                <div>
                                  <div className='icon-info'>
                                    <FontAwesomeIcon icon={faUser} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3} lg={3} sm={6} xs={12}>
                          <div className='panel-info-payroll'>
                            <div className='header-info'>
                              {t('LBL.TOTAL')} {t('LBL.SALARY')}
                            </div>
                            <div className='body-info'>
                              <div className='d-flex'>
                                <div className='w-100'>
                                  <h3 className='value-info'>
                                    {this.state.total_salary}
                                  </h3>
                                </div>
                                <div>
                                  <div className='icon-info'>
                                    <FontAwesomeIcon icon={faDollarSign} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3} lg={3} sm={6} xs={12}>
                          <div className='panel-info-payroll'>
                            <div className='header-info'>
                              {t('LBL.AVERAGE_SALARY')}
                            </div>
                            <div className='body-info'>
                              <div className='d-flex'>
                                <div className='w-100'>
                                  <h3 className='value-info'>
                                    {this.state.avg_salary}
                                  </h3>
                                </div>
                                <div>
                                  <div className='icon-info'>
                                    <FontAwesomeIcon icon={faCoins} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3} lg={3} sm={6} xs={12}>
                          <div className='panel-info-payroll'>
                            <div className='header-info'>
                              {t('LBL.TOTAL')} {t('LBL.TAX')}
                            </div>
                            <div className='body-info'>
                              <div className='d-flex'>
                                <div className='w-100'>
                                  <h3 className='value-info'>
                                    {this.state.total_tax}
                                  </h3>
                                </div>
                                <div>
                                  <div className='icon-info'>
                                    <FontAwesomeIcon icon={faPercentage} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
                <Row>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <Disbursement
                      year={this.state.year}
                      month={this.state.month}
                      office={this.state.office}
                    />
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <Range
                      year={this.state.year}
                      month={this.state.month}
                      office={this.state.office}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <PaymentOrgLevel
                      getmenu={this.getDetailSalaryLevel}
                      year={this.state.year}
                      month={this.state.month}
                      office={this.state.office}
                    />
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <ComponentAverage
                      year={this.state.year}
                      month={this.state.month}
                      office={this.state.office}
                    />
                  </Col>
                </Row>
              </div>
            </Printer>
          </div>
        )}
        {
          this.state.detailorg && (
            <DetailPaymentOrg
              getmenu={this.getDetailSalaryLevel}
              getdetail={this.getDetailSection}
              year={this.state.year}
              month={this.state.month}
              office={this.state.office}
            />
          )
        }
        {
          this.state.detailsection && (
            <DetailSection
              getmenu={this.getDetailSalaryLevel}
              year={this.state.year}
              month={this.state.month}
              office={this.state.office}
              org_id={this.state.org_id}
            />
          )
        }
      </Container>
    );
  }
}


export default reduxForm({
  destroyOnUnmount: false,
  form: 'FormEmployeePeriode',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(PayrollDashboard));
