/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfilePanel from './components/ProfilePanel';
import TaskList from './components/TaskList';
import { deleteCryptoTableData } from '../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../shared/prop-types/TablesProps';
import ShortCut from './components/MiniShortcut_new';
import SelfService from './components/SelfService';
import EmployeeAttdStatus from './components/EmpAttendanceStat';
import EmployeeJobLevel from './components/EmpJobLevel';
import InfoLeaveBalance from './components/infoLeaveBalance';
import SliderPanelHome from './components/SliderPanel';
import NewsUpdate from './components/NewsUpdatePanel';
import LoginActivity from './components/loginActivity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import i18next from 'i18next';
import moment from 'moment';

import commonEn from '../../../translations/en/common.json';
import commonDe from '../../../translations/de/common.json';
import globalId from '../../../translations/id/global_lang.json';
import globalEn from '../../../translations/en/global_lang.json';
import globalCh from '../../../translations/ch/global_lang.json';
import Cookies from 'universal-cookie';

class CryptoDashboard extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    // location: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      date: '',
      package: '',
      expired: '',
      loaded: 0,
    };

    this.getCompleteDay = this.getCompleteDay.bind(this);
  }

  componentDidMount = () => {
    this.getCompleteDay();
    this.getAccountPackage();
    this.setState((state) => ({ loaded: state.loaded + 1 }));
  };

  componentWillReceiveProps() {
    this.getCompleteDay();
  }

  onDeleteCryptoTableData = (index, e) => {
    e.preventDefault();
    const arrayCopy = [...this.props.cryptoTable];
    arrayCopy.splice(index, 1);
    this.props.dispatch(deleteCryptoTableData(arrayCopy));
  };

  getCompleteDay() {
    const { t } = this.props;
    const days = [
      `${t('LBL.SUNDAY')}`,
      `${t('LBL.MONDAY')}`,
      `${t('LBL.TUESDAY')}`,
      `${t('LBL.WEDNESDAY')}`,
      `${t('LBL.THURSDAY')}`,
      `${t('LBL.FRIDAY')}`,
      `${t('LBL.SATURDAY')}`,
    ];
    const monthNames = [
      `${t('LBL.JANUARY')}`,
      `${t('LBL.FEBRUARY')}`,
      `${t('LBL.MARCH')}`,
      `${t('LBL.APRIL')}`,
      `${t('LBL.MAY')}`,
      `${t('LBL.JUNE')}`,
      `${t('LBL.JULI')}`,
      `${t('LBL.AUGUST')}`,
      `${t('LBL.SEPTEMBER')}`,
      `${t('LBL.OCTOBER')}`,
      `${t('LBL.NOVEMBER')}`,
      `${t('LBL.DESEMBER')}`,
    ];

    const d = new Date();
    const dayName = `${days[d.getDay()]},   ${moment(d).format(
      'MMMM Do yyyy',
    )}`;
    this.setState({ date: dayName });
  }

  getAccountPackage() {
    const member = ls.get('membership');
    this.setState({
      package: member.package,
      expired: moment(member.plan_end).format('MMMM Do, yyyy'),
    });
  }

  render() {
    const { t } = this.props;
    const hour = new Date().getHours();
    let greeting;
    if (hour >= 5 && hour <= 10) {
      greeting = t('LBL.GOOD_MORNING');
    } else if (hour >= 11 && hour <= 14) {
      greeting = t('LBL.GOOD_AFTERNOON');
    } else if (hour >= 14 && hour <= 18) {
      greeting = t('LBL.GOOD_EVENING');
    } else if (hour >= 18 || hour < 5) {
      greeting = t('LBL.GOOD_NIGHT');
    }

    return (
      <div className="container-fluid home-fluid">
        <div className="home-header">
          <Container>
            <Row>
              <Col md={3} lg={3} sm={12} xs={12}>
                <div className="d-flex">
                  <div className="w-100 welcome-screen">
                    <h4>
                      <b>{greeting}</b>, {t('LBL.WELCOME_BACK')}!
                    </h4>
                    <p>{this.state.date}</p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <SelfService />
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <div className="company-status">
                  <div className="d-flex">
                    <div className="w-100 item-info">
                      <div className="d-flex">
                        <div className="icon-package gold">
                          <FontAwesomeIcon icon={faCrown} />
                        </div>
                        <div className="package-title">
                          <h4>
                            {t('LBL.PACKAGE')} {this.state.package}{' '}
                          </h4>
                          <p>
                            {t('LBL.EXPIRED_ON')} {this.state.expired}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="dashboard" style={{ marginTop: '-70px' }}>
          <Row>
            <Col md={3} lg={3} sm={12} xs={12}>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <InfoLeaveBalance location={this.props.location} />
                </Col>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <LoginActivity />
                </Col>
              </Row>
            </Col>
            <Col md={6} lg={6} sm={12} xs={12} style={{ marginTop: '10px' }}>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <TaskList />
                </Col>
                {/* <Col md={12} lg={12} sm={12} xs={12}>
                  <SliderPanelHome />
                </Col> */}
                <Col md={12} lg={12} sm={12} xs={12}>
                  <NewsUpdate />
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6} sm={12} xs={12}>
                  <EmployeeAttdStatus />
                </Col>
                <Col md={6} lg={6} sm={12} xs={12}>
                  <EmployeeJobLevel />
                </Col>
              </Row>
            </Col>
            <Col md={3} lg={3} sm={12} xs={12}>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <ShortCut />
                </Col>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <ProfilePanel />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect((state) => ({
  cryptoTable: state.cryptoTable.items,
}))(translate('global')(CryptoDashboard));
