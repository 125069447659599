import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { OrgChart } from 'd3-org-chart';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

const OrgChartComponent = (props, ref) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(4);

  // ref
  const d3Container = useRef(null);
  const chart = useRef(null);

  useEffect(() => {
    if (!chart.current) chart.current = new OrgChart();

    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
    function exitHandler() {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullScreen(false);
        chart.current
          .svgHeight(window.screen.height - 350)
          .fit()
          .render();
      } else {
        chart.current.svgHeight(window.screen.height + 47).render();
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart.current) chart.current = new OrgChart();

      chart.current
        .container(d3Container.current)
        .data(props.data)
        .nodeWidth((d) => 200)
        .nodeHeight((d) => 120)
        .onNodeClick((d, i, arr) => {
          console.log(d, 'Id of clicked node ');
        })
        .nodeContent(nodeContent)
        .svgHeight(window.screen.height - 350)
        // .nodeUpdateTransform(50, 100)
        .buttonContent(buttonContent)
        .render();

      const data = chart.current.getChartState();
      if (props.data.length > 0) {
        const nodeLevel = data.allNodes.filter((node) => {
          return node.depth <= currentLevel;
        });
        for (const node of nodeLevel) {
          chart.current.setExpanded(node.id);
        }
        chart.current.render();
        setTimeout(() => {
          chart.current.fit();
        }, 500);
        // chart.current.collapseAll().render();
      }
    }
  }, [props.data, d3Container.current]);

  const nodeContent = useCallback((d, i, arr, state) => {
    const component = (
      <div
        style={{
          paddingTop: 30,
          backgroundColor: 'none',
          marginLeft: 1,
          height: d.height,
          overflow: 'visible',
        }}
      >
        <div
          style={{
            height: d?.data?.position ? d.height - 32 : d.height - 60,
            paddingTop: 0,
            borderRadius: 17,
            backgroundColor: 'white',
            border: '1px solid #D8D6D6',
          }}
        >
          {d.data.photo && (
            <img
              src={d.data.photo}
              style={{
                marginTop: -20,
                marginLeft: d.width / 2 - 20,
                borderRadius: 20,
                width: 40,
                height: 40,
                objectFit: 'cover',
                objectPosition: '50% 0px',
                mixBlendMode: 'normal',
                scale: 5,
              }}
            />
          )}

          {/* top line */}
          <div
            style={{
              marginTop: d?.data?.position && !d.data.employees ? -20 : -1,
              backgroundColor: '#057AD7',
              height: 12,
              borderTopLeftRadius: 17,
              borderTopRightRadius: 17,
              border: '1px solid #D8D6D6',
            }}
          ></div>

          {/* emp name and position */}
          <div
            style={{
              padding: 20,
              paddingTop: d?.data?.position ? 15 : 15,
              textAlign: 'center',
            }}
          >
            {d?.data?.employees ? (
              // when grouping employee
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  position: 'relative',
                  left: -(
                    (d.data.employees.length > 5
                      ? 8
                      : d.data.employees.length) * 10
                  ),
                  top: 45,
                }}
              >
                {d.data.employees.map((emp, index) => {
                  return (
                    <>
                      {index < 5 && (
                        <img
                          id={emp.id}
                          alt={emp.employee_name}
                          title={emp.employee_name}
                          src={emp.photo}
                          style={{
                            marginTop: -30,
                            marginLeft: d.width / 2 - 30,
                            borderRadius: 100,
                            width: 30,
                            height: 30,
                            position: 'absolute',
                            top: 0,
                            left: index * 30,
                          }}
                        />
                      )}
                    </>
                  );
                })}

                {/* limit employee same position to 5 and showing more icon */}
                {d.data.employees.length > 5 && (
                  <div
                    style={{
                      background: '#0e62d7',
                      marginTop: -30,
                      marginLeft: d.width / 2 - 30,
                      borderRadius: 100,
                      width: 30,
                      height: 30,
                      position: 'absolute',
                      top: 0,
                      left: 5 * 30,
                      fontSize: '1.2em',
                      color: '#fff',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      lineHeight: 2,
                    }}
                  >
                    <span>+{d.data.employees.length - 5}</span>
                  </div>
                )}
              </div>
            ) : (
              // when single employee
              <div
                style={{
                  color: '#3F3F3F',
                  fontSize: 12,
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {d?.data.employee_name ?? '-'}
              </div>
            )}

            {/* emp position */}
            {d?.data?.position && (
              <div
                style={{
                  color: '#404040',
                  fontSize: 10,
                  marginTop: !d.data.employees ? 4 : -5,
                }}
              >
                {d.data.position}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(component);
  }, []);

  const buttonContent = useCallback(({ node, state }) => {
    const component = (
      <div
        style={{
          backgroundColor: '#737373',
          color: '#fff',
          borderRadius: 15,
          height: 25,
          minWidth: 25,
          padding: 8,
          fontSize: 10,
          margin: 'auto auto',
        }}
      >
        <span>
          {node.data._directSubordinates}{' '}
          {node.children ? (
            <i className="fas fa-angle-up"></i>
          ) : (
            <i className="fas fa-angle-down"></i>
          )}
        </span>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(component);
  }, []);

  return (
    <Row>
      <Col md={12}>
        <div ref={d3Container} />
        <div className="chart-action-buttons">
          <span className="chart-btn-action-group">
            <button
              id="btnCollapse"
              onClick={() => {
                const data = chart.current.getChartState();
                const nodeLevel = data.allNodes.filter((node) => {
                  return node.depth == currentLevel;
                });
                setCurrentLevel(currentLevel <= 0 ? 0 : currentLevel - 1);
                for (const node of nodeLevel) {
                  chart.current.setExpanded(node.id, false);
                }
                chart.current.render();
                setTimeout(() => {
                  chart.current.fit();
                }, 500);
              }}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-chevron-up"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnCollapse'}>
              Collapse One Level
            </UncontrolledTooltip>
            <br />
          </span>

          <span>
            <button
              id="btnExpand"
              onClick={() => {
                const nextLevel = currentLevel + 1;
                const data = chart.current.getChartState();
                const nodeLevel = data.allNodes.filter((node) => {
                  return node.depth == nextLevel;
                });
                // console.log(nodeLevel, `node-${nextLevel}`);
                for (const node of nodeLevel) {
                  chart.current.setExpanded(node.id);
                }
                chart.current.render();
                if (nodeLevel.length > 0) {
                  setCurrentLevel(nextLevel);
                }

                setTimeout(() => {
                  chart.current.fit();
                }, 500);
              }}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-chevron-down"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnExpand'}>
              Expand One Level
            </UncontrolledTooltip>
            <br />
          </span>

          {/* <span className="chart-btn-action-group">
            <button
              id="btnCollapseAll"
              onClick={() => {
                chart.current.collapseAll();
                setTimeout(() => {
                  chart.current.fit();
                }, 500);
              }}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-angle-double-up"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnCollapseAll'}>
              Collapse All
            </UncontrolledTooltip>

            <button
              id="btnExpandAll"
              onClick={() => {
                chart.current.expandAll();
                setTimeout(() => {
                  chart.current.fit();
                }, 500);
              }}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-angle-double-down"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnExpandAll'}>
              Expand All
            </UncontrolledTooltip>
            <br />
          </span>

          <span className="chart-btn-action-group">
            <button
              id="btnZoomIn"
              onClick={() => chart.current.zoomIn()}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-plus"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnZoomIn'}>
              Zoom In
            </UncontrolledTooltip>
            <br />
          </span> */}

          <span>
            <button
              id="btnZoomOut"
              onClick={() => chart.current.zoomOut()}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-minus"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnZoomOut'}>
              Zoom Out
            </UncontrolledTooltip>
            <br />
          </span>

          <span>
            <button
              id="btnFit"
              onClick={() => {
                chart.current.fit();
              }}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-compress-arrows-alt"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnFit'}>
              Fit Chart
            </UncontrolledTooltip>
            <br />
          </span>

          <span>
            <button
              id="btnExport"
              onClick={() => chart.current.exportImg({ full: true, scale: 16 })}
              className="chart-btn chart-btn-action-button waves-effect waves-light"
            >
              <i className="fas fa-cloud-download-alt"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnExport'}>
              Export as PNG
            </UncontrolledTooltip>
            <br />
          </span>

          <span>
            <button
              id="btnFullscreen"
              onClick={() => {
                if (!isFullScreen) {
                  chart.current.fullscreen(
                    d3Container.current.parentElement.parentElement
                      .parentElement,
                  );
                  chart.current.svgHeight(window.screen.height + 47);
                  setTimeout(() => {
                    chart.current.fit();
                  }, 300);
                  setFullScreen(true);
                } else {
                  setFullScreen(false);
                  chart.current.svgHeight(window.screen.height - 350);
                  document.webkitExitFullscreen();
                  setTimeout(() => {
                    chart.current.fit();
                  }, 300);
                }
              }}
              className="chart-btn chart-btn-action-button waves-effect waves-light {}"
            >
              <i className="fas fa-expand"></i>
            </button>
            <UncontrolledTooltip placement={'left'} target={'btnFullscreen'}>
              Full Screen
            </UncontrolledTooltip>
            <br />
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default OrgChartComponent;
