/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable operator-assignment */
import React, { PureComponent } from 'react';
import { Card, Button } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
// import { ResponsiveContainer } from 'recharts';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};

class MaritalChart extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      // idUser: '',
      token: '',
      // intervalId: null,
    };
    this.getDetail = this.getDetail.bind(this);
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    // this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getNmberEmployees();
    });
  }

  componentWillUnmount() {
    // clearInterval(this.state.intervalId);
  }

  getNmberEmployees() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/employee/getmaritalstatuspercentage/`,
      config,
    ).then((response) => {
      const labels = [];
      const datas = [];
      const colour = [];
      for (let i = 0; i < response.data.data.length; i += 1) {
        colour.push(response.data.data[i].fill);
        labels.push(response.data.data[i].name);
        datas.push(response.data.data[i].value);
      }
      const initialState = {
        labels: labels,
        datasets: [
          {
            label: 'MaritalStatus',
            backgroundColor: colour,
            // borderColor: '#ff0066',
            borderWidth: 1,
            hoverBackgroundColor: colour,
            // hoverBorderColor: '#cc0052',
            data: datas,
          },
        ],
      };
      this.setState({ data: initialState });
    });
  }

  getDetail() {
    const detailmarital = true;
    const allmenu = false;
    setTimeout(() => {
    }, 200); this.props.getmenu(detailmarital, allmenu);
  }

  render() {
    const { t } = this.props;

    return (
      <Card>
        <div className="dashboard__chart chart_border">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.MARITAL_STATUS')}
              </div>
              <div className="w-100" style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => this.getDetail()}
                >{t('LBL.DETAIL')}
                </Button>
              </div>
              {/* <div className="other-chart-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle className="icon" outline>
                        <FontAwesomeIcon icon={faBars} />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown__menu">
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Another Action</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div> */}
            </div>
          </div>
          <div className="chart-body">
            <Bar data={this.state.data} options={options} />
          </div>
        </div>
      </Card>
    );
  }
}

export default translate('global')(MaritalChart);
