/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import ComponentPage from './Component';
import THRSettingPage from './THRSetting';
// import BpjsSettingPage from './BpjsSetting';
import GroupComponentPage from './GroupComponent/newindex';
import ProrateSetting from './ProrateSetting';
import BpjsTypePage from './BpjsType';
import BpjsRate from './BpjsType/bpjsrate';
import CountryCodePage from './CountryCode';
import FormatReport from './Formatreport/index';
import InformationFormat from './Formatreport/information';
import DetailFormat from './Formatreport/detail';
import ComponentFormat from './Formatreport/component';
import Payatt from './Payattribut';
import Payump from './Payump';
import BankTransfer from './BankTransfer/index';
import BankAttribute from './BankTransfer/attribute';
import PayslipSetting from './PayslipSetting';
import ls from 'local-storage';
import axios from 'axios';
import PropTypes from 'prop-types';
import Authenticator from '../../Authenticator';

const HeaderIcon = '/img/preference_icon/icon-payroll.png';

class PayrollSettingTabPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeTab: '1',
      page: '1',
      prp_id: '',
      prc_id: '',
      rtk_id: '',
      pbt_id: '',
      income_column: '',
      menu: [],
      token: '',
      apiws: '',
      idUser: '',
      men_id: '',
      fga_id: '',
      validateToken: false,
      componentDataUpdated: false,
    };
    this.infoFunction = this.infoFunction.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
  }
  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getReference();
    });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({
        men_id: location.state.men_id,
        fga_id: location.state.fga_id,
      });
    }
    this.checkAuth();
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(`${apiws}/menu/getreference/`, formBody, config).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map((obj) =>
          array.push({ men_id: obj.men_id, label: obj.lbl_code }),
        );
        this.setState({
          menu: array,
        });
        console.log(this.state.menu);
      }
    });
  }

  getpayreport = (id) => {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/payreport/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          this.setState({ income_column: getResponseData[0].income_column });
        } else {
          alert('error');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };
  getpayreportcolumn = (id) => {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/payreportcolumns/getAllItemById/${id}`,
        '',
        config,
      )
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          this.setState({ pyt_id: getResponseData[0].pyt_id });
        } else {
          alert('error');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };

  infoFunction(id) {
    this.setState({ page: '2' });
    this.setState({ prp_id: id });
    setTimeout(() => {
      this.getpayreport(id);
    });
  }

  detailFunction = (id) => {
    this.setState({ prp_id: id });
    this.setState({ page: '3' });
  };

  detailBankAttribute = (id) => {
    this.setState({ rtk_id: id });
    this.setState({ page: '2' });
  };

  detailBpjsRate = (id) => {
    this.setState({ pbt_id: id });
    this.setState({ page: '2' });
  };

  formatFunction = () => {
    this.setState({ page: '1' });
  };

  componentFunction = (id) => {
    this.setState({ page: '4' });
    this.setState({ prc_id: id });
    setTimeout(() => {
      this.getpayreportcolumn(id);
    });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/token/isOtp`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.otp) {
            this.setState({ validateToken: true });
          }
        }
      })
      .catch((error) => {
        // showNotifications('Fail', 'Delete Failed', 'danger');
      });
  }

  render() {
    const { t } = this.props;
    const { page, validateToken } = this.state;
    if (validateToken === false) {
      return <Authenticator checkAuth={this.checkAuth} />;
    }
    return (
      <Container>
        <Card>
          <CardBody className="p0">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="payroll" />
                  <h3 className="page-title">{t('MEN.PAYROLL_SETTING')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('LBL.SETTINGS_YOUR_PAYROLL')}
                  </h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  {this.state.menu.map((data, index) =>
                    data.label === 'LBL.BPJS' ||
                    data.label === 'LBL.FORMAT_REPORT' ||
                    data.label === 'BANK_TRANSFER' ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              this.state.activeTab === (index + 1).toString(),
                          })}
                          onClick={() => {
                            this.toggle((index + 1).toString());
                            this.formatFunction();
                          }}
                        >
                          <span className="navlink_tab-title">
                            {t(data.label)}
                          </span>
                        </NavLink>
                      </NavItem>
                    ) : (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              this.state.activeTab === (index + 1).toString(),
                          })}
                          onClick={() => {
                            this.toggle((index + 1).toString());
                          }}
                        >
                          <span className="navlink_tab-title">
                            {t(data.label)}
                          </span>
                        </NavLink>
                      </NavItem>
                    ),
                  )}
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {t('LBL.PREFERENCES')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {t('LBL.PRORATE')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      {t('LBL.COMPONENT')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      {t('LBL.GROUP_COMPONENT')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      {t('LBL.THR')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '7' })}
                      onClick={() => {
                        this.toggle('7');
                        this.formatFunction();
                      }}
                    >
                      {t('LBL.BPJS')}
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '6' })}
                        onClick={() => {
                                              this.toggle('6');
                                          }}
                      >
                        {t('LBL.BPJS')} {t('MEN.SETTING')}
                      </NavLink>
                    </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '14' })}
                      onClick={() => {
                        this.toggle('14');
                      }}
                    >
                      {t('LBL.PAY_UMP')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '8' })}
                      onClick={() => {
                        this.toggle('8');
                      }}
                    >
                      {t('LBL.COUNTRY_CODE')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '9' })}
                      onClick={() => {
                        this.toggle('9');
                      }}
                    >
                      {t('LBL.TAX_TARIFF')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '10' })}
                      onClick={() => {
                        this.toggle('10');
                      }}
                    >
                      {t('LBL.PTKP')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '11' })}
                      onClick={() => {
                        this.toggle('11');
                      }}
                    >
                      {t('LBL.TAX_CLASS')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '12' })}
                      onClick={() => {
                        this.toggle('12');
                        this.formatFunction();
                      }}
                    >
                      {t('LBL.FORMAT_REPORT')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '13' })}
                      onClick={() => {
                        this.toggle('13');
                      }}
                    >
                      {t('LBL.PAY_ATTRIBUTE')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '15' })}
                      onClick={() => {
                        this.toggle('15');
                        this.formatFunction();
                      }}
                    >
                      {t('LBL.BANK_TRANSFER')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '16' })}
                      onClick={() => {
                        this.toggle('16');
                      }}
                    >
                      {t('LBL.PAYSLIP_SETTING')}
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.menu.map(
                    (data, index) =>
                      (data.label === 'LBL.PRORATE' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <ProrateSetting
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                            />
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.COMPONENT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <ComponentPage
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                              onDataChanged={() => {
                                const { componentDataUpdated } = this.state;
                                // set trigger to load a new data
                                if (!componentDataUpdated) {
                                  this.setState({ componentDataUpdated: true });
                                  // revert to false
                                  setTimeout(() => {
                                    this.setState({
                                      componentDataUpdated: false,
                                    });
                                  }, 1000);
                                }
                              }}
                            />
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.GROUP_COMPONENT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <GroupComponentPage
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                              isDataUpdated={this.state.componentDataUpdated}
                            />
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.THR' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <THRSettingPage
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                            />
                          </Col>
                        </TabPane>
                        /* <TabPane tabId="6">
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <BpjsSettingPage initialValues={{ bpjs_rate: [{}] }} />
                          </Col>
                        </TabPane> */
                      )) ||
                      (data.label === 'LBL.BPJS' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            {page === '1' && (
                              <BpjsTypePage
                                formatFunction={this.formatFunction}
                                detailFunction={this.detailBpjsRate}
                                men_id={data.men_id}
                                fga_id={this.state.fga_id}
                              />
                            )}
                            {page === '2' && (
                              <BpjsRate
                                formatFunction={this.formatFunction}
                                pbt_id={this.state.pbt_id}
                              />
                            )}
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.COUNTRY_CODE' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <CountryCodePage
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                            />
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.FORMAT_REPORT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            {page === '1' && (
                              <FormatReport
                                formatFunction={this.formatFunction}
                                infoFunction={this.infoFunction}
                                detailFunction={this.detailFunction}
                                men_id={data.men_id}
                                fga_id={this.state.fga_id}
                              />
                            )}
                            {page === '2' && (
                              <InformationFormat
                                formatFunction={this.formatFunction}
                                infoFunction={this.infoFunction}
                                prp_id={this.state.prp_id}
                                income_column={this.state.income_column}
                                componentFunction={this.componentFunction}
                              />
                            )}
                            {page === '3' && (
                              <DetailFormat
                                formatFunction={this.formatFunction}
                                prp_id={this.state.prp_id}
                              />
                            )}
                            {page === '4' && (
                              <ComponentFormat
                                prp_id={this.state.prp_id}
                                prc_id={this.state.prc_id}
                                pyt_id={this.state.pyt_id}
                                infoFunction={this.infoFunction}
                              />
                            )}
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.PAY_ATTRIBUTE' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <Payatt
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                            />
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.PAY_UMP' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <Payump
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                            />
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.BANK_TRANSFER' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            {page === '1' && (
                              <BankTransfer
                                formatFunction={this.formatFunction}
                                detailFunction={this.detailBankAttribute}
                                men_id={data.men_id}
                                fga_id={this.state.fga_id}
                              />
                            )}
                            {page === '2' && (
                              <BankAttribute
                                formatFunction={this.formatFunction}
                                rtk_id={this.state.rtk_id}
                              />
                            )}
                          </Col>
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.PAYSLIP_SETTING' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <PayslipSetting
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                            />
                          </Col>
                        </TabPane>
                      )),
                  )}
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(PayrollSettingTabPage);
