/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
/* eslint-disable no-mixed-operators */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import ShiftPage from './Shift';
import GroupShiftPage from './GroupShift';
import AttendanceSettingPage from './AttendanceSetting';
import Component from './Component';
import AttendanceType from './AttendanceType';
import AttendanceDayOff from './AttendanceDayOff';
import AttendanceLeave from './AttendanceLeave';
// import AttendanceReconSetting from './AttendanceReconSetting';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import Geofence from './GeofenceArea/index';
import FormGeofence from './GeofenceArea/formGeofence';
import AttReason from './AttendanceReason/index';

const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class PayrollSettingTabPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  }
  constructor() {
    super();
    this.state = {
      activeTab: '1',
      menu: [],
      token: '',
      apiws: '',
      idUser: '',
      men_id: '',
      fga_id: '',
      page: '1',
      arr_shift: [],
      statusEdit: false,
    };

    this.editGeofence = this.editGeofence.bind(this);
    this.listGeofence = this.listGeofence.bind(this);
    this.getShift = this.getShift.bind(this);
  }

  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getReference(); this.getShift(); });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({ men_id: location.state.men_id, fga_id: location.state.fga_id });
    }
    console.log(this.state.location);
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/menu/getreference/`,
      formBody,
      config,
    ).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map(obj => (
          array.push({ men_id: obj.men_id, label: obj.lbl_code })
        ));
        this.setState({
          menu: array,
        });
      }
    });
  }

  getShift() {
    const formBody = '';
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/attshift/getAll/`;
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        this.setState({ arr_shift: response.data.data });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  editGeofence(id) {
    if (typeof id === 'number') {
      this.setState({ ogf_id: id, page: '2', statusEdit: true });
    } else {
      this.setState({ ogf_id: '', page: '2', statusEdit: false });
    }
  }

  listGeofence() {
    this.setState({ page: '1' });
  }

  render() {
    const { t } = this.props;
    // eslint-disable-next-line no-unused-vars
    return (
      <Container>
        <Card>
          <CardBody className="p0">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="attendance" />
                  <h3 className="page-title">{t('MEN.ATTENDANCE_SETTING')}</h3>
                  <h3 className="page-subhead subhead">{t('MEN.ATTENDANCE_SETTING')}</h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  {this.state.menu.map((data, index) => (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === (index + 1).toString() })}
                        onClick={() => {
                          this.toggle((index + 1).toString());
                        }}
                      >
                        <span className="navlink_tab-title">{t(data.label)}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {t('LBL.SHIFT')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {t('LBL.GROUP_SHIFT')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      {t('LBL.ATTENDANCE_SETTING')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      {t('LBL.COMPONENT')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      {t('LBL.ATTENDANCE_TYPE')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => {
                        this.toggle('6');
                      }}
                    >
                      {t('LBL.DAY_OFF_TITLE')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '7' })}
                      onClick={() => {
                        this.toggle('7');
                      }}
                    >
                      {t('MEN.LEAVE')}
                    </NavLink>
                  </NavItem> */}
                  { /* <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '7' })}
                        onClick={() => {
                          this.toggle('7');
                        }}
                      >
                        {t('LBL.RECON_SETTING')}
                      </NavLink>
                    </NavItem> */ }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.menu.map((data, index) => (
                    data.label === 'LBL.SHIFT' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <ShiftPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.GROUP_SHIFT' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <GroupShiftPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                          shift={this.state.arr_shift}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.ATTENDANCE_SETTING' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <AttendanceSettingPage
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.COMPONENT' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <Component
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.ATTENDANCE_TYPE' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <AttendanceType
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.DAY_OFF_TITLE' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <AttendanceDayOff

                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.LEAVE_TYPE' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <AttendanceLeave
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    ) ||
                    data.label === 'LBL.GEOFENCE_AREA' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        {
                          this.state.page === '1' && <Geofence
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                            editGeofence={this.editGeofence}
                          />
                        }
                        {
                          this.state.page === '2' &&
                          <FormGeofence
                            ogf_id={this.state.ogf_id}
                            edit={this.state.statusEdit}
                            listGeofence={this.listGeofence}
                          />
                        }
                      </TabPane>
                    ) ||
                    data.label === 'LBL.ATTENDANCE_DISPENSATION_REASON' &&
                    (
                      <TabPane tabId={(index + 1).toString()}>
                        <AttReason
                          men_id={data.men_id}
                          fga_id={this.state.fga_id}
                        />
                      </TabPane>
                    )
                    /* <TabPane tabId="7">
                      <AttendanceReconSetting />
                    </TabPane> */
                  ))}
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(PayrollSettingTabPage);
