import React, { useContext, useEffect, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Fill, Icon, Style, Text } from 'ol/style';
import { Point } from 'ol/geom'; // Import Point class
import Feature from 'ol/Feature'; // Import Feature class
import { fromLonLat } from 'ol/proj';
import FieldActivityBottom from './FieldActivityBottom';
import { ActivityContext } from './ActivityContext';

const FieldMap = ({
  getActivity = () => {},
  getAllActivity = () => {},
  setSelectedDate = () => {},
  dateHasActivity,
  selectedDate,
  lng,
  lat,
}) => {
  const [state, dispatch] = useContext(ActivityContext);
  const [zoom, setZoom] = useState(13);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    // Create a vector source and a vector layer for markers
    const vectorSource = new VectorSource({
      features: state?.activity.map((markerData, index) => {
        return new Feature({
          geometry: new Point(
            fromLonLat([markerData.location_lon, markerData.location_lat]),
          ),
          number: state?.activity?.length - index,
        });
      }),
    });

    // ...

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        // Icon style
        const iconStyle = new Style({
          image: new Icon({
            src: '/img/map-marker.svg',
          }),
        });

        // Text style
        const textStyle = new Style({
          text: new Text({
            text: feature.get('number').toString(),
            font: '12px Arial',
            offsetY: 0, // Adjust this value to change the vertical position of the number relative to the icon
            fill: new Fill({ color: '#fff' }),
            padding: [4, 4, 4, 4], // Add some padding to the background
          }),
        });

        // Use an array to combine both styles
        return [iconStyle, textStyle];
      },
    });

    // ...

    // Create the map
    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([lng, lat]),
        zoom: zoom,
      }),
    });

    // Event listener for marker click
    map.on('click', (evt) => {
      map.forEachFeatureAtPixel(evt.pixel, (feature) => {
        setSelectedMarker(feature.get('number'));
      });
    });

    // Clean up the map when the component unmounts
    return () => map.setTarget(null);
  }, [state]);

  return (
    <div className="map-con">
      <div id="map" className="map-container" />
      <FieldActivityBottom
        getActivity={getActivity}
        getAllActivity={getAllActivity}
        setSelectedDate={setSelectedDate}
        dateHasActivity={dateHasActivity}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default FieldMap;
