/* eslint-disable function-paren-newline */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// eslint-disable-next-line no-unused-vars
import SidebarCategory from './SidebarCategory';
// eslint-disable-next-line no-unused-vars
import ls from 'local-storage';
import { translate } from 'react-i18next';
import utils from '../../../../utils';
import { connect } from 'react-redux';

const uri = window.location.pathname.split('/');
const selectedMenu = { backgroundColor: '#0955b7' };

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      current_menu: '',
      tree_menu: [],
      lang: null,
      fga_id: ls.get('function_access')[0].fga_id ?? '',
    };
    // eslint-disable-next-line prefer-destructuring
    this.state.current_menu = uri[1];
    const { t } = this.props;
    this.state.lang = t;
    this.getMenu.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps) {
    const { t, sidebar } = this.props;
    if (this.state.lang !== t) {
      this.setState({
        lang: t,
      });
    }

    if (!utils.deepEqual(sidebar, nextProps.sidebar)) {
      this.setState({
        tree_menu: nextProps?.sidebar?.menuTree,
      });
    }
  }

  setLocalStorage(men_id) {
    ls.set('men_id', men_id);
    ls.set('fga_id', this.state.fga_id);
  }

  // eslint-disable-next-line no-unused-vars
  getMenu = (menu, dropdownItem, icon) => (
    // <UncontrolledDropdown style={selectedStyle} className="topbar__nav-dropdown">
    //   <DropdownToggle className="topbar__nav-dropdown-toggle">
    //     {menu} <ChevronDownIcon />
    //   </DropdownToggle>
    //   <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
    //     {dropdownItem}
    //   </DropdownMenu>
    // </UncontrolledDropdown>
    <ul className="sidebar__block">
      <SidebarCategory title={menu} icon={icon}>
        {dropdownItem}
      </SidebarCategory>
    </ul>
  );

  createMenu = (arrayMenu, parent) => {
    // const { t } = this.props;
    const result = [];
    for (let i = 0; i < arrayMenu.length; i += 1) {
      const setStyle =
        arrayMenu[i].menu.toLowerCase() === uri[1] ? selectedMenu : {};
      if (arrayMenu[i].submenu.length > 0 && arrayMenu[i].level < 2) {
        result.push(
          this.getMenu(
            this.state.lang(arrayMenu[i].lbl_code),
            this.createMenu(arrayMenu[i].submenu, 1),
            arrayMenu[i].icon,
            setStyle,
          ),
        );
      } else {
        // eslint-disable-next-line no-lonely-if
        if (parent === 0) {
          // eslint-disable-next-line function-paren-newline
          result.push(
            // <Link
            //   style={setStyle}
            //   className="topbar__nav-link"
            //   to={{ pathname: arrayMenu[i].to, state: { men_id: arrayMenu[i].id, fga_id: this.state.fga_id } }}
            // >
            //   {this.state.lang(arrayMenu[i].lbl_code)}
            // </Link>
            <SidebarLink
              title={this.state.lang(arrayMenu[i].lbl_code)}
              icon="home"
              route={arrayMenu[i].to}
              onClick={this.hideSidebar}
            />,
          );
        }
        if (parent === 1) {
          // eslint-disable-next-line function-paren-newline
          result.push(
            <SidebarLink
              title={this.state.lang(arrayMenu[i].lbl_code)}
              icon="home"
              route={arrayMenu[i].to}
              onClick={() => this.setLocalStorage(arrayMenu[i].id)}
            />,

            // <DropdownItem
            //   // className="topbar__link"
            //   tag={Link}
            //   to={{ pathname: arrayMenu[i].to, state: { men_id: arrayMenu[i].id, fga_id: this.state.fga_id } }}
            //   onClick={() => this.setLocalStorage(arrayMenu[i].id)}
            //   onContextMenu={() => this.setLocalStorage(arrayMenu[i].id)}
            // >{this.state.lang(arrayMenu[i].lbl_code)}
            // </DropdownItem>,
          );
        }
      }
    }
    return result;
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        {this.createMenu(this.state.tree_menu, 0)}
        {/* <ul className="sidebar__block">
          <SidebarLink title="Dashboard Default" icon="home" route="/dashboard_default" onClick={this.hideSidebar} />
          <SidebarLink
            title="Dashboard E-commerce"
            icon="store"
            route="/dashboard_e_commerce"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Dashboard Fitness"
            icon="heart-pulse"
            newLink
            route="/dashboard_fitness"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Dashboard Crypto"
            icon="rocket"
            newLink
            route="/dashboard_crypto"
            onClick={this.hideSidebar}
          />
          <SidebarCategory title="Layout" icon="layers">
            <button className="sidebar__link" onClick={this.props.changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button className="sidebar__link" onClick={this.props.changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="UI Elements" icon="diamond">
            <SidebarLink title="Alerts" route="/ui/alerts" onClick={this.hideSidebar} />
            <SidebarLink title="Buttons" route="/ui/buttons" onClick={this.hideSidebar} />
            <SidebarLink title="Carousel" route="/ui/carousel" onClick={this.hideSidebar} />
            <SidebarLink title="Collapse" route="/ui/collapse" onClick={this.hideSidebar} />
            <SidebarLink title="Grids" route="/ui/grids" onClick={this.hideSidebar} />
            <SidebarLink title="Modals" route="/ui/modals" onClick={this.hideSidebar} />
            <SidebarLink title="Notifications" route="/ui/notifications" onClick={this.hideSidebar} />
            <SidebarLink title="Panels" route="/ui/panels" onClick={this.hideSidebar} />
            <SidebarLink title="Progress Bars" route="/ui/progress_bars" onClick={this.hideSidebar} />
            <SidebarLink title="Range Sliders" route="/ui/range_sliders" onClick={this.hideSidebar} />
            <SidebarLink title="Tabs" route="/ui/tabs" onClick={this.hideSidebar} />
            <SidebarLink title="Timeline" route="/ui/timeline" onClick={this.hideSidebar} />
            <SidebarLink title="Tooltips & Popovers" route="/ui/tooltips" onClick={this.hideSidebar} />
            <SidebarLink title="Typography" route="/ui/typography" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarLink title="Mail Application" icon="envelope" route="/mail" onClick={this.hideSidebar} />
          <SidebarLink title="Chat Application" icon="bubble" route="/chat" onClick={this.hideSidebar} />
          <SidebarCategory title="Forms" icon="file-add">
            <SidebarLink title="Basic Form" route="/forms/basic_form" onClick={this.hideSidebar} />
            <SidebarLink title="Check Form Controls" route="/forms/check_form_controls" onClick={this.hideSidebar} />
            <SidebarLink title="File Upload" route="/forms/file_upload" onClick={this.hideSidebar} />
            <SidebarLink title="Floating Labels Form" route="/forms/floating_labels_form" onClick={this.hideSidebar} />
            <SidebarLink title="Form Dropzone" route="/forms/form_dropzone" onClick={this.hideSidebar} />
            <SidebarLink title="Form Layouts" route="/forms/form_layouts" onClick={this.hideSidebar} />
            <SidebarLink title="Form Picker" route="/forms/form_picker" onClick={this.hideSidebar} />
            <SidebarLink title="Form Validation" route="/forms/form_validation" onClick={this.hideSidebar} />
            <SidebarLink title="Mask Form" route="/forms/mask_form" onClick={this.hideSidebar} />
            <SidebarLink title="Material Form" route="/forms/material_form" onClick={this.hideSidebar} />
            <SidebarLink title="Wizard Form" route="/forms/wizard_form" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Tables" icon="list" isNew>
            <SidebarLink title="Basic tables" route="/tables/basic_tables" onClick={this.hideSidebar} />
            <SidebarLink title="Data table" route="/tables/data_table" onClick={this.hideSidebar} />
            <SidebarLink title="Editable table" route="/tables/editable_table" onClick={this.hideSidebar} />
            <SidebarLink title="Material table" newLink route="/tables/material_table" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Charts" icon="chart-bars">
            <SidebarLink title="ChartsJS" route="/charts/charts_js" onClick={this.hideSidebar} />
            <SidebarLink title="React-vis" route="/charts/react_vis" onClick={this.hideSidebar} />
            <SidebarLink title="Recharts" route="/charts/recharts" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Maps" icon="map">
            <SidebarLink title="Google map" route="/maps/google_map" onClick={this.hideSidebar} />
            <SidebarLink title="Vector map" route="/maps/vector_map" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Account" icon="user">
            <SidebarLink title="Email Confirmation" route="/account/email_confirmation" />
            <SidebarLink title="Lock Screen" route="/lock_screen" />
            <SidebarLink title="Log In" route="/log_in" />
            <SidebarLink title="Log In Photo" route="/log_in_photo" />
            <SidebarLink title="Profile" route="/account/profile" onClick={this.hideSidebar} />
            <SidebarLink title="Register" route="/register" />
            <SidebarLink title="Register Photo" route="/register_photo" />
          </SidebarCategory>
          <SidebarCategory title="E-commerce" icon="cart">
            <SidebarLink title="Cart" route="/e-commerce/cart" onClick={this.hideSidebar} />
            <SidebarLink title="Catalog" route="/e-commerce/catalog" onClick={this.hideSidebar} />
            <SidebarLink title="Orders List" route="/e-commerce/orders_list" onClick={this.hideSidebar} />
            <SidebarLink title="Payment" route="/e-commerce/payment" onClick={this.hideSidebar} />
            <SidebarLink title="Product Edit" route="/e-commerce/product_edit" onClick={this.hideSidebar} />
            <SidebarLink title="Product Page" route="/e-commerce/product_page" onClick={this.hideSidebar} />
            <SidebarLink title="Products List" route="/e-commerce/products_list" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Default Pages" icon="file-empty">
            <SidebarLink title="404" route="/404" />
            <SidebarLink title="Calendar" route="/default_pages/calendar" onClick={this.hideSidebar} />
            <SidebarLink title="FAQs" route="/default_pages/faq" onClick={this.hideSidebar} />
            <SidebarLink title="Gallery" route="/default_pages/gallery" onClick={this.hideSidebar} />
            <SidebarLink title="Invoice template" route="/default_pages/invoice_template" onClick={this.hideSidebar} />
            <SidebarLink title="Pricing Cards" route="/default_pages/pricing_cards" onClick={this.hideSidebar} />
            <SidebarLink title="Project Summary" route="/default_pages/project_summary" onClick={this.hideSidebar} />
            <SidebarLink title="Search Results" route="/default_pages/search_results" onClick={this.hideSidebar} />
            <SidebarLink title="Text Editor" route="/default_pages/text_editor" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Multilevel Menu " icon="menu">
            <SidebarLink title="Second Level Item" />
            <SidebarCategory title="Second Level Item">
              <SidebarLink title="Third Level Item" />
              <SidebarLink title="Third Level Item" />
            </SidebarCategory>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Log Out" icon="exit" route="/log_in" />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title="Documentation"
            icon="text-align-justify"
            route="/documentation/introduction"
            onClick={this.hideSidebar}
          />
        </ul> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
});

export default connect(mapStateToProps)(translate('global')(SidebarContent));
