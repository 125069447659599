/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable quote-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../../../utils';

let translator = () => {};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png'.;

class LeaveReport extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.JOB_NAME'),
      t('LBL.JOB_TITLE'),
      // t('LBL.UNIT_ORGANIZATION'),
      t('LBL.LEAVE_TYPE'),
      t('LBL.LEAVE_DATE'),
      t('LBL.NO_OF_DAYS'),
    ];
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      urlData: '',
      arrOffice: [],
      arrUnitOrganization: [],
      arrEmployee: [],
      arrLeaveType: [],
      condOffId: '',
      condEmployee: '',
      condEnd: '',
      condOrgId: '',
      condLecId: '',
      condStart: '',
      type: '',
      displayTable: '',
      displaySave: '',
      displayLoad: 'none',
      filename: 'Leave History Report',
      dataexcel: [],
      headerexcel: [],
      trigger: false,
      org: '',
    };

    this.getOffice = this.getOffice.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getReport = this.getReport.bind(this);
    this.getLeavecategory = this.getLeavecategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getOffice();
      this.getUnitOrganization();
      this.getEmployee();
      this.getLeavecategory();
    });
  };

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgoffice/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          this.setState({ arrOffice: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${apiws}/orgorganization/getAll`, config)
      .then((res) => {
        const org = res.data.data;
        const array = [];
        if (res.data.status === 'ok') {
          org.map((obj) =>
            array.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ arrUnitOrganization: array });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // if (org.length === 0) {
    //   this.setState({ arrUnitOrganization: array });
    // } else {
    //   org.map(obj => (
    //     array.push({ value: obj.org_id, label: obj.unit_organization })
    //   ));
    //   this.setState({ arrUnitOrganization: array });
    // }
    // });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/employee/getAllItemOrg/${org}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ arrEmployee: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getLeavecategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/Attleave/getAll/`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ arrLeaveType: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.atl_id, label: obj.leave_name }),
          );
          this.setState({ arrLeaveType: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport() {
    this.setState({
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
    });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      off_id: this.state.condOffId,
      emp_id: this.state.condEmployee,
      end_date: this.state.condEnd,
      org_id: this.state.condOrgId,
      ret_id: this.state.condRetId,
      atl_id: this.state.condLecId,
      ems_id: this.state.condEmsId,
      start_date: this.state.condStart,
      type: '2',
    };
    const bodyParameters = {
      cond: conds,
    };
    axios
      .post(`${apiws}/empleavehistory/getReportLeave`, bodyParameters, config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const { data, header } = res.data;
          const array = [];
          data.map((obj, index) => array.push({ No: index + 1, ...obj }));
          this.setState({
            dataexcel: array,
            headerexcel: header,
            trigger: !this.state.trigger,
          });
          this.setState({
            displayTable: '',
            displayLoad: 'none',
            displaySave: '',
          });
          this.setState({ type: '2' });
          setTimeout(() => {
            showNotifications('Success', 'Load Success', 'success');
            this.inputElement.click();
          }, 500);
        }
      });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  };

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  handleSubmit(values) {
    console.log(values);
    this.setState({
      displayTable: 'none',
      displayLoad: '',
      displaySave: 'none',
    });
    const offid =
      typeof values.office === 'undefined' || values.office === null
        ? ''
        : values.office.value;
    const orgId =
      typeof values.organization === 'undefined' || values.organization === null
        ? ''
        : values.organization.value;
    const empId =
      typeof values.employee === 'undefined' || values.employee === null
        ? ''
        : values.employee.value;
    // eslint-disable-next-line valid-typeof
    const start =
      typeof values.period_start === 'undefined' || values.period_start === null
        ? ''
        : values.period_start.format('YYYY-MM-DD');
    const end =
      typeof values.period_end === 'undefined' || values.period_end === null
        ? ''
        : values.period_end.format('YYYY-MM-DD');

    const lecId =
      typeof values.atl_id === 'undefined' || values.atl_id === null
        ? ''
        : values.atl_id.value;
    const type_report1 =
      typeof values.type_report1 === 'undefined' || values.type_report1 === null
        ? ''
        : values.type_report1.value;

    setTimeout(() => {
      this.setState({ condOffId: offid });
      this.setState({ condOrgId: orgId });
      this.setState({ condEmployee: empId });
      this.setState({ condStart: start });
      this.setState({ condEnd: end });
      this.setState({ condLecId: lecId });
      this.setState({ type: type_report1 });
    }, 100);
    if (values.type_report1.value === '1') {
      setTimeout(() => {
        this.setState({
          displayTable: '',
          displayLoad: 'none',
          displaySave: '',
        });
        this.setState({
          urlData: `${ls.get('ws_ip')}/empleavehistory/getReportLeave`,
        });
        this.getData();
        showNotifications('Success', 'Load Success', 'success');
      }, 100);
    } else {
      setTimeout(() => {
        this.getReport();
      }, 500);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      arrOffice,
      arrUnitOrganization,
      arrEmployee,
      arrLeaveType,
      displaySave,
      displayLoad,
      displayTable,
      condOffId,
      condOrgId,
      condEmployee,
      condStart,
      condEnd,
      condLecId,
      type,
    } = this.state;
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0" style={{ paddingLeft: '20px' }}>
              {/* <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="leave" />
                  <h3 className="page-title">{t('LBL.LEAVE_REPORT')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.VIEW')} {t('LBL.LEAVE_REPORT')}</h3>
                </div>
              </Col> */}
              <Col md={12}>
                <h3 className="page-title">{t('LBL.LEAVE_REPORT')}</h3>
                <h3 className="page-subhead subhead">
                  {t('LBL.VIEW')} {t('LBL.LEAVE_REPORT')}
                </h3>
              </Col>
              <Col md={12} lg={6} style={{ marginTop: '25px' }}>
                <form
                  name="leave_report_form"
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.OFFICE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="office"
                        component={renderSelectField}
                        type="text"
                        options={arrOffice}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.UNIT_ORGANIZATION')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="organization"
                        component={renderSelectField}
                        type="text"
                        options={arrUnitOrganization}
                        onChange={(val) => {
                          if (typeof val.value === 'undefined') {
                            this.setState({ org: '' });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          } else {
                            this.setState({ org: val.value });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EMPLOYEE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee"
                        component={renderSelectField}
                        type="text"
                        options={arrEmployee}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.PERIOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="period_start"
                        component={renderDatePickerField}
                        placeholder="Start Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                      <MinusIcon className="date-picker__svg" />
                      <Field
                        name="period_end"
                        component={renderDatePickerField}
                        type="text"
                        placeholder="End Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_TYPE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atl_id"
                        component={renderSelectField}
                        type="text"
                        options={arrLeaveType}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TYPE_REPORT')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="type_report1"
                        component={renderSelectField}
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                  </div>
                  {type === '2' && (
                    <ExcelFile
                      element={
                        <div
                          onClick={this.handleClick}
                          onKeyPress={this.handleClick}
                          role="button"
                          tabIndex="0"
                        >
                          <input
                            type="hidden"
                            ref={(ref) => {
                              this.inputElement = ref;
                            }}
                          />
                        </div>
                      }
                      filename={this.state.filename}
                    >
                      <ExcelSheet data={this.state.dataexcel} name="Employees">
                        {this.state.headerexcel.map((obj) => (
                          <ExcelColumn label={obj} value={obj} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button
                      color="primary"
                      type="submit"
                      style={{ display: displaySave }}
                    >
                      {t('FRM.SUBMIT')}{' '}
                    </Button>
                    <Button color="primary" style={{ display: displayLoad }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
            <Row style={{ display: displayTable }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <Row className="m5 table-nopadding">
                      <div className="card__title">
                        <h5 className="bold-text">{t('LBL.LEAVE_REPORT')}</h5>
                      </div>
                      <ViewListTable
                        url={urlData}
                        heads={this.heads}
                        // primaryKey="pyp_id"
                        // widthTable={this.state.dataWidth}
                        buttonAction="noButton"
                        conds={{
                          off_id: condOffId,
                          emp_id: condEmployee,
                          org_id: condOrgId,
                          atl_id: condLecId,
                          start_date: condStart,
                          end_date: condEnd,
                          type: type,
                        }}
                        onRef={(ref) => (this.child = ref)}
                        displayStyleHead="none"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12} lg={12}>
                <div className="table-responsive" style={{ padding: '15px' }}>
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Employee Name</th>
                        <th>Employee Code</th>
                        <th>Job Name</th>
                        <th>Job Title</th>
                        <th>Leave Type</th>
                        <th>Leave Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Rosi Sekiro</td>
                        <td>S00001</td>
                        <td>Departement Head</td>
                        <td>Accounting Manager</td>
                        <td>Annual</td>
                        <td>1 March - 2 March (2 Days)</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Riki Suryanto</td>
                        <td>S00002</td>
                        <td>Section Head</td>
                        <td>HRD Supervisor</td>
                        <td>Sick</td>
                        <td>1 June - 4 June (4 Days)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row> */}
          </div>
        </Card>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    type_report1: {
      fieldLabel: `${translator('LBL.TYPE_REPORT')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'leave_report_form', // a unique identifier for this form
  validate,
})(translate('global')(LeaveReport));
