/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../notificationMessages';
import SweetAlert from 'react-bootstrap-sweetalert';

class positionSetting extends PureComponent {
  static propTypes = {
    dataTree: PropTypes.func.isRequired,
    editMode: PropTypes.func.isRequired,
    loadTree: PropTypes.func.isRequired,
    updateTree: PropTypes.func.isRequired,
    fullMode: PropTypes.func.isRequired,
    editcond: PropTypes.string.isRequired,
    deletecond: PropTypes.string.isRequired,
  }
  constructor() {
    super();
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      treeData: [],
      token: '',
      apiws: '',
      deleteConfirm: false,
      deleteId: '',
      virttree: true,
      heighttree: '400px',
    };
    this.deleteItem = this.deleteItem.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(props, state) {
    if (props.dataTree !== state.treeData) {
      return {
        treeData: props.dataTree,
      };
    }
    return null;
  }
  componentDidMount = () => {
    const { fullMode } = this.props;
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    if (fullMode) {
      this.viewfulltree();
    }
  }


  componentDidUpdate = () => {
    const { dataTree } = this.props;
    this.setState({ treeData: dataTree });
  }

  deleteMode(id) {
    this.setState({ deleteConfirm: true, deleteId: id });
  }

  viewfulltree() {
    this.setState({ virttree: false, heighttree: '100%' });
  }

  deleteItem() {
    const { token, apiws, deleteId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgposition/deleteitem/${deleteId}`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Delete Success', 'success');
        this.props.loadTree();
      } else if (response.data.status === 'child') {
        showNotifications('Fail', 'Data is a parent, delete the child first!', 'danger');
      } else {
        showNotifications('Fail', 'Delete Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      showNotifications('Fail', 'Delete Failed', 'danger');
      // this.setState({ button_disabled: false });
    });
    this.setState({ deleteConfirm: false });
  }


  render() {
    const {
      treeData,
      deleteConfirm,
      virttree,
      heighttree,
    } = this.state;
    const { editMode, updateTree } = this.props;
    // eslint-disable-next-line prefer-destructuring
    return (
      <Card className="treeMenu_card">
        <CardBody style={{ height: heighttree }}>
          <SweetAlert
            warning
            show={deleteConfirm}
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => this.deleteItem()}
            onCancel={() => this.setState({ deleteConfirm: false })}
          >
            You will delete this item !
          </SweetAlert>
          <SortableTree
            isVirtualized={virttree}
            treeData={treeData}
            onChange={treeDatas => updateTree(treeDatas)}
            rowHeight={50}
            canDrag={false}
            generateNodeProps={rowInfo => ({
              buttons: [
                <div>
                  <button
                    className="btn btn-outline-success btn-sm"
                    style={{
                      verticalAlign: 'middle',
                      padding: '0px 8px',
                      marginBottom: '0px',
                      marginRight: '5px',
                      paddingRight: '3px',
                      display: this.props.editcond === '1' ? '' : 'none',
                    }}
                    onClick={() => editMode(rowInfo.node.pos_id)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button
                    className="btn btn-outline-danger btn-sm"
                    style={{
                      verticalAlign: 'middle',
                      padding: '0px 8px',
                      marginBottom: '0px',
                      marginRight: '5px',
                      paddingRight: '3px',
                      display: this.props.deletecond === '1' ? '' : 'none',
                    }}
                    onClick={() => this.deleteMode(rowInfo.node.pos_id)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>,
              ],
            })
            }
          />
        </CardBody >
      </Card >
    );
  }
}


export default translate('common')(positionSetting);
