/*
 * Page Label Preferences
 * Notes: Master Data Function Access
 * @author Gerdi
 * @date 22/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../../notificationMessages';
import PropTypes from 'prop-types';
import { deleteCryptoTableData } from '../../../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../../../shared/prop-types/TablesProps';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderRadioButtonField from '../../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { CalendarBlankIcon } from 'mdi-react';
import moment from 'moment';

class SurveyQuestion extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    setparam: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.QUESTION') },
      { '2': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['5%', '80%', '15%'],
      showOption: 'none',
      statusEdit: false,
      disableButton: false,
      action: '',
      modal: false,
      answerType: 0,
      tempLabel: '',
      options: [],
      trigger: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getButton = this.getButton.bind(this);
    this.showOptions = this.showOptions.bind(this);
    this.addOptions = this.addOptions.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
  }

  componentDidMount = () => {
    this.setState(
      {
        token: ls.get('token'),
        apiws: ls.get('ws_ip'),
        idUser: ls.get('user_cred').usr_id,
      },
      () => {
        this.getTableData();
      },
    );
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.QUESTION') },
      { '2': t('FRM.FUNCTION') },
    ];
    this.setState({ esg_id: newProps.setparam });
    this.setState(
      {
        urlData: `${ls.get('ws_ip')}/empsurvey/getAllQuestion/${
          newProps.setparam
        }`,
      },
      () => {
        this.getTableData();
      },
    );
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  showOptions(type) {
    if (type === '3') {
      this.setState({ showOption: 'none' });
    } else {
      this.setState({
        showOption: '',
        answerType: type,
      });
    }
  }

  addOptions() {
    if (this.state.tempLabel !== '') {
      const newOptions = this.state.options;
      newOptions.push(this.state.tempLabel);
      this.setState({
        options: newOptions,
        trigger: !this.state.trigger,
        tempLabel: '',
      });
    }
  }

  async handleSubmit(values) {
    this.setState({ disableButton: true });
    const { apiws, token, idUser, esg_id, options } = this.state;
    const { esq_id, question, answer_type } = values;

    let action;
    let url;
    let data = {
      esg_id,
      question,
      answer_type:
        typeof answer_type === 'object' ? answer_type.value : answer_type,
      answer_options: options,
    };

    if (esq_id === undefined || esq_id === '') {
      action = 'add';
      data = { ...data, cuser_id: idUser };
      url = `${apiws}/empsurvey/saveQuestion`;
    } else {
      action = 'edit';
      data = { ...data, muser_id: idUser };
      url = `${apiws}/empsurvey/updateQuestion/${esq_id}`;
    }
    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios
      .post(url, formBody, config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          this.props.destroy();
          showNotifications('Success', 'Save Success', 'success');
          this.getTableData();
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
        this.setState({
          modal: false,
          statusEdit: false,
          disableButton: false,
          answerType: 0,
          tempLabel: '',
          options: [],
        });
        this.showOptions('');
      })
      .catch(() => {
        showNotifications('Fail', 'Save Failed', 'danger');
      });
  }

  addQuestion() {
    this.setState({ action: 'add' });
    this.toggle();
    this.props.initialize({
      esq_id: '',
      question: '',
      answer_type: '',
    });
  }

  async editQuestion(id, e, param) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/empsurvey/getQuestionById/${id}`, '', config)
      .then(async (response) => {
        if (parseInt(response.data.data.length, 10) > 0) {
          if (id === undefined || id === '' || typeof id === 'undefined') {
            this.props.initialize({
              esq_id: '',
              question: '',
              answer_type: '',
            });
          } else {
            if (response.data.status === 'ok') {
              const optionList = [];
              response.data.options.map((o) => optionList.push(o.option_label));
              this.setState({
                statusEdit: true,
                disableButton: false,
                modal: true,
                action: 'edit',
                options: optionList,
                showOption: '',
                answerType: response.data.data[0].answer_type,
              });
              if (response.data.data[0].answer_type === '3') {
                this.setState({ showOption: 'none' });
              }
              this.props.initialize({
                esq_id: response.data.data[0].esq_id,
                question: response.data.data[0].question,
                answer_type: response.data.data[0].answer_type,
              });
            }
          }
        }
      });
  }

  deleteQuestion(id) {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/empsurvey/deleteQuestion/${id}`,
            `duser_id=${idUser}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              this.getTableData();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch((error) => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      answerType: 0,
      tempLabel: '',
      options: [],
      statusEdit: false,
      showOption: 'none',
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      urlData,
      disableButton,
      statusEdit,
      showOption,
      options,
    } = this.state;
    const addBtn = (
      <Col>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={this.addQuestion}
          style={{ float: 'right', 'margin-bottom': '20px' }}
        >
          {t('FRM.ADD')} {t('LBL.QUESTION')}
        </Button>
      </Col>
    );
    const backBtn = (
      <Button
        color="secondary"
        className="btn_table_navbar"
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
        onClick={() => this.props.onparam('')}
        disable={pristine}
      >
        {' '}
        {t('FRM.BACK')} {t('MEN.SURVEY')}
      </Button>
    );

    let labelButton;
    let colorButton;
    if (statusEdit) {
      labelButton = t('FRM.EDIT');
      colorButton = 'success';
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="esq_id"
                  widthTable={this.state.dataWidth}
                  buttonAction={['move-up', 'move-down', 'edit', 'delete']}
                  backBtn={backBtn}
                  moveUpFunc={this.editQuestion}
                  moveDownFunc={this.editQuestion}
                  updateFunc={this.editQuestion}
                  deleteFunc={this.deleteQuestion}
                  onRef={(ref) => (this.child = ref)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {' '}
            {this.state.action === 'add' ? t('FRM.ADD') : t('FRM.EDIT')}{' '}
            {t('LBL.QUESTION')}{' '}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="reduxSurveyQuestion"
              id="reduxSurveyQuestion"
            >
              <input type="hidden" name="esq_id" value="" />
              <Container>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.QUESTION')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="question"
                          component="textarea"
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.ANSWER_TYPE')}
                      </span>
                      <div className="form__form-group-field d-block">
                        <div className="row w-100">
                          <div className="col-md-4">
                            <Field
                              name="answer_type"
                              component={renderSelectField}
                              type="textemployee"
                              options={[
                                { value: '1', label: 'Radio Button' },
                                { value: '2', label: 'Check Box' },
                                { value: '3', label: 'Free Text' },
                              ]}
                              placeholder="Answer Type"
                              onChange={(val) => this.showOptions(val.value)}
                            />
                          </div>
                          <div className="col-md-8">
                            <div
                              className={`${showOption === '' &&
                                'd-flex '}form-group-inline-btn`}
                              style={{ display: showOption }}
                            >
                              <div className="w-100">
                                <Field
                                  component={renderTextInput}
                                  type="text"
                                  placeholder="Question Answer"
                                  onChange={(val) =>
                                    this.setState({
                                      tempLabel: val.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div style={{ width: '50px' }}>
                                <button
                                  type="button"
                                  onClick={this.addOptions}
                                  className="btn btn-xs btn-secondary"
                                >
                                  <i className="fas fa-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row w-100">
                          <div className="col-md-12">
                            <div
                              className="preview-answer"
                              style={{ display: showOption }}
                            >
                              <label className="title-preview">
                                Preview Answer
                              </label>
                              <div>
                                {options.map((x, i) => {
                                  let item;
                                  if (this.state.answerType === '1') {
                                    item = (
                                      <Field
                                        name="unused"
                                        component={renderRadioButtonField}
                                        label={x}
                                        radioValue={i}
                                      />
                                    );
                                  } else if (this.state.answerType === '2') {
                                    item = (
                                      <Field
                                        name={`unused_${i}`}
                                        component={renderCheckBoxField}
                                        label={x}
                                      />
                                    );
                                  }
                                  return item;
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle('cancel')}>
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color={colorButton}
              form="reduxSurveyQuestion"
              type="submit"
              disabled={disableButton}
            >
              {' '}
              {labelButton}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.question) {
    errors.question = "Question shouldn't be empty";
  }
  if (!values.answer_type) {
    errors.answer_type = "Answer type shouldn't be empty";
  }
  // console.log(errors);
  return errors;
};

export default reduxForm({
  form: 'reduxSurveyQuestion',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(SurveyQuestion));
