/* eslint-disable array-callback-return */
/*
 * Page Payroll Prorate
 * Notes: Setting prorate
 * @author Gerdi
 * @date 05/08/2019
 * Modification (please note below)
 */
/* eslint-disable prefer-const */
/* eslint-disable no-const-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable quote-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../shared/components/form/Select';
import showNotifications from '../../notificationMessages';

class ProrateSetting extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      customNumberStyle: { display: 'none' },
      holidayStyle: { display: 'none' },
      token: '',
      apiws: '',
      idUser: '',
      getProrates: [],
      varX: '',
      varY: '',
      disabledSaveButton: 'disabled',
      disabledForm: '',
      statusEdit: false,
      displayStyle: { display: 'none' },
      displayFormula: '',
      editcond: '',
      addcond: '',
      deletecond: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.getProrate = this.getProrate.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') }, () => {
      this.getProrate();
      this.getButton();
    });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getProrate() {
    const { apiws, token, getProrates = [] } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payproratesetting/getAll`, '', config)
      .then((response) => {
        if (response.data.data.length !== 0) {
          const propsetting = response.data.data[0].prorate_setting;
          const customnumber = response.data.data[0].custom_number;
          const countholiday = response.data.data[0].count_holiday;
          let holidayboolean;
          if (countholiday === 0) {
            holidayboolean = false;
          } else if (countholiday === null) {
            holidayboolean = false;
          } else {
            holidayboolean = true;
          }
          this.setState({ displayStyle: { display: '' } });
          this.setState({ disabledForm: 'disabled' });
          this.setState({ statusEdit: true });
          this.setState({ getProrates: [] });
          getProrates.push({ value: propsetting });
          this.setState({ displayFormula: propsetting });
          this.handleChange(getProrates[0]);
          this.props.destroy();
          this.props.initialize({
            prorate_setting: propsetting,
            custom_number: customnumber,
            count_holiday: holidayboolean,
          });
        }
      });
  }

  handleChange(e) {
    this.setState({ varX: '' });
    this.setState({ varY: '' });
    this.setState({ displayStyle: { display: '' } });
    this.setState({ disabledSaveButton: '' });
    const datas = e.value;
    this.setState({ displayFormula: datas });
    if (datas === 2) {
      this.setState({ customNumberStyle: { display: 'none' } });
      this.setState({ holidayStyle: { display: '' } });
    } else if (datas === 3) {
      this.setState({ customNumberStyle: { display: '' } });
      this.setState({ holidayStyle: { display: 'none' } });
    } else if (datas === 4) {
      this.setState({ customNumberStyle: { display: '' } });
      this.setState({ holidayStyle: { display: '' } });
    } else {
      this.setState({ holidayStyle: { display: 'none' } });
      this.setState({ customNumberStyle: { display: 'none' } });
    }
    this.props.initialize({
      custom_number: '',
      count_holiday: '',
    });
  }

  mapData(b) {
    let val_b;
    if (b !== null) {
      if (typeof b === 'object') {
        val_b = b.value;
      } else if (typeof b === 'boolean') {
        if (b === true) {
          val_b = 1;
        } else {
          val_b = 0;
        }
      } else {
        val_b = b;
      }
    } else {
      val_b = null;
    }
    return val_b;
  }

  handleSubmit(values) {
    let err = 0;
    if (typeof values.prorate_setting === 'undefined') {
      err = 1;
    }
    if (err === 0) {
      const { token, apiws, idUser } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const formBody = Object.keys(values)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              this.mapData(values[key]),
            )}`,
        )
        .join('&');
      axios
        .post(`${apiws}/payproratesetting/getAll`, formBody, config)
        .then((response) => {
          let url;
          let body;
          if (response.data.data.length === 0) {
            url = `${apiws}/payproratesetting/saveItem`;
            body = [formBody, `cuser_id=${idUser}`].join('&');
          } else {
            const pysid = response.data.data[0].pys_id;
            body = [`pys_id=${pysid}`, formBody, `muser_id=${idUser}`].join(
              '&',
            );
            url = `${apiws}/payproratesetting/updateItem/${pysid}`;
          }
          axios.post(url, body, config).then((update) => {
            if (update.data.status === 'ok') {
              showNotifications('Success', 'Update Success', 'success');
              this.props.destroy();
              this.getProrate();
            } else {
              showNotifications('Fail', 'Save Failed', 'danger');
            }
          });
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledForm: '' });
    this.setState({ statusEdit: false });
  }

  render() {
    const {
      customNumberStyle,
      holidayStyle,
      displayStyle,
      disabledSaveButton,
      disabledForm,
      statusEdit,
    } = this.state;
    const { handleSubmit, t } = this.props;
    const fieldnumber = {
      textAlign: 'right',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'textfield',
      margin: '0',
    };
    let varX;
    let varY;
    if (this.state.displayFormula === 1) {
      varX = `${t('LBL.WORKING_DAY_JOIN')} ${t('LBL.TILL')} ${t(
        'LBL.END_OF_MONTH',
      )}`;
      varY = `${t('LBL.WORKING_DAY_MONTH')}`;
    } else if (this.state.displayFormula === 2) {
      varX = `${t('LBL.JOIN_DATE')} ${t('LBL.TILL')} ${t('LBL.END_OF_MONTH')}`;
      varY = `${t('LBL.WORKING_WHOLE_DAYS')}`;
    } else if (this.state.displayFormula === 3) {
      varX = `${t('LBL.WORKING_DAY_JOIN')} ${t('LBL.TILL')} ${t(
        'LBL.END_OF_MONTH',
      )}`;
      varY = `${t('LBL.CUSTOM_NUMBER')}`;
    } else if (this.state.displayFormula === 4) {
      varX = `${t('LBL.JOIN_DATE')} ${t('LBL.TILL')} ${t('LBL.END_OF_MONTH')}`;
      varY = `${t('LBL.CUSTOM_NUMBER')}`;
    }
    let button_action;
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          onClick={this.handleEdit}
          type="button"
          style={{ isplay: this.state.editcond === '1' ? '' : 'none' }}
        >
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <ButtonToolbar>
          <Button color="secondary" onClick={this.getProrate} type="button">
            {t('FRM.CANCEL')}
          </Button>
          <Button
            color="primary"
            type="submit"
            form="formprorate"
            disabled={disabledSaveButton}
            style={{ display: this.state.addcond === '1' ? '' : 'none' }}
          >
            {t('FRM.SAVE')}
          </Button>
        </ButtonToolbar>
      );
    }
    return (
      <Container>
        {/* <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <h4>Prorate</h4>
            <hr />
          </Col>
        </Row> */}
        <Row>
          <Col md={6} xs={12} lg={6} xl={6}>
            <Card>
              <CardBody style={{ padding: '20px 20px 30px 20px' }}>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  form="formprorate"
                  id="formprorate"
                >
                  <Container>
                    <Row>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PRORATE_SETTING')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            onChange={this.handleChange}
                            id="prorate_setting"
                            name="prorate_setting"
                            component={renderSelectField}
                            type="text"
                            disabled={disabledForm}
                            options={[
                              { value: 1, label: 'Based on Working Day' },
                              { value: 2, label: 'Based on Calendar Day' },
                              { value: 3, label: 'Custom on Working Day' },
                              { value: 4, label: 'Custom on Calendar Day' },
                            ]}
                          />
                        </div>
                      </div>
                      <div
                        className="form__form-group"
                        style={customNumberStyle}
                      >
                        <div className="form__form-group-field">
                          <Field
                            name="custom_number"
                            id="custom_number"
                            component="input"
                            type="number"
                            placeholder={t('LBL.CUSTOM_NUMBER')}
                            style={fieldnumber}
                            disabled={disabledForm}
                          />
                        </div>
                      </div>
                      <div className="form__form-group" style={holidayStyle}>
                        <div className="checkbox_form form__form-group-field">
                          <Field
                            name="count_holiday"
                            id="count_holiday"
                            component={renderCheckBoxField}
                            label={t('LBL.COUNT_HOLIDAY')}
                            disabled={disabledForm}
                          />
                        </div>
                      </div>
                      <ButtonToolbar className="form__button-toolbar">
                        {button_action}
                      </ButtonToolbar>
                    </Row>
                  </Container>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12} lg={6} xl={6} style={displayStyle}>
            <Container>
              <Row>
                <div
                  style={{
                    backgroundColor: '#efefef',
                    padding: '25px 20px',
                    color: '#616161',
                    borderRadius: '4px',
                  }}
                >
                  <table style={{ textAlign: 'center' }}>
                    <tr>
                      <td
                        style={{
                          borderBottom: '2px solid #b3b3b3',
                          padding: '0px 15px',
                        }}
                      >
                        {varX}
                      </td>
                      <td rowSpan="2" style={{ padding: '0px 10px' }}>
                        x
                      </td>
                      <td rowSpan="2" style={{ padding: '0px 10px' }}>
                        {t('LBL.BASIC_SALARY')}
                      </td>
                    </tr>
                    <tr>
                      <td>{varY}</td>
                    </tr>
                  </table>
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.prorate_setting) {
    errors.prorate_setting = 'Prorate Setting field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  form: 'prorateform',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(ProrateSetting));
