/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faPencilAlt,
  faSave,
} from '@fortawesome/fontawesome-free-solid';
import Swal from 'sweetalert2';

// let compthr = '';
class BpjsType extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    // pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    detailFunction: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      buttondisabled: false,
      disabledinput: false,
      dataWidth: ['5%', '30%', '30%', '10%'],
      urlData: '',
      arrComponent: [],
      disabledComponent: true,
      statusEdit: true,
      editcond: '',
      addcond: '',
      deletecond: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.GROUP') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editbpjstype = this.editbpjstype.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deletebpjstype = this.deletebpjstype.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.GROUP') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.props.destroy();
    // this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
    this.loadSelect();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getComponent();
      this.loadSelect();
      this.getButton();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({
      urlData: `${ls.get('ws_ip')}/payketenagakerjaantype/getAllitem/`,
    });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getComponent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paycomponent/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pyc_id, label: obj.component }),
          );
          this.setState({ arrComponent: Arr });
        }
      });
  }

  handleSubmit(values) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const url = `${ls.get(
      'ws_ip',
    )}/payketenagakerjaancomponent/saveItemComponent`;
    const dynComponent = JSON.stringify(values.component);
    const addBody = [
      `pyc_id=${dynComponent}`,
      `cuser_id=${ls.get('user_cred').usr_id}`,
    ].join('&');
    axios.post(url, addBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.loadSelect();
        this.setState({
          statusEdit: true,
          disabledComponent: true,
        });
      }
    });
  }

  handleSubmitInput(values) {
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.pbt_id;
    const userid = ls.get('user_cred').usr_id;
    const types =
      typeof values.pbt_type === 'object'
        ? values.pbt_type.value
        : values.pbt_type;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/payketenagakerjaantype/saveItem`;
      data = {
        ...values,
        pbt_type: types,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/payketenagakerjaantype/updateItem/${id}`;
      data = {
        ...values,
        pbt_type: types,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.loadSelect();
          this.setState({ modal: false });
          setTimeout(() => {
            this.child.reload();
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  async editbpjstype(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/payketenagakerjaantype/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            pbt_id: getResponseData.type[0].pbt_id,
            pbt_name: getResponseData.type[0].pbt_name,
            pbt_type: getResponseData.type[0].pbt_type,
          };
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledComponent: false });
    this.setState({ statusEdit: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusEdit: true });
  }

  deletebpjstype(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/payketenagakerjaantype/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  loadSelect() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/payketenagakerjaancomponent/getAll/`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          for (let i = 0; i < dataResponse.length; i++) {
            Arr.push({
              value: dataResponse[i].pyc_id,
              label: dataResponse[i].component,
            });
          }
          this.props.initialize({
            component: Arr,
          });
        }
      });
  }

  render() {
    const { handleSubmit, t, detailFunction } = this.props;
    const { statusEdit, urlData, arrComponent, disabledComponent } = this.state;

    let button_component;
    const addBtn = (
      <div className="add-btn-comp">
        <Button
          color="primary"
          size="sm"
          className="btn_table_navbar"
          onClick={() => this.toggle()}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            display: this.state.addcond === '1' ? '' : 'none',
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> {t('FRM.ADD')}
        </Button>
      </div>
    );
    if (statusEdit) {
      button_component = (
        <Button
          className="form__form-group input_height_select"
          color="secondary"
          type="button"
          style={{
            width: '80px',
            marginLeft: '10px',
            padding: '1px',
          }}
          onClick={this.handleEdit}
          // disabled={pristine}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_component = (
        <Button
          className="form__form-group input_height_select"
          color="primary"
          type="submit"
          form="bpjstypeformcomponent"
          style={{
            width: '80px',
            marginLeft: '10px',
            padding: '1px',
          }}
          // disabled={pristine}
        >
          <FontAwesomeIcon icon={faSave} />
          {t('FRM.SAVE')}
        </Button>
      );
    }
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    return (
      <Container>
        {/* <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <h4>BPJS</h4>
            <hr />
          </Col>
        </Row> */}
        <Row>
          <Col
            md={12}
            xs={12}
            lg={12}
            xl={12}
            className="filter-comp-panel"
            style={{ padding: '5px 30px 0px 30px' }}
          >
            <div className="filter-box active">
              <form
                className="form"
                onSubmit={handleSubmit(this.handleSubmit)}
                name="bpjstypeformcomponent"
                id="bpjstypeformcomponent"
              >
                <Row className="form-inline">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <div className="form-filter">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Component BPJS
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="component"
                            className='custom-multi'
                            component={renderSelectField}
                            multi="true"
                            options={arrComponent}
                            disabled={disabledComponent}
                          />
                          {button_component}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '0px 0px 0px 0px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pbt_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editbpjstype}
                  deleteFunc={this.deletebpjstype}
                  detailFunc={detailFunction}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  displayTitle="none"
                  buttonAction="buttonAll"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.BPJS')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="bpjstypeform"
                  id="bpjstypeform"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <Card>
                          <CardBody>
                            <input type="hidden" name="pbt_id" />
                            <Col xs={12} md={12} lg={12} xl={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.BPJS')} {t('LBL.GROUP')}{' '}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="pbt_type"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                      {
                                        value: 1,
                                        label: 'BPJS Ketenagakerjaan',
                                      },
                                      { value: 2, label: 'BPJS Kesehatan' },
                                    ]}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={12} lg={12} xl={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.BPJS')} {t('LBL.NAME')}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="pbt_name"
                                    component={renderTextInput}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  form="bpjstypeform"
                  // disabled={pristine}
                >
                  {' '}
                  {t('FRM.SAVE')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.pbt_type) {
    errors.pbt_type = 'Group BPJS field shouldn’t be empty';
  }
  if (!values.pbt_name) {
    errors.pbt_name = 'Name field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  form: 'bpjstypeform',
  // onSubmit: submit,
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(BpjsType));
