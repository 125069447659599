/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import ls from 'local-storage';
import { Redirect } from 'react-router-dom';

class ReportAttendanceView extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
    inProcess: PropTypes.func.isRequired,
    urlData: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.DATE'),
      t('LBL.TIME_IN'),
      t('LBL.TIME_OUT'),
      t('LBL.STATUS'),
      t('LBL.SHIFT'),
      t('LBL.HOURS_WORKED'),
      t('LBL.LATE'),
    ];

    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '20%', '30%', '20%', '28%'],
      urlData: [],
      redirectUrl: false,
      detailId: '',
    };
    this.detailRows = this.detailRows.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getTableData(); });
    // this.setState({ urlData: `${ls.get('ws_ip')}/attreport/getAllitem/` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.DATE'),
      t('LBL.TIME_IN'),
      t('LBL.TIME_OUT'),
      t('LBL.STATUS'),
      t('LBL.SHIFT'),
      t('LBL.HOURS_WORKED'),
      t('LBL.LATE'),
    ];
    setTimeout(() => {
      const { onProcess } = this.props;
      if (onProcess) {
        this.getTableData();
        this.props.inProcess();
      }
    });
  }

  getTableData() {
    this.child.reload();
  }

  detailRows(id) {
    this.setState({
      redirectUrl: true,
      detailId: id,
    });
  }

  render() {
    const { redirectUrl, detailId } = this.state;
    if (redirectUrl) {
      return <Redirect to={{ pathname: '/payroll/employeelist', state: { pypId: detailId } }} />;
    }
    const addBtn = '';
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody style={{ padding: '20px 5px 30px 10px' }}>
            <ViewListTable
              url={this.props.urlData}
              heads={this.heads}
              addBtn={addBtn}
              primaryKey="atr_id"
              widthTable={this.state.dataWidth}
              deleteFunc={this.deleteRows}
              updateFunc={this.editRows}
              detailFunc={this.detailRows}
              buttonAction="noButton"
              onRef={ref => (this.child = ref)}
              displayStyleHead="none"
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}


export default (translate('global')(ReportAttendanceView));
