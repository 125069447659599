import React, { PureComponent } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import '../../scss/containers/fieldreport.scss';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../shared/components/form/Select';
import renderDatePickerField from '../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import ViewListTable from '../../shared/components/table/viewListTable';
import showNotifications from '../Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../utils';
import Layout from '../Layout/index';
import moment from 'moment';

let translator = () => {};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class LeaveReport extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.state = {
      apiws: ls.get('ws_ip'),
      token: ls.get('token'),
      idUser: '',
      urlData: '',
      arrEmployee: [],
      condEmployee: '',
      condEnd: '',
      condLecId: '',
      condStart: '',
      type: '',
      activityType: '',
      displayTable: '',
      displaySave: '',
      displayLoad: 'none',
      filename: 'Report Activity',
      dataexcel: [],
      headerexcel: [],
      trigger: false,
      org: '',
      selectedOption: null,
      showActivityType: false,
      actType: [],
      currentDate: moment().format('YYYY-MM-DD'),
      newEndDate: '',
    };

    this.getEmployee = this.getEmployee.bind(this);
    this.getReport = this.getReport.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFacType = this.getFacType.bind(this);
  }

  getFacType() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${apiws}/facactivitytype/getAll`, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const fat = response.data.data;
          const arr = [];
          fat.map((obj) =>
            arr.push({ value: obj.fat_id, label: obj.activity_name }),
          );
          this.setState({ actType: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount = () => {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getEmployee();
    });
    this.getFacType();
  };

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/facactivity/getAllEmp`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.emp_name,
            }),
          );
          this.setState({ arrEmployee: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport() {
    this.setState({
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
    });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      emp_id: this.state.emp_id,
      start_date: this.state.condStart,
      end_date: this.state.condEnd,
      type: '2',
      activity_type: this.state.activityType,
    };
    const bodyParameters = {
      cond: conds,
    };
    axios
      .post(`${apiws}/facactivity/getReportActivity`, bodyParameters, config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const { data, header } = res.data;
          const array = [];
          data.map((obj, index) => array.push({ No: index + 1, ...obj }));
          this.setState({
            dataexcel: array,
            headerexcel: header,
            trigger: !this.state.trigger,
          });
          this.setState({
            displayTable: '',
            displayLoad: 'none',
            displaySave: '',
          });
          this.setState({ type: '2' });
          if (
            this.state.condStart > this.state.condEnd ||
            this.state.newEndDate > this.state.currentDate
          ) {
            return false;
          } else {
            setTimeout(() => {
              showNotifications('Success', 'Load Success', 'success');
              this.inputElement.click();
            }, 500);
          }
        }
      });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  };

  handleSubmit(values) {
    this.setState({
      displayTable: 'none',
      displayLoad: '',
      displaySave: 'none',
    });
    // eslint-disable-next-line valid-typeof
    const start =
      typeof values.period_start === 'undefined' || values.period_start === null
        ? ''
        : values.period_start.format('YYYY-MM-DD HH:MM:SS');
    const end =
      typeof values.period_end === 'undefined' || values.period_end === null
        ? ''
        : values.period_end.format('YYYY-MM-DD HH:MM:SS');
    const type_report1 =
      typeof values.type_report1 === 'undefined' || values.type_report1 === null
        ? ''
        : values.type_report1.value;
    const type_report3 =
      typeof values.type_report3 === 'undefined' || values.type_report3 === null
        ? ''
        : values.type_report3.value;
    const separateEndDate = end.slice(0, 10);
    const emp_id = values.employee?.value;
    setTimeout(() => {
      this.setState({
        condStart: start,
        condEnd: end,
        type: type_report1,
        activityType: type_report3,
        condEmployee: emp_id,
        newEndDate: separateEndDate,
      });
    }, 100);
    if (values.type_report1.value === '1') {
      setTimeout(() => {
        this.setState({
          displayTable: '',
          displayLoad: 'none',
          displaySave: '',
          urlData: `${ls.get('ws_ip')}/facactivity/getReportActivity`,
        });
        if (
          this.state.condStart > this.state.condEnd ||
          this.state.newEndDate > this.state.currentDate
        ) {
          return false;
        } else {
          this.getData();
          showNotifications('Success', 'Load Success', 'success');
        }
      }, 100);
    } else {
      setTimeout(() => {
        this.getReport();
      }, 500);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      displaySave,
      displayLoad,
      condEmployee,
      condStart,
      condEnd,
      type,
      activityType,
      actType,
    } = this.state;

    return (
      <>
        <Layout />
        <div className="container__wrap ">
          <Container className="p-3">
            <Card>
              <div
                className="tab-body-panel"
                style={{
                  borderTopLeftRadius: '17px ',
                  borderTopRightRadius: '17px',
                  borderBottomLeftRadius: '9px',
                  borderBottomRightRadius: '9px',
                }}
              >
                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                  <Col md={12} className="field-report-title">
                    <img
                      src="img/calendar-phiro-icon.svg"
                      alt=""
                      width={50}
                      height={50}
                    />
                    <span className="field-title">
                      {' '}
                      {t('LBL.ACTIVITYREPORT')}
                    </span>
                  </Col>
                  <Col md={12} lg={6} className="p-0">
                    <form
                      name="leave_report_form"
                      className="form form--horizontal report"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <div
                        className="form__form-group common"
                        style={{ marginTop: '15px' }}
                      >
                        <span className="form__form-group-label">
                          {t('LBL.EMPLOYEE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="employee"
                            component={renderSelectField}
                            type="text"
                            options={this.state.arrEmployee}
                          />
                        </div>
                      </div>
                      <div className="form__form-group common">
                        {this.state.condStart > this.state.condEnd && (
                          <span
                            className="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              position: 'absolute',
                              zIndex: '10',
                              marginLeft: '372px',
                              padding: '5px 10px',
                              border: '3px',
                              marginTop: '-27px',
                            }}
                          >
                            End date shouldn`t less than Start Date
                          </span>
                        )}
                        {this.state.newEndDate > this.state.currentDate && (
                          <span
                            className="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              position: 'absolute',
                              zIndex: '10',
                              marginLeft: '372px',
                              padding: '5px 10px',
                              border: '3px',
                              marginTop: '-27px',
                            }}
                          >
                            Please select a date on or before today
                          </span>
                        )}
                        <span className="form__form-group-label">
                          {t('LBL.PERIOD')}
                        </span>
                        <div className="form__form-group-field ">
                          <Field
                            name="period_start"
                            component={renderDatePickerField}
                            placeholder="Start Date"
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                          <MinusIcon className="date-picker__svg" />
                          <Field
                            name="period_end"
                            component={renderDatePickerField}
                            type="text"
                            placeholder="End Date"
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                      <div className="form__form-group common">
                        <span className="form__form-group-label ">
                          {t('LBL.ACTIVITYTYPE')}
                        </span>
                        <div className="form__form-group-field position-relative">
                          <Field
                            name="type_report3"
                            component={renderSelectField}
                            options={actType}
                          />
                        </div>
                      </div>
                      <div className="form__form-group common">
                        <span className="form__form-group-label ">
                          {t('LBL.REPORTTYPE')}
                        </span>
                        <div className="form__form-group-field ">
                          <Field
                            name="type_report1"
                            component={renderSelectField}
                            options={[
                              { value: '1', label: 'View' },
                              { value: '2', label: 'Excel' },
                            ]}
                          />
                        </div>
                      </div>
                      {type === '2' && (
                        <ExcelFile
                          element={
                            <div
                              onClick={this.handleClick}
                              onKeyPress={this.handleClick}
                              role="button"
                              tabIndex="0"
                            >
                              <input
                                type="hidden"
                                ref={(ref) => {
                                  this.inputElement = ref;
                                }}
                              />
                            </div>
                          }
                          filename={this.state.filename}
                        >
                          <ExcelSheet
                            data={this.state.dataexcel}
                            name="Activity"
                          >
                            {this.state.headerexcel.map((obj) => (
                              <ExcelColumn label={obj} value={obj} />
                            ))}
                          </ExcelSheet>
                        </ExcelFile>
                      )}
                      <ButtonToolbar
                        className="form__button-toolbar wizard__toolbar"
                        style={{
                          marginLeft: '182px',
                          paddingTop: '10px',
                          paddingBottom: '18px',
                        }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          style={{ display: displaySave, padding: '5px 17px' }}
                        >
                          {t('LBL.GENERATEREPORT')}
                        </Button>
                        <Button
                          color="primary"
                          style={{ display: displayLoad }}
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: '6px' }}
                          />
                          {t('FRM.LOADING')}
                        </Button>
                      </ButtonToolbar>
                    </form>
                  </Col>
                </Row>
              </div>
            </Card>
            <Card>
              <Row
                style={{
                  marginRight: '-15px',
                  marginLeft: '-15px',
                }}
              >
                <Col xs={12} md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody style={{ padding: '1rem' }}>
                      <Row className="p-3 table-nopadding">
                        <div className="card__title">
                          <h5 className="bold-text">
                            {t('LBL.ACTIVITYREPORT')}
                          </h5>
                        </div>
                        <ViewListTable
                          url={urlData}
                          heads={[
                            t('LBL.NUMBER'),
                            t('LBL.EMPLOYEENAME'),
                            t('LBL.ACTIVITYDATE'),
                            'Activity Time',
                            t('LBL.LOCATIONNAME'),
                            t('LBL.ACTIVITYTYPE'),
                            t('LBL.ACTIVITYDESC'),
                            t('LBL.LOCATION'),
                            t('LBL.ACTIVITYPROOF'),
                          ]}
                          buttonAction="noButton"
                          conds={{
                            emp_id: condEmployee,
                            start_date: condStart,
                            end_date: condEnd,
                            activity_type: activityType,
                          }}
                          onRef={(ref) => (this.child = ref)}
                          displayStyleHead="none"
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}

const validate = (values) => {
  const validation = {
    type_report1: {
      fieldLabel: `${translator('Report Type')}`,
      required: true,
    },
    period_start: {
      fieldLabel: `${translator('Period')}`,
      required: true,
    },
    period_end: {
      fieldLabel: `${translator('Period')}`,
      required: true,
    },
  };
  return utils.validate(validation, values);
};

export default reduxForm({
  form: 'leave_report_form', // a unique identifier for this form
  validate,
})(translate('global')(LeaveReport));
