/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase, no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import ls from 'local-storage';
import axios from 'axios';
import moment from 'moment';
import { store } from '../../../App/store';
import { connect } from 'react-redux';
import utils from '../../../../utils';

const imgPieChartEmpty = `${process.env.PUBLIC_URL}/img/piechart-notfound-illustration.png`;

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {payload.map((entry, index) => (
      <li key={`item-${index}`}>
        <span style={{ backgroundColor: entry.color }} />
        {entry.value}
      </li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      vslue: PropTypes.string,
    }),
  ).isRequired,
};

// eslint-disable-next-line no-unused-vars
const data01 = [
  { name: 'Permanent', value: 70, fill: '#2C96F8' },
  { name: 'Contract', value: 20, fill: '#f6da6e' },
  { name: 'Probation', value: 10, fill: '#4ce1b6' },
];

class EmpStatus extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      token: ls.get('token'),
      apiws: ls.get('ws_ip'),
      iduser: ls.get('user_cred').usr_id,
      trigger: true,
      displayChart: '',
      displayPict: 'none',
    };
    this.getAttPercentage = this.getAttPercentage.bind(this);
  }

  componentDidMount() {
    this.setState({
      displayChart: this?.props?.user?.attendance?.length > 0 ? '' : 'none',
      displayPict: this?.props?.user?.attendance?.length > 0 ? 'none' : '',
    });
    this.getAttPercentage({});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user?.data?.empId != nextProps?.user?.data?.empId) {
      this.getAttPercentage({ empId: nextProps?.user?.data?.empId });
    }
  }

  shouldComponentUpdate(nextProps) {
    return !utils.deepEqual(nextProps, this.props);
  }

  getAttPercentage({ empId = null }) {
    const { apiws, token, iduser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let getDataUser = true;

    if (iduser === 0) getDataUser = false;
    if (!this.props?.user?.data?.empId) getDataUser = false;

    if (getDataUser) {
      const fd = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const first_day = moment(fd).format('YYYY-MM-DD');
      const now = moment(new Date()).format('YYYY-MM-DD');
      const day_permonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0,
      ).getDate();
      const data = { day_permonth, first_day, now };
      const formBody = Object.keys(data)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');

      axios
        .post(
          `${apiws}/Attreport/getPercentage/${empId ??
            this?.props?.user?.data?.empId}`,
          formBody,
          config,
        )
        .then((res) => {
          if (res.data.status === 'ok') {
            if (res.data.data.length > 0) {
              const rpv = res.data.data;
              const arr = [];
              rpv.map((obj) =>
                arr.push({
                  name: obj.name,
                  total: obj.total,
                  value: obj.value,
                  fill: obj.fill,
                }),
              );

              store.dispatch({ type: 'SET_USER_ATTENDANCE', data: arr });
              if (this.state.displayPict != 'none') {
                this.setState({
                  displayChart: '',
                  displayPict: 'none',
                });
              }
            } else {
              this.setState({ displayChart: 'none', displayPict: '' });
            }
          }
        });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <div className="dashboard__chart chart-home">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">{t('LBL.ATTENDANCE_STATUS')}</div>
            </div>
          </div>
          <div className="chart-body d-flex flex-column">
            <div
              className="empty-piechart text-center w-100"
              style={{ display: this.state.displayPict }}
            >
              <img src={imgPieChartEmpty} alt="" />
              <p>{t('LBL.ATTENDANCE_DATA_EMPTY')}</p>
            </div>
            <div className="w-100" style={{ display: this.state.displayChart }}>
              <div className="d-flex">
                <div className="container-pie-chart">
                  <ResponsiveContainer width={100} height={100}>
                    <PieChart>
                      <Pie
                        data={this?.props?.user?.attendance ?? []}
                        dataKey="value"
                        innerRadius={20}
                        outerRadius={40}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="w-100">
                  <ul className="pie-chart-legend">
                    {this?.props?.user?.attendance?.map((obj, index) => (
                      <li key={`attendance_${index}`}>
                        <div className="d-flex">
                          <div className="legend-name">
                            <span
                              className="legend-color"
                              style={{ backgroundColor: obj.fill }}
                            />
                            {obj.name}
                          </div>
                          <div className="legend-total">{obj.total}</div>
                          <div className="legend-total-percent">
                            {obj.value}%
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(translate('global')(EmpStatus));
