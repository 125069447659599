import React, { useState } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import '../../scss/containers/fieldactivity.scss';
import { translate } from 'react-i18next';
const SearchInput = ({getSearch = () => {}, t}) => {
  
  return (
    <div className="user-act-monitoring">
      <span className="act-mot-title">{t('LBL.ACTIVITYMON')}</span>
      <div className="field-activity-input">
        <i className="fa fa-search"></i>
        <Input placeholder="Search Employee.." onChange={(e) => getSearch(e)} />
      </div>
    </div>
  );
};

export default (translate('global')(SearchInput));
