/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase, object-shorthand */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import ReactExport from 'react-export-excel';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

// let compthr = '';
class ReportAdjustment extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      arrEmployee: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '10%', '20%', '20%', '10%', '10%', '10%', '20%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      dataexcel: [],
      headerexcel: [],
      type: '',
      displayLoad: 'none',
      displaySave: '',
      office: '',
      org_id: '',
      emp_id: '',
      adjustment_end_date: '',
      adjustment_start_date: '',
      updated: false,
      period_start: '',
      period_end: '',
      org: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.EMPLOYEE_CODE') },
      { '2': t('LBL.EMPLOYEE_NAME') },
      { '3': t('LBL.UNIT_ORGANIZATION') },
      { '4': t('LBL.JOB_TITLE') },
      { '5': t('LBL.START_DATE') },
      { '6': t('LBL.END_DATE') },
      { '7': t('LBL.BASIC_SALARY') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.getTableData = this.getTableData.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.EMPLOYEE_CODE') },
      { '2': t('LBL.EMPLOYEE_NAME') },
      { '3': t('LBL.UNIT_ORGANIZATION') },
      { '4': t('LBL.JOB_TITLE') },
      { '5': t('LBL.START_DATE') },
      { '6': t('LBL.END_DATE') },
      { '7': t('LBL.BASIC_SALARY') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      // this.getTableData();
      this.loadSelectBox();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  };

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/employee/getAllItemOrg/${org}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ arrEmployee: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgoffice/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          this.setState({ orgoffice: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgcompany/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.cmp_id, label: obj.company }),
          );
          this.setState({ company: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ orgorganization: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/employee/getAllItemOrg/0`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ arrEmployee: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSubmitInput(values) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const start = values.start_date.format('YYYY-MM-DD');
    const end = values.end_date.format('YYYY-MM-DD');
    let office;
    let org;
    let employee;
    if (
      typeof values.orgorganization === 'undefined' ||
      values.orgorganization === null
    ) {
      org = '';
    } else {
      org =
        typeof values.orgorganization === 'object'
          ? values.orgorganization.value
          : values.orgorganization;
    }

    if (typeof values.orgoffice === 'undefined' || values.orgoffice === null) {
      office = '';
    } else {
      office =
        typeof values.orgoffice === 'object'
          ? values.orgoffice.value
          : values.orgoffice;
    }

    if (typeof values.employee === 'undefined' || values.employee === null) {
      employee = '';
    } else {
      employee =
        typeof values.employee === 'object'
          ? values.employee.value
          : values.employee;
    }
    const conds = {
      org_id: org,
      off_id: office,
      emp_id: employee,
      adjustment_start_date: start,
      adjustment_end_date: end,
      report_type: values.type_report.value
    };
    const bodyParameters = {
      cond: conds,
    };

    this.setState({ displayLoad: '', displaySave: 'none' });
    this.setState({ period_start: start });
    this.setState({ period_end: end });
    this.setState({ org_id: org });
    this.setState({ office: office });
    this.setState({ emp_id: employee });
    // const row = [];
    const url = `${apiws}/payadjustment/getReport/`;
    axios.post(url, bodyParameters, config).then((response) => {
      if (response.data.status === 'ok') {
        if (response.data.data.length === 0) {
          showNotifications('Success', 'Empty Data', 'success');
          this.setState({ urlData: `${apiws}/payadjustment/getReport` });
          this.setState({ displayLoad: 'none', displaySave: '' });
          this.setState({ rows: [] });
          if (values.type_report.value === '1') {
            this.getTableData();
          }
        } else {
          if (values.type_report.value === '1') {
            this.setState({ urlData: `${apiws}/payadjustment/getReport` });
            this.setState({ updated: true });
            if (this.state.updated) {
              this.setState({ displayLoad: 'none', displaySave: '' });
              this.getTableData();
              showNotifications('Success', 'Available Data', 'success');
              this.setState({ updated: false });
            }
          } else {
            const { data, header } = response.data;
            const array = [];
            data.forEach((obj, index) => {
              let arrFix = [];
              for (const [key, value] of Object.entries(obj)) {
                header.map((item) => {
                  if (key == item) {
                    arrFix = { ...arrFix, [item]: value };
                  }
                });
              }
              array.push({
                No: index + 1,
                'Employee Code': obj.emp_code,
                'Employee Name': obj.emp_name,
                'Organizational Unit': obj.unit_organization,
                'Job Title': obj.job_level,
                'Start Date': obj.adjustment_start_date
                  ? moment(obj.adjustment_start_date).format('DD/MM/YYYY')
                  : '-',
                'End Date': obj.adjustment_end_date
                  ? moment(obj.adjustment_end_date).format('DD/MM/YYYY')
                  : '-',
                ...arrFix,
              });
              arrFix = [];
            });

            this.setState({ type: '2', dataexcel: array, headerexcel: header });
            this.setState({ updated: true });
            if (this.state.updated) {
              this.setState({ displayLoad: 'none', displaySave: '' });
              this.inputElement.click();
              showNotifications('Success', 'Available Data', 'success');
              this.setState({ updated: false });
            }
          }
        }
      } else {
        showNotifications('Fail', 'Failed', 'danger');
        this.setState({ displayLoad: 'none', displaySave: '' });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      orgorganization,
      orgoffice,
      urlData,
      company,
      displayLoad,
      displaySave,
      arrEmployee,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card style={{ paddingLeft: '15px' }}>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">
                      {t('LBL.PAYROLL')} - {t('LBL.ADJUSTMENT')}
                    </h3>
                    <h3 className="page-subhead subhead">
                      {t('LBL.VIEW_ADJUSTMENT')}
                    </h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form--horizontal mt15"
                      onSubmit={handleSubmit(this.handleSubmitInput)}
                    >
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">
                                {t('LBL.ORGANIZATION')}
                              </span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="orgoffice"
                                  component={renderSelectField}
                                  type="text"
                                  options={orgoffice}
                                  placeholder="Office"
                                />
                                <Field
                                  name="orgorganization"
                                  component={renderSelectField}
                                  type="text"
                                  options={orgorganization}
                                  placeholder="Unit Organization"
                                  onChange={(val) => {
                                    if (typeof val.value === 'undefined') {
                                      this.setState({ org: '' });
                                      setTimeout(() => {
                                        this.getEmployee();
                                      }, 200);
                                    } else {
                                      this.setState({ org: val.value });
                                      setTimeout(() => {
                                        this.getEmployee();
                                      }, 200);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.EMPLOYEE')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmployee}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">
                                {t('LBL.PERIOD')}
                              </span>
                              <div className="form__form-group-field range-select-form">
                                <Field
                                  name="start_date"
                                  component={renderDatePickerField}
                                  placeholder="Start Date"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                                <span
                                  className="form__form-group-label"
                                  style={{ 'margin-left': '10px' }}
                                >
                                  {' '}
                                  To
                                </span>
                                <Field
                                  name="end_date"
                                  component={renderDatePickerField}
                                  type="text"
                                  placeholder="End Date"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.TYPE_REPORT')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  id="typeAttdReport"
                                  name="type_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'View' },
                                    { value: '2', label: 'Excel' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {this.state.type === '2' && (
                          <ExcelFile
                            element={
                              <div
                                onClick={this.handleClick}
                                onKeyPress={this.handleClick}
                                role="button"
                                tabIndex="0"
                              >
                                <input
                                  type="hidden"
                                  ref={(input) => (this.inputElement = input)}
                                />
                              </div>
                            }
                            filename="Report Adjustment"
                          >
                            <ExcelSheet
                              data={this.state.dataexcel}
                              name="Employees"
                            >
                              {this.state.headerexcel.map((obj) => (
                                <ExcelColumn label={obj} value={obj} />
                              ))}
                            </ExcelSheet>
                          </ExcelFile>
                        )}
                        <ButtonToolbar className="form__button-toolbar">
                          {/* <Button color="secondary" type="button">Cancel</Button> */}
                          <Button
                            color="primary"
                            type="submit"
                            style={{ display: displaySave }}
                          >
                            {t('FRM.SUBMIT')}
                          </Button>
                          <Button
                            color="primary"
                            style={{ display: displayLoad }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: '6px' }}
                            />
                            {t('FRM.LOADING')}
                          </Button>
                        </ButtonToolbar>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row className="m5 table-nopadding">
                  <div className="card__title">
                    <h5 className="bold-text">
                      {t('MEN.REPORT')} {t('LBL.ADJUSTMENT')}
                    </h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    primaryKey="pad_id"
                    widthTable={this.state.dataWidth}
                    displayStyleHead="none"
                    buttonAction="noButton"
                    conds={{
                      emp_id: this.state.emp_id,
                      adjustment_start_date: this.state.period_start,
                      adjustment_end_date: this.state.period_end,
                      off_id: this.state.office,
                      org_id: this.state.org_id,
                    }}
                    onRef={(ref) => (this.child = ref)}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Adjustment Report</h5>
                </div>
                <Table responsive className="table--bordered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Employee Code</th>
                      <th>Employee Name</th>
                      <th>Unit Organization</th>
                      <th>Job Title</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>{
                    this.state.rows.map(data => (
                      <tr>
                        {Object.entries(data).map(([name, value]) => (<td key={name} >{value}</td>))
                        }
                      </tr>
                    ))
                  }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date || values.start_date === null) {
    errors.start_date = 'Period shouldn’t be empty';
  }
  if (!values.end_date || values.end_date === null) {
    errors.end_date = 'Period shouldn’t be empty';
  }
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'reportadj',
  validate,
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(ReportAdjustment));
