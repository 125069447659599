/* eslint-disable array-callback-return */
/*
 * Page Job Name
 * Notes: Master Data Job Name
 * @author Fadri
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 26/11/2019
 * Note Update : Update validation, Data table every function crud and auto pilihan data job level
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import utils from '../../../../utils';

let jobNameExist = [];
class JobNamePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    inUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.JOB_NAME') },
      { '2': t('LBL.JOB_LEVEL') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '50%', '25%', '20%'],
      arrJobLevel: [],
      urlData: [],
      statusEdit: false,
      addcond: '',
      editcond: '',
      deletecond: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getJobLevel = this.getJobLevel.bind(this);
    this.editJobname = this.editJobname.bind(this);
    this.deleteJobname = this.deleteJobname.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getJobLevel();
      this.getJobName();
      this.getButton();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/orgjobname/getAllItem` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.JOB_NAME') },
      { '2': t('LBL.JOB_LEVEL') },
      { '3': t('FRM.FUNCTION') },
    ];
    const { onUpdate } = this.props;
    if (onUpdate) {
      this.getJobLevel();
      this.props.inUpdate();
    }
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getJobLevel() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgjoblevel/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.job_id, label: obj.job_level }),
          );
          this.setState({ arrJobLevel: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getJobName() {
    const { token, apiws } = this.state;
    jobNameExist = [];
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/orgjobname/getAll`, '', config).then((res) => {
      const dataResponse = res.data.data;
      for (let i = 0; i < dataResponse.length; i += 1) {
        jobNameExist[i] = dataResponse[i].job_name;
      }
    });
  }

  toggle(item) {
    if (item === 'add') {
      this.getJobName();
    }
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let error = true;
    const errorJobName = jobNameExist;
    if (!values.job_name) {
      error = false;
    }
    if (!values.job_id) {
      error = false;
    }
    if (errorJobName.includes(values.job_name)) {
      error = false;
    }
    let url;
    let data;
    const userid = ls.get('user_cred').usr_id;
    const apiws = ls.get('ws_ip');
    const id = values.jbn_id;
    const jobId =
      typeof values.job_id === 'object' ? values.job_id.value : values.job_id;
    const mappingData = {
      jbn_id: values.jbn_id,
      job_id: jobId,
      job_name: values.job_name,
    };
    if (id === undefined || id === '') {
      url = `${apiws}/orgjobname/saveItem`;
      data = { ...mappingData, cuser_id: userid };
    } else {
      url = `${apiws}/orgjobname/updateItem/${id}`;
      data = { ...mappingData, muser_id: userid };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.child.reload('addedit');
            this.toggle();
            this.getJobName();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  editJobname(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgjobname/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const indexJobName = jobNameExist.indexOf(
            getResponseData[0].job_name,
          );
          if (indexJobName !== -1) {
            jobNameExist.splice(indexJobName, 1);
          }
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(getResponseData[0]);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteJobname(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/orgjobname/deleteitem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
                this.props.onDelete();
                this.getJobName();
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { statusEdit, arrJobLevel, urlData } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.JOB_NAME')}
      </Button>
    );
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addjobnameform"
          disabled={pristine}
        >
          {' '}
          Edit
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addjobnameform"
          type="submit"
          disabled={pristine}
        >
          Save
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="jbn_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteJobname}
                  updateFunc={this.editJobname}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  displayTitle="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.JOB_NAME')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addjobnameform"
                  id="addjobnameform"
                >
                  <input type="hidden" value="" name="jbn_id" />
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.JOB_LEVEL')}
                    </span>
                    <div className="form__form-group-field">
                      <input type="hidden" value="" name="jbn_id" />
                      <Field
                        name="job_id"
                        id="job_id"
                        component={renderSelectField}
                        type="text"
                        options={arrJobLevel}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.JOB_NAME')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="job_name"
                        component={renderTextInput}
                        type="text"
                        maxLength="160"
                      />
                    </div>
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    job_name: {
      fieldLabel: 'Job name',
      required: true,
      maxLength: 150,
    },
    job_id: {
      fieldLabel: 'Job level',
      required: true,
    },
  };
  const errors = utils.validate(validation, values);

  const errorJobName = jobNameExist;
  if (errorJobName.includes(values.job_name)) {
    errors.job_name = 'Job Name is already taken';
  }
  return errors;
};

export default reduxForm({
  form: 'jobnameforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(JobNamePage));
