/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase, no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../notificationMessages';
import { translate } from 'react-i18next';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Leavesubmission from '../../SP_File/leavesubmission';
import Overtimesubmission from '../../SP_File/overtimesubmission';
import Attendancesubmission from '../../SP_File/attendancesubmission';
import Claimsubmission from '../../SP_File/claimsubmission';
import utils from '../../../../../../utils';
import Swal from 'sweetalert2';
// import ReactDynamicImport from 'react-dynamic-import';
// import Iframe from './components/iframe.js';

const UserDefault = '/img/user-default-photo.png';

let overtimeExist = [];
let attendanceExist = [];
let leaveExist = [];
const leaveHist = [];
let day = 0;
let type = '';

class submissionForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
    // pristine: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.ACTIVITY') },
      { 2: t('LBL.OPC_STATUS') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      emp_id: '',
      redirect: false,
      addcond: '',
      displayLoad: 'none',
      displaySave: '',
      wfl_data: [],
      arrHistory: [],
      wfl_checklist: [],
      sp_file: '',
      duration: '',
      post: [],
      zindextyp: '9999',
      zindexdate: '0',
      touched: false,
      totaldays: 0,
      type: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.btnClick = this.btnClick.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getOvertimeReqDate = this.getOvertimeReqDate.bind(this);
    this.getAttendanceReqDate = this.getAttendanceReqDate.bind(this);
    this.getLeaveReqDate = this.getLeaveReqDate.bind(this);
    this.getClaimReq = this.getClaimReq.bind(this);
  }

  componentDidMount = () => {
    console.log(this.props.location);
    overtimeExist = [];
    attendanceExist = [];
    leaveExist = [];
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ sp_file: this.props.location.state.sp_file });
    this.props.initialize({
      wfl_check: this.props.location.state.dt_checklist,
    });
    // console.log(this.props.location.state.sp_file);
    if (this.props.location.state.sp_file === '/leavesubmission') {
      this.getWorkflowLeave();
      this.getLeaveReqDate();
    } else if (this.props.location.state.sp_file === '/attendancesubmission') {
      this.getWorkflowAttendance();
      this.getAttendanceReqDate();
    } else if (this.props.location.state.sp_file === '/overtimesubmission') {
      this.getWorkflowOvertime();
      this.getOvertimeReqDate();
    } else if (this.props.location.state.sp_file === '/claimsubmission') {
      this.getWorkflowClaim();
      this.getClaimReq();
    }
  };

  getWorkflowAttendance() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .get(`${ls.get('ws_ip')}/workflow/getworkflowattendance/`, config)
      .then((resemp) => {
        console.log(resemp.data.data, 'res data');
        const dt_att = resemp.data.data.attendance;
        const dt_checklist = resemp.data.data.checklist;
        const array_wfl = [];
        const array_checklist = [];
        // eslint-disable-next-line array-callback-return
        dt_att.map((obj) =>
          array_wfl.push({
            wfl_id: obj.wfl_id,
            wfa_id: obj.wfa_id,
            workflow: obj.workflow,
            activity: obj.activity,
          }),
        );
        dt_checklist.map((obj) => array_checklist.push(obj));
        this.setState({
          wfl_data: array_wfl,
          wfl_checklist: array_checklist,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getWorkflowOvertime() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .get(`${ls.get('ws_ip')}/workflow/getworkflowovertime/`, config)
      .then((resemp) => {
        const dt_overtime = resemp.data.data.overtime;
        const dt_checklist = resemp.data.data.checklist;
        const array_wfl = [];
        const array_checklist = [];
        // eslint-disable-next-line array-callback-return
        dt_overtime.map((obj) =>
          array_wfl.push({
            wfl_id: obj.wfl_id,
            wfa_id: obj.wfa_id,
            workflow: obj.workflow,
            activity: obj.activity,
          }),
        );
        dt_checklist.map((obj) => array_checklist.push(obj));
        this.setState({
          wfl_data: array_wfl,
          wfl_checklist: array_checklist,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getWorkflowClaim() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .get(`${ls.get('ws_ip')}/workflow/getworkflowclaim/`, config)
      .then((resemp) => {
        const dt_claim = resemp.data.data.attendance;
        const dt_checklist = resemp.data.data.checklist;
        const array_wfl = [];
        const array_checklist = [];
        // eslint-disable-next-line array-callback-return
        dt_claim.map((obj) =>
          array_wfl.push({
            wfl_id: obj.wfl_id,
            wfa_id: obj.wfa_id,
            workflow: obj.workflow,
            activity: obj.activity,
          }),
        );
        dt_checklist.map((obj) => array_checklist.push(obj));
        this.setState({
          wfl_data: array_wfl,
          wfl_checklist: array_checklist,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getWorkflowLeave() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .get(`${ls.get('ws_ip')}/workflow/getworkflowleave/`, config)
      .then((resemp) => {
        const dt_leave = resemp.data.data.leave;
        const array_wfl = [];
        const array_checklist = [];
        // eslint-disable-next-line array-callback-return
        dt_leave.map((obj) =>
          array_wfl.push({
            wfl_id: obj.wfl_id,
            wfa_id: obj.wfa_id,
            workflow: obj.workflow,
            activity: obj.activity,
          }),
        );
        this.setState({ wfl_data: array_wfl });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getValue(value, newValue, previousValue, name) {
    this.setState((prevState) => ({
      post: { ...prevState.post, [name]: newValue },
    }));
    // if (name === 'overtime_end_time') {
    //   const calculation = moment(this.state.post.overtime_end_time) - moment(this.state.post.overtime_start_time);
    // }
    setTimeout(() => {
      this.getTotalDay(this.state.post);
    }, 100);
    setTimeout(() => {
      const total_days = day;
      const start = moment(this.state.post.overtime_start_time); // some random moment in time (in ms)
      const end = moment(this.state.post.overtime_end_time); // some random moment after start (in ms)
      const diff = end.diff(start);

      // execution
      const duration = moment.utc(diff).format('HH:mm');
      this.setState((prevState) => ({
        post: {
          ...prevState.post,
          duration,
          total_days,
          type,
        },
      }));
      if (name === 'leave_type') {
        this.setState({ zindextyp: 'auto' });
        this.setState({ zindexdate: 'auto' });
      } else if (name === 'start_date') {
        this.setState({ zindexdate: 'auto' });
        this.setState({ zindextyp: 'auto' });
      }

      if (
        value?.leave_type?.leave_taken_type == '1' ||
        this.state.post?.leave_type?.leave_taken_type == '1'
      ) {
        // setTimeout(() => {
        this.setState((prevState) => ({
          post: {
            ...prevState.post,
            leave_day_type: '',
          },
        }));
        // }, 100);
      }
    }, 1000);
  }

  getOvertimeReqDate() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { emp_id } = ls.get('user_cred');
    const dataBody = {
      emp_id,
    };
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/Empovertimerequest/getovertimeexist/`,
        formBody,
        config,
      )
      .then((response) => {
        const dataReq = response.data.data.datacase;
        const dataHist = response.data.data.datahist;
        if (dataReq.length !== 0) {
          Swal.fire({
            title: 'Info',
            html:
              'You already have pending requests, <br>Monitoring your request!',
            type: 'info',
            showConfirmButton: false,
            timer: 3000,
          });
          this.setState({ redirect: true });
        } else {
          // Arr.push({ value: 0, label: 'Unit Organization' });
          dataHist.map((obj) => overtimeExist.push(obj.overtime_date));
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getAttendanceReqDate() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { emp_id } = ls.get('user_cred');
    const dataBody = {
      emp_id,
    };
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/Attdispensationrequest/getattendanceexist/`,
        formBody,
        config,
      )
      .then((response) => {
        const dataReq = response.data.data.datacase;
        const dataHist = response.data.data.datahist;

        if (dataReq.length !== 0) {
          Swal.fire({
            title: 'Info',
            html:
              'You already have pending requests, <br>Monitoring your request!',
            type: 'info',
            showConfirmButton: false,
            timer: 3000,
          });
          this.setState({ redirect: true });
        } else {
          dataHist.map((obj) => {
            attendanceExist.push(moment(obj.date).format('YYYY-MM-DD'));
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getLeaveReqDate() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { emp_id } = ls.get('user_cred');
    const dataBody = {
      emp_id,
    };
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/Empleaverequest/getLeavereqExist`,
        formBody,
        config,
      )
      .then((response) => {
        const dataReq = response.data.data.datacase;
        const dataHist = response.data.data.datahist;
        const Arr = [];
        if (dataReq.length !== 0) {
          Arr.push({ value: 0, label: 'Unit Organization' });
          Swal.fire({
            title: 'Info',
            html:
              'You already have pending requests, <br>Monitoring your request!',
            type: 'info',
            showConfirmButton: false,
            timer: 3000,
          });
          this.setState({ redirect: true });
        } else {
          dataHist.map((obj) => {
            leaveHist.push({
              leave_start: obj.start_date,
              leave_end: obj.end_date,
            });
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getClaimReq() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { emp_id } = ls.get('user_cred');
    const dataBody = {
      emp_id,
    };
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/Appreimbursement/getPendingRequest`,
        formBody,
        config,
      )
      .then((response) => {
        const dataResponse = response.data.data[0];
        if (dataResponse.total > 0) {
          showNotifications(
            'Warning',
            'You still have pending claim request',
            'warning',
          );
          this.setState({ redirect: true });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTotalDay = (post) => {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const data = {
      emp_id: ls.get('user_cred').emp_id,
      // wfl_id: post.leave_type.wfl,
      // atl_id: post.leave_type.value,
      start_date: moment(post.start_date).format('YYYY-MM-DD'),
      end_date: moment(post.end_date).format('YYYY-MM-DD'),
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(`${ls.get('ws_ip')}/workflow/generateTotalDay`, formBody, config)
      .then((response) => {
        day = response.data.total;
        // eslint-disable-next-line prefer-destructuring
        type = response.data.type;
      })
      .catch(() => {
        // console.log(error);
      });
  };

  linkBack() {
    this.setState({ redirect: true });
  }

  btnClick(val) {
    this.setState({ action: val });
    // this.state ={data: 'sds'};
  }

  handleSubmit(values) {
    // console.log(this.state.post);
    // return;
    this.props.destroy();
    // console.log(values);
    const { wfl_data, post, sp_file } = this.state;
    const { wfa_id } = wfl_data[0];
    const { wfl_id } = wfl_data[0];
    const { emp_id } = ls.get('user_cred');
    const userid = ls.get('user_cred').usr_id;
    const { apiws, token } = this.state;
    const wfl_checklist = [];
    let url;
    let data;
    const { desc } = values;
    let error = false;
    let formBody;
    if (sp_file === '/overtimesubmission') {
      if (typeof post.oct_id === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (
        post.overtime_date === null ||
        typeof post.overtime_date === 'undefined'
      ) {
        error = true;
        this.setState({ touched: true });
      }
      if (
        post.overtime_start_time === null ||
        typeof post.overtime_start_time === 'undefined'
      ) {
        error = true;
        this.setState({ touched: true });
      }
      if (
        post.overtime_end_time === null ||
        typeof post.overtime_end_time === 'undefined'
      ) {
        error = true;
        this.setState({ touched: true });
      }
      if (post.reason === '' || typeof post.reason === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (error === false) {
        const ot = {
          oct_id:
            typeof post.oct_id === 'undefined' || post.oct_id === null
              ? ''
              : post.oct_id.value,
          overtime_date:
            typeof post.overtime_date === 'undefined' ||
            post.overtime_date === null
              ? null
              : moment(post.overtime_date).format('YYYY-MM-DD'),
          overtime_start_time:
            typeof post.overtime_start_time === 'undefined' ||
            post.overtime_start_time === null
              ? null
              : moment(post.overtime_start_time).format('HH:mm'),
          overtime_end_time:
            typeof post.overtime_end_time === 'undefined' ||
            post.overtime_end_time === null
              ? null
              : moment(post.overtime_end_time).format('HH:mm'),
          reason:
            typeof post.reason === 'undefined' || post.reason === null
              ? ''
              : post.reason,
          duration:
            typeof post.duration === 'undefined' || post.duration === null
              ? ''
              : post.duration,
        };
        const request = JSON.stringify(ot);
        url = `${apiws}/workflow/generateovertimecase`;
        data = {
          data_request: request,
          wfl_id,
          wfa_id,
          sp_file,
          emp_id,
          desc,
          cuser_id: userid,
        };
      }
    }
    if (sp_file === '/attendancesubmission') {
      const time_in =
        post.time_in === null || typeof post.time_in === 'undefined';
      const time_out =
        post.time_out === null || typeof post.time_out === 'undefined';
      const time = time_in && time_out;
      if (typeof post.adr_id === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (post.date === null || typeof post.date === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (time === true) {
        error = true;
        this.setState({ touched: true });
      }
      if (post.reason === '' || typeof post.reason === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (error === false) {
        const ot = {
          adr_id:
            typeof post.adr_id === 'undefined' || post.adr_id === null
              ? ''
              : post.adr_id.value,
          att_date_in:
            typeof post.date === 'undefined' || post.date === null
              ? null
              : moment(post.date).format('YYYY-MM-DD'),
          att_date_out:
            typeof post.date === 'undefined' || post.date === null
              ? null
              : moment(post.date).format('YYYY-MM-DD'),
          date:
            typeof post.date === 'undefined' || post.date === null
              ? null
              : moment(post.date).format('YYYY-MM-DD'),
          att_time_in:
            typeof post.time_in === 'undefined' || post.time_in === null
              ? ''
              : moment(post.time_in).format('HH:mm'),
          att_time_out:
            typeof post.time_out === 'undefined' || post.time_out === null
              ? ''
              : moment(post.time_out).format('HH:mm'),
          reason:
            typeof post.reason === 'undefined' || post.reason === null
              ? ''
              : post.reason,
        };
        const request = JSON.stringify(ot);
        url = `${apiws}/workflow/generateattendancecase`;
        data = {
          data_request: request,
          wfl_id,
          wfa_id,
          // data_checklist: wfl_check,
          sp_file,
          emp_id,
          desc,
          cuser_id: userid,
        };
      }
    }
    if (sp_file === '/leavesubmission') {
      if (typeof post.leave_type === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      // else {
      //   if (
      //     post.leave_type.leave_taken_type != 1 &&
      //     typeof post.leave_day_type === 'undefined'
      //   ) {
      //     error = true;
      //     this.setState({ touched: true });
      //   }

      //   if (
      //     post.leave_type.is_attachement_required == 't' &&
      //     !post.attachment
      //   ) {
      //     error = true;
      //     this.setState({ touched: true });
      //   }
      // }
      if (post.start_date === null || typeof post.start_date === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (post.end_date === null || typeof post.end_date === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (post.remarks === '' || typeof post.remarks === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (moment(post.start_date).isAfter(post.end_date)) {
        error = true;
        this.setState({ touched: true });
      }

      if (error === false) {
        const ot = {
          wfl_id: post.leave_type.wfl,
          atl_id: post.leave_type.value,
          start_date: moment(post.start_date).format('YYYY-MM-DD'),
          end_date: moment(post.end_date).format('YYYY-MM-DD'),
          error: true,
          remarks: typeof post.remarks === 'undefined' ? '' : post.remarks,
          leave_day_type:
            typeof post.leave_day_type === 'undefined'
              ? 1
              : post.leave_day_type.value,
        };
        const request = JSON.stringify(ot);
        url = `${apiws}/workflow/generateleavecase`;
        data = {
          data_request: request,
          wfl_id,
          wfa_id,
          sp_file,
          emp_id,
          desc,
          cuser_id: userid,
        };
      }
    }
    if (sp_file === '/claimsubmission') {
      if (typeof post.ect_id === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (post.claim_date === null || typeof post.claim_date === 'undefined') {
        error = true;
        this.setState({ touched: true });
      }
      if (
        post.claim_value === null ||
        typeof post.claim_value === 'undefined'
      ) {
        error = true;
        this.setState({ touched: true });
      }
      if (error === false) {
        const ot = {
          ect_id:
            typeof post.ect_id === 'undefined' || post.ect_id === null
              ? ''
              : post.ect_id.value,
          claim_date:
            typeof post.claim_date === 'undefined' || post.claim_date === null
              ? null
              : moment(post.claim_date).format('YYYY-MM-DD'),
          claim_value:
            typeof post.claim_value === 'undefined' || post.claim_value === null
              ? ''
              : post.claim_value,
          ecr_reason:
            typeof post.ecr_reason === 'undefined' || post.ecr_reason === null
              ? ''
              : post.ecr_reason,
        };
        const request = JSON.stringify(ot);
        // const attachmentVal = post.attachment;
        url = `${apiws}/workflow/generateclaimcase`;
        data = {
          data_request: request,
          wfl_id,
          wfa_id,
          // data_checklist: wfl_check,
          sp_file,
          emp_id,
          desc,
          // attachment: post.attachment[0],
          cuser_id: userid,
        };
      }
    }

    if (sp_file === '/claimsubmission') {
      if (error === false) {
        formBody = Object.keys(data).reduce((formData, key) => {
          formData.append(key, data[key]);
          return formData;
        }, new FormData());
        if (typeof post.attachment === 'object') {
          for (let ln = 0; ln < post.attachment.length; ln++) {
            formBody.append(`attachmentClaim${ln}`, post.attachment[ln]);
          }
        }
      }
    }
    // else if (error === false) {
    //   formBody = Object.keys(data).map(key =>
    //     `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    // }

    if (sp_file === '/leavesubmission') {
      if (error === false) {
        formBody = Object.keys(data).reduce((formData, key) => {
          formData.append(key, data[key]);
          return formData;
        }, new FormData());
        if (typeof post.attachment === 'object') {
          for (let ln = 0; ln < post.attachment.length; ln++) {
            formBody.append(`attachment_${ln}`, post.attachment[ln]);
          }
        }
      }
    }

    if (sp_file === '/attendancesubmission') {
      if (error === false) {
        formBody = Object.keys(data).reduce((formData, key) => {
          formData.append(key, data[key]);
          return formData;
        }, new FormData());
        if (typeof post.attachment === 'object') {
          for (let ln = 0; ln < post.attachment.length; ln++) {
            formBody.append(`attachment_${ln}`, post.attachment[ln]);
          }
        }
      }
    }

    if (sp_file === '/overtimesubmission') {
      if (error === false) {
        formBody = Object.keys(data).reduce((formData, key) => {
          formData.append(key, data[key]);
          return formData;
        }, new FormData());
        if (typeof post.attachment === 'object') {
          for (let ln = 0; ln < post.attachment.length; ln++) {
            formBody.append(`attachment_${ln}`, post.attachment[ln]);
          }
        }
      }
    }
    // else if (error === false) {
    //   formBody = Object.keys(data).map(key =>
    //     `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    // }

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        // 'Content-Type': sp_file === '/claimsubmission' ? 'multipart/form-data' : 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error === false) {
      console.log(formBody);
      this.setState({ displayLoad: '', displaySave: 'none' });
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            this.setState({ redirect: true, displayLoad: true });
            setTimeout(() => {
              this.props.destroy();
            }, 200);
          } else if (response.data.status === 'failed') {
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications('Fail', 'Save Failed', 'danger');
          } else if (response.data.status === 'already') {
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications(
              'Fail',
              'Submission was already submitted',
              'danger',
            );
          } else if (response.data.status === 'limit') {
            let limitMsg;
            if (sp_file === '/claimsubmission') {
              limitMsg = `${this.props.t('LBL.CLAIM_LIMIT')} ${this.props.t(
                'LBL.EXCEED',
              )}`;
            } else if (sp_file === '/leavesubmission') {
              limitMsg = `${this.props.t('LBL.LEAVE_LIMIT')} ${this.props.t(
                'LBL.EXCEED',
              )}`;
            }
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications('Fail', limitMsg, 'danger');
          } else if (response.data.status === 'limit_access') {
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications(
              'Fail',
              'Your submission limit has exceeded the maximum limit',
              'warning',
            );
          } else if (response.data.status === 'not_eligible') {
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications(
              'Warning',
              'You are not allowed to make overtime requests',
              'warning',
            );
          } else {
            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications('Fail', response.data.status, 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { t, location } = this.props;
    const { displayLoad, displaySave } = this.state;
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div className="header-no-bg">
                  {this.state.sp_file === '/leavesubmission' && (
                    <h4 className="title-label">{t('LBL.LEAVE_SUBMISSION')}</h4>
                  )}
                  {this.state.sp_file === '/overtimesubmission' && (
                    <h4 className="title-label">
                      {t('LBL.OVERTIME_SUBMISSION')}
                    </h4>
                  )}
                  {this.state.sp_file === '/attendancesubmission' && (
                    <h4 className="title-label">
                      {t('LBL.ATTENDANCE_SUBMISSION')}
                    </h4>
                  )}
                  {this.state.sp_file === '/claimsubmission' && (
                    <h4 className="title-label">{t('LBL.CLAIM_SUBMISSION')}</h4>
                  )}
                  <p className="sub-label">{t('LBL.FILL_OUT')}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="card-login-info">
                        <div className="d-flex">
                          <div className="user-profile-login">
                            <img src={UserDefault} alt="user" />
                          </div>
                          <div className="w-100">
                            <h4 className="username-login">
                              {t('LBL.LOGIN_AS')}{' '}
                              {`${ls.get('user_cred').first_name} ${
                                ls.get('user_cred').last_name
                              }`}
                            </h4>
                            <p className="caption-label">
                              {t('LBL.REQUEST_DATE')} :{' '}
                              {moment(new Date()).format('DD/MM/YYYY')}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h4 className="sub-header-label">
                      {t('LBL.SUBMISSION_FORM')}
                    </h4>
                    {this.state.sp_file === '/leavesubmission' && (
                      <Leavesubmission
                        disabled={this.state.touched}
                        getValue={this.getValue}
                        value={this.state.post}
                        validate={validate}
                        zindextyp={this.state.zindextyp}
                        zindexdate={this.state.zindexdate}
                      />
                    )}
                    {this.state.sp_file === '/overtimesubmission' && (
                      <Overtimesubmission
                        disabled={this.state.touched}
                        getValue={this.getValue}
                        value={this.state.post}
                        validate={validate}
                      />
                    )}
                    {this.state.sp_file === '/attendancesubmission' && (
                      <Attendancesubmission
                        disabled={this.state.touched}
                        getValue={this.getValue}
                        validate={validate}
                      />
                    )}
                    {this.state.sp_file === '/claimsubmission' && (
                      <Claimsubmission
                        disabled={this.state.touched}
                        getValue={this.getValue}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <div className="ticket">
                  <div className="ticket__wrapper">
                    <div className="ticket__header">
                      {t('LBL.SUBMISSION_DETAIL')}
                    </div>
                  </div>
                  <div className="ticket__divider">
                    <div className="ticket__notch" />
                    <div className="ticket__notch ticket__notch--right" />
                  </div>
                  <div className="ticket__body">
                    <section className="ticket__section">
                      <p>{t('LBL.REQUESTER')}</p>
                      <h4>{`${ls.get('user_cred').first_name} ${
                        ls.get('user_cred').last_name
                      }`}</h4>
                    </section>
                    <section className="ticket__section">
                      {this.state.sp_file === '/leavesubmission' && (
                        <div>
                          <p>{t('LBL.LEAVE_TYPE')}</p>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post?.leave_type?.label ?? '-'}
                          </h4>
                        </div>
                      )}
                      {this.state.sp_file === '/overtimesubmission' && (
                        <div>
                          <p>{t('LBL.OVERTIME_TYPE')}</p>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post?.oct_id?.label ?? '-'}
                          </h4>
                        </div>
                      )}
                      {this.state.sp_file === '/attendancesubmission' && (
                        <div>
                          <p>{t('LBL.ATTENDANCE_REASON')}</p>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post?.adr_id?.label ?? '-'}
                          </h4>
                        </div>
                      )}
                      {this.state.sp_file === '/claimsubmission' && (
                        <div>
                          <p>{t('LBL.CLAIM_TYPE')}</p>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post?.ect_id?.label ?? '-'}
                          </h4>
                        </div>
                      )}
                    </section>
                    <section className="ticket__section">
                      <p>{t('LBL.DATE')}</p>
                      {this.state.sp_file === '/leavesubmission' && (
                        <h4 style={{ fontSize: '14.4px' }}>
                          {this.state.post.start_date
                            ? moment(this.state.post.start_date).format(
                                'DD/MM/YYYY',
                              )
                            : ''}{' '}
                          -{' '}
                          {this.state.post.end_date
                            ? moment(this.state.post.end_date).format(
                                'DD/MM/YYYY',
                              )
                            : ''}
                        </h4>
                      )}
                      {this.state.sp_file === '/overtimesubmission' && (
                        <div>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post.overtime_date
                              ? moment(this.state.post.overtime_date).format(
                                  'DD/MM/YYYY',
                                )
                              : ''}{' '}
                            -{' '}
                            {this.state.post.overtime_start_time
                              ? `${moment(
                                  this.state.post.overtime_start_time,
                                ).format('HH:mm')} Until`
                              : ''}{' '}
                            {this.state.post.overtime_end_time
                              ? moment(
                                  this.state.post.overtime_end_time,
                                ).format('HH:mm')
                              : ''}
                          </h4>
                          <p>{t('LBL.DURATION')}</p>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post.duration}{' '}
                            {this.state.post.duration ? t('LBL.HOURS') : '-'}
                          </h4>
                        </div>
                      )}
                      {this.state.sp_file === '/attendancesubmission' && (
                        <div>
                          <h4 style={{ fontSize: '14.4px' }}>
                            {this.state.post.date
                              ? moment(this.state.post.date).format(
                                  'DD/MM/YYYY',
                                )
                              : '-'}
                          </h4>
                          {typeof this.state.post.time_in === 'undefined' ||
                          this.state.post.time_in === null ? (
                            ''
                          ) : (
                            <p>{t('LBL.TIME_IN')}</p>
                          )}
                          <h4 style={{ fontSize: '14.4px' }}>
                            {typeof this.state.post.time_in === 'undefined' ||
                            this.state.post.time_in === null
                              ? ''
                              : moment(this.state.post.time_in).format('HH:mm')}
                          </h4>
                          {typeof this.state.post.time_out === 'undefined' ||
                          this.state.post.time_out === null ? (
                            ''
                          ) : (
                            <p>{t('LBL.TIME_OUT')}</p>
                          )}
                          <h4 style={{ fontSize: '14.4px' }}>
                            {typeof this.state.post.time_out === 'undefined' ||
                            this.state.post.time_out === null
                              ? ''
                              : moment(this.state.post.time_out).format(
                                  'HH:mm',
                                )}
                          </h4>
                        </div>
                      )}
                      {this.state.sp_file === '/claimsubmission' && (
                        <h4 style={{ fontSize: '14.4px' }}>
                          {this.state.post.claim_date
                            ? moment(this.state.post.claim_date).format(
                                'DD/MM/YYYY',
                              )
                            : '-'}
                        </h4>
                      )}
                    </section>
                    {/* {(this.state.sp_file !== '/claimsubmission') && ( */}
                    <section className="ticket__section">
                      {this.state.sp_file === '/claimsubmission' ||
                      this.state.sp_file === '/attendancesubmission' ? (
                        <p>{t('LBL.DESCRIPTION')}</p>
                      ) : (
                        <p>{t('LBL.REASON')}</p>
                      )}
                      {this.state.sp_file === '/leavesubmission' && (
                        <h4 style={{ fontSize: '14.4px' }}>
                          {this.state.post.remarks}
                        </h4>
                      )}
                      {(this.state.sp_file === '/overtimesubmission' ||
                        this.state.sp_file === '/attendancesubmission') && (
                        <h4 style={{ fontSize: '14.4px' }}>
                          {this.state.post.reason}
                        </h4>
                      )}
                      {this.state.sp_file === '/claimsubmission' && (
                        <h4 style={{ fontSize: '14.4px' }}>
                          {this.state.post.ecr_reason}
                        </h4>
                      )}
                      {this.state.post.remarks === undefined &&
                        this.state.post.reason === undefined &&
                        this.state.post.ecr_reason === undefined && (
                          <h4 style={{ fontSize: '14.4px' }}>-</h4>
                        )}
                    </section>
                    {/* )} */}
                  </div>
                  <footer className="ticket__footer">
                    <Button
                      color="secondary"
                      block
                      onClick={() => this.setState({ redirect: true })}
                    >
                      {t('FRM.BACK')}
                    </Button>
                    <Button
                      color="primary"
                      block
                      style={{ display: displayLoad, marginRight: '0px' }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      form="submissionFlow"
                      style={{ display: displaySave }}
                      // disabled={pristine}
                      onClick={this.handleSubmit}
                      block
                    >
                      {' '}
                      {t('FRM.SUBMIT')}
                    </Button>
                  </footer>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    overtime_date: {
      fieldLabel: 'Date',
      required: true,
    },
    overtime_start_time: {
      fieldLabel: 'Start time',
      required: true,
    },
    overtime_end_time: {
      fieldLabel: 'End time',
      required: true,
    },
    reason: {
      fieldLabel: 'Reason',
      required: true,
    },
    oct_id: {
      fieldLabel: 'Overtime Type',
      required: true,
    },
    remarks: {
      fieldLabel: 'Reason',
      required: true,
    },
    start_date: {
      fieldLabel: 'Start Date',
      required: true,
    },
    end_date: {
      fieldLabel: 'End date',
      required: true,
      lockDateAfter: 'start_date',
      fieldLabelBefore: 'Start Date',
    },
    leave_type: {
      fieldLabel: 'Leave Type',
      required: true,
    },
    leave_day_type: {
      fieldLabel: 'Leave Day Type',
      required: true,
    },
    attachment: {
      fieldLabel: 'Attachment',
      required: true,
    },
    date: {
      fieldLabel: 'Date',
      required: true,
    },
    adr_id: {
      fieldLabel: 'Dispensation Reason',
      required: true,
    },
    ect_id: {
      fieldLabel: 'Claim Name',
      required: true,
    },
    claim_date: {
      fieldLabel: 'Claim Date',
      required: true,
    },
    claim_value: {
      fieldLabel: 'Claim Amount',
      required: true,
    },
  };

  const errors = utils.validate(validation, values);
  if (
    typeof values.overtime_date !== 'undefined' &&
    values.overtime_date !== null
  ) {
    if (
      overtimeExist.indexOf(
        moment(values.overtime_date).format('YYYY-MM-DD'),
      ) !== -1
    ) {
      errors.overtime_date = 'Date has been requested';
    }
  }

  if (!values.time_in && !values.time_out) {
    errors.time_out = 'One of Time shouldnt be empty';
  }
  if (typeof values.start_date !== 'undefined' && values.start_date !== null) {
    leaveHist.forEach((element) => {
      if (
        moment(values.start_date) >= moment(element.leave_start) &&
        moment(values.end_date) <= moment(element.leave_end)
      ) {
        errors.start_date = `${element.leave_start} and ${element.leave_end} has been submited`;
      }
    });
  }
  if (typeof values.start_date !== 'undefined' && values.start_date !== null) {
    leaveHist.forEach((element) => {
      if (
        moment(values.start_date) <= moment(element.leave_start) &&
        moment(values.end_date) >= moment(element.leave_end)
      ) {
        errors.start_date = `${element.leave_start} and ${element.leave_end} has been submited`;
      }
    });
  }
  if (typeof values.end_date !== 'undefined' && values.end_date !== null) {
    leaveHist.forEach((element) => {
      if (
        moment(values.start_date) >= moment(element.leave_start) &&
        moment(values.start_date) <= moment(element.leave_end) &&
        moment(values.end_date) >= moment(element.leave_end)
      ) {
        errors.end_date = `${element.leave_start} and ${element.leave_end} has been submited`;
      }
    });
  }
  if (typeof values.start_date !== 'undefined' && values.start_date !== null) {
    leaveHist.forEach((element) => {
      if (
        moment(values.start_date) <= moment(element.leave_start) &&
        moment(values.end_date) >= moment(element.leave_start) &&
        moment(values.end_date) <= moment(element.leave_end)
      ) {
        errors.start_date = `${element.leave_start} and ${element.leave_end} has been submited`;
      }
    });
  }
  if (!values.reason) {
    errors.reason = 'Reason shouldnt be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'submissionFlow', // a unique identifier for this form
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(submissionForm));
