/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import NumberFormat from 'react-number-format';

class RangeofAchievement extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    addFunc: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      componentptkp: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '40%', '40%', '10%'],
      statusEdit: false,
      urlData: '',
      pptvalue: '',
      editcond: '',
      addcond: '',
      deletecond: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.WEIGHT') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editRange = this.editRange.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deletePhase = this.deletePhase.bind(this);
    this.getPtkp = this.getPtkp.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.WEIGHT') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.props.destroy();
    this.setState({ pptvalue: '' });
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getPtkp();
      this.getButton();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({
      urlData: `${ls.get('ws_ip')}/pfmrangeofachievement/getAllitem`,
    });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  handleSubmitInput(values) {
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.pra_id;
    const userid = ls.get('user_cred').usr_id;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/pfmrangeofachievement/saveItem`;
      data = {
        ...values,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/pfmrangeofachievement/updateItem/${id}`;
      data = {
        ...values,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(url, formBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.setState({ modal: false });
        setTimeout(() => {
          this.child.reload();
        }, 2000);
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    });
  }

  async editRange(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/pfmrangeofachievement/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            pra_id: getResponseData[0].pra_id,
            achievement_name: getResponseData[0].achievement_name,
            achievement_weight: getResponseData[0].achievement_weight,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }

  getPtkp() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/rtbtaxstatus/getAll`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        const arr = [];
        response.data.data.map((obj) =>
          arr.push({ value: obj.rtx_id, label: obj.tax_status }),
        );
        this.setState({ componentptkp: arr });
      }
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledinput: false });
    this.setState({ statusedit: false });
    this.setState({ buttondisabled: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusedit: true });
  }

  deletePhase(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/pfmrangeofachievement/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine, addFunc } = this.props;
    const { statusEdit, urlData } = this.state;

    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.RANGE_OF_ACHIEVEMENT')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button color="success" type="submit" form="yearform">
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="yearform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pra_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editRange}
                  deleteFunc={this.deletePhase}
                  addFunc={addFunc}
                  buttonAction={['edit', 'delete']}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('LBL.RANGE_OF_ACHIEVEMENT')} {t('LBL.FORM')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="yearform"
                  id="yearform"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <input type="hidden" name="pra_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Col xs={12} md={6}>
                              <Field
                                name="achievement_name"
                                component={renderTextInput}
                                // placeholder="Phase Name"
                              />
                            </Col>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.WEIGHT')}
                          </span>
                          <div className="form__form-group-field">
                            <Col xs={12} md={6}>
                              <Field
                                name="achievement_weight"
                                component={renderTextInput}
                              />
                            </Col>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'yearform',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(RangeofAchievement));
