/* eslint-disable no-mixed-operators, max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class InputPhone extends PureComponent {
    static propTypes = {
      input: PropTypes.shape().isRequired,
      placeholder: PropTypes.string,
      disabled: PropTypes.bool,
      meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
      }),
    };


    static defaultProps = {
      placeholder: '',
      disabled: false,
      meta: null,
    };


    render() {
      const {
        input, placeholder, disabled, meta: { touched, error },
      } = this.props;
      return (
        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
          <PhoneInput
            disabled={disabled}
            placeholder={placeholder}
            value={input.value}
            onChange={(value) => { if (value) { input.onChange(value); } else { input.onChange(''); } }}
          />
          {touched || error && <span className="form__form-group-error">{error}</span>}
        </div>
      );
    }
}


export default InputPhone;
