/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unneeded-ternary */
/*
 * Page News & Updates Preferences
 * Notes: Master Data News & Updates
 * @author Fakhrurrozi
 * @date 12/04/2021
 * Modification (please note below)
*/
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Button, Card, CardBody, Col, Container, ButtonToolbar, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../../Preferences/notificationMessages';
// import showNotifications from '../show';
import PropTypes from 'prop-types';
import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import { Field, reduxForm, FieldArray } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderMaskInput from '../../../../shared/components/form/InputMask';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import utils from '../../../../utils';
import { Spinner } from 'react-bootstrap';
import renderNumberFormat from '../../../../shared/components/form/NumberFormat';

class PayrollDataPage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    rtbtaxstatus: PropTypes.func.isRequired,
    paytaxmethod: PropTypes.func.isRequired,
    rtbpayproratesetting: PropTypes.func.isRequired,
    paycomponentgroup: PropTypes.func.isRequired,
    paycomponentgrouprel: PropTypes.func.isRequired,
    paycomponentrecur: PropTypes.func.isRequired,
    rtbbank: PropTypes.func.isRequired,
    valuecomp: PropTypes.func.isRequired,
    oldvaluecomp: PropTypes.func.isRequired,
    paytaxclass: PropTypes.func.isRequired,
    disabledTaxStatus: PropTypes.func.isRequired,
    disabledTaxMethod: PropTypes.func.isRequired,
    tempform: PropTypes.func.isRequired,
    loadEmployee: PropTypes.func.isRequired,
    parmid: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('FRM.TITLE') },
      { '2': t('LBL.DESCRIPTION') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['10%', '25%', '45%', '20%'],
      title: '',
      errorTitle: 'none',
      description: '',
      errorDescription: 'none',
      attachment: '',
      tableInput: 'none',
      tableData: '',
      statusEdit: false,
      disableButton: false,
      disableLng: false,
      arrLng: [],
      modal: false,
      disabledTKNO: true,
      disabledKSNO: true,
      action: '',
      oldvalue: [],
      value: [],
      displaySpinner: 'none',
      displaySave: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadEmployee = this.loadEmployee.bind(this);
    this.changeTK = this.changeTK.bind(this);
    this.changeKS = this.changeKS.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { tempform } = props;
    if (props.tempform.length === undefined && state.tempForm === undefined) {
      console.log(tempform);
      props.initialize({ ...tempform, npwp_date: tempform.npwp_date === null ? tempform.npwp_date : moment(tempform.npwp_date), join_date: moment(tempform.join_date) });
      // state.setState({ tempform: props.tempform });
      return {
        tempForm: props.tempform,
      };
    }

    return null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/appnews/getAllItem` });
    this.setState({ comp_payroll: this.props.tempform.component_payroll });
    this.setState({ oldvalue: this.props.oldvaluecomp });
    this.setState({ value: this.props.valuecomp });
    setTimeout(() => {
      this.setDefaultValue();
    }, 100);
  };

  componentWillReceiveProps(props) {
    console.log(props);
  }
  setDefaultValue() {
    const { tempform, paycomponentrecur } = this.props;
    const { temp2, comp_payroll } = this.state;
    // this.props.paycomponentgroup.map((comp) => {
    //   if (typeof temp2.pyg_id === 'undefined' || temp2.pyg_id === null) {
    //     console.log(comp.label);
    //     if (comp.label.toLowerCase() === 'reguler') {
    //       this.props.initialize({ pyg_id: comp.value });
    //       setTimeout(() => {
    //         this.state.temp2.pyg_id = comp.value;
    //         this.changePayrollGroup({ value: comp.value, ...tempform });
    //       }, 200);
    //     }
    //   }
    // });
    let emp_nama;
    console.log('last_name', tempform.last_name);
    if (tempform.last_name === 'undefined') {
      console.log('masuk sini');
      emp_nama = tempform.first_name;
    } else {
      console.log('masuk sini1');
      emp_nama = tempform.first_name;
    }
    console.log('emp_nama', emp_nama);
    if (typeof comp_payroll === 'undefined' || comp_payroll.length === 0) {
      this.props.initialize({ ...tempform, component_payroll: paycomponentrecur, emp_name: emp_nama });
    } else {
      console.log(tempform);
      this.props.initialize({ ...tempform, component_payroll: comp_payroll });
    }
    this.changeKS({ value: tempform.bpjs_ks_registered });
    this.changeTK({ value: tempform.bpjs_tk_registered });
  }

  async handleSubmit(values) {
    const {
      apiws,
      token,
      idUser,
      tempForm,
    } = this.state;
    const comp_payroll = [];
    let ctr_id;
    let adjustment_start_date;
    let adjustment_end_date;
    let backpay_date;
    let npwp_date;
    let file_npwp;

    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    values.component_payroll.forEach((element, index) => {
      comp_payroll.push({
        pyc_id: element.pyc_id === null || null ? '' : element.pyc_id,
        label: element.label === null || null ? '' : element.label,
        is_backpay: element.is_backpay === null || '' ? '' : element.is_backpay,
        is_effective: element.is_effective === null || '' ? '' : element.is_effective,
        old_value: typeof this.state.oldvalue[index] === 'undefined' ? 0 : this.state.oldvalue[index],
        pec_value: typeof this.state.value[index] === 'undefined' ? 0 : this.state.value[index],
      });
    });
    const componentPayroll = JSON.stringify(comp_payroll);
    const rtk_id = (typeof values.rtk_id === 'object') ? values.rtk_id.value : values.rtk_id;
    const rtx_id = (typeof values.rtx_id === 'object') ? values.rtx_id.value : values.rtx_id;
    const ptm_id = (typeof values.ptm_id === 'object') ? values.ptm_id.value : values.ptm_id;
    const tcs_id = (typeof values.tcs_id === 'object') ? values.tcs_id.value : values.tcs_id;

    const bpjs_ks_registered =
      (typeof values.bpjs_ks_registered === 'object') ? values.bpjs_ks_registered.value : values.bpjs_ks_registered;
    const bpjs_tk_registered =
      (typeof values.bpjs_tk_registered === 'object') ? values.bpjs_tk_registered.value : values.bpjs_tk_registered;
    const overtime = (typeof values.overtime === 'object') ? values.overtime.value : values.overtime;
    const bpjs_ks_paid_by_company = typeof (values.bpjs_ks_by_company) === 'undefined' ? '2' : '1';
    const bpjs_tk_paid_by_company = typeof (values.bpjs_tk_by_company) === 'undefined' ? '2' : '1';

    if (typeof values.adjustment_start_date === 'undefined') {
      adjustment_start_date = '';
    } else if (values.adjustment_start_date === null || values.adjustment_start_date === '') {
      adjustment_start_date = '';
    } else {
      adjustment_start_date = values.adjustment_start_date.format('YYYY-MM-DD');
    }

    if (typeof values.adjustment_end_date === 'undefined') {
      adjustment_end_date = '';
    } else if (values.adjustment_end_date === null || values.adjustment_end_date === '') {
      adjustment_end_date = '';
    } else {
      adjustment_end_date = values.adjustment_end_date.format('YYYY-MM-DD');
    }

    if (typeof values.backpay_date === 'undefined' || values.backpay_date === null || values.backpay_date === '') {
      backpay_date = '';
    } else {
      backpay_date = values.backpay_date.format('YYYY-MM-DD');
    }

    if (typeof values.npwp_date === 'undefined' || values.npwp_date === null || values.npwp_date === '') {
      npwp_date = '';
    } else {
      npwp_date = values.npwp_date.format('YYYY-MM-DD');
    }

    if (values.file_npwp !== null) {
      file_npwp = (typeof values.file_npwp === 'object') ? values.file_npwp.file : values.file_npwp;
    } else {
      file_npwp = '';
    }
    const padId = values.pad_id;
    const convertdata = {
      component_payroll: componentPayroll,
      rtk_id,
      rtx_id,
      pad_id: padId,
      bpjs_ks_registered,
      bpjs_tk_registered,
      overtime,
      adjustment_start_date,
      adjustment_end_date,
      backpay_date,
      bpjs_ks_paid_by_company,
      bpjs_tk_paid_by_company,
      npwp_date,
      file_npwp,
      biological_mother: values.biological_mother,
      npwp: values.npwp,
      bank_account: values.bank_account,
      bank_account_holder: values.bank_account_holder,
      bpjs_tk_no: values.bpjs_tk_no,
      bpjs_ks_no: values.bpjs_ks_no,
      ptm_id,
      tcs_id,
    };
    // console.log(JSON.stringify(convertdata));
    let url;
    const userid = ls.get('user_cred').usr_id;
    const data = { ...convertdata, muser_id: userid };
    const id = this.props.tempform.emp_id;
    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());
    await axios.post(
      `${apiws}/employee/updatePayroll/${id}`,
      formBody,
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.loadEmployee(id);
        this.setState({ displaySpinner: 'none', displaySave: '' });
        // setTimeout(() => {
        //   this.setState({ displaySpinner: 'none', displaySave: '' }, () => (
        //     <Redirect
        //       to={
        //         {
        //           pathname: '/administration/employee',
        //           state: { men_id: this.props.parmid.men_employee, fga_id: this.props.parmid.fga_id },
        //         }
        //       }
        //     />
        //   ));
        // }, 200);
        // this.setDefaultValue();
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
        this.setState({ displaySpinner: 'none', displaySave: '' });
      }
    }).catch(() => {
      showNotifications('Fail', 'Save Failed', 'danger');
      this.setState({ displaySpinner: 'none', displaySave: '' });
    });
  }

  changeValue(e, f) {
    if (f === 'title') {
      if (!e.target.value) {
        this.setState({
          disableButton: true,
          title: '',
        });
      } else {
        this.setState({
          disableButton: false,
          title: e.target.value,
        });
      }
    } else if (f === 'description') {
      if (!e.target.value) {
        this.setState({
          disableButton: true,
          description: '',
        });
      } else {
        this.setState({
          disableButton: false,
          description: e.target.value,
        });
      }
    }
  }

  async loadEmployee(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios.post(
        `${apiws}/employee/getAllItemById/${id}`,
        '',
        config,
      ).then((response) => {
        // prompt('', JSON.stringify(response));
        const dataResponse = response.data.data;

        const adjustment = [];
        if (dataResponse.adjustment.length !== 0) {
          adjustment.push({
            pad_id: dataResponse.adjustment[0].pad_id,
            adjustment_start_date: (dataResponse.adjustment[0].adjustment_start_date),
            adjustment_end_date: (dataResponse.adjustment[0].adjustment_end_date),
            backpay_date: (dataResponse.adjustment[0].backpay_date),
          });
        } else {
          adjustment.push({
            pad_id: '',
            adjustment_start_date: null,
            adjustment_end_date: null,
            backpay_date: null,
          });
        }

        dataResponse.pay_component.map(obj => (
          this.state.value.push(obj.pec_value)
        ));

        dataResponse.pay_component.map(obj => (
          this.state.oldvalue.push(obj.old_value)
        ));

        const datamapping = {
          bpjs_ks_by_company: dataResponse.user[0].bpjs_ks_paid_by_company === '1' ? true : false,
          bpjs_tk_by_company: dataResponse.user[0].bpjs_tk_paid_by_company === '1' ? true : false,
          ...dataResponse.user[0],
          npwp_date: dataResponse.user[0].npwp_date === null ? dataResponse.user[0].npwp_date : moment(dataResponse.user[0].npwp_date),
          component_payroll: dataResponse.pay_component,
          pad_id: adjustment[0].pad_id,
          adjustment_start_date: adjustment[0].adjustment_start_date === null ? adjustment[0].adjustment_start_date : moment(adjustment[0].adjustment_start_date),
          adjustment_end_date: adjustment[0].adjustment_end_date === null ? adjustment[0].adjustment_end_date : moment(adjustment[0].adjustment_end_date),
          backpay_date: adjustment[0].backpay_date === null ? adjustment[0].backpay_date : moment(adjustment[0].backpay_date),
        };
        this.setState({ tempForm: datamapping });
        this.props.initialize(datamapping);
      }).catch((error) => {
        console.log(error);
      });

      // Update dynamic update tax 10/05/2021
      // if (this.props.parmid.state.statusEdit) {
      //   // console.log(new Date().getMonth() + 1);
      //   if (new Date().getMonth() + 1 !== 1) {
      //     this.setState({ disabledTax: true });
      //     this.setState({ disabledTaxMethod: true });
      //   }
      // }
    });
  }

  changeTK(event) {
    if (event.value === '1') {
      this.setState({ disabledTKNO: false });
    } else {
      this.setState({ disabledTKNO: true });
    }
  }

  changeKS(event) {
    if (event.value === '1') {
      this.setState({ disabledKSNO: false });
    } else {
      this.setState({ disabledKSNO: true });
    }
  }

  renderComponentPayroll = ({
    fields, meta: { error }, t, valuecomp, oldvaluecomp, tempform,
  }) => (
    <div>
      {
        fields.map((component, index) => (
          <Row className="h_line">
            <div className="identity_form_group" >
              <Col md={3} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">&nbsp;</span>
                  {/* <div className="form__form-group-field"> */}
                  <input type="hidden" name={`${component}.pyc_id`} />
                  <Field
                    name={`${component}.label`}
                    component="input"
                    type="text"
                    disabled
                  />
                  {/* </div> */}
                </div>
              </Col>
              <Row>
                <Col md={6} sm={12}>
                  <div className="form__form-group" >
                    <span className="form__form-group-label">{t('LBL.OLD_VALUE')}</span>
                    <Field
                      name={`${component}.old_value`}
                      component={renderNumberFormat}
                      other={{
                        value: this.state.oldvalue[index],
                        thousandSeparator: '.',
                        decimalSeparator: ',',
                        prefix: 'Rp ',
                        disabled: true,
                      }}
                      onChange={(val) => {
                        const a = this.state.oldvalue.slice();
                        a[index] = val.value;
                        this.setState({ oldvalue: a });
                      }}
                    />
                    {/* </div> */}
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form__form-group" >
                    <span className="form__form-group-label">{t('LBL.VALUE')}</span>
                    <Field
                      name={`${component}.pec_value`}
                      component={renderNumberFormat}
                      other={{
                        // defaultValue: this.state.oldvalue,
                        value: this.state.value[index],
                        thousandSeparator: '.',
                        decimalSeparator: ',',
                        prefix: 'Rp ',
                      }}
                      onChange={(val) => {
                        const a = this.state.value.slice();
                        a[index] = val.value;
                        this.setState({ value: a });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Col md={1} sm={12}>
                <div className="form__form-group" >
                  <span className="form__form-group-label">{t('LBL.BACKPAY')}</span>
                  <div className="form__form-group-field checkbox_custom">
                    <Field
                      name={`${component}.is_backpay`}
                      component={renderTextInput}
                      type="checkbox"
                    />
                  </div>
                </div>
              </Col>
              <Col md={1} sm={12}>
                <div className="form__form-group" >
                  <span className="form__form-group-label">{t('LBL.EFFECTIVE')}</span>
                  <div className="form__form-group-field checkbox_custom">
                    <Field
                      name={`${component}.is_effective`}
                      component={renderTextInput}
                      type="checkbox"
                    />
                  </div>
                </div>
              </Col>

            </div>
          </Row>
        ))
      }
      {error && <li className="error">{error}</li>}
    </div>
  );

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      tableData,
      tableInput,
      disableButton,
      statusEdit,
      errorTitle,
      errorDescription,
      arrLng,
      disableLng,
      displaySpinner,
      displaySave,
    } = this.state;

    // if (this.state.statusEdit) {
    //   return (<Redirect to={{
    //     pathname: '/administration/employee',
    //     state: { men_id: this.props.parmid.men_employee, fga_id: this.props.parmid.fga_id },
    //   }}
    //   />);
    // }
    // if (statusEdit) {
    //   labelButton = t('FRM.EDIT');
    //   colorButton = 'success';
    // } else {
    const colorButton = 'primary';
    const labelButton = t('FRM.SAVE');
    // }
    return (
      <Container>
        <Row style={{ 'display': tableData }}>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 20px 30px 20px' }}>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="newsform"
                  id="newsform"
                  style={{ padding: '15px' }}
                >
                  <input type="hidden" name="anu_id" value="" />
                  <Container>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="emp_name"
                            component={renderTextInput}
                            type="text"
                            placeholder={t('LBL.EMPLOYEE_NAME')}
                            disabled
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.TAX_METHOD')}</span>
                          <Field
                            name="ptm_id"
                            component={renderSelectField}
                            type="text"
                            // disabled={disabledTaxMethod}
                            options={this.props.paytaxmethod}
                            placeholder="Choose Tax Method"
                          />
                        </div>
                      </Col>
                      <Col md={2} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.TAX_STATUS')}</span>
                          <Field
                            name="rtx_id"
                            component={renderSelectField}
                            type="text"
                            // disabled={disabledTaxStatus}
                            options={this.props.rtbtaxstatus}
                            placeholder="Choose Tax Status"
                          />
                        </div>
                      </Col>
                      <Col md={2} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.TAX_RECIPIENT')}</span>
                          <Field
                            name="tcs_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.paytaxclass}
                            placeholder="Choose Tax Status"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.OVERTIME')} </span>
                          <Field
                            name="overtime"
                            component={renderSelectField}
                            type="text"
                            options={[
                              { value: '1', label: 'Eligible' },
                              { value: '2', label: 'Not' },
                            ]}
                            placeholder="Overtime"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BPJS_KETENAGAKERJAAN')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="bpjs_tk_registered"
                            component={renderSelectField}
                            type="text"
                            onChange={this.changeTK}
                            options={[
                              { value: '1', label: t('LBL.REGISTERED') },
                              { value: '2', label: t('LBL.NOT_REGISTERED') },
                            ]}
                            placeholder="Choose BPJS TK"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label" style={{ width: '100%' }}>{t('LBL.NUMBER_BPJS_KETENAGAKERJAAN')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="bpjs_tk_no"
                            component={renderTextInput}
                            disabled={this.state.disabledTKNO}
                            type="text"
                            maxLength={32}
                            placeholder="Nomor BPJS Tenaga Kerja"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      {/* <Col md={4} xs={12}>
                        <div className="checkbox_form_group">
                          <Field
                            name="bpjs_tk_by_company"
                            component={renderCheckBoxField}
                            label={t('LBL.PAID_BY_COMPANY')}
                          />
                        </div>
                      </Col> */}
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BPJS_KESEHATAN')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="bpjs_ks_registered"
                            component={renderSelectField}
                            type="text"
                            onChange={this.changeKS}
                            options={[
                              { value: '1', label: t('LBL.REGISTERED') },
                              { value: '2', label: t('LBL.NOT_REGISTERED') },
                            ]}
                            placeholder="Choose BPJS Kesehatan"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.NUMBER_BPJS_KESEHATAN')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="bpjs_ks_no"
                            disabled={this.state.disabledKSNO}
                            component={renderTextInput}
                            type="text"
                            maxLength={32}
                            placeholder="Your Nomor BPJS Kesehatan"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      {/* <Col md={4} xs={12}>
                        <div className="checkbox_form_group">
                          <Field
                            name="bpjs_ks_by_company"
                            component={renderCheckBoxField}
                            label={t('LBL.PAID_BY_COMPANY')}
                          />
                        </div>
                      </Col> */}
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">NPWP</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="npwp"
                            component={renderMaskInput}
                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]}
                            type="text"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md={2} sm={12}>
                        <div className="form__form-group" >
                          <span className="form__form-group-label">{t('LBL.NPWP_DATE')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <input type="hidden" name="pad_id" />
                          <Field
                            name="npwp_date"
                            component={renderDatePickerField}
                            placeholder="dd-mm-yyyy"
                          />
                          {/* <div className="form__form-group-icon">
                            <CalendarBlankIcon className="form__form-group-icon"/>
                          </div> */}
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.UPLOAD')} NPWP
                          </span>
                          <Field
                            name="file_npwp"
                            allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                            maxSize={1000}
                            component={renderFileInputField}
                            type="textemployee"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BANK_NAME')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="rtk_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtbbank}
                            placeholder="Select Bank"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BANK_ACCOUNT')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="bank_account"
                            component={renderTextInput}
                            type="number"
                            maxLength={32}
                            placeholder="Your Bank Account"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ACCOUNT_HOLDER')}</span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="bank_account_holder"
                            component={renderTextInput}
                            maxLength={32}
                            type="text"
                            placeholder="Your Bank Account Holder"
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                    </Row>
                    <Row >
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BIOLOGICAL_MOTHER')}</span>
                          <Field
                            name="biological_mother"
                            component={renderTextInput}
                            type="text"
                            placeholder="Your Biological Mother"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="h_line">
                      <Col md={12} sm={12}>
                        <div className="adj-panel-profile">
                          <div className="title-adj">
                            {t('LBL.PAYROLL')}
                          </div>
                          <div className="body-adj">
                            <Row>
                              <Col md={4}>
                                <div className="form__form-group" >
                                  <span className="form__form-group-label">{t('LBL.PAYROLL')} {t('LBL.START_DATE')}</span>
                                  <div className="form__form-group-field">
                                    <input type="hidden" name="pad_id" />
                                    <Field
                                      name="adjustment_start_date"
                                      component={renderDatePickerField}
                                    />
                                    <div className="form__form-group-icon">
                                      <CalendarBlankIcon />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="form__form-group" >
                                  <span className="form__form-group-label">{t('LBL.PAYROLL')} {t('LBL.END_DATE')}</span>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="adjustment_end_date"
                                      component={renderDatePickerField}
                                    />
                                    <div className="form__form-group-icon">
                                      <CalendarBlankIcon />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="form__form-group" >
                                  <span className="form__form-group-label">{t('LBL.BACKPAY')} {t('LBL.DATE')} </span>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="backpay_date"
                                      component={renderDatePickerField}
                                    />
                                    <div className="form__form-group-icon">
                                      <CalendarBlankIcon />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {/* <Col md={4} sm={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.PAYROLL')} {t('LBL.GROUP_COMPONENT')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="pyg_id"
                            component={renderSelectField}
                            type="text"
                            options={paycomponentgroup}
                            onChange={this.changePayrollGroup}
                            placeholder="Select Payroll Group Component"
                          />
                        </div>
                      </div>
                    </Col> */}
                              <Col md={12} sm={12}>
                                <FieldArray
                                  name="component_payroll"
                                  component={this.renderComponentPayroll}
                                  props={{
                                    t,
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </Container>
                </form>
                <ButtonToolbar className="form__button-toolbar" >
                  {/* <Button
                    style={{
                      'position': 'absolute',
                      'left': '40%',
                    }}
                    color="secondary"
                  >{t('FRM.CANCEL')}
                  </Button> */}
                  <Link
                    className="link_action_tbl"
                    id="Edit"
                    to={{
                      pathname: '/administration/employee',
                    }}
                    style={{ 'margin-right': '10px' }}
                  >
                    <Button
                      style={{
                        'position': 'absolute',
                        'left': '40%',
                        display: displaySave,
                      }}
                      color="secondary"
                    >{t('FRM.BACK')}
                    </Button>
                  </Link>
                  <Button
                    style={{
                      'position': 'absolute',
                      'left': '50%',
                    }}
                    color={colorButton}
                    form="newsform"
                    type="submit"
                    disabled={disableButton}
                  > {labelButton}
                  </Button>
                  <Button
                    color="primary"
                    style={{
                      'position': 'absolute',
                      'left': '50%',
                      display: displaySpinner,
                    }}
                    type="button"
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    {t('FRM.LOADING')}
                  </Button>
                </ButtonToolbar>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}


const validate = (values) => {
  const validation = {
    tcs_id: {
      fieldLabel: 'Tax Recipient',
      required: true,
    },
    rtx_id: {
      fieldLabel: 'Tax status',
      required: true,
    },
    overtime: {
      fieldLabel: 'Overtime',
      required: true,
    },
    bpjs_tk_registered: {
      fieldLabel: 'BPJS TK',
      required: true,
    },
    bpjs_ks_registered: {
      fieldLabel: 'BPJS KS',
      required: true,
    },
    rtk_id: {
      fieldLabel: 'Bank',
      required: true,
    },
    bank_account: {
      fieldLabel: 'Bank account',
      required: true,
    },
    bank_account_holder: {
      fieldLabel: 'Bank account holder',
      required: true,
    },
    ptm_id: {
      fieldLabel: 'Default tax method',
      required: true,
    },
    // component_payroll: {
    //   dynamicField: true,
    //   children: [
    //     { name: 'pec_value', label: 'Value', required: true },
    //   ],
    // },
  };

  const errors = utils.validate(validation, values);

  console.log(errors);
  // if (typeof values.component_payroll !== 'undefined') {
  //   values.component_payroll.forEach((comp, index) => {
  //     console.log(comp);
  //     if (!comp.pec_value.value) {
  //       errors.componentPayroll[index] = 'value shouldn`t be empty';
  //     }
  //   });
  // }

  return errors;
};


export default reduxForm({
  form: 'reduxPayrollloyeeDataPage', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(PayrollDataPage));
