/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-properties */
/* eslint-disable react/no-unused-prop-types */

import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Table } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import Workbook from 'react-excel-workbook';
import ReactExport from 'react-export-excel';

const buttonRef = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class EmployeeSocialIncurance extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    datas: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    inUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NO'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.DATE_OF_BIRTH'),
      t('LBL.IDENTITY'),
      t('LBL.SEX'),
      t('LBL.BLOOD_TYPE'),
      t('LBL.MARITAL_STATUS'),
      t('LBL.COUNTRY_CODE'),
      `${t('LBL.OFFICE')} ${t('LBL.CODE')}`,
    ];
    this.state = {
      apiws: '',
      token: '',
      urlData: '',
      arrYear: [],
      arrEmployee: [],
      arrOffice: [],
      arrUnitOrganization: [],
      displayLoad: 'none',
      condOrganization: '',
      condOffice: '',
      condEmployee: '',
      condActive: '',
      condYear: '',
      condMonth: '',
      dataTwo: [],
      dataThree: [],
      periodlabel: t('LBL.PERIOD'),
      type: '',
    };
    this.getYear = this.getYear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getDownloadExcel = this.getDownloadExcel.bind(this);
  }

  componentDidMount = () => {
    this.getYear();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getOffice();
      this.getUnitOrganization();
      this.getEmployee();
      this.getLocation();
    });
  };

  componentWillReceiveProps(newProps) {
    const { t, datas } = newProps;
    if (datas) {
      this.heads = [
        t('LBL.NO'),
        t('LBL.KPJ'),
        t('LBL.EMPLOYEE'),
        t('LBL.DATE_OF_BIRTH'),
        t('LBL.EMPLOYEE_DESCRIPTION'),
      ];
    } else {
      this.heads = [
        t('LBL.NO'),
        t('LBL.EMPLOYEE_CODE'),
        t('LBL.EMPLOYEE'),
        t('LBL.DATE_OF_BIRTH'),
        t('LBL.IDENTITY'),
        t('LBL.SEX'),
        t('LBL.BLOOD_TYPE'),
        t('LBL.MARITAL_STATUS'),
        t('LBL.COUNTRY_CODE'),
        `${t('LBL.OFFICE')} ${t('LBL.CODE')}`,
      ];
    }
  }

  getTableData() {
    this.child.reload();
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getLocation() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/reportsocialinsurance/getAlllocation`,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({ dataThree: res.data.data });
      }
    });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgoffice`,
      config,
    ).then((res) => {
      const off = res.data.data;
      const array = [];
      if (off.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        off.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getDownloadExcel(id) {
    const { t } = this.props;
    const {
      apiws,
      token,
      condActive,
      condOrganization,
      condOffice,
      condEmployee,
      condYear,
      condMonth,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const bodyParameters = {
      cond: {
        'is_active': condActive,
        'org_id': condOrganization,
        'off_id': condOffice,
        'emp_id': condEmployee,
        'month': condMonth,
        'year': condYear,
      },
    };
    let url;
    if (id === '0') {
      url = `${apiws}/reportsocialinsurance/getEmployeeReportExcel`;
    } else if (id === '1') {
      url = `${apiws}/reportsocialinsurance/getEmployeeNonActive`;
    }
    axios.post(
      url,
      bodyParameters,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        this.setState({ dataTwo: response.data.data });
        this.inputElement.click();
      }
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  handleChange(val) {
    const { t } = this.props;
    if (val.value === '0') {
      this.setState({ periodlabel: t('LBL.JOIN_DATE') });
    } else if (val.value === '1') {
      this.setState({ periodlabel: t('LBL.RESIGN_DATE') });
    }
  }

  handleSubmit(values) {
    console.log(this.state.type);
    // const type = (typeof values.type_report === 'undefined' || values.type_report === null) ? '' : values.type_report.value;
    const orgId = (typeof values.organization === 'undefined' || values.organization === null) ? '' : values.organization.value;
    const offId = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
    const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
    const stsId = (typeof values.status === 'undefined' || values.status === null) ? '' : values.status.value;
    const month = (typeof values.period_month === 'undefined' || values.period_month === null) ? '' : values.period_month.value;
    const year = (typeof values.period_year === 'undefined' || values.period_year === null) ? '' : values.period_year.value;
    const type = values.type_report.value;
    this.setState({ condOrganization: orgId });
    this.setState({ condOffice: offId });
    this.setState({ condEmployee: empId });
    this.setState({ condActive: stsId });
    this.setState({ condYear: year });
    this.setState({ condMonth: month });
    if (stsId === '0') {
      this.setState({ urlData: `${ls.get('ws_ip')}/reportsocialinsurance/getEmployee` });
      this.props.onUpdate();
    } else if (stsId === '1') {
      this.setState({ urlData: `${ls.get('ws_ip')}/reportsocialinsurance/getEmployeeNonActive` });
      this.props.inUpdate();
    }
    setTimeout(() => {
      if (type === '1') {
        this.getTableData();
      } else {
        this.getDownloadExcel(stsId);
      }
      showNotifications('Success', 'Load Success', 'success');
    }, 500);
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrYear,
      arrEmployee,
      arrOffice,
      arrUnitOrganization,
      urlData,
      displayLoad,
      condEmployee,
      condActive,
      condOrganization,
      condOffice,
      condYear,
      condMonth,
      dataTwo,
      dataThree,
    } = this.state;
    const dataOne = [
      {
        column: '',
        petunjuk: '',
      },
      {
        column: '',
        petunjuk: '',
      },
      {
        column: 'Langkah Penggunaan Sheet "Data TK Baru"',
        petunjuk: '',
      },
      {
        column: 'Pengisian Column',
        petunjuk: 'Petunjuk',
      },
      {
        column: 'NIK',
        petunjuk: 'Berisi nomor identitas dari tenaga kerja. Wajib diisi. Sistem akan melakukan pengecekan dengan data Adminduk jika jenis identitas adalah KTP.',
      },
      {
        column: 'NAMA',
        petunjuk: 'Berisi nama depan/nama lengkap dari tenaga kerja. Wajib diisi.',
      },
      {
        column: 'TGL_LAHIR',
        petunjuk: 'Berisi tgl_lahir dari tenaga kerja, diisi dengan format dd-mm-yyyy contoh : 31-12-1992. Wajib diisi.',
      },
      {
        column: 'JENIS_IDENTITAS',
        petunjuk: 'Berisi jenis identitas dari tenaga kerja. pilihan ada 2 yaitu PASSPORT atau KTP. contoh : PASSPORT',
      },
      {
        column: 'MASA_LAKU_IDENTITAS',
        petunjuk: 'Berisi masa berlaku identitas tenaga kerja, diisi dengan format dd-mm-yyyy contoh : 31-12-1992.  Wajib diisi',
      },
      {
        column: 'JENIS_KELAMIN',
        petunjuk: 'Berisi jenis kelamin tenaga kerja, diisi dengan inisial L atau P.',
      },
      {
        column: 'SURAT_MENYURAT_KE',
        petunjuk: 'Berisi surat menyurat dikirim ke, diisi dengan initial S atau E. Keterangan : S (Alamat) , E (Email) ',
      },
      {
        column: 'STATUS_KAWIN',
        petunjuk: 'Berisi status kawin dari tenaga kerja, diisi dengan initial Y atau T. Keterangan : Y (KAWIN) , T (BELUM KAWIN) ',
      },
      {
        column: 'GOLONGAN_DARAH',
        petunjuk: 'Berisi golongan darah dari tenaga kerja, diisi dengan golongan A, B, AB, O.',
      },
      {
        column: 'KODE_NEGARA',
        petunjuk: 'Berisi kode negara dari tenaga kerja, diisi dengan kode negara contoh : ID , keterangan : ID (INDONESIA).',
      },
      {
        column: 'LOKASI_PEKERJAAN',
        petunjuk: 'Berisi Kode Lokasi Pekerjaan dari tenaga kerja, untuk daftar kode Pekerjaan terdapat pada sheet LOKASI_PEKERJAAN.',
      },
      {
        column: '',
        petunjuk: '',
      },
      {
        column: 'Untuk pengisian data tenaga kerja terdapat di sheet data_tk_baru ',
        petunjuk: '',
      },
    ];
    const dataOneNA = [
      {
        column: '',
        petunjuk: '',
      },
      {
        column: 'Langkah Penggunaan Sheet "Data Tenaga Kerja Nonaktif""',
        petunjuk: '',
      },
      {
        column: 'Pengisian Column',
        petunjuk: 'Petunjuk',
      },
      {
        column: 'KPJ',
        petunjuk: 'Berisi nomor KPJ dari tenaga kerja. Wajib diisi. Sistem akan melakukan pengecekan dengan data KPJ yang diinput.',
      },
      {
        column: 'NAMA',
        petunjuk: 'Berisi nama depan/nama lengkap dari tenaga kerja. Wajib diisi.',
      },
      {
        column: 'TGL_LAHIR',
        petunjuk: 'Berisi tgl_lahir dari tenaga kerja, diisi dengan format dd-mm-yyyy contoh : 31-12-1992. Wajib diisi.',
      },
      {
        column: 'KETERANGAN',
        petunjuk: 'Berisi Keterangan dari tenaga kerja yang dinonaktifkan.',
      },
      {
        column: '',
        petunjuk: '',
      },
      {
        column: '* Kolom yang ditandai warna kuning wajib diisi. ',
        petunjuk: '',
      },
      {
        column: '* Untuk pengisian data tenaga kerja terdapat di sheet data_tk_na ',
        petunjuk: '',
      },
    ];
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.EMPLOYEE')} {t('MEN.REPORT')}</h3>
                    <h3 className="page-subhead subhead">{t('MEN.EMPLOYEE_DATA_OVERVIEW')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form className="form form--horizontal mt15" onSubmit={handleSubmit(this.handleSubmit)}>
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="status"
                                  placeholder="Status"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '0', label: `${t('LBL.ACTIVE')}` },
                                    { value: '1', label: `${t('LBL.NOT_ACTIVE')}` },
                                  ]}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee"
                                  placeholder="Name"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmployee}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{this.state.periodlabel}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_month"
                                  component={renderSelectField}
                                  type="text"
                                  placeholder="Month"
                                  options={[
                                    { value: '01', label: `${t('LBL.JANUARY')}` },
                                    { value: '02', label: `${t('LBL.FEBRUARY')}` },
                                    { value: '03', label: `${t('LBL.MARCH')}` },
                                    { value: '04', label: `${t('LBL.APRIL')}` },
                                    { value: '05', label: `${t('LBL.MAY')}` },
                                    { value: '06', label: `${t('LBL.JUNE')}` },
                                    { value: '07', label: `${t('LBL.JULI')}` },
                                    { value: '08', label: `${t('LBL.AUGUST')}` },
                                    { value: '09', label: `${t('LBL.SEPTEMBER')}` },
                                    { value: '10', label: `${t('LBL.OCTOBER')}` },
                                    { value: '11', label: `${t('LBL.NOVEMBER')}` },
                                    { value: '12', label: `${t('LBL.DESEMBER')}` },
                                  ]}
                                />
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  placeholder="Year"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="format_report"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                      { value: 'new', label: `${t('LBL.NEW_EMPLOYEE')}` },
                                    ]}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="organization"
                                  placeholder="Organization"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrUnitOrganization}
                                />
                                <Field
                                  name="office"
                                  placeholder="Office"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrOffice}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row >
                        <Row>
                          <Col md={4} xs={12} lg={4} xl={4}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  id="typeAttdReport"
                                  name="type_report"
                                  placeholder="Type"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'View' },
                                    { value: '2', label: 'Excel' },
                                  ]}
                                  onChange={val => this.setState({ type: val.value })}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {(this.state.type === '2' && condActive === '0') && (
                          <ExcelFile
                            filename="template_tk_JJ092615.xlsx"
                            element={
                              <div
                                onClick={this.handleClick}
                                onKeyPress={this.handleClick}
                                role="button"
                                tabIndex="0"
                              ><input type="hidden" ref={input => this.inputElement = input} />
                              </div>}
                          >
                            <ExcelSheet data={dataOne} name="Petunjuk Pengisian" >
                              <ExcelColumn value="column" />
                              <ExcelColumn value="petunjuk" />
                            </ExcelSheet>
                            <ExcelSheet data={dataTwo} name="data_tk_baru">
                              <ExcelColumn label="NO_PEGAWAI" value="emp_code" />
                              <ExcelColumn label="NAMA_DEPAN" value="emp_name" />
                              <ExcelColumn label="NAMA_TENGAH" value="bar" />
                              <ExcelColumn label="NAMA_BELAKANG" value="bar" />
                              <ExcelColumn label="GELAR" value="bar" />
                              <ExcelColumn label="TELEPON_AREA_RUMAH" value="bar" />
                              <ExcelColumn label="TELEPON_RUMAH" value="phone" />
                              <ExcelColumn label="TELEPON_AREA_KANTOR" value="bar" />
                              <ExcelColumn label="TELEPON_KANTOR" value="bar" />
                              <ExcelColumn label="TELEPON_EXT_KANTOR" value="bar" />
                              <ExcelColumn label="HP" value="mobile" />
                              <ExcelColumn label="EMAIL" value="email" />
                              <ExcelColumn label="TEMPAT_LAHIR" value="pob" />
                              <ExcelColumn label="TANGGAL_LAHIR" value="dob" />
                              <ExcelColumn label="NAMA_IBU_KANDUNG" value="bar" />
                              <ExcelColumn label="JENIS_IDENTITAS" value="citizenid" />
                              <ExcelColumn label="NOMOR_IDENTITAS" value="citizenid_number" />
                              <ExcelColumn label="MASA_LAKU_IDENTITAS" value="citizenid_expired_date" />
                              <ExcelColumn label="JENIS_KELAMIN" value="sex" />
                              <ExcelColumn label="SURAT_MENYURAT_KE" value="bar" />
                              <ExcelColumn label="TANGGAL_KEPESERTAAN" value="bar" />
                              <ExcelColumn label="STATUS_KAWIN" value="marital_status" />
                              <ExcelColumn label="GOLONGAN_DARAH" value="blood_type" />
                              <ExcelColumn label="NPWP" value="npwp" />
                              <ExcelColumn label="KODE_NEGARA" value="country_code" />
                              <ExcelColumn label="UPAH" value="salary" />
                              <ExcelColumn label="ALAMAT" value="address" />
                              <ExcelColumn label="KODE_POS" value="postal_code" />
                              <ExcelColumn label="LOKASI_PEKERJAAN" value="locationid" />
                            </ExcelSheet>
                            <ExcelSheet data={dataThree} name="Lokasi Pekerjaan">
                              <ExcelColumn label="NAMA" value="bpjs_code_location" />
                              <ExcelColumn label="KODE_LOKASI" value="cty_name" />
                            </ExcelSheet>
                          </ExcelFile>
                        )}
                        {(this.state.type === '2' && condActive === '1') && (
                          <ExcelFile
                            filename="template_tk_na_JJ092615.xlsx"
                            element={
                              <div
                                onClick={this.handleClick}
                                onKeyPress={this.handleClick}
                                role="button"
                                tabIndex="0"
                              ><input type="hidden" ref={input => this.inputElement = input} />
                              </div>}
                          >
                            <ExcelSheet data={dataOneNA} name="Petunjuk Pengisian" >
                              <ExcelColumn value="column" />
                              <ExcelColumn value="petunjuk" />
                            </ExcelSheet>
                            <ExcelSheet data={dataTwo} name="data_tk_na">
                              <ExcelColumn label="KPJ" value="bpjs_tk_no" />
                              <ExcelColumn label="NAMA" value="emp_name" />
                              <ExcelColumn label="TGL_LAHIR" value="dob" />
                              <ExcelColumn label="KETERANGAN" value="employeement_status" />
                            </ExcelSheet>
                          </ExcelFile>
                        )}
                        <ButtonToolbar className="form__button-toolbar">
                          <Button color="primary" type="submit">{t('FRM.SUBMIT')}</Button>
                        </ButtonToolbar>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayLoad, textAlign: 'center' }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row className="m5 table-nopadding">
                  <div className="card__title">
                    <h5 className="bold-text">{t('LBL.EMPLOYEE')} {t('MEN.REPORT')}</h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    // primaryKey="pyp_id"
                    // widthTable={this.state.dataWidth}
                    buttonAction="noButton"
                    conds={{
                      'is_active': condActive,
                      'org_id': condOrganization,
                      'off_id': condOffice,
                      'emp_id': condEmployee,
                      'month': condMonth,
                      'year': condYear,
                    }}
                    onRef={ref => (this.child = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  if (!values.status) {
    errors.status = 'Employee status field shouldn’t be empty';
  }
  if (!values.period_year) {
    errors.period_year = 'Year field shouldn’t be empty';
  }
  // if (!values.period_month) {
  //   errors.period_month = 'Month field shouldn’t be empty';
  // }
  return errors;
};

export default reduxForm({
  validate,
  form: 'reportbpjsemp', // a unique identifier for this form
})(translate('global')(EmployeeSocialIncurance));
