/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import RunProcessViewTable from './components/RunProcessViewTable';
import RunProcessForm from './components/RunProcessForm';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import Authenticator from '../../Authenticator';
import axios from 'axios';

class Payroll extends PureComponent {
  static propTypes = {
    location: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      dataUpdate: false,
      men_id: '',
      fga_id: '',
      validateToken: false,
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    if (typeof location.men_id === 'undefined' && typeof location.fga_id === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({ men_id: location.state.men_id, fga_id: location.state.fga_id });
    }
    this.checkAuth();
  }

  setDefault = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = () => {
    this.setState({ dataUpdate: true });
  }

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/token/isOtp`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        if (response.data.otp) {
          this.setState({ validateToken: true });
        }
      }
    }).catch((error) => {
      // showNotifications('Fail', 'Delete Failed', 'danger');
    });
  }

  render() {
    const { validateToken } = this.state;
    if (validateToken === false) {
      return (
        <Authenticator checkAuth={this.checkAuth} />
      );
    }
    return (
      <Container>
        <Row>
          <RunProcessForm
            onProcess={this.loadAfterSubmit}
          />
        </Row>
        <Row>
          <RunProcessViewTable
            men_id={this.state.men_id}
            fga_id={this.state.fga_id}
            onProcess={this.state.dataUpdate}
            inProcess={this.setDefault}
          />
        </Row>
      </Container>
    );
  }
}

export default Payroll;
