/* eslint-disable no-unused-vars */
/* eslint-disable brace-style */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import SortableTree from 'react-sortable-tree';
import PropTypes from 'prop-types';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencilAlt, faTimes } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../notificationMessages';
import { faCrown } from '@fortawesome/fontawesome-free-solid';
// import SweetAlert from 'react-bootstrap-sweetalert';


function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.org_id] = arrElem;
    mappedArr[arrElem.org_id].children = [];
  }


  // eslint-disable-next-line no-restricted-syntax
  for (const id in mappedArr) {
    // eslint-disable-next-line no-prototype-builtins
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.org_id_parent) {
        if (mappedElem.children.length === 0) {
          mappedArr[mappedElem.org_id_parent].children.push({
            org_id: mappedElem.org_id,
            ppi_id: mappedElem.ppi_id,
            title: mappedElem.unit_organization,
            expanded: true,
            children: mappedElem.children,
          });
        } else {
          mappedArr[mappedElem.org_id_parent].children.push({
            org_id: mappedElem.org_id,
            ppi_id: mappedElem.ppi_id,
            title: mappedElem.unit_organization,
            expanded: true,
            children: mappedElem.children,
          });
        }
        // eslint-disable-next-line brace-style
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push({
          org_id: mappedElem.org_id,
          title: mappedElem.unit_organization,
          ppi_id: mappedElem.ppi_id,
          expanded: true,
          children: mappedElem.children,
        });
      }
    }
  }

  return tree;
}


class KPIUnitOrganization extends PureComponent {
  static propTypes = {
    dataTree: PropTypes.func.isRequired,
    editMode: PropTypes.func.isRequired,
    loadTree: PropTypes.func.isRequired,
    // updateTree: PropTypes.func.isRequired,
    fullMode: PropTypes.func.isRequired,
    deleteMode: PropTypes.func.isRequired,
    editcond: PropTypes.func.isRequired,
    deletecond: PropTypes.func.isRequired,
    // deleteToggle: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    // eslint-disable-next-line no-unused-vars
    // const datarr2 = [{
    // eslint-disable-next-line max-len
    //   org_id: 2, title: 'Information Technology', expanded: true, children: [{ org_id: 5, title: 'Research and Development', expanded: true }, { org_id: 6, title: 'HRD', expanded: true }, { org_id: 7, title: 'Sales', expanded: true }, { org_id: 8, title: 'Product Division', expanded: true }, { org_id: 9, title: 'Implementor Division', expanded: true }],
    // }];
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      treeData: [],
      token: '',
      apiws: '',
      deleteConfirm: false,
      deleteId: '',
      virttree: true,
      heighttree: '500px',
      dataTrees: [],

    };
    this.deleteItem = this.deleteItem.bind(this);
    this.viewfulltree = this.viewfulltree.bind(this);
  }

  componentDidMount = () => {
    const { fullMode } = this.props;
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.dataTreeLoad();
    });
    if (fullMode) {
      this.viewfulltree();
    }
  }


  componentWillReceiveProps() {
    const { fullMode } = this.props;
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.dataTreeLoad();
    });
    if (fullMode) {
      this.viewfulltree();
      this.dataTreeLoad();
    }
  }

  //   componentDidUpdate = () => {
  //     const { dataTree } = this.props;
  //     this.setState({ treeData: dataTree });
  //   }

  dataTreeLoad() {
    const { token, apiws, slcunit } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let datatree = [];
    axios.post(
      `${apiws}/orgorganization/getAllKpi/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        datatree = unplet(response.data.data);
        this.setState({ dataTrees: datatree });
      }

      const dataResponse = response.data.data;
      const Arr = slcunit;
      if (dataResponse.length === 0) {
        Arr.push({ value: 0, label: 'Unit Organization' });
        this.setState({ slcunit: Arr });
      } else {
        Arr.push({ value: 0, label: 'Unit Organization' });
        dataResponse.map(obj => (
          Arr.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ slcunit: Arr });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  deleteItem(id) {
    this.setState({ deleteConfirm: true, deleteId: id });
  }

  deleteMode() {
    // this.setState({ deleteConfirm: true, deleteId: id });
    const userid = ls.get('user_cred').usr_id;
    const { deleteId } = this.state;
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgorganization/deleteitem/${deleteId}`,
      `duser_id=${userid}`,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Delete Success', 'success');
        this.props.deleteMode();
        this.props.loadTree();
      } else if (response.data.status === 'child') {
        showNotifications('Fail', 'Data is a parent, delete the child first!', 'danger');
      } else {
        showNotifications('Fail', 'Delete Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      showNotifications('Fail', 'Delete Failed', 'danger');
      // this.setState({ button_disabled: false });
    });
    this.setState({ deleteConfirm: false });
  }

  viewfulltree() {
    this.setState({ virttree: false, heighttree: 'auto' });
  }

  updateTree(datatree) {
    this.setState({ dataTrees: datatree });
  }

  // eslint-disable-next-line class-methods-use-this
  updateNode(val, tt) {
    let title;
    if (val === null) {
      title = (
        <textarea >
          {tt}
        </textarea>
      );
    } else {
      title = (
        <div>
          {tt}
        </div>
      );
    }
  }

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      dataTrees, deleteConfirm, virttree, heighttree,
    } = this.state;
    // const {
    //   editMode, editcond, deletecond,
    // } = this.props;

    return (
      <Card className="treeMenu_card" style={{ padding: '0px 15px 0px 15px' }}>
        <CardBody style={{ padding: '20px 5px 30px 10px', height: heighttree }}>
          <SortableTree
            className="kpiOrgTree_pref"
            isVirtualized={virttree}
            treeData={dataTrees}
            rowHeight={50}
            onChange={treedata => this.updateTree(treedata)}
            generateNodeProps={({ node, path }) =>
            ({
              title: (node.ppi_id !== null && (
                <textarea
                  rows="2"
                  cols="55"
                  style={{
                    fontWeight: 'normal',
                    color: '#1874ef',
                    border: '1px solid #bbb',
                    borderRadius: '2px',
                    padding: '5px',
                    marginTop: '.4rem',
                  }}
                  readOnly
                >
                  {node.title}
                </textarea>
              )
              ),
            })
            }
            scaffoldBlockPxWidth={100}
            icons={faCrown}
            canDrag={false}
          />
        </CardBody>
      </Card>
    );
  }
}

export default translate('common')(KPIUnitOrganization);
