import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
// import { translate } from 'react-i18next';
import Promotionmutation from './Components/index';
import PropTypes from 'prop-types';

export default class PromotionMutation extends PureComponent {
  static propTypes = {
    location: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Container>
        <Row>
          <Promotionmutation
            parmid={this.props.location}
          />
        </Row>
      </Container>
    );
  }
}
