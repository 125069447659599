/* eslint-disable array-callback-return */
/*
 * Page Organization Level
 * Notes: Master Data Organization Level
 * @author Fadri
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 26/11/2019
 * Note Update : Update validation, Data table every function crud
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';

let orgLevelExist = [];
class UnitOrganizationLevelPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LEVEL') },
      { '2': t('LBL.NAME') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '50%', '25%', '20%'],
      urlData: [],
      statusEdit: false,
      addcond: '',
      editcond: '',
      deletecond: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteOrgLevel = this.deleteOrgLevel.bind(this);
    this.editOrgLevel = this.editOrgLevel.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getOrgLevel = this.getOrgLevel.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getOrgLevel();
      this.getButton();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/orgorganizationlevel/getAllItem`,
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LEVEL') },
      { '2': t('LBL.UNIT_ORGANIZATION_LEVEL') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getOrgLevel() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    orgLevelExist = [];
    axios
      .post(`${ls.get('ws_ip')}/orgorganizationlevel/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          console.log(response.data.data);
          const dataResponse = response.data.data;
          // const array = [];
          for (let i = 0; i < dataResponse.length; i += 1) {
            orgLevelExist[i] = dataResponse[i].level;
          }
          this.child.reload('delete');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle(item) {
    if (item === 'add') {
      this.getOrgLevel();
    }
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let url;
    let error = true;
    const errorOrgLevel = orgLevelExist;
    if (!values.level) {
      error = false;
    }
    if (errorOrgLevel.includes(values.lwvwl)) {
      error = false;
    }
    const apiws = ls.get('ws_ip');
    const userid = ls.get('user_cred').usr_id;
    const id = values.olv_id;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/orgorganizationlevel/saveItem`;
      data = { ...values, cuser_id: userid };
    } else {
      data = { ...values, muser_id: userid };
      url = `${apiws}/orgorganizationlevel/updateItem/${id}`;
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.child.reload('addedit');
            this.toggle();
            this.getOrgLevel();
            this.props.onUpdate();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  editOrgLevel(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgorganizationlevel/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const indexOrgLevel = orgLevelExist.indexOf(getResponseData[0].level);
          if (indexOrgLevel !== -1) {
            orgLevelExist.splice(indexOrgLevel, 1);
          }
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(getResponseData[0]);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteOrgLevel(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/orgorganizationlevel/deleteitem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');

              this.getOrgLevel();
              // this.props.onDelete();
              if (orgLevelExist.length > 0) {
                this.child.reload();
              }
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { statusEdit, urlData } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.LEVEL')}
      </Button>
    );
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addlevelnameform"
          disabled={pristine}
        >
          {' '}
          Edit
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addlevelnameform"
          type="submit"
          disabled={pristine}
        >
          Save
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="olv_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteOrgLevel}
                  updateFunc={this.editOrgLevel}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  displayTitle="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.LEVEL')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addlevelnameform"
                  id="addlevelnameform"
                >
                  <input type="hidden" value="" name="olv_id" />
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEVEL')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="level"
                        component={renderTextInput}
                        type="text"
                        maxLength="80"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.UNIT_ORGANIZATION_LEVEL')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="level_name"
                        component={renderTextInput}
                        type="text"
                        maxLength="80"
                      />
                    </div>
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorOrgLevel = orgLevelExist;
  if (!values.level) {
    errors.level = 'Organization level field shouldn’t be empty';
  }
  if (!values.level_name) {
    errors.level_name = 'Organization level name field shouldn’t be empty';
  }
  if (errorOrgLevel.indexOf(values.level) !== -1) {
    errors.level = 'Organization level is already taken';
  }
  return errors;
};

export default reduxForm({
  form: 'unitOrganizationLevelForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(UnitOrganizationLevelPage));
