/* eslint-disable max-len, function-paren-newline */

import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ls from 'local-storage';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import config from '../../../../shared/components/config/config';
import EyeIcon from 'mdi-react/EyeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/fontawesome-free-solid';
import { syncLang } from '../../../../redux/actions/langAction';
import i18next from 'i18next';
import Cookies from 'universal-cookie';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      ipws: config.ws.ip,
      username: '',
      password: '',
      redirect: false,
      expired: false,
      spanError: 'none',
      textError: '',
      subdomain: config.subdomain,
    };

    this.showPassword = this.showPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }

  getDetailUserByToken(token) {
    fetch(`${this.state.ipws}/user/getAllItemByToken`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result);
        if (result.status && result.status === 'ok') {
          ls.set('user_cred', result.data[0]);
          ls.set('function_access', result.function_access);
          ls.set('membership', result.membership);
          this.setState({ redirect: true });
        } else if (result.status === 'expired') {
          ls.set('user_cred', result.data[0]);
          this.setState({ expired: true });
        }
      });
  }

  handleUsername(event) {
    this.setState({
      username: event.target.value,
      spanError: 'none',
    });
  }

  handlePassword(event) {
    this.setState({
      password: event.target.value,
      spanError: 'none',
    });
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    let curLang = cookie.get('language');
    if (typeof curLang === 'undefined') curLang = 'gbr';

    const { username, password } = this.state;
    if (!username) {
      this.setState({
        textError: 'The username field is empty',
        spanError: '',
      });
    }
    if (!password) {
      this.setState({
        textError: 'The password field is empty!',
        spanError: '',
      });
    }
    ls.set('ws_ip', this.state.ipws);
    fetch(`${this.state.ipws}/login/auth`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `username=${this.state.username}&password=${this.state.password}&subdomain=${this.state.subdomain}`,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 'SUCCESS') {
          ls.set('token', result.token);
          this.getDetailUserByToken(result.token);

          syncLang()
            .then((langOpts) => {
              i18next.init(
                {
                  interpolation: { escapeValue: false }, // React already does escaping
                  lng: curLang,
                  ...langOpts,
                },
                () => {
                  this.forceUpdate();
                },
              );
            })
            .catch((err) => {
              console.log('set lang erorr', err);
            });
        } else if (result.status === 'BLOCK TEMPORARY') {
          this.setState({
            textError: 'Username has been blocked please try 1 hour again!',
            spanError: '',
          });
        } else if (result.status === 'FAILED') {
          this.setState({
            textError: 'Invalid Username or Password!',
            spanError: '',
          });
        } else if (result.status === 'BLOCKED') {
          this.setState({
            textError: 'Username has been blocked, contact your Administrator!',
            spanError: '',
          });
        } else if (result.status === 'INACTIVE') {
          this.setState({
            textError: 'Username inactive, please contact Administrator!',
            spanError: '',
          });
        } else if (result.status === 'NULL') {
          this.setState({
            textError: 'Username and password not registered!',
            spanError: '',
          });
        }
      });
    event.preventDefault();
  }

  showPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { spanError, textError } = this.state;
    let logoDev = false;
    if (
      typeof config.environment === 'undefined' ||
      config.environment === 'dev' ||
      config.environment === 'development'
    ) {
      logoDev = true;
    }
    // console.log(this.state.redirect, 'RETURN TO DASHBOARD <<<<<');
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    if (this.state.expired) {
      return <Redirect to="/membership" />;
    }

    return (
      <form className="form login-form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span
            className="form__form-group-error"
            style={{
              background: '#ffbcbc',
              borderRadius: '3px',
              position: 'absolute',
              padding: '5px 10px 5px 10px',
              margin: '-25px 0px 0px 0px',
              width: '100%',
              display: spanError,
            }}
          >
            {textError}
          </span>
        </div>
        {logoDev ? (
          <div className="dev-version-login">
            <FontAwesomeIcon icon={faCogs} />
            Development Version
          </div>
        ) : (
          ''
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              value={this.state.username}
              onChange={this.handleUsername}
              component="input"
              type="text"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="form__form-group mb-5">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              value={this.state.password}
              onChange={this.handlePassword}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              className={`form__form-group-button${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <Link to="/forgot_password">Forget Password ?</Link>
          </div>
        </div>
        {/* <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
            />
          </div>
        </div> */}
        <div className="account__btns">
          <button
            onClick={this.handleSubmit}
            className="btn btn-primary account__btn"
            color="primary"
            type="submit"
          >
            Sign In
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'loginFormRedux', // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LogInForm);
