/* eslint-disable array-callback-return, prefer-destructuring, arrow-parens, object-shorthand */
/* eslint-disable react/no-unused-state, react/no-unescaped-entities, operator-assignment */
/* eslint-disable max-len, react/no-did-mount-set-state, no-unused-vars, no-lonely-if */
/* eslint-disable quote-props, camelcase, no-return-assign, prefer-destructuring, no-unneeded-ternary */
/* eslint-disable class-methods-use-this, prefer-template, prefer-const. object-shorthand */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import {
  faSync,
  faTrashAlt,
  faPencilAlt,
  faFileAlt,
  faDollarSign,
} from '@fortawesome/fontawesome-free-solid';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { translate } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import renderTextInput from '../../../../shared/components/form/TextInput';
import EditTable from '../../../../shared/components/table/EditableTable';
import Pagination from '../../../../shared/components/pagination/Pagination';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import moment from 'moment';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import LoanAdd from './loanadd';

class LoanPageTable extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    parmid: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      // t('LBL.LOAN_NUMBER'),
      t('LBL.LOAN_TYPE'),
      t('LBL.LOAN_DATE'),
      t('LBL.LOAN'),
      t('LBL.OUTSTANDING'),
      t('LBL.INSTALLMENT'),
      // t('LBL.STATUS'),
      t('FRM.FUNCTION'),
    ];
    this.state = {
      modal: false,
      modalPembayaran: false,
      loan: '',
      installment: '',
      interest: '',
      payment: '',
      outstanding: '',
      parmelhId: '',
      redirect: false,
      redirectPayment: false,
      arrPeriod: [],
      urlData: '',
      disabledMonth: '',
      disabledYear: '',
      arrYear: [],
      dataInitEdit: [],
      dataStateEdit: [],
      spanErrorPayment: 'none',
      spanErrorPaymentmethod: 'none',
      spanErrorPaymentperiod: 'none',
      statusEdit: false,
      dataWidth: ['5%', '30%', '10%', '10%', '10%', '10%', '5%'],
    };
    this.toggle = this.toggle.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
    this.editFunc = this.editFunc.bind(this);
    this.deleteFunc = this.deleteFunc.bind(this);
    this.fileFunc = this.fileFunc.bind(this);
    this.toggleProsesPembayaran = this.toggleProsesPembayaran.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.paymentFunc = this.paymentFunc.bind(this);
    this.getButton = this.getButton.bind(this);
    this.disabledPeriod = this.disabledPeriod.bind(this);
    this.disabledunPeriod = this.disabledunPeriod.bind(this);
  }

  componentWillMount = () => {
    this.getButton();
  };

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getYear();
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/emploanhist` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      // t('LBL.LOAN_NUMBER'),
      t('LBL.LOAN_TYPE'),
      t('LBL.LOAN_DATE'),
      t('LBL.LOAN'),
      t('LBL.OUTSTANDING'),
      t('LBL.INSTALLMENT'),
      // t('LBL.STATUS'),
      t('FRM.FUNCTION'),
    ];
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getYear() {
    const startYear = new Date().getFullYear();
    let modifyYear = new Date().getFullYear() - 2;
    const year = [];
    while (modifyYear <= startYear) {
      year.push({
        value: modifyYear,
        label: modifyYear,
      });
      modifyYear = modifyYear + 1;
    }
    // console.log(year);
    this.setState({ arrYear: year });
  }

  // getPeriode() {
  //   const { apiws, token } = this.state;
  //   const config = {
  //     headers: {
  //       Authorization: `bearer ${token}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   axios.get(
  //     `${apiws}/payperiod`,
  //     config,
  //   ).then((res) => {
  //     const period = res.data.data;
  //     const array = [];
  //     if (period.length === 0) {
  //       this.setState({ arrPeriod: array });
  //     } else {
  //       period.map(obj => (
  //         array.push({ value: obj.pyp_id, label: obj.period_code })
  //       ));
  //       this.setState({ arrPeriod: array });
  //     }
  //   }).catch(() => {
  //     // console.log(error);
  //   });
  // }

  toggle(x) {
    if (x === 'add') {
      this.setState({
        dataInitEdit: [],
        dataStateEdit: [],
        statusEdit: false,
        spanErrorPaymentmethod: 'none',
        spanErrorPayment: 'none',
        spanErrorPaymentperiod: 'none',
      });
    }
    if (x === 'close') {
      this.getTableData();
    }
    this.setState(
      {
        modal: !this.state.modal,
        loan: '',
        totalLoan: '',
        installment: '',
        interest: '',
      },
      () => {
        this.props.destroy();
      },
    );
  }

  toggleProsesPembayaran() {
    this.setState(
      {
        modalPembayaran: !this.state.modalPembayaran,
        payment: '',
        outstanding: '',
        term: '',
      },
      () => {
        this.props.destroy();
      },
    );
  }

  convertDate(str) {
    const date = new Date(str);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }

  mapFormData(b, a) {
    let val_b;
    if (b !== '') {
      if (a.indexOf('_date') !== -1) {
        val_b = this.convertDate(b);
      } else if (typeof b === 'object') {
        val_b = b.value;
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  // handleSubmit(values) {
  //   const empId = this.props.parmid.state.usrid;
  //   const {
  //     apiws,
  //     token,
  //     idUser,
  //     loan,
  //     interest,
  //     totalLoan,
  //     installment,
  //   } = this.state;
  //   const formBody = Object.keys(values).map(key =>
  //     `${encodeURIComponent(key)}=${encodeURIComponent(this.mapFormData(values[key], key))}`).join('&');
  //   const config = {
  //     'Authorization': `bearer ${token}`,
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   };
  //   let add;
  //   let action;
  //   let link;
  //   let body;
  //   if (typeof values.elh_id === 'undefined') {
  //     add = 1;
  //     action = 'POST';
  //     link = `${apiws}/emploanhist`;
  //     body = [
  //       formBody,
  //       `loan=${loan}`,
  //       `total_loan=${totalLoan}`,
  //       `interest=${interest}`,
  //       `installment=${installment}`,
  //       `cuser_id=${idUser}`,
  //       `emp_id=${empId}`,
  //     ].join('&');
  //   } else {
  //     add = 0;
  //     action = 'PUT';
  //     link = `${apiws}/emploanhist/${values.elh_id}`;
  //     body = [
  //       formBody,
  //       `loan=${loan}`,
  //       `total_loan=${totalLoan}`,
  //       `interest=${interest}`,
  //       `installment=${installment}`,
  //       `muser_id=${idUser}`,
  //       `emp_id=${empId}`,
  //     ].join('&');
  //   }
  //   const options = {
  //     method: action,
  //     url: link,
  //     headers: config,
  //     data: body,
  //   };
  //   axios(options).then((response) => {
  //     if (response.data.status === 'ok') {
  //       this.toggle('close');
  //       this.getTableData();
  //       showNotifications('Success', 'Save Success', 'success');
  //       this.props.destroy();
  //     }
  //   });
  // }

  handleSubmitPayment(values) {
    const empId = this.props.parmid.state.usrid;
    const { apiws, token, idUser, outstanding, payment } = this.state;
    const formBody = Object.keys(values)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.mapFormData(values[key], key),
          )}`,
      )
      .join('&');
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let add;
    let action;
    let link;
    let body;
    let period;
    const paymentby = values.lop_id;
    if (typeof values.lop_id === 'undefined') {
      period = '';
    } else {
      if (paymentby === '1') {
        let month;
        let year;
        if (typeof values.month === 'object') {
          month = values.month.value;
        } else {
          month = '';
        }
        if (typeof values.year === 'object') {
          year = values.year.value;
        } else {
          year = '';
        }
        period = `${year}${month}`;
      } else {
        period = '';
      }
    }
    const newOutstanding = parseFloat(outstanding) - parseFloat(payment);
    if (typeof values.elp_id === 'undefined') {
      add = 1;
      action = 'POST';
      link = `${apiws}/emploanpayment`;
      body = [
        formBody,
        `period=${period}`,
        `payment=${payment}`,
        `outstanding=${newOutstanding}`,
        `cuser_id=${idUser}`,
        `emp_id=${empId}`,
      ].join('&');
    } else {
      add = 0;
      action = 'PUT';
      link = `${apiws}/emploanpayment/${values.elp_id}`;
      body = [
        formBody,
        `period=${period}`,
        `payment=${payment}`,
        `outstanding=${outstanding}`,
        `muser_id=${idUser}`,
        `emp_id=${empId}`,
      ].join('&');
    }
    if (payment === '') {
      this.setState({ spanErrorPayment: '' });
    } else if (typeof values.lop_id === 'undefined') {
      this.setState({ spanErrorPaymentmethod: '' });
    } else {
      if (period === '' && paymentby === '1') {
        this.setState({ spanErrorPaymentperiod: '' });
      } else {
        const options = {
          method: action,
          url: link,
          headers: config,
          data: body,
        };
        axios(options).then((response) => {
          if (response.data.status === 'ok') {
            this.toggleProsesPembayaran();
            this.getTableData();
            showNotifications('Success', 'Save Success', 'success');
            this.props.destroy();
          }
        });
      }
    }
  }

  editFunc(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${apiws}/emploanhist/${id}`, config).then((response) => {
      if (response.data.status === 'ok') {
        this.setState({ statusEdit: true });
        const dataResponse = response.data.data.loan;
        this.toggle('edit');
        const interest = dataResponse[0].interest;
        const isPayment = response.data.data.payment.length > 0 ? true : false;
        this.setState({
          dataStateEdit: {
            loan: dataResponse[0].loan,
            interest: interest.replace('.', ','),
            totalLoan: dataResponse[0].total_loan,
            installment: dataResponse[0].installment,
            payment: isPayment,
          },
        });
        const startDate =
          dataResponse[0].start_date === null
            ? ''
            : moment(dataResponse[0].start_date);
        this.setState({
          dataInitEdit: {
            elh_id: dataResponse[0].elh_id,
            lot_id: dataResponse[0].lot_id,
            loan_name: dataResponse[0].name,
            start_date: startDate,
            loa_id: `${dataResponse[0].loa_id}`,
            term: dataResponse[0].installment_term,
            remarks: dataResponse[0].remarks,
          },
        });
      }
    });
  }

  deleteFunc(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${apiws}/emploanhist/${id}`, config, `duser_id=${userid}`)
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 100);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  fileFunc(id) {
    this.setState({
      redirectPayment: true,
      parmelhId: id,
    });
  }

  disabledunPeriod() {
    this.setState({
      disabledMonth: '',
      disabledYear: '',
      spanErrorPaymentmethod: 'none',
    });
  }

  disabledPeriod() {
    this.setState({
      disabledMonth: 'disabled',
      disabledYear: 'disabled',
      spanErrorPaymentmethod: 'none',
    });
  }

  paymentFunc(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${apiws}/emploanhist/${id}`, config).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse1 = response.data.data.loan;
        const dataResponse2 = response.data.data.payment;
        const outStanding =
          dataResponse2.length > 0
            ? dataResponse2[0].outstanding
            : dataResponse1[0].total_loan;
        const terM =
          dataResponse2.length > 0 ? parseInt(dataResponse2[0].term, 0) + 1 : 1;
        this.toggleProsesPembayaran('add');
        this.setState({
          totalLoan: dataResponse1[0].total_loan,
          outstanding: outStanding,
        });
        this.props.initialize({
          elh_id: dataResponse1[0].elh_id,
          loan_name: dataResponse1[0].loan_name,
          term: terM,
        });
      }
    });
  }

  render() {
    const { handleSubmit, pristine, t } = this.props;
    const name = this.props.parmid.state.name.replace(/\s\s+/g, ' ');
    const usrid = this.props.parmid.state.usrid;
    const menid = this.props.parmid.state.men_id;
    const fgaid = this.props.parmid.state.fga_id;
    const menemployee = this.props.parmid.state.men_employee;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.LOAN')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: { men_id: menemployee, fga_id: fgaid },
          }}
        />
      );
    }
    if (this.state.redirectPayment) {
      return (
        <Redirect
          to={{
            pathname: '/administration/loanhistory',
            state: {
              elhId: this.state.parmelhId,
              usrId: this.props.parmid.state.usrid,
              name: this.props.parmid.state.name,
              code: this.props.parmid.state.code,
              men_id: menid,
              fga_id: fgaid,
              men_employee: menemployee,
            },
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          {/* <Col md={8}>
            <div className="user-photo">
              <img
                className="profile-pic"
                src={(this.props.parmid.state.photo.substr(this.props.parmid.state.photo.length - 4) === 'null') ? 'https://api.adorable.io/avatars/285/abott@adorable.png' : this.props.parmid.state.photo}
                alt=""
              />
            </div>
            <span className="lbl_emp_name">Andi Setiawan</span>
          </Col> */}
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ paddingBottom: '10px' }}>
                  <h3 className="page-title">{t('LBL.LOAN_HISTORY')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('LBL.VIEW_LOAN_HISTORY')}
                  </h3>
                </Col>
                <ViewListTable
                  url={this.state.urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="elh_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteFunc}
                  updateFunc={this.editFunc}
                  paymentFunc={this.paymentFunc}
                  fileFunc={this.fileFunc}
                  onRef={(ref) => (this.child = ref)}
                  textHead={`${this.props.parmid.state.name} (${this.props.parmid.state.code}) `}
                  conds={this.props.parmid.state.usrid}
                  displayStyleHead=""
                  editCond={this.state.editcond}
                  addCond={this.state.addcond}
                  deleteCond={this.state.deletecond}
                  buttonAction={['edit', 'delete', 'file', 'payment']}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="m5">
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog
              modal-input"
            style={{ marginTop: '100px', maxWidth: '650px' }}
          >
            <LoanAdd
              empname={name}
              userid={usrid}
              toggle={this.toggle}
              stateEdit={this.state.dataStateEdit}
              initEdit={this.state.dataInitEdit}
              statusEdit={this.state.statusEdit}
            />
          </Modal>
          <Modal
            isOpen={this.state.modalPembayaran}
            toggle={this.toggleProsesPembayaran}
            className="modal-dialog modal-input"
            style={{ marginTop: '100px', maxWidth: '650px' }}
          >
            <ModalHeader toggle={this.toggleProsesPembayaran}>
              {t('LBL.LOAN_PAYMENT')}
            </ModalHeader>
            <ModalBody>
              <form
                id="addpaymentloan"
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmitPayment)}
              >
                <Field
                  name="elh_id"
                  component={renderTextInput}
                  type="hidden"
                />
                <Field
                  name="elp_id"
                  component={renderTextInput}
                  type="hidden"
                />
                <Container>
                  {/* <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Nomer Pinjaman</span>
                        <div className="form__form-group-field">
                          <Field
                            name="noPinjaman"
                            component="input"
                            type="text"
                            placeholder="No Pinjaman"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.NAME')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderTextInput}
                            type="text"
                            placeholder={name}
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.LOAN_TYPE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="loan_name"
                            component={renderTextInput}
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.LOAN')}
                        </span>
                        <div className="form__form-group-field">
                          <NumberFormat
                            maxLength="19"
                            style={{ textAlign: 'right' }}
                            component={NumberFormat}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowedDecimalSeparators={[',']}
                            type="text"
                            prefix="Rp "
                            value={this.state.totalLoan}
                            placeholder="Loan (Rp)"
                            disabled
                            onValueChange={() => {
                              // let a;
                              // alert(this.state.loan);
                              // if (this.state.loan !== null) {
                              //   a = this.state.loan.slice();
                              // }
                              // a = val.value;
                              // this.setState({ loan: a });
                              // this.setState({ totalLoan: a });
                            }}
                          />
                          {/* <Field
                            name="name"
                            component="input"
                            type="text"
                            placeholder="Rp 11.000.000"
                            disabled
                          /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.OUTSTANDING')}
                        </span>
                        <div className="form__form-group-field">
                          <NumberFormat
                            maxLength="19"
                            style={{ textAlign: 'right' }}
                            component={NumberFormat}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowedDecimalSeparators={[',']}
                            type="text"
                            prefix="Rp "
                            value={this.state.outstanding}
                            placeholder="Rp 1.000.000"
                            disabled
                            onValueChange={(val) => {
                              // let a;
                              // alert(this.state.loan);
                              // if (this.state.loan !== null) {
                              //   a = this.state.loan.slice();
                              // }
                              const a = val.value;
                              this.setState({ loan: a });
                              // this.setState({ totalLoan: a });
                            }}
                          />
                          {/* <Field
                            name="name"
                            component="input"
                            type="text"
                            placeholder="Rp 10.000.000"
                            disabled
                          /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} lg={8} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PAYMENT_DATE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="payment_date"
                            component={renderDatePickerField}
                            placeholder="Payment Date"
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PAYMENT')}
                        </span>
                        <div className="form__form-group-field">
                          <Row>
                            <Col md={6} xs={12}>
                              <div className="d-flex align-items-center">
                                <div className="w-100">
                                  <span
                                    className="form__form-group-error-custom"
                                    style={{
                                      display: this.state.spanErrorPayment,
                                    }}
                                  >
                                    Payment field shouldn’t be empty
                                  </span>
                                  <NumberFormat
                                    maxLength="19"
                                    style={{ textAlign: 'right' }}
                                    component={NumberFormat}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    allowedDecimalSeparators={[',']}
                                    type="text"
                                    prefix="Rp "
                                    value={this.state.payment}
                                    placeholder="Payment (Rp)"
                                    onValueChange={(val) => {
                                      // let a;
                                      // alert(this.state.loan);
                                      // if (this.state.loan !== null) {
                                      //   a = this.state.loan.slice();
                                      // }
                                      const a = val.value;
                                      this.setState({
                                        payment: a,
                                        spanErrorPayment: 'none',
                                      });
                                    }}
                                  />
                                  {/* <Field
                                    name="cicilanInput"
                                    component="input"
                                    type="text"
                                    placeholder="Rp 2.000.000"
                                  /> */}
                                </div>
                              </div>
                            </Col>
                            <Col md={6} xs={12}>
                              <div className="d-flex align-items-center number-format">
                                <div className="label-form-loan">
                                  {t('LBL.TERM')}
                                </div>
                                <div className="w-100 number-format">
                                  <Field
                                    name="term"
                                    component={renderTextInput}
                                    type="number"
                                    maxLength="3"
                                    disabled
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PAYMENT_METHOD')}
                        </span>
                        <div className="form__form-group-field">
                          <Row className="w-100">
                            <span
                              className="form__form-group-error-custom"
                              style={{
                                display: this.state.spanErrorPaymentmethod,
                              }}
                            >
                              Payment method field shouldn’t be empty
                            </span>
                            <Col lg={6} md={6} xs={12}>
                              <Field
                                name="lop_id"
                                component={renderRadioButtonField}
                                label="Salary"
                                radioValue="1"
                                onChange={this.disabledunPeriod}
                              />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                              <Field
                                name="lop_id"
                                component={renderRadioButtonField}
                                label="Cash"
                                radioValue="2"
                                onChange={this.disabledPeriod}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PERIOD_SALARY')}
                        </span>
                        <div className="form__form-group-field">
                          <Col
                            lg={6}
                            md={6}
                            xs={12}
                            style={{ paddingLeft: '0px' }}
                          >
                            <span
                              className="form__form-group-error-custom"
                              style={{
                                display: this.state.spanErrorPaymentperiod,
                              }}
                            >
                              Period salary field shouldn’t be empty
                            </span>
                            <Field
                              disabled={this.state.disabledMonth}
                              name="month"
                              component={renderSelectField}
                              // options={this.state.arrPeriod}
                              options={[
                                { value: '01', label: `${t('LBL.JANUARY')}` },
                                { value: '02', label: `${t('LBL.FEBRUARY')}` },
                                { value: '03', label: `${t('LBL.MARCH')}` },
                                { value: '04', label: `${t('LBL.APRIL')}` },
                                { value: '05', label: `${t('LBL.MAY')}` },
                                { value: '06', label: `${t('LBL.JUNE')}` },
                                { value: '07', label: `${t('LBL.JULI')}` },
                                { value: '08', label: `${t('LBL.AUGUST')}` },
                                { value: '09', label: `${t('LBL.SEPTEMBER')}` },
                                { value: '10', label: `${t('LBL.OCTOBER')}` },
                                { value: '11', label: `${t('LBL.NOVEMBER')}` },
                                { value: '12', label: `${t('LBL.DESEMBER')}` },
                              ]}
                            />
                          </Col>
                          <Col lg={6} md={6} xs={12}>
                            <Field
                              disabled={this.state.disabledYear}
                              name="year"
                              component={renderSelectField}
                              options={this.state.arrYear}
                            />
                          </Col>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleProsesPembayaran}>
                {t('FRM.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                form="addpaymentloan"
                disabled={pristine}
                onClick={this.toggleAndRefresh}
              >
                {' '}
                {t('FRM.SAVE')}
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'LabelListForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  // asyncValidate,
  // asyncBlurFields: ['job_level'],
})(translate('global')(LoanPageTable));
