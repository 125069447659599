/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars, react/no-did-mount-set-state, max-len, react/no-unused-state */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faCertificate,
} from '@fortawesome/fontawesome-free-solid';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { store } from '../../../App/store';
import { connect } from 'react-redux';

const nodataimg = `${process.env.PUBLIC_URL}/img/relax-at-home.png`;

class TaskTabData extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayTask: this?.props.user?.tasklist?.length > 0,
      displayTaskList: this?.props.user?.tasklist?.length > 0,
      displayTaskKpiplan: false,
      totalKpiplan: 0,
      contentData: [],
      redirectKpi: false,
      tasklistData: [],
      token: ls.get('token'),
      apiws: ls.get('ws_ip'),
      idUser: ls.get('user_cred').usr_id,
    };

    this.getKpiplan = this.getKpiplan.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.gotoTasklistKpi = this.gotoTasklistKpi.bind(this);
  }

  componentDidMount = () => {
    this.getKpiplan();

    if (this.props.user?.tasklist?.length == 0) {
      this.getTaskList();
    } else {
      setTimeout(() => {
        this.getTaskList();
      }, 200);
    }
  };

  getKpiplan() {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .get(`${apiws}/pfmkpiplan/getAllkpiplan/${idUser}`, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.data.length > 0) {
            const total = response.data.data.length;
            this.setState({
              totalKpiplan: total,
              displayTask: true,
              displayTaskKpiplan: true,
            });
          }
        }
      });
  }

  getTaskList() {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const emp_id = ls.get('user_cred').emp_id;

    axios
      .post(`${apiws}/workflow/gettasklist`, `emp_id=${emp_id}`, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response?.data?.total > 0) {
            console.log(response?.data?.total);
            const { total } = response.data;
            const taskdata = response.data.data;
            const { workflow } = response.data;
            const arrdata = [];
            workflow?.map((obj) => {
              const { wfl_id } = obj;
              if (typeof taskdata[wfl_id] !== 'undefined') {
                arrdata.push({
                  total_task: taskdata[wfl_id].length,
                  wfl_name: obj.workflow,
                  wfl_id: obj.wfl_id,
                  data: taskdata[wfl_id],
                });
              }
            });

            store.dispatch({ type: 'SET_USER_TASKLIST', data: arrdata });
            if (!this.state.displayTaskList) {
              this.setState({
                displayTask: true,
                displayTaskList: true,
              });
            }
          } else {
            store.dispatch({ type: 'SET_USER_TASKLIST', data: [] });
            if (this.state.displayTaskList) {
              this.setState({
                displayTask: false,
                displayTaskList: false,
              });
            }
          }
        }
      });
  }

  gotoTasklistKpi() {
    this.setState({ redirectKpi: true });
  }

  gotoApproval(wfl_id, wfl_name, data) {
    this.setState({
      redirectApproval: true,
      data,
      wfl_id,
      wfl_name,
    });
  }

  render() {
    const { t } = this.props;
    const {
      displayTask,
      totalKpiplan,
      displayTaskKpiplan,
      displayTaskList,
    } = this.state;

    let contentKpiplan = '';
    let contentTask = '';

    if (this.state.redirectKpi) {
      return (
        <Redirect
          to={{
            pathname: '/performance/taskkpi',
            state: {
              periodId: this.state.periodId,
              // phaseId: this.state.phaseId,
              // periodPhaseId: this.state.periodPhaseId,
              // reviewerId: this.state.reviewerId,
            },
          }}
        />
      );
    }

    if (this.state.redirectApproval) {
      return (
        <Redirect
          to={{
            pathname: '/workflow/approval',
            state: {
              wfl_id: this.state.wfl_id,
              wfl_name: this.state.wfl_name,
              data: this.state.data,
            },
          }}
        />
      );
    }

    if (displayTaskList) {
      contentTask = this.props?.user?.tasklist?.map((obj, index) => (
        <li>
          {' '}
          <i className="icon__task-list">
            <FontAwesomeIcon icon={faBriefcase} />
          </i>{' '}
          {obj.wfl_name}
          {/* <span className="task-badge">5</span> */}
          <div className="task-detail">
            <span
              className="task-detail-badge bg-red"
              onClick={() => {
                this.gotoApproval(obj.wfl_id, obj.wfl_name, obj.data);
              }}
            >
              {obj.total_task}
            </span>
            {/* <span className="task-detail-badge bg-green">2</span>
              <span className="task-detail-badge bg-yellow">1</span> */}
          </div>
        </li>
      ));
    }

    if (displayTaskKpiplan) {
      contentKpiplan = (
        <li>
          {' '}
          <i className="icon__task-list">
            <FontAwesomeIcon icon={faCertificate} />
          </i>{' '}
          KPI Plan Approval
          <span className="task-badge" onClick={this.gotoTasklistKpi}>
            {totalKpiplan}
          </span>
          <div className="task-detail">
            <span className="task-detail-badge bg-red">0</span>
            <span className="task-detail-badge bg-green">{totalKpiplan}</span>
            <span className="task-detail-badge bg-yellow">0</span>
          </div>
        </li>
      );
    }

    const contentData = [contentTask, contentKpiplan];
    return (
      <div className="taskAlertPanel">
        <ul className="taskTab-list">
          {/* */}
          {displayTask ? (
            contentData
          ) : (
            <li style={{ borderBottom: 'none' }}>
              <div className="d-flex align-items-center no-data-box">
                <div className="no-data-img-tasklist">
                  <img src={nodataimg} alt="leave-icon" />
                </div>
                <div className="w-100 text-center">
                  <p>{t('LBL.NO_TASK')}</p>
                  {/* <p>There is no <b>task</b> for you now.</p> */}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(TaskTabData));
