/* eslint-disable no-mixed-operators */
/*
 * Page Grade
 * Notes: Master Data Grade
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';

class AttendanceDayOffPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    editGeofence: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.TITLE') },
      { '2': t('LBL.OFFICE') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '30%', '20%', '20%', '15%'],
      urlData: [],
      statusEdit: false,
      arrGroupShift: [],
      arrShift: [],
      showofficeinput: false,
      arrOffice: [],
      disabledCuti: true,
      editcond: '',
      addcond: '',
      deletecond: '',
      displayForm: false,
      ogf_id: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteGeofence = this.deleteGeofence.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getOffice = this.getOffice.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/geofence/getAllItem` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.TITLE') },
      { '2': t('LBL.OFFICE') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getTableData() {
    this.child.reload();
  }

  getOffice() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgoffice/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ dta_office: response.data.data });
          const arr = [];
          this.state.dta_office.map((data) => {
            arr.push({ value: data.off_id, label: data.office });
            return arr;
          });
          this.setState({
            arrOffice: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  showInputOffice() {
    this.setState({ showofficeinput: true });
  }

  hideInputOffice() {
    this.setState({ showofficeinput: false });
  }

  toggle(item) {
    if (item === 'add') {
      this.hideInputOffice();
    }
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    // console.log(values);
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.atd_id;

    let url;
    let data = {
      off_id: dta_office,
      atd_date: values.atd_date,
      description: values.description,
      atd_title: values.atd_title,
      atd_applies_to: atdApplies,
      is_thr: values.thr,
    };
    if (id === undefined || id === '') {
      url = `${apiws}/attdayoffoffice/saveItem`;
      data = { ...data, cuser_id: userid };
    } else {
      url = `${apiws}/attdayoffoffice/updateItem/${id}`;
      data = { ...data, muser_id: userid };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ modal: false });
            this.child.reload('addedit');
            this.props.destroy();
            this.hideInputOffice();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  handleChange(val) {
    // eslint-disable-next-line no-underscore-dangle
    console.log(val._d.getFullYear());
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        // eslint-disable-next-line no-underscore-dangle
        `${apiws}/attdayoffoffice/getYear/${val._d.getFullYear()}`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const count_component = response.data.data.length;
          const year = [];
          console.log(response.data);
          if (count_component === 0) {
            this.setState({ disabledCuti: false });
          } else {
            for (let i = 0; i < count_component; i++) {
              year.push({ year: response.data.data[i].is_cuti });
            }
            const counter = year.filter((e) => {
              if (e.year === 'true') {
                return true;
              }
              return false;
            });
            console.log(counter);
            if (counter.length === 1) {
              this.setState({ disabledCuti: true });
            } else {
              this.setState({ disabledCuti: false });
            }
          }
        }
      });
  }

  deleteGeofence(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/geofence/delete`,
            `ogf_id=${id}&duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.success === true) {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { statusEdit, urlData, arrOffice, showofficeinput } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.props.editGeofence}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.GEOFENCE_AREA')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 15px 30px 15px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ogf_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteGeofence}
                  updateFunc={this.props.editGeofence}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.atd_title) {
    errors.atd_title = 'Day off title field shouldn’t be empty';
  }
  if (!values.atd_applies_to) {
    errors.atd_applies_to = 'Day off applies to field shouldn’t be empty';
  }
  if (typeof values.atd_applies_to === 'object') {
    const atdApplies = values.atd_applies_to.value;
    if (atdApplies === 'regional') {
      if (!values.off_id) {
        errors.off_id = 'Day off applies to office field shouldn’t be empty';
      }
    }
  }
  if (!values.description) {
    errors.description = 'Description name field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'dayoffforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AttendanceDayOffPage));
