/* eslint-disable no-mixed-operators, arrow-parens */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable max-len, no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NumberFormatForm from 'react-number-format';

class NumberFormat extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
    other: PropTypes.object,
    disableTouched: PropTypes.bool,
  };

  static defaultProps = {
    disableTouched: false,
    meta: null,
    other: {},
  };

  render() {
    const {
      other, input: { onChange }, disableTouched, meta: { touched, error },
    } = this.props;
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <NumberFormatForm
          style={{ width: '100%', textAlign: 'right' }}
          type="text"
          onValueChange={(e) => onChange(e)}
          {...other}
        />
        {(error && touched) && <span className="form__form-group-error">{error}</span>}
        {(disableTouched && error) && <span className="form__form-group-error">{error}</span>}
      </div>
    );
  }
}

export default NumberFormat;
