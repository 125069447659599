import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../Layout/index';
import FieldActivityUSerList from './FieldActivityUserList';
import FieldMap from './FieldMap';
import ls from 'local-storage';
import axios from 'axios';
import moment from 'moment';
import { ACTIONS, ActivityContext, getReducer } from './ActivityContext';

const FieldActivity = () => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });
  const [state, dispatch] = getReducer();
  const [selectedDate, setSelectedDate] = useState(
    moment().format('DD/MM/YYYY'),
  );
  const [idEmp, setIdEmp] = useState(0);
  const [lng, setLng] = useState(106.8456); // Jakarta longitude
  const [lat, setLat] = useState(-6.2088); // Jakarta latitude
  const [dateHasActivity, setDateHasActivity] = useState([]);

  const getActivity = (id) => {
    if (id != undefined) {
      setIdEmp(id);
    }

    let url;
    if (id == undefined) {
      if (idEmp == 0) return false;
      url = `${auth.apiws}/facactivity/getActivityEmp/${idEmp}`;
    } else {
      url = `${auth.apiws}/facactivity/getActivityEmp/${id}`;
    }

    const conds = {
      period: selectedDate,
    };
    const bodyParameters = {
      cond: conds,
    };
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, bodyParameters, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          let dataResponse = response.data.data;
          let tempLat = 0;
          let tempLon = 0;
          if (dataResponse?.length > 0) {
            dataResponse?.map((item) => {
              tempLat += +item.location_lat;
              tempLon += +item.location_lon;
            });
            tempLat /= +dataResponse?.length;
            tempLon /= +dataResponse?.length;

            setLng(+tempLon);
            setLat(+tempLat);
          } else {
            setLng(106.8456);
            setLat(-6.2088);
          }
          setTimeout(() => {
            dispatch({ type: ACTIONS.SET_ACTIVITY, data: response.data.data });
          }, 200);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllActivity = (id) => {
    if (id != undefined) {
      setIdEmp(id);
    }

    let url;
    if (id == undefined) {
      if (idEmp == 0) return false;
      url = `${auth.apiws}/facactivity/getAllActivityUser/${idEmp}`;
    } else {
      url = `${auth.apiws}/facactivity/getAllActivityUser/${id}`;
    }

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(url, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          let tempDate = [];
          response.data.data.map((el) => {
            let dateTime = el.activity_datetime.substr(0, 10);
            tempDate.push(moment(dateTime));
          });

          setDateHasActivity(tempDate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Layout />
      <div className="container__wrap">
        <ActivityContext.Provider value={[state, dispatch]}>
          <Container>
            <Row style={{ marginRight: '-3px' }}>
              <Col md="4" sm="5">
                <FieldActivityUSerList
                  getActivity={getActivity}
                  getAllActivity={getAllActivity}
                />
              </Col>

              <Col md="8" sm="7">
                <FieldMap
                  getActivity={getActivity}
                  getAllActivity={getAllActivity}
                  setSelectedDate={setSelectedDate}
                  dateHasActivity={dateHasActivity}
                  selectedDate={selectedDate}
                  lng={lng}
                  lat={lat}
                />
              </Col>
            </Row>
          </Container>
        </ActivityContext.Provider>
      </div>
    </>
  );
};

export default FieldActivity;
