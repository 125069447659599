import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { translate } from 'react-i18next';
import './okr.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faUser,
  faPlus,
  faCalendar,
  faTrashAlt,
  faPencilAlt,
  faPenSquare,
  faEllipsisV,
  faBuilding,
  faCircle,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes, { object } from 'prop-types';
import KeyResult from './FormKeyResult';
import ChangeAlignment from './FormChangeAlignment';
import { Link } from 'react-router-dom';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../Preferences/notificationMessages';
import { faBriefcase } from '@fortawesome/fontawesome-free-solid';
import Swal from 'sweetalert2';
import FormObjective from './FormObjective';
import DetailObjective from './DetailObjective/index';
import moment from 'moment';

const Accordian = ({
  t,
  setIsCompany,
  summaryData,
  filter,
  direction,
  alignmentData,
  reloadObjective,
  ...args
}) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const [modalAlignment, setModalAlignment] = useState(false);
  const [modalObjective, setModalObjective] = useState(false);
  const [modalKeyResult, setModalKeyResult] = useState(false);
  const [modalDetailObjective, setModalDetailObjective] = useState(false);

  const [isObjCompany, setIsObjCompany] = useState(false);
  const [objDropdown, setObjDropdown] = useState('');
  const [alignDropdown, setAlignDropdown] = useState('');
  const [activeObj, setActiveObj] = useState([]);
  const [activeKr, setActiveKr] = useState([]);

  const toggleOffChange = (nameModal) => {
    if (nameModal == 'alignment') {
      setModalAlignment(false);
    } else if (nameModal == 'keyResult') {
      setModalKeyResult(false);
      setActiveKr([]);
      setActiveObj([]);
    } else if (nameModal == 'objective') {
      setModalObjective(false);
      setActiveObj([]);
    } else if (nameModal == 'objectiveDetail') {
      setModalDetailObjective(false);
      setActiveObj([]);
    }
  };

  const modalChangeAlignment = (objId) => {
    setModalAlignment(true);
    setObjId(objId);
  };
  const modalEditObjective = (objId) => {
    setModalObjective(true);
    setObjId(objId);
  };
  const modalKR = (objId, item) => {
    setModalKeyResult(true);
    setActiveObj(item);
    setObjId(item.obj_id);
  };

  // const [dropdownOpen2, setDropdownOpen2] = useState(false);
  // const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
  // const [dropdownOpen3, setDropdownOpen3] = useState(false);
  // const toggle3 = () => setDropdownOpen3(!dropdownOpen3);

  const [isLoading, setIsLoading] = useState(false);
  const [objectives, setObjective] = useState([]);
  const [objId, setObjId] = useState('');
  const [objDtl, setObjDtl] = useState('');
  const [summary, setSummary] = useState({
    count_obj: 0,
    count_kr: 0,
    achievement: 0,
  });

  const getObjectives = () => {
    setIsLoading(true);

    const raw = JSON.stringify({
      period: filter.date,
      owner: filter.owner,
      year: filter.year,
    });

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrobjective/getObjective`, raw, config)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status == 'ok') {
          setIsCompany(response.data.is_obj_company);
          setIsObjCompany(response.data.is_obj_company);

          if (filter.owner.orgId == ls.get('user_cred').org_id) {
            setSummary((prev) => ({
              ...prev,
              count_obj: response.data.count_objective,
              count_kr: response.data.count_kr_objective,
              achievement: response.data.achievement,
            }));
          }
          setIsLoading(false);
          setObjective(response.data.data);
          // setIsObjCompany(response.data.is_obj_company);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteObjective = (objId) => {
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: t('LBL.DELETEOBJCONFIRM'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${auth.apiws}/okrobjective/deleteItem/${objId}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              getObjectives();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  };

  const deleteKeyResult = (orsId) => {
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${auth.apiws}/okrkeyresult/deleteItem/${orsId}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              getObjectives();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  };

  useEffect(() => {
    if (filter.owner.nameOwner !== '') {
      getObjectives();
    }
  }, [filter]);

  // function run when success create new obj
  useEffect(() => {
    getObjectives();
  }, [reloadObjective]);

  useEffect(() => {
    summaryData(summary);
  }, [summary]);

  // useEffect(() => {
  //   alignmentData(alignment);
  // }, [alignment]);

  // console.log(isLoading, objectives?.length);
  return (
    <>
      {objectives?.length == 0 && isLoading == false ? (
        <Row>
          <div className="text-center w-100 my-5">
            <img src="/img/empty-state-okr.png" alt="" />
            <h3 className="text-empty mt-2">{t('LBL.NOOKR')}</h3>
          </div>
        </Row>
      ) : (
        <>
          {objectives?.map((item) => (
            <Accordion defaultActiveKey={`obj_${item.obj_id}`} className="mt-3">
              <Card id="cardCustom">
                <Card.Header>
                  <Row>
                    <Col md={6}>
                      <a
                        href="#"
                        className="title-objective text-decoration-none"
                        onClick={() => {
                          setModalDetailObjective(true);
                          setActiveObj(item);
                        }}
                      >
                        {item?.objective_title}
                      </a>
                      <div className="d-flex align-items-center mt-2">
                        <div className="white-space">
                          <Accordion.Toggle
                            variant="link"
                            eventKey="1"
                            onClick={() => {
                              const objState = [...objectives];
                              const indexComment = objState.findIndex(
                                (obj) => obj.obj_id == item.obj_id,
                              );

                              // console.log(
                              //   objState[indexComment],
                              //   indexComment,
                              //   'uppss',
                              // );
                              if (indexComment !== -1) {
                                objState[indexComment] = {
                                  ...objState[indexComment],
                                  show_kr: objState[indexComment].show_kr
                                    ? false
                                    : true,
                                };

                                // Set the state with the updated array
                                setObjective(objState);
                                toggleAccordion();
                              }
                            }}
                            className="text-dark bg-transparent border-0"
                          >
                            <FontAwesomeIcon
                              icon={faKey}
                              className="add-result mr-2"
                            />
                            <span>
                              {item.count_key_result} {t('LBL.KR')}
                            </span>
                            <i
                              className={`ml-2 fa ${
                                item?.show_kr
                                  ? 'fa-chevron-up'
                                  : 'fa-chevron-down'
                              }`}
                            ></i>
                          </Accordion.Toggle>
                        </div>
                        {item?.is_add_key_result && (
                          <button
                            id="addKeyResult"
                            className="add-result ml-3 white-space"
                            onClick={() => modalKR(item?.obj_id, item)}
                          >
                            <FontAwesomeIcon icon={faPlus} className="" />
                            <span className="ml-2">{t('LBL.ADDNEWKR')}</span>
                          </button>
                        )}
                      </div>
                    </Col>
                    <Col md={5}>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        id="objectives"
                      >
                        <div
                          className="objective-user my-2 my-md-0 d-flex align-items-center w-25"
                          style={{ marginRight: '30px' }}
                        >
                          <FontAwesomeIcon
                            icon={
                              item?.emp_name
                                ? faUser
                                : item?.unit_organization
                                ? faBriefcase
                                : faBuilding
                            }
                            className="add-result"
                          />
                          <div
                            className="objective-level"
                            id={`objective-level-${item.obj_id}`}
                          >
                            <span className="ml-2">
                              {item?.emp_name ??
                                item?.unit_organization ??
                                item?.company}
                            </span>
                            {item?.show_tooltip && (
                              <UncontrolledTooltip
                                placement="top"
                                target={`objective-level-${item.obj_id}`}
                              >
                                {item?.emp_name ??
                                  item?.unit_organization ??
                                  item?.company}
                              </UncontrolledTooltip>
                            )}
                          </div>
                        </div>
                        <div className="objective-date w-75">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="add-result"
                          />
                          {item?.period_name.substr(4, 1) != '-' ? (
                            <span className="ml-2">{item?.period_name}</span>
                          ) : (
                            <span className="ml-2">
                              {moment(
                                new Date(item?.period_name.substr(0, 10)),
                              ).format('D MMM YYYY')}{' '}
                              -{' '}
                              {moment(
                                new Date(item?.period_name.substr(13)),
                              ).format('D MMM YYYY')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="d-flex justify-content-between">
                          <div
                            className={`status-${String(item?.status_name)
                              .replace(' ', '-')
                              .toLowerCase()}`}
                          >
                            {item?.status_name}
                          </div>
                          <div className="d-flex align-items-center w-68">
                            <div className="mr-2 sub-progress">
                              {t('LBL.PROGRESS')}
                            </div>

                            <Progress
                              value={item?.achievement_percentage || 0}
                              className="w-100"
                              color={
                                item?.achievement_percentage <= 25
                                  ? 'danger'
                                  : item?.achievement_percentage <= 49.9
                                  ? 'warning'
                                  : 'primary'
                              }
                            >
                              {item?.achievement_percentage || 0} %
                            </Progress>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {item?.is_edit_objective && (
                      <Col md={1} className="d-flex justify-content-end">
                        <UncontrolledDropdown
                          // isOpen={item.obj_id == objDropdown ? true : false}
                          // toggle={toggle}
                          className="link_action_tbl"
                        >
                          <DropdownToggle
                            className="icon btn-outline-primary btn-data-table"
                            outline
                            onClick={() => {
                              setObjDropdown(item.obj_id);
                              setAlignDropdown(item.align_to);
                            }}
                            id={`tooltip-${item.obj_id}`}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </DropdownToggle>
                          <UncontrolledTooltip
                            placement="top"
                            target={`tooltip-${item.obj_id}`}
                          >
                            More Actions
                          </UncontrolledTooltip>
                          <DropdownMenu {...args} className="dropdown__menu">
                            <DropdownItem
                              onClick={() => {
                                setActiveObj(item);
                                setModalDetailObjective(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} className="fa-fw" />
                              <span
                                className="ml-2"
                                style={{ color: '#646777' }}
                              >
                                {t('LBL.DETAIL')}
                              </span>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                modalChangeAlignment(item.obj_id);
                                // getAlignment();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenSquare}
                                className="add-result fa-fw"
                              />
                              <span className="ml-2">{t('LBL.CHGOBJ')}</span>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setActiveObj(item);
                                modalEditObjective(item.obj_id);
                                // getAlignment();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                className="text-warning fa-fw"
                              />
                              <span className="ml-2">{t('LBL.EDITOBJ')}</span>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => deleteObjective(item?.obj_id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="text-danger fa-fw"
                              />
                              <span className="ml-2">{t('LBL.DELETEOBJ')}</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    )}
                  </Row>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {item.key_results.map((keyresult, index) => (
                      <Row id="keyResultDetails" className="key-result-details">
                        <Col md={9}>
                          <div className="d-flex align-items-start">
                            <img
                              src={
                                keyresult.emp_id
                                  ? '/img/icon-key-result-user.svg'
                                  : '/img/icon-key-results-dev.svg'
                              }
                              alt=""
                            />
                            <div className="flex-column ml-3">
                              {keyresult?.overdue_checkin && (
                                <div>
                                  <img src="/img/warning-icon.svg" alt="" />{' '}
                                  <span className="overdue-text">
                                    {t('LBL.HAVE_OVERDUE_CHECKIN')}
                                  </span>
                                </div>
                              )}
                              <div className="sub-heading-title">
                                {keyresult.key_result_title}
                              </div>
                              <Link
                                to={{
                                  pathname: '/okr/checkin',
                                  state: {
                                    ors_id: keyresult.ors_id,
                                    oos_id: keyresult.oos_id,
                                    oru_id: keyresult.this_time,
                                    status: keyresult.status_name,
                                    has_access: keyresult.has_access,
                                    checkin_target: keyresult.checkin_target,
                                    key_result_title:
                                      keyresult.key_result_title,
                                    achievement_percentage:
                                      keyresult.achievement_percentage,
                                    count_kr_notcheckin:
                                      item.key_results.length -
                                      item.count_kr_checkin,
                                    metric_name: keyresult.metric_name,
                                  },
                                }}
                              >
                                <Button className="btn btn-objective btn-primary mt-2">
                                  {t('LBL.CHECKIN')}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <Row>
                            <Col
                              md={4}
                              className="d-flex justify-content-start"
                            >
                              <div className="mr-4">
                                <div className="sub-heading-title">
                                  {t('LBL.PROGRESS')}
                                </div>
                                <div className="">
                                  {keyresult.achievement_percentage || 0}%
                                </div>
                              </div>
                            </Col>
                            <Col
                              md={4}
                              className="d-flex justify-content-start"
                            >
                              <div className="flex-column mr-4">
                                <div className="sub-heading-title">
                                  {t('LBL.STATUS')}
                                </div>
                                <div className=" no-wrap">
                                  <div
                                    className={`status-${String(
                                      keyresult.status_name,
                                    )
                                      .replace(' ', '-')
                                      .toLowerCase()}`}
                                  >
                                    {keyresult.status_name}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            {keyresult?.is_edit_key_result && (
                              <Col
                                md={4}
                                className="d-flex justify-content-end"
                              >
                                <UncontrolledDropdown className="link_action_tbl">
                                  <DropdownToggle
                                    className="icon btn-outline-primary btn-data-table"
                                    outline
                                    id={`tooltip-inner-${index}`}
                                  >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    {...args}
                                    className="show2 dropdown__menu"
                                  >
                                    <DropdownItem
                                      onClick={() => {
                                        setActiveKr(keyresult);
                                        modalKR(item.obj_id, item);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        className="text-warning"
                                      />
                                      <span className="ml-2">
                                        {t('LBL.EDITKR')}
                                      </span>
                                    </DropdownItem>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`tooltip-inner-${index}`}
                                    >
                                      More Actions
                                    </UncontrolledTooltip>
                                    <DropdownItem
                                      onClick={() =>
                                        deleteKeyResult(keyresult?.ors_id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="text-danger"
                                      />
                                      <span className="ml-2">
                                        {t('LBL.DELETEKR')}
                                      </span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))}
        </>
      )}

      {modalObjective && (
        <FormObjective
          modal={modalObjective}
          // alignment={alignment}
          reload={getObjectives}
          isObjCompany={isObjCompany}
          toggleOffChange={() => setModalObjective(false)}
          data={activeObj}
        />
      )}

      {modalAlignment && (
        <ChangeAlignment
          objId={objDropdown}
          // alignment={alignment}
          alignSelected={alignDropdown}
          modal={modalAlignment}
          reload={getObjectives}
          toggleOffChange={toggleOffChange}
        />
      )}

      {modalKeyResult && (
        <KeyResult
          id={objId}
          modal={modalKeyResult}
          reload={getObjectives}
          toggleOffChange={toggleOffChange}
          data={activeKr}
          objective={activeObj}
        />
      )}

      {modalDetailObjective && (
        <DetailObjective
          t={t}
          modal={modalDetailObjective}
          objective={activeObj}
          toggleOffChange={() => setModalDetailObjective(false)}
        />
      )}
    </>
  );
};

Accordian.propTypes = {
  direction: PropTypes.string,
};

export default translate('global')(Accordian);
