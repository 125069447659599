import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import moment from 'moment';
import ls from 'local-storage';
import axios from 'axios';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPaperclip } from '@fortawesome/free-solid-svg-icons';

import renderTextInput from '../../../../shared/components/form/TextInput';
import { connectWs } from '../../../../socket';

const TaskKanbanItem = ({
  t,
  payload,
  provided,
  snapshot,
  toggleNotification,
  getDataSection,
}) => {
  const [note, setNote] = useState('');
  const [comments, setComment] = useState([]);
  const [detailTask, setDetailTask] = useState([]);
  const [modalDetail, setModalDetail] = useState(false);
  const [file, setFile] = useState(null);

  const refSocket = useRef(null);
  const hiddenFileInput = useRef(null);

  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
    usr_id: ls.get('user_cred').usr_id,
  });
  const initWebSocket = useCallback(() => {
    const onWSMessage = (message) => {
      const jsonMessage = JSON.parse(message.data);
      setComment((prev) => [jsonMessage.data, ...prev]);
    };

    const { socket, socketTimeout } = connectWs(
      'wss://service.phirogo.com/ws',
      onWSMessage,
    );
    refSocket.current = { socket, socketTimeout };
  }, [comments]);
  const updateDoneTask = (id, status) => {
    let url;

    const formData = new FormData();
    formData.append('pse_id', payload?.pse_id);
    formData.append('pps_id', payload?.pps_id);
    formData.append('pta_id', payload?.pta_id);

    if (status == 0) {
      url = `${auth.apiws}/task/updateDoneTask/${id}`;
    } else if (status == 1) {
      url = `${auth.apiws}/task/updateUnDoneTask/${id}`;
    }
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          if (status == 0) {
            toggleNotification();
          }
          getDataSection();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCommentList = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/task/getCommentsTask/${detailTask?.pta_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          setComment(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendNotes = () => {
    if (!note.trim() && file == null) return false;

    const formData = new FormData();
    formData.append('pta_id', detailTask?.pta_id);
    formData.append('pse_id', detailTask?.pse_id);
    formData.append('attachment', file);
    formData.append('notes', note);

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(`${auth.apiws}/task/sendComment`, formData, config)
      .then((response) => {
        // setIsLoading(false);
        if (response.data.status == 'ok') {
          setFile(null);
          setNote('');
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
  };

  const handleFileRemove = () => setFile(null);
  const toggles = () => setModalDetail(!modalDetail);
  const handleClick = () => hiddenFileInput.current.click();
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const allowedType = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/jpeg',
      'application/pdf',
    ];

    if (fileUploaded) {
      const fileType = fileUploaded.type;
      const fileSize = fileUploaded.size;
      const allowedSize = 5000; // in kilobytes

      if (!allowedType.includes(fileType)) {
        Swal.fire({
          title: 'Warning',
          text: 'File type not allowed!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      if (Math.ceil(fileSize / 1000) > allowedSize) {
        Swal.fire({
          title: 'Warning',
          text: 'Maximum file size 5 MB!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      setFile(fileUploaded);
    }
  };

  useEffect(() => {
    if (detailTask?.pta_id) {
      getCommentList(); // get list comment by id task
    }
  }, [detailTask]);

  useEffect(() => {
    initWebSocket();

    return () => {
      refSocket.current.socket.close();
      clearTimeout(refSocket.current.socketTimeout);
    };
  }, []);

  return (
    <div
      className="task-item"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() => {
        setDetailTask(payload);
        // toggle active if file manager does'nt open
        if (hiddenFileInput.current == null) toggles();
      }}
    >
      <div
        className="task-priority"
        style={{
          backgroundColor:
            payload.tpr_id == '1'
              ? '#F16C7A'
              : payload.tpr_id == '2'
              ? '#F5AE0E'
              : payload.tpr_id == '3'
              ? '#28a745'
              : '',
        }}
      ></div>
      <div className="task-content">
        <Field
          name={`checkTask-${payload.pta_id}`}
          component={renderCheckBoxField}
          props={{
            input: {
              value: payload?.task_status == 1 ? true : false,
              onChange: (e) => {
                if (e?.type == 'change') {
                  updateDoneTask(payload?.pta_id, payload?.task_status);
                }
              },
            },
          }}
          disabled={payload?.is_edit == true ? false : true}
        />
        <p
          className={`task-title ${
            payload?.task_status == '1' ? 'line__through' : ''
          }  `}
        >
          {payload.task_name}
        </p>
      </div>
      <div className="task-info">
        <img
          src={
            payload?.photo_assignee ??
            payload?.photo_assignee + '/img/user-pink.svg'
          }
          width={20}
          height={20}
          style={{ borderRadius: '999px', marginRight: 8, objectFit: 'cover' }}
        />
        <small className="task-due">
          {payload?.task_finish
            ? moment(payload?.task_finish).format('DD MMM YYYY')
            : null}
        </small>
      </div>
      {/* START MODAL TASK DETAIL  */}
      <Modal
        isOpen={modalDetail}
        toggle={toggles}
        size="lg"
        className="modal__TaskDetail"
      >
        <ModalHeader className="">
          <div className="d-flex w-100">
            <div className="mr-auto">{detailTask?.task_name}</div>
            <button className="send__chat" onClick={toggles}>
              <img src="/img/close-icon.svg" width={15} alt="" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row className="p-3">
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <div className="flex-column mb-3">
                    <p>{t('LBL.DESCRIPTION')}</p>
                    <div className="desc__">
                      <p>{detailTask?.task_description ?? '-'}</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="flex-column mb-3">
                    <p>{t('LBL.TASKASSIGNEE')}</p>
                    <div className="d-flex align-items-center">
                      <img
                        src={detailTask?.photo_assignee}
                        className="avatar_assign"
                        alt=""
                      />
                      <div className="ml-2 fw-bold">
                        {' '}
                        {detailTask?.emp_name_assignee ?? '-'}
                      </div>
                    </div>
                  </div>
                  <div className="flex-column mb-3">
                    <p>{t('LBL.ASSIGNER')}</p>
                    <div className="d-flex align-items-center">
                      <img
                        src={detailTask?.photo_assigner}
                        className="avatar_assign"
                        alt=""
                      />
                      <div className="ml-2 fw-bold">
                        {' '}
                        {detailTask?.emp_name_assigner ?? '-'}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="flex-column mb-3">
                    <p>{t('LBL.DUEDATE')}</p>
                    <div className="d-flex align-items-center">
                      <span>
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="text-danger"
                        />
                      </span>
                      <div className="ml-2 fw-bold">
                        {detailTask?.task_finish
                          ? moment(detailTask?.task_finish).format('D MMM Y')
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="flex-column mb-3">
                    <p>{t('LBL.PRIORITY')}</p>
                    <div
                      className={
                        'd-flex align-items-center status__' +
                        detailTask?.priority_name +
                        ''
                      }
                    >
                      {detailTask?.priority_name ?? '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-2">
              <h5>{t('LBL.ACTIVITY')}</h5>
              <div className="d-flex align-items-center mt-3 ">
                <img
                  src={
                    ls.get('user_cred').path_photo
                      ? ls.get('ws_ip') +
                        '/public/uploads' +
                        ls.get('user_cred').path_photo
                      : '/img/icon-avatar-user.svg'
                  }
                  className="user__avatar"
                  alt="avatar-user"
                />
                <div className="mx-2 form w-100">
                  <input
                    value={note}
                    maxLength={1000}
                    type="text"
                    placeholder={'Enter Comment'}
                    onChange={(event) => setNote(event.target?.value)}
                    className="form__form-group-input-wrap form__form-group-input-wrap--error-above"
                  />
                </div>
                <button onClick={handleClick} className="send__chat">
                  <span style={{ fontSize: '20px', color: '#999999' }}>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </span>
                </button>
                <input
                  type="file"
                  onChange={handleChange}
                  component={renderTextInput}
                  ref={hiddenFileInput}
                  style={{ display: 'none' }}
                />
                <button className="send__chat" onClick={() => sendNotes()}>
                  <img src="/img/icon-send.svg" alt="" />
                </button>
              </div>
              <div className="mt-3 mb-2 ">
                {file?.name && (
                  <div className="preview__upload d-flex align-items-center p-2">
                    <div className="mr-auto d-flex align-items-center">
                      <img
                        src={
                          file?.file_type == 'pdf'
                            ? '/img/pdf-icon.svg'
                            : file?.file_type == 'image'
                            ? '/img/image-icon.svg'
                            : ''
                        }
                        className="mr-2"
                        width={20}
                        alt=""
                      />
                      <div>{file?.name}</div>
                    </div>
                    <button
                      className="send__chat"
                      href="#"
                      onClick={handleFileRemove}
                    >
                      <img src="/img/close-icon.svg" width={15} alt="" />
                    </button>
                  </div>
                )}
              </div>

              <div className="scrolls">
                {comments?.map((item) => (
                  <div className="d-flex align-items-center my-3">
                    <div className="w-100 d-flex">
                      <img
                        src={
                          item?.path_photo
                            ? item?.path_photo
                            : ls.get('ws_ip') +
                              '/public/uploads/img/icon-avatar-user.svg'
                        }
                        className="user__avatar"
                        alt=""
                      />
                      <div className="w-100 ml-2 notes">
                        <div className="d-flex justify-content-between">
                          <div className="sender__chat">{item?.emp_name}</div>
                          <div className="date__and__time">{item?.ctime}</div>
                        </div>
                        <div className="desc__ d-flex align-items-center">
                          <img
                            src={
                              item?.file_type == 'pdf'
                                ? '/img/pdf-icon.svg'
                                : item?.file_type == 'image'
                                ? '/img/image-icon.svg'
                                : ''
                            }
                            className="mr-2"
                            width={20}
                            alt=""
                          />
                          <a
                            className="text-decoration-none"
                            target="_blank"
                            href={
                              item?.attachment
                                ? ls.get('ws_ip') + item?.attachment
                                : '#'
                            }
                          >
                            {item?.file_name}
                          </a>
                        </div>
                        <div className="desc__">{item?.notes}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* END MODAL TASK DETAIL */}
    </div>
  );
};

export default TaskKanbanItem;
