// eslint-disable-next-line no-console
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import ls from 'local-storage';
import { connect } from 'react-redux';
import utils from '../../../../utils';
import { store } from '../../../App/store';

class ProfilePanelData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiws: ls.get('ws_ip'),
      token: ls.get('token'),
      idUser: ls.get('user_cred').usr_id,
    };
    this.getProfileData = this.getProfileData.bind(this);
  }

  componentDidMount = () => {
    // get user profile data at once from redux
    if (!utils.isObjectExist(this?.props?.user?.data)) {
      this.getProfileData();
    }
  };

  getProfileData() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let getDataUser = true;
    if (idUser === 0) {
      getDataUser = false;
    }

    if (getDataUser) {
      axios
        .post(`${apiws}/appuser/getAllItemByIdEmp/${idUser}`, '', config)
        .then((response) => {
          let userName = '';
          if (response.data.data.length !== 0) {
            userName = response.data.data[0].emp_id;
            const checkUser = userName === null ? true : false;
            const userObj = {};

            if (checkUser) {
              Object.assign(userObj, {
                empName: `${response.data.data[0].first_name} ${response.data.data[0].last_name}`,
              });
            } else {
              Object.assign(userObj, {
                empId: response.data.data[0].emp_id,
                empName: response.data.data[0].emp_employee_current.emp_name,
                empMail: response.data.data[0].emp_employee_current.email,
                posName:
                  response.data.data[0].emp_employee_current.position_name,
                empCode: response.data.data[0].emp_employee_current.emp_code,
                avatar: `${`${ls.get('ws_ip')}/public/uploads${
                  response.data.data[0].emp_employee_current.path_photo
                }`}`,
              });
            }

            store.dispatch({ type: 'SET_USER_DATA', data: userObj });
          }
        });
    }
  }

  render() {
    return (
      <div className="profile__information">
        <div className="profile__avatar" style={{ minWidth: '70px' }}>
          <img
            className="profile-pic"
            src={this.props?.user?.data?.avatar}
            alt=""
          />
        </div>
        <div className="profile__data">
          <p className="profile__name">{this.props?.user?.data?.empName}</p>
          <p className="profile__work">{this.props?.user?.data?.posName}</p>
          <p className="profile__work">{this.props?.user?.data?.empCode}</p>
          <p id="profileEmail" className="profile__contact">
            <UncontrolledTooltip placement="bottom" target="profileEmail">
              {this.props?.user?.data?.empMail}
            </UncontrolledTooltip>
            {this.props?.user?.data?.empMail}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfilePanelData);
