import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import TaskTableItem from './TaskTableItem';
import './styles/taskContainer.scss';

import TaskTableCollapse from './TaskTableCollapse';

const TaskTableContainer = (props) => {
  const {
    t,
    id,
    sectionId,
    section,
    tasks,
    titleCollapse,
    toggleOffCanvas,
    toggleNotification,
    getDetailTask,
    getDataSection,
    reloadSection,
    handleRenameSection,
    idActiveSection,
    dragOverlayItem,
    user,
    priority,
  } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <TaskTableCollapse
      t={t}
      section={section}
      idActiveSection={idActiveSection}
      handleRenameSection={handleRenameSection}
      reloadSection={reloadSection}
      sectionId={sectionId}
      title={titleCollapse}
    >
      <table className="table table-hover" id="task-tableview">
        <thead>
          <tr>
            <th style={{ width: 14 }}></th>
            <th width="40%">Task Title</th>
            <th width="15%">Assignee</th>
            <th width="15%">Due Date</th>
            <th width="15%">Task Giver</th>
            <th width="15%">Status</th>
            <th width="15%">Priority</th>
            <th style={{ width: 24 }}></th>
          </tr>
        </thead>
        <tbody ref={setNodeRef}>
          {tasks ? (
            <SortableContext
              id={id}
              items={tasks}
              strategy={verticalListSortingStrategy}
            >
              {tasks?.map((item, id) => (
                <TaskTableItem
                  t={t}
                  tab={props.tab}
                  key={item.pta_id}
                  id={item.pta_id}
                  data={item}
                  toggleOffCanvas={toggleOffCanvas}
                  toggleNotification={toggleNotification}
                  getDetailTask={getDetailTask}
                  getDataSection={getDataSection}
                  user={props.user}
                  priority={priority}
                />
              ))}
            </SortableContext>
          ) : (
            <>
              {tasks?.map((item, id) => (
                <TaskTableItem
                  t={t}
                  tab={props.tab}
                  key={item.pta_id}
                  id={item.pta_id}
                  data={item}
                  toggleOffCanvas={toggleOffCanvas}
                  toggleNotification={toggleNotification}
                  getDetailTask={getDetailTask}
                  getDataSection={getDataSection}
                  user={props.user}
                  priority={priority}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </TaskTableCollapse>
  );
};

export default TaskTableContainer;
