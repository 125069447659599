/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable import/newline-after-import */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import FileUploadDefault from './components/FileUploadDefault';
import FileUploadCustomHeight from './components/FileUploadCustomHeight';
import ls from 'local-storage';
import ws from '../../../shared/components/config/config';
import Swal from 'sweetalert2';

const consume = (stream, total = 0) => {
  while (stream.state === 'readable') {
    const data = stream.read();
    total += data.byteLength;
    console.log(`received ${data.byteLength} bytes (${total} bytes in total).`);
  }
  if (stream.state === 'waiting') {
    stream.ready.then(() => consume(stream, total));
  }
  return stream.closed;
};

// eslint-disable-next-line no-unused-vars
const uploadImage = (values) => {
  /* upload using file */
  const data = new FormData();
  data.append('file', values.files[0]);
  fetch(`${ws.ws.ip}/upload/file`, {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': `Bearer ${ls.get('token')}`,
    },
  }).then(response => response.json()).then((result) => {
    Swal.fire(
      'Success',
      'Data is saved!',
      'success',
    );
  }).catch((error) => {
    Swal.fire(
      'Error Saved',
      error,
      'error',
    );
  });
};

const FileUpload = ({ t }) => (
  <Container>
    {/* <Row>
      <Col md={12}>
        <h3 className="page-title">{t('forms.file_upload.title')}</h3>
        <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3>
      </Col>
    </Row> */}
    <Row>
      <FileUploadDefault onSubmit={uploadImage} />
      <FileUploadCustomHeight onSubmit={uploadImage} />
    </Row>
  </Container>
);

FileUpload.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(FileUpload);
