/* eslint-disable no-unused-vars, object-property-newline */
/* eslint-disable array-callback-return, jsx-a11y/alt-text */
/* eslint-disable max-len, class-methods-use-this */
/* eslint-disable no-mixed-operators, no-nested-ternary */
/* eslint-disable react/no-unused-state, no-plusplus */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable quote-props, prefer-destructuring */
/* eslint-disable camelcase, arrow-parens */
/* eslint-disable object-shorthand, no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import showNotifications from '../Preferences/notificationMessages';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import { Redirect } from 'react-router-dom';

class InformationKpi extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      arrPhase: [],
      arrPeriodPhase: [],
      redirectPlan: false,
      redirectTrack: false,
      redirectEvaluate: false,
      redirectDetailPlan: false,
      redirectDetailTracking: false,
      redirectDetailEvaluation: false,
      year: '',
      periodName: '',
      periodDate: '',
      addcond: '',
      editcond: '',
      deletecond: '',
      periodId: '',
      phaseId: '',
      periodPhaseId: '',
      reviewerId: '',
      empId: '',
      empName: '',
      empCode: '',
      phaseName: '',
    };
    this.getButton = this.getButton.bind(this);
    this.kpiPlan = this.kpiPlan.bind(this);
    this.kpiPlanDetail = this.kpiPlanDetail.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
  }

  componentDidMount = () => {
    this.getProfileData();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState(
      {
        iduser: ls.get('user_cred').usr_id,
      },
      () => {
        setTimeout(() => {
          this.getInformation();
        }, 1000);
      },
    );
  };

  getProfileData() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let getDataUser = true;
    if (ls.get('user_cred').usr_id === 0) {
      getDataUser = false;
    }
    if (getDataUser) {
      axios
        .post(
          `${ls.get('ws_ip')}/appuser/getAllItemByIdEmp/${
            ls.get('user_cred').usr_id
          }`,
          '',
          config,
        )
        .then((response) => {
          let userName = '';
          if (response.data.data.length !== 0) {
            userName = response.data.data[0].emp_id;
            const checkUser = userName === null ? true : false;
            if (checkUser) {
              this.setState({
                empId: 1,
                empName: `${response.data.data[0].first_name} ${response.data.data[0].last_name}`,
              });
            } else {
              this.setState({
                empMail: response.data.data[0].emp_employee_current.email,
              });
              this.setState({
                empId: response.data.data[0].emp_employee_current.emp_id,
              });
              this.setState({
                empName: response.data.data[0].emp_employee_current.emp_name,
              });
              this.setState({
                posName:
                  response.data.data[0].emp_employee_current.position_name,
              });
              this.setState({
                empCode: response.data.data[0].emp_employee_current.emp_code,
              });
              this.setState({
                avatar: `${`${ls.get('ws_ip')}/public/uploads${
                  response.data.data[0].emp_employee_current.path_photo
                }`}`,
              });
            }
          }
        });
    }
  }

  getInformation() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empId = this.state.empId;
    axios
      .post(
        `${ls.get('ws_ip')}/pfmperiod/getinformationkpi/${empId}`,
        '',
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const { data } = res.data;
          const year = data.period.year;
          const name = data.period.period_name;
          const period = data.period.period_date;
          // const reviewer = data.period.prv_id;
          const datamaping = [];
          let statusPhase;
          let detailPhase;
          let activePhase = false;
          let disabledPhase = false;
          let iconPhase;
          let i = 0;
          let stat;
          let j = 0;
          data.phase.forEach((obj) => {
            stat = obj.status === null ? '' : obj.status;
            const phase = obj.phase_name.toLowerCase();
            if (obj.start_date !== null) {
              statusPhase =
                obj.kpi_plan === 0
                  ? 'Not filled yet'
                  : obj.status === null
                  ? 'Draft'
                  : obj.status;
              i =
                stat.indexOf('terima') > -1 || stat.indexOf('approve') > -1
                  ? i + 1
                  : i;
              i =
                stat.indexOf('reject') > -1 || stat.indexOf('tolak') > -1
                  ? i + 99
                  : i;
              activePhase = i === j ? true : false;
              detailPhase =
                stat.indexOf('terima') > -1 ||
                stat.indexOf('approve') > -1 ||
                stat.indexOf('tolak') > -1 ||
                stat.indexOf('reject') > -1
                  ? true
                  : false;
              disabledPhase =
                statusPhase === 'Not filled yet' ||
                statusPhase === 'Draft' ||
                i > j
                  ? false
                  : true;
              iconPhase =
                phase.indexOf('plan') > -1
                  ? 'plan'
                  : phase.indexOf('tracking') > -1
                  ? 'tracking'
                  : 'evaluation';
              datamaping.push({
                pfp_id: obj.pfp_id,
                ppp_id: obj.ppp_id,
                pph_id: obj.pph_id,
                phase_name: obj.phase_name,
                start_date: obj.start_date === null ? null : obj.start_date,
                end_date: obj.end_date === null ? null : obj.end_date,
                phase_status: statusPhase,
                phase_active: activePhase,
                phase_disabled: disabledPhase,
                phase_detail: detailPhase,
                phase_icon: iconPhase,
              });
              j++;
            }
          });
          // console.log(datamaping);
          this.setState({
            arrPeriodPhase: datamaping,
            year: year,
            periodName: name,
            periodDate: period,
          });
        } else {
          this.setState({
            arrPeriodPhase: [],
            year: '',
            periodName: '',
            periodDate: '',
          });
        }
      });
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
            }
          });
        }
      });
  }

  kpiPlan(w, x, y, z) {
    // indra
    const phase = z.toLowerCase();
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empId = this.state.empId;
    axios
      .post(`${ls.get('ws_ip')}/emphierarchy/getCountEmp/${empId}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          let emp_id_parent = '';
          emp_id_parent = res.data.data[0].emp_id_parent;
          console.log('emp_id_parent', emp_id_parent);
          if (emp_id_parent === null || emp_id_parent === '') {
            showNotifications(
              'Fail',
              'Please fill your superordinat!',
              'danger',
            );
          } else {
            if (phase.indexOf('plan') > -1 || phase.indexOf('rencana') > -1) {
              this.setState({
                periodId: w, // pfp_id
                phaseId: x, // pph_id
                periodPhaseId: y, // ppp_id
                redirectPlan: true,
                phaseName: z,
              });
            }
            if (phase.indexOf('track') > -1 || phase.indexOf('monitor') > -1) {
              this.setState({
                periodId: w, // pfp_id
                phaseId: x, // pph_id
                periodPhaseId: y, // ppp_id
                redirectTrack: true,
                phaseName: z,
              });
            }
            if (
              phase.indexOf('evaluasi') > -1 ||
              phase.indexOf('evaluation') > -1
            ) {
              this.setState({
                periodId: w, // pfp_id
                phaseId: x, // pph_id
                periodPhaseId: y, // ppp_id
                redirectEvaluate: true,
                phaseName: z,
              });
            }
          }
        }
      });
  }

  kpiPlanDetail(w, x, y, z) {
    const phase = z.toLowerCase();
    if (phase.indexOf('plan') > -1 || phase.indexOf('rencana') > -1) {
      this.setState({
        periodId: w, // pfp_id
        phaseId: x, // pph_id
        periodPhaseId: y, // ppp_id
        redirectDetailPlan: true,
        phaseName: z,
      });
    }
    if (phase.indexOf('track') > -1 || phase.indexOf('monitor') > -1) {
      this.setState({
        periodId: w, // pfp_id
        phaseId: x, // pph_id
        periodPhaseId: y, // ppp_id
        redirectDetailTracking: true,
        phaseName: z,
      });
    }
    if (phase.indexOf('evaluasi') > -1 || phase.indexOf('evaluation') > -1) {
      this.setState({
        periodId: w, // pfp_id
        phaseId: x, // pph_id
        periodPhaseId: y, // ppp_id
        redirectDetailEvaluation: true,
        phaseName: z,
      });
    }
  }

  render() {
    const { t } = this.props;

    if (this.state.redirectPlan) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpi',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empId,
              empName: this.state.empName,
              empCode: this.state.empCode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    if (this.state.redirectTrack) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpitracking',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empId,
              empName: this.state.empName,
              empCode: this.state.empCode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    if (this.state.redirectEvaluate) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpievaluation',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empId,
              empName: this.state.empName,
              empCode: this.state.empCode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    if (this.state.redirectDetailPlan) {
      return (
        <Redirect
          to={{
            pathname: '/performance/plandetail',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empId,
              empName: this.state.empName,
              empCode: this.state.empCode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    if (this.state.redirectDetailTracking) {
      return (
        <Redirect
          to={{
            pathname: '/performance/trackingdetail',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empId,
              empName: this.state.empName,
              empCode: this.state.empCode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    if (this.state.redirectDetailEvaluation) {
      return (
        <Redirect
          to={{
            pathname: '/performance/evaluationdetail',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empId,
              empName: this.state.empName,
              empCode: this.state.empCode,
              phaseName: this.state.phaseName,
            },
          }}
        />
      );
    }

    return (
      <Container>
        <Row>
          <Col xs={3} md={3} lg={3} xl={3}>
            <div className="card-profile-kpi">
              <div className="profile__information">
                <div className="profile__avatar">
                  <img className="profile-pic" src={this.state.avatar} alt="" />
                </div>
                <div className="profile__data" style={{ paddingTop: '10px' }}>
                  <p className="profile__name">{this.state.empName}</p>
                  <p className="profile__name">{this.state.empCode}</p>
                  <p className="profile__work">{this.state.posName}</p>
                </div>
              </div>
              <div className="kpi-phase-info">
                {this.state.year === '' && (
                  <div style={{ textAlign: 'center' }}>
                    <b>There is no set period data now.</b>
                  </div>
                )}
                <div className="year-kpi">
                  <form className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.YEAR')}
                      </span>
                      <p>{this.state.year}</p>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.PERIOD_NAME')}
                      </span>
                      <p>{this.state.periodName}</p>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.PERIOD_DATE')}
                      </span>
                      <p>{this.state.periodDate}</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={9} md={9} lg={9} xl={9}>
            <form className="form">
              <Container>
                <Row className="m5" />
                <div className="col-md-12">
                  <div className="title-label-list">
                    <span className="m5">Phase List:</span>
                  </div>
                </div>
                {this.state.arrPeriodPhase.length === 0 ? (
                  <div className="col-md-12">
                    <div className="kpi-phase-list">
                      <div style={{ textAlign: 'center', height: '30px' }}>
                        No set data phase now.
                      </div>
                    </div>
                  </div>
                ) : (
                  this.state.arrPeriodPhase.map((data) => (
                    <div className="col-md-12">
                      <div className="kpi-phase-list">
                        <div className={`phase-icon ${data.phase_icon}`}>
                          <FontAwesomeIcon icon={faBullhorn} />
                          {} {data.phase_name}
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="w-100 col-item">
                            <div className="label-group">
                              <span className="form__form-group-label">
                                {t('LBL.START_DATE')}
                              </span>
                              <p>{data.start_date}</p>
                            </div>
                          </div>
                          <div className="w-100 col-item">
                            <div className="label-group">
                              <span className="form__form-group-label">
                                {t('LBL.END_DATE')}
                              </span>
                              <p>{data.end_date}</p>
                            </div>
                          </div>
                          <div className="w-100 col-item br-0">
                            <span
                              className="form__form-group-label"
                              style={{ verticalAlign: 'middle' }}
                            >
                              Status:
                              <br />
                              <b>{data.phase_status}</b>
                            </span>
                          </div>
                          <div className="w-100 col-item text-right">
                            {data.phase_active && (
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.kpiPlan(
                                    data.pfp_id,
                                    data.pph_id,
                                    data.ppp_id,
                                    data.phase_name,
                                  )
                                }
                                disabled={data.phase_disabled ? 'disabled' : ''}
                              >
                                {' '}
                                Process
                              </Button>
                            )}
                            {data.phase_detail && (
                              <Button
                                color="secondary"
                                onClick={() =>
                                  this.kpiPlanDetail(
                                    data.pfp_id,
                                    data.pph_id,
                                    data.ppp_id,
                                    data.phase_name,
                                  )
                                }
                                style={{ width: '82px' }}
                              >
                                {' '}
                                Detail
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'informationKpi',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(InformationKpi));
