/* eslint-disable react/prefer-stateless-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable consistent-return */

import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import { store } from '../../App/store';
import { clearUserMenu } from '../../../redux/actions/sidebarActions';

class LogOut extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idUser: ls.get('user_cred').usr_id,
      redirect: false,
    };
    this.renderRedirect = this.renderRedirect.bind(this);
  }

  componentWillMount = () => {
    // this.logOut();
    this.logOut();
  };
  componentDidMount = () => {
    ls.clear();
    store.dispatch(clearUserMenu());
    store.dispatch({ type: 'CLEAR_USER' });
    store.dispatch({ type: 'SET_USER_RELOGIN', data: false });
    //
  };
  componentWillReceiveProps() {
    // this.logOut();
  }

  logOut() {
    const { idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    store.dispatch({ type: 'SET_USER_RELOGIN', data: false });
    axios
      .post(`${ls.get('ws_ip')}/login/logout/${idUser}`, '', config)
      .then(async () => {
        ls.clear();
        this.setState({
          redirect: true,
        });
        // this.renderRedirect();
      });
  }

  renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  render() {
    // return this.renderRedirect();
    return <Redirect to="/" />;
  }
}

export default reduxForm({
  form: 'log_out_form', // a unique identifier for this form
})(LogOut);
