/* eslint-disable max-len */
/*
 * Page Payroll Component
 * Notes: Master Data Component
 * @author Gerdi
 * @date 07/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faPlus,
  faSlidersH,
  faUndo,
} from '@fortawesome/fontawesome-free-solid';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../shared/components/form/Select';
import showNotifications from '../../../Preferences/notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import Swal from 'sweetalert2';
import isNumber from '../../isnumber';

let arrDefaultComponent = [];
class Component extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onDataChanged: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      modal: false,
      modalFormulaState: false,
      modalStyle: '',
      spanTaxMethod: 'none',
      spanTaxPaid: 'none',
      allowenceData: [],
      deductionData: [],
      otherData: [],
      arrTaxCategory: [],
      statusEdit: false,
      orderNumber: '',
      bpjstype: [],
      disabledtype: false,
      disabledBackpay: false,
      arrComponent: [],
      displaySpinner: 'none',
      displaySave: '',
      disabledCancel: '',
      displayOrder: 'none',
      filterPanel: false,
      search_tax: '',
      search_compcat: '',
      search_comp: '',
    };
    this.handleSubmitComponent = this.handleSubmitComponent.bind(this);
    this.modalAddPayrollComponent = this.modalAddPayrollComponent.bind(this);
    this.modalFormula = this.modalFormula.bind(this);
    this.clickAllowance = this.clickAllowance.bind(this);
    this.clickDeduction = this.clickDeduction.bind(this);
    this.clickOther = this.clickOther.bind(this);
    this.getComponent = this.getComponent.bind(this);
    this.getOrderNumber = this.getOrderNumber.bind(this);
    this.getBpjsType = this.getBpjsType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBackPay = this.handleChangeBackPay.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
  }

  componentWillMount = () => {
    this.getComponent();
    this.getOrderNumber();
    this.props.destroy();
  };

  componentDidMount = () => {
    this.setState({ token: ls.get('token') }, () => {
      this.getComponent();
      this.getOrderNumber();
      this.getBpjsType();
    });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    const arrNameField = ['order_number'];
    document.addEventListener('keydown', function(event) {
      const target = event.target.name;
      if (target !== undefined) {
        if (arrNameField.includes(target)) {
          isNumber(event);
        }
      }
    });
  };

  getOrderNumber() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/paycomponent/getMaxNumber`, '', config).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({
          orderNumber: res.data.data[0].order_number,
        });
      }
    });
  }

  getComponent() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    arrDefaultComponent = [];
    axios
      .post(`${apiws}/paycomponent/getAll`, '', config)
      .then((rescomponent) => {
        const count_component = rescomponent.data.data.length;
        const arrAllowance = [];
        const arrDeduction = [];
        const arrOther = [];
        const Arr = [];
        if (count_component === 0) {
          this.setState({ arrComponent: Arr });
        } else {
          rescomponent.data.data.map((obj) =>
            Arr.push({ value: obj.pyc_id, label: obj.component }),
          );
          this.setState({ arrComponent: Arr });
        }
        for (let i = 0; i < count_component; i++) {
          if (rescomponent.data.data[i].is_default === '1') {
            const id_component = rescomponent.data.data[i].pyc_id;
            arrDefaultComponent.push(id_component);
          }
          if (rescomponent.data.data[i].pyt_id === 1) {
            const name_component = rescomponent.data.data[i].component;
            const id_component = rescomponent.data.data[i].pyc_id;
            const pytid = rescomponent.data.data[i].pyt_id;
            arrAllowance.push({
              pyt_id: pytid,
              pyc_id: id_component,
              component: name_component,
            });
          } else if (rescomponent.data.data[i].pyt_id === 2) {
            const name_component = rescomponent.data.data[i].component;
            const id_component = rescomponent.data.data[i].pyc_id;
            const pytid = rescomponent.data.data[i].pyt_id;
            arrDeduction.push({
              pyt_id: pytid,
              pyc_id: id_component,
              component: name_component,
            });
          } else {
            const name_component = rescomponent.data.data[i].component;
            const id_component = rescomponent.data.data[i].pyc_id;
            const pytid = rescomponent.data.data[i].pyt_id;
            arrOther.push({
              pyt_id: pytid,
              pyc_id: id_component,
              component: name_component,
            });
          }
        }
        this.setState({ allowenceData: arrAllowance });
        this.setState({ deductionData: arrDeduction });
        this.setState({ otherData: arrOther });
      });
    axios
      .post(`${apiws}/paycomponentgrouprel/getAllitemComponent`, '', config)
      .then((rescomponent) => {
        if (rescomponent.data.status === 'ok') {
          const count_component = rescomponent.data.data.length;
          for (let i = 0; i < count_component; i++) {
            const id_component = rescomponent.data.data[i].pyc_id;
            arrDefaultComponent.push(id_component);
          }
        }
      });
    axios
      .post(`${apiws}/paytaxcategory/getAll/`, '', config)
      .then((rescategory) => {
        const rtc = rescategory.data.data;
        const Arr = [];
        if (rtc.length === 0) {
          this.setState({ arrTaxCategory: Arr });
        } else {
          rtc.map((obj) =>
            Arr.push({ value: obj.ptc_id, label: obj.tax_category }),
          );
          this.setState({ arrTaxCategory: Arr });
        }
      });
  }

  getBpjsType() {
    const { apiws, token } = this.state;
    const arr = [];
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payketenagakerjaantype/getAllitem/`, '', config)
      .then((res) => {
        const count_component = res.data.data.length;
        for (let i = 0; i < count_component; i++) {
          arr.push({
            value: res.data.data[i].pbt_id,
            label: res.data.data[i].pbt_name,
          });
        }
        this.setState({ bpjstype: arr });
      });
  }

  modalAddPayrollComponent() {
    this.setState({
      modal: !this.state.modal,
      disabledCancel: '',
      displaySave: '',
      displaySpinner: 'none',
      displayOrder: 'none',
    });
  }

  clickAllowance() {
    this.props.initialize({
      pyc_id: '',
      pyt_id: '1',
      component: '',
      component_code: '',
      ptc_id: '',
      component_type: '',
      pys_id: '',
      ptm_id: '',
      // 'tax_paid_by': '',
      is_bpjs: '',
    });
    this.setState({
      modal: !this.state.modal,
      statusEdit: false,
      disabledtype: false,
      disabledBackpay: false,
    });
  }

  clickDeduction() {
    this.props.initialize({
      pyc_id: '',
      pyt_id: '2',
      component: '',
      component_code: '',
      ptc_id: '',
      component_type: '',
      pys_id: '',
      ptm_id: '',
      // 'tax_paid_by': '',
    });
    this.setState({
      modal: !this.state.modal,
      statusEdit: false,
      disabledtype: false,
      disabledBackpay: false,
    });
  }

  clickOther() {
    this.props.initialize({
      pyc_id: '',
      pyt_id: '3',
      component: '',
      component_code: '',
      ptc_id: '',
      component_type: '',
      pys_id: '',
      ptm_id: '',
      // 'tax_paid_by': '',
    });
    this.setState({
      modal: !this.state.modal,
      statusEdit: false,
      disabledtype: false,
      disabledBackpay: false,
    });
  }

  modalFormula() {
    this.setState({
      modalFormulaState: !this.state.modalFormulaState,
    });
  }

  handleSubmitComponent(values) {
    const err = 0;
    let add = 0;
    let bpjstype;
    this.setState({
      disabledCancel: 'disabled',
    });
    // mandatory tax method dan tax paid by
    // if (typeof values.ptm_id === 'undefined') {
    //   this.setState({ spanTaxMethod: 'none' });
    //   err = 0;
    // }
    // if (typeof values.tax_paid_by === 'undefined') {
    //   this.setState({ spanTaxPaid: 'none' });
    //   err = 0;
    // }
    if (values.is_bpjs === true) {
      bpjstype =
        typeof values.bpjs_type === 'object'
          ? values.bpjs_type.value
          : values.bpjs_type;
    } else {
      bpjstype = '';
    }
    const data = {
      ...values,
      bpjstype,
    };
    if (err === 0) {
      const { token, apiws, idUser } = this.state;
      const formBody = Object.keys(data)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              mapFormData(data[key]),
            )}`,
        )
        .join('&');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      if (values.pyc_id === '') {
        add = 1;
      }
      if (add === 1) {
        this.setState({
          displaySpinner: '',
          displaySave: 'none',
        });
        const formBodyUser = [formBody, `cuser_id=${idUser}`].join('&');
        axios
          .post(`${apiws}/paycomponent/saveItem`, formBodyUser, config)
          .then((response) => {
            if (response.data.status === 'ok') {
              this.modalAddPayrollComponent();
              this.props.destroy();
              this.getComponent();
              showNotifications('Success', 'Save Success', 'success');
              // trigger to load a new data on group component
              this.props.onDataChanged();
            }
          })
          .catch(() => {
            // console.log(error);
          });
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will update this item !',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#15DD96',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, update it!',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({
              displaySpinner: '',
              displaySave: 'none',
            });
            const formBodyUser = [formBody, `muser_id=${idUser}`].join('&');
            const pycid = values.pyc_id;
            axios
              .post(
                `${apiws}/paycomponent/updateItem/${pycid}`,
                formBodyUser,
                config,
              )
              .then((response) => {
                if (response.data.status === 'ok') {
                  this.modalAddPayrollComponent();
                  this.props.destroy();
                  this.getComponent();
                  showNotifications('Success', 'Update Success', 'success');
                  this.setState({ displayOrder: 'none' });
                  // trigger to load a new data on group component
                  this.props.onDataChanged();
                }
              })
              .catch(() => {
                // console.log(error);
              });
          }
        });
      }
    }
  }

  handleChange(value) {
    if (value.target === undefined) {
      this.setState({ disabledtype: false });
    } else if (value.target.checked === true) {
      this.setState({ disabledtype: true });
    } else if (value.target.checked === false) {
      this.setState({ disabledtype: false });
    }
  }

  handleChangeBackPay(values) {
    if (values.target === undefined) {
      this.setState({ disabledBackpay: false });
    } else if (values.target.checked === true) {
      this.setState({ disabledBackpay: true });
    } else if (values.target.checked === false) {
      this.setState({ disabledBackpay: false });
    }
  }

  updateComponent(pyc, pyt) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    this.setState({ displayOrder: '' });
    axios
      .post(`${apiws}/paycomponent/getAllitemByid/${pyc}`, '', config)
      .then((res) => {
        const comp = res.data.data[0].component;
        const code = res.data.data[0].component_code;
        const ptc = res.data.data[0].ptc_id;
        const type = res.data.data[0].component_type;
        const thr = res.data.data[0].is_thr;
        const loan = res.data.data[0].is_loan;
        const recurring = res.data.data[0].is_recurring;
        // const ptm = res.data.data[0].ptm_id;
        // const paid = res.data.data[0].tax_paid_by;
        const bpjs = res.data.data[0].is_bpjs;
        const overtime = res.data.data[0].is_overtime;
        const unpaid = res.data.data[0].is_unpaid_leave;
        // const bpjs_tk = res.data.data[0].is_bpjs_tk;
        // const bpjs_ks = res.data.data[0].is_bpjs_ks;
        // const adjustment = res.data.data[0].is_adjustment;
        const backpay = res.data.data[0].is_back_pay;
        // const bpjs = res.data.data[0].is_bpjs;
        const order = res.data.data[0].order_number;
        const datarecurring = recurring === '1';
        const datathr = thr === '1';
        const dataloan = loan === '1';
        const dataovertime = overtime === '1';
        const dataunpaid = unpaid === '1';
        // const databpjs_tk = bpjs_tk === '1';
        // const databpjs_ks = bpjs_ks === '1';
        // const dataadjustment = adjustment === '1';
        const databackpay = backpay === '1';
        const databpjs = bpjs === '1';
        const bpjstype = res.data.data[0].pbt_id;
        const pyc_backpay = res.data.data[0].pyc_back_pay;
        if (bpjs === '1') {
          this.handleChange({
            target: {
              checked: true,
            },
          });
        }
        if (backpay === '1') {
          this.handleChangeBackPay({
            target: {
              checked: true,
            },
          });
        }
        this.props.initialize({
          pyc_id: pyc,
          pyt_id: pyt,
          component: comp,
          component_code: code,
          ptc_id: `${ptc}`,
          component_type: `${type}`,
          is_thr: datathr,
          is_loan: dataloan,
          is_recurring: datarecurring,
          // 'ptm_id': `${ptm}`,
          // 'tax_paid_by': paid,
          // 'is_bpjs_tk': databpjs_tk,
          // 'is_bpjs_ks': databpjs_ks,
          // 'is_adjustment': dataadjustment,
          is_back_pay: databackpay,
          is_bpjs: databpjs,
          is_overtime: dataovertime,
          unpaid_leave: dataunpaid,
          order_number: order,
          bpjs_type: bpjstype,
          pyc_back_pay: pyc_backpay,
        });
      });
    this.setState({
      modal: !this.state.modal,
      statusEdit: true,
    });
  }

  deleteComponent(id) {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const uniqueComponent = Array.from(new Set(arrDefaultComponent));
    const deletes = uniqueComponent.includes(id);
    if (deletes) {
      Swal.fire({
        title: 'Item locked',
        text: 'Sorry, This item cannot be delete !',
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will delete this item !',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              `${apiws}/paycomponent/deleteItem/${id}`,
              `duser_id=${idUser}`,
              config,
            )
            .then(() => {
              this.getComponent();
              // trigger to load a new data on group component
              this.props.onDataChanged();
              showNotifications('Success', 'Delete Success', 'success');
            })
            .catch(() => {
              // console.log(error);
              this.setState({ button_disabled: false });
            });
          axios
            .post(
              `${apiws}/paycomponent/getAllEmployeeComponent/${id}`,
              '',
              config,
            )
            .then((response) => {
              if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                  axios.post(
                    `${apiws}/paycomponent/deleteItemEmployeeComponent/${response.data.data[i].pec_id}`,
                    `duser_id=${idUser}`,
                    config,
                  );
                }
              }
            });
        }
      });
    }
  }

  handleChangeCategory(e) {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const array = [];
    const bodyParameters = {
      tax_category: this.state.search_tax,
      comp_category: this.state.search_compcat,
      search: this.state.search_comp,
    };
    axios
      .post(
        `${ls.get('ws_ip')}/paycomponent/getAllItemFilterCategory`,
        bodyParameters,
        config,
      )
      .then((rescomponent) => {
        if (rescomponent.data.status === 'ok') {
          const count_component = rescomponent.data.total;
          const arrAllowance = [];
          const arrDeduction = [];
          const arrOther = [];
          const Arr = [];
          if (count_component === 0) {
            this.setState({ arrComponent: Arr });
          } else {
            rescomponent.data.data.map((obj) =>
              Arr.push({ value: obj.pyc_id, label: obj.component }),
            );
            this.setState({ arrComponent: Arr });
          }
          for (let i = 0; i < count_component; i++) {
            if (rescomponent.data.data[i].is_default === '1') {
              const id_component = rescomponent.data.data[i].pyc_id;
              arrDefaultComponent.push(id_component);
            }
            if (rescomponent.data.data[i].pyt_id === 1) {
              const name_component = rescomponent.data.data[i].component;
              const id_component = rescomponent.data.data[i].pyc_id;
              const pytid = rescomponent.data.data[i].pyt_id;
              arrAllowance.push({
                pyt_id: pytid,
                pyc_id: id_component,
                component: name_component,
              });
            } else if (rescomponent.data.data[i].pyt_id === 2) {
              const name_component = rescomponent.data.data[i].component;
              const id_component = rescomponent.data.data[i].pyc_id;
              const pytid = rescomponent.data.data[i].pyt_id;
              arrDeduction.push({
                pyt_id: pytid,
                pyc_id: id_component,
                component: name_component,
              });
            } else {
              const name_component = rescomponent.data.data[i].component;
              const id_component = rescomponent.data.data[i].pyc_id;
              const pytid = rescomponent.data.data[i].pyt_id;
              arrOther.push({
                pyt_id: pytid,
                pyc_id: id_component,
                component: name_component,
              });
            }
          }
          this.setState({ allowenceData: arrAllowance });
          this.setState({ deductionData: arrDeduction });
          this.setState({ otherData: arrOther });
          // const { data } = res.data;
          // const { header } = res.data;
          // data.map((obj, index) => (
          //   array.push({ 'No': (index + 1), ...obj })
          // ));
          // this.setState({ dataexcel: array, headerexcel: header });
        }
      });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const modalStyle = {
      maxWidth: '750px',
      marginTop: '95px',
      textAlign: 'center',
    };
    const {
      pyt_id,
      pyc_id,
      spanTaxMethod,
      spanTaxPaid,
      allowenceData,
      deductionData,
      otherData,
      arrTaxCategory,
      statusEdit,
      orderNumber,
      bpjstype,
      disabledtype,
      disabledBackpay,
      arrComponent,
      displaySpinner,
      displaySave,
      disabledCancel,
      displayOrder,
    } = this.state;
    let pytForm;
    let labelButton;
    let colorButton;
    if (statusEdit) {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
      pytForm = (
        <Col md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('LBL.COMPONENT')} {t('LBL.TYPE')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="pyt_id"
                component={renderSelectField}
                type="text"
                options={[
                  { value: '1', label: `${t('LBL.ALLOWANCE')}` },
                  { value: '2', label: `${t('LBL.DEDUCTION')}` },
                  { value: '3', label: `${t('LBL.OTHER')}` },
                ]}
              />
            </div>
          </div>
        </Col>
      );
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.ADD');
      pytForm = (
        <Field name="pyt_id" value={pyt_id} component="input" type="hidden" />
      );
    }
    const data_allowence = allowenceData.map((alw) => (
      <ButtonGroup>
        <Button
          color="primary-soft"
          className="btn-component"
          size="sm"
          onClick={() => this.updateComponent(alw.pyc_id, alw.pyt_id)}
        >
          {' '}
          {alw.component}
        </Button>
        <Button
          color="primary-soft"
          className="btn-component-delete icon icon--right"
          size="sm"
          onClick={() => this.deleteComponent(alw.pyc_id)}
        >
          {' '}
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </ButtonGroup>
    ));
    const data_deduction = deductionData.map((ddc) => (
      <ButtonGroup>
        <Button
          color="primary-soft"
          className="btn-component"
          size="sm"
          onClick={() => this.updateComponent(ddc.pyc_id, ddc.pyt_id)}
        >
          {' '}
          {ddc.component}
        </Button>
        <Button
          color="primary-soft"
          className="btn-component-delete icon icon--right"
          size="sm"
          onClick={() => this.deleteComponent(ddc.pyc_id)}
        >
          {' '}
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </ButtonGroup>
    ));
    const data_other = otherData.map((oth) => (
      <ButtonGroup>
        <Button
          color="primary-soft"
          className="btn-component"
          size="sm"
          onClick={() => this.updateComponent(oth.pyc_id, oth.pyt_id)}
        >
          {' '}
          {oth.component}
        </Button>
        <Button
          color="primary-soft"
          className="btn-component-delete icon icon--right"
          size="sm"
          onClick={() => this.deleteComponent(oth.pyc_id)}
        >
          {' '}
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </ButtonGroup>
    ));
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <div className="d-flex align-items-center">
              {/* <div className="w-100">
                <h4>Component</h4>
              </div> */}
              <div className="w-100">
                <ButtonToolbar className="justify-content-end mt-min15">
                  <Button color="primary-clear not-active">
                    <FontAwesomeIcon icon={faUndo} />
                    Clear Filter
                  </Button>
                  <Button
                    color={
                      this.state.filterPanel
                        ? 'primary-filter active'
                        : 'primary-filter '
                    }
                    onClick={() =>
                      this.setState({ filterPanel: !this.state.filterPanel })
                    }
                  >
                    <FontAwesomeIcon icon={faSlidersH} />
                    Filter
                  </Button>
                </ButtonToolbar>
              </div>
            </div>
            <hr />
          </Col>
          <Col md={12} xs={12} lg={12} xl={12} className="filter-comp-panel">
            <div
              className={
                this.state.filterPanel
                  ? 'filter-box active'
                  : 'filter-box not-active'
              }
            >
              <form className="form">
                <Row className="form-inline">
                  <Col md={4} xs={12} lg={4} xl={4}>
                    <div className="form-filter">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Component Name
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="componentName"
                            component="input"
                            type="text"
                            onChange={(val) => {
                              this.setState({ search_comp: val.target.value });
                              if (this.timeout) clearTimeout(this.timeout);
                              this.timeout = setTimeout(() => {
                                this.handleChangeCategory();
                              }, 300);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xs={12} lg={4} xl={4}>
                    <div className="form-filter">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Tax Category
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="tax_cat"
                            component={renderSelectField}
                            options={arrTaxCategory}
                            onChange={(val) => {
                              this.setState({ search_tax: val.value });
                              if (this.timeout) clearTimeout(this.timeout);
                              this.timeout = setTimeout(() => {
                                this.handleChangeCategory();
                              }, 300);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xs={12} lg={4} xl={4}>
                    <div className="form-filter">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Component Category
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="comp_cat"
                            component={renderSelectField}
                            options={[
                              { value: '1', label: 'THR' },
                              { value: '2', label: 'Recurring' },
                              { value: '3', label: 'Backpay' },
                              { value: '4', label: 'BPJS' },
                              { value: '5', label: 'Unpaid Leave' },
                            ]}
                            onChange={(val) => {
                              this.setState({ search_compcat: val.value });
                              if (this.timeout) clearTimeout(this.timeout);
                              this.timeout = setTimeout(() => {
                                this.handleChangeCategory();
                              }, 300);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12} lg={4} xl={4} sm={6}>
            <div className="panel-component">
              <div className="comp-header">
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    {t('LBL.INCOME')}/{t('LBL.ALLOWANCE')}
                  </div>
                  <div className="add-btn-comp">
                    <Button
                      color="primary-soft"
                      size="sm"
                      id="Allowance"
                      onClick={this.clickAllowance}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <div className="comp-body">
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <ButtonToolbar id="CompPayroll">
                          {data_allowence}
                        </ButtonToolbar>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          <Col md={4} xs={12} lg={4} xl={4} sm={6}>
            <div className="panel-component">
              <div className="comp-header">
                <div className="d-flex align-items-center">
                  <div className="w-100">{t('LBL.DEDUCTION')}</div>
                  <div className="add-btn-comp">
                    <Button
                      color="primary-soft"
                      size="sm"
                      id="Deduction"
                      onClick={this.clickDeduction}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <div className="comp-body">
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <ButtonToolbar id="CompPayroll">
                          {data_deduction}
                        </ButtonToolbar>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          <Col md={4} xs={12} lg={4} xl={4} sm={6}>
            <div className="panel-component">
              <div className="comp-header">
                <div className="d-flex align-items-center">
                  <div className="w-100">{t('LBL.OTHER')}</div>
                  <div className="add-btn-comp">
                    <Button
                      color="primary-soft"
                      size="sm"
                      id="Other"
                      onClick={this.clickOther}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <div className="comp-body">
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <ButtonToolbar id="CompPayroll">
                          {data_other}
                        </ButtonToolbar>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalAddPayrollComponent}
          className="modal-dialog modal-input modal-component"
          style={modalStyle}
        >
          <ModalHeader>{t('LBL.PAYROLL_COMPONENT')}</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <form
                    className="form form--horizontal"
                    name="formComponentPayroll"
                    id="formComponentPayroll"
                    onSubmit={handleSubmit(this.handleSubmitComponent)}
                  >
                    <Field
                      name="pyc_id"
                      value={pyc_id}
                      component="input"
                      type="hidden"
                    />
                    {pytForm}
                    <Col xs={8} md={8} lg={8} xl={8}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.NAME')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="component"
                            component={renderTextInput}
                            type="text"
                            maxLength="100"
                            placeholder="Component Name"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4} md={4} lg={4} xl={4}>
                      <div className="form__form-group">
                        <Field
                          name="component_code"
                          component={renderTextInput}
                          type="text"
                          placeholder={t('LBL.CODE')}
                          maxLength="10"
                        />
                      </div>
                    </Col>
                    <Col md={6} style={{ display: displayOrder }}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.ORDER')}
                        </span>
                        <div className="form__form-group-field number-form">
                          <Field
                            name="order_number"
                            component={renderTextInput}
                            type="text"
                            placeholder={`${orderNumber}`}
                            maxLength="2"
                            values={orderNumber}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.TYPE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="component_type"
                            component={renderSelectField}
                            type="text"
                            options={[
                              { value: '1', label: 'Monthly' },
                              { value: '2', label: 'One Time' },
                              { value: '3', label: 'Daily' },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.TAX_CATEGORY')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="ptc_id"
                            component={renderSelectField}
                            type="text"
                            options={arrTaxCategory}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md={12}>
                      <div className="form__form-group">
                        <span
                          className="form__form-group-error"
                          style={
                            {
                              background: '#ffbcbc',
                              borderRadius: '3px',
                              position: 'absolute',
                              padding: '5px 10px 5px 5px',
                              margin: '-15px 0px 0px 140px',
                              display: spanTaxMethod,
                            }
                          }
                        >
                          Tax Method field shouldn’t be empty
                        </span>
                        <span className="form__form-group-label">{t('LBL.TAX_METHOD')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="ptm_id"
                            component={renderRadioButtonField}
                            label="Gross Up"
                            radioValue="1"
                          />
                          <Field
                            name="ptm_id"
                            component={renderRadioButtonField}
                            label="Gross"
                            radioValue="2"
                          />
                          <Field
                            name="ptm_id"
                            component={renderRadioButtonField}
                            label="Netto"
                            radioValue="3"
                          />
                          <Field
                            name="ptm_id"
                            component={renderRadioButtonField}
                            label="Mixed"
                            radioValue="4"
                          />
                        </div>
                      </div>
                    </Col> */}
                    {/* <Col md={12}>
                      <div className="form__form-group">
                        <span
                          className="form__form-group-error"
                          style={
                            {
                              background: '#ffbcbc',
                              borderRadius: '3px',
                              position: 'absolute',
                              padding: '5px 10px 5px 5px',
                              margin: '-15px 0px 0px 140px',
                              display: spanTaxPaid,
                            }
                          }
                        >
                          Tax Paid By field shouldn’t be empty
                        </span>
                        <span className="form__form-group-label">{t('LBL.TAX_PAID_BY')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="tax_paid_by"
                            component={renderRadioButtonField}
                            label="Company"
                            radioValue="1"
                          />
                          <Field
                            name="tax_paid_by"
                            component={renderRadioButtonField}
                            label="Employee"
                            radioValue="2"
                          />
                        </div>
                      </div>
                    </Col> */}
                    <Col md={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.COMPONENT')}
                        </span>
                        <div className="form__form-group-field">
                          <Col md={4} style={{ paddingLeft: '0px' }}>
                            <Field
                              name="is_thr"
                              component={renderCheckBoxField}
                              label={`${t('LBL.THR')}`}
                            />
                          </Col>
                          <Col md={4}>
                            <Field
                              name="is_recurring"
                              component={renderCheckBoxField}
                              label={`${t('LBL.RECURRING')}`}
                            />
                          </Col>
                          <Col md={4}>
                            <Field
                              name="is_loan"
                              component={renderCheckBoxField}
                              label={`${t('LBL.LOAN')}`}
                            />
                          </Col>
                          {/* <Col md={4}>
                            <Field
                              name="is_adjustment"
                              component={renderCheckBoxField}
                              label={`${t('LBL.ADJUSTMENT')}?`}
                            />
                          </Col> */}
                        </div>
                        <div className="form__form-group-field">
                          <Col md={4} style={{ paddingLeft: '0px' }}>
                            <Field
                              name="is_back_pay"
                              component={renderCheckBoxField}
                              label={`${t('LBL.BACKPAY')}`}
                              onChange={this.handleChangeBackPay}
                            />
                          </Col>
                          <Col md={4}>
                            <Field
                              name="is_bpjs"
                              component={renderCheckBoxField}
                              label={`${t('LBL.BPJS')}`}
                              onChange={this.handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <Field
                              name="is_overtime"
                              component={renderCheckBoxField}
                              label={`${t('LBL.OVERTIME')}`}
                            />
                          </Col>
                        </div>
                        <div className="form__form-group-field">
                          <Col md={4} style={{ paddingLeft: '0px' }}>
                            <Field
                              name="unpaid_leave"
                              component={renderCheckBoxField}
                              label="Unpaid Leave"
                            />
                          </Col>
                        </div>
                      </div>
                    </Col>
                    {disabledtype && (
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.BPJS')} {t('LBL.TYPE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="bpjs_type"
                              component={renderSelectField}
                              type="text"
                              options={bpjstype}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                    {disabledBackpay && (
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.BACKPAY')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="pyc_back_pay"
                              component={renderSelectField}
                              type="text"
                              options={arrComponent}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </form>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.modalAddPayrollComponent}
              disabled={disabledCancel}
            >
              {t('FRM.CANCEL')}
            </Button>
            <Button
              style={{ display: displaySave }}
              type="submit"
              color={colorButton}
              form="formComponentPayroll"
              disabled={pristine}
            >
              {' '}
              {labelButton}
            </Button>
            <Button color="primary" style={{ display: displaySpinner }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '6px' }}
              />
              Loading...
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

function mapFormData(b) {
  let val_b;
  if (typeof b === 'boolean') {
    if (b === true) {
      val_b = 1;
    } else {
      val_b = 0;
    }
  } else if (typeof b === 'object') {
    if (b === null) {
      val_b = '';
    } else {
      val_b = b.value;
    }
  } else if (b === 'null') {
    val_b = '';
  } else {
    val_b = b;
  }
  return val_b;
}

const validate = (values) => {
  const errors = {};
  if (values.pyt_id === null) {
    errors.pyt_id = 'Component Type field shouldn’t be empty';
  }
  if (!values.component) {
    errors.component = 'Component field shouldn’t be empty';
  }
  if (!values.component_code) {
    errors.component_code = 'Code field no empty';
  }
  // if (!values.ptc_id) {
  //   errors.ptc_id = 'Component Category field shouldn’t be empty';
  // }
  if (!values.component_type) {
    errors.component_type = 'Type field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'paycomponentforms',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(Component));
