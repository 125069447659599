/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { Card, CardBody, Button, ButtonToolbar, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../notificationMessages';
import renderTextInput from '../../../../../shared/components/form/TextInput';

const positionLenght = [];
class PositionInputForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    getTableData: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    positionslc: PropTypes.func.isRequired,
    editModal: PropTypes.func.isRequired,
    rtbunitorg: PropTypes.array.isRequired,
    rtbjobname: PropTypes.array.isRequired,
    // pos_root: PropTypes.string.isRequired,
    addcond: PropTypes.string.isRequired,
    resetJobName: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      positionslc: [],
      apiws: '',
      token: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
  }
  static getDerivedStateFromProps(props, state) {
    if (props.positionslc !== state.positionslc) {
      return {
        positionslc: props.positionslc,
        positionLenght: props.positionslc,
      };
    }
    if (props.editMode !== state.modal) {
      return {
        modal: props.editMode,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
  }
  componentWillReceiveProps() {

  }
  componentDidUpdate(nextProps) {
    const { positionslc } = this.props;
    this.setState({ positionslc });

    if (nextProps.resetJobName !== this.props.resetJobName) {
      this.props.dispatch(change('positioninput', 'jbn_id', ''));
    }
  }

  toggle() {
    this.props.editModal();
  }

  handleSubmitInput(values) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let url;
    const userid = ls.get('user_cred').usr_id;

    let mappingData = {};
    const jobId = (typeof values.jbn_id === 'object') ? values.jbn_id.value : values.jbn_id;
    const orgId = (typeof values.org_id === 'object') ? values.org_id.value : values.org_id;
    let posIdParent;
    if (values.pos_id_parent === 'null' || values.pos_id_parent === null) {
      posIdParent = 0;
    } else {
      posIdParent = (typeof values.pos_id_parent === 'object') ? values.pos_id_parent.value : values.pos_id_parent;
    }
    mappingData = ({
      jbn_id: jobId,
      org_id: orgId,
      position_nm: values.position_nm,
      pos_id_parent: posIdParent,
    });
    let data;
    const id = values.pos_id;
    if (id === undefined || id === '') {
      url = `${apiws}/orgposition/saveItem`;
      data = { ...mappingData, cuser_id: userid };
    } else {
      url = `${apiws}/orgposition/updateItem/${id}`;
      data = { ...mappingData, muser_id: userid };
    }

    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        // eslint-disable-next-line no-unused-expressions
        this.props.getTableData;
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
      this.props.getTableData();
    }).catch(() => {
      // console.log(error);
    });
  }


  render() {
    const {
      t,
      handleSubmit,
      pristine,
      rtbunitorg,
      rtbjobname,
    } = this.props;
    const {
      positionslc,
      modal,
    } = this.state;

    if (modal) {
      return (
        <Card>
          <Modal
            isOpen={this.state.modal}
            // toggle={this.toggle}
            className="modal-dialog modal-input"
            style={{ marginTop: '100px' }}
          >
            <ModalHeader>
              {t('FRM.EDIT')} {t('LBL.JOB_TITLE')}
            </ModalHeader>
            <ModalBody>
              <form
                name="positioninput"
                id="positioninput"
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmitInput)}
              >
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION_NAME')}</span>
                  {/* {(this.props.pos_root === true) ? (
                    <div className="form__form-group-field">
                      <Field
                        name="org_id"
                        component={renderSelectField}
                        type="text"
                        options={rtbunitorg}
                        placeholder="----"
                        disabled
                      />
                    </div>
                  ) : ( */}
                  <div className="form__form-group-field">
                    <Field
                      name="org_id"
                      component={renderSelectField}
                      type="text"
                      options={rtbunitorg}
                      placeholder="----"
                    />
                  </div>
                  {/* )} */}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.JOB_NAME')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="jbn_id"
                      component={renderSelectField}
                      type="text"
                      options={rtbjobname}
                      placeholder="----"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.PARENT')}</span>
                  {/* <input name="pos_id" type="text" /> */}
                  {/* {(this.props.pos_root === true) ? (
                    <div className="form__form-group-field">
                      <Field
                        name="pos_id_parent"
                        component={renderSelectField}
                        type="text"
                        options={positionslc}
                        placeholder="----"
                        disabled="true"
                      />
                    </div>
                  ) : ( */}
                  <div className="form__form-group-field">
                    <Field
                      name="pos_id_parent"
                      component={renderSelectField}
                      type="text"
                      options={positionslc}
                      placeholder="----"
                    />
                  </div>
                  {/* )} */}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.JOB_TITLE')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="position_nm"
                      component={renderTextInput}
                      type="text"
                      maxLength="80"

                    />
                  </div>
                </div>
                <Field
                  name="pos_id"
                  component={renderTextInput}
                  type="hidden"
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
              <Button
                color="primary"
                type="submit"
                form="positioninput"
                disabled={pristine}
              // onClick={this.toggle}
              > {t('FRM.SAVE')}
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </Card>
      );
    }

    return (
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmitInput)}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION_NAME')}</span>
              <div className="form__form-group-field">
                <Field
                  name="org_id"
                  component={renderSelectField}
                  type="text"
                  options={rtbunitorg}
                  placeholder="----"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.JOB_NAME')}</span>
              <div className="form__form-group-field">
                <Field
                  name="jbn_id"
                  component={renderSelectField}
                  type="text"
                  options={rtbjobname}
                  placeholder="----"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.PARENT')}</span>
              <div className="form__form-group-field">
                <Field
                  name="pos_id_parent"
                  component={renderSelectField}
                  type="text"
                  options={positionslc}
                  placeholder="----"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.JOB_TITLE')}</span>
              <div className="form__form-group-field">
                <Field
                  name="position_nm"
                  component={renderTextInput}
                  type="text"
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
                disabled={pristine}
                style={{ display: this.props.addcond === '1' ? '' : 'none' }}
              >{t('FRM.SAVE')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    );
  }
}
// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// async function submit(values) {
//   // const { token, apiws, id } = this.props;
//   await sleep(500);
//   const config = {
//     headers: {
//       Authorization: `bearer ${ls.get('token')}`,
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//   };
//   let url;
//   const apiws = ls.get('ws_ip');
//   const userid = ls.get('user_cred').usr_id;

//   let mappingData = {};
//   const jobId = (typeof values.jbn_id === 'object') ? values.jbn_id.value : values.jbn_id;
//   const orgId = (typeof values.org_id === 'object') ? values.org_id.value : values.org_id;
//   const posIdParent = (typeof values.pos_id_parent === 'object') ? values.pos_id_parent.value : values.pos_id_parent;
//   mappingData = ({
//     jbn_id: jobId,
//     org_id: orgId,
//     position_nm: values.position_nm,
//     pos_id_parent: posIdParent,
//   });
//   let data;
//   const id = values.pos_id;
//   if (id === undefined || id === '') {
//     url = `${apiws}/orgposition/saveItem`;
//     data = { ...mappingData, cuser_id: userid };
//   } else {
//     url = `${apiws}/orgposition/updateItem/${id}`;
//     data = { ...mappingData, muser_id: userid };
//   }

//   const formBody = Object.keys(data).map(key =>
//     `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
//   axios.post(
//     url,
//     formBody,
//     config,
//   ).then((response) => {
//     if (response.data.status === 'ok') {
//       showNotifications('Success', 'Save Success', 'success');
//       // this.props.refreshTree();
//     } else {
//       showNotifications('Fail', 'Save Failed', 'danger');
//     }
//   }).catch(() => {
//     // console.log(error);
//   });

// }

const validate = (values) => {
  const errors = {};
  const posLen = positionLenght.length;
  if (!values.jbn_id) {
    errors.jbn_id = 'Job Name field shouldn’t be empty';
  }

  if (!values.org_id) {
    errors.org_id = 'Organization Name field shouldn’t be empty';
  }

  if (posLen !== 0) {
    if (!values.pos_id_parent) {
      errors.pos_id_parent = 'Main Job Title field shouldn’t be empty';
    }
  }

  // eslint-disable-next-line max-len
  if (typeof values.pos_id_parent === 'object' && values.pos_id_parent !== null && values.pos_id_parent.value !== null) {
    if (values.pos_id === values.pos_id_parent.value) {
      errors.pos_id_parent = 'Main Job Title field shouldn’t be filled by current Job Title';
    }
  } else if (values.pos_id === values.pos_id_parent) {
    errors.pos_id_parent = 'Main Job Title field shouldn’t be filled by current Job Title';
  }

  if (!values.position_nm) {
    errors.position_nm = 'Job Title Name field shouldn’t be empty';
  }

  return errors;
};


export default reduxForm({
  form: 'positioninput', // a unique identifier for this form
  // onSubmit: submit,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(PositionInputForm));
