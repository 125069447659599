import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const gb = '/img/language/gb.png';
const id = '/img/language/id.png';
const ch = '/img/language/ch.png';
// const de = `${process.env.PUBLIC_URL}/img/language/de.png`;

const GbLng = () => (
  <span className="topbar__language-btn-title">
    <img src={gb} alt="gb" />
    <span>EN</span>
  </span>
);

const IdLng = () => (
  <span className="topbar__language-btn-title">
    <img src={id} alt="id" />
    <span>ID</span>
  </span>
);

const ChLng = () => (
  <span className="topbar__language-btn-title">
    <img src={ch} alt="ch" />
    <span>CHN</span>
  </span>
);
// const DeLng = () => (
//   <span className="topbar__language-btn-title">
//     <img src={de} alt="de" />
//     <span>DE</span>
//   </span>
// );

class TopbarLanguage extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      mainButtonContent: <GbLng />,
    };

    this.setLanguage = this.setLanguage.bind(this);
  }

  componentWillMount() {
    const lng = cookies.get('language');
    this.props.i18n.changeLanguage(lng);
    this.setLanguage(lng);
  }

  setLanguage(lng) {
    switch (lng) {
      case 'gbr':
        this.setState({ mainButtonContent: <GbLng /> });
        break;
      case 'idn':
        this.setState({ mainButtonContent: <IdLng /> });
        break;
      case 'chn':
        this.setState({ mainButtonContent: <ChLng /> });
        break;
      // case 'de':
      //   this.setState({ mainButtonContent: <DeLng /> });
      //   break;
      default:
        this.setState({ mainButtonContent: <GbLng /> });
        break;
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  changeLanguage = (lng) => {
    console.log(lng);
    this.props.i18n.changeLanguage(lng);
    cookies.set('language', lng);
    this.setLanguage(lng);
  };

  render() {
    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" onClick={this.toggle}>
          {this.state.mainButtonContent}
          <DownIcon className="topbar__icon" />
        </button>
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('gbr')}
          >
            <GbLng />
          </button>
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('idn')}
          >
            <IdLng />
          </button>

          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('chn')}
          >
            <ChLng />
          </button>
          {/* <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('de')}
          >
            <DeLng />
          </button> */}
        </Collapse>
      </div>
    );
  }
}

export default translate('common')(TopbarLanguage);
