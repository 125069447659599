/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase, array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import Spinner from 'react-bootstrap/Spinner';

let arrNumber = [];
class PhasePreferences extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    addFunc: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      arrWorkflow: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '30%', '30%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      pptvalue: '',
      editcond: '',
      addcond: '',
      deletecond: '',
      modal: false,
      displaySpinner: false,
      disabeldCancel: false,
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PHASE') },
      { '2': t('LBL.WORKFLOW') },
      { '3': t('LBL.ORDER_NUMBER') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.editPhase = this.editPhase.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deletePhase = this.deletePhase.bind(this);
    this.getWorkflow = this.getWorkflow.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PHASE') },
      { '2': t('LBL.WORKFLOW') },
      { '3': t('LBL.ORDER_NUMBER') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.getPhase();
    this.getTableData();
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.props.destroy();
    this.setState({ pptvalue: '' });
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
    this.getPhase();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getWorkflow();
      this.getButton();
      this.getPhase();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/Pfmphase/getAllitem/` });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  handleSubmit(values) {
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.pph_id;
    const phase_id =
      !(typeof values.phase_name === 'object') || values.phase_name === null
        ? values.phase_name
        : values.phase_name.label;
    const workflow_id =
      typeof values.wfl_id === 'object' || !(values.wfl_id !== null)
        ? values.wfl_id.value
        : values.wfl_id;
    const userid = ls.get('user_cred').usr_id;
    let url;
    let phase_id1;
    let data;
    if (phase_id === '1' || phase_id === 'Plan') {
      phase_id1 = 'Plan';
    } else if (phase_id === '2' || phase_id === 'Tracking') {
      phase_id1 = 'Tracking';
    } else if (phase_id === '3' || phase_id === 'Tracking 1') {
      phase_id1 = 'Tracking 1';
    } else if (phase_id === '4' || phase_id === 'Tracking 2') {
      phase_id1 = 'Tracking 2';
    } else if (phase_id === '5' || phase_id === 'Tracking 3') {
      phase_id1 = 'Tracking 3';
    } else if (phase_id === '6' || phase_id === 'Tracking 4') {
      phase_id1 = 'Tracking 4';
    } else if (phase_id === '7' || phase_id === 'Tracking 5') {
      phase_id1 = 'Tracking 5';
    } else if (phase_id === '8' || phase_id === 'Evaluation') {
      phase_id1 = 'Evaluation';
    }
    // console.log('phase_idBE', phase_id1);
    if (id === undefined || id === '') {
      url = `${apiws}/pfmphase/saveItem`;
      data = {
        ...values,
        phase_name: phase_id,
        wfl_id: workflow_id,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/pfmphase/updateItem/${id}`;
      data = {
        ...values,
        phase_name: phase_id1,
        wfl_id: workflow_id,
        muser_id: userid,
      };
    }
    this.setState({ displaySpinner: true, disabeldCancel: true });
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(url, formBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.setState({ modal: false });
        this.setState({ displaySpinner: false, disabeldCancel: false });
        setTimeout(() => {
          this.child.reload();
        }, 300);
      } else {
        if (response.data.status1 === 'failed') {
          this.setState({ displaySpinner: false, disabeldCancel: false });
          showNotifications(
            'Order Number is Already Taken',
            'Save Failed',
            'danger',
          );
        } else {
          this.setState({ displaySpinner: false, disabeldCancel: false });
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }
    });
  }

  editPhase(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmphase/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const getStatus = response.data.status1;
          // const getIdpph = response.data.idphase;
          const idxNumber = arrNumber.indexOf(getResponseData[0].pph_order);
          if (idxNumber !== -1) {
            arrNumber.splice(idxNumber, 1);
          }
          if (getResponseData[0].phase_name === 'Plan') {
            getResponseData[0].phase_name = '1';
          } else if (getResponseData[0].phase_name === 'Tracking') {
            getResponseData[0].phase_name = '2';
          } else if (getResponseData[0].phase_name === 'Tracking 1') {
            getResponseData[0].phase_name = '3';
          } else if (getResponseData[0].phase_name === 'Tracking 2') {
            getResponseData[0].phase_name = '4';
          } else if (getResponseData[0].phase_name === 'Tracking 3') {
            getResponseData[0].phase_name = '5';
          } else if (getResponseData[0].phase_name === 'Tracking 4') {
            getResponseData[0].phase_name = '6';
          } else if (getResponseData[0].phase_name === 'Tracking 5') {
            getResponseData[0].phase_name = '7';
          } else if (getResponseData[0].phase_name === 'Evaluation') {
            getResponseData[0].phase_name = '8';
          }
          // indra
          console.log('valphase_name', getResponseData[0].phase_name);
          const datamaping = {
            pph_id: getResponseData[0].pph_id,
            phase_name: getResponseData[0].phase_name,
            wfl_id: getResponseData[0].wfl_id,
            order_phase: getResponseData[0].pph_order,
            status: getStatus,
          };
          console.log('data maping', datamaping);
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.initialize(datamaping);
          });
        }
      });
  }

  getWorkflow() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/workflow/getAll`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        const arr = [];
        for (let i = 0; i < response.data.data.length; i += 1) {
          const workflow = response.data.data[i].workflow.toLowerCase();
          if (
            workflow.indexOf('kpi') > -1 ||
            workflow.indexOf('performance') > -1
          ) {
            arr.push({
              value: response.data.data[i].wfl_id,
              label: response.data.data[i].workflow,
            });
          }
        }
        this.setState({ arrWorkflow: arr });
      }
    });
  }

  getPhase() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/Pfmphase/getAllitem/`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        arrNumber = [];
        for (let i = 0; i < response.data.data.length; i += 1) {
          arrNumber[i] = response.data.data[i].pph_order;
        }
      }
    });
  }

  deletePhase(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/pfmphase/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else if (response.data.status === 'used') {
              showNotifications(
                'Warning',
                'cant delete phase, phase used at some period',
                'warning',
              );
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine, addFunc } = this.props;
    const { displaySpinner, disabeldCancel, urlData, arrWorkflow } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.PHASE')}
      </Button>
    );
    const modalStyle = {
      marginTop: '100px',
      maxWidth: '800px',
      textAlign: 'center',
    };
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pph_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editPhase}
                  deleteFunc={this.deletePhase}
                  addFunc={addFunc}
                  buttonAction={['edit', 'delete']}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {t('LBL.PHASE')} {t('LBL.FORM')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="phaseformsubmit"
                  id="phaseformsubmit"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <input type="hidden" name="pph_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PHASE')}
                          </span>
                          <div className="form__form-group-field">
                            <Col xs={12} md={12} lg={12} xl={12}>
                              <Field
                                name="phase_name"
                                component={renderSelectField}
                                options={[
                                  { value: 1, label: 'Plan' },
                                  { value: 2, label: 'Tracking' },
                                  { value: 3, label: 'Tracking 1' },
                                  { value: 4, label: 'Tracking 2' },
                                  { value: 5, label: 'Tracking 3' },
                                  { value: 6, label: 'Tracking 4' },
                                  { value: 7, label: 'Tracking 5' },
                                  { value: 8, label: 'Evaluation' },
                                ]}
                              />
                            </Col>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.WORKFLOW')}
                          </span>
                          <div className="form__form-group-field">
                            <Col xs={12} md={12} lg={12} xl={12}>
                              <Field
                                name="wfl_id"
                                component={renderSelectField}
                                options={arrWorkflow}
                              />
                            </Col>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ORDER_NUMBER')}
                          </span>
                          <div className="form__form-group-field number-form">
                            <Col xs={2} md={2} lg={2} xl={2}>
                              <input type="hidden" value="" name="status" />
                              <Field
                                name="order_phase"
                                component={renderTextInput}
                              />
                            </Col>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  disabled={disabeldCancel}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {displaySpinner ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    form="phaseformsubmit"
                    type="submit"
                    disabled={pristine}
                  >
                    {t('FRM.SAVE')}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorNumbers = arrNumber;
  if (!values.phase_name) {
    errors.phase_name = 'Phase name field shouldn’t be empty';
  }
  if (values.phase_name === null) {
    errors.phase_name = 'Phase name field shouldn’t be empty';
  }
  if (!values.wfl_id || values.wfl_id === null) {
    errors.wfl_id = 'Workflow field shouldn’t be empty';
  }
  if (!values.order_phase) {
    errors.order_phase = 'no empty';
  }
  if (values.status !== 'edit') {
    if (errorNumbers.includes(parseInt(values.order_phase, 0))) {
      errors.order_phase = 'aleardy taken';
    }
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'phaseformpreferences',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(PhasePreferences));
