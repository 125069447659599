/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import MinusIcon from 'mdi-react/MinusIcon';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import renderSelectField from '../../../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../../../shared/components/form/IntervalDatePicker';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import showNotifications from '../../../../../containers/Preferences/notificationMessages';
import ls from 'local-storage';
import axios from 'axios';

class addPayrollComponent extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    periodId: PropTypes.func.isRequired,
    start: PropTypes.func.isRequired,
    end: PropTypes.func.isRequired,
    status: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      arrEmp: [],
      arrOffice: [],
      arrOrganization: [],
      modal: false,
      disabledOffice: false,
      disabledEmp: false,
      disabledOrg: false,
      filter: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getOffice = this.getOffice.bind(this);
    this.getOrganization = this.getOrganization.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.periodId !== state.pypid) {
      return {
        pypid: props.periodId,
      };
    }
    if (props.isOpen !== state.modal) {
      return {
        modal: props.isOpen,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getEmployee(); this.getOffice(); this.getOrganization();
    });
  }
  getTableData() {
    this.child.reload();
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/employee/getAll/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.emp_id, label: obj.emp_name })
        ));
        this.setState({ arrEmp: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getOffice() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/orgoffice/getAllItem/`,
      '',
      config,
    ).then((response) => {
      const office = response.data.data;
      const array = [];
      if (office.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        office.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getOrganization() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      const organization = response.data.data;
      const array = [];
      if (organization.length === 0) {
        this.setState({ arrOrganization: array });
      } else {
        organization.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrOrganization: array });
        console.log(this.state.arrOrganization);
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  handleChange(value) {
    switch (value) {
      case '1':
        this.setState({ disabledOffice: false });
        this.setState({ disabledOrg: true });
        this.setState({ disabledEmp: true });
        this.setState({ filter: value });
        break;
      case '2':
        this.setState({ disabledOffice: true });
        this.setState({ disabledOrg: false });
        this.setState({ disabledEmp: true });
        this.setState({ filter: value });
        break;
      case '3':
        this.setState({ disabledOffice: true });
        this.setState({ disabledOrg: true });
        this.setState({ disabledEmp: false });
        this.setState({ filter: value });
        break;
      default:
    }
  }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const error = true;
    const id = values.atm_id;
    const pyp = this.props.periodId;
    const st = this.props.status;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/attprocess/saveItem`;
      data = {
        ...values,
        pypid: pyp,
        apr_status: st,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/attprocess/updateItem/${id}`;
      data = { ...values, muser_id: userid };
    }
    data = {
      data: JSON.stringify(data),
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrEmp, arrOffice, arrOrganization, disabledEmp, disabledOffice, disabledOrg, filter,
    } = this.state;
    let filter_action;
    if (filter === '1') {
      filter_action = (
        <div className="form__form-group">
          <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
          <div className="form__form-group-field">
            <Field
              name="officeAutoComplete"
              component={renderSelectField}
              options={arrOffice}
              type="text"
              placeholder="Search Office"
              disabled={disabledOffice}
              clearable="true"
            />
            <div className="form__form-group-icon">
              <AccountSearchIcon />
            </div>
          </div>
        </div>);
    } else if (filter === '2') {
      filter_action = (
        <div className="form__form-group">
          <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
          <div className="form__form-group-field">
            <Field
              name="organizationAutoComplete"
              component={renderSelectField}
              options={arrOrganization}
              type="text"
              placeholder="Search Organization"
              disabled={disabledOrg}
            />
            <div className="form__form-group-icon">
              <AccountSearchIcon />
            </div>
          </div>
        </div>);
    } else if (filter === '3') {
      filter_action = (
        <div className="form__form-group">
          <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
          <div className="form__form-group-field">
            <Field
              name="employeeAutoComplete"
              component={renderSelectField}
              options={arrEmp}
              type="text"
              placeholder="Search Employee"
              disabled={disabledEmp}
            />
            <div className="form__form-group-icon">
              <AccountSearchIcon />
            </div>
          </div>
        </div>);
    }
    return (
      <Modal isOpen={this.state.modal} className="modal-dialog modal-input modal-component">
        <ModalHeader>{t('MEN.ATTENDANCE_PROCESS')} - {t('LBL.ADVANCE')}</ModalHeader>
        <ModalBody>
          <form
            className="form form--horizontal"
            onSubmit={handleSubmit(this.handleSubmit)}
            name="addadvanceattprocess"
            id="addadvanceattprocess"
          >
            <Container>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <form className="form form--horizontal">
                        <div className="form__form-group form__form-group--address">
                          <span className="form__form-group-label">{t('LBL.DATE')}  </span>
                          <div className="form__form-group-field range-select-form">
                            <Field
                              name="start_date"
                              component={renderTextInput}
                              type="text"
                              placeholder={this.props.start}
                              disabled="true"
                            />
                            <MinusIcon className="date-picker__svg" />
                            <Field
                              name="end_date"
                              component={renderTextInput}
                              type="text"
                              placeholder={this.props.end}
                              disabled="true"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.FILTER_BY')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="apr_filter"
                              component={renderSelectField}
                              type="text"
                              options={[
                                { value: '1', label: t('LBL.OFFICE') },
                                { value: '2', label: t('LBL.UNIT_ORGANIZATION') },
                                { value: '3', label: t('LBL.EMPLOYEE') },
                              ]}
                              onChange={(val) => { this.handleChange(val.value); }}
                            />
                          </div>
                        </div>
                        {filter_action}
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form="addadvanceattprocess">{t('LBL.PROCESS')}</Button>
          <Button color="secondary" onClick={() => this.props.toggleModal()}>{t('FRM.CANCEL')}</Button>
        </ModalFooter>
      </Modal >
    );
  }
}

export default reduxForm({
  form: 'advance_apr_form', // a unique identifier for this form
})(translate('global')(addPayrollComponent));
