/* eslint-disable function-paren-newline, max-len */

import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import config from '../../../../shared/components/config/config';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

class ResetPasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      ipws: config.ws.ip,
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      spanError: 'none',
      textError: '',
      resetForm: true,
      title: 'Reset Password',
      message: '',
      backToLogin: false,
      subdomain: config.subdomain,
    };

    this.showPassword = this.showPassword.bind(this);
    this.showConfirmPassword = this.showConfirmPassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken() {
    fetch(`${this.state.ipws}/user/getUserByToken`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `token=${this.props.token}`,
    }).then(res => res.json()).then(
      (result) => {
        if (result.status === 'ok') {
          this.setState({
            resetForm: true,
          });
        } else {
          this.setState({
            resetForm: false,
            title: 'Sorry, your token expired!',
            message: 'Please Request a new password reset link.',
          });
        }
      },
    );
  }

  showPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  showConfirmPassword() {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }

  handlePassword(event) {
    this.setState({
      password: event.target.value,
      spanError: 'none',
    });
  }

  handleConfirmPassword(event) {
    this.setState({
      confirmPassword: event.target.value,
      spanError: 'none',
    });
  }

  handleSubmit(event) {
    const { token } = this.props;
    const { password, confirmPassword, subdomain } = this.state;
    if (password !== confirmPassword) {
      this.setState({
        textError: 'Your new password and confirmation password do not match',
        spanError: '',
      });
    } else if (!password) {
      this.setState({
        textError: 'Password field shouldn’t be empty',
        spanError: '',
      });
    } else if (!confirmPassword) {
      this.setState({
        textError: 'Confirm Password field shouldn’t be empty',
        spanError: '',
      });
    } else {
      fetch(`${this.state.ipws}/login/resetPassword`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `token=${token}&password=${password}&confirm_password=${confirmPassword}&subdomain=${subdomain}`,
      }).then(res => res.json()).then(
        (result) => {
          if (result.status === 'ok') {
            this.setState({
              title: 'Password Changed!',
              message: 'You can now sign in with your new password.',
              spanError: 'none',
              resetForm: false,
              backToLogin: true,
            });
          } else {
            this.setState({
              textError: result.message,
              spanError: '',
            });
          }
        },
      );
    }
    event.preventDefault();
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      spanError,
      textError,
      resetForm,
      title,
      message,
      backToLogin,
    } = this.state;

    let viewForm;
    if (resetForm) {
      viewForm = (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group" style={{ height: '30px' }}>
            <span
              className="form__form-group-error"
              style={
                {
                  background: '#ffbcbc',
                  borderRadius: '3px',
                  position: 'absolute',
                  padding: '5px 10px 5px 10px',
                  margin: '-25px 0px 0px 0px',
                  width: '100%',
                  display: spanError,
                  fontSize: '14px',
                  fontStyle: 'italic',
                }
              }
            >
              {textError}
            </span>
          </div>
          <div className="form__form-group form__form-group--forgot">
            <div className="form__form-group-field">
              <Field
                name="password"
                component="input"
                onChange={this.handlePassword}
                value={this.state.password}
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="New Password"
                style={{
                  borderRadius: '3px',
                  height: '45px',
                  fontSize: '16px',
                }}
              />
              <button
                className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                onClick={e => this.showPassword(e)}
                type="button"
                style={{ height: '45px' }}
              ><EyeIcon />
              </button>
            </div>
          </div>
          <div className="form__form-group form__form-group--forgot">
            <div className="form__form-group-field">
              <Field
                name="confirm_password"
                component="input"
                onChange={this.handleConfirmPassword}
                value={this.state.confirmPassword}
                type={this.state.showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                style={{
                  borderRadius: '3px',
                  height: '45px',
                  fontSize: '16px',
                }}
              />
              <button
                className={`form__form-group-button${this.state.showConfirmPassword ? ' active' : ''}`}
                onClick={e => this.showConfirmPassword(e)}
                type="button"
                style={{ height: '45px' }}
              ><EyeIcon />
              </button>
            </div>
          </div>
          <div className="account__btns">
            <button
              onClick={this.handleSubmit}
              className="btn btn-primary account__btn"
              color="primary"
              type="submit"
              style={{ fontSize: '16px' }}
            >
              Reset Password
            </button>
          </div>
        </form>
      );
    }
    let btnBack;
    if (backToLogin) {
      btnBack = (
        <Link style={{ margin: '0px' }} className="btn btn-default account__btn" to="/">Sign in</Link>
      );
    }
    return (
      <>
        <div className="account__profile">
          <div
            className="account__logo"
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              marginBottom: '50px',
            }}
          />
          <p
            className="account__name"
            style={{
              textTransform: 'capitalize',
              paddingBottom: '30px',
              fontSize: '32px',
            }}
          >{title}
          </p>
          <p className="account__sub" style={{ fontSize: '14px' }}>{message}</p>
        </div>
        {viewForm}
        {btnBack}
      </>
    );
  }
}

export default reduxForm({
  form: 'reset_passsword_form', // a unique identifier for this form
})(translate('global')(ResetPasswordForm));
