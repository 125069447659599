/* eslint-disable camelcase, react/jsx-curly-brace-presence */
/* eslint-disable no-prototype-builtins, react/no-unused-state */
/* eslint-disable max-len, no-unused-vars */
/* eslint-disable no-return-assign, no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/fontawesome-free-solid';
// import MinusIcon from 'mdi-react/MinusIcon';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { jsonToCSV } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import showNotifications from '../../../Preferences/notificationMessages';
import Spinner from 'react-bootstrap/Spinner';
// import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';

class ReportPayrollBankTransfer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.BANK_ACCOUNT'),
      t('LBL.BANK'),
      t('LBL.OFFICE'),
      t('LBL.TAKE_HOME_PAY'),
    ];
    this.state = {
      apiws: '',
      token: '',
      urlData: '',
      arrYear: [],
      arrEmployee: [],
      arrEmpStatus: [],
      // arrBank: [],
      arrOffice: [],
      arrUnitOrganization: [],
      downloadCSV: false,
      downloadTXT: false,
      displayLoad: 'none',
      displaySave: '',
      displayTable: '',
      arrFormatReport: '',
      period: '',
      remark: '',
      month: '',
      encoding: false,
      typeReport: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
  }

  componentDidMount = () => {
    this.getYear();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.loadSelectBox();
    });
    this.getTableData();
    this.getYear();
    this.setState({ urlData: `${ls.get('ws_ip')}/reportpayroll/getbanktransfer` });
  };

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getTableData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport(format) {
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const { typeReport } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const url = `${ls.get('ws_ip')}/reportpayroll/getBankTransfer/${format}`;
    const conds = {
      pyp_id: this.state.condPypId,
      org_id: this.state.condOrganization,
      emp_id: this.state.condEmployee,
      rtk_id: this.state.condBank,
      // transaction_remark: this.state.condRemark,
      off_id: this.state.condOffice,
      ems_id: this.state.condEmployeeStatus,
      period: this.state.period,
      remark: this.state.remark,
      month: this.state.month,
      type: '2',
    };
    const bodyParameters = {
      cond: conds,
    };
    axios.post(
      url,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok' && res.data.total > 0) {
        const { data } = res.data;
        let array = [];
        const obj = res.data.custom_header;
        if (obj.constructor.name === 'Object') {
          if (this.state.condBank === 1) {
            array.push({
              'Bank Account': obj.bank_account,
              'Amount Transfer': obj.total,
              'Employee Name': obj.emp_name,
            });
          }
          if (this.state.condBank === 14) {
            array.push({
              'Bank Account': obj.bank_account,
              'Employee Name': obj.office,
              Currency: obj.currency,
              THP: obj.total,
              Office: obj.payroll,
              'Total Employee': obj.total_employee,
              'Pay Generate': obj.pay_generate,
              '': '',
            });
          }
          if (this.state.condBank === 4) {
            array.push({
              'To Acc No.': obj.bank_account,
              'To Acc Name': obj.emp_name,
              'To Acc Address 1': obj.city1,
              'To Acc Address 2': obj.city2,
              'To Acc Address 3': obj.city3,
              'Transfer Currency': obj.currency,
              'Transfer Amount': obj.total_hp,
              'Transaction Remark': obj.test_01,
              'Customer Ref No.': obj.space1,
              'FT Service': obj.employeement_status,
              'To Acc Bank Code': obj.space2,
              'To Acc Bank Name': obj.bank_name,
              'To Acc Bank Address 1.': obj.city4,
              'To Acc Bank Address 2.': obj.space3,
              'To Acc Bank Address 3.': obj.space4,
              'Bank City Name / Country Name': obj.space5,
              'Beneficiary Notification Flag': obj.N,
              'Benef Notification E-mail': obj.email,
              'Organization Directory Name': obj.space6,
              'Identical Status': obj.space7,
              'Beneficiary Status': obj.space8,
              'Beneficiary Citizenship': obj.Y,
              'Purpose of Transaction': obj.space9,
              'Transaction Description': obj.space10,
              'Remittance Code 1': obj.space11,
              'Remittance Information 1': obj.space12,
              'Remittance Code 2': obj.space13,
              'Remittance Information 2': obj.space14,
              'Remittance Code 3': obj.space15,
              'Remittance Information 3': obj.space16,
              'Remittance Code 4': obj.space17,
              'Remittance Information 4': obj.space18,
              'Instruction Code 1': obj.space19,
              'Instruction Remark 1': obj.space20,
              'Instruction Code 2': obj.space21,
              'Instruction Remark 2': obj.space22,
              'Instruction Code 3': obj.space23,
              'Instruction Remark 3': obj.space24,
              'Charge Instruction': obj.space25,
              'SWIFT Method / Beneficiary Type': obj.tax_status,
              'Extended Payment Detail': obj.space26,
              'Corespondent Bank': obj.space27,
              'Treasury Code': obj.space28,
              '': '',
            });
          }
          array.push(...data);
        } else {
          array = data;
        }
        let conf_export;
        const conf = {
          quotes: false,
          quoteChar: '',
          escapeChar: '',
          header: false,
          dynamicTyping: true,
          skipEmptyLines: false,
          columns: null,
        };
        if (typeReport === '2') {
          conf_export = { ...conf, delimiter: ',' };
        } else if (typeReport === '3') {
          conf_export = { ...conf, delimiter: '' };
        }
        const results = jsonToCSV(array, conf_export);
        console.log('results', results);
        setTimeout(() => {
          if (this.state.condBank === 4) {
            this.setState({ csvData: results });
          } else {
            this.setState({ csvData: results });
          }
          if (typeReport === '2') {
            this.setState({ downloadCSV: true });
            this.inputElementCsv.click();
          } else if (typeReport === '3') {
            this.setState({ downloadTXT: true });
            this.inputElementTxt.click();
          }
          showNotifications('Success', 'Load Success', 'success');
        }, 500);
      } else {
        setTimeout(() => {
          showNotifications('Info', 'Data not found', 'info');
        }, 500);
      }
    });
  }


  loadSelectBox() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    // axios.get(
    //   `${apiws}/rtbbank/getAll`,
    //   config,
    // ).then((res) => {
    //   const rtk = res.data.data;
    //   const array = [];
    //   if (rtk.length === 0) {
    //     this.setState({ arrBank: array });
    //   } else {
    //     rtk.map(obj => (
    //       array.push({ value: obj.rtk_id, label: obj.bank })
    //     ));
    //     this.setState({ arrBank: array });
    //   }
    // });

    axios.get(
      `${apiws}/payreport/getAllItem`,
      config,
    ).then((res) => {
      const format = res.data.data;
      const array = [];
      if (format.length === 0) {
        this.setState({ arrFormatReport: array });
      } else {
        format.map(obj => (
          array.push({ value: obj.prp_id, label: obj.format_name })
        ));
        this.setState({ arrFormatReport: array });
      }
    });

    axios.post(
      `${apiws}/employee/getAllItemOrg/0`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.get(
      `${apiws}/rtbemployeementstatus/getAll`,
      config,
    ).then((res) => {
      const emp = res.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmpStatus: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.ems_id, label: obj.ems_employeement_status })
        ));
        this.setState({ arrEmpStatus: array });
      }
    });

    // axios.get(
    //   `${apiws}/rtbbank/getAll`,
    //   config,
    // ).then((res) => {
    //   const rtk = res.data.data;
    //   const array = [];
    //   if (rtk.length === 0) {
    //     this.setState({ arrBank: array });
    //   } else {
    //     rtk.map(obj => (
    //       array.push({ value: obj.rtk_id, label: obj.bank })
    //     ));
    //     this.setState({ arrBank: array });
    //   }
    // });

    axios.get(
      `${apiws}/orgoffice`,
      config,
    ).then((res) => {
      const off = res.data.data;
      const array = [];
      if (off.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        off.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    });

    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }


  handleSubmit(values) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    this.setState({ displayTable: 'none', displayLoad: '', displaySave: 'none' });
    const month = (typeof values.period_month === 'undefined') ? '' : values.period_month.value;
    const year = (typeof values.period_year === 'undefined') ? '' : values.period_year.value;
    const valperiod = (year === '' || month === '') ? false : true;
    if (valperiod) {
      const period = `${year}${month}`;
      const orgId = (typeof values.unit_organization === 'undefined' || values.unit_organization === null) ? '' : values.unit_organization.value;
      const offId = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
      const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
      const emsId = (typeof values.employeement_status === 'undefined' || values.employeement_status === null) ? '' : values.employeement_status.value;
      const rtkId = (typeof values.bank === 'undefined' || values.bank === null) ? '' : values.bank.value;
      const { remark } = values;
      const format = values.format_report.value;
      axios.get(
        `${apiws}/payperiod/getId/${period}`,
        config,
      ).then((res) => {
        if (res.data.status === 'ok' && res.data.data.length > 0) {
          const pypId = res.data.data[0].pyp_id;
          setTimeout(() => {
            this.setState({ condPypId: pypId });
            this.setState({ condOrganization: orgId });
            this.setState({ condOffice: offId });
            this.setState({ condEmployee: empId });
            this.setState({ condEmployeeStatus: emsId });
            this.setState({ condBank: rtkId });
            this.setState({ remark });
            this.setState({ period });
            this.setState({ month: values.period_month.label });
            this.setState({ typeReport: values.type_report.value });
            console.log('remark', remark);
          }, 100);
          if (values.type_report.value === '2' || values.type_report.value === '3') {
            setTimeout(() => {
              this.getReport(format);
            }, 1000);
          } else if (values.type_report.value === '1') {
            this.setState({ urlData: `${ls.get('ws_ip')}/reportpayroll/getBankTransfer/${format}` });
            setTimeout(() => {
              this.getTableData();
              showNotifications('Success', 'Load Success', 'success');
            }, 200);
          }
        } else {
          this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
          setTimeout(() => {
            showNotifications('Info', 'Data not found', 'info');
          }, 500);
        }
      });
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrYear,
      arrEmpStatus,
      arrEmployee,
      // arrBank,
      arrOffice,
      arrUnitOrganization,
      urlData,
      displayTable,
      displayLoad,
      displaySave,
      condPypId,
      condEmployee,
      condEmployeeStatus,
      condBank,
      remark,
      condOrganization,
      condOffice,
      csvData,
      arrFormatReport,
      month,
      period,
      encoding,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card style={{ paddingLeft: '15px' }}>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.PAYROLL')} - {t('MEN.REPORT')} {t('LBL.BANK_TRANSFER')}</h3>
                    <h3 className="page-subhead subhead">{t('LBL.VIEW_BANK_TRANSFER')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form className="form form--horizontal mt15" onSubmit={handleSubmit(this.handleSubmit)}>
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_month"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '01', label: `${t('LBL.JANUARY')}` },
                                    { value: '02', label: `${t('LBL.FEBRUARY')}` },
                                    { value: '03', label: `${t('LBL.MARCH')}` },
                                    { value: '04', label: `${t('LBL.APRIL')}` },
                                    { value: '05', label: `${t('LBL.MAY')}` },
                                    { value: '06', label: `${t('LBL.JUNE')}` },
                                    { value: '07', label: `${t('LBL.JULI')}` },
                                    { value: '08', label: `${t('LBL.AUGUST')}` },
                                    { value: '09', label: `${t('LBL.SEPTEMBER')}` },
                                    { value: '10', label: `${t('LBL.OCTOBER')}` },
                                    { value: '11', label: `${t('LBL.NOVEMBER')}` },
                                    { value: '12', label: `${t('LBL.DESEMBER')}` },
                                  ]}
                                />
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                />
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                  // placeholder="Select Year"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="format_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrFormatReport}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmployee}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="employeement_status"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmpStatus}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="office"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrOffice}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="unit_organization"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrUnitOrganization}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.BANK')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="bank"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: 1, label: 'BCA' },
                                    { value: 14, label: 'CIMB Niaga' },
                                    { value: 4, label: 'Mandiri' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.TRANSACTION_REMARK')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="remark"
                                  component={renderTextInput}
                                  type="textarea"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="type_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'View' },
                                    { value: '2', label: 'CSV' },
                                    { value: '3', label: 'TXT' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <ButtonToolbar className="form__button-toolbar">
                              <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                              <Button color="primary" style={{ display: displayLoad }}>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: '6px' }}
                                />
                                {t('FRM.LOADING')}
                              </Button>
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      </Container>
                      {(this.state.downloadCSV === true) && (
                        <CSVLink
                          filename="Upload Loader Bank.csv"
                          target="_blank"
                          data={csvData}
                          uFEFF={encoding}
                        ><input type="hidden" ref={input => this.inputElementCsv = input} />
                        </CSVLink>
                      )}
                      {(this.state.downloadTXT === true) && (
                        <CSVLink
                          filename="Upload Loader Bank.txt"
                          target="_blank"
                          data={csvData}
                          uFEFF={encoding}
                        ><input type="hidden" ref={input => this.inputElementTxt = input} />
                        </CSVLink>
                      )}
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayTable }}>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row className="m5 table-nopadding">
                  <div className="card__title">
                    <h5 className="bold-text">{t('LBL.BANK_TRANSFER')}</h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    buttonAction="noButton"
                    conds={{
                      pyp_id: condPypId,
                      org_id: condOrganization,
                      off_id: condOffice,
                      emp_id: condEmployee,
                      ems_id: condEmployeeStatus,
                      rtk_id: condBank,
                      type: '1',
                      period,
                      remark,
                      month,
                    }}
                    onRef={ref => (this.child = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.period_month) {
    errors.period_month = 'Period shouldn’t be empty';
  }
  if (!values.period_year) {
    errors.period_year = 'Period shouldn’t be empty';
  }
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  if (!values.format_report) {
    errors.format_report = 'Format report field shouldn’t be empty';
  }
  if (!values.office) {
    errors.office = 'office field shouldnt be empty';
  }
  if (!values.bank) {
    errors.bank = 'bank field shouldnt be empty';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'payroll_bank_transfer', // a unique identifier for this form
})(translate('global')(ReportPayrollBankTransfer));
