import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../../../../shared/components/config/config';
import { store } from '../../../App/store';
import ls from 'local-storage';
import { Row, Col } from 'reactstrap';

class AccountForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      textError: '',
      showPassword: false,
      spanError: 'none',
    };

    this.showPassword = this.showPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log(event.key);
      event.preventDefault();
      document.getElementById('submitEnter').click(); // Klik tombol Submit
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const password = event?.target?.password?.value;

    if (!password || password.trim() == '') return false;

    fetch(`${config.ws.ip}/login/auth`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `username=${this.props.data.empCode}&password=${password}&subdomain=${config.subdomain}`,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 'SUCCESS') {
          ls.set('token', result.token);
          store.dispatch({ type: 'SET_USER_RELOGIN', data: false });
          // this.getDetailUserByToken(result.token);
        } else if (result.status === 'BLOCK TEMPORARY') {
          this.setState({
            textError: 'Username has been blocked please try 1 hour again!',
            spanError: '',
          });
        } else if (result.status === 'FAILED') {
          this.setState({
            textError: 'Invalid Username or Password!',
            spanError: '',
          });
        } else if (result.status === 'BLOCKED') {
          this.setState({
            textError: 'Username has been blocked, contact your Administrator!',
            spanError: '',
          });
        } else if (result.status === 'INACTIVE') {
          this.setState({
            textError: 'Username inactive, please contact Administrator!',
            spanError: '',
          });
        } else if (result.status === 'NULL') {
          this.setState({
            textError: 'Username and password not registered!',
            spanError: '',
          });
        }
      });
  }

  render() {
    const { spanError, textError } = this.state;
    return (
      <form
        className="form"
        style={{ maxHeight: '170px' }}
        onSubmit={this.handleSubmit}
      >
        <div className="form__form-group form__form-group--forgot">
          <div style={{ textAlign: 'center' }} className="pt-4 pb-4 pl-5 pr-5">
            <span
              className="form__form-group-label"
              style={{ fontSize: '15px' }}
            >
              Sorry your access session has expired
            </span>
            <br />
            <span
              className="form__form-group-label"
              style={{ fontSize: '15px' }}
            >
              please input your password
            </span>
          </div>
          <div className="pt-1">
            <Row style={{ maxHeight: '30px'}}>
              <Col xs={7} md={9} lg={9} xl={9} style={{ paddingRight: '0' }}>
                <Field
                  name="password"
                  component="input"
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  style={{
                    width: '90%',
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                  }}
                  onKeyPress={this.handleKeyPress}
                />
                <button
                  className={`form__form-group-button${
                    this.state.showPassword ? ' active' : ''
                  }`}
                  onClick={(e) => this.showPassword(e)}
                  style={{
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </Col>
              <Col xs={5} md={3} lg={3} xl={3}>
                <button
                  className="btn btn-primary account__btn btn-sm"
                  id="submitEnter"
                  style={{ borderRadius: '5px' }}
                  type="submit"
                >
                  Submit
                </button>
              </Col>
            </Row>
            <div className="form__form-group">
              <span
                className="form__form-group-error"
                style={{
                  background: '#ffbcbc',
                  borderRadius: '3px',
                  position: 'absolute',
                  padding: '5px 10px 5px 10px',
                  margin: '2px 0px 0px 0px',
                  width: '76%',
                  display: spanError,
                  fontSize: '11px'
                }}
              >
                {textError}
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password shouldn't be empty";
  }

  console.log({ errors });
  return errors;
};

export default reduxForm({
  form: 'account_lock_form',
  validate, // a unique identifier for this form
})(AccountForm);
