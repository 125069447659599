/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unneeded-ternary */

import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderDocumentInputField from '../../../../shared/components/form/DocumentInput';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import SunEditor from 'suneditor-react';

// let compthr = '';
class PayslipTemplate extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      sunEditorButtonList: [
        ['bold', 'underline', 'italic'],
        ['outdent', 'indent', 'align'],
        ['list'],
        // ['template'],
      ],
      apiws: '',
      iduser: '',
      token: '',
      plp_id: '',
      button_disabled: false,
      disabledinput: false,
      statusedit: false,
      email_description: '',
      generate_password: 1,
    };
    this.handleEmailDescChange = this.handleEmailDescChange.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
    }, () => {
      this.getTemplate();
    });
  }

  getTemplate() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/paypayslippreferences/getAll`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const res = response.data.data;
        if (res.length === 0) {
          this.setState({ disabledinput: false, statusedit: false });
        } else {
          this.props.destroy();
          this.props.initialize({ plp_id: res[0].plp_id, ...res[0] });
          if (res[0].email_description === null || res[0].email_description === '<p><br></p>' || res[0].email_description.length === 0) {
            this.setState({ email_description: '' });
          } else {
            this.setState({ email_description: res[0].email_description });
          }
          this.setState({ disabledinput: true, statusedit: true, generate_password: res[0].generate_password });
        }
      } else {
        this.setState({ button_disabled: false });
      }
    }).catch(() => {
      // console.log(error);
      this.setState({ button_disabled: false });
    });
  }

  handleSubmitInput(values) {
    const {
      apiws, token, iduser,
    } = this.state;
    let template;
    if (values.template !== null) {
      template = (typeof values.template === 'object') ? values.template.file : '';
    } else {
      template = '';
    }
    const id = values.plp_id;
    let url;
    let data;
    if (id === undefined || id === '' || id === null) {
      url = `${apiws}/paypayslippreferences/saveItem`;
      data = {
        ...values,
        cuser_id: iduser,
        email_description: this.state.email_description,
        template,
      };
    } else {
      url = `${apiws}/paypayslippreferences/updateItem/${id}`;
      data = {
        ...values,
        muser_id: iduser,
        email_description: this.state.email_description,
        template,
      };
    }
    this.setState({ button_disabled: true });
    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        this.setState({
          button_disabled: false, disabledinput: true, statusedit: true,
        });
        showNotifications('Success', 'Save Success', 'success');
        this.getTemplate();
      } else {
        this.setState({ button_disabled: false });
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      this.setState({ button_disabled: false });
    });
  }

  handleEmailDescChange(values) {
    this.setState({ email_description: values });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledinput: false, statusedit: false, button_disabled: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.setState({ disabledinput: true, statusedit: true });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      component,
      button_disabled,
      disabledinput,
      statusedit,
      generate_password,
    } = this.state;
    let button_action;
    if (statusedit) {
      button_action = (
        <Button
          color="success"
          type="button"
          onClick={this.handleEdit}
        > {t('FRM.EDIT')}
        </Button>);
    } else {
      button_action = (
        <ButtonToolbar>
          <Button
            color="secondary"
            type="button"
            onClick={this.handleCancel}
          > {t('FRM.CANCEL')}
          </Button>
          <Button
            color="primary"
            type="submit"
            form="payslipforminput"
            disabled={button_disabled}
          > {t('FRM.SAVE')}
          </Button>
        </ButtonToolbar>);
    }
    return (
      <Container>
        {/* <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <h4>{t('LBL.PAYSLIP_SETTING')}</h4>
            <hr />
          </Col>
        </Row> */}
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <form
              className="form form--horizontal"
              name="payslipforminput"
              id="payslipforminput"
              onSubmit={handleSubmit(this.handleSubmitInput)}
            >
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.PDF_TEMPLATES')}</span>
                <div className="form__form-group-field">
                  <Col md={12}>
                    <input type="hidden" name="plp_id" />
                    <Field
                      name="template"
                      disabled={disabledinput}
                      component={renderDocumentInputField}
                      type="text"
                    />
                  </Col>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.EMAIL_DESCRIPTION')}</span>
                <div className="form__form-group-field">
                  <Col md={12}>
                    <SunEditor
                      setDefaultStyle="font-family: Roboto; font-size: 14px;"
                      height="150px"
                      // eslint-disable-next-line react/jsx-boolean-value
                      disable={disabledinput}
                      onChange={this.handleEmailDescChange}
                      setContents={this.state.email_description}
                      setOptions={{
                        buttonList: this.state.sunEditorButtonList,
                      }}
                    />
                  </Col>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.PASSWORD')}</span>
                <div className="form__form-group-field">
                  <Col md="6" lg="4" xs="12">
                    <Field
                      name="generate_password"
                      component={renderRadioButtonField}
                      label="Generate every month"
                      radioValue={1}
                      defaultChecked={(generate_password === 1) ? true : false}
                      disabled={disabledinput}
                    />
                  </Col>
                  <Col md="6" lg="4" xs="12">
                    <Field
                      name="generate_password"
                      component={renderRadioButtonField}
                      label="One time"
                      radioValue={2}
                      disabled={disabledinput}
                      defaultChecked={(generate_password === 2) ? true : false}
                    />
                  </Col>
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar" style={{ marginLeft: '185px' }}>
                {button_action}
              </ButtonToolbar>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <div className="note-file-upload">
              <h5>
                <FontAwesomeIcon icon={faInfoCircle} />
                Notes
              </h5>
              <hr />
              <ol>
                <li>Format Payslip adalah file pdf. Contoh template (<a href="../../template.docx" download="payslip_template.docx">download</a>)</li>
                <li>Email description redaksi email</li>
              </ol>

            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'payslipsettingform',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  // validate,
})(translate('global')(PayslipTemplate));
