/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faSync, faTimes, faClock, faFileImage, faFilePdf } from '@fortawesome/fontawesome-free-solid';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ls from 'local-storage';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderIntervalDatePickerField from '../../../../../shared/components/form/IntervalDatePicker';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import renderTimePickerIntervalField from '../../../../../shared/components/form/TimePickerInterval';
import utils from '../../../../../utils';

class submissionForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    wfo_id: PropTypes.string.isRequired,
    // emp_id: PropTypes.string.isRequired,
    initialize: PropTypes.func.isRequired,
    disabledField: PropTypes.bool.isRequired,
    getValue: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '15%', '20%', '20%', '15%', '15%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      displayLoad: 'none',
      displaySave: '',
      disabled: false,
      arrworkflow: [],
      arrOvertime: [],
      attachment: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getOvertimeReq = this.getOvertimeReq.bind(this);
    this.getOvertimeCategory = this.getOvertimeCategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.checkAttachment = this.checkAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.viewAttachment = this.viewAttachment.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getOvertimeCategory();
      // this.getButton();
    });
    if (typeof this.props.wfo_id !== 'undefined') {
      this.getOvertimeReq();
      this.setState({ disabled: this.props.disabledField });
      this.setState({ displaySave: 'none' });
    }
  }


  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getOvertimeCategory();
      // this.getButton();
    });
    if (typeof this.props.wfo_id === 'undefined') {
      this.props.initialize(newProps.value);
    }
  }

  getEmploymentStatus() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/rtbemployeementstatus/getAll/`,
      '',
      config,
    ).then((resEmpStatus) => {
      const emp = resEmpStatus.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmpStatus: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.ems_id, label: obj.ems_employeement_status })
        ));
        this.setState({ arrEmpStatus: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getOvertimeReq = () => {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const wfo = this.props.wfo_id;
    axios.get(
      `${ls.get('ws_ip')}/empovertimerequest/getOvertimeByWfo/${wfo}`,
      config,
    ).then((resemp) => {
      const dataResponse = resemp.data.data;
      const start = moment(dataResponse[0].overtime_start_time); // some random moment in time (in ms)
      const end = moment(dataResponse[0].overtime_end_time); // some random moment after start (in ms)
      const diff = end.diff(start);

      // execution
      const duration = moment.utc(diff).format('HH:mm');
      const datamapp = {
        eor_id: dataResponse[0].eor_id,
        oct_id: dataResponse[0].oct_id,
        overtime_date: moment(dataResponse[0].overtime_date),
        overtime_start_time: moment(dataResponse[0].overtime_start_time),
        overtime_end_time: moment(dataResponse[0].overtime_end_time),
        duration,
        reason: dataResponse[0].eor_reason,
      };

      this.props.initialize(datamapp);
    }).catch(() => {
      // console.log(error);
    });
  }

  getWorkflow() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios.post(
      `${ls.get('ws_ip')}/workflow/getAllItem/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.wfl_id, label: obj.workflow })
        ));
        this.setState({ arrworkflow: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getOvertimeCategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios.post(
      `${ls.get('ws_ip')}/payovertimecategory/getAllItem/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.oct_id, label: obj.name })
        ));
        this.setState({ arrOvertime: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '', displaySave: 'none' });
    const { apiws, token } = this.state;
    const userid = ls.get('user_cred').usr_id;
    // eslint-disable-next-line prefer-destructuring
    const emp_id = ls.get('user_cred').emp_id;
    const overtime_date = moment(values.overtime_date).format('YYYY-MM-DD');
    // const remarks = typeof values.remarks === 'undefined' ? '' : values.remarks;
    const wfl_id = (typeof values.oct_id.wfl_id === 'undefined' || values.oct_id.wfl_id === null)
      ? '' : values.oct_id.wfl_id;
    const oct_id = (typeof values.oct_id === 'undefined' || values.oct_id === null)
      ? '' : values.oct_id.value || values.oct_id;
    const url = `${apiws}/workflow/generateovertimecase`;
    const data = {
      ...values,
      wfl_id,
      emp_id,
      oct_id,
      //   remarks,
      overtime_date,
      cuser_id: userid,
    };

    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Leave request has submitted', 'success');
        this.setState({ displayLoad: 'none', displaySave: '', redirect: true });
        setTimeout(() => {
          this.props.destroy();
        }, 200);
      } else {
        this.setState({ displayLoad: 'none', displaySave: '' });
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  checkAttachment(e) {
    const file = e.target.files[0];
    const allowedType = ['application/pdf', 'image/gif', 'image/jpeg', 'image/png'];
    const allowedSize = 1000; // in kilobytes
    if (!allowedType.includes(file.type)) {
      Swal.fire({
        title: 'Warning',
        text: 'File type not allowed!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else if (Math.ceil(file.size / 1000) > allowedSize) {
      Swal.fire({
        title: 'Warning',
        text: 'Maximum file size exceeded!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      this.props.getValue('', [...this.state.attachment, file], '', 'attachment');
      this.setState({ attachment: [...this.state.attachment, file] });
    }
  }

  removeAttachment(pos) {
    const list = this.state.attachment;
    list.splice(pos, 1);
    setTimeout(() => {
      this.props.getValue(this.props.value, list, '', 'attachment');
    }, 200);
    this.setState({ attachment: list, trigger: !this.state.trigger });
  }

  viewAttachment(pos) {
    const list = this.state.attachment;
    console.log(pos);
    this.setState({
      modalHeader: list[pos].name,
      modalContent: typeof list[pos].path === 'string' ? list[pos].path : URL.createObjectURL(list[pos]),
    });
    this.toggle();
  }

  downloadAttachment(pos) {
    const list = this.state.attachment;
    const objectURL = typeof list[pos].path === 'string' ? list[pos].path : URL.createObjectURL(list[pos]);
    const a = document.createElement('a');
    a.href = objectURL;
    a.target = '_blank';
    a.download = list[pos].name;
    a.click();
  }


  render() {
    const { handleSubmit, t } = this.props;
    const {
      disabled, displayLoad, displaySave, arrworkflow, arrOvertime, attachment,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{ float: 'right', 'margin-bottom': '20px', display: this.state.addcond === '1' ? '' : 'none' }}
      >
        {t('FRM.ADD')} {t('LBL.LEAVE_REQUEST')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (this.state.redirect) {
      return (<Redirect to="/dashboard" />);
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row>
                  {/* {this.state.disabled === false && (
                    <Col md={8}>
                      <h3 className="page-title">{t('LBL.SUBMISSION_OVERTIME')}</h3>
                    </Col>
                  )} */}
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      id="addovertimehistory"
                      name="addovertimehistory"
                      className="form form__vertical"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <Container>
                        <input type="hidden" name="eor_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.DATE')}</span>
                          <div className="form__form-group-field">
                            <Col md={4} xs={12} style={{ padding: '0' }}>
                              <Field
                                name="overtime_date"
                                component={renderDatePickerField}
                                placeholder="Start Date"
                                disabled={disabled}
                                onChange={(this.props.getValue)}

                                props={{ disableTouched: this.props.disabled }}
                              />
                            </Col>
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.OVERTIME_TYPE')}</span>
                          <div className="form__form-group-field">
                            <Col md={4} xs={12} style={{ padding: '0' }}>
                              <Field
                                name="oct_id"
                                component={renderSelectField}
                                options={arrOvertime}
                                disabled={disabled}
                                onChange={(this.props.getValue)}

                                props={{ disableTouched: this.props.disabled }}
                              />
                            </Col>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.OVERTIME_DURATION')}</span>
                          <div className="form__form-group-field">
                            <Row className="align-items-center">
                              <Col md={3} xs={12}>
                                <Field
                                  name="overtime_start_time"
                                  component={renderTimePickerIntervalField}
                                  placeholder="18:00"
                                  formatdate="HH:mm"
                                  disabled={disabled}
                                  onChange={this.props.getValue}
                                  props={{ disableTouched: this.props.disabled }}
                                />
                              </Col>
                              <Col md={3} xs={12}>
                                <Field
                                  name="overtime_end_time"
                                  component={renderTimePickerIntervalField}
                                  placeholder="18:00"
                                  formatdate="HH:mm"
                                  disabled={disabled}
                                  onChange={this.props.getValue}
                                  props={{ disableTouched: this.props.disabled }}
                                />
                              </Col>
                              <Col md={2} xs={12}>
                                <Field
                                  name="duration"
                                  component={renderTextInput}
                                  formatdate="HH:mm"
                                  disabled="true"
                                />
                              </Col>
                              <h6 className="form__form-group-label" style={{ marginBottom: '0px' }}>{t('LBL.HOURS')}</h6>
                            </Row>
                          </div>
                        </div>
                        {/* <Row>
                          <Col md="12" lg="12" xl="12" sm="12">

                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.UPLOAD_FILE')}</span>
                              <div className="form__form-group-file">
                                {
                                  this.props.emp_id === undefined && <label htmlFor="attachment">Choose the file</label>
                                }
                                {
                                  this.props.emp_id === undefined &&
                                  <div>
                                    <input
                                      type="file"
                                      name="attachment"
                                      id="attachment"
                                      props={{ disableTouched: this.props.disabled }}
                                      onChange={(e) => {
                                        this.checkAttachment(e);
                                        setTimeout(() => {
                                          this.props.getValue(this.props.value, this.state.attachment, '', 'attachment');
                                        }, 200);
                                      }}
                                    />
                                    <small>File type: png,jpg,jpeg,pdf</small>
                                    <br />
                                    <small>File size: 1Mb</small>
                                  </div>
                                }
                                <div >
                                  {
                                    attachment.map((file, pos) => (
                                      <div>
                                        <FontAwesomeIcon icon={(file.type === 'application/pdf' || file.name.split('.')[file.name.split('.').length - 1] === 'pdf') ? faFilePdf : faFileImage} />
                                        <span>{file.name}</span>
                                        {
                                          (file.type !== 'application/pdf' && file.name.split('.')[file.name.split('.').length - 1] !== 'pdf') &&
                                          <button
                                            type="button"
                                            onClick={() => this.viewAttachment(pos)}
                                            className="btn btn-sm btn-outline-secondary btn-data-table mx-1"
                                          >
                                            {t('LBL.VIEW')}
                                          </button>
                                        }
                                        <button
                                          type="button"
                                          onClick={() => this.downloadAttachment(pos)}
                                          className="btn btn-sm btn-outline-success btn-data-table mx-1"
                                        >
                                          {t('FRM.DOWNLOAD')}
                                        </button>
                                        {
                                          this.props.emp_id === undefined &&
                                          <button
                                            type="button"
                                            onClick={() => this.removeAttachment(pos)}
                                            className="btn btn-sm btn-outline-danger btn-data-table mx-1"
                                          >
                                            <i className="fas fa-times" />
                                          </button>
                                        }
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.REASON')}</span>
                          <div className="form__form-group-field">
                            <Col md={12} xs={12} style={{ padding: '0' }}>
                              <Field
                                name="reason"
                                component={renderTextInput}
                                type="textarea"
                                disabled={this.state.disabled}
                                onChange={this.props.getValue}
                                props={{ disableTouched: this.props.disabled }}
                                maxLength="350"
                              />
                            </Col>
                          </div>
                        </div>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>{this.state.modalHeader}</ModalHeader>
          <ModalBody>
            <img src={this.state.modalContent} alt={this.state.modalHeader} />
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}


const validate = (values) => {
  const validation = {
    overtime_date: {
      fieldLabel: 'Date',
      required: true,
    },
    overtime_start_time: {
      fieldLabel: 'Start time',
      required: true,
    },
    overtime_end_time: {
      fieldLabel: 'End time',
      required: true,
    },
    reason: {
      fieldLabel: 'Reason',
      required: true,
    },
    oct_id: {
      fieldLabel: 'Overtime Type',
      required: true,
    },
  };

  const errors = utils.validate(validation, values);

  if (moment(values.overtime_date) === new Date()) {
    errors.overtime_date = 'Date has been requested';
  }
  return errors;
};

export default reduxForm({
  form: 'approval', // a unique identifier for this form
  validate,
})(translate('global')(submissionForm));
