const initialState = {
  data: {},
  isLocked: false,
  hierarcy: {},
  shortcut: [],
  news: [],
  leaveBalance: [],
  companyVisionMision: {},
  tasklist: [],
  attendance: [],
  jobLevel: [],
  birthday: [],
  notification: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return { ...state, data: action.data };
    case 'SET_USER_RELOGIN':
      return { ...state, isLocked: action.data };
    case 'SET_USER_HIERARCY':
      return { ...state, hierarcy: { ...state.hierarcy, ...action.data } };
    case 'SET_USER_MENU_SHORTCUT':
      return { ...state, shortcut: action.data };
    case 'SET_USER_NEWS':
      return { ...state, news: action.data };
    case 'SET_USER_LEAVE_BALANCE':
      return { ...state, leaveBalance: action.data };
    case 'SET_LOG_ACTIVITY':
      return { ...state, userLog: action.data };
    case 'SET_VISION_MISION':
      return { ...state, companyVisionMision: action.data };
    case 'SET_USER_TASKLIST':
      return { ...state, tasklist: action.data };
    case 'SET_USER_ATTENDANCE':
      return { ...state, attendance: action.data };
    case 'SET_USER_JOB_LEVEL':
      return { ...state, jobLevel: action.data };
    case 'SET_USER_BIRTHDAY':
      return { ...state, birthday: action.data };
    case 'SET_USER_NOTIFICATION':
      return { ...state, notification: action.data };
    case 'CLEAR_USER':
      return initialState;
    default:
      return state;
  }
}
