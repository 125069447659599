/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent, useState } from 'react';
import PropTypes, { array, number, object } from 'prop-types';
import ls from 'local-storage';
import Swal from 'sweetalert2';
import { text } from '@fortawesome/fontawesome-svg-core';

class FileInputField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    allowedType: PropTypes.array.isRequired,
    maxSize: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    value: null,
  };

  constructor() {
    super();
    this.state = {
      showImage: true,
      image: '',
      mimeType: {
        png: 'image/png',
        gif: 'image/gif',
        jpg: 'image/jpeg',
        pdf: 'application/pdf',
      },
    };

    this.handleImagePreview = this.handleImagePreview.bind(this);
  }

  handleImagePreview(e) {
    const { onChange, name, value, allowedType, maxSize } = this.props;
    const { mimeType } = this.state;
    e.preventDefault();
    const reader = new FileReader();
    // convert files to an array
    const files = [...e.target.files];
    reader.onloadend = () => {
      this.setState({ image: reader.result, showImage: true });
    };

    console.log(name);

    const fileType = files[0]?.type ?? null;
    const fileSize = files[0]?.size ?? null;
    const validImageTypes = [];

    if (!fileSize || !fileType) {
      return false;
    }
    if (typeof allowedType === 'object' && allowedType.length > 0) {
      allowedType.map((f) => validImageTypes.push(mimeType[f]));
    }
    if (
      !validImageTypes.includes(fileType) &&
      typeof allowedType === 'object' &&
      allowedType.length > 0
    ) {
      Swal.fire({
        title: 'Warning',
        text: 'Wrong type of file!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else if (Math.ceil(fileSize / 1000) > maxSize && maxSize > 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Maximum file size exceeded!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      onChange({ file: files[0], name: files[0].name });
      reader.readAsDataURL(files[0]);
    }
  }

  handleImageClose = () => {
    const { onChange } = this.props;
    this.setState({ showImage: false });
    onChange({});
  };

  handleImageRemove = () => {
    const { onChange } = this.props;
    console.log(this.props, '<<<<<input');
    onChange({ file: null, name: null });
  };
  render() {
    const {
      onChange,
      name,
      value,
      disabled,
      allowedType,
      maxSize,
    } = this.props;
    const { image, showImage } = this.state;
    // console.log(this.state.mimeType);
    const imageStyle = {
      width: '280px',
      height: 'auto',
      border: '1px solid #ddd',
      borderRadius: '3px',
      padding: '10px',
    };
    const { isClosed } = this.state;
    let $imagePreview = null;
    if (image && showImage) {
      $imagePreview = (
        <>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="text-end"
              onClick={this.handleImageClose}
              style={{
                paddingTop: '4px',
                paddingBottom: '4px',
                border: 'none',
              }}
            >
              <img src="/img/icon-close.svg" />
            </button>
          </div>
          <img src={image} alt="" className="img-fluid mt-3" />
        </>
      );
    } else if (typeof value == 'string' && value) {
      $imagePreview = (
        <img
          src={`${`${ls.get('ws_ip')}/public/uploads${value}`}`}
          alt=""
          className="img-fluid mt-3"
        />
      );
    } else if (typeof value == 'object') {
      $imagePreview = (
        <div
          className="mt-3 d-flex flex-column text-center align-items-center justify-content-center border rounded-lg"
          style={{ height: 150 }}
        >
          {value?.name && (
            <div style={{ position: 'absolute', top: 130, right: 25 }}>
              <button
                className="text-end"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleImageRemove();
                }}
                style={{
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  border: 'none',
                }}
              >
                <img src="/img/icon-close.svg" />
              </button>
            </div>
          )}
          <img
            src={`${
              value.file && value.name
                ? `${ls.get('ws_ip')}/public/uploads${value.file}`
                : '/img/empty-banner.png'
            }`}
            alt=""
            // className="mt-3"
            style={{
              width: value.file && value.name ? 355 : 56,
              height: 149,
              objectFit: value.file && value.name ? 'cover' : 'contain',
            }}
          />
          {/* {!value.name && (
            <p className="mt-2 text-muted ml-2">
              {value.name ? value.name : '...'}
            </p>
          )} */}
        </div>
      );
    } else {
      $imagePreview = (
        // <img src={'/img/placeholder-blank-image.png'} className='img-fluid mt-3'/>
        <div
          className="mt-3 d-flex flex-column text-center align-items-center justify-content-center border rounded-lg"
          style={{ height: 150 }}
        >
          <img src={'/img/empty-banner.png'} alt="" width={56} />
          <p className="mt-2 text-muted ml-2">
            {value.name ? value.name : '...'}
          </p>
        </div>
      );
    }

    if (allowedType.includes('pdf')) {
      // const [isClosed, setIsClosed] = useState(false);
      // const value = {};

      $imagePreview = (
        <div
          className="mt-3 d-flex flex-column p-3 align-items-center border rounded-lg"
          style={{ height: 150 }}
        >
          {value.name && (
            <div className="d-flex justify-content-end w-100">
              <button
                className=""
                style={{
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  border: 'none',
                }}
                onClick={this.handleImageClose}
              >
                <img src="/img/icon-close.svg" />
              </button>
            </div>
          )}
          <img
            src={'/img/empty-pdf.png'}
            className="mt-3"
            width={56}
            style={{
              // filter: isClosed ? 'grayscale(1)' : 'grayscale(0)',
              filter: value.name ? 'grayscale(0)' : 'grayscale(1)',
              opacity: value.name ? 1 : 0.5,
            }}
          />
          <p className="mt-2 text-muted ml-2">
            {value.name ? value.name : '...'}
          </p>
        </div>
      );
    }

    return (
      <div className="form__form-group-file">
        {disabled ? (
          ''
        ) : (
          <label htmlFor={name} className="d-flex bg-white">
            <div
              className="label"
              style={{
                padding: '8px 12px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: 250,
              }}
            >
              {value.name ? value.name : '...'}
            </div>
            <div
              className="ml-auto"
              style={{ background: '#EFEFEF', padding: '8px 12px' }}
            >
              Choose File
            </div>
          </label>
        )}
        {/* <label htmlFor={name}>Choose the file</label> */}
        <div>
          <input
            type="file"
            name={name}
            id={name}
            disabled={disabled}
            onChange={this.handleImagePreview}
          />
          {typeof allowedType === 'object' && allowedType.length > 0 && (
            <>
              <small>Harap unggah file dengan type </small>
              <small className="text-danger">{allowedType.join(', ')}</small>
            </>
          )}
          {maxSize > 0 && (
            <>
              <small> dengan ukuran maksimal </small>
              <small className="text-danger">{maxSize}kb</small>
            </>
          )}
          <br />
          {$imagePreview}
        </div>
      </div>
    );
  }
}

const renderFileInputField = (props) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <FileInputField
      {...props.input}
      disabled={props.disabled}
      allowedType={props.allowedType}
      maxSize={props.maxSize}
    />
    {props.type === 'textemployee' && props.meta.error && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
    {props.meta.touched && props.meta.error && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
  </div>
);

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  type: PropTypes.string,
  allowedType: PropTypes.array,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
};

renderFileInputField.defaultProps = {
  meta: null,
  type: text,
  allowedType: array,
  maxSize: number,
  disabled: false,
};

export default renderFileInputField;
