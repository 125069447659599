/* eslint-disable import/no-named-as-default-member */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OfficeList from './Components/viewOfficeList';
import { deleteCryptoTableData } from '../../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';

class Office extends PureComponent {
  static propTypes = {
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };
  onDeleteCryptoTableData = (index, e) => {
    e.preventDefault();
    const arrayCopy = [...this.props.cryptoTable];
    arrayCopy.splice(index, 1);
    this.props.dispatch(deleteCryptoTableData(arrayCopy));
  };
  render() {
    return (
      <Container className="office_class">
        <Row>
          <OfficeList men_id={this.props.men_id} fga_id={this.props.fga_id} />
        </Row>
      </Container>
    );
  }
}

export default connect(state => ({
  cryptoTable: state.cryptoTable.items,
}))(translate('common')(Office));
