/*
 * Page News & Updates Preferences
 * Notes: Master Data News & Updates
 * @author Fakhrurrozi
 * @date 12/04/2021
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../notificationMessages';
import PropTypes from 'prop-types';
import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import renderTextInput from '../../../../shared/components/form/TextInput';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/fontawesome-free-solid';
import './form.css';

class NewsAndUpdatesList extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('FRM.TITLE') },
      { '2': t('LBL.DESCRIPTION') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['10%', '25%', '57%', '8%'],
      title: '',
      errorTitle: 'none',
      description: '',
      publish_start: '',
      publish_end: '',
      errorDescription: 'none',
      attachment: '',
      tableInput: 'none',
      tableData: '',
      statusEdit: false,
      disableButton: false,
      disableLng: false,
      arrLng: [],
      modal: false,
      action: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getButton = this.getButton.bind(this);
    this.backListNews = this.backListNews.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addNews = this.addNews.bind(this);
    this.editNews = this.editNews.bind(this);
    this.deleteNews = this.deleteNews.bind(this);
    this.checkAttachment = this.checkAttachment.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/appnews/getAllItem` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('FRM.TITLE') },
      { '2': t('LBL.DESCRIPTION') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getLanguage() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/applanguage/getAll`, '', config)
      .then((response) => {
        const remp = response.data.data;
        const array = [];
        if (response.data.status === 'ok') {
          remp.map((obj) =>
            array.push({ value: obj.lng_id, label: obj.lng_language }),
          );
          this.setState({ arrLng: array });
        }
        // console.log(this.state.arrLng);
      });
  }

  checkAttachment(e) {
    const file = e.target.files[0];
    const allowedType = ['application/pdf'];
    const allowedSize = 1000; // in kilobytes
    if (!allowedType.includes(file.type)) {
      Swal.fire({
        title: 'Warning',
        text: 'Only pdf allowed!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else if (Math.ceil(file.size / 1000) > allowedSize) {
      Swal.fire({
        title: 'Warning',
        text: 'Maximum file size exceeded!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      this.setState({ attachment: file });
    }
  }

  backListNews() {
    this.setState({
      tableData: '',
      tableInput: 'none',
    });
    this.getTableData();
  }

  async handleSubmit(values) {
    const { apiws, token, idUser } = this.state;
    const {
      anu_id,
      title,
      description,
      publish_start,
      publish_end,
      banner,
      attachment,
    } = values;
    let url;
    const dataInput = new FormData();
    dataInput.append('title', title);
    dataInput.append('description', description);
    publish_start
      ? dataInput.append(
          'publish_start',
          moment(publish_start).format('YYYY-MM-DD'),
        )
      : dataInput.append(
          'publish_start',
          moment(new Date()).format('YYYY-MM-DD'),
        );
    dataInput.append('publish_end', moment(publish_end).format('YYYY-MM-DD'));
    if (typeof attachment !== 'undefined') {
      dataInput.append(`attachment${0}`, attachment?.file);
    }
    if (typeof banner !== 'undefined') {
      dataInput.append(`attachment${1}`, banner?.file);
    }
    if (anu_id === undefined || anu_id === '') {
      dataInput.append('cuser_id', idUser);
      url = `${apiws}/appnews/saveItem`;
    } else {
      dataInput.append('muser_id', idUser);
      url = `${apiws}/appnews/updateItem/${anu_id}`;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    if (title !== '') {
      await axios
        .post(url, dataInput, config)
        .then(async (response) => {
          if (response.data.status === 'ok') {
            this.props.destroy();
            showNotifications('Success', 'Save Success', 'success');
            this.setState({
              modal: !this.state.modal,
              action: '',
              statusEdit: false,
            });
            this.getTableData();
          }
        })
        .catch(() => {
          showNotifications('Fail', 'Save Failed', 'danger');
        });
    } else {
      // console.log();
    }
  }

  addNews() {
    this.setState({
      disableButton: true,
      action: 'add',
      statusEdit: false,
    });
    // this.props.destroy();
    this.toggle();
  }

  toggle() {
    this.props.destroy();
    this.setState({ modal: !this.state.modal, disableButton: true });
  }

  async editNews(id, e, param) {
    const { token, apiws, title, description } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/appnews/getItemById/${id}`, '', config)
      .then(async (response) => {
        if (parseInt(response.data.data.length, 10) > 0) {
          if (id === undefined || id === '' || typeof id === 'undefined') {
            this.props.initialize({
              anu_id: '',
              title: title,
              description: description,
            });
          } else {
            if (response.data.status === 'ok') {
              const getResponseData = response.data.data;
              this.setState({
                statusEdit: true,
                disableButton: false,
                attachment: '',
                action: 'edit',
                modal: true,
              });
              console.log(getResponseData[0].attachment, '<<<<ATTACHMENT');
              const file =
                getResponseData[0].attachment === null
                  ? ''
                  : {
                      name: getResponseData[0]?.attachment
                        ?.split('/')[3]
                        ?.split('_')
                        .slice(1)
                        .join('_'),
                    };
              // this.setState({
              //   attachment: file,
              // });
              this.props.initialize({
                anu_id: getResponseData[0].anu_id,
                title: getResponseData[0].title,
                description: getResponseData[0].description,
                banner: {
                  file: getResponseData[0].banner,
                  name: getResponseData[0].banner
                    .split('/')[3]
                    ?.split('_')
                    .slice(1)
                    .join('_'),
                },
                // banner: getResponseData[0].banner,
                attachment: file,
                publish_start: moment(getResponseData[0].publish_start),
                publish_end: moment(getResponseData[0].publish_end),
              });
            }
          }
        }
      });
  }

  deleteNews(id) {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/appnews/deleteitem/${id}`,
            `duser_id=${idUser}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              this.getTableData();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch((error) => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  changeValue(e, f) {
    if (f === 'title') {
      if (e?.target?.value) {
        this.setState({
          disableButton: false,
          title: e?.target?.value,
        });
      }
    } else if (f === 'description') {
      if (e?.target?.value) {
        this.setState({
          disableButton: false,
          description: e?.target?.value,
        });
      }
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      tableData,
      tableInput,
      disableButton,
      statusEdit,
      errorTitle,
      errorDescription,
      arrLng,
      disableLng,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.addNews}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('MEN.NEWS_AND_UPDATES')}
      </Button>
    );

    let labelButton;
    let colorButton;
    if (statusEdit) {
      labelButton = t('FRM.EDIT');
      colorButton = 'success';
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
    }
    return (
      <Container>
        <Row style={{ display: tableData }}>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="anu_id"
                  widthTable={this.state.dataWidth}
                  wrap={true}
                  deleteFunc={this.deleteNews}
                  updateFunc={this.editNews}
                  onRef={(ref) => (this.child = ref)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.action === 'add' ? t('FRM.ADD') : t('FRM.EDIT')}{' '}
            {t('MEN.NEWS_AND_UPDATES')}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="newsform"
              id="newsform"
            >
              <input type="hidden" name="anu_id" value="" />
              <Container>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('FRM.TITLE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="title"
                          id="title"
                          component={renderTextInput}
                          maxLength="90"
                          type="text"
                          onChange={(e) => {
                            this.changeValue(e, 'title');
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.DESCRIPTION')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          id="description"
                          component={renderTextInput}
                          maxLength="450"
                          type="textarea"
                          onChange={(e) => {
                            this.changeValue(e, 'description');
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" sm="12" xs="12">
                    <div className="form__form-group align-items-baseline">
                      <span className="form__form-group-label">
                        {/* {t('FRM.PUBLISH_PERIOD')} */}
                        Period Publish
                      </span>
                      <div className="theme-light form__form-group-field">
                        <div className="d-flex position-relative">
                          {/* <span>{t('FRM.PUBLISH_START')}</span> */}
                          <Field
                            name="publish_start"
                            component={renderDatePickerField}
                            props={{ value: this.state.publish_start }}
                            value={this.state.publish_end}
                            defaultValue={this.state.publish_start}
                          />
                          <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCalendar} />
                          </div>
                        </div>
                        <div className="flex-column mx-3 justify-content-center d-flex">
                          <span className="horizontal-line"></span>
                        </div>
                        <div className="d-flex position-relative">
                          {/* <span>{t('FRM.PUBLISH_END')}</span> */}
                          <Field
                            name="publish_end"
                            component={renderDatePickerField}
                            props={{ value: this.state.publish_end }}
                            value={this.state.publish_end}
                            defaultValue={this.state.publish_end}
                          />
                          <div className="icon-wrapper">
                            <FontAwesomeIcon icon={faCalendar} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" sm="6" xs="6">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Picture</span>
                      <div className="theme-light form__form-group-field">
                        <Field
                          name="banner"
                          allowedType={['png', 'jpg', 'gif']}
                          maxSize={5000}
                          component={renderFileInputField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6" lg="6" sm="6" xs="6">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('FRM.ATTACHMENT')}
                      </span>
                      <div className="theme-light form__form-group-field">
                        <Field
                          name="attachment"
                          allowedType={['pdf']}
                          maxSize={5000}
                          component={renderFileInputField}
                        />
                        <div className="form__form-group-file">
                          {/* <label htmlFor="attachment">Choose the file</label> */}

                          <div
                            className="d-inline ml-1"
                            style={{ position: 'relative' }}
                          >
                            {this.state.attachment.name}
                            {this.state.attachment.name && (
                              <button
                                type="button"
                                onClick={() =>
                                  this.setState({ attachment: '' })
                                }
                                className="btn btn-sm btn-outline-danger btn-data-table mx-1"
                              >
                                <i className="fas fa-times" />
                              </button>
                            )}
                          </div>
                          <div>
                            <input
                              type="file"
                              name="attachment"
                              id="attachment"
                              onChange={(e) => this.checkAttachment(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color={colorButton}
              form="newsform"
              type="submit"
              disabled={disableButton}
            >
              {' '}
              {labelButton}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Title field shouldn’t be empty';
  }
  if (!values.description) {
    errors.description = 'Description field shouldn’t be empty';
  }
  if (!values.publish_end) {
    errors.publish_end = 'End Period field shouldn’t be empty';
  }
  if (new Date(values.publish_end) <= new Date()) {
    errors.publish_end = 'End period should after today';
  }
  if (new Date(values.publish_start) > new Date(values.publish_end)) {
    errors.publish_start = 'Start period should before end period';
  }
  return errors;
};

export default reduxForm({
  form: 'reduxNewsAndUpdatesList', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(NewsAndUpdatesList));
