/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Row, Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../../shared/components/form/Select';
import AdvanceAttendanceProcess from './AdvanceAttendanceProcess';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../Preferences/notificationMessages';


class AttendaceProcesssForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      arrPcs: [],
      pypid: '',
      startdate: '',
      enddate: '',
      status: '',
      apiws: '',
      token: '',
      idUser: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getPeriodProcess(); });
  }

  getPeriodProcess() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/payperiod/getAllRunAttendance`,
      '',
      config,
    ).then((response) => {
      const pcs = response.data.data;
      const array = [];
      if (pcs.length === 0) {
        this.setState({ arrPcs: array });
      } else {
        pcs.map(obj => (
          array.push({
            value: obj.pyp_id,
            label: obj.period_code,
            start: obj.attendance_start,
            end: obj.attendance_end,
            status: obj.is_process_attendance,
          })
        ));
        this.setState({ arrPcs: array });
      }
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    const {
      apiws,
      token,
      idUser,
    } = this.state;
    const pypId = values.select.value;
    const periodStart = values.select.start;
    const periodEnd = values.select.end;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const link = `${apiws}/paysalary/processAttendance`;
    const body = [`period_id=${pypId}`, `period_start=${periodStart}`, `period_end=${periodEnd}`, `muser_id=${idUser}`].join('&');
    axios.post(
      link,
      body,
      config,
    ).then((res) => {
      this.props.onProcess();
      showNotifications('Success', 'Save Success', 'success');
    });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      pypid, startdate, enddate, status,
    } = this.state;

    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col>
                <h3 className="page-title">{t('MEN.TRANSFER_TO_PAYROLL')}</h3>
                <h3 className="page-subhead subhead">{t('MEN.TRANSFER_TO_PAYROLL')}</h3>
              </Col>
            </Row>
            <Row className="m5">
              <Col md={6} xs={12} lg={6} xl={6}>
                <form className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="select"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('LBL.SELECT_PERIOD')}
                        options={this.state.arrPcs}
                        onChange={(val) => {
                          this.setState({ pypid: val.value });
                          this.setState({ startdate: val.start });
                          this.setState({ enddate: val.end });
                          this.setState({ status: val.status });
                        }}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="secondary" onClick={this.toggle}>{t('LBL.ADVANCE')}</Button>
                    <Button color="primary" type="submit">{t('LBL.PROCESS')}</Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <AdvanceAttendanceProcess periodId={pypid} start={startdate} end={enddate} status={status} isOpen={this.state.modal} toggleModal={this.toggle} />
      </Col >
    );
  }
}

export default reduxForm({
  form: 'apr_form', // a unique identifier for this form
})(translate('global')(AttendaceProcesssForm));
