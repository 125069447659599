/*
 * Page Password Setting
 * Notes: Setting Password User
 * @author Ajeng
 * @date 26/07/2019
 * Modification (please note below)
 * @dev Gerdi
 * @date 06/08/2019
 * Tasks: Update css and validate
*/
/* eslint-disable react/sort-comp */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderTextInput from '../../../../shared/components/form/TextInput';
import showNotifications from '../../../Preferences/notificationMessages';

class PasswordPreferences extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      t: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        apiws: '',
        token: '',
        idUser: '',
        buttonDisabled: false,
        statusEdit: true,
        disabledForm: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
    }
    componentDidMount = () => {
      this.setState({ token: ls.get('token') });
      this.setState({ apiws: ls.get('ws_ip') });
      this.setState({
        idUser: ls.get('user_cred').usr_id,
      }, () => {
        this.checkPassword();
      }); 
    }
    checkBoolean(x) {
      let val_x;
      if (x === 'true') {
        val_x = true;
      } else {
        val_x = false;
      }
      return val_x;
    }
    checkPassword() {
      const { apiws, token, idUser } = this.state;
      const config = {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios.post(
        `${apiws}/apppasswordsetting/getAll`,
        '',
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          if (dataResponse.length === 0) {
            this.setState({ disabledForm: false, statusEdit: false });
          } else {
            this.setState({ buttonDisabled: false, disabledForm: true, statusEdit: true });
            this.props.destroy();
            this.props.initialize({
              'pws_id': dataResponse[0].pws_id,
              'min_len_password': dataResponse[0].min_len_password,
              'allow_char': dataResponse[0].allow_char,
              'password_expired_day': dataResponse[0].password_expired_day,
              'password_history': dataResponse[0].password_history,
              'allow_uppercase': this.checkBoolean(dataResponse[0].allow_uppercase),
              'allow_lowercase': this.checkBoolean(dataResponse[0].allow_lowercase),
              'allow_number': this.checkBoolean(dataResponse[0].allow_number),
            });  
          }
        } else {
          this.setState({ buttonDisabled: false });
        }
      }).catch(() => {
        this.setState({ buttonDisabled: false });
      });
    }
    handleSubmit(values) {
      const {
        apiws, 
        token,
        idUser,
      } = this.state;
      let errors = true;
      if (!values.min_len_password) {
        errors = false;
      }
      if (!values.password_expired_day) {
        errors = false;
      }
      if (!values.password_history) {
        errors = false;
      }
      let url;
      let data;
      const id = values.pws_id;
      if (id === undefined || id === '') {
        url = `${apiws}/apppasswordsetting/saveItem`;
        data = { ...values, cuser_id: idUser };
      } else {
        url = `${apiws}/apppasswordsetting/updateItem/${id}`;
        data = { ...values, muser_id: idUser };
      }
      if (errors) {
        const formBody = Object.keys(data).map(key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');        
        this.setState({ buttonDisabled: true });
        const config = {
          headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        axios.post(
          url,
          formBody,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            this.setState({ buttonDisabled: false, disabledForm: true, statusEdit: true });
            this.checkPassword();
            showNotifications('Success', 'Save Success', 'success');
          } else {
            this.setState({ buttonDisabled: false });
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        }).catch(() => {
          this.setState({ buttonDisabled: false });
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }
    handleEdit(e) {
      e.preventDefault();
      this.setState({ buttonDisabled: false });
      this.setState({ disabledForm: false });
      this.setState({ statusEdit: false });
    }
    render() {
      const { handleSubmit, t } = this.props;
      const {
        statusEdit,
        disabledForm,
        buttonDisabled,
      } = this.state;
      let button_action;
      if (statusEdit) {
        button_action = (
          <Button
            color="success"
            onClick={this.handleEdit}
            type="button"
            disabled={buttonDisabled}
          > {t('FRM.EDIT')}
          </Button>);
      } else {
        button_action = (
          <Button
            color="primary"
            type="submit"
            form="formpasswordpreferences"
            disabled={buttonDisabled}
          > {t('FRM.SAVE')}
          </Button>);
      }
      return (
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4} xs={12} lg={4} xl={4}>
                      <form
                        className="form form--horizontal"
                        name="formpasswordpreferences"
                        id="formpasswordpreferences"
                        onSubmit={handleSubmit(this.handleSubmit)}
                      >
                        <Field
                          name="pws_id"
                          component={renderTextInput}                         
                          type="hidden"
                        />
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ALLOW_CHAR')} </span>
                          <div className="form__form-group-field">
                            <Field
                              name="allow_char"
                              component={renderTextInput}
                              disabled={disabledForm}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label" >{t('LBL.MIN_LEN_PASSWORD')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="min_len_password"
                              component={renderTextInput}
                              disabled={disabledForm}
                              type="number"
                            />
                          </div>
                        </div>
                        {/* <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.PASSWORD_EXPIRED_DAY')}</span>
                          <div className="form__form-group-field">
                            <Row style={{ 'padding-left': '15px' }} >
                              <Col md={4} xs={12} style={{ 'padding': '0' }}>
                                <Field
                                  name="password_expired_day"
                                  component={renderTextInput}
                                  disabled={disabledForm}
                                  type="number"
                                />
                              </Col>
                              <Col md={4} xs={12}>
                                <p
                                  className="label-form-right"
                                  style={{ marginTop: '9px' }}
                                > {t('LBL.DAYS')}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.PASSWORD_HISTORY')}</span>
                          <div className="form__form-group-field">
                            <Row style={{ 'padding-left': '15px' }} >
                              <Col md={4} xs={12} style={{ 'padding': '0' }}>
                                <Field
                                  name="password_history"
                                  component={renderTextInput}
                                  disabled={disabledForm}
                                  type="number"
                                />
                              </Col>
                              <Col md={4} xs={12}>
                                <p
                                  className="label-form-right"
                                  style={{ marginTop: '9px' }}
                                > {t('LBL.TIMES')}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ALLOW_UPPERCASE')}</span>
                          <div className="form__form-group-field">
                            <div>
                              <Field
                                name="allow_uppercase"
                                component={renderCheckBoxField}
                                disabled={disabledForm}                         
                              />         
                            </div> 
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ALLOW_LOWERCASE')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="allow_lowercase"
                              component={renderCheckBoxField}
                              disabled={disabledForm}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ALLOW_NUMBER')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="allow_number"
                              component={renderCheckBoxField}
                              disabled={disabledForm}
                            />
                          </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                          {button_action}
                        </ButtonToolbar>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
}

const validate = (values) => {
  const errors = {};

  if (!values.min_len_password) {
    errors.min_len_password = 'no empty';
  }
  if (!values.password_expired_day) {
    errors.password_expired_day = 'no empty';
  }
  if (!values.password_history) {
    errors.password_history = 'no empty';
  }
  return errors;
};

export default reduxForm({
  form: 'passwordpreferencesform',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(PasswordPreferences));
