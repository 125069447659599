import React, { useContext, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faSyncAlt,
} from '@fortawesome/fontawesome-free-solid';
import Swal from 'sweetalert2';
import 'react-datepicker/dist/react-datepicker.css';
import ls from 'local-storage';
import '../../scss/containers/fieldtimeline.scss';
import '../../scss/containers/fieldsidbar.scss';
import '../../scss/containers/fieldselect.scss';
import { Resizable } from 're-resizable';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import renderSelectField from '../../shared/components/form/Select';
import renderTextInput from '../../shared/components/form/TextInput';
import showNotifications from '../../../src/containers/Preferences/notificationMessages';
import { ActivityContext } from './ActivityContext';
import { translate } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { OSM, Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Fill, Icon, Style, Text } from 'ol/style';
import { Map, View } from 'ol';

const validate = (values) => {
  const errors = {};
  if (!values.activity_location) {
    errors.activity_location = "Activity Location shouldn't be empty";
  }
  if (!values.activity_description) {
    errors.activity_description = "Activity Description shouldn't be empty";
  }
  if (!values.activity) {
    errors.activity = "Activity Type shouldn't be empty";
  }
  if (!values.location_address) {
    errors.location_address = "Location Address shouldn't be empty";
  }
  return errors;
};

const FieldActivityBottom = ({
  t,
  getActivity = () => {},
  getAllActivity = () => {},
  setSelectedDate = () => {},
  dateHasActivity,
  selectedDate,
}) => {
  const [state, dispatch] = useContext(ActivityContext);
  const [selectedDatePicker, setSelectedDatePicker] = useState(null);
  const [close, setClose] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    location_address: '',
    fat_id: '',
    location_lon: '',
    location_lat: '',
    activity_location: '',
    activity_description: '',
    files: [],
    id_deleted: [],
    edit: false,
  });
  const [location, setLocation] = useState(true);

  const [isHoveredArray, setIsHoveredArray] = useState(
    new Array(3).fill(false),
  );

  const [page, setPage] = useState('');
  const [dataDetail, setDataDetail] = useState({
    fac_id: '',
    activity_name: '',
    activity_type: '',
    location_address: '',
    location_description: '',
    images: [],
    emp_id: '',
  });

  const [newData, setNewData] = useState([]);

  useEffect(() => {
    state?.activity?.map((item) => {
      setNewData((prev) => [
        ...prev,
        {
          fac_id: item.fac_id,
          activity_name: item.activity_name,
          activity_datetime: item.activity_datetime,
          location_address: item.location_address,
        },
      ]);
    });
    return () => setNewData([]);
  }, [state]);

  const [height, setHeight] = useState(250);

  const auth = {
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  };

  const updateDimensions = (d) => {
    setHeight((prevHeight) => prevHeight + d.height);
  };

  const handleResizeStopping = (e, direction, ref, d) => {
    updateDimensions(d);
  };

  const handleClick = (btn) => {
    if (btn == 'open') {
      if (state?.activity?.length == 0) {
        setClose(false);
      } else {
        setClose(true);
      }
    } else if (btn == 'close') {
      setClose(false);
    } else if (state?.activity?.length !== 0) {
      setClose(true);
    } else {
      if (state?.activity?.length == 0) {
        setClose(false);
      }
    }
  };

  useEffect(() => {
    handleClick();
  }, [state?.activity]);

  const handleHover = (index) => {
    const updatedArray = [...isHoveredArray];
    updatedArray[index] = true;
    setIsHoveredArray(updatedArray);
  };

  const handleMouseLeave = (index) => {
    const updatedArray = [...isHoveredArray];
    updatedArray[index] = false;
    setIsHoveredArray(updatedArray);
  };

  const getCoord = () => {
    // GET COORD

    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    };
    return new Promise((resolve, reject) => {
      const successCallback = (position) => {
        setFormData((prev) => ({
          ...prev,
          location_lon: position.coords.longitude,
          location_lat: position.coords.latitude,
        }));
        resolve(formData);
      };
      const errorCallback = (error) => {
        reject(error);
        if (error.message == 'User denied Geolocation') {
          Swal.fire({
            title: 'Warning',
            text: 'Location must be allowed!',
            type: 'warning',
            showCloseButton: true,
            closeButtonColor: '#3085d6',
            reverseButtons: true,
          });
        }
      };

      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        options,
      );
    });
  };

  useEffect(() => {
    getCoord();
  }, []);

  const getLocation = async () => {
    if (!formData.location_lat) await getCoord();

    let url = `${auth.apiws}/facactivity/getLocation`;
    const bodyParameters = {
      lat: formData.location_lat,
      lon: formData.location_lon,
    };
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
      },
    };

    axios
      .post(url, bodyParameters, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          if (page != 'edit') {
            setFormData((prev) => ({
              ...prev,
              location_address: response.data.data,
            }));
            setLocation(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleOffcanvas = (page) => {
    getLocation();
    setPage(page);

    if (page == 'new') {
      setFormData({
        location_address: '',
        fat_id: '',
        location_lon: formData.location_lon,
        location_lat: formData.location_lat,
        activity_location: '',
        activity_description: '',
      });
      setIsOpen(!isOpen);
    } else if (page == 'edit') {
      setFormData({
        location_address: dataDetail.location_address,
        fat_id: dataDetail.activity_type,
        location_lon: dataDetail.location_lon,
        location_lat: dataDetail.location_lat,
        activity_location: dataDetail.activity_location,
        activity_description: dataDetail.activity_description,
        id_deleted: formData.id_deleted,
        edit: false,
      });
      setIsOpen(isOpen);
    } else {
      setIsOpen(!isOpen);
      setDataDetail({
        fac_id: '',
        activity_location: '',
        activity_type: '',
        location_address: '',
        activity_description: '',
        location_lon: '',
        location_lat: '',
        images: [],
        emp_id: '',
      });
    }
  };

  const getActivityDetail = (id) => {
    let url = `${auth.apiws}/facactivity/getItemById/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const temp = [];
          response.data.data.map((item) => {
            temp.push({ url: item.path, id: item.fpr_id });
          });
          setDataDetail({
            fac_id: response.data.data[0].fac_id,
            activity_location: response.data.data[0].activity_location,
            activity_type: response.data.data[0].fat_id,
            location_address: response.data.data[0].location_address,
            activity_description: response.data.data[0].activity_description,
            location_lon: response.data.data[0].location_lon,
            location_lat: response.data.data[0].location_lat,
            emp_id: response.data.data[0].emp_id,
            images: temp,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reload = () => {
    getActivity(state?.activity[0]?.emp_id);
    getAllActivity(state?.activity[0]?.emp_id);
  };

  const handleDateChange = (event) => {
    setSelectedDate(moment(event).format('DD/MM/YYYY'));
    setSelectedDatePicker(event);
  };

  useEffect(() => {
    reload();
  }, [selectedDate]);

  return (
    <>
      <div className="map-act-sidebar">
        <Resizable
          size={{ width: '100%', height: close ? 'auto' : 0 }}
          maxHeight={500}
          minHeight={60}
          enable={{
            top: true,
            bottom: true,
            right: false,
            left: false,
            topRight: false,
            topLeft: false,
            bottomRight: false,
            bottomLeft: false,
          }}
          handleClasses={{
            top: 'my-top-handle',
          }}
          onResizeStop={handleResizeStopping}
        >
          <Container className="container-activity-top">
            <Row>
              <Col md={5}>
                <span className="activity-title">{t('LBL.ACTIVITYHIST')}</span>
              </Col>
              <Col md={7} className="col-activity-btn ">
                <div
                  className="form__form-group-field act-history-date"
                  style={{
                    width: 'unset',
                    justifyContent: 'end',
                    marginRight: '0rem',
                    position: 'relative',
                  }}
                >
                  <div className="date-picker">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <DatePicker
                        className="form__form-group-datepicker"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        dateFormat="DD/MM/YYYY"
                        selected={selectedDatePicker}
                        highlightDates={(['highlight-date'], dateHasActivity)}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="position-absolute"
                    style={{
                      top: '9px',
                      right: '7px',
                      color: 'rgba(100, 103, 119, 0.42)',
                    }}
                  />
                </div>

                <button
                  type="button"
                  className="btn-activity"
                  onClick={() => toggleOffcanvas('new')}
                >
                  {t('LBL.NEWACTIVITY')}
                </button>
                {close ? (
                  <button
                    type="button"
                    className="btn-close-act"
                    onClick={() => handleClick('close')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-close-act"
                    onClick={() => handleClick('open')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                    </svg>
                  </button>
                )}
              </Col>
            </Row>

            <section className="timeline_area section_padding_130">
              <Container>
                <Row>
                  <Col xs={12}>
                    {close && (
                      <>
                        <div
                          className="apland-timeline-area"
                          style={{ height }}
                        >
                          {newData?.map((data, index) => (
                            <div className="single-timeline-area">
                              <div
                                className="timeline-date wow fadeInLeft"
                                data-wow-delay="0.1s"
                                style={{
                                  visibility: 'visible',
                                  animationDelay: '0.1s',
                                  animationName: 'fadeInLeft',
                                }}
                              ></div>
                              <div className="timeline-number">
                                {newData.length - index}
                              </div>
                              <Row>
                                <Col
                                  md={12}
                                  onClick={() => {
                                    toggleOffcanvas('detail');
                                    getActivityDetail(data.fac_id);
                                  }}
                                >
                                  <div className="timeline-datetime">
                                    {data.activity_datetime} WIB
                                  </div>
                                  <div
                                    className="single-timeline-content d-flex wow fadeInLeft"
                                    data-wow-delay="0.3s"
                                    style={{
                                      visibility: 'visible',
                                      animationDelay: '0.3s',
                                      animationName: 'fadeInLeft',
                                    }}
                                  >
                                    <div
                                      className="timeline-text"
                                      onMouseEnter={() => handleHover(0)}
                                      onMouseLeave={() => handleMouseLeave(0)}
                                    >
                                      <h6>{data.activity_name}</h6>
                                      <p>{data.location_address}</p>
                                      {isHoveredArray[0] ? (
                                        <div className="hover-click-to-view">
                                          Click to view
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          </Container>
        </Resizable>
      </div>

      <FieldSidebar
        toggleOffcanvas={toggleOffcanvas}
        getCoord={getCoord}
        getLocation={getLocation}
        data={formData}
        setData={setFormData}
        auth={auth}
        setPage={setPage}
        page={page}
        dataDetail={dataDetail}
        setDataDetail={setDataDetail}
        t={t}
        isOpen={isOpen}
        getActivity={getActivity}
        setLocation={setLocation}
        location={location}
      />
    </>
  );
};

const FieldSidebarForm = ({
  toggleOffcanvas = () => {},
  getCoord = () => {},
  getLocation = () => {},
  data = {},
  setData = () => {},
  auth = [],
  page = '',
  dataDetail = {},
  setDataDetail = () => {},
  t = {},
  getActivity = () => {},
  setLocation = () => {},
  location,
  isOpen,
}) => {
  const [actType, setActType] = useState([]);
  const [touched, setTouched] = useState(false);

  const refreshLocation = async () => {
    setLocation(false);
    await getCoord();
    getLocation();
  };

  const getFacType = () => {
    let url = `${auth.apiws}/facactivitytype/getAll`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const fat = response.data.data;
          const arr = [];
          fat.map((obj) =>
            arr.push({ value: obj.fat_id, label: obj.activity_name }),
          );
          setTimeout(() => {
            setActType(arr);
          }, 500);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFacType();
  }, []);

  useEffect(() => {
    // Create a vector source and a vector layer for markers
    const vectorSource = new VectorSource({
      features: [
        new Feature({
          geometry: new Point(
            fromLonLat([dataDetail.location_lon, dataDetail.location_lat]),
          ),
        }),
      ],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        // Icon style
        const iconStyle = new Style({
          image: new Icon({
            src: '/img/map-marker.svg',
          }),
        });

        // Text style
        const textStyle = new Style({
          text: new Text({
            font: '12px Arial',
            offsetY: 0, // Adjust this value to change the vertical position of the number relative to the icon
            fill: new Fill({ color: '#fff' }),
            padding: [4, 4, 4, 4], // Add some padding to the background
          }),
        });

        // Use an array to combine both styles
        return [iconStyle, textStyle];
      },
    });

    // ...

    // Create the map
    const map = new Map({
      target: 'minimap',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([dataDetail.location_lon, dataDetail.location_lat]),
        zoom: 13,
      }),
    });

    // Event listener for marker click
    map.on('click', (evt) => {
      // open new tab google maps
      window.open(
        `https://www.google.com/maps/search/${Number.parseFloat(
          data.location_lat,
        ).toFixed(5)},${Number.parseFloat(data.location_lon).toFixed(5)}`,
      );
    });

    // Clean up the map when the component unmounts
    return () => map.setTarget(null);
  }, [data]);

  const selectActivity = (values) => {
    setData((prev) => ({ ...prev, fat_id: values.value }));
  };

  const handleSubmit = (e) => {
    const { emp_id } = ls.get('user_cred');
    const userid = ls.get('user_cred').usr_id;
    let error = false;
    let isEdit = true;
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    formData.append('location_lat', data.location_lat);
    formData.append('location_lon', data.location_lon);
    formData.append('location_address', data.location_address);
    formData.append('fat_id', data.fat_id);
    formData.append('id_deleted', data.id_deleted);
    formData.append('emp_id', emp_id);
    formData.append('userid', userid);

    setData((prev) => ({
      ...prev,
      edit: true,
    }));

    if (page == 'new') {
      if (!data.files) {
        error = true;
        setTouched(true);
      } else {
        error = false;
        setTouched(false);
        for (let i = 0; i < data.files.length; i++) {
          formData.append(`file${i}`, data.files[i]);
        }
      }
    } else {
      if (data.files) {
        for (let i = 0; i < data.files.length; i++) {
          formData.append(`file${i}`, data.files[i]);
        }
      }
    }

    if (data.edit == false) {
      isEdit = false;
    }

    if (data.fat_id === '' || typeof data.fat_id === 'undefined') {
      error = true;
      setTouched(true);
    }
    if (
      data.location_address === '' ||
      typeof data.location_address === 'undefined'
    ) {
      error = true;
      setTouched(true);
    }
    if (error === false && isEdit == true) {
      let url = ``;
      if (page == 'new') {
        url = `${auth.apiws}/facactivity/saveItem`;
      } else {
        url = `${auth.apiws}/facactivity/updateItem/${dataDetail.fac_id}`;
      }
      const config = {
        headers: {
          Authorization: `bearer ${auth.token}`,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data.status == 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            toggleOffcanvas();
            getActivity(emp_id);
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const refCanvas = useRef();
  return (
    <>
      <div
        ref={refCanvas}
        className="offcanvas fade show"
        style={{
          opacity: isOpen ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          zIndex: isOpen ? 999 : -999,
        }}
        onClick={() => {
          refCanvas.current.style.opacity = 0;
          setTimeout(() => toggleOffcanvas(), 1000);
        }}
      >
        <div className="offcanvas-dialog" onClick={(e) => e.stopPropagation()}>
          <div className="offcanvas-header">
            {page == 'new' && (
              <span className="off-title">{t('LBL.NEWACTIVITY')}</span>
            )}
            {page == 'detail' && (
              <span className="off-title">{t('LBL.DETAILACTIVITY')}</span>
            )}
            {page == 'edit' && (
              <span className="off-title">{t('LBL.EDITACTIVITY')}</span>
            )}
            <button
              className="close"
              onClick={() => {
                refCanvas.current.style.opacity = 0;
                setTimeout(() => toggleOffcanvas(), 1000);
              }}
            >
              &times;
            </button>
          </div>
          <div className="offcanvas-body">
            <form method="post" className="form" onSubmit={handleSubmit}>
              {page != 'new' && (
                <div id="minimap" className="minimap-container" />
              )}
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.LOCATIONNAME')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="activity_location"
                    component={renderTextInput}
                    clearable
                    type="text"
                    props={{
                      disableTouched: touched,
                      value:
                        page == 'detail'
                          ? dataDetail?.activity_location == ''
                            ? 'Loading...'
                            : dataDetail?.activity_location
                          : data?.activity_location ?? '',
                    }}
                    disabled={page == 'detail'}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ACTIVITYTYPE')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="activity"
                    component={renderSelectField}
                    type="text"
                    options={actType}
                    placeholder="Select Activity"
                    onChange={selectActivity}
                    props={{
                      disableTouched: touched,
                      value:
                        page == 'detail'
                          ? dataDetail?.activity_type == ''
                            ? 'Loading...'
                            : dataDetail?.activity_type
                          : data?.fat_id ?? '',
                    }}
                    disabled={page == 'detail'}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ACTIVITYDESC')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="activity_description"
                    component={renderTextInput}
                    clearable
                    type="textarea"
                    props={{
                      disableTouched: touched,
                      value:
                        page == 'detail'
                          ? dataDetail?.activity_description == ''
                            ? 'Loading...'
                            : dataDetail?.activity_description
                          : data?.activity_description ?? '',
                    }}
                    disabled={page == 'detail'}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ACTIVITYPROOF')}
                </span>
                {page == 'new' ? (
                  <PhotoUploader
                    setData={setData}
                    data={data}
                    touched={touched}
                    page={page}
                    t={t}
                  />
                ) : (
                  <PhotoSlider
                    images={dataDetail.images}
                    page={page}
                    data={data}
                    setData={setData}
                    dataDetail={dataDetail}
                    setDataDetail={setDataDetail}
                    t={t}
                  />
                )}
              </div>

              <div className="d-flex w-100">
                <div className="p-2 w-100 d-flex align-items-center">
                  <div className="location">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ color: '#1974ef', width: '13px' }}
                    />
                    <span style={{ paddingLeft: '5px' }}>
                      {t('LBL.GEOLOCATION')}
                    </span>
                  </div>
                </div>
                <div className="p-2 flex-shrink-1">
                  <div className="fetching">
                    {location && data.location_address ? (
                      <FontAwesomeIcon
                        icon={faSyncAlt}
                        style={{ color: '#1974ef', width: '13px' }}
                        onClick={refreshLocation}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSyncAlt}
                        style={{ color: 'grey', width: '13px' }}
                        className="fetching-img rotate-animation"
                        onClick={refreshLocation}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="locationPlace"
                    component={renderTextInput}
                    type="textarea"
                    input={
                      page != 'new'
                        ? {
                            value: dataDetail.location_address
                              ? dataDetail.location_address
                              : 'Loading...',
                          }
                        : {
                            value:
                              data.location_address && location
                                ? data.location_address
                                : 'Loading...',
                          }
                    }
                    disabled={true}
                    props={{ disableTouched: touched }}
                  />
                </div>
              </div>
              {page == 'new' ? (
                <div className="offcanvas-footer">
                  <div className="offcanvas-footer-btn-group">
                    <button type="button" onClick={toggleOffcanvas}>
                      {t('LBL.CANCEL')}
                    </button>
                    <button type="submit" className="add">
                      {t('LBL.RECORDACTIVITY')}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {page != 'edit' ? (
                    <div className="offcanvas-footer">
                      <div className="offcanvas-footer-btn-group">
                        <button type="button" onClick={toggleOffcanvas}>
                          {t('LBL.CANCEL')}
                        </button>
                        {dataDetail.emp_id == ls.get('user_cred').emp_id && (
                          <button
                            type="button"
                            className="edit"
                            onClick={() => toggleOffcanvas('edit')}
                          >
                            {t('LBL.EDITACTIVITY')}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="offcanvas-footer">
                      <div className="offcanvas-footer-btn-group">
                        <button type="button" onClick={toggleOffcanvas}>
                          {t('LBL.CANCEL')}
                        </button>
                        <button type="submit" className="update">
                          {t('LBL.RECORDACTIVITY')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const FieldSidebar = reduxForm({
  form: 'fieldActivityForm', // Give a unique name to your form
  validate,
})(FieldSidebarForm);

const PhotoUploader = ({ setData, data, touched, page, t }) => {
  const [selectedImage, setSelectedImage] = useState([]);
  const fileInputRef = useRef();

  const handleFileChange = (event) => {
    const reader = new FileReader();
    const files = event?.target?.files;
    const allowedType = ['image/gif', 'image/jpeg', 'image/png'];
    if (files) {
      const fileType = files[0].type;
      const fileSize = files[0].size;
      const allowedSize = 5000; // in kilobytes
      if (!allowedType.includes(fileType)) {
        Swal.fire({
          title: 'Warning',
          text: 'File type not allowed!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }
      if (Math.ceil(fileSize / 1000) > allowedSize) {
        Swal.fire({
          title: 'Warning',
          text: 'Maximum file size 5 MB!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      let images = data?.files ?? [];

      for (let file of event.target.files) images.push(file);

      setSelectedImage([
        ...selectedImage,
        { url: URL.createObjectURL(files[0]), id: `new_${Math.random()}` },
      ]);

      setData((prev) => ({
        ...prev,
        files: images,
      }));
    } else {
      showNotifications('Photo', 'Must Insert Photo', 'warning');
    }
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {selectedImage.length == 5 && selectedImage.length != 0 ? (
        <PhotoSlider
          images={selectedImage}
          setSelectedImage={setSelectedImage}
          setData={setData}
          data={data}
          page={page}
          t={t}
        />
      ) : (
        <>
          {selectedImage.length > 0 && (
            <PhotoSlider
              images={selectedImage}
              setSelectedImage={setSelectedImage}
              setData={setData}
              data={data}
              page={page}
              t={t}
            />
          )}
          <button
            type="button"
            onClick={handleChooseFile}
            className="btn-uploader"
          >
            <img src="img/photo-uploader-icon.svg" alt="Choose Photo" />
          </button>
          <span className="upload-caption">{t('LBL.INSERTPHOTO')}</span>
          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {touched === true && selectedImage.length === 0 ? (
              <span className="form__form-group-error">
                Photo shouldn't be empty
              </span>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  );
};

const PhotoSlider = ({
  images,
  page,
  data,
  setData = () => {},
  setDataDetail = () => {},
  setSelectedImage = () => {},
  t,
}) => {
  const [modal, setModal] = useState(false);
  const fileInputRef = useRef();
  const [index, setIndex] = useState(0);
  const testRef = React.createRef();

  useEffect(() => {
    testRef.current?.goToSlide(index, true);
  }, [index]);

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };
  const toggle = (image, index) => {
    setModal(!modal);
    setIndex(index);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const allowedType = ['image/gif', 'image/jpeg', 'image/png'];
    if (files) {
      const fileType = files[0].type;
      const fileSize = files[0].size;
      const allowedSize = 5000; // in kilobytes
      if (!allowedType.includes(fileType)) {
        Swal.fire({
          title: 'Warning',
          text: 'File type not allowed!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }
      if (Math.ceil(fileSize / 1000) > allowedSize) {
        Swal.fire({
          title: 'Warning',
          text: 'Maximum file size 5 MB!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      let images = data?.files ?? [];

      for (let file of event.target.files) images.push(file);

      setData((prev) => ({
        ...prev,
        files: images,
      }));

      if (page == 'edit') {
        setDataDetail((prev) => ({
          ...prev,
          images: prev.images.concat({
            url: URL.createObjectURL(files[0]),
            id: `new_${Math.random()}`,
          }),
        }));
      }
    }
  };

  const deleteItem = (item) => {
    let deletedIndex = null;
    let deletedIndexNew = null;
    let dataFiles = data.files;
    const dataFilter = images.filter((i) => i.id !== item.id);
    const dataFilterNew = images.filter((file) =>
      new RegExp(/new/gi).exec(file.id),
    );

    images.map((image, index) => {
      if (image.id == item.id) {
        deletedIndex = index;
        return true;
      }
    });

    if (typeof item.id == 'number') {
      setData((prev) => ({
        ...prev,
        id_deleted: prev.id_deleted?.concat(item.id),
      }));
    }

    dataFilterNew.map((image, index) => {
      if (image.id == item.id) {
        deletedIndexNew = index;
        return true;
      }
    });

    if (page == 'edit') {
      setDataDetail((prev) => ({
        ...prev,
        images: dataFilter,
      }));
    } else {
      setSelectedImage(dataFilter);
    }

    dataFiles?.splice(deletedIndexNew ?? deletedIndex, 1);

    setData((prev) => ({
      ...prev,
      files: dataFiles,
    }));
  };

  return (
    <div className="photo-slider-container ">
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container non-modal"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 3,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {images?.map((image, index) => (
          <div key={index} className="position-relative">
            <img
              onClick={() => toggle(image, index)}
              src={image?.url ?? image.url}
              style={{
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
            {page == 'edit' && image != images[0] && (
              <button
                type="button"
                className="remove-img-btn position-absolute"
                style={{
                  top: '3%',
                  right: '2%',
                  border: 'none',
                  background: '#fff',
                  fill: '#D84531',
                  borderRadius: '4px',
                  padding: '2px 5px ',
                }}
                onClick={() => deleteItem(image)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                  style={{ fontSize: '10px' }}
                >
                  <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                </svg>
              </button>
            )}
            {page == 'new' && (
              <button
                type="button"
                className="remove-img-btn position-absolute"
                style={{
                  top: '3%',
                  right: '2%',
                  border: 'none',
                  background: '#fff',
                  fill: '#D84531',
                  borderRadius: '4px',
                  padding: '2px 5px ',
                }}
                onClick={() => deleteItem(image)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                  style={{ fontSize: '10px' }}
                >
                  <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                </svg>
              </button>
            )}
          </div>
        ))}
      </Carousel>
      {page == 'edit' && (
        <>
          <button
            type="button"
            style={{
              border: 'none',
              borderRadius: '6px',
              background: '#f5f9ff',
              marginTop: '10px',
            }}
            onClick={handleChooseFile}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <img
              src="img/icon-photo-upload.svg"
              alt=""
              style={{ height: '20px' }}
            />
            <span
              style={{
                fontSize: '10px',
                color: '#1974ef',
                paddingLeft: '5px',
              }}
            >
              {t('LBL.INSERTPHOTO')}
            </span>
          </button>
        </>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('LBL.PHOTO')}</ModalHeader>
        <ModalBody>
          {/* Use the selectedImage data here */}
          <Carousel
            ref={testRef}
            containerClass="container modal"
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
              },
            }}
          >
            {images?.map((image, i) => (
              <div onClick={() => toggle(image)}>
                <img src={image?.url ? image.url : ''} />
              </div>
            ))}
          </Carousel>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
})(translate('global')(FieldActivityBottom));
