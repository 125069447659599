import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import DataTablePagination from '../../../../shared/components/DataTable/DataTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import { Field, reduxForm } from 'redux-form';
import {
  faBullseye,
  faKey,
  faSearch,
} from '@fortawesome/fontawesome-free-solid';
import renderTextInput from '../../../../shared/components/form/TextInput';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';

const ModalProgress = ({ modal, toggle, progressType, filter }) => {
  const apiws = ls.get('ws_ip');
  const token = ls.get('token');

  const [data, setData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    page: 1,
    column: '',
    sortDirection: '',
    query: '',
    trigger: false,
  });

  const columns = [
    {
      name: 'Title',
      selector: (row) => (
        <div className="gap-2">
          <FontAwesomeIcon
            icon={row.ors_id == null ? faBullseye : faKey}
            className="text-primary mx-3"
          />{' '}
          {row.title}
        </div>
      ),
      sortable: true,
      sortField: 'title',
    },
    {
      name: 'Align To',
      selector: (row) => row.align_name ?? '-',
      sortable: true,
      sortField: 'align_name',
    },
    {
      name: 'Owner',
      selector: (row) => row.owner,
      sortable: true,
      sortField: 'owner',
    },
    {
      name: 'Team',
      selector: (row) => row.team,
      sortable: true,
      sortField: 'team',
    },
    {
      name: 'Progress (%)',
      selector: (row) => <span>{row.achievement_percentage ?? 0}%</span>,
      sortable: true,
      sortField: 'achievement_percentage',
    },
    {
      name: 'Status',
      selector: (row) => row.status_name,
      sortable: true,
      sortField: 'status_name',
    },
  ];

  const initDataOkr = () => {
    const form = new FormData();

    form.append('query', data.query);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));
    form.append('type', progressType);

    axios
      .post(`${apiws}/okrobjective/getOkrProgress`, form, {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          setData((prev) => ({
            ...prev,
            data: response.data.data,
            totalRows: response.data.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'to',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    pagination: true,
  };

  useEffect(() => {
    initDataOkr();
  }, [progressType]);

  return (
    <>
      <Modal className="modal-dahboard-okr" isOpen={modal} toggle={() => toggle('progress')}>
        <ModalHeader toggle={() => toggle('progress')}>
          Progress Distribution
        </ModalHeader>
        <ModalBody className="py-4 px-3">
          <Col md={4} className="search-table">
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    name="align"
                    component={renderTextInput}
                    placeholder="Enter Key Result Title"
                    type="text"
                    onChange={(el) => {
                      if (el.currentTarget.value == '') {
                        setData((prev) => ({ ...prev, query: '' }));
                      } else {
                        setData((prev) => ({
                          ...prev,
                          query: el.currentTarget.value,
                        }));
                      }

                      initDataOkr();
                    }}
                  />
                  <div className="form__form-group-icon">
                    <span class="pl-1 pr-1">
                      <FontAwesomeIcon color="black" icon={faSearch} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <DataTable
            columns={columns}
            data={data.data}
            direction="auto"
            responsive
            striped
            customStyles={customStyles}
            paginationComponent={DataTablePagination}
            paginationServer
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationTotalRows={data.totalRows}
            onChangeRowsPerPage={(newPerPage, page) => {
              const form = new FormData();

              form.append('query', data.query);
              form.append('page', page);
              form.append('per_page', newPerPage);
              form.append('sort', data.column || 'title');
              form.append('order', data.sortDirection || 'asc');
              form.append('filter', JSON.stringify(filter));
              form.append('type', progressType);

              axios
                .post(`${ls.get('ws_ip')}/okrobjective/getOkrProgress`, form, {
                  headers: {
                    Authorization: `bearer ${ls.get('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then((res) => {
                  setData({
                    ...data,
                    data: res.data.data,
                    perPage: newPerPage,
                    page,
                    totalRows: res.data.total,
                  });
                  // setTotalRows(res.data.total);
                });
              // setDatatable({ ...datatable, loading: false });
            }}
            onChangePage={(page) => {
              const form = new FormData();

              form.append('query', data.query);
              form.append('page', page);
              form.append('per_page', data.perPage);
              form.append('sort', data.column || 'title');
              form.append('order', data.sortDirection || 'asc');
              form.append('filter', JSON.stringify(filter));
              form.append('type', progressType);

              axios
                .post(`${ls.get('ws_ip')}/okrobjective/getOkrProgress`, form, {
                  headers: {
                    Authorization: `bearer ${ls.get('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then((res) => {
                  setData({
                    ...data,
                    data: res.data.data,
                    page,
                    totalRows: res.data.total,
                  });
                  // setTotalRows(res.data.total);
                });
              // setDatatable({ ...datatable, loading: false });
            }}
            sortServer
            onSort={(col, sort) => {
              const form = new FormData();

              form.append('query', data.query);
              form.append('page', data.page);
              form.append('per_page', data.perPage);
              form.append('sort', col.sortField);
              form.append('order', sort);
              form.append('filter', JSON.stringify(filter));
              form.append('type', progressType);

              axios
                .post(`${ls.get('ws_ip')}/okrobjective/getOkrProgress`, form, {
                  headers: {
                    Authorization: `bearer ${ls.get('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then((res) => {
                  setData({
                    ...data,
                    data: res.data.data,
                    column: col.sortField,
                    sortDirection: sort,
                    totalRows: res.data.total,
                  });
                });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle('progress')}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '100px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: '100px',
    },
  },
};

export default reduxForm({
  destroyOnUnmount: true,
})(translate('global')(ModalProgress));
