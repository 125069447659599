/* eslint-disable no-mixed-operators, max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

class InputMask extends PureComponent {
    static propTypes = {
      input: PropTypes.shape().isRequired,
      placeholder: PropTypes.string,
      disabled: PropTypes.bool,
      type: PropTypes.string,
      mask: PropTypes.func.isRequired,
      guide: PropTypes.bool,
      meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
      }),
    };


    static defaultProps = {
      placeholder: '',
      disabled: false,
      guide: true,
      meta: null,
      type: 'text',
    };

    render() {
      const {
        input, placeholder, disabled, type, mask, guide, meta: { touched, error },
      } = this.props;
      if (type === 'textemployee') {
        return (
          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
            <MaskedInput {...input} disabled={disabled} placeholder={placeholder} type={type} mask={mask} guide={guide} />
            {touched || error && <span className="form__form-group-error">{error}</span>}
          </div>
        );
      }
      return (
        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
          <MaskedInput {...input} disabled={disabled} placeholder={placeholder} type={type} mask={mask} guide={guide} />
          {touched && error && <span className="form__form-group-error">{error}</span>}
        </div>
      );
    }
}


export default InputMask;
