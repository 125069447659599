/* eslint-disable react/forbid-prop-types */
/*
 * Page News & Updates Preferences
 * Notes: Master Data News & Updates
 * @author Fakhrurrozi
 * @date 12/04/2021
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  ButtonToolbar,
} from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../../Preferences/notificationMessages';
// import showNotifications from '../show';
import PropTypes from 'prop-types';
import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import utils from '../../../../utils';
import { Spinner } from 'react-bootstrap';

class CompanyDataPage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    rtbcompany: PropTypes.func.isRequired,
    rtboffice: PropTypes.func.isRequired,
    rtbgeofence: PropTypes.func.isRequired,
    rtborgjobband: PropTypes.func.isRequired,
    rtborgjoblevel: PropTypes.func.isRequired,
    rtborgjobname: PropTypes.func.isRequired,
    rtborgposition: PropTypes.func.isRequired,
    rtborgjobgrade: PropTypes.func.isRequired,
    rtborgjobbandgrade: PropTypes.func.isRequired,
    rtbattshift: PropTypes.func.isRequired,
    rtborgorganization: PropTypes.func.isRequired,
    emphierarchy: PropTypes.func.isRequired,
    tempform: PropTypes.func.isRequired,
    rtbemployeementstatus: PropTypes.func.isRequired,
    getJobName: PropTypes.func.isRequired,
    getJobTitle: PropTypes.func.isRequired,
    getJobGrade: PropTypes.func.isRequired,
    getSuperordinat: PropTypes.func.isRequired,
    parmid: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('FRM.TITLE') },
      { '2': t('LBL.DESCRIPTION') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['10%', '25%', '45%', '20%'],
      title: '',
      errorTitle: 'none',
      description: '',
      errorDescription: 'none',
      attachment: '',
      tableInput: 'none',
      tableData: '',
      statusEdit: false,
      disableButton: false,
      disableLng: false,
      arrLng: [],
      modal: false,
      action: '',
      displaySpinner: 'none',
      displaySave: '',
      touched: false,
      errors_ogf_id: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.loadEmployee = this.loadEmployee.bind(this);
    this.getSuperordinat = this.getSuperordinat.bind(this);
    this.getJobName = this.getJobName.bind(this);
    this.getJobGrade = this.getJobGrade.bind(this);
    this.getJobTitle = this.getJobTitle.bind(this);
    // this.dataCompany = this.dataCompany.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { tempform } = props;
    if (props.tempform.length === undefined && state.tempForm === undefined) {
      props.initialize({ ...tempform, join_date: moment(tempform.join_date) });
      return {
        tempForm: props.tempform,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    // this.setState({ idUser: ls.get('user_cred').usr_id }, async () => {
    //   await this.loadEmployee(ls.get('user_cred').emp_id);
    // });
    this.setState({ idUser: ls.get('user_cred').usr_id });
  };

  async loadEmployee(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(`${apiws}/employee/getAllItemById/${id}`, '', config)
        .then((response) => {
          // prompt('', JSON.stringify(response));
          const dataResponse = response.data.data;
          // console.log(JSON.stringify(dataResponse));
          if (dataResponse.superordinat.length !== 0) {
            this.setState({
              superordinat: dataResponse.superordinat[0].emp_id,
            });
          } else {
            this.setState({ superordinat: '' });
          }
          const datamaping = {
            ...dataResponse.user[0],
            ogf_id: dataResponse.geofence.map((item) => {
              return item.ogf_id;
            }),
            emp_parent: this.state.superordinat,
            join_date: moment(dataResponse.user[0].join_date),
          };
          this.getJobName({ value: `${dataResponse.user[0].org_id}` });
          this.setState({ orgId: dataResponse.user[0].org_id });
          this.getJobTitle({ value: `${dataResponse.user[0].jbn_id}` });
          this.getJobGrade({ value: `${dataResponse.user[0].jbd_id}` });
          this.getSuperordinat({ value: `${dataResponse.user[0].pos_id}` });
          this.setState({ tempForm: datamaping });
          this.props.initialize(datamaping);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  // async dataCompany() {
  //   const { tempform } = this.props;
  //   const datamaping = ({
  //     off_id: tempform.off_id,
  //     ogf_id: tempform.ogf_id,
  //     org_id: tempform.org_id,
  //     jbn_id: tempform.jbn_id,
  //     pos_id: tempform.pos_id,
  //     jbd_id: tempform.jbd_id,
  //     jgd_id: tempform.jgd_id,
  //     emp_parent: tempform.emp_parent,
  //     join_date: tempform.join_date,
  //     ats_id: tempform.ats_id,
  //   });
  //   this.setState(tempform);
  //   this.props.initialize(datamaping);
  // }

  async handleSubmit(values) {
    const { apiws, token, idUser, tempForm } = this.state;

    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    // console.log(config);
    // console.log(url);
    // console.log(dataInput.get('attachment'));
    let ogf_id = [];
    let emp_parent;
    // console.log(values.ogf_id);
    if (values.ogf_id.length) {
      values?.ogf_id?.map((item) => {
        if (item.value) {
          ogf_id.push(item.value);
        } else {
          ogf_id = values.ogf_id;
        }
      });
    }

    const ats_id =
      typeof values.ats_id === 'object' ? values.ats_id.value : values.ats_id;
    const off_id =
      typeof values.off_id === 'object' ? values.off_id.value : values.off_id;
    const org_id =
      typeof values.org_id === 'object' ? values.org_id.value : values.org_id;
    const jbn_id =
      typeof values.jbn_id === 'object' ? values.jbn_id.value : values.jbn_id;
    // const jbg_id = (typeof values.jbg_id === 'object') ? values.jbg_id.value : values.jbg_id;
    const pos_id =
      typeof values.pos_id === 'object' ? values.pos_id.value : values.pos_id;
    const ems_id =
      typeof values.ems_id === 'object' ? values.ems_id.value : values.ems_id;
    const jbd_id =
      typeof values.jbd_id === 'object' ? values.jbd_id.value : values.jbd_id;
    const jgd_id =
      typeof values.jgd_id === 'object' ? values.jgd_id.value : values.jgd_id;
    // if (typeof values.ogf_id !== 'undefined') {
    //   if (values.ogf_id !== null) {
    //     ogf_id = (typeof values.ogf_id === 'object') ? values.ogf_id.value : values.ogf_id;
    //   } else {
    //     ogf_id = '';
    //   }
    // } else {
    //   ogf_id = '';
    // }
    if (typeof values.emp_parent !== 'undefined') {
      if (values.emp_parent !== null) {
        emp_parent =
          typeof values.emp_parent === 'object'
            ? values.emp_parent.value
            : values.emp_parent;
      } else {
        emp_parent = '';
      }
    } else {
      emp_parent = '';
    }
    const data = {
      off_id,
      org_id,
      jbn_id,
      pos_id,
      ems_id,
      jbd_id,
      jgd_id,
      join_date: values.join_date.format('YYYY-MM-DD'),
      ogf_id,
      ats_id,
      emp_parent,
      emp_code: this.props.tempform.emp_code,
      muser_id: idUser,
    };
    const id = this.props.tempform.emp_id;
    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    if (this.state.touched == false) {
      await axios
        .post(`${apiws}/employee/updateOD/${id}`, formBody, config)
        .then(async (response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.props.destroy();
            setTimeout(() => {
              this.loadEmployee(id);
              this.setState({ displaySpinner: 'none', displaySave: '' });
              // this.setState({
              //   statusEdit: true,
              // });
            }, 200);
          } else if (response.data.status == 'exist_submission') {
            showNotifications('Save Failed', 'Pending workflow request exists!', 'warning');
            this.setState({ displaySpinner: 'none', displaySave: '' });
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
            this.setState({ displaySpinner: 'none', displaySave: '' });
          }
        })
        .catch(() => {
          showNotifications('Fail', 'Save Failed', 'danger');
        });
    }
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const datatree = [];

    const usrd = ls.get('user_cred').usr_id;
    axios
      .post(`${apiws}/orgoffice/getAll`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          this.setState({ rtboffice: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/geofence/getAll`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ogf_id, label: obj.title }),
          );
          this.setState({ rtbgeofence: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgjobband/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.jbd_id, label: obj.job_band }),
          );
          this.setState({ rtborgjobband: Arr });
        } else {
          // this.setState({ button_disabled: false });
          // showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ rtborgorganization: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/rtbemployeementstatus/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status }),
          );
          this.setState({ rtbemployeementstatus: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/attsetting/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ats_id, label: obj.ats_name }),
          );
          this.setState({ rtbattshift: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSuperordinat(pos_id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const datatree = [];

    const usrd = ls.get('user_cred').usr_id;
    axios
      .post(
        `${apiws}/employee/getAllItemByPosition/${pos_id.value}`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          setTimeout(() => {
            this.setState({ emphierarchy: Arr });
          }, 500);
        } else {
          this.setState({ button_disabled: false });
          // showNotifications('Fail', 'Failed Check', 'danger');
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ button_disabled: false });
      });
  }

  getJobName(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobname/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const jbn = response.data.data;
          const arr = [];
          jbn.map((obj) =>
            arr.push({ value: obj.jbn_id, label: obj.job_name }),
          );
          setTimeout(() => {
            this.setState({
              rtborgjobname: arr,
              orgId: id.value,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobTitle(id) {
    const { orgId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgposition/getAllHierarchy/${orgId}/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const pos = response.data.data;
          const arr = [];
          pos.map((obj) =>
            arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          setTimeout(() => {
            this.setState({
              rtborgposition: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobGrade(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobbandgrade/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const grade = response.data.data;
          const arr = [];
          grade.map((obj) =>
            arr.push({ value: obj.jgd_id, label: obj.job_grade }),
          );
          setTimeout(() => {
            this.setState({
              rtborgjobgrade: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      tableData,
      tableInput,
      disableButton,
      statusEdit,
      errorTitle,
      errorDescription,
      arrLng,
      disableLng,
      displaySave,
      displaySpinner,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.addNews}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('MEN.NEWS_AND_UPDATES')}
      </Button>
    );

    // if (this.state.statusEdit) {
    //   return (<Redirect to={{
    //     pathname: '/administration/employee',
    //     state: { men_id: this.props.parmid.men_employee, fga_id: this.props.parmid.fga_id },
    //   }}
    //   />);
    // }

    // let labelButton;
    // let colorButton;
    // if (statusEdit) {
    //   labelButton = t('FRM.EDIT');
    //   colorButton = 'success';
    // } else {
    //   colorButton = 'primary';
    //   labelButton = t('FRM.SAVE');
    // }
    const colorButton = 'primary';
    const labelButton = t('FRM.SAVE');
    return (
      <Container>
        <Row style={{ display: tableData }}>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 20px 30px 20px' }}>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="newsform"
                  id="newsform"
                >
                  <input type="hidden" name="anu_id" value="" />
                  <Container>
                    <Row>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.OFFICE')}
                          </span>
                          <Field
                            name="off_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtboffice}
                            placeholder="Choose Office"
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.GEOFENCE_AREA')}
                          </span>
                          <Field
                            name="ogf_id"
                            component={renderSelectField}
                            className="custom-multi"
                            type="text"
                            style="styleTop"
                            options={this.props.rtbgeofence}
                            placeholder="Choose Geofence Area (max. 5)"
                            multi="true"
                            onChange={(prev, next) => {
                              if (next.length > 5) {
                                this.setState({
                                  touched: true,
                                  errors_ogf_id: 'Maximum 5 geofence locations',
                                  disableButton: true,
                                });
                              } else if (!next.length) {
                                this.setState({
                                  touched: true,
                                  errors_ogf_id:
                                    'Geofence locations shouldn`t be empty',
                                  disableButton: true,
                                });
                              } else {
                                this.setState({
                                  touched: false,
                                  disableButton: false,
                                });
                              }
                            }}
                            props={{
                              meta: {
                                touched: this.state.touched,
                                error: this.state.errors_ogf_id,
                              },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.UNIT_ORGANIZATION')}
                          </span>

                          <Field
                            name="org_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtborgorganization}
                            onChange={this.props.getJobName}
                            placeholder="Unit Organization"
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_NAME')}
                          </span>
                          <Field
                            name="jbn_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtborgjobname}
                            onChange={this.props.getJobTitle}
                            placeholder="Choose Job Name"
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_TITLE')}
                          </span>
                          <Field
                            name="pos_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtborgposition}
                            onChange={this.props.getSuperordinat}
                            placeholder="Choose Position"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_BAND')}
                          </span>
                          <Field
                            name="jbd_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtborgjobband}
                            onChange={this.props.getJobGrade}
                            placeholder="Choose Job Band"
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.GRADE')}
                          </span>
                          <Field
                            name="jgd_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtborgjobgrade}
                            placeholder="Choose Grade"
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.SUPERORDINAT')}
                          </span>
                          <Field
                            name="emp_parent"
                            component={renderSelectField}
                            type="text"
                            options={this.props.emphierarchy}
                            placeholder="Choose Super Ordinat"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.EMPLOYMENT_STATUS')}
                          </span>
                          <Field
                            name="ems_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtbemployeementstatus}
                            placeholder="Employee Status"
                            disabled="true"
                          />
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOIN_DATE')}
                          </span>
                          {/* <div className="form__form-group-field"> */}
                          <Field
                            name="join_date"
                            component={renderDatePickerField}
                            // props={{
                            //   disableTouched: false,
                            //   value: this.state.data.join_date?._i,
                            // }}
                          />
                          {/* <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div> */}
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ATTENDANCE')}
                          </span>
                          <Field
                            name="ats_id"
                            component={renderSelectField}
                            type="text"
                            options={this.props.rtbattshift}
                            placeholder="Attendance"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
                <ButtonToolbar className="form__button-toolbar">
                  {/* <Button
                    style={{
                      'position': 'absolute',
                      'left': '40%',
                    }}
                    color="secondary"
                  >{t('FRM.CANCEL')}
                  </Button> */}
                  <Link
                    className="link_action_tbl"
                    id="Edit"
                    to={{
                      pathname: '/administration/employee',
                    }}
                    style={{ 'margin-right': '10px' }}
                  >
                    <Button
                      style={{
                        position: 'absolute',
                        left: '40%',
                        display: displaySave,
                      }}
                      color="secondary"
                    >
                      {t('FRM.BACK')}
                    </Button>
                  </Link>
                  <Button
                    style={{
                      position: 'absolute',
                      left: '50%',
                      display: displaySave,
                    }}
                    color={colorButton}
                    form="newsform"
                    type="submit"
                    disabled={disableButton}
                  >
                    {' '}
                    {labelButton}
                  </Button>
                  <Button
                    color="primary"
                    style={{
                      position: 'absolute',
                      left: '50%',
                      display: displaySpinner,
                    }}
                    type="button"
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    {t('FRM.LOADING')}
                  </Button>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    job_level: {
      fieldLabel: 'Job Level',
      required: true,
    },
    cmp_id: {
      fieldLabel: 'Company',
      required: true,
    },
    off_id: {
      fieldLabel: 'Office',
      required: true,
    },
    jbd_id: {
      fieldLabel: 'Job band',
      required: true,
    },
    jbn_id: {
      fieldLabel: 'Job name',
      required: true,
    },
    jgd_id: {
      fieldLabel: 'Job Grade',
      required: true,
    },
    pos_id: {
      fieldLabel: 'Position',
      required: true,
    },
    org_id: {
      fieldLabel: 'Organization',
      required: true,
    },
    join_date: {
      fieldLabel: 'Join date',
      required: true,
    },
    ats_id: {
      fieldLabel: 'Attendance',
      required: true,
    },
    ogf_id: {
      fieldLabel: 'Geofence locations',
      required: true,
    },
    // dyn_identity: {
    //   dynamicField: true,
    //   children: [
    //     { name: 'identity', label: 'Identity', required: true },
    //     { name: 'identity_no', label: 'Identity No', required: true },
    //   ],
    // },
  };
  const errors = utils.validate(validation, values);
  if (values?.ogf_id?.length > 5) {
    errors.ogf_id = 'Maximum 5 geofence locations';
  }
  return errors;
};

export default reduxForm({
  form: 'reduxCompanyloyeeDataPage', // must identik
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(translate('global')(CompanyDataPage));
