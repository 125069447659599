/*
 * Page Attendance Type
 * Notes: Master Data Attendance Type
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Field, reduxForm, reset } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faTrashAlt,
  faPencilAlt,
} from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import { Link } from 'react-router-dom';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import showNotifications from '../../notificationMessages';
import renderChromeColorPickerField from '../../../../shared/components/form/ChromeColorPicker';
import moment from 'moment';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import NumberFormat from '../../../../shared/components/NumberFormat';

let attTypeCode = [];
let attTypeName = [];
class AttendanceTypePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.ATTENDANCE_TYPE_CODE') },
      { '2': t('LBL.ATTENDANCE_TYPE') },
      { '3': t('LBL.COMPONENT') },
      { '4': t('LBL.PAYROLL_CALCULATION') },
      { '5': t('LBL.WEIGHT') },
      { '6': t('LBL.COLOUR') },
      { '7': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      editcond: '',
      addcond: '',
      deletecond: '',
      apiws: '',
      token: '',
      dataWidth: ['5%', '15%', '20%', '15%', '15%', '10%', '10%', '10%'],
      urlData: [],
      statusEdit: false,
      arrAttComponent: [],
      spanPayrollCalculate: 'none',
      order_no: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteAttType = this.deleteAttType.bind(this);
    this.editAttType = this.editAttType.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getAttComponent = this.getAttComponent.bind(this);
    this.getAttType = this.getAttType.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getAttComponent();
      this.getAttType();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/attcode/getAllitem` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.ATTENDANCE_TYPE_CODE') },
      { '2': t('LBL.ATTENDANCE_TYPE') },
      { '3': t('LBL.COMPONENT') },
      { '4': t('LBL.PAYROLL_CALCULATION') },
      { '5': t('LBL.WEIGHT') },
      { '6': t('LBL.COLOUR') },
      { '7': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getAttComponent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/attcomponent/getall`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ dta_component: response.data.data });
          const arr = [];
          this.state.dta_component.map((data) => {
            arr.push({ value: data.atc_id, label: data.atc_component });
            return arr;
          });
          this.setState({
            arrAttComponent: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getAttType() {
    const { token, apiws } = this.state;
    attTypeCode = [];
    attTypeName = [];
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attcode/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          for (let i = 0; i < dataResponse.length; i += 1) {
            attTypeCode[i] = dataResponse[i].ato_code;
            attTypeName[i] = dataResponse[i].ato_value;
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle() {
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
    this.getAttComponent();
    this.getAttType();
  }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.ato_id;
    const attComponent =
      typeof values.atc_id.value === 'undefined'
        ? values.atc_id
        : values.atc_id.value;
    const colorHex =
      typeof values.ato_color === 'undefined' ||
      values.ato_color === '' ||
      values.ato_color === null
        ? ''
        : values.ato_color.hex;
    const colorRgb =
      typeof values.ato_color === 'undefined' ||
      values.ato_color === '' ||
      values.ato_color === null
        ? ''
        : values.ato_color;
    const jsonColoRgb = colorRgb === '' ? '' : JSON.stringify(colorRgb);
    let error = true;
    const errorTypeCode = attTypeCode;
    const errorTypeName = attTypeName;
    if (!values.ato_code) {
      error = false;
    }
    if (!values.ato_value) {
      error = false;
    }
    if (!values.ato_type) {
      error = false;
      this.setState({ spanPayrollCalculate: '' });
    }
    if (errorTypeCode.includes(values.ato_code)) {
      error = false;
    }
    if (errorTypeName.includes(values.ato_value)) {
      error = false;
    }
    let url;
    let dataBody;
    const data = {
      ato_code: values.ato_code,
      ato_value: values.ato_value,
      order_no: values.order_no,
      ato_type: values.ato_type,
      ato_color: colorHex,
      atc_id: attComponent,
      rgb: jsonColoRgb,
      ato_description: values.ato_description,
    };
    if (id === undefined || id === '') {
      url = `${apiws}/attcode/saveItem`;
      dataBody = { ...data, cuser_id: userid };
    } else {
      url = `${apiws}/attcode/updateItem/${id}`;
      dataBody = { ...data, muser_id: userid };
    }
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ modal: false });
            this.child.reload('addedit');
            this.props.destroy();
            this.getAttType();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  editAttType(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attcode/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const idxAttTypeCode = attTypeCode.indexOf(
            getResponseData[0].ato_code,
          );
          if (idxAttTypeCode !== -1) {
            attTypeCode.splice(idxAttTypeCode, 1);
          }
          const idxAttTypeName = attTypeName.indexOf(
            getResponseData[0].ato_value,
          );
          if (idxAttTypeName !== -1) {
            attTypeName.splice(idxAttTypeName, 1);
          }
          const rgbData =
            getResponseData[0].rgb === null
              ? ''
              : JSON.parse(getResponseData[0].rgb);
          const datamaping = {
            ato_id: getResponseData[0].ato_id,
            ato_code: getResponseData[0].ato_code,
            ato_value: getResponseData[0].ato_value,
            order_no: getResponseData[0].order_no,
            atc_id: getResponseData[0].atc_id,
            ato_description: getResponseData[0].ato_description,
            ato_type: getResponseData[0].ato_type,
            ato_color: rgbData,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteAttType(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attcode/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
                this.getAttType();
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const {
      statusEdit,
      urlData,
      arrAttComponent,
      spanPayrollCalculate,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.ATTENDANCE_TYPE')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addjobnameform"
          disabled={pristine}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addjobnameform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ato_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteAttType}
                  updateFunc={this.editAttType}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.ATTENDANCE_TYPE')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addjobnameform"
                  id="addjobnameform"
                >
                  <input type="hidden" name="ato_id" />
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TYPE')} {t('LBL.CODE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ato_code"
                        id="ato_code"
                        component={renderTextInput}
                        type="text"
                        maxLength="23"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TYPE')} {t('LBL.NAME')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ato_value"
                        id="ato_value"
                        component={renderTextInput}
                        type="text"
                        maxLength="50"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.COMPONENT')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atc_id"
                        id="atc_id"
                        component={renderSelectField}
                        type="text"
                        options={arrAttComponent}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.WEIGHT')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="order_no"
                        component={NumberFormat}
                        type="number"
                        props={{
                          props: { maxLength: 4, allowNegative: false },
                        }}
                      />
                      {/* <NumberFormat
                        maxLength="19"
                        style={{ width: '100%', textAlign: 'right' }}
                        type="text"
                        allowNegative={false}
                        value={this.state.order_no}
                      /> */}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DESCRIPTION')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ato_description"
                        id="ato_description"
                        component={renderTextInput}
                        type="textarea"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span
                      className="form__form-group-error"
                      style={{
                        background: '#ffbcbc',
                        borderRadius: '3px',
                        position: 'absolute',
                        padding: '5px 10px 5px 5px',
                        margin: '-25px 0px 0px 140px',
                        display: spanPayrollCalculate,
                      }}
                    >
                      Payroll Calculation field shouldn’t be empty
                    </span>
                    <span className="form__form-group-label">
                      {t('LBL.PAYROLL_CALCULATION')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ato_type"
                        id="ato_type"
                        component={renderRadioButtonField}
                        label="Present"
                        radioValue="1"
                        defaultChecked
                      />
                      <Field
                        name="ato_type"
                        id="ato_type"
                        component={renderRadioButtonField}
                        label="Not Present"
                        radioValue="2"
                      />
                      <Field
                        name="ato_type"
                        id="ato_type"
                        component={renderRadioButtonField}
                        label="Holiday"
                        radioValue="3"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.COLOUR')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ato_color"
                        component={renderChromeColorPickerField}
                      />
                    </div>
                  </div>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">Chrome Color Picker</span>
                    <div className="form__form-group-field">
                      <Field
                        name="chrome_color"
                        component={renderChromeColorPickerField}
                      />
                    </div>
                  </div> */}
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorTypeCode = attTypeCode;
  const errorTypeName = attTypeName;
  if (!values.ato_code) {
    errors.ato_code = 'Attendance type code field shouldn’t be empty';
  } else if (!/^[a-zA-Z\s0-9]*$/i.test(values.ato_code)) {
    errors.ato_code = 'Only text allowed';
  }
  if (!values.ato_value) {
    errors.ato_value = 'Attendance type name field shouldn’t be empty';
  } else if (!/^[a-zA-Z\s0-9]*$/i.test(values.ato_value)) {
    errors.ato_value = 'Only text allowed';
  } else {
    const atc = values.ato_code;
    if (atc.length > 50) {
      errors.ato_code = 'characters cannot be more than 255 digit';
    }
  }
  if (!values.ato_value) {
    errors.ato_value = 'Attendance type name field shouldn’t be empty';
  } else {
    const ato = values.ato_value;
    if (ato.length > 255) {
      errors.ato_code = 'characters cannot be more than 255 digit';
    }
  }
  if (!values.atc_id) {
    errors.atc_id = 'Attendance component field shouldn’t be empty';
  } else {
    const atd = values.atc_id;
    if (atd.length > 255) {
      errors.atc_id = 'characters cannot be more than 255 digit';
    }
  }
  if (!values.order_no) {
    errors.order_no = 'Weight field shouldn’t be empty';
  } else if (!/^[a-zA-Z\s0-9]*$/i.test(values.order_no)) {
    errors.order_no = 'Only text allowed';
  }
  if (!values.ato_description) {
    errors.ato_description = 'Description field shouldn’t be empty';
  } else if (!/^[a-zA-Z\s0-9]*$/i.test(values.ato_description)) {
    errors.ato_description = 'Only text allowed';
  } else {
    const odr = values.order_no;
    if (odr.length > 255) {
      errors.order_no = 'characters cannot be more than 255 digit';
    }
  }
  if (!values.ato_description) {
    errors.ato_description = 'Descrition field shouldn’t be empty';
  } else {
    const atod = values.ato_description;
    if (atod.length > 255) {
      errors.ato_description = 'characters cannot be more than 255 digit';
    }
  }
  if (errorTypeCode.includes(values.ato_code)) {
    errors.ato_code = 'Attendance type code is already taken';
  }
  if (errorTypeName.includes(values.ato_value)) {
    errors.ato_value = 'Attendance type name is already taken';
  }
  return errors;
};

export default reduxForm({
  form: 'formAttendanceType',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AttendanceTypePage));
