/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable quote-props */
/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm, FieldArray, reset, destroy } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import moment from 'moment';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import utils from '../../../../utils';

// let spanError = 'none';
class GroupShiftSetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    // shift: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.GROUP_SHIFT') },
      { '2': t('LBL.REPETITION') },
      { '3': t('FRM.FUNCTION') },
    ];
    this.state = {
      arrShift: [],
      arr_shift: [],
      urlData: '',
      apiws: '',
      token: '',
      statusEdit: false,
      dataWidth: ['5%', '45%', '35%', '15%'],
      editcond: '',
      addcond: '',
      deletecond: '',
    };
    this.handleSubmitGroupshift = this.handleSubmitGroupshift.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderShift = this.renderShift.bind(this);
    this.editGrid = this.editGrid.bind(this);
    this.deleteGrid = this.deleteGrid.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState(
      { urlData: `${ls.get('ws_ip')}/attshiftgroup/getAllitem` },
      () => {
        this.getTableData();
        this.getButton();
      },
    );
    this.loadSelectBox();
    this.getShift();
  }

  componentDidMount = () => {
    this.props.initialize({
      group_shift_name: '',
      dyn_shift: [{ shift_day_input: 1 }],
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.GROUP_SHIFT') },
      { '2': t('LBL.REPETITION') },
      { '3': t('FRM.FUNCTION') },
    ];
    this.getShift();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getShift() {
    const formBody = '';
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/attshift/getAll/`;
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ arr_shift: response.data.data });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  toggle(x) {
    if (x === 'add' || x === 'cancel') {
      this.setState({ statusEdit: false });
      this.setState({ modal: !this.state.modal });
      const datamaping = {
        group_shift_name: '',
        dyn_shift: [{ shift_day_input: '1' }],
      };
      this.props.initialize(datamaping);
    }
    this.loadSelectBox();
  }

  deleteGrid(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attshiftgroup/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.getTableData();
              }, 200);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  editGrid(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attshiftgroup/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ modal: !this.state.modal });
          this.setState({ statusEdit: true });
          const daytypearr = [];
          const getResponseData = response.data.data;
          getResponseData.forEach((element) => {
            daytypearr.push({
              shift_day_input: element.day_no,
              group_shift_type: element.ath_id,
              shift_start_time: element.working_day_start,
              shift_end_time: element.working_day_end,
            });
          });
          const datamaping = {
            atg_id: getResponseData[0].atg_id,
            group_shift_name: getResponseData[0].shift_group,
            dyn_shift: daytypearr,
          };
          this.props.destroy();
          this.props.initialize(datamaping);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  loadSelectBox() {
    const formBody = '';
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/attshift/getAll`;
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ data_shift: response.data.data });
          const arr = [];
          this.state.data_shift.map((data) => {
            arr.push({ value: data.ath_id, label: data.shift });
            return arr;
          });
          this.setState({ arrShift: arr });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  handleSubmitGroupshift(values) {
    let url;
    let data;
    const id = values.atg_id;
    const ws_ip = ls.get('ws_ip');
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let error = true;
    if (!values.group_shift_name) {
      error = false;
    }
    if (typeof values.dyn_shift[0].shift_day_input === 'undefined') {
      // spanError = '';
      error = false;
    }
    if (typeof values.dyn_shift[0].group_shift_type === 'undefined') {
      // spanError = '';
      error = false;
    }
    if (id === undefined || id === '') {
      data = { ...values, cuser_id: userid };
      url = `${ws_ip}/attshiftgroup/saveItem`;
    } else {
      data = { ...values, muser_id: userid };
      url = `${ws_ip}/attshiftgroup/updateItem/${id}`;
    }
    data = {
      data: JSON.stringify(data),
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ modal: !this.state.modal });
            this.props.destroy();
            this.getTableData();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  renderShift = ({ fields, meta: { error }, arrShift, arr_shift }) => (
    <div>
      {fields.map((shiftinput, index) => (
        <Row className="form__form-group">
          <Col md={2} xs={12}>
            <Field
              name={`${shiftinput}.shift_day_input`}
              component={renderTextInput}
              type="number"
              className="input-horizontal"
              placeholder={this.props.t('LBL.DAYS')}
              readOnly
            />
          </Col>
          <Col md={3} xs={12}>
            <Field
              name={`${shiftinput}.group_shift_type`}
              component={renderSelectField}
              type="text"
              options={arrShift}
              onChange={(e) => {
                const getTime = arr_shift.filter(
                  ({ ath_id }) => ath_id === e.value,
                )[0];
                // const current = fields.get(index);
                if (typeof getTime !== 'undefined') {
                  fields.get(index).shift_start_time =
                    getTime.working_day_start;
                  fields.get(index).shift_end_time = getTime.working_day_end;
                } else {
                  fields.get(index).shift_start_time = '';
                  fields.get(index).shift_end_time = '';
                }
              }}
              placeholder={this.props.t('LBL.SHIFT')}
            />
          </Col>
          <Col md={2} xs={12}>
            <Field
              name={`${shiftinput}.shift_start_time`}
              component={renderTextInput}
              type="text"
              className="input-horizontal"
              disabled="true"
            />
          </Col>
          <Col md={2} xs={12}>
            <Field
              name={`${shiftinput}.shift_end_time`}
              component={renderTextInput}
              type="text"
              className="input-horizontal"
              disabled="true"
            />
          </Col>
          {index === 0 ? (
            <Col md={3} sm={3}>
              <Button
                className="add_btn_identity"
                onClick={() =>
                  fields.push({ shift_day_input: fields.length + 1 })
                }
                color="primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          ) : (
            <Col md={3} sm={3}>
              <Button
                className="add_btn_identity"
                onClick={() => {
                  // eslint-disable-next-line no-plusplus
                  for (let fi = index; fi < fields.length; fi++) {
                    fields.get(fi).shift_day_input -= 1;
                  }
                  fields.remove(index);
                }}
                color="danger"
                size="sm"
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </Col>
          )}
        </Row>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );

  render() {
    const { arrShift, urlData, arr_shift, statusEdit } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let buttonColor;
    let buttonText;
    let disabledButton;
    if (statusEdit) {
      buttonColor = 'success';
      buttonText = 'Edit';
      disabledButton = pristine;
    } else {
      buttonColor = 'primary';
      buttonText = 'Save';
      disabledButton = pristine;
    }
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => {
          this.toggle('add');
        }}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.GROUP_SHIFT')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      marginTop: '95px',
      textAlign: 'center',
    };
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="atg_id"
                  deleteFunc={this.deleteGrid}
                  updateFunc={this.editGrid}
                  widthTable={this.state.dataWidth}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={modalStyle}
        >
          <ModalHeader>
            {' '}
            {t('FRM.ADD')} {t('LBL.GROUP_SHIFT')}{' '}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmitGroupshift)}
              name="groupshiftinput"
              id="groupshiftinput"
            >
              <input type="hidden" name="atg_id" />
              <Col md={7} xs={7} lg={7} xl={7}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.NAME')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="group_shift_name"
                      type="text"
                      component={renderTextInput}
                    />
                  </div>
                </div>
              </Col>
              {/* <Col md={7} xs={7} lg={7} xl={7}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.REPETITION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="input_days"
                        type="number"
                        component={renderTextInput}
                      />
                      <p className="label-form-right">{t('LBL.DAYS')}</p>
                    </div>
                  </div>
                </Col>
                <hr /> */}
              <Col md={12} xs={12} lg={12} xl={12}>
                <div className="form__form-group">
                  {/* <span
                      className="form__form-group-error"
                      style={
                        {
                          background: '#ffbcbc',
                          borderRadius: '3px',
                          position: 'absolute',
                          padding: '5px 10px 5px 5px',
                          margin: '-25px 0px 0px 140px',
                          // display: spanError,
                        }
                      }
                    >
                      Group Shift field shouldn’t be empty
                    </span> */}
                  <span className="form__form-group-label">
                    {' '}
                    {t('LBL.GROUP_SHIFT')}
                  </span>
                  <div className="form__form-group-field time-interval-form">
                    <div className="form-container">
                      <FieldArray
                        name="dyn_shift"
                        component={this.renderShift}
                        props={{ arrShift, arr_shift }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                this.toggle('cancel');
              }}
            >
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color={buttonColor}
              type="submit"
              form="groupshiftinput"
              disabled={disabledButton}
            >
              {' '}
              {buttonText}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// async function submit(values) {
//   await sleep(500);
//   const data = {
//     data: JSON.stringify(values),
//   };
//   const formBody = Object.keys(data).map(key =>
//     `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
//   const config = {
//     headers: {
//       // eslint-disable-next-line quote-props
//       'Authorization': `bearer ${ls.get('token')}`,
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//   };
//   const ws_ip = ls.get('ws_ip');
//   const url = `${ws_ip}/attshiftgroup/saveItem`;
//   axios.post(
//     url,
//     formBody,
//     config,
//   ).then((response) => {
//     if (response.data.status === 'ok') {
//       // this.setState({ button_disabled: false });
//       showNotifications('Success', 'Save Success', 'success');
//       this.props.destroy();
//     } else {
//       // this.setState({ button_disabled: false });
//       showNotifications('Fail', 'Save Failed', 'danger');
//     }
//   }).catch(() => {
//     // console.log(error);
//     // this.setState({ button_disabled: false });
//   });
//   // window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
// }
// const datainit = {
//   group_shift_name: '',
//   input_days: '',
//   dyn_identity: [{}],
// };

// function loadaftersubmit(result, dispatch, props) {
//   dispatch(reset('groupshiftform'));
// }

const validate = (values) => {
  // const errorsShiftExist = shiftExist;
  const validation = {
    dyn_shift: {
      dynamicField: true,
      children: [
        { name: 'shift_day_input', label: 'Days', required: true },
        { name: 'group_shift_type', label: 'Shift type', required: true },
      ],
    },
  };

  const errors = utils.validate(validation, values);

  if (!values.group_shift_name) {
    errors.group_shift_name = 'Group shift name field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  form: 'groupshiftform', // a unique identifier for this form
  // onSubmit: submit,
  // dirty: true,
  // updateUnregisteredFields: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  // onSubmitSuccess: loadaftersubmit,
  // initialValues: datainit,
  validate,
})(translate('global')(GroupShiftSetting));
