import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import Layout from '../Layout/index';
import axios from 'axios';
import ls from 'local-storage';
import Structure from './Structure';

import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import renderTextInput from '../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderSelectField from '../../shared/components/form/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderDatePickerField from '../../shared/components/form/DatePicker';
import '../../scss/containers/fieldsidbar.scss';
import {
  faSearch,
  faUser,
  faCalendar,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import './okr.css';

const ObjectiveStructure = ({ t }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [dataSideBar, setDataSideBar] = useState([]);
  const [dataKey, setDataKey] = useState([]);
  const [styleObj, setStyleObj] = useState('');

  // vars
  const apiws = ls.get('ws_ip');
  const token = ls.get('token');

  useEffect(() => {
    initData();
  }, []);
  const initData = useCallback(() => {
    if (!isLoading) setLoading(true);
    axios
      .post(`${apiws}/okrobjective/getOkrStructure`, '', {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        const { data } = response;
        if (data.status == 'ok') {
          setData(data.data);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  }, []);

  // component
  const LoadingComponent = useCallback(() => {
    if (isLoading) {
      return (
        <div
          className="load__icon-wrap text-center align-self-center"
          style={{ marginTop: '30vh' }}
        >
          <svg className="load__icon">
            <path
              fill="#4ce1b6"
              d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
            />
          </svg>
        </div>
      );
    } else {
      return <></>;
    }
  }, [isLoading]);

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = (id) => {
    if (id != 0) {
      getDetailData(id?.substring(4));
    } else {
      setIsOffcanvasOpen(!isOffcanvasOpen);
    }
    // console.log(isOffcanvasOpen);
    if (isOffcanvasOpen == true) {
      setDataSideBar([]);
      setStyleObj('');
      setDataKey([]);
    }
    // getDetailData(id?.slice(4))
    // console.log(id);
  };

  const getDetailData = (id) => {
    console.log(id);
    axios
      .post(`${apiws}/okrobjective/getDetail/${id}`, '', {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        let dataResponse;
        let arr = [];
        let style;
        if (response.data.status == 'ok') {
          dataResponse = response.data.data;
          setDataSideBar(dataResponse);

          dataResponse.map((item) => {
            if (item.status_name == 'On Track') {
              setStyleObj('status-ontrack');
            } else if (item.status_name == 'Completed') {
              setStyleObj('status-completed');
            } else if (item.status_name == 'On Hold') {
              setStyleObj('status-onhold');
            } else if (item.status_name == 'Cancelled') {
              setStyleObj('status-canceled');
            } else if (item.status_name == 'Delayed') {
              setStyleObj('status-delayed');
            } else if (item.status_name == 'Not Started') {
              setStyleObj('status-not-started');
            }
            if (item.okr_status_name == 'On Track') {
              style = 'status-ontrack';
            } else if (item.okr_status_name == 'Completed') {
              style = 'status-completed';
            } else if (item.okr_status_name == 'On Hold') {
              style = 'status-onhold';
            } else if (item.okr_status_name == 'Cancelled') {
              style = 'status-canceled';
            } else if (item.okr_status_name == 'Delayed') {
              style = 'status-delayed';
            } else if (item.okr_status_name == 'Not Started') {
              style = 'status-not-started';
            }
            arr.push({
              key_result_title: item.key_result_title,
              okr_status_name: item.okr_status_name,
              okrStyle: style,
            });
          });
          setDataKey(arr);
          setIsOffcanvasOpen(!isOffcanvasOpen);
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  };
  const refCanvas = useRef();
  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Container className="p-3" id="">
          <Row>
            <Col md={12}>
              <div className="tab-body-panels" id="objective_structure">
                <div className="okr-content">
                  <div className="flex-column ms-3 mb-3">
                    <h3 className="page-title">{t('LBL.OKRSTRUCTURE')}</h3>
                  </div>
                </div>
                <LoadingComponent />

                {!isLoading && (
                  <Structure data={data} toggleOffcanvas={toggleOffcanvas} />
                )}
              </div>
            </Col>

            <div
              className="offcanvas fade show"
              style={{
                opacity: isOffcanvasOpen ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
                zIndex: isOffcanvasOpen ? 999 : -999,
              }}
              ref={refCanvas}
              onClick={() => {
                refCanvas.current.style.opacity = 0;
                setTimeout(()=>toggleOffcanvas('0'),1000);
              }}
            >
              <div
                className="offcanvas-dialog"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="offcanvas-header px-3 py-3">
                  <span className="off-title">{t('LBL.OBJDETAIL')}</span>
                  <button
                    className="close"
                    onClick={() => {
                      refCanvas.current.style.opacity = 0;
                      setTimeout(()=>toggleOffcanvas('0'),1000);
                    }}
                  >
                    &times;
                  </button>
                </div>
                {/* {dataSideBar.map()} */}
                <div className="offcanvas-body mx-2">
                  <div className="mb-3 title-detail-objective">
                    {dataSideBar[0]?.objective_title ?? '-'}
                  </div>
                  <Row>
                    <Col md={7} className="mb-2">
                      <div className="objective-date">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="add-result"
                        />
                        <span className="ml-2">
                          {dataSideBar[0]?.period_name ?? '-'}
                        </span>
                      </div>
                    </Col>
                    <Col md={4} className="mb-2">
                      <div className="objective-date">
                        <FontAwesomeIcon
                          icon={faChartBar}
                          className="add-result"
                        />
                        <span className="ml-2">
                          {dataSideBar[0]?.achievement_percentage ?? 0}%
                        </span>
                      </div>
                    </Col>

                    <Col md={7} className="d-flex align-items-center mb-2">
                      <div className="objective-date">
                        <FontAwesomeIcon icon={faUser} className="add-result" />
                        <span className="ml-2">
                          {dataSideBar[0]?.owner ?? '-'}
                        </span>
                      </div>
                    </Col>
                    <Col md={4} className="mb-2">
                      <div className="objective-date">
                        <div className={`${styleObj}`}>
                          <span className="">
                            {dataSideBar[0]?.status_name ?? '-'}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-4 mb-3 title-detail-objective">
                    Key Result
                  </div>
                  {dataKey.map((item) => (
                    <>
                      {item.okr_status_name != undefined && (
                        <div className="px-3 py-3 border my-3">
                          <div>{item?.key_result_title}</div>
                          <div className="ml-auto w-25 mt-2">
                            <div className={`px-2  ${item.okrStyle}`}>
                              <span className="">{item?.okr_status_name}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
})(translate('global')(ObjectiveStructure));
