/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Modal,
  ModalBody, ModalHeader, ModalFooter, Table,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import NumberFormat from '../../../../shared/components/NumberFormat';

// let compthr = '';
class ReportAttendance extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    componentFunction: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    prp_id: PropTypes.string.isRequired,
    income_column: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '20%', '20%', '20%', '10%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DESCRIPTION') },
      { '3': t('LBL.TYPE') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editInformation = this.editInformation.bind(this);
    this.deleteInformation = this.deleteInformation.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DESCRIPTION') },
      { '3': t('LBL.TYPE') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle(x) {
    this.props.destroy();
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.loadSelectBox();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/payreportcolumns/getAllItemPrp/${this.props.prp_id}` });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ orgoffice: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgcompany/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.cmp_id, label: obj.company })
        ));
        this.setState({ company: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ orgorganization: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  search(values) {
    console.log(values);
  }


  handleSubmitInput(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const error = true;
    const id = values.prc_id;
    const types = (typeof values.type === 'object') ? values.type.value : values.type;
    const prpid = this.props.prp_id;
    const incomecolumn = this.props.income_column;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/payreportcolumns/saveItem`;
      data = {
        types,
        prpid,
        incomecolumn,
        ...values,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/payreportcolumns/updateItem/${id}`;
      data = {
        types,
        prpid,
        incomecolumn,
        ...values,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
          setTimeout(() => {
            this.child.reload();
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  editInformation(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/payreportcolumns/getAllItemById/${id}`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        const datamaping = {
          prc_id: getResponseData[0].prc_id,
          ordernumber: getResponseData[0].column_order,
          name: getResponseData[0].name,
          type: getResponseData[0].pyt_id,
        };
        this.setState({ statusEdit: true });
        this.setState({ modal: true }, () => {
          this.props.initialize(datamaping);
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  deleteInformation(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payreportcolumns/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.child.reload('delete');
            }, 2000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }

  render() {
    const {
      handleSubmit, t, pristine, componentFunction, formatFunction,
    } = this.props;
    const {
      orgorganization,
      orgoffice,
      urlData,
      company,
      statusEdit,
    } = this.state;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('LBL.DESCRIPTION')}
      </Button>
    );
    const backBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={formatFunction}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="reportinformationform"
          disabled={pristine}
        > {t('FRM.EDIT')}
        </Button>);
    } else {
      button_action = (
        <Button
          color="primary"
          form="reportinformationform"
          type="submit"
          disabled={pristine}
        >{t('FRM.SAVE')}
        </Button>);
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            {/* <div style={{ float: 'left', 'margin-left': '25px' }}>
              <h3>Format: </h3>
              <h5>Group Code: </h5>
              <h5>Group Name: </h5>
            </div> */}
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="prc_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  buttonAction="component"
                  onRef={ref => (this.child = ref)}
                  updateFunc={this.editInformation}
                  deleteFunc={this.deleteInformation}
                  componentFunc={componentFunction}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}> {t('FRM.ADD')} {t('LBL.DESCRIPTION')}</ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="reportinformationform"
                  id="reportinformationform"
                >
                  <Container>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <input type="hidden" name="prc_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label"> {t('LBL.ORDER')} {t('LBL.NUMBER')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="ordernumber"
                              component={NumberFormat}
                              props={{ props: { maxLength: 10, allowNegative: false } }}
                              type="text"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label"> {t('LBL.NAME')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="name"
                              component={renderTextInput}
                              type="text"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label"> {t('LBL.TYPE')}</span>
                          <div className="form__form-group-field products-list__search">
                            <Field
                              name="type"
                              component={renderSelectField}
                              options={[
                                { value: '1', label: 'Allowance' },
                                { value: '2', label: 'Deduction' },
                              ]}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => { this.toggle('cancel'); }}> {t('FRM.CANCEL')}</Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.ordernumber) {
    errors.ordernumber = 'Order Number shouldn’t be empty';
  }
  if (!values.name) {
    errors.name = 'Label shouldn’t be empty';
  }
  if (!values.type) {
    errors.type = 'Type field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'reportinformationform',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(ReportAttendance));
