/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faSync, faTimes, faClock } from '@fortawesome/fontawesome-free-solid';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ls from 'local-storage';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderIntervalDatePickerField from '../../../../../shared/components/form/IntervalDatePicker';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import renderTimePickerIntervalField from '../../../../../shared/components/form/TimePickerInterval';


class submissionForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    wfo_id: PropTypes.string.isRequired,
    initialize: PropTypes.string.isRequired,
    disabledField: PropTypes.bool.isRequired,
    getValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '15%', '20%', '20%', '15%', '15%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      displayLoad: 'none',
      displaySave: '',
      disabled: false,
      arrworkflow: [],
      arrreason: [],
      arrEmp: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getAttendanceReq = this.getAttendanceReq.bind(this);
    this.getattreason = this.getattreason.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getattreason();
      // this.getButton();
    });
    if (typeof this.props.wfo_id !== 'undefined') {
      this.getAttendanceReq();
      this.setState({ disabled: this.props.disabledField });
      this.setState({ displaySave: 'none' });
    }
  }


  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getattreason();
      this.getEmployee();
      // this.getButton();
    });
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/employee/getAllAsc`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.emp_id, label: obj.emp_name })
        ));
        this.setState({ arrEmp: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getAttendanceReq() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const wfo = this.props.wfo_id;
    axios.get(
      `${ls.get('ws_ip')}/attdispensationrequest/getAttendanceByWfo/${wfo}`,
      config,
    ).then((resemp) => {
      const dataResponse = resemp.data.data;
      const array = [];
      const datamapp = {
        adq_id: dataResponse[0].adq_id,
        adr_id: dataResponse[0].adr_id,
        wfl_id: dataResponse[0].wfl_id,
        emp_id: dataResponse[0].emp_id,
        reason: dataResponse[0].adr_reason,
        date: dataResponse[0].date_in !== null ? moment(dataResponse[0].date_in) : moment(dataResponse[0].date_out),
        time_in: dataResponse[0].date_in !== null ? moment(dataResponse[0].date_in) : '',
        time_out: dataResponse[0].date_out !== null ? moment(dataResponse[0].date_out) : '',
      };
      this.props.initialize(datamapp);
    }).catch(() => {
      // console.log(error);
    });
  }

  getWorkflow() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios.post(
      `${ls.get('ws_ip')}/workflow/getAllItem/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.wfl_id, label: obj.workflow })
        ));
        this.setState({ arrworkflow: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getattreason() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios.post(
      `${ls.get('ws_ip')}/attdispensationreason/getAllItem/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.adr_id, label: obj.reason })
        ));
        this.setState({ arrreason: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '', displaySave: 'none' });
    const { apiws, token } = this.state;
    const userid = ls.get('user_cred').usr_id;
    // eslint-disable-next-line prefer-destructuring
    const emp_id = ls.get('user_cred').emp_id;
    const overtime_date = moment(values.overtime_date).format('YYYY-MM-DD');
    // const remarks = typeof values.remarks === 'undefined' ? '' : values.remarks;
    const wfl_id = (typeof values.oct_id.wfl_id === 'undefined' || values.oct_id.wfl_id === null)
      ? '' : values.oct_id.wfl_id;
    const oct_id = (typeof values.oct_id === 'undefined' || values.oct_id === null)
      ? '' : values.oct_id.value || values.oct_id;
    const url = `${apiws}/workflow/generateattendancecase`;
    const data = {
      ...values,
      wfl_id,
      emp_id,
      oct_id,
      //   remarks,
      overtime_date,
      cuser_id: userid,
    };

    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Leave request has submitted', 'success');
        this.setState({ displayLoad: 'none', displaySave: '', redirect: true });
        setTimeout(() => {
          this.props.destroy();
        }, 200);
      } else {
        this.setState({ displayLoad: 'none', displaySave: '' });
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }


  render() {
    const { handleSubmit, t } = this.props;
    const {
      disabled, displayLoad, displaySave, arrworkflow, arrreason, arrEmp,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{ float: 'right', 'margin-bottom': '20px', display: this.state.addcond === '1' ? '' : 'none' }}
      >
        {t('FRM.ADD')} {t('LBL.LEAVE_REQUEST')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (this.state.redirect) {
      return (<Redirect to="/dashboard" />);
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row>
                  {/* {this.state.disabled === false && (
                    <Col md={8}>
                      <h3 className="page-title">{t('LBL.SUBMISSION_OVERTIME')}</h3>
                    </Col>
                  )} */}
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form__vertical"
                      onSubmit={handleSubmit(this.handleSubmit)}
                      name="addattmonitoring"
                      id="addattmonitoring"
                    >
                      <Field
                        name="adq_id"
                        component={renderTextInput}
                        type="hidden"
                      />
                      <Container>
                        <Row>
                          <Col md="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.DATE')}</span>
                              <div className="form__form-group-field">
                                <Col md={3} xs={12} style={{ padding: '0' }}>
                                  <Field
                                    name="date"
                                    component={renderDatePickerField}
                                    onChange={(this.props.getValue)}
                                    disabled={disabled}
                                    props={{ disableTouched: this.props.disabled }}
                                  />
                                </Col>
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" sm="6" xs="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TIME_IN')}</span>
                              <div className="form__form-group-field" style={{ display: 'inline-block' }}>
                                <div className="timepicker_sync">
                                  <Col md={12} xs={12} style={{ padding: '0' }}>
                                    <Field
                                      name="time_in"
                                      component={renderTimePickerIntervalField}
                                      placeholder="hh:mm"
                                      formatdate="HH:mm"
                                      onChange={(this.props.getValue)}
                                      disabled={disabled}
                                      props={{ disableTouched: this.props.disabled }}
                                    />
                                  </Col>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md="3" sm="6" xs="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TIME_OUT')}</span>
                              <div className="form__form-group-field" style={{ display: 'inline-block' }}>
                                <div className="timepicker_sync">
                                  <Col md={12} xs={12} style={{ padding: '0' }}>
                                    <Field
                                      name="time_out"
                                      component={renderTimePickerIntervalField}
                                      placeholder="hh:mm"
                                      formatdate="HH:mm"
                                      onChange={(this.props.getValue)}
                                      disabled={disabled}
                                      props={{ disableTouched: this.props.disabled }}
                                    />
                                  </Col>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.ATTENDANCE_DISPENSATION_REASON')}</span>
                              <div className="form__form-group-field">
                                <Col md={12} xs={12} style={{ padding: '0' }}>
                                  <Field
                                    name="adr_id"
                                    component={renderSelectField}
                                    options={arrreason}
                                    onChange={(this.props.getValue)}
                                    disabled={disabled}
                                    props={{ disableTouched: this.props.disabled }}
                                  />
                                </Col>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.DESCRIPTION')}</span>
                              <div className="form__form-group-field">
                                <Col md={12} xs={12} style={{ padding: '0' }}>
                                  <Field
                                    name="reason"
                                    component={renderTextInput}
                                    type="textarea"
                                    onChange={(this.props.getValue)}
                                    disabled={disabled}
                                    props={{ disableTouched: this.props.disabled }}
                                    maxLength="350"
                                  />
                                </Col>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'approval', // a unique identifier for this form
})(translate('global')(submissionForm));
