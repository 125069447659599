import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import TaskItem from './TaskItem';
import './styles/taskContainer.scss';

import TaskCollapse from './TaskCollapse';

const Container = (props) => {
  const {
    t,
    id,
    sectionId,
    section,
    tasks,
    titleCollapse,
    toggleOffCanvas,
    toggleNotification,
    getDetailTask,
    getDataSection,
    reloadSection,
    handleRenameSection,
    idActiveSection,
    dragOverlayItem,
  } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <TaskCollapse
      t={t}
      section={section}
      idActiveSection={idActiveSection}
      handleRenameSection={handleRenameSection}
      reloadSection={reloadSection}
      sectionId={sectionId}
      title={titleCollapse}
    >
      {tasks ? (
        <SortableContext
          id={id}
          items={tasks}
          strategy={verticalListSortingStrategy}
        >
          <div ref={setNodeRef} className="tm__inner-content">
            <div className="content__item">
              {tasks?.map((item, id) => (
                <TaskItem
                  t={t}
                  tab={props.tab}
                  key={item.pta_id}
                  id={item.pta_id}
                  data={item}
                  toggleOffCanvas={toggleOffCanvas}
                  toggleNotification={toggleNotification}
                  getDetailTask={getDetailTask}
                  getDataSection={getDataSection}
                  user={props.user}
                />
              ))}
            </div>
          </div>
        </SortableContext>
      ) : (
        <div ref={setNodeRef} className="tm__inner-content">
          <div className="content__item">
            {tasks?.map((item, id) => (
              <TaskItem
                t={t}
                tab={props.tab}
                key={item.pta_id}
                id={item.pta_id}
                data={item}
                toggleOffCanvas={toggleOffCanvas}
                toggleNotification={toggleNotification}
                getDetailTask={getDetailTask}
                getDataSection={getDataSection}
                user={props.user}
              />
            ))}
          </div>
        </div>
      )}
    </TaskCollapse>
  );
};

export default Container;
