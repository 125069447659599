import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import 'font-awesome/css/font-awesome.min.css';
// import Bootstrap from './bootstrap/Bootstrap';

// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import MinusIcon from 'mdi-react/MinusIcon';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
// import renderSelectField from '../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
import { Provider } from 'react-redux';
import { Grid, Store } from 'react-redux-grid';

// eslint-disable-next-line react/prefer-stateless-function
class MenuManagement extends PureComponent {
    static propTypes = {
      //   t: PropTypes.func.isRequired,
    //   handleSubmit: PropTypes.func.isRequired,
    };

    render() {
    //   const treedatasrc =
      const datas = {

        root: {
          id: -1,
          parentId: null,
          children: [
            {
              id: 1,
              parentId: -1,
              Name: 'Home',
            },
            {
              id: 2,
              parentId: -1,
              Name: 'Administration',
              children: [
                {
                  id: 21,
                  parentId: 2,
                  Name: 'Employee',
                //   leaf: false,
                },
                {
                  id: 22,
                  parentId: 2,
                  Name: 'Leave',
                //   leaf: false,
                },
              ],
            },
            {
              id: 3,
              parentId: -1,
              Name: 'Payroll',
              children: [
                {
                  id: 31,
                  parentId: 2,
                  Name: 'Run Process',
                //   leaf: false,
                },
                {
                  id: 32,
                  parentId: 2,
                  Name: 'Setting Period',
                //   leaf: false,
                },
                {
                  id: 33,
                  parentId: 2,
                  Name: 'Attendance - Process',
                //   leaf: false,
                },
                {
                  id: 34,
                  parentId: 2,
                  Name: 'Tax Calculation',
                //   leaf: false,
                },
              ],
            },
            {
              id: 4,
              parentId: -1,
              Name: 'Preferences',
              children: [
                {
                  id: 41,
                  parentId: 4,
                  Name: 'Organization Setting',
                //   leaf: false,
                },
                {
                  id: 42,
                  parentId: 4,
                  Name: 'Payroll Setting',
                //   leaf: false,
                },
                {
                  id: 43,
                  parentId: 4,
                  Name: 'Attendance Setting',
                //   leaf: false,
                },
                {
                  id: 44,
                  parentId: 4,
                  Name: 'Setting',
                //   leaf: false,
                },
              ],
            },
            {
              id: 5,
              parentId: -1,
              Name: 'Report',
              children: [
                {
                  id: 51,
                  parentId: 2,
                  Name: 'Payroll',
                //   leaf: false,
                },
                {
                  id: 52,
                  parentId: 2,
                  Name: 'BPJS',
                //   leaf: false,
                },
                {
                  id: 53,
                  parentId: 2,
                  Name: 'Tax',
                //   leaf: false,
                },
              ],
            },
          ],
        },
      };
      //   const {  } = this.props;
      //   const treeConfig = {
      //     dataSource: data,
      //     // eslint-disable-next-line no-undef
      //     data,
      //     stateKey: 'tree-grid-1',
      //     gridType: 'tree', // either `tree` or `grid`,
      //     showTreeRootNode: false, // dont display root node of tree
      //     columns: [
      //       {
      //         name: 'Name',
      //         width: '30%',
      //         className: 'additional-class',
      //         dataIndex: 'Name',
      //         sortable: false,
      //         expandable: true,
      //       },
      //       {
      //         name: 'Phone Number',
      //         dataIndex: 'Phone Number',
      //         sortable: false,
      //         className: 'additional-class',
      //       },
      //       {
      //         name: 'Email',
      //         dataIndex: 'Email',
      //         sortable: false,
      //         className: 'additional-class',
      //         defaultSortDirection: 'descend',
      //       },
      //       {
      //         name: 'Address',
      //         dataIndex: 'Address',
      //         sortable: false,
      //         className: 'additional-class',
      //       },
      //     ],
      //     plugins: {
      //       GRID_ACTIONS: null,
      //       SELECTION_MODEL: {
      //         mode: 'single',
      //       },
      //       PAGER: {
      //         enabled: false,
      //       },
      //       BULK_ACTIONS: {
      //         enabled: false,
      //       },
      //     },
      //   };
      const stateKey = 'tree';
      //   const pageSize = 20;

      const events = {
        // HANDLE_CELL_CLICK: (cell, reactEvent, id, browserEvent) => {
        //   console.log('On Cell Click Event');
        // },
        // HANDLE_CELL_DOUBLE_CLICK: (cell, reactEvent, id, browserEvent) => {
        //   console.log('On Cell Double Click Event');
        // },
        // HANDLE_ROW_CLICK: (row, reactEvent, id, browserEvent) => {
        //   console.log('On Row Click Event');
        // },
        // HANDLE_ROW_DOUBLE_CLICK: (row, reactEvent, id, browserEvent) => {
        //   console.log('On Row Double Click Event');
        // },
        HANDLE_BEFORE_SELECTION: () => {
          // console.log('On Before Selection');
        },
        HANDLE_AFTER_SELECTION: () => {
          // console.log('On After Selection');
        },
        HANDLE_AFTER_INLINE_EDITOR_SAVE: () => {
          // console.log('On After Save Inline Editor Event');
        },
        HANDLE_BEFORE_BULKACTION_SHOW: () => {
          // console.log('On Before Bulk Action Show');
        },
        HANDLE_AFTER_BULKACTION_SHOW: () => {
          // console.log('On After Bulk Action Show');
        },
      };

      const treeConfig = {
        showTreeRootNode: false,
        // dataSource: data,
        store: Store,
        gridType: 'tree',
        dragAndDrop: true,
        columns: [
          {
            name: 'Name',
            width: '30%',
            className: 'additional-class',
            dataIndex: 'Name',
            sortable: false,
            expandable: true,
          },
          {
            name: 'Action',
            width: '30%',
            className: 'additional-class',
            dataIndex: 'Action',
            sortable: false,
            expandable: true,
          },
        ],
        data: datas,
        plugins: {
          GRID_ACTIONS: null,
          SELECTION_MODEL: {
            mode: 'single',
          },
          PAGER: {
            enabled: false,
          },
          BULK_ACTIONS: {
            enabled: false,
          },
        },
        events,
        stateKey,
      };

      return (
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody className="p0">
                  <Row className="m5">
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <h3 className="page-title">Menu Management</h3>
                      <h3 className="page-subhead subhead">Add new menu</h3>
                    </Col>
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <Provider store={Store}>
                        <Grid
                          {...treeConfig}
                        />
                      </Provider>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
}

export default reduxForm({
  form: 'horizontal_form_layout', // a unique identifier for this form
})(translate('common')(MenuManagement));
