/* eslint-disable array-callback-return */
/* eslint-disable camelcase, max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import utils from '../../../../utils';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import renderTextInput from '../../../../shared/components/form/TextInput';

class Atthistory extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    parmid: PropTypes.string.isRequired,
    pristine: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.DATE') },
      { 2: t('LBL.TIME_IN') },
      // { 3: t('LBL.DATE_OUT') },
      { 4: t('LBL.TIME_OUT') },
      { 5: t('LBL.REASON') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.state = {
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '25%', '15%', '10%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
    };

    this.getTableData = this.getTableData.bind(this);
    this.deleteAtthistory = this.deleteAtthistory.bind(this);
    this.editAtthistory = this.editAtthistory.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.getButton = this.getButton.bind(this);
    this.getattreason = this.getattreason.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getButton();
      this.getattreason();
      // this.getLastRecord();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/attdispensationhistory/getAllItemByEmpId/${
        this.props.parmid.state.usrid
      }`,
    });
    this.setState({ emp_id: this.props.parmid.state.usrid });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.DATE') },
      { 2: t('LBL.TIME_IN') },
      // { 3: t('LBL.DATE_OUT') },
      { 4: t('LBL.TIME_OUT') },
      { 5: t('LBL.REASON') },
      { 6: t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getattreason() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios
      .post(`${ls.get('ws_ip')}/attdispensationreason/getAllItem/`, '', config)
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.adr_id, label: obj.reason }),
          );
          this.setState({ arrreason: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    const { apiws, token, emp_id } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const error = false;
    const id = values.adh_id;
    let adr_id;
    let time_in;
    let time_out;
    if (typeof values.adr_id !== 'undefined') {
      if (values.adr_id !== null) {
        adr_id =
          typeof values.adr_id === 'object'
            ? values.adr_id.value
            : values.adr_id;
      } else {
        adr_id = '';
      }
    } else {
      adr_id = '';
    }
    // if (typeof values.time_in !== 'undefined') {
    //   if (values.time_in !== null) {
    //     time_in = (typeof values.time_in === 'object') ? values.time_in.value : values.time_in;
    //   } else {
    //     time_in = '';
    //   }
    // } else {
    //   time_in = '';
    // }

    // if (typeof values.time_out !== 'undefined') {
    //   if (values.time_out !== null) {
    //     time_out = (typeof values.time_out === 'object') ? values.time_out.value : values.time_out;
    //   } else {
    //     time_out = '';
    //   }
    // } else {
    //   time_out = '';
    // }
    console.log(values);
    const att_date_in =
      typeof values.date === 'undefined' || values.date === null
        ? ''
        : moment(values.date).format('YYYY-MM-DD');
    const att_date_out =
      typeof values.date === 'undefined' || values.date === null
        ? ''
        : moment(values.date).format('YYYY-MM-DD');
    const att_time_in =
      typeof values.time_in === 'undefined' ||
      values.time_in === null ||
      values.time_in === ''
        ? ''
        : moment(values.time_in).format('HH:mm');
    const att_time_out =
      typeof values.time_out === 'undefined' ||
      values.time_out === null ||
      values.time_out === ''
        ? ''
        : moment(values.time_out).format('HH:mm');
    const reason =
      typeof values.reason === 'undefined' || values.reason === null
        ? ''
        : values.reason;
    // const emp_id = ls.get('user_cred').emp_id;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${ls.get('ws_ip')}/attdispensationhistory/saveItem`;
      data = {
        adr_id,
        att_date_in,
        att_date_out,
        att_time_in,
        att_time_out,
        reason,
        emp_id,
        cuser_id: userid,
      };
    } else {
      url = `${ls.get('ws_ip')}/attdispensationhistory/updateItem/${id}`;
      data = {
        adr_id,
        att_date_in,
        att_time_in,
        att_date_out,
        att_time_out,
        emp_id,
        reason,
        cuser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    if (!error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            setTimeout(() => {
              this.props.destroy();
              this.setState({ modal: false });
              this.child.reload();
            }, 200);
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }
  async editAtthistory(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/attdispensationhistory/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            adh_id: getResponseData[0].adh_id,
            date:
              getResponseData[0].date_in === null
                ? moment(getResponseData[0].date_out)
                : moment(getResponseData[0].date_in),
            time_in:
              getResponseData[0].date_in === null
                ? ''
                : moment(getResponseData[0].date_in),
            time_out:
              getResponseData[0].date_out === null
                ? ''
                : moment(getResponseData[0].date_out),
            reason: getResponseData[0].description,
            adr_id: getResponseData[0].adr_id,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle() {
    this.props.destroy();
    this.setState({ modal: !this.state.modal });
  }

  deleteAtthistory(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attdispensationhistory/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 1000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      disabled,
      displayLoad,
      displaySave,
      arrworkflow,
      arrreason,
      arrEmp,
    } = this.state;
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.ATTENDANCE_HISTORY')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col md={8}>
                <h3 className="page-title">{t('LBL.ATTENDANCE_HISTORY')}</h3>
                <h3 className="page-subhead subhead">
                  {t('LBL.VIEW')} {t('LBL.ATTENDANCE_HISTORY')}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} lg={12} xl={12}>
                <Card>
                  <div style={{ padding: '20px 5px 30px 10px' }}>
                    <ViewListTable
                      url={this.state.urlData}
                      heads={this.heads}
                      primaryKey="adh_id"
                      widthTable={this.state.dataWidth}
                      updateFunc={this.editAtthistory}
                      deleteFunc={this.deleteAtthistory}
                      displayStyleHead="none"
                      onRef={(ref) => (this.child = ref)}
                      addBtn={addBtn}
                      backBtn={backBtn}
                      editCond={this.state.editcond}
                      addCond={this.state.addcond}
                      deleteCond={this.state.deletecond}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Modal
                  isOpen={this.state.modal}
                  className="modal-dialog
                        modal-input"
                  style={{ marginTop: '100px', maxWidth: '650px' }}
                >
                  <ModalHeader>
                    {t('FRM.ADD')} {t('LBL.ATTENDANCE_HISTORY')}
                  </ModalHeader>
                  <ModalBody>
                    <form
                      id="form_Atthist"
                      className="form form--horizontal"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <Container>
                        <input type="hidden" name="adh_id" />
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <div className="form__form-group-field form-group-ml-pl-0 w-100">
                                  <Field
                                    name="date"
                                    component={renderDatePickerField}
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" lg="12" xl="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.TIME_IN')}
                              </span>
                              <div className="form__form-group-field">
                                <Row className="w-100">
                                  <Col md={4} sm={12}>
                                    <div className="timepicker_sync">
                                      <Field
                                        name="time_in"
                                        component={
                                          renderTimePickerIntervalField
                                        }
                                        placeholder="hh:mm"
                                        formatdate="HH:mm"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col md="12" lg="12" xl="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.TIME_OUT')}
                              </span>
                              <div className="form__form-group-field">
                                <Row className="w-100">
                                  <Col md={4} sm={12}>
                                    <div className="timepicker_sync">
                                      <Field
                                        name="time_out"
                                        component={
                                          renderTimePickerIntervalField
                                        }
                                        placeholder="hh:mm"
                                        formatdate="HH:mm"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.ATTENDANCE_DISPENSATION_REASON')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="adr_id"
                                  component={renderSelectField}
                                  options={arrreason}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.DESCRIPTION')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="reason"
                                  component={renderTextInput}
                                  type="textarea"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      {t('FRM.CANCEL')}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      form="form_Atthist"
                      disabled={pristine}
                    >
                      {' '}
                      {t('FRM.SAVE')}
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const validate = (values) => {
  const validation = {
    reason: {
      fieldLabel: 'Reason',
      required: true,
    },
    date: {
      fieldLabel: 'Start date',
      required: true,
    },
    // time_in: {
    //   fieldLabel: 'Time in',
    //   required: true,
    // },
    // time_out: {
    //   fieldLabel: 'Time out',
    //   required: true,
    // },
    adr_id: {
      fieldLabel: 'Reason',
      required: true,
    },
  };
  const errors = utils.validate(validation, values);

  if (!values.time_in && !values.time_out) {
    errors.time_out = 'One of Time shouldnt be empty';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'form_Atthistory',
})(translate('global')(Atthistory));
