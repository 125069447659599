/* eslint-disable no-unused-vars, max-len */
/* eslint-disable react/no-unused-state, class-methods-use-this */
/* eslint-disable quote-props, react/sort-comp */
/* eslint-disable react/no-did-mount-set-state, react/no-unused-prop-types */

import React, { PureComponent } from 'react';
import { Container, Row, Col, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import showNotifications from '../../../Preferences/notificationMessages';

class RunPayrollFormAdvance extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      t: PropTypes.func.isRequired,
      onProcess: PropTypes.func.isRequired,
      pristine: PropTypes.func.isRequired,
      initialize: PropTypes.func.isRequired,
      destroy: PropTypes.func.isRequired,
      toggleAdvance: PropTypes.func.isRequired,
      objFilter: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      this.state = {
        apiws: '',
        token: '',
        idUser: '',
        arrPeriod: [],
        arrComponentGroup: [],
        urlData: '',
        displaySpinner: 'none',
        displaySave: '',
        filtered: [],
        modal: '',
      };
      this.hanldeSubmitFilter = this.hanldeSubmitFilter.bind(this);
    }

    componentDidMount = () => {
      this.setState({ token: ls.get('token') });
      this.setState({ apiws: ls.get('ws_ip') });
      this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
        this.getOrganization();
        this.getOffice();
        this.getEmployee();
        this.getReligion();
      });
      this.setState({ urlData: `${ls.get('ws_ip')}/payprocess/` });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    getEmployee() {
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      axios.post(
        `${ls.get('ws_ip')}/employee/getAllFilter`,
        '',
        config,
      ).then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map(obj => (
            array.push({ value: obj.emp_id, label: obj.emp_name })
          ));
          this.setState({ arrEmp: array });
        }
      });
    }

    getReligion() {
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      axios.post(
        `${ls.get('ws_ip')}/rtbreligion/getAll/`,
        '',
        config,
      ).then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrReligion: array });
        } else {
          emp.map(obj => (
            array.push({ value: obj.rtg_id, label: obj.religion })
          ));
          this.setState({ arrReligion: array });
        }
      });
    }


    getOffice() {
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      axios.get(
        `${ls.get('ws_ip')}/orgoffice`,
        config,
      ).then((response) => {
        const office = response.data.data;
        const array = [];
        if (office.length === 0) {
          this.setState({ arrOffice: array });
        } else {
          office.map(obj => (
            array.push({ value: obj.off_id, label: obj.office })
          ));
          this.setState({ arrOffice: array });
        }
      });
    }

    getOrganization() {
      const config = {
        headers: { Authorization: `Bearer ${ls.get('token')}` },
      };
      axios.post(
        `${ls.get('ws_ip')}/orgorganization/getAll/`,
        '',
        config,
      ).then((response) => {
        const organization = response.data.data;
        const array = [];
        if (organization.length === 0) {
          this.setState({ arrOrganization: array });
        } else {
          organization.map(obj => (
            array.push({ value: obj.org_id, label: obj.unit_organization })
          ));
          this.setState({ arrOrganization: array });
        }
      });
    }

    handleChange(value) {
      switch (value) {
        case '1':
          this.setState({ filter: value });
          break;
        case '2':
          this.setState({ filter: value });
          break;
        case '3':
          this.setState({ filter: value });
          break;
        case '4':
          this.setState({ filter: value });
          break;
        default:
      }
    }

    hanldeSubmitFilter(values) {
      let filterReady = false;
      const filterSave = values.filter.value;
      let filterValue;
      if (filterSave === '1') {
        filterValue = {
          'cond': 'off_id',
          'filter': 'Office',
          'value': values.office.value,
          'label': values.office.label,
        };
        filterReady = true;
      } else if (filterSave === '2') {
        filterValue = {
          'cond': 'org_id',
          'filter': 'Organization',
          'value': values.organization.value,
          'label': values.organization.label,
        };
        filterReady = true;
      } else if (filterSave === '3') {
        filterValue = {
          'cond': 'emp_id',
          'filter': 'Employee',
          'value': values.employee.value,
          'label': values.employee.label,
        };
        filterReady = true;
      } else if (filterSave === '4') {
        filterValue = {
          'cond': 'rtg_id',
          'filter': 'Religion',
          'value': values.religion.value,
          'label': values.religion.label,
        };
        filterReady = true;
      }
      if (filterReady) {
        this.props.toggleAdvance();
        this.props.objFilter(filterValue);
        showNotifications('Success', 'Filter has been set', 'success');
      }
    }

    render() {
      const { handleSubmit, t, pristine } = this.props;
      const {
        filter,
        arrOffice,
        arrEmp,
        arrOrganization,
        arrReligion,
      } = this.state;
      let filterAction;
      if (filter === '1') {
        filterAction = (
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
            <div className="form__form-group-field">
              <Field
                name="office"
                component={renderSelectField}
                options={arrOffice}
                type="text"
                placeholder="Search Office"
                // disabled={disabledOffice}
                clearable="true"
              />
              <div className="form__form-group-icon-select">
                <AccountSearchIcon />
              </div>
            </div>
          </div>);
      } else if (filter === '2') {
        filterAction = (
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
            <div className="form__form-group-field">
              <Field
                name="organization"
                component={renderSelectField}
                options={arrOrganization}
                type="text"
                placeholder="Search Organization"
              />
              <div className="form__form-group-icon-select">
                <AccountSearchIcon />
              </div>
            </div>
          </div>);
      } else if (filter === '3') {
        filterAction = (
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
            <div className="form__form-group-field">
              <Field
                name="employee"
                component={renderSelectField}
                options={arrEmp}
                type="text"
                placeholder="Search Employee"
              />
              <div className="form__form-group-icon-select">
                <AccountSearchIcon />
              </div>
            </div>
          </div>);
      } else if (filter === '4') {
        filterAction = (
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
            <div className="form__form-group-field">
              <Field
                name="religion"
                component={renderSelectField}
                options={arrReligion}
                type="text"
                placeholder="Search Religion"
              />
              <div className="form__form-group-icon-select">
                <AccountSearchIcon />
              </div>
            </div>
          </div>);
      }
      return (
        <div>
          <ModalHeader>{t('LBL.PROCESS')} {t('LBL.PAYROLL')} - {t('LBL.ADVANCE')}</ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.hanldeSubmitFilter)}
              name="filterpayrollprocess"
              id="filterpayrollprocess"
            >
              <Container>
                <Row style={{ paddingTop: '15px' }}>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <form className="form form--horizontal">
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.FILTER_BY')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="filter"
                            component={renderSelectField}
                            type="text"
                            options={[
                              { value: '1', label: t('LBL.OFFICE') },
                              { value: '2', label: t('LBL.UNIT_ORGANIZATION') },
                              { value: '3', label: t('LBL.EMPLOYEE') },
                              { value: '4', label: t('LBL.RELIGION') },
                            ]}
                            onChange={(val) => { this.handleChange(val.value); }}
                          />
                        </div>
                      </div>
                      {filterAction}
                    </form>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.props.toggleAdvance()}>{t('FRM.CANCEL')}</Button>
            <Button type="submit" color="primary" disabled={pristine} form="filterpayrollprocess">{t('FRM.SAVE')}</Button>
          </ModalFooter>
        </div>
      );
    }
}

const validate = (values) => {
  const errors = {};
  if (!values.filter) {
    errors.filter = 'Period field shouldn’t be empty';
  }
  if (!values.office) {
    errors.office = 'Office field shouldn’t be empty';
  }
  if (!values.organization) {
    errors.organization = 'Organization field shouldn’t be empty';
  }
  if (!values.employee) {
    errors.employee = 'Employee field shouldn’t be empty';
  }
  if (!values.religion) {
    errors.religion = 'Religion field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'runpayrollFormAdvanceform',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(RunPayrollFormAdvance));
