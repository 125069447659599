/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state, react/no-unescaped-entities */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable quote-props, camelcase, no-return-assign */
/* eslint-disable no-unused-vars, prefer-template */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import moment from 'moment';
import '../../../Tables/DataTable/assets/css/style.css';
import utils from '../../../../utils';

const FunctionFormater = ({ value }) =>
  value === 'active' ? (
    <span className="badge badge-success">Enabled</span>
  ) : (
    <span className="badge badge-disabled">Disabled</span>
  );

FunctionFormater.propTypes = {
  value: PropTypes.string.isRequired,
};

let translator = () => {};
class overtimeHistory extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    parmid: PropTypes.string.isRequired,
    initialize: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.OVERTIME_TYPE'),
      t('LBL.OVERTIME_DATE'),
      t('LBL.OVERTIME_TIME'),
      t('FRM.FUNCTION'),
    ];
    this.state = {
      modal: false,
      dataWidth: ['5%', '30%', '10%', '10%', '5%'],
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      arrOvertimeType: [],
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteFunc = this.deleteFunc.bind(this);
    this.editFunc = this.editFunc.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getButton();
      this.getOvertimeCategory();
    });
    // eslint-disable-next-line max-len
    this.setState({ urlData: `${ls.get('ws_ip')}/empovertimehist` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.OVERTIME_TYPE'),
      t('LBL.OVERTIME_DATE'),
      t('LBL.OVERTIME_TIME'),
      t('FRM.FUNCTION'),
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getOvertimeCategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/payovertimecategory/getAllItem/`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ arrOvertimeType: array });
        } else {
          wf.map((obj) => array.push({ value: obj.oct_id, label: obj.name }));
          this.setState({ arrOvertimeType: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  toggle(x) {
    if (x === 'add') {
      this.props.destroy();
      this.setState({
        modal: !this.state.modal,
      });
    }
    if (x === 'close') {
      this.setState({
        modal: !this.state.modal,
      });
    }
  }

  convertDate(str) {
    const date = new Date(str);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }

  convertTime(str) {
    const date = new Date(str);
    const hour = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return [hour, minutes].join(':');
  }

  mapFormData(b, a) {
    let val_b;
    if (b !== '') {
      if (a.indexOf('_time') !== -1) {
        val_b = this.convertTime(b);
      } else if (a.indexOf('_date') !== -1) {
        val_b = this.convertDate(b);
      } else if (typeof b === 'object') {
        val_b = b.value;
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  handleSubmit(values) {
    console.log(values);
    const empId = this.props.parmid.state.usrid;
    const { apiws, token, idUser } = this.state;
    let oct_id;
    if (typeof values.oct_id !== 'undefined') {
      if (values.oct_id !== null) {
        oct_id =
          typeof values.oct_id === 'object'
            ? values.oct_id.value
            : values.oct_id;
      } else {
        oct_id = '';
      }
    } else {
      oct_id = '';
    }

    const overtime_date =
      typeof values.overtime_date === 'undefined' ||
      values.overtime_date === null
        ? null
        : moment(values.overtime_date).format('YYYY-MM-DD');
    const overtime_start_time =
      typeof values.overtime_start_time === 'undefined' ||
      values.overtime_start_time === null
        ? null
        : moment(values.overtime_start_time).format('HH:mm');
    const overtime_end_time =
      typeof values.overtime_end_time === 'undefined' ||
      values.overtime_end_time === null
        ? null
        : moment(values.overtime_end_time).format('HH:mm');
    const reason =
      typeof values.reason === 'undefined' || values.reason === null
        ? ''
        : values.reason;

    const diff = values.overtime_end_time.diff(values.overtime_start_time);

    // execution
    const duration = moment.utc(diff).format('HH:mm');
    console.log(moment(overtime_date) + moment(duration));
    const data = {
      oct_id,
      overtime_date,
      overtime_start_time,
      overtime_end_time,
      reason,
      duration,
    };

    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let add;
    let action;
    let link;
    let body;
    if (typeof values.eoh_id === 'undefined') {
      add = 1;
      action = 'POST';
      link = `${apiws}/empovertimehist`;
      body = [formBody, `cuser_id=${idUser}`, `emp_id=${empId}`].join('&');
    } else {
      add = 0;
      action = 'PUT';
      link = `${apiws}/empovertimehist/${values.eoh_id}`;
      body = [
        formBody,
        `eoh_id=${values.eoh_id}`,
        `muser_id=${idUser}`,
        `emp_id=${empId}`,
      ].join('&');
    }
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    axios(options).then((response) => {
      if (response.data.status === 'ok') {
        this.toggle('close');
        this.getTableData();
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
      }
    });
  }

  editFunc(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${apiws}/empovertimehist/${id}`, config).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        this.toggle('add');
        this.props.initialize({
          eoh_id: dataResponse[0].eoh_id,
          oct_id: dataResponse[0].oct_id,
          overtime_date: moment(dataResponse[0].overtime_date),
          overtime_start_time: moment(dataResponse[0].overtime_start_time),
          overtime_end_time: moment(dataResponse[0].overtime_end_time),
          reason: dataResponse[0].remarks,
        });
      }
    });
  }

  deleteFunc(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(
            `${apiws}/empovertimehist/${id}`,
            config,
            `duser_id=${userid}`,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, pristine, t } = this.props;
    const { urlData, arrOvertimeType } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.OVERTIME')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ paddingBottom: '10px' }}>
                  <h3 className="page-title">{t('LBL.OVERTIME_HISTORY')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('LBL.VIEW')} {t('LBL.OVERTIME_HISTORY')}
                  </h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="eoh_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteFunc}
                  updateFunc={this.editFunc}
                  onRef={(ref) => (this.child = ref)}
                  textHead={`${this.props.parmid.state.name} (${this.props.parmid.state.code}) `}
                  conds={this.props.parmid.state.usrid}
                  displayStyleHead=""
                  editCond={this.state.editcond}
                  addCond={this.state.addcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="m5">
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog modal-input"
            style={{ marginTop: '100px', maxWidth: '650px' }}
          >
            <ModalHeader>
              {t('FRM.ADD')} {t('LBL.OVERTIME')}
            </ModalHeader>
            <ModalBody>
              <form
                id="addovertimehistory"
                name="addovertimehistory"
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
              >
                <Field
                  name="eoh_id"
                  component={renderTextInput}
                  type="hidden"
                />
                <Container>
                  <Row>
                    <Col md={9} lg={9} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.DATE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="overtime_date"
                            component={renderDatePickerField}
                            placeholder="Start Date"
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9} lg={9} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.OVERTIME_TYPE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="oct_id"
                            component={renderSelectField}
                            options={arrOvertimeType}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '-13px' }}>
                    <Col md={9} lg={9} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.OVERTIME_DURATION')}
                        </span>
                        <div className="form__form-group-field">
                          <Row>
                            <Col md={6} xs={12}>
                              <Field
                                name="overtime_start_time"
                                component={renderTimePickerIntervalField}
                                placeholder="18:00"
                                formatdate="HH:mm"
                              />
                            </Col>
                            <Col md={6} xs={12}>
                              <Field
                                name="overtime_end_time"
                                component={renderTimePickerIntervalField}
                                placeholder="18:00"
                                formatdate="HH:mm"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9} lg={9} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.REASON')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="reason"
                            component="textarea"
                            type="text"
                            maxLength="255"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.toggle('close')}>
                {t('FRM.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                form="addovertimehistory"
                disabled={pristine}
              >
                {' '}
                {t('FRM.SAVE')}
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    overtime_date: {
      required: true,
      fieldLabel: translator('LBL.DATE'),
    },
    oct_id: {
      required: true,
      fieldLabel: translator('LBL.OVERTIME_TYPE'),
    },
    overtime_start_time: {
      required: true,
      fieldLabel: `${translator('LBL.OVERTIME_DURATION')} start`,
    },
    overtime_end_time: {
      required: true,
      // lockTimeAfter: 'overtime_start_time',
      fieldLabel: `${translator('LBL.OVERTIME_DURATION')} end`,
      // fieldLabelBefore: `${translator('LBL.OVERTIME_DURATION')} start`,
    },
  };
  const errors = utils.validate(validation, values);

  return errors;
};

export default reduxForm({
  form: 'LabelListForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(overtimeHistory));
