import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Layout from '../Layout/index';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderSelectField from '../../shared/components/form/Select';
import renderTextInput from '../../shared/components/form/TextInput';
import {
  faList,
  faThLarge,
  faPlus,
  faSearch,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import PlusIcon from 'mdi-react/PlusIcon';

import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import FormTask from './components/FormTask';

import './index.scss';

const TaskManagementEmpty = ({t}) => {

  const [activeId, setActiveId] = useState();
  const [items, setItems] = useState({});
  const [dataItems, setDataItems] = useState([]);
  const [addSection, setAddSection] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    isEdit: false,
    data: {
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      assignee: [],
      priority: '',
    }
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const toggleOffCanvas = (page) => {
    if (page == 'new') {
      setModalData({
        isEdit: false,
      });
      setModalOpen(!modalOpen);
    } else if (page == 'edit') {
      setModalData({
        isEdit: true,
      });
      setModalOpen(!modalOpen);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  return(
    <>
      <Layout />
      {modalOpen && (
        <FormTask toggleOffCanvas={toggleOffCanvas} modalData={modalData}/>
      )}
      <div className="container__wrap" id="tm__wrap">
        <Container className="pt-3">
          <div className='tm__inner'>
            <div className='tm__inner-header'>
              <Row>
                <Col md={4} className="mb-3 mb-md-0">
                  <div className='d-flex align-items-center gap-3'>
                    <UncontrolledDropdown>
                      <DropdownToggle className="dropdown__sample-project">
                        Select Project
                        <FontAwesomeIcon icon={faCaretDown} className="fa-fw pl-2" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown__sample-project__menu">
                        <div className='dropdown__menu-content'>
                          <div className='menu-content__header'>
                            <h5>Projects</h5>
                          </div>
                          <div className='menu-content__search'>
                            <form className="form">
                              <div className="d-flex position-relative w-100">
                                <Field name="search_project" component={renderTextInput} placeholder='Search Project'/>
                                <div className="icon-wrapper">
                                  <FontAwesomeIcon icon={faSearch} />
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className='menu-content__project-items'>
                            <div className='project-items__empty'>
                              <img src={process.env.PUBLIC_URL + '/img/empty-task.svg'} width={150} height={150} />
                              <h4 className='text-primary'>Start your first project</h4>
                              <p className='mt-0'>Click on + the button</p>
                            </div>
                          </div>
                          <div className='menu-content__project-footer'>
                            <button>
                              <FontAwesomeIcon icon={faList} className="mr-2" />
                              Project List
                            </button>
                          </div>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <button className='btn btn-primary tm__add-project-button'>
                      <FontAwesomeIcon icon={faPlus} className="fa-fw" />
                    </button>
                  </div>
                </Col>
                <Col md={8}>
                  <Row className="align-items-center ml-0">
                    <Col md={2} className="ml-auto text-right pl-0 mb-3 mb-md-0">
                      <button className='btn-filter'>
                        <FontAwesomeIcon icon={faList} className="fa-fw" />
                      </button>
                      <button className='btn-filter'>
                        <FontAwesomeIcon icon={faThLarge} className="fa-fw" />
                      </button>
                    </Col>
                    <Col md={4} className="pl-0 mb-3 mb-md-0">
                      <Field
                        name="status"
                        component={renderSelectField}
                        placeholder="Select Status"
                        className='select-status'
                      />
                    </Col>
                    <Col md={5} className="pl-0 mb-3 mb-md-0">
                      <div className='select-priority d-flex align-items-center'>
                        <Field
                          name="priority"
                          component={renderSelectField}
                          placeholder="Select Priority"
                          className='select-prority'
                        />
                        <button
                          className="btn btn-primary btn-add-task"
                          style={{
                            whiteSpace: 'nowrap'
                          }}
                          onClick={() => toggleOffCanvas('new')}
                        >
                          Add Task
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='tm__inner-wrapper'>
              {/* Blank Container / No Task */}
            </div>
            {
              addSection ?
              <button className='button__add-section px-0 text-muted' onClick={() => setAddSection(!addSection)}>
                <PlusIcon className='mr-2' style={{height: 14, width: 14}}/>
                Add Section
              </button> :
              <Col md={6} className="px-0 mt-2">
                <form className="form">
                  <Field
                    name="add-section"
                    component={renderTextInput}
                    placeholder="Add Section"
                  />
                  <div className='mt-3'>
                    <button className='btn btn-secondary' onClick={() => setAddSection(!addSection)}>
                      Cancel
                    </button>
                    <button className='btn btn-primary'>
                      Save
                    </button>
                  </div>
                </form>
              </Col>
            }
            <div className='empty-task text-center'>
              <img src={process.env.PUBLIC_URL + '/img/empty-task.svg'} width={320} height={320} />
              <h4 className='text-primary'>No Task Yet</h4>
              <p className='mt-0'>Add your to-dos keep track of them across Workspace</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );

  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the container0 droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length)
        ]
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
      }));
    }

    setActiveId(null);
  }

}

export default reduxForm({
  form: 'form-task-management',
  destroyOnUnmount: true,
})(translate('global')(TaskManagementEmpty));