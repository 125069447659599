import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from 'reactstrap';
import Chart from 'react-apexcharts';
import Layout from '../../../Layout/index';

import './styleOkr.css';
import { translate } from 'react-i18next';
import renderSelectField from '../../../../shared/components/form/Select';
import { reduxForm, Field } from 'redux-form';
import DataTable from 'react-data-table-component';
import DataTablePagination from '../../../../shared/components/DataTable/DataTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OkrContext } from './OkrContext';
import {
  faSearch,
  faDownload,
  faKey,
  faCircle,
} from '@fortawesome/fontawesome-free-solid';
import renderTextInput from '../../../../shared/components/form/TextInput';
import axios from 'axios';
import ls from 'local-storage';

const OkrDetail = ({ filter, t }) => {
  const apiws = ls.get('ws_ip');
  const token = ls.get('token');
  const [inpt, setInpt] = useState('');

  const [state, dispatch] = useContext(OkrContext);

  const [data, setData] = useState({
    data: state?.okrDetail,
    loading: false,
    totalRows: state?.okrDetailTotal,
    perPage: 10,
    page: 1,
    column: '',
    sortDirection: '',
    query: '',
    trigger: false,
  });

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      data: state?.okrDetail,
      totalRows: state?.okrDetailTotal,
    }));
  }, [state?.okrDetail]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'to',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    pagination: true,
  };

  const ProgressDistribution = ({ props, data, id }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [dataPer, setDataPer] = useState({
      completed: 0,
      ontrack: 0,
      delayed: 0,
      onhold: 0,
      cancelled: 0,
      notstarted: 0,
    });

    const toggleTooltip = (item) => {
      setTooltipOpen(!tooltipOpen);
      if (item == props.team_id) {
        setDataPer((prev) => ({
          ...prev,
          completed: props.completed,
          ontrack: props.ontrack,
          delayed: props.delayed,
          onhold: props.onhold,
          cancelled: props.cancelled,
          notstarted: props.notstarted,
        }));
      }
    };

    return (
      <div
        className="tooltip-status"
        id={`team_${id}`}
        onMouseOver={() => {
          toggleTooltip(props.team_id);
        }}
        onMouseOut={() => {
          toggleTooltip();
        }}
      >
        <Progress multi>
          <Progress bar color="success" value={props.completed}></Progress>
          <Progress bar color="primary" value={props.ontrack}></Progress>
          <Progress bar color="secondary" value={props.delayed}></Progress>
          <Progress bar color="warning" value={props.onhold}></Progress>
          <Progress bar color="danger" value={props.cancelled}></Progress>
          <Progress bar color="light" value={props.notstarted}></Progress>
        </Progress>
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={`team_${id}`}
          className="tooltip-custom"
        >
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              className="text-success"
              size="sm"
            />{' '}
            Completed: {dataPer.completed ?? 0}%
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              className="text-primary"
              size="sm"
            />{' '}
            On Track: {dataPer.ontrack ?? 0}%
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              className="text-secondary"
              size="sm"
            />{' '}
            Delayed: {dataPer.delayed ?? 0}%
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              className="text-warning"
              size="sm"
            />{' '}
            On Hold: {dataPer.onhold ?? 0}%
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              className="text-danger"
              size="sm"
            />{' '}
            Canceled: {dataPer.cancelled ?? 0}%
          </div>
          <div>
            <FontAwesomeIcon icon={faCircle} className="text-light" size="sm" />{' '}
            Not Started: {dataPer.notstarted ?? 0}%
          </div>
        </Tooltip>
      </div>

      // Modal
    );
  };

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        width: '100px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        width: '100px',
      },
    },
  };

  const columns = [
    {
      name: t("LBL.TEAM"),
      selector: (row) => row.team,
    },
    {
      name: t("LBL.TOTALOBJ"),
      selector: (row) => row.obj,
    },
    {
      name: t("LBL.TOTALKR"),
      selector: (row) => row.key,
    },
    {
      name: t("LBL.PROGRESS") + ' (%)',
      selector: (row) => <span>{row.progress ?? 0}%</span>,
    },
    {
      name: t("LBL.STATUSDIST"),
      cell: (row, id) => (
        <div className="w-100">
          <ProgressDistribution props={row} data={data?.data} id={id} />
        </div>
      ),
    },
  ];

  const search = (val) => {
    const form = new FormData();

    form.append('query', val);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${ls.get('ws_ip')}/okrobjective/getOkrByTeam`, form, {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        setData({
          ...data,
          data: res.data.data,
          totalRows: res.data.total,
        });
        // setTotalRows(res.data.total);
      });
  };

  // useEffect(()=>{
  //   search()
  // }, [data.query])

  return (
    <Row className="pt-4">
      <Col md={12}>
        <div className="card-okr-dashboard">
          <div className="mb-4 title-dashboard-okr">{t("LBL.OKRDETAILS")}</div>
          <Col md={4} className="search-table">
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    name="team"
                    component={renderTextInput}
                    placeholder="Search Team..."
                    type="text"
                    onChange={(el) => {
                      console.log(el.currentTarget.value);
                      if (el.currentTarget.value == '') {
                        setData((prev) => ({ ...prev, query: '' }));
                      } else {
                        setData((prev) => ({
                          ...prev,
                          query: el.currentTarget.value,
                        }));
                      }

                      search(el.currentTarget.value);
                    }}
                  />
                  <div className="form__form-group-icon">
                    <span class="pl-1 pr-1">
                      <FontAwesomeIcon color="black" icon={faSearch} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <DataTable
            columns={columns}
            data={data.data}
            direction="auto"
            responsive
            striped
            customStyles={customStyles}
            paginationComponent={DataTablePagination}
            paginationServer
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationTotalRows={data.totalRows}
            onChangeRowsPerPage={(newPerPage, page) => {
              const form = new FormData();

              form.append('query', data.query);
              form.append('page', page);
              form.append('per_page', newPerPage);
              form.append('sort', data.column || 'title');
              form.append('order', data.sortDirection || 'asc');
              form.append('filter', JSON.stringify(filter));

              axios
                .post(`${ls.get('ws_ip')}/okrobjective/getOkrByTeam`, form, {
                  headers: {
                    Authorization: `bearer ${ls.get('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then((res) => {
                  setData({
                    ...data,
                    data: res.data.data,
                    perPage: newPerPage,
                    page,
                    totalRows: res.data.total,
                  });
                });
            }}
            onChangePage={(page) => {
              const form = new FormData();

              form.append('query', data.query);
              form.append('page', page);
              form.append('per_page', data.perPage);
              form.append('sort', data.column || 'title');
              form.append('order', data.sortDirection || 'asc');
              form.append('filter', JSON.stringify(filter));

              axios
                .post(`${ls.get('ws_ip')}/okrobjective/getOkrByTeam`, form, {
                  headers: {
                    Authorization: `bearer ${ls.get('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then((res) => {
                  setData({
                    ...data,
                    data: res.data.data,
                    page,
                    totalRows: res.data.total,
                  });
                });
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default translate('global')(OkrDetail) ;
