import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import config from '../../../../shared/components/config/config';

class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      origin: window.location.origin,
      ipws: config.ws.ip,
      email: '',
      spanError: 'none',
      textError: '',
      resetForm: true,
      title: 'Forgot Password',
      message: 'Enter your email address to reset your password',
      subdomain: config.subdomain,
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmail(event) {
    this.setState({
      email: event.target.value,
      spanError: 'none',
    });
  }

  handleSubmit(event) {
    const { origin, email, subdomain } = this.state;
    // console.log(origin);
    if (!email) {
      this.setState({
        textError: 'The Email field is empty',
        spanError: '',
      });
    }
    fetch(`${this.state.ipws}/login/forgotPassword`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `email=${email}&origin=${origin}&subdomain=${subdomain}`,
    }).then(res => res.json()).then(
      (result) => {
        if (result.status === 'ok') {
          this.setState({
            title: 'Please Check your email',
            message: result.message,
            spanError: 'none',
            resetForm: false,
          });
        } else {
          this.setState({
            textError: result.message,
            spanError: '',
          });
        }
      },
      () => {
        // console.log(error);
      },
    );
    event.preventDefault();
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      spanError,
      textError,
      resetForm,
      title,
      message,
    } = this.state;
    let viewForm;
    if (resetForm) {
      viewForm = (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span
              className="form__form-group-error"
              style={
                {
                  background: '#ffbcbc',
                  borderRadius: '3px',
                  position: 'absolute',
                  padding: '5px 10px 5px 10px',
                  margin: '-25px 0px 0px 0px',
                  width: '100%',
                  display: spanError,
                  fontSize: '14px',
                  fontStyle: 'italic',
                }
              }
            >
              {textError}
            </span>
          </div>
          <div className="form__form-group form__form-group--forgot">
            <div className="form__form-group-field">
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="Email"
                onChange={this.handleEmail}
                style={{
                  borderRadius: '3px',
                  height: '45px',
                  fontSize: '16px',
                }}
              />
            </div>
          </div>
          <div className="account__btns">
            <button
              onClick={this.handleSubmit}
              className="btn btn-primary account__btn"
              color="primary"
              type="submit"
              style={{ fontSize: '16px' }}
            >
              Reset My Password
            </button>
          </div>
        </form>
      );
    }
    return (
      <>
        <div className="account__profile">
          <div
            className="account__logo"
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              marginBottom: '50px',
            }}
          />
          <p
            className="account__name"
            style={{
              textTransform: 'capitalize',
              paddingBottom: '30px',
              fontSize: '32px',
            }}
          >{title}
          </p>
          <p className="account__sub" style={{ fontSize: '14px' }}>{message}</p>
        </div>
        {viewForm}
        <div className="account__btns">
          <Link style={{ margin: '0px' }} className="btn btn-default account__btn" to="/">Back to Sign in</Link>
        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'forgot_passsword_form', // a unique identifier for this form
})(ForgotPasswordForm);
