/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/*
 * Page Payroll Preference
 * Notes: Setting payroll tax and bpjs
 * @author Gerdi
 * @date 05/08/2019
 * Modification (please note below)
 */
/* eslint-disable react/sort-comp */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm, reset } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import showNotifications from '../../../Preferences/notificationMessages';
import NumberFormat from 'react-number-format';

import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';

class Preferences extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      button_disabled: false,
      status_edit: false,
      disabled_form: false,
      component_pjtp: [],
      component_ptm: [],
      pycrat: [],
      token: '',
      apiws: '',
      pycJkkName: '',
      iduser: '',
      paybpjstype: [],
      arr: [],
      myj: '',
      editcond: '',
      addcond: '',
      deletecond: '',
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.getById = this.getById.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTaxMethod = this.getTaxMethod.bind(this);
  }
  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState(
      {
        iduser: ls.get('user_cred').usr_id,
        apiws: ls.get('ws_ip'),
      },
      () => {
        this.getById();
        this.getTaxMethod();
        this.getButton();
      },
    );
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTaxMethod() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const arr = [];
    axios
      .post(`${apiws}/paytaxmethod/getAll`, '', config)
      .then(async (restype) => {
        const arrtype = [];
        const count_type = restype.data.data.length;
        for (let i = 0; i < count_type; i++) {
          arrtype.push({
            value: restype.data.data[i].ptm_id,
            label: restype.data.data[i].tax_method,
          });
        }
        // arr.push({

        // });
        this.setState({ component_ptm: arrtype });
        // this.setState({ arrrate: arr });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getById() {
    const { apiws, token, iduser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/paytaxpreferences/getAll`, '', config)
      .then((response) => {
        const dataresponse = response.data.data;
        if (response.data.data.length === 0) {
          this.setState({ disabled_form: false });
          this.setState({ status_edit: false });
        } else {
          this.setState({ disabled_form: true });
          this.setState({ status_edit: true });
          const round = dataresponse[0].rounded;
          const dataround = round === 1;

          // const ptmid = response.data.data[0].ptm_id;
          // const { rounded } = dataresponse[0];
          this.setState({ myj: response.data.data[0].max_yearly_job_cost });
          this.props.initialize({
            ptf_id: response.data.data[0].ptf_id,
            ptm_id: response.data.data[0].ptm_id,
            job_percentage: response.data.data[0].job_percentage,
            rounded: dataround,
            non_npwp_guarantor: response.data.data[0].non_npwp_guarantor,
            penalty: response.data.data[0].penalty_non_npwp_percentage,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabled_form: false });
    this.setState({ status_edit: false });
    this.setState({ button_disabled: false });
  }

  handleSubmit(value) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let url;
    const error = true;
    const apiws = ls.get('ws_ip');
    const userid = ls.get('user_cred').usr_id;
    const id = value.ptf_id;
    const ptm_id =
      typeof value.ptm_id === 'object' ? value.ptm_id.value : value.ptm_id;
    const non_npwp_guarantor =
      typeof value.non_npwp_guarantor === 'object'
        ? value.non_npwp_guarantor.value
        : value.non_npwp_guarantor;
    const max_yearly_job = this.state.myj;
    const rounded = value.rounded === false ? 0 : 1;
    let data;

    const mappingData = {
      ...value,
      max_yearly_job,
      ptm_id,
      rounded,
      non_npwp_guarantor,
    };
    if (id === undefined || id === '') {
      url = `${apiws}/paytaxpreferences/saveItem`;
      data = { ...mappingData, cuser_id: userid };
    } else {
      url = `${apiws}/paytaxpreferences/updateItem/${id}`;
      data = { ...mappingData, muser_id: userid };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ disabled_form: true });
            this.setState({ status_edit: true });
            this.child.reload();
            // this.getById();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }
  render() {
    const { handleSubmit, t } = this.props;
    const {
      status_edit,
      buttonDisabled,
      disabled_form,
      component_ptm,
    } = this.state;
    let button_action;
    if (status_edit) {
      button_action = (
        <Button
          color="success"
          onClick={this.handleEdit}
          type="button"
          style={{ display: this.state.editcond === '1' ? '' : 'none' }}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <ButtonToolbar>
          <Button color="secondary" onClick={this.getById} type="button">
            {' '}
            {t('FRM.CANCEL')}
          </Button>
          <Button
            color="primary"
            type="submit"
            form="payformpreferences"
            disabled={buttonDisabled}
            style={{ display: this.state.addcond === '1' ? '' : 'none' }}
          >
            {' '}
            {t('FRM.SAVE')}
          </Button>
        </ButtonToolbar>
      );
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            {/* <h4>{t('MEN.PREFERENCES')}</h4>
            <hr /> */}
            <form
              className="form form--horizontal"
              name="payformpreferences"
              id="payformpreferences"
              onSubmit={handleSubmit(this.handleSubmit)}
            >
              <input type="hidden" name="ptf_id" value="" />
              <Row className="w-100">
                <Col xs={12} md={12} lg={6} xl={6} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TAX_METHOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ptm_id"
                        component={renderSelectField}
                        placeholder="----"
                        options={component_ptm}
                        disabled={disabled_form}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={12} md={12} lg={6} xl={6} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.JOB_PERCENTAGE')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '150px' }}
                    >
                      <Field
                        name="job_percentage"
                        component={renderTextInput}
                        type="number"
                        disabled={disabled_form}
                      />
                      <span
                        className="form__form-group-label"
                        style={{ 'margin-left': '10px' }}
                      >
                        %
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={12} md={12} lg={6} xl={6} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.MAX_YEARLY_JOB')}
                    </span>
                    <div className="form__form-group-field number-form">
                      <NumberFormat
                        name="max_yearly_job"
                        thousandSeparator="."
                        decimalSeparator=","
                        value={this.state.myj}
                        disabled={disabled_form}
                        onValueChange={(val) => {
                          this.setState({ myj: val.floatValue });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={12} md={12} lg={6} xl={6} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.NON_NPWP_GUARANTOR')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="non_npwp_guarantor"
                        component={renderSelectField}
                        placeholder="---"
                        options={[
                          { value: '1', label: 'Company' },
                          { value: '2', label: 'Employee' },
                        ]}
                        disabled={disabled_form}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={12} md={12} lg={6} xl={6} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.PENALTY_NON_NPWP')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '150px' }}
                    >
                      <Field
                        name="penalty"
                        component={renderTextInput}
                        disabled={disabled_form}
                        type="number"
                      />
                      <span
                        className="form__form-group-label"
                        style={{ 'margin-left': '10px' }}
                      >
                        %
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={12} md={12} lg={6} xl={6} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.ROUNDED')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="rounded"
                        component={renderCheckBoxField}
                        disabled={disabled_form}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <ButtonToolbar className="form__button-toolbar">
                {button_action}
              </ButtonToolbar> */}
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'paypreferencesform',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Preferences));
