/* eslint-disable camelcase */
/* eslint-disable max-len, object-curly-newline */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
// import MyTeam from './MyTeam';
import ls from 'local-storage';
import axios from 'axios';
import ProjectMember from './MyTeamMember';
import PropTypes from 'prop-types';
import { store } from '../../../App/store';
import utils from '../../../../utils';
import { connect } from 'react-redux';

const Avatar = `${process.env.PUBLIC_URL}/img/user-default-photo.png`;
const NotFoundImg = `${process.env.PUBLIC_URL}/img/not-found-ord.png`;

class AccountHierarchy extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  constructor() {
    super();
    this.state = {
      activeTab: '1',
      apiws: ls.get('ws_ip'),
      token: ls.get('token'),
      idUser: ls.get('user_cred').usr_id,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount = () => {
    if (Object.keys(this?.props?.user?.hierarcy).length == 0) {
      this.getData();
    }
  };

  getData() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let getDataUser = true;
    if (idUser === 0) getDataUser = false;

    if (getDataUser) {
      axios
        .post(`${apiws}/appuser/getAllItemById/${idUser}`, '', config)
        .then((response) => {
          let empId = '';
          if (response.data.data.length !== 0) {
            empId = response.data.data[0].emp_id;
          }

          if (empId !== '') {
            const reloadState = this;
            const subs = [];
            const sups = [];
            const peers = [];

            // Subs Data
            axios
              .post(`${apiws}/emphierarchy/getAllItemSub/${empId}`, '', config)
              .then((subsResponse) => {
                if (subsResponse.data.data.length > 0) {
                  for (let i = 0; i < subsResponse.data.data.length; i += 1) {
                    let path_photo;
                    if (
                      subsResponse.data.data[i].path_photo === null ||
                      subsResponse.data.data[i].path_photo === '' ||
                      subsResponse.data.data[i].path_photo === 'undefined'
                    ) {
                      path_photo = Avatar;
                    } else {
                      path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                        subsResponse.data.data[i].path_photo
                      }`}`;
                    }
                    subs.push({
                      emp_name: subsResponse.data.data[i].emp_name,
                      position_name: subsResponse.data.data[i].position_name,
                      path_photo,
                    });
                  }

                  store.dispatch({ type: 'SET_USER_HIERARCY', data: { subs } });
                }
              })
              .catch(() => {
                // eslint-disable-next-line no-console
                // console.log('subs');
                // eslint-disable-next-line no-console
                // console.log(error);
              });
            // Sups Data
            axios
              .post(`${apiws}/emphierarchy/getAllItemSup/${empId}`, '', config)
              .then((supsResponse) => {
                if (supsResponse.data.data.length > 0) {
                  for (let i = 0; i < supsResponse.data.data.length; i += 1) {
                    let path_photo;
                    if (
                      supsResponse.data.data[i].path_photo === null ||
                      supsResponse.data.data[i].path_photo === '' ||
                      supsResponse.data.data[i].path_photo === 'undefined'
                    ) {
                      path_photo = Avatar;
                    } else {
                      path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                        supsResponse.data.data[i].path_photo
                      }`}`;
                    }
                    sups.push({
                      emp_name: supsResponse.data.data[i].emp_name,
                      position_name: supsResponse.data.data[i].position_name,
                      path_photo,
                    });
                  }

                  store.dispatch({ type: 'SET_USER_HIERARCY', data: { sups } });
                }
              })
              .catch(() => {
                // eslint-disable-next-line no-console
                // console.log('sups');
                // eslint-disable-next-line no-console
                // console.log(error);
              });
            // Peer Data
            axios
              .post(`${apiws}/emphierarchy/getAllItemPeer/${empId}`, '', config)
              .then((peersResponse) => {
                if (peersResponse.data.data.length > 0) {
                  for (let i = 0; i < peersResponse.data.data.length; i += 1) {
                    let path_photo;
                    if (
                      peersResponse.data.data[i].path_photo === null ||
                      peersResponse.data.data[i].path_photo === '' ||
                      peersResponse.data.data[i].path_photo === 'undefined'
                    ) {
                      path_photo = Avatar;
                    } else {
                      path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                        peersResponse.data.data[i].path_photo
                      }`}`;
                    }
                    peers.push({
                      emp_name: peersResponse.data.data[i].emp_name,
                      position_name: peersResponse.data.data[i].position_name,
                      path_photo,
                    });
                  }

                  store.dispatch({
                    type: 'SET_USER_HIERARCY',
                    data: { peers },
                  });
                }
              })
              .catch(() => {
                // eslint-disable-next-line no-console
                // console.log('peers');
                // eslint-disable-next-line no-console
                // console.log(error);
              });
          }
        });
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <CardBody className="tabs__account_hierarchy">
        <div className="tabs tabs--justify tabs--bordered-bottom">
          <div className="tabs__wrap tabs_border-top">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '1',
                  })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  {t('LBL.SUPER_ORD')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '2',
                  })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  {t('LBL.SUB_ORD')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '3',
                  })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  {t('LBL.PEER')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {!this?.props?.user?.hierarcy?.sups ? (
                  <div className="not-found-member">
                    <div className="img-not-found">
                      <img
                        src={NotFoundImg}
                        alt="not-found"
                        style={{ width: '145px', height: '160px' }}
                      />
                    </div>
                    <div className="not-found-msg">
                      <h4>{t('LBL.DATA_NOT_FOUND')}</h4>
                      <p>{t('LBL.RELAX')}</p>
                    </div>
                  </div>
                ) : (
                  this?.props?.user?.hierarcy?.sups?.map((supsItem, index) => (
                    <div key={`superior_${index}`}>
                      <ProjectMember
                        img={supsItem.path_photo}
                        name={`${supsItem.emp_name}`}
                        link="/chat"
                        linkStatus="/chat1"
                        post={`${supsItem.position_name}`}
                        value="0"
                      />
                    </div>
                  ))
                )}
              </TabPane>
              <TabPane tabId="2">
                {!this?.props?.user?.hierarcy?.subs ? (
                  <div className="not-found-member">
                    <div className="img-not-found">
                      <img
                        src={NotFoundImg}
                        alt="not-found"
                        style={{ width: '145px', height: '160px' }}
                      />
                    </div>
                    <div className="not-found-msg">
                      <h4>{t('LBL.DATA_NOT_FOUND')}</h4>
                      <p>{t('LBL.RELAX')}</p>
                    </div>
                  </div>
                ) : (
                  this?.props?.user?.hierarcy?.subs?.map((subsItem, index) => (
                    <div key={`subordinate_${index}`}>
                      <ProjectMember
                        img={subsItem.path_photo}
                        name={`${subsItem.emp_name}`}
                        link="/chat"
                        linkStatus="/chat1"
                        post={`${subsItem.position_name}`}
                        value="0"
                      />
                    </div>
                  ))
                )}
              </TabPane>
              <TabPane tabId="3">
                {!this?.props?.user?.hierarcy?.peers ? (
                  <div className="not-found-member">
                    <div className="img-not-found">
                      <img
                        src={NotFoundImg}
                        alt="not-found"
                        style={{ width: '145px', height: '160px' }}
                      />
                    </div>
                    <div className="not-found-msg">
                      <h4>{t('LBL.DATA_NOT_FOUND')}</h4>
                      <p>{t('LBL.RELAX')}</p>
                    </div>
                  </div>
                ) : (
                  this?.props?.user?.hierarcy?.peers?.map(
                    (peersItem, index) => (
                      <div key={`peer_${index}`}>
                        <ProjectMember
                          img={peersItem.path_photo}
                          name={`${peersItem.emp_name}`}
                          link="/chat"
                          linkStatus="/chat1"
                          post={`${peersItem.position_name}`}
                          value="0"
                        />
                      </div>
                    ),
                  )
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </CardBody>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(translate('global')(AccountHierarchy));
