/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import LeaveReport from '../../Report/ESSreport/LeaveReport/index';
import AttendanceSummary from '../../Report/ESSreport/AttendanceSummary/index';
import OvertimeReport from '../../Report/ESSreport/OvertimeReport/index';
import ClaimReport from '../../Report/ESSreport/ClaimReport/index';
import LoanReport from '../../Report/ESSreport/LoanReport/index';
import ClaimBalanceReport from '../../Report/ESSreport/ClaimBalanceReport/index';
import LeaveBalanceReport from '../../Report/ESSreport/LeaveBalanceReport/index';
// import Attendance from '../AttendanceReport';
import AttendanceDispensation from '../../Report/ESSreport/AttendanceDispensation';
import ls from 'local-storage';
import axios from 'axios';
import PropTypes from 'prop-types';
import Authenticator from '../../Authenticator';

const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class PayrollSettingTabPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeTab: '1',
      page: '1',
      menu: [],
      token: '',
      apiws: '',
      idUser: '',
      men_id: '',
      fga_id: '',
      validateToken: false,
    };
  }
  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getReference();
    });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({
        men_id: location.state.men_id,
        fga_id: location.state.fga_id,
      });
    }
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(`${apiws}/menu/getreference/`, formBody, config).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map((obj) =>
          array.push({ men_id: obj.men_id, label: obj.lbl_code }),
        );
        this.setState({
          menu: array,
        });
        console.log(this.state.menu);
      }
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { page, validateToken } = this.state;
    return (
      <Container>
        <Card>
          <CardBody className="p0">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img
                    className="header-icon"
                    src={HeaderIcon}
                    alt="attendance"
                  />
                  <h3 className="page-title">{t('MEN.ESS_REPORT')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('MEN.ESS_REPORT')}
                  </h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  {this.state.menu.map((data, index) => (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            this.state.activeTab === (index + 1).toString(),
                        })}
                        onClick={() => {
                          this.toggle((index + 1).toString());
                        }}
                      >
                        <span className="navlink_tab-title">
                          {t(data.label)}
                        </span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.menu.map(
                    (data, index) =>
                      (data.label === 'LBL.LEAVE_REPORT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <LeaveReport
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.OVERTIME_REPORT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <OvertimeReport
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.ATTENDANCE_DISPENSATION' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <AttendanceDispensation
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'MEN.CLAIM_REPORT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <ClaimReport
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      /* indra */
                      (data.label === 'MEN.CLAIM_BALANCE_REPORT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <ClaimBalanceReport
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'MEN.LEAVE_BAL_REPORT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <LeaveBalanceReport
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )),
                      // ||
                      // data.label === 'MEN.LOAN_REPORT' &&
                      // (
                      //   <TabPane tabId={(index + 1).toString()}>
                      //     <LoanReport
                      //       men_id={data.men_id}
                      //       fga_id={this.state.fga_id}
                      //     />
                      //   </TabPane>
                      // )
                  )}
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(PayrollSettingTabPage);
