/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent, useState, useRef } from 'react';
import {
  Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Modal,
  ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import moment from 'moment';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File


// let compthr = '';
class Attribut extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    documentSettingFunc: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dct_id: PropTypes.string.isRequired,
    // onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      editorHasChange: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['10%', '20%', '20%', '25%', '25%', '25%'],
      statusEdit: false,
      urlData: '',
      editorValue: '',
      valueEditor: '',
      sunEditorButtonList: [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        '/', // Line break
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video', 'audio'], // You must add the 'katex' library at options to use the 'math' plugin.
        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
        ['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        // ['save', 'template']
      ],
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.OFFICE') },
      { '2': t('LBL.VALUE') },
      { '3': t('LBL.START_DATE') },
      { '4': t('LBL.END_DATE') },
      { '5': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editattribut = this.editattribut.bind(this);
    this.deleteattribut = this.deleteattribut.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.OFFICE') },
      { '2': t('LBL.VALUE') },
      { '3': t('LBL.START_DATE') },
      { '4': t('LBL.END_DATE') },
      { '5': t('FRM.FUNCTION') },
    ];
  }


  getOffice() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ orgoffice: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  toggle() {
    this.props.destroy();
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
  }

  getDocumentId() {
    console.log(this.props.dct_id);
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/appdocument/getAllItemById/${this.props.dct_id}`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        // this.setState({ modal: true }, () => {
        //   this.props.destroy();
        // });
        // console.log(getResponseData[0]);
        this.props.destroy();
        this.props.initialize(getResponseData[0]);
        this.setState({ valueEditor: getResponseData[0].file });
      } else {
        // this.setState({ button_disabled: false });
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      // this.setState({ button_disabled: false });
    });
  }

  componentDidMount = () => {
    console.log(this.props.dct_id);
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getOffice();
      if (this.props.dct_id !== '') {
        this.getDocumentId();
        this.setState({ statusEdit: true });
      }
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  }

  handleChangeEditor(values) {
    // console.log(values);
    this.setState({ editorValue: values, editorHasChange: true });
  }

  handleSubmitInput(values) {
    const {
      apiws,
      iduser,
      token,
    } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.dct_id;
    const userid = ls.get('user_cred').usr_id;
    const editor = this.state.editorValue;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/appdocument/saveItem`;
      data = {
        ...values,
        file: editor,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/appdocument/updateItem/${id}`;
      data = {
        ...values,
        file: editor,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.props.documentSettingFunc();
        // this.setState({ modal: false });
        setTimeout(() => {
        }, 2000);
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  async editattribut(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios.post(
      `${apiws}/appdocument/getAllItemById/${id}`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        const datamaping = {
          pmp_id: getResponseData[0].pmp_id,
          off_id: getResponseData[0].off_id,
          value: getResponseData[0].ump_value,
          start_date: getResponseData[0].start_date === null ? getResponseData[0].start_date : moment(getResponseData[0].start_date),
          end_date: getResponseData[0].end_date === null ? getResponseData[0].end_date : moment(getResponseData[0].end_date),
        };
        this.setState({ statusEdit: true });
        this.setState({ modal: true }, () => {
          this.props.destroy();
          this.props.initialize(datamaping);
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledinput: false });
    this.setState({ statusedit: false });
    this.setState({ buttondisabled: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusedit: true });
  }

  deleteattribut(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payump/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.child.reload('delete');
            }, 2000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }

  render() {
    const {
      handleSubmit, t, pristine, documentSettingFunc,
    } = this.props;
    const {
      component,
      buttondisabled,
      disabledinput,
      statusEdit,
      urlData,
    } = this.state;

    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('LBL.PAY_UMP')}
      </Button>
    );
    const backBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={documentSettingFunc}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="adddocument"
          style={{ margin: '10px' }}
        // disabled={pristine}
        > {t('FRM.EDIT')}
        </Button>);
    } else {
      button_action = (
        <Button
          color="primary"
          form="adddocument"
          style={{ margin: '10px' }}
          type="submit"
        // disabled={pristine && !this.state.editorHasChange}
        >{t('FRM.SAVE')}
        </Button>);
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '5px 5px 10px 10px' }} >
                <form id="adddocument" className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmitInput)}>
                  <div className="row">
                    <div className="col-md-12">
                      {backBtn}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.DOCUMENT')}</span>
                    <div className="form__form-group-field">
                      <input type="hidden" name="dct_id" />
                      <Field
                        name="document"
                        component={renderTextInput}
                        type="text"
                      />
                    </div>
                  </div>
                  <SunEditor
                    height="500px"
                    onChange={this.handleChangeEditor}
                    setContents={this.state.valueEditor}
                    setOptions={{
                      buttonList: this.state.sunEditorButtonList,
                    }}
                  />
                  {button_action}
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    );
  }
}

export default reduxForm({
  form: 'adddocument',
  // onSubmit: submit,
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Attribut));
