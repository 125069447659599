import React, { useEffect, useState, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import renderTextInput from '../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import renderSelectField from '../../../shared/components/form/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../containers/Preferences/notificationMessages';
import moment from 'moment';

import './styles/formTask.scss';

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = "Title shouldn't be empty";
  }
  if (!values.assignee) {
    errors.assignee = "Assignee shouldn't be empty";
  }
  if (
    values.publish_start &&
    values.publish_end &&
    values.publish_end < values.publish_start
  ) {
    errors.publish_end = "End date shouldn't less than Start Date";
  }

  return errors;
};

const FormTask = (props) => {
  const { toggleOffCanvas, modalData, t, activeTab, isOpen } = props;
  const refCanvas = useRef();
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [emp_id] = useState(ls.get('user_cred').emp_id);

  const [dataForm, setDataForm] = useState({
    title: '',
    desc: modalData?.data?.description,
    start_date: modalData?.data?.startDate,
    end_date: modalData?.data?.endDate,
    assignee: modalData?.data?.assignee,
    priority: modalData?.data?.priority,
    pta_id: modalData?.data?.pta_id,
    pps_id: modalData?.data?.pps_id ?? null,
    pse_id: modalData?.data?.pse_id ?? null,
  });

  useEffect(() => {
    setDataForm({
      title: modalData?.data?.title,
      desc: modalData?.data?.description,
      start_date: modalData?.data?.startDate,
      end_date: modalData?.data?.endDate,
      assignee: modalData?.data?.assignee,
      priority: modalData?.data?.priority,
      pta_id: modalData?.data?.pta_id,
      pps_id: modalData?.data?.pps_id ?? null,
      pse_id: modalData?.data?.pse_id ?? null,
    });
    if (activeTab == '1') {
      setDataForm((prev) => ({
        ...prev,
        assignee: emp_id,
      }));
    }
  }, [modalData]);

  const [touched, setTouched] = useState(false);
  const [touchedAssigne, setTouchedAssigne] = useState(false);

  const handleSubmit = (e) => {
    let error = false;
    const userid = ls.get('user_cred').usr_id;

    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    formData.append('start_date', dataForm.start_date);
    formData.append('end_date', dataForm.end_date);
    formData.append('assignee', dataForm.assignee);
    formData.append('priority', dataForm.priority);
    formData.append('cuser_id', userid);
    formData.append('tab', activeTab);
    formData.append('emp_id', emp_id);
    if (dataForm.pps_id) {
      formData.append('pps_id', dataForm?.pps_id);
    }
    if (dataForm.pse_id) {
      formData.append('pse_id', dataForm?.pse_id);
    }

    if (!dataForm.assignee) {
      setTouchedAssigne(true);
      error = true;
    } else {
      setTouchedAssigne(false);
      error = false;
    }

    if (!dataForm.title) {
      setTouched(true);
      error = true;
    } else {
      setTouched(false);
      error = false;
    }

    console.log(error);

    if (error == false) {
      let url = ``;
      if (!props.modalData.isEdit) {
        url = `${auth.apiws}/task/saveItem`;
      } else {
        url = `${auth.apiws}/task/updateItem/${dataForm.pta_id}`;
      }
      const config = {
        headers: {
          Authorization: `bearer ${auth.token}`,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data.status == 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            toggleOffCanvas();

            // getActivity(emp_id);
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
          // props.toggleOffcanvas();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div
        id="offcanvas-form-task"
        className="offcanvas fade show"
        style={{
          opacity: isOpen ? 1 : 0,
          transition: 'opacity 0.9s ease-in-out',
          zIndex: isOpen ? 999 : -999,
        }}
        ref={refCanvas}
        onClick={() => {
          refCanvas.current.style.opacity = 0;
          setTimeout(() => toggleOffCanvas('0'), 1000);
        }}

        // onClick={() => props.toggleOffCanvas()}
      >
        <div className="offcanvas-dialog" onClick={(e) => e.stopPropagation()}>
          <div className="offcanvas-header p-3">
            <span className="off-title">
              {props.modalData.isEdit ? t('LBL.EDITTASK') : t('LBL.ADDTASK')}
            </span>
            <button
              className="close"
              onClick={() => {
                refCanvas.current.style.opacity = 0;
                setTimeout(() => toggleOffCanvas('0'), 1000);
              }}
            >
              &times;
            </button>
          </div>
          <div className="offcanvas-body mt-0 p-3">
            <form
              action=""
              id="formTask"
              className="form form--horizontal"
              onSubmit={handleSubmit}
            >
              <div className="form__form-group">
                <span className="form__form-group-label mt-0">
                  {t('LBL.TASKTITLE')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="title"
                    id="title"
                    component={renderTextInput}
                    clearable
                    maxLength="255"
                    type="text"
                    onChange={(e) => {
                      // console.log(e);
                      if (e?.target?.value != undefined) {
                        setDataForm((prev) => ({
                          ...prev,
                          title: e?.target?.value,
                        }));
                      }
                    }}
                    disableTouched={touched}
                    props={{ value: dataForm?.title ?? '' }}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label mt-0">
                  {t('LBL.DESCRIPTION')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="description"
                    id="description"
                    component={renderTextInput}
                    clearable
                    maxLength="255"
                    onChange={(e) => {
                      // console.log(e);
                      if (e?.target?.value != undefined) {
                        setDataForm((prev) => ({
                          ...prev,
                          desc: e?.target?.value,
                        }));
                      }
                    }}
                    type="textarea"
                    props={{ value: dataForm?.desc ?? '' }}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="theme-light form__form-group-field ml-0">
                  <div className="d-flex position-relative flex-column">
                    <span className="mb-2">{t('LBL.START_DATE')}</span>
                    <div className="d-flex">
                      <Field
                        name="publish_start"
                        component={renderDatePickerField}
                        onChange={(e) => {
                          setDataForm((prev) => ({
                            ...prev,
                            start_date: moment(e).format('YYYY-MM-DD'),
                          }));
                        }}
                        props={{
                          value: dataForm?.start_date ?? '',
                        }}
                        clear
                      />
                      <div className="icon-wrapper">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex position-relative flex-column">
                    <span className="mb-2">{t('LBL.END_DATE')}</span>
                    <div className="d-flex">
                      <Field
                        className="right"
                        name="publish_end"
                        component={renderDatePickerField}
                        onChange={(e) => {
                          setDataForm((prev) => ({
                            ...prev,
                            end_date: moment(e).format('YYYY-MM-DD'),
                          }));
                        }}
                        props={{
                          value: dataForm?.end_date ?? '',
                        }}
                        clear
                      />
                      <div className="icon-wrapper">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label mt-0">
                  {t('LBL.TASKASSIGNEE')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="assignee"
                    component={renderSelectField}
                    placeholder={t('LBL.SELECTASSIGNEE')}
                    options={props?.user}
                    disableTouched={touchedAssigne}
                    onChange={(e) => {
                      setDataForm((prev) => ({ ...prev, assignee: e?.value }));
                    }}
                    clear
                    props={{ value: dataForm?.assignee ?? '' }}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label mt-0">
                  {t('LBL.PRIORITY')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="priority"
                    component={renderSelectField}
                    placeholder={t('LBL.SELECTPRIORITY')}
                    options={props?.priority}
                    disableTouched={touched}
                    onChange={(e) => {
                      setDataForm((prev) => ({ ...prev, priority: e?.value }));
                    }}
                    clear
                    props={{ value: dataForm?.priority ?? '' }}
                  />
                </div>
              </div>
              <div className="offcanvas-footer">
                <div className="offcanvas-footer-btn-group">
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => {
                      refCanvas.current.style.opacity = 0;
                      setTimeout(() => toggleOffCanvas('0'), 1000);
                    }}
                  >
                    {t('LBL.CANCEL')}
                  </button>
                  <button type="submit" className="btn-save">
                    {t('FRM.SAVE')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'FormTaskForm', // Give a unique name to your form
  validate,
})(FormTask);
