/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable indent */
import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import showResults from './show';
import WizardFormAddEmployee from './components/WizardFormAddEmployee';

const HeaderIcon = `${process.env.PUBLIC_URL}/img/page_header/icon-add-emp.png`;

// const WizardForm = (props) => {
class WizardForm extends PureComponent {
  static propTypes = {
    location: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
  } ;

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return (<Container>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody className="p0">
              <Row className="m0">
                <Col md={12} style={{ padding: '0px' }}>
                  <div className="header-page-panel">
                    <img className="header-icon" src={HeaderIcon} alt="Add Employee" />
                    <h3 className="page-title">{t('FRM.ADD')} {t('LBL.EMPLOYEE')}</h3>
                    <h3 className="page-subhead subhead">{t('FRM.ADD')}  {t('LBL.NEW_EMPLOYEE')}</h3>
                  </div>
                </Col>
                {/* <div className="btn_action_table_navbar">
                  <ButtonToolbar>
                    <Link className="btn btn-primary btn_table_navbar" to="/administration/employee">
                      <FontAwesomeIcon icon={faArrowLeft} /> Cancel
                    </Link>
                  </ButtonToolbar>
                </div> */}
              </Row>
              <Row>
                <Col>
                  <WizardFormAddEmployee
                    parmid={this.props.location}
                    onSubmit={showResults}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    );
  }
}

export default (translate('global')(WizardForm));
