import { createContext, useReducer } from 'react';

const initalState = {
  needAtt: [],
  needAttTotal: 0,
  noParent: [],
  noParentTotal: 0,
  obj : [],
  objTotal : 0,
  okrDetail: [],
  okrDetailTotal: 0,
  key: [],
  keyTotal: 0
};

const ACTIONS = {
  SET_NEED_ATT: 'SET_NEED_ATT',
  SET_NEED_ATT_TOTAL: 'SET_NEED_ATT_TOTAL',
  SET_NO_PARENT: 'SET_NO_PARENT',
  SET_NO_PARENT_TOTAL: 'SET_NO_PARENT_TOTAL',
  SET_OBJ: 'SET_OBJ',
  SET_OBJ_TOTAL: 'SET_OBJ_TOTAL',
  SET_KEY: 'SET_KEY',
  SET_KEY_TOTAL: 'SET_KEY_TOTAL',
  SET_OKR_DETAIL: 'SET_OKR_DETAIL',
  SET_OKR_DETAIL_TOTAL: 'SET_OKR_DETAIL_TOTAL',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_NEED_ATT:
      return { ...state, needAtt: action.data };
    case ACTIONS.SET_NEED_ATT_TOTAL:
      return { ...state, needAttTotal: action.data };
    case ACTIONS.SET_NO_PARENT:
      return { ...state, noParent: action.data };
    case ACTIONS.SET_NO_PARENT_TOTAL:
      return { ...state, noParentTotal: action.data };
    case ACTIONS.SET_OBJ:
      return { ...state, obj: action.data };
    case ACTIONS.SET_OBJ_TOTAL:
      return { ...state, objTotal: action.data };
    case ACTIONS.SET_OKR_DETAIL:
      return { ...state, okrDetail: action.data };
    case ACTIONS.SET_OKR_DETAIL_TOTAL:
      return { ...state, okrDetailTotal: action.data };
    case ACTIONS.SET_KEY:
      return { ...state, key: action.data };
    case ACTIONS.SET_KEY_TOTAL:
      return { ...state, keyTotal: action.data };
    default:
      return state;
  }
}

const OkrContext = createContext();
const getReducer = () => useReducer(reducer, initalState);

export { ACTIONS, OkrContext, getReducer };
