/* eslint-disable array-callback-return */
/*
 * Page Grade
 * Notes: Master Data Grade
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';

let attendanceSettingExist = [];
class AttendanceSettingPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.PERIOD_START') },
      { '3': t('LBL.PERIOD_END') },
      { '4': t('LBL.GROUP_SHIFT') },
      { '5': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '20%', '20%', '20%', '15%'],
      urlData: [],
      statusEdit: false,
      arrGroupShift: [],
      arrShift: [],
      editcond: '',
      addcond: '',
      deletecond: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteAttendanceSetting = this.deleteAttendanceSetting.bind(this);
    this.editAttendanceSetting = this.editAttendanceSetting.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getGroupShift = this.getGroupShift.bind(this);
    this.getAttandaceSetting = this.getAttandaceSetting.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getGroupShift();
      this.getAttandaceSetting();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/attsetting/getAllitem` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.PERIOD_START') },
      { '3': t('LBL.PERIOD_END') },
      { '4': t('LBL.GROUP_SHIFT') },
      { '5': t('FRM.FUNCTION') },
    ];
    this.getGroupShift();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getTableData() {
    this.child.reload();
  }

  getGroupShift() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const url = `${apiws}/attshiftgroup/getall`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const data_shift = response.data.data;
          const arr = [];
          data_shift.map((data) => {
            arr.push({ value: data.atg_id, label: data.shift_group });
          });
          this.setState({
            arrGroupShift: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getAttandaceSetting() {
    const { token, apiws } = this.state;
    attendanceSettingExist = [];
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/attsetting/getAll/`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        for (let i = 0; i < dataResponse.length; i += 1) {
          attendanceSettingExist[i] = dataResponse[i].ats_name;
        }
      }
    });
  }

  loadShift(id) {
    if (typeof id === 'undefined') {
      this.setState({
        arrShift: [],
      });
    } else {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const url = `${apiws}/attsetting/getallgroupshiftbyid/${id}`;
      axios
        .post(url, '', config)
        .then((response) => {
          if (response.data.status === 'ok') {
            this.setState({ data_shift: response.data.data });
            const arr = [];
            this.state.data_shift.map((data) => {
              arr.push({
                shift: data.shift,
                day_no: data.day_no,
                working_day_start: data.working_day_start,
                working_day_end: data.working_day_end,
              });
              return arr;
            });
            this.setState({
              arrShift: arr,
            });
          }
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    }
  }

  getTableShift() {
    const { t } = this.props;
    if (this.state.arrShift.length > 0) {
      return (
        <div className="form__form-group">
          <span className="form__form-group-label" />
          <div className="form__form-group-field">
            <Table className="table--bordered" striped responsive>
              <thead>
                <tr>
                  <th>{t('LBL.DAYS')}</th>
                  <th>{t('LBL.GROUP_SHIFT')}</th>
                  <th>{t('LBL.TIME')}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.arrShift.map((link) => (
                  <tr>
                    <td width="5%">{link.day_no}</td>
                    <td width="35%">{link.shift}</td>
                    <td width="60%">
                      {link.working_day_start}-{link.working_day_end}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    }
    return '';
  }

  toggle() {
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
    this.getGroupShift();
    this.getAttandaceSetting();
  }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.ats_id;
    let error = true;
    const errorAttandanceSetting = attendanceSettingExist;
    if (!values.ats_name) {
      error = false;
    }
    if (errorAttandanceSetting.includes(values.ats_name)) {
      error = false;
    }
    if (!values.atg_id) {
      error = false;
    }
    let url;
    let dataBody;
    let valueAtg;
    const data = {
      ats_name: values.ats_name,
      period_start: values.period_start,
      period_end: values.period_end,
    };
    if (id === undefined || id === '') {
      url = `${apiws}/attsetting/saveItem`;
      valueAtg = values.atg_id.value;
      dataBody = { ...data, atg_id: valueAtg, cuser_id: userid };
    } else {
      url = `${apiws}/attsetting/updateItem/${id}`;
      valueAtg =
        typeof values.atg_id.value === 'undefined'
          ? values.atg_id
          : values.atg_id.value;
      dataBody = { ...data, atg_id: valueAtg, muser_id: userid };
    }
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ modal: false });
            this.child.reload('addedit');
            this.props.destroy();
            this.getAttandaceSetting();
            this.loadShift();
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  editAttendanceSetting(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attsetting/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const idxAttSetting = attendanceSettingExist.indexOf(
            getResponseData[0].ats_name,
          );
          if (idxAttSetting !== -1) {
            attendanceSettingExist.splice(idxAttSetting, 1);
          }
          const datamaping = {
            ats_name: getResponseData[0].ats_name,
            period_start: moment(new Date(getResponseData[0].period_start)),
            period_end: moment(new Date(getResponseData[0].period_end)),
            atg_id: getResponseData[0].atg_id,
            ats_id: getResponseData[0].ats_id,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.loadShift(getResponseData[0].atg_id);
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteAttendanceSetting(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attsetting/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
                this.getAttandaceSetting();
                this.loadShift();
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { statusEdit, urlData, arrGroupShift } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.ATTENDANCE_SETTING')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addattsettingform"
          disabled={pristine}
        >
          {' '}
          Edit
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addattsettingform"
          type="submit"
          disabled={pristine}
        >
          Save
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ats_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteAttendanceSetting}
                  updateFunc={this.editAttendanceSetting}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.ATTENDANCE_SETTING')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addattsettingform"
                  id="addattsettingform"
                >
                  <input type="hidden" name="ats_id" />
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.NAME')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ats_name"
                        component={renderTextInput}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.PERIOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="period_start"
                        component={renderDatePickerField}
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                      <span className="form__form-group-label" />
                      <Field
                        name="period_end"
                        component={renderDatePickerField}
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.GROUP_SHIFT')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atg_id"
                        component={renderSelectField}
                        type="text"
                        options={arrGroupShift}
                        onChange={(e) => {
                          this.loadShift(e.value);
                        }}
                      />
                    </div>
                    {this.getTableShift()}
                  </div>
                  <Container style={{ padding: '2px' }}>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div className="note-file-upload">
                          <h5>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            {t('LBL.NOTES')}
                          </h5>
                          <hr />
                          <span>
                            <ol>
                              <li>
                                {t('LBL.THE_DAY_FROM')} <b>{t('LBL.START')} </b>
                                {t('LBL.PERIOD_MUST_BE')}{' '}
                                <b>{t('LBL.FIRST_DAY')} </b>
                                {t('LBL.THE_SHIFT')}{' '}
                              </li>
                              <li>
                                {t('LBL.THE_DAY_FROM')} <b>{t('LBL.END')} </b>
                                {t('LBL.PERIOD_MUST_BE')}{' '}
                                <b>{t('LBL.LAST_DAY')} </b>
                                {t('LBL.THE_SHIFT')}{' '}
                              </li>
                            </ol>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorAttandanceSetting = attendanceSettingExist;
  if (!values.ats_name) {
    errors.ats_name = 'Attendance name field shouldn’t be empty';
  }
  if (errorAttandanceSetting.includes(values.ats_name)) {
    errors.ats_name = 'Attendance name is already taken';
  }
  if (!values.period_end) {
    errors.period_end = 'Period start field shouldn’t be empty';
  }
  if (!values.period_start) {
    errors.period_start = 'Period end field shouldn’t be empty';
  }
  if (values.period_end !== null) {
    if (
      typeof values.period_start === 'object' &&
      values.period_start.isAfter(values.period_end)
    ) {
      errors.period_end = 'End date shouldn’t less than Start date';
    }
  }
  if (!values.atg_id) {
    errors.atg_id = 'Group shift field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  form: 'attsettingform',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AttendanceSettingPage));
