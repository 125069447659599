import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { OrgChart } from 'd3-org-chart';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { faKey, faChartBar } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import chartCmpBlue from '../../shared/img/icon/chart-cmp-blue.svg';
import keyCmpBlue from '../../shared/img/icon/key-cmp-blue.svg';
import chartEmpGreen from '../../shared/img/icon/chart-emp-green.svg';
import keyEmpGreen from '../../shared/img/icon/key-emp-green.svg';
import chartOrgPink from '../../shared/img/icon/chart-org-pink.svg';
import keyOrgPink from '../../shared/img/icon/key-org-pink.svg';
import collapse from '../../shared/img/icon/collapse.svg';
import expand from '../../shared/img/icon/expand.svg';

const Structure = (props, ref) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);

  // ref
  const d3Container = useRef(null);
  const chart = useRef(null);

  useEffect(() => {
    if (!chart.current) chart.current = new OrgChart();

    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
    function exitHandler() {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullScreen(false);
        chart.current
          .svgHeight(window.screen.height - 350)
          .fit()
          .render();
      } else {
        chart.current.svgHeight(window.screen.height + 47).render();
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart.current) chart.current = new OrgChart();

      chart.current
        .container(d3Container.current)
        .data(props.data)
        .nodeWidth((d) => 200)
        .nodeHeight((d) => 120)
        .onNodeClick((d, i, arr) => {
          console.log(d, 'Id of clicked node ');
          props.toggleOffcanvas(d);
        })
        .nodeContent(nodeContent)
        .svgHeight(window.screen.height - 350)
        // .nodeUpdateTransform(50, 100)
        .buttonContent(buttonContent)
        .render();

      if (props.data.length > 0) {
        chart.current.collapseAll().render();
      }
    }
  }, [props.data, d3Container.current]);

  const nodeContent = useCallback((d, i, arr, state) => {
    const component = (
      <div
        style={{
          paddingTop: 30,
          backgroundColor: 'none',
          marginLeft: 1,
          height: d.height,
          overflow: 'visible',
        }}
      >
        <div
          style={{
            height: d?.data?.okr_key ? d.height - 20 : d.height - 40,
            // height: d.height - 20,
            paddingTop: 0,
            borderRadius: 17,
            backgroundColor: 'white',
            border: '1px solid #D8D6D6',
          }}
        >
          {/* {d.data.photo && (
              <img
                src={d.data.photo}
                style={{
                  marginTop: -20,
                  marginLeft: d.width / 2 - 20,
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  objectFit: 'cover',
                  objectPosition: '50% 0px',
                  mixBlendMode: 'normal',
                  scale: 5,
                }}
              />
            )} */}

          {/* top line */}
          <div
            style={{
              marginTop: -1,
              backgroundColor:
                d.data.name == 'Organization Objective'
                  ? '#F16C7A'
                  : d.data.name == 'Individual Objective'
                  ? '#1CB479'
                  : '#057AD7',
              height: '1em',
              borderTopLeftRadius: 17,
              borderTopRightRadius: 17,
              border: '1px solid #D8D6D6',
              padding: 20,
              paddingTop: 6,
              textAlign: 'center',
            }}
          >
            <p
              style={{
                color: 'white',
                fontSize: 12,
                // fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {d?.data?.name}
            </p>
          </div>

          <div
            style={{
              padding: 20,
              paddingTop: 12,
              textAlign: 'center',
            }}
          >
            <div
              style={{
                color: '#3F3F3F',
                fontSize: 12,
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {d?.data.objective_title ?? '-'}
              {d?.data?.okr_key && (
                <div className="d-flex align-items-center justify-content-center my-2">
                  <div className="mr-2">
                    <img
                      src={
                        d.data.name == 'Organization Objective'
                          ? keyOrgPink
                          : d.data.name == 'Individual Objective'
                          ? keyEmpGreen
                          : keyCmpBlue
                      }
                      alt=""
                      size="12"
                      style={{
                        marginRight: '1px',
                        marginLeft: '1px',
                        color:
                          d.data.name == 'Organization Objective'
                            ? '#F16C7A'
                            : d.data.name == 'Individual Objective'
                            ? '#1CB479'
                            : '#057AD7',
                      }}
                    />
                    <span>
                      {d?.data?.okr_key[0] == ''
                        ? '0'
                        : d?.data?.okr_key?.length}{' '}
                      Key Results
                    </span>
                  </div>
                  <div>
                    <img
                      src={
                        d.data.name == 'Organization Objective'
                          ? chartOrgPink
                          : d.data.name == 'Individual Objective'
                          ? chartEmpGreen
                          : chartCmpBlue
                      }
                      alt=""
                      size="12"
                      style={{
                        marginRight: '1px',
                        marginLeft: '1px',
                      }}
                    />
                    <span>{d?.data?.achievement_percentage ?? '0'}%</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(component);
  }, []);

  const buttonContent = useCallback(({ node, state }) => {
    const component = (
      <div
        style={{
          backgroundColor: '#737373',
          color: '#fff',
          borderRadius: 15,
          height: 25,
          minWidth: 25,
          padding: 8,
          fontSize: 10,
          marginTop: '2em',
        }}
      >
        <span>
          {node.data._directSubordinates}{' '}
          {node.children ? (
            // <i className="fas fa-angle-up"></i>
            <img src={collapse} />
          ) : (
            // <i className="fas fa-angle-down"></i>
            <img src={expand} />
          )}
        </span>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(component);
  }, []);

  return (
    <Row>
      {props?.data?.length > 0 && (
        <Col md={12}>
          <div ref={d3Container} />
          <div className="chart-action-buttons-okr">
            <span className="chart-btn-action-group">
              <button
                id="btnCollapse"
                onClick={() => {
                  const data = chart.current.getChartState();
                  const nodeLevel = data.allNodes.filter((node) => {
                    return node.depth == currentLevel;
                  });
                  setCurrentLevel(currentLevel == 0 ? 0 : currentLevel - 1);
                  for (const node of nodeLevel) {
                    chart.current.setExpanded(node.id, false).render();
                  }
                  setTimeout(() => {
                    chart.current.fit();
                  }, 500);
                }}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-chevron-up"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnCollapse'}>
                Collapse One Level
              </UncontrolledTooltip>
              <br />
            </span>

            <span>
              <button
                id="btnExpand"
                onClick={() => {
                  const nextLevel = currentLevel + 1;
                  const data = chart.current.getChartState();
                  const nodeLevel = data.allNodes.filter((node) => {
                    return node.depth == nextLevel;
                  });
                  for (const node of nodeLevel) {
                    chart.current.setExpanded(node.id);
                  }
                  chart.current.render();
                  if (nodeLevel.length > 0) {
                    setCurrentLevel(nextLevel);
                  }
                  setTimeout(() => {
                    chart.current.fit();
                  }, 500);
                }}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-chevron-down"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnExpand'}>
                Expand One Level
              </UncontrolledTooltip>
              <br />
            </span>

            <span className="chart-btn-action-group">
              <button
                id="btnCollapseAll"
                onClick={() => {
                  chart.current.collapseAll();
                  setTimeout(() => {
                    chart.current.fit();
                  }, 500);
                }}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-angle-double-up"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnCollapseAll'}>
                Collapse All
              </UncontrolledTooltip>
            </span>
            <span>
              <button
                id="btnExpandAll"
                onClick={() => {
                  chart.current.expandAll();
                  setTimeout(() => {
                    chart.current.fit();
                  }, 500);
                }}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-angle-double-down"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnExpandAll'}>
                Expand All
              </UncontrolledTooltip>
              <br />
            </span>

            <span className="chart-btn-action-group">
              <button
                id="btnZoomIn"
                onClick={() => chart.current.zoomIn()}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-plus"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnZoomIn'}>
                Zoom In
              </UncontrolledTooltip>
              <br />
            </span>

            <span>
              <button
                id="btnZoomOut"
                onClick={() => chart.current.zoomOut()}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-minus"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnZoomOut'}>
                Zoom Out
              </UncontrolledTooltip>
              <br />
            </span>

            <span>
              <button
                id="btnFit"
                onClick={() => {
                  chart.current.fit();
                }}
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-compress-arrows-alt"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnFit'}>
                Fit Chart
              </UncontrolledTooltip>
              <br />
            </span>

            <span>
              <button
                id="btnExport"
                onClick={() =>
                  chart.current.exportImg({ full: true, scale: 16 })
                }
                className="chart-btn chart-btn-action-button waves-effect waves-light"
              >
                <i className="fas fa-cloud-download-alt"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnExport'}>
                Export as PNG
              </UncontrolledTooltip>
              <br />
            </span>

            <span>
              <button
                id="btnFullscreen"
                onClick={() => {
                  if (!isFullScreen) {
                    chart.current.fullscreen(
                      d3Container.current.parentElement.parentElement
                        .parentElement,
                    );
                    chart.current.svgHeight(window.screen.height + 47);
                    setTimeout(() => {
                      chart.current.fit();
                    }, 300);
                    setFullScreen(true);
                  } else {
                    setFullScreen(false);
                    chart.current.svgHeight(window.screen.height - 350);
                    document.webkitExitFullscreen();
                    setTimeout(() => {
                      chart.current.fit();
                    }, 300);
                  }
                }}
                className="chart-btn chart-btn-action-button waves-effect waves-light {}"
              >
                <i className="fas fa-expand"></i>
              </button>
              <UncontrolledTooltip placement={'left'} target={'btnFullscreen'}>
                Full Screen
              </UncontrolledTooltip>
              <br />
            </span>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Structure;
