/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable operator-assignment */
/* eslint-disable space-infix-ops */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import ls from 'local-storage';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

class TaxEmployeeList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.POSITION'),
      t('FRM.FUNCTION'),
    ];

    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '20%', '30%', '20%', '28%'],
      urlData: [],
      modal: false,
      empCode: '',
      empName: '',
      taxStatus: '',
      arrAllowance: [],
      arrDeducution: [],
      takeHomePay: '',
      totalAllowance: '',
      totalDeduction: '',
      salary: '',
      taxAllowance: '',
      otherAllowance: '',
      honorarium: '',
      natura: '',
      totalRegular: '',
      irregular: '',
      insurance: '',
      total: '',
      jobCost: '',
      deduction: '',
      pension: '',
      netto: '',
      yearlyIncome: '',
      ptkp: '',
      pkp: '',
      yearlyPph: '',
      taxPaid: '',
      pphAllowance: '',
      taxMethod: '',
      taxMethodRef: '',
      redirectUrl: false,
      pypId: '',
      taxCategory: '',
      taxCategoryId: '',
    };
    this.toggle = this.toggle.bind(this);
    this.detailRows = this.detailRows.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getTableData(); });
    this.setState({ urlData: `${ls.get('ws_ip')}/paysalary/getAllEmpByPeriod/` });
    this.setState({ pypId: this.props.location.state.pypId });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.POSITION'),
      t('FRM.FUNCTION'),
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  detailRows(id) {
    this.toggle();
    const { apiws, token } = this.state;
    const pypId = this.props.location.state.pypId;
    if (typeof pypId === 'undefined') {
      this.setState({ redirectUrl: true });
    }
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/paytaxpreferences/getAllPref`,
      '',
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({ taxMethodRef: res.data.data[0].tax_method });
      }
    });
    axios.post(
      `${apiws}/payemployeecurrent/getAllItemById/${id}`,
      `pyp_id=${pypId}`,
      config,
    ).then((res) => {
      const dataResponse = res.data.data[0];
      this.setState({
        empCode: dataResponse.emp_code,
        empName: dataResponse.emp_name,
        unitOrganization: dataResponse.unit_organization,
        office: dataResponse.office,
        employeementStatus: dataResponse.employeement_status,
        taxStatus: dataResponse.tax_status,
        npwp: dataResponse.npwp,
        taxMethod: dataResponse.tax_method,
        taxCategoryId: dataResponse.tcs_id,
        joinDate: moment(dataResponse.join_date).format('DD/MM/YYYY'),
      });
    });
    axios.post(
      `${apiws}/paysalary/getAllTaxComponentById/${id}`,
      `pyp_id=${pypId}`,
      config,
    ).then((res) => {
      if (res.data.data.length !== 0) {
        const dataResponse = res.data.data[0];
        // alert(JSON.stringify(dataResponse));
        this.setState({
          salary: (dataResponse.salary === null) ? 0: dataResponse.salary,
          otherAllowance: dataResponse.other_allowance,
          honorarium: dataResponse.honorarium,
          natura: dataResponse.natura,
          pphAllowance: dataResponse.pph_allowance,
          totalRegular: (dataResponse.total_regular === null) ? 0: dataResponse.total_regular,
          irregular: dataResponse.irregular,
          insurance: dataResponse.insurance,
          total: (dataResponse.total === null) ? 0: dataResponse.total,
          jobCost: dataResponse.job_cost,
          deduction: dataResponse.deduction,
          pension: dataResponse.pension,
          netto: (dataResponse.netto === null) ? 0: dataResponse.netto,
          yearlyIncome: (dataResponse.yearly_income < 0) ? dataResponse.next_yearly_income : dataResponse.yearly_income,
          ptkp: dataResponse.ptkp,
          pkp: (dataResponse.pkp < 0) ? 0 : dataResponse.pkp,
          yearlyPph: (dataResponse.yearly_pph === null || dataResponse.yearly_pph < 0) ? 0: dataResponse.yearly_pph,
          taxPaid: (dataResponse.tax_paid === null) ? 0: dataResponse.tax_paid,
          taxPaidRegular: (dataResponse.tax_paid_regular === null) ? 0: dataResponse.tax_paid_regular,
          taxPaidIrregular: (dataResponse.tax_paid_irregular === null) ? 0: dataResponse.tax_paid_irregular,

          prevsalary: (dataResponse.prev_salary === null) ? 0: dataResponse.prev_salary,
          prevotherAllowance: dataResponse.prev_other_allowance,
          prevhonorarium: dataResponse.prev_honorarium,
          prevnatura: dataResponse.prev_natura,
          prevpphAllowance: dataResponse.prev_pph_allowance,
          prevtotalRegular: (dataResponse.prev_total_regular === null) ? 0: dataResponse.prev_total_regular,
          previrregular: dataResponse.prev_irregular,
          previnsurance: dataResponse.prev_insurance,
          prevtotal: (dataResponse.prev_total === null) ? 0: dataResponse.prev_total,
          prevjobCost: dataResponse.prev_job_cost,
          prevdeduction: dataResponse.prev_deduction,
          prevpension: dataResponse.prev_pension,
          prevnetto: (dataResponse.prev_netto === null) ? 0: dataResponse.prev_netto,
          prevyearlyIncome: (dataResponse.prev_yearly_income < 0) ? dataResponse.prev_next_yearly_income : dataResponse.prev_yearly_income,
          prevptkp: dataResponse.prev_ptkp,
          prevpkp: (dataResponse.prev_pkp < 0) ? 0 : dataResponse.prev_pkp,
          prevyearlyPph: (dataResponse.prev_yearly_pph === null || dataResponse.prev_yearly_pph < 0) ? '': dataResponse.prev_yearly_pph,
          prevtaxPaid: (dataResponse.prev_tax_paid === null) ? '': dataResponse.prev_tax_paid,
          prevtaxPaidRegular: (dataResponse.prev_tax_paid_regular === null) ? 0: dataResponse.prev_tax_paid_regular,
          prevtaxPaidIrregular: (dataResponse.prev_tax_paid_irregular === null) ? 0: dataResponse.prev_tax_paid_irregular,

          nextsalary: (dataResponse.next_salary === null) ? 0: dataResponse.next_salary,
          nextotherAllowance: dataResponse.next_other_allowance,
          nexthonorarium: dataResponse.next_honorarium,
          nextnatura: dataResponse.next_natura,
          nextpphAllowance: dataResponse.next_pph_allowance,
          nexttotalRegular: dataResponse.next_total_regular,
          nextirregular: dataResponse.next_irregular,
          nextinsurance: dataResponse.next_insurance,
          nexttotal: dataResponse.next_total,
          nextjobCost: dataResponse.next_job_cost,
          nextdeduction: dataResponse.next_deduction,
          nextpension: dataResponse.next_pension,
          nextnetto: dataResponse.next_netto,
          nextyearlyIncome: (dataResponse.next_yearly_income < 0) ? dataResponse.next_next_yearly_income : dataResponse.next_yearly_income,
          nextptkp: dataResponse.next_ptkp,
          nextpkp: (dataResponse.next_pkp < 0) ? 0 : dataResponse.next_pkp,
          nextyearlyPph: (dataResponse.next_yearly_pph === null || dataResponse.next_yearly_pph < 0) ? 0: dataResponse.next_yearly_pph,
          nexttaxPaid: (dataResponse.next_tax_paid === null) ? 0: dataResponse.next_tax_paid,
          nexttaxPaidRegular: (dataResponse.next_tax_paid_regular === null) ? 0: dataResponse.next_tax_paid_regular,
          nexttaxPaidIrregular: (dataResponse.next_tax_paid_irregular === null) ? 0: dataResponse.next_tax_paid_irregular,
        });
      }
    });
    setTimeout(() => {
      axios.post(
        `${apiws}/paytaxclass/getAllItemById/${this.state.taxCategoryId}`,
        '',
        config,
      ).then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({ taxCategory: res.data.data[0].class_name });
        }
      });
    }, 500);
  }

  render() {
    const { urlData, redirectUrl } = this.state;
    const { t } = this.props;
    const addBtn = '';
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn_table_navbar"
        onClick={() => this.setState({ redirectUrl: true })}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '750px',
      marginTop: '95px',
      textAlign: 'center',
    };
    if (redirectUrl) {
      return <Redirect to={{ pathname: '/payroll/taxcalculation' }} />;
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ 'paddingBottom': '10px' }}>
                  <h3 className="page-title">{t('MEN.TAX')} - {t('MEN.EMPLOYEE_LIST')} {t('LBL.PROCESS')} {t('MEN.TAX')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.VIEW')} {t('MEN.EMPLOYEE_LIST')} {t('FRM.FROM')} {t('LBL.PROCESS')} {t('MEN.TAX')}</h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="emp_id"
                  conds={this.state.pypId}
                  widthTable={this.state.dataWidth}
                  detailFunc={this.detailRows}
                  buttonAction="detailOnly"
                  onRef={ref => (this.child = ref)}
                  displayStyleHead="none"
                />
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className="modal-dialog modal-input"
                  style={modalStyle}
                >
                  <ModalHeader toggle={this.toggle}>{t('LBL.TAX_INFORMATION')}</ModalHeader>
                  <ModalBody>
                    <Container>
                      <Card>
                        <CardBody style={{ padding: '5px 5px 5px 5px' }}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th colSpan="2">{t('LBL.EMPLOYEE_INFORMATION')}</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td width="40%">{t('LBL.EMPLOYEE_CODE')}</td>
                                  <td>: {this.state.empCode}</td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.EMPLOYEE')}</td>
                                  <td>: {this.state.empName}</td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.STATUS')}</td>
                                  <td>: {this.state.employeementStatus} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.JOIN_DATE')}</td>
                                  <td>: { this.state.joinDate }</td>
                                </tr>
                                <tr>
                                  <td>PTKP</td>
                                  <td>: {this.state.taxStatus} </td>
                                </tr>
                                <tr>
                                  <td>NPWP</td>
                                  <td>: {this.state.npwp} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.TAX_METHOD')}</td>
                                  <td>: {
                                    (this.state.taxMethod === '' || this.state.taxMethod === null) ?
                                    this.state.taxMethodRef : this.state.taxMethod }
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.TAX_CATEGORY')}</td>
                                  <td>: {this.state.taxCategory}</td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody style={{ padding: '5px 5px 5px 5px' }}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th colSpan="2">{t('LBL.COMPONENT_TAX')}</th>
                                <th>{t('MEN.TAX')} {t('LBL.MONTHLY')}</th>
                                <th>{t('LBL.PREVIOUS_MONTH')}</th>
                                <th>{t('LBL.ALL_MONTH')}</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td width="40%">{t('LBL.SALARY')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.salary, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevsalary, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextsalary, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('MEN.TAX')} {t('LBL.ALLOWANCE')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.pphAllowance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevpphAllowance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextpphAllowance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.OTHER_ALLOWANCE')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.otherAllowance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevotherAllowance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextotherAllowance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.INSURANCE')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.insurance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.previnsurance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextinsurance, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.NATURA')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.natura, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevnatura, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextnatura, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.TOTAL')} {t('LBL.REGULER')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.totalRegular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevtotalRegular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nexttotalRegular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.IRREGULAR')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.irregular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.previrregular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextirregular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.TOTAL')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.total, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevtotal, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nexttotal, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.JOB_COST')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.jobCost, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevjobCost, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextjobCost, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.PENSION')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.pension, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevpension, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextpension, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.DEDUCTION')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.deduction, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevdeduction, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nextdeduction, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.NETTO')} {t('LBL.MONTHLY')} {t('LBL.INCOME')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.netto, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.prevnetto, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.nextnetto, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.NETTO')} {t('LBL.YEARLY')} {t('LBL.INCOME')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.yearlyIncome, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.prevyearlyIncome, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.nextyearlyIncome, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>PTKP</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.ptkp, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.prevptkp, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.nextptkp, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>PKP</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.pkp, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.prevpkp, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.nextpkp, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>PPH {t('LBL.YEARLY')}</td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.yearlyPph, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.prevyearlyPph, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {(this.state.taxCategoryId !== 4) &&
                                      <NumberFormat
                                        value={parseInt(this.state.nextyearlyPph, 0)}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        renderText={value => <div> {value} </div>}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td><b>{t('LBL.TAX_PAID_REGULAR')}</b></td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.taxPaidRegular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevtaxPaidRegular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nexttaxPaidRegular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><b>{t('LBL.TAX_PAID_IRREGULAR')}</b></td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.taxPaidIrregular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevtaxPaidIrregular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nexttaxPaidIrregular, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><b>{t('LBL.TAX_PAID')}</b></td>
                                  <td> : </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.taxPaid, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.prevtaxPaid, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <NumberFormat
                                      value={parseInt(this.state.nexttaxPaid, 0)}
                                      displayType={'text'}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      renderText={value => <div> {value} </div>}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </CardBody>
                      </Card>
                    </Container>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(TaxEmployeeList));
