/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import ls from 'local-storage';
import { Redirect } from 'react-router-dom';

class RunAttendanceProcessView extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
    inProcess: PropTypes.func.isRequired,
    attEmployee: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.PERIOD_CODE'),
      t('LBL.PERIOD_DATE'),
      t('LBL.PROCESS_DATE'),
      t('FRM.FUNCTION'),
    ];

    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '20%', '30%', '20%', '28%'],
      urlData: [],
      redirectUrl: false,
      detailId: '',
    };
    this.detailRows = this.detailRows.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getTableData(); });
    this.setState({ urlData: `${ls.get('ws_ip')}/payperiod/getAllItemAttendance/` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.PERIOD_CODE'),
      t('LBL.PERIOD_DATE'),
      t('LBL.PROCESS_DATE'),
      t('FRM.FUNCTION'),
    ];
    setTimeout(() => {
      const { onProcess } = this.props;
      if (onProcess) {
        this.getTableData();
        this.props.inProcess();
      }
    });
  }

  getTableData() {
    this.child.reload();
  }

  detailRows(id) {
    this.props.attEmployee(id);
    // this.setState({
    //   redirectUrl: true,
    //   detailId: id,
    // });
  }

  render() {
    const { urlData, redirectUrl, detailId } = this.state;
    const { t } = this.props;
    if (redirectUrl) {
      return <Redirect to={{ pathname: '/payroll/attendancelist', state: { pypId: detailId } }} />;
    }
    const addBtn = '';
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody style={{ padding: '20px 5px 30px 10px' }}>
            <Col md={12} style={{ 'paddingBottom': '10px' }}>
              <h3 className="page-title">{t('LBL.ATTENDANCE')} - {t('MEN.LIST_ATT_PROCESS')}</h3>
              <h3 className="page-subhead subhead">{t('MEN.ATTENDANCE_LIST_PERIOD')}</h3>
            </Col>
            <ViewListTable
              url={urlData}
              heads={this.heads}
              addBtn={addBtn}
              primaryKey="pyp_id"
              widthTable={this.state.dataWidth}
              deleteFunc={this.deleteRows}
              updateFunc={this.editRows}
              detailFunc={this.detailRows}
              buttonAction="detailOnly"
              onRef={ref => (this.child = ref)}
              displayStyleHead="none"
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

/* eslint-disable react/no-array-index-key
import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';

const CartCard = () => (
  <Col md={12} lg={12}>
    <Card className="cart">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Attendance Process History</h5>
        </div>
        <Table className="table--bordered cart__table" responsive>
          <thead>
            <tr>
              <th>Attendance Period</th>
              <th>Date</th>
              <th width="15%">Status</th>
              <th width="15%" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01/12/2018-30/12/2018</td>
              <td>
                25/12/2018
              </td>
              <td><span style={{ color: '#15DD96' }}><FontAwesomeIcon icon={faCheckCircle} /> Success</span></td>
              <td>
                <Link className="btn btn-primary btn-sm btn-payroll mt-min15" to="/dashboard_default">
                  <FontAwesomeIcon icon={faForward} /> Re-run
                </Link>
              </td>
            </tr>
            <tr>
              <td>01/12/2018-30/12/2018</td>
              <td>
                25/12/2018
              </td>
              <td><span style={{ color: '#dc3545' }}><FontAwesomeIcon icon={faTimesCircle} /> Failed</span></td>
              <td>
                <Link className="btn btn-primary btn-sm btn-payroll mt-min15" to="/dashboard_default">
                  <FontAwesomeIcon icon={faForward} /> Re-run
                </Link>
              </td>
            </tr>
            <tr>
              <td>01/12/2018-30/12/2018</td>
              <td>
                25/12/2018
              </td>
              <td><span style={{ color: '#15DD96' }}><FontAwesomeIcon icon={faCheckCircle} /> Success</span></td>
              <td>
                <Link className="btn btn-primary btn-sm btn-payroll mt-min15" to="/dashboard_default">
                  <FontAwesomeIcon icon={faForward} /> Re-run
                </Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

export default CartCard; */
export default (translate('global')(RunAttendanceProcessView));
