/* eslint-disable no-return-assign, react/no-unused-prop-types */
/* eslint-disable react/no-unused-state, no-unused-expressions */
/* eslint-disable no-plusplus, no-sequences */
/* eslint-disable no-lonely-if, object-curly-newline */
/* eslint-disable react/sort-comp, no-param-reassign */
/* eslint-disable react/jsx-no-bind, arrow-parens */
/* eslint-disable no-unused-vars, react/jsx-closing-bracket-location */
/* eslint-disable no-const-assign, prefer-destructuring */
/* eslint-disable camelcase, array-callback-return */
/* eslint-disable class-methods-use-this, consistent-return */
/* eslint-disable quote-props, max-len, object-shorthand */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import {
  Button,
  Col,
  Container,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import showNotifications from '../../notificationMessages';
import Spinner from 'react-bootstrap/Spinner';
import renderNumberFormat from '../../../../shared/components/form/NumberFormat';
import NumberFormat from 'react-number-format';

class Kpiform extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    pvm_id: PropTypes.func.isRequired,
    ppr_id: PropTypes.func.isRequired,
    pso_id: PropTypes.func.isRequired,
    addStrategicOjb: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    reloadTree: PropTypes.func.isRequired,
    statusEdit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      idUser: '',
      token: '',
      displaySpinner: false,
      disabledInput: false,
      disabledPerspective: true,
      hideParameter: false,
      disabled: false,
      hideScoring: false,
      dataWidth: ['5%', '40%', '40%', '15%'],
      statusEdit: false,
      urlData: '',
      arrPerspective: [],
      arrOrg: [],
      arrScoring: [],
      arrTarget: [],
      arrCategory: [],
      // arrTolerance: [],
      strategicName: '',
      target: '',
      targetValue: '',
      category: '',
      keyPerformance: '',
      organization: '',
      // tolerance: '',
      ppi_id: '',
    };
    const { t } = props;

    this.getButton = this.getButton.bind(this);
    this.getPerspective = this.getPerspective.bind(this);
    this.getStrategicObjective = this.getStrategicObjective.bind(this);
    this.getOrganization = this.getOrganization.bind(this);
    this.getScoring = this.getScoring.bind(this);
    this.getTarget = this.getTarget.bind(this);
    this.getCategory = this.getCategory.bind(this);
    // this.getTolerance = this.getTolerance.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderParameter = this.renderParameter.bind(this);
    this.changeScoring = this.changeScoring.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getPerspective();
      this.getStrategicObjective();
      this.getOrganization();
      this.getScoring();
      this.getCategory();
      // this.getTolerance();
      this.getTarget();
      this.getButton();
    });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/pfmstrategic` });
    if (this.props.statusEdit) {
      this.getDataKpi();
    }
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const data = { fga_id: fga, men_id: men };
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getOrganization() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgorganization/getAll`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const arr = [];
          res.data.data.map((obj) =>
            arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ arrOrg: arr });
        }
      });
  }

  getCategory() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${ls.get('ws_ip')}/pfmcategoryweightreference`, config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const arr = [];
          res.data.data.map((obj) =>
            arr.push({ value: obj.pcr_id, label: obj.category_name }),
          );
          this.setState({ arrCategory: arr });
        }
      });
  }

  getTarget() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${ls.get('ws_ip')}/pfmtarget`, config).then((res) => {
      if (res.data.status === 'ok') {
        const arr = [];
        res.data.data.map((obj) =>
          arr.push({ value: obj.ptg_id, label: obj.target_name }),
        );
        this.setState({ arrTarget: arr });
      }
    });
  }

  // getTolerance() {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${ls.get('token')}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   axios.get(
  //     `${ls.get('ws_ip')}/pfmtolerance`,
  //     config,
  //   ).then((res) => {
  //     if (res.data.status === 'ok') {
  //       const arr = [];
  //       let noIndex = 1;
  //       for (let i = 0; i < res.data.data.length; i += 1) {
  //         noIndex += 1;
  //         arr.push({
  //           no: noIndex,
  //           ...res.data.data[i],
  //         });
  //       }
  //       this.setState({ arrTolerance: arr });
  //     }
  //   });
  // }

  getScoring() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${ls.get('ws_ip')}/pfmscoringrange`, config).then((res) => {
      if (res.data.status === 'ok') {
        const arr = [];
        res.data.data.map((obj) =>
          arr.push({
            value: obj.psr_id,
            label: obj.range_name,
            index: obj.items_number,
          }),
        );
        this.setState({ arrScoring: arr });
      }
    });
  }

  getStrategicObjective() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = this.props.pso_id;
    axios.get(`${ls.get('ws_ip')}/pfmstrategic/${id}`, config).then((res) => {
      if (res.data.status === 'ok') {
        const strategic = res.data.data[0].strategic_objective_name;
        this.setState({ strategicName: strategic });
        if (this.props.statusEdit === false) {
          this.props.initialize({
            ppr_id: this.props.ppr_id,
            pso_id: this.props.pso_id,
            strategic_objective_name: strategic,
          });
        }
      }
    });
  }

  getPerspective() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${ls.get('ws_ip')}/pfmperspective`, config).then((res) => {
      if (res.data.status === 'ok') {
        const arr = [];
        res.data.data.map((obj) =>
          arr.push({ value: obj.ppr_id, label: obj.perspective_name }),
        );
        this.setState({ arrPerspective: arr });
      }
    });
  }

  getDataKpi() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${ls.get('ws_ip')}/pfmkpi/${this.props.pvm_id}`, config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const target = res.data.data.kpi[0].ptg_id;
          const scoring = res.data.data.kpi[0].psr_id;
          this.changeTarget(target);
          if (scoring !== null) {
            this.changeScoring(scoring);
          }
          this.getStrategicObjective();
          const kpi = res.data.data.kpi[0];
          const arr = [];
          for (let i = 0; i < res.data.data.kpi_parameter.length; i += 1) {
            arr.push({
              parameter_score: res.data.data.kpi_parameter[i].parameter_score,
              parameter: res.data.data.kpi_parameter[i].parameter,
            });
          }
          const dataKpi = {
            ...kpi,
            strategic_objective_name: this.state.strategicName,
            dyn_parameter: arr,
          };
          this.setState({
            ppi_id: res.data.data.kpi[0].ppi_id,
            keyPerformance: res.data.data.kpi[0].kpi_name,
            organization: res.data.data.kpi[0].org_id,
            category: res.data.data.kpi[0].pcr_id,
            target: res.data.data.kpi[0].ptg_id,
            targetValue: res.data.data.kpi[0].target_value,
            // tolerance: res.data.data.kpi[0].pto_id,
            strategicName: this.props.addStrategicOjb,
          });
          console.log('target_value', res.data.data.kpi[0].target_value);
          this.props.initialize(dataKpi);
        }
      });
  }

  mapFormData(b) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (b === 'null' || b === null) {
          val_b = '';
        } else {
          val_b = b.value;
        }
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  handleSubmit(values) {
    const parameter = JSON.stringify(values.dyn_parameter);
    const { apiws, token, idUser } = this.state;
    this.setState({ displaySpinner: true, disabledInput: true });
    const target =
      typeof values.ptg_id === 'object' ? values.ptg_id.value : values.ptg_id;
    const valuesfix = target === 4 ? values : { ...values, psr_id: null };
    // console.log(target);
    delete values.dyn_parameter;
    const formBody = Object.keys(valuesfix)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.mapFormData(valuesfix[key]),
          )}`,
      )
      .join('&');
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let action;
    let link;
    let body;
    if (typeof values.ppi_id === 'undefined') {
      action = 'POST';
      link = `${apiws}/pfmkpi`;
      body =
        target === 4
          ? [formBody, `dyn_parameter=${parameter}`, `cuser_id=${idUser}`].join(
              '&',
            )
          : [formBody, 'dyn_parameter=', `cuser_id=${idUser}`].join('&');
    } else {
      action = 'PUT';
      link = `${apiws}/pfmkpi/${values.ppi_id}`;
      body =
        target === 4
          ? [formBody, `dyn_parameter=${parameter}`, `muser_id=${idUser}`].join(
              '&',
            )
          : [formBody, 'dyn_parameter=', `muser_id=${idUser}`].join('&');
    }
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    // console.log(body);
    axios(options).then((res) => {
      if (res.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.setState({ displaySpinner: false });
        this.setState({ disabledInput: false });
        this.setState({ hideParameter: false });
        this.setState({ hideScoring: false });
        this.props.destroy();
        this.props.toggle();
      } else {
        showNotifications('Failed', 'Save Failed', 'danger');
      }
      // if (this.props.statusEdit) {
      //   this.getDataKpi();
      // } else {
      //   this.props.initialize({
      //     ppr_id: this.props.ppr_id,
      //     pso_id: this.props.pso_id,
      //     strategic_objective_name: this.state.strategicName,
      //   });
      // }
    });
  }

  changeScoring(x) {
    this.setState({ hideParameter: true });
    const arr = [];
    for (let i = 0; i < x.index; i += 1) {
      arr.push({
        parameter_score: i + 1,
        parameter: '',
      });
    }
    if (this.props.statusEdit) {
      this.props.initialize({
        ppi_id: this.state.ppi_id,
        ppr_id: this.props.ppr_id,
        pso_id: this.props.pso_id,
        strategic_objective_name: this.state.strategicName,
        org_id: this.state.organization,
        pcr_id: this.state.category,
        kpi_name: this.state.keyPerformance,
        ptg_id: this.state.target,
        target_value: this.state.targetValue,
        // pto_id: this.state.tolerance,
        dyn_parameter: arr,
      });
    } else {
      this.props.initialize({
        ppr_id: this.props.ppr_id,
        pso_id: this.props.pso_id,
        strategic_objective_name: this.state.strategicName,
        org_id: this.state.organization,
        pcr_id: this.state.category,
        kpi_name: this.state.keyPerformance,
        ptg_id: this.state.target,
        target_value: this.state.targetValue,
        // pto_id: this.state.tolerance,
        dyn_parameter: arr,
      });
    }
  }

  renderParameter = ({ fields, meta: { error } }) => (
    <tbody>
      {fields.map((paraminput, index) => (
        <tr>
          <td style={{ verticalAlign: 'top' }}>
            <Field
              name={`${paraminput}.parameter_score`}
              component={renderTextInput}
              type="number"
              disabled
            />
          </td>
          <td>
            <Field
              name={`${paraminput}.parameter`}
              component={renderTextInput}
              type="textarea"
            />
          </td>
        </tr>
      ))}
    </tbody>
  );

  changeOrg(x) {
    this.setState({ organization: x });
  }

  changeCategory(x) {
    this.setState({ category: x });
  }

  changeKey(x) {
    this.setState({ keyPerformance: x });
  }

  changeTarget(x) {
    this.setState({ target: x });
    this.setState({ hideScoring: false });
    this.setState({ hideParameter: false });
    this.setState({ hideNormal: true });
    this.setState({ hideCurr: false });
    if (x === 4) {
      this.setState({ hideScoring: true });
      this.setState({ hideNormal: true });
      this.setState({ hideCurr: false });
    }
    if (x === 2) {
      this.setState({ hideNormal: false });
      this.setState({ hideCurr: true });
    }
    if (x === 1 || x === 3) {
      this.setState({ hideNormal: true });
      this.setState({ hideCurr: false });
    }
  }

  changeTargetVal(x) {
    this.setState({ targetValue: x.target.value });
  }

  // changeTolerance(x) {
  //   this.setState({ tolerance: x });
  // }

  render() {
    const {
      disabled,
      handleSubmit,
      t,
      pristine,
      addStrategicOjb,
      ppr_id,
    } = this.props;
    const {
      arrPerspective,
      arrOrg,
      displaySpinner,
      disabledInput,
      disabledPerspective,
      arrScoring,
      arrTarget,
      arrCategory,
      // arrTolerance,
      hideParameter,
      hideScoring,
      hideCurr,
      hideNormal,
    } = this.state;

    return (
      <div>
        <ModalHeader>
          {t('LBL.KPI')} {t('LBL.FORM')}
        </ModalHeader>
        <ModalBody>
          <form
            className="form form--horizontal"
            onSubmit={handleSubmit(this.handleSubmit)}
            name="strateficobjective"
            id="strateficobjective"
          >
            <Field
              name="ppi_id"
              component={renderTextInput}
              type="hidden"
              style={{ height: '0px', padding: '0px' }}
            />
            <Container>
              <Row>
                <Col xs={6} md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.PERSPECTIVE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ppr_id"
                        component={renderSelectField}
                        options={arrPerspective}
                        disabled={disabledPerspective}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.STRATEGIC_OBJECTIVE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="strategic_objective_name"
                        component={renderTextInput}
                        disabled={disabledPerspective}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.ORGANIZATION')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="org_id"
                        component={renderSelectField}
                        options={arrOrg}
                        disabled={disabledInput}
                        onChange={(x) => this.changeOrg(x.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.CATEGORY')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="pcr_id"
                        component={renderSelectField}
                        options={arrCategory}
                        disabled={disabledInput}
                        onChange={(x) => this.changeCategory(x.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.KEY_PERFORMANCE_INDICATOR')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="kpi_name"
                        component={renderTextInput}
                        type="textarea"
                        onChange={(x) => this.changeKey(x.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TARGET')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="ptg_id"
                        component={renderSelectField}
                        options={arrTarget}
                        type="textarea"
                        onChange={(x) => this.changeTarget(x.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TARGET_VALUE')}
                    </span>
                    <div className="form__form-group-field number-form">
                      {hideCurr && (
                        <Field
                          name="target_value"
                          component={renderNumberFormat}
                          // props={{ disableTouched: this.props.disabled }}
                          onChange={(val) => {
                            this.setState({ targetValue: val.floatValue });
                          }}
                          other={{
                            defaultValue: this.state.targetValue,
                            value: this.state.targetValue,
                            thousandSeparator: '.',
                            decimalSeparator: ',',
                            disabled,
                          }}
                        />
                      )}
                      {hideNormal && (
                        <Field
                          name="target_value"
                          component={renderTextInput}
                          type="number"
                          inputmode="numeric"
                          maxLength="15"
                          // onChange={(x) => this.changeTargetVal(x)}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={6} md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TOLERANCE')}</span>
                    <div className="form__form-group-field">
                      {
                        arrTolerance.map(data => (
                          <Col lg={5} md={5} xs={5} xl={5} style={{ paddingLeft: '0px' }}>
                            <Field
                              name={`asdsada_${data.pto_id}`}
                              component={renderCheckBoxField}
                              label={data.tolerance_name}
                              radioValue={data.pto_id}
                              // defaultChecked
                              onChange={() => this.changeTolerance(data.pto_id)}
                            />
                          </Col>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row> */}
              {this.state.hideScoring && (
                <Row>
                  <Col xs={6} md={6} lg={6} xl={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.SCORING_RANGE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="psr_id"
                          component={renderSelectField}
                          options={arrScoring}
                          type="textarea"
                          onChange={(x) => this.changeScoring(x)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {hideParameter && (
                <Row>
                  <Col xs={6} md={6} lg={6} xl={6}>
                    <div
                      className="form__form-group"
                      style={{ marginTop: '-5px' }}
                    >
                      <table
                        id="myTable"
                        className="table data-grid-tbl responsive no-wrap table-no-btn"
                      >
                        <thead>
                          <th style={{ width: '10%' }}>{t('LBL.SCORE')}</th>
                          <th style={{ width: '90%' }}>{t('LBL.PARAMETER')}</th>
                        </thead>
                        <FieldArray
                          name="dyn_parameter"
                          component={this.renderParameter}
                        />
                      </table>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
            <Field
              name="pso_id"
              component={renderTextInput}
              type="hidden"
              style={{ height: '0px', padding: '0px' }}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <div style={{ textAlign: 'center' }}>
            <Button color="secondary" onClick={this.props.cancel}>
              {t('FRM.CANCEL')}
            </Button>
            {displaySpinner ? (
              <Button color="primary">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: '6px' }}
                />
                Loading...
              </Button>
            ) : (
              <Button
                color="primary"
                form="strateficobjective"
                type="submit"
                disabled={pristine}
              >
                {' '}
                {t('FRM.SAVE')}
              </Button>
            )}
          </div>
        </ModalFooter>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.org_id) {
    errors.org_id = "Unit organization shouldn't be empty";
  }
  if (!values.pcr_id) {
    errors.pcr_id = "Category shouldn't be empty";
  }
  if (!values.kpi_name) {
    errors.kpi_name = "Kpi name shouldn't be empty";
  }
  if (!values.ptg_id) {
    errors.ptg_id = "Target shouldn't be empty";
  }
  if (!values.target_value) {
    errors.target_value = "Target value shouldn't be empty";
  }
  const ptg =
    typeof values.ptg_id === 'object' ? values.ptg_id.value : values.ptg_id;
  if (ptg === 4) {
    if (!values.psr_id) {
      errors.psr_id = "Scoring range shouldn't be empty";
    }
  }

  return errors;
};

export default reduxForm({
  form: 'kpiform',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Kpiform));
