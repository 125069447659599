/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable quote-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
// import renderDatePickerField from '../../../../shared/components/form/DatePicker';
// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import MinusIcon from 'mdi-react/MinusIcon';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../../../utils';

let translator = () => {};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class LeaveBalanceReport extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.LEAVE_NAME'),
      t('LBL.LEAVE_BALANCE_TYPE'),
      t('LBL.PERIOD_START'),
      t('LBL.PERIOD_END'),
      t('LBL.BALANCE'),
      t('LBL.LEAVE_BALANCE'),
      t('LBL.TOTAL_USED_BALANCE'),
      '',
    ];
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      urlData: '',
      arrEmployee: [],
      arrLeaveType: [],
      condEmployee: '',
      condAtlId: '',
      condBalType: '',
      condEnd: '',
      condStart: '',
      type: '',
      displayTable: '',
      displaySave: '',
      displayLoad: 'none',
      filename: 'Leave Balance Report',
      dataexcel: [],
      headerexcel: [],
      trigger: false,
      org: '',
    };

    this.getOffice = this.getOffice.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getLeavecategory = this.getLeavecategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getOffice();
      this.getUnitOrganization();
      this.getEmployee();
      this.getLeavecategory();
    });
  };

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgoffice/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          this.setState({ arrOffice: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${apiws}/orgorganization/getAll`, config)
      .then((res) => {
        const org = res.data.data;
        const array = [];
        if (res.data.status === 'ok') {
          org.map((obj) =>
            array.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ arrUnitOrganization: array });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/employee/getAllItemOrg/${org}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ arrEmployee: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getLeavecategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/Attleave/getAll/`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ arrLeaveType: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.atl_id, label: obj.leave_name }),
          );
          this.setState({ arrLeaveType: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport() {
    this.setState({
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
    });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      emp_id: this.state.condEmployee,
      atl_id: this.state.condAtlId,
      balance_type: this.state.condBalType,
      end_date: this.state.condEnd,
      start_date: this.state.condStart,
      type: '2',
    };

    const bodyParameters = {
      cond: conds,
    };
    axios
      .post(`${apiws}/empleavebalance/getReport`, bodyParameters, config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const { data, header } = res.data;
          const array = [];
          data.map((obj, index) => array.push({ No: index + 1, ...obj }));
          // console.log(array, header);
          this.setState({
            dataexcel: array,
            headerexcel: header,
            trigger: !this.state.trigger,
          });
          this.setState({
            displayTable: '',
            displayLoad: 'none',
            displaySave: '',
          });
          this.setState({ type: '2' });
        }
      })
      .finally(() => {
        showNotifications('Success', 'Load Success', 'success');
        this.inputElement.click();
      });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  };

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  handleSubmit(values) {
    this.setState({
      displayTable: 'none',
      displayLoad: '',
      displaySave: 'none',
    });
    const empId =
      typeof values.employee2 === 'undefined' || values.employee2 === null
        ? ''
        : values.employee2.value;
    const atlId =
      typeof values.atl_id === 'undefined' || values.atl_id === null
        ? ''
        : values.atl_id.value;
    const balType =
      typeof values.balance_type === 'undefined' || values.balance_type === null
        ? ''
        : values.balance_type.value;
    // eslint-disable-next-line valid-typeof
    const start =
      typeof values.period_start2 === 'undefined' ||
      values.period_start2 === null
        ? ''
        : values.period_start2.format('YYYY-MM-DD');
    const end =
      typeof values.period_end2 === 'undefined' || values.period_end2 === null
        ? ''
        : values.period_end2.format('YYYY-MM-DD');
    const type_report =
      typeof values.type_report3 === 'undefined' || values.type_report3 === null
        ? '1'
        : values.type_report3.value;
    setTimeout(() => {
      this.setState({ condEmployee: empId });
      this.setState({ condAtlId: atlId });
      this.setState({ condBalType: balType });
      this.setState({ condStart: start });
      this.setState({ condEnd: end });
      this.setState({ type: type_report });
    }, 100);
    if (type_report === '1') {
      setTimeout(() => {
        this.setState({
          displayTable: '',
          displayLoad: 'none',
          displaySave: '',
        });
        this.setState({
          urlData: `${ls.get('ws_ip')}/empleavebalance/getReport`,
        });
        this.getData();
        showNotifications('Success', 'Load Success', 'success');
      }, 1000);
    } else {
      setTimeout(() => {
        this.getReport();
      }, 500);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      arrEmployee,
      arrLeaveType,
      displaySave,
      displayLoad,
      displayTable,
      condEmployee,
      condAtlId,
      condBalType,
      condStart,
      condEnd,
      type,
    } = this.state;
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0">
              <Col md={12} lg={6} style={{ marginTop: '25px' }}>
                <form
                  name="leave_balance_report_form"
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EMPLOYEE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee2"
                        component={renderSelectField}
                        type="text"
                        options={arrEmployee}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_TYPE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="atl_id"
                        component={renderSelectField}
                        // type="text"
                        options={arrLeaveType}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_BALANCE_TYPE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="balance_type"
                        component={renderSelectField}
                        options={[
                          { value: '1', label: 'Day' },
                          { value: '2', label: 'Month' },
                        ]}
                      />
                    </div>
                  </div>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                    <div className="form__form-group-field" >
                      <Field
                        name="period_start2"
                        component={renderDatePickerField}
                        placeholder="Start Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                      <MinusIcon className="date-picker__svg" />
                      <Field
                        name="period_end2"
                        component={renderDatePickerField}
                        type="text"
                        placeholder="End Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TYPE_REPORT')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="type_report3"
                        component={renderSelectField}
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                  </div>
                  {type === '2' && (
                    <ExcelFile
                      element={
                        <div
                          onClick={this.handleClick}
                          onKeyPress={this.handleClick}
                          role="button"
                          tabIndex="0"
                        >
                          <input
                            type="hidden"
                            ref={(ref) => {
                              this.inputElement = ref;
                            }}
                          />
                        </div>
                      }
                      filename={this.state.filename}
                    >
                      <ExcelSheet
                        data={this.state.dataexcel}
                        name="Leave Balance"
                      >
                        {this.state.headerexcel.map((obj) => (
                          <ExcelColumn label={obj} value={obj} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button
                      color="primary"
                      type="submit"
                      style={{ display: displaySave }}
                    >
                      {t('FRM.SUBMIT')}{' '}
                    </Button>
                    <Button color="primary" style={{ display: displayLoad }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
            <Row style={{ display: displayTable }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <Row className="m5 table-nopadding">
                      <div className="card__title">
                        <h5 className="bold-text">
                          {t('MEN.LEAVE_BAL_REPORT')}
                        </h5>
                      </div>
                      <ViewListTable
                        url={urlData}
                        heads={this.heads}
                        // primaryKey="pyp_id"
                        // widthTable={this.state.dataWidth}
                        buttonAction="noButton"
                        conds={{
                          emp_id: condEmployee,
                          atl_id: condAtlId,
                          balance_type: condBalType,
                          start_date: condStart,
                          end_date: condEnd,
                          type: type,
                        }}
                        onRef={(ref) => (this.child = ref)}
                        displayStyleHead="none"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    type_report3: {
      fieldLabel: `${translator('LBL.TYPE_REPORT')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'leave_balance_report_form', // a unique identifier for this form
  validate,
})(translate('global')(LeaveBalanceReport));
