/*
 * Page Label Preferences
 * Notes: Master Data Function Access
 * @author Gerdi
 * @date 22/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../notificationMessages';
import PropTypes from 'prop-types';
import { deleteCryptoTableData } from '../../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';

const labelNameExist = { EN: [], ID: [], CH: [] };
const labelCodeExist = { EN: [], ID: [], CH: [] };
class LabelSetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LABEL_CODE') },
      { '2': t('LBL.LABEL') },
      { '3': t('LBL.LANGUAGE') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['10%', '25%', '40%', '5%', '20%'],
      labelName: '',
      errorLabelName: 'none',
      labelCode: '',
      errorLabelCode: 'none',
      language: '',
      tableInput: 'none',
      tableData: '',
      rowDataLabel: [],
      rowLabelId: [],
      statusEdit: false,
      disableButton: false,
      disableLng: false,
      arrLng: [],
      action: '',
      modal: false,
      lblType: 'LBL',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getButton = this.getButton.bind(this);
    this.backListLabel = this.backListLabel.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addLabel = this.addLabel.bind(this);
    this.editLabel = this.editLabel.bind(this);
    this.deleteLabel = this.deleteLabel.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getLanguage();
      this.getLangLabel();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/applabel/getAllItem` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LABEL_CODE') },
      { '2': t('LBL.LABEL') },
      { '3': t('LBL.LANGUAGE') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getLanguage() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/applanguage/getAll`, '', config)
      .then((response) => {
        const remp = response.data.data;
        const array = [];
        if (response.data.status === 'ok') {
          remp.map((obj) =>
            array.push({ value: `${obj.lng_id}`, label: obj.lng_language }),
          );
          this.setState({ arrLng: array });
        }
        // console.log(this.state.arrLng);
      });
  }

  async getLangLabel() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${ls.get('ws_ip')}/applabel/getAll`, '', config)
      .then(async (res) => {
        const dataResponse = res.data.data;
        console.log(dataResponse);
        for (let i = 0; i < dataResponse?.length; i += 1) {
          labelNameExist[dataResponse[i].lng_id][i] = dataResponse[i].lbl_label;
          labelCodeExist[dataResponse[i].lng_id][i] = dataResponse[i].lbl_code;
        }
        console.log('labelname', labelNameExist);
        console.log(labelCodeExist);
      });
  }

  backListLabel() {
    this.setState({
      tableData: '',
      tableInput: 'none',
    });
    this.getTableData();
  }

  async handleSubmit(values) {
    const { apiws, token, idUser, rowMenuId } = this.state;
    const id = values.lbl_id;
    const label = values.lbl_label;
    const type =
      typeof values.labelType === 'object'
        ? values.labelType.value
        : values.labelType;
    const code = `${type}.${values.lbl_code}`;
    const lang =
      typeof values.lng_id.value === 'undefined'
        ? values.lng_id
        : values.lng_id.value;
    let action;
    let url;
    let dataInput;
    let error;
    if (id === undefined || id === '') {
      action = 'add';
      dataInput = `lbl_code=${code}&lbl_label=${label}&lng_id=${lang}&cuser_id=${idUser}`;
      url = `${apiws}/applabel/saveItem`;
    } else {
      action = 'edit';
      dataInput = `lbl_code=${code}&lbl_label=${label}&lng_id=${lang}&muser_id=${idUser}`;
      url = `${apiws}/applabel/updateItem/${id}`;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (label !== '') {
      await axios
        .post(url, dataInput, config)
        .then(async (response) => {
          if (response.data.status === 'ok') {
            this.props.destroy();
            showNotifications('Success', 'Save Success', 'success');
            this.setState({
              modal: false,
              statusEdit: false,
            });
            this.getTableData();
            this.getLangLabel();
          }
        })
        .catch(() => {
          showNotifications('Fail', 'Save Failed', 'danger');
        });
    } else {
      // console.log();
    }
  }

  addLabel() {
    this.setState({ action: 'add', disableButton: true });
    this.toggle();
    this.props.initialize({
      lbl_id: '',
      lbl_code: '',
      lbl_label: '',
      lng_id: '',
    });
  }

  async editLabel(id, e, param) {
    const { token, apiws, labelName } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/applabel/getItemById/${id}`, '', config)
      .then(async (response) => {
        if (parseInt(response.data.data.length, 10) > 0) {
          if (id === undefined || id === '' || typeof id === 'undefined') {
            this.props.initialize({
              lbl_id: '',
              lbl_code: '',
              lbl_label: labelName,
              lng_id: '',
            });
          } else {
            if (response.data.status === 'ok') {
              const getResponseData = response.data.data;
              const idxLabel1 = getResponseData[0].lbl_label;
              const idxLabel2 = idxLabel1.indexOf(getResponseData[0].lbl_label);
              // console.log('labelname1', idxLabel2);
              // const idxLabel = labelNameExist.indexOf(getResponseData[0].lbl_label);
              if (idxLabel2 !== -1) {
                // labelNameExist.splice(idxLabel2, 1);
              }
              this.setState({
                statusEdit: true,
                disableButton: false,
                modal: true,
                action: 'edit',
                language: response.data.data[0].lng_id,
              });
              const code = response.data.data[0].lbl_code;
              const codeStr = code.split('.');
              this.props.initialize({
                lbl_id: response.data.data[0].lbl_id,
                labelType: `${codeStr[0]}`,
                lbl_code: codeStr[1],
                lbl_label: response.data.data[0].lbl_label,
                lng_id: `${response.data.data[0].lng_id.trim()}`,
              });
            }
          }
        }
      });
  }

  deleteLabel(id) {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/applabel/deleteitem/${id}`,
            `duser_id=${idUser}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              this.getTableData();
              this.getLangLabel();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
            this.getLangLabel();
          })
          .catch((error) => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  toggle() {
    if (typeof e === 'string') {
      this.setState({ action: e });
    }
    this.setState({ modal: !this.state.modal, disableButton: true });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      urlData,
      disableButton,
      statusEdit,
      errorLabelName,
      arrLng,
      disableLng,
    } = this.state;
    const addBtn = (
      <Col>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={this.addLabel}
          style={{ float: 'right', 'margin-bottom': '20px' }}
        >
          {t('FRM.ADD')} {t('LBL.LABEL')}
        </Button>
      </Col>
    );

    let labelButton;
    let colorButton;
    if (statusEdit) {
      labelButton = t('FRM.EDIT');
      colorButton = 'success';
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="lbl_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteLabel}
                  updateFunc={this.editLabel}
                  onRef={(ref) => (this.child = ref)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {' '}
            {this.state.action === 'add' ? t('FRM.ADD') : t('FRM.EDIT')}{' '}
            {t('LBL.LABEL')}{' '}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="labelform"
              id="labelform"
            >
              <input type="hidden" name="lbl_id" value="" />
              <Container>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.LABEL_CODE')}
                      </span>
                      <div className="form__form-group-field">
                        <div className="input-group-prepend mr-1">
                          <Field
                            component={renderSelectField}
                            name="labelType"
                            id="labelType"
                            options={[
                              { value: 'LBL', label: 'LBL' },
                              { value: 'FRM', label: 'FRM' },
                              { value: 'MEN', label: 'MEN' },
                            ]}
                          />
                        </div>
                        <Field
                          name="lbl_code"
                          id="lbl_code"
                          component={renderTextInput}
                          type="text"
                          // onChange={(e) => { this.changeValue(e, 'labelCode'); }}
                          style={{ marginLeft: '1rem' }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      {/* <span
                        className="form__form-group-error"
                        style={
                          {
                            background: '#ffbcbc',
                            borderRadius: '3px',
                            position: 'absolute',
                            padding: '5px 10px 5px 10px',
                            float: 'right',
                            margin: '-28px 0px 0px 420px',
                            display: errorLabelName,
                          }
                        }
                      >
                        Label name is already taken
                      </span> */}
                      <span className="form__form-group-label">
                        {t('LBL.LABEL')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="lbl_label"
                          id="lbl_label"
                          component={renderTextInput}
                          type="text"
                          // onChange={(e) => { this.changeValue(e, 'labelName'); }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.LANGUAGE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="lng_id"
                          id="lng_id"
                          component={renderSelectField}
                          options={arrLng}
                          // onChange={(e) => { this.changeValue(e, 'language'); }}
                          disabled={disableLng}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle('cancel')}>
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color={colorButton}
              form="labelform"
              type="submit"
              disabled={pristine}
            >
              {' '}
              {labelButton}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const labelName = labelNameExist;
  const labelCode = labelCodeExist;
  if (!values.lbl_label) {
    errors.lbl_label = 'Label field shouldn’t be empty';
  } else if (
    labelName[values.lng_id.value] !== undefined &&
    labelName[values.lng_id.value].includes(values.lbl_label)
  ) {
    errors.lbl_label = 'Label is already taken';
  }
  if (!values.lbl_code) {
    errors.lbl_code = 'Label code field shouldn’t be empty';
  } else {
    if (values.lbl_code && values.labelType) {
      const lblNew = `${values.labelType.value}.${values.lbl_code}`;
      if (
        labelCode[values.lng_id.value] !== undefined &&
        labelCode[values.lng_id.value].includes(lblNew)
      ) {
        errors.lbl_code = 'Label code is already taken';
      }
    }
  }
  if (typeof values.lng_id === 'undefined' || values.lng_id === null) {
    errors.lng_id = 'Language field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'reduxLabelSetting', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(LabelSetting));
