/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import {
  faPlus,
  faMinus,
  faTrashAlt,
  faPencilAlt,
  faTimes,
  faSearch,
  faRedoAlt,
  faBook,
  faAsterisk,
  faArrowsAltV,
} from '@fortawesome/fontawesome-free-solid';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import renderTextInput from '../../../../shared/components/form/TextInput';
import showNotifications from '../../notificationMessages';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import SunEditor, { buttonList } from 'suneditor-react';
import HtmlDocx from 'html-docx-js/dist/html-docx';
import FileSaver from 'file-saver';

class DocumentPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    textEditorFunc: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    // this.heads = [
    //   { '0': t('LBL.NUMBER') },
    //   { '1': t('LBL.DOCUMENT') },
    //   { '2': 'File' },
    //   { '3': t('FRM.FUNCTION') },
    // ];
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.DOCUMENT'),
      'FIle',
      t('FRM.FUNCTION'),
    ];
    this.state = {
      dataTable: [],
      token: '',
      apiws: '',
      iduser: '',
      joblevel: [],
      modal: false,
      urlData: '',
      file: '',
      rows: [],
      dataWidth: ['5%', '70%', '25%'],
    };
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.toggleAndRefresh = this.toggleAndRefresh.bind(this);
    this.deletedocument = this.deletedocument.bind(this);
    this.getDocument = this.getDocument.bind(this);
    this.getDocumentId = this.getDocumentId.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ urlData: `${ls.get('ws_ip')}/appdocument/getAllItem` });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
    }, () => {
      this.getTableData();
    });
  }
  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.DOCUMENT'),
      'File',
      t('FRM.FUNCTION'),
    ];
  }

  getDocument() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/appdocument/getAllItem/10/0`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        this.setState({ rows: getResponseData });
        // this.setState({ file: getResponseData.file });
        // console.log(getRE);
      } else {
        // this.setState({ button_disabled: false });
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      // this.setState({ button_disabled: false });
    });
  }

  getDocumentId(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/appdocument/getAllItemById/${id}`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        // this.setState({ modal: true }, () => {
        // this.props.destroy();
        // this.setState({ file: getResponseData[0].file });
        setTimeout(() => {
          const content = `<!DOCTYPE html>${getResponseData[0].file}`;
          // const content = `<!DOCTYPE html>${getResponseData[0].file}`;
          const converted = HtmlDocx.asBlob(content);
          // const string = HtmlDocx.asBlob('<!DOCTYPE html><head>hello</head><body>yes</body>');
          FileSaver.saveAs(converted, getResponseData[0].document);
        }, 2000);
        // });
      } else {
        // this.setState({ button_disabled: false });
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      // this.setState({ button_disabled: false });
    });
  }
  getTableData() {
    this.child.reload();
  }

  deletedocument(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/appdocument/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.getTableData();
            }, 2000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          // console.log(error);
          showNotifications('Fail', 'Delete Failed', 'danger');
          // this.setState({ button_disabled: false });
        });
      }
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    }, () => { this.props.destroy(); });
  }
  toggleAndRefresh() {
    this.setState({
      modal: !this.state.modal,
    });
    setTimeout(() => {
      this.getTableData();
    }, 2000);
  }

  render() {
    const {
      urlData,
    } = this.state;
    const {
      handleSubmit, pristine, t, textEditorFunc,
    } = this.props;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={textEditorFunc}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('LBL.DOCUMENT')}
      </Button>
    );
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="dct_id"
                  deleteFunc={this.deletedocument}
                  updateFunc={textEditorFunc}
                  onRef={ref => (this.child = ref)}
                  widthTable={this.state.dataWidth}
                  getDocumentId={this.getDocumentId}
                  displayStyleHead="none"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0 mt-min15">
                <Row>
                  <Col md={8} />
                  <Col md={4}>
                    <div className="btn_action_table_navbar">
                      <ButtonToolbar>
                        <Modal
                          isOpen={this.state.modal}
                          toggle={this.toggle}
                          className="modal-dialog
                            modal-input"
                          style={{ marginTop: '100px' }}
                        >
                          <ModalHeader toggle={this.toggle}>
                            {t('LBL.DOCUMENT')}
                          </ModalHeader>
                          <ModalBody>
                            <form id="adddocument" className="form form--horizontal" onSubmit={handleSubmit}>
                              <div className="form__form-group">
                                <div>{parse(this.state.file)}</div>
                              </div>
                            </form>
                          </ModalBody>
                          <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
                          </ModalFooter>
                        </Modal>
                      </ButtonToolbar>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

async function submit(values) {
  await sleep(500);
  const config = {
    headers: {
      'Authorization': `bearer ${ls.get('token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  let url;
  const apiws = ls.get('ws_ip');
  const userid = ls.get('user_cred').usr_id;
  const id = values.dct_id;

  let data;
  if (id === undefined || id === '') {
    url = `${apiws}/appdocument/saveItem`;
    data = { ...values, cuser_id: userid };
  } else {
    url = `${apiws}/appdocument/updateItem/${id}`;
    data = { ...values, muser_id: userid };
  }
  const formBody = Object.keys(data).map(key =>
    `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
  axios.post(
    url,
    formBody,
    config,
  ).then((response) => {
    if (response.data.status === 'ok') {
      showNotifications('Success', 'Save Success', 'success');
    } else {
      showNotifications('Fail', 'Save Failed', 'danger');
    }
  }).catch(() => {
    // console.log(error);
  });
}

const validate = (values) => {
  const errors = {};

  if (!values.job_id) {
    errors.job_id = 'Job Level field shouldn’t be empty';
  }

  if (!values.job_name) {
    errors.job_name = 'Job Band field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  form: 'documentform', // a unique identifier for this form
  onSubmit: submit,
  validate,
})(translate('global')(DocumentPage));
