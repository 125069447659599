/* eslint-disable no-unused-vars, no-plusplus */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url, jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Card, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faUser,
  faPaperclip,
} from '@fortawesome/fontawesome-free-solid';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import moment from 'moment';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { store } from '../../../App/store';
import './NewsUpdatePanel.css';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  swipeToSlide: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  rows: 1,
  slidesPerRow: 1,
  nextArrow: <NextSlide />,
  prevArrow: <PrevSlide />,
  // className: "d-flex flex-column",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function NextSlide(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        backgroundColor: '#024377',
        padding: 6,
        position: 'absolute',
        right: 24,
        zIndex: 1,
        top: 'calc(50% - 40px)',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} className="text-white" />
    </div>
  );
}

function PrevSlide(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        backgroundColor: '#024377',
        padding: 6,
        position: 'absolute',
        left: 24,
        zIndex: 1,
        top: 'calc(50% - 40px)',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleLeft} className="text-white" />
    </div>
  );
}

class NewsAndUpdatesPanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: ls.get('ws_ip'),
      token: ls.get('token'),
      idUser: ls.get('user_cred')?.usr_id,
      visi: '',
      misi: '',
      modal: false,
      selectedNews: null,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    if (this?.props?.user?.news?.length == 0) {
      this.getNews();
    } else {
      setTimeout(() => {
        this.getNews();
      }, 2000);
    }
  };

  getNews() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${apiws}/appnews/getAllForPanel`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          store.dispatch({ type: 'SET_USER_NEWS', data: response.data.data });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getAttachment(id) {
    const { apiws, token } = this.state;
    const config = {
      responseType: 'blob',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/appnews/getAttachment/${id}`, '', config)
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle(i) {
    if (typeof i === 'number') {
      this.setState({ selectedNews: i });
    } else {
      this.setState({ selectedNews: null });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const { t } = this.props;
    const { selectedNews } = this.state;
    let newsId;
    let newsTitle;
    let newsDescription;
    let newsAttachment;
    let newsDt;
    let newsAttachmentName = '';
    let newsDate;
    let banner;
    const newsData = this.props.user.news;

    if (selectedNews !== null) {
      const timestamp = newsData[selectedNews].ctime;
      const dateTime = timestamp.split(' ');
      const date = dateTime[0].split('-');
      const day = moment(timestamp).format('dddd');
      newsDt = `${day}, ${date[2]}/${date[1]}/${date[0]}`;
      newsId = newsData[selectedNews].anu_id;
      newsTitle = newsData[selectedNews].title;
      newsDescription = newsData[selectedNews].description;

      // new format from figma design
      newsDate = moment(timestamp).format('DD MMMM YYYY');
      banner =
        newsData[selectedNews].banner === '' ||
        newsData[selectedNews].banner === ' '
          ? '/img/empty-news-image.png'
          : `${ls.get('ws_ip')}/public/uploads${newsData[selectedNews].banner}`;
      if (
        newsData[selectedNews].attachment !== '' ||
        newsData[selectedNews].attachment !== null
      ) {
        newsAttachment = `${this.state.apiws}/appnews/getattachment/${newsData[selectedNews].anu_id}`;
        const AttName = newsData[selectedNews].attachment.split('_');
        if (AttName.length > 1) {
          for (let i = 1; i < AttName.length; i++) {
            newsAttachmentName += AttName[i];
          }
        }
      }
    }
    return (
      <Card>
        <div className="news-update-panel">
          <div className="news-panel-title">{t('MEN.NEWS_AND_UPDATES')}</div>
          <div className="news-panel-list px-0">
            <ul>
              <Slider {...settings} className="dashboard__carousel">
                {this?.props?.user?.news?.map((data, i) => {
                  const date = moment(data.ctime).format('DD MMMM YYYY');
                  return (
                    <li key={`news_${data.anu_id}`}>
                      <div className="list-news-update">
                        <a
                          onClick={() => this.toggle(i)}
                          style={{ cursor: 'pointer' }}
                        >
                          <img
                            src={
                              data.banner.length > 1
                                ? ls.get('ws_ip') +
                                  '/public/uploads' +
                                  data.banner
                                : '/img/empty-news-image.png'
                            }
                            style={{ maxHeight: 170, objectFit: 'cover' }}
                            className="rounded-lg mb-2"
                          />
                        </a>
                        <div className="d-flex">
                          <div
                            className="title-news-update"
                            style={{ color: '#646777' }}
                          >
                            {data.title}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            className="news-date"
                            style={{ color: '#646777' }}
                          >
                            {date}
                          </div>
                          {/* <div className="act-detail">
                              <a
                                onClick={() => this.toggle(i)}
                                className="link-see-detail-news"
                              >
                                {t('LBL.SEE_DETAIL')}
                                <FontAwesomeIcon icon={faAngleRight} />
                              </a>
                            </div> */}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </Slider>
            </ul>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input rounded-lg overflow-hidden"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}> News & Update </ModalHeader>
          <ModalBody>
            {banner !== '' && (
              <div className="news-header p-2">
                <div className="img-news-header">
                  <img
                    src={banner}
                    style={{ objectFit: 'contain' }}
                    alt="news-img"
                  />
                </div>
              </div>
            )}
            <div
              className="news-desc px-2 pt-0 border-0 pb-0"
              style={{ textAlign: 'justify' }}
            >
              <div className="row">
                <div className="col-md-8">
                  <h5
                    style={{
                      fontWeight: 600,
                      color: '#646777',
                    }}
                  >
                    {newsTitle}
                  </h5>
                  <p
                    style={{
                      color: '#646777',
                      marginTop: 0,
                    }}
                  >
                    {newsDate}
                  </p>
                </div>
                <div className="col-md-4">
                  {newsAttachmentName !== '' && (
                    <button className="bg-transparent border-0 d-flex align-items-center text-primary ml-auto">
                      <FontAwesomeIcon icon={faPaperclip} className={'mr-1'} />
                      <span onClick={() => this.getAttachment(newsId)}>
                        {newsAttachmentName}
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{newsDescription}</p>
            </div>
          </ModalBody>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(
  connect(mapStateToProps)(NewsAndUpdatesPanel),
);
