/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../../Preferences/notificationMessages';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
// import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import ReactExport from 'react-export-excel';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ReportAttendanceForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { url, t } = props;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      arrPeriod: [],
      urlData: '',
      orgorganization: [],
      company: [],
      empcurrent: [],
      search: '',
      org: '',
      dataexcel: '',
      type: '',
    };
    this.heads = [
      'No',
      'Employee Code',
      'Employee',
      'Organizational Unit',
      'Date',
      'Time In',
      'Time out',
      'Status',
      'Shift',
      'Hours worked',
      'Late',
    ];
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.loadSelectBox(); });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/${org}`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ empcurrent: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios.post(
      `${apiws}/orgcompany/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.cmp_id, label: obj.company })
        ));
        this.setState({ company: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/employee/getAllItemOrg/0`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ empcurrent: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgcompany/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.cmp_id, label: obj.company })
        ));
        this.setState({ company: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ orgorganization: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  handleClick = (e) => {
    e.preventDefault();
  }

  handleSubmit(values) {
    console.log(values);
    const {
      apiws,
      token,
      idUser,
      url,
    } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (typeof values.start_date === 'undefined') {
      showNotifications('Fail', 'Period is empty', 'danger');
    } else if (typeof values.end_date === 'undefined') {
      showNotifications('Fail', 'Period is empty', 'danger');
    } else {
      const org_id = (typeof values.org_id === 'undefined' || values.org_id === null) ? '0' : values.org_id.value;
      const emp_id = (typeof values.emp_employee === 'undefined' || values.emp_employee === null) ? '0' : values.emp_employee.label2;
      const start = values.start_date.format('YYYY-MM-DD');
      const end = values.end_date.format('YYYY-MM-DD');
      const link = `${apiws}/Attreport/getAttendancereport/${start}/${end}/${org_id}/${emp_id}`;
      axios.post(
        link,
        '',
        config,
      ).then((res) => {
        if (res.data.status === 'ok') {
          if (values.type_report.value === '1') {
            if (res.data.data.length === 0) {
              showNotifications('Success', 'Empty Data', 'success');
            } else {
              showNotifications('Success', 'Available Data', 'success');
              this.props.destroy();
              this.props.onProcess(link);
              setTimeout(() => {
                this.loadSelectBox();
              }, 200);
            }
          } else if (values.type_report.value === '2') {
            if (res.data.data.length === 0) {
              showNotifications('Success', 'Empty Data', 'success');
            } else {
              showNotifications('Success', 'Available Data', 'success');
              this.props.destroy();
              const data = res.data.data;
              const array = [];
              data.map((obj, index) => (
                array.push({
                  'No': (index + 1),
                  'Employee Code': obj.emp_code,
                  'Employee': obj.emp_name,
                  'Organizational Unit': obj.unit_organization,
                  'Date': obj.atr_date,
                  'Time In': obj.atr_in,
                  'Time out': obj.atr_out,
                  'Status': obj.atr_status,
                  'Shift': obj.ats_name,
                  'Hours worked': obj.hours_worked,
                  'Late': obj.atr_value,
                })
              ));
              this.setState({ dataexcel: array, headerexcel: this.heads });
              setTimeout(() => {
                showNotifications('Success', 'Load Success', 'success');
                this.setState({ type: '2' });
                this.inputElement.click();
              }, 1000);
            }
          }
        } else {
          showNotifications('Fail', 'Failed', 'danger');
        }
      });
    }
  }
  render() {
    const { handleSubmit, t } = this.props;
    const { company, orgorganization, empcurrent } = this.state;
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col>
                <h3 className="page-title">Attendance Summary</h3>
              </Col>
            </Row>
            <Row className="m5">
              <Col md={6} xs={12} lg={6} xl={6}>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="runAttendanceReportMonthly"
                  id="runAttendanceReportMonthly"
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="cmp_id"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Company"
                        options={company}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="org_id"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Unit Organization"
                        options={orgorganization}
                        onChange={(val) => {
                          console.log(val); this.setState({ org: val.value }); setTimeout(() => {
                            this.getEmployee();
                          }, 200);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="emp_employee"
                        component={renderSelectField}
                        type="text"
                        placeholder="Emp code, Name"
                        options={empcurrent}
                      />
                      <div className="form__form-group-icon">
                        <AccountSearchIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                    <div className="form__form-group-field">
                      <div className="d-flex w-100">
                        <div className="w-100" style={{ paddingRight: '5px' }}>
                          <Field
                            name="select"
                            component={renderSelectField}
                            placeholder="Month"
                            options={[
                              { value: 'jan', label: 'January' },
                              { value: 'feb', label: 'February' },
                              { value: 'mar', label: 'March' },
                            ]}
                          />
                        </div>
                        <div className="w-100" style={{ paddingLeft: '5px' }}>
                          <Field
                            name="select"
                            component={renderSelectField}
                            placeholder="Year"
                            options={[
                              { value: '2015', label: '2015' },
                              { value: '2016', label: '2016' },
                              { value: '2017', label: '2017' },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Attendance Type</span>
                    <div className="form__form-group-field">
                      <Field
                        name="select"
                        component={renderSelectField}
                        placeholder="Select Leave Type"
                        options={[
                          { value: '...', label: '...' },
                          { value: '...', label: '...' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                    <div className="form__form-group-field">
                      <Field
                        id="typeAttdReport"
                        name="type_report"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select type report"
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                    {(this.state.type === '2') && (
                      <ExcelFile
                        element={
                          <div
                            onClick={this.handleClick}
                            onKeyPress={this.handleClick}
                            role="button"
                            tabIndex="0"
                          ><input type="hidden" ref={input => this.inputElement = input} />
                          </div>}
                        filename="Report Attendance"
                      >
                        <ExcelSheet data={this.state.dataexcel} name="Employees">
                          {
                            this.state.headerexcel.map(obj => (
                              <ExcelColumn label={obj} value={obj} />
                            ))
                          }
                        </ExcelSheet>
                      </ExcelFile>
                    )}
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button
                      color="primary"
                      className="btn_table_navbar"
                      style={{ float: 'right', 'margin-bottom': '20px' }}
                    >
                      {t('FRM.SUBMIT')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'horizontal_form_layout', // a unique identifier for this form
  validate,
})(translate('global')(ReportAttendanceForm));
