import React, { useEffect, useMemo, useState } from 'react';
import TaskKanbanContainer from './TaskKanbanContainer';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './styles/TaskKanban.scss';
import ls from 'local-storage';
import axios from 'axios';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';

const validate = (values) => {
  const errors = {};
  if (!values.section_name) {
    errors.section_name = "Section name shouldn't be empty";
  }
  return errors;
};

const TaskKanban = ({
  t,
  items,
  reloadSection,
  toggleOffCanvas,
  toggleNotification,
  getDataSection,
  activeTab,
}) => {
  const [activeId, setActiveId] = useState(0);
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
    usr_id: ls.get('user_cred').usr_id,
    emp_id: ls.get('user_cred').emp_id,
  });
  const [sections, setSections] = useState(items);
  const [defaultSectionId, setDefaultSectionId] = useState([]);
  const [filter, setFilter] = useState({
    status: '',
    priority: '',
  });
  const [orderSection, setOrderSection] = useState([]);
  const [isAddSection, setIsAddSection] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [touched, setTouched] = useState(false);

  const handleChangeSection = (event) => {
    const value = event?.target?.value;
    if (value == 'Done' || value == 'Archive') {
      setErrMessage("Section name can't be same as the default section");
      setTouched(true);
    } else if (!value) {
      setErrMessage("Section name shouldn't be empty");
      setTouched(true);
    } else {
      setErrMessage('');
      setTouched(false);
    }

    setSectionName(event?.target?.value); // set input section name
  };

  const handleDragEnd = (result) => {
    console.log(result, '<<<<<<<<');
    if (result.type === 'section') {
      handleReorderSection(result.source?.index, result.destination?.index);
      return;
    }
    const destination = result.destination?.droppableId;
    const activeIndex = result.source?.index;
    const overIndex = result.destination?.index;
    const source = result.source?.droppableId;
    if (destination === source) {
      handleOrderTask(activeIndex, overIndex);
    } else {
      setSections((prev) => {
        const updatedSections = [...prev];

        const activeSectionIndex = updatedSections.findIndex((section) => {
          if (activeTab == '1') {
            return section.pps_id === source;
          } else {
            return section.pse_id === source;
          }
        });
        const overSectionIndex = updatedSections.findIndex((section) => {
          if (activeTab == '1') {
            return section.pps_id === destination;
          } else {
            return section.pse_id === destination;
          }
        });
        if (activeSectionIndex !== -1 && overSectionIndex !== -1) {
          const activeSection = updatedSections[activeSectionIndex];
          const overSection = updatedSections[overSectionIndex];

          const movedTask = activeSection.tasks.splice(activeIndex, 1)[0];
          const overTask = overSection.tasks[overIndex];
          if (overTask) {
            overSection.tasks.splice(overIndex, 0, movedTask);
          } else {
            overSection.tasks.push(movedTask);
          }
        }

        //SET FORM DATA
        let updateArr = [];
        updatedSections.map((item) => {
          let dataTemp = {};
          item.tasks.map((data, index) => {
            const reversedIndex = item.tasks.length - 1 - index;
            if (activeTab == '1') {
              dataTemp = {
                pps_id: item.pps_id,
                order: reversedIndex,
                pta_id: data.pta_id,
              };
            } else {
              dataTemp = {
                pse_id: item.pse_id,
                order: reversedIndex,
                pta_id: data.pta_id,
              };
            }
            updateArr.push(dataTemp);
          });
        });
        // UPDATE ORDER
        const config = {
          headers: {
            Authorization: `bearer ${auth.token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const formFilter = new FormData();
        formFilter.append('data', JSON.stringify(updateArr));
        formFilter.append('tab', activeTab);
        formFilter.append('pta_id', activeId);

        axios
          .post(`${auth.apiws}/Task/updateSectionOnDrag`, formFilter, config)
          .then((response) => {
            // getDataSection();
          })
          .catch((error) => {
            // setIsLoading(false);
            console.log(error);
          });

        // RETURN NEW SECTION TO FETCHING DATA
        return updatedSections;
      });
    }
  };

  const handleOrderTask = (activeIndex, overIndex) => {
    if (activeIndex === overIndex) {
      return;
    }
    console.log('masuk sini');
    setSections((sections) => {
      const updatedSections = sections.map((section) => {
        const updatedTasks = section.tasks.slice();
        const taskIndex = updatedTasks.findIndex(
          (item) => item.pta_id == activeId,
        );
        if (taskIndex !== -1 && overIndex !== null) {
          const [movedItem] = updatedTasks.splice(activeIndex, 1);
          updatedTasks.splice(overIndex, 0, movedItem);
        }
        return {
          ...section,
          tasks: updatedTasks,
        };
      });
      console.log(updatedSections, 'mutating state');
      // SET FORMDATA
      let updateArr = [];
      updatedSections.map((item) => {
        let dataTemp = {};
        item.tasks.map((data, index) => {
          const reversedIndex = item.tasks.length - 1 - index;

          dataTemp = {
            pps_id: data.pps_id,
            order: reversedIndex,
            pta_id: data.pta_id,
          };
          updateArr.push(dataTemp);
        });
      });

      // UPDATE ORDER
      const config = {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const formFilter = new FormData();
      formFilter.append('data', JSON.stringify(updateArr));
      formFilter.append('tab', activeTab);
      formFilter.append('pta_id', activeId);

      axios
        .post(`${auth.apiws}/Task/updateSectionOnDrag`, formFilter, config)
        .then((response) => {
          // getDataSection();
        })
        .catch((error) => {
          // setIsLoading(false);
          console.log(error);
        });

      // RETURN NEW SECTION TO FETCHING DATA
      return updatedSections;
    });
  };

  const handleReorderSection = (activeSectionIndex, overSectionIndex) => {
    if (overSectionIndex == 0 || activeSectionIndex == overSectionIndex) {
      return;
    }
    console.log(activeSectionIndex, overSectionIndex);
    console.log(orderSection, 'before');
    let reOrderSection = [...orderSection];
    const activeSectionId = reOrderSection[activeSectionIndex];
    const overSectionId = reOrderSection[overSectionIndex];
    if (defaultSectionId.includes(overSectionId)) return false; // disable default drag section
    if (activeSectionId && overSectionId) {
      reOrderSection.splice(activeSectionIndex, 1);
      reOrderSection.splice(overSectionIndex, 0, activeSectionId);

      setSections((sections) => {
        const updatedSections = [...sections];
        const activeSection = updatedSections[activeSectionIndex];
        updatedSections.splice(activeSectionIndex, 1);
        updatedSections.splice(overSectionIndex, 0, activeSection);
        // UPDATE ORDER
        const config = {
          headers: {
            Authorization: `bearer ${auth.token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const formData = new FormData();
        formData.append(
          activeTab === 1 ? 'pps_ids' : 'pse_ids',
          reOrderSection,
        );
        formData.append('emp_id', auth.emp_id);
        formData.append('muser_id', auth.usr_id);

        axios
          .post(`${auth.apiws}/Section/reorderSection`, formData, config)
          .then((response) => {
            getDataSection();
          })
          .catch((error) => {
            // setIsLoading(false);
            console.log(error);
          });

        return updatedSections;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let url = `${auth.apiws}/section/saveItem`;

    const formData = new FormData();
    formData.append('section_name', sectionName);
    formData.append('cuser_id', ls.get('user_cred').usr_id);

    if (activeTab == 1) {
      formData.append('personal', true);
      formData.append('emp_id', ls.get('user_cred').emp_id);
    } else {
      formData.append('personal', false);
    }

    if (!sectionName) {
      setErrMessage("Section name shouldn't be empty");
      setTouched(true);
      error = true;
    } else if (sectionName == 'Done' || sectionName == 'Archive') {
      setErrMessage("Section name can't be same as the default section");
      setTouched(true);
      error = true;
    }

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    if (!error) {
      setIsAddSection(!isAddSection);

      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data.status == 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            setTouched(false);
            setSectionName('');
            getDataSection();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function handleDragStart(result) {
    console.log(result);
    setActiveId(result?.draggableId);
    if (result.type === 'section') {
      if (activeTab == 1) {
        const pps_ids = sections.map((item) => item.pps_id);
        setOrderSection(pps_ids);
      } else {
        const pse_ids = sections.map((item) => item.pse_id);
        setOrderSection(pse_ids);
      }
    }
  }

  useEffect(() => {
    console.log(items, 'SECTIONS');
  }, []);
  useEffect(() => {
    let defaultSectionId = [];
    items?.map((val) => {
      if (val.default_section) {
        defaultSectionId.push(val?.pse_id ?? val?.pps_id);
      }
    });

    setDefaultSectionId(defaultSectionId);
    setSections(items);
  }, [items]);

  return (
    <DragDropContext
      onDragStart={handleDragStart}
      onDragEnd={(result) => handleDragEnd(result)}
    >
      <div className="task-flex">
        <Droppable
          droppableId={auth.usr_id}
          direction="horizontal"
          type="section"
        >
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="task-flex">
                  {sections?.map((item, index) => (
                    <Draggable
                      draggableId={`sect-${item.pps_id || item.pse_id}`}
                      key={`sect-${item.pps_id || item.pse_id}`}
                      index={index}
                      isDragDisabled={index === 0 ? true : false}
                    >
                      {(provided, snapshot) => (
                        <>
                          <TaskKanbanContainer
                            t={t}
                            data={item}
                            tab={activeTab}
                            tasks={item.tasks}
                            reloadSection={reloadSection}
                            toggleOffCanvas={toggleOffCanvas}
                            toggleNotification={toggleNotification}
                            getDataSection={getDataSection}
                            handleDragStart={handleDragStart}
                            providedSection={provided}
                            snapshotSection={snapshot}
                          />
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            );
          }}
        </Droppable>

        {/* add section or container */}
        <div className="h-auto">
          <div className="task-container-wrapper">
            <div className="task-container pt-3">
              {isAddSection ? (
                <>
                  <form
                    className="form"
                    id="formSection"
                    name="formSection"
                    onSubmit={handleSubmit}
                  >
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above position-relative">
                      <input
                        id="section_name"
                        name="section_name"
                        type="text"
                        placeholder="Add Section"
                        className="bg-white"
                        onChange={handleChangeSection}
                      />
                      {(!sectionName ||
                        sectionName == 'Done' ||
                        sectionName == 'Archive') &&
                      touched ? (
                        <span className="form__form-group-error">
                          {errMessage}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                          setIsAddSection(!isAddSection);
                        }}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <button
                  className="button-add-section"
                  onClick={() => {
                    setIsAddSection(!isAddSection);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ width: 10, height: 10 }}
                  />
                  {t('LBL.ADDSECTION')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default reduxForm({
  form: 'formSection',
  destroyOnUnmount: false,
  validate,
})(translate('global')(TaskKanban));
