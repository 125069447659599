/* eslint-disable no-return-assign, react/no-unused-prop-types */
/* eslint-disable react/no-unused-state, no-sequences */
/* eslint-disable no-plusplus, array-callback-return */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props, object-curly-newline */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../notificationMessages';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/fontawesome-free-solid';

class Perspective extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    addStrategicOjb: PropTypes.func.isRequired,
    modalStrategic: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      idUser: '',
      token: '',
      dataWidth: ['5%', '40%', '40%', '15%'],
      statusEdit: false,
      urlData: '',
      displaySpinner: false,
      disabledInput: false,
      modalStrategic: false,
      arrPerspective: [],
      arrCompanyStatement: [],
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERSPECTIVE') },
      { '2': t('LBL.STRATEGIC_OBJECTIVE') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getButton = this.getButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitStrategic = this.submitStrategic.bind(this);
    this.editPerspective = this.editPerspective.bind(this);
    this.deletePerspective = this.deletePerspective.bind(this);
    this.addStrategic = this.addStrategic.bind(this);
    this.toggleStrategic = this.toggleStrategic.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERSPECTIVE') },
      { '2': t('LBL.STRATEGIC_OBJECTIVE') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.getLink();
    this.props.destroy();
    this.setState({ disabledInput: false });
    this.setState({ displaySpinner: false });
    this.setState({ modal: !this.state.modal });
  }

  toggleStrategic() {
    this.getPerspective();
    this.getLink();
    this.setState({ modalStrategic: !this.state.modalStrategic });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getButton();
    });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/pfmperspective` });
  };

  getPerspective() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${ls.get('ws_ip')}/pfmperspective`, config).then((res) => {
      if (res.data.status === 'ok') {
        const arr = [];
        res.data.data.map((obj) =>
          arr.push({ value: obj.ppr_id, label: obj.perspective_name }),
        );
        this.setState({ arrPerspective: arr });
      }
    });
  }

  getLink() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(`${ls.get('ws_ip')}/pfmcompanystatement`, config).then((res) => {
      if (res.data.status === 'ok') {
        const arr = [];
        res.data.data.map((obj) =>
          arr.push({ value: obj.pcm_id, label: obj.statement_name }),
        );
        this.setState({ arrCompanyStatement: arr });
      }
    });
  }

  async editPerspective(id) {
    this.getLink();
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .get(`${apiws}/pfmperspective/${id}`, config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            ppr_id: getResponseData[0].ppr_id,
            perspective_name: getResponseData[0].perspective_name,
            pcm_id: getResponseData[0].pcm_id,
          };
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deletePerspective(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${apiws}/pfmperspective/${id}`, config, `duser_id=${userid}`)
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              if (response.data.status === 'obj') {
                showNotifications(
                  'Fail',
                  'Please Delete Strategic Objective First',
                  'danger',
                );
                setTimeout(() => {
                  this.child.reload('delete');
                }, 2000);
              } else {
                showNotifications('Fail', 'Delete Failed', 'danger');
              }
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const data = { fga_id: fga, men_id: men };
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  mapFormData(b) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (b === 'null' || b === null) {
          val_b = '';
        } else {
          val_b = b.value;
        }
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  handleSubmit(values) {
    const { apiws, token, idUser } = this.state;
    this.setState({ displaySpinner: true, disabledInput: true });
    const formBody = Object.keys(values)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.mapFormData(values[key]),
          )}`,
      )
      .join('&');
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let add;
    let action;
    let link;
    let body;
    if (typeof values.ppr_id === 'undefined') {
      add = 1;
      action = 'POST';
      link = `${apiws}/pfmperspective`;
      body = [formBody, `cuser_id=${idUser}`].join('&');
    } else {
      add = 0;
      action = 'PUT';
      link = `${apiws}/pfmperspective/${values.ppr_id}`;
      body = [formBody, `muser_id=${idUser}`].join('&');
    }
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    axios(options).then(() => {
      this.toggle();
      this.child.reload('addedit');
      showNotifications('Success', 'Save Success', 'success');
      this.props.destroy();
    });
  }

  submitStrategic(values) {
    const { apiws, token, idUser } = this.state;
    this.setState({ displaySpinner: true, disabledInput: true });
    const formBody = Object.keys(values)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.mapFormData(values[key]),
          )}`,
      )
      .join('&');
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let add;
    let action;
    let link;
    let body;
    if (typeof values.pso_id === 'undefined') {
      add = 1;
      action = 'POST';
      link = `${apiws}/pfmstrategic`;
      body = [formBody, `cuser_id=${idUser}`].join('&');
    } else {
      add = 0;
      action = 'PUT';
      link = `${apiws}/pfmstrategic/${values.pso_id}`;
      body = [formBody, `muser_id=${idUser}`].join('&');
    }
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    axios(options).then(() => {
      this.toggleStrategic();
      this.child.reload('addedit');
      showNotifications('Success', 'Save Success', 'success');
      this.props.destroy();
    });
  }

  addStrategic(id) {
    this.props.initialize({ ppr_id: id });
    this.toggleStrategic();
  }

  render() {
    const { handleSubmit, t, pristine, addStrategicOjb } = this.props;
    const {
      urlData,
      displaySpinner,
      disabledInput,
      arrPerspective,
    } = this.state;
    const modalStyle = {
      maxWidth: '600px',
      marginTop: '95px',
      textAlign: 'center',
    };

    const addBtn = (
      <div className="add-btn-comp">
        <Button
          color="primary"
          size="sm"
          className="btn_table_navbar"
          onClick={() => this.toggle('add')}
          style={{ float: 'right', 'margin-bottom': '20px' }}
          // disabled={disabledAddBtn}
        >
          {t('FRM.ADD')} {t('LBL.PERSPECTIVE')}
        </Button>
      </div>
    );

    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ppr_id"
                  updateFunc={this.editPerspective}
                  deleteFunc={this.deletePerspective}
                  addFunc={this.addStrategic}
                  buttonAction={['addstrategic', 'edit', 'delete']}
                  widthTable={this.state.dataWidth}
                  getLink={addStrategicOjb}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {t('LBL.PERSPECTIVE')} {t('LBL.FORM')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="perspectiveform"
                  id="perspectiveform"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <input type="hidden" name="ppr_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.LINK_TO')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="pcm_id"
                              component={renderSelectField}
                              options={this.state.arrCompanyStatement}
                              disabled={disabledInput}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PERSPECTIVE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="perspective_name"
                              component={renderTextInput}
                              disabled={disabledInput}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {displaySpinner ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    form="perspectiveform"
                    type="submit"
                    disabled={pristine}
                  >
                    {t('FRM.SAVE')}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modalStrategic}
              toggle={this.toggleStrategic}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggleStrategic}>
                {t('LBL.STRATEGIC_OBJECTIVE')} {t('LBL.FORM')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.submitStrategic)}
                  name="strateficobjective"
                  id="strateficobjective"
                >
                  <Field
                    name="pso_id"
                    component={renderTextInput}
                    type="hidden"
                  />
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={8} xl={8}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PERSPECTIVE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="ppr_id"
                              component={renderSelectField}
                              options={arrPerspective}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.STRATEGIC_OBJECTIVE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="strategic_objective_name"
                              component={renderTextInput}
                              disabled={disabledInput}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggleStrategic();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {displaySpinner ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    form="strateficobjective"
                    type="submit"
                    disabled={pristine}
                  >
                    {t('FRM.SAVE')}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'perspectiveform',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Perspective));
