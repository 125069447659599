/* eslint-disable valid-typeof */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../Preferences/notificationMessages';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import PollIcon from 'mdi-react/PollIcon';
import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import ReactExport from 'react-export-excel';
import utils from '../../../../utils';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';

let translator = () => {};

const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ReportSurveyForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { url, t } = props;
    translator = t;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      arrPeriod: [],
      urlData: '',
      orgorganization: [],
      company: [],
      empcurrent: [],
      surveygroup: [],
      search: '',
      org: '',
      dataexcel: '',
      type: '',
    };
    this.heads = [
      'No',
      'Group Name',
      'Question',
      'Employee Code',
      'Employee',
      'Organizational Unit',
      'Answer',
      'Answer Time',
    ];
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.loadSelectBox();
      this.getEmployee();
      this.getSurveyGroup();
    });
  };

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/employee/getAllItemOrg/${org}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ empcurrent: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSurveyGroup() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/empsurvey/getAllGroup/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.esg_id, label: obj.group_name }),
          );
          this.setState({ surveygroup: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${apiws}/orgcompany/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.cmp_id, label: obj.company }),
          );
          this.setState({ company: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.post(
    //   `${apiws}/employee/getAllItemOrg/0`,
    //   '',
    //   config,
    // ).then((res) => {
    //   const dataResponse = res.data.data;
    //   if (res.data.status === 'ok') {
    //     const Arr = [];
    //     dataResponse.map(obj => (
    //       Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
    //     ));
    //     this.setState({ empcurrent: Arr });
    //   }
    // }).catch((error) => {
    //   console.log(error);
    // });

    axios
      .post(`${apiws}/orgcompany/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.cmp_id, label: obj.company }),
          );
          this.setState({ company: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ orgorganization: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick = (e) => {
    e.preventDefault();
  };

  handleSubmit(values) {
    const { apiws, token, idUser, url } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const org =
      typeof values.org_id === 'undefined' || values.org_id === null
        ? ''
        : values.org_id.value;
    const emp =
      typeof values.emp_employee === 'undefined' || values.emp_employee === null
        ? ''
        : values.emp_employee.label2;
    const esg =
      typeof values.survey_group === 'undefined' || values.survey_group === null
        ? ''
        : values.survey_group.value;
    const start =
      typeof values.period_start === 'undefined' || values.period_start === null
        ? ''
        : values.period_start.format('YYYY-MM-DD');
    const end =
      typeof values.period_end === 'undefined' || values.period_end === null
        ? ''
        : values.period_end.format('YYYY-MM-DD');

    const dt = {
      start,
      end,
      org_id: org,
      emp_id: emp,
      esg_id: esg,
    };
    const bodyParameters = {
      cond: dt,
    };
    const link = `${apiws}/Empsurvey/getReport`;
    axios.post(link, bodyParameters, config).then((res) => {
      if (res.data.status === 'ok') {
        if (values.type_report.value === '1') {
          if (res.data.data.length === 0) {
            showNotifications('Success', 'Empty Data', 'success');
          } else {
            showNotifications('Success', 'Available Data', 'success');
          }
          this.props.onProcess(link, dt, res.data.data);
          setTimeout(() => {
            this.loadSelectBox();
          }, 200);
        } else if (values.type_report.value === '2') {
          if (res.data.data.length === 0) {
            showNotifications('Success', 'Empty Data', 'success');
          } else {
            showNotifications('Success', 'Available Data', 'success');
            const data = res.data.data;
            const array = [];
            data.map((obj, index) =>
              array.push({
                No: index + 1,
                'Group Name': obj.group_name,
                Question: obj.question,
                'Employee Code': obj.employee_code,
                Employee: obj.employee,
                'Organizational Unit': obj.unit,
                Answer: obj.answer,
                'Answer Time': obj.answer_time,
              }),
            );
            this.setState({ dataexcel: array, headerexcel: this.heads });
            setTimeout(() => {
              this.setState({ type: '2' });
              this.inputElement.click();
            }, 1000);
          }
        }
      } else {
        showNotifications('Fail', 'Failed', 'danger');
      }
    });
  }
  render() {
    const { handleSubmit, t } = this.props;
    const { company, orgorganization, empcurrent, surveygroup } = this.state;
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="leave" />
                  <h3 className="page-title">{t('LBL.SURVEY_REPORT')}</h3>
                </div>
              </Col>
            </Row>
            <Row className="m5">
              <Col md={6} xs={12} lg={6} xl={6} style={{ marginTop: '25px' }}>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="runAttendanceReportMonthly"
                  id="runAttendanceReportMonthly"
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.COMPANY')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="cmp_id"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Company"
                        options={company}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.UNIT_ORGANIZATION')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="org_id"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Unit Organization"
                        options={orgorganization}
                        onChange={(val) => {
                          if (typeof val.value === 'undefined') {
                            this.setState({ org: '' });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          } else {
                            this.setState({ org: val.value });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EMPLOYEE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="emp_employee"
                        component={renderSelectField}
                        type="text"
                        placeholder="Emp code, Name"
                        options={empcurrent}
                      />
                      <div className="form__form-group-icon">
                        <AccountSearchIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.GROUP_NAME')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="survey_group"
                        component={renderSelectField}
                        type="text"
                        placeholder="Survey Group Name"
                        options={surveygroup}
                      />
                      <div className="form__form-group-icon">
                        <PollIcon />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                    <div className="form__form-group-field" >
                      <Field
                        name="period_date"
                        component={renderIntervalDatePickerField}
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                  </div> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.PERIOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="period_start"
                        component={renderDatePickerField}
                        placeholder="Start Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                      <MinusIcon className="date-picker__svg" />
                      <Field
                        name="period_end"
                        component={renderDatePickerField}
                        type="text"
                        placeholder="End Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TYPE_REPORT')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        id="typeAttdReport"
                        name="type_report"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select type report"
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                    {this.state.type === '2' && (
                      <ExcelFile
                        element={
                          <div
                            onClick={this.handleClick}
                            onKeyPress={this.handleClick}
                            role="button"
                            tabIndex="0"
                          >
                            <input
                              type="hidden"
                              ref={(input) => (this.inputElement = input)}
                            />
                          </div>
                        }
                        filename="Survey Report"
                      >
                        <ExcelSheet
                          data={this.state.dataexcel}
                          name="Employees"
                        >
                          {this.state.headerexcel.map((obj) => (
                            <ExcelColumn label={obj} value={obj} />
                          ))}
                        </ExcelSheet>
                      </ExcelFile>
                    )}
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button
                      color="primary"
                      className="btn_table_navbar"
                      type="submit"
                      style={{ float: 'right', 'margin-bottom': '20px' }}
                      form="runAttendanceReportMonthly"
                    >
                      {t('FRM.SUBMIT')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const validate = (values) => {
  const validation = {
    period_start: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      required: true,
    },
    period_end: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      required: true,
    },
    type_report: {
      fieldLabel: `${translator('LBL.TYPE_REPORT')}`,
      required: true,
    },
  };

  const errors = utils.validate(validation, values);
  console.log(errors);
  return errors;
};

export default reduxForm({
  form: 'report_attendance_form', // a unique identifier for this form
  validate,
})(translate('global')(ReportSurveyForm));
