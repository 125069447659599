/* eslint-disable no-mixed-operators */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/extensions */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unused-state,object-shorthand,arrow-parens,comma-dangle,jsx-a11y/anchor-is-valid,no-script-url,react/style-prop-object,no-console,no-trailing-spaces,space-in-parens,space-infix-ops,react/no-unescaped-entities,camelcase,no-plusplus,max-len,quote-props,no-unused-vars */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import ws from '../../../../shared/components/config/config';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/fontawesome-free-solid';
import '../assets/css/style.css';

const user1 = `${process.env.PUBLIC_URL}/img/user_photo/user-1.jpg`;
const user2 = `${process.env.PUBLIC_URL}/img/user_photo/user-2.png`;
const user3 = `${process.env.PUBLIC_URL}/img/user_photo/user-3.jpg`;
const user4 = `${process.env.PUBLIC_URL}/img/user_photo/user-4.jpg`;

const WAIT_INTERVAL = 1000;

export default class DataTable extends PureComponent {
  constructor() {
    super();
    this.heads = [
      'No',
      'Pics',
      'Employee Name',
      'Employee Code',
      'Position Name',
      'Job Title',
      'Join Date',
      '#',
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.state = {
      rows: [],
      limit: 5, // default is 5 rows
      offset: 0,
      page: 1,
      disable_preview: '',
      disable_next: '',
      modal_show: false,
      search: '',
      total: 0,
      total_pagination: 0,
      current_pagination: 0,
      label_idx_start: 0,
      label_idx_end: 0,
    };
    this.reload = this.reload.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.changePage = this.changePage.bind(this);
    this.search = this.search.bind(this);
    this.createButtonPagination = this.createButtonPagination.bind(this);
    this.goto = this.goto.bind(this);
  }

  componentWillMount() {
    this.timer = null;
    this.next();
  }

  paginationNumber() {
    const total_pagination = this.state.total / this.state.limit;
    this.setState({
      total_pagination: Math.ceil(total_pagination),
    });

    const page_start = Number.parseInt(this.state.offset, 10) + 1;
    const page_end =
      Number.parseInt(this.state.limit, 10) +
      Number.parseInt(this.state.offset, 10);
    this.setState({
      label_idx_start: page_start,
      label_idx_end: page_end > this.state.total ? this.state.total : page_end,
    });
  }

  // eslint-disable-next-line react/sort-comp
  changePage(event) {
    this.setState(
      {
        limit: event.target.value,
      },
      () => {
        setTimeout(() => {
          this.reload();
        }, 50);
      },
    );
  }

  search(event) {
    this.setState({ search: event.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  previous() {
    let state_offset = this.state.offset - this.state.limit;
    this.setState({ modal_show: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_title: response.data.data[i].job_title,
              join_date: response.data.data[i].join_date,
            });
          }

          // simpan state limit offset
          nextState.setState({
            rows: rows,
            total: response.data.total,
            page: this.state.page - 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: response.data.total > no_index ? '' : 'disabled',
          });

          this.paginationNumber();
        } else {
          // sembunyikan modal loading data jika data kosong
          this.setState({ modal_show: false });
        }
      })
      .catch((error) => {
        // sembunyikan modal loading data
        this.setState({ modal_show: false });
        console.log(error);
      });
  }

  reload() {
    // eslint-disable-next-line no-underscore-dangle
    const reloadState = this;
    // eslint-disable-next-line no-underscore-dangle
    let state_offset = this.state.offset;
    this.setState({ modal_show: true });
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        this.setState({ modal_show: false });
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_title: response.data.data[i].job_title,
              join_date: response.data.data[i].join_date,
            });
          }
          reloadState.setState({
            rows: rows,
            total: response.data.total,
            disable_next: no_index >= response.data.total ? 'disabled' : '',
            page: 1,
            total_pagination: 0,
            current_pagination: 0,
          });

          this.paginationNumber();
        }
      })
      .catch((error) => {
        this.setState({ modal_show: false });
        console.log(error);
      });
  }

  next() {
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (this.state.page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_title: response.data.data[i].job_title,
              join_date: response.data.data[i].join_date,
            });
          }
          nextState.setState({
            rows: rows,
            total: response.data.total,
            page: this.state.page + 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch((error) => {
        this.setState({ modal_show: false });
        console.log(error);
      });
  }

  goto(e) {
    e.preventDefault();
    const page = parseInt(e.target.innerHTML, 10);
    console.log(page);
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_title: response.data.data[i].job_title,
              join_date: response.data.data[i].join_date,
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: page + 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch((error) => {
        this.setState({ modal_show: false });
        console.log(error);
      });
  }

  createButtonPagination() {
    const total_button = 5;
    const child_pagination = [];
    const state_page = this.state.page - 1;

    // set minimum index
    if (
      this.state.current_pagination < 1 ||
      state_page >= this.state.current_pagination
    ) {
      this.setState({
        current_pagination: state_page + total_button,
      });
    }

    // set maximum index
    if (state_page < this.state.current_pagination - total_button) {
      this.setState({
        current_pagination: this.state.current_pagination - total_button,
      });
    }

    for (let i = 1; i <= this.state.total_pagination; i++) {
      let selected = '';

      if (state_page === i) {
        selected = 'active';
      }

      if (
        i >= this.state.current_pagination - total_button - 1 &&
        i <= this.state.current_pagination
      ) {
        child_pagination.push(
          <li className={`page-item ${selected}`}>
            {' '}
            <a onClick={this.goto} className="page-link" href="#">
              {' '}
              {i}{' '}
            </a>{' '}
          </li>,
        );
      }
    }

    return child_pagination;
  }

  render() {
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding: '20px 10px 30px 10px' }}>
            <div class="container-fluid">
              <div class="wrapper">
                <div class="row">
                  <div class="col-md-6">
                    <h3 class="page-title">Employee List</h3>
                    <h3 class="page-subhead subhead">
                      View &amp; Managed Employees
                    </h3>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="btn btn-primary"
                      style={{ float: 'right', 'margin-bottom': '20px' }}
                    >
                      Add Employee
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="input-group mb-3">
                      <input
                        value={this.state.search}
                        onChange={this.search}
                        type="text"
                        class="form-control"
                        placeholder="Search by keywords ..."
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="action-table-btn">
                      <form class="form-inline">
                        <button class="btn btn-secondary mr-2 btn-data-table">
                          {' '}
                          <i class="fas fa-print" />
                        </button>
                        <div class="dropdown">
                          <a
                            class="btn btn-secondary mr-2 btn-data-table dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fas fa-file-upload" />
                          </a>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a class="dropdown-item" href="#">
                              Export to Excel
                            </a>
                            <a class="dropdown-item" href="#">
                              Export to PDF
                            </a>
                            <a class="dropdown-item" href="#">
                              Export to JPG
                            </a>
                          </div>
                        </div>
                        <button class="btn btn-secondary btn-data-table">
                          {' '}
                          <i class="fas fa-redo" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <table
                  id="myTable"
                  class="table data-grid-tbl responsive no-wrap"
                >
                  <thead>
                    {this.heads.map((data) => (
                      <th>{data}</th>
                    ))}
                  </thead>
                  <tbody>
                    {this.state.rows.map((data) => (
                      <tr>
                        <td>{data.no}</td>
                        <td>
                          <img class="profile-pic" src={user1} alt="" />
                        </td>
                        <td>{data.emp_name}</td>
                        <td>{data.emp_code}</td>
                        <td>{data.position_name}</td>
                        <td>{data.job_title}</td>
                        <td>{data.join_date}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-primary btn-data-table"
                          >
                            <i class="fas fa-pencil-alt" />
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-danger btn-data-table"
                          >
                            <i class="fas fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <div class="paging-panel-bottom">
                      <div class="row">
                        <div class="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li
                                className={`page-item ${this.state.disable_preview}`}
                              >
                                <a
                                  onClick={this.previous}
                                  className="disabled page-link"
                                  href="#"
                                  tabIndex="-1"
                                  aria-disabled="true"
                                >
                                  {' '}
                                  Previous
                                </a>
                              </li>
                              {this.createButtonPagination()}
                              <li
                                className={`page-item ${this.state.disable_next}`}
                              >
                                <a
                                  onClick={this.next}
                                  className="page-link"
                                  href="#"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div class="col-md-6 ">
                          <form class="form-inline float-right">
                            <div class="form-group mb-2">
                              <label class="mr-3">Per Page</label>
                              <select
                                onChange={this.changePage}
                                class="form-control"
                              >
                                <option>5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                              </select>
                            </div>
                            <div class="form-group mx-sm-3 mb-2">
                              <label>
                                Showing {this.state.label_idx_start} to{' '}
                                {this.state.label_idx_end} of {this.state.total}
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
