/* eslint-disable no-unused-vars, max-len */
/* eslint-disable react/no-unused-state, class-methods-use-this */
/* eslint-disable quote-props, react/sort-comp */
/* eslint-disable react/no-did-mount-set-state, react/no-unused-prop-types */

import React, { PureComponent } from 'react';
import { Row, Card, CardBody, Col, Button, ButtonToolbar, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../Preferences/notificationMessages';
import PayrollAdvanceFilter from './RunProcessFormAdvance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';

class RunPayrollProcess extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      t: PropTypes.func.isRequired,
      onProcess: PropTypes.func.isRequired,
      pristine: PropTypes.func.isRequired,
      initialize: PropTypes.func.isRequired,
      destroy: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      this.state = {
        apiws: '',
        token: '',
        idUser: '',
        arrPeriod: [],
        arrComponentGroup: [],
        urlData: '',
        displaySpinner: 'none',
        displaySave: '',
        filtered: [],
        modal: '',
        condLabel: '',
        condValue: '',
        disabledAdvance: false,
      };
      this.getPeriode = this.getPeriode.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.toggle = this.toggle.bind(this);
      this.getObjFilter = this.getObjFilter.bind(this);
    }

    componentDidMount = () => {
      this.setState({ token: ls.get('token') });
      this.setState({ apiws: ls.get('ws_ip') });
      this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
        this.getPeriode();
        this.getGroupComponent();
      });
      this.setState({ urlData: `${ls.get('ws_ip')}/payprocess/` });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    getPeriode() {
      const { apiws, token } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios.get(
        `${apiws}/payperiod`,
        config,
      ).then((res) => {
        const period = res.data.data;
        const array = [];
        if (period.length === 0) {
          this.setState({ arrPeriod: array });
        } else {
          period.map(obj => (
            array.push({ value: obj.pyp_id, label: obj.period_code })
          ));
          this.setState({ arrPeriod: array });
        }
      });
    }

    getGroupComponent() {
      const { apiws, token } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios.get(
        `${apiws}/paycomponentgroup`,
        config,
      ).then((res) => {
        const componentgroup = res.data.data;
        const array = [];
        if (componentgroup.length === 0) {
          this.setState({ arrComponentGroup: array });
        } else {
          componentgroup.map(obj => (
            array.push({ value: obj.pyg_id, label: obj.component_group })
          ));
          this.setState({ arrComponentGroup: array });
        }
      }).catch(() => {
        // console.log(error);
      });
    }

    handleChange(value) {
      switch (value) {
        case '1':
          this.setState({ filter: value });
          break;
        case '2':
          this.setState({ filter: value });
          break;
        case '3':
          this.setState({ filter: value });
          break;
        case '4':
          this.setState({ filter: value });
          break;
        default:
      }
    }

    getObjFilter(x) {
      this.setState({
        filtered: x,
        condLabel: x.filter,
        condValue: x.label,
      });
    }

    handleSubmit(values) {
      const {
        apiws,
        idUser,
        filtered,
      } = this.state;
      this.setState({
        displaySave: 'none',
        displaySpinner: '',
        disabledAdvance: true,
      });
      const pypId = values.pyp_id.value;
      const pygId = values.pyg_id.value;
      const config = {
        headers: {
          'Authorization': `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const filter = JSON.stringify(filtered);
      const link = `${apiws}/paysalary/process`;
      const body = [`period_id=${pypId}`, `pyg_id=${pygId}`, `muser_id=${idUser}`, `cond=${filter}`].join('&');
      axios.post(
        link,
        body,
        config,
      ).then(() => {
        this.props.onProcess();
        showNotifications('Success', 'Payroll Running Finish', 'success');
        this.setState({
          displaySave: '',
          displaySpinner: 'none',
          disabledAdvance: false,
        });
        this.setState({
          filtered: [],
          condLabel: '',
          condValue: '',
        });
        this.props.destroy();
      });
    }

    render() {
      const { handleSubmit, t, pristine } = this.props;
      const {
        arrPeriod,
        arrComponentGroup,
        displaySpinner,
        displaySave,
        condLabel,
        condValue,
        disabledAdvance,
      } = this.state;
      return (
        <Col xs={12} md={12} lg={12} xl={12}>
          <Card>
            <CardBody className="p0">
              <Row className="m5">
                <Col>
                  <h3 className="page-title">{t('LBL.PROCESS_PAYROLL')}</h3>
                </Col>
              </Row>
              <Row className="m5">
                <Col md={6} xs={12} lg={6} xl={6}>
                  <form
                    className="form form--horizontal"
                    onSubmit={handleSubmit(this.handleSubmit)}
                    name="runprocesspayroll"
                    id="runprocesspayroll"
                  >
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                      <div className="form__form-group-field">
                        <Field
                          name="pyp_id"
                          component={renderSelectField}
                          type="text"
                          placeholder={t('LBL.SELECT_PERIOD')}
                          options={arrPeriod}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('LBL.GROUP_COMPONENT')}</span>
                      <div className="form__form-group-field">
                        <Field
                          name="pyg_id"
                          component={renderSelectField}
                          type="text"
                          placeholder={t('LBL.SELECT_COMPONENT_GROUP')}
                          options={arrComponentGroup}
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        color="secondary"
                        onClick={this.toggle}
                        disabled={disabledAdvance}
                      >
                        {t('LBL.ADVANCE')}
                      </Button>
                      <Button
                        color="primary"
                        form="runprocesspayroll"
                        disabled={pristine}
                        style={{ float: 'right', 'margin-bottom': '20px', display: displaySave }}
                      >
                        {t('LBL.PROCESS')}
                      </Button>
                      <Button color="primary" style={{ display: displaySpinner }}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '6px' }}
                        />
                        {t('FRM.LOADING')}
                      </Button>
                    </ButtonToolbar>
                  </form>
                </Col>
                {(condLabel !== '') &&
                  <Col md={6} xs={12} lg={6} xl={6} style={{ padding: '0px 20px 0px 20px !important', marginTop: '0px !important' }}>
                    <div className="note-file-upload" style={{ marginTop: '0px', paddingBottom: '22px' }}>
                      <h5> <FontAwesomeIcon icon={faInfoCircle} /> Filter By</h5>
                      <hr />
                      <ol style={{ listStyleType: 'circle' }}>
                        <li> {condLabel} : {condValue}</li>
                      </ol>
                    </div>
                  </Col>
                }
              </Row>
            </CardBody>
          </Card>
          <Modal isOpen={this.state.modal} className="modal-dialog modal-input modal-component">
            <PayrollAdvanceFilter
              toggleAdvance={this.toggle}
              objFilter={this.getObjFilter}
            />
          </Modal >
        </Col>
      );
    }
}

const validate = (values) => {
  const errors = {};
  if (!values.pyp_id) {
    errors.pyp_id = 'Period field shouldn’t be empty';
  }
  if (!values.pyg_id) {
    errors.pyg_id = 'Group component field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'runpayrollprocessform',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(RunPayrollProcess));
