import React from 'react';
// import { Col, Container, Row } from 'reactstrap';
// import MembershipPage from './components/MembershipPage';
import Package from '../../../containers/Account/Package/index';

const Membership = () => (
  <Package />
);

export default Membership;
