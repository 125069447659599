/* eslint-disable no-unused-vars, object-property-newline */
/* eslint-disable array-callback-return */
/* eslint-disable max-len, class-methods-use-this */
/* eslint-disable no-mixed-operators, no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable quote-props, prefer-destructuring */
/* eslint-disable camelcase, arrow-parens */
/* eslint-disable object-shorthand, no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../Preferences/notificationMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/fontawesome-free-solid';
import renderTextInput from '../../shared/components/form/TextInput';
import renderSelectField from '../../shared/components/form/Select';
import renderMaskInput from '../../shared/components/form/InputMask';
import SunEditor from 'suneditor-react';
import renderDatePickerField from '../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import moment from 'moment';
import { date } from 'html-docx-js/dist/html-docx';
import { Redirect } from 'react-router-dom';
import ViewListTable from '../../shared/components/table/viewListTable';
// import renderSelectField from '../../../../shared/components/form/Select';

class TaskKpi extends PureComponent {
  static propTypes = {
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DATE') },
      { '2': t('LBL.EMPLOYEE') },
      { '3': t('LBL.PERIOD_NAME') },
      { '6': t('FRM.FUNCTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      urlData: '',
      arrPeriodPhase: [],
      redirect: false,
      periodId: '',
      phaseId: '',
      periodPhaseId: '',
      approval: false,
      dataWidth: ['5%', '15%', '15%', '15%', '15%', '10%', '10%', '10%'],
    };
    this.proccessTaskKpiPlan = this.proccessTaskKpiPlan.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/pfmkpiplan/getTaskKpiPlan` });
    setTimeout(() => {
      this.getTableData();
    }, 50);
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DATE') },
      { '2': t('LBL.EMPLOYEE') },
      { '3': t('LBL.PERIOD_NAME') },
      { '6': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              // this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      });
  }

  proccessTaskKpiPlan(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${apiws}/pfmkpiplan/getTaskKpiPlanById/${id}`, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const period = response.data.data[0].pfp_id;
          this.setState({
            periodId: period,
            phaseId: 1,
            periodPhaseId: 1,
            approval: true,
            redirect: true,
          });
        }
      });
  }

  render() {
    const { t } = this.props;
    const { urlData } = this.state;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpi',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              reviewerId: '',
              approval: this.state.approval,
            },
          }}
        />
      );
    }

    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ paddingBottom: '10px' }}>
                  <h3 className="page-title">
                    {t('LBL.TASK')} {t('LBL.KPI')}
                  </h3>
                  <h3 className="page-subhead subhead">
                    {t('LBL.TASK')} {t('LBL.KPI')}
                  </h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  // addBtn={addBtn}
                  primaryKey="row"
                  widthTable={this.state.dataWidth}
                  buttonAction={['detail']}
                  // updateFunc={this.editAttMonitoring}
                  // deleteFunc={this.deleteAttMonitoring}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  // editCond={editcond}
                  // deleteCond={deletecond}
                  detailFunc={this.proccessTaskKpiPlan}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'TaskKpi',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(TaskKpi));
