/*
 * Page Security Setting
 * Notes: Setting Security User
 * @author Ajeng
 * @date 24/07/2019
 * Modification (please note below)
 * @dev Gerdi
 * @date 09/08/2019
 * Tasks: Update css and validate
*/
/* eslint-disable indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/sort-comp */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderTextInput from '../../../../shared/components/form/TextInput';
import showNotifications from '../../../Preferences/notificationMessages';

class SecurityPreferences extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      t: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        apiws: '',
        token: '',
        idUser: '',
        statusEdit: true,
        disableForm: false,
        disableButton: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleEdit = this.handleEdit.bind(this);
    }
    componentDidMount = () => {
      this.setState({ token: ls.get('token') });
      this.setState({ apiws: ls.get('ws_ip') });
      this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.checkSecurity(); });
    }
    checkBoolean(x) {
      let val_x;
      if (x === 'true') {
        val_x = true;
      } else {
        val_x = false;
      }
      return val_x;
    }
    checkSecurity() {
      const { apiws, token, idUser } = this.state;
      const config = {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios.post(
        `${apiws}/appsecuritysetting/getAllItemByUser/${idUser}`,
        '',
        config,
      ).then((response) => {
        if (response.data.data.length !== 0) {
          const scrid = response.data.data[0].scr_id;
          const maxwrong = response.data.data[0].max_wrong_password;
          const automaticlockuser = response.data.data[0].automatic_lock_user;
          const automaticsessiontimeout = response.data.data[0].automatic_session_timeout;
          const loginonedevice = this.checkBoolean(response.data.data[0].login_one_device);
          this.setState({ disableForm: 'disabled' });
          this.setState({ statusEdit: true });
          this.props.initialize({
            'scr_id': scrid,
            'max_wrong_password': maxwrong,
            'automatic_lock_user': automaticlockuser,
            'automatic_session_timeout': automaticsessiontimeout,
            'login_one_device': loginonedevice,
          });
        } else {
          this.setState({ statusEdit: false });
        }
      }).catch(() => {
        this.setState({ disableButton: false });
      });
    }
    handleSubmit(values) {
      const {
        apiws, 
        token,
        idUser,
      } = this.state;
      let errors = true;
      if (!values.automatic_lock_user) {
        errors = false;
      }
      if (!values.automatic_session_timeout) {
        errors = false;
      }
      if (!values.max_wrong_password) {
        errors = false;
      }
      let url;
      let data;
      const id = values.scr_id;
      if (id === undefined || id === '') {
        url = `${apiws}/appsecuritysetting/saveItem`;
        data = { ...values, cuser_id: idUser };
      } else {
        url = `${apiws}/appsecuritysetting/updateItem/${id}`;
        data = { ...values, muser_id: idUser };
      }
      if (errors) {
        const formBody = Object.keys(data).map(key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
        this.setState({ disableButton: true });
        const config = {
          headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        axios.post(
          url,
          formBody,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            this.checkSecurity();
            this.setState({ disableButton: false, disableForm: true, statusEdit: true });
            showNotifications('Success', 'Save Success', 'success');
          } else {
            this.setState({ disableButton: false });
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Save Failed', 'danger');
          this.setState({ disableButton: false });
        });
      }
    }
    handleChange() {
      this.setState({ buttondisabled: '' });
    }
    handleEdit(e) {
      e.preventDefault();
      this.setState({
        disableForm: false, statusEdit: false,
      });
    }
    render() {
      const { handleSubmit, t } = this.props;
      const {
        statusEdit,
        disableForm,
      } = this.state;
      let buttonAction;
      if (statusEdit) {
        buttonAction = (
          <Button
            color="success"
            onClick={this.handleEdit}
            disabled={this.state.disableButton}
            type="button"
          > {t('FRM.EDIT')}
          </Button>);
      } else {
        buttonAction = (
          <Button
            color="primary"
            type="submit"
            form="formsecuritypreferences"
          > {t('FRM.SAVE')}
          </Button>);
      }
      return (
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4} xs={12} lg={4} xl={4}>
                      <form
                        className="form form--horizontal"
                        name="formsecuritypreferences"
                        id="formsecuritypreferences"
                        onSubmit={handleSubmit(this.handleSubmit)}
                      >      
                        <Field
                          name="scr_id"
                          component={renderTextInput}
                          type="hidden"
                        />   
                        <div className="form__form-group">
                          <span className="form__form-group-label" >{t('LBL.MAX_WRONG_PASSWORD')}</span>
                          <div className="form__form-group-field">
                            <Row>
                              <Col className="d-flex" md={4} xs={12} style={{ 'padding': '0' }}>
                                <Field
                                  name="max_wrong_password"
                                  component={renderTextInput}
                                  disabled={disableForm}
                                  type="number"
                                />
                              </Col>
                              <Col className="d-flex" md={4} xs={12}>
                                <p
                                  className="label-form-right mt-2"
                                > {t('LBL.TIMES')}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {/* <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.AUTOMATIC_LOCK_USER')}</span>
                          <div className="form__form-group-field">
                            <Row>
                              <Col className="d-flex" md={4} xs={12} style={{ 'padding': '0' }}>
                                <Field
                                  name="automatic_lock_user"
                                  component={renderTextInput}
                                  disabled={disableForm}
                                  type="number"
                                />
                              </Col>
                              <Col className="d-flex" md={4} xs={12}>
                                <p
                                  className="label-form-right mt-2"
                                > {t('LBL.DAYS')}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.AUTOMATIC_SESSION_TIMEOUT')}</span>
                          <div className="form__form-group-field">
                            <Row>
                              <Col className="d-flex" md={4} xs={12} style={{ 'padding': '0' }}>
                                <Field
                                  name="automatic_session_timeout"
                                  component={renderTextInput}
                                  disabled={disableForm}
                                  type="number"
                                />
                              </Col>
                              <Col className="d-flex" md={5} xs={12}>
                                <p
                                  className="label-form-right mt-2"
                                > {t('LBL.SECONDS')}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {/* <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.LOGIN_ONE_DEVICE')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="login_one_device"
                              id="login_one_device"
                              component={renderCheckBoxField}
                              disabled={disableForm}                                                        
                            />
                          </div>
                        </div> */}
                        <ButtonToolbar className="form__button-toolbar">
                          {buttonAction}
                        </ButtonToolbar>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
}

const validate = (values) => {
  const errors = {};

  if (!values.automatic_lock_user) {
    errors.automatic_lock_user = 'no empty';
  }
  if (!values.automatic_session_timeout) {
    errors.automatic_session_timeout = 'no empty';
  }
  if (!values.max_wrong_password) {
    errors.max_wrong_password = 'no empty';
  }
 
  return errors;
};

export default reduxForm({
  form: 'securitypreferencesform',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(SecurityPreferences));
