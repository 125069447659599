/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-properties */

import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Table } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import ReactExport from 'react-export-excel';

const buttonRef = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class SalarySocialIncurance extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NO'),
      t('LBL.IDENTITY'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.KPJ'),
      t('LBL.KODE_TK'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.DATE_OF_BIRTH'),
      t('LBL.SALARY'),
      t('LBL.BACKPAY'),
      t('LBL.BLTH'),
      t('LBL.NPP'),
    ];
    this.state = {
      apiws: '',
      token: '',
      urlData: '',
      arrYear: [],
      arrEmployee: [],
      arrOffice: [],
      arrUnitOrganization: [],
      displayLoad: 'none',
      condOrganization: '',
      condOffice: '',
      condEmployee: '',
      condActive: '',
      condPeriod: '',
      condPeriodCode: '',
      dataTwo: [],
      type: '',
    };
    this.getYear = this.getYear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getDownloadExcel = this.getDownloadExcel.bind(this);
  }

  componentDidMount = () => {
    this.getYear();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getOffice();
      this.getUnitOrganization();
      this.getEmployee();
    });
  };

  getTableData() {
    this.child.reload();
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgoffice`,
      config,
    ).then((res) => {
      const off = res.data.data;
      const array = [];
      if (off.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        off.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getDownloadExcel() {
    const { t } = this.props;
    const {
      apiws,
      token,
      condActive,
      condOrganization,
      condOffice,
      condEmployee,
      condPeriod,
      condPeriodCode,
      type,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const bodyParameters = {
      cond: {
        'is_active': condActive,
        'org_id': condOrganization,
        'off_id': condOffice,
        'emp_id': condEmployee,
        'pyp_id ': condPeriod,
        'period': condPeriodCode,
        'type': type,
      },
    };
    const url = `${apiws}/reportsocialinsurance/getSalary`;
    axios.post(
      url,
      bodyParameters,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        this.setState({ dataTwo: response.data.data });
        this.inputElement.click();
      }
    });
  }

  handleSubmit(values) {
    // const type = (typeof values.type_report === 'undefined' || values.type_report === null) ? '' : values.type_report.value;
    const {
      apiws,
      token,
      type,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const month = (typeof values.period_month_upah === 'undefined' || values.period_month_upah === null) ? '' : values.period_month_upah.value;
    const year = (typeof values.period_year_upah === 'undefined' || values.period_year_upah === null) ? '' : values.period_year_upah.value;
    const valperiod = (year === '' || month === '') ? false : true;
    if (valperiod) {
      const period = `${year}${month}`;
      // axios.get(
      //   `${apiws}/payperiod/getId/${period}`,
      //   config,
      // ).then((res) => {
      //   if (res.data.data.length !== 0) {
      // const pypId = res.data.data[0].pyp_id;
      const orgId = (typeof values.organization_salary === 'undefined' || values.organization_salary === null) ? '' : values.organization_salary.value;
      const offId = (typeof values.office_salary === 'undefined' || values.office_salary === null) ? '' : values.office_salary.value;
      const empId = (typeof values.employee_salary === 'undefined' || values.employee_salary === null) ? '' : values.employee_salary.value;
      const stsId = (typeof values.status_salary === 'undefined' || values.status_salary === null) ? '' : values.status_salary.value;
      this.setState({ condOrganization: orgId });
      this.setState({ condOffice: offId });
      this.setState({ condEmployee: empId });
      this.setState({ condActive: stsId });
      // this.setState({ condPeriod: pypId });
      this.setState({ condPeriodCode: period });
      this.setState({ urlData: `${ls.get('ws_ip')}/reportsocialinsurance/getSalary` });
      setTimeout(() => {
        if (this.state.type === '1') {
          this.getTableData();
        } else {
          this.setState({ urlData: '' });
          this.getDownloadExcel();
        }
        showNotifications('Success', 'Load Success', 'success');
      }, 500);
      // }
      // });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  render() {
    const dataOne = [
      {
        column: '',
        petunjuk: '',
      },
      {
        column: 'Langkah Penggunaan Sheet "Data Upah"',
        petunjuk: '',
      },
      {
        column: 'Pengisian Column',
        petunjuk: 'Petunjuk',
      },
      {
        column: 'UPAH',
        petunjuk: 'Berisi upah dari tenaga kerja. Wajib diisi. Decimal menggunakan tanda titik (.) Contoh : 1500000,99.',
      },
      {
        column: 'RAPEL',
        petunjuk: 'Berisi rapel dari tenaga kerja. Decimal menggunakan tanda titik (.) Contoh : 1500000,99.',
      },
      {
        column: '',
        petunjuk: '',
      },
      {
        column: '* Perubahan data hanya pada kolom upah dan rapel, susunan data tidak boleh dirubah atau disorting. ',
        petunjuk: '',
      },
      {
        column: '* Untuk pengisian data tenaga kerja terdapat di sheet data_upah ',
        petunjuk: '',
      },
    ];
    const { handleSubmit, t } = this.props;
    const {
      arrYear,
      arrEmployee,
      arrOffice,
      arrUnitOrganization,
      urlData,
      displayLoad,
      condEmployee,
      condActive,
      condOrganization,
      condOffice,
      condPeriod,
      condPeriodCode,
      dataTwo,
      type,
    } = this.state;
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.SALARY')} {t('MEN.REPORT')}</h3>
                    <h3 className="page-subhead subhead">{t('LBL.SALARY')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form className="form form--horizontal mt15" onSubmit={handleSubmit(this.handleSubmit)}>
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_month_upah"
                                  component={renderSelectField}
                                  type="text"
                                  placeholder="Month"
                                  options={[
                                    { value: '01', label: `${t('LBL.JANUARY')}` },
                                    { value: '02', label: `${t('LBL.FEBRUARY')}` },
                                    { value: '03', label: `${t('LBL.MARCH')}` },
                                    { value: '04', label: `${t('LBL.APRIL')}` },
                                    { value: '05', label: `${t('LBL.MAY')}` },
                                    { value: '06', label: `${t('LBL.JUNE')}` },
                                    { value: '07', label: `${t('LBL.JULI')}` },
                                    { value: '08', label: `${t('LBL.AUGUST')}` },
                                    { value: '09', label: `${t('LBL.SEPTEMBER')}` },
                                    { value: '10', label: `${t('LBL.OCTOBER')}` },
                                    { value: '11', label: `${t('LBL.NOVEMBER')}` },
                                    { value: '12', label: `${t('LBL.DESEMBER')}` },
                                  ]}
                                />
                                <Field
                                  name="period_year_upah"
                                  component={renderSelectField}
                                  type="text"
                                  placeholder="Year"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="format_report"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                      { value: 'new', label: `${t('LBL.NEW_EMPLOYEE')}` },
                                    ]}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="status_salary"
                                  placeholder="Status"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '0', label: `${t('LBL.ACTIVE')}` },
                                    { value: '1', label: `${t('LBL.NOT_ACTIVE')}` },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee_salary"
                                  placeholder="Name"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmployee}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="organization_salary"
                                  placeholder="Organization"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrUnitOrganization}
                                />
                                <Field
                                  name="office_salary"
                                  placeholder="Office"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrOffice}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4} xs={12} lg={4} xl={4}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  id="typeAttdReport"
                                  name="type_report"
                                  placeholder="Type"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'View' },
                                    { value: '2', label: 'Excel' },
                                  ]}
                                  onChange={val => this.setState({ type: val.value })}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {(this.state.type === '2') && (
                          <ExcelFile
                            filename="template_upah_JJ092615.xlsx"
                            element={
                              <div
                                onClick={this.handleClick}
                                onKeyPress={this.handleClick}
                                role="button"
                                tabIndex="0"
                              ><input type="hidden" ref={input => this.inputElement = input} />
                              </div>}
                          >
                            <ExcelSheet data={dataOne} name="Petunjuk Pengisian" >
                              <ExcelColumn value="column" />
                              <ExcelColumn value="petunjuk" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.dataTwo} name="data_upah">
                              <ExcelColumn label="NIK" value="nik" />
                              <ExcelColumn label="ID_PEGAWAI" value="emp_code" />
                              <ExcelColumn label="KPJ" value="kpj" />
                              <ExcelColumn label="KODE_TK" value="kode_tk" />
                              <ExcelColumn label="NAMA_LENGKAP" value="emp_name" />
                              <ExcelColumn label="TGL_LAHIR" value="dob" />
                              <ExcelColumn label="UPAH" value="salary" />
                              <ExcelColumn label="RAPEL" value="backpay" />
                              <ExcelColumn label="BLTH" value="blth" />
                              <ExcelColumn label="NPP" value="npp" />
                            </ExcelSheet>
                          </ExcelFile>
                        )}
                        <ButtonToolbar className="form__button-toolbar">
                          <Button color="primary" type="submit">{t('FRM.SUBMIT')}</Button>
                        </ButtonToolbar>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayLoad, textAlign: 'center' }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row className="m5 table-nopadding">
                  <div className="card__title">
                    <h5 className="bold-text">{t('LBL.SALARY')} {t('MEN.REPORT')}</h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    // addBtn={downloadBtn}
                    // primaryKey="pyp_id"
                    // widthTable={this.state.dataWidth}
                    buttonAction="noButton"
                    conds={{
                      'is_active': condActive,
                      'org_id': condOrganization,
                      'off_id': condOffice,
                      'emp_id': condEmployee,
                      'pyp_id': condPeriod,
                      'period': condPeriodCode,
                      'type': type,
                    }}
                    onRef={ref => (this.child = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.period_month_upah) {
    errors.period_month_upah = 'Period field shouldn’t be empty';
  }

  if (!values.period_year_upah) {
    errors.period_year_upah = 'Period field shouldn’t be empty';
  }
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'horizontal_form_layout', // a unique identifier for this form
})(translate('global')(SalarySocialIncurance));
