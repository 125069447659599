/* eslint-disable array-callback-return */
/*
 * Page Group Component
 * Notes: Master Data Group Component
 * @author Gerdi
 * @date 02/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-parens */
/* eslint-disable operator-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign */

import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import ComponentList from './components/componentGrouping';

const groupNameExist = [];
const codeNameExist = [];
class GroupComponents extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.GROUP_COMPONENT') },
      { '2': t('LBL.CODE') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['5%', '50%', '25%', '20%'],
      buttonAction: ['edit', 'delete', 'component', 'duplicate'],
      defaultId: 1,
      urlData: [],
      statusEdit: false,
      statusDuplicate: false,
      onSaving: false,
      redirectComponent: false,
      pygId: '',
      duplicateId: '',
      duplicateName: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteRows = this.deleteRows.bind(this);
    this.duplicateRows = this.duplicateRows.bind(this);
    this.editRows = this.editRows.bind(this);
    this.getGroupComponent = this.getGroupComponent.bind(this);
    this.componentRows = this.componentRows.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getGroupComponent();
      this.getButton();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/paycomponentgroup/getAllItem`,
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.GROUP_COMPONENT') },
      { '2': t('LBL.CODE') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getTableData() {
    this.child.reload();
  }

  getGroupComponent() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/paycomponentgroup/getAll/`, '', config).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        for (let i = 0; i < dataResponse.length; i += 1) {
          groupNameExist[i] = dataResponse[i].component_group;
          codeNameExist[i] = dataResponse[i].component_code;
        }
      }
    });
  }

  toggle(item) {
    if (item === 'add') {
      this.getGroupComponent();
      this.setState({
        statusEdit: false,
        statusDuplicate: false,
      });
      this.props.initialize({
        pyg_id: '',
        component_code: '',
        component_group: '',
      });
    } else if (item === 'edit') {
      this.setState({
        statusEdit: true,
        statusDuplicate: false,
      });
    } else if (item === 'duplicate') {
      this.props.initialize({
        pyg_id: '',
        component_code: '',
        component_group: '',
      });
      this.setState({
        statusEdit: false,
        statusDuplicate: true,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    this.setState({ onSaving: true });
    const {
      apiws,
      token,
      idUser,
      statusEdit,
      statusDuplicate,
      duplicateId,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const code = values.component_code;
    const group = values.component_group;
    const addBody = [
      `component_code=${code}`,
      `component_group=${group}`,
      `cuser_id=${idUser}`,
    ].join('&');
    let url = `${apiws}/paycomponentgroup/saveItem`;
    if (statusEdit) {
      const pyg_id = values.pyg_id;
      url = `${apiws}/paycomponentgroup/updateItem/${pyg_id}`;
    }
    if (statusDuplicate) {
      url = `${apiws}/paycomponentgroup/duplicateItem/${duplicateId}`;
    }
    axios.post(url, addBody, config).then(() => {
      this.getTableData();
      showNotifications('Success', 'Save Success', 'success');
      this.setState({
        statusEdit: false,
        onSaving: false,
        modal: !this.state.modal,
      });
      this.props.destroy();
    });
  }

  editRows(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/paycomponentgroup/getAllItemById/${id}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const dataResponse = res.data.data;
          const pygid = dataResponse.component_group[0].pyg_id;
          const code = dataResponse.component_group[0].component_code;
          const group = dataResponse.component_group[0].component_group;
          const indexGroupName = groupNameExist.indexOf(group);
          if (indexGroupName !== -1) {
            groupNameExist.splice(indexGroupName, 1);
          }
          const indexCodeName = codeNameExist.indexOf(code);
          if (indexCodeName !== -1) {
            codeNameExist.splice(indexCodeName, 1);
          }
          this.toggle('edit');
          this.props.initialize({
            pyg_id: pygid,
            component_code: code,
            component_group: group,
          });
        }
      });
  }

  duplicateRows(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/paycomponentgroup/getAllItemById/${id}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          this.setState({
            duplicateId: id,
            duplicateName: res.data.data.component_group[0].component_group,
          });
          this.toggle('duplicate');
        }
      });
  }

  deleteRows(id) {
    // Group Component Used by User
    const { token, apiws, idUser, defaultId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let deletes = false;
    if (defaultId === id) {
      deletes = true;
    }
    if (deletes) {
      Swal.fire({
        title: 'Item locked',
        text: 'Sorry, This item cannot be delete !',
        type: 'warning',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will delete this item !',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        confirmBtnBsStyle: 'danger',
        cancelBtnBsStyle: 'default',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              `${apiws}/paycomponentgroup/deleteItem/${id}`,
              `duser_id=${idUser}`,
              config,
            )
            .then((res) => {
              if (res.data.status === 'ok') {
                this.props.destroy();
                showNotifications('Delete', 'Delete Success', 'success');
                this.getTableData();
              }
            });
        }
      });
    }
  }

  componentRows = (id) => {
    this.setState({
      redirectComponent: true,
      pygId: id,
    });
    if (id === '') {
      this.setState({
        redirectComponent: false,
        pygId: '',
      });
      setTimeout(() => {
        this.getTableData();
      }, 50);
    }
  };

  render() {
    const {
      statusEdit,
      urlData,
      onSaving,
      redirectComponent,
      pygId,
      statusDuplicate,
      duplicateName,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    const modalStyle = {
      maxWidth: '700px',
      marginTop: '95px',
      textAlign: 'center',
    };
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.GROUP_COMPONENT')}
      </Button>
    );
    let button_cancel;
    let field_pygid;
    if (statusEdit) {
      field_pygid = (
        <Field name="pyg_id" component={renderTextInput} type="hidden" />
      );
      button_cancel = (
        <Button
          color="secondary"
          type="button"
          onClick={this.cancelgroupComponent}
        >
          {' '}
          {t('FRM.CANCEL')}
        </Button>
      );
    }
    if (pygId !== '') {
      if (redirectComponent) {
        return (
          <ComponentList
            groupId={this.state.pygId}
            groupComponent={this.componentRows}
          />
        );
      }
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pyg_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteRows}
                  updateFunc={this.editRows}
                  duplicateFunc={this.duplicateRows}
                  componentFunc={this.componentRows}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  displayTitle="none"
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                  buttonAction={this.state.buttonAction}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader>
                {' '}
                {statusDuplicate ? t('LBL.DUPLICATE') : t('FRM.ADD')}{' '}
                {t('LBL.GROUP_COMPONENT')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="groupcompform"
                  id="groupcompform"
                >
                  {field_pygid}
                  <Container>
                    <Row>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.GROUP_COMPONENT')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="component_group"
                              id="component_group"
                              component={renderTextInput}
                              type="text"
                              onChange={this.handleChangeGroupComp}
                              maxLength="100"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {' '}
                            {t('LBL.CODE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="component_code"
                              id="component_code"
                              component={renderTextInput}
                              type="text"
                              maxLength="10"
                              onChange={this.handleChangeGroupCode}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {statusDuplicate && (
                      <Row>
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div className="note-file-upload">
                            <h5>
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Notes
                            </h5>
                            <hr />
                            <span>
                              All components and formulas will be duplicated
                              automatically from the <b>{duplicateName}</b>{' '}
                              component group.
                            </span>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
                {onSaving ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    color="success"
                    type="submit"
                    form="groupcompform"
                    disabled={pristine}
                  >
                    {' '}
                    Save
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorGroupName = groupNameExist;
  const errorCodeName = codeNameExist;
  if (!values.component_group) {
    errors.component_group = 'Group component field shouldn’t be empty';
  }
  if (!values.component_code) {
    errors.component_code = 'Code field shouldn’t be empty';
  }
  if (errorGroupName.includes(values.component_group)) {
    errors.component_group = 'Group component is already taken';
  }
  if (errorCodeName.includes(values.component_code)) {
    errors.component_code = 'Code group component is already taken';
  }
  return errors;
};

export default reduxForm({
  form: 'groupcomponentforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(GroupComponents));
