/* eslint-disable camelcase */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable padded-blocks */

import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import ReportAttendanceViewTable from './Components/ViewTableReportAttendance';
import ReportAttendanceForm from './Components/FormReportAttendance';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

class ReportAttendance extends PureComponent {
  static propTypes = {
    location: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      dataUpdate: false,
      url: '',
      dt: [],
      att_status: [],
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
  }

  setDefault = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = (val, dt, distinc) => {
    this.setState({ dataUpdate: true });
    this.setState({ url: val, dt });
    this.setState({ att_status: distinc });
  }

  render() {
    // console.log(this.props.location);
    const {
      dataUpdate, url, dt, att_status,
    } = this.state;
    return (
      <Container>
        <Row>
          <ReportAttendanceForm
            onProcess={this.loadAfterSubmit}
            location={this.props.location}
          />
        </Row>
        <Row>
          <ReportAttendanceViewTable
            onProcess={dataUpdate}
            inProcess={this.setDefault}
            urlData={url}
            conds={dt}
            attstatus={att_status}
          />
        </Row>
      </Container>
    );
  }
}

export default translate('global')(ReportAttendance);
