/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state, class-methods-use-this, max-len, quote-props */

import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, Container, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import config from '../../../shared/components/config/config';
import renderTextInput from '../../../shared/components/form/TextInput';
import renderMaskInput from '../../../shared/components/form/InputMask';
import renderSelectField from '../../../shared/components/form/Select';
import showNotifications from '../../Preferences/notificationMessages';

class PackagePage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      fullname: '',
      email: '',
      phone: '',
      redirect: false,
    };

    this.handlePreferencesContact = this.handleSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState(
      {
        iduser: ls.get('user_cred').usr_id,
        fullname: `${ls.get('user_cred').first_name} ${
          ls.get('user_cred').last_name
        }`,
        email: ls.get('user_cred').email,
        phone: ls.get('user_cred').mobile,
      },
      () => {
        const datamaping = {
          fullname: this.state.fullname,
          email: this.state.email,
          phone: this.state.phone,
        };
        this.props.initialize(datamaping);
      },
    );
  };

  goBack() {
    window.history.go(-1);
  }

  handleSubmit(values) {
    const { apiws, token, iduser } = this.state;

    const pContact =
      typeof values.preferences_contact === 'undefined' ||
      values.preferences_contact === null
        ? ''
        : values.preferences_contact.value;
    const pTime =
      typeof values.preferences_time === 'undefined' ||
      values.preferences_time === null
        ? ''
        : values.preferences_time.value;
    const data = {
      ...values,
      subdomain: config.subdomain,
      preferences_contact: pContact,
      preferences_time: pTime,
      cuser_id: iduser,
    };

    const url = `${apiws}/planrequest/saveItem`;
    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());
    const header = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    axios
      .post(url, formBody, header)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.setState({ redirect: true });
        } else if (response.data.status === 'available') {
          showNotifications('Warning', 'Upgrade has been requested', 'warning');
        } else {
          showNotifications('Fail', response.data.message, 'danger');
        }
      })
      .catch(() => {});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    const { handleSubmit, reset, t } = this.props;
    return (
      <div className="container-fluid home-fluid">
        <div className="home-header">
          <Container>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div className="package-header">
                  <h4>
                    <span className="lnr lnr-exit-up" />{' '}
                    <b>{t('LBL.UPGRADE')}</b> {t('LBL.YOUR_PACKAGE')}
                  </h4>
                  <p>{t('LBL.PACKAGE_DESCRIPTION')}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="dashboard" style={{ marginTop: '-70px' }}>
          <Row className="justify-content-md-center">
            <Col sm={12} md={8} lg={8}>
              <Card>
                <CardBody>
                  <form
                    className="form flex-column"
                    onSubmit={handleSubmit(this.handleSubmit)}
                  >
                    <div className="w-100">
                      <Row>
                        <Col sm={12} md={4} lg={4}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.NAME')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                component={renderTextInput}
                                name="fullname"
                                type="text"
                                disabled
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.EMAIL')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                component={renderTextInput}
                                name="email"
                                type="email"
                                disabled
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.NUMBER_OF_EMPLOYEES')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="user_count"
                                component={renderTextInput}
                                type="number"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="w-100">
                      <Row>
                        <Col sm={12} md={4} lg={4}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.PREFERENCE_CONTACT')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="preferences_contact"
                                component={renderSelectField}
                                type="text"
                                options={[
                                  { value: '1', label: 'by Email' },
                                  { value: '2', label: 'by Phone' },
                                  { value: '3', label: 'by Whatsapp' },
                                ]}
                                label="Preferences Contact"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.CONTACT')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="phone"
                                component={renderMaskInput}
                                type="text"
                                mask={[
                                  '+',
                                  '6',
                                  '2',
                                  ' ',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  ' ',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  ' ',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                placeholder="+62 "
                                guide={false}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.PREFERENCE_TIME')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="preferences_time"
                                component={renderSelectField}
                                type="text"
                                options={[
                                  { value: '1', label: '08.00 - 12.00' },
                                  { value: '2', label: '13.00 - 17.00' },
                                ]}
                                label="Preferences Time"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="w-100">
                      <Row>
                        <Col sm={12} md={12} lg={12} className="text-right">
                          <div role="toolbar" style={{ marginTop: '15px' }}>
                            <Button
                              type="button"
                              onClick={() => {
                                this.goBack();
                              }}
                            >
                              {t('FRM.CANCEL')}
                            </Button>
                            <Button color="primary" type="submit">
                              {t('FRM.SAVE')}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: 'upgrade_plan_form', // a unique identifier for this form
})(translate('global')(PackagePage));
