/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable quote-props */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../notificationMessages';
import moment from 'moment';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import utils from '../../../../utils';

let shiftExist = [];

class ShiftSetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.SHIFT') },
      { '2': t('LBL.DAY_TYPE') },
      { '3': t('LBL.WORIKING_DAY_START') },
      { '4': t('LBL.WORIKING_DAY_END') },
      { '5': t('LBL.ENTRY_LIMIT') },
      { '6': t('LBL.EXIT_LIMIT') },
      { '7': t('FRM.FUNCTION') },
    ];
    this.state = {
      dynForm: [],
      defaultvalue: moment(),
      urlData: '',
      apiws: '',
      token: '',
      statusEdit: false,
      modal: false,
      buttonDisabled: true,
      inputDisabled: false,
      dataWidth: ['5%', '20%', '10%', '10%', '10%', '10%', '10%', '15%'],
      editcond: '',
      addcond: '',
      touched: false,
      deletecond: '',
    };
    this.editGrid = this.editGrid.bind(this);
    this.deleteGrid = this.deleteGrid.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderBreakTime = this.renderBreakTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeShift = this.changeShift.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ urlData: `${ls.get('ws_ip')}/attshift/getAllitem` }, () => {
      this.getTableData();
      this.getShift();
      this.getButton();
    });
    this.setState({
      defaultvalue:
        this.state.defaultvalue && this.state.defaultvalue.format('HH:mm'),
    });
  };
  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.SHIFT') },
      { '2': t('LBL.DAY_TYPE') },
      { '3': t('LBL.WORIKING_DAY_START') },
      { '4': t('LBL.WORIKING_DAY_END') },
      { '5': t('LBL.ENTRY_LIMIT') },
      { '6': t('LBL.EXIT_LIMIT') },
      { '7': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getShift() {
    shiftExist = [];
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${ls.get('ws_ip')}/attshift/getAll`, '', config).then((res) => {
      const dataResponse = res.data.data;
      for (let i = 0; i < dataResponse.length; i += 1) {
        shiftExist[i] = dataResponse[i].shift;
      }
    });
  }

  deleteGrid(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attshift/deleteitem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.getTableData();
                this.getShift();
              }, 1000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  editGrid(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attshift/getJoinItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ modal: !this.state.modal });
          this.setState({ touched: true });
          this.setState({ buttonDisabled: true });
          this.setState({ statusEdit: true });
          const daytypearr = [];
          const getResponseData = response.data.data;
          const indexName = shiftExist.indexOf(getResponseData[0].shift);
          if (indexName !== -1) {
            shiftExist.splice(indexName, 1);
          }
          getResponseData.forEach((element) => {
            const breakStart =
              getResponseData[0].break_start === null
                ? ''
                : moment(element.break_start, 'HH:mm');
            const breakEnd =
              getResponseData[0].break_end === null
                ? ''
                : moment(element.break_end, 'HH:mm');
            daytypearr.push({
              break_hour_start: breakStart,
              break_hour_end: breakEnd,
            });
          });
          let cek_late = 0;
          if (getResponseData[0].late_tolerance != null) {
            cek_late = 1;
            this.setState({ inputDisabled: false });
          }
          const workingDayStart =
            getResponseData[0].working_day_start === null
              ? ''
              : moment(getResponseData[0].working_day_start, 'HH:mm');
          const workingDayEnd =
            getResponseData[0].working_day_end === null
              ? ''
              : moment(getResponseData[0].working_day_end, 'HH:mm');
          const lateTolerance =
            getResponseData[0].late_tolerance === null
              ? ''
              : moment(getResponseData[0].late_tolerance, 'HH:mm');
          const entryLimit =
            getResponseData[0].entry_limit === null
              ? ''
              : moment(getResponseData[0].entry_limit, 'HH:mm');
          const exitLimit =
            getResponseData[0].exit_limit === null
              ? ''
              : moment(getResponseData[0].exit_limit, 'HH:mm');
          const datamaping = {
            working_day_start: workingDayStart,
            working_day_end: workingDayEnd,
            late_tolerance_time: lateTolerance,
            entry_limit: entryLimit,
            exit_limit: exitLimit,
            late_tolerance_check: cek_late,
            ath_id: getResponseData[0].ath_id,
            shift: getResponseData[0].shift,
            day_type: getResponseData[0].day_type,
            dyn_breaktime: daytypearr,
          };
          this.props.destroy();
          this.props.initialize(datamaping);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  changeLate() {
    const { inputDisabled } = this.state;
    if (inputDisabled) {
      this.setState({ inputDisabled: false });
    } else {
      this.setState({ inputDisabled: true });
    }
  }

  changeShift(x) {
    if (x.target.value === '') {
      this.setState({ buttonDisabled: true });
    } else {
      this.setState({ buttonDisabled: false });
    }
  }

  handleSubmit(values) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const userid = ls.get('user_cred').usr_id;
    const id = values.ath_id;
    const isValid = !utils.isObjectExist(validate(values));
    if (!isValid) return false;

    let url;
    let data;
    if (id === undefined || id === '') {
      data = { ...values, cuser_id: userid };
      url = `${ws_ip}/attshift/saveItem`;
    } else {
      data = { ...values, muser_id: userid };
      url = `${ws_ip}/attshift/updateItem/${id}`;
    }
    data = {
      data: JSON.stringify(data),
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.setState({ modal: !this.state.modal });
          this.getTableData();
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  removeForm(i) {
    const dynForm = [...this.state.dynForm];
    dynForm.splice(i, 1);
    this.setState({ dynForm });
  }

  toggle(x) {
    if (x === 'add' || x === 'cancel') {
      this.setState({ statusEdit: false });
      this.setState({ inputDisabled: false });
      this.setState({ buttonDisabled: true });
      this.setState({ modal: !this.state.modal });
      const datamaping = {
        working_day_start: '',
        working_day_end: '',
        late_tolerance_time: '',
        entry_limit: '',
        exit_limit: '',
        late_tolerance_check: '',
        ath_id: '',
        shift: '',
        day_type: '',
        dyn_breaktime: [{}],
      };
      this.props.initialize(datamaping);
    }
  }

  renderBreakTime = ({ fields, meta: { error } }) => (
    <div>
      {fields.map((breaktime, index) => (
        <Row className="form__form-group">
          <div className="col-3 col-md-3" style={{ paddingRight: 0 }}>
            <Field
              name={`${breaktime}.break_hour_start`}
              component={renderTimePickerIntervalField}
              placeholder="hh:mm"
              formatdate="HH:mm"
            />
          </div>
          <div>
            <MinusIcon className="date-picker__svg" />
          </div>
          <div className="col-3 col-md-3" style={{ paddingLeft: 0 }}>
            <Field
              name={`${breaktime}.break_hour_end`}
              component={renderTimePickerIntervalField}
              placeholder="hh:mm"
              formatdate="HH:mm"
            />
          </div>
          {index === 0 ? (
            <div>
              <Button
                className="add_btn"
                onClick={() => fields.push({})}
                color="primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className="add_btn_identity"
                onClick={() => fields.remove(index)}
                color="danger"
                size="sm"
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </div>
          )}
        </Row>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );
  render() {
    const { handleSubmit, t, pristine } = this.props;
    const { urlData, statusEdit, inputDisabled } = this.state;
    let buttonColor;
    let buttonText;
    let disabledButton;
    if (statusEdit) {
      buttonColor = 'success';
      buttonText = 'Edit';
      disabledButton = pristine;
    } else {
      buttonColor = 'primary';
      buttonText = 'Save';
      disabledButton = this.state.buttonDisabled;
    }
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => {
          this.toggle('add');
        }}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.SHIFT')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '700px',
      marginTop: '95px',
      textAlign: 'center',
    };
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ath_id"
                  deleteFunc={this.deleteGrid}
                  updateFunc={this.editGrid}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  widthTable={this.state.dataWidth}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog modal-input"
            style={modalStyle}
          >
            <ModalHeader>
              {' '}
              {t('FRM.ADD')} {t('LBL.SHIFT')}{' '}
            </ModalHeader>
            <ModalBody>
              <form
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
                name="shiftforminput"
                id="shiftforminput"
              >
                <Col md={7} xs={7} lg={7} xl={7}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.NAME')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="shift"
                        onChange={this.changeShift}
                        component={renderTextInput}
                        type="text"
                        props={{ disableTouched: this.state.touched }}
                      />
                    </div>
                  </div>
                </Col>
                <input type="hidden" name="ath_id" />
                <Col md={7} xs={7} lg={7} xl={7}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DAY_TYPE')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ zIndex: '9999' }}
                    >
                      <Field
                        name="day_type"
                        // onChange={this.handleChange.bind(this, 'day_type')}
                        component={renderSelectField}
                        type="text"
                        options={[
                          { value: '1', label: 'Weekday' },
                          // { value: '2', label: 'Flexible Day' },
                          { value: '3', label: 'Weekend' },
                        ]}
                        props={{ disableTouched: this.state.touched }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} xs={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.WORKING_HOUR_SETTING')}
                    </span>
                    <div className="form__form-group-field time-interval-form">
                      <div className="form-container">
                        <Row>
                          <div
                            className="col-3 col-md-3"
                            style={{ paddingRight: 0 }}
                          >
                            <Field
                              name="working_day_start"
                              component={renderTimePickerIntervalField}
                              placeholder="08:00"
                              formatdate="HH:mm"
                              props={{ disableTouched: this.state.touched }}
                            />
                          </div>
                          <div>
                            <MinusIcon className="date-picker__svg" />
                          </div>
                          <div
                            className="col-3 col-md-3"
                            style={{ paddingLeft: 0 }}
                          >
                            <Field
                              name="working_day_end"
                              component={renderTimePickerIntervalField}
                              placeholder="17:00"
                              formatdate="HH:mm"
                              props={{ disableTouched: this.state.touched }}
                            />
                          </div>
                          <div
                            className="col-4 col-md-4"
                            style={{ paddingLeft: 0 }}
                          >
                            <Field
                              name="late_tolerance_check"
                              component={renderCheckBoxField}
                              label={t('LBL.LATE_TOLERANCE')}
                              onChange={() => {
                                this.changeLate();
                              }}
                              defaultChecked={inputDisabled}
                            />
                            <Field
                              name="late_tolerance_time"
                              component={renderTimePickerIntervalField}
                              placeholder="hh:mm"
                              formatdate="HH:mm"
                              disabled={inputDisabled}
                            />
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} xs={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.ENTRY_LIMIT')} - {t('LBL.EXIT_LIMIT')}
                    </span>
                    <div className="form__form-group-field time-interval-form">
                      <div className="form-container">
                        <Row>
                          <div
                            className="col-3 col-md-3"
                            style={{ paddingRight: 0 }}
                          >
                            <Field
                              name="entry_limit"
                              component={renderTimePickerIntervalField}
                              placeholder="08:00"
                              formatdate="HH:mm"
                              props={{ disableTouched: this.state.touched }}
                            />
                          </div>
                          <div>
                            <MinusIcon className="date-picker__svg" />
                          </div>
                          <div
                            className="col-3 col-md-3"
                            style={{ paddingLeft: 0 }}
                          >
                            <Field
                              name="exit_limit"
                              component={renderTimePickerIntervalField}
                              placeholder="17:00"
                              formatdate="HH:mm"
                              props={{ disableTouched: this.state.touched }}
                            />
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} xs={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                      // style={{ 'margin-top': '10px' }}
                    >
                      {' '}
                      {t('LBL.BREAK_TIME')}
                    </span>
                    <div className="form__form-group-field time-interval-form">
                      <div className="form-container">
                        <FieldArray
                          name="dyn_breaktime"
                          component={this.renderBreakTime}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.toggle('cancel');
                }}
              >
                {t('FRM.CANCEL')}
              </Button>
              <Button
                color={buttonColor}
                type="submit"
                form="shiftforminput"
                disabled={disabledButton}
              >
                {' '}
                {buttonText}
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    );
  }
}

/* const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
async function submit(values) {
  await sleep(500);
  const config = {
    headers: {
      'Authorization': `bearer ${ls.get('token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const ws_ip = ls.get('ws_ip');
  const userid = ls.get('user_cred').usr_id;
  const id = values.ath_id;
  let url;
  let data;
  if (id === undefined || id === '') {
    url = `${ws_ip}/attshift/saveItem`;
    data = { ...values, cuser_id: userid };
  } else {
    data = { ...values, muser_id: userid };
    url = `${ws_ip}/attshift/updateItem/${id}`;
  }
  data = {
    data: JSON.stringify(values),
  };
  const formBody = Object.keys(data).map(key =>
    `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
  axios.post(
    url,
    formBody,
    config,
  ).then((response) => {
    if (response.data.status === 'ok') {
      showNotifications('Success', 'Save Success', 'success');
    } else {
      showNotifications('Fail', 'Save Failed', 'danger');
    }
  }).catch(() => {
    // console.log(error);
  });
}
function loadaftersubmit(result, dispatch, props) {
  dispatch(reset('shift_form'));
} */

const validate = (values) => {
  const validation = {
    shift: {
      fieldLabel: 'Shift',
      required: true,
    },
    day_type: {
      fieldLabel: 'Day type',
      required: true,
    },
    working_day_start: {
      fieldLabel: 'Start time',
      required: true,
    },
    working_day_end: {
      fieldLabel: 'End time',
      required: true,
      // lockTimeAfter: 'working_day_start',
      fieldLabelBefore: 'Start Time',
    },
    entry_limit: {
      fieldLabel: 'Entry limit time',
      // required: true,
    },
    exit_limit: {
      fieldLabel: 'Exit limit time',
      // required: true,
      // lockTimeAfter: 'entry_limit',
      fieldLabelBefore: 'Entry limit time',
    },
    dyn_breaktime: {
      dynamicField: true,
      children: [
        {
          name: 'break_hour_start',
          label: 'Start Breaktime',
          // required: true
        },
        {
          name: 'break_hour_end',
          label: 'End Breaktime',
          // required: true,
          lockTimeAfter: 'break_hour_start',
          fieldLabelBefore: 'Start Breaktime',
        },
      ],
    },
  };
  const errors = utils.validate(validation, values);
  if (values.entry_limit > values.working_day_start) {
    errors.entry_limit = 'Entry Limit should not greater than start working hours'
  }
  let start_day = moment(values.working_day_start).diff(moment('00:00:00', 'HH:mm'), 'minutes')
  if (start_day == 0) {
    errors.working_day_start = 'Working hour start must be in 00:01 until 23:59'
  }
  let end_day = moment(values.working_day_end).diff(moment('00:00:00', 'HH:mm'), 'minutes')
  if (end_day == 0) {
    errors.working_day_end = 'Working hour end must be in 00:01 until 23:59'
  }
  if (values.exit_limit) {
    let ext_lmt = moment(values.exit_limit).diff(moment('00:00:00', 'HH:mm'), 'minutes')
    if (ext_lmt == 0) {
      errors.exit_limit = 'Exit Limit must be in 00:01 until 23:59'
    }
    if (values.entry_limit <= values.exit_limit && values.exit_limit <= values.working_day_end) {
      errors.exit_limit = 'Exit Limit should not less than end working hours and greater than entry limit'
    }
  }
  const errorsShiftExist = shiftExist;
  if (errorsShiftExist.includes(values.shift)) {
    errors.shift = 'Shift name is already taken';
  }
  return errors;
};

export default reduxForm({
  form: 'shiftinputform', // a unique identifier for this form
  // onSubmit: submit,
  // onSubmitSuccess: loadaftersubmit,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(ShiftSetting));
