/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container, Row, Card, CardBody, Col, Table, ButtonToolbar, Button,
  Modal, ModalFooter, ModalBody, ModalHeader,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faTrashAlt,
  faPencilAlt,
  faTimes,
  faSearch,
  faRedoAlt,
  faBook,
  faAsterisk,
  faArrowsAltV,
} from '@fortawesome/fontawesome-free-solid';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../Preferences/notificationMessages';
import renderTextInput from '../../../shared/components/form/TextInput';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderSelectField from '../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../Tables/DataTable/assets/css/style.css';
import { Link } from 'react-router-dom';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';
import renderMaskInput from '../../../shared/components/form/InputMask';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../../utils';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderFileInputField from '../../../shared/components/form/FileInput';
import MinusIcon from 'mdi-react/MinusIcon';

// parameter tanslates
let translator = () => { };
class FunctionAccessPage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.WORKFLOW')} ${t('LBL.NAME')}`,
      t('LBL.DOCUMENT'),
      t('LBL.ACTIVITY'),
      t('LBL.ORDER'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.props.initialize({});
  }

  // menerima jika ada props dari manapun yang di update
  // akan diproses disini
  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.WORKFLOW')} ${t('LBL.NAME')}`,
      t('LBL.DOCUMENT'),
      t('LBL.ACTIVITY'),
      t('LBL.ORDER'),
      t('FRM.FUNCTION'),
    ];
  }

  handleSubmit(values) {
    // console.log(values);
    setTimeout(() => {
      this.setState({ loading: true });
    }, 100);
    showNotifications('Success', 'Save Success', 'success');
    // showNotifications('Failed', 'NPK already exist in database', 'danger');
    this.props.initialize({});
  }

  toggle() {
    this.props.initialize({});
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    return (
      <Container>
        <Col md={12} lg={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <form
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
                name="workflowform"
                id="workflowform"
              >
                <Container>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.WORKFLOW')} {t('LBL.NAME')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="workflow"
                            component={renderTextInput}
                            type="text"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.DESCRIPTION')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="description"
                            component={renderTextInput}
                            type="textarea"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.ACTIVITY')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="activity"
                            component={renderSelectField}
                            options={[{ 'value': 1, label: 'Label One' }, { 'value': 2, label: 'Label Two' }]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.COMPONENT')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="manyactivity"
                            component={renderMultiSelectField}
                            options={[{ 'value': 1, label: 'Label One' }, { 'value': 2, label: 'Label Two' }]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.NPWP')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="npwp"
                            component={renderMaskInput}
                            mask={[/[1-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.SEX')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="gender"
                            component={renderRadioButtonField}
                            label="Male"
                            radioValue="male"
                            // defaultChecked
                          />
                          <Field
                            name="gender"
                            component={renderRadioButtonField}
                            label="Female"
                            radioValue="female"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.PAYROLL')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="componentbpjs"
                            component={renderCheckBoxField}
                            label="BPJS"
                          />
                        </div>
                        <div className="form__form-group-field">
                          <Field
                            name="componenttax"
                            component={renderCheckBoxField}
                            label="TAX"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} xl={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.START_DATE')}</span>
                        <div className="form__form-group-field" >
                          <Field
                            name="start_date"
                            component={renderDatePickerField}
                            placeholder="Start Date"
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                        <div className="form__form-group-field" >
                          <Field
                            name="period_date"
                            component={renderIntervalDatePickerField}
                            placeholder="DD-MM-YYYY"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} xl={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.TIME')}</span>
                        <div className="form__form-group-field" >
                          <Field
                            name="start_time"
                            component={renderTimePickerIntervalField}
                            placeholder="08:00"
                            formatdate="HH:mm"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.WORKING_HOUR_SETTING')}</span>
                        <div className="form__form-group-field">
                          <div className="timepicker--interval">
                            <div className="timepicker-form">
                              <Field
                                name="working_day_start"
                                component={renderTimePickerIntervalField}
                                placeholder="08:00"
                                formatdate="HH:mm"
                              />
                            </div>
                            <div className="timepicker_svg">
                              <MinusIcon className="time-picker__svg" />
                            </div>
                            <div className="timepicker-form">
                              <Field
                                name="working_day_end"
                                component={renderTimePickerIntervalField}
                                placeholder="17:00"
                                formatdate="HH:mm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} xs={5} lg={5} xl={5}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.UPLOAD_PHOTO')}</span>
                        <div className="form__form-group-field upload-photo-form" >
                          <Field
                            name="photo"
                            component={renderFileInputField}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </form>
              <Container>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div style={{ float: 'right' }}>
                      <Button color="secondary" onClick={this.toggle}>Reset</Button>
                      {(this.state.loading) ?
                        (
                          <Button color="primary">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: '6px' }}
                            />
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            form="workflowform"
                            type="submit"
                            disabled={pristine}
                          > {t('FRM.SAVE')}
                          </Button>
                        )
                      }
                    </div>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}

// Old Validation
// const validate = (values) => {
//   const errors = {};
//   if (!values.workflow) {
//     errors.workflow = 'Workflow field shouldn’t be empty';
//   }
//   if (!values.description) {
//     errors.description = 'Description field shouldn’t be empty';
//   }
//   if (!values.activity) {
//     errors.activity = 'Activity field shouldn’t be empty';
//   }
//   if (!values.manyactivity) {
//     errors.manyactivity = 'Many activity field shouldn’t be empty';
//   }
//   if (!values.npwp) {
//     errors.npwp = 'Npwp field shouldn’t be empty';
//   }
//   if (!values.component) {
//     errors.component = 'Component field shouldn’t be empty';
//   }
//   return errors;
// };

// New Validation
const validate = (values) => {
  const validation = {
    workflow: {
      fieldLabel: `${translator('LBL.WORKFLOW')}`,
      required: true,
    },
    description: {
      fieldLabel: `${translator('LBL.DESCRIPTION')}`,
      required: true,
    },
    activity: {
      fieldLabel: `${translator('LBL.ACTIVITY')}`,
      required: true,
    },
    manyactivity: {
      fieldLabel: `${translator('LBL.COMPONENT')}`,
      required: true,
    },
    npwp: {
      fieldLabel: `${translator('LBL.ACTIVITY')}`,
      required: true,
    },
    component: {
      fieldLabel: `${translator('LBL.ACTIVITY')}`,
      required: true,
    },
    start_date: {
      fieldLabel: `Start ${translator('LBL.DATE')}`,
      required: true,
    },
    period_date: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      intervalDateTime: true,
    },
    start_time: {
      fieldLabel: `${translator('LBL.TIME')}`,
      required: true,
    },
    working_day_start: {
      fieldLabel: `${translator('LBL.TIME_IN')}`,
      required: true,
    },
    working_day_end: {
      fieldLabel: `${translator('LBL.TIME_IN')}`,
      required: true,
      lockTimeAfter: 'working_day_start',
      fieldLabelBefore: 'Start Time',
    },
    photo: {
      fieldLabel: `${translator('LBL.UPLOAD_PHOTO')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'reduxFunctionAccess', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(FunctionAccessPage));
