/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import utils from '../../../utils';

const renderSelectField = (props) => {
  console.log(props.input.value);
  const Ref = useRef(Select);
  const [selectedValue, setSelectedValue] = useState(null);
  if (typeof props.func !== 'undefined') {
    props.func(props.input.value);
  }
  if (typeof props.func !== 'undefined') {
    props.func(props.input.value);
  }
  if (props.input.value || props.value) {
    useEffect(() => {
      let value = null;
      let temp = [];
      if (Array.isArray(props.input.value)) {
        // given value on change
        if (utils.isObjectExist(props.input.value[0])) {
          value = props.input.value;
        } else {
          // populate first selected val
          props.options?.map((item) => {
            if (props.input.value) {
              props.input.value?.map((data) => {
                if (item.value == data) {
                  temp.push(item);
                }
              });
            } else {
              if (props.value == item.value) {
                temp.push(item);
              } else {
                temp.push([]);
              }
            }
          });

          value = temp;
        }
      } else {
        if (
          typeof props.input.value == 'object' &&
          utils.isObjectExist(props.input.value)
        ) {
          value = props.input.value;
        } else {
          if (props.group) {
            props.options?.map((item) => {
              item.options?.map((dataOpt) => {
                if (dataOpt.value == props.input.value) {
                  temp.push({ value: dataOpt.value, label: dataOpt.label });
                  // console.log(dataOpt);
                  // value = {value: dataOpt.value, label: dataOpt.label};
                  // console.log({value: dataOpt.value, label: dataOpt.label});
                }
              });
            });
            value = temp;
          }
          props.options?.map((item) => {
            if (
              typeof item.value == 'string' &&
              typeof props.input.value == 'string'
            ) {
              if (item.value == props.input.value) {
                value = { value: item.value, label: item.label };
              }
            } else {
              if (+item.value == +props.input.value) {
                value = { value: item.value, label: item.label };
              }
            }
            // if (props.value) {
            //   if (+item.value == +props.value) {
            //     value = { value: item.value, label: item.label };
            //   }
            // }
          });
        }

        if (props.value) {
          props.options?.map((item) => {
            if (item.value == props.value) {
              value = { value: item.value, label: item.label };
            }
          });
        }
        // if (props.input.value && !props.value) {
        //   props.options?.map((item) => {
        //     if (item.value == props.value) {
        //       value = { value: item.value, label: item.label };
        //     } else {
        //       value = null;
        //     }
        //   });
        // }

        // ganti jadi clear nnt
        if (props.value == '' && props.clear) {
          setSelectedValue(null);
        }
      }
      setSelectedValue(value);
    }, [props.input.value, props.value, props.options]);
  } else {
    useEffect(() => {
      if (!props?.input?.value && props?.selected) {
        setSelectedValue(props.options[0]);
        props.input.onChange(props.options[0]);
      } else {
        if (Array.isArray(props.options)) {
          props.options?.map((item) => {
            if (item.value == props.value) {
              setSelectedValue(item);
            } else {
              setSelectedValue(null);
            }
          });
        }
      }
    }, [props.selected]);

    // useEffect(() => {
    //   if (props.value !== '')  {
    //     console.log(props.value)
    //     props.options?.map((item) => {
    //       if (item.value == props.value) {
    //         setSelectedValue(item);
    //       }
    //     });
    //   }
    // }, [props.value]);
  }
  if (props.input.saveOnBlur) {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <Select
          className={`form__form-group-select ${props?.className ?? ''}`}
          ref={(ref) => {
            Ref.current = ref;
            if (props?.input?.ref) props.input.ref(ref);
          }}
          // ref={props?.input?.ref}
          onChange={(val, meta) => {
            if (
              meta.action == 'clear' &&
              Ref.current.select.hasGroups == true
            ) {
              setTimeout(() => {
                setSelectedValue(null);
              }, 10);
            }
            setSelectedValue(val);
            if (typeof props.input.onChange != 'undefined')
              props.input.onChange(val);
          }}
          onBlur={props.input.onBlur}
          options={props.options || []}
          isDisabled={props.disabled || false}
          placeholder={props.placeholder}
          isMulti={props.multi || false}
          value={selectedValue || props.input.value || props.value}
          isClearable={props.isClearable}
          isOptionDisabled={(option) => option.isDisabled == true}
          openMenuOnFocus={props.openMenuOnFocus}
          styles={{
            groupHeading: (base) => ({
              ...base,
              color: '#1974EF',
              borderTop: '1px solid #ddd',
              paddingTop: '15px',
            }),
          }}
        />
        {props.meta.touched && props.meta.error && (
          <span className={`form__form-group-error ${props?.style ?? ''}`}>
            {props.meta.error}
          </span>
        )}
        {props.disableTouched && props.meta.error && (
          <span className="form__form-group-error">{props.meta.error}</span>
        )}
      </div>
    );
  }
  return (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <Select
        className={`form__form-group-select ${props?.className ?? ''}`}
        ref={(ref) => {
          Ref.current = ref;
          if (props?.input?.ref) props.input.ref(ref);
        }}
        // ref={props?.input?.ref}
        onChange={(val, meta) => {
          if (meta.action == 'clear' && Ref.current.select.hasGroups == true) {
            setTimeout(() => {
              setSelectedValue(null);
            }, 10);
          }
          setSelectedValue(val);
          if (typeof props.input.onChange != 'undefined')
            props.input.onChange(val);
        }}
        // onBlur={props.input.onBlur}
        options={props.options || []}
        isDisabled={props.disabled || false}
        placeholder={props.placeholder}
        isMulti={props.multi || false}
        value={selectedValue ?? props.value ?? props.input.value}
        isClearable={props.isClearable}
        isOptionDisabled={(option) => option.isDisabled == true}
        openMenuOnFocus={props.openMenuOnFocus}
        styles={{
          groupHeading: (base) => ({
            ...base,
            color: '#1974EF',
            borderTop: '1px solid #ddd',
            paddingTop: '15px',
          }),
        }}
      />
      {props.meta.touched && props.meta.error && (
        <span className={`form__form-group-error ${props?.style ?? ''}`}>
          {props.meta.error}
        </span>
      )}
      {props.disableTouched && props.meta.error && (
        <span className="form__form-group-error">{props.meta.error}</span>
      )}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      isOptionDisabled: PropTypes.bool,
    }),
  ),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  isDisabled: false,
  type: 'text',
  isMulti: false,
  isClearable: true,
  clear: false,
};

export default React.memo(renderSelectField);
