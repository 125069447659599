import React, { useEffect, useRef, useState } from 'react';
import renderSelectField from '../../shared/components/form/Select';
import renderTextInput from '../../shared/components/form/TextInput';
import { Field, reduxForm, reset } from 'redux-form';
import { translate } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonToolbar,
  UncontrolledTooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import './okr.css';
import ls from 'local-storage';
import axios from 'axios';
import renderDatePickerField from '../../shared/components/form/DatePicker';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import showNotifications from '../../../src/containers/Preferences/notificationMessages';
import Swal from 'sweetalert2';

const FormKeyResult = ({
  id,
  modal,
  data,
  toggleOffChange,
  objective,
  reload,
  t,
}) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [isSubmit, setIsSubmit] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [custom, setCustom] = useState(false);
  const [period, setPeriod] = useState([]);
  const [status, setStatus] = useState([]);
  const [owner, setOwner] = useState([]);
  const [timePeriod, setTimePeriod] = useState([]);
  const [yearOpt, setYearOpt] = useState([]);
  const [calculationType, setCalculationType] = useState([]);
  const [unitMeasurement, setUnitMeasurment] = useState([]);

  const [error, setError] = useState({
    key_result_title: '',
    key_result_level: '',
    date: '',
    period: '',
    target: '',
    status: '',
    check_in_period: '',
    unit_measurement: '',
    calculation_type: '',
  });

  const [dataForm, setDataForm] = useState({
    key_result_title: data?.key_result_title || '',
    key_result_description: data?.key_result_description || '',
    key_result_level: data?.cmp_id || data?.emp_id || data?.org_id || '',
    status: data?.oos_id || '',
    target: data?.target_value_to || '',
    unit_measurement: data?.otc_id || '',
    calculation_type: data?.calculation_type || '',
    start_date: data?.period_start ? moment(data?.period_start) : null,
    end_date: data?.period_end ? moment(data?.period_end) : null,
    period: !data?.custom_date ? data?.ocp_id : 'custom' || '',
    check_in_period: data?.time_parameter_name || '',
    year: data?.period_start
      ? data?.period_start?.substring(0, 4)
      : new Date().getFullYear(),
  });

  const typeLevel = useRef('');

  const toggle = () => {
    setTooltip(!tooltip);
  };

  const getPeriod = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrobjective/getallperiod/${dataForm?.year}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];
          const yearOpts = [];

          dataResponse.map((item) => {
            arr.push({
              value: item.ocp_id,
              label: item.period_name,
              startDate: item.start_date,
              finishDate: item.finish_date,
            });
          });

          const curYear = new Date().getFullYear();

          // loop options +5 year ahead
          for (let i = -1; i < 4; i++) {
            yearOpts.push({
              value: curYear + i,
              label: `${curYear + i}`,
            });
          }
          setYearOpt(yearOpts);

          // setDataForm((prev) => ({
          //   ...prev,
          //   year: new Date().getFullYear(),
          // }));

          setPeriod([
            ...arr,
            {
              value: 'custom',
              label: 'Custom',
              startDate: null,
              finishDate: null,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatus = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrobjectivestatus/getall`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];

          dataResponse.map((item) => {
            arr.push({
              value: item.oos_id,
              label: item.status_name,
            });
          });

          setStatus(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCheckInPeriod = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrkeyresult/getAllCheckInPeriod`, {}, config)
      .then((response) => {
        let arrWeekly = [];
        let arrMonth = [];
        let dataResponse = response.data.data;
        dataResponse?.map((item) => {
          if (item.time_parameter == 1) {
            arrWeekly.push(item);
          } else {
            arrMonth.push(item);
          }
        });

        const periodOpt = [
          {
            label: 'Weekly',
            options: arrWeekly,
          },
          {
            label: 'Monthly',
            options: arrMonth,
          },
        ];
        setTimePeriod(periodOpt);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssigneList = () => {
    const raw = JSON.stringify({
      emp_id: objective?.emp_id,
      org_id: objective?.org_id,
      objective: false,
    });

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/orgorganization/getEmpAndOrgStucture`, raw, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          // filter level by section
          const employ = response.data?.data?.filter((item) =>
            item.hasOwnProperty('emp_name'),
          );
          const unit = response.data?.data?.filter((item) =>
            item.hasOwnProperty('unit_organization'),
          );

          setOwner([
            {
              label: 'Organization',
              options: unit,
            },
            {
              label: 'Individual',
              options: employ,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUnitMeasurment = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/Okrkeyresult/getUnitMeasurement`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const arr = [];
          response.data?.data?.map((item) => {
            arr.push({
              value: item.otc_id,
              label: item.metric_name,
            });
          });

          setUnitMeasurment(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCalculationType = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/Okrkeyresult/getCalculationType`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          setCalculationType(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKrTitle = (event) => {
    // set value data form
    setDataForm({
      ...dataForm,
      key_result_title: event.target?.value,
    });

    // set error status
    if (event.target?.value == undefined || event.target?.value == '') {
      setError((prev) => ({
        ...prev,
        key_result_title: "Key result title should'nt be empty",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        key_result_title: '',
      }));
    }
  };

  const handleKrDescription = (event) => {
    setDataForm({
      ...dataForm,
      key_result_description: event.target?.value,
    });
  };

  const handleTarget = (event) => {
    if (
      (!event?.target?.value.match(/^-?\d+(\.\d{0,2})?$/) &&
        event?.target?.value.length !== 0) ||
      event?.target?.value.charAt(0) == '0' ||
      event?.target?.value.length == 53
    ) {
      return false;
    }
    // set value data form
    setDataForm({
      ...dataForm,
      target: event.target?.value,
    });

    // set error status
    if (event.target?.value == undefined || event.target?.value == '') {
      setError((prev) => ({
        ...prev,
        target: "Key result target should'nt be empty",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        target: '',
      }));
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();

    if (dataForm.start_date == null || dataForm.end_date == null) {
      setError((prev) => ({ ...prev, date: "Date should'nt be empty" }));
    }

    if (
      dataForm.start_date !== null &&
      dataForm.end_date !== null &&
      (dataForm.start_date?.format('YYYY-MM-DD') >= objective.period_start &&
        dataForm.end_date?.format('YYYY-MM-DD') <= objective.period_end) ==
        false
    ) {
      setError((prev) => ({
        ...prev,
        date: 'Key result date must be inside the objective date',
      }));
    } else if (
      dataForm.end_date?.format('YYYY-MM-DD') <
      dataForm.start_date?.format('YYYY-MM-DD')
    ) {
      setError((prev) => ({
        ...prev,
        date: "End date shouldn't less than Start Date",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        date: '',
      }));
    }

    if (dataForm.key_result_title == '') {
      setError((prev) => ({
        ...prev,
        key_result_title: "Key result title should'nt be empty",
      }));
    }

    if (dataForm.period == '' || dataForm.period == undefined) {
      setError((prev) => ({ ...prev, period: "Period should'nt be empty" }));
    }

    if (dataForm.key_result_level == '') {
      setError((prev) => ({
        ...prev,
        key_result_level: "Key Result level should'nt be empty",
      }));
    }

    if (dataForm.check_in_period == '') {
      setError((prev) => ({
        ...prev,
        check_in_period: "Check in period should'nt be empty",
      }));
    }

    if (dataForm.target == '') {
      setError((prev) => ({
        ...prev,
        target: "Key result target should'nt be empty",
      }));
    }

    if (dataForm.unit_measurement == '') {
      setError((prev) => ({
        ...prev,
        unit_measurement: "Unit of measurement should'nt be empty",
      }));
    }

    if (dataForm.calculation_type == '') {
      setError((prev) => ({
        ...prev,
        calculation_type: "Calculation type should'nt be empty",
      }));
    }

    if (dataForm.status == '') {
      setError((prev) => ({
        ...prev,
        status: "Status should'nt be empty",
      }));
    }

    if (
      (dataForm.start_date?.format('YYYY-MM-DD') >= objective.period_start &&
        dataForm.end_date?.format('YYYY-MM-DD') <= objective.period_end) ==
        false ||
      dataForm.end_date?.format('YYYY-MM-DD') <
        dataForm.start_date?.format('YYYY-MM-DD') ||
      dataForm.key_result_title == '' ||
      dataForm.key_result_level == '' ||
      dataForm.status == '' ||
      dataForm.check_in_period == '' ||
      dataForm.calculation_type == '' ||
      !dataForm.target
    )
      return false;

    formData.append('obj_id', id);
    formData.append('key_result_title', dataForm.key_result_title);
    formData.append('key_result_description', dataForm.key_result_description);
    formData.append('start_date', dataForm.start_date.format('YYYY-MM-DD'));
    formData.append('end_date', dataForm.end_date.format('YYYY-MM-DD'));
    formData.append('oos_id', dataForm.status);
    formData.append('otc_id', dataForm.unit_measurement);
    formData.append('target_value_from', 0);
    formData.append('target_value_to', dataForm.target);
    formData.append('achievement_value', 0);
    formData.append('user_id', ls.get('user_cred').usr_id);
    formData.append(typeLevel.current, dataForm.key_result_level);
    formData.append('check_in_period', dataForm.check_in_period);
    formData.append('calculation_type', dataForm.calculation_type);
    if (dataForm.period) formData.append('ocp_id', dataForm.period);
    if (custom) {
      formData.append(
        'period_name',
        moment(dataForm.start_date).format('YYYY-MM-DD') +
          ' - ' +
          moment(dataForm.end_date).format('YYYY-MM-DD'),
      );
    }

    const url =
      data?.ors_id == undefined
        ? `${auth.apiws}/okrkeyresult/saveItem`
        : `${auth.apiws}/okrkeyresult/updateItem/${data?.ors_id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    if (
      (data.period_start != dataForm.start_date?.format('YYYY-MM-DD') ||
        data.period_end != dataForm.end_date?.format('YYYY-MM-DD')) &&
      data.ors_id
    ) {
      setIsSubmit(true);

      Swal.fire({
        title: 'Are you sure?',
        text: t('LBL.EDIT_OKR_VALIDATE'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          axios
            .post(url, formData, config)
            .then((response) => {
              if (response.data.status == 'ok') {
                showNotifications('Success', 'Submit Submission', 'success');
                reload();
                toggleOffChange('keyResult');
              }
            })
            .catch((error) => {
              showNotifications('Fail', 'Save Failed', 'danger');
            });
        }
      });
    } else {
      setIsSubmit(true);

      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data.status == 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            reload();
            toggleOffChange('keyResult');
          }
        })
        .catch((error) => {
          showNotifications('Fail', 'Save Failed', 'danger');
        });
    }
  };

  useEffect(() => {
    if (dataForm.period == 'custom') {
      setCustom(true);
    } else {
      setCustom(false);
    }
  }, [dataForm.period]);

  useEffect(() => {
    getPeriod(); // get all period
    getStatus(); // get all status
    getAssigneList(); // get assigne list
    getUnitMeasurment(); // get all unit of measurement
    getCalculationType(); // get all calculation type
    getAllCheckInPeriod(); // get checkin period list

    typeLevel.current = data?.cmp_id
      ? 'cmp_id'
      : data?.org_id
      ? 'org_id'
      : data?.emp_id
      ? 'emp_id'
      : '';
  }, []);

  return (
    <>
      <div>
        <Modal size="lg" isOpen={modal} toggle={toggleOffChange}>
          <ModalHeader className="pl-4" toggle={toggleOffChange}>
            {data?.ors_id ? t('LBL.EDITKR') : t('LBL.ADDNEWKR')}
          </ModalHeader>
          <ModalBody>
            <form method="post" name="formkr" id="formkr" className="form">
              <Row className="p-3" id="formObjective">
                {/* key result title */}
                <Col md={6}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.KRTITLE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="key_result_title"
                        component={renderTextInput}
                        maxLength={200}
                        placeholder="Enter Key Result Title"
                        input={{
                          value: dataForm.key_result_title,
                          onChange: handleKrTitle,
                        }}
                        props={{
                          meta: {
                            touched: true,
                            error: error.key_result_title,
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {/* key result description */}
                <Col md={12}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.KRDESC')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="description"
                        component={renderTextInput}
                        placeholder="Enter Description"
                        type="textarea"
                        input={{
                          value: dataForm.key_result_description,
                          onChange: handleKrDescription,
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {/* key result period */}
                <Col md={4}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.KRPERIOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="period"
                        component={renderSelectField}
                        placeholder="Select Key Result Period"
                        options={period}
                        props={{
                          input: {
                            value: dataForm?.period,
                            onChange: (value) => {
                              // console.log(dataForm.year + value?.startDate.substring(4,10));
                              setDataForm((prev) => ({
                                ...prev,
                                period: value?.value || '',
                                start_date: value?.startDate
                                  ? moment(
                                      dataForm.year +
                                        value?.startDate.substring(4, 10),
                                    )
                                  : null,
                                end_date: value?.finishDate
                                  ? moment(
                                      dataForm.year +
                                        value?.finishDate.substring(4, 10),
                                    )
                                  : null,
                              }));

                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  period: "Period should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  period: '',
                                }));
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.period,
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {/* year */}
                <Col md={2}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">{t('LBL.YEAR')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="Year"
                        component={renderSelectField}
                        placeholder="Year"
                        isClearable={false}
                        options={yearOpt}
                        props={{
                          input: {
                            value: dataForm.year,
                            onChange: (val) => {
                              setDataForm((prev) => ({
                                ...prev,
                                year: val.value,
                                start_date: dataForm?.start_date
                                  ? moment(
                                      val.value +
                                        '-' +
                                        dataForm?.start_date?.format('MM-DD'),
                                    )
                                  : null,
                                end_date: dataForm?.end_date
                                  ? moment(
                                      val.value +
                                        '-' +
                                        dataForm?.end_date?.format('MM-DD'),
                                    )
                                  : null,
                              }));
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {/* start date */}
                <Col md={3}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.START_DATE')}
                    </span>
                    <div className="form__form-group-field">
                      <div className="date-picker">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <DatePicker
                            name="start_date"
                            className="form__form-group-datepicker"
                            onChange={(date) => {
                              setDataForm((prev) => ({
                                ...prev,
                                start_date: date,
                              }));

                              if (period?.length > 0) {
                                let breakLoop = false;

                                period.forEach((val, index) => {
                                  if (breakLoop) return;

                                  if (
                                    date?.format('MM-DD') ==
                                      val?.startDate?.substring(5, 10) &&
                                    dataForm?.end_date?.format('MM-DD') ==
                                      val?.finishDate?.substring(5, 10)
                                  ) {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: val?.value,
                                    }));
                                    breakLoop = true;
                                  } else {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: 'custom',
                                    }));
                                  }
                                });
                              }

                              if (
                                date == null ||
                                date == '' ||
                                dataForm?.end_date == null ||
                                dataForm?.end_date == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  date: "Date should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  date: '',
                                }));
                              }
                            }}
                            dateFormat="DD/MM/YYYY"
                            value={dataForm?.start_date}
                            selected={dataForm?.start_date}
                            showMonthDropdown
                            showYearDropdown
                          />
                        </div>
                      </div>
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </Col>

                {/* end date */}
                <Col md={3} id="endDate">
                  <MinusIcon className="date-picker__svg" />

                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.END_DATE')}
                    </span>
                    <div className="form__form-group-field">
                      <div className="date-picker">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <DatePicker
                            name="end_date"
                            className="form__form-group-datepicker"
                            value={dataForm.end_date}
                            onChange={(date) => {
                              setDataForm((prev) => ({
                                ...prev,
                                end_date: date,
                              }));

                              if (period?.length > 0) {
                                let breakLoop = false;

                                period.forEach((val, index) => {
                                  if (breakLoop) return;

                                  if (
                                    date?.format('MM-DD') ==
                                      val?.finishDate?.substring(5, 10) &&
                                    dataForm?.start_date?.format('MM-DD') ==
                                      val?.startDate?.substring(5, 10)
                                  ) {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: val?.value,
                                    }));
                                    breakLoop = true;
                                  } else {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: 'custom',
                                    }));
                                  }
                                });
                              }

                              if (
                                date == null ||
                                date == '' ||
                                dataForm?.start_date == null ||
                                dataForm?.start_date == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  date: "Date should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  date: '',
                                }));
                              }
                            }}
                            dateFormat="DD/MM/YYYY"
                            selected={dataForm?.end_date}
                            showMonthDropdown
                            showYearDropdown
                          />
                          {error.date && (
                            <span className="form__form-group-error">
                              {error.date}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </Col>

                {/* key result status */}
                <Col md={6}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.KRSTATUS')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="status"
                        component={renderSelectField}
                        placeholder="Select Key Result Status"
                        options={status}
                        props={{
                          input: {
                            value: dataForm.status,
                            onChange: (value) => {
                              setDataForm((prev) => ({
                                ...prev,
                                status: value?.value || '',
                              }));

                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  status: "Status should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  status: '',
                                }));
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.status,
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {/* key result assignee */}
                <Col md={6}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.KRASSIGNEE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="key_result_level"
                        component={renderSelectField}
                        placeholder="Select Key Result Assigne"
                        options={owner}
                        props={{
                          input: {
                            value: dataForm.key_result_level,
                            onChange: (value) => {
                              setDataForm((prev) => ({
                                ...prev,
                                key_result_level: value?.value || '',
                              }));

                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  key_result_level:
                                    "Key result level should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  key_result_level: '',
                                }));
                              }

                              if (value?.emp_id) {
                                typeLevel.current = 'emp_id';
                              } else if (value?.org_id) {
                                typeLevel.current = 'org_id';
                              } else if (value?.cmp_id) {
                                typeLevel.current = 'cmp_id';
                              } else {
                                typeLevel.current = '';
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.key_result_level,
                          },
                        }}
                        group="true"
                      />
                    </div>
                  </div>
                </Col>

                {/* Key Result Target */}
                <Col md={6}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.KRTARGET')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="target"
                        textAlign="left"
                        type="number"
                        maxLength={8}
                        component={renderTextInput}
                        placeholder="Enter Key result target"
                        input={{
                          value: dataForm.target,
                          onChange: handleTarget,
                        }}
                        props={{
                          meta: {
                            touched: true,
                            error: error.target,
                          },
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {/* unit of measurement */}
                <Col md={6}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">{t('LBL.UOM')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="unit_measurement"
                        component={renderSelectField}
                        placeholder="Select Unit of Measurment"
                        options={unitMeasurement}
                        props={{
                          input: {
                            value: dataForm.unit_measurement,
                            onChange: (value) => {
                              setDataForm((prev) => ({
                                ...prev,
                                unit_measurement: value?.value || '',
                              }));
                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  unit_measurement:
                                    "Unit of measurement should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  unit_measurement: '',
                                }));
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.unit_measurement,
                          },
                        }}
                        group="true"
                      />
                    </div>
                  </div>
                </Col>

                {/* checkin period */}
                <Col md={6}>
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.CHECKINPERIOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="check_in_period"
                        component={renderSelectField}
                        placeholder="Select Check In Period"
                        options={timePeriod}
                        props={{
                          input: {
                            value: dataForm.check_in_period,
                            onChange: (value) => {
                              setDataForm((prev) => ({
                                ...prev,
                                check_in_period: value?.value || '',
                              }));
                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  check_in_period:
                                    "Check in period should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  check_in_period: '',
                                }));
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.check_in_period,
                          },
                        }}
                        group="true"
                      />
                    </div>
                  </div>
                </Col>

                {/* calculation type */}
                <Col md={6}>
                  <div className="form__form-group">
                    <div className="d-flex">
                      <span class="form__form-group-label">
                        {t('LBL.CALCTYPETITLE')}
                      </span>
                      <div className="ml-1">
                        <img
                          id="PopoverTop"
                          onClick={toggle}
                          src="/img/icon-info.png"
                          alt="info calculation type"
                          style={{ height: '20px' }}
                        />
                        <Popover
                          placement="top"
                          isOpen={tooltip}
                          toggle={toggle}
                          target="PopoverTop"
                        >
                          <PopoverBody>
                            <div>
                              <span className="font-weight-bold mr-1">
                                {t('LBL.SUM')}:
                              </span>
                              {t('LBL.SUMTYPEDESC')}
                            </div>
                            <div>
                              <span className="font-weight-bold mr-1">
                                {t('LBL.AVERAGE')}:
                              </span>
                              {t('LBL.AVERAGETYPEDESC')}
                            </div>
                            <div>
                              <span className="font-weight-bold mr-1">
                                {t('LBL.LATEST')}:
                              </span>
                              {t('LBL.LATESTTYPEDESC')}
                            </div>
                          </PopoverBody>
                        </Popover>
                      </div>
                    </div>
                    <div className="form__form-group-field">
                      <Field
                        name="calculation_type"
                        component={renderSelectField}
                        placeholder="Select Calculation Type"
                        options={calculationType}
                        props={{
                          input: {
                            value: dataForm.calculation_type,
                            onChange: (value) => {
                              setDataForm((prev) => ({
                                ...prev,
                                calculation_type: value?.value || '',
                              }));
                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  calculation_type:
                                    "Calculation type should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  calculation_type: '',
                                }));
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.calculation_type,
                          },
                        }}
                        group="true"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => toggleOffChange('keyResult')}
            >
              {t('LBL.CANCEL')}
            </Button>
            <Button color="primary" onClick={handleSubmit} disabled={isSubmit}>
              {t('FRM.SAVE')}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'formkr',
  enableReinitialize: true,
  // validate,
})(translate('global')(FormKeyResult));
