/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-parens */
/* eslint-disable space-infix-ops */
import React, { PureComponent } from 'react';
// eslint-disable-next-line no-unused-vars
import { Card, CardBody, Col, UncontrolledTooltip, Button, Container, Row } from 'reactstrap';
// import { Bar } from 'react-chartjs-2';
import { translate } from 'react-i18next';
// import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
import ls from 'local-storage';
// import PfmDivision from './Chart/division/divisionrank';
import DetailDivision from './Chart/division/detail';
// import PfmEmp from './Chart/employeeperform/empperformance';
import PfmPhase from './Chart/achievement/achievementrank';


class PerformanceDashboard extends PureComponent {
  static propTypes = {
    //   detailGender: PropTypes.bool.isRequired,
    //   allMenu: PropTypes.bool.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      token: '',
      orgId: [],
      level: 1,
      parent: [0, 0],
      all: true,
      detailgender: false,
      arrUnitOrganization: [],
      arrPeriod: [],
      pfp_id: '',
      org_id: '',
    };
    this.getPeriod = this.getPeriod.bind(this);
    this.getDetailDivision = this.getDetailDivision.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.setOrganization = this.setOrganization.bind(this);
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getPeriod();
      this.getUnitOrganization();
      this.getCurrent();
    });
  }

  getDetailDivision = (detailmenu, allmenu) => {
    this.setState({
      all: allmenu, detailDivision: detailmenu,
    });
    this.getCurrent();
  }

  getCurrent() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/Pfmperiod/getcurrentperiod/`,
      '',
      config,
    ).then((res) => {
      console.log(res.data);
      this.props.initialize({ year: res.data.data[0].pfp_id });
      // const year =
      // this.setState({ total_emp: rpv.total_employee });
      // this.setState({ total_salary: ts });
      // this.setState({ avg_salary: avg });
      // this.setState({ total_tax: tax });
    });
  }

  getPeriod() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/Pfmperiod/getAll/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrPeriod: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pfp_id, label: obj.period_name })
        ));
        this.setState({ arrPeriod: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getUnitOrganization() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${ls.get('ws_ip')}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  setPeriod(event) {
    const val = (typeof event.value === 'undefined') ? '' : event.value;
    this.setState({ pfp_id: val });
  }

  setOrganization(event) {
    const val = (typeof event.value === 'undefined') ? '' : event.value;
    this.setState({ org_id: val });
  }

  render() {
    const { t } = this.props;
    return (
      <Container className="dashboard">
        {(this.state.all === true) && (
          <div style={{ padding: '15px' }}>
            <Card>
              <div className="dashboard__chart chart_border">
                <div className="chart-title">
                  <div className="d-flex align-items-center" />
                </div>
                <div className="row justify-content-md-center">
                  <div className="col-md-2">
                    <Field
                      name="year"
                      component={renderSelectField}
                      placeholder={t('LBL.PERIOD')}
                      options={this.state.arrPeriod}
                      onChange={this.setPeriod}
                    />
                  </div>
                  {/* <div className="col-md-2">
                    <Field
                      name="office"
                      component={renderSelectField}
                      placeholder={t('LBL.UNIT_ORGANIZATION')}
                      options={this.state.arrUnitOrganization}
                      onChange={this.setOrganization}
                    />
                  </div> */}
                </div>
                <hr />
              </div>
            </Card>
            <Row>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Row>
                  {/* <Col md={12} lg={12} sm={12} xs={12}>
                    <PfmDivision pfp_id={this.state.pfp_id} org_id={this.state.org_id} getmenu={this.getDetailDivision} />
                  </Col> */}
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <PfmPhase pfp_id={this.state.pfp_id} org_id={this.state.org_id} />
                  </Col>
                </Row>
              </Col>
              {/* <Col md={6} lg={6} sm={12} xs={12}>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <PfmEmp pfp_id={this.state.pfp_id} org_id={this.state.org_id} />
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </div>
        ) || (
            this.state.detailDivision === true && (
              <DetailDivision pfp_id={this.state.pfp_id} org_id={this.state.org_id} getmenu={this.getDetailDivision} />
            )
          )}
      </Container>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'PfmDashboard',
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
})(translate('global')(PerformanceDashboard));
