/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/extensions */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unused-state,object-shorthand,arrow-parens,comma-dangle,jsx-a11y/anchor-is-valid,no-script-url,react/style-prop-object,no-console,no-trailing-spaces,space-in-parens,space-infix-ops,react/no-unescaped-entities,camelcase,no-plusplus,max-len,quote-props,no-unused-vars */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable-next-line react/sort-comp */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../shared/components/form/Select.jsx';
import showNotifications from '../../Preferences/notificationMessages';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import ws from '../../../shared/components/config/config';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faSearch,
  faUserPlus,
} from '@fortawesome/fontawesome-free-solid';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes, { object } from 'prop-types';
import { withRouter } from 'react-router';
import renderTextInput from '../../../shared/components/form/TextInput';

import Swal from 'sweetalert2';
import '../../Tables/DataTable/assets/css/style.css';

const WAIT_INTERVAL = 1000;
const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class payslipForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // submitPassword: PropTypes.func.isRequired,
    // handleSubmitPassword: PropTypes.func.isRequired,
    // men_id: PropTypes.string.isRequired,
    // fga_id: PropTypes.string.isRequired,
    // location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      arrYear: [],
      rows: [],
      limit: 10, // default is 10 rows
      offset: 0,
      page: 1,
      disable_preview: '',
      disable_next: '',
      modal_show: false,
      search: '',
      total: 0,
      total_pagination: 0,
      total_active: 0,
      current_pagination: 0,
      label_idx_start: 0,
      label_idx_end: 0,
      path_root: '',
      parm_id_usr: 0,
      menu: [],
      year: '',
      addcond: '',
      editcond: '',
      menu2: [],
      maxuser: 0,
      btnAdjsustment: false,
      menIdAdjustment: '',
      btnPromotion: false,
      menIdPromotion: '',
      men_id: '',
      fga_id: '',
      displayLoad: 'none',
      displaySave: '',
      dataMounth: [],
      type: 1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.getYear = this.getYear.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.reload = this.reload.bind(this);
    // this.next = this.next.bind(this);
    // this.setYear = this.setYear.bind(this);
    // this.getReference = this.getReference.bind(this);
    // this.getActiveEmp = this.getActiveEmp.bind(this);
  }

  componentDidMount = () => {
    this.getYear();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.POSITION_NAME'),
      t('LBL.JOB_BAND'),
      t('LBL.JOIN_DATE'),
      t('LBL.STATUS'),
      '#',
    ];
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  isEmpty(obj) {
    return !Object.keys(obj).length;
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  modalPassword(pyp_id, path) {
    const token = ls.get('token');
    const apiws = ls.get('ws_ip');
    let base64String;
    const reader = new FileReader();
    // const urlFile = `${ls.get('ws_ip')}/public/uploads${path}`;
    // reader.readAsDataURL(urlFile);
    // console.log('xxxxxxxx', );
    // reader.onloadend = () => {
    //   // base64String = reader.result;
    // };
    this.setState({
      modalHeader: 'Payslip',
      pyp_id: pyp_id,
      modalContent: path,
    });
    this.toggle();
  }

  handleSubmit(values) {
    console.log(values);
    // const { token } = this.state;
    const token = ls.get('token');
    const apiws = ls.get('ws_ip');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (this.isEmpty(values) === false) {
      this.setState({
        displayTable: 'none',
        displayLoad: '',
        displaySave: 'none',
      });
      // const type = typeof values.type === 'undefined' ? '' : values.type;
      // console.log(type);

      axios
        .get(
          `${apiws}/payslip/getListPayslipId/${values.period_year.value}`,
          config,
        )
        .then((res) => {
          if (res.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ dataMounth: res.data.data });
            this.setState({ displayLoad: 'none', displaySave: '' });
          } else {
            showNotifications('Fail', 'Data not found', 'danger');
            this.setState({ displayLoad: 'none', displaySave: '' });
          }
          // console.log(res);
        });
    }
  }

  submitPassword(values) {
    // console.log('xxxxxxxx', values);
    const token = ls.get('token');
    const apiws = ls.get('ws_ip');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
  }

  render() {
    const { handleSubmit, t } = this.props;
    // console.log(typeof submitPassword);
    const { arrYear, displayLoad, displaySave } = this.state;
    return (
      <Col md={12} lg={12}>
        <Card>
            <div className="container">
              <div className="wrapper">
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Card style={{ paddingLeft: '15px' }}>
                      <CardBody className="p0">
                        <Row className="m5">
                          <Col md={12}>
                            <h3 className="page-title">{t('LBL.PAYSLIP')}</h3>
                            <h3 className="page-subhead subhead">
                              {t('LBL.VIEW')} {t('LBL.PAYSLIP')}{' '}
                            </h3>
                          </Col>
                        </Row>
                        <Row className="m5">
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <form
                              className="form form--horizontal mt15"
                              onSubmit={handleSubmit(this.handleSubmit)}
                              name="filterPeriod"
                              id="filterPeriod"
                              // key={'filterperiod'}
                            >
                              <Container>
                                <Row>
                                  <Col md={6} xs={12} lg={6} xl={6}>
                                    <div className="form__form-group form__form-group--address">
                                      <span className="form__form-group-label">
                                        {t('LBL.PERIOD')}
                                      </span>
                                      <div className="form__form-group-field form-flex">
                                        <Field
                                          name="period_year"
                                          component={renderSelectField}
                                          type="text"
                                          options={arrYear}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12} xs={12} lg={12} xl={12}>
                                    <ButtonToolbar className="form__button-toolbar">
                                      <Button
                                        color="primary"
                                        type="submit"
                                        style={{ display: displaySave }}
                                      >
                                        {t('FRM.SUBMIT')}{' '}
                                      </Button>
                                      <Button 
                                        color="primary"
                                        style={{ display: displayLoad }}
                                      >
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          style={{ marginRight: '6px' }}
                                        />
                                        {t('FRM.LOADING')}
                                      </Button>
                                    </ButtonToolbar>
                                  </Col>
                                </Row>
                              </Container>
                            </form>
                          </Col>
                        </Row>
                        <Row className="m5">
                          <Col md={12} style={{ width: '100%' }}>
                            <div className="table-responsive">
                              <table class="table data-grid-tbl responsive no-wrap">
                                <thead>
                                  <tr>
                                    <th class="col-md-9">Month</th>
                                    <th>#</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    Object.values(this.state.dataMounth).map((value, index) => (
                                      <tr>
                                        <td class="col-md-9">{value.month_name}</td>
                                        <td>
                                          <button class="btn btn-sm btn-outline-primary btn-data-table" id={`view${value.pyp_id}`} type="button" onClick={() => this.modalPassword(value.pyp_id, value.file)}><i class="fas fa-eye" /></button>
                                          <UncontrolledTooltip placement="top" target={`view${value.pyp_id}`}>
                                            {t('LBL.VIEW')}
                                          </UncontrolledTooltip>   
                                        </td>
                                      </tr> 
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
        </Card>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.modalHeader}
          </ModalHeader>
          <ModalBody>
            <Container>
              <iframe
                src={`data:application/pdf;base64, ${this.state.modalContent}`}
                title={this.state.modalHeader}
                width="100%" 
                height={500}
              />
            </Container>
          </ModalBody>
        </Modal>
      </Col>
    );
  }
}

// export default translate('global')(DataTable);

export default reduxForm({
  destroyOnUnmount: false,
  form: 'payslipForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(payslipForm));
