import React, { useEffect, useState } from 'react';
import { Card, CardBody, Input, Row, Col } from 'reactstrap';
import '../../scss/containers/fieldactivity.scss';
import ls from 'local-storage';
import axios from 'axios';
import SearchInput from './FieladActivityUserSearch';

const FieldActivityUserList = ({
  getActivity = () => {},
  getAllActivity = () => {},
}) => {
  const [activeUserId, setActiveUserId] = useState(null);
  const [users, setUser] = useState([]);
  const [search, setSearch] = useState(null);

  const auth = {
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  };

  const handleCardClick = (userId) => {
    setActiveUserId(userId);
    getAllActivity(userId);
    getActivity(userId);
  };

  useEffect(() => {
    if (!activeUserId) {
      handleCardClick(ls.get('user_cred').emp_id);
    }
  }, [activeUserId]);

  const getList = () => {
    let url = `${auth.apiws}/facactivity/getAllEmpSub`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          setTimeout(() => {
            setUser(response.data.data);
          }, 200);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    getList();
  }, []);

  const reload = () => {
    const bodyParameters = {
      query: search,
    };
    let url = `${auth.apiws}/facactivity/getAllEmpSub`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, bodyParameters, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          setTimeout(() => {
            setUser(response.data.data);
          }, 200);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    reload();
  }, [search]);

  return (
    <Card>
      <CardBody className="card-field-activity">
        <SearchInput getSearch={getSearch} />
        <div className="user-card-group">
          {users.map((user) => (
            <div
              className={`user-card ${
                activeUserId === user.emp_id ? 'active' : ''
              }`}
              onClick={() => handleCardClick(user.emp_id)}
              key={user.emp_id}
              style={
                activeUserId === user.emp_id
                  ? { border: '3px solid #37a7ff' }
                  : {}
              }
            >
              <Row>
                <Col md="5" sm="5" lg="3">
                  <div className="user-img-container">
                    <img
                      className="user-act-img"
                      src={`${user.path_photo || 'user-default-photo.png'}`}
                    />
                  </div>
                </Col>
                <Col md="7" sm="7" lg="9">
                  <div className="user-act-title">{user.emp_name}</div>
                  <div className="user-act-subtitle">{user.position_name}</div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default FieldActivityUserList;
