/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus, max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable no-undef */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { Container, Row, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import Spinner from 'react-bootstrap/Spinner';

class ThrPreferences extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      editcond: '',
      addcond: '',
      deletecond: '',
      buttonSave: false,
    };
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.rendermultiply = this.rendermultiply.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillMount() {
    this.props.initialize({ dyn_multiply: [{}] });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ iduser: ls.get('user_cred').usr_id }, () => {
      this.loadSelect();
      this.getButton();
    });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  async loadSelect() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios
      .post(`${apiws}/paythr/getAll`, '', config)
      .then(async (response) => {
        if (response.data.data.length < 1) {
          this.setState({ disabledinput: false });
          this.setState({ statusedit: false });
        } else {
          this.setState({ disabledinput: true });
          this.setState({ statusedit: true });
          const getAfter = response.data.data[0].get_after;
          const proratE = response.data.data[0].prorate;
          const pyhId = response.data.data[0].pyh_id;
          const arr = [];
          const marr = [];
          await axios
            .post(
              `${apiws}/paythrcomponent/getAllItemById/${pyhId}`,
              '',
              config,
            )
            .then(async (rescomponent) => {
              const count_component = rescomponent.data.data.length;
              for (let i = 0; i < count_component; i++) {
                arr.push({
                  value: rescomponent.data.data[i].pyc_id,
                  label: rescomponent.data.data[i].component,
                });
              }
              // this.setState({ pycArr: arr });
            });
          await axios
            .post(
              `${apiws}/paythrmultiplier/getAllItemById/${pyhId}`,
              '',
              config,
            )
            .then(async (resmultiplier) => {
              const rm = resmultiplier.data.data.length;
              if (rm === 0) {
                marr.push({});
              } else {
                for (let i = 0; i < rm; i++) {
                  marr.push({
                    year: resmultiplier.data.data[i].service_year,
                    times: resmultiplier.data.data[i].multiplier_factor,
                  });
                }
              }
            });
          this.props.initialize({
            pyh_id: pyhId,
            get_after: getAfter,
            prorate: proratE,
            pyc_id: arr,
            dyn_multiply: marr,
          });
        }
      });
    axios.post(`${apiws}/paycomponent/getAll`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.pyc_id, label: obj.component }),
        );
        this.setState({ component: Arr });
      }
    });
  }

  onAddForm = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ dynForm: [...prevState.dynForm, ''] }));
  };

  removeForm(i) {
    const dynForm = [...this.state.dynForm];
    dynForm.splice(i, 1);
    this.setState({ dynForm });
  }

  rendermultiply = ({ fields, meta: { error }, disabledinput }) => (
    <div>
      {fields.map((multiply, index) => (
        <Row className="form__form-group">
          <Col md={2} xs={12}>
            <Field
              name={`${multiply}.year`}
              component="input"
              type="number"
              maxLength="4"
              className="form_input_w55"
              disabled={disabledinput}
            />
          </Col>
          <Col md={4} xs={12}>
            <p className="label-form-right" style={{ marginTop: '9px' }}>
              {this.props.t('LBL.YEAR')}, {this.props.t('LBL.MULTIPLEBY')}
            </p>
          </Col>
          <Col md={2} xs={12}>
            <Field
              name={`${multiply}.times`}
              component="input"
              type="number"
              maxLength="4"
              className="form_input_w55"
              disabled={disabledinput}
            />
          </Col>
          <Col md={2} xs={12}>
            <p className="label-form-right" style={{ marginTop: '9px' }}>
              {this.props.t('LBL.TIMES')}
            </p>
          </Col>
          {index === 0 ? (
            <Col md={2} sm={12}>
              <Button
                className="add_btn_identity"
                onClick={() => fields.push()}
                color="primary"
                size="sm"
                disabled={disabledinput}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          ) : (
            <Col md={2} sm={12}>
              <Button
                className="add_btn_identity"
                onClick={() => fields.remove(index)}
                color="danger"
                size="sm"
                disabled={disabledinput}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </Col>
          )}
        </Row>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );

  mapFormData(b, a) {
    const { comparray, yearmultiply, timesmultiply } = this.state;
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (a === 'pyc_id') {
          for (let i = 0; i < Object.keys(b).length; i++) {
            comparray.push(b[i].value);
          }
          val_b = '';
        } else if (a === 'dyn_multiply') {
          for (let i = 0; i < Object.keys(b).length; i++) {
            yearmultiply.push(b[i].year);
            timesmultiply.push(b[i].times);
          }
          val_b = '';
        } else {
          val_b = b.value;
        }
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  handleSubmitInput(values) {
    this.setState({ buttonSave: true });
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const dynMultiply = JSON.stringify(values.dyn_multiply);
    const dynComponent = JSON.stringify(values.pyc_id);
    const prorates =
      typeof values.prorate === 'object'
        ? values.prorate.value
        : values.prorate;
    const data = [
      `get_after=${values.get_after}`,
      `pys_id=${prorates}`,
      `cuser_id=${iduser}`,
      `component=${dynComponent}`,
      `multiply=${dynMultiply}`,
    ].join('&');
    let url;
    if (typeof values.pyh_id === 'undefined') {
      url = `${apiws}/paythr/saveItem`;
    } else {
      url = `${apiws}/paythr/updateItem/${values.pyh_id}`;
    }
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.setState({ buttonSave: false });
        this.handleCancel();
      }
    });
  }

  formatDateTimeNow() {
    const dates = new Date();
    let month = (dates.getMonth() + 1).toString();
    let day = dates.getDate().toString();
    const year = dates.getFullYear();
    let hours = dates.getHours();
    let minutes = dates.getMinutes();
    let seconds = dates.getSeconds();
    if (hours.length < 2) {
      hours = `0${hours}`;
    }
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (minutes.length < 2) {
      minutes = `${minutes}`;
    }
    if (seconds.length < 2) {
      seconds = `${seconds}`;
    }
    const datefix = [year, month, day].join('-');
    const timefix = [hours, minutes, seconds].join(':');
    return [datefix, timefix].join(' ');
  }

  handleEdit() {
    this.setState({ disabledinput: false });
    this.setState({ statusedit: false });
    this.setState({ buttondisabled: false });
  }

  handleCancel() {
    this.props.initialize({ dyn_multiply: [{}] });
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusedit: true });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      component,
      buttondisabled,
      disabledinput,
      statusedit,
      buttonSave,
    } = this.state;
    let button_action;
    if (statusedit) {
      button_action = (
        <Button
          color="success"
          type="button"
          onClick={this.handleEdit}
          style={{ display: this.state.editcond === '1' ? '' : 'none' }}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      if (buttonSave) {
        button_action = (
          <ButtonToolbar>
            <Button
              color="secondary"
              type="button"
              onClick={this.handleCancel}
              style={{ display: this.state.addcond === '1' ? '' : 'none' }}
            >
              {' '}
              {t('FRM.CANCEL')}
            </Button>
            <Button color="primary">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '6px' }}
              />
              Loading...
            </Button>
          </ButtonToolbar>
        );
      } else {
        button_action = (
          <ButtonToolbar>
            <Button
              color="secondary"
              type="button"
              onClick={this.handleCancel}
              style={{ display: this.state.addcond === '1' ? '' : 'none' }}
            >
              {' '}
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color="primary"
              type="submit"
              form="thrforminput"
              disabled={pristine}
            >
              {' '}
              {t('FRM.SAVE')}
            </Button>
          </ButtonToolbar>
        );
      }
    }
    return (
      <Container>
        <Row>
          <Col md={8} xs={12} lg={8} xl={8}>
            <form
              className="form form--horizontal"
              name="thrforminput"
              id="thrforminput"
              onSubmit={handleSubmit(this.handleSubmitInput)}
            >
              <Field name="pyh_id" component={renderTextInput} type="hidden" />
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.EMPLOYEE_GET_THR')}
                </span>
                <div className="form__form-group-field">
                  <Col md={2}>
                    <Field
                      name="get_after"
                      component={renderTextInput}
                      type="number"
                      className="form_input_w55"
                      disabled={disabledinput}
                    />
                  </Col>
                  <Col md={9}>
                    <p
                      className="label-form-right"
                      style={{ marginTop: '5px' }}
                    >
                      {t('LBL.MONTH')}
                    </p>
                  </Col>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.PRORATE')}{' '}
                </span>
                <div className="form__form-group-field">
                  <Col md={12}>
                    <Field
                      name="prorate"
                      component={renderSelectField}
                      type="text"
                      placeholder="----"
                      disabled={disabledinput}
                      options={[
                        { value: '1', label: `${t('LBL.MONTH')} (/12)` },
                        { value: '2', label: `${t('LBL.DAYS')} (/360)` },
                      ]}
                    />
                  </Col>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.COMPONENT')}{' '}
                </span>
                <div className="form__form-group-field">
                  <Col md={12}>
                    <Field
                      name="pyc_id"
                      component={renderSelectField}
                      placeholder="----"
                      options={component}
                      className='custom-multi'
                      multi="true"
                      disabled={disabledinput}
                    />
                  </Col>
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ 'margin-top': '10px' }}
                >
                  {t('LBL.MULTIPLIER_SETTING')}
                </span>
                <div className="form__form-group-field form-group-mb15">
                  <div className="form-container">
                    <Col md={12}>
                      <FieldArray
                        name="dyn_multiply"
                        component={this.rendermultiply}
                        props={{ disabledinput: this.state.disabledinput }}
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{ marginLeft: '185px' }}
              >
                {button_action}
              </ButtonToolbar>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.get_after) {
    errors.get_after = 'get THR after field shouldn’t be empty';
  }
  if (!values.prorate) {
    errors.prorate = 'Prorate field shouldn’t be empty';
  }
  if (!values.pyc_id) {
    errors.pyc_id = 'Comonent THR field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'paythrsettingform',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(ThrPreferences));
