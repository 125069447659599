import React, { useEffect, useRef, useState } from 'react';
import renderSelectField from '../../shared/components/form/Select';
import renderTextInput from '../../shared/components/form/TextInput';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import './okr.css';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../Preferences/notificationMessages';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const FormObjective = ({
  isObjCompany,
  modal,
  toggleOffChange,
  data,
  reload,
  t,
}) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [isSubmit, setIsSubmit] = useState(false);
  const [custom, setCustom] = useState(false);
  const [alignment, setAlignment] = useState([]);
  const [period, setPeriod] = useState([]);
  const [status, setStatus] = useState([]);
  const [owner, setOwner] = useState([]);
  const [error, setError] = useState({
    objective_title: '',
    objective_level: '',
    date: '',
    period: '',
    status: '',
  });
  const [yearOpt, setYearOpt] = useState([]);

  const [dataForm, setDataForm] = useState({
    align_to: data?.align_to || '',
    objective_title: data?.objective_title || '',
    objective_description: data?.objective_description || '',
    objective_level: data?.cmp_id || data?.emp_id || data?.org_id || '',
    status: data?.oos_id || '',
    start_date: data?.period_start ? moment(data?.period_start) : null,
    end_date: data?.period_end ? moment(data?.period_end) : null,
    period: !data?.custom_date ? data?.ocp_id : 'custom' || '',
    year: data?.period_start
      ? data?.period_start?.substring(0, 4)
      : new Date().getFullYear(),
  });

  const typeLevel = useRef('');

  const getAlignment = () => {
    const raw = JSON.stringify({
      obj_id: data?.obj_id,
    });

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/Okrobjective/getAlignment`, raw, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arrCmp = [];
          const arrOrg = [];
          const arrEmp = [];

          const options = [
            {
              label: 'Company',
              options: arrCmp,
            },
            {
              label: 'Organization',
              options: arrOrg,
            },
            {
              label: 'Individual',
              options: arrEmp,
            },
          ];
          dataResponse.map((item) => {
            if (item.obj_id !== data?.obj_id) {
              if (item.emp_id) {
                arrEmp.push({
                  value: item.obj_id,
                  label: item.objective_title,
                });
              } else if (item.cmp_id) {
                arrCmp.push({
                  value: item.obj_id,
                  label: item.objective_title,
                });
              } else {
                arrOrg.push({
                  value: item.obj_id,
                  label: item.objective_title,
                });
              }
            }
          });

          setAlignment(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPeriod = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrobjective/getallperiod/${dataForm?.year}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];
          const yearOpts = [];

          dataResponse.map((item) => {
            arr.push({
              value: item.ocp_id,
              label: item.period_name,
              startDate: item.start_date,
              finishDate: item.finish_date,
            });
          });

          const curYear = new Date().getFullYear();

          // loop options +5 year ahead
          for (let i = -1; i < 4; i++) {
            yearOpts.push({
              value: curYear + i,
              label: `${curYear + i}`,
            });
          }
          setYearOpt(yearOpts);

          setPeriod([
            ...arr,
            {
              value: 'custom',
              label: 'Custom',
              startDate: null,
              finishDate: null,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatus = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrobjectivestatus/getall`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];

          dataResponse.map((item) => {
            arr.push({
              value: item.oos_id,
              label: item.status_name,
            });
          });

          setStatus(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getObjectiveLevel = () => {
    const raw = JSON.stringify({
      emp_id: ls.get('user_cred').emp_id,
      org_id: null,
      objective: true,
    });

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/orgorganization/getEmpAndOrgStucture`, raw, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          // filter level by section
          const company = response.data?.data?.filter((item) =>
            item.hasOwnProperty('company'),
          );
          const employ = response.data?.data?.filter((item) =>
            item.hasOwnProperty('emp_name'),
          );
          const unit = response.data?.data?.filter((item) =>
            item.hasOwnProperty('unit_organization'),
          );

          setOwner([
            {
              label: 'Company',
              options: company,
            },
            {
              label: 'Organization',
              options: unit,
            },
            {
              label: 'Individual',
              options: employ,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleObjTitle = (event) => {
    // set value data form
    setDataForm({
      ...dataForm,
      objective_title: event.target?.value,
    });

    // set error status
    if (event.target?.value == undefined || event.target?.value == '') {
      setError((prev) => ({
        ...prev,
        objective_title: "Objective title should'nt be empty",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        objective_title: '',
      }));
    }
  };

  const handleObjDescription = (event) => {
    setDataForm({
      ...dataForm,
      objective_description: event.target?.value,
    });
  };

  const handleSubmit = () => {
    // state dataform
    const formData = new FormData();

    if (dataForm.start_date == '' || (dataForm.end_date == '' && custom)) {
      setError((prev) => ({ ...prev, date: "Date should'nt be empty" }));
    }

    if (
      dataForm.end_date?.format('YYYY-MM-DD') <
      dataForm.start_date?.format('YYYY-MM-DD')
    ) {
      setError((prev) => ({
        ...prev,
        date: "End date shouldn't less than Start Date",
      }));
    }

    if (dataForm.objective_title == '') {
      setError((prev) => ({
        ...prev,
        objective_title: "Objective title should'nt be empty",
      }));
    }

    if (dataForm.period == '' || dataForm.period == undefined) {
      setError((prev) => ({ ...prev, period: "Period should'nt be empty" }));
    }

    if (dataForm.objective_level == '') {
      setError((prev) => ({
        ...prev,
        objective_level: "Objective level should'nt be empty",
      }));
    }

    if (dataForm.status == '') {
      setError((prev) => ({
        ...prev,
        status: "Status should'nt be empty",
      }));
    }

    if (
      ((dataForm.start_date == '' || dataForm.end_date == '') && custom) ||
      (dataForm.period == '' && !custom) ||
      dataForm.end_date?.format('YYYY-MM-DD') <
        dataForm.start_date?.format('YYYY-MM-DD') ||
      dataForm.objective_title == '' ||
      dataForm.objective_level == '' ||
      dataForm.status == ''
    )
      return false;

    setIsSubmit(true);

    formData.append('user_id', ls.get('user_cred').usr_id);
    formData.append('objective_title', dataForm.objective_title);
    formData.append('objective_description', dataForm.objective_description);
    formData.append('start_date', dataForm.start_date.format('YYYY-MM-DD'));
    formData.append('end_date', dataForm.end_date.format('YYYY-MM-DD'));
    formData.append('oos_id', dataForm.status);
    formData.append(typeLevel.current, dataForm.objective_level);
    formData.append('align_to', dataForm.align_to);
    if (dataForm.period) formData.append('ocp_id', dataForm.period);
    if (custom) {
      formData.append(
        'period_name',
        moment(dataForm.start_date).format('YYYY-MM-DD') +
          ' - ' +
          moment(dataForm.end_date).format('YYYY-MM-DD'),
      );
    }

    const url =
      data == undefined
        ? `${auth.apiws}/okrobjective/saveItem`
        : `${auth.apiws}/okrobjective/updateItem/${data?.obj_id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          showNotifications('Success', 'Submit Submission', 'success');
          reload();
          toggleOffChange();
        }
      })
      .catch((error) => {
        showNotifications('Fail', 'Save Failed', 'danger');
      });
  };

  useEffect(() => {
    getAlignment();
    // get status list
    getStatus();
    // get objective level
    getObjectiveLevel();
    // get period list
    getPeriod();

    // set type objective level if edit modal
    typeLevel.current = data?.cmp_id
      ? 'cmp_id'
      : data?.org_id
      ? 'org_id'
      : data?.emp_id
      ? 'emp_id'
      : '';
  }, []);

  useEffect(() => {
    if (dataForm.period == 'custom') {
      setCustom(true);
    } else {
      setCustom(false);
    }
  }, [dataForm.period]);

  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={toggleOffChange}>
        <ModalHeader className="pl-4" toggle={toggleOffChange}>
          {data?.obj_id ? t('LBL.EDITOBJ') : t('LBL.ADDNEWOBJ')}
        </ModalHeader>
        <ModalBody>
          <form method="post" id="formobj" name="formobj" className="form">
            <Row className="p-3" id="formObjective">
              <Col md={6}>
                <div className="form__form-group">
                  <span class="form__form-group-label">{t('LBL.ALIGN')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="align_to"
                      component={renderSelectField}
                      placeholder="Select Align"
                      options={alignment}
                      group="true"
                      props={{
                        input: {
                          value: dataForm.align_to,
                          onChange: (value) => {
                            // set value data form
                            setDataForm((prev) => ({
                              ...prev,
                              align_to: value?.value,
                            }));
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span class="form__form-group-label">
                    {t('LBL.OBJTITLE')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="objective_title"
                      component={renderTextInput}
                      placeholder="Enter Title"
                      maxLength={200}
                      type="text"
                      input={{
                        value: dataForm.objective_title,
                        onChange: handleObjTitle,
                      }}
                      props={{
                        meta: {
                          touched: true,
                          error: error.objective_title,
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group">
                  <span class="form__form-group-label">{t('LBL.OBJDESC')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="objective_description"
                      component={renderTextInput}
                      placeholder="Enter Description"
                      type="textarea"
                      input={{
                        value: dataForm.objective_description,
                        onChange: handleObjDescription,
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="form__form-group">
                  <span class="form__form-group-label">
                    {t('LBL.OBJPERIOD')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="period"
                      component={renderSelectField}
                      placeholder="Select Period"
                      options={period}
                      props={{
                        input: {
                          value: dataForm?.period,
                          onChange: (value) => {
                            setDataForm((prev) => ({
                              ...prev,
                              period: value?.value || '',
                              start_date: value?.startDate
                                ? moment(
                                    dataForm.year +
                                      value?.startDate.substring(4, 10),
                                  )
                                : null,
                              end_date: value?.finishDate
                                ? moment(
                                    dataForm.year +
                                      value?.finishDate.substring(4, 10),
                                  )
                                : null,
                            }));

                            if (
                              value?.value == undefined ||
                              value?.value == ''
                            ) {
                              setError((prev) => ({
                                ...prev,
                                period: "Period should'nt be empty",
                              }));
                            } else {
                              setError((prev) => ({
                                ...prev,
                                period: '',
                              }));
                            }
                          },
                        },
                        meta: {
                          touched: true,
                          error: error.period,
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <div className="form__form-group">
                  <span class="form__form-group-label">{t('LBL.YEAR')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="Year"
                      component={renderSelectField}
                      placeholder="Year"
                      isClearable={false}
                      options={yearOpt}
                      props={{
                        input: {
                          value: dataForm.year,
                          onChange: (val) => {
                            setDataForm((prev) => ({
                              ...prev,
                              year: val.value,
                              start_date: dataForm?.start_date
                                ? moment(
                                    val.value +
                                      '-' +
                                      dataForm?.start_date?.format('MM-DD'),
                                  )
                                : null,
                              end_date: dataForm?.end_date
                                ? moment(
                                    val.value +
                                      '-' +
                                      dataForm?.end_date?.format('MM-DD'),
                                  )
                                : null,
                            }));
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="form">
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.START_DATE')}
                    </span>
                    <div className="form__form-group-field">
                      <div className="date-picker">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <DatePicker
                            name="start_date"
                            className="form__form-group-datepicker"
                            value={dataForm?.start_date}
                            onChange={(date) => {
                              setDataForm((prev) => ({
                                ...prev,
                                start_date: date,
                              }));

                              if (period?.length > 0) {
                                let breakLoop = false;

                                period.forEach((val, index) => {
                                  if (breakLoop) return;

                                  if (
                                    date?.format('MM-DD') ==
                                      val?.startDate?.substring(5, 10) &&
                                    dataForm?.end_date?.format('MM-DD') ==
                                      val?.finishDate?.substring(5, 10)
                                  ) {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: val?.value,
                                    }));
                                    breakLoop = true;
                                  } else {
                                    console.log('EHEEE');
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: 'custom',
                                    }));
                                  }
                                });
                              }

                              if (
                                date == null ||
                                date == '' ||
                                dataForm?.end_date == null ||
                                dataForm?.end_date == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  date: "Date should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  date: '',
                                }));
                              }
                            }}
                            dateFormat="DD/MM/YYYY"
                            selected={dataForm?.start_date}
                            showMonthDropdown
                            showYearDropdown
                          />
                        </div>
                      </div>
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={3}>
                <MinusIcon className="date-picker__svg" />

                <div className="form">
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                      {t('LBL.END_DATE')}
                    </span>
                    <div className="form__form-group-field">
                      <div className="date-picker">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <DatePicker
                            name="end_date"
                            className="form__form-group-datepicker"
                            value={dataForm.end_date}
                            onChange={(date) => {
                              setDataForm((prev) => ({
                                ...prev,
                                end_date: date,
                              }));

                              if (period?.length > 0) {
                                let breakLoop = false;

                                period.forEach((val, index) => {
                                  if (breakLoop) return;

                                  if (
                                    date?.format('MM-DD') ==
                                      val?.finishDate?.substring(5, 10) &&
                                    dataForm?.start_date?.format('MM-DD') ==
                                      val?.startDate?.substring(5, 10)
                                  ) {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: val?.value,
                                    }));
                                    breakLoop = true;
                                  } else {
                                    setDataForm((prev) => ({
                                      ...prev,
                                      period: 'custom',
                                    }));
                                  }
                                });
                              }

                              if (
                                date == null ||
                                date == '' ||
                                dataForm?.start_date == null ||
                                dataForm?.start_date == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  date: "Date should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  date: '',
                                }));
                              }
                            }}
                            dateFormat="DD/MM/YYYY"
                            selected={dataForm.end_date}
                            showMonthDropdown
                            showYearDropdown
                          />
                          {error.date && (
                            <span className="form__form-group-error">
                              {error.date}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span class="form__form-group-label">
                    {t('LBL.OBJSTATUS')}
                  </span>
                  <div className="form__form-group-field">
                    {status && (
                      <Field
                        name="status"
                        component={renderSelectField}
                        placeholder="Select Status"
                        options={status}
                        props={{
                          input: {
                            value: dataForm.status,
                            onChange: (value) => {
                              setDataForm((prev) => ({
                                ...prev,
                                status: value?.value || '',
                              }));

                              if (
                                value?.value == undefined ||
                                value?.value == ''
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  status: "Status should'nt be empty",
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  status: '',
                                }));
                              }
                            },
                          },
                          meta: {
                            touched: true,
                            error: error.status,
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="form__form-group">
                  <span class="form__form-group-label">{t('LBL.OBJLVL')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="objective_level"
                      component={renderSelectField}
                      placeholder="Select Level"
                      options={owner}
                      group="true"
                      props={{
                        input: {
                          value: dataForm.objective_level,
                          onChange: (value) => {
                            setDataForm((prev) => ({
                              ...prev,
                              objective_level: value?.value || '',
                            }));

                            if (
                              value?.value == undefined ||
                              value?.value == ''
                            ) {
                              setError((prev) => ({
                                ...prev,
                                objective_level:
                                  "Objective level should'nt be empty",
                              }));
                            } else {
                              setError((prev) => ({
                                ...prev,
                                objective_level: '',
                              }));
                            }

                            if (value?.emp_id) {
                              typeLevel.current = 'emp_id';
                            } else if (value?.org_id) {
                              typeLevel.current = 'org_id';
                            } else if (value?.cmp_id) {
                              typeLevel.current = 'cmp_id';
                            } else {
                              typeLevel.current = '';
                            }
                          },
                        },
                        meta: {
                          touched: true,
                          error: error.objective_level,
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" type="button" onClick={toggleOffChange}>
            {t('LBL.CANCEL')}
          </Button>
          <Button color="primary" onClick={handleSubmit} disabled={isSubmit}>
            {t('FRM.SAVE')}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default reduxForm({
  form: 'form-objective',
  destroyOnUnmount: true,
  // validate,
})(translate('global')(FormObjective));
