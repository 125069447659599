/*
 * Page Add Employee Current in Period Setting
 * Notes:
 * @author Gerdi
 * @date 02/11/2019
 * Modification (please note below)
*/
/* eslint-disable no-lonely-if, max-len */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../Preferences/notificationMessages';
// import Swal from 'sweetalert2';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../../shared/components/form/Select';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';

class PeriodAddEmployee extends PureComponent {
  static propTypes = {
    // initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pypidParam: PropTypes.func.isRequired,
    empidParam: PropTypes.func.isRequired,
    buttonDisabled: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      pypid: '',
      modal: false,
      btnDisabled: false,
      offIdFrom: '',
      posIdFrom: '',
      orgIdFrom: '',
      jobIdFrom: '',
      jbdIdFrom: '',
      jbnIdFrom: '',
      jgdIdFrom: '',
      // jbgIdFrom: '',
      offIdTo: '',
      posIdTo: '',
      orgIdTo: '',
      jobIdTo: '',
      jbdIdTo: '',
      jbnIdTo: '',
      jgdIdTo: '',
      jbgIdTo: '',
      dataUpdated: true,
    };
    // this.getUpdated = this.getUpdated.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      // this.getUpdated();
      this.getPayPeriod();
      this.getEmpPeriod();
      this.props.buttonDisabled(false);
    });
  }

  getPayPeriod() {
    const { empidParam, pypidParam } = this.props;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/payemployeecurrent/getAllItemById/${empidParam}`,
      `pyp_id=${pypidParam}`,
      config,
    ).then((res) => {
      const bpjsTK = (res.data.data[0].bpjs_tk_registered === '1') ? 'Registered' : 'Unregistered';
      const bpjsKS = (res.data.data[0].bpjs_ks_registered === '1') ? 'Registered' : 'Unregistered';
      const resignDate = (res.data.data[0].resign_date === null || res.data.data[0].resign_date === 'null' || res.data.data[0].resign_date === '') ? '' : moment(res.data.data[0].resign_date).format('DD/MM/YYYY');
      this.setState({
        offIdFrom: res.data.data[0].office,
        posIdFrom: res.data.data[0].position_name,
        orgIdFrom: res.data.data[0].unit_organization,
        jbdIdFrom: res.data.data[0].job_band,
        jbnIdFrom: res.data.data[0].job_name,
        jgdIdFrom: res.data.data[0].job_grade,
        npwpFrom: res.data.data[0].npwp,
        bpjstkFrom: bpjsTK,
        bpjsksFrom: bpjsKS,
        taxMethodFrom: res.data.data[0].tax_method,
        taxStatusFrom: res.data.data[0].tax_status,
        atsnameFrom: res.data.data[0].ats_name,
        joinDateFrom: moment(res.data.data[0].join_date).format('DD/MM/YYYY'),
        resignDateFrom: resignDate,
        // jobIdFrom: res.data.data[0].job_level,
        // jbgIdFrom: res.data.data[0].job_band_grade,
      });
    });
  }

  getEmpPeriod() {
    const { empidParam } = this.props;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/employee/getAllItemByIdCurrent/${empidParam}`,
      '',
      config,
    ).then((res) => {
      const bpjsTK = (res.data.data[0].bpjs_tk_registered === '1') ? 'Registered' : 'Unregistered';
      const bpjsKS = (res.data.data[0].bpjs_ks_registered === '1') ? 'Registered' : 'Unregistered';
      const resignDate = (res.data.data[0].resign_date === null || res.data.data[0].resign_date === 'null' || res.data.data[0].resign_date === '') ? '' : moment(res.data.data[0].resign_date).format('DD/MM/YYYY');
      this.setState({
        empname: res.data.data[0].emp_name,
        empcode: res.data.data[0].emp_code,
        offIdTo: res.data.data[0].office,
        posIdTo: res.data.data[0].position_name,
        orgIdTo: res.data.data[0].unit_organization,
        jbdIdTo: res.data.data[0].job_band,
        jbnIdTo: res.data.data[0].job_name,
        jgdIdTo: res.data.data[0].job_grade,
        npwpTo: res.data.data[0].npwp,
        bpjstkTo: bpjsTK,
        bpjsksTo: bpjsKS,
        taxMethodTo: res.data.data[0].tax_method,
        taxStatusTo: res.data.data[0].tax_status,
        atsnameTo: res.data.data[0].ats_name,
        joinDateTo: moment(res.data.data[0].join_date).format('DD/MM/YYYY'),
        resignDateTo: resignDate,
        // jobIdTo: res.data.data[0].job_title,
        // jbgIdTo: res.data.data[0].job_band_grade,
      });
    });
  }

  // getUpdated() {
  //   const { empidParam, pypidParam } = this.props;
  //   const config = {
  //     headers: {
  //       'Authorization': `bearer ${ls.get('token')}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   axios.post(
  //     `${ls.get('ws_ip')}/payemployeecurrent/getUpdateDataEmp/${empidParam}/${pypidParam}`,
  //     '',
  //     config,
  //   ).then((res) => {
  //     if (res.data.status === 'ok') {
  //       const countData = res.data.data.length;
  //       if (countData === 0) {
  //         this.props.buttonDisabled(true);
  //         this.setState({ dataUpdated: false });
  //       } else {
  //         this.setState({ dataUpdated: true });
  //         this.props.buttonDisabled(false);
  //       }
  //     }
  //   });
  // }

  render() {
    const { t } = this.props;
    const {
      empname,
      empcode,
      offIdFrom,
      posIdFrom,
      orgIdFrom,
      jbdIdFrom,
      jobIdFrom,
      jbnIdFrom,
      jgdIdFrom,
      npwpFrom,
      bpjsksFrom,
      bpjstkFrom,
      taxMethodFrom,
      taxStatusFrom,
      atsnameFrom,
      joinDateFrom,
      resignDateFrom,
      // jbgIdFrom,
      offIdTo,
      posIdTo,
      orgIdTo,
      jbdIdTo,
      jobIdTo,
      jbnIdTo,
      jgdIdTo,
      jbgIdTo,
      dataUpdated,
      npwpTo,
      bpjsksTo,
      bpjstkTo,
      taxMethodTo,
      taxStatusTo,
      atsnameTo,
      joinDateTo,
      resignDateTo,
    } = this.state;
    let textHead;
    let hideRow;
    if (dataUpdated) {
      textHead = t('FRM.FROM');
      hideRow = '';
    } else {
      textHead = t('LBL.DETAIL');
      hideRow = 'none';
    }
    return (
      <Container>
        <Row>
          <table
            id="myTable"
            className="table data-grid-tbl responsive no-wrap"
          >
            <thead style={{ width: '100%' }}>
              <th colSpan="3" >{empname} ({empcode})</th>
            </thead>
            <thead>
              <th>{t('LBL.DATA')}</th>
              <th>{textHead}</th>
              <th style={{ display: hideRow }}>To</th>
            </thead>
            <tbody>
              <tr>
                <td>NPWP</td>
                <td>{npwpFrom}</td>
                <td style={{ display: hideRow }}>{npwpTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.BPJS_KETENAGAKERJAAN')}</td>
                <td>{bpjstkFrom}</td>
                <td style={{ display: hideRow }}>{bpjstkTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.BPJS_KESEHATAN')}</td>
                <td>{bpjsksFrom}</td>
                <td style={{ display: hideRow }}>{bpjsksTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.TAX_METHOD')}</td>
                <td>{taxMethodFrom}</td>
                <td style={{ display: hideRow }}>{taxMethodTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.TAX_STATUS')}</td>
                <td>{taxStatusFrom}</td>
                <td style={{ display: hideRow }}>{taxStatusTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.ATTENDANCE')}</td>
                <td>{atsnameFrom}</td>
                <td style={{ display: hideRow }}>{atsnameTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.JOIN_DATE')}</td>
                <td>{joinDateFrom}</td>
                <td style={{ display: hideRow }}>{joinDateTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.RESIGN_DATE')}</td>
                <td>{resignDateFrom}</td>
                <td style={{ display: hideRow }}>{resignDateTo}</td>
              </tr>
              <tr>
                <td style={{ width: '30%' }}>{t('LBL.OFFICE')}</td>
                <td>{offIdFrom}</td>
                <td style={{ display: hideRow }}>{offIdTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.ORGANIZATION')}</td>
                <td>{orgIdFrom}</td>
                <td style={{ display: hideRow }}>{orgIdTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.JOB_NAME')}</td>
                <td>{jbnIdFrom}</td>
                <td style={{ display: hideRow }}>{jbnIdTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.POSITION')}</td>
                <td>{posIdFrom}</td>
                <td style={{ display: hideRow }}>{posIdTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.JOB_BAND')}</td>
                <td>{jbdIdFrom}</td>
                <td style={{ display: hideRow }}>{jbdIdTo}</td>
              </tr>
              <tr>
                <td>{t('LBL.JOB_GRADE')}</td>
                <td>{jgdIdFrom}</td>
                <td style={{ display: hideRow }}>{jgdIdTo}</td>
              </tr>
            </tbody>
          </table>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'FormEmployeePeriode',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(PeriodAddEmployee));
