/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import SortableTree from 'react-sortable-tree';
import PropTypes from 'prop-types';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../notificationMessages';
import SweetAlert from 'react-bootstrap-sweetalert';

class unitOrganizationSetting extends PureComponent {
  static propTypes = {
    dataTree: PropTypes.func.isRequired,
    editMode: PropTypes.func.isRequired,
    loadTree: PropTypes.func.isRequired,
    updateTree: PropTypes.func.isRequired,
    fullMode: PropTypes.func.isRequired,
    deletecond: PropTypes.func.isRequired,
    editcond: PropTypes.func.isRequired,
    // deleteMode: PropTypes.func.isRequired,
    // deleteToggle: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    // eslint-disable-next-line no-unused-vars
    const datarr = [{
      org_id: 2,
      title: 'Information Technology',
      expanded: true,
      org_id_parent: 0,
      org_order: 1,
      children: [{
        org_id: 5, title: 'Research and Development', expanded: true, org_id_parent: 2, org_order: 1,
      }, {
        org_id: 6, title: 'HRD', expanded: true, org_id_parent: 2, org_order: 2,
      }, {
        org_id: 7, title: 'Sales', expanded: true, org_id_parent: 2, org_order: 3,
      }, {
        org_id: 8, title: 'Product Division', expanded: true, org_id_parent: 2, org_order: 4,
      }, {
        org_id: 9, title: 'Implementor Division', expanded: true, org_id_parent: 2, org_order: 5,
      }],
    }];
    // const datarr2 = [{
    // eslint-disable-next-line max-len
    //   org_id: 2, title: 'Information Technology', expanded: true, children: [{ org_id: 5, title: 'Research and Development', expanded: true }, { org_id: 6, title: 'HRD', expanded: true }, { org_id: 7, title: 'Sales', expanded: true }, { org_id: 8, title: 'Product Division', expanded: true }, { org_id: 9, title: 'Implementor Division', expanded: true }],
    // }];
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      treeData: [],
      token: '',
      apiws: '',
      deleteConfirm: false,
      deleteId: '',
      virttree: true,
      heighttree: '600px',

    };
    this.deleteItem = this.deleteItem.bind(this);
    this.viewfulltree = this.viewfulltree.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(props, state) {
    if (props.dataTree !== state.treeData) {
      return {
        treeData: props.dataTree,
      };
    }

    return null;
  }

  componentDidMount = () => {
    const { fullMode } = this.props;
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    if (fullMode) {
      this.viewfulltree();
    }
  }

  // componentWillReceiveProps() {
  //   const { deleteToggle } = this.props;
  //   alert(deleteToggle);
  //   if (deleteToggle) {
  //     this.setState({ deleteConfirm: false });
  //   }
  // }

  componentDidUpdate = () => {
    const { dataTree } = this.props;
    this.setState({ treeData: dataTree });
  }

  deleteItem(id) {
    this.setState({ deleteConfirm: true, deleteId: id });
  }

  deleteMode() {
    // this.setState({ deleteConfirm: true, deleteId: id });
    const userid = ls.get('user_cred').usr_id;
    const { deleteId } = this.state;
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/menu/deleteitem/${deleteId}`,
      `duser_id=${userid}`,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        // console.log(response);
        showNotifications('Success', 'Deleted', 'success');
        // this.props.deleteMode();
        this.props.loadTree();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } else if (response.data.status === 'child') {
        showNotifications('Fail', 'Data is a parent, delete the child first!', 'danger');
      } else {
        showNotifications('Fail', 'Delete Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
      showNotifications('Fail', 'Delete Failed', 'danger');
      // this.setState({ button_disabled: false });
    });
    this.setState({ deleteConfirm: false });
  }

  viewfulltree() {
    this.setState({ virttree: false, heighttree: 'auto' });
  }

  render() {
    const {
      treeData, deleteConfirm, virttree, heighttree,
    } = this.state;
    const { editMode, updateTree } = this.props;

    return (
      <Card className="treeMenu_card">
        <CardBody style={{ padding: '20px 25px 30px 20px', height: heighttree }}>
          <SweetAlert
            warning
            show={deleteConfirm}
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => this.deleteMode()}
            onCancel={() => this.setState({ deleteConfirm: false })}
          >
            You will delete this item !
          </SweetAlert>
          <SortableTree
            isVirtualized={virttree}
            treeData={treeData}
            rowHeight={50}
            onChange={treedata => updateTree(treedata)}
            generateNodeProps={rowInfo => ({
              buttons: [
                <div>
                  <button
                    className="btn btn-outline-success btn-sm"
                    style={{
                      verticalAlign: 'middle',
                      padding: '0px 8px',
                      marginBottom: '0px',
                      marginRight: '5px',
                      paddingRight: '3px',
                      display: this.props.editcond === '1' ? '' : 'none',
                    }}
                    onClick={() => editMode(rowInfo.node.men_id)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button
                    className="btn btn-outline-danger btn-sm"
                    style={{
                      verticalAlign: 'middle',
                      padding: '0px 8px',
                      marginBottom: '0px',
                      marginRight: '5px',
                      paddingRight: '3px',
                      display: this.props.deletecond === '1' ? '' : 'none',
                    }}
                    // onClick={() => this.deleteMode(rowInfo.node.org_id)}
                    onClick={() => this.deleteItem(rowInfo.node.men_id)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>,
              ],
            })}
          />
        </CardBody>
      </Card>
    );
  }
}

export default translate('global')(unitOrganizationSetting);
