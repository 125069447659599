/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../../../shared/components/form/Select';
import renderTextInput from '../../../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import showNotifications from '../../../../notificationMessages';
import ViewListTable from '../../../../../../shared/components/table/viewListTable';
import renderRadioButtonField from '../../../../../../shared/components/form/RadioButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import SunEditor from 'suneditor-react';

// let compthr = '';
class Workflowactivity extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    setparam: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    getChecklist: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      wfl_id: '',
      multiplierArr: [],
      dataWidth: ['5%', '20%', '20%', '20%', '10%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      arrEmp: [],
      // displayPos: false,
      // wfa_actor: false,
      slcunit: [],
      bodyemail: '',
      arrDistribution: [
        { value: '1', label: 'Atasan Langsung' },
        { value: '2', label: 'Atasan Tidak Langsung' },
        { value: '3', label: 'Position' },
        // { value: '4', label: 'Custom' },
        { value: '5', label: 'Case Owner' },
      ],
      sunEditorButtonList: [
        ['bold', 'underline', 'italic'],
        ['outdent', 'indent', 'align'],
        ['list'],
        // ['save', 'template']
      ],
    };
    const { t } = props;

    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.NAME'),
      t('LBL.POSITION'),
      t('LBL.TYPE'),
      t('LBL.DISTRIBUTION'),
      // t('LBL.CHECKLIST'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
    this.handleChangedistribution = this.handleChangedistribution.bind(this);
    this.handleChangeActivity = this.handleChangeActivity.bind(this);
    this.handleContent = this.handleContent.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t, setparam } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.NAME'),
      t('LBL.POSITION'),
      t('LBL.TYPE'),
      t('LBL.DISTRIBUTION'),
      // t('LBL.CHECKLIST'),
      t('FRM.FUNCTION'),
    ];
    this.setState({
      urlData: `${ls.get('ws_ip')}/wflactivity/getallitem/${newProps.setparam}`,
    });
    this.setState({ wfl_id: newProps.setparam });
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getButton();
    });
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getTableData() {
    this.child.reload();
  }

  toggle(id) {
    const { t } = this.props;
    if (id === 'add') {
      this.props.destroy();
      this.setState({ titleModal: t('FRM.ADD') });
      this.setState({ status_edit: false, bodyemail: '' });
    } else {
      this.setState({ status_edit: true });
      this.setState({ titleModal: t('FRM.EDIT') });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.loadSelectBox();
    });
    console.log(this.props.setparam);
    // this.setState({ urlData: `${ls.get('ws_ip')}/wflactivity/getallitem/${this.props.setparam}` });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  };

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgposition/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        if (dataResponse.length === 0) {
          // Arr.push({ value: 0, label: 'Unit Organization' });
          this.setState({ slcunit: Arr });
        } else {
          // Arr.push({ value: 0, label: 'Unit Organization' });
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          this.setState({ slcunit: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/employee/getAllitem/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  search(values) {
    console.log(values);
  }

  editDetail(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/wflactivity/getAllItembyid/${id}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data.activity;
        const wfa_actor = res.data.data.activity_actor;
        // this.toggle();
        const val = [];

        if (
          dataResponse[0].alert_content === null ||
          dataResponse[0].alert_content === '<p><br></p>' ||
          dataResponse[0].alert_content.length === 0
        ) {
          this.setState({ bodyemail: '' });
        } else {
          this.setState({ bodyemail: dataResponse[0].alert_content });
        }
        setTimeout(() => {
          this.handleChangedistribution({
            value: `${dataResponse[0].distribution_type}`,
          });
        }, 200);
        this.setState({
          modal: !this.state.modal,
        });
        let datamapping_actor = [];
        if (dataResponse[0].distribution_type === 4) {
          datamapping_actor = {
            waa_id: wfa_actor[0].waa_id,
            emp_id: wfa_actor[0].emp_id,
            emp_id_next: wfa_actor[0].emp_id_next,
          };
        } else {
          datamapping_actor = {
            waa_id: '',
            emp_id: '',
            emp_id_next: '',
          };
        }
        this.props.initialize({ ...dataResponse[0], ...datamapping_actor });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteDetail(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { wfl_id } = this.state;

    const body = {
      duser_id: userid,
      wfl: wfl_id,
    };

    const formBody = Object.keys(body)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`,
      )
      .join('&');

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(`${apiws}/wflactivity/deleteItem/${id}`, formBody, config)
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 200);
            } else if (response.data.status == 'already_exist') {
              showNotifications(
                'Cannot Edit/Delete',
                'Pending workflow request exists',
                'warning',
              );
            } else if (response.data.status === 'used') {
              showNotifications('Fail', 'Activity used in sequence', 'danger');
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  handleContent(values) {
    this.setState({ bodyemail: values });
  }

  handleChangeActivity(id) {
    if (id.value === '2') {
      const arrDistribution = [
        { value: '1', label: 'Atasan Langsung' },
        { value: '2', label: 'Atasan Tidak Langsung' },
        { value: '3', label: 'Position' },
      ];
      this.setState({ arrDistribution });
    } else {
      const arrDistribution = [
        { value: '1', label: 'Atasan Langsung' },
        { value: '2', label: 'Atasan Tidak Langsung' },
        { value: '3', label: 'Position' },
        // { value: '4', label: 'Custom' },
        { value: '5', label: 'Case Owner' },
      ];
      this.setState({ arrDistribution });
    }
  }

  handleChangedistribution(id) {
    if (id.value === '3') {
      setTimeout(() => {
        this.setState({ displayPos: true, wfa_actor: false });
      }, 200);
    } else if (id.value === '4') {
      setTimeout(() => {
        this.setState({ displayPos: false, wfa_actor: true });
      }, 200);
    } else {
      setTimeout(() => {
        this.setState({ wfa_actor: false, displayPos: false });
      }, 200);
    }
  }

  handleSubmitInput(values) {
    console.log(values);
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const error = true;
    const id = values.wfa_id;
    const { wfl_id } = this.state;
    let posid;
    let empid;
    let empidnext;
    let distribution_type;
    let sp_file;
    // const distribution_type = (typeof values.distribution_type === 'object') ?
    //   values.distribution_type.value : values.distribution_type;
    const activitytype =
      typeof values.activity_type === 'object'
        ? values.activity_type.value
        : values.activity_type;

    if (typeof values.pos_id !== 'undefined') {
      if (values.pos_id !== null) {
        posid =
          typeof values.pos_id === 'object'
            ? values.pos_id.value
            : values.pos_id;
      } else {
        posid = '';
      }
    } else {
      posid = '';
    }

    if (typeof values.distribution_type !== 'undefined') {
      if (values.distribution_type !== null) {
        distribution_type =
          typeof values.distribution_type === 'object'
            ? values.distribution_type.value
            : values.distribution_type;
      } else {
        distribution_type = '';
      }
    } else {
      distribution_type = '';
    }

    if (typeof values.emp_id !== 'undefined') {
      if (values.emp_id !== null) {
        empid =
          typeof values.emp_id === 'object'
            ? values.emp_id.value
            : values.emp_id;
      } else {
        empid = '';
      }
    } else {
      empid = '';
    }

    if (typeof values.emp_id_next !== 'undefined') {
      if (values.emp_id_next !== null) {
        empidnext =
          typeof values.emp_id_next === 'object'
            ? values.emp_id_next.value
            : values.emp_id_next;
      } else {
        empidnext = '';
      }
    } else {
      empidnext = '';
    }

    if (typeof values.sp_file !== 'undefined') {
      if (values.sp_file !== null) {
        sp_file =
          typeof values.sp_file === 'object'
            ? values.sp_file.value
            : values.sp_file;
      } else {
        sp_file = '';
      }
    } else {
      sp_file = '';
    }

    // const uploadfile = (typeof values.upload_file === 'undefined' || values.upload_file === 'null') ? '' : values.upload_file;
    let url;
    let data;
    const content = this.state.bodyemail;
    if (id === undefined || id === '') {
      url = `${apiws}/wflactivity/saveItem`;
      data = {
        ...values,
        posid,
        wfl_id,
        distribution_type,
        activitytype,
        empid,
        empidnext,
        content,
        // uploadfile,
        sp_file,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/wflactivity/updateItem/${id}`;
      data = {
        ...values,
        posid,
        wfl_id,
        distribution_type,
        activitytype,
        empid,
        empidnext,
        content,
        // uploadfile,
        sp_file,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.props.destroy();
            setTimeout(() => {
              this.setState({ modal: false, bodyemail: '' });
              this.child.reload();
            }, 200);
          } else if (response.data.status == 'already_exist') {
            showNotifications(
              'Cannot Edit/Delete',
              'Pending workflow request exists',
              'warning',
            );
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  render() {
    const {
      handleSubmit,
      t,
      pristine,
      formatFunction,
      getChecklist,
    } = this.props;
    const {
      status_edit,
      titleModal,
      passworddisabled,
      wf,
      wf_text,
      slcunit,
      jobnm,
      urlData,
      addcond,
    } = this.state;
    let button_action;
    const addBtn = (
      <Button
        className="btn_table_navbar"
        color="primary"
        style={{ float: 'right', 'margin-bottom': '20px' }}
        onClick={() => this.toggle('add')}
      >
        {' '}
        {t('FRM.ADD')} {t('LBL.ACTIVITY')}
      </Button>
    );
    const backBtn = (
      <Button
        color="secondary"
        className="btn_table_navbar"
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
        onClick={() => this.props.onparam('')}
        disable={pristine}
      >
        {' '}
        {t('FRM.BACK')} {t('LBL.WORKFLOW')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (status_edit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="activityforminput"
          // disabled={pristine}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="activityforminput"
          type="submit"
          // disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            {/* <div style={{ float: 'left', 'margin-left': '25px' }}>
              <h3>Format: </h3>
              <h5>Group Code: </h5>
              <h5>Group Name: </h5>
            </div> */}
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="wfa_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  // getLink={getChecklist}
                  onRef={(ref) => (this.child = ref)}
                  updateFunc={this.editDetail}
                  deleteFunc={this.deleteDetail}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}> {titleModal} </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="activityforminput"
                  id="activityforminput"
                >
                  <Field
                    name="wfa_id"
                    component={renderTextInput}
                    type="hidden"
                  />
                  <Field
                    name="wfa_event"
                    id="fua_id"
                    // component={renderSelectField}
                    component={renderTextInput}
                    type="hidden"
                  />
                  <Container>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ACTIVITY')} {t('LBL.NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="activity"
                              component={renderTextInput}
                              type="text"
                              value={this.state.wf}
                              placeholder="Activity Name"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ACTIVITY')} {t('LBL.ORDER')}
                          </span>
                          <div className="form__form-group-field">
                            <Col md={4} xs={12} style={{ padding: '0' }}>
                              <Field
                                name="activity_order"
                                component={renderTextInput}
                                type="number"
                                placeholder="Order Number"
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>
                      <Row style={{ display: passworddisabled }}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.DESCRIPTION')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="activity_description"
                              id="activity_description"
                              component={renderTextInput}
                              type="textarea"
                              placeholder="Activity Description"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ACTIVITY')} {t('LBL.TYPE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="activity_type"
                              id="activity_type"
                              component={renderSelectField}
                              type="text"
                              // placeholder="----"
                              options={[
                                { value: '1', label: 'Create' },
                                { value: '2', label: 'Approve' },
                                { value: '3', label: 'Finish' },
                                // { value: '4', label: 'Rejected' },
                              ]}
                              onChange={this.handleChangeActivity}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.DISTRIBUTION')} {t('LBL.TYPE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="distribution_type"
                              id="distribution_type"
                              component={renderSelectField}
                              type="text"
                              // placeholder="----"
                              options={this.state.arrDistribution}
                              onChange={this.handleChangedistribution}
                            />
                          </div>
                        </div>
                      </Row>
                      {this.state.displayPos === true && (
                        <Row>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.POSITION')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="pos_id"
                                component={renderSelectField}
                                type="text"
                                options={slcunit}
                              />
                            </div>
                          </div>
                        </Row>
                      )}
                      {this.state.wfa_actor === true && (
                        <div>
                          <Field
                            name="waa_id"
                            // component={renderSelectField}
                            component={renderTextInput}
                            type="hidden"
                          />
                          <Row>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.REQUESTER')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="emp_id"
                                  component={renderSelectField}
                                  type="text"
                                  options={this.state.arrEmp}
                                />
                              </div>
                            </div>
                          </Row>
                          <Row>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.APPROVAL')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="emp_id_next"
                                  component={renderSelectField}
                                  type="text"
                                  options={this.state.arrEmp}
                                />
                              </div>
                            </div>
                          </Row>
                        </div>
                      )}

                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ADDITIONAL_FORM')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="sp_file"
                              id="distribution_type"
                              component={renderSelectField}
                              type="text"
                              // placeholder="----"
                              options={[
                                {
                                  value: '/leavesubmission',
                                  label: 'Leave Submission',
                                },
                                {
                                  value: '/attendancesubmission',
                                  label: 'Attendance submission',
                                },
                                {
                                  value: '/overtimesubmission',
                                  label: 'Overtime Submission',
                                },
                                {
                                  value: '/claimsubmission',
                                  label: 'Claim Submission',
                                },
                                {
                                  value: '/kpiplansubmission',
                                  label: 'KPI Plan Submission',
                                },
                                {
                                  value: '/kpitrackingsubmission',
                                  label: 'KPI Tracking Submission',
                                },
                                {
                                  value: '/kpievaluationsubmission',
                                  label: 'KPI Evaluation Submission',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.BODY_EMAIL')}
                          </span>
                          <div className="form__form-group-field">
                            <SunEditor
                              setDefaultStyle="font-family: Roboto; font-size: 14px; z-index: 0"
                              height="150px"
                              // eslint-disable-next-line react/jsx-boolean-value
                              // disable={pristine}
                              onChange={this.handleContent}
                              setContents={this.state.bodyemail}
                              setOptions={{
                                buttonList: this.state.sunEditorButtonList,
                              }}
                            />
                          </div>
                        </div>
                      </Row>
                      <Col
                        md={6}
                        xs={12}
                        lg={6}
                        xl={6}
                        style={{ marginTop: '25px' }}
                      >
                        <div className="note-file-upload">
                          <h5>
                            <b>Body Email Note:</b>
                          </h5>
                          <ol>
                            <li>
                              {' '}
                              __DIST_EMPLOYEE__ : Employee Distribution to{' '}
                            </li>
                            <li>__APPLICANT__ : Applicant Employee </li>
                            <li> __EMP_CODE__ : Applicant Employee Code</li>
                            <li>__POSITION__ : Positiion Applicant</li>
                            <li>__DATE__ : Submission Date</li>
                            <li> __REASON__ : Submission Reason</li>
                            <li> __NOTE__ : Note approver</li>
                            <li> __CURRENT_DATE__ : Current Date</li>
                          </ol>
                        </div>
                      </Col>
                    </Col>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle('cancel');
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.activity) {
    errors.activity = 'Activity field shouldn’t be empty';
  }

  if (!values.activity_type) {
    errors.activity_type = 'Activity type field shouldn’t be empty';
  }

  if (!values.activity_order) {
    errors.activity_order = 'Activity order field shouldn’t be empty';
  }

  if (!values.activity_description) {
    errors.activity_description =
      'Activity description field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'activity_workflow',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Workflowactivity));
