/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable react/no-did-mount-set-state */
/* eslint no-lonely-if: "error" */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
} from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import '../../../../../Tables/DataTable/assets/css/style.css';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import Approval from '../Approval/approval';

class WflActivitySetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    setparam: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.ACTIVITY'),
      t('LBL.REQUEST_DATE'),
      t('LBL.REQUEST_BY'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      wf: '',
      wfl_name: '',
      token: '',
      status_edit: false,
      iduser: '',
      rows: [],
      limit: 10, // default is 5 rows
      offset: 0,
      page: 1,
      disable_preview: '',
      disable_next: '',
      modal_show: false,
      search: '',
      total_pagination: 0,
      current_pagination: 0,
      label_idx_start: 0,
      label_idx_end: 0,
      total: 0,
      wfl_id: '',
      displayTable: '',
      displayForm: false,
      dt_workflow: [],
      arrHistory: [],
    };
    this.reload = this.reload.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.changePage = this.changePage.bind(this);
    this.search = this.search.bind(this);
    this.createButtonPagination = this.createButtonPagination.bind(this);
    this.goto = this.goto.bind(this);
    this.getapproval = this.getapproval.bind(this);
    this.getHistoryCase = this.getHistoryCase.bind(this);
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    setTimeout(() => {
      this.setState({
        wfl_id: this.props.location.state.wfl_id, rows: this.props.location.state.data, wfl_name: this.props.location.state.wfl_name, total: this.props.location.state.data.length,
      });
      this.paginationNumber();
    }, 300);
  }

  componentWillReceiveProps(newProps) {
    const { t, setparam } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.ACTIVITY'),
      t('LBL.REQUEST_DATE'),
      t('LBL.REQUEST_BY'),
      t('FRM.FUNCTION'),
    ];
    if (this.timeout) clearTimeout(this.timeout);
    this.setState({ rows: [] });
    console.log(newProps);
    this.setState({ wf: setparam });
    // this.props.initialize({ 'wfl_id': setparam });
    setTimeout(() => {
      this.setState({
        wfl_id: this.props.location.state.wfl_id, rows: this.props.location.state.data, wfl_name: this.props.location.state.wfl_name, total: this.props.location.state.data.length,
      });
      this.paginationNumber();
    }, 300);
  }

  paginationNumber() {
    const total_pagination = this.state.total / this.state.limit;
    this.setState({
      total_pagination: Math.ceil(total_pagination),
    });

    const page_start = Number.parseInt(this.state.offset, 10) + 1;
    const page_end = Number.parseInt(this.state.limit, 10) + Number.parseInt(this.state.offset, 10);
    this.setState({
      label_idx_start: page_start,
      label_idx_end: page_end > this.state.total ? this.state.total : page_end,
    });
  }

  // eslint-disable-next-line react/sort-comp
  changePage(event) {
    this.setState({
      limit: event.target.value,
    }, () => {
      setTimeout(() => {
        this.reload();
      }, 50);
    });
  }

  search(event) {
    this.setState({ search: event.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  previous() {
    let state_offset = this.state.offset - this.state.limit;
    this.setState({ modal_show: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { 'Authorization': `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios.post(
      `${ls.get('ws_ip')}/workflow/getAllItem/${this.state.limit}/${state_offset}`,
      bodyParameters,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        // eslint-disable-next-line no-underscore-dangle
        let no_index = state_offset;
        for (let i = 0; i < response.data.data.length; i += 1) {
          no_index += 1;
          rows.push({
            no: no_index,
            code: response.data.data[i].wfl_id,
            name: response.data.data[i].workflow,
          });
        }

        // simpan state limit offset
        nextState.setState({
          rows,
          total: response.data.total,
          page: this.state.page - 1,
          offset: state_offset,
          modal_show: false,
          disable_preview: (state_offset < 1 ? 'disabled' : ''),
          disable_next: response.data.total > no_index ? '' : 'disabled',
        });

        this.paginationNumber();
      } else {
        // sembunyikan modal loading data jika data kosong
        this.setState({ modal_show: false });
      }
    }).catch(() => {
      // sembunyikan modal loading data
      this.setState({ modal_show: false });
      // console.log(error);
    });
  }


  reload(x) {
    alert(x);
    // eslint-disable-next-line no-underscore-dangle
    const reloadState = this;
    // eslint-disable-next-line no-underscore-dangle
    let state_offset = this.state.offset;
    this.setState({ modal_show: true });
    const rows = [];
    const config = {
      headers: { 'Authorization': `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    let val_wfl;
    if (typeof wf === 'undefined') {
      val_wfl = this.state.wf;
    } else {
      val_wfl = x;
    }
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios.post(
      `${ls.get('ws_ip')}/wflactivity/getAllItem/${this.state.limit}/${state_offset}/${val_wfl}`,
      bodyParameters,
      config,
    ).then((response) => {
      alert(JSON.stringify(response));
      this.setState({ modal_show: false });
      if (response.data.status === 'ok') {
        // eslint-disable-next-line no-underscore-dangle
        let no_index = state_offset;
        for (let i = 0; i < response.data.data.length; i += 1) {
          no_index += 1;
          rows.push({
            no: no_index,
            id: response.data.data[i].wfa_id,
            name: response.data.data[i].activity,
            orgid: response.data.data[i].unit_organization,
            jobid: response.data.data[i].job_name,
            type: this.getType(response.data.data[i].activity_type),
            distribution: this.getDistribution(response.data.data[i].distribution_type),
          });
        }
        reloadState.setState({
          rows,
          total: response.data.total,
          disable_next: (no_index >= response.data.total ? 'disabled' : ''),
          page: 1,
          total_pagination: 0,
          current_pagination: 0,
        });

        this.paginationNumber();
      }
    }).catch(() => {
      this.setState({ modal_show: false });
      // console.log(error);
    });
  }

  next(wf) {
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (this.state.page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { 'Authorization': `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios.post(
      `${ls.get('ws_ip')}/wflactivity/getAllItem/${this.state.limit}/${state_offset}/${wf}`,
      bodyParameters,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        // eslint-disable-next-line no-underscore-dangle
        let no_index = state_offset;
        for (let i = 0; i < response.data.data.length; i += 1) {
          no_index += 1;
          rows.push({
            no: no_index,
            code: response.data.data[i].wfa_id,
            name: response.data.data[i].activity,
          });
        }
        nextState.setState({
          rows,
          total: response.data.total,
          page: this.state.page + 1,
          offset: state_offset,
          modal_show: false,
          disable_preview: (state_offset < 1 ? 'disabled' : ''),
          disable_next: (no_index >= response.data.total ? 'disabled' : ''),
        });

        this.paginationNumber();
      }
    }).catch(() => {
      this.setState({ modal_show: false });
      // console.log(error);
    });
  }

  goto(e) {
    e.preventDefault();
    const page = parseInt(e.target.innerHTML, 10);
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { 'Authorization': `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios.post(
      `${ls.get('ws_ip')}/workflow/getAllItem/${this.state.limit}/${state_offset}`,
      bodyParameters,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        // eslint-disable-next-line no-underscore-dangle
        let no_index = state_offset;
        for (let i = 0; i < response.data.data.length; i += 1) {
          no_index += 1;
          rows.push({
            no: no_index,
            code: response.data.data[i].wfl_id,
            name: response.data.data[i].workflow,
          });
        }
        nextState.setState({
          rows,
          total: response.data.total,
          page: page + 1,
          offset: state_offset,
          modal_show: false,
          disable_preview: (state_offset < 1 ? 'disabled' : ''),
          disable_next: (no_index >= response.data.total ? 'disabled' : ''),
        });

        this.paginationNumber();
      }
    }).catch(() => {
      this.setState({ modal_show: false });
      // console.log(error);
    });
  }

  createButtonPagination() {
    const total_button = 5;
    const child_pagination = [];
    const state_page = this.state.page - 1;
    // set minimum index
    if ((this.state.current_pagination < 1) || (state_page >= this.state.current_pagination)) {
      this.setState({
        current_pagination: state_page + total_button,
      });
    }
    // set maximum index
    if (state_page < (this.state.current_pagination - total_button)) {
      this.setState({
        current_pagination: this.state.current_pagination - total_button,
      });
    }

    for (let i = 1; i <= this.state.total_pagination; i++) {
      let selected = '';
      if (state_page === i) {
        selected = 'active';
      }
      if ((i >= ((this.state.current_pagination - total_button) - 1)) && (i <= this.state.current_pagination)) {
        child_pagination.push(<li className={`page-item ${selected}`}><a onClick={this.goto} className="page-link" href="#">{i}</a></li>);
      }
    }
    return child_pagination;
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  // eslint-disable-next-line class-methods-use-this
  setValueForm(id, text) {
    document.getElementById(id).value = text;
  }

  // eslint-disable-next-line class-methods-use-this
  setForm(id) {
    document.getElementById(id).reset();
  }

  getapproval(data) {
    this.setState({
      displayForm: true, displayTable: 'none', dt_workflow: data, wfo_id: data.cases.id,
    });
  }

  getHistoryCase() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    console.log(this.state.wfo_id);
    const wfo = this.state.wfo_id;
    axios.get(
      `${ls.get('ws_ip')}/workflow/gethistorycase/${wfo}`,
      config,
    ).then((resemp) => {
      const hist = resemp.data.data;
      const array = [];
      hist.map(obj => (
        array.push({ activity: obj.activity, opc_status: obj.opc_status })
      ));
      this.setState({ arrHistory: hist });
    }).catch(() => {
      // console.log(error);
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card style={{ display: this.state.displayTable }}>
              <CardBody style={{ 'padding': '20px 10px 30px 10px' }}>
                <div className="container-fluid" >
                  <div className="wrapper">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="input-group mb-3">
                          <input
                            value={this.state.search}
                            onChange={this.search}
                            type="text"
                            className="form-control"
                            placeholder={t('LBL.SEARCHING_BY_KEYWORDS')}
                            style={{ 'font-size': '0.8rem !important' }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text" ><FontAwesomeIcon icon={faSearch} /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table id="myTable" className="table data-grid-tbl responsive no-wrap">
                      <thead>
                        <th colSpan="8">{t('LBL.WORKFLOW')} : { this.state.wfl_name }</th>
                      </thead>
                      <thead>
                        {
                        this.heads.map(data => (
                          <th>{data}</th>
                        ))
                      }
                      </thead>
                      <tbody>
                        {
                        this.state.rows.map((data, index) => (
                          <tr>
                            <td style={{ width: '5%' }}>{index + 1}</td>
                            <td style={{ width: '30%' }}>{data.activity}</td>
                            <td style={{ width: '20%' }}>{moment(data.cases.created_time).format('DD/MM/YYYY')}</td>
                            <td style={{ width: '20%' }}>{data.cases.emp_req}</td>
                            <td style={{ width: '10%' }}>
                              <button
                                type="button"
                                onClick={() => this.getapproval(data)}
                                className="btn btn-sm btn-outline-primary btn-data-table"
                              > <i className="fas fa-align-justify" />
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="paging-panel-bottom">
                          <div className="row">
                            <div className="col-md-6">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li className={`page-item ${this.state.disable_preview}`}>
                                    <a onClick={this.previous} className="disabled page-link" href="#" tabIndex="-1" aria-disabled="true"> {t('FRM.PREVIOUS')} </a>
                                  </li>
                                  { this.createButtonPagination() }
                                  <li className={`page-item ${this.state.disable_next}`}>
                                    <a onClick={this.next} className="page-link" href="#">{t('FRM.NEXT')}</a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                            <div className="col-md-6 ">
                              <form className="form-inline float-right">
                                <div className="form-group mb-2">
                                  <label className="mr-3">{t('FRM.PER_PAGE')}</label>
                                  <select onChange={this.changePage} className="form-control" >
                                    <option>10</option>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>100</option>
                                  </select>
                                </div>
                                <div className="form-group mx-sm-3 mb-2">
                                  <label>{t('FRM.SHOWING')} {this.state.label_idx_start} {t('FRM.TO')} {this.state.label_idx_end} {t('FRM.OF')} {this.state.rows.length}</label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {this.state.displayForm === true && (
            <Approval
              dt_workflow={this.state.dt_workflow}
              wfl_id={this.state.wfl_id}
              wfo_id={this.state.wfo_id}
            />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.activity) {
    errors.activity = 'Activity field shouldn’t be empty';
  }
  if (!values.activity_description) {
    errors.activity_description = 'Activity description field shouldn’t be empty';
  }
  if (!values.activity_type) {
    errors.activity_type = 'Activity type field shouldn’t be empty';
  }
  // if (!values.org_id) {
  //   errors.org_id = 'Organization access field shouldn’t be empty';
  // }
  // if (!values.jbn_id) {
  //   errors.jbn_id = 'Job name access field shouldn’t be empty';
  // }
  return errors;
};

export default reduxForm({
  form: 'activityforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(WflActivitySetting));
