/* eslint-disable no-unused-vars, quotes, no-lonely-if, prefer-destructuring, react/no-unused-prop-types */
/* eslint-disable array-callback-return, brace-style, object-curly-newline, no-unneeded-ternary */
/* eslint-disable max-len, class-methods-use-this, react/prop-types, jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-operators, react/jsx-closing-bracket-location, no-loop-func */
/* eslint-disable react/no-unused-state, no-prototype-builtins, no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-for, no-restricted-syntax, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable quote-props, jsx-a11y/anchor-is-valid, operator-assignment, no-return-assign */
/* eslint-disable camelcase, arrow-parens, no-plusplus, prefer-template, jsx-a11y/alt-text */
/* eslint-disable object-shorthand, react/jsx-curly-brace-presence, arrow-body-style */
/* eslint-disable react/no-did-mount-set-state, function-paren-newline, jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import {
  UncontrolledTooltip, Container, Row, Col, Button, Table,
  ButtonToolbar, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import ViewListTable from '../../../../../shared/components/table/viewListTable';

class KpiPlanApprovalForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    wfo_id: PropTypes.func.isRequired,
    emp_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
      { '3': t('LBL.PHASE') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      empid: '',
      empname: '',
      empcode: '',
      redirectUrl: false,
      phaseName: '',
      periodPhaseId: '',
      periodId: '',
      phaseId: '',
      kpiPlanData: [],
      kpiPlanCategory: [],
      kpiPlanStrategic: [],
      wflId: '',
      arrAchievement: [],
      issuePpd: '',
      issuePpi: '',
      issuePph: '',
      issueKpi: '',
      issueEmp: '',
      urlData: '',
      dataWidth: ['5%', '40%', '40%', '15%'],
      showTableIssue: true,
      modal: false,
      totalScore: 0,
    };
    this.getKpiplan = this.getKpiplan.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    this.props.destroy();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.getCase();
    setTimeout(() => {
      this.getKpiplan();
      this.getFlow();
    }, 500);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
      { '3': t('LBL.PHASE') },
    ];
  }

  getCase() {
    const wfo = this.props.wfo_id;
    const emp = this.props.emp_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${ls.get('ws_ip')}/pfmkpiplan/getAllitemkpiplancase/${wfo}/${emp}`,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const dataResponse = res.data.data[0];
        this.setState({
          periodId: dataResponse.pfp_id,
          phaseId: dataResponse.pph_id,
          periodPhaseId: dataResponse.ppp_id,
          phaseName: dataResponse.phase_name,
        });
      }
    });
  }

  getKpiplan() {
    const { periodId, phaseId, periodPhaseId } = this.state;
    const emp = this.props.emp_id;
    const wfo = this.props.wfo_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${ls.get('ws_ip')}/pfmkpiplan/getallitemcategorycase/${emp}/${periodId}/${phaseId}/${periodPhaseId}/${wfo}`,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        if (res.data.data.category.length > 0) {
          const category = res.data.data.category;
          const strategic = res.data.data.strategic;
          const kpi = res.data.data.kpi;
          let total = 0;
          for (let a = 0; a < category.length; a++) {
            total = total + parseInt(category[a].final_weight, 0);
          }
          this.setState({
            kpiPlanCategory: category,
            kpiPlanStrategic: strategic,
            kpiPlanData: kpi,
            totalScore: total,
          });
        }
      }
    });
  }

  getAchievement() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/pfmkpiplan/getAllachievement`,
      '',
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const dataResponse = res.data.data;
        const array = [];
        dataResponse.map(obj => (
          array.push({ value: obj.pra_id, label: obj.achievement_name })
        ));
        this.setState({ arrAchievement: array });
      }
    });
  }

  getFlow() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/workflow/getWorkflowPerformance`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data.performance[0];
        this.setState({
          wflId: dataResponse.wfl_id,
          workflow: dataResponse.workflow,
          activity: dataResponse.activity,
        });
      }
    });
  }

  getTableData() {
    this.child.reload();
  }

  toggle(ppd_id, kpi_name, ppi_id) {
    const emp = this.props.emp_id;
    this.setState({
      urlData: `${ls.get('ws_ip')}/pfmkpiplanissue/getAllitem`,
      modal: !this.state.modal,
      issuePpd: ppd_id,
      issuePpi: ppi_id,
      issuePph: this.state.phaseId,
      issueKpi: kpi_name,
      issueEmp: emp,
    });
    setTimeout(() => {
      this.getTableData();
    }, 300);
  }

  render() {
    const { t } = this.props;
    const {
      redirectUrl,
      kpiPlanData,
      kpiPlanCategory,
      kpiPlanStrategic,
    } = this.state;

    if (redirectUrl) {
      const link = (<Redirect to={{ pathname: '/workflow/approval' }} />);
      return link;
    }

    const arrDataKpiPlan = [];
    for (let i = 0; i < kpiPlanCategory.length; i++) {
      const category = kpiPlanCategory[i];
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.CATEGORY')}</span>
          <div className="form__form-group-field">
            <Col xs={5} md={5} lg={5} xl={5}>
              <input
                style={{ fontWeight: 'bold' }}
                name="category"
                disabled="true"
                value={category.category_name}
              />
            </Col>
          </div>
        </div>,
      );
      for (let j = 0; j < kpiPlanStrategic[`${category.pcr_id}`].length; j++) {
        const strategic = kpiPlanStrategic[`${category.pcr_id}`][j];
        arrDataKpiPlan.push(
          <div className="form__form-group">
            <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.STRATEGIC_OBJECTIVE')}</span>
            <div className="form__form-group-field">
              <Col xs={5} md={5} lg={5} xl={5}>
                <input
                  name="startegic"
                  disabled="true"
                  value={strategic.strategic_objective_name}
                />
              </Col>
            </div>
          </div>,
        );
        for (let k = 0; k < kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`].length; k++) {
          const kpi = kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`][k];
          const satuan = (kpi.target === '%') ? '%' : '';
          arrDataKpiPlan.push(
            <div className="form__form-group">
              <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.KPI')}</span>
              <div className="form__form-group-field">
                <Col xs={3} md={3} lg={3} xl={3}>
                  <textarea
                    name="kpi"
                    disabled="true"
                    value={kpi.kpi_name}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} xl={1}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TARGET')}</span>
                    <div className="form__form-group-field number-form" style={{ width: '100%', padding: '0px' }}>
                      <input name="target" value={`${kpi.target_value} ${satuan}`} disabled />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.WEIGHT')}</span>
                    <div className="form__form-group-field number-form" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        name={`weight_${kpi.ppn_id}_${category.pcr_id}`}
                        component={renderTextInput}
                        type="text"
                        placeholder={kpi.plan_weight}
                        disabled
                        maxLength="3"
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.DIDNT_REACH')}</span>
                    <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        component={renderTextInput}
                        name={`pto_1_${kpi.ppn_id}`}
                        type="text"
                        placeholder={kpi.didnt_reach}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.REACH')}</span>
                    <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        component={renderTextInput}
                        name={`pto_2_${kpi.ppn_id}`}
                        type="text"
                        placeholder={kpi.reach}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EXCEED')}</span>
                    <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        component={renderTextInput}
                        name={`pto_3_${kpi.ppn_id}`}
                        type="text"
                        placeholder={kpi.exceed}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>,
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Col xs={2} md={2} lg={2} xl={2}>
                  <span className="form__form-group-label">{t('LBL.ACHIEVMENT')}</span>
                  <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                    <Field
                      component={renderTextInput}
                      name={`pra_${kpi.ppn_id}`}
                      type="text"
                      input={{ value: kpi.final_achievement }}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={8} md={8} lg={8} xl={8}>
                  <span className="form__form-group-label">{t('LBL.NOTES')}</span>
                  <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                    <Field
                      component={renderTextInput}
                      name={`notes_${kpi.ppn_id}`}
                      type="textarea"
                      classes="kpi-tracking"
                      input={{ value: kpi.notes }}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={1} md={1} lg={1} xl={1} style={{ paddingTop: '40px' }}>
                  <Button
                    color="secondary"
                    style={{ width: '150px' }}
                    onClick={() => this.toggle(kpi.ppd_id, kpi.kpi_name, kpi.ppi_id)}
                    className="btn_table_navbar"
                  > {t('LBL.PROBLEM_ISSUES')}
                  </Button>
                </Col>
              </div>
            </div>,
          );
        }
      }
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.WEIGHT')} {category.category_name}</span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.weight_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.RESULT')} {category.category_name}</span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`weightverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.plan_weight}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.SCORE')} {category.category_name}</span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`weightverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.final_weight}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <hr style={{ width: '100%' }} />,
      );
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 20px 30px 20px' }}>
                <Row style={{ padding: '0px' }}>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div>
                      <form className="form form--horizontal">
                        {arrDataKpiPlan}
                        <div className="form__form-group" style={{ marginTop: '12px' }}>
                          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>
                            <b>{t('LBL.SCORE')} {t('LBL.KPI')} {this.state.phaseName}</b>
                          </span>
                          <div className="form__form-group-field number-form">
                            <Col xs={3} md={3} lg={3} xl={3}>
                              <Field
                                name="total_score"
                                component={renderTextInput}
                                type="text"
                                disabled
                                placeholder={this.state.totalScore}
                              />
                            </Col>
                          </div>
                        </div>
                        <hr style={{ width: '100%' }} />
                      </form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog modal-input"
            style={{ maxWidth: '1000px', marginTop: '100px' }}
          >
            <ModalHeader toggle={this.toggle}>
              {t('LBL.PROBLEM_ISSUES')} {t('LBL.FORM')}
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col>
                    <ViewListTable
                      url={this.state.urlData}
                      displayStyleHead=""
                      textHead={`KPI : ${this.state.issueKpi}`}
                      heads={this.heads}
                      primaryKey="pli_id"
                      buttonAction="noButton"
                      conds={{ ppi_id: this.state.issuePpi, pph_id: this.state.issuePph, emp_id: this.state.issueEmp }}
                      widthTable={this.state.dataWidth}
                      onRef={ref => (this.child = ref)}
                      deleteFunc={this.deleteRows}
                      updateFunc={this.editRows}
                      />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'kpiplanapproval',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KpiPlanApprovalForm));
