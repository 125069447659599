import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from 'reactstrap';
import Layout from '../Layout/index';
import { Accordion, Card, Button } from 'react-bootstrap';
import './okr.css';
import renderSelectField from '../../shared/components/form/Select';
import renderTextInput from '../../shared/components/form/TextInput';
import { translate } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import Chart from 'react-apexcharts';
import {
  faHeart,
  faInfoCircle,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import showNotifications from '../../../src/containers/Preferences/notificationMessages';
import ls from 'local-storage';
import axios from 'axios';
import { ACTIONS, OkrContext, getReducer } from './OkrContext';
import { connectWs } from '../../socket';
import { CalendarBlankIcon } from 'mdi-react';

const validate = (values) => {
  const errors = {};
  if (!values.achievement_value) {
    errors.achievement_value = "Achievement value shouldn't be empty";
  }

  if (!values.oos_id) {
    errors.oos_id = "Status value shouldn't be empty";
  }

  if (!values.oru_id) {
    errors.oru_id = "Check in time value shouldn't be empty";
  }

  return errors;
};

const DetailAccordion = ({ data, metricName, t }) => {
  const [dataAcc, setDataAcc] = useState([]);
  const [tempId, setTempId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = (id) => {
    if (id != tempId) {
      setIsOpen(!isOpen);
      setTempId(id);
    } else {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    setDataAcc(data);
  }, [data]);

  return (
    <>
      {dataAcc?.map((item) => (
        <Accordion>
          <Card className="accordion-detail-check-in">
            <Accordion.Toggle
              eventKey={`kr_${item.oru_id}`}
              className="custom-accordion-toggle"
              onClick={() => {
                const krState = [...dataAcc];
                const indexComment = krState.findIndex(
                  (kr) => kr.oru_id == item.oru_id,
                );
                if (indexComment !== -1) {
                  krState[indexComment] = {
                    ...krState[indexComment],
                    show_time: krState[indexComment].show_time ? false : true,
                  };

                  // Set the state with the updated array
                  setDataAcc(krState);
                  toggleAccordion(item.oru_id);
                }
              }}
            >
              <Card.Header className="custom-accordion-header">
                <h5>
                  {item?.name_time} ( {item?.plan_date} )
                </h5>
                <i
                  className={
                    item?.show_time == false
                      ? `ml-2 fa fa-chevron-down`
                      : `ml-2 fa fa-chevron-up`
                  }
                ></i>
              </Card.Header>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`kr_${item.oru_id}`}>
              <Card.Body className="custom-accordion-body">
                <Row className="align-items-center">
                  <Col md={4}>
                    <div className="body-user">
                      <div className="d-flex align-items-center">
                        <img
                          src={`${ls.get('ws_ip')}/public/uploads${
                            item?.path_photo === null ||
                            item?.path_photo === '' ||
                            item?.path_photo === 'undefined'
                              ? '/user-images.png'
                              : item?.path_photo
                          }`}
                          height={32}
                          width={32}
                          className="avatar-comment"
                        />
                        <p className="ml-2 mt-0">{item?.emp_name}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={4}>
                        <div className="body-item">
                          <h5 style={{ fontWeight: 500 }}>
                            {t('LBL.CKINSTATUS')}
                          </h5>
                          <div
                            className={
                              item?.checkin_status == 'Ontime Check in'
                                ? 'rounded status-ontime-checkin'
                                : 'rounded status-late-checkin'
                            }
                          >
                            {item?.checkin_status}
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="body-item">
                          <h5 style={{ fontWeight: 500 }}>
                            {t('LBL.CHECKINTIME')}
                          </h5>
                          <p>{item?.checkin_time}</p>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="body-item">
                          <h5 style={{ fontWeight: 500 }}>
                            {t('LBL.PROGRESS')}
                          </h5>
                          <p>{item?.progress} %</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}
    </>
  );
};

const CheckInKeyResult = (props) => {
  const params = props.location?.state;
  const refSocket = useRef(null);
  const [state, dispatch] = getReducer();
  const [countComment, setCountComment] = useState(0);
  const t = props?.t;

  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [isOpen, setIsOpen] = useState(false);

  const [status, setStatus] = useState([]);
  const [checkInTime, setCheckInTime] = useState([]);
  const [commentKR, setCommentKR] = useState([]);
  const [history, setHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState(false);
  const [checkinBetweenDate, setCheckinBetweenDate] = useState({
    start_date: '',
    end_date: '',
  });

  const [data, setData] = useState({
    achievement_value: '',
    oos_id: '',
    oru_id: params?.oru_id || '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const toggleLike = (orcId) => {
    setIsLiked(!isLiked);
    likeComment(orcId);
  };

  const toggleAccordion = (orcId, isReply) => {
    setIsOpen(!isOpen);
    getReplyComment(orcId, isReply);
  };

  const [dataDetailCI, setDataDetailCI] = useState([]);

  const selectStatus = (e) => {
    setData((prev) => ({ ...prev, oos_id: e?.value }));
  };

  const selectCheckInTime = (e) => {
    setData((prev) => ({ ...prev, oru_id: e?.value }));
  };

  const getDetailKR = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/Okrkeyresult/getDetailKeyResult/${params?.ors_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          const item = response.data.data[0];
          setData((prev) => ({ ...prev, oos_id: item.oos_id }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDetailKRCheckIn = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/Okrkeyresult/getDetailAllCheckinKR/${params?.ors_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          setDataDetailCI(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCheckInTime = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/Okrkeyresult/getWeeks/${params?.ors_id}`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];
          dataResponse.map((item) => {
            let label;
            let isDisabled = false;

            if (!item.status || item.status == 'NOT_YET_TIME')
              isDisabled = true;

            const imageStatusObj = {
              COMPLETED: '/img/misc/check-circle.svg',
              PENDING: '/img/misc/pending-circle.svg',
              OVERDUE: '/img/misc/alert-circle.svg',
              NOT_YET_TIME: '/img/misc/stop-circle.svg',
            };

            let imageStatus = imageStatusObj[item.status] ?? '';

            label = (
              <>
                <img src={imageStatus} className="mr-1" /> {item.number_week}
              </>
            );

            arr.push({
              value: item.oru_id,
              label: label,
              isDisabled: isDisabled ?? false,
            });
          });

          setCheckInTime(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChartData = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/Okrkeyresult/getChartData/${params?.ors_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          dispatch({
            type: ACTIONS.SET_CHART,
            current: response.data.current,
            target: response.data.target,
            name: response.data.name,
            metricName: response.data.metricName,
          });

          let startDate = new Date(
            response.data.current.length > 0 ? response.data.current[0][0] : '',
          ).toLocaleDateString('en-us', {
            month: 'short',
            day: 'numeric',
          });

          let endDate = new Date(
            response.data.current.length > 0
              ? response.data.current[response.data.current.length - 1][0]
              : '',
          ).toLocaleDateString('en-us', {
            month: 'short',
            day: 'numeric',
          });

          setCheckinBetweenDate({
            start_date: response.data.current.length >= 0 ? startDate : '',
            end_date: response.data.current.length >= 1 ? endDate : '',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllStatus = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/Okrobjectivestatus/getAll`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];
          dataResponse.map((item) => {
            arr.push({ value: item.oos_id, label: item.status_name });
          });

          setStatus(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCommentKR = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrkeyresult/getCommentKeyResult/${params?.ors_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          setCommentKR(response.data.data);
          setCountComment(response.data.data.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const likeComment = (commentId) => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const formData = new FormData();
    formData.append('orc_id', commentId);

    axios
      .post(`${auth.apiws}/okrkeyresult/likeComment`, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const commentState = [...commentKR];

          const indexComment = commentState.findIndex(
            (item) => item.orc_id == commentId,
          );

          // console.log(indexComment, 'id comment index');

          if (indexComment !== -1) {
            let like;
            response.data.data.like == 1
              ? (like = commentState[indexComment].count_like + 1)
              : (like = commentState[indexComment].count_like - 1);
            // Update the specific element
            commentState[indexComment] = {
              ...commentState[indexComment],
              count_like: like,
              is_like: response.data.data.like == 1 ? true : false,
            };
            // Set the state with the updated array
            setCommentKR(commentState);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReplyComment = (commentId, isReply) => {
    if (isReply == 1) {
      const commentState = [...commentKR];
      const indexComment = commentState.findIndex(
        (item) => item.orc_id == commentId,
      );

      if (indexComment !== -1) {
        // Update the specific element
        commentState[indexComment] = {
          ...commentState[indexComment],
          is_reply: 0,
          reply_comment: [],
        };

        // Set the state with the updated array
        setCommentKR(commentState);
      }
    } else {
      const config = {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      axios
        .post(
          `${auth.apiws}/okrkeyresult/getReplyComment/${commentId}`,
          {},
          config,
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data.status == 'ok') {
            const commentState = [...commentKR];

            const indexComment = commentState.findIndex(
              (item) => item.orc_id == response.data.data[0].parent_orc_id,
            );

            if (indexComment !== -1) {
              // Update the specific element
              commentState[indexComment] = {
                ...commentState[indexComment],
                is_reply: 1,
                reply_comment: response.data.data,
              };

              // Set the state with the updated array
              setCommentKR(commentState);
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const getHistoryKR = () => {
    setIsLoading(true);

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrkeyresult/getHistoryKey/${params?.ors_id}`,
        {},
        config,
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.status == 'ok') {
          setHistory(response.data.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const sendComment = (orc_id) => {
    let replyMessage = '';

    if (message == '' && orc_id == undefined) return false;
    if (orc_id !== undefined) {
      let valueReply = document.getElementById(`reply-${orc_id}`).value;
      if (!valueReply) return false;

      replyMessage = valueReply;
    }

    const formData = new FormData();
    formData.append('ors_id', params?.ors_id);

    if (orc_id !== undefined) {
      formData.append('parent_orc_id', orc_id);
      formData.append('comment', replyMessage);
    } else {
      formData.append('comment', message);
    }

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(`${auth.apiws}/okrkeyresult/sendComment`, formData, config)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status == 'ok') {
          // getCommentKR();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTouched(true);
    const form = e.target;
    const formData = new FormData(form);
    formData.append('oos_id', data?.oos_id);
    formData.append('oru_id', data?.oru_id);

    if (!form.achievement_value?.value || !data?.oos_id || !data?.oru_id)
      return false;

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrkeyresult/updateTarget/${params?.ors_id}`,
        formData,
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          setData((prev) => ({
            ...prev,
            achievement_value: response.data.achievement,
            oos_id: response.data.status_kr,
          }));
          getChartData();
          getDetailKR();
          getDetailKRCheckIn();
          getCheckInTime();
          getHistoryKR();
          showNotifications('Success', 'Submit Submission', 'success');
        }
      })
      .catch((error) => {
        showNotifications('Fail', 'Save Failed', 'danger');
        console.log(error);
      });
  };

  const initWebSocket = useCallback(() => {
    const onWSMessage = (message) => {
      const jsonMessage = JSON.parse(message.data);
      switch (jsonMessage.type) {
        case 'OKR_COMMENT_NEW':
          setCommentKR((prev) => [jsonMessage.data, ...prev]);
          break;

        case 'OKR_REPLY_COMMENT':
          setCommentKR((prev) =>
            prev.map((comment) => {
              if (comment.orc_id == jsonMessage.dest) {
                comment.count_comment = jsonMessage.count_reply;
                comment.reply_comment = [
                  jsonMessage.data,
                  ...comment.reply_comment,
                ];
              }

              return comment;
            }),
          );
          // toggleAccordion
          break;

        default:
          break;
      }
    };

    const { socket, socketTimeout } = connectWs(
      'wss://service.phirogo.com/ws',
      onWSMessage,
    );
    refSocket.current = { socket, socketTimeout };
  }, [commentKR]);

  useEffect(() => {
    setCountComment(commentKR.length);
  }, [commentKR]);

  useEffect(() => {
    getCheckInTime();
    getAllStatus();
    getCommentKR();
    getHistoryKR();
    getChartData();
    getDetailKR();
    getDetailKRCheckIn();
    initWebSocket();

    return () => {
      refSocket.current.socket.close();
      clearTimeout(refSocket.current.socketTimeout);
    };
  }, []);

  return (
    <OkrContext.Provider value={[state, dispatch]}>
      <Layout />
      <div className="container__wrap" id="checkinKeyResult">
        <Container className="p-3">
          <Row>
            <Col md="12">
              <div className="tab-body-panels">
                <div className="okr-content">
                  <div className="flex-column ms-3 mb-3">
                    <h3 className="page-title">{t('LBL.CHECKINKR')}</h3>
                    <div className="page-subhead subhead">
                      {t('LBL.CHECKINKR')}
                    </div>
                  </div>
                  <form
                    className="form wizard__form"
                    name="formcheckin"
                    id="formcheckin"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="7">
                        <Row>
                          <Col md={12} className="mb-3">
                            {params?.count_kr_notcheckin !== 0 && (
                              <Alert className="alert-checkin p-2">
                                <span>
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-2"
                                  />
                                  <span className="text-dark">
                                    There are {params?.count_kr_notcheckin} key
                                    results that has not been check in{' '}
                                  </span>
                                </span>
                              </Alert>
                            )}
                          </Col>

                          <Col md={12}>
                            <Row>
                              <Col md={3} className="d-flex align-items-center">
                                <span className="form__form-group-label ">
                                  {t('LBL.KRTITLE')}
                                </span>
                              </Col>
                              <Col md={9}>
                                <div className="form">
                                  <div className="form__form-group-field position-relative">
                                    <Field
                                      // name="type_report3"
                                      component={renderTextInput}
                                      // input={{ onChange: setInpt }}
                                      type="textemployee"
                                      props={{
                                        input: {
                                          value: params?.key_result_title,
                                        },
                                      }}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={12} className="mt-3">
                            <Row>
                              <Col md={3} className="d-flex align-items-center">
                                <span className="form__form-group-label ">
                                  {t('LBL.CHECKINTIME')}
                                </span>
                              </Col>
                              <Col md={9}>
                                <div className="form">
                                  <div className="form__form-group-field position-relative">
                                    <Field
                                      component={renderSelectField}
                                      name="oru_id"
                                      type="text"
                                      placeholder="Select Check in time"
                                      options={checkInTime}
                                      onChange={(e) => selectCheckInTime(e)}
                                      props={{
                                        disableTouched: data.oru_id
                                          ? false
                                          : true,
                                        value: data.oru_id,
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={12} className="mt-3">
                            <Row>
                              <Col md={3} className="d-flex align-items-center">
                                <span className="form__form-group-label ">
                                  {t('LBL.CHECKINTARGET')}
                                </span>
                              </Col>
                              <Col md={9}>
                                <div className="form">
                                  <div className="form__form-group-field position-relative">
                                    <div className="form__form-group-field">
                                      <Field
                                        component={renderTextInput}
                                        type="textemployee"
                                        props={{
                                          input: {
                                            value: params?.checkin_target,
                                          },
                                        }}
                                        disabled
                                      />
                                      <div className="form__form-group-icon">
                                        {params?.metric_name == 'Rupiah'
                                          ? 'Rp'
                                          : params?.metric_name == 'Percentage'
                                          ? '%'
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={12} className="mt-3">
                            <Row>
                              <Col md={3} className="d-flex align-items-center">
                                <span className="form__form-group-label ">
                                  {t('LBL.CURRENT')}
                                </span>
                              </Col>
                              <Col md={9}>
                                <div className="form">
                                  <div className="form__form-group-field position-relative">
                                    <Field
                                      name="achievement_value"
                                      textAlign="left"
                                      component={renderTextInput}
                                      type="number"
                                      placeholder="Enter Current"
                                      props={{
                                        disableTouched: touched,
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={12} className="mt-3">
                            <Row>
                              <Col md={3} className="">
                                <span className="form__form-group-label mt-2">
                                  {t('LBL.KRSTATUS')}
                                </span>
                              </Col>
                              <Col md={9}>
                                <div className="form">
                                  <div className="form__form-group-field position-relative">
                                    <Field
                                      component={renderSelectField}
                                      name="oos_id"
                                      placeholder="Select Status"
                                      options={status}
                                      onChange={selectStatus}
                                      props={{
                                        disableTouched: data.oos_id
                                          ? false
                                          : true,
                                        value: data.oos_id,
                                      }}
                                    />
                                  </div>
                                </div>
                                {/*  */}
                                {params?.has_access && (
                                  <div className="mt-5">
                                    <Button
                                      form="formcheckin"
                                      className="btn btn-primary"
                                      type="submit"
                                    >
                                      {t('LBL.SUBMITKR')}
                                    </Button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="5" className="mt-3 mt-md-0 pl-3 pl-md-0">
                        <ChartKeyResult betweenDate={checkinBetweenDate} />
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5" id="comments">
            <Col md={12}>
              <div className="tab-body-panels">
                <div className="okr-content">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab == '1' ? 'active' : ''}
                        onClick={() => setActiveTab('1')}
                      >
                        {t('LBL.DETAIL')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab == '2' ? 'active' : ''}
                        onClick={() => setActiveTab('2')}
                      >
                        {t('LBL.HISTORY')}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={activeTab == '3' ? 'active' : ''}
                        onClick={() => setActiveTab('3')}
                      >
                        {t('LBL.COMMENT')} ({countComment})
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="pt-4">
                    <TabPane tabId="1">
                      <div className="detail-header">
                        <Row>
                          <Col md={5}>
                            <Row>
                              <Col md={5}>
                                <div className="okr-checkin-item item-wrapper">
                                  <h5>{t('LBL.OBJTITLE')}</h5>
                                  <p>{dataDetailCI[0]?.objective_title}</p>
                                </div>
                              </Col>
                              <Col md={7}>
                                <div className="okr-checkin-item item-wrapper">
                                  <h5>{t('LBL.KRDESC')}</h5>
                                  <p>
                                    {dataDetailCI[0]?.key_result_description ??
                                      '-'}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={7}>
                            <Row>
                              <Col md={3}>
                                <div className="okr-checkin-item item-wrapper">
                                  <h5>{t('LBL.KRASSIGNEE')}</h5>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        auth.apiws +
                                        '/public/uploads/user-default-photo.png'
                                      }
                                      height={16}
                                      width={16}
                                    />
                                    <p>{dataDetailCI[0]?.assignee}</p>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="okr-checkin-item item-wrapper">
                                  <h5>{t('LBL.KRPERIOD')}</h5>
                                  <p>{dataDetailCI[0]?.period_name}</p>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="okr-checkin-item item-wrapper">
                                  <h5>{t('LBL.KRSTATUS')}</h5>
                                  <p>{dataDetailCI[0]?.status_name}</p>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="okr-checkin-item item-wrapper">
                                  <h5>{t('LBL.CHECKINPERIOD')}</h5>
                                  <p>{dataDetailCI[0]?.time_parameter_name}</p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div className="detail-body">
                        <DetailAccordion
                          data={dataDetailCI[0]?.time_checkins}
                          metricName={dataDetailCI[0]?.metric_name}
                          t={t}
                        />
                      </div>
                    </TabPane>

                    <TabPane tabId="2">
                      {history.length > 0 ? (
                        <div>
                          {history?.map((item) => (
                            <div className="body p-3 mb-3">
                              <div className="">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      item.path_photo
                                        ? ls.get('ws_ip') +
                                          '/public/uploads' +
                                          item.path_photo
                                        : '/img/icon-avatar-user.svg'
                                    }
                                    className="avatar-comment"
                                  />
                                  <div className="flex-column ml-3">
                                    <div>
                                      <b>{item?.username}</b>
                                    </div>
                                    <div>{item?.mtime}</div>
                                  </div>
                                </div>
                                <div className="py-2">{item?.status}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex justify-content-center">
                            <img src="/img/empty-state-okr.png" />
                          </div>
                          <p className="text-center">{t('LBL.NOHISTORY')}</p>
                        </div>
                      )}
                    </TabPane>

                    <TabPane tabId="3">
                      <Card>
                        {params?.has_access && (
                          <div className="body p-3 mb-comment">
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  ls.get('user_cred').path_photo
                                    ? ls.get('ws_ip') +
                                      '/public/uploads' +
                                      ls.get('user_cred').path_photo
                                    : '/img/icon-avatar-user.svg'
                                }
                                className="avatar-comment"
                                width={200}
                              />
                              <div className="form w-100 ml-2">
                                <div className="form__form-group-field position-relative">
                                  <input
                                    value={message}
                                    onChange={(event) => {
                                      setMessage(event.target.value);
                                    }}
                                    type="text"
                                    className="form__form-group-input-wrap form__form-group-input-wrap--error-above"
                                    placeholder={'Enter Comment'}
                                  />
                                </div>
                              </div>
                              <button
                                id="addKeyResult"
                                onClick={() => sendComment()}
                              >
                                <img src="/img/icon-send.svg" alt="" />
                              </button>
                            </div>
                          </div>
                        )}

                        {commentKR.length > 0 ? (
                          <>
                            {commentKR.map((item) => (
                              <Accordion>
                                <Card>
                                  <Card.Header>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          item.path_photo
                                            ? item.path_photo
                                            : '/img/icon-avatar-user.svg'
                                        }
                                        className="avatar-comment"
                                        alt=""
                                      />
                                      <div className="flex-column ml-3">
                                        <div>
                                          <b>
                                            {item?.first_name} {item?.last_name}
                                          </b>
                                        </div>
                                        <div>{item?.ctime}</div>
                                      </div>
                                    </div>
                                    <div className=" py-2">{item?.comment}</div>
                                    <hr />
                                    <div className="d-flex align-items-center">
                                      <div className="mr-auto d-flex align-items-center">
                                        <div>
                                          <button
                                            id="addKeyResult"
                                            onClick={() =>
                                              toggleLike(item?.orc_id)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faHeart}
                                              className={
                                                item?.is_like
                                                  ? 'text-danger'
                                                  : 'text-secondary'
                                              }
                                            />
                                            <span className="ml-2">
                                              {item?.count_like}
                                            </span>
                                          </button>
                                        </div>
                                        <div className="ml-3">
                                          <FontAwesomeIcon
                                            icon={faComment}
                                            className="add-result"
                                          />
                                          <span className="ml-2">
                                            {t('LBL.REPLY')}{' '}
                                            {item?.count_comment}
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <Accordion.Toggle
                                          as="a"
                                          variant="link"
                                          eventKey="0"
                                          id="showComment"
                                          className="p-0"
                                          onClick={() => {
                                            toggleAccordion(
                                              item?.orc_id,
                                              item?.is_reply,
                                            );
                                          }}
                                        >
                                          {item?.is_reply == 1
                                            ? 'Hide Reply'
                                            : 'Show Reply'}
                                        </Accordion.Toggle>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Accordion.Collapse
                                    eventKey="0"
                                    id="collapseComment"
                                  >
                                    <>
                                      {item.reply_comment.length > 0 && (
                                        <>
                                          <Card.Body>
                                            {item?.reply_comment.map(
                                              (replyComment, i) => (
                                                <div
                                                  className={`px-4 ${
                                                    i > 0 ? 'mt-3' : ''
                                                  }`}
                                                >
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      src={
                                                        replyComment.path_photo
                                                          ? replyComment.path_photo
                                                          : '/img/icon-avatar-user.svg'
                                                      }
                                                      alt=""
                                                      className="avatar-comment"
                                                      width={40}
                                                    />
                                                    <div className="flex-column ml-3">
                                                      <div>
                                                        <b>
                                                          {
                                                            replyComment?.first_name
                                                          }{' '}
                                                          {
                                                            replyComment?.last_name
                                                          }
                                                        </b>
                                                      </div>
                                                      <div>
                                                        {replyComment?.ctime}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="py-2">
                                                    {replyComment?.comment}
                                                  </div>
                                                </div>
                                              ),
                                            )}
                                          </Card.Body>
                                        </>
                                      )}
                                    </>
                                  </Accordion.Collapse>
                                  {params?.has_access && (
                                    <Card.Footer>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={
                                            ls.get('user_cred').path_photo
                                              ? ls.get('ws_ip') +
                                                '/public/uploads' +
                                                ls.get('user_cred').path_photo
                                              : '/img/icon-avatar-user.svg'
                                          }
                                          className="avatar-comment"
                                          alt=""
                                        />
                                        <div className="form w-100 ml-2">
                                          <div className="form__form-group-field position-relative">
                                            <input
                                              placeholder="Enter Comment"
                                              name={`reply-${item.orc_id}`}
                                              id={`reply-${item.orc_id}`}
                                            />
                                          </div>
                                        </div>
                                        <button
                                          id="addKeyResult"
                                          onClick={() =>
                                            sendComment(item?.orc_id)
                                          }
                                        >
                                          <img
                                            src="/img/icon-send.svg"
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                    </Card.Footer>
                                  )}
                                </Card>
                              </Accordion>
                            ))}
                          </>
                        ) : (
                          <>
                            {/* Empty State No Comments  */}
                            <div className="w-100 text-center pt-5">
                              <img src="/img/img-empty-state.svg" alt="" />
                              <div className="pt-4 fw-">
                                <b>{t('LBL.NOCOMMENTS')}</b>
                              </div>
                              <div className="text-comment">
                                {t('LBL.EMPTYSTATECOMMENT')}
                              </div>
                            </div>
                          </>
                        )}
                      </Card>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </OkrContext.Provider>
  );
};

const ChartKeyResult = ({ betweenDate }) => {
  const [state, dispatch] = useContext(OkrContext);
  const refSelectionAxis = useRef(null);
  const refChartLine = useRef(Chart);
  const refChartArea = useRef(Chart);
  const chart = {
    options: {
      stroke: {
        width: [3, 3],
        curve: 'straight',
      },
      chart: {
        id: 'chart1',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: !1,
        },
      },
      tooltip: {
        x: {
          formatter: (value) => {
            let label;
            let date = new Date(value);

            const year = date.toLocaleString('default', { year: 'numeric' });
            const month = date.toLocaleString('default', { month: '2-digit' });
            const day = date.toLocaleString('default', { day: '2-digit' });

            let formatDate = [year, month, day].join('-');
            label = state.chart?.name[formatDate];

            if (label) label = label[0];
            return `${label}`;
          },
        },
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 0,
        align: 'center',
        title: {
          text: state.chart?.metricName,
          style: {
            fontSize: '11px',
            fontWeight: 400,
            color: '#373D3F',
          },
        },
      },
      xaxis: {
        type: 'date',
        tickAmount: state.chart?.current?.length == 1 ? 0 : 3,
        labels: {
          formatter: (value) => {
            let date = new Date(value);
            // const year = date.toLocaleString('default', { year: 'numeric' });
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.toLocaleString('default', { day: '2-digit' });

            return `${month} ${day}`;
          },
        },
      },
      colors: ['#057AD7', '#FD4E45'],
      legend: {
        markers: {
          width: 8,
          height: 8,
        },
      },
      markers: {
        style: 'inverted',
        size: 3,
      },
    },
    series: [
      {
        name: 'Target',
        data: state.chart?.target,
      },
      {
        name: 'Current',
        data: state.chart?.current,
      },
    ],
  };

  const chartDrag = {
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
        id: 'chart2',
        height: 50,
        type: 'area',
        brush: {
          target: 'chart1',
          enabled: true,
        },
        selection: {
          enabled: true,
          xaxis: {
            min:
              state.chart?.current.length > 0 ? state.chart?.current[0][0] : 0,
            max:
              state.chart?.current.length >= 2
                ? state.chart?.current[1][0]
                : state.chart?.current.length == 1
                ? state.chart?.current[0][0]
                : 0,
          },
        },
      },
      legend: {
        show: false,
      },
      colors: ['#057AD7', '#FD4E45'],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.91,
          opacityTo: 0.1,
        },
      },
    },
    series: [
      {
        name: 'Target',
        data: state?.chart?.target,
      },
      {
        name: 'Current',
        data: state?.chart?.current,
      },
    ],
  };

  useEffect(() => {
    refChartArea.current?.chart?.updateOptions(
      {
        chart: {
          selection: {
            enabled: true,
            xaxis:
              refSelectionAxis?.current ??
              chartDrag.options.chart?.selection?.xaxis,
          },
        },
      },
      true,
      false,
    );
  });

  return (
    <Row className="mx-0">
      <div className="border w-100">
        <div className="pr-3 pt-3">
          <Col md="12" className="px-0">
            <Chart
              ref={refChartLine}
              options={chart.options}
              series={chart.series}
              type="line"
              height="200"
            />
          </Col>
        </div>
        {state?.chart?.current?.length > 1 && (
          <div>
            <Col md="12" className="px-0">
              <Chart
                ref={refChartArea}
                options={chartDrag.options}
                series={chartDrag.series}
                type="area"
                height="50"
              />
              <div className="d-flex justify-content-between">
                <label className="between-date mx-2">
                  {betweenDate.start_date}
                </label>
                <label className="between-date mx-2">
                  {betweenDate.end_date}
                </label>
              </div>
            </Col>
          </div>
        )}
      </div>
    </Row>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  form: 'formcheckin',
  validate,
})(translate('global')(CheckInKeyResult));
