/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

class TaxTariff extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '40%', '35%', '15%'],
      statusEdit: false,
      urlData: '',
      incometo: '',
      incomefrom: '',
      editcond: '',
      addcond: '',
      deletecond: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.INCOME') },
      { '2': t('LBL.TARIFF') },
      { '3': '' },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editTaxTariff = this.editTaxTariff.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deleteTaxTariff = this.deleteTaxTariff.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.INCOME') },
      { '2': t('LBL.TARIFF') },
      { '3': '' },
    ];
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/paytaxtariff/getAllitem/` });
    this.getTableData();
    this.getButton();
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.props.destroy();
    this.setState({ incomefrom: '' });
    this.setState({ incometo: '' });
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
  }

  handleSubmitInput(values) {
    const income_from = this.state.incomefrom;
    const income_to = this.state.incometo;
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.ptt_id;
    const userid = ls.get('user_cred').usr_id;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/paytaxtariff/saveItem`;
      data = {
        ...values,
        income_from,
        income_to,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/paytaxtariff/updateItem/${id}`;
      data = {
        ...values,
        income_from,
        income_to,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
          setTimeout(() => {
            this.child.reload();
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  async editTaxTariff(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/paytaxtariff/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            ptt_id: getResponseData[0].ptt_id,
            tariff: getResponseData[0].tariff,
          };
          this.setState({ incomefrom: getResponseData[0].income_from });
          this.setState({ incometo: getResponseData[0].income_to });
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledinput: false });
    this.setState({ statusedit: false });
    this.setState({ buttondisabled: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusedit: true });
  }

  deleteTaxTariff(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/paytaxtariff/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      component,
      buttondisabled,
      disabledinput,
      statusEdit,
      urlData,
      iduser,
    } = this.state;

    let button_action;
    let addBtn;
    let functBtn = 'noButton';
    if (iduser === 1) {
      addBtn = (
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={() => this.toggle('add')}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            display: this.state.addcond === '1' ? '' : 'none',
          }}
        >
          {t('FRM.ADD')} {t('LBL.TAX_TARIFF')}
        </Button>
      );
      functBtn = ['edit', 'delete'];
    }
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button color="success" type="submit" form="taxtariffform">
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="taxtariffform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.ADD')}
        </Button>
      );
    }
    return (
      <Container>
        {/* <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <h4>Tax Tarif</h4>
            <hr />
          </Col>
        </Row> */}
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ptt_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editTaxTariff}
                  deleteFunc={this.deleteTaxTariff}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                  buttonAction={functBtn}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {t('FRM.ADD')} {t('LBL.TAX_TARIFF')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="taxtariffform"
                  id="taxtariffform"
                >
                  <Container>
                    <Row>
                      <Col md={5} xs={12} lg={5} xl={5}>
                        <input type="hidden" name="ptt_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.INCOME')}
                          </span>
                          <div className="form__form-group-field number-form">
                            <NumberFormat
                              name="income_from"
                              thousandSeparator="."
                              decimalSeparator=","
                              value={this.state.incomefrom}
                              type="text"
                              maxLength="100"
                              onValueChange={(val) => {
                                this.setState({ incomefrom: val.floatValue });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={5} xs={12} lg={5} xl={5}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.TO')}
                          </span>
                          <div className="form__form-group-field number-form">
                            <NumberFormat
                              name="income_to"
                              thousandSeparator="."
                              decimalSeparator=","
                              value={this.state.incometo}
                              type="text"
                              maxLength="100"
                              onValueChange={(val) => {
                                this.setState({ incometo: val.floatValue });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5} xs={12} lg={5} xl={5}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.TARIFF')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="tariff"
                              placeholder="%"
                              component={renderTextInput}
                              type="number"
                              maxLength="100"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle('cancel');
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}
const validate = (values) => {
  const errors = {};
  if (!values.income_from) {
    errors.income_from = 'Income from field shouldn’t be empty';
  }
  if (!values.income_to) {
    errors.income_to = 'Income to field shouldn’t be empty';
  }
  if (values.income_to < values.income_from) {
    errors.income_to = 'Income from not more than income to';
  }
  if (!values.tariff) {
    errors.tariff = 'Tariff field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'taxtariffform',
  validate,
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(TaxTariff));
