/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';
import { reduxForm } from 'redux-form';
import showNotifications from '../../../Preferences/notificationMessages';
import base64forma1 from './form';
// import { Preview, print } from 'react-html2pdf';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
// import template from './certificatax.html';

class ReportAttendanceForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // onProcess: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    condYear: PropTypes.string.isRequired,
    condEmp_id: PropTypes.string.isRequired,
    condCmpId: PropTypes.string.isRequired,
    back: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { url, t } = props;
    this.state = {
      arrPdf: [],
      idPage: [],
      displayLoad: 'none',
      total: '',
    };

    this.getReport = this.getReport.bind(this);
    this.download = this.download.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.getReport();
  }

  getReport() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      'pyp_id': '',
      'year': this.props.condYear,
      'emp_id': this.props.condEmp_id,
      'cmp_id': this.props.condCmpId,
      'tax_value': '',
      'type': '3',
    };
    const array = [];
    const pg = [];
    const bodyParameters = {
      cond: conds,
    };

    axios.post(
      `${ls.get('ws_ip')}/reportpayroll/getReporttaxa1`,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const pcs = res.data.data;
        this.setState({ total: res.data.total });
        pcs.map(obj => (
          this.state.idPage.push({ page: obj.Nama.replace(/\s/g, ''), nama: obj.Nama })
        ));
        pcs.map(obj => (
          array.push({
            page: obj.Nama.replace(/\s/g, ''),
            masapajak: obj['Masa Pajak'],
            tahunpajak: (obj['Tahun Pajak']).substring(2),
            nomorbp: (obj['Nomor Bukti Potong']).substring(9),
            bulanpenghasilanawal: obj['Masa Perolehan Awal'],
            bulanpenghasilanakhir: obj['Masa Perolehan Akhir'],
            company: obj.company,
            npwpperusahaan1: obj['company npwp'] === null || obj['company npwp'] === '' ? String.fromCharCode(160) : obj['company npwp'].substring(0, 12),
            npwpperusahaan2: obj['company npwp'] === null || obj['company npwp'] === '' ? String.fromCharCode(160) : obj['company npwp'].substring(13, 16),
            npwpperusahaan3: obj['company npwp'] === null || obj['company npwp'] === '' ? String.fromCharCode(160) : obj['company npwp'].substring(17),
            npwppemotong1: obj['NPWP Pemotong'] === null || obj['NPWP Pemotong'] === '' ? String.fromCharCode(160) : obj['NPWP Pemotong'].substring(0, 12),
            npwppemotong2: obj['NPWP Pemotong'] === null || obj['NPWP Pemotong'] === '' ? String.fromCharCode(160) : obj['NPWP Pemotong'].substring(13, 16),
            npwppemotong3: obj['NPWP Pemotong'] === null || obj['NPWP Pemotong'] === '' ? String.fromCharCode(160) : obj['NPWP Pemotong'].substring(17),
            npwppunya1: obj.NPWP === null ? String.fromCharCode(160) : obj.NPWP.substring(0, 12),
            npwppunya2: obj.NPWP === null ? String.fromCharCode(160) : obj.NPWP.substring(13, 16),
            npwppunya3: obj.NPWP === null ? String.fromCharCode(160) : obj.NPWP.substring(17),
            nik: obj.NIK === null ? String.fromCharCode(160) : obj.NIK,
            K: obj['Status PTKP'] === 'K' ? obj['Jumlah Tanggungan'] : String.fromCharCode(160),
            TK: obj['Status PTKP'] === 'TK' ? obj['Jumlah Tanggungan'] : String.fromCharCode(160),
            hb: obj['Status PTKP'] === 'HB' ? obj['Jumlah Tanggungan'] : String.fromCharCode(160),
            nama: obj.Nama.substring(0, 44),
            jabatan: obj['Nama Jabatan'] === null ? String.fromCharCode(160) : obj['Nama Jabatan'].substring(0, 32),
            karyawanasing: obj['WP Luar Negeri'] === 'Y' ? 'X' : String.fromCharCode(160),
            alamat: obj.Alamat === null ? String.fromCharCode(160) : obj.Alamat.substring(0, 50),
            Male: obj['Jenis Kelamin'] === 'M' ? 'X' : String.fromCharCode(160),
            Female: obj['Jenis Kelamin'] === 'F' ? 'X' : String.fromCharCode(160),
            kodepajak01: obj['Kode Pajak'].substring(9) === '1' ? 'X' : String.fromCharCode(160),
            kodepajak02: obj['Kode Pajak'].substring(9) === '2' ? 'X' : String.fromCharCode(160),
            kodenegara: obj['Kode Negara'] === null ? String.fromCharCode(160) : obj['Kode Negara'],
            jumlah1: obj['jumlah 1'],
            jumlah2: obj['jumlah 2'],
            jumlah3: obj['jumlah 3'],
            jumlah4: obj['jumlah 4'],
            jumlah5: obj['jumlah 5'],
            jumlah6: obj['jumlah 6'],
            jumlah7: obj['jumlah 7'],
            jumlah8: obj['jumlah 8'],
            jumlah9: obj['jumlah 9'],
            jumlah10: obj['jumlah 10'],
            jumlah11: obj['jumlah 11'],
            jumlah12: obj['jumlah 12'],
            jumlah13: obj['jumlah 13'],
            jumlah14: obj['jumlah 14'],
            jumlah15: obj['jumlah 15'],
            jumlah16: obj['jumlah 16'],
            jumlah17: obj['jumlah 17'],
            jumlah18: obj['jumlah 18'],
            jumlah19: obj['jumlah 19'],
            jumlah20: obj['jumlah 20'],
            namapemotong: obj['Nama Pemotong'] === null || obj['Nama Pemotong'] === '' ? String.fromCharCode(160) : obj['Nama Pemotong'],
            tanggalpotong: obj['Tanggal Bukti Potong'] === null ? String.fromCharCode(160) : obj['Tanggal Bukti Potong'].substring(8),
            bulanpotong: obj['Tanggal Bukti Potong'] === null ? String.fromCharCode(160) : obj['Tanggal Bukti Potong'].substring(5, 7),
            tahunpotong: obj['Tanggal Bukti Potong'] === null ? String.fromCharCode(160) : obj['Tanggal Bukti Potong'].substring(0, 4),
          })
        ));
        this.setState({ arrPdf: array });
      }
    });
    setTimeout(() => {
      showNotifications('Success', 'Load Success', 'success');
    }, 1000);
  }

  print(name, id) {
    const element = document.querySelector(`#${id}`);
    if (!element) {
      console.log('%c Pdf generate error', 'font-weight: bold; font-size: 15px; color: red; text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px 1px 0px black, -1px -1px 0px black ');
      console.warn('failed to copy dom for pdf print');
      return false;
    }
    html2canvas(element, { scale: '1' }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const hratio = canvas.height / canvas.width;
      const pdf = new Jspdf('p', 'pt', 'a4');
      const width = (pdf.internal.pageSize.width) + 250;
      const height = hratio * width;

      pdf.addImage(imgData, 'JPG', 20, 20, width, height);
      const currentTime = new Date();
      pdf.save(`${name}.pdf`);
    });
    this.setState({ displayLoad: 'block' });
  }


  download() {
    this.setState({ displayLoad: 'block' });
    setTimeout(() => {
      // let i;
      // for (i = 0; i < this.state.idPage.length; i++) {
      //   print(this.state.idPage[i].nama, this.state.idPage[i].page);
      //   if (i === this.state.idPage.length - 1) {
      //     setTimeout(() => {
      //       showNotifications('Success', 'Load Success', 'success');
      //       this.setState({ displayLoad: 'none' });
      //     }, 1000);
      //   }
      // }
      this.state.idPage.map(data =>
        (
          this.print(data.nama, data.page)
        ));
      showNotifications('Success', 'Load Success', 'success');
      this.setState({ displayLoad: 'none' });
    }, 200);
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrPdf,
      displayLoad,
    } = this.state;
    return (
      <div>
        <Card>
          <CardBody style={{ paddingTop: '0px' }}>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit" onClick={this.props.back} >{t('FRM.BACK')}</Button>
              <Button color="primary" type="submit" onClick={this.download} >{t('FRM.DOWNLOAD')}</Button>
            </ButtonToolbar>
            <label>{t('LBL.TOTAL')} {t('LBL.DOCUMENT')} : {this.state.total}</label>
          </CardBody>
        </Card>
        <Row style={{ display: displayLoad, textAlign: 'center' }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: '35px' }} />
        {arrPdf.map((data, index) => (
          // <React.Fragment>
          //   <PDF
          //     preview
          //     autoprint
          //   >
          //     <Html sourceById={data.page} />
          //     <AddPage format="a6" orientation="l" />
          //   </PDF>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0" style={{ zIndex: '0' }}>
                <div id={data.page}>
                  <div id="page_1">
                    <div id="p1dimg1">
                      <img src={base64forma1} alt="" id="p1img1" />
                    </div>
                    <div className="dclr" />
                    <div id="id1_1">
                      <table cellPadding="0" cellSpacing="0" className="bp-0">
                        <tr>
                          <td className="td-1"><p>{data.masapajak}</p></td>
                          <td className="td-2"><p>{data.tahunpajak}</p></td>
                          <td className="td-3"><p>{data.nomorbp}</p></td>
                          <td className="td-4"><p>{data.bulanpenghasilanawal}</p></td>
                          <td className="td-5"><p>{data.bulanpenghasilanakhir}</p></td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" className="bp-1">
                        <tr>
                          <td className="td-1"><p>{data.npwpperusahaan1}</p></td>
                          <td className="td-2"><p>{data.npwpperusahaan2}</p></td>
                          <td className="td-3"><p>{data.npwpperusahaan3}</p></td>
                          <td className="td-4"><p>&nbsp;</p></td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="td-5">
                            <p>{data.company}</p>
                          </td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" className="bp-2">
                        <tr>
                          <td className="td-1"><p>{data.npwppunya1}</p></td>
                          <td className="td-2"><p>{data.npwppunya2}</p></td>
                          <td className="td-3"><p>{data.npwppunya3}</p></td>
                          <td className="td-4" colSpan="4"><p>&nbsp;</p></td>
                        </tr>
                        <tr>
                          <td className="td-5" colSpan="3"><p>{data.nik}</p></td>
                          <td className="td-6"><p>{data.K}</p></td>
                          <td className="td-7" colSpan="2"><p>{data.TK}</p></td>
                          <td className="td-8"><p>{data.hb}</p></td>
                        </tr>
                        <tr>
                          <td className="td-9" colSpan="3"><p>{data.nama}</p></td>
                          <td className="td-10" colSpan="4"><p>{data.jabatan}</p></td>
                        </tr>
                        <tr>
                          <td className="td-11" colSpan="3" rowSpan="2"><p>{data.alamat}</p></td>
                          <td className="td-12" colSpan="4"><p>X</p></td>
                        </tr>
                        <tr>
                          {/* <td className="td-11" colSpan="3" ><p>{data.alamatline2}</p></td> */}
                          <td className="td-13" colSpan="4"><p>{data.kodenegara}</p></td>
                        </tr>
                        <tr>
                          <td className="td-14" colSpan="3">
                            <table cellPadding="0" cellSpacing="0" className="bp-2-1">
                              <tr>
                                <td className="td-14-1"><p>{data.Male}</p></td>
                                <td className="td-14-2"><p>{data.Female}</p></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" className="bp-3">
                        <tr>
                          <td className="td-15">
                            <table cellPadding="0" cellSpacing="0" className="bp-3-1">
                              <td className="td-15-1"><p>{data.kodepajak01}</p></td>
                              <td className="td-15-2"><p>{data.kodepajak02}</p></td>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="td-16 h-19"><p>&nbsp;</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-19"><p>{data.jumlah1}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-16"><p>{data.jumlah2}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-19"><p>{data.jumlah3}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-17"><p>{data.jumlah4}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-18"><p>{data.jumlah5}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-17"><p>{data.jumlah6}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-18"><p>{data.jumlah7}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-17"><p>{data.jumlah8}</p></td>
                        </tr>
                        <tr>
                          <td className="td-16 h-19"><p>&nbsp;</p></td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" className="bp-4">
                        <tr>
                          <td className="td-25 h-18"><p>{data.jumlah9}</p></td>
                        </tr>
                        <tr>
                          <td className="td-25 h-18"><p>{data.jumlah10}</p></td>
                        </tr>
                        <tr>
                          <td className="td-25 h-18"><p>{data.jumlah11}</p></td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" className="bp-5">
                        <tr>
                          <td className="td-26 h-17"><p>&nbsp;</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-18"><p>{data.jumlah12}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-17"><p>{data.jumlah13}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-16"><p>{data.jumlah14}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-19"><p>{data.jumlah15}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-16"><p>{data.jumlah16}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-19"><p>{data.jumlah17}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-16"><p>{data.jumlah18}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-19"><p>{data.jumlah19}</p></td>
                        </tr>
                        <tr>
                          <td className="td-26 h-16"><p>{data.jumlah20}</p></td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" className="bp-6">
                        <tr>
                          <td className="td-17"><p>{data.npwppemotong1}</p></td>
                          <td className="td-18"><p>{data.npwppemotong2}</p></td>
                          <td className="td-19"><p>{data.npwppemotong3}</p></td>
                          <td colSpan="3" className="td-20" />
                        </tr>
                        <tr>
                          <td className="td-21" colSpan="3"><p>{data.namapemotong}</p></td>
                          <td className="td-22"><p>{data.tanggalpotong}</p></td>
                          <td className="td-23"><p>{data.bulanpotong}</p></td>
                          <td className="td-24"><p>{data.tahunpotong}</p></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          // </React.Fragment>
        ))}
      </div>
    );
  }
}

export default reduxForm({
  form: 'bpa1report', // a unique identifier for this form
})(translate('global')(ReportAttendanceForm));
