/* eslint-disable array-callback-return */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable operator-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import renderSelectField from '../../../../shared/components/form/Select';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import ReactExport from 'react-export-excel';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import Spinner from 'react-bootstrap/Spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportPayrollMonthly extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.OFFICE'),
    ];
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      urlData: '',
      arrYear: [],
      arrEmployee: [],
      arrEmpStatus: [],
      arrBank: [],
      arrOffice: [],
      arrUnitOrganization: [],
      arrGroupComponent: [],
      arrFormatReport: [],
      condPygId: '',
      condPypId: '',
      condOrganization: '',
      condOffice: '',
      condBank: '',
      condEmployee: '',
      condEmployeeStatus: '',
      displayLoad: 'none',
      displaySave: '',
      displayTable: '',
      type: '',
      dataexcel: [],
      headerexcel: [],
      filename: 'Report Payroll ',
      trigger: false,
    };
    this.getYear = this.getYear.bind(this);
    this.getEmployeeStatus = this.getEmployeeStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getFormatReport = this.getFormatReport.bind(this);
    this.getReport = this.getReport.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    this.getYear();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getEmployeeStatus();
      this.getOffice();
      this.getUnitOrganization();
      this.getBank();
      this.getEmployee();
      this.getGroupComponent();
      this.getFormatReport();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/reportpayroll/getPayrollMonthly` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.OFFICE'),
    ];
  }

  getTableData() {
    this.childs.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/0`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getEmployeeStatus() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/rtbemployeementstatus/getAll`,
      config,
    ).then((res) => {
      const emp = res.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmpStatus: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.ems_id, label: obj.ems_employeement_status })
        ));
        this.setState({ arrEmpStatus: array });
      }
    });
  }

  getBank() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/rtbbank/getAll`,
      config,
    ).then((res) => {
      const rtk = res.data.data;
      const array = [];
      if (rtk.length === 0) {
        this.setState({ arrBank: array });
      } else {
        rtk.map(obj => (
          array.push({ value: obj.rtk_id, label: obj.bank })
        ));
        this.setState({ arrBank: array });
      }
    });
  }

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgoffice`,
      config,
    ).then((res) => {
      const off = res.data.data;
      const array = [];
      if (off.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        off.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getFormatReport() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/payreport/getAllItem`,
      config,
    ).then((res) => {
      const format = res.data.data;
      const array = [];
      if (format.length === 0) {
        this.setState({ arrFormatReport: array });
      } else {
        format.map(obj => (
          array.push({ value: obj.prp_id, label: obj.format_name })
        ));
        this.setState({ arrFormatReport: array });
      }
    });
  }

  getGroupComponent() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/paycomponentgroup/getAll`,
      config,
    ).then((res) => {
      const agc = res.data.data;
      const array = [];
      if (agc.length === 0) {
        this.setState({ arrGroupComponent: array });
      } else {
        agc.map(obj => (
          array.push({ value: obj.pyg_id, label: obj.component_group })
        ));
        this.setState({ arrGroupComponent: array });
      }
    });
  }

  getReport(format_report) {
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      'pyg_id': this.state.condPygId,
      'org_id': this.state.condOrganization,
      'off_id': this.state.condOffice,
      'emp_id': this.state.condEmployee,
      'ems_id': this.state.condEmployeeStatus,
      'rtk_id': this.state.condBank,
      'period': this.state.condPeriod,
      'type': '2',
    };
    const array = [];
    const bodyParameters = {
      cond: conds,
    };
    if (typeof format_report === 'undefined' || format_report === null) {
      axios.post(
        `${ls.get('ws_ip')}/reportpayroll/getPayrollMonthly`,
        bodyParameters,
        config,
      ).then((res) => {
        if (res.data.status === 'ok') {
          const data = res.data.data;
          const header = res.data.header;
          data.map((obj, index) => (
            array.push({ 'No': (index + 1), ...obj })
          ));
          setTimeout(() => {
            this.setState({ dataexcel: array, headerexcel: header, trigger: !this.state.trigger });
          }, 200);
        }
      });
      setTimeout(() => {
        showNotifications('Success', 'Load Success', 'success');
        this.setState({ type: '2' });
        this.inputElement.click();
      }, 1000);
    } else {
      const format = format_report.value;
      axios.post(
        `${apiws}/reportpayroll/getGenerateFormat/${format}/`,
        bodyParameters,
        config,
      ).then((res) => {
        if (res.data.status === 'ok' || res.data.total > 0) {
          const data = res.data.data;
          const header = res.data.header;
          const sum = [];
          data.map((obj, index) => (
            array.push({ 'No': (index + 1), ...obj })
          ));
          header.map((obj) => {
            Object.entries(data).map((val) => {
              if (typeof (val[1][obj]) === 'number') {
                sum[obj] = sum[obj] ? (sum[obj] + val[1][obj]) : val[1][obj];
              } else if (obj === 'Employee Name') {
                sum[obj] = 'Total';
              } else {
                sum[obj] = '';
              }
            });
          });
          array.push({ 'No': '', ...sum, style: { font: { sz: '24', bold: true } } });
          this.setState({ dataexcel: array, headerexcel: header, trigger: !this.state.trigger });
          showNotifications('Success', 'Load Success', 'success');
          this.setState({ type: '2' });
          this.inputElement.click();
        } else {
          this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
          setTimeout(() => {
            showNotifications('Info', 'Data not found', 'info');
          }, 500);
        }
      });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  handleChange(val) {
    const label = val.label;
    this.setState({ filename: `Report Payroll ${label}` });
  }

  handleSubmit(values) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (this.isEmpty(values) === false) {
      this.setState({ displayTable: 'none', displayLoad: '', displaySave: 'none' });
      const month = (typeof values.period_month === 'undefined') ? '' : values.period_month.value;
      const year = (typeof values.period_year === 'undefined') ? '' : values.period_year.value;
      const valperiod = (year === '' || month === '') ? false : true;
      if (valperiod) {
        const period = `${year}${month}`;
        const pygId = (typeof values.group_component === 'undefined' || values.group_component === null) ? '' : values.group_component.value;
        const orgId = (typeof values.organization === 'undefined' || values.organization === null) ? '' : values.organization.value;
        const offId = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
        const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
        const emsId = (typeof values.employeement_status === 'undefined' || values.employeement_status === null) ? '' : values.employeement_status.value;
        const rtkId = (typeof values.bank === 'undefined' || values.bank === null) ? '' : values.bank.value;
        this.setState({ condPeriod: period });
        this.setState({ condPygId: pygId });
        this.setState({ condOrganization: orgId });
        this.setState({ condOffice: offId });
        this.setState({ condEmployee: empId });
        this.setState({ condEmployeeStatus: emsId });
        this.setState({ condBank: rtkId });
        if (typeof values.type_report === 'undefined') {
          showNotifications('Info', 'Type Report must be choosen!', 'info');
          setTimeout(() => {
            this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
          }, 1000);
        } else if (values.type_report.value === '2') {
          setTimeout(() => {
            this.getReport(values.format_report);
          }, 1000);
        } else if (values.type_report.value === '1') {
          this.setState({ type: '' });
          if (values.format_report === null || typeof values.format_report === 'undefined') {
            this.setState({ urlData: `${ls.get('ws_ip')}/reportpayroll/getPayrollMonthly` });
            setTimeout(() => {
              this.getTableData();
            }, 1000);
          } else {
            const format = values.format_report.value;
            this.setState({ urlData: `${apiws}/reportpayroll/getGenerateFormat/${format}` });
            setTimeout(() => {
              this.getTableData();
            }, 1000);
            showNotifications('Success', 'Load Success', 'success');
          }
        }
      }
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrYear,
      arrEmpStatus,
      arrEmployee,
      arrBank,
      arrOffice,
      arrUnitOrganization,
      arrFormatReport,
      urlData,
      displayTable,
      displayLoad,
      displaySave,
      condPygId,
      condEmployee,
      condEmployeeStatus,
      condBank,
      condOrganization,
      condOffice,
      condPeriod,
    } = this.state;
    const addBtn = '';
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card style={{ paddingLeft: '15px' }}>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.PAYROLL')} - {t('LBL.MONTHLY_REPORT')}</h3>
                    <h3 className="page-subhead subhead">{t('LBL.VIEW_PAYROLL')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form className="form form--horizontal mt15" onSubmit={handleSubmit(this.handleSubmit)}>
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_month"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '01', label: `${t('LBL.JANUARY')}` },
                                    { value: '02', label: `${t('LBL.FEBRUARY')}` },
                                    { value: '03', label: `${t('LBL.MARCH')}` },
                                    { value: '04', label: `${t('LBL.APRIL')}` },
                                    { value: '05', label: `${t('LBL.MAY')}` },
                                    { value: '06', label: `${t('LBL.JUNE')}` },
                                    { value: '07', label: `${t('LBL.JULI')}` },
                                    { value: '08', label: `${t('LBL.AUGUST')}` },
                                    { value: '09', label: `${t('LBL.SEPTEMBER')}` },
                                    { value: '10', label: `${t('LBL.OCTOBER')}` },
                                    { value: '11', label: `${t('LBL.NOVEMBER')}` },
                                    { value: '12', label: `${t('LBL.DESEMBER')}` },
                                  ]}
                                  onChange={this.handleChange}
                                />
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="format_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrFormatReport}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmployee}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="employeement_status"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmpStatus}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="office"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrOffice}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="organization"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrUnitOrganization}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.BANK')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="bank"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrBank}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  id="typeAttdReport"
                                  name="type_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'View' },
                                    { value: '2', label: 'Excel' },
                                  ]}
                                />
                              </div>
                            </div>
                            {(this.state.type === '2') && (
                              <ExcelFile
                                element={
                                  <div ><input type="hidden" ref={input => this.inputElement = input} />
                                  </div>}
                                filename={this.state.filename}
                              >
                                <ExcelSheet data={this.state.dataexcel} name="Employees">
                                  {
                                    this.state.headerexcel.map(obj => (
                                      <ExcelColumn label={obj} value={obj} />
                                    ))
                                  }
                                </ExcelSheet>
                              </ExcelFile>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <ButtonToolbar className="form__button-toolbar">
                              <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                              <Button color="primary" style={{ display: displayLoad }}>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: '6px' }}
                                />
                                {t('FRM.LOADING')}
                              </Button>
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayTable }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row className="m5 table-nopadding">
                  <div className="card__title">
                    <h5 className="bold-text">{t('LBL.MONTHLY_REPORT')}</h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    addBtn={addBtn}
                    buttonAction="noButton"
                    conds={{
                      'pyg_id': condPygId,
                      'period': condPeriod,
                      'org_id': condOrganization,
                      'off_id': condOffice,
                      'emp_id': condEmployee,
                      'ems_id': condEmployeeStatus,
                      'rtk_id': condBank,
                      'type': '1',
                    }}
                    onRef={ref => (this.childs = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.period_month) {
    errors.period_month = 'Period shouldn’t be empty';
  }
  if (!values.period_year) {
    errors.period_year = 'Period shouldn’t be empty';
  }
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  if (!values.format_report) {
    errors.format_report = 'Format report field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'horizontal_form_layout', // a unique identifier for this form
  validate,
})(translate('global')(ReportPayrollMonthly));
