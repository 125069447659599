/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */

export default (function isSpecialChar(event) {
  const keyCode = event.which ? event.which : event.keyCode;
  if (keyCode === 222) {
    event.preventDefault();
  }
});

