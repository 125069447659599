/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter,
  Card, CardBody, Button, ButtonToolbar, Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
// import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
// import DatePicker from 'react-datepicker';
import showNotifications from '../../../../Preferences/notificationMessages';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import AddEmployee from './PeriodAddEmployee';
import EditEmployee from './PeriodEditEmployee';
import DetailEmployee from './PeriodDetailEmployee';

class PeriodEmployee extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    setparam: PropTypes.func.isRequired,
    idparam: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': `${t('MEN.EMPLOYEE')} ${t('LBL.NAME')}` },
      { '2': t('LBL.ORGANIZATION') },
      { '3': t('LBL.OFFICE') },
      { '4': t('LBL.POSITION') },
      { '5': t('LBL.JOB_BAND') },
      { '6': t('FRM.FUNCTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      pypId: '',
      empId: '',
      modal: false,
      modalEdit: false,
      modalDetail: false,
      urlData: '',
      dataWidth: ['5%', '15%', '15%', '15%', '15%', '10%', '15%'],
      btnDisabled: true,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onButton = this.onButton.bind(this);
    this.deleteEmpRows = this.deleteEmpRows.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
    });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.props.initialize({});
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/payemployeecurrent/getAllItem` });
    this.setState({ pypId: this.props.idparam });
  }

  componentWillReceiveProps(newProps) {
    // const id = pypId;
    // if (id) {
    //   // this.getTableData();
    //   this.setState({ pypId: id });
    // }
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': `${t('MEN.EMPLOYEE')} ${t('LBL.NAME')}` },
      { '2': t('LBL.ORGANIZATION') },
      { '3': t('LBL.OFFICE') },
      { '4': t('LBL.POSITION') },
      { '5': t('LBL.JOB_BAND') },
      { '6': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleEdit(id) {
    this.setState({
      empId: id,
      modalEdit: !this.state.modalEdit,
    });
  }

  toggleDetail(id) {
    this.setState({
      empId: id,
      modalDetail: !this.state.modalDetail,
    });
  }

  onReload() {
    this.getTableData();
  }

  onButton(x) {
    if (x) {
      this.setState({ btnDisabled: true });
    } else {
      this.setState({ btnDisabled: false });
    }
  }

  deleteEmpRows(id) {
    const {
      token,
      apiws,
      idUser,
      pypId,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payemployeecurrent/deleteItem/${id}/${pypId}`,
          `duser_id=${idUser}`,
          config,
        ).then((response) => {
          // alert(JSON.stringify(response));
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            this.getTableData();
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch((error) => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }

  handleSubmit() {
    const {
      empId,
      pypId,
      token,
      apiws,
      idUser,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will update this item !',
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#007bff',
      confirmButtonClass: 'primary',
      cancelButtonClass: 'secondary',
      confirmButtonText: 'Yes, update it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payemployeecurrent/updateItem/${empId}/${pypId}`,
          `muser_id=${idUser}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Update Success', 'success');
            this.getTableData();
            this.setState({
              modalEdit: !this.state.modalEdit,
            });
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch((error) => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const {
      urlData, pypId,
    } = this.state;
    const modalStyle = {
      maxWidth: '800px',
      marginTop: '95px',
      textAlign: 'center',
    };
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.toggle}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('MEN.EMPLOYEE')}
      </Button>
    );

    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn_table_navbar"
        onClick={() => this.props.onparam()}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <Col md={12} style={{ 'paddingBottom': '10px' }}>
                  <h3 className="page-title">{t('LBL.PAYROLL')} - {t('MEN.EMPLOYEE_LIST')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.SETTING_EMPLOYEE_PAYROLL_PERIOD')}</h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="emp_id"
                  customKey="payroll_pay_lm"
                  conds={pypId}
                  updateFunc={this.toggleEdit}
                  deleteFunc={this.deleteEmpRows}
                  detailFunc={this.toggleDetail}
                  buttonAction="buttonAll"
                  widthTable={this.state.dataWidth}
                  textHead={this.props.setparam}
                  onRef={ref => (this.child = ref)}
                />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}> {t('FRM.ADD')} </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} >
                      <AddEmployee
                        onreload={this.onReload}
                        addidparam={this.state.pypId}
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
          <Col>
            <Modal
              isOpen={this.state.modalDetail}
              toggle={this.toggleDetail}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggleDetail}> {t('FRM.ADD')} </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12} >
                      <DetailEmployee
                        onreload={this.onReload}
                        empidParam={this.state.empId}
                        pypidParam={this.state.pypId}
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
          <Col>
            <Modal
              isOpen={this.state.modalEdit}
              toggle={this.toggleEdit}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggleEdit}> {t('FRM.EDIT')} </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="updateEmployeeCurrent"
                  id="updateEmployeeCurrent"
                >
                  <Container>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12} >
                        <EditEmployee
                          onreload={this.onReload}
                          empidParam={this.state.empId}
                          pypidParam={this.state.pypId}
                          buttonDisabled={this.onButton}
                        />
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => { this.toggleEdit(); }}>{t('FRM.CANCEL')}</Button>
                <Button
                  color="primary"
                  type="submit"
                  form="updateEmployeeCurrent"
                  disabled={this.state.btnDisabled}
                > {t('FRM.SAVE')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'FormEmployeePeriode',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(PeriodEmployee));
