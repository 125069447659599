/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import TaxMonthly from './TaxMonthly/index';
import TaxYearly from './TaxYearly/index';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import Authenticator from '../../Authenticator';

const HeaderIcon = '/img/preference_icon/icon-payroll.png';

class TaxCalculation extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      menu: [],
      token: '',
      apiws: '',
      men_id: '',
      fga_id: '',
      validateToken: false,
    };

    this.getReference = this.getReference.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getReference();
    });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({
        men_id: location.state.men_id,
        fga_id: location.state.fga_id,
      });
    }
    this.checkAuth();
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(`${apiws}/menu/getreference/`, formBody, config).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map((obj) =>
          array.push({ men_id: obj.men_id, label: obj.lbl_code }),
        );
        this.setState({
          menu: array,
        });
        console.log(this.state.menu);
      }
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/token/isOtp`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.otp) {
            this.setState({ validateToken: true });
          }
        }
      })
      .catch((error) => {
        // showNotifications('Fail', 'Delete Failed', 'danger');
      });
  }

  render() {
    const { t } = this.props;
    const { validateToken } = this.state;

    if (validateToken === false) {
      return <Authenticator checkAuth={this.checkAuth} />;
    }
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0" style={{ padding: '0px' }}>
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="tax" />
                  <h3 className="page-title">{t('LBL.TAX')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('MEN.TAX_CALCULATION')}
                  </h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  {this.state.menu.map((data, index) => (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            this.state.activeTab === (index + 1).toString(),
                        })}
                        onClick={() => {
                          this.toggle((index + 1).toString());
                        }}
                      >
                        <span className="navlink_tab-title">
                          {t(data.label)}
                        </span>
                      </NavLink>
                    </NavItem>
                  ))}
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {t('LBL.TAX')} {t('LBL.MONTHLY')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {t('LBL.TAX')} {t('LBL.YEARLY')}
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.menu.map(
                    (data, index) =>
                      (data.label === 'LBL.TAX_MONTHLY' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <TaxMonthly
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.TAX_YEARLY' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <TaxYearly
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )),
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(TaxCalculation);
