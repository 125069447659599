import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import AttendanceProcessViewTable from './components/AttendanceProcessViewTable';
import AttendanceEmployeeListTable from './components/index';
import AttendanceProcessForm from './components/AttendanceProcessForm';

class AttendanceProcess extends PureComponent {
  constructor() {
    super();
    this.state = {
      dataUpdate: false,
      pypId: '',
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
  }

  setDefault = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = () => {
    this.setState({ dataUpdate: true });
  }

  attEmployee = (x) => {
    this.setState({ pypId: x });
  }

  render() {
    const { pypId } = this.state;
    if (pypId !== '') {
      return (
        <Container>
          <Row>
            <AttendanceEmployeeListTable
              period={this.state.pypId}
              attEmployee={this.attEmployee}
            />
          </Row>
        </Container>
      );
    }
    return (
      <Container>
        <Row>
          <AttendanceProcessForm
            onProcess={this.loadAfterSubmit}
          />
        </Row>
        <Row>
          <AttendanceProcessViewTable
            onProcess={this.state.dataUpdate}
            inProcess={this.setDefault}
            attEmployee={this.attEmployee}
          />
        </Row>
      </Container>
    );
  }
}

export default AttendanceProcess;
