/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-return-assign */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint no-lonely-if: "error" */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../Preferences/notificationMessages';
import renderTextInput from '../../../shared/components/form/TextInput';
import renderSelectField from '../../../shared/components/form/Select';
import '../../Tables/DataTable/assets/css/style.css';
import NumberFormat from 'react-number-format';
import ViewListTable from '../../../shared/components/table/viewListTable';
import Authenticator from '../../Authenticator';
import Spinner from 'react-bootstrap/Spinner';

class ComponentPayrollEmployee extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.CODE'),
      t('LBL.NAME'),
      t('LBL.JOB_TITLE'),
      t('LBL.VALUE'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      token: '',
      modal: false,
      statusEdit: false,
      iduser: '',
      apiws: '',
      provinces: '',
      rows: [],
      arrComponent: [],
      arrPeriod: [],
      limit: 10, // default is 5 rows
      offset: 0,
      page: 1,
      disable_preview: '',
      disable_next: '',
      modal_show: false,
      search: '',
      component: '',
      period: '',
      total: 0,
      total_pagination: 0,
      current_pagination: 0,
      label_idx_start: 0,
      label_idx_end: 0,
      pecValue: 0,
      buttonDisabled: true,
      urlData: '',
      addcond: '',
      editcond: '',
      deletecond: '',
      men_id: '',
      fga_id: '',
      validateToken: false,
      buttonSave: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onChangeComponent = this.onChangeComponent.bind(this);
    this.onChangePeriod = this.onChangePeriod.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editRows = this.editRows.bind(this);
    this.getButton = this.getButton.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({
        men_id: location.state.men_id,
        fga_id: location.state.fga_id,
      });
    }
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') }, () => {
      this.getComponent();
      this.getPeriod();
      this.getButton();
    });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/payempcomponent/getAllItem` });
    if (this.state.validateToken) {
      this.getTableData();
    }
    this.checkAuth();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.CODE'),
      t('LBL.NAME'),
      t('LBL.JOB_TITLE'),
      t('LBL.VALUE'),
      t('FRM.FUNCTION'),
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.state.men_id;
    const fga = this.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          console.log(array);
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
              // this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getComponent() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/paycomponent/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pyc_id, label: obj.component }),
          );
          this.setState({ arrComponent: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getPeriod() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payperiod/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pyp_id, label: obj.period_code }),
          );
          this.setState({ arrPeriod: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle() {
    this.props.initialize({
      period: this.state.period,
      component: this.state.component,
      search: this.state.search,
    });
    this.setState({
      pecValue: 0,
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    this.setState({ buttonSave: true });
    const { apiws, token, iduser, pecValue } = this.state;
    const id = values.pec_id;
    const val = values.pec_value;
    let rupiah;
    let data;
    if (val !== pecValue) {
      rupiah = pecValue;
      data = { ...values, pec_value: rupiah, muser_id: iduser };
    } else {
      data = { ...values, muser_id: iduser };
    }
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const url = `${apiws}/payempcomponent/updateItem/${id}`;
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(url, formBody, config)
      .then((resinput) => {
        if (resinput.data.status === 'ok') {
          this.toggle();
          showNotifications('Success', 'Save Success', 'success');
          this.child.reload();
          this.setState({
            pecValue: 0,
            buttonSave: false,
          });
          this.props.initialize({
            period: this.state.period,
            component: this.state.component,
            search: this.state.search,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  onChangePeriod(e) {
    if (typeof e.value === 'number') {
      this.setState(
        {
          period: e.value,
        },
        () => {
          setTimeout(() => {
            this.child.reload();
          }, 50);
        },
      );
    } else {
      this.setState({ period: '' });
    }
  }

  onChangeComponent(e) {
    if (typeof e.value === 'number') {
      this.setState(
        {
          component: e.value,
        },
        () => {
          setTimeout(() => {
            this.child.reload();
          }, 50);
        },
      );
    } else {
      this.setState({ component: '' });
    }
  }

  editRows(id) {
    const { apiws, token } = this.state;
    this.setState({ buttonDisabled: true });
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payempcomponent/getAllItembyid/${id}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        this.toggle();
        this.setState({
          statusEdit: true,
          pecValue: dataResponse[0].pec_value,
        });
        this.props.initialize({
          pec_value: dataResponse[0].pec_value,
          pec_id: dataResponse[0].pec_id,
        });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/token/isOtp`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.otp) {
            this.setState({ validateToken: true });
          }
        }
      })
      .catch((error) => {
        // showNotifications('Fail', 'Delete Failed', 'danger');
      });
  }

  render() {
    const {
      statusEdit,
      arrComponent,
      arrPeriod,
      pecValue,
      buttonDisabled,
      validateToken,
      buttonSave,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    const button_action = t('FRM.SAVE');
    let hiddenPecId;
    if (statusEdit) {
      hiddenPecId = (
        <Field name="pec_id" component={renderTextInput} type="hidden" />
      );
    }
    if (validateToken === false) {
      return <Authenticator checkAuth={this.checkAuth} />;
    }
    return (
      <Container>
        <Card>
          <CardBody style={{ padding: '20px 10px 30px 10px' }}>
            <ViewListTable
              url={this.state.urlData}
              heads={this.heads}
              primaryKey="pec_id"
              widthTable={this.state.dataWidth}
              updateFunc={this.editRows}
              buttonAction="updateOnly"
              displayStyleHead="none"
              onRef={(ref) => (this.child = ref)}
              component={true}
              arrPeriod={this.state.arrPeriod}
              arrComponent={this.state.arrComponent}
              onChangeComponent={this.onChangeComponent}
              onChangePeriod={this.onChangePeriod}
              conds={{
                period: this.state.period,
                component: this.state.component,
              }}
              pageTitle={t('LBL.PAY_COMPONENT_EMPLOYEE')}
              editCond={this.state.editcond}
            />
          </CardBody>
        </Card>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={modalStyle}
        >
          <ModalHeader toggle={this.toggle}>
            {' '}
            {t('FRM.EDIT')} {t('LBL.VALUE')}{' '}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="payempcomponent"
              id="payempcomponent"
            >
              {hiddenPecId}
              <Field
                name="pec_value"
                component={renderTextInput}
                type="hidden"
              />
              <Container>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.VALUE')}
                      </span>
                      <div className="form__form-group-field">
                        <NumberFormat
                          style={{ textAlign: 'right' }}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale="2"
                          autoComplete="off"
                          prefix={'Rp '}
                          value={pecValue}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return (
                              formattedValue === '' ||
                              floatValue <= 1000000000000
                            );
                          }}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            this.setState({ pecValue: value });
                            if (pecValue !== value) {
                              this.setState({ buttonDisabled: false });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              {t('FRM.CANCEL')}
            </Button>
            {buttonSave ? (
              <Button color="primary">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: '6px' }}
                />
                Loading...
              </Button>
            ) : (
              <Button
                color="primary"
                form="payempcomponent"
                type="submit"
                disabled={buttonDisabled}
              >
                {' '}
                Save
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.workflow) {
    errors.workflow = 'Workflow field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'workflowforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(ComponentPayrollEmployee));
