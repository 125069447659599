import React, {
  useState,
  useRef,
  useEffect,
  createRef,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faPencilAlt,
  faBox,
  faBoxOpen,
  faEllipsisV,
  faTrashAlt,
  faCalendar,
  faPaperclip,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import DragIcon from 'mdi-react/DragIcon';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';

import './styles/taskItem.scss';
import '../styles/styleModal.scss';
import moment from 'moment';
import axios from 'axios';
import ls from 'local-storage';
import { connectWs } from '../../../../socket';
import Swal from 'sweetalert2';

const PRIORITY_STATUS = { 1: 'High', 2: 'Medium', 3: 'Low' };
const STATUS = { 0: 'Active', 1: 'Done', 2: 'Archive' };
const regexJPG = /\.(jpe?g|png)$/i;
let selectRef = null;

const TaskTableItemChild = (props, ...args) => {
  const [note, setNote] = useState('');
  const [data, setData] = useState([]);
  const [comments, setComment] = useState([]);
  const [detailTask, setDetailTask] = useState([]);
  const [file, setFile] = useState(null);

  // init ref
  const refSocket = useRef(null);

  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
    usr_id: ls.get('user_cred').usr_id,
    emp_id: ls.get('user_cred').emp_id,
  });
  const {
    t,
    id,
    toggleOffCanvas,
    toggleNotification,
    getDetailTask,
    getDataSection,
    user,
    priority,
  } = props;
  const initWebSocket = useCallback(() => {
    const onWSMessage = (message) => {
      const jsonMessage = JSON.parse(message.data);
      setComment((prev) => [jsonMessage.data, ...prev]);
    };

    const { socket, socketTimeout } = connectWs(
      'wss://service.phirogo.com/ws',
      onWSMessage,
    );
    refSocket.current = { socket, socketTimeout };
  }, [comments]);

  const sendNotes = () => {
    if (!note.trim() && file == null) return false;

    const formData = new FormData();
    formData.append('pta_id', detailTask?.pta_id);
    formData.append('pse_id', detailTask?.pse_id);
    formData.append('attachment', file);
    formData.append('notes', note);

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(`${auth.apiws}/task/sendComment`, formData, config)
      .then((response) => {
        // setIsLoading(false);
        if (response.data.status == 'ok') {
          setFile(null);
          setNote('');
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
  };

  const getCommentList = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/task/getCommentsTask/${detailTask?.pta_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          setComment(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateArchive = (id) => {
    const formData = new FormData();
    formData.append('pse_id', data.pse_id);
    formData.append('pps_id', data.pps_id);
    formData.append('pta_id', data.pta_id);
    formData.append('tab', props.tab);

    let url = `${auth.apiws}/task/updateArchive/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unArchiveTask = (id) => {
    const formData = new FormData();
    formData.append('pse_id', data.pse_id);
    formData.append('pps_id', data.pps_id);
    formData.append('pta_id', data.pta_id);
    formData.append('tab', props.tab);

    let url = `${auth.apiws}/task/unArchiveTask/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTask = (id) => {
    let url = `${auth.apiws}/task/deleteTask/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateDoneTask = (id, status) => {
    let url;

    const formData = new FormData();
    formData.append('pse_id', data.pse_id);
    formData.append('pps_id', data.pps_id);
    formData.append('pta_id', data.pta_id);
    formData.append('tab', props.tab);

    if (status == 0) {
      url = `${auth.apiws}/task/updateDoneTask/${id}`;
    } else if (status == 1) {
      url = `${auth.apiws}/task/updateUnDoneTask/${id}`;
    }
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          if (status == 0) {
            toggleNotification();
          }
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [modalDetail, setModalDetail] = useState(false);
  const toggles = () => setModalDetail(!modalDetail);
  const [fileName, setFileName] = useState('');

  const hiddenFileInput = useRef(null);
  const handleClick = () => hiddenFileInput.current.click();
  const handleFileRemove = () => setFile(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const allowedType = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/jpeg',
      'application/pdf',
    ];

    if (fileUploaded) {
      const fileType = fileUploaded.type;
      const fileSize = fileUploaded.size;
      const allowedSize = 5000; // in kilobytes

      if (!allowedType.includes(fileType)) {
        Swal.fire({
          title: 'Warning',
          text: 'File type not allowed!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      if (Math.ceil(fileSize / 1000) > allowedSize) {
        Swal.fire({
          title: 'Warning',
          text: 'Maximum file size 5 MB!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      setFile(fileUploaded);
    }
  };

  const [tableEdit, setTableEdit] = useState({
    taskTitle: false,
    assignee: false,
    dueDate: false,
    taskGiver: false,
    status: false,
    priority: false,
  });

  useEffect(() => {
    if (detailTask?.pta_id) {
      getCommentList(); // get list comment by id task
    }
  }, [detailTask]);

  useEffect(() => {
    setData(props.data);
    setSelectAssignee({
      value: data?.task_assignee,
      label: data?.emp_name_assignee,
    });
    setSelectPriority({
      value: data?.tpr_id,
      label: PRIORITY_STATUS[data?.tpr_id],
    });
  }, [props.data]);

  useEffect(() => {
    initWebSocket();

    return () => {
      refSocket.current.socket.close();
      clearTimeout(refSocket.current.socketTimeout);
    };
  }, []);

  useEffect(() => {
    const formData = new FormData();
    console.log(data, 'ini');
    formData.append('title', textValue);
    formData.append('assignee', selectAssignee.value);
    formData.append(
      'end_date',
      selectDueDate ? moment(selectDueDate).format('YYYY-MM-DD') : null,
    );
    formData.append('priority', selectPriority.value);
    if (selectAssignee.value == auth.emp_id) {
      formData.append('pps_id', data?.pps_id);
    }
    // formData.append('pse_id', data?.pse_id);
    formData.append('cuser_id', auth?.usr_id);
    formData.append('tab', props.tab);

    let url = `${auth.apiws}/Task/updateItem/${data?.pta_id}`;

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (isUpdate) {
      axios
        .post(url, formData, config)
        .then((response) => {
          getDataSection();
          setUpdateFlag(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tableEdit]);

  //----- State Edit Table Value --------
  const inputRef = useRef(null);
  const dateRef = useRef(null);
  const [textValue, setTextValue] = useState(data?.task_name);
  const [selectAssignee, setSelectAssignee] = useState({
    value: data?.task_assignee,
    label: data?.emp_name_assignee,
  });
  const [selectDueDate, setSelectDueDate] = useState(
    data?.task_finish ? moment(data?.task_finish) : null,
  );
  const [selectPriority, setSelectPriority] = useState({
    value: data?.tpr_id,
    label: PRIORITY_STATUS[data?.tpr_id],
  });
  const [isUpdate, setUpdateFlag] = useState(false);

  const tableEditEvent = (col) => {
    if (col == 'taskTitle') {
      setTableEdit({ ...tableEdit, taskTitle: !tableEdit.taskTitle });
    }

    if (col == 'assignee') {
      setTableEdit({ ...tableEdit, assignee: !tableEdit.assignee });
    }

    if (col == 'dueDate') {
      setTableEdit({ ...tableEdit, dueDate: !tableEdit.dueDate });
    }

    if (col == 'taskGiver') {
      setTableEdit({ ...tableEdit, taskGiver: !tableEdit.taskGiver });
    }

    if (col == 'status') {
      setTableEdit({ ...tableEdit, status: !tableEdit.status });
    }

    if (col == 'priority') {
      setTableEdit({ ...tableEdit, priority: !tableEdit.priority });
    }
  };

  console.log(data?.emp_id_assigner == auth?.emp_id, data?.admin_access);
  return (
    <>
      {data != false && (
        <>
          <td>
            <DragIcon style={{ height: 14, width: 14 }} />
          </td>
          <td>
            <div
              className="d-flex gap-3"
              style={{
                flexWrap: tableEdit.taskTitle ? 'wrap' : 'nowrap',
                height: tableEdit.taskTitle ? 50 : null,
              }}
            >
              <Field
                name={`checkTask-${id}`}
                component={renderCheckBoxField}
                props={{
                  input: {
                    value: data?.task_status == 1 ? true : false,
                    onChange: (e) => {
                      if (e?.type == 'change') {
                        updateDoneTask(data?.pta_id, data?.task_status);
                      }
                    },
                  },
                }}
                disabled={data?.is_edit == true ? false : true}
              />
              {tableEdit.taskTitle == true ? (
                <div className="form">
                  <Field
                    name="title"
                    id="title"
                    component={renderTextInput}
                    props={{
                      input: {
                        onChange: (e) => setTextValue(e.target.value),
                        onBlur: () => {
                          tableEditEvent('taskTitle');
                          setUpdateFlag(true);
                        },
                        onKeyDown: (val) => {
                          setUpdateFlag(true);
                          setTextValue(val);
                          tableEditEvent('taskTitle');
                        },
                        value: textValue,
                        ref: inputRef,
                      },
                      meta: {
                        touched: true,
                        error:
                          textValue.length < 1
                            ? "Title shouldn't be empty"
                            : null,
                      },
                    }}
                    maxLength={90}
                    type="text-table-edit"
                    style={{
                      backgroundColor: '#ffffff',
                    }}
                  />
                </div>
              ) : (
                <p
                  className={`item__title my-0 ${
                    data?.task_status == '1' ? 'line__through' : ''
                  }`}
                  onDoubleClick={() => {
                    if (data.emp_id_assigner == auth.emp_id) {
                      setTextValue(data?.task_name);
                      tableEditEvent('taskTitle');
                      setTimeout(() => {
                        inputRef.current.focus();
                      }, 100);
                    }
                  }}
                >
                  {textValue || data?.task_name}
                </p>
              )}
            </div>
          </td>
          <td>
            {tableEdit.assignee == true ? (
              <>
                <div className="form">
                  <Field
                    name="assignee"
                    component={renderSelectField}
                    isClearable={false}
                    className="select-status"
                    options={user}
                    props={{
                      input: {
                        value: selectAssignee,
                        onChange: (e) => {
                          setSelectAssignee(e);
                          tableEditEvent('assignee');
                          setUpdateFlag(true);
                        },
                        onBlur: () => tableEditEvent('assignee'),
                        ref: (ref) => (inputRef.current = ref),
                        saveOnBlur: true,
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="d-flex align-items-center"
                  onDoubleClick={() => {
                    if (data.emp_id_assigner == auth.emp_id) {
                      tableEditEvent('assignee');
                      setTimeout(() => {
                        inputRef.current?.select?.inputRef?.focus();
                      }, 100);
                    }
                  }}
                >
                  <img
                    src={
                      data?.photo_assignee ??
                      process.env.PUBLIC_URL + '/img/user-pink.svg'
                    }
                    width={24}
                    height={24}
                    style={{
                      borderRadius: '999px',
                      marginRight: 8,
                      objectFit: 'cover',
                    }}
                  />
                  <p className="my-0">
                    {selectAssignee?.label || data?.emp_name_assignee}
                  </p>
                </div>
              </>
            )}
          </td>
          <td>
            {tableEdit.dueDate == true ? (
              <>
                <div className="form">
                  <Field
                    name="dueDate"
                    component={renderDatePickerField}
                    props={{
                      dateFormat: 'DD MMM YYYY',
                      input: {
                        value: selectDueDate,
                        onChange: (e) => {
                          if (moment(e).format('YYYY-MM-DD') < data?.task_start)
                            return false;
                          e != null
                            ? setSelectDueDate(moment(e))
                            : setSelectDueDate(null);
                          setTableEdit('dueDate');
                          setUpdateFlag(true);
                        },
                        onBlur: (val) => {
                          val
                            ? setSelectDueDate(moment(val))
                            : setSelectDueDate(null);
                          setTableEdit('dueDate');
                          setUpdateFlag(true);
                        },
                        ref: (ref) => (dateRef.current = ref),
                      },
                    }}
                    value={data?.task_finish}
                  />
                </div>
              </>
            ) : (
              <>
                <p
                  className="my-0"
                  onDoubleClick={() => {
                    if (data.emp_id_assigner == auth.emp_id) {
                      tableEditEvent('dueDate');
                      setTimeout(() => {
                        dateRef.current?.input?.focus();
                      }, 100);
                    }
                  }}
                >
                  {data?.task_finish
                    ? moment(selectDueDate || data?.task_finish).format(
                        'DD MMM YYYY',
                      )
                    : '-'}
                </p>
              </>
            )}
          </td>
          <td>
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    data?.photo_assigner ??
                    process.env.PUBLIC_URL + '/img/user-pink.svg'
                  }
                  width={24}
                  height={24}
                  style={{
                    borderRadius: '999px',
                    marginRight: 8,
                    objectFit: 'cover',
                  }}
                />
                <p className="my-0">{data?.emp_name_assigner}</p>
              </div>
            </>
          </td>
          <td>
            <>
              <p className="my-0">{STATUS[data?.task_status]}</p>
            </>
          </td>
          <td>
            {tableEdit.priority == true ? (
              <>
                <div className="form">
                  <Field
                    name="priority"
                    component={renderSelectField}
                    className="select-priority"
                    options={priority}
                    props={{
                      input: {
                        value: selectPriority,
                        onChange: (e) => {
                          setSelectPriority(e);
                          setTableEdit('priority');
                          setUpdateFlag(true);
                        },
                        onBlur: () => {
                          tableEditEvent('priority');
                          setUpdateFlag(true);
                        },
                        saveOnBlur: true,
                        ref: (ref) => (selectRef = ref),
                      },
                      openMenuOnFocus: true,
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <p
                  className="my-0"
                  onDoubleClick={() => {
                    if (data.emp_id_assigner == auth.emp_id) {
                      tableEditEvent('priority');
                      setTimeout(() => {
                        selectRef?.select?.inputRef?.focus();
                      }, 200);
                    }
                  }}
                >
                  {data?.tpr_id ? (
                    <p
                      className={`priority_${PRIORITY_STATUS[
                        selectPriority.value || data?.tpr_id || '1'
                      ].toLowerCase()}`}
                    >
                      {PRIORITY_STATUS[selectPriority.value || data?.tpr_id]}
                    </p>
                  ) : (
                    <p className="text-center">-</p>
                  )}
                </p>
              </>
            )}
          </td>
          <td>
            {data?.is_edit == true && (
              <>
                <UncontrolledDropdown id={`task_${data?.pta_id}`}>
                  <DropdownToggle className="btn btn-sm btn-data-table btn-outline-primary">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </DropdownToggle>
                  <DropdownMenu {...args}>
                    <>
                      <DropdownItem
                        onClick={() => {
                          setDetailTask(data);
                          toggles();
                        }}
                        className="mb-1"
                      >
                        <FontAwesomeIcon className="text-muted" icon={faEye} />
                        <span style={{ marginLeft: '0.90rem' }}>
                          {t('LBL.DETAIL')}
                        </span>
                      </DropdownItem>
                      {data?.task_status == 2 ? (
                        data?.emp_id_assigner == auth.emp_id ||
                        data?.admin_access ? (
                          <>
                            <DropdownItem
                              className="mt-1"
                              onClick={() => deleteTask(data?.pta_id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="text-danger"
                              />
                              <span className="ml-3">
                                {t('LBL.DELETETASK')}
                              </span>
                            </DropdownItem>
                            <DropdownItem
                              className="mt-1"
                              onClick={() => unArchiveTask(data?.pta_id)}
                            >
                              <FontAwesomeIcon
                                icon={faBoxOpen}
                                className="text-secondary"
                              />
                              <span style={{ marginLeft: '0.70rem' }}>
                                {t('LBL.UNARCHIVETASK')}
                              </span>
                            </DropdownItem>
                          </>
                        ) : (
                          ''
                        )
                      ) : data?.emp_id_assigner == auth.emp_id ||
                        data?.admin_access ? (
                        <>
                          <DropdownItem
                            onClick={() => {
                              toggleOffCanvas('edit');
                              getDetailTask(data?.pta_id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="text-warning"
                              icon={faPencilAlt}
                            />
                            <span className="ml-3 ">{t('LBL.EDITTASK')}</span>
                          </DropdownItem>

                          <DropdownItem
                            className="mt-1"
                            onClick={() => updateArchive(data?.pta_id)}
                          >
                            <FontAwesomeIcon
                              icon={faBox}
                              className="text-primary"
                            />
                            <span className="ml-3">{t('LBL.ARCHIVETASK')}</span>
                          </DropdownItem>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledTooltip
                  placement="top"
                  target={`task_${data?.pta_id}`}
                >
                  {t('LBL.MORE')}
                </UncontrolledTooltip>
              </>
            )}
          </td>

          {/* START MODAL TASK DETAIL  */}
          <Modal
            isOpen={modalDetail}
            toggle={toggles}
            size="lg"
            className="modal__TaskDetail"
          >
            <ModalHeader className="">
              <div className="d-flex w-100">
                <div className="mr-auto">{detailTask?.task_name}</div>
                <button className="send__chat" onClick={toggles}>
                  <img src="/img/close-icon.svg" width={15} alt="" />
                </button>
              </div>
            </ModalHeader>
            <ModalBody>
              <Row className="p-3">
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.DESCRIPTION')}</p>
                        <div className="desc__">
                          {detailTask?.task_description ?? '-'}
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.TASKASSIGNEE')}</p>
                        <div className="d-flex align-items-center">
                          <img
                            src={detailTask?.photo_assignee}
                            className="avatar_assign"
                            alt=""
                          />
                          <div className="ml-2 fw-bold">
                            {' '}
                            {detailTask?.emp_name_assignee ?? '-'}
                          </div>
                        </div>
                      </div>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.ASSIGNER')}</p>
                        <div className="d-flex align-items-center">
                          <img
                            src={detailTask?.photo_assigner}
                            className="avatar_assign"
                            alt=""
                          />
                          <div className="ml-2 fw-bold">
                            {' '}
                            {detailTask?.emp_name_assigner ?? '-'}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.DUEDATE')}</p>
                        <div className="d-flex align-items-center">
                          <span>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="text-danger"
                            />
                          </span>
                          <div className="ml-2 fw-bold">
                            {detailTask?.task_finish
                              ? moment(detailTask?.task_finish).format(
                                  'D MMM Y',
                                )
                              : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.PRIORITY')}</p>
                        <div
                          className={
                            'd-flex align-items-center status__' +
                            detailTask?.priority_name +
                            ''
                          }
                        >
                          {detailTask?.priority_name ?? '-'}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mt-2">
                  <h5>{t('LBL.ACTIVITY')}</h5>
                  <div className="d-flex align-items-center mt-3 ">
                    <img
                      src={
                        ls.get('user_cred').path_photo
                          ? ls.get('ws_ip') +
                            '/public/uploads' +
                            ls.get('user_cred').path_photo
                          : '/img/icon-avatar-user.svg'
                      }
                      className="user__avatar"
                      alt="avatar-user"
                    />
                    <div className="mx-2 form w-100">
                      <textarea
                        value={note}
                        rows={1}
                        maxLength={1000}
                        placeholder={'Enter Comment'}
                        onKeyDown={handleMultiRowText}
                        onChange={(event) => setNote(event.target?.value)}
                        style={{ minHeight: 32 }}
                      />
                    </div>
                    <button onClick={handleClick} className="send__chat">
                      <span style={{ fontSize: '20px', color: '#999999' }}>
                        <FontAwesomeIcon icon={faPaperclip} />
                      </span>
                    </button>
                    <input
                      type="file"
                      onChange={handleChange}
                      component={renderTextInput}
                      ref={hiddenFileInput}
                      style={{ display: 'none' }}
                    />
                    <button className="send__chat" onClick={() => sendNotes()}>
                      <img src="/img/icon-send.svg" alt="" />
                    </button>
                  </div>
                  <div className="mt-3 mb-2 ">
                    {file?.name && (
                      <div className="preview__upload d-flex align-items-center p-2">
                        <div className="mr-auto d-flex align-items-center">
                          <img
                            src={`/img/${
                              regexJPG.test(file?.name) ? 'image' : 'pdf'
                            }-icon.svg`}
                            className="mr-2"
                            width={20}
                            alt=""
                          />
                          <div>{file?.name}</div>
                        </div>
                        <button
                          className="send__chat"
                          href="#"
                          onClick={handleFileRemove}
                        >
                          <img src="/img/close-icon.svg" width={15} alt="" />
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="scrolls">
                    {comments?.map((item) => (
                      <div className="d-flex align-items-center my-3">
                        <div className="w-100 d-flex">
                          <img
                            src={
                              item?.path_photo
                                ? item?.path_photo
                                : ls.get('ws_ip') +
                                  '/public/uploads/img/icon-avatar-user.svg'
                            }
                            className="user__avatar"
                            alt=""
                          />
                          <div className="w-100 ml-2 notes">
                            <div className="d-flex justify-content-between">
                              <div className="sender__chat">
                                {item?.emp_name}
                              </div>
                              <div className="date__and__time">
                                {item?.ctime}
                              </div>
                            </div>
                            <div className="desc__ d-flex align-items-center">
                              <img
                                src={
                                  item?.file_type == 'pdf'
                                    ? '/img/pdf-icon.svg'
                                    : item?.file_type == 'image'
                                    ? '/img/image-icon.svg'
                                    : ''
                                }
                                className="mr-2"
                                width={20}
                                alt=""
                              />
                              <a
                                className="text-decoration-none"
                                target="_blank"
                                href={
                                  item?.attachment
                                    ? ls.get('ws_ip') + item?.attachment
                                    : '#'
                                }
                              >
                                {item?.file_name}
                              </a>
                            </div>
                            <div className="desc__">{item?.notes}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          {/* END MODAL TASK DETAIL */}
        </>
      )}
    </>
  );
};

function handleMultiRowText(e) {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
}

export default reduxForm({
  form: 'formEditTaskTableView', // must identik
})(TaskTableItemChild);
