/* eslint-disable no-unused-var, quote-props, react/no-did-mount-set-state, no-return-assign */

import React, { PureComponent } from 'react';
import {
  Container, Row, Col, Button,
  ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../Preferences/notificationMessages';
import renderTextInput from '../../shared/components/form/TextInput';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import ViewListTable from '../../shared/components/table/viewListTable';
import utils from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/fontawesome-free-solid';

const translator = () => { };
class KpiTrackingProblemIssueForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    issuePpi: PropTypes.func.isRequired,
    issuePpd: PropTypes.func.isRequired,
    issuePph: PropTypes.func.isRequired,
    issueKpi: PropTypes.func.isRequired,
    issueEmp: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
      { '3': t('LBL.PHASE') },
      { '4': t('FRM.FUNCTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '35%', '35%', '10%', '15%'],
      showTableIssue: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideTable = this.hideTable.bind(this);
    this.showTable = this.showTable.bind(this);
    this.deleteRows = this.deleteRows.bind(this);
    this.editRows = this.editRows.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ urlData: `${ls.get('ws_ip')}/pfmkpiplanissue/getAllitem` });
    setTimeout(() => {
      this.getTableData();
    }, 300);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
      { '3': t('LBL.PHASE') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  handleSubmit(values) {
    const {
      apiws,
      token,
    } = this.state;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const action = 'POST';
    let link;
    const id = (typeof values.pli_id === 'undefined') ? '' : values.pli_id;
    if (id === '') {
      link = `${apiws}/pfmkpiplanissue/saveItem`;
    } else {
      link = `${apiws}/pfmkpiplanissue/updateItem/${id}`;
    }
    const body = [
      `ppd_id=${this.props.issuePpd}`,
      `issue=${values.issue}`,
      `action=${values.action}`,
      `pli_id=${id}`].join('&');
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    this.setState({ displaySpinnerIssue: true });
    axios(options).then(() => {
      showNotifications('Success', 'Save Success', 'success');
      this.setState({ showTableIssue: true });
      this.setState({ displaySpinnerIssue: false });
      this.getTableData();
    });
  }

  hideTable() {
    this.props.destroy();
    this.setState({
      showTableIssue: false,
    });
  }

  showTable() {
    this.setState({
      showTableIssue: true,
    });
    setTimeout(() => {
      this.getTableData();
    }, 100);
  }

  editRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/pfmkpiplanissue/getAllitemById/${id}`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        this.hideTable();
        this.props.initialize({
          pli_id: dataResponse.pli_id,
          issue: dataResponse.problem_issue,
          action: dataResponse.counter_action,
        });
      }
    });
  }

  deleteRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/pfmkpiplanissue/deleteItem/${id}`,
          '',
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            this.child.reload('delete');
            showNotifications('Success', 'Delete Success', 'success');
          } else {
            showNotifications('Fail', 'Delete Fail', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Fail', 'danger');
        });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;

    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.hideTable}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        <FontAwesomeIcon icon={faPlus} />{t('FRM.ADD')}
      </Button>
    );

    return (
      <div>
        <ModalHeader>
          {t('LBL.PROBLEM_ISSUES')} {t('LBL.FORM')}
        </ModalHeader>
        <ModalBody>
          <form
            className="form form--horizontal"
            onSubmit={handleSubmit(this.handleSubmit)}
            name="addissue"
            id="addissue"
          >
            <Container>
              {(this.state.showTableIssue) ? (
                <Row>
                  <Col>
                    <ViewListTable
                      url={this.state.urlData}
                      displayStyleHead=""
                      textHead={`KPI : ${this.props.issueKpi}`}
                      addBtn={addBtn}
                      heads={this.heads}
                      primaryKey="pli_id"
                      flagKey="flag"
                      buttonAction={['edit', 'delete']}
                      conds={{ ppi_id: this.props.issuePpi, pph_id: this.props.issuePph, emp_id: this.props.issueEmp }}
                      widthTable={this.state.dataWidth}
                      onRef={ref => (this.child = ref)}
                      deleteFunc={this.deleteRows}
                      updateFunc={this.editRows}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('LBL.PROBLEM_ISSUES')}</span>
                      <div className="form__form-group-field" style={{ marginBottom: '-10px', marginTop: '-5px' }}>
                        <input type="hidden" name="pli_id" value="" />
                        <Field
                          name="issue"
                          component={renderTextInput}
                          type="textarea"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('LBL.COUNTER_ACTION')}</span>
                      <div className="form__form-group-field" style={{ marginBottom: '-10px', marginTop: '-5px' }}>
                        <Field
                          name="action"
                          component={renderTextInput}
                          type="textarea"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </form>
        </ModalBody>
        {this.state.showTableIssue === false &&
          <ModalFooter>
            <div style={{ textAlign: 'center' }}>
              <Button color="secondary" onClick={this.showTable}>{t('FRM.CANCEL')}</Button>
              {(this.state.displaySpinnerIssue) ? (
                <Button color="primary">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '6px' }}
                  />
                  Loading...
                </Button>
              ) : (
                <Button
                  color="primary"
                  form="addissue"
                  type="submit"
                  disabled={pristine}
                > {t('FRM.SAVE')}
                </Button>
              )}
            </div>
          </ModalFooter>
        }
      </div>
    );
  }
}

const validate = (values) => {
  const validation = {
    issue: {
      fieldLabel: `${translator('LBL.PROBLEM_ISSUES')}`,
      required: true,
    },
    action: {
      fieldLabel: `${translator('LBL.COUNTER_ACTION')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'kpitrackingproblemissue',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KpiTrackingProblemIssueForm));
