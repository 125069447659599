/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */

import React, { PureComponent } from 'react';
import { Container, Card } from 'reactstrap'; // Row, Nav, NavItem, NavLink, TabContent, TabPane
// import classnames from 'classnames';
import { translate } from 'react-i18next';
import Setting from './Setting';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import Authenticator from '../../Authenticator';
import axios from 'axios';

// const HeaderIcon = `${process.env.PUBLIC_URL}/img/preference_icon/icon-attendance-setting.png`;

class ReportPayroll extends PureComponent {
  static propTypes = {
    // format: PropTypes.string.isRequired,
    // t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      men_id: '',
      fga_id: '',
      validateToken: false,
    };

    this.checkAuth = this.checkAuth.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    console.log(location);
    if (typeof location.men_id === 'undefined' && typeof location.fga_id === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({ men_id: location.state.men_id, fga_id: location.state.fga_id });
    }
    this.checkAuth();
  }

  // static getDerivedStateFromProps(props) {
  //   if (props.format !== undefined) {
  //     console.log(props.format);
  //     return {
  //       page: props.format,
  //     };
  //   }

  //   return null;
  // }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/token/isOtp`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        if (response.data.otp) {
          this.setState({ validateToken: true });
        }
      }
    }).catch((error) => {
      // showNotifications('Fail', 'Delete Failed', 'danger');
    });
  }

  render() {
    // const { t } = this.props;
    const { validateToken } = this.state;

    if (validateToken === false) {
      return (
        <Authenticator checkAuth={this.checkAuth} />
      );
    }
    return (
      <Container>
        <Card>
          <div className="p0">
            { /* <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="attendance" />
                  <h3 className="page-title">Setting Payroll Periode</h3>
                  <h3 className="page-subhead subhead">Setting your payroll</h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {t('LBL.SETTING_PERIOD')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Setting />
                  </TabPane>
                </TabContent>
              </div>
            </div> */ }
            <Setting men_id={this.state.men_id} fga_id={this.state.fga_id} />
          </div>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(ReportPayroll);
