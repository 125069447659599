/* eslint-disable max-len, arrow-body-style, consistent-return, react/no-array-index-key */
/* eslint-disable class-methods-use-this, react/no-did-mount-set-state, no-unused-vars */

import React, { PureComponent } from 'react';
import { Container, Row, Col, CardBody, Card, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import renderTextInput from '../../shared/components/form/TextInput';
import config from '../../shared/components/config/config';
import Spinner from 'react-bootstrap/Spinner';
import showNotifications from '../../containers/Preferences/notificationMessages';

class Authenticator extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    checkAuth: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    backBtn: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      otp: new Array(6).fill(''),
      displayLoad: 'none',
      displaySave: '',
      subdomain: config.subdomain,
      apiws: '',
      userId: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ userId: ls.get('user_cred').usr_id });
  }

  handleChange(e, index) {
    if (!e.target.value) {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      } else {
        return false;
      }
    } else if (e.target.nextSibling) {
      // Focus next input
      e.target.nextSibling.focus();
    }
    this.setState({
      otp: [
        ...this.state.otp.map((d, idx) => (idx === index ? e.target.value : d)),
      ],
    });
  }

  handleClear() {
    this.setState({ otp: [...this.state.otp.map((v) => '')] });
  }

  handleSubmit(v) {
    const { subdomain, apiws, userId } = this.state;
    const token = `${v.otp0}${v.otp1}${v.otp2}${v.otp3}${v.otp4}${v.otp5}`;
    fetch(`${apiws}/auth/validate`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `subdomain=${subdomain}&usr_id=${userId}&token=${token}`,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 'SUCCESS') {
            ls.set('token', result.new_token);
            this.props.checkAuth();
          } else {
            showNotifications('Fail', result.message, 'danger');
          }
        },
        () => {
          // console.log(error);
        },
      );
  }

  render() {
    const { handleSubmit, reset, backBtn, t } = this.props;
    const { otp, displayLoad, displaySave } = this.state;
    return (
      <div className="container-fluid home-fluid">
        <div className="home-header">
          <Container>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div className="package-header">
                  <h4>
                    <span className="lnr lnr-warning" />{' '}
                    <b>{t('LBL.FOR_SECURITY_REASON')}</b>
                  </h4>
                  <p>{t('LBL.PLEASE_INSERT_OTP')}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="dashboard" style={{ marginTop: '-70px' }}>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Card>
                <CardBody className="p0">
                  <Row className="m5">
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div
                          style={{
                            display: 'table',
                            width: '100%',
                            // height: 'calc(100vh - 250px)',
                          }}
                        >
                          <div
                            style={{
                              display: 'table-cell',
                              verticalAlign: 'middle',
                            }}
                          >
                            <div style={{ width: '100%', margin: 'auto' }}>
                              <div
                                style={{
                                  padding: 20,
                                  textAlign: 'center',
                                }}
                              >
                                {otp.map((data, index) => {
                                  return (
                                    <Field
                                      component={renderTextInput}
                                      maxLength="1"
                                      value={data}
                                      name={`otp${index}`}
                                      onChange={(e) =>
                                        this.handleChange(e, index)
                                      }
                                      key={index}
                                      type="otp"
                                    />
                                  );
                                })}
                              </div>
                              <Row style={{ justifyContent: 'center' }}>
                                {backBtn}
                                <Button
                                  color="secondary"
                                  type="button"
                                  className="btn-sm"
                                  onClick={reset}
                                >
                                  {t('LBL.CLEAR')}
                                </Button>
                                <Button
                                  color="primary"
                                  type="submit"
                                  className="btn-sm"
                                  style={{
                                    display: displaySave,
                                    marginRight: 0,
                                  }}
                                >
                                  {t('LBL.VERIFY')}
                                </Button>
                                <Button
                                  color="primary"
                                  style={{ display: displayLoad }}
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '6px' }}
                                  />
                                  {t('FRM.LOADING')}
                                </Button>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: 'auth_form', // a unique identifier for this form
})(translate('global')(Authenticator));
