import React, { useEffect, useLayoutEffect, useState } from 'react';
import LockScreenForm from './components/LockScreenForm';
import { connect } from 'react-redux';
import { store } from '../../App/store';
import { faLock, faSignOutAlt } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';

import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import './components/lockscreen.css';

const LockScreen = ({ user, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  let isLocked = user?.isLocked;
  if (window.location.pathname == '/logout') {
    if (user.isLocked) {
      store.dispatch({ type: 'SET_USER_RELOGIN', data: false });
      isLocked = false;
    }
  }

  const toggle = () => {
    setIsOpen(false);
  };

  const clearLocalStorage = () => {
    localStorage.clear();
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isLocked}
        className="modal-dialog modal-input"
        backdropClassName="modal-lockscreen"
        style={{
          maxWidth: '500px',
          marginTop: '100px',
          borderRadius: '50%',
        }}
        id="modal-lockscreen"
      >
        <ModalHeader toggle={toggle} style={{ paddingLeft: '25px' }}>
          <FontAwesomeIcon
            icon={faLock}
            style={{ color: '#1974EF', marginRight: '6px' }}
          />
          Access Relogin
        </ModalHeader>
        <ModalBody>
          <Container>
            <LockScreenForm data={user?.data} />
          </Container>
        </ModalBody>
        <ModalFooter style={{ paddingRight: '5%', fontSize: '13px' }}>
          <a onClick={clearLocalStorage} href="/logout">
            <FontAwesomeIcon
              icon={faSignOutAlt}
              style={{ color: '#1974EF', marginRight: '6px' }}
            />
            Logout
          </a>
        </ModalFooter>
      </Modal>
      {/* {!isLocked && children} */}

      {/* <div style={user?.isLocked ? { display: 'none' } : { display: 'block' }}>
        {children}
      </div> */}
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(LockScreen);
