/* eslint-disable no-mixed-operators, react/no-unused-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import NewsAndUpdatesList from './Components/index';

const HeaderIcon = '/img/preference_icon/icon-news-setting.png';

class NewsAndUpdates extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      menu: [],
      token: '',
      apiws: '',
      idUser: '',
      men_id: '',
      fga_id: '',
    };
  }

  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getReference();
    });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({
        men_id: location.state.men_id,
        fga_id: location.state.fga_id,
      });
    }
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(`${apiws}/menu/getreference/`, formBody, config).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map((obj) =>
          array.push({ men_id: obj.men_id, label: obj.lbl_code }),
        );
        this.setState({
          menu: array,
        });
      }
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Card>
          <CardBody className="p0">
            <Row className="m0" style={{ padding: '0px' }}>
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img
                    className="header-icon"
                    src={HeaderIcon}
                    alt="attendance"
                  />
                  <h3 className="page-title">{t('MEN.NEWS_AND_UPDATES')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('MEN.NEWS_AND_UPDATES')} {t('LBL.PREFERENCES')}
                  </h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {t('MEN.NEWS_AND_UPDATES')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <NewsAndUpdatesList />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(NewsAndUpdates);
