/* eslint-disable no-underscore-dangle */
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { reducer as reduxFormReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage';

import {
  cryptoTableReducer,
  newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  userReducer,
  loadBoxEmployeeReducer
} from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  cryptoTable: cryptoTableReducer,
  newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  user: userReducer,
  loadBoxEmployee: loadBoxEmployeeReducer
});

const persistConfig = {
  key: 'phirogo-app',
  storage,
  version: 4,
  blacklist: ['form', 'theme', 'cryptoTable', 'newOrder', 'customizer'],
  whitelist: ['sidebar', 'form', 'user', 'loadBoxEmployee'],
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

// const store = createStore(
//   reducer,
//   {},
//   compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),
// );

export { store, persistor };
