/* eslint-disable no-unused-vars, quotes, no-lonely-if, prefer-destructuring, react/no-unused-prop-types */
/* eslint-disable array-callback-return, brace-style, object-curly-newline, no-unneeded-ternary */
/* eslint-disable max-len, class-methods-use-this, react/prop-types, jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-operators, react/jsx-closing-bracket-location, no-loop-func */
/* eslint-disable react/no-unused-state, no-prototype-builtins, no-unused-expressions, no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-for, no-restricted-syntax, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable quote-props, jsx-a11y/anchor-is-valid, operator-assignment, no-return-assign */
/* eslint-disable camelcase, arrow-parens, no-plusplus, prefer-template, jsx-a11y/alt-text */
/* eslint-disable object-shorthand, react/jsx-curly-brace-presence, arrow-body-style, no-sequences */
/* eslint-disable react/no-did-mount-set-state, function-paren-newline, jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import { Container, Row, Col, Button, Card, CardBody, Modal } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import showNotifications from '../Preferences/notificationMessages';
import renderTextInput from '../../shared/components/form/TextInput';
import renderSelectField from '../../shared/components/form/Select';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { data } from 'browserslist';
import ProblemandIssue from './ProblemIssue';

const UserDefault = '/img/user-default-photo.png';

let arrFinalValue = [];
const arrCategoryKpi = [];

class KpiEvaluationForms extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
      { '3': t('FRM.FUNCTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      empid: '',
      empname: '',
      empcode: '',
      modal: false,
      displaySpinner: false,
      disabledButtonSave: true,
      redirectUrl: false,
      arrPhase: [],
      phaseName: '',
      periodPhaseId: '',
      periodId: '',
      phaseId: '',
      kpiPlanData: [],
      kpiPlanCategory: [],
      kpiPlanStrategic: [],
      totalWeight: 0,
      reviewerId: '',
      arrEmployee: [],
      reviewer: '',
      approval: false,
      workflow: '',
      activity: '',
      showPickedItem: false,
      arrEmpPicked: [],
      showData: false,
      wflId: '',
      submitPlan: false,
      arrAchievement: [],
      arrAchievementRoutine: [],
      issuePpi: '',
      issuePpd: '',
      issuePph: '',
      issueKpi: '',
      issueEmp: '',
      urlData: '',
      dataWidth: ['5%', '35%', '35%', '10%'],
      showTableIssue: true,
      kpiState: false,
      totalScore: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmitKpi = this.handleSubmitKpi.bind(this);
    this.getKpiplan = this.getKpiplan.bind(this);
    this.getAchievement = this.getAchievement.bind(this);
  }

  componentDidMount = () => {
    // this.props.destroy();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    setTimeout(() => {
      this.getKpiCategory();
      this.getKpiplan();
      this.getAchievement();
      this.getFlow();
    }, 300);
    if (typeof this.props.location.state === 'undefined') {
      this.setState({ redirectUrl: true });
    } else {
      setTimeout(() => {
        this.setState({
          periodId: this.props.location.state.periodId,
          phaseId: this.props.location.state.phaseId,
          periodPhaseId: this.props.location.state.periodPhaseId,
          empid: this.props.location.state.empId,
          empname: this.props.location.state.empName,
          empcode: this.props.location.state.empCode,
          phaseName: this.props.location.state.phaseName,
        });
      }, 50);
    }
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PROBLEM_ISSUES') },
      { '2': t('LBL.COUNTER_ACTION') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getAchievement() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/pfmkpiplan/getAllachievement`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const dataResponse = res.data.data;
          const arrayAll = [];
          const arrayRoutine = [];
          for (let i = 0; i < res.data.data.length; i++) {
            if (dataResponse[i].achievement_name !== 'Exceed') {
              arrayRoutine.push({
                value: dataResponse[i].pra_id,
                weight: dataResponse[i].achievement_weight,
                label: dataResponse[i].achievement_name,
              });
            }
            arrayAll.push({
              value: dataResponse[i].pra_id,
              weight: dataResponse[i].achievement_weight,
              label: dataResponse[i].achievement_name,
            });
          }
          this.setState({ arrAchievement: arrayAll });
          this.setState({ arrAchievementRoutine: arrayRoutine });
        }
      });
  }

  getEmployeePicked(x) {
    const { periodId, phaseId, periodPhaseId } = this.state;
    const kpiId = x.id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllemployeePlan/${kpiId}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const dataResponse = res.data.data;
          setTimeout(() => {
            this.setState({ arrEmpPicked: dataResponse });
          }, 100);
        }
      });
    this.setState({
      showPickedItem: true,
      showPickedTitle: x.title,
    });
  }

  getButton() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const btn = {};
    const formBody = Object.keys(btn)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(btn[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
            }
          });
        }
      });
  }

  getKpiCategory() {
    const { periodId, empid, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllitemcategorykpi/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const n = res.data.data.length;
          const d = res.data.data;
          for (let i = 0; i < n; i++) {
            arrCategoryKpi[d[i].ppi_id] = d[i].pcr_id;
          }
        }
      });
  }

  getKpiplan(val, x, y, z) {
    const {
      periodId,
      kpiState,
      kpiPlanData,
      kpiPlanCategory,
      kpiPlanStrategic,
      empid,
      phaseId,
      periodPhaseId,
      idUser,
    } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const arrKPi = kpiPlanData;
    const arrCat = kpiPlanCategory;
    if (!(typeof val === 'undefined')) {
      let achive = 0;
      for (let i = 0; i < kpiPlanCategory.length; i++) {
        const catId = kpiPlanCategory[i].pcr_id;
        let total = 0;
        for (let j = 0; j < kpiPlanStrategic[catId].length; j++) {
          const strId = kpiPlanStrategic[catId][j].pso_id;
          for (let k = 0; k < kpiPlanData[catId][strId].length; k++) {
            if (kpiPlanData[catId][strId][k].ppn_id === z) {
              const achivementWeight = parseFloat(val.weight, 0);
              const planWeight = parseInt(
                kpiPlanData[catId][strId][k].plan_weight,
                0,
              );
              const score = Math.round(planWeight * achivementWeight);
              total = total + score;
              kpiPlanData[catId][strId][k].final_weight = `${score}`;
              this.setState({ kpiState: true });
              setTimeout(() => {
                if (kpiState) {
                  this.setState({ kpiPlanData: arrKPi });
                  this.setState({ kpiState: false });
                }
              }, 500);
            } else {
              const finalWeight =
                kpiPlanData[catId][strId][k].final_weight === null
                  ? 0
                  : parseInt(kpiPlanData[catId][strId][k].final_weight, 0);
              total = total + finalWeight;
            }
          }
        }
        this.setState({ kpiState: true });
        arrCat[i].score_value = `${total}`;
        setTimeout(() => {
          if (kpiState) {
            this.setState({ kpiPlanCategory: arrCat });
            this.setState({ kpiState: false });
          }
        }, 500);
        achive = achive + total;
      }
      this.setState({ totalScore: achive });
    } else {
      arrFinalValue = [];
      axios
        .get(
          `${ls.get(
            'ws_ip',
          )}/pfmkpiplan/getAllitemcategorydetail/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
          config,
        )
        .then((res) => {
          if (res.data.status === 'ok') {
            if (res.data.data.category.length > 0) {
              const category = res.data.data.category;
              const strategic = res.data.data.strategic;
              const kpi = res.data.data.kpi;
              for (let a = 0; a < category.length; a++) {
                for (let b = 0; b < strategic[category[a].pcr_id].length; b++) {
                  for (
                    let c = 0;
                    c <
                    kpi[category[a].pcr_id][
                      strategic[category[a].pcr_id][b].pso_id
                    ].length;
                    c++
                  ) {
                    const ppn =
                      kpi[category[a].pcr_id][
                        strategic[category[a].pcr_id][b].pso_id
                      ][c].ppn_id;
                    arrFinalValue.push(`pra_${ppn}`);
                  }
                }
              }
              this.setState({
                kpiPlanCategory: category,
                kpiPlanStrategic: strategic,
                kpiPlanData: kpi,
                showData: true,
              });
            }
          }
        });
    }
  }

  getFlow() {
    const { token, apiws, phaseId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/getWorkflowPerformance/${phaseId}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data.performance[0];
          this.setState({
            wflId: dataResponse.wfl_id,
            workflow: dataResponse.workflow,
            activity: dataResponse.activity,
          });
        }
      });
  }

  changeAchievement(val, x, y, z) {
    this.getKpiplan(val, x, y, z);
  }

  toggle(ppd_id, ppi_id, kpi_name) {
    this.setState({
      modal: !this.state.modal,
      issuePpi: ppi_id,
      issuePpd: ppd_id,
      issuePph: this.state.phaseId,
      issueKpi: kpi_name,
      issueEmp: this.state.empid,
    });
  }

  handleSubmitKpi(values) {
    console.log(values);
    const {
      apiws,
      token,
      idUser,
      empid,
      wflId,
      periodId,
      phaseId,
      periodPhaseId,
    } = this.state;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const arrAchievement = [];
    Object.keys(values).forEach((obj) => {
      if (obj.includes('pra')) {
        const split = obj.split('_');
        const ppn = split[1];
        arrAchievement.push({ ppn_id: ppn, pra_id: values[obj] });
      }
    });
    const action = 'POST';
    const link = `${apiws}/pfmkpiplandetail/saveItemEvaluation`;
    const body = [
      `dyn_achivement=${JSON.stringify(arrAchievement)}`,
      `emp_id=${empid}`,
      `pfp_id=${periodId}`,
      `pph_id=${phaseId}`,
      `ppp_id=${periodPhaseId}`,
      `wfl_id=${wflId}`,
      `cuser_id=${idUser}`,
    ].join('&');
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    this.setState({ displaySpinner: true });
    axios(options).then(() => {
      this.setState({ displaySpinner: false, redirectUrl: true });
      this.props.destroy();
      showNotifications('Success', 'Save Draft Success', 'success');
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      displaySpinner,
      redirectUrl,
      kpiPlanData,
      kpiPlanCategory,
      kpiPlanStrategic,
      approval,
      disabledButtonSave,
    } = this.state;

    if (redirectUrl) {
      const link = <Redirect to={{ pathname: '/performance/information' }} />;
      return link;
    }

    if (approval) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpi',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empid,
              empName: this.state.empname,
              empCode: this.state.empcode,
            },
          }}
        />
      );
    }

    const arrDataKpiPlan = [];
    for (let i = 0; i < kpiPlanCategory.length; i++) {
      const category = kpiPlanCategory[i];
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.CATEGORY')}
          </span>
          <div className="form__form-group-field">
            <Col xs={5} md={5} lg={5} xl={5}>
              <input
                style={{ fontWeight: 'bold' }}
                name="category"
                disabled="true"
                value={category.category_name}
              />
            </Col>
          </div>
        </div>,
      );
      for (let j = 0; j < kpiPlanStrategic[`${category.pcr_id}`].length; j++) {
        const strategic = kpiPlanStrategic[`${category.pcr_id}`][j];
        arrDataKpiPlan.push(
          <div className="form__form-group">
            <span
              className="form__form-group-label"
              style={{ paddingLeft: '15px' }}
            >
              {t('LBL.STRATEGIC_OBJECTIVE')}
            </span>
            <div className="form__form-group-field">
              <Col xs={5} md={5} lg={5} xl={5}>
                <input
                  name="startegic"
                  disabled="true"
                  value={strategic.strategic_objective_name}
                />
              </Col>
            </div>
          </div>,
        );
        for (
          let k = 0;
          k < kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`].length;
          k++
        ) {
          const kpi =
            kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`][k];
          const satuan = kpi.target === '%' ? '%' : '';
          const arrAchieve =
            category.category_name === 'Routine'
              ? this.state.arrAchievementRoutine
              : this.state.arrAchievement;
          arrDataKpiPlan.push(
            <div className="form__form-group">
              <span
                className="form__form-group-label"
                style={{ paddingLeft: '15px' }}
              >
                {t('LBL.KPI')}
              </span>
              <div className="form__form-group-field">
                <Col xs={3} md={3} lg={3} xl={3}>
                  <textarea
                    name="kpi"
                    disabled="true"
                    value={kpi.kpi_name}
                    // style={{ height: '200px' }}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} xl={1}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TARGET')}
                    </span>
                    <div
                      className="form__form-group-field number-form"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <input
                        name="target"
                        value={`${kpi.target_value} ${satuan}`}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.WEIGHT')}
                    </span>
                    <div
                      className="form__form-group-field number-form"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        name={`weight_${kpi.ppn_id}_${category.pcr_id}`}
                        component={renderTextInput}
                        type="text"
                        placeholder={kpi.plan_weight}
                        maxLength="3"
                        disabled
                        // input={{ value: '10' }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DIDNT_REACH')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_1_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        input={{ value: kpi.didnt_reach }}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.REACH')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_2_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.reach }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EXCEED')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_3_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.exceed }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>,
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Col xs={3} md={3} lg={3} xl={3}>
                  <span className="form__form-group-label">
                    {t('LBL.ACHIEVMENT')}
                  </span>
                  <div
                    className="form__form-group-field"
                    style={{ width: '100%', padding: '0px' }}
                  >
                    <Field
                      component={renderTextInput}
                      name={`oldpra_${kpi.ppn_id}`}
                      type="text"
                      input={{ value: kpi.achievement_name }}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={3} md={3} lg={3} xl={3}>
                  <span className="form__form-group-label">
                    {t('LBL.FINAL_VALUE')}
                  </span>
                  <div
                    className="form__form-group-field"
                    style={{ width: '100%', padding: '0px' }}
                  >
                    <Field
                      component={renderSelectField}
                      name={`pra_${kpi.ppn_id}`}
                      options={arrAchieve}
                      onChange={(x) =>
                        this.getKpiplan(
                          x,
                          category.pcr_id,
                          strategic.pso_id,
                          kpi.ppn_id,
                        )
                      }
                      // input={{ value: kpi.final_pra_id, onChange: (x) => this.getKpiplan(x, category.pcr_id, strategic.pso_id, kpi.ppn_id) }}
                    />
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <span className="form__form-group-label">
                    {t('LBL.SCORE')}
                  </span>
                  <div
                    className="form__form-group-field"
                    style={{ width: '100%', paddingLeft: '0px' }}
                  >
                    <Field
                      name={`score_${kpi.ppn_id}`}
                      component={renderTextInput}
                      type="text"
                      // classes="kpi-evaluate"
                      // placeholder={kpi.final_weight}
                      input={{ value: kpi.final_weight }}
                      disabled
                    />
                  </div>
                </Col>
                <Col
                  xs={1}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{ paddingLeft: '165px', paddingTop: '40px' }}
                >
                  <Button
                    // outline
                    color="primary"
                    style={{ width: '150px' }}
                    onClick={() =>
                      this.toggle(kpi.ppd_id, kpi.ppi_id, kpi.kpi_name)
                    }
                    disabled={data.phase_disabled ? 'disabled' : ''}
                    className="btn_table_navbar"
                  >
                    {' '}
                    {t('LBL.PROBLEM_ISSUES')}
                  </Button>
                </Col>
              </div>
            </div>,
          );
        }
      }
      //  style={{ border: '1px solid grey', width: '100%', padding: '15px 2px 5px 2px', margin: '20px 0px 20px 0px' }}
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.WEIGHT')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.weight_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.RESULT')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`weightverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.weight_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.SCORE')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`scoreverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.score_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(<hr style={{ width: '100%' }} />);
    }
    const phaseNm = this.state.phaseName.toUpperCase();
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div className="header-no-bg">
                  <h4 className="title-label">
                    {t('LBL.KPI')} {phaseNm} {t('SUBMISSION')}
                  </h4>
                  <p className="sub-label">
                    Please fill out the form below and review your submission
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="card-login-info">
                        <div className="d-flex">
                          <div className="user-profile-login">
                            <img src={UserDefault} alt="user" />
                          </div>
                          <div className="w-100">
                            <h4 className="username-login">
                              {t('LBL.LOGIN_AS')}{' '}
                              {`${ls.get('user_cred').first_name} ${
                                ls.get('user_cred').last_name
                              }`}
                            </h4>
                            <p className="caption-label">
                              {t('LBL.REQUEST_DATE')} :{' '}
                              {moment(new Date()).format('YYYY-MM-DD')}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="sub-header-label">{t('LBL.SUBMISSION_FORM')}</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 20px 30px 20px' }}>
                {/* {this.state.showData === false &&
                  <Row style={{ margin: '0px !important', padding: '0px !important', textAlign: 'right' }}>
                    <Col style={{ margin: '0px !important', padding: '0px !important' }} />
                    <Col style={{ margin: '0px !important', padding: '0px !important' }} >
                      <Button
                        color="default"
                        className="btn btn-secondary btn_table_navbar"
                        onClick={() => this.setState({ redirectUrl: true })}
                        style={{ float: 'right', marginRight: '15px', marginBottom: '0px' }}
                      >
                        {t('FRM.BACK')}
                      </Button>
                    </Col>
                  </Row>
                } */}
                <div
                  className="profile-identity"
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    marginTop: '0px',
                    width: '100%',
                  }}
                >
                  {/* <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t('LBL.SUBMISSION')}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <table id="myTable" className="table data-grid-tbl responsive no-wrap">
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.WORKFLOW')}</td>
                            <td>:
                              {(this.state.workflow === '') ? (
                                <span style={{ color: 'red' }}> Your flow has not been set, please contact the administrator.</span>
                              ) : ` ${this.state.workflow}`
                              }
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.ACTIVITY')}</td>
                            <td>: {this.state.activity}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUESTER')}</td>
                            <td>: {this.state.empname} ({this.state.empcode})</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUEST_DATE')}</td>
                            <td>: {moment(new Date()).format('YYYY-MM-DD')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faFileAlt} />
                        {t('FRM.REQUEST_DETAIL')} - {t('LBL.KPI_EVALUATION')}
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className="modal-dialog modal-input"
                      style={{ maxWidth: '1000px', marginTop: '100px' }}
                    >
                      <ProblemandIssue
                        issuePpd={this.state.issuePpd}
                        issuePpi={this.state.issuePpi}
                        issuePph={this.state.issuePph}
                        issueKpi={this.state.issueKpi}
                        issueEmp={this.state.issueEmp}
                      />
                    </Modal>
                  </Row>
                  {this.state.showData && (
                    <Row style={{ padding: '0px' }}>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <form
                            className="form form--horizontal"
                            onSubmit={handleSubmit(this.handleSubmitKpi)}
                            name="savedraftkpievaluation"
                            id="savedraftkpievaluation"
                          >
                            {arrDataKpiPlan}
                            <div
                              className="form__form-group"
                              style={{ marginTop: '12px' }}
                            >
                              <span
                                className="form__form-group-label"
                                style={{ paddingLeft: '15px' }}
                              >
                                <b>
                                  {t('LBL.SCORE')} {t('LBL.KPI')}{' '}
                                  {this.state.phaseName}
                                </b>
                              </span>
                              <div className="form__form-group-field number-form">
                                <Col xs={3} md={3} lg={3} xl={3}>
                                  <Field
                                    name="total_score"
                                    component={renderTextInput}
                                    type="text"
                                    disabled
                                    placeholder={this.state.totalScore}
                                  />
                                </Col>
                              </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <Button
                                color="secondary"
                                onClick={() =>
                                  this.setState({ redirectUrl: true })
                                }
                              >
                                {t('FRM.BACK')}
                              </Button>
                              {this.state.workflow === '' ? (
                                <Button color="primary" type="submit" disabled>
                                  {' '}
                                  {t('FRM.SAVE')}
                                </Button>
                              ) : displaySpinner ? (
                                <Button color="primary">
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '6px' }}
                                  />
                                  Loading...
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  form="savedraftkpievaluation"
                                  type="submit"
                                  disabled={pristine}
                                >
                                  {' '}
                                  {t('FRM.SAVE')}
                                </Button>
                              )}
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const finalV = arrFinalValue;
  const uniqueF = new Set(finalV);
  const fixF = [...uniqueF];
  for (let m = 0; m < fixF.length; m++) {
    if (!values[fixF[m]]) {
      errors[fixF[m]] = 'Final value shouldn’t be empty';
    }
  }
  return errors;
};

export default reduxForm({
  form: 'kpievaluationforms',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KpiEvaluationForms));
