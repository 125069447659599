/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Home"
            icon="home"
            route="/dashboard_crypto"
            onClick={this.hideSidebar}
          />
          <SidebarCategory title="Administration" icon="user">
            <SidebarLink title="Employee" route="/administration/employee" onClick={this.hideSidebar} />
            <SidebarLink title="Leave" route="/administration/leave" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Payroll" icon="book" route="/payroll" onClick={this.hideSidebar}>
            <SidebarLink title="Run Process" route="/payroll/runprocess" onClick={this.hideSidebar} />
            <SidebarLink title="Setting Period" route="/payroll/settingperiod" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Preferences" icon="cog">
            <SidebarCategory title="Organization Setting">
              <SidebarLink
                title="Company"
                route="/preferences/organizationsetting/company"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Office"
                route="/preferences/organizationsetting/office"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Unit Organization"
                route="/preferences/organizationsetting/unitorganization"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Job Level"
                route="/preferences/organizationsetting/joblevel"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Position"
                route="/preferences/organizationsetting/position"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
            <SidebarCategory title="Payroll Setting">
              <SidebarLink
                title="Preferences"
                route="/preferences/payrollsetting/preferences"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Component"
                route="/preferences/payrollsetting/components"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="THR Setting"
                route="/preferences/payrollsetting/thrsetting"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
            <SidebarCategory title="Attendance Setting">
              <SidebarLink
                title="Shift"
                route="/preferences/attendancesetting/shift"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Group Shift"
                route="/preferences/attendancesetting/groupshift"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Attendance Setting"
                route="/preferences/attendancesetting/attendsettings"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Overtime Setting"
                route="/preferences/payrollsetting/thrsetting"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          </SidebarCategory>
          <SidebarCategory title="Report" icon="file-empty">
            <SidebarCategory title="Payroll">
              <SidebarLink title="Monthly Report" route="/report/payroll/payrollmonthly" onClick={this.hideSidebar} />
              <SidebarLink
                title="Bank Transfer"
                route="/report/payroll/payrollbanktransfer"
                onClick={this.hideSidebar}
              />
              <SidebarLink title="Payslip" route="/report/payroll/payrollpayslip" onClick={this.hideSidebar} />
            </SidebarCategory>
            <SidebarCategory title="BPJS">
              <SidebarLink
                title="Tax Detail Calculation"
                route="/report/bpjsreport/taxdetailcalc"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Tax 1721A1"
                route="/report/bpjsreport/tax1721a1"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Tax Export Bukti Potong"
                route="/report/payroll/payrollbanktransfer"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Template" icon="layers">
            <ul className="sidebar__block">
              <SidebarLink title="Dashboard Default" route="/dashboard_default" onClick={this.hideSidebar} />
              <SidebarLink
                title="Dashboard E-commerce"
                icon="store"
                route="/dashboard_e_commerce"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Dashboard Fitness"
                icon="heart-pulse"
                newLink
                route="/dashboard_fitness"
                onClick={this.hideSidebar}
              />
              <SidebarCategory title="Layout" >
                <button className="sidebar__link" onClick={this.props.changeToLight}>
                  <p className="sidebar__link-title">Light Theme</p>
                </button>
                <button className="sidebar__link" onClick={this.props.changeToDark}>
                  <p className="sidebar__link-title">Dark Theme</p>
                </button>
              </SidebarCategory>
              <SidebarCategory title="UI Elements">
                <SidebarLink title="Alerts" route="/ui/alerts" onClick={this.hideSidebar} />
                <SidebarLink title="Buttons" route="/ui/buttons" onClick={this.hideSidebar} />
                <SidebarLink title="Carousel" route="/ui/carousel" onClick={this.hideSidebar} />
                <SidebarLink title="Collapse" route="/ui/collapse" onClick={this.hideSidebar} />
                <SidebarLink title="Grids" route="/ui/grids" onClick={this.hideSidebar} />
                <SidebarLink title="Modals" route="/ui/modals" onClick={this.hideSidebar} />
                <SidebarLink title="Notifications" route="/ui/notifications" onClick={this.hideSidebar} />
                <SidebarLink title="Panels" route="/ui/panels" onClick={this.hideSidebar} />
                <SidebarLink title="Progress Bars" route="/ui/progress_bars" onClick={this.hideSidebar} />
                <SidebarLink title="Range Sliders" route="/ui/range_sliders" onClick={this.hideSidebar} />
                <SidebarLink title="Tabs" route="/ui/tabs" onClick={this.hideSidebar} />
                <SidebarLink title="Timeline" route="/ui/timeline" onClick={this.hideSidebar} />
                <SidebarLink title="Tooltips & Popovers" route="/ui/tooltips" onClick={this.hideSidebar} />
                <SidebarLink title="Typography" route="/ui/typography" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarLink title="Mail Application" icon="envelope" route="/mail" onClick={this.hideSidebar} />
              <SidebarLink title="Chat Application" icon="bubble" route="/chat" onClick={this.hideSidebar} />
              <SidebarCategory title="Forms" >
                <SidebarLink title="Basic Form" route="/forms/basic_form" onClick={this.hideSidebar} />
                <SidebarLink title="Check Form Controls" route="/forms/check_form_controls" onClick={this.hideSidebar} />
                <SidebarLink title="File Upload" route="/forms/file_upload" onClick={this.hideSidebar} />
                <SidebarLink title="Floating Labels Form" route="/forms/floating_labels_form" onClick={this.hideSidebar} />
                <SidebarLink title="Form Dropzone" route="/forms/form_dropzone" onClick={this.hideSidebar} />
                <SidebarLink title="Form Layouts" route="/forms/form_layouts" onClick={this.hideSidebar} />
                <SidebarLink title="Form Picker" route="/forms/form_picker" onClick={this.hideSidebar} />
                <SidebarLink title="Form Validation" route="/forms/form_validation" onClick={this.hideSidebar} />
                <SidebarLink title="Mask Form" route="/forms/mask_form" onClick={this.hideSidebar} />
                <SidebarLink title="Material Form" route="/forms/material_form" onClick={this.hideSidebar} />
                <SidebarLink title="Wizard Form" route="/forms/wizard_form" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarCategory title="Tables">
                <SidebarLink title="Basic tables" route="/tables/basic_tables" onClick={this.hideSidebar} />
                <SidebarLink title="Data table" route="/tables/data_table" onClick={this.hideSidebar} />
                <SidebarLink title="Editable table" route="/tables/editable_table" onClick={this.hideSidebar} />
                <SidebarLink title="Material table" newLink route="/tables/material_table" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarCategory title="Charts">
                <SidebarLink title="ChartsJS" route="/charts/charts_js" onClick={this.hideSidebar} />
                <SidebarLink title="React-vis" route="/charts/react_vis" onClick={this.hideSidebar} />
                <SidebarLink title="Recharts" route="/charts/recharts" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarCategory title="Maps" >
                <SidebarLink title="Google map" route="/maps/google_map" onClick={this.hideSidebar} />
                <SidebarLink title="Vector map" route="/maps/vector_map" onClick={this.hideSidebar} />
              </SidebarCategory>
            </ul>
            <ul className="sidebar__block">
              <SidebarCategory title="Account" >
                <SidebarLink title="Email Confirmation" route="/account/email_confirmation" />
                <SidebarLink title="Lock Screen" route="/lock_screen" />
                <SidebarLink title="Log In" route="/log_in" />
                <SidebarLink title="Log In Photo" route="/log_in_photo" />
                <SidebarLink title="Profile" route="/account/profile" onClick={this.hideSidebar} />
                <SidebarLink title="Register" route="/register" />
                <SidebarLink title="Register Photo" route="/register_photo" />
              </SidebarCategory>
              <SidebarCategory title="E-commerce" >
                <SidebarLink title="Cart" route="/e-commerce/cart" onClick={this.hideSidebar} />
                <SidebarLink title="Catalog" route="/e-commerce/catalog" onClick={this.hideSidebar} />
                <SidebarLink title="Orders List" route="/e-commerce/orders_list" onClick={this.hideSidebar} />
                <SidebarLink title="Payment" route="/e-commerce/payment" onClick={this.hideSidebar} />
                <SidebarLink title="Product Edit" route="/e-commerce/product_edit" onClick={this.hideSidebar} />
                <SidebarLink title="Product Page" route="/e-commerce/product_page" onClick={this.hideSidebar} />
                <SidebarLink title="Products List" route="/e-commerce/products_list" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarCategory title="Default Pages" >
                <SidebarLink title="404" route="/404" />
                <SidebarLink title="Calendar" route="/default_pages/calendar" onClick={this.hideSidebar} />
                <SidebarLink title="FAQs" route="/default_pages/faq" onClick={this.hideSidebar} />
                <SidebarLink title="Gallery" route="/default_pages/gallery" onClick={this.hideSidebar} />
                <SidebarLink title="Invoice template" route="/default_pages/invoice_template" onClick={this.hideSidebar} />
                <SidebarLink title="Pricing Cards" route="/default_pages/pricing_cards" onClick={this.hideSidebar} />
                <SidebarLink title="Project Summary" route="/default_pages/project_summary" onClick={this.hideSidebar} />
                <SidebarLink title="Search Results" route="/default_pages/search_results" onClick={this.hideSidebar} />
                <SidebarLink title="Text Editor" route="/default_pages/text_editor" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarCategory title="Multilevel Menu " >
                <SidebarLink title="Second Level Item" />
                <SidebarCategory title="Second Level Item">
                  <SidebarLink title="Third Level Item" />
                  <SidebarLink title="Third Level Item" />
                </SidebarCategory>
              </SidebarCategory>
            </ul>
            <ul className="sidebar__block">
              <SidebarLink title="Log Out" route="/log_in" />
            </ul>
            <ul className="sidebar__block">
              <SidebarLink
                title="Documentation"
                route="/documentation/introduction"
                onClick={this.hideSidebar}
              />
            </ul>
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
