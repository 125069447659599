/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props, react/forbid-prop-types */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import NumberFormat from 'react-number-format';
import ProfilePanelData from '../../../Dashboards/Crypto/components/ProfilePanelData';

let jobIdExist = [];
class WeightCategory extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    addFunc: PropTypes.func.isRequired,
    comp_category: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      componentptkp: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '15%', '15%', '30%', '10%'],
      statusEdit: false,
      urlData: '',
      pptvalue: '',
      editcond: '',
      addcond: '',
      deletecond: '',
      arrperiod: [],
      arrCategory: [],
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERIOD') },
      { '2': t('LBL.JOB_LEVEL') },
      { '3': t('LBL.WEIGHT') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editweight = this.editweight.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.getJobLevel = this.getJobLevel.bind(this);
    this.getPeriod = this.getPeriod.bind(this);
    this.getButton = this.getButton.bind(this);
    this.joblevelExist = this.joblevelExist.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERIOD') },
      { '2': t('LBL.JOB_LEVEL') },
      { '3': t('LBL.WEIGHT') },
      { '4': t('FRM.FUNCTION') },
    ];
    this.getPeriod();
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.props.destroy();
    this.setState({ pptvalue: '' });
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
    setTimeout(() => {
      this.props.initialize({ comp_category: this.props.comp_category });
    }, 200);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getJobLevel();
      this.getPeriod();
      this.getButton();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({
      urlData: `${ls.get('ws_ip')}/Pfmcategoryweight/getAllitem/`,
    });
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  onLinkClick(e) {
    e.preventDefault();
    // further processing happens here
  }

  handleSubmitInput(values) {
    const { apiws, iduser, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.pcw_id;
    const userid = ls.get('user_cred').usr_id;
    const weight_value = values.comp_category;
    const weightvalue = [];
    weight_value.forEach((obj) => {
      weightvalue.push({
        pcl_id: obj.pcl_id,
        pcr_id: obj.pcr_id,
        category_name: obj.category_name,
        weight_value:
          typeof obj.weight_value === 'undefined' || obj.weight_value === null
            ? 0
            : obj.weight_value,
      });
    });
    const wev = JSON.stringify(weightvalue);
    let job_id;
    let pfp;
    if (typeof values.job_level === 'undefined' || values.job_level === null) {
      job_id = '';
    } else {
      if (typeof values.job_level === 'object') {
        job_id = values.job_level.value;
      } else {
        job_id = values.job_level;
      }
    }
    if (typeof values.period === 'undefined' || values.period === null) {
      pfp = '';
    } else {
      if (typeof values.period === 'object') {
        pfp = values.period.value;
      } else {
        pfp = values.period;
      }
    }
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/pfmcategoryweight/saveItem`;
      data = {
        pfp,
        job_id,
        wev,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/pfmcategoryweight/updateItem/${id}`;
      data = {
        pfp,
        job_id,
        wev,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(url, formBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.setState({ modal: false });
        setTimeout(() => {
          this.child.reload();
        }, 2000);
      } else {
        showNotifications('Fail', response.data.status, 'danger');
      }
    });
  }

  editweight(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmcategoryweight/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            pcw_id: getResponseData[0].pcw_id,
            job_level: getResponseData[0].job_id,
            period: getResponseData[0].pfp_id,
          };
          const component = [];
          response.data.weight_value.forEach((obj) => {
            component.push({
              pcl_id: obj.pcl_id,
              pcr_id: obj.pcr_id,
              weight_value: obj.weight_value,
              // eslint-disable-next-line no-unneeded-ternary
              category_name: obj.category_name,
            });
          });
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize({ ...datamaping, comp_category: component });
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }
  getJobLevel() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgjoblevel/getAllItem`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const arr = [];
          response.data.data.map((obj) =>
            arr.push({ value: obj.job_id, label: obj.job_level }),
          );
          this.setState({ arrJobLevel: arr });
        }
      });
  }

  getPeriod() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/pfmperiod/getAll`, '', config).then((response) => {
      if (response.data.status === 'ok') {
        const arr = [];
        response.data.data.map((obj) =>
          arr.push({ value: obj.pfp_id, label: `${obj.period_name}` }),
        );
        // window.location.reload(true);
        this.setState({ arrperiod: arr });
      }
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ disabledinput: false });
    this.setState({ statusedit: false });
    this.setState({ buttondisabled: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.loadSelect();
    this.setState({ disabledinput: true });
    this.setState({ statusedit: true });
  }

  deleteCategory(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/pfmcategoryweight/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  joblevelExist(val) {
    jobIdExist = [];
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${apiws}/pfmcategoryweight/getAllItemByPeriod`,
        `pfp_id=${val.value}`,
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const arr = [];
          response.data.data.map((obj) => jobIdExist.push(obj.job_id));
        }
      });
  }

  renderCategory = ({ fields, meta: { error }, t }) => (
    <div>
      {fields.map((item, index) => (
        <div>
          <input type="hidden" name={`${item}.pcl_id`} />
          <input type="hidden" name={`${item}.pcr_id`} />
          <Row>
            <Col xs={9} md={9} lg={9} xl={9}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {this.props.comp_category[index].category_name}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`${item}.weight_value`}
                    component={renderTextInput}
                    type="number"
                  />
                  <span className="percent-label-form">%</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );

  render() {
    const { handleSubmit, t, pristine, addFunc } = this.props;
    const {
      componentptkp,
      buttondisabled,
      disabledinput,
      statusEdit,
      urlData,
      arrJobLevel,
      arrperiod,
    } = this.state;

    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.WEIGHT_CATEGORY')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button color="success" type="submit" form="yearform">
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="yearform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        {/* <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <h4>PTKP</h4>
            <hr />
          </Col>
        </Row> */}
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pcw_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editweight}
                  deleteFunc={this.deleteCategory}
                  addFunc={addFunc}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('LBL.WEIGHT')} {t('LBL.FORM')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="yearform"
                  id="yearform"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <input type="hidden" name="pfy_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.PERIOD')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="period"
                              component={renderSelectField}
                              onClick={(e) => {
                                this.onLinkClick(e);
                              }}
                              options={arrperiod}
                              onChange={(e) => {
                                this.joblevelExist(e);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_LEVEL')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="job_level"
                              component={renderSelectField}
                              options={arrJobLevel}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <FieldArray
                          name="comp_category"
                          component={this.renderCategory}
                          props={{
                            t,
                          }}
                        />
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col xs={12} md={12} lg={4} xl={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ROUTINE')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="routine"
                              component={renderTextInput}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={4} xl={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.IMPROVEMENT')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="improvement"
                              component={renderTextInput}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={4} xl={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.BREAKTHROUGH')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="breakthrough"
                              component={renderTextInput}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row> */}
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  console.log(jobIdExist);
  const errors = {};
  if (!values.period) {
    errors.period = 'period field shouldn’t be empty';
  }
  if (!values.job_level) {
    errors.job_level = 'job level field shouldn’t be empty';
  }
  if (typeof values.job_level !== 'undefined') {
    if (jobIdExist.indexOf(parseInt(values.job_level.value, 10)) !== -1) {
      errors.job_level = 'Job level has been used at this period';
    }
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'weightcategory',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(WeightCategory));
