/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign, class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len, react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars, prefer-destructuring */
/* eslint-disable object-shorthand, object-curly-newline */
/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faAngleUp,
  faAngleDown,
  faSearch,
} from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import showNotifications from '../../../Preferences/notificationMessages';
import { store } from '../../../App/store';
import { connect } from 'react-redux';

class ShortcutPanelNew extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      modal: false,
      modalCustomShortcut: false,
      isUserAdmin: false,
      apiws: ls.get('ws_ip'),
      token: ls.get('token'),
      idUser: ls.get('user_cred')?.usr_id,
      rows: [],
      menIds: [],
      checktrue: [],
      datamenu: [],
      checked: true,
      emp_id: ls.get('user_cred')?.emp_id,
      date: '',
      fga_id: ls.get('function_access')[0]?.fga_id,
      totalFav: '',
    };

    this.toggle = this.toggle.bind(this);
    this.customShortcutToggle = this.customShortcutToggle.bind(this);
    this.checkedToggle = this.checkedToggle.bind(this);
    this.getMenuData = this.getMenuData.bind(this);
    this.search = this.search.bind(this);
    this.updateToggle = this.updateToggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.getFavMenuData = this.getFavMenuData.bind(this);
    this.getCompleteDay = this.getCompleteDay.bind(this);
  }

  componentDidMount = () => {
    
    this.getCompleteDay();

    // get fav user menu only at once
    if (this.props?.user?.shortcut?.length == 0) {
      this.getFavMenuData() 
      this.getMenuData()
    }
  };

  componentWillReceiveProps() {
    this.getCompleteDay();
  }

  search(event) {
    this.setState({ search: event.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getMenuData();
    }, 50);
  }

  onMoveUp(key, order) {
    const { apiws, token, idUser } = this.state;
    const url = `${apiws}/appmenufavorite/moveUpItem`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = `men_id=${key}&order_no=${order}&user_id=${idUser}`;
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        this.getMenuData();
        this.getFavMenuData();
      }
    });
    // const reloadState = this;
    // if (key === 0) return;
    // const rows = this.state.rows.slice();
    // const index = key - 1;
    // const itemAbove = rows[index];
    // rows[key - 1] = rows[key];
    // rows[key] = itemAbove;
    // reloadState.setState({ rows });
    // console.log(rows);
  }

  onMoveDown(key, order) {
    // const reloadState = this;
    // const rows = this.state.rows.slice();
    // if (key === rows.length - 1) return;
    // const index = key + 1;
    // const itemBelow = rows[index];
    // rows[key + 1] = rows[key];
    // rows[key] = itemBelow;
    // reloadState.setState({ rows });
    // console.log(rows);
    const { apiws, token, idUser } = this.state;
    const url = `${apiws}/appmenufavorite/moveDownItem`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = `men_id=${key}&order_no=${order}&user_id=${idUser}`;
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        this.getMenuData();
        this.getFavMenuData();
      }
    });
  }

  updateToggle(menuid) {
    const { apiws, token, idUser } = this.state;
    const url = `${apiws}/appmenufavorite/saveItem`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = `men_id=${menuid}&user_id=${idUser}`;
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        this.getMenuData();
        this.getFavMenuData();
      } else if (response.data.status === 'limited') {
        Swal.fire({
          title: 'Warning',
          text: 'Only 5 menu allowed!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        this.getMenuData();
        this.getFavMenuData();
      } else {
        // console.log('Failed Add');
      }
    });
    // const data = this.state.rows.slice();
    // if (checked === true) {
    //   if (this.state.datamenu.length > 4) {
    //     Swal.fire({
    //       title: 'Warning',
    //       text: 'Only 5 menu allowed!',
    //       type: 'warning',
    //       showCloseButton: true,
    //       closeButtonColor: '#3085d6',
    //       reverseButtons: true,
    //     });
    //     this.getMenuData(true);
    //   } else {
    //     this.state.datamenu.push({
    //       men_id: menuid,
    //       usr_id: this.state.idUser,
    //       cuser_id: this.state.idUser,
    //     });
    //   }
    // } else if (checked === false) {
    //   this.state.datamenu = this.state.datamenu.filter(e => e.men_id !== menuid);
    // }
  }

  handleSubmitInput(event) {
    const menu = this.state.datamenu;
    const error = true;
    const { apiws, token } = this.state;
    const url = `${apiws}/appmenufavorite/saveItem`;
    const data = {
      data: JSON.stringify(menu),
      usr_id: this.state.idUser,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(url, formBody, config).then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ modal: false });
          setTimeout(() => {
            this.getFavMenuData();
            this.getMenuData();
          }, 1000);
          showNotifications('Success', 'Save Success', 'success');
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
    }
  }

  getMenuData() {
    const reloadState = this;
    const rows = [];
    const data = [];
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const bodyParameters = { id: idUser, query: this.state.search };
    axios
      .post(`${apiws}/appmenufavorite/getAllitem`, bodyParameters, config)
      .then((response) => {
        if (response.data.data.length > 0) {
          for (let i = 0; i < response.data.data.length; i += 1) {
            rows.push({
              men_id: response.data.data[i].men_id,
              menu: response.data.data[i].menu,
              default_icon: response.data.data[i].default_icon,
              checked: response.data.data[i].checked,
              order_no: response.data.data[i].order_no,
            });
            if (response.data.data[i].checked === true) {
              data.push({
                men_id: response.data.data[i].men_id,
                usr_id: this.state.idUser,
                cuser_id: this.state.idUser,
              });
            }
          }

          reloadState.setState({
            totalFav: response.data.total,
            datamenu: data,
            rows,
          });
        }
      });
  }

  getCompleteDay() {
    const { t } = this.props;
    const days = [
      `${t('LBL.SUNDAY')}`,
      `${t('LBL.MONDAY')}`,
      `${t('LBL.TUESDAY')}`,
      `${t('LBL.WEDNESDAY')}`,
      `${t('LBL.THURSDAY')}`,
      `${t('LBL.FRIDAY')}`,
      `${t('LBL.SATURDAY')}`,
    ];
    const monthNames = [
      `${t('LBL.JANUARY')}`,
      `${t('LBL.FEBRUARY')}`,
      `${t('LBL.MARCH')}`,
      `${t('LBL.APRIL')}`,
      `${t('LBL.MAY')}`,
      `${t('LBL.JUNE')}`,
      // eslint-disable-next-line indent
      `${t('LBL.JULI')}`,
      `${t('LBL.AUGUST')}`,
      `${t('LBL.SEPTEMBER')}`,
      `${t('LBL.OCTOBER')}`,
      `${t('LBL.NOVEMBER')}`,
      `${t('LBL.DESEMBER')}`,
    ];

    const d = new Date();
    const dayName = `${days[d.getDay()]},   ${d.getDate()} ${
      monthNames[d.getMonth()]
    }`;
    this.setState({ date: dayName });
  }

  getFavMenuData() {
    const datas = [];
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${apiws}/appmenufavorite/getAllItemByUsrId/${idUser}`, '', config)
      .then((response) => {
        if (response.data.data.length > 0) {
          response?.data?.data?.forEach((key) => {
            datas.push({
              men_id: key.men_id,
              menu: key.menu,
              menu2: key.menu.replace(/\s/g, ''),
              amfid: key.amf_id,
              default_icon: key.default_icon,
              lbl_code: key.lbl_code,
              url: key.url,
            });
          });

          store.dispatch({ type: 'SET_USER_MENU_SHORTCUT', data: datas });
        }
      });
  }

  toggle() {
    this.getMenuData();
    this.setState({
      modal: !this.state.modal,
      menIds: [],
    });
  }

  customShortcutToggle() {
    this.setState({
      modal: !this.state.modal,
      modalCustomShortcut: !this.state.modalCustomShortcut,
    });
  }

  checkedToggle(e) {
    this.setState({
      isUserAdmin: !this.state.isUserAdmin,
    });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { totalFav } = this.state;
    return (
      <div className="shortcut-box">
        <div className="title">
          <div className="d-flex align-items-center">
            <div className="w-100">
              <div className="heading">{t('LBL.SHORTCUT')}</div>
            </div>
            <div className="button-manage-shortcut">
              <Button
                id="manageShortcut"
                className="btn-header"
                onClick={this.toggle}
              >
                <FontAwesomeIcon icon={faCog} />
                <UncontrolledTooltip placement="bottom" target="manageShortcut">
                  {t('LBL.MANAGE')} {t('LBL.SHORTCUT')}
                </UncontrolledTooltip>
              </Button>
            </div>
          </div>
        </div>
        <div className="list_shortcut_menu">
          <div className="shortcut_icon_box justify-content-between">
            {/* Start MiniShortcutFavMenu */}
            {this.props?.user?.shortcut?.map((item) => (
              <Link
                key={`shortcut_${item.men_id}`}
                className={`${item.menu}`}
                to={{
                  pathname: item.url,
                  state: {
                    usrid: this.state.emp_id,
                    men_id: item.men_id,
                    fga_id: this.state.fga_id,
                    men_employee: item.men_id,
                  },
                }}
              >
                <div id={`${item.menu2}`} className="menu_list bg-blue-light">
                  {/* <FontAwesomeIcon icon={faHome} /> */}
                  <FontAwesomeIcon icon={`${item.default_icon}`} />
                  {/* <span className="shortcutlist_icon"><i className={`fa ${item.default_icon}`} /></span> */}
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`${item.menu2}`}
                  >
                    {item.menu}
                  </UncontrolledTooltip>
                </div>
              </Link>
            ))}
            {/* End MiniShortcutFavMenu */}
          </div>
        </div>
        {/* Start MiniShortcutAllMenu */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ marginTop: '100px' }}
        >
          <ModalHeader toggle={this.toggle}>
            {t('LBL.MANAGE')} {t('LBL.SHORTCUT')}
          </ModalHeader>
          <ModalBody>
            <form
              id="addshortcut"
              onSubmit={handleSubmit(this.handleSubmitInput)}
              name="addshortcut"
            >
              <div className="search-form-shortcut">
                {/* <p>Switch the menu below to make it a favorite shortcut menu<br />(4 shortcuts allowed)</p> */}
                {/* <p>Switch the menu below to make it a favorite shortcut menu</p> */}
                <InputGroup>
                  <Input
                    value={this.state.search}
                    onChange={this.search}
                    type="text"
                    placeholder="Search Shortcut Menu..."
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div
                style={{
                  overflowY: 'auto',
                  height: '390px',
                  maxHeight: '390px',
                }}
              >
                <Table className="table-shortcut table--bordered">
                  <tbody>
                    {this.state.rows.map((data, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={key}>
                        <td>
                          <span className="shortcutlist_icon">
                            <FontAwesomeIcon icon={data.default_icon} />
                          </span>
                          {data.menu}
                        </td>
                        <td
                          className="nav-shortcutlist"
                          style={{ float: 'left' }}
                        >
                          {data.checked && (
                            <div className="btn-group-shortcutlist">
                              {data.order_no !== 1 ? (
                                <Button
                                  className="btn-nav-shortcut"
                                  color="secondary"
                                  size="sm"
                                  onClick={() =>
                                    this.onMoveUp(data.men_id, data.order_no)
                                  }
                                >
                                  <FontAwesomeIcon icon={faAngleUp} />
                                </Button>
                              ) : (
                                <Button
                                  className="btn-nav-shortcut"
                                  color="secondary"
                                  size="sm"
                                  // onClick={() => this.onMoveUp(data.men_id, data.order_no)}
                                  disabled
                                >
                                  <FontAwesomeIcon icon={faAngleUp} />
                                </Button>
                              )}
                              {data.order_no !== totalFav ? (
                                <Button
                                  className="btn-nav-shortcut"
                                  color="secondary"
                                  size="sm"
                                  onClick={() =>
                                    this.onMoveDown(data.men_id, data.order_no)
                                  }
                                >
                                  <FontAwesomeIcon icon={faAngleDown} />
                                </Button>
                              ) : (
                                <Button
                                  className="btn-nav-shortcut"
                                  color="secondary"
                                  size="sm"
                                  // onClick={() => this.onMoveDown(data.men_id, data.order_no)}
                                  disabled
                                >
                                  <FontAwesomeIcon icon={faAngleDown} />
                                </Button>
                              )}
                            </div>
                          )}
                        </td>
                        <td className="text-right" style={{ float: 'right' }}>
                          <BootstrapSwitchButton
                            checked={data.checked}
                            onlabel=" "
                            offlabel=" "
                            size="sm"
                            onstyle="success"
                            width={40}
                            onChange={(e) =>
                              this.updateToggle(data.men_id, e, key)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </form>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" type="submit" form="addshortcut" >{t('FRM.SAVE')}</Button>
            <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
          </ModalFooter> */}
        </Modal>
        {/* End MiniShortcutAllMenu */}
        <Modal
          isOpen={this.state.modalCustomShortcut}
          toggle={this.customShortcutToggle}
          className="modal-dialog modal-input modal-large"
          style={{ marginTop: '100px' }}
        >
          <ModalHeader toggle={this.customShortcutToggle}>
            {t('FRM.ADD')} {t('FRM.SHORTCUT')}
          </ModalHeader>
          <ModalBody style={{ overflowY: 'auto', height: '280px' }}>
            <h4>box modal content</h4>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.customShortcutToggle}>
              {t('FRM.SAVE')}
            </Button>{' '}
            <Button color="secondary" onClick={this.customShortcutToggle}>
              {t('FRM.CANCEL')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default reduxForm({
  form: 'formShortcutPanelNew',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(translate('global')(connect(mapStateToProps)(ShortcutPanelNew)));
