/* eslint-disable array-callback-return */
/*
 * Page Adjustment
 * Notes: Master Data Adjustment
 * @author Gerdi
 * @date 22/09/2019
 * Modification (please note below)
*/
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef, prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { Container, Col, Modal, Button, Row, Card, CardBody, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import MinusIcon from 'mdi-react/MinusIcon';
import Spinner from 'react-bootstrap/Spinner';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCircle,
  faCheck,
  faAngleRight,
  faPlus,
  faAssistiveListeningSystems,
} from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import utils from '../../../../utils';

let adjustedMonth = [];
class AdjustmentListPage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    parmid: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.ADJUSTMENT_START') },
      { '2': t('LBL.ADJUSTMENT_END') },
      { '3': t('LBL.BACKPAY_DATE') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['10%', '25%', '25%', '20%', '20%'],
      statusEdit: false,
      disableButton: false,
      redirect: false,
      disabledInput: true,
      adjustmentStart: '',
      adjustmentEnd: '',
      backpayDate: '',
      remark: '',
      pygId: '',
      padId: '',
      dynForm: [],
      arrPycid: [],
      arrBackpay: [],
      arrEffective: [],
      arrNewValue: [],
      spanErrorStart: 'none',
      spanErrorEnd: 'none',
      spanErrorGroup: 'none',
      statusSave: false,
      hideOldValue: 'none',
      pygEdit: '',
      idEdit: '',
      newValue: [],
      oldValue: [],
      effectiveValue: [],
      backpayValue: [],
      buttonLoad: false,
      addcond: '',
      editcond: '',
      deletecond: '',
    };
    this.toggle = this.toggle.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deleteAdjustment = this.deleteAdjustment.bind(this);
    this.editAdjustment = this.editAdjustment.bind(this);
    this.renderAdjustment = this.renderAdjustment.bind(this);
    this.changeAndSetState = this.changeAndSetState.bind(this);
    this.getCheckData = this.getCheckData.bind(this);
    this.getButton = this.getButton.bind(this);
    this.getAdjustedDate = this.getAdjustedDate.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getCheckData();
      this.getButton();
      this.getAdjustedDate();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/payadjustment/getAllItem` });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.ADJUSTMENT_START') },
      { '2': t('LBL.ADJUSTMENT_END') },
      { '3': t('LBL.BACKPAY_DATE') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
    this.setState({
      adjustmentStart: '',
      adjustmentEnd: '',
      backpayDate: '',
      remark: '',
      pygId: '',
      padId: '',
      arrPycid: [],
      arrBackpay: [],
      arrEffective: [],
      arrNewValue: [],
    });
    this.props.initialize({
      'adjustment_start_date': '',
      'adjustment_end_date': '',
      'backpay_date': '',
      'remark': '',
      'pad_id': '',
      'pyg_id': '',
      dynAdjustment: [{}],
    });
  }

  getButton() {
    const men = this.props.parmid.state.men;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
      formBody,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const resmen = res.data.data;
        const array = [];
        if (resmen?.length > 0) {
          resmen.map(obj => (
            array.push(obj)
          ));
        }
        array.map((dt) => {
          if (dt.fua_read_priv === '1') {
            this.setState({ addcond: dt.fua_add });
            this.setState({ editcond: dt.fua_edit });
            this.setState({ deletecond: dt.fua_delete });
          }
        });
      }
    });
  }

  getAdjustedDate() {
    adjustedMonth = [];
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const empId = this.props.parmid.state.usrid;
    axios.post(
      `${ls.get('ws_ip')}/payadjustment/getExistDateEmp/${empId}`,
      '',
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        res.data.data.map(dt => {
          adjustedMonth.push(dt);
        });
      }
    });
  }

  getComponentRecurring() {
    const { token, apiws, oldValue } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const empId = this.props.parmid.state.usrid;
    axios.post(
      `${apiws}/paycomponent/getAllItemRecurring/${empId}`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        if (dataResponse.length !== 0) {
          const Arr = [];
          dataResponse.forEach((element, index) => {
            oldValue[index] = element.new_value;
          });
          dataResponse.map(obj => (
            Arr.push({
              'pyc_id': obj.pyc_id,
              'component': obj.component,
            })
          ));
          this.props.initialize({
            'adjustment_start_date': '',
            'adjustment_end_date': '',
            'backpay_date': '',
            'remark': '',
            'pad_id': '',
            dynAdjustment: Arr,
          });
        }
      }
    });
  }

  getCheckData(edit) {
    const { token, apiws, padId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const empId = this.props.parmid.state.usrid;
    axios.post(
      `${apiws}/payadjustment/getAllitemByEmp/${empId}`,
      '',
      config,
    ).then((response) => {
      if (response.data.data.length >= 1) {
        this.setState({ padId: response.data.data[0].pad_id });
        this.setState({ pygEdit: response.data.data[0].pyg_id });
      }
    });
  }

  mapFormData(b, a) {
    let val_b;
    if (a.search('date') === -1) {
      if (b === null) {
        val_b = '';
      } else if (b === 'null') {
        val_b = '';
      } else {
        val_b = b;
      }
    } else {
      if (b === '' || b === null || b === 'null') {
        val_b = '';
      } else {
        val_b = moment(b).format('YYYY-MM-DD');
      }
    }
    return val_b;
  }

  handleSubmit(values) {
    this.setState({
      arrPycid: [],
      arrBackpay: [],
      arrEffective: [],
      arrNewValue: [],
      buttonLoad: true,
    });
    const {
      apiws,
      token,
      idUser,
      arrPycid,
      newValue,
      oldValue,
      statusEdit,
    } = this.state;
    // Penting untuk change value inside object
    let data;
    let valuesData;

    // Check End null
    const adj_end = (values.adjustment_end_date === null) ? true : false;
    if (adj_end) valuesData = { ...values, adjustment_end_date: '' };

    // Loop component
    const arr = [];
    const count = values.dynAdjustment.length;
    for (let i = 0; i < count; i += 1) {
      const newVal = (newValue[i] === '' || newValue[i] === null || typeof newValue[i] === 'undefined') ? '' : newValue[i];
      const oldVal = (oldValue[i] === '' || oldValue[i] === null || typeof oldValue[i] === 'undefined') ? '' : oldValue[i];
      data = { ...values.dynAdjustment[i], 'new_value': newVal, 'old_value': oldVal };
      arr.push(data);
    }

    // Param data web service
    const empId = this.props.parmid.state.usrid;
    let url;
    let body;
    let error = 0;
    if (statusEdit) {
      url = `${apiws}/payadjustment/updateItem/${values.pad_id}`;
    } else {
      url = `${apiws}/payadjustment/saveItem`;
    }
    const start_date = moment(values.adjustment_start_date).format('YYYY-MM-DD');
    const end_date = moment(values.adjustment_end_date).format('YYYY-MM-DD');
    if (typeof values.adjustment_start_date === 'undefined' || values.adjustment_start_date === '') { error = 1; }
    if (end_date < start_date) { error = 1; }
    valuesData = { ...values, dynAdjustment: arr };
    const formBody = Object.keys(values).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(this.mapFormData(valuesData[key], key))}`).join('&');
    const dynAdjustment = JSON.stringify(valuesData.dynAdjustment);
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error === 0) {
      body = [formBody, `emp_id=${empId}`, `cuser_id=${idUser}`, `pyc_id=${arrPycid}`, `dyn_adjustment=${dynAdjustment}`].join('&');
      axios.post(url, body, config).then((resinput) => {
        if (resinput.data.status === 'ok') {
          this.toggle();
          this.getTableData();
          this.getCheckData();
          this.getAdjustedDate();
          this.setState({
            arrPycid: [],
            arrBackpay: [],
            arrEffective: [],
            arrNewValue: [],
          });
          showNotifications('Success', 'Save Success', 'success');
        }
        this.setState({ buttonLoad: false });
      });
    } else if (error === 1) {
      const startdate = moment(values.adjustment_start_date).format('YYYY-MM-DD');
      const enddate = moment(values.adjustment_end_date).format('YYYY-MM-DD');
      if (typeof values.adjustment_start_date === 'undefined' || values.adjustment_start_date === '') { this.setState({ spanErrorStart: '' }); }
      if (enddate < startdate) { this.setState({ spanErrorEnd: '' }); }
      this.setState({ buttonLoad: false });
    }
  }

  toggle(id) {
    if (id === 'add') {
      this.props.destroy();
      this.setState({ statusEdit: false });
      this.setState({ statusSave: false });
      this.getComponentRecurring();
      this.getAdjustedDate();
      this.setState({
        arrPycid: [],
        arrBackpay: [],
        arrEffective: [],
        arrNewValue: [],
        newValue: [],
        effectiveValue: [],
        backpayValue: [],
      });
    } else {
      this.setState({ statusSave: false });
      this.setState({ statusEdit: true });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  renderAdjustment = ({
    fields,
    meta: { error },
    disabledInput,
    hideOldValue,
    newValue,
    oldValue,
    effectiveValue,
    backpayValue,
  }) => (
    <tbody>
      {fields.map((adjustment, index) => (
        <tr>
          <td>
            <input type="hidden" name={`${adjustment}.pyc_id`} />
            <Field
              name={`${adjustment}.component`}
              component="input"
              type="text"
              disabled={disabledInput}
            />
          </td>
          <td className="number-form">
            { /* <Field
              name={`${adjustment}.old_value`}
              component="input"
              type="number"
              disabled
            /> */ }
            <NumberFormat
              name={`${adjustment}.old_value`}
              component={NumberFormat}
              thousandSeparator="."
              decimalSeparator=","
              prefix="Rp "
              type="text"
              value={oldValue[index]}
              onValueChange={(val) => {
                const a = this.state.oldValue.slice();
                a[index] = val.value;
                this.setState({ oldValue: a });
              }}
              disabled
            />
          </td>
          <td className="number-form">
            { /* <Field
              name={`${adjustment}.new_value`}
              component="input"
              type="number"
            /> */ }
            <NumberFormat
              name={`${adjustment}.new_value`}
              component={NumberFormat}
              thousandSeparator="."
              decimalSeparator=","
              prefix="Rp "
              type="text"
              value={newValue[index]}
              onValueChange={(val) => {
                const a = this.state.newValue.slice();
                a[index] = val.value;
                this.setState({ newValue: a });
              }}
            />
          </td>
          <td>
            <Field
              name={`${adjustment}.is_backpay`}
              id={`${adjustment}.is_backpay`}
              component={renderCheckBoxField}
              defaultChecked={backpayValue[index]}
            />
          </td>
          <td>
            <Field
              name={`${adjustment}.is_effective`}
              id={`${adjustment}.is_effective`}
              component={renderCheckBoxField}
              defaultChecked={effectiveValue[index]}
            />
          </td>
        </tr>
      ))
      }
    </tbody>
  );

  editAdjustment(id, noToggle, changeGroup, idGroup) {
    this.props.destroy();
    const {
      token,
      apiws,
      newValue,
      oldValue,
      effectiveValue,
      backpayValue,
    } = this.state;
    this.setState({ idEdit: id });
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const empId = this.props.parmid.state.usrid;
    const url = `${apiws}/payadjustmentcomponent/getAllItemGroupIdCustom/${id}/${empId}`;
    const Arr = [];
    axios.post(
      url,
      '',
      config,
    ).then((rescomp) => {
      if (rescomp.data.status === 'ok') {
        const dataResponse = rescomp.data.data;
        const yearExist = moment(dataResponse.data[0].adjustment_start_date).format('Y');
        const monthExist = moment(dataResponse.data[0].adjustment_start_date).format('M');
        for (let i = 0; i < adjustedMonth.length; i++) {
          if (adjustedMonth[i].year === yearExist && adjustedMonth[i].month === monthExist) {
            adjustedMonth.splice(i, 1);
          }
        }
        const adjEnd = (dataResponse.data[0].adjustment_end_date === null) ? '' : moment(dataResponse.data[0].adjustment_end_date);
        const backPay = (dataResponse.data[0].backpay_date === null) ? '' : moment(dataResponse.data[0].backpay_date);
        if (!noToggle) {
          this.toggle();
        }
        dataResponse.component.forEach((element, index) => {
          newValue[index] = element.new_value;
          oldValue[index] = element.old_value;
          effectiveValue[index] = (element.is_effective === '1') ? true : false;
          backpayValue[index] = (element.is_backpay === '1') ? true : false;
        });
        dataResponse.component.map(obj => (
          Arr.push({
            'pyc_id': obj.pyc_id,
            'component': obj.component,
            'new_value': obj.new_value,
          })
        ));
        this.props.initialize({
          'adjustment_start_date': dataResponse.data[0].adjustment_start_date !== null || dataResponse.data[0].adjustment_start_date !== '' ? moment(dataResponse.data[0].adjustment_start_date) : '',
          'adjustment_end_date': adjEnd,
          'backpay_date': backPay,
          'remark': dataResponse.data[0].remark,
          'pad_id': dataResponse.data[0].pad_id,
          dynAdjustment: Arr,
        });
      }
    });
  }

  deleteAdjustment(id) {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payadjustment/deleteItem/${id}`,
          `duser_id=${idUser}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            this.getTableData();
            this.getCheckData();
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch((error) => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
        axios.post(
          `${apiws}/payadjustmentcomponent/getAllItemGroupId/${id}`,
          '',
          config,
        ).then((resdel) => {
          const rowData = resdel.data.data;
          const countData = resdel.data.data.length;
          for (let i = 0; i < countData; i += 1) {
            axios.post(
              `${apiws}/payadjustmentcomponent/deleteItem/${rowData[i].pac_id}`,
              `duser_id=${idUser}`,
              config,
            );
          }
        });
      }
    });
  }

  changeAndSetState(x, y) {
    if (y === '') {
      if (x.target.name === 'remark') {
        this.setState({ remark: x.target.value });
      }
    } else {
      if (y === 'adjustment_start_date') {
        this.setState({ adjustmentStart: moment(x) });
        this.setState({ spanErrorStart: 'none' });
      }
      if (y === 'adjustment_end_date') {
        this.setState({ adjustmentEnd: moment(x) });
        this.setState({ spanErrorEnd: 'none' });
      }
      if (y === 'backpay_date') {
        this.setState({ backpayDate: moment(x) });
      }
    }
  }

  render() {
    const {
      handleSubmit,
      t,
      pristine,
    } = this.props;
    const {
      urlData,
      statusEdit,
      redirect,
      statusSave,
      disabledInput,
      hideOldValue,
      newValue,
      oldValue,
      effectiveValue,
      backpayValue,
      buttonLoad,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{ float: 'right', 'margin-bottom': '20px', display: this.state.addcond === '1' ? '' : 'none' }}
      >
        <FontAwesomeIcon icon={faPlus} /> {t('FRM.ADD')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '850px',
      marginTop: '95px',
      textAlign: 'center',
    };
    const buttonColor = 'primary';
    const buttonText = 'Save';
    let formId;
    let disabled;
    if (statusEdit) {
      formId = (
        <Field
          name="pad_id"
          component={renderTextInput}
          type="hidden"
        />
      );
      if (statusSave) {
        disabled = '';
      } else {
        disabled = 'pristine';
      }
    } else {
      if (statusSave) {
        disabled = '';
      } else {
        disabled = 'pristine';
      }
    }

    if (redirect) {
      return (<Redirect to={{
        pathname: '/administration/employee',
        state: { men_id: this.props.parmid.state.men_employee, fga_id: this.props.parmid.state.fga_id },
      }}
      />);
    }

    if (typeof this.props.parmid.state === 'undefined') {
      return <Redirect to="/administration/employee" />;
    }

    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="pad_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteAdjustment}
                  updateFunc={this.editAdjustment}
                  onRef={ref => (this.child = ref)}
                  textHead={`${this.props.parmid.state.name} (${this.props.parmid.state.code}) `}
                  conds={this.props.parmid.state.usrid}
                  displayStyleHead=""
                  displayTitle=""
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                  addCond={this.state.addcond}
                  pageTitle={`${t('LBL.ADJUSTMENT_LIST')}`}
                  pageSubTitle={`${t('LBL.VIEW')} & ${t('LBL.MANAGE')} ${t('LBL.ADJUSTMENT')} ${t('LBL.PAYROLL')}`}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={modalStyle}
        >
          <ModalHeader toggle={this.toggle}> <b> {t('LBL.ADJUSTMENT')} {t('LBL.PAYROLL')} {t('LBL.FORM')} </b> </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="adjustmentforminput"
              id="adjustmentforminput"
            >
              {formId}
              <Col md={6}>
                <div className="form__form-group" >
                  <span className="form__form-group-label">{t('LBL.START_DATE')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="adjustment_start_date"
                      component={renderDatePickerField}
                      placeholder="Start Date"
                      onChange={(x) => { this.changeAndSetState(x, 'adjustment_start_date'); }}
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group" >
                  <span className="form__form-group-label" style={{ paddingLeft: '30px' }}>{t('LBL.END_DATE')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="adjustment_end_date"
                      component={renderDatePickerField}
                      onChange={(x) => { this.changeAndSetState(x, 'adjustment_end_date'); }}
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group" >
                  <span className="form__form-group-label">{t('LBL.BACKPAY_DATE')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="backpay_date"
                      component={renderDatePickerField}
                      onChange={(x) => { this.changeAndSetState(x, 'backpay_date'); }}
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={10}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.REMARK')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="remark"
                      id="remark"
                      component={renderTextInput}
                      type="textarea"
                      onChange={(x) => { this.changeAndSetState(x, ''); }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group" style={{ marginTop: '-15px' }}>
                  <span className="form__form-group-label">{t('LBL.ADJUSTMENT')} {t('LBL.PAYROLL')}</span>
                  <div className="form__form-group-field form-group-mb15">
                    <table
                      id="myTable"
                      className="table data-grid-tbl responsive no-wrap table-no-btn"
                    >
                      <thead>
                        <th>{t('LBL.COMPONENT')}</th>
                        <th>{t('LBL.OLD_VALUE')}</th>
                        <th>{t('LBL.VALUE')}</th>
                        <th>{t('LBL.BACKPAY')}</th>
                        <th>{t('LBL.EFFECTIVE')}</th>
                      </thead>
                      <FieldArray
                        name="dynAdjustment"
                        component={this.renderAdjustment}
                        props={{
                          disabledInput: disabledInput,
                          hideOldValue: hideOldValue,
                          newValue: newValue,
                          oldValue: oldValue,
                          effectiveValue: effectiveValue,
                          backpayValue: backpayValue,
                        }}
                      />
                    </table>
                  </div>
                </div>
              </Col>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
            {(buttonLoad) ?
              (
                <Button color="primary">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '6px' }}
                  />
                  {t('FRM.LOADING')}
                </Button>
              ) :
              (
                <Button
                  color={buttonColor}
                  type="submit"
                  form="adjustmentforminput"
                  disabled={pristine}
                > {buttonText}
                </Button>
              )
            }
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    adjustment_start_date: {
      fieldLabel: 'Start date',
      required: true,
    },
    adjustment_end_date: {
      fieldLabel: 'End date',
      // required: true,
      lockDateAfter: 'adjustment_start_date',
      fieldLabelBefore: 'Start date',
    },
  };
  const errors = utils.validate(validation, values);
  if (values.adjustment_start_date !== null) {
    if (typeof values.adjustment_start_date === 'object') {
      let slicedDate = false;
      adjustedMonth.map(exist => {
        if (parseInt(exist.year, 10) === values.adjustment_start_date.year() && (parseInt(exist.month, 10) - 1) === values.adjustment_start_date.month()) {
          slicedDate = true;
        }
      });
      if (slicedDate === true) {
        errors.adjustment_start_date = 'Determined month is already has data';
      }
    }
  }
  return errors;
};

export default reduxForm({
  form: 'adjustmentForms', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AdjustmentListPage));
