/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
// import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import utils from '../../../../utils';
import renderNumberFormat from '../../../../shared/components/form/NumberFormat';

// let compthr = '';
class Reimbursement extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    initialize: PropTypes.func.isRequired,
    infoFunction: PropTypes.func.isRequired,
    detailFunction: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      orgposition: [],
      disableButton: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '40%', '20%', '25%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      page: '',
      payatt: [],
      editcond: '',
      addcond: '',
      deletecond: '',
      duplicate: false,
      claim_limit_value: 0,
      claim_period_year: 0,
      claim_period_month: 0,
      minimum_working_year: 0,
      minimum_working_month: 0,
      is_period: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.CLAIM_NAME') },
      { '2': t('LBL.CLAIM_METHOD') },
      { '3': t('LBL.CLAIM_LIMIT') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.toggle = this.toggle.bind(this);
    this.generate = this.generate.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editClaimType = this.editClaimType.bind(this);
    this.deleteClaimType = this.deleteClaimType.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.CLAIM_NAME') },
      { '2': t('LBL.CLAIM_METHOD') },
      { '3': t('LBL.CLAIM_LIMIT') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle(x) {
    this.props.destroy();
    this.setState({
      claim_limit_value: 0,
      claim_period_year: 0,
      claim_period_month: 0,
      minimum_working_year: 0,
      minimum_working_month: 0,
    });
    if (x === 'add' || x === 'cancel') {
      this.setState({ statusEdit: false });
      this.setState({ modal: !this.state.modal });
    } else if (x === 'dup') {
      this.setState({ duplicate: !this.state.duplicate });
    } else {
      this.setState({ statusEdit: false });
      this.setState({ modal: !this.state.modal });
    }
  }

  generate() {
    const { apiws, iduser, token } = this.state;

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Generate claim balance? this may take a few minutes.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, generate!',
      reverseButtons: true,
    }).then(() => {
      axios
        .post(`${apiws}/Empclaimbalance/generate`, `usr_id=${iduser}`, config)
        .then((res) => {
          if (res.data[res.data.length - 1] === 'Finish') {
            showNotifications('Success', 'Generated', 'success');
          }
        });
    });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.loadSelectBox();
      this.getButton();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({
      urlData: `${ls.get('ws_ip')}/Appreimbursement/getAllItem/`,
    });
  };

  getButton() {
    const men = ls.get('men_id');
    const fga = ls.get('fga_id');
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  editClaimType(id) {
    const { token, apiws } = this.state;
    this.setState({ disableButton: true });
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/appreimbursement/getItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const getResponseTerm = response.data.term;
          let terms = {};
          if (Object.keys(getResponseTerm).length > 0) {
            const editItems = [];
            getResponseTerm.item.forEach((items) => {
              if (
                getResponseTerm.name === 'all_job_title' ||
                getResponseTerm.name === 'job_title'
              ) {
                this.state.orgposition.forEach((pos) => {
                  if (pos.value === items) {
                    editItems.push(pos);
                  }
                });
              } else if (getResponseTerm.name === 'job_level') {
                this.state.orgjoblevel.forEach((job) => {
                  if (typeof job.value === 'string') {
                    if (job.value === `${items}`) {
                      editItems.push(job);
                    }
                  } else {
                    if (job.value === items) {
                      editItems.push(job);
                    }
                  }
                });
              } else if (getResponseTerm.name === 'emp_status') {
                this.state.empstatus.forEach((ems) => {
                  if (ems.value === items) {
                    editItems.push(ems);
                  }
                });
              }
            });
            if (getResponseTerm.name === 'all_job_title') {
              terms = { ...terms, exclude_pos_id: editItems };
            } else if (getResponseTerm.name === 'job_title') {
              terms = { ...terms, include_pos_id: editItems };
            } else if (getResponseTerm.name === 'job_level') {
              terms = { ...terms, include_job_id: editItems };
            } else if (getResponseTerm.name === 'emp_status') {
              terms = { ...terms, include_ems_id: editItems };
            }
          }
          const propmapping = {
            ect_id: getResponseData[0].ect_id,
            claim_name: getResponseData[0].claim_name,
            claim_method: getResponseData[0].claim_method,
            claim_limit: getResponseData[0].claim_limit,
            applied_to: getResponseData[0].applied_to,
            claim_period: getResponseData[0].claim_period,
            based_on_join_date: getResponseData[0].based_on_join_date,
            working_period: getResponseData[0].working_period,
            gender: getResponseData[0].gender,
            term_name: getResponseTerm.name,
            ...terms,
          };
          const statemapping = {
            claim_limit_value: getResponseData[0].claim_limit_value,
            claim_period_year: getResponseData[0].claim_period_year,
            claim_period_month: getResponseData[0].claim_period_month,
            minimum_working_year: getResponseData[0].minimum_working_year,
            minimum_working_month: getResponseData[0].minimum_working_month,
          };
          if (getResponseData[0].applied_to !== '1') {
            setTimeout(() => {
              this.setState({ is_period: 'none' });
            }, 200);
          }
          this.setState(
            { ...statemapping, statusEdit: true, modal: true },
            () => {
              this.props.initialize(propmapping);
            },
          );
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteClaimType(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/appreimbursement/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgjoblevel/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.job_id, label: obj.job_level }),
          );
          this.setState({ orgjoblevel: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/rtbemployeementstatus/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status }),
          );
          this.setState({ empstatus: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.post(
    //   `${apiws}/orgorganization/getAll/`,
    //   '',
    //   config,
    // ).then((response) => {
    //   if (response.data.status === 'ok') {
    //     const dataResponse = response.data.data;
    //     const Arr = [];
    //     dataResponse.map(obj => (
    //       Arr.push({ value: obj.org_id, label: obj.unit_organization })
    //     ));
    //     this.setState({ orgorganization: Arr });
    //   }
    // }).catch((error) => {
    //   console.log(error);
    // });

    axios
      .post(`${apiws}/orgposition/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          this.setState({ orgposition: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSubmitInput(values) {
    const userid = ls.get('user_cred').usr_id;
    const {
      token,
      apiws,
      claim_limit_value,
      claim_period_year,
      claim_period_month,
      minimum_working_year,
      minimum_working_month,
    } = this.state;
    const error = true;

    const id = values.ect_id ? values.ect_id : '';
    let postData = `ect_id=${id}`;
    const claim_name =
      typeof values.claim_name === 'string' ? values.claim_name : '';
    postData += `&claim_name=${claim_name}`;
    const claim_method =
      typeof values.claim_method === 'string' ? values.claim_method : '';
    postData += `&claim_method=${claim_method}`;
    const claim_limit =
      typeof values.claim_limit === 'string' ? values.claim_limit : '';
    postData += `&claim_limit=${claim_limit}&claim_limit_value=${claim_limit_value}`;
    const applied_to =
      typeof values.applied_to === 'string' ? values.applied_to : '';
    postData += `&applied_to=${applied_to}`;
    const claim_period =
      typeof values.claim_period === 'string' ? values.claim_period : '';
    // eslint-disable-next-line max-len
    postData += `&claim_period=${claim_period}&claim_period_year=${claim_period_year}&claim_period_month=${claim_period_month}`;
    const based_on_join_date =
      typeof values.based_on_join_date === 'string'
        ? values.based_on_join_date
        : '';
    postData += `&based_on_join_date=${based_on_join_date}`;
    let gender = '';
    if (typeof values.gender === 'object') {
      gender = values.gender.value;
    }
    postData += `&gender=${gender}`;
    const working_period =
      typeof values.working_period === 'string' ? values.working_period : '';
    // eslint-disable-next-line max-len
    postData += `&working_period=${working_period}&minimum_working_year=${minimum_working_year}&minimum_working_month=${minimum_working_month}`;
    const term_name =
      typeof values.term_name === 'string' ? values.term_name : '';
    postData += `&term_name=${term_name}`;
    const employee_term = [
      'all_employee',
      'all_job_title',
      'job_title',
      'job_level',
      'emp_status',
    ].indexOf(term_name);
    postData += `&employee_term=${employee_term}`;
    const exclude_pos_id =
      typeof values.exclude_pos_id === 'object'
        ? encodeURIComponent(JSON.stringify(values.exclude_pos_id))
        : '';
    postData += `&exclude_pos_id=${exclude_pos_id}`;
    const include_pos_id =
      typeof values.include_pos_id === 'object'
        ? encodeURIComponent(JSON.stringify(values.include_pos_id))
        : '';
    postData += `&include_pos_id=${include_pos_id}`;
    const include_job_id =
      typeof values.include_job_id === 'object'
        ? encodeURIComponent(JSON.stringify(values.include_job_id))
        : '';
    postData += `&include_job_id=${include_job_id}`;
    const include_ems_id =
      typeof values.include_ems_id === 'object'
        ? encodeURIComponent(JSON.stringify(values.include_ems_id))
        : '';
    postData += `&include_ems_id=${include_ems_id}`;
    postData += `&user_id=${userid}`;

    let url;
    if (id === undefined || id === '') {
      url = `${apiws}/appreimbursement/saveItem`;
    } else {
      url = `${apiws}/appreimbursement/updateItem/${id}`;
    }
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(url, postData, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
          this.setState({ duplicate: false });
          setTimeout(() => {
            this.child.reload();
          }, 1000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  render() {
    const {
      handleSubmit,
      t,
      pristine,
      infoFunction,
      detailFunction,
    } = this.props;
    const {
      orgposition,
      orgjoblevel,
      empstatus,
      urlData,
      company,
      statusEdit,
      duplicate,
      disableButton,
      is_period,
    } = this.state;
    let button_action;
    const addBtn = (
      <div>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={() => this.toggle('add')}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            display: this.state.addcond === '1' ? '' : '',
          }}
        >
          {t('FRM.ADD')} {t('LBL.CLAIM')}
        </Button>
        <Button
          color="secondary"
          className="btn_table_navbar"
          onClick={() => this.generate()}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            'margin-right': '5pt',
            display: this.state.addcond === '1' ? '' : '',
          }}
        >
          {t('LBL.GENERATE')}
        </Button>
      </div>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addformatreport"
          // disabled={pristine && disableButton}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addformatreport"
          type="submit"
          // disabled={pristine && disableButton}
        >
          {' '}
          {t('FRM.SAVE')}
        </Button>
      );
    }

    // if (duplicate) {
    //   return (

    //   );
    // }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="ect_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  updateFunc={this.editClaimType}
                  deleteFunc={this.deleteClaimType}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.CLAIM')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="addformatreport"
                  id="addformatreport"
                >
                  <Container>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <input type="hidden" name="ect_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CLAIM_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="claim_name"
                              component={renderTextInput}
                              type="text"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ display: 'none' }}>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CLAIM_METHOD')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="claim_method"
                              component={renderRadioButtonField}
                              label="Cash"
                              radioValue="1"
                              defaultChecked
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CLAIM_LIMIT')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="claim_limit"
                              component={renderRadioButtonField}
                              label={t('LBL.UNLIMITED')}
                              radioValue="1"
                              defaultChecked
                            />
                            <Field
                              name="claim_limit"
                              component={renderRadioButtonField}
                              radioValue="2"
                              additionalComponent={[
                                // <Input
                                //   name="claim_limit_value"
                                //   type="text"
                                //   onChange={e => this.setState({
                                //     claim_limit_value: e.target.value,
                                //     disableButton: !this.state.disableButton,
                                //   })}
                                //   defaultValue={this.state.claim_limit_value}
                                //   style={{ width: '150px', display: 'inline-block' }}
                                // />
                                <Field
                                  name="claim_limit_value"
                                  component={renderNumberFormat}
                                  other={{
                                    defaultValue: this.state.claim_limit_value,
                                    thousandSeparator: '.',
                                    decimalSeparator: ',',
                                    prefix: 'Rp ',
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      claim_limit_value: e.value,
                                      disableButton: !this.state.disableButton,
                                    })
                                  }
                                />,
                              ]}
                            />
                            <Field
                              name="claim_limit"
                              component={renderRadioButtonField}
                              label={t('LBL.BASIC_SALARY')}
                              radioValue="3"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.APPLIED_TO')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="applied_to"
                              component={renderRadioButtonField}
                              label={t('LBL.EVERY_PERIOD')}
                              radioValue="1"
                              defaultChecked
                              onChange={() => this.setState({ is_period: '' })}
                            />
                            <Field
                              name="applied_to"
                              component={renderRadioButtonField}
                              label={t('LBL.EVERY_CLAIM')}
                              radioValue="2"
                              onChange={() =>
                                this.setState({ is_period: 'none' })
                              }
                            />
                            <Field
                              name="applied_to"
                              component={renderRadioButtonField}
                              label={t('LBL.ONCE')}
                              radioValue="3"
                              onChange={() =>
                                this.setState({ is_period: 'none' })
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ display: is_period }}>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CLAIM_PERIOD')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="claim_period"
                              component={renderRadioButtonField}
                              label={t('LBL.YEAR')}
                              radioValue="1"
                              defaultChecked
                              additionalComponent={[
                                <Input
                                  name="claim_period_year"
                                  type="text"
                                  style={{
                                    width: '50px',
                                    display: 'inline-block',
                                    marginLeft: '9px',
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      claim_period_year: e.target.value,
                                      disableButton: !this.state.disableButton,
                                    })
                                  }
                                  defaultValue={this.state.claim_period_year}
                                />,
                              ]}
                            />
                          </div>
                          <div className="form__form-group-field">
                            <Field
                              name="claim_period"
                              component={renderRadioButtonField}
                              label={t('LBL.MONTH')}
                              radioValue="2"
                              additionalComponent={[
                                <Input
                                  name="claim_period_month"
                                  type="text"
                                  style={{
                                    width: '50px',
                                    display: 'inline-block',
                                    marginLeft: '9px',
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      claim_period_month: e.target.value,
                                      disableButton: !this.state.disableButton,
                                    })
                                  }
                                  defaultValue={this.state.claim_period_month}
                                />,
                              ]}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.BASED_ON_JOIN_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="based_on_join_date"
                              component={renderRadioButtonField}
                              label={t('LBL.YES')}
                              radioValue="1"
                              defaultChecked
                            />
                            <Field
                              name="based_on_join_date"
                              component={renderRadioButtonField}
                              label={t('LBL.NO')}
                              radioValue="0"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="h_line">
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="adj-panel-profile">
                          <div className="title-adj">{t('LBL.TERM')}</div>
                          <div className="body-adj">
                            <Row>
                              <Col md={6} xs={12} lg={6} xl={6}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.SEX')}
                                  </span>
                                  <div className="form__form-group-field products-list__search">
                                    <Field
                                      name="gender"
                                      component={renderSelectField}
                                      options={[
                                        { value: '1', label: t('LBL.MALE') },
                                        { value: '2', label: t('LBL.FEMALE') },
                                        { value: '0', label: t('LBL.BOTH') },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} xs={12} lg={6} xl={6}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.WORKING_PERIOD')}
                                  </span>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="working_period"
                                      component={renderRadioButtonField}
                                      label={t('LBL.YEAR')}
                                      radioValue="1"
                                      defaultChecked
                                      additionalComponent={[
                                        <Input
                                          name="minimum_working_year"
                                          type="text"
                                          style={{
                                            width: '50px',
                                            display: 'inline-block',
                                            marginLeft: '9px',
                                          }}
                                          onChange={(e) =>
                                            this.setState({
                                              minimum_working_year:
                                                e.target.value,
                                              disableButton: !this.state
                                                .disableButton,
                                            })
                                          }
                                          defaultValue={
                                            this.state.minimum_working_year
                                          }
                                        />,
                                      ]}
                                    />
                                  </div>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="working_period"
                                      component={renderRadioButtonField}
                                      label={t('LBL.MONTH')}
                                      radioValue="2"
                                      additionalComponent={[
                                        <Input
                                          name="minimum_working_month"
                                          type="text"
                                          style={{
                                            width: '50px',
                                            display: 'inline-block',
                                            marginLeft: '9px',
                                          }}
                                          onChange={(e) =>
                                            this.setState({
                                              minimum_working_month:
                                                e.target.value,
                                              disableButton: !this.state
                                                .disableButton,
                                            })
                                          }
                                          defaultValue={
                                            this.state.minimum_working_month
                                          }
                                        />,
                                      ]}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} xs={12} lg={12} xl={12}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.EMPLOYEE_TERM')}
                                  </span>
                                  <div className="form__form-group-field products-list__search">
                                    <Field
                                      name="term_name"
                                      component={renderRadioButtonField}
                                      label={t('LBL.ALL_EMPLOYEE')}
                                      radioValue="all_employee"
                                      defaultChecked
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    &nbsp;
                                  </span>
                                  <div className="form__form-group-field products-list__search">
                                    <Row className="w-100">
                                      <Col md={4}>
                                        <Field
                                          name="term_name"
                                          component={renderRadioButtonField}
                                          label={t('LBL.ALL_JOB_TITLE')}
                                          radioValue="all_job_title"
                                        />
                                      </Col>
                                      <Col md={2}>{t('LBL.EXCEPT')}:</Col>
                                      <Col md={6}>
                                        <Field
                                          name="exclude_pos_id"
                                          component={renderSelectField}
                                          placeholder="----"
                                          options={orgposition}
                                          multi="true"
                                          className='custom-multi'
                                          // disabled={disabledinput}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    &nbsp;
                                  </span>
                                  <div className="form__form-group-field products-list__search">
                                    <Row className="w-100">
                                      <Col md={4}>
                                        <Field
                                          name="term_name"
                                          component={renderRadioButtonField}
                                          label={t('LBL.JOB_TITLE')}
                                          radioValue="job_title"
                                        />
                                      </Col>
                                      <Col md={2}>{t('LBL.PICK')}:</Col>
                                      <Col md={6}>
                                        <Field
                                          name="include_pos_id"
                                          component={renderSelectField}
                                          placeholder="----"
                                          options={orgposition}
                                          multi="true"
                                          className='custom-multi'
                                          // disabled={disabledinput}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    &nbsp;
                                  </span>
                                  <div className="form__form-group-field products-list__search">
                                    <Row className="w-100">
                                      <Col md={4}>
                                        <Field
                                          name="term_name"
                                          component={renderRadioButtonField}
                                          label={t('LBL.JOB_LEVEL')}
                                          radioValue="job_level"
                                        />
                                      </Col>
                                      <Col md={2}>{t('LBL.PICK')}:</Col>
                                      <Col md={6}>
                                        <Field
                                          name="include_job_id"
                                          component={renderSelectField}
                                          placeholder="----"
                                          options={orgjoblevel}
                                          multi="true"
                                          className='custom-multi'
                                          // disabled={disabledinput}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    &nbsp;
                                  </span>
                                  <div className="form__form-group-field products-list__search">
                                    <Row className="w-100">
                                      <Col md={4}>
                                        <Field
                                          name="term_name"
                                          component={renderRadioButtonField}
                                          label={t('LBL.EMPLOYEE_STATUS')}
                                          radioValue="emp_status"
                                        />
                                      </Col>
                                      <Col md={2}>{t('LBL.PICK')}:</Col>
                                      <Col md={6}>
                                        <Field
                                          name="include_ems_id"
                                          component={renderSelectField}
                                          placeholder="----"
                                          options={empstatus}
                                          multi="true"
                                          className='custom-multi'
                                          // disabled={disabledinput}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle('cancel');
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    claim_name: {
      fieldLabel: 'Claim name',
      required: true,
    },
  };

  const errors = utils.validate(validation, values);

  return errors;
};

export default reduxForm({
  form: 'addformatreport',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(Reimbursement));
