import React, { useEffect, useState } from 'react';
import renderSelectField from '../../shared/components/form/Select';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import './okr.css';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../src/containers/Preferences/notificationMessages';

// const validate = (values) => {
//   const errors = {};
//   if (!values.align_to) {
//     errors.align_to = "Alignment shouldn't be empty";
//   }
//   return errors;
// };

const ChangeAligment = ({
  objId,
  alignSelected,
  modal,
  reload,
  toggleOffChange,
  t
}) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [alignSelect, setAlignSelect] = useState(alignSelected);
  const [alignment, setAlignment] = useState([]);

  const getAlignment = () => {
    const raw = JSON.stringify({
      obj_id: objId,
    });

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/Okrobjective/getAlignment`, raw, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arrCmp = [];
          const arrOrg = [];
          const arrEmp = [];

          const groupedOptions = [
            {
              label: 'Company',
              options: arrCmp,
            },
            {
              label: 'Organization',
              options: arrOrg,
            },
            {
              label: 'Individual',
              options: arrEmp,
            },
          ];


          dataResponse.map((item) => {
            if (item.obj_id !== objId) {
              if (item.emp_id){
                arrEmp.push({
                  value: item.obj_id,
                  label: item.objective_title,
                });
              } else if (item.cmp_id){
                arrCmp.push({
                  value: item.obj_id,
                  label: item.objective_title,
                });
              } else {
                arrOrg.push({
                  value: item.obj_id,
                  label: item.objective_title,
                });
              }
            }
          });

          setAlignment(groupedOptions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('align_to', alignSelect);
    formData.append('user_id', ls.get('user_cred').usr_id);

    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrobjective/updateAlignment/${objId}`,
        formData,
        config,
      )
      .then((response) => {
        if ((response.data.status = 'ok')) {
          showNotifications('Success', 'Submit Submission', 'success');
          reload();
          toggleOffChange('alignment');
        }
      })
      .catch((error) => {
        showNotifications('Fail', 'Save Failed', 'danger');
      });
  };

  useEffect(() => {
    getAlignment();
  }, []);

  return (
    <>
      <Modal size="sm" isOpen={modal} toggle={toggleOffChange}>
        <ModalHeader className="pl-4" toggle={toggleOffChange}>
          {t('LBL.CHGOBJ')}
        </ModalHeader>
        <ModalBody>
          <Row className="p-3" id="formObjective">
            <Col md={12}>
              <form name="formalignment" id="formalignment">
                <div className="form">
                  <div className="form__form-group">
                    <span class="form__form-group-label">
                    {t('LBL.SELECTOBJ')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="align_to"
                        component={renderSelectField}
                        placeholder="Select Objective Status"
                        options={alignment}
                        value={alignSelect}
                        props={{
                          disableTouched: true,
                          input: {
                            value: alignSelect || '',
                            onChange: (val) => setAlignSelect(val?.value),
                          },
                        }}
                        group="true"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => toggleOffChange('alignment')}
          >
            {t('LBL.CANCEL')}
          </Button>
          <Button color="primary" onClick={handleSubmit}>
          {t('FRM.SAVE')}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default reduxForm({
  form: 'formAlignment',
  destroyOnUnmount: true,
  // validate,
})(translate('global')(ChangeAligment));
