/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-parens */
/* eslint-disable space-infix-ops */
import React, { PureComponent } from 'react';
// eslint-disable-next-line no-unused-vars
import { Card, CardBody, Col, UncontrolledTooltip, Button, Container, Row, ButtonToolbar } from 'reactstrap';
// import { Bar } from 'react-chartjs-2';
import { translate } from 'react-i18next';
// import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/fontawesome-free-solid';
import PropTypes from 'prop-types';
// import axios from 'axios';
import ls from 'local-storage';
import EmpAge from './Chart/age/empage';
import EmpGender from './Chart/gender/empgender';
import EmpOffice from './Chart/office/empoffice';
import EmpStatus from './Chart/empstatus/empstatus';
import JobLevel from './Chart/joblevel/joblevel';
import EmpMarital from './Chart/marital/maritalstatus';
import DetailGender from './Chart/gender/detail';
import DetailAge from './Chart/age/detail';
import DetailJoblevel from './Chart/joblevel/detail';
import DetailMarital from './Chart/marital/detail';
import Detailoffice from './Chart/office/detail';
import DetailEms from './Chart/empstatus/detail';
import Printer from 'react-pdf-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Spinner from 'react-bootstrap/Spinner';


class NumberEmployeeChart extends PureComponent {
  static propTypes = {
    //   detailGender: PropTypes.bool.isRequired,
    //   allMenu: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    currentDate: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      token: '',
      orgId: [],
      level: 1,
      parent: [0, 0],
      all: true,
      detailgender: false,
      displaySpinner: 'none',
      displaySave: '',
    };
    this.getDetailGender = this.getDetailGender.bind(this);
    this.getDetailAge = this.getDetailAge.bind(this);
    this.getDetailJobLevel = this.getDetailJobLevel.bind(this);
    this.getDetailEmployeeStatus = this.getDetailEmployeeStatus.bind(this);
    this.getDetailMaritalStatus = this.getDetailMaritalStatus.bind(this);
    this.getDetailOffice = this.getDetailOffice.bind(this);
    this.download = this.download.bind(this);
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
  }

  componentWillUnmount() {
    // clearInterval(this.state.intervalId);
  }

  getDetailGender(detailmenu, allmenu) {
    setTimeout(() => {
      this.setState({ all: allmenu, detailgender: detailmenu });
    }, 200);
  }

  getDetailAge(detailmenu, allmenu) {
    setTimeout(() => {
      this.setState({ all: allmenu, detailage: detailmenu });
    }, 200);
  }

  getDetailJobLevel(detailmenu, allmenu) {
    setTimeout(() => {
      this.setState({ all: allmenu, detailjoblevel: detailmenu });
    }, 200);
  }

  getDetailEmployeeStatus(detailmenu, allmenu) {
    setTimeout(() => {
      this.setState({ all: allmenu, detailems: detailmenu });
    }, 200);
  }

  getDetailMaritalStatus(detailmenu, allmenu) {
    setTimeout(() => {
      this.setState({ all: allmenu, detailmarital: detailmenu });
    }, 200);
  }

  getDetailOffice(detailmenu, allmenu) {
    setTimeout(() => {
      this.setState({ all: allmenu, detailoffice: detailmenu });
    }, 200);
  }

  download() {
    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    // const { vfs } = vfsFonts.pdfMake;
    // pdfMake.vfs = vfs;
    const input = document.getElementById('htmltemplate');
    // console.log(input);
    window.scrollTo(0, 0);
    html2canvas(input, { scale: '1' })
      .then((canvas) => {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('p', 'pt', 'a4');
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const hratio = canvas.height / canvas.width;
        const widt = (pdf.internal.pageSize.width);
        const height = (hratio * widt);
        pdf.addImage(imgData, 'JPEG', 30, 30, (widt - 45), (height - 45));
        // pdf.output('dataurlnewwindow');
        pdf.save('People Dashboard');
        this.setState({
          displaySave: '',
          displaySpinner: 'none',
        });
      });
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/jpeg', 1.0);
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgData, 'JPEG', 0, 0);
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save('download.pdf');
    //   });
    // const val = htmlToPdfmake(`${input}`);
    // const dd = { content: val };
    // pdfMake.createPdf(dd).download();
  }
  render() {
    const { t, currentDate } = this.props;
    const { displaySave, displaySpinner } = this.state;
    return (
      <Container className="dashboard">
        <div className="row">
          <div className="col-md-12 text-right">
            <Button
              color="primary"
              style={{ display: displaySave, zIndex: '99' }}
              onClick={this.download}
            >
              <FontAwesomeIcon icon={faPrint} />
              {t('LBL.PRINT')} PDF
            </Button>
            <Button color="primary" style={{ display: displaySpinner, marginRight: '15px' }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '6px' }}
              />
              {t('FRM.LOADING')}
            </Button>
          </div>
        </div>
        {(this.state.all === true) && (
          <Printer>
            <div
              id="htmltemplate"
            // style={{ width: '210mm', height: '297mm' }}
            >
              <div style={{ padding: '15px' }}>
                <Row style={{ marginTop: '65px' }}>
                  <Col md={12} lg={12} xs={12} className="text-center">
                    <h4 className="title-dashboard-report">
                      {t('LBL.EMPLOYEE')} {t('LBL.DASHBOARD')} {currentDate}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <EmpGender getmenu={this.getDetailGender} />
                      </Col>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <EmpMarital getmenu={this.getDetailMaritalStatus} />
                      </Col>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <JobLevel getmenu={this.getDetailJobLevel} />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <EmpOffice getmenu={this.getDetailOffice} />
                      </Col>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <EmpAge getmenu={this.getDetailAge} />
                      </Col>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <EmpStatus getmenu={this.getDetailEmployeeStatus} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Printer>

        ) || (
            this.state.detailgender === true && (
              <Printer>
                <div
                  id="htmltemplate"
                >
                  <DetailGender getmenu={this.getDetailGender} />
                </div>
              </Printer>
            )
          ) || (
            this.state.detailage === true && (
              <Printer>
                <div
                  id="htmltemplate"
                >
                  <DetailAge getmenu={this.getDetailAge} />
                </div>
              </Printer>
            )
          ) || (
            this.state.detailjoblevel === true && (
              <Printer>
                <div
                  id="htmltemplate"
                >
                  <DetailJoblevel getmenu={this.getDetailJobLevel} />
                </div>
              </Printer>
            )
          ) || (
            this.state.detailems === true && (
              <Printer>
                <div
                  id="htmltemplate"
                >
                  <DetailEms getmenu={this.getDetailEmployeeStatus} />
                </div>
              </Printer>
            )
          ) || (
            this.state.detailmarital === true && (
              <Printer>
                <div
                  id="htmltemplate"
                >
                  <DetailMarital getmenu={this.getDetailMaritalStatus} />
                </div>
              </Printer>
            )
          ) || (

            this.state.detailoffice === true && (
              <Printer>
                <div
                  id="htmltemplate"
                >
                  <Detailoffice getmenu={this.getDetailOffice} />
                </div>
              </Printer>
            )
          )}
      </Container>
    );
  }
}

export default translate('global')(NumberEmployeeChart);
