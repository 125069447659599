/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import AvTimerIcon from 'mdi-react/AvTimerIcon';
import moment from 'moment';
import classNames from 'classnames';

const renderTimePickerIntervalField = (props) => {
  const [open, setOpen] = useState(false);
  const btnClass = classNames({
    'form__form-group-button': true,
    active: open,
  });
  return (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <div className="time_picker_interval">
        <TimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={props.input.onChange}
          showSecond={false}
          format={props.formatdate}
          use12Hours={props.timeMode}
          value={props.input.value}
          disabled={props.disabled || false}
          autoComplete="off"
        />
        <button className={btnClass} onClick={() => setOpen(!open)}>
          <AvTimerIcon />
        </button>
      </div>

      {(props.disableTouched && props.meta.error) && <span className="form__form-group-error">{props.meta.error}</span>}
      {((props.meta.touched) && props.meta.error) &&
        <span className="form__form-group-error">{props.meta.error}</span>}
    </div>
  );
};

renderTimePickerIntervalField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  timeMode: PropTypes.bool,
  formatdate: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

renderTimePickerIntervalField.defaultProps = {
  timeMode: false,
  formatdate: 'hh:mm',
  value: moment(),
  disabled: false,
};

export default renderTimePickerIntervalField;
