/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus, react/no-unused-state */
/* eslint-disable brace-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { translate } from 'react-i18next';
import { reset } from 'redux-form';
import FormUnitOrganization from './components/formUnitOrganization';
import UnitOrgTreeMenu from './components/treeMenuUnitOrganization';
import axios from 'axios';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/fontawesome-free-solid';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import showNotifications from '../../../Preferences/notificationMessages';

function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.org_id] = arrElem;
    mappedArr[arrElem.org_id].children = [];
  }

  // eslint-disable-next-line no-restricted-syntax
  Object.keys(mappedArr).map((id) => {
    // eslint-disable-next-line no-prototype-builtins
    if (typeof mappedArr[id] === 'object') {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.org_id_parent || mappedElem.org_id_parent !== 0) {
        mappedArr[mappedElem.org_id_parent].children.push({
          org_id: mappedElem.org_id,
          title: mappedElem.unit_organization,
          expanded: true,
          children: mappedElem.children,
        });
        // eslint-disable-next-line brace-style
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push({
          org_id: mappedElem.org_id,
          title: mappedElem.unit_organization,
          expanded: true,
          children: mappedElem.children,
        });
      }
    }
  });
  return tree;
}

class unitOrganizationSetting extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    inUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };
  constructor(props) {
    super();
    this.state = {
      dataTrees: [],
      apiws: '',
      token: '',
      editMode: false,
      slcunit: [],
      orglvl: [],
      defaultValues: {},
      modalFullScreen: false,
      nameUnitOrg: '',
      addcond: '',
      editcond: '',
      deletecond: '',
      dataWidth: ['10%', '20%', '25%', '20%'],
    };
    const { t } = props;

    this.heads = [
      { 0: t('LBL.NO') },
      { 1: t('LBL.UNIT_ORGANIZATION') },
      { 2: t('LBL.PARENT_ORGANIZATION_UNIT') },
      { 3: t('LBL.LEVEL') },
      // { 4: t('LBL.START_DATE') },
      // { 5: t('LBL.END_DATE') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.dataTreeLoad = this.dataTreeLoad.bind(this);
    this.loadaftersubmit = this.loadaftersubmit.bind(this);
    this.editTreeModal = this.editTreeModal.bind(this);
    this.updateTree = this.updateTree.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglefullmode = this.togglefullmode.bind(this);
    this.fulltree = this.fulltree.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.getButton = this.getButton.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deleteOrg = this.deleteOrg.bind(this);
  }

  componentWillMount() {
    const setdefaultvalue = {
      org_id: '',
      olv_id: '',
      org_id_parent: '',
      unit_organization: '',
      // org_order: '',
      start_date: '',
      end_date: '',
    };
    this.setState({ defaultValues: setdefaultvalue });
    this.setState({ token: ls.get('token') });
    this.setState({
      urlData: `${ls.get('ws_ip')}/orgorganization/getAllitem/`,
    });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.dataTreeLoad();
      this.getOrgLevel();
      this.getButton();
      this.getTableData();
    });
  }

  componentWillReceiveProps(props) {
    const { inUpdate, t } = this.props;
    if (inUpdate) {
      this.getOrgLevel();
    }
    this.heads = [
      { 0: t('LBL.NO') },
      { 1: t('LBL.UNIT_ORGANIZATION') },
      { 2: t('LBL.PARENT_ORGANIZATION_UNIT') },
      { 3: t('LBL.LEVEL') },
      // { 4: t('LBL.START_DATE') },
      // { 5: t('LBL.END_DATE') },
      { 4: t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getOrgLevel() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgorganizationlevel/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const res = response.data.data;
          const Arr = [];
          res.map((obj) =>
            Arr.push({ value: obj.olv_id, label: obj.level_name }),
          );
          this.setState({ orglvl: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  dataTreeLoad() {
    const { token, apiws, slcunit } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let datatree = [];
    const Arr = [];
    console.log('dataTreeLoad kepanggil kawan');
    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          datatree = unplet(response.data.data);
          this.setState({ dataTrees: response.data.data });
        }

        const dataResponse = response.data.data;
        if (dataResponse.length === 0) {
          Arr.push({ value: 0, label: 'Unit Organization' });
        } else {
          Arr.push({ value: 0, label: 'Unit Organization' });
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
        }

        console.table(datatree);
        this.setState({ dataTrees: datatree, slcunit: Arr });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  fulltree() {
    this.setState({ modalFullScreen: true });
  }

  loadaftersubmit(result, dispatch, props) {
    this.setState({
      slcunit: [],
      editMode: false,
      defaultValues: {},
      nameUnitOrg: '',
    });
    setTimeout(() => {
      this.dataTreeLoad();
    }, 500);
    dispatch(reset('unitOrg'));
    this.props.onUpdate();
  }

  editTreeModal(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    // let datatree = [];
    axios
      .post(`${apiws}/orgorganization/getallitembyid/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataValues = response.data.data[0];
          this.setState({ nameUnitOrg: dataValues.unit_organization });
          // if (dataValues.start_date !== null) {
          //   startdate = moment(dataValues.start_date);
          // }
          // if (dataValues.end_date !== null) {
          //   enddate = moment(dataValues.end_date);
          // }
          const setdefaultvalue = {
            org_id: dataValues.org_id,
            olv_id: dataValues.olv_id,
            org_id_parent:
              dataValues.org_id_parent === 0 ? '0' : dataValues.org_id_parent,
            unit_organization: dataValues.unit_organization,
            // org_order: dataValues.org_order,
            // start_date: startdate,
            // end_date: enddate,
          };

          this.setState({ defaultValues: setdefaultvalue });
        }
      })
      .catch(() => {
        // console.log(error);
      });
    this.setState({ editMode: true });
  }

  deleteOrg(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/orgorganization/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
                this.deleteItem();
                this.dataTreeLoad();
                this.getTableData();
              }, 200);
            } else if (response.data.status === 'child') {
              showNotifications(
                'Fail',
                'Delete Child of this organization first',
                'danger',
              );
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  updateTree(datatree) {
    this.setState({ dataTrees: datatree });
  }

  toggle() {
    this.setState(
      {
        defaultValues: {},
        editMode: !this.state.editMode,
      },
      () => {},
    );
  }

  togglefullmode() {
    this.setState(
      {
        modalFullScreen: !this.state.modalFullScreen,
      },
      () => {},
    );
  }

  deleteItem = () => {
    this.props.onDelete();
  };

  render() {
    const {
      dataTrees,
      defaultValues,
      editMode,
      slcunit,
      modalFullScreen,
      orglvl,
    } = this.state;
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col md={6}>
            <FormUnitOrganization
              initialValues={defaultValues}
              editMode={editMode}
              slcUnit={slcunit}
              orglvl={orglvl}
              editModal={this.toggle}
              onSubmitSuccess={this.loadaftersubmit}
              getTableData={this.getTableData}
              editUnitOrg={this.state.nameUnitOrg}
              addcond={this.state.addcond}
            />
          </Col>
          <Col md={6} style={{ height: '100%' }}>
            <button
              className="btn btn-secondary btn-sm"
              style={{
                verticalAlign: 'middle',
                padding: '4px 8px',
                marginBottom: '0px',
                marginRight: '5px',
                paddingRight: '3px',
                position: 'absolute',
                top: '5px',
                right: '15px',
                zIndex: '100',
              }}
              onClick={() => this.fulltree()}
            >
              <FontAwesomeIcon icon={faExpandArrowsAlt} />
            </button>
            <UnitOrgTreeMenu
              dataTree={dataTrees}
              editMode={this.editTreeModal}
              loadTree={this.dataTreeLoad}
              updateTree={this.updateTree}
              deleteMode={this.deleteItem}
              getTableData={this.getTableData}
              editcond={this.state.editcond}
              deletecond={this.state.deletecond}
              // deleteToggle={this.state.deleteConfirm}
            />
            <Modal
              isOpen={modalFullScreen}
              toggle={this.togglefullmode}
              className="modal-dialog modal-input"
              style={{ maxWidth: '1050px' }}
            >
              <ModalHeader toggle={this.togglefullmode}>
                {t('LBL.ORGANIZATION_SETTING')}
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: '450px',
                  height: '400px',
                  overflowY: 'auto',
                }}
              >
                <UnitOrgTreeMenu
                  fullMode
                  dataTree={dataTrees}
                  editMode={this.editTreeModal}
                  loadTree={this.dataTreeLoad}
                  updateTree={this.updateTree}
                  getTableData={this.getTableData}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.togglefullmode}>
                  {t('FRM.CANCEL')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
          {/* <Row> */}
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={this.state.urlData}
                  heads={this.heads}
                  primaryKey="org_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editTreeModal}
                  deleteFunc={this.deleteOrg}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
          {/* </Row> */}
        </Row>
      </Container>
    );
  }
}

export default translate('global')(unitOrganizationSetting);
