/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable operator-assignment */
import React, { PureComponent } from 'react';
import { Card } from 'reactstrap';
import { HorizontalBar } from 'react-chartjs-2';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: '#0000',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};

class RangeSalaryChart extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    office: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      // idUser: '',
      token: '',
      year: '',
      month: '',
      office: '',
      // intervalId: null,
    };
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    // this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getRangeSalary();
    });
    this.setState({ year: this.props.year });
    this.setState({ month: this.props.month });
    this.setState({ office: this.props.office });
  }


  componentWillReceiveProps(nextprops) {
    this.setState({ year: nextprops.year });
    this.setState({ month: nextprops.month });
    this.setState({ office: nextprops.office });
    setTimeout(() => {
      this.getRangeSalary();
    }, 200);
  }

  getRangeSalary() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = { year: this.state.year, month: this.state.month, off_id: this.state.office };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/chart/getSalaryEmployee`,
      formBody,
      config,
    ).then((response) => {
      const labels = ['> 50Mil', '30Mil < 50Mil', '20Mil < 30Mil',
        '15Mil < 20Mil', '10Mil < 15Mil', '5Mil < 10Mil', '< 5Mil'];
      let datas1 = 0;
      let datas2 = 0;
      let datas3 = 0;
      let datas4 = 0;
      let datas5 = 0;
      let datas6 = 0;
      let datas7 = 0;

      const len = response.data.data;
      if (typeof len !== 'undefined') {
        for (let i = 0; i < response.data.data.length; i += 1) {
          const pesVal = response.data.data[i].pes_value;
          if (parseInt(pesVal, 0) > 50000000) {
            datas1 = datas1 + 1;
          } else if (parseInt(pesVal, 0) > 30000000 && parseInt(pesVal, 0) <= 50000000) {
            datas2 = datas2 + 1;
          } else if (parseInt(pesVal, 0) > 20000000 && parseInt(pesVal, 0) <= 30000000) {
            datas3 = datas3 + 1;
          } else if (parseInt(pesVal, 0) > 15000000 && parseInt(pesVal, 0) <= 20000000) {
            datas4 = datas4 + 1;
          } else if (parseInt(pesVal, 0) > 10000000 && parseInt(pesVal, 0) <= 15000000) {
            datas5 = datas5 + 1;
          } else if (parseInt(pesVal, 0) > 5000000 && parseInt(pesVal, 0) <= 10000000) {
            datas6 = datas6 + 1;
          } else {
            datas7 = datas7 + 1;
          }
        }
        const initialState = {
          labels: labels,
          datasets: [
            {
              label: 'The Number of Salaries',
              backgroundColor: '#2495f5',
              // borderColor: '#ff0066',
              borderWidth: 1,
              hoverBackgroundColor: '#ebf1fa',
              // hoverBorderColor: '#cc0052',
              data: [datas1, datas2, datas3, datas4, datas5, datas6, datas7],
            },
          ],
        };
        this.setState({ data: initialState });
      }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Card>
        <div className="dashboard__chart chart_border">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.SALARY_RANGE')}
              </div>
              {/* <div className="w-100" style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                onClick={() => this.getDetail()}
              >{t('LBL.DETAIL')}
              </Button>
            </div> */}
            </div>
          </div>
          <div className="chart-body">
            <HorizontalBar data={this.state.data} options={options} />
          </div>
        </div>
      </Card>
    );
  }
}

export default translate('global')(RangeSalaryChart);
