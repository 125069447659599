/* eslint-disable no-mixed-operators */
/*
 * Page Grade
 * Notes: Master Data Grade
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  ButtonToolbar,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSearch } from '@fortawesome/fontawesome-free-solid';
// import {
// interaction, layer, custom, control, // name spaces
// Interactions, Overlays, Controls, // group
// Map, Layers, Overlay, Util, // objects
// } from 'react-openlayers';
import 'ol/ol.css';
import { Draw, Modify, Snap } from 'ol/interaction';
import Map from 'ol/Map';
import View from 'ol/View';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { transform, fromLonLat } from 'ol/proj';
import Polygon from 'ol/geom/Polygon';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style, Circle, Stroke, Fill } from 'ol/style';
import IndexGeofence from '.';
import { compose, withProps, lifecycle, withState } from 'recompose';
import { withScriptjs } from 'react-google-maps';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// const { compose, withProps, lifecycle } = require('recompose');
// const { withScriptjs } = require('react-google-maps');
// const {
//   StandaloneSearchBox,
// } = require('react-google-maps/lib/components/places/StandaloneSearchBox');
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';
let places = [];
const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDteQMYorivui0PPKlb-SBNlnV2_REvWfo&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ width: '100%' }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          places = refs.searchBox.getPlaces();
          this.setState({
            places,
          });
        },
      });
    },
  }),
  withScriptjs,
)((props) => (
  <div data-standalone-searchbox="">
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Set Point Interest"
        style={{
          boxSizing: 'border-box',
          border: '1px solid transparent',
          width: '240px',
          height: '32px',
          padding: '0 12px',
          borderRadius: '3px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
          fontSize: '14px',
          outline: 'none',
          textOverflow: 'ellipses',
        }}
      />
    </StandaloneSearchBox>
    <ol>
      {props.places.map(
        ({ place_id, formatted_address, geometry: { location } }) => (
          <li key={place_id}>
            {formatted_address}
            {' at '}({location.lat()}, {location.lng()})
          </li>
        ),
      )}
    </ol>
  </div>
));
let requestComplete = true;
let abortController = new AbortController();
// eslint-disable-next-line no-unused-expressions
class AttendanceDayOffPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    listGeofence: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    ogf_id: PropTypes.string.isRequired,
    edit: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DAY_OFF_TITLE') },
      { '2': t('LBL.THR') },
      { '3': t('LBL.DATE') },
      { '4': t('LBL.DESCRIPTION') },
      { '5': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '30%', '20%', '20%', '15%'],
      urlData: [],
      statusEdit: false,
      arrGroupShift: [],
      arrShift: [],
      showofficeinput: false,
      arrOffice: [],
      disabledCuti: true,
      editcond: '',
      addcond: '',
      deletecond: '',
      payload: '',
      koordinat: '',
      center_point: [],
      latlong: [],
      tablegeofence: false,
      value: '',
      suggestions: [],
      places: [],
      vectorSource: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getOffice = this.getOffice.bind(this);
    this.getButton = this.getButton.bind(this);
    // this.loadMap = this.loadMap.bind(this);
    this.startDraw = this.startDraw.bind(this);

    // this.raster = new TileLayer({
    //   source: new OSM(),
    // });

    this.source = new VectorSource({ wrapX: false });

    this.vector = new VectorLayer({
      source: this.source,
    });

    this.map = new Map();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getOffice();
    });
    this.getButton();
    if (this.props.edit === true) {
      this.getAllitemByid();
      // this.setmap();
      this.draw = new Draw({
        source: this.source,
        type: 'Polygon',
      });
      this.draw.on('drawend', (evt) => {
        const { feature } = evt;
        // const coords = feature.getGeometry().getCoordinates();
        // const koor = JSON.stringify(coords);
        const geojson = new GeoJSON();
        const payload = `[${geojson.writeFeature(feature, {
          featureProjection: this.map.getView().getProjection(),
        })}]`;

        const geojsonobject = geojson.writeFeature(feature, {
          featureProjection: this.map.getView().getProjection(),
        });

        // set vector layer
        const source = new VectorSource({
          features: new GeoJSON().readFeatures(geojsonobject, {
            featureProjection: this.map.getView().getProjection(),
          }),
        });

        const vector = new VectorLayer({
          source,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.7)',
            }),
            stroke: new Stroke({
              color: '#ffcc33',
              width: 2,
            }),
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color: '#ffcc33',
              }),
            }),
          }),
        });

        this.map.addLayer(vector);
        this.map.setTarget('map');
        // this.map.getView().setCenter(transform([113.9213, 0.7893], 'EPSG:4326', 'EPSG:3857'));
        // this.map.getView().setZoom(4);

        this.setState({ payload });
      });
    } else {
      const view = new View({
        center: [113.9213, 0.7893],
        zoom: 4,
      });
      const rasterLayer = new TileLayer({
        source: new OSM(),
      });
      this.map.addLayer(rasterLayer);
      // this.map.addLayer(vector);
      this.map.setTarget('map');
      // this.map.set
      this.map
        .getView()
        .setCenter(transform([113.9213, 0.7893], 'EPSG:4326', 'EPSG:3857'));
      this.map.getView().setZoom(4);
      // this.loadMap();
      this.draw = new Draw({
        source: this.source,
        type: 'Polygon',
      });

      this.draw.on('drawend', (evt) => {
        const { feature } = evt;

        const geojson = new GeoJSON();
        const payload = `[${geojson.writeFeature(feature, {
          featureProjection: this.map.getView().getProjection(),
        })}]`;
        this.setState({ payload });
        const geojsonobject = geojson.writeFeature(feature, {
          featureProjection: this.map.getView().getProjection(),
        });

        // set vector layer
        const source = new VectorSource({
          features: new GeoJSON().readFeatures(geojsonobject, {
            featureProjection: this.map.getView().getProjection(),
          }),
        });

        const vector = new VectorLayer({
          source,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.7)',
            }),
            stroke: new Stroke({
              color: '#ffcc33',
              width: 2,
            }),
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color: '#ffcc33',
              }),
            }),
          }),
        });

        this.map.addLayer(vector);
        this.map.setTarget('map');
        // this.map.getView().setCenter(transform([113.9213, 0.7893], 'EPSG:4326', 'EPSG:3857'));
        // this.map.getView().setZoom(4);
        // this.map.setTarget('map');
        // this.map.setView(v);
      });
    }
  };

  componentWillReceiveProps(newProps) {
    console.log(newProps);
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DAY_OFF_TITLE') },
      { '2': t('LBL.THR') },
      { '3': t('LBL.DATE') },
      { '4': t('LBL.DESCRIPTION') },
      { '5': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  startDraw() {
    this.map.getLayers().forEach((layer) => {
      const source = layer.getSource();

      if (source instanceof VectorSource) {
        source.clear();
      }
    });
    this.map.addInteraction(this.draw);
  }

  getOffice() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgoffice/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ dta_office: response.data.data });
          const arr = [];
          this.state.dta_office.map((data) => {
            arr.push({ value: data.off_id, label: data.office });
            return arr;
          });
          this.setState({
            arrOffice: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getAllitemByid(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/geofence/getAllItemById/${this.props.ogf_id}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const res = response.data.data;
          this.props.initialize({ name: res[0].title });
          // this.setmap(res[0].center_point, res[0].geojson);
          this.setmapbygeojson(res[0].center_point, res[0].geojson);
          // this.setState({ centerpoint: res[0].center_point, latlong: res[0].geojson });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  handleOnSearch = (string, results) => {
    console.log(string);
    if (!requestComplete) {
      abortController.abort();
      abortController = new AbortController();
    }
    requestComplete = false;
    const ws_ip = ls.get('ws_ip');
    const token = ls.get('token');

    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    axios({
      method: 'POST',
      url: `${ws_ip}/Facactivity/getLocationByAddress`,
      data: {
        search: string,
      },
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      signal: abortController.signal,
    })
      .then((res) => {
        const array = [];
        console.log(res);
        requestComplete = true;
        if (res.data.length > 0) {
          res.data.map((obj) =>
            array.push({
              id: obj.id,
              name: obj.address,
              lat: obj.latlong?.lat,
              lon: obj.latlong?.lon,
            }),
          );
          console.log(array);
          this.setState({ places: array });
        }
        console.log(this.state.places, 'onSearch');
      })
      .catch(() => {
        // console.log(error);
      });
  };

  formatResult(item) {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left', fontSize: 11 }}>
          {item.name}
        </span>
      </>
    );
  }

  setmapbygeojson(cp, latlon) {
    const coordinatesPolygon = [];
    const coord = JSON.parse(latlon);
    // Cycle traversal transfers longitude and latitude to the projection coordinate system of "EPSG:4326"
    for (let i = 0; i < coord.length; i++) {
      const pointTransform = fromLonLat(
        [coord[i].longitude, coord[i].latitude],
        'EPSG:4326',
      );
      coordinatesPolygon.push(pointTransform);
    }

    console.log(coordinatesPolygon);
    const geojsonObject = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinatesPolygon],
      },
      properties: null,
    };

    // Declare a new array
    // Tile Layer
    this.setState({ payload: `[${JSON.stringify(geojsonObject)}]` });
    const rasterLayer = new TileLayer({
      source: new OSM(),
    });

    const source = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        featureProjection: this.map.getView().getProjection(),
      }),
    });

    const vector = new VectorLayer({
      source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.7)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });

    const extent = source.getExtent();
    this.map.addLayer(rasterLayer);
    this.map.addLayer(vector);
    this.map.setTarget('map');
    this.map
      .getView()
      .setCenter(
        transform([cp.longitude, cp.latitude], 'EPSG:4326', 'EPSG:3857'),
      );
    this.map.getView().fit(extent);
    // this.map.getView().setZoom(1);
  }

  hideInputOffice() {
    this.setState({ showofficeinput: false });
  }

  toggle(item) {
    if (item === 'add') {
      this.hideInputOffice();
    }
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    console.log(values);
    const userid = ls.get('user_cred').usr_id;
    const { token } = this.state;
    const id = this.props.ogf_id;
    let data = {
      fence: this.state.payload,
      title: values.name,
    };
    let url;
    if (id === undefined || id === '') {
      url = `${ls.get('ws_ip')}/geofence/add`;
      data = { ...data, cuser_id: userid };
    } else {
      url = `${ls.get('ws_ip')}/geofence/edit/${id}`;
      data = { ...data, muser_id: userid };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.success === true) {
          showNotifications('Success', 'Save Success', 'success');
          this.props.listGeofence();
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  handleOnSelect = (item) => {
    // the item selected
    console.log(item);
    this.map
      .getView()
      .setCenter(transform([item.lon, item.lat], 'EPSG:4326', 'EPSG:3857'));
    this.map.getView().setZoom(18);
  };

  handleOnFocus = () => {
    console.log('Focused');
  };

  render() {
    const { statusEdit, urlData, arrOffice, showofficeinput } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button color="success" type="submit" form="addgeofence">
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button color="primary" form="addgeofence" type="submit">
          {t('FRM.SAVE')}
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <input name="ogf_id" type="hidden" />
          <Col md={12} xs={12} lg={12} xl={12} l>
            <form
              className="form form--vertical"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="addgeofence"
              id="addgeofence"
            >
              <Col md={8} xs={12} lg={8} xl={8}>
                <div id="map" className="map-container" />
              </Col>
              <Col md={4} xs={12} lg={4} xl={4}>
                <h4 className="form__form-group-label">
                  {t('FRM.ADD')} {t('LBL.GEOFENCE_AREA')}
                </h4>
                <hr style={{ marginTop: '5px' }} />
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    color="success"
                    type="button"
                    onClick={() => this.startDraw()}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                    {t('LBL.DRAW_POLYGON')}
                  </Button>
                </ButtonToolbar>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Set Point Interest
                  </span>
                  <div className="form__form-group-field">
                    {/* <Field
                        name="setPointInput"
                        component="input"
                        onChange={evt => this.loadMap(evt)}
                        type="text"
                        placeholder="Set Point Interest"
                      /> */}
                    {/* <PlacesWithStandaloneSearchBox getstate={this.handleOnSelect} /> */}
                    {/* <div className="form__form-group-icon form-search-custom">
                        <FontAwesomeIcon icon={faSearch} />
                      </div> */}
                    <div style={{ width: 400, zIndex: 999 }}>
                      <ReactSearchAutocomplete
                        items={this.state.places}
                        inputDebounce={1000}
                        onSearch={this.handleOnSearch}
                        onSelect={this.handleOnSelect}
                        onFocus={this.handleOnFocus}
                        formatResult={this.formatResult}
                        placeholder={'Search...'}
                        autoFocus
                      />
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <ButtonToolbar
                  className="form__button-toolbar"
                  style={{ marginTop: '15px' }}
                >
                  <Button type="button" onClick={this.props.listGeofence}>
                    Cancel
                  </Button>
                  {button_action}
                </ButtonToolbar>
              </Col>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.atd_title) {
    errors.atd_title = 'Day off title field shouldn’t be empty';
  }
  if (!values.atd_applies_to) {
    errors.atd_applies_to = 'Day off applies to field shouldn’t be empty';
  }
  if (typeof values.atd_applies_to === 'object') {
    const atdApplies = values.atd_applies_to.value;
    if (atdApplies === 'regional') {
      if (!values.off_id) {
        errors.off_id = 'Day off applies to office field shouldn’t be empty';
      }
    }
  }
  if (!values.description) {
    errors.description = 'Description name field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'dayoffforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AttendanceDayOffPage));
