import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import './okr.css';
import './Accordian';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../shared/components/form/Select';
import { translate } from 'react-i18next';
import Accordian from './Accordian';
import FormObjective from './FormObjective';
import ls from 'local-storage';
import axios from 'axios';

const listObjective = ({ dataSummary, args, t }) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [isObjCompany, setIsObjCompany] = useState(false);
  const [modal, setModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [owner, setOwner] = useState([]);
  const [period, setPeriod] = useState([]);
  const [alignment, setAlignmentData] = useState([]);
  const [filter, setFilter] = useState({
    date: {
      ocpId: '',
      nameDate: '',
      startDate: '',
      finishDate: '',
    },
    owner: {
      nameOwner: '',
      empId: '',
      orgId: ls.get('user_cred').org_id || '',
      cmpId: '',
    },
    year: new Date().getFullYear(),
  });
  const [yearOpt, setYearOpt] = useState([]);

  const modalObjective = () => setModal(true);
  const toggleOff = () => setModal(false);
  const reloadObjective = () => setReload(true);
  const setIsCompany = (boolean) => setIsObjCompany(boolean);

  const setAlignment = (data) => {
    setAlignmentData(data);
  };

  const setSummary = (data) => {
    dataSummary(data);
  };

  const getFilterOwner = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrobjective/getOrgAndAEmp/`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;

          const arrCompany = [];
          const arrOrg = [];
          const arrEmp = [];

          const groupedOptions = [
            {
              label: 'Company',
              options: arrCompany,
            },
            {
              label: 'Organization',
              options: arrOrg,
            },
            {
              label: 'Individual',
              options: arrEmp,
            },
          ];

          dataResponse.map((item) => {
            const value = item?.emp_name
              ? item?.emp_id
              : item.vision
              ? item.cmp_id
              : item?.org_id;

            const label = item?.emp_name
              ? item?.emp_name
              : item.vision
              ? item.company
              : item?.unit_organization;

            if (item?.vision) {
              arrCompany.push({
                value: value,
                label: label,
                nameOwner: item?.company,
                cmpId: item?.cmp_id,
              });
            } else if (item?.org_id_parent) {
              arrOrg.push({
                value: value,
                label: label,
                nameOwner: item?.unit_organization,
                orgId: item?.org_id,
              });
            } else if (item?.emp_name) {
              arrEmp.push({
                value: value,
                label: label,
                nameOwner: item?.emp_name,
                empId: item?.emp_id,
              });
            }
          });

          setOwner(groupedOptions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFilterPeriod = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/okrobjective/getAllPeriod/${filter?.year}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const arr = [];
          const yearOpts = [];
          dataResponse.map((item) => {
            arr.push({
              value: item.ocp_id,
              label: item.period_name,
              startDate: item.start_date,
              finishDate: item.finish_date,
            });
            if (new RegExp('Annual', 'g').test(item.period_name)) {
              setFilter((prev) => ({
                ...prev,
                date: {
                  ocpId: item.ocp_id,
                  nameDate: item.period_name,
                  startDate: item.start_date,
                  finishDate: item.finish_date,
                },
              }));
              const curYear = new Date().getFullYear();

              // loop options +5 year ahead
              for (let i = -1; i < 4; i++) {
                yearOpts.push({
                  value: curYear + i,
                  label: `${curYear + i}`,
                });
              }
            }
          });
          setYearOpt(yearOpts);
          setPeriod(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrgUser = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/facactivity/getEmpByLogin/`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          setFilter((prev) => ({
            ...prev,
            owner: { orgId: response.data.data[0].org_id },
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFilterOwner();
    getFilterPeriod();
    getOrgUser();
  }, []);

  return (
    <>
      <Col md={12}>
        <Row className="form">
          <Col md={3} className="mb-md-0 mb-2">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="owner"
                  component={renderSelectField}
                  placeholder="Select Owner"
                  options={owner}
                  isClearable={false}
                  props={{
                    disableTouched: true,
                    input: {
                      value:
                        filter?.owner?.cmpId ||
                        filter?.owner?.empId ||
                        filter?.owner?.orgId ||
                        '',
                      onChange: (val) => {
                        setFilter((prev) => ({
                          ...prev,
                          owner: {
                            nameOwner: val?.nameOwner || '',
                            empId: val?.empId || '',
                            orgId: val?.orgId || '',
                            cmpId: val?.cmpId || '',
                          },
                        }));
                      },
                    },
                  }}
                  group="true"
                />
              </div>
            </div>
          </Col>
          <Col md={3} className="mb-md-0 mb-2 ">
            <div className="form__form-group ml-0">
              <div className="form__form-group-field">
                <Field
                  name="period"
                  component={renderSelectField}
                  placeholder="Select Period"
                  options={period}
                  isClearable={false}
                  props={{
                    disableTouched: true,
                    input: {
                      value: filter?.date.ocpId || '',
                      onChange: (val) => {
                        setFilter((prev) => ({
                          ...prev,
                          date: {
                            ocpId: val?.value || '',
                            nameDate: val?.label || '',
                            startDate: val?.startDate || '',
                            finishDate: val?.finishDate || '',
                          },
                        }));
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={2} className="mb-md-0 mb-2">
            <div className="form__form-group ml-0">
              <div className="form__form-group-field">
                <Field
                  name="Year"
                  component={renderSelectField}
                  placeholder="Year"
                  isClearable={false}
                  options={yearOpt}
                  props={{
                    input: {
                      value: filter.year,
                      onChange: (val) => {
                        setFilter((prev) => ({ ...prev, year: val.value }));
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={2} className="d-flex justify-content-end ml-auto">
            <Button
              className="btn btn-objective btn-primary"
              onClick={modalObjective}
            >
              {t('LBL.ADDNEWOBJ')}
            </Button>
            {modal && (
              <FormObjective
                modal={modalObjective}
                alignment={alignment}
                reload={reloadObjective}
                isObjCompany={isObjCompany}
                toggleOffChange={toggleOff}
                // data={activeObj}
              />
            )}
          </Col>
        </Row>

        <Accordian
          filter={filter}
          alignmentData={setAlignment}
          summaryData={setSummary}
          setIsCompany={setIsCompany}
          reloadObjective={reload}
        />
      </Col>
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
})(translate('global')(listObjective));
