/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import Slider from 'react-slick';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import CrosshairsGpsIcon from 'mdi-react/CrosshairsGpsIcon';
import { translate } from 'react-i18next';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';
import parse from 'html-react-parser';
import { store } from '../../../App/store';
import { connect } from 'react-redux';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  swipeToSlide: true,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Sliderpanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      apiws: ls.get('ws_ip'),
      token: ls.get('token'),
      idUser: ls.get('user_cred')?.usr_id,
    };
  }

  componentDidMount = () => {
    this.checkCompany();
  };

  checkCompany() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${apiws}/Orgcompany/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          if (dataResponse.length === 0) {
            this.setState({
              disabled_form: false,
              status_edit: false,
              hideEditor: false,
            });
          } else {
            const visi =
              dataResponse[0].vision === null ? '' : dataResponse[0].vision;
            const misi =
              dataResponse[0].mission === null ? '' : dataResponse[0].mission;

            store.dispatch({
              type: 'SET_VISION_MISION',
              data: {
                visi,
                misi,
              },
            });
          }
        } else {
          this.setState({ button_disabled: false });
        }
      })
      .catch(() => {
        this.setState({ button_disabled: false });
      });
  }

  render() {
    const { handleSubmit, t } = this.props;
    return (
      <div className="slider-info-home">
        <Slider {...settings} className="dashboard__carousel">
          {/* <div>
            <div className="dashboard__slide info-panel-covid">
              <h4> Monitoring gejala COVID-19</h4>
              <p>
                Ayo kenali gejalanya dan laporkan kondisi Anda <br />
                setiap hari ke perusahaan.
              </p>
              <Button
                color="primary-soft"
                size="sm"
              >
                Selengkapnya
              </Button>
            </div>
          </div> */}
          <div>
            <div className="dashboard__slide">
              <CrosshairsGpsIcon />
              <p className="dashboard__slide-title">{t('LBL.OUR_VISION')}</p>
              <div className="vismis-content">
                {parse(this?.props?.user?.companyVisionMision?.visi ?? '')}
              </div>
            </div>
          </div>
          <div>
            <div className="dashboard__slide">
              <EmoticonIcon />
              <p className="dashboard__slide-title">{t('LBL.OUR_MISSION')}</p>
              <div className="vismis-content">
                {parse(this?.props?.user?.companyVisionMision?.misi ?? '')}
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(Sliderpanel));
