/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import MinusIcon from 'mdi-react/MinusIcon';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import ls from 'local-storage';
import axios from 'axios';
import renderSelectField from '../../../shared/components/form/Select';
import renderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
import showNotifications from '../../../containers/Preferences/notificationMessages';

class LeaveRequest extends PureComponent {
  static propTypes = {
    //   t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      token: '',
      arrEmp: [],
      leavecategory: [],
      modal: false,
    };

    this.getLeavecategory = this.getLeavecategory.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getEmpid = this.getEmpid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen !== state.modal) {
      return {
        modal: props.isOpen,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getEmpid(); this.getLeavecategory(); this.getEmployee();
    });
  }

  async getEmpid() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    await axios.post(
      `${ls.get('ws_ip')}/appuser/getAllItemById/${ls.get('user_cred').usr_id}`,
      '',
      config,
    ).then(async (resempid) => {
      const user = resempid.data.data;
      if (resempid.data.status === 'ok') {
        this.props.initialize({ emp_id: user[0].emp_id });
        await axios.post(
          `${ls.get('ws_ip')}/Employee/getAllItemExceptedEmp/${user[0].emp_id}`,
          '',
          config,
        ).then(async (resemp) => {
          const emp = resemp.data.data;
          const array = [];
          if (emp.length === 0) {
            this.setState({ arrEmp: array });
          } else {
            emp.map(obj => (
              array.push({ value: obj.emp_id, label: obj.emp_name })
            ));
            this.setState({ arrEmp: array });
          }
        }).catch(() => {
          // console.log(error);
        });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    console.log(empid);
    axios.post(
      `${ls.get('ws_ip')}/Employee/getAllItemExceptedEmp/${empid}`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.emp_id, label: obj.emp_name })
        ));
        this.setState({ arrEmp: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getLeavecategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/Rtbleavecategory/getAll/`,
      '',
      config,
    ).then((response) => {
      const wf = response.data.data;
      const array = [];
      if (wf.length === 0) {
        this.setState({ leavecategory: array });
      } else {
        wf.map(obj => (
          array.push({ value: obj.lec_id, label: obj.leave_category })
        ));
        this.setState({ leavecategory: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  handleSubmit(values) {
    const { apiws, token } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const error = true;
    const id = values.atm_id;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/empleaverequest/saveItem`;
      data = {
        ...values,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/empleaverequest/updateItem/${id}`;
      data = { ...values, muser_id: userid };
    }
    data = {
      data: JSON.stringify(data),
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { arrEmp, leavecategory } = this.state;
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Modal isOpen={this.state.modal} style={modalStyle} className="modal-dialog modal-input modal-component">
                    <ModalHeader>Leave History - Add new leave history</ModalHeader>
                    <ModalBody>
                      <form
                        className="form form--horizontal"
                        name="addleaverequest"
                        id="addleaverequest"
                        onSubmit={handleSubmit(this.handleSubmit)}
                      >
                        <Container className="p0">
                          <Row>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Leave Type</span>
                                <div className="form__form-group-field">
                                  <input type="hidden" name="emp_id" />
                                  <Field
                                    name="leave_type"
                                    component={renderSelectField}
                                    type="text"
                                    options={leavecategory}
                                    placeholder="Select leave Type"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Date</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="interval_date"
                                    component={renderIntervalDatePickerField}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Comment</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="comment"
                                    component="textarea"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Delegate To</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="emp_delegate_to"
                                    component={renderSelectField}
                                    options={arrEmp}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div className="form_adjustment">
                              <ButtonToolbar className="form__button-toolbar">
                                {/* <Link className="btn btn-outline-primary btn_table_navbar" to="/administration/leave">
                                  Back
                              </Link>
                                <Button color="primary" type="submit">Save</Button> */}
                              </ButtonToolbar>
                            </div>
                          </Row>
                        </Container>
                      </form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="submit"
                        color="primary"
                        form="addleaverequest"
                        disabled={pristine}
                      > Save
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => this.props.toggleModal()}
                        disabled={pristine}
                      >Cancel
                      </Button>
                    </ModalFooter>
                  </Modal >
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default reduxForm({
  form: 'leavereq', // a unique identifier for this form
})(translate('common')(LeaveRequest));
