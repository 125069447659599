import React from 'react';
import './dataTablePagination.css'; // Import your CSS file for styling

const DataTablePagination = ({
  currentPage,
  // totalPages,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  rowCount,
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1);
    }
  };

  return (
    <div className="data-table-pagination">
      <div className="left-pagination">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a
              className="page-link"
              onClick={handlePrevClick}
              style={{ cursor: 'pointer' }}
            >
              Previous
            </a>
          </li>
          {pages.map((page) => (
            <li
              key={page}
              className={`page-item ${page === currentPage ? 'active' : ''}`}
              onClick={() => onChangePage(page)}
            >
              <a className="page-link" style={{ cursor: 'pointer' }}>
                {page}
              </a>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? 'disabled' : ''
            }`}
          >
            <a
              className="page-link"
              onClick={handleNextClick}
              style={{ cursor: 'pointer' }}
            >
              Next
            </a>
          </li>
        </ul>
      </div>
      <div className="right-pagination">
        <div className="items-per-page">
          <label style={{ paddingTop: '15px', color: '#999999' }}>
            Per page:
          </label>
          <select
            value={rowsPerPage}
            onChange={(event) =>
              onChangeRowsPerPage(parseInt(event.target.value))
            }
            className="select-pagination"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div className="pagination-info">
          Showing {rowsPerPage * (currentPage - 1) + 1} to {rowCount} of{' '}
          {totalPages}
        </div>
      </div>
    </div>
  );
};

export default DataTablePagination;
