/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export default (function isAlphaNumeric(event) {
  // if (!(/[a-zA-Z0-9\s\d\-]/i.test(event.key) || event.code === 'Space')) {
  // event.preventDefault();
  // }
  if (!(/[a-zA-Z0-9]/i.test(event.key) || event.key === ' ' || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Delete')) {
    event.preventDefault();
  }
});
