import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from 'reactstrap';
import Chart from 'react-apexcharts';
import Layout from '../../../Layout/index';
import OkrDetail from './OkrDetail';
import ModalNeedAtt from './ModalNeedAtt';
import ModalNoParent from './modalNoParent';
import ModalGoalType from './ModalGoalType';
import ModalStatus from './ModalStatus';
import ModalProgress from './ModalProgress';
import ModalTotalObjective from './ModalTotalObjective';
import ModalTotalKeyResult from './ModalTotalKeyResult';
import {
  ACTIONS,
  ActivityContext,
  getReducer,
  initialState,
} from './OkrContext';

import './styleOkr.css';
import { translate } from 'react-i18next';
import renderSelectField from '../../../../shared/components/form/Select';
import { reduxForm, Field } from 'redux-form';
import DataTable from 'react-data-table-component';
import DataTablePagination from '../../../../shared/components/DataTable/DataTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faDownload,
  faKey,
  faCircle,
} from '@fortawesome/fontawesome-free-solid';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ls from 'local-storage';
import axios from 'axios';
import { OkrContext } from './OkrContext';

const OverviewOkr = ({ t }) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });
  const [yearOpt, setYearOpt] = useState([]);

  const [state, dispatch] = getReducer();
  const [data, setData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    page: 1,
    column: '',
    sortDirection: '',
    query: '',
    trigger: false,
    bodyParam: {},
  });

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        width: '100px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        width: '100px',
      },
    },
  };

  const [radial, setRadiial] = useState({
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '65%',
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -20,
              show: false,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              offsetY: 14,
              color: '#057AD7',
              fontSize: '30px',
              fontWeight: 'bold',
              show: true,
            },
          },
        },
      },

      labels: ['Percent'],
    },
  });
  const [period, setPeriod] = useState();
  const [owners, setOwner] = useState();
  const [inpt, setInpt] = useState('');
  const [modal, setModal] = useState(false);
  const [modalNeedAtt, setModalNeedAtt] = useState(false);
  const [modalNoParent, setModalNoParent] = useState(false);
  const [modalGoalType, setModalGoalType] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalProgress, setModalProgress] = useState(false);
  const [modalTotalObjective, setModalTotalObjective] = useState(false);
  const [modalTotalKeyResult, setModalTotalKeyResult] = useState(false);

  const [statusType, setStatusType] = useState('');
  const [progressType, setProgressType] = useState('');

  const [overallProgress, setOverallProgress] = useState(0);
  const [percent, setPercent] = useState({
    progress: {
      high: 0,
      medium: 0,
      low: 0,
    },
    status: {
      completed: 0,
      onHold: 0,
      onTrack: 0,
      delayed: 0,
      cancelled: 0,
      notStarted: 0,
    },
  });

  const [filter, setFilter] = useState({
    owner: {
      type: 'org_id',
      value: ls.get('user_cred').org_id,
    },
    period: {
      value: '',
      name: '',
    },
    year: new Date().getFullYear(),
  });

  const [dataCount, setDataCount] = useState({
    needAtt: 0,
    noParent: 0,
    objective: 0,
    keyResult: 0,
  });

  const toggle = (val) => {
    if (val == 'needAtt') {
      setModalNeedAtt(!modalNeedAtt);
    } else if (val == 'noParent') {
      setModalNoParent(!modalNoParent);
    } else if (val == 'goalType') {
      setModalGoalType(!modalGoalType);
    } else if (val == 'status') {
      setModalStatus(!modalStatus);
    } else if (val == 'progress') {
      setModalProgress(!modalProgress);
    } else if (val == 'totalObjective') {
      setModalTotalObjective(!modalTotalObjective);
    } else if (val == 'totalKeyResult') {
      setModalTotalKeyResult(!modalTotalKeyResult);
    } else {
      setModal(!modal);
    }
  };

  const initPercentProgress = () => {
    const form = new FormData();
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getPercentProgress`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          setPercent((prev) => ({
            ...prev,
            progress: {
              high: response.data.data.high,
              medium: response.data.data.medium,
              low: response.data.data.low,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initPercentStatus = () => {
    const form = new FormData();
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getPercentStatus`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          setPercent((prev) => ({
            ...prev,
            status: {
              completed: response.data.data.completed,
              onHold: response.data.data.onHold,
              onTrack: response.data.data.onTrack,
              delayed: response.data.data.delayed,
              notStarted: response.data.data.notStarted,
              cancelled: response.data.data.cancelled,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initDataOkrNeedAtt = () => {
    const form = new FormData();
    form.append('query', data.query);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getOkrNeedAtt`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          dispatch({ type: ACTIONS.SET_NEED_ATT, data: response.data.data });
          dispatch({
            type: ACTIONS.SET_NEED_ATT_TOTAL,
            data: response.data.total,
          });

          setDataCount((prev) => ({
            ...prev,
            needAtt: response.data.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initDataOkrNoParent = () => {
    const form = new FormData();
    form.append('query', data.query);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getOkrNoParent`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          dispatch({ type: ACTIONS.SET_NO_PARENT, data: response.data.data });
          dispatch({
            type: ACTIONS.SET_NO_PARENT_TOTAL,
            data: response.data.total,
          });

          setDataCount((prev) => ({
            ...prev,
            noParent: response.data.total,
          }));
          // setData((prev) => ({
          //   ...prev,
          //   totalRows: response.data.total,
          // }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initDataOkrObj = () => {
    const form = new FormData();
    form.append('query', data.query);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getOkrObjective`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          dispatch({ type: ACTIONS.SET_OBJ, data: response.data.data });
          dispatch({
            type: ACTIONS.SET_OBJ_TOTAL,
            data: response.data.total,
          });

          setDataCount((prev) => ({
            ...prev,
            objective: response.data.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initDataOkrKey = () => {
    const form = new FormData();
    form.append('query', data.query);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getOkrKeyResult`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          dispatch({ type: ACTIONS.SET_KEY, data: response.data.data });
          dispatch({
            type: ACTIONS.SET_KEY_TOTAL,
            data: response.data.total,
          });

          setDataCount((prev) => ({
            ...prev,
            keyResult: response.data.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initDataOkrByTeam = () => {
    const form = new FormData();
    form.append('query', data.query);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getOkrByTeam`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          console.log(response.data.data);
          dispatch({ type: ACTIONS.SET_OKR_DETAIL, data: response.data.data });
          dispatch({
            type: ACTIONS.SET_OKR_DETAIL_TOTAL,
            data: response.data.total,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFilterOwner = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrobjective/getOrgAndAEmp/`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;

          const arrCompany = [];
          const arrOrg = [];
          const arrEmp = [];

          const groupedOptions = [
            {
              label: 'Company',
              options: arrCompany,
            },
            {
              label: 'Organization',
              options: arrOrg,
            },
            {
              label: 'Individual',
              options: arrEmp,
            },
          ];

          dataResponse.map((item) => {
            const value = item?.emp_name
              ? item?.emp_id
              : item.vision
              ? item.cmp_id
              : item?.org_id;

            const label = item?.emp_name
              ? item?.emp_name
              : item.vision
              ? item.company
              : item?.unit_organization;

            if (item?.vision) {
              arrCompany.push({
                value: value,
                label: label,
                nameOwner: item?.company,
                cmpId: item?.cmp_id,
              });
            } else if (item?.org_id_parent) {
              arrOrg.push({
                value: value,
                label: label,
                nameOwner: item?.unit_organization,
                orgId: item?.org_id,
              });
            } else if (item?.emp_name) {
              arrEmp.push({
                value: value,
                label: label,
                nameOwner: item?.emp_name,
                empId: item?.emp_id,
              });
            }
          });

          setOwner(groupedOptions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFilterPeriod = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${auth.apiws}/okrobjective/getAllPeriod/${filter?.year}`, {}, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          const dataResponse = response.data.data;
          const yearOpts = [];

          const arr = [];
          dataResponse.map((item) => {
            arr.push({
              value: item?.ocp_id,
              label: item?.period_name,
              start_date: item?.start_date,
              finish_date: item?.finish_date,
            });
            if (new RegExp('Annual', 'g').test(item.period_name)) {
              setFilter((prev) => ({
                ...prev,
                period: { value: item.ocp_id, name: item.period_name },
              }));
            }
          });
          const curYear = new Date().getFullYear();

          // loop options +5 year ahead
          for (let i = -1; i < 4; i++) {
            yearOpts.push({
              value: curYear + i,
              label: `${curYear + i}`,
            });
          }
          setYearOpt(yearOpts);
          setPeriod(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOverallProgress = () => {
    const form = new FormData();
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${auth.apiws}/okrobjective/getoverallprogress`, form, {
        headers: {
          Authorization: `bearer ${auth.token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          setOverallProgress(response.data?.overall_progress);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOverallProgress();
    initDataOkrNeedAtt();
    initDataOkrNoParent();
    initPercentProgress();
    initPercentStatus();
    initDataOkrObj();
    initDataOkrKey();
    initDataOkrByTeam();
  }, [filter]);

  useEffect(() => {
    getFilterOwner();
    getFilterPeriod();
  }, []);
  return (
    <>
      <Container>
        <OkrContext.Provider value={[state, dispatch]}>
          <div className="tab-okr-dashboard">
            <Row className="d-flex align-items-center">
              <Col md={4}>
                {/* <div className="title-dashboard-okr">OVERVIEW</div> */}
              </Col>
              <Col md={8} className="">
                <Row className="form">
                  <Col md={6}>
                    <Field
                      name="owner"
                      component={renderSelectField}
                      placeholder="Select Owner"
                      group={'true'}
                      isClearable={false}
                      options={owners}
                      props={{
                        input: {
                          value: filter.owner.value,
                          onChange: (val) => {
                            if (val.orgId !== undefined) {
                              setFilter((prev) => ({
                                ...prev,
                                owner: { type: 'org_id', value: val.value },
                              }));
                            } else if (val.empId !== undefined) {
                              setFilter((prev) => ({
                                ...prev,
                                owner: { type: 'emp_id', value: val.value },
                              }));
                            } else if (val.cmpId !== undefined) {
                              setFilter((prev) => ({
                                ...prev,
                                owner: { type: 'cmp_id', value: val.value },
                              }));
                            } else {
                              setFilter((prev) => ({ ...prev, owner: {} }));
                            }
                          },
                        },
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <Field
                      name="period"
                      component={renderSelectField}
                      placeholder="Select Period"
                      isClearable={false}
                      options={period}
                      props={{
                        input: {
                          value: filter.period.value,
                          onChange: (val) => {
                            setFilter((prev) => ({
                              ...prev,
                              period: { value: val.value, name: val.label },
                            }));
                          },
                        },
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Field
                      name="Year"
                      component={renderSelectField}
                      placeholder="Year"
                      isClearable={false}
                      options={yearOpt}
                      props={{
                        input: {
                          value: filter.year,
                          onChange: (val) => {
                            setFilter((prev) => ({ ...prev, year: val.value }));
                          },
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-4">
              <Col md={4}>
                <div className="card-okr-dashboard">
                  <div className="title-dashboard-okr">
                    {t('LBL.ALLPROGRESS')}
                  </div>
                  <Chart
                    options={radial.options}
                    series={[overallProgress]}
                    type="radialBar"
                    height={200}
                  />
                </div>
              </Col>

              <Col md={4}>
                <Row className="h-100">
                  <Col md={12}>
                    <div className="card-okr-dashboard h-100">
                      <div className="d-flex align-items-start">
                        <div className="mr-auto">
                          <div className="title-dashboard-okr">
                            {t('LBL.NEEDATT')}
                          </div>
                        </div>
                        <div>
                          <Button
                            color="primary"
                            outline
                            className="btn-sm"
                            onClick={() => {
                              toggle('needAtt');
                            }}
                          >
                            {t('LBL.DETAIL')}
                          </Button>
                        </div>
                      </div>
                      <div className="total-parent-objective">
                        {dataCount.needAtt ?? 0}
                      </div>
                    </div>
                  </Col>

                  <Col md={12} className="mt-3">
                    <div className="card-okr-dashboard h-100">
                      <div className="d-flex align-items-start">
                        <div className="mr-auto">
                          <div className="title-dashboard-okr">
                            {t('LBL.NOPARENT')}
                          </div>
                        </div>
                        <div>
                          <div>
                            <Button
                              color="primary"
                              outline
                              className="btn-sm"
                              onClick={() => {
                                toggle('noParent');
                              }}
                            >
                              {t('LBL.DETAIL')}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="total-parent-objective">
                        {dataCount.noParent ?? 0}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={4}>
                <div className="card-okr-dashboard h-100">
                  <div className="d-flex align-items-start">
                    <div className="mr-auto">
                      <div className="title-dashboard-okr">
                        {t('LBL.GOALTYPE')}
                      </div>
                    </div>
                  </div>
                  <button
                    className="p-2 border bg-transparent w-100 mt-3 card-type-okr"
                    onClick={() => {
                      toggle('totalObjective');
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="mr-auto">
                        <div className="d-flex align-items-center">
                          <div className="mr-2">
                            <img
                              src="/img/icon-objective.svg"
                              alt=""
                              width={25}
                            />
                          </div>
                          <div>{t('LBL.TOTALOBJ')}</div>
                        </div>
                      </div>
                      <div>{dataCount.objective ?? 0}</div>
                    </div>
                  </button>
                  <button
                    className="p-2 border bg-transparent w-100 mt-2 card-type-okr"
                    onClick={() => {
                      toggle('totalKeyResult');
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="mr-auto">
                        <div className="d-flex align-items-center">
                          <div className="mr-2">
                            <img
                              src="/img/icon-key-objective.svg"
                              alt=""
                              width={25}
                            />
                          </div>
                          <div>{t('LBL.TOTALKR')}</div>
                        </div>
                      </div>
                      <div>{dataCount.keyResult ?? 0}</div>
                    </div>
                  </button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div
                  className="card-okr-dashboard h-100 progress_distribution"
                  id=""
                >
                  <div className="title-dashboard-okr">
                    {t('LBL.DISTPROGRESS')}
                  </div>
                  <div className="mt-2">
                    <div>&gt;50%</div>
                    <Progress value={percent.progress.high} color="primary">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('progress');
                          setProgressType('high');
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.progress.high || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>25% - 50%</div>
                    <Progress value={percent.progress.medium} color="warning">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('progress');
                          setProgressType('medium');
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.progress.medium || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>0% - 25%</div>
                    <Progress value={percent.progress.low} color="danger">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('progress');
                          setProgressType('basic');
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.progress.low || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="card-okr-dashboard progress_distribution">
                  <div className="title-dashboard-okr">
                    {t('LBL.STATUSDIST')}
                  </div>
                  <div className="mt-2">
                    <div>Completed</div>
                    <Progress value={percent.status.completed} color="success">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('status');
                          setStatusType('Completed');
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.status.completed || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>On Track</div>
                    <Progress value={percent.status.onTrack} color="primary">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('status');
                          setStatusType('On Track');
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.status.onTrack || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>On Hold</div>
                    <Progress value={percent.status.onHold} color="secondary">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('status');
                          setStatusType('On Hold');
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.status.onHold || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>Delayed</div>
                    <Progress value={percent.status.delayed} color="warning">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('status');
                          setStatusType('Delayed');
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.status.delayed || 0} %
                        </div>
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>Cancelled</div>
                    <Progress value={percent.status.cancelled} color="danger">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('status');
                          setStatusType('Cancelled');
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.status.cancelled || 0} %
                        </div>{' '}
                      </button>
                    </Progress>
                  </div>
                  <div className="mt-2">
                    <div>Not Started</div>
                    <Progress value={percent.status.notStarted} color="light">
                      <button
                        className="btn-transparant"
                        onClick={() => {
                          toggle('status');
                          setStatusType('Not Started');
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            color: 'white',
                            fontSize: '1.2em',
                          }}
                        >
                          {percent.status.notStarted || 0} %
                        </div>{' '}
                      </button>
                    </Progress>
                  </div>
                </div>
              </Col>
            </Row>

            <OkrDetail filter={filter} />

            {modalNeedAtt && (
              <ModalNeedAtt
                modal={modalNeedAtt}
                toggle={toggle}
                filter={filter}
              />
            )}

            {modalNoParent && (
              <ModalNoParent
                modal={modalNoParent}
                toggle={toggle}
                filter={filter}
              />
            )}

            {modalGoalType && (
              <ModalGoalType
                modal={modalGoalType}
                toggle={toggle}
                filter={filter}
              />
            )}

            {/* Modal Status Distribution */}
            <ModalStatus
              modal={modalStatus}
              toggle={toggle}
              statusType={statusType}
              filter={filter}
            />

            {/* Modal Progress */}
            <ModalProgress
              modal={modalProgress}
              toggle={toggle}
              progressType={progressType}
              filter={filter}
            />

            {modalTotalObjective && (
              <ModalTotalObjective
                modal={ModalTotalObjective}
                toggle={toggle}
                filter={filter}
              />
            )}
            {modalTotalKeyResult && (
              <ModalTotalKeyResult
                modal={ModalTotalKeyResult}
                toggle={toggle}
                filter={filter}
              />
            )}
          </div>
        </OkrContext.Provider>
      </Container>
    </>
  );
};

export default translate('global')(
  reduxForm({
    destroyOnUnmount: false,
  })(OverviewOkr),
);
