/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable quote-props */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable padded-blocks */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unused-state,object-shorthand,arrow-parens,comma-dangle,jsx-a11y/anchor-is-valid,no-script-url,react/style-prop-object,no-console,no-trailing-spaces,space-in-parens,space-infix-ops,react/no-unescaped-entities,camelcase,no-plusplus,max-len,quote-props,no-unused-vars */

import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Modal, ModalBody, } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import ViewSlip from '../../../../shared/components/table/viewSlip';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class PayrollPayslip extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.EMAIL'),
      t('LBL.OFFICE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.JOB_TITLE'),
      t('LBL.BANK'),
      t('LBL.BANK_ACCOUNT'),
      t('LBL.ACCOUNT_HOLDER'),
      t('LBL.PAYSLIP_PASSWORD'),
      t('FRM.FUNCTION'),
    ];
    this.months = [
      { value: '01', label: `${t('LBL.JANUARY')}`, short: 'Jan' },
      { value: '02', label: `${t('LBL.FEBRUARY')}`, short: 'Feb' },
      { value: '03', label: `${t('LBL.MARCH')}`, short: 'Mar' },
      { value: '04', label: `${t('LBL.APRIL')}`, short: 'Apr' },
      { value: '05', label: `${t('LBL.MAY')}`, short: 'May' },
      { value: '06', label: `${t('LBL.JUNE')}`, short: 'Jun' },
      { value: '07', label: `${t('LBL.JULI')}`, short: 'Jul' },
      { value: '08', label: `${t('LBL.AUGUST')}`, short: 'Aug' },
      { value: '09', label: `${t('LBL.SEPTEMBER')}`, short: 'Sep' },
      { value: '10', label: `${t('LBL.OCTOBER')}`, short: 'Oct' },
      { value: '11', label: `${t('LBL.NOVEMBER')}`, short: 'Nov' },
      { value: '12', label: `${t('LBL.DESEMBER')}`, short: 'Dec' },
    ];
    this.state = {
      apiws: '',
      token: '',
      urlData: '',
      payslipUrlData: '',
      idUser: '',
      arrYear: [],
      arrEmpStatus: [],
      arrFormatReport: [],
      arrEmployee: [],
      arrCompany: [],
      arrUnitOrganization: [],
      arrOffice: [],
      arrBank: [],
      displayLoad: 'none',
      displaySave: '',
      displaySlip: 'none',
      displayTable: 'none',
      condPygId: '',
      condPypId: '',
      condCompany: '',
      condOrganization: '',
      condOffice: '',
      condBank: '',
      condType: '',
      condEmail: '',
      condEmpId: '',
      condEmployee: '',
      condEmployeeStatus: '',
      showPassword: false,
      showModal: false,
      displayEmail: 'none',
      payslipPeriode: '',
      filename: 'Leave History Report',
      dataexcel: [],
      headerexcel: [],
      type: '',
    };

    this.getYear = this.getYear.bind(this);
    this.getFormatReport = this.getFormatReport.bind(this);
    this.getEmployeeStatus = this.getEmployeeStatus.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getCompany = this.getCompany.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.getOffice = this.getOffice.bind(this);
    this.getBank = this.getBank.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getReport = this.getReport.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.getDataSlip = this.getDataSlip.bind(this);
    this.detailPayslip = this.detailPayslip.bind(this);
  }

  componentDidMount = () => {
    this.getYear();
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getDataSlip();
      this.getFormatReport();
      this.getEmployeeStatus();
      this.getEmployee();
      this.getCompany();
      this.getUnitOrganization();
      this.getOffice();
      this.getBank();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/reportpayroll/getGenerateSlip/1` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
  }

  onChangeType(val) {
    if (val.value === '4') {
      this.setState({ displayEmail: '' });
    } else {
      this.setState({ displayEmail: 'none' });
    }
  }

  getDataSlip() {
    this.childTable.reload();
    this.setState({
      displaySlip: 'none', displayLoad: 'none', displaySave: '', displayTable: ''
    });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getFormatReport() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/payreport/getAllItem`,
      config,
    ).then((res) => {
      const format = res.data.data;
      const array = [];
      if (format.length === 0) {
        this.setState({ arrFormatReport: array });
      } else {
        format.map(obj => (
          array.push({ value: obj.prp_id, label: obj.format_name })
        ));
        this.setState({ arrFormatReport: array });
      }
    });
  }

  getEmployeeStatus() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/rtbemployeementstatus/getAll`,
      config,
    ).then((res) => {
      const emp = res.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmpStatus: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.ems_id, label: obj.ems_employeement_status })
        ));
        this.setState({ arrEmpStatus: array });
      }
    });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/0`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getCompany() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgcompany/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.cmp_id, label: obj.company })
        ));
        this.setState({ arrCompany: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgoffice`,
      config,
    ).then((res) => {
      const off = res.data.data;
      const array = [];
      if (off.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        off.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    });
  }

  getBank() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/rtbbank/getAll`,
      config,
    ).then((res) => {
      const rtk = res.data.data;
      const array = [];
      if (rtk.length === 0) {
        this.setState({ arrBank: array });
      } else {
        rtk.map(obj => (
          array.push({ value: obj.rtk_id, label: obj.bank })
        ));
        this.setState({ arrBank: array });
      }
    });
  }

  getReport(format_report) {
    this.setState({ displaySlip: 'none', displayTable: 'none' });
    const format = format_report.value;
    const conds = {
      'pyg_id': this.state.condPygId,
      'pyp_id': this.state.condPypId,
      'cmp_id': this.state.condCompany,
      'org_id': this.state.condOrganization,
      'off_id': this.state.condOffice,
      'emp_id': this.state.condEmployee,
      'ems_id': this.state.condEmployeeStatus,
      'rtk_id': this.state.condBank,
      'type': this.state.condType,
      'email': this.state.condEmail,
      'cuser_id': this.state.idUser,
      'format': format,
    };
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const bodyParameters = {
      cond: conds,
    };
    let url = `${apiws}/reportpayroll/getGenerateSlip/${format}/0/0`;
    if (this.state.condType === '5') {
      url = `${apiws}/reportpayroll/getPayslip/${format}/0/0`;
    }
    axios.post(
      url,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({ displayLoad: 'none', displaySave: '' });
        if (this.state.condType === '2') {
          this.setState({ showModal: true, srcFile: `data:application/pdf;base64, ${res.data.file}`, password: res.data.payslip_password });
        }
        if (this.state.condType === '5') {
          const { data, header } = res.data;
          const array = [];
          data.map((obj, index) => (
            array.push({ 'No': (index + 1), ...obj })
          ));
          this.setState({ dataexcel: array, headerexcel: header });
        }
        setTimeout(() => {
          if (this.state.condType === '5') {
            this.setState({ type: '5' });
            this.inputElement.click();
          }
          showNotifications('Success', res.data.message, 'success');
        }, 1000);
      }
    });
  }

  detailPayslip(id) {
    this.setState({ condEmpId: id });
    setTimeout(() => {
      this.childView.reload();
      this.setState({
        displaySlip: '', displayLoad: 'none', displaySave: '', displayTable: 'none'
      });
    }, 1000);

  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  handleChange(val) {
    const label = val.label;
    this.setState({ filename: `Report Payroll ${label}` });
  }

  handleSubmit(values) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (this.isEmpty(values) === false) {
      this.setState({
        displaySlip: 'none', displayLoad: '', displaySave: 'none', displayTable: 'none'
      });
      const month = (typeof values.period_month === 'undefined') ? '' : values.period_month.value;
      const year = (typeof values.period_year === 'undefined') ? '' : values.period_year.value;
      const valperiod = (year === '' || month === '') ? false : true;
      if (valperiod) {
        const payslip_periode = `${values.period_month.label} ${values.period_year.label}`;
        const period = `${year}${month}`;
        const pygId = (typeof values.group_component === 'undefined' || values.group_component === null) ? '' : values.group_component.value;
        const cmpId = (typeof values.company === 'undefined' || values.company === null) ? '' : values.company.value;
        const orgId = (typeof values.organization === 'undefined' || values.organization === null) ? '' : values.organization.value;
        const offId = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
        const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
        const emsId = (typeof values.employeement_status === 'undefined' || values.employeement_status === null) ? '' : values.employeement_status.value;
        const rtkId = (typeof values.bank === 'undefined' || values.bank === null) ? '' : values.bank.value;
        const type = (typeof values.type_report === 'undefined' || values.type_report === null) ? '' : values.type_report.value;
        const email = (typeof values.email === 'undefined' || values.email === null) ? '' : values.email;
        axios.get(
          `${apiws}/payperiod/getId/${period}`,
          config,
        ).then((res) => {
          if (res.data.data.length > 0) {
            const pypId = res.data.data[0].pyp_id;
            setTimeout(() => {
              this.setState({ condPeriod: period });
              this.setState({ condPygId: pygId });
              this.setState({ condPypId: pypId });
              this.setState({ condCompany: cmpId });
              this.setState({ condOrganization: orgId });
              this.setState({ condOffice: offId });
              this.setState({ condEmployee: empId });
              this.setState({ condEmployeeStatus: emsId });
              this.setState({ condBank: rtkId });
              this.setState({ condType: type });
              this.setState({ condEmail: email });
              this.setState({ payslipPeriode: payslip_periode });
            }, 100);
            if (!values.type_report) {
              showNotifications('Fail', 'Type Report must be choosen!', 'danger');
              setTimeout(() => {
                this.setState({
                  displaySlip: 'none', displayLoad: 'none', displaySave: '', displayTable: 'none'
                });
              }, 1000);
            } else if (values.type_report.value === '1') {
              // if (!values.employee) {
              //   this.setState({ displayLoad: 'none', displaySave: '' });
              //   showNotifications('Fail', 'Employee must be choosen!', 'danger');
              // } else {
              // this.setState({ type: '' });
              // const format = values.format_report.value;
              // this.setState({ urlData: `${apiws}/reportpayroll/getGenerateSlip/${format}` });
              // this.setState({ payslipUrlData: `${apiws}/reportpayroll/getPayslip/${format}` });
              // setTimeout(() => {
              //   // this.detailPayslip();
              //   this.getDataSlip();
              // }, 1000);
              // }
              const format = values.format_report.value;
              this.setState({ urlData: `${apiws}/reportpayroll/getGenerateSlip/${format}` });
              this.setState({ payslipUrlData: `${apiws}/reportpayroll/getPayslip/${format}` });
              setTimeout(() => {
                // this.detailPayslip();
                this.getDataSlip();
                showNotifications('Success', 'Load Success', 'success');
              }, 1000);
            } else if (values.type_report.value === '2') {
              if (!values.employee) {
                this.setState({ displayLoad: 'none', displaySave: '' });
                showNotifications('Fail', 'Employee must be choosen!', 'danger');
              } else {
                setTimeout(() => {
                  this.getReport(values.format_report);
                }, 1000);
              }
            } else if (values.type_report.value === '3') {
              Swal.fire({
                title: 'Are you sure?',
                text: 'Payslip will be sent to the employee\'s email !',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ff4861',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Continue',
                reverseButtons: true,
              }).then((result) => {
                if (result.value) {
                  setTimeout(() => {
                    this.getReport(values.format_report);
                  }, 1000);
                } else {
                  this.setState({ displayLoad: 'none', displaySave: '' });
                }
              });
            } else if (values.type_report.value === '4') {
              if (!values.email) {
                this.setState({ displayLoad: 'none', displaySave: '' });
                showNotifications('Fail', 'Employee must be choosen!', 'danger');
              } else {
                Swal.fire({
                  title: 'Are you sure?',
                  text: `Payslip will be sent to ${values.email} !`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#ff4861',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Continue',
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    setTimeout(() => {
                      this.getReport(values.format_report);
                    }, 1000);
                  } else {
                    this.setState({ displayLoad: 'none', displaySave: '' });
                  }
                });
              }
            } else if (values.type_report.value === '5') {
              setTimeout(() => {
                this.getReport(values.format_report);
              }, 1000);
            }
          } else {
            showNotifications('Not Found', 'Payslip Periode Not Found!', 'warning');
            setTimeout(() => {
              this.setState({
                displaySlip: 'none',
                displayLoad: 'none',
                displayTable: 'none',
                displaySave: '',
              });
            }, 1000);
          }
        });
      } else {
        if (!values.period_month) {
          showNotifications('Fail', 'Month must be choosen!', 'danger');
        }
        if (!values.period_year) {
          showNotifications('Fail', 'Year must be choosen!', 'danger');
        }
      }
    }
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrYear,
      arrFormatReport,
      arrEmpStatus,
      arrEmployee,
      arrUnitOrganization,
      arrCompany,
      arrOffice,
      arrBank,
      displayLoad,
      displaySave,
      displaySlip,
      displayTable,
      condPypId,
      condPygId,
      condEmpId,
      condEmployee,
      condEmployeeStatus,
      condBank,
      condCompany,
      condOrganization,
      condOffice,
      urlData,
      payslipUrlData,
      srcFile,
      password,
      showModal,
      displayEmail,
      payslipPeriode,
      type,
    } = this.state;
    const closeButton = {
      position: 'absolute',
      top: '15px',
      right: '15px',
      color: 'white',
      fontSize: '50px',
    };
    const toggle = () => this.setState({ showModal: !showModal });
    const externalCloseBtn = <button className="close" style={closeButton} onClick={toggle}>&times;</button>;
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card style={{ paddingLeft: '15px' }}>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.PAYROLL')} - {t('LBL.PAYSLIP')}</h3>
                    <h3 className="page-subhead subhead">{t('LBL.VIEW_PAYSLIP')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form id="form_payslip" className="form form--horizontal mt15" onSubmit={handleSubmit(this.handleSubmit)}>
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_month"
                                  component={renderSelectField}
                                  type="text"
                                  options={this.months}
                                  onChange={this.handleChange}
                                />
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="format_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrFormatReport}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmployee}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="employeement_status"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrEmpStatus}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="office"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrOffice}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="organization"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrUnitOrganization}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="company"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrCompany}
                                />
                                <Field
                                  name="office"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrOffice}
                                />
                                <Field
                                  name="organization"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrUnitOrganization}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.BANK')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="bank"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrBank}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  id="typeAttdReport"
                                  name="type_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'View' },
                                    { value: '2', label: 'PDF' },
                                    { value: '3', label: 'Generate' },
                                    { value: '4', label: 'Test Email' },
                                    { value: '5', label: 'Password Payslip' },
                                  ]}
                                  onChange={this.onChangeType}
                                />
                              </div>
                            </div>
                            {(type === '5') && (
                              <ExcelFile
                                element={
                                  <div
                                    onClick={this.handleClick}
                                    onKeyPress={this.handleClick}
                                    role="button"
                                    tabIndex="0"
                                  ><input type="hidden" ref={(ref) => { this.inputElement = ref; }} />
                                  </div>}
                                filename={this.state.filename}
                              >
                                <ExcelSheet data={this.state.dataexcel} name="Payslip">
                                  {
                                    this.state.headerexcel.map(obj => (
                                      <ExcelColumn label={obj} value={obj} />
                                    ))
                                  }
                                </ExcelSheet>
                              </ExcelFile>
                            )}
                          </Col>
                        </Row>
                        <Row style={{ display: displayEmail }}>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TO')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  id="email"
                                  name="email"
                                  component={renderTextInput}
                                  type="email"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <ButtonToolbar className="form__button-toolbar">
                              <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                              <Button color="primary" style={{ display: displayLoad }}>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: '6px' }}
                                />
                                {t('FRM.LOADING')}
                              </Button>
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displaySlip }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" style={{ marginLeft: '30px' }} onClick={this.getDataSlip}>{t('FRM.BACK')} </Button>
                  </ButtonToolbar>
                </Row>
                <ViewSlip
                  url={urlData}
                  conds={{
                    'pyg_id': condPygId,
                    'pyp_id': condPypId,
                    'cmp_id': condCompany,
                    'org_id': condOrganization,
                    'off_id': condOffice,
                    'emp_id': condEmpId,
                    'ems_id': condEmployeeStatus,
                    'rtk_id': condBank,
                    'type': '1',
                  }}
                  onRef={ref => (this.childView = ref)}
                  displayStyleHead="none"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayTable }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row className="m5 table-nopadding">
                  <div className="card__title">
                    <h5 className="bold-text">{t('LBL.PAYSLIP_REPORT')}</h5>
                    {/* <h5 className="subhead">{t('LBL.PAYSLIP_PERIOD')} {payslipPeriode}</h5> */}
                  </div>
                  <ViewListTable
                    url={payslipUrlData}
                    heads={this.heads}
                    detailFunc={this.detailPayslip}
                    primaryKey="emp_id"
                    buttonAction="detailOnly"
                    conds={{
                      'pyg_id': condPygId,
                      'pyp_id': condPypId,
                      'cmp_id': condCompany,
                      'org_id': condOrganization,
                      'off_id': condOffice,
                      'emp_id': condEmployee,
                      'ems_id': condEmployeeStatus,
                      'rtk_id': condBank,
                      'type': '1',
                    }}
                    onRef={ref => (this.childTable = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={showModal}
              className="modal-dialog modal-input"
              style={{
                maxWidth: '80%',
                textAlign: 'center',
              }}
              toggle={toggle}
              external={externalCloseBtn}
            >
              <ModalBody style={{ height: '90vh', padding: '0px' }}>
                <div className="row" style={{ margin: '8px' }}>
                  <p style={{ fontWeight: 'bold' }}>Payslip Password : {password}</p>
                </div>
                <iframe title="Payslip" width="100%" height="100%" src={srcFile} />
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}
const validate = (values) => {
  const errors = {};
  if (!values.period_month) {
    errors.period_month = 'Period field shouldn’t be empty';
  }
  if (!values.period_year) {
    errors.period_year = 'Period field shouldn’t be empty';
  }
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  if (!values.format_report) {
    errors.format_report = 'Format report field shouldn’t be empty';
  }
  return errors;
};
export default reduxForm({
  validate,
  form: 'payroll_payslip', // a unique identifier for this form
})(translate('global')(PayrollPayslip));
