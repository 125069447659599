/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import axios from 'axios';
import ls from 'local-storage';
import UserIcon from 'mdi-react/UserIcon';


export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, this.getProfileData);
  }
  getProfileData() {
    const {
      apiws,
      token,
      idUser,
    } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let getDataUser = true;
    if (idUser === 0) {
      getDataUser = false;
    }
    if (getDataUser) {
      axios.post(
        `${apiws}/appuser/getAllItemById/${idUser}`,
        '',
        config,
      ).then((response) => {
        let userName = '';
        if (response.data.length > 0) {
          userName = response.data.data[0].emp_id;
          const checkUser = (userName === null) ? false : true;
          if (checkUser) {
            axios.post(
              `${apiws}/employee/getAllItemByIdCurrent/${userName}`,
              '',
              config,
            );
            // .then(() => {
            // if (res.data.length > 0) {
            //   this.setState({
            // avatar: `${`${ls.get('ws_ip')}/public/uploads${res.data.data[0].path_photo}`}`,
            //   });
            // }
            // console.log(this.state.avatar);
            // });
          }
        }
      });
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    return (
      <div className="topbar__profile">
        <button className="topbar__btn topbar__btn--mail topbar__btn--new" onClick={this.toggle}>
          <UserIcon />
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="My Profiles" icon="user" path="/account/profile" />
            <TopbarMenuLink title="Calendar" icon="calendar-full" path="/default_pages/calendar" />
            <TopbarMenuLink title="Tasks" icon="list" path="/default_pages/calendar" />
            <TopbarMenuLink title="Inbox" icon="inbox" path="/mail" />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Account Settings" icon="cog" path="/account/profile" /> */}
            {/* <TopbarMenuLink title="Membership" icon="users" path="/account/membership" /> */}
            <TopbarMenuLink title="Package" icon="exit-up" path="/account/package" />
            <TopbarMenuLink title="Change Password" icon="lock" path="/account/change_password" />
            {/* <TopbarMenuLink title="Lock Screen" icon="lock" path="/lock_screen" /> */}
            <TopbarMenuLink title="Log Out" icon="exit" path="/logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}
