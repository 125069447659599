import React, { useEffect } from 'react';
import { actions } from 'react-redux-form';

const TextInput = ({
  input = {},
  placeholder,
  disabled = false,
  readOnly = false,
  type = 'text',
  maxLength = 255,
  max,
  textAlign = 'right',
  classes,
  disableTouched,
  value,
  meta: { touched, error },
  clearable = false,
}) => {
  useEffect(() => {
    if (typeof input.onChange == 'function') {
      if (clearable) {
        if (value) {
          input.onChange(value);
        } else {
          input.onChange(null);
        }
      } else {
        if (value) {
          input.onChange(value);
        }
      }
    }
  }, [value]);

  if (type === 'textarea') {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <textarea
          {...input}
          autoComplete="off"
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          className={classes}
          onBlur={() => actions.blur('input.name')}
        />
        {touched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
        {disableTouched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    );
  }
  if (type === 'textemployee') {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <input
          {...input}
          autoComplete="off"
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          max={max}
          className={classes}
        />
        {touched ||
          (error && <span className="form__form-group-error">{error}</span>)}
      </div>
    );
  }
  if (type === 'textareaemp') {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <textarea
          {...input}
          autoComplete="off"
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          className={classes}
          onBlur={() => actions.blur('input.name')}
        />
        {touched ||
          (error && <span className="form__form-group-error">{error}</span>)}
      </div>
    );
  }
  if (type === 'otp') {
    return (
      <input
        {...input}
        autoComplete="off"
        maxLength={maxLength}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        type={type}
        max={max}
        className="otp"
      />
    );
  }
  if (type === 'number') {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <input
          {...input}
          autoComplete="off"
          onKeyUp={() => {
            input.onChange(input.value.substr(0, maxLength));
          }}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          type="number"
          max={max}
          className={classes}
          style={{ textAlign: textAlign }}
          min="0"
        />
        {touched ||
          (error && <span className="form__form-group-error">{error}</span>)}
        {disableTouched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    );
  }

  if (type === 'numberDigit') {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <input
          {...input}
          autoComplete="off"
          onKeyUp={() => {
            input?.onChange(input?.value?.substr(0, maxLength));
          }}
          onChange={(e) => {
            if (new RegExp(/^\d+$/).exec(e?.target?.value)) {
              input.value = e?.target?.value;
            } else if (e?.target?.value?.length == 0) {
              input.value = e?.target?.value;
            }
          }}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          type="text"
          max={max}
          style={{ textAlign: textAlign }}
          min="0"
        />
        {touched && error && (
          <span className="form__form-group-error" style={{ right: '13.9em' }}>
            {error}
          </span>
        )}
        {disableTouched && error && (
          <span className="form__form-group-error" style={{ right: '13.9em' }}>
            {error}
          </span>
        )}
      </div>
    );
  }

  if (type === 'text-table-edit') {
    return (
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-below">
        <input
          {...input}
          autoComplete="off"
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          max={max}
          onKeyDown={(e) => {
            if (e.key === 'Enter') input.onKeyDown(e.target.value);
          }}
          className={classes}
          ref={input.ref}
        />
        {touched ||
          (error && <span className="form__form-group-error">{error}</span>)}
        {disableTouched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    );
  }

  return (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <input
        {...input}
        // value={value}
        autoComplete="off"
        maxLength={maxLength}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        type={type}
        max={max}
        className={classes}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
      {disableTouched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

export default TextInput;
