import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import './index.css';

import renderTextInput from '../../../shared/components/form/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { faPencilAlt, faSearch, faTrashAlt } from '@fortawesome/fontawesome-free-solid';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import DataTable from 'react-data-table-component';
import DataTablePagination from '../../../shared/components/DataTable/DataTablePagination';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../notificationMessages';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';

const ProductivitySetting = ({ t }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [inpt, setInpt] = useState('');
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(undefined);
  const [displayLoad, setDisplayLoad] = useState('none');
  const [displaySave, setDisplaySave] = useState('block');
  const [data, setData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    page: 1,
    column: '',
    sortDirection: '',
    query: '',
  });

  useEffect(() => {
    initDataProductivity();
  }, []);
  useEffect(() => {
    initDataProductivity();
  }, [data.query]);

  const toggleModal = () => {
    setIsEdit(undefined);
    setInpt('');
    setModal(!modal);
  };
  const toggleTabpane = tab => setActiveTab(tab);
  const toggleDisplay = (load) => {
    if (load) {
      setDisplayLoad('block');
      setDisplaySave('none');
    } else {
      setDisplayLoad('none');
      setDisplaySave('block');
    }
  };
  const openEdit = (e, id) => {
    toggleModal();
    setInpt(e);
    setIsEdit(id);
  };

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        width: '100px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        width: '100px',
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'to',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    pagination: true,
  };
  const initDataProductivity = () => {
    axios
      .post(
        `${ls.get('ws_ip')}/Facactivitytype/getAll`,
        {
          query: data.query,
          page: data.page,
          per_page: data.perPage,
          sort: data.column || 'activity_name',
          order: data.sortDirection || 'asc',
        },
        {
          headers: {
            Authorization: `bearer ${ls.get('token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          setData((prev) => ({
            ...prev,
            data: response.data.data,
            totalRows: response.data.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = values => {
    const userid = ls.get('user_cred').usr_id;
    toggleDisplay(true);
    const url = isEdit ? `${ls.get('ws_ip')}/Facactivitytype/updateItem` : `${ls.get('ws_ip')}/Facactivitytype/saveItem`;
    axios.post(
      url, { activity_name: inpt, user_id: userid, fat_id: isEdit || '' },
      {
        'headers': {
          'Authorization': `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ).then(res => {
      toggleDisplay(false);
      if (res.data.status === 'ok') {
        initDataProductivity();

        setTimeout(() => {
          showNotifications('Success', 'Available Data', 'success');
          toggleModal();
        }, 200);
      } else {
        showNotifications('Fail', 'Failed', 'danger');
      }
    });
  };

  const handleDelete = id => {
    const userid = ls.get('user_cred').usr_id;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${ls.get('ws_ip')}/Facactivitytype/deleteItem`,
          { user_id: userid, fat_id: id },
          {
            'headers': {
              'Authorization': `bearer ${ls.get('token')}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        ).then(res => {
          if (res.data.status === 'ok') {
            initDataProductivity();

            setTimeout(() => {
              showNotifications('Success', 'Available Data', 'success');
            }, 200);
          } else {
            showNotifications('Fail', 'Failed', 'danger');
          }
        });
      }
    });
  }

  const columns = [
    {
      name: t('LBL.NUMBER'),
      cell: (row, idx) => {
        return (data.page - 1) * data.perPage + (idx + 1);
      },
    },
    {
      name: t('LBL.ACTIVITYTYPE'),
      selector: (row) => row.activity_name,
    },
    {
      name: t('FRM.FUNCTION'),
      cell: (row) => (
        <div className='d-flex align-items-center' id='btnProductivity'>
          <Button
            className='btn btn-sm btn-data-table btn-outline-primary'
            onClick={() => openEdit(row.activity_name, row.fat_id)}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className='btn btn-sm btn-outline-danger btn-data-table'
            onClick={() => handleDelete(row.fat_id)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Card>
          <CardBody className='p0'>
            <Row className='m0' style={{ padding: '0px' }}>
              <Col md={12} style={{ padding: '0px' }}>
                <div className='header-page-panel d-flex align-items-center'>
                  <img
                    src='/img/productivity-icon-default.png'
                    alt=''
                    width={50}
                  />
                  <div className='ml-2'>
                    <h3 className='page-title'>{t('MEN.ACTIVITY_SETTING')}</h3>
                    <h3 className='page-subhead subhead'>
                      {t('MEN.ACTIVITY_SETTING')}
                    </h3>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='tabs tabs--bordered-top'>
              <div className='tabs__wrap'>
                <Nav tabs className='tab-preferences'>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '1',
                      })}
                      onClick={() => {
                        toggleTabpane('1');
                      }}
                    >
                      {t('LBL.ACTIVITY')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent>
                  <TabPane tabId='1'></TabPane>

                  <Row className='p-3 mt-3'>
                    <Col md={3}>
                      <div className='form'>
                        <div className='form__form-group'>
                          <div className='form__form-group-field'>
                            <input
                              type='text'
                              className='form__form-group-input-wrap form__form-group-input-wrap--error-above'
                              placeholder='Enter Key Result Title'
                              onChange={(q) => setData({ ...data, query: q.target.value })}
                            />
                            <div className='form__form-group-icon'>
                              <span className='pl-1 pr-1'>
                                <FontAwesomeIcon
                                  color='black'
                                  icon={faSearch}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={9}
                      className='d-flex justify-content-end add-type-activity'
                    >
                      <Button
                        color='primary'
                        className='btn_table_navbar'
                        style={{ height: '80%' }}
                        onClick={toggleModal}
                      >
                        {t('FRM.ADD')}{' '}{t('LBL.ACTIVITYTYPE')}
                      </Button>
                    </Col>

                    <Col md={12}>
                      <DataTable
                        columns={columns}
                        data={data.data}
                        direction='auto'
                        responsive
                        striped
                        customStyles={customStyles}
                        paginationComponent={DataTablePagination}
                        paginationServer
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationTotalRows={data.totalRows}
                        onChangeRowsPerPage={(newPerPage, page) => {
                          axios
                            .post(
                              `${ls.get('ws_ip')}/Facactivitytype/getAll`,
                              {
                                page,
                                per_page: newPerPage,
                                sort: data.column || 'activity_name',
                                order: data.sortDirection || 'asc',
                                query: data.query,
                              },
                              {
                                headers: {
                                  Authorization: `bearer ${ls.get('token')}`,
                                  'Content-Type': 'application/x-www-form-urlencoded',
                                },
                              },
                            )
                            .then((response) => {
                              if (response.data.status === 'ok') {
                                setData({
                                  ...data,
                                  data: response.data.data,
                                  perPage: newPerPage,
                                  page,
                                  totalRows: response.data.total,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        onChangePage={(page) => {
                          axios
                            .post(
                              `${ls.get('ws_ip')}/Facactivitytype/getAll`,
                              {
                                page,
                                per_page: data.perPage,
                                sort: data.column || 'activity_name',
                                order: data.sortDirection || 'asc',
                                query: data.query,
                              },
                              {
                                headers: {
                                  Authorization: `bearer ${ls.get('token')}`,
                                  'Content-Type': 'application/x-www-form-urlencoded',
                                },
                              },
                            )
                            .then((response) => {
                              if (response.data.status === 'ok') {
                                setData({
                                  ...data,
                                  data: response.data.data,
                                  page,
                                  totalRows: response.data.total,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        sortServer
                        onSort={(col, sort) => {
                          axios
                            .post(
                              `${ls.get('ws_ip')}/Facactivitytype/getAll`,
                              {
                                page: data.page,
                                per_page: data.perPage,
                                sort: col.sortField,
                                order: sort,
                                query: data.query,
                              },
                              {
                                headers: {
                                  Authorization: `bearer ${ls.get('token')}`,
                                  'Content-Type': 'application/x-www-form-urlencoded',
                                },
                              },
                            )
                            .then((response) => {
                              if (response.data.status === 'ok') {
                                setData({
                                  ...data,
                                  data: response.data.data,
                                  column: col.sortField,
                                  sortDirection: sort,
                                  totalRows: response.data.total,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                    </Col>
                    <Modal
                      id='addTypeActivity'
                      isOpen={modal}
                      toggle={toggleModal}
                    >
                      <ModalHeader toggle={toggleModal}>
                        {isEdit? t('FRM.EDIT'):t('FRM.ADD')}{' '}{t('LBL.ACTIVITYTYPE')}
                      </ModalHeader>
                      <ModalBody className='p-3'>
                        <Row>
                          <Col md='12'>
                            <div className='form'>
                              <div className='form__form-group'>
                                <span className='form__form-group-label'>
                                  {t('LBL.ACTIVITYTYPE')}
                                </span>
                                <div className='form__form-group-field'>
                                  <Field
                                    className='form-control'
                                    component={renderTextInput}
                                    type='text'
                                    input={{ value: inpt, onChange: (event) => setInpt(event.target?.value) }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color='secondary'
                          onClick={toggleModal}
                        >
                          {t('FRM.BACK')}
                        </Button>
                        <Button
                          color='primary'
                          style={{ display: displayLoad, marginRight: '0px' }}
                        >
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            style={{ marginRight: '6px' }}
                          />
                          {t('FRM.LOADING')}
                        </Button>
                        <Button
                          color='primary'
                          type='submit'
                          form='submissionFlow'
                          style={{ display: displaySave }}
                          onClick={handleSubmit}
                        >
                          {t('FRM.SUBMIT')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Row>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
})(translate('global')(ProductivitySetting));
