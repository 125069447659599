/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';
import utils from '../../../utils';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';

const IntervalDatePickerField = (props) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // did mount
    const { value } = props.input;
    // console.log(props.input.name, value);
    // set start date when differ
    if (utils.isObjectExist(value) && props.input.value.start !== startDate) {
      setStartDate(props.input.value.start);
    }

    if (utils.isObjectExist(value) && props.input.value.end !== endDate) {
      setEndDate(props.input.value.end);
    }
  });

  const handleChange = ({ startDate: startDateParam, endDate: endDateParam }) => {
    startDateParam = startDateParam || startDate;
    endDateParam = endDateParam || endDate;
    if (startDateParam && startDateParam.isAfter(endDateParam)) {
      endDateParam = startDateParam;
    }
    // update state
    setStartDate(startDateParam);
    setEndDate(endDateParam);

    // trigger change to redux form
    props.input.onChange({ start: startDateParam, end: endDateParam });
  };

  const handleChangeStart = startDateParam => handleChange({ startDate: startDateParam });

  const handleChangeEnd = endDateParam => handleChange({ endDate: endDateParam });

  return (
    <div className="date-picker date-picker--interval">
      <div>
        {props.meta.touched && utils.isObjectExist(props.meta.error) && props.meta.error.start && (
          <span className="form__form-group-error-custom">{props.meta.error.start}</span>
        )}
        <DatePicker
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeStart}
          dateFormat="DD/MM/YYYY"
          showMonthDropdown
          showYearDropdown
          placeholderText="From"
        />
        <div className="form__form-group-icon">
          <CalendarBlankIcon />
        </div>
      </div>
      <MinusIcon className="date-picker__svg" />
      <div>
        {props.meta.touched && utils.isObjectExist(props.meta.error) && props.meta.error.end && (
          <span className="form__form-group-error-custom">{props.meta.error.end}</span>
        )}
        <DatePicker
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeEnd}
          dateFormat="DD/MM/YYYY"
          showMonthDropdown
          showYearDropdown
          placeholderText="To"
        />
        <div className="form__form-group-icon">
          <CalendarBlankIcon />
        </div>
      </div>
    </div>
  );
};

IntervalDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }).isRequired,
};

export default IntervalDatePickerField;
