import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faUser,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import './index.css';

const DetailObjective = ({ toggleOffChange, modal, objective, t }) => {
  console.log(objective, 'objective');
  return (
    <>
      <Modal isOpen={modal} toggle={toggleOffChange}>
        <ModalHeader toggle={toggleOffChange}>
          <div className="d-flex align-items-center">
            <img src="/img/objective-title-icon.svg" />
            <div className="flex-column ml-2">
              <div className="title-detail-objective">{t('LBL.OBJTITLE')}</div>
              <di className="mt-1 content-detail-obajective">
                {objective?.objective_title}
              </di>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="px-4 py-4">
          <Row>
            <Col md={5}>
              <div className="title-detail-objective">{t('LBL.PARENTOBJ')}</div>
              <div className="mt-1 content-detail-obajective">
                {objective?.align_name ?? '-'}
              </div>
            </Col>
            <Col md={5}>
              <div className="title-detail-objective">
                {t('LBL.TASKASSIGNEE')}
              </div>
              <div className="d-flex align-items-center mt-1">
                <FontAwesomeIcon
                  icon={
                    objective?.emp_name
                      ? faUser
                      : objective?.unit_organization
                      ? faBriefcase
                      : faBuilding
                  }
                  className="add-result"
                />
                <div className="ml-2">
                  {objective?.emp_name ??
                    objective?.unit_organization ??
                    objective?.company}
                </div>
              </div>
              {/* <div className="d-flex align-items-center mt-1">
                <img src="/img/icon-user-objective.svg" width={30} />
                <div className="ml-2">Fadel Nashr</div>
              </div> */}
            </Col>
            <Col md={2}>
              <div className="title-detail-objective">{t('LBL.STATUS')}</div>
              <div className="mt-1 ">
                <span
                  className={`status-${String(objective?.status_name)
                    .replace(' ', '-')
                    .toLowerCase()}`}
                >
                  {objective?.status_name ?? '-'}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={5}>
              <div className="title-detail-objective">{t('LBL.OBJPERIOD')}</div>
              <div className="mt-1 con nt-detail-obajective">
                {objective?.period_name.substr(4, 1) != '-' ? (
                  <span>
                    {objective?.period_name}{' '}
                    {objective?.period_start.substr(0, 4)}
                  </span>
                ) : (
                  <span>
                    {moment(
                      new Date(objective?.period_name.substr(0, 10)),
                    ).format('D MMM YYYY')}{' '}
                    -{' '}
                    {moment(new Date(objective?.period_name.substr(13))).format(
                      'D MMM YYYY',
                    )}
                  </span>
                )}
              </div>
            </Col>
            <Col md={7}>
              <div className="title-detail-objective">{t('LBL.OBJDESC')}</div>
              <div className="mt-1 content-detail-obajective">
                {objective?.objective_description ?? '-'}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* {modalKeyResult && (
        <KeyResult
          modal={modalKeyResult}
          toggleOffChange={toggleOffModalKeyResult}
        />
      )}

      {modalObjective && (
        <FormObjective
          modal={modalObjective}
          toggleOffChange={toggleOffModalObjective}
        />
      )} */}
    </>
  );
};

export default translate('global')(DetailObjective);
