import React, { useState } from "react";
import { Alert } from 'reactstrap';
import './styles/taskNotification.scss';

const TaskNotification = (props) => {
  const { toggleNotification, notificationOpen } = props;
  const { title, description } = props.data

  return(
    <>
      <Alert color={'success'} isOpen={notificationOpen} className='task-notification'>
        <button className="close" onClick={() => toggleNotification()}><span className="lnr lnr-cross" /></button>
        <div className="alert__content">
          <div className='d-flex align-items-center' style={{gap: 16}}>
            <div className='icon'>
              <img src={process.env.PUBLIC_URL + '/img/task-checked.svg'} width={32} height={32} />
            </div>
            <div className="content">
              <h5>{title}</h5>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </Alert>
    </>
  );
}

export default TaskNotification;