/* eslint-disable no-mixed-operators, max-len */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import KPIForm from './KPI/addkpi';
import KPI from './KPI/index';
import Perspective from './Perspective/index';
import RangeOfAchievement from './RangeOfAchievement/index';
import StrategicObjective from './StrategicObjective/index';
import Period from './Year/addperiod';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import WeightCategory from './WeightCategory/index';
import PhaseRef from './PfmPhase/index';
import KPIUnitOrg from './KPI/perunitorganization';

const HeaderIcon = '/img/preference_icon/icon-perform-setting.png';

class PerformanceManagement extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      menu: [],
      token: '',
      apiws: '',
      idUser: '',
      men_id: '',
      fga_id: '',
      // page: '1',
      // pfy_id: '',
      ppr_id: '',
      pso_id: '',
      pagePerspective: '1',
      arrCategory: [],
      arrPhase: [],
      modalStrategic: false,
    };

    this.addStrategicOjb = this.addStrategicOjb.bind(this);
    this.addPerspective = this.addPerspective.bind(this);
    this.addKpi = this.addKpi.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.getPfmPhase = this.getPfmPhase.bind(this);
    // this.addPeriod = this.addPeriod.bind(this);
    // this.addYear = this.addYear.bind(this);
    this.setModalStrategic = this.setModalStrategic.bind(this);
  }

  componentWillMount() {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getCategory();
      this.getPfmPhase();
    });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getReference();
    });
    const { location } = this.props;
    if (typeof location.state === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({
        men_id: location.state.men_id,
        fga_id: location.state.fga_id,
      });
    }
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
      cuser_id: idUser,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(`${apiws}/menu/getreference/`, formBody, config).then((res) => {
      const resmen = res.data.data;
      const array = [];
      if (resmen?.length > 0) {
        resmen.map((obj) =>
          array.push({ men_id: obj.men_id, label: obj.lbl_code }),
        );
        this.setState({
          menu: array,
        });
        console.log(this.state.menu);
      }
    });
  }

  getPfmPhase() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmphase/getAllItem`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const arr = [];
          response.data.data.forEach((obj) => {
            arr.push({ pph_id: obj.pph_id, phase_name: obj.phase_name });
          });
          // this.props.initialize({ comp_phase: arr });
          this.setState({ arrPhase: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCategory() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/pfmcategoryreference/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const arr = [];
          response.data.data.map((obj) =>
            arr.push({ pcr_id: obj.pcr_id, category_name: obj.category_name }),
          );
          this.setState({ arrCategory: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // addPeriod= (val) => {
  //   this.setState({ pfy_id: val, page: '2' });
  // }

  // addYear() {
  //   this.setState({ page: '1' });
  // }
  setModalStrategic(val) {
    console.log(val);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ modalStrategic: val });
  }

  addStrategicOjb = (val) => {
    this.setState({ ppr_id: val, pagePerspective: '2' });
  };

  addKpi = (val) => {
    this.setState({
      ppr_id: this.state.ppr_id,
      pso_id: val,
      pagePerspective: '3',
    });
  };

  addPerspective() {
    this.setState({ pagePerspective: '1' });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Card>
          <CardBody className="p0">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img
                    className="header-icon"
                    src={HeaderIcon}
                    alt="attendance"
                  />
                  <h3 className="page-title">
                    {t('MEN.PERFORMANCE_MANAGEMENT')}
                  </h3>
                  <h3 className="page-subhead subhead">
                    {t('LBL.PERFORMANCE_MANAGEMENT_SETTING')}
                  </h3>
                </div>
              </Col>
            </Row>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs className="tab-preferences">
                  {this.state.menu.map((data, index) => (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            this.state.activeTab === (index + 1).toString(),
                        })}
                        onClick={() => {
                          this.toggle((index + 1).toString());
                        }}
                      >
                        {data.label === 'LBL.PERSPECTIVE' ? (
                          <span className="navlink_tab-title">
                            {(this.state.pagePerspective === '1' &&
                              t('LBL.PERSPECTIVE')) ||
                              (this.state.pagePerspective === '2' &&
                                t('LBL.STRATEGIC_OBJECTIVE'))}
                          </span>
                        ) : (
                          <span className="navlink_tab-title">
                            {t(data.label)}
                          </span>
                        )}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.state.menu.map(
                    (data, index) =>
                      ((data.label === 'LBL.PHASE' ||
                        data.label === 'LBL.PHASE_REFERENCE') && (
                        <TabPane tabId={(index + 1).toString()}>
                          <PhaseRef
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                            comp_category={this.state.arrCategory}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.PERIOD' && (
                        <TabPane tabId={(index + 1).toString()}>
                          {/* {this.state.page === '1' && (
                          <Year
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                            addFunc={this.addPeriod}
                          />
                        ) || this.state.page === '2' && ( */}
                          <Period
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                            addFunc={this.addYear}
                            // pfy_id={this.state.pfy_id}
                            comp_phase={this.state.arrPhase}
                          />
                          {/* )} */}
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.WEIGHT_CATEGORY_KPI' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <WeightCategory
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                            comp_category={this.state.arrCategory}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.RANGE_OF_ACHIEVEMENT' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <RangeOfAchievement
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.PERSPECTIVE' && (
                        <TabPane tabId={(index + 1).toString()}>
                          {(this.state.pagePerspective === '1' && (
                            <Perspective
                              men_id={data.men_id}
                              fga_id={this.state.fga_id}
                              addStrategicOjb={this.addStrategicOjb}
                              modalStrategic={this.setModalStrategic}
                            />
                          )) ||
                            (this.state.pagePerspective === '2' && (
                              <StrategicObjective
                                men_id={data.men_id}
                                fga_id={this.state.fga_id}
                                addPerspective={this.addPerspective}
                                ppr_id={this.state.ppr_id}
                                addKpi={this.addKpi}
                                modal={this.state.modalStrategic}
                              />
                            )) ||
                            (this.state.pagePerspective === '3' && (
                              <KPIForm
                                men_id={data.men_id}
                                fga_id={this.state.fga_id}
                                ppr_id={this.state.ppr_id}
                                pso_id={this.state.pso_id}
                                addStrategicOjb={this.addStrategicOjb}
                              />
                            ))}
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.KPI' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <KPI
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )) ||
                      (data.label === 'LBL.KPI_UNIT_ORG' && (
                        <TabPane tabId={(index + 1).toString()}>
                          <KPIUnitOrg
                            men_id={data.men_id}
                            fga_id={this.state.fga_id}
                          />
                        </TabPane>
                      )),
                  )}
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default translate('global')(PerformanceManagement);
