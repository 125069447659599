/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable quote-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Container, Row, Col, ButtonToolbar, Card, CardBody, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import showNotifications from '../../../Preferences/notificationMessages';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import utils from '../../../../utils';

let translator = () => { };

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class Attdispensation extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE'),
      t('LBL.JOB_TITLE'),
      t('LBL.PHASE'),
      t('LBL.ACHIEVMENT'),
    ];
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      urlData: '',
      arrOffice: [],
      arrUnitOrganization: [],
      arrEmployee: [],
      arrPhase: [],
      condOffId: '',
      condEmployee: '',
      condYear: '',
      condOrgId: '',
      condStatus: '',
      condPeriod: '',
      type: '',
      displayTable: '',
      displaySave: '',
      displayLoad: 'none',
      filename: 'KPI Report',
      dataexcel: [],
      headerexcel: [],
      arrYear: [],
      disPeriod: true,
      arrPeriod: [],
      trigger: false,
      dataWidth: ['5%', '5%', '5%', '5%', '5%'],
      org: '',
    };

    this.getOffice = this.getOffice.bind(this);
    this.getUnitOrganization = this.getUnitOrganization.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getPhase = this.getPhase.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getYear = this.getYear.bind(this);
  }

  componentDidMount = () => {
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getOffice();
      this.getUnitOrganization();
      this.getEmployee();
      this.getPhase();
      this.getPeriod();
      this.getYear();
    });
  };

  getOffice() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getUnitOrganization() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/orgorganization/getAll`,
      config,
    ).then((res) => {
      const org = res.data.data;
      const array = [];
      if (org.length === 0) {
        this.setState({ arrUnitOrganization: array });
      } else {
        org.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrUnitOrganization: array });
      }
    });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/${org}`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getPhase() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/PfmPhase/getAllItem/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pph_id, label: obj.phase_name })
        ));
        this.setState({ arrPhase: array });
        console.log(array);
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getPeriod(year) {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const data = { 'year': year };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${ls.get('ws_ip')}/Pfmperiod/getByYear/`,
      formBody,
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrPeriod: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.pfp_id, label: obj.period_name })
        ));
        this.setState({ arrPeriod: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getReport() {
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      'off_id': this.state.condOffId,
      'emp_id': this.state.condEmployee,
      'year': this.state.condYear,
      'org_id': this.state.condOrgId,
      'period': this.state.condPeriod,
      'status': this.state.condStatus,
      'type': '2',
    };

    const bodyParameters = {
      cond: conds,
    };
    axios.post(
      `${apiws}/pfmkpiplan/getreportkpi`,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const { data, header } = res.data;
        const array = [];
        data.map((obj, index) => (
          array.push({ 'No': (index + 1), ...obj })
        ));
        this.setState({ dataexcel: array, headerexcel: header, trigger: !this.state.trigger });
        this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
        setTimeout(() => {
          showNotifications('Success', 'Load Success', 'success');
          this.setState({ type: '2' });
          this.inputElement.click();
        }, 1000);
      }
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.disabled = true;
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  handleSubmit(values) {
    this.setState({ displayTable: 'none', displayLoad: '', displaySave: 'none' });
    const offid = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
    const orgId = (typeof values.organization === 'undefined' || values.organization === null) ? '' : values.organization.value;
    const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
    const year = (typeof values.period_year === 'undefined' || values.period_year === null) ? '' : values.period_year.value;
    const period = (typeof values.period === 'undefined' || values.period === null) ? '' : values.period.value;
    const phase = (typeof values.phase === 'undefined' || values.phase === null) ? '' : values.phase.value;
    // const status = (typeof values.status === 'undefined' || values.status === null) ? '' : values.status.value;
    const type_report = (typeof values.type_report === 'undefined' || values.type_report === null) ? '' : values.type_report.value;

    setTimeout(() => {
      this.setState({ condOffId: offid });
      this.setState({ condOrgId: orgId });
      this.setState({ condEmployee: empId });
      this.setState({ condPeriod: period });
      this.setState({ condYear: year });
      this.setState({ condPhase: phase });
      // this.setState({ condStatus: status });
      this.setState({ type: type_report });
    }, 100);
    if (values.type_report.value === '1') {
      this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
      this.setState({ urlData: `${ls.get('ws_ip')}/pfmkpiplan/getreportkpi` });
      showNotifications('Success', 'Load Success', 'success');
      setTimeout(() => {
        this.getData();
      }, 200);
    } else {
      setTimeout(() => {
        this.getReport();
      }, 100);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      arrOffice,
      arrUnitOrganization,
      arrEmployee,
      arrPhase,
      displaySave,
      displayLoad,
      displayTable,
      condOffId,
      condOrgId,
      condEmployee,
      condPeriod,
      condYear,
      condStatus,
      type,
      arrYear,
      arrPeriod,
      condPhase,
    } = this.state;
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0">
              {/* <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="leave" />
                  <h3 className="page-title">{t('LBL.LEAVE_REPORT')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.VIEW')} {t('LBL.LEAVE_REPORT')}</h3>
                </div>
              </Col> */}
              <Col md={12} lg={6} style={{ marginTop: '25px' }}>
                <form name="kpi_report" className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="office"
                        component={renderSelectField}
                        type="text"
                        options={arrOffice}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="organization"
                        component={renderSelectField}
                        type="text"
                        options={arrUnitOrganization}
                        onChange={(val) => {
                          if (typeof val.value === 'undefined') {
                            this.setState({ org: '' });
                            setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          } else {
                            this.setState({ org: val.value }); setTimeout(() => {
                              this.getEmployee();
                            }, 200);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee"
                        component={renderSelectField}
                        type="text"
                        options={arrEmployee}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                    <div className="form__form-group-field form-flex">
                      <Field
                        name="period_year"
                        component={renderSelectField}
                        type="text"
                        placeholder="Year"
                        onChange={(val) => { this.getPeriod(val.value); }}
                        options={arrYear}
                      />
                      <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                      <Field
                        name="period"
                        component={renderSelectField}
                        type="text"
                        options={arrPeriod}
                        placeholder="Period"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.PHASE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phase"
                        component={renderSelectField}
                        type="text"
                        options={arrPhase}
                      />
                    </div>
                  </div>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.STATUS')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="status"
                        component={renderSelectField}
                        type="text"
                        options={[{ value: '1', label: 'Approved' }, { value: '2', label: 'Rejected' }]}
                      />
                    </div>
                  </div> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="type_report"
                        component={renderSelectField}
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                  </div>
                  {(type === '2') && (
                    <ExcelFile
                      element={
                        <div
                          onClick={this.handleClick}
                          onKeyPress={this.handleClick}
                          role="button"
                          tabIndex="0"
                        ><input type="hidden" ref={(ref) => { this.inputElement = ref; }} />
                        </div>}
                      filename={this.state.filename}
                    >
                      <ExcelSheet data={this.state.dataexcel} name="Employees">
                        {
                          this.state.headerexcel.map(obj => (
                            <ExcelColumn label={obj} value={obj} />
                          ))
                        }
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                    <Button color="primary" style={{ display: displayLoad }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
            <Row style={{ display: displayTable }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <Row className="m5 table-nopadding">
                      <div className="card__title">
                        <h5 className="bold-text">{t('LBL.KPI_REPORT')}</h5>
                      </div>
                      <ViewListTable
                        url={urlData}
                        heads={this.heads}
                        // primaryKey="pyp_id"
                        widthTable={this.state.dataWidth}
                        buttonAction="noButton"
                        conds={{
                          'off_id': condOffId,
                          'emp_id': condEmployee,
                          'org_id': condOrgId,
                          'status': condStatus,
                          'period': condPeriod,
                          'year': condYear,
                          'phase': condPhase,
                          'type': '1',
                        }}
                        arrPhase={arrPhase}
                        onRef={ref => (this.child = ref)}
                        displayStyleHead="none"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    period_year: {
      fieldLabel: `${translator('LBL.YEAR')}`,
      required: true,
    },
    period: {
      fieldLabel: `${translator('LBL.PERIOD')}`,
      required: true,
    },
    type_report: {
      fieldLabel: `${translator('LBL.TYPE_REPORT')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'kpi_report', // a unique identifier for this form
  validate,
})(translate('global')(Attdispensation));
