import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
} from 'reactstrap';
import Layout from '../Layout/index';
import Select from 'react-select';
import ViewListTable from '../../shared/components/table/viewListTable';
import DataTable from 'react-data-table-component';
import DataTablePagination from '../../shared/components/DataTable/DataTablePagination';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import renderTextInput from '../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderSelectField from '../../shared/components/form/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderDatePickerField from '../../shared/components/form/DatePicker';
import { faSearch, faDownload } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import ReactExport from 'react-export-excel';
import showNotifications from '../Preferences/notificationMessages';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ObjectiveReport = ({ t }) => {
  const [data, setData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    page: 1,
    column: '',
    sortDirection: '',
    obj_title: '',
    query: '',
    filename: 'Report OKR',
    dataexcel: [],
    headerexcel: [],
    trigger: false,
    startDate: '',
    endDate: '',
  });
  const [filterObj, setFilterObj] = useState({
    value: 0,
    label: 'All Objective',
  });
  const [dataObjName, setDataObjName] = useState([]);
  const excel = useRef(0);

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        width: '100px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        width: '100px',
      },
    },
  };

  const columns = [
    {
      name: t('LBL.OBJTITLE'),
      selector: (row) => row.objective_title,
      sortable: true,
      sortField: 'objective_title',
      // width: '300px'
    },
    {
      name: t('LBL.KRTITLE'),
      selector: (row) => row.key_result_title,
      sortable: true,
      sortField: 'key_result_title',
    },
    {
      name: t('LBL.OBJPROGRESS'),
      selector: (row) => row.Obj_achievement_percentage,
      sortable: true,
      sortField: 'Obj_achievement_percentage',
      width: '100px',
    },
    {
      name: t('LBL.KRPROGRESS'),
      selector: (row) => row.Key_achievement_percentage,
      sortable: true,
      sortField: 'Key_achievement_percentage',
      width: '100px',
    },
    {
      name: t('LBL.KRASSIGNEE'),
      selector: (row) => row.owner,
      sortable: true,
      sortField: 'owner',
      width: '130px',
    },
    {
      name: t('LBL.KRSTATUS'),
      selector: (row) => row.status_name,
      sortable: true,
      sortField: 'status_name',
      width: '110px',
    },
    {
      name: t('LBL.KRPERIOD'),
      selector: (row) => row.period_name,
      sortable: true,
      sortField: 'period_name',
      width: '200px',
    },
  ];

  const apiws = ls.get('ws_ip');
  const token = ls.get('token');

  const initDataReport = () => {
    axios
      .post(
        `${apiws}/okrobjective/getReport`,
        {
          query: data.query,
          page: data.page,
          per_page: data.perPage,
          sort: data.column || 'objective_title',
          order: data.sortDirection || 'asc',
          startDate: data.startDate,
          endDate: data.endDate,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        // setLoading(false);
        if (response.data.status == 'ok') {
          setData((prev) => ({
            ...prev,
            data: response.data.data,
            totalRows: response.data.total,
          }));
        } else {
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  };

  const initDataReportFilterStartDate = (event) => {
    axios
      .post(
        `${apiws}/okrobjective/getReport`,
        {
          query: data.query,
          page: data.page,
          per_page: data.perPage,
          sort: data.column || 'objective_title',
          order: data.sortDirection || 'asc',
          startDate: moment(event).format('YYYY-MM-DD'),
          endDate: data.endDate
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        // setLoading(false);
        if (response.data.status == 'ok') {
          setData((prev) => ({
            ...prev,
            data: response.data.data,
            totalRows: response.data.total,
            startDate: moment(event).format('YYYY-MM-DD'),
          }));
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initDataReportFilterEndDate = (event) => {
    axios
    .post(
      `${apiws}/okrobjective/getReport`,
      {
        query: data.query,
        page: data.page,
        per_page: data.perPage,
        sort: data.column || 'objective_title',
        order: data.sortDirection || 'asc',
        startDate: data.startDate,
        endDate: moment(event).format('YYYY-MM-DD')
      },
      {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then((response) => {
      // setLoading(false);
      if (response.data.status == 'ok') {
        setData((prev) => ({
          ...prev,
          data: response.data.data,
          totalRows: response.data.total,
          endDate: moment(event).format('YYYY-MM-DD'),
        }));
      } else {
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const getNameObj = () => {
    axios
      .post(`${apiws}/okrobjective/getnameobj`, '', {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        // setLoading(false);
        if (response.data.status == 'ok') {
          let dataResponse = response.data.data;
          const Arr = [];

          Arr.push(filterObj),
            dataResponse.map((obj) =>
              Arr.push({ value: obj.obj_id, label: obj.objective_title }),
            );
          setDataObjName(Arr);
        } else {
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  };

  const search = (val) => {
    axios
      .post(
        `${apiws}/okrobjective/getReport`,
        {
          query: val,
          page: data.page,
          per_page: data.perPage,
          sort: data.column || 'objective_title',
          order: data.sortDirection || 'asc',
          obj_title: data.obj_title,
          startDate: data.startDate,
          endDate: data.endDate
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        setData((prev) => ({
          ...prev,
          data: response.data.data,
          totalRows: response.data.total,
          // query: val ?? '',
        }));
        // } else {
        // }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  };

  const getExcelReport = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const bodyParameters = {
      query: data.query,
      page: data.page,
      per_page: data.perPage,
      sort: data.column || 'objective_title',
      order: data.sortDirection || 'asc',
      obj_title: data.obj_title,
      startDate: data.startDate,
      endDate: data.endDate
    };

    axios
      .post(
        `${ls.get('ws_ip')}/okrobjective/getReportExcel`,
        bodyParameters,
        config,
      )
      .then((res) => {
        if (res.data.status == 'ok') {
          const { data, header } = res.data;
          const array = [];
          data.map((obj, index) => array.push({ No: index + 1, ...obj }));
          setData((prev) => ({
            ...prev,
            dataexcel: array,
            headerexcel: header,
            trigger: !prev.trigger,
          }));
          setTimeout(() => {
            showNotifications('Success', 'Load Success', 'success');
            excel.current.click();
          }, 500);
        }
      });
  };

  const filterObjTitle = (val) => {
    axios
      .post(
        `${apiws}/okrobjective/getReport`,
        {
          query: data.query,
          page: data.page,
          per_page: data.perPage,
          sort: data.column || 'objective_title',
          order: data.sortDirection || 'asc',
          obj_title: val,
          startDate: data.startDate,
          endDate: data.endDate
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        // setLoading(false);
        // if (response.data.status == 'ok') {
        setData((prev) => ({
          ...prev,
          data: response.data.data,
          totalRows: response.data.total,
          obj_title: val,
        }));
        setFilterObj(val);
        // } else {
        // }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  };

  useEffect(() => {
    initDataReport();
    getNameObj();
  }, []);
  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'to',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    pagination: true,
  };

  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Container className="p-3">
          <Row>
            <Col md={12}>
              <div className="tab-body-panels" id="objective_report">
                <div className="okr-content">
                  <div className="flex-column ms-3 mb-3">
                    <h3 className="page-title">{t('LBL.OKRREPORT')}</h3>
                    <h3 class="page-subhead subhead">
                      {t('LBL.OKRREPORTDESC')}
                    </h3>
                  </div>
                  <Row>
                    <Col md={3}>
                      <div className="form">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <input
                              value={data.query}
                              onInput={(query) =>
                                setData({ ...data, query: query.target.value })
                              }
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  initDataReport();
                                }
                              }}
                              onChange={(e) => search(e.target.value)}
                              type="text"
                              className="form__form-group-input-wrap form__form-group-input-wrap--error-above"
                              placeholder="Enter Key Result Title"
                            />
                            <div className="form__form-group-icon">
                              <span class="pl-1 pr-1">
                                <FontAwesomeIcon
                                  color="black"
                                  icon={faSearch}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="">
                      <div className="form">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <Field
                              name="objective_title"
                              component={renderSelectField}
                              isClearable={false}
                              placeholder="Select Objective"
                              props={{
                                input: {
                                  value: filterObj,
                                  onChange: (obj) => {
                                    if (obj == null) {
                                      setData((prev) => ({
                                        ...prev,
                                        obj_title: '',
                                      }));
                                    } else {
                                      setData((prev) => ({
                                        ...prev,
                                        obj_title: obj.value,
                                      }));
                                      filterObjTitle(obj);
                                    }
                                  },
                                },
                              }}
                              options={dataObjName}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex ">
                      <div className="form">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <Field
                              name="period_start"
                              component={renderDatePickerField}
                              placeholderText="Enter period start date"
                              onChange={(val) => {
                                initDataReportFilterStartDate(val);
                              }}
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ fontSize: '5px', paddingTop: '2px' }}>
                        <span>
                          {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                          <MinusIcon className="date-picker__svg" />
                        </span>
                      </div>
                      <div className="form">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <Field
                              name="period_start"
                              component={renderDatePickerField}
                              placeholderText="Enter period end date"
                              onChange={(val) => {
                                initDataReportFilterEndDate(val);
                              }}
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={1} className="d-flex justify-content-end">
                      <Button
                        className="export-objective"
                        onClick={() => {
                          getExcelReport();
                        }}
                        id="btn-export"
                      >
                        <span class="">
                          <FontAwesomeIcon icon={faDownload} />
                        </span>
                      </Button>
                      <UncontrolledTooltip
                        placement="top"
                        target={`btn-export`}
                      >
                        {t('LBL.EXPORT')}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                  <DataTable
                    columns={columns}
                    data={data.data}
                    direction="auto"
                    responsive
                    striped
                    customStyles={customStyles}
                    paginationComponent={DataTablePagination}
                    paginationServer
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    paginationTotalRows={data.totalRows}
                    onChangeRowsPerPage={(newPerPage, page) => {
                      // setData({ ...data, loading: true });

                      axios
                        .post(
                          `${ls.get('ws_ip')}/okrobjective/getReport`,
                          {
                            page,
                            per_page: newPerPage,
                            sort: data.column || 'objective_title',
                            order: data.sortDirection || 'asc',
                            query: data.query,
                          },
                          {
                            headers: {
                              Authorization: `bearer ${ls.get('token')}`,
                              'Content-Type':
                                'application/x-www-form-urlencoded',
                            },
                          },
                        )
                        .then((res) => {
                          setData({
                            ...data,
                            data: res.data.data,
                            perPage: newPerPage,
                            page,
                            totalRows: res.data.total,
                          });
                          // setTotalRows(res.data.total);
                        });
                      // setDatatable({ ...datatable, loading: false });
                    }}
                    onChangePage={(page) => {
                      // setData({ ...data, loading: true });

                      axios
                        .post(
                          `${ls.get('ws_ip')}/okrobjective/getReport`,
                          {
                            page,
                            per_page: data.perPage,
                            sort: data.column || 'objective_title',
                            order: data.sortDirection || 'asc',
                            query: data.query,
                          },
                          {
                            headers: {
                              Authorization: `bearer ${ls.get('token')}`,
                              'Content-Type':
                                'application/x-www-form-urlencoded',
                            },
                          },
                        )
                        .then((res) => {
                          setData({
                            ...data,
                            data: res.data.data,
                            page,
                            totalRows: res.data.total,
                          });
                          // setTotalRows(res.data.total);
                        });
                      // setDatatable({ ...datatable, loading: false });
                    }}
                    sortServer
                    onSort={(col, sort) => {
                      // setData({ ...datatable, loading: true });

                      axios
                        .post(
                          `${ls.get('ws_ip')}/okrobjective/getReport`,
                          {
                            // q: datatable.query,
                            page: data.page,
                            per_page: data.perPage,
                            sort: col.sortField,
                            order: sort,
                            query: data.query,
                          },
                          {
                            headers: {
                              Authorization: `bearer ${ls.get('token')}`,
                              'Content-Type':
                                'application/x-www-form-urlencoded',
                            },
                          },
                        )
                        .then((res) => {
                          setData({
                            ...data,
                            data: res.data.data,
                            column: col.sortField,
                            sortDirection: sort,
                            totalRows: res.data.total,
                          });
                        });
                      // setData({ ...datatable, loading: false });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          {/* EXCEL */}
          <ExcelFile
            element={
              <div
                // onClick
                // onKeyPress
                role="button"
                tabIndex="0"
              >
                <input
                  type="hidden"
                  ref={(ref) => {
                    excel.current = ref;
                  }}
                />
              </div>
            }
            filename={data.filename}
          >
            <ExcelSheet data={data.dataexcel} name="Employee">
              {data.headerexcel.map((obj) => (
                <ExcelColumn label={obj} value={obj} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        </Container>
      </div>
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
})(translate('global')(ObjectiveReport));
