import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import Layout from '../Layout/index';
import './okr.css';
import ListObjective from './ListObjective';
import ls from 'local-storage';
import axios from 'axios';
import { translate } from 'react-i18next';

const ObjectivePlan = ({t}) => {
  const [summary, setSummary] = useState({
    count_objective: 0,
    count_keyresult: 0,
    achievement: 0,
  });

  const setCardSummary = (summary) => {
    setSummary({
      count_objective: summary?.count_obj || 0,
      count_keyresult: summary?.count_kr || 0,
      achievement: summary?.achievement || 0,
    });
  };
  return (
    <>
      <Layout />
      <div className="container__wrap" id="objective-plan">
        <Container className="p-3">
          <div className="px-3 mb-5">
            <Row className="pt-3 px-3 pb-4 summary-okr">
            <Col md={12} className="my-2">
                <div className="text-white text-summary">{t('LBL.SUMMARY')}</div>
              </Col>
              <Col md="4 mb-3 mb-md-0">
                <div className="p-3 total-objective bg-white">
                  <div className="d-flex align-items-center">
                    <img src="/img/icon-objective.svg" alt="" />
                    <div className="flex-column ml-3">
                      <div className="count-objective">
                        {summary?.count_objective}
                      </div>
                      <div className="desc-objective">{t('LBL.TOTALOBJ')}</div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4 mb-3 mb-md-0">
                <div className="p-3 total-objective bg-white">
                  <div className="d-flex align-items-center">
                    <img src="/img/icon-key-objective.svg" alt="" />
                    <div className="flex-column ml-3">
                      <div className="count-objective">
                        {summary?.count_keyresult}
                      </div>
                      <div className="desc-objective">{t('LBL.TOTALKR')}</div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4 mb-3 mb-md-0">
                <div className="p-3 total-objective bg-white">
                  <div className="d-flex align-items-center">
                    <img src="/img/icon-achievment.svg" alt="" />
                    <div className="flex-column ml-3">
                      <div className="count-objective">
                        {summary?.achievement}%
                      </div>
                      <div className="desc-objective">{t('LBL.ACHIEVMENT')}</div>
                    </div>
                  </div>
                </div>
              </Col>             
              <div></div>
            </Row>
          </div>

          <Row>
            <Col md="12">
              <div className="tab-body-panels">
                <div className="okr-content">
                  <div className="flex-column ms-3 mb-3">
                    <h3 className="page-title">{t('LBL.OKRPLANTITLE')}</h3>
                    <h3 class="page-subhead subhead">{t('LBL.OKRSUBTITLE')}</h3>
                  </div>
                  <Row>
                    <ListObjective dataSummary={setCardSummary}></ListObjective>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default (translate('global')(ObjectivePlan));
