/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import { Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import WorkflowList from './Component/workflow';
import ActivityList from './Component/Activity/activity2';
import CheckList from './Component/Activity/checklist';
import OrderList from './Component/sequence';
// import FormList from './Component/form';
class Workflow extends React.Component {
  static propTypes = {
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor() {
    super();
    this.state = {
      disp_workflow: 'block',
      disp_activity: 'none',
      disp_order: 'none',
      disp_checklist: 'none',
      param_activity: '',
      param_order: '',
    };
    this.OpenActivity = this.OpenActivity.bind(this);
    this.getChecklist = this.getChecklist.bind(this);
  }

  getChecklist(id) {
    this.setState({ param_activity: id, disp_checklist: 'block', disp_activity: 'none' });
  }

  OpenActivity(x, txt) {
    // console.log('indextxt', txt);
    // console.log('x', x);
    if (txt === 'activity') {
      this.setState({
        disp_workflow: 'none',
        disp_activity: 'block',
        disp_order: 'none',
        disp_checklist: 'none',
        param_wfl: x,
      });
    } else if (txt === 'order') {
      this.setState({
        disp_workflow: 'none',
        disp_activity: 'none',
        disp_order: 'block',
        param_order: x,
      });
    } else if (txt === 'checklist') {
      this.setState({
        disp_activity: 'none',
        disp_checklist: 'block',
        param_activity: x,
      });
    } else {
      window.location.reload();
      this.setState({
        disp_workflow: 'block',
        disp_activity: 'none',
        disp_order: 'none',
        param_activity: '',
        param_order: '',
        param_sts: txt,
      });
    }
  }

  render() {
    return (
      <Container>
        <Row style={{ display: this.state.disp_workflow }}>
          <WorkflowList
            onparam={this.OpenActivity}
            men_id={this.props.men_id}
            fga_id={this.props.fga_id}
            setsts={this.state.param_sts}
          />
        </Row>
        <Row style={{ display: this.state.disp_activity }}>
          <ActivityList
            onparam={this.OpenActivity}
            setparam={this.state.param_wfl}
            getChecklist={this.getChecklist}
            men_id={this.props.men_id}
            fga_id={this.props.fga_id}
          />
        </Row>
        <Row style={{ display: this.state.disp_checklist }}>
          <CheckList
            onparam={this.OpenActivity}
            setparam={this.state.param_activity}
            wfl_id={this.state.param_wfl}
            men_id={this.props.men_id}
            fga_id={this.props.fga_id}
          />
        </Row>
        <Row style={{ display: this.state.disp_order }}>
          <OrderList
            onparam={this.OpenActivity}
            setparam_order={this.state.param_order}
            men_id={this.props.men_id}
            fga_id={this.props.fga_id}
          />
        </Row>
        { /* <Row style={{ display: this.state.disp_form }}>
          <FormList
            onparam={this.OpenActivity}
            setparam_activity={this.state.param_activity}
            setparam_form={this.state.param_form}
          />
        </Row> */ }
      </Container>
    );
  }
}

export default (translate('global')(Workflow));
