/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Table } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/fontawesome-free-solid';
// import MinusIcon from 'mdi-react/MinusIcon';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderTimePickerIntervalField from '../../../../shared/components/form/TimePickerInterval';
// import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';

class TaxDetailCalc extends PureComponent {
    static propTypes = {
      //   t: PropTypes.func.isRequired,
      handleSubmit: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      this.state = {
        showPassword: false,
      };
    }

    showPassword = (e) => {
      e.preventDefault();
      this.setState({
        showPassword: !this.state.showPassword,
      });
    };

    render() {
      const { handleSubmit } = this.props;

      return (
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody className="p0">
                  <Row className="m5">
                    <Col md={12}>
                      <h3 className="page-title">Report - Tax Detail Calculation</h3>
                      <h3 className="page-subhead subhead">View Your Tax Detail Calculation Report</h3>
                    </Col>
                  </Row>
                  <Row className="m5">
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <form className="form form--horizontal" onSubmit={handleSubmit}>
                        <Container>
                          <Row>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group form__form-group--address">
                                <span className="form__form-group-label">Report Type</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="report_banktrf_period_type"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                        { value: 'Annual', label: 'Annual' },
                                        { value: 'Monthly', label: 'Monthly' },
                                    ]}
                                    placeholder="Type"
                                  />
                                  <Field
                                    name="report_banktrf_period_year"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                        { value: '2018', label: '2018' },
                                        { value: '2019', label: '2019' },
                                        { value: '2020', label: '2020' },
                                        { value: '2021', label: '2021' },
                                    ]}
                                    placeholder="Month"
                                  />
                                  <Field
                                    name="report_banktrf_period_month"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                            { value: '1', label: 'January' },
                                            { value: '2', label: 'February' },
                                            { value: '3', label: 'March' },
                                            { value: '4', label: 'April' },
                                            { value: '5', label: 'May' },
                                        ]}
                                    placeholder="Year"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Employee ID/Name</span>
                                <div className="form__form-group-field products-list__search">
                                  <input placeholder="Search" name="employee_search" />
                                  <MagnifyIcon />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} xs={12} lg={12} xl={12}>
                              <div className="form__form-group form__form-group--address">
                                <span className="form__form-group-label">Organization</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="report_organization_select"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                        { value: '1', label: '...' },
                                        { value: '2', label: '...' },
                                        { value: '3', label: '...' },
                                    ]}
                                    placeholder="Company"
                                  />
                                  <Field
                                    name="report_location_select"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                        { value: '1', label: '...' },
                                        { value: '2', label: '...' },
                                    ]}
                                    placeholder="Location"
                                  />
                                  <Field
                                    name="report_unit_org_select"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                        { value: '1', label: '...' },
                                        { value: '2', label: '...' },
                                    ]}
                                    placeholder="Unit Organization"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} xs={12} lg={4} xl={4}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Output</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="report_payslip_output"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                                { value: '1', label: 'HTML' },
                                                { value: '2', label: 'Excel' },
                                            ]}
                                    placeholder="Select Output"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <ButtonToolbar className="form__button-toolbar">
                            <Button color="primary" type="submit">Submit</Button>
                          </ButtonToolbar>
                        </Container>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Tax Detail Calculation Report</h5>
                  </div>
                  <Table responsive className="table--bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Company</th>
                        <th>Location</th>
                        <th>Job</th>
                        <th>Start Month</th>
                        <th>End Month</th>
                        <th>NPWP</th>
                        <th>Description Status</th>
                        <th>Income Tax Allowance</th>
                        <th>Allowance</th>
                        <th>Honorium</th>
                        <th>Insurance</th>
                        <th>Natura</th>
                        <th>Bonus</th>
                        <th>Bryto Total</th>
                        <th>Job Cost</th>
                        <th>Retire</th>
                        <th>Deduction</th>
                        <th>Net Income</th>
                        <th>Previous Income</th>
                        <th>A Year Income/Annualized</th>
                        <th>Non-Taxable Income</th>
                        <th>Annualized Taxable Income</th>
                        <th>Annualized Income Tax</th>
                        <th>PPh of Previous Period</th>
                        <th>PPh 21</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="28" className="text-center">----NO DATA---</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
}

export default reduxForm({
  form: 'horizontal_form_layout', // a unique identifier for this form
})(translate('common')(TaxDetailCalc));
