/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable object-curly-newline */
import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import { Field, submit, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../../shared/components/form/Select';
// import showNotifications from '../../../../Preferences/notificationMessages';

class addPayrollComponent extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      // destroy: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      this.state = {
        apiws: '',
        token: '',
      };
      // this.handleSubmit = this.handleSubmit.bind(this);
    }

    // handleSubmit(e) {
    //   e.preventDefault();
    //   alert('asd');
    // }

    render() {
      const { handleSubmit } = this.props;

      // eslint-disable-next-line no-unused-vars
      const RemoteSubmitButton = ({ dispatch }) => (
        <Button
          type="button"
          style={style}
          onClick={() => dispatch(submit('remoteSubmit'))}
        >Submit
        </Button>);

      return (
        <Col xs={12} md={12} lg={12} xl={12}>
          <form
            className="form form--horizontal"
            name="formcomponentpayroll"
            id="formcomponentpayroll"
            onSubmit={handleSubmit}
          >
            <div className="form__form-group form__form-group--address">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <Field
                  name="component"
                  id="component"
                  component="input"
                  type="text"
                />
                <Field
                  name="component_code"
                  id="component_code"
                  component="input"
                  type="text"
                  placeholder="Code"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tax Category</span>
              <div className="form__form-group-field">
                <Field
                  name="payroll_tax_category"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Type</span>
              <div className="form__form-group-field">
                <Field
                  name="component_type"
                  id="component_type"
                  component={renderSelectField}
                  type="text"
                  options={[
                        { value: 'monthly', label: 'Monthly' },
                        { value: 'one time', label: 'One Time' },
                        { value: 'daily', label: 'Daily' },
                    ]}
                />
              </div>
              <div className="form__form-group-field check_box_mt10">
                <Field
                  name="pys_id"
                  component={renderCheckBoxField}
                  label="Prorate ?"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tax Method</span>
              <div className="form__form-group-field">
                <Field
                  name="pmt_id"
                  component={renderRadioButtonField}
                  label="Gross Up"
                  radioValue="1"
                />
                <Field
                  name="pmt_id"
                  component={renderRadioButtonField}
                  label="Gross"
                  radioValue="2"
                />
                <Field
                  name="pmt_id"
                  component={renderRadioButtonField}
                  label="Netto"
                  radioValue="3"
                />
                <Field
                  name="pmt_id"
                  component={renderRadioButtonField}
                  label="Mixed"
                  radioValue="4"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tax Paid by</span>
              <div className="form__form-group-field">
                <Field
                  name="tax_paid_select"
                  component={renderRadioButtonField}
                  label="Company"
                  radioValue="1"
                />
                <Field
                  name="tax_paid_select"
                  component={renderRadioButtonField}
                  label="Employee"
                  radioValue="2"
                />
              </div>
            </div>
          </form>
        </Col>
      );
    }
}

export default reduxForm({
  form: 'horizontal_form_layout',
})(translate('common')(addPayrollComponent));
