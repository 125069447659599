/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { Container, Row, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import AdjustmentList from './Components/index';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import Authenticator from '../../Authenticator';

class AdjustmentIndex extends PureComponent {
  static propTypes = {
    location: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      validateToken: false,
      redirect: false,
    };

    this.checkAuth = this.checkAuth.bind(this);
    this.linkBack = this.linkBack.bind(this);
  }

  componentWillMount() {
    this.checkAuth();
  }

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/token/isOtp`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        if (response.data.otp) {
          this.setState({ validateToken: true });
        }
      }
    }).catch((error) => {
      // showNotifications('Fail', 'Delete Failed', 'danger');
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  render() {
    const { validateToken, redirect } = this.state;
    const { t, location } = this.props;
    const backBtn = (
      <Button
        color="primary"
        className="btn btn-success"
        onClick={this.linkBack}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (redirect) {
      return (<Redirect to={{
      pathname: '/administration/employee',
            state: { men_id: location.state.men_employee, fga_id: location.state.fga_id },
      }}
      />);
    }
    if (validateToken === false) {
      return (
        <Authenticator checkAuth={this.checkAuth} backBtn={backBtn} />
      );
    }
    return (
      <Container>
        <Row>
          <AdjustmentList parmid={this.props.location} />
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(AdjustmentIndex));
