/* eslint-disable no-unused-vars, quotes, no-lonely-if, prefer-destructuring, react/no-unused-prop-types */
/* eslint-disable array-callback-return, brace-style, object-curly-newline, no-unneeded-ternary */
/* eslint-disable max-len, class-methods-use-this, react/prop-types, jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-operators, react/jsx-closing-bracket-location, no-loop-func */
/* eslint-disable react/no-unused-state, no-prototype-builtins, no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-for, no-restricted-syntax, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable quote-props, jsx-a11y/anchor-is-valid, operator-assignment, no-nested-ternary */
/* eslint-disable camelcase, arrow-parens, no-plusplus, prefer-template, jsx-a11y/alt-text */
/* eslint-disable object-shorthand, react/jsx-curly-brace-presence, arrow-body-style */
/* eslint-disable react/no-did-mount-set-state, function-paren-newline, jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import {
  UncontrolledTooltip,
  Container,
  Row,
  Col,
  Button,
  Table,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import showNotifications from '../Preferences/notificationMessages';
import SortableTree from 'react-sortable-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faFileAlt,
  faArrowCircleLeft,
  faAngleRight,
  faTimes,
} from '@fortawesome/fontawesome-free-solid';
import renderTextInput from '../../shared/components/form/TextInput';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import Swal from 'sweetalert2';
import { AcroFormCheckBox } from 'jspdf';

const UserDefault = '/img/user-default-photo.png';
const photoIcon = '/img/show-emp-icon.png';

let kpiPlanPicked = [];
let arrKpiplan = [];
let arrWeightName = [];
const arrCategoryKpi = [];
const arrWeightVerify = [];
const arrCategoryId = [];
let formKpiPlan = false;
let arrCheck = [];
let checksts = '';

function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;
  // arrKpiplan = [];
  let isplan = false;
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.pvm_id] = arrElem;
    mappedArr[arrElem.pvm_id].children = [];
  }
  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      if (mappedElem.pvm_ref === '0' || mappedElem.pvm_ref === 0) {
        isplan = false;
        tree.push({
          id: mappedElem.pvm_id,
          title: mappedElem.pvm_name,
          level: mappedElem.pvm_level,
          expanded: true,
          kpi: mappedElem.kpi_plan,
          children: mappedElem.children,
        });
      } else {
        if (mappedElem.children.length === 0) {
          isplan =
            mappedElem.ppi_id !== 0 && kpiPlanPicked.includes(mappedElem.ppi_id)
              ? true
              : false;
          if (isplan) {
            arrKpiplan.push(mappedElem.pvm_id);
          }
          mappedArr[mappedElem.pvm_ref].children.push({
            id: mappedElem.pvm_id,
            title: mappedElem.pvm_name,
            level: mappedElem.pvm_level,
            expanded: true,
            kpi: mappedElem.kpi_plan,
            children: mappedElem.children,
          });
        } else {
          isplan = false;
          mappedArr[mappedElem.pvm_ref].children.push({
            id: mappedElem.pvm_id,
            title: mappedElem.pvm_name,
            level: mappedElem.pvm_level,
            expanded: true,
            kpi: mappedElem.kpi_plan,
            children: mappedElem.children,
          });
        }
      }
    }
  }
  return tree;
}

class KpiPlanForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      empid: '',
      empname: '',
      empcode: '',
      modal: false,
      displaySpinner: false,
      dataTree: '',
      disabledButtonSave: true,
      redirectUrl: false,
      arrPhase: [],
      phaseName: '',
      periodPhaseId: '',
      periodId: '',
      phaseId: '',
      kpiPlanData: [],
      kpiPlanCategory: [],
      kpiPlanStrategic: [],
      totalWeight: 0,
      reviewerId: '',
      arrEmployee: [],
      reviewer: '',
      approval: false,
      workflow: '',
      activity: '',
      showPickedItem: false,
      arrEmpPicked: [],
      showData: false,
      wflId: '',
      submitPlan: false,
      disabledBtnBack: false,
      kpiState: false,
      showPickedTitle: '',
      arrKpiSetting: [],
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitKpi = this.handleSubmitKpi.bind(this);
    this.changeWeight = this.changeWeight.bind(this);
    this.getKpiplan = this.getKpiplan.bind(this);
  }

  componentDidMount = () => {
    this.props.destroy();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    setTimeout(() => {
      this.getKpiCategory();
      this.getKpiplan();
      this.getFlow();
    }, 300);
    if (typeof this.props.location.state === 'undefined') {
      this.setState({ redirectUrl: true });
    } else {
      setTimeout(() => {
        this.setState({
          periodId: this.props.location.state.periodId,
          phaseId: this.props.location.state.phaseId,
          periodPhaseId: this.props.location.state.periodPhaseId,
          empid: this.props.location.state.empId,
          empname: this.props.location.state.empName,
          empcode: this.props.location.state.empCode,
          phaseName: this.props.location.state.phaseName,
        });
      }, 50);
    }
  };

  getEmployeePicked(x) {
    const { periodId, phaseId, periodPhaseId } = this.state;
    const kpiId = x.id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllemployeePlan/${kpiId}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const dataResponse = res.data.data;
          setTimeout(() => {
            this.setState({ arrEmpPicked: dataResponse });
          }, 100);
        }
      });
    this.setState({
      showPickedItem: true,
      showPickedTitle: x.title,
    });
  }

  getKpi() {
    const { showData, empid, periodId, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let url;
    let body;
    if (showData) {
      console.log('inthis1');
      url = `${ls.get(
        'ws_ip',
      )}/pfmkpiplan/getAlltree/${empid}/${periodId}/${phaseId}/${periodPhaseId}`;
      body = 'emp_id=';
    } else {
      console.log('inthis2');
      url = `${ls.get('ws_ip')}/pfmkpi/getAlltree`;
      body = `emp_id=${empid}`;
      // url = `${ls.get('ws_ip')}/pfmkpiplan/getAlltree/${empid}/${periodId}/${phaseId}/${periodPhaseId}`;
      // body = `emp_id=${empid}`;
    }
    axios.post(url, body, config).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({ arrKpiSetting: res.data.data });
        const tree = unplet(res.data.data);
        this.setState({ dataTree: tree });
      }
    });
  }

  getButton() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const btn = {};
    const formBody = Object.keys(btn)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(btn[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
            }
          });
        }
      });
  }

  getKpiCategory() {
    const { periodId, empid, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(
        `${ls.get(
          'ws_ip',
        )}/pfmkpiplan/getAllitemcategorykpi/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const n = res.data.data.length;
          const d = res.data.data;
          for (let i = 0; i < n; i++) {
            arrCategoryKpi[d[i].ppi_id] = d[i].pcr_id;
          }
        }
      });
  }

  getKpiplan(val, x, y) {
    formKpiPlan = false;
    const {
      periodId,
      kpiPlanCategory,
      kpiPlanStrategic,
      kpiPlanData,
      kpiState,
      empid,
      phaseId,
      periodPhaseId,
    } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (!(typeof x === 'undefined')) {
      const arrKPi = kpiPlanData;
      const arrCat = kpiPlanCategory;
      for (let i = 0; i < kpiPlanCategory.length; i++) {
        const catId = kpiPlanCategory[i].pcr_id;
        let total = 0;
        for (let j = 0; j < kpiPlanStrategic[catId].length; j++) {
          const strId = kpiPlanStrategic[catId][j].pso_id;
          for (let k = 0; k < arrKPi[catId][strId].length; k++) {
            if (arrKPi[catId][strId][k].ppn_id === x) {
              arrKPi[catId][strId][k].kpi_weight = `${val}`;
              total = total + val;
              this.setState({ kpiState: true });
              setTimeout(() => {
                if (kpiState) {
                  this.setState({ kpiPlanData: arrKPi });
                  this.setState({ kpiState: false });
                }
              }, 500);
            } else {
              const finalWeight =
                typeof arrKPi[catId][strId][k].kpi_weight === 'undefined'
                  ? 0
                  : parseInt(arrKPi[catId][strId][k].kpi_weight, 0);
              total = total + finalWeight;
            }
          }
        }
        if (catId === y) {
          this.setState({ kpiState: true });
          arrCat[i].total_weight = `${total}`;
          if (parseInt(kpiPlanCategory[i].weight_value, 0) === total) {
            arrWeightVerify[i] = true;
          } else {
            arrWeightVerify[i] = false;
          }
          setTimeout(() => {
            if (kpiState) {
              this.setState({ kpiPlanCategory: arrCat });
              this.setState({ kpiState: false });
            }
          }, 500);
        }
      }
    } else {
      kpiPlanPicked = [];
      arrWeightName = [];
      axios
        .get(
          `${ls.get(
            'ws_ip',
          )}/pfmkpiplan/getallitemcategory/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
          config,
        )
        .then((res) => {
          if (res.data.status === 'ok') {
            if (res.data.data.category.length > 0) {
              const arr = [];
              res.data.data.kpiplan.map((obj) => arr.push(obj.ppi_id));
              const category = res.data.data.category;
              const strategic = res.data.data.strategic;
              const kpi = res.data.data.kpi;
              for (let a = 0; a < category.length; a++) {
                arrCategoryId[a] = category[a].pcr_id;
                for (let b = 0; b < strategic[category[a].pcr_id].length; b++) {
                  for (
                    let c = 0;
                    c <
                    kpi[category[a].pcr_id][
                      strategic[category[a].pcr_id][b].pso_id
                    ].length;
                    c++
                  ) {
                    const ppn =
                      kpi[category[a].pcr_id][
                        strategic[category[a].pcr_id][b].pso_id
                      ][c].ppn_id;
                    const pcr = category[a].pcr_id;
                    arrWeightName.push(`weight_${ppn}_${pcr}`);
                    console.log(arrWeightName);
                  }
                }
              }
              this.setState({
                kpiPlanCategory: res.data.data.category,
                kpiPlanStrategic: res.data.data.strategic,
                kpiPlanData: res.data.data.kpi,
                // showData: true,
                // disabledButtonSave: false,
              });
              kpiPlanPicked = arr;
            }
          }
          if (res.data.data.category.length > 0) {
            this.setState({
              showData: true,
              disabledButtonSave: false,
            });
          } else {
            this.setState({
              showData: false,
              disabledButtonSave: false,
            });
          }
        });
    }
  }

  getFlow() {
    const { token, apiws, phaseId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/getWorkflowPerformance/${phaseId}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data.performance[0];
          this.setState({
            wflId: dataResponse.wfl_id,
            workflow: dataResponse.workflow,
            activity: dataResponse.activity,
          });
        }
      });
  }

  hidePicked() {
    this.setState({
      showPickedItem: false,
    });
  }

  handleSubmit() {
    const {
      apiws,
      token,
      idUser,
      periodId,
      empid,
      phaseId,
      periodPhaseId,
      showData,
    } = this.state;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const emp = empid === null || empid === 'null' ? 1 : empid;
    const action = 'POST';
    const link = `${apiws}/pfmkpiplan`;
    const body = [
      `pph_id=${phaseId}`,
      `pfp_id=${periodId}`,
      `ppp_id=${periodPhaseId}`,
      `emp_id=${emp}`,
      `emp_id=${emp}`,
      `arrCheck=${arrCheck}`,
      `checksts=${checksts}`,
      `dyn_kpi=${arrKpiplan}`,
      `cuser_id=${idUser}`,
    ].join('&');
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    if (showData) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will update this item !',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15DD96', // '#ff4861',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, update it!',
        reverseButtons: true,
      }).then((result) => {
        this.setState({ displaySpinner: true });
        if (result.value) {
          axios(options).then(() => {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ displaySpinner: false });
            this.setState({ disabledButtonSave: false });
            this.setState({ modal: !this.state.modal });
            this.getKpiplan();
            this.getKpiCategory();
            formKpiPlan = false;
          });
        } else {
          this.setState({ displaySpinner: false });
        }
      });
    } else {
      this.setState({ displaySpinner: true });
      axios(options).then(() => {
        showNotifications('Success', 'Save Success', 'success');
        this.setState({ displaySpinner: false });
        this.setState({ disabledButtonSave: true });
        this.props.destroy();
        this.setState({ modal: !this.state.modal });
        this.setState({ showData: true });
        this.getKpiplan();
        this.getKpiCategory();
        formKpiPlan = false;
      });
    }
  }

  toggle() {
    this.setState({ dataTree: [] });
    formKpiPlan = true;
    this.setState({ modal: !this.state.modal });
    arrKpiplan = [];
    arrCheck = [];
    this.getKpi();
  }

  checkedKpi(x, y) {
    const { arrKpiSetting, kpiState } = this.state;
    const arr = arrKpiSetting;
    if (!(typeof x.target === 'undefined')) {
      if (!(typeof x.target.checked === 'undefined')) {
        if (x.target.checked) {
          // Update tree
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].pvm_id === y) {
              this.setState({ kpiState: true });
              arr[i].kpi_plan = true;
              if (kpiState) {
                this.setState({ arrKpiSetting: arr });
                this.setState({ kpiState: false });
              }
            }
          }
          const tree = unplet(arrKpiSetting);
          this.setState({ dataTree: tree });
          // push id
          arrKpiplan.push(y);
          arrCheck.push(y);
          checksts = '1';
          console.log('arrKpiplanchecklist', arrKpiplan);
          console.log('arrCheckchecklist', arrCheck);
          this.setState({ disabledButtonSave: false });
        } else {
          // Update tree
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].pvm_id === y) {
              this.setState({ kpiState: true });
              arr[i].kpi_plan = false;
              if (kpiState) {
                this.setState({ arrKpiSetting: arr });
                this.setState({ kpiState: false });
              }
            }
          }
          const tree = unplet(arrKpiSetting);
          this.setState({ dataTree: tree });
          // de;ete id
          const a = arrKpiplan.indexOf(y);
          arrKpiplan.splice(a, 1);
          const n = arrKpiplan.length;
          if (n === 0) {
            this.setState({ disabledButtonSave: true });
          }
          // const b = arrCheck.indexOf(y);
          // arrCheck.splice(b, 1);
          console.log('arrKpiplanUNchecklist', arrKpiplan);
          console.log('arrCheckUNchecklist', arrCheck);
          arrCheck.push(y);
          checksts = '2';
        }
      }
    }
  }

  changeWeight(x) {
    formKpiPlan = false;
    const name = x.currentTarget.name;
    const wname = name.split('_');
    const k = parseInt(wname[1], 0); // kpi
    const c = parseInt(wname[2], 0); // category
    const val =
      parseInt(x.currentTarget.value, 0) > 0
        ? parseInt(x.currentTarget.value, 0)
        : 0;
    this.getKpiplan(val, k, c);
  }

  handleSubmitKpi(values) {
    const {
      apiws,
      token,
      idUser,
      empid,
      wflId,
      periodId,
      phaseId,
      periodPhaseId,
    } = this.state;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const planWeight = [];
    const planTolerance = [];
    Object.keys(values).forEach((obj) => {
      if (obj.includes('weight')) {
        const split = obj.split('_');
        planWeight.push({
          ppn_id: split[1],
          ppi_id: split[3],
          weight: values[obj],
        });
      }
      if (obj.includes('pto')) {
        const split = obj.split('_');
        planTolerance.push({
          ppn_id: split[2],
          pto_id: split[1],
          pto_value: values[obj],
        });
      }
    });
    const action = 'POST';
    const link = `${apiws}/pfmkpiplandetail`;
    const body = [
      `dyn_plan_weight=${JSON.stringify(planWeight)}`,
      `dyn_plan_tolerance=${JSON.stringify(planTolerance)}`,
      `emp_id=${empid}`,
      `pfp_id=${periodId}`,
      `pph_id=${phaseId}`,
      `ppp_id=${periodPhaseId}`,
      `wfl_id=${wflId}`,
      `cuser_id=${idUser}`,
    ].join('&');
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    this.setState({ displaySpinner: true });
    axios(options).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({ displaySpinner: false, redirectUrl: true });
        this.props.destroy();
        showNotifications('Success', 'Save Success', 'success');
      } else {
        this.setState({ displaySpinner: false, redirectUrl: false });
        this.props.destroy();
        showNotifications('Failed', 'Save Failed', 'danger');
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      displaySpinner,
      disabledButtonSave,
      redirectUrl,
      kpiPlanData,
      kpiPlanCategory,
      kpiPlanStrategic,
      approval,
    } = this.state;

    if (redirectUrl) {
      const link = <Redirect to={{ pathname: '/performance/information' }} />;
      return link;
    }

    if (approval) {
      return (
        <Redirect
          to={{
            pathname: '/performance/kpi',
            state: {
              periodId: this.state.periodId,
              phaseId: this.state.phaseId,
              periodPhaseId: this.state.periodPhaseId,
              empId: this.state.empid,
              empName: this.state.empname,
              empCode: this.state.empcode,
            },
          }}
        />
      );
    }

    const arrDataKpiPlan = [];
    for (let i = 0; i < kpiPlanCategory.length; i++) {
      const category = kpiPlanCategory[i];
      console.log('category length', kpiPlanCategory.length);
      console.log('category', category);
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.CATEGORY')}
          </span>
          <div className="form__form-group-field">
            <Col xs={5} md={5} lg={5} xl={5}>
              <input
                style={{ fontWeight: 'bold' }}
                name="category"
                disabled="true"
                value={category.category_name}
              />
            </Col>
          </div>
        </div>,
      );
      for (let j = 0; j < kpiPlanStrategic[`${category.pcr_id}`].length; j++) {
        const strategic = kpiPlanStrategic[`${category.pcr_id}`][j];
        console.log(
          'pcr_id length',
          kpiPlanStrategic[`${category.pcr_id}`].length,
        );
        console.log('strategic', strategic);
        arrDataKpiPlan.push(
          <div className="form__form-group">
            <span
              className="form__form-group-label"
              style={{ paddingLeft: '15px' }}
            >
              {t('LBL.STRATEGIC_OBJECTIVE')}
            </span>
            <div className="form__form-group-field">
              <Col xs={5} md={5} lg={5} xl={5}>
                <input
                  name="startegic"
                  disabled="true"
                  value={strategic.strategic_objective_name}
                />
              </Col>
            </div>
          </div>,
        );
        for (
          let k = 0;
          k < kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`].length;
          k++
        ) {
          console.log(
            'kpiPlanData length',
            kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`].length,
          );
          const kpi =
            kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`][k];
          console.log('kpi', kpi);
          const satuan = kpi.target === '%' ? '%' : '';
          const disabledExceed =
            category.category_name === 'Routine' ? true : false;
          arrDataKpiPlan.push(
            <div className="form__form-group">
              <span
                className="form__form-group-label"
                style={{ paddingLeft: '15px' }}
              >
                {t('LBL.KPI')}
              </span>
              <div className="form__form-group-field">
                <Col xs={3} md={3} lg={3} xl={3}>
                  <textarea name="kpi" disabled="true" value={kpi.kpi_name} />
                </Col>
                <Col xs={1} md={1} lg={1} xl={1}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.TARGET')}
                    </span>
                    <div
                      className="form__form-group-field number-form"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <input
                        name="target"
                        value={`${kpi.target_value} ${satuan}`}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.WEIGHT')}
                    </span>
                    <div
                      className="form__form-group-field number-form"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        name={`weight_${kpi.ppn_id}_${category.pcr_id}_${kpi.ppi_id}`}
                        component={renderTextInput}
                        type="text"
                        onChange={(x) => this.changeWeight(x)}
                        maxLength="3"
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.DIDNT_REACH')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_1_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.REACH')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_2_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EXCEED')}
                    </span>
                    <div
                      className="form__form-group-field"
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Field
                        component={renderTextInput}
                        name={`pto_3_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled={disabledExceed}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>,
          );
        }
      }
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.WEIGHT')} {category.category_name}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.weight_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span
            className="form__form-group-label"
            style={{ paddingLeft: '15px' }}
          >
            {t('LBL.RESULT')}
          </span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`weightverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.total_weight}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(<hr style={{ width: '100%' }} />);
    }
    const phaseNm = this.state.phaseName.toUpperCase();
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div className="header-no-bg">
                  <h4 className="title-label">
                    {t('LBL.KPI')} {phaseNm} {t('SUBMISSION')}
                  </h4>
                  <p className="sub-label">
                    Please fill out the form below and review your submission
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="card-login-info">
                        <div className="d-flex">
                          <div className="user-profile-login">
                            <img src={UserDefault} alt="user" />
                          </div>
                          <div className="w-100">
                            <h4 className="username-login">
                              {t('LBL.LOGIN_AS')}{' '}
                              {`${ls.get('user_cred').first_name} ${
                                ls.get('user_cred').last_name
                              }`}
                            </h4>
                            <p className="caption-label">
                              {t('LBL.REQUEST_DATE')} :{' '}
                              {moment(new Date()).format('DD/MM/YYYY')}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="sub-header-label">{t('LBL.SUBMISSION_FORM')}</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 20px 30px 20px' }}>
                {/* {this.state.showData === false &&
                  <Row style={{ margin: '0px !important', padding: '0px !important', textAlign: 'right' }}>
                    <Col style={{ margin: '0px !important', padding: '0px !important' }} />
                    <Col style={{ margin: '0px !important', padding: '0px !important' }} >
                      <Button
                        color="default"
                        className="btn btn-secondary btn_table_navbar"
                        onClick={() => this.setState({ redirectUrl: true })}
                        style={{ float: 'right', marginRight: '15px', marginBottom: '0px' }}
                      >
                        {t('FRM.BACK')}
                      </Button>
                    </Col>
                  </Row>
                } */}
                <div
                  className="profile-identity"
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    marginTop: '0px',
                    width: '100%',
                  }}
                >
                  {/* <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t('LBL.SUBMISSION')}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <table id="myTable" className="table data-grid-tbl responsive no-wrap">
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.WORKFLOW')}</td>
                            <td>:
                              {(this.state.workflow === '') ? (
                                <span style={{ color: 'red' }}> Your flow has not been set, please contact the administrator.</span>
                              ) : ` ${this.state.workflow}`
                              }
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.ACTIVITY')}</td>
                            <td>: {this.state.activity}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUESTER')}</td>
                            <td>: {this.state.empname} ({this.state.empcode})</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUEST_DATE')}</td>
                            <td>: {moment(new Date()).format('YYYY-MM-DD')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faFileAlt} />
                        {t('FRM.REQUEST_DETAIL')} - {t('LBL.KPI_PLAN')}
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <div style={{ paddingLeft: '15px', paddingTop: '10px' }}>
                        Key Performance Indicator: {}
                        <Link type="button" onClick={this.toggle} to="#">
                          {' '}
                          {`Open to Look KPI's`}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className="modal-dialog modal-input"
                      style={{ maxWidth: '1050px' }}
                    >
                      <ModalHeader toggle={this.toggle}>
                        {t('LBL.KPI_PLAN')} {t('LBL.FORM')}
                      </ModalHeader>
                      <ModalBody>
                        <form
                          className="form form--horizontal"
                          onSubmit={handleSubmit(this.handleSubmit)}
                          name="addkpiplan"
                          id="addkpiplan"
                        >
                          <Container>
                            <Row>
                              <Col>
                                <Card className="treeMenu_card kpiplan-tree">
                                  <CardBody style={{ height: '500px' }}>
                                    <SortableTree
                                      treeData={this.state.dataTree}
                                      rowHeight={50}
                                      canDrag={false}
                                      className={'kpi-treeMenu'}
                                      generateNodeProps={(rowInfo) => ({
                                        buttons: [
                                          <div className="icon-parent-tree">
                                            {rowInfo.node.level < 4 && (
                                              <FontAwesomeIcon
                                                icon={faAngleRight}
                                              />
                                            )}
                                          </div>,
                                          <div className="checkbox-left">
                                            {rowInfo.node.level > 3 && (
                                              <Field
                                                name={`kpi_${rowInfo.node.id}`}
                                                id={`kpi_${rowInfo.node.id}`}
                                                onChange={(x) =>
                                                  this.checkedKpi(
                                                    x,
                                                    rowInfo.node.id,
                                                  )
                                                }
                                                component={renderCheckBoxField}
                                                defaultChecked={
                                                  rowInfo.node.kpi
                                                }
                                                type="checkbox"
                                              />
                                            )}
                                          </div>,
                                          <div>
                                            {rowInfo.node.level > 3 && (
                                              <div className="user-photo kpiplan-add-emp">
                                                <img
                                                  src={photoIcon}
                                                  title="Show employee"
                                                  id={`emp${rowInfo.node.id}`}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                    this.getEmployeePicked(
                                                      rowInfo.node,
                                                    )
                                                  }
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={`emp${rowInfo.node.id}`}
                                                >
                                                  Show employee
                                                </UncontrolledTooltip>
                                              </div>
                                            )}
                                          </div>,
                                        ],
                                      })}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                              {this.state.showPickedItem && (
                                <Col xs={4} md={4} lg={4} xl={4}>
                                  <div className="kpiplan-show-emp">
                                    <div className="kpiplan-title">
                                      <Link
                                        to="#"
                                        onClick={() => this.hidePicked()}
                                      >
                                        <div className="d-flex">
                                          <div className="w-100">
                                            KPI Picked
                                          </div>
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faTimes}
                                              className="fa-fw"
                                            />
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="kpiplan-picked">
                                      {this.state.showPickedTitle}
                                    </div>
                                    <span
                                      style={{
                                        paddingLeft: '0px',
                                        paddingBottom: '15px',
                                      }}
                                    >
                                      Employee list:
                                    </span>
                                    <hr style={{ margin: '10px 0px' }} />
                                    <div className="emp-list-kpi">
                                      <Table className="table-shortcut table--bordered">
                                        <tbody>
                                          {this.state.arrEmpPicked.map((dt) => (
                                            <tr>
                                              <td>
                                                <div
                                                  className="user-photo"
                                                  style={{
                                                    marginLeft: '0px',
                                                    marginTop: '12px',
                                                  }}
                                                >
                                                  <img
                                                    className="profile-pic"
                                                    src={dt.path_photo}
                                                    alt=""
                                                  />
                                                </div>
                                                <span className="lbl_emp_name">
                                                  {dt.emp_name}
                                                  <br />
                                                  {dt.emp_code}
                                                </span>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Container>
                        </form>
                      </ModalBody>
                      <ModalFooter>
                        <div style={{ textAlign: 'center' }}>
                          <Button color="secondary" onClick={this.toggle}>
                            {t('FRM.CANCEL')}
                          </Button>
                          {displaySpinner ? (
                            <Button color="primary">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '6px' }}
                              />
                              Loading...
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              form="addkpiplan"
                              type="submit"
                              disabled={disabledButtonSave}
                            >
                              {' '}
                              {t('FRM.SAVE')}
                            </Button>
                          )}
                        </div>
                      </ModalFooter>
                    </Modal>
                  </Row>
                  {this.state.showData && (
                    <Row style={{ padding: '0px' }}>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <form
                            className="form form--horizontal"
                            onSubmit={handleSubmit(this.handleSubmitKpi)}
                            name="savedraftkpi"
                            id="savedraftkpi"
                          >
                            <hr style={{ width: '100%', paddingTop: '0px' }} />
                            {arrDataKpiPlan}
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <Button
                                color="secondary"
                                disabled={this.state.disabledBtnBack}
                                onClick={() =>
                                  this.setState({ redirectUrl: true })
                                }
                              >
                                {' '}
                                {t('FRM.BACK')}
                              </Button>
                              {this.state.workflow === '' ? (
                                <Button color="primary" type="submit" disabled>
                                  {' '}
                                  {t('FRM.SAVE')}
                                </Button>
                              ) : displaySpinner ? (
                                <Button color="primary">
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '6px' }}
                                  />
                                  Loading...
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  form="savedraftkpi"
                                  type="submit"
                                  disabled={pristine}
                                >
                                  {' '}
                                  {t('FRM.SAVE')}
                                </Button>
                              )}
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (formKpiPlan === false) {
    const weightN = arrWeightName;
    const uniqueW = new Set(weightN);
    const fixW = [...uniqueW];
    for (let m = 0; m < fixW.length; m++) {
      if (!values[fixW[m]]) {
        errors[fixW[m]] = 'Weight shouldn’t be empty';
      }
    }
    for (let n = 0; n < arrCategoryId.length; n++) {
      const x = `weightverify_${arrCategoryId[n]}`;
      if (arrWeightVerify[n] === false) {
        errors[x] = 'Result weight is not valid';
      }
    }
  }
  return errors;
};

export default reduxForm({
  form: 'kpiplan',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KpiPlanForm));
