import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Field } from 'redux-form';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faPencilAlt,
  faBox,
  faBoxOpen,
  faEllipsisV,
  faTrashAlt,
  faCalendar,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import DragIcon from 'mdi-react/DragIcon';
import renderTextInput from '../../../../shared/components/form/TextInput';

import './styles/taskItem.scss';
import '../styles/styleModal.scss';
import moment from 'moment';
import axios from 'axios';
import ls from 'local-storage';
import { connectWs } from '../../../../socket';
import Swal from 'sweetalert2';

const STATUS_TASK = {
  0: 'Active',
  1: 'Done',
  2: 'Archived',
};
const regexJPG = /\.(jpe?g|png)$/i;

const TaskItemChild = (props, ...args) => {
  // init state
  const [note, setNote] = useState('');
  const [comments, setComment] = useState([]);
  const [detailTask, setDetailTask] = useState([]);
  const [modalDetail, setModalDetail] = useState(false);
  const [file, setFile] = useState(null);
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
    emp_id: ls.get('user_cred').emp_id,
  });

  // init ref
  const refSocket = useRef(null);
  const hiddenFileInput = useRef(null);

  const {
    t,
    id,
    data,
    toggleOffCanvas,
    toggleNotification,
    getDetailTask,
    getDataSection,
    user,
  } = props;

  // init socket comment
  const initWebSocket = useCallback(() => {
    const onWSMessage = (message) => {
      const jsonMessage = JSON.parse(message.data);
      setComment((prev) => [jsonMessage.data, ...prev]);
    };

    const { socket, socketTimeout } = connectWs(
      'wss://service.phirogo.com/ws',
      onWSMessage,
    );
    refSocket.current = { socket, socketTimeout };
  }, [comments]);

  const sendNotes = () => {
    if (!note.trim() && file == null) return false;

    const formData = new FormData();
    formData.append('pta_id', detailTask?.pta_id);
    formData.append('pse_id', detailTask?.pse_id);
    formData.append('attachment', file);
    formData.append('notes', note);

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(`${auth.apiws}/task/sendComment`, formData, config)
      .then((response) => {
        // setIsLoading(false);
        if (response.data.status == 'ok') {
          setFile(null);
          setNote('');
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
  };

  const updateArchive = (id) => {
    const formData = new FormData();
    formData.append('pse_id', data.pse_id);
    formData.append('pps_id', data.pps_id);
    formData.append('pta_id', data.pta_id);
    formData.append('tab', props.tab);

    let url = `${auth.apiws}/task/updateArchive/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unArchiveTask = (id) => {
    const formData = new FormData();
    formData.append('pse_id', data.pse_id);
    formData.append('pps_id', data.pps_id);
    formData.append('pta_id', data.pta_id);
    formData.append('tab', props.tab);

    let url = `${auth.apiws}/task/unArchiveTask/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTask = (id) => {
    let url = `${auth.apiws}/task/deleteTask/${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCommentList = () => {
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(
        `${auth.apiws}/task/getCommentsTask/${detailTask?.pta_id}`,
        {},
        config,
      )
      .then((response) => {
        if (response.data.status == 'ok') {
          setComment(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateDoneTask = (id, status) => {
    let url;

    const formData = new FormData();
    formData.append('pse_id', data.pse_id);
    formData.append('pps_id', data.pps_id);
    formData.append('pta_id', data.pta_id);
    formData.append('tab', props.tab);

    if (status == 0) {
      url = `${auth.apiws}/task/updateDoneTask/${id}`;
    } else if (status == 1) {
      url = `${auth.apiws}/task/updateUnDoneTask/${id}`;
    }
    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status == 'ok') {
          if (status == 0) {
            toggleNotification();
          }
          getDataSection();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileRemove = () => setFile(null);
  const toggles = () => setModalDetail(!modalDetail);
  const handleClick = () => hiddenFileInput.current.click();
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const allowedType = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/jpeg',
      'application/pdf',
    ];

    if (fileUploaded) {
      const fileType = fileUploaded.type;
      const fileSize = fileUploaded.size;
      const allowedSize = 5000; // in kilobytes

      if (!allowedType.includes(fileType)) {
        Swal.fire({
          title: 'Warning',
          text: 'File type not allowed!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      if (Math.ceil(fileSize / 1000) > allowedSize) {
        Swal.fire({
          title: 'Warning',
          text: 'Maximum file size 5 MB!',
          type: 'warning',
          showCloseButton: true,
          closeButtonColor: '#3085d6',
          reverseButtons: true,
        });
        return false;
      }

      setFile(fileUploaded);
    }
  };

  useEffect(() => {
    if (detailTask?.pta_id) {
      getCommentList(); // get list comment by id task
    }
  }, [detailTask]);

  useEffect(() => {
    initWebSocket();

    return () => {
      refSocket.current.socket.close();
      clearTimeout(refSocket.current.socketTimeout);
    };
  }, []);

  return (
    <>
      {data != false && (
        <div className="task__item">
          <Row className="ml-0">
            <Col md={8} className="pl-0">
              <div className="item__start">
                <div className="start__checkbox d-flex">
                  <DragIcon style={{ height: 14, width: 14, marginRight: 8 }} />
                  <Field
                    name={`checkTask-${id}`}
                    component={renderCheckBoxField}
                    props={{
                      input: {
                        value: data?.task_status == 1 ? true : false,
                        onChange: (e) => {
                          if (e?.type == 'change') {
                            updateDoneTask(data?.pta_id, data?.task_status);
                          }
                        },
                      },
                    }}
                    disabled={data?.is_edit == true ? false : true}
                  />
                </div>
                <div
                  className={`start__content ${
                    data?.task_status == 2 ? 'opacity-75' : null
                  }`}
                >
                  <a
                    onClick={() => {
                      setDetailTask(data);
                      toggles();
                    }}
                  >
                    <h5
                      className={`item__title ${
                        data?.task_status == '1' ? 'line__through' : ''
                      }  `}
                    >
                      {data?.task_name ?? '-'}
                    </h5>
                  </a>
                  <p
                    className={data?.task_status == '1' ? 'line__through' : ''}
                  >
                    {data?.task_description ?? '-'}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="pl-0 mt-3 mt-md-0">
              <div className="item__end">
                <div className="end__badges">
                  <div className="badge__item">
                    {t('LBL.DUEDATE')} :{' '}
                    {data?.task_finish
                      ? moment(data?.task_finish).format('DD MMM YYYY')
                      : '-'}
                  </div>
                  <div className="badge__item d-flex align-items-center">
                    {t('LBL.PRIORITY')} :
                    {data?.tpr_id == '1' ? (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="fa-fw ml-1 text-danger"
                      />
                    ) : data?.tpr_id == '2' ? (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="fa-fw ml-1 text-warning"
                      />
                    ) : data?.tpr_id == '3' ? (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="fa-fw ml-1 text-success"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className="end__actions">
                  <button
                    className="bg-transparent action__item assignee-avatar"
                    id={`assignee_${data?.pta_id}`}
                  >
                    <img
                      src={
                        data?.photo_assignee ??
                        process.env.PUBLIC_URL + '/img/user-pink.svg'
                      }
                      width={24}
                      height={24}
                      style={{ borderRadius: '999px', objectFit: 'cover' }}
                    />
                  </button>
                  <>
                    {data?.is_edit == true && (
                      <>
                        {data?.emp_id_assigner == auth.emp_id ||
                        data.admin_access ? (
                          <UncontrolledDropdown id={`task_${data?.pta_id}`}>
                            <DropdownToggle className="btn btn-sm btn-data-table btn-outline-primary ">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </DropdownToggle>
                            <DropdownMenu {...args}>
                              <>
                                {data?.task_status == 2 ? (
                                  <>
                                    <DropdownItem
                                      className="mt-1"
                                      onClick={() => deleteTask(data?.pta_id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="text-danger"
                                      />
                                      <span className="ml-3">
                                        {t('LBL.DELETETASK')}
                                      </span>
                                    </DropdownItem>
                                    <DropdownItem
                                      className="mt-1"
                                      onClick={() =>
                                        unArchiveTask(data?.pta_id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faBoxOpen}
                                        className="text-secondary"
                                      />
                                      <span style={{ marginLeft: '0.70rem' }}>
                                        {t('LBL.UNARCHIVETASK')}
                                      </span>
                                    </DropdownItem>
                                  </>
                                ) : (
                                  <>
                                    <DropdownItem
                                      onClick={() => {
                                        toggleOffCanvas('edit');
                                        getDetailTask(data?.pta_id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="text-warning"
                                        icon={faPencilAlt}
                                      />
                                      <span className="ml-3 ">
                                        {t('LBL.EDITTASK')}
                                      </span>
                                    </DropdownItem>
                                    <DropdownItem
                                      className="mt-1"
                                      onClick={() =>
                                        updateArchive(data?.pta_id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faBox}
                                        className="text-primary"
                                      />
                                      <span className="ml-3">
                                        {t('LBL.ARCHIVETASK')}
                                      </span>
                                    </DropdownItem>
                                  </>
                                )}
                              </>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        ) : (
                          ''
                        )}

                        {data?.emp_id_assigner == auth.emp_id ||
                        data.admin_access ? (
                          <UncontrolledTooltip
                            placement="top"
                            target={`task_${data?.pta_id}`}
                          >
                            {t('LBL.MORE')}
                          </UncontrolledTooltip>
                        ) : (
                          ''
                        )}
                        <UncontrolledTooltip
                          placement="top"
                          target={`assignee_${data?.pta_id}`}
                        >
                          {data?.emp_name_assignee}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </>
                </div>
              </div>
            </Col>
          </Row>

          {/* START MODAL TASK DETAIL  */}
          <Modal
            isOpen={modalDetail}
            toggle={toggles}
            size="lg"
            className="modal__TaskDetail"
          >
            <ModalHeader className="">
              <div className="d-flex w-100">
                <div className="mr-auto">{detailTask?.task_name}</div>
                <button className="send__chat" onClick={toggles}>
                  <img src="/img/close-icon.svg" width={15} alt="" />
                </button>
              </div>
            </ModalHeader>
            <ModalBody>
              <Row className="p-3">
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.DESCRIPTION')}</p>
                        <div className="desc__">
                          {detailTask?.task_description ?? '-'}
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.TASKASSIGNEE')}</p>
                        <div className="d-flex align-items-center">
                          <img
                            src={detailTask?.photo_assignee}
                            className="avatar_assign"
                            alt=""
                          />
                          <div className="ml-2 fw-bold">
                            {' '}
                            {detailTask?.emp_name_assignee ?? '-'}
                          </div>
                        </div>
                      </div>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.ASSIGNER')}</p>
                        <div className="d-flex align-items-center">
                          <img
                            src={detailTask?.photo_assigner}
                            className="avatar_assign"
                            alt=""
                          />
                          <div className="ml-2 fw-bold">
                            {' '}
                            {detailTask?.emp_name_assigner ?? '-'}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.DUEDATE')}</p>
                        <div className="d-flex align-items-center">
                          <span>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="text-danger"
                            />
                          </span>
                          <div className="ml-2 fw-bold">
                            {detailTask?.task_finish
                              ? moment(detailTask?.task_finish).format(
                                  'D MMM Y',
                                )
                              : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="flex-column mb-3">
                        <p>{t('LBL.PRIORITY')}</p>
                        <div
                          className={
                            'd-flex align-items-center status__' +
                            detailTask?.priority_name +
                            ''
                          }
                        >
                          {detailTask?.priority_name ?? '-'}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mt-2">
                  <h5>{t('LBL.ACTIVITY')}</h5>
                  <div className="d-flex align-items-center mt-3 ">
                    <img
                      src={
                        ls.get('user_cred').path_photo
                          ? ls.get('ws_ip') +
                            '/public/uploads' +
                            ls.get('user_cred').path_photo
                          : '/img/icon-avatar-user.svg'
                      }
                      className="user__avatar"
                      alt="avatar-user"
                    />
                    <div className="mx-2 form w-100">
                      <input
                        value={note}
                        type="text"
                        maxLength={1000}
                        placeholder={'Enter Comment'}
                        onChange={(event) => setNote(event.target?.value)}
                        className="form__form-group-input-wrap form__form-group-input-wrap--error-above"
                      />
                    </div>
                    <button onClick={handleClick} className="send__chat">
                      <span style={{ fontSize: '20px', color: '#999999' }}>
                        <FontAwesomeIcon icon={faPaperclip} />
                      </span>
                    </button>
                    <input
                      type="file"
                      onChange={handleChange}
                      component={renderTextInput}
                      ref={hiddenFileInput}
                      style={{ display: 'none' }}
                    />
                    <button className="send__chat" onClick={() => sendNotes()}>
                      <img src="/img/icon-send.svg" alt="" />
                    </button>
                  </div>
                  <div className="mt-3 mb-2 ">
                    {file?.name && (
                      <div className="preview__upload d-flex align-items-center p-2">
                        <div className="mr-auto d-flex align-items-center">
                          <img
                            src={
                              file?.file_type == 'pdf'
                                ? '/img/pdf-icon.svg'
                                : file?.file_type == 'image'
                                ? '/img/image-icon.svg'
                                : ''
                            }
                            className="mr-2"
                            width={20}
                            alt=""
                          />
                          <div>{file?.name}</div>
                        </div>
                        <button
                          className="send__chat"
                          href="#"
                          onClick={handleFileRemove}
                        >
                          <img src="/img/close-icon.svg" width={15} alt="" />
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="scrolls">
                    {comments?.map((item) => (
                      <div className="d-flex align-items-center my-3">
                        <div className="w-100 d-flex">
                          <img
                            src={
                              item?.path_photo
                                ? item?.path_photo
                                : ls.get('ws_ip') +
                                  '/public/uploads/img/icon-avatar-user.svg'
                            }
                            className="user__avatar"
                            alt=""
                          />
                          <div className="w-100 ml-2 notes">
                            <div className="d-flex justify-content-between">
                              <div className="sender__chat">
                                {item?.emp_name}
                              </div>
                              <div className="date__and__time">
                                {item?.ctime}
                              </div>
                            </div>
                            <div className="desc__ d-flex align-items-center">
                              <img
                                src={
                                  item?.file_type == 'pdf'
                                    ? '/img/pdf-icon.svg'
                                    : item?.file_type == 'image'
                                    ? '/img/image-icon.svg'
                                    : ''
                                }
                                className="mr-2"
                                width={20}
                                alt=""
                              />
                              <a
                                className="text-decoration-none"
                                target="_blank"
                                href={
                                  item?.attachment
                                    ? ls.get('ws_ip') + item?.attachment
                                    : '#'
                                }
                              >
                                {item?.file_name}
                              </a>
                            </div>
                            <div className="desc__">{item?.notes}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          {/* END MODAL TASK DETAIL */}
        </div>
      )}
    </>
  );
};

export default TaskItemChild;
