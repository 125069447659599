import { createContext, useReducer } from 'react';

const initalState = {
  chart: {
    current: [],
    target: [],
    name: {},
    metricName: '',
  },
};

const ACTIONS = {
  SET_CHART: 'SET_CHART',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_CHART:
      return {
        ...state,
        chart: {
          current: action.current,
          target: action.target,
          name: action.name,
          metricName: action.metricName
        },
      };

    default:
      return state;
  }
}

const OkrContext = createContext();
const getReducer = () => useReducer(reducer, initalState);

export { ACTIONS, OkrContext, getReducer };
