import Cookies from 'universal-cookie';
import ls from 'local-storage';
import axios from 'axios';
import { store } from '../../containers/App/store';
import utils from '../../utils';
import globalId from '../../translations/id/global_lang.json';
import globalEn from '../../translations/en/global_lang.json';
import globalCh from '../../translations/ch/global_lang.json';

export function syncLang() {
  const cookie = new Cookies();

  let curLang = cookie.get('language');
  if (typeof curLang === 'undefined') curLang = 'gbr';

  const config = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        `${ls.get('ws_ip')}/applabel/getAll`,
        '',
        config,
      );
      const dataResponse = res.data.data;
      let explodedCode;
      if (typeof dataResponse !== 'undefined') {
        for (let i = 0; i < dataResponse.length; i++) {
          const lang = dataResponse[i].lng_id.trim();
          const code = dataResponse[i].lbl_code;
          const label = dataResponse[i].lbl_label;
          explodedCode = code.split('.');

          switch (lang) {
            case 'ID':
              Object.assign(globalId, {
                [explodedCode[0]]: {
                  ...globalId[explodedCode[0]],
                  [explodedCode[1]]: label,
                },
              });
              break;
            case 'EN':
              Object.assign(globalEn, {
                [explodedCode[0]]: {
                  ...globalEn[explodedCode[0]],
                  [explodedCode[1]]: label,
                },
              });
              break;
            case 'CH':
              Object.assign(globalCh, {
                [explodedCode[0]]: {
                  ...globalCh[explodedCode[0]],
                  [explodedCode[1]]: label,
                },
              });
              break;
            default:
              break;
          }
        }
      }
      
      const langOpts = {
        gbr: {
          global: globalEn,
        },
        idn: {
          global: globalId,
        },
        chn: {
          global: globalCh,
        },
      };
      resolve(langOpts);
    } catch (err) {
      reject(err);
    }
  });
}
