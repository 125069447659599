import React, { Component, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { PersistGate } from 'redux-persist/integration/react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import { persistor, store } from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import { syncLang } from '../../redux/actions/langAction';
import Cookies from 'universal-cookie';
import ls from 'local-storage';
import { clearUserMenu } from '../../redux/actions/sidebarActions';

i18next.init(i18nextConfig);

const NotFound = (props) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center text-center"
      style={{ height: '100vh' }}
    >
      <div className="">
        <img src="/img/403_NEW.svg" alt="" width={225} />
        <h4
          className="text-primary mt-3 mb-3"
          style={{ fontWeight: '600', fontSize: '20px' }}
        >
          Upgrade to unlock premium feature!
        </h4>
        <div style={{ fontWeight: '500', fontSize: '18px' }}>
          You can get a lot more out of it.{' '}
        </div>
        <div style={{ fontWeight: '500', fontSize: '18px' }}>
          Upgrade your Phiro Go package to get the feature.
        </div>

        <a className="btn btn-outline-primary mt-3" href="/dashboard">
          Back to Home
        </a>
        <a
          className="btn btn-primary mt-3"
          href="https://www.phirogo.com"
          target="_blank"
        >
          Upgrade Feature
        </a>
      </div>
    </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLangConfigured: false,
      loading: true,
      loaded: false,
      fua: false,
    };
  }

  componentWillMount() {
    this.handleBlockPage();
    const cookie = new Cookies();
    let curLang = cookie.get('language');
    if (typeof curLang === 'undefined') curLang = 'gbr';

    if (ls.get('ws_ip')) {
      syncLang()
        .then((langOpts) => {
          i18next.init(
            {
              interpolation: { escapeValue: false }, // React already does escaping
              lng: curLang,
              ...langOpts,
            },
            () => {
              this.setState({ isLangConfigured: true });
            },
          );
        })
        .catch((err) => {
          console.log('set lang error', err);
        });
    } else {
      this.setState({ isLangConfigured: true });
    }
  }

  handleBlockPage = () => {
    if (!ls.get('token') && !['/logout','/', '/forgot_password'].includes(window.location.pathname)) {
      ls.clear();
      store.dispatch(clearUserMenu());
      store.dispatch({ type: 'CLEAR_USER' });
      store.dispatch({ type: 'SET_USER_RELOGIN', data: false });
      window.location.href = '/logout';
    }
    let fns = ls.get('function_access')?.map((fn) => fn.url) ?? [];
    this.setState({
      fua: [
        ...fns,
        '/dashboard',
        '/',
        '/workflow/submission',
        '/workflow/monitoring',
        '/workflow/approval',
        '/workflow/monitoring',
        '/administration/addemployee',
        '/administration/editemployee',
        '/report/payslip',
      ].includes(window.location.pathname),
    });
  };

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      // ganti basenamenya pada BrowserRouter
      // menjadi basename="/"
      <Provider store={store}>
        <PersistGate loading={<div>loading...</div>} persistor={persistor}>
          <BrowserRouter basename="/">
            <I18nextProvider i18n={i18next}>
              <ScrollToTop>
                <>
                  {!loaded ||
                    (!this.state.isLangConfigured && (
                      <div className={`load${loading ? '' : ' loaded'}`}>
                        <div className="load__icon-wrap">
                          <svg className="load__icon">
                            <path
                              fill="#4ce1b6"
                              d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                  {!this.state.fua && window.location.pathname !== '/logout' ? (
                    <NotFound />
                  ) : (
                    <div>{this.state.isLangConfigured && <Router />}</div>
                  )}
                </>
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default hot(module)(App);
