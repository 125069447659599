/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable operator-assignment */
import React, { PureComponent } from 'react';
import { Card, Button } from 'reactstrap';
// import { Bar } from 'react-chartjs-2';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';

class AverageSalaryChart extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      // idUser: '',
      token: '',
      // intervalId: null,
    };

    this.getDetail = this.getDetail.bind(this);
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    // this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getNmberEmployees();
    });
  }

  componentWillUnmount() {
    // clearInterval(this.state.intervalId);
  }

  getNmberEmployees() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/employee/getempofficepercentage/`,
      config,
    ).then((response) => {
      const rpv = response.data.data;
      const arr = [];
      rpv.map(obj => (
        arr.push({
          name: obj.name, total: obj.jml, value: obj.value, fill: obj.fill,
        })
      ));
      this.setState({ data: arr });
    });
  }

  getDetail() {
    const detailjoblevel = true;
    const allmenu = false;
    setTimeout(() => {
    }, 200); this.props.getmenu(detailjoblevel, allmenu);
  }

  render() {
    const { t } = this.props;

    return (
      <Card>
        <div className="dashboard__chart chart_border">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.OFFICE')}
              </div>
              <div className="w-100" style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => this.getDetail()}
                >{t('LBL.DETAIL')}
                </Button>
              </div>
            </div>
          </div>
          <div className="chart-body d-flex">
            <div className="container-pie-chart-lg">
              <ResponsiveContainer width={200} height={100}>
                <PieChart>
                  <Pie
                    data={this.state.data}
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    cx={83}
                    cy={80}
                    innerRadius={50}
                    outerRadius={80}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="w-100">
              <ul className="pie-chart-legend">
                {this.state.data.map(obj => (
                  <li>
                    <div className="d-flex">
                      <div
                        className="legend-name w-100"
                        style={{
                          width: 'auto',
                        }}
                      >
                        <span className="legend-color" style={{ backgroundColor: obj.fill }} />
                        {obj.name}
                      </div>
                      {/* <div className="legend-total">{obj.total}</div> */}
                      <div
                        className="legend-total-percent"
                      >{obj.value}%
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

      </Card>
    );
  }
}

export default translate('global')(AverageSalaryChart);
