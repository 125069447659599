/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // UncontrolledDropdown,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
// import { faFemale, faMale } from '@fortawesome/fontawesome-free-solid';


// eslint-disable-next-line no-unused-vars
// const data01 = [{ name: 'Permanent', value: 70, fill: '#2C96F8' },
// { name: 'Contract', value: 20, fill: '#f6da6e' },
// { name: 'Probation', value: 10, fill: '#4ce1b6' }];

class EmpStatus extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      data01: [],
      index: false,
    };
    this.getPercentageEms = this.getPercentageEms.bind(this);
  }
  componentDidMount = () => {
    // alert(this.props.datan);
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
    }, () => {
      this.getPercentageEms();
    });
  }
  getPercentageEms() {
    const { token, apiws } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post(
      `${apiws}/employee/getempgender/`,
      '',
      config,
    ).then((response) => {
      const rpv = response.data.data;
      const arr = [];
      rpv.map(obj => (
        arr.push({
          name: obj.name, total: obj.total, value: obj.value, fill: obj.fill,
        })
      ));
      this.setState({ data01: arr });
    }).catch(() => {
      // console.log(error);
    });
  }

  getDetail() {
    const detailGender = true;
    const allmenu = false;
    setTimeout(() => {
      this.setState({ index: true });
    }, 200); this.props.getmenu(detailGender, allmenu);
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <div className="dashboard__chart chart_border">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.SEX')}
              </div>
              <div className="w-100" style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => this.getDetail()}
                >{t('LBL.DETAIL')}
                </Button>
              </div>
              {/* <div className="other-chart-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle className="icon" outline>
                        <FontAwesomeIcon icon={faBars} />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown__menu">
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Another Action</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div> */}
            </div>
          </div>
          <div className="chart-body d-flex">
            <div className="container-pie-chart-lg">
              <ResponsiveContainer width={200} height={100}>
                <PieChart>
                  <Pie
                    data={this.state.data01}
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    cx={83}
                    cy={80}
                    innerRadius={50}
                    outerRadius={80}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="w-100">
              <ul className="pie-chart-legend">
                {this.state.data01.map(obj => (
                  <li>
                    <div className="d-flex">
                      <div className="legend-name w-100">
                        <span className="legend-color" style={{ backgroundColor: obj.fill }} />
                        {obj.name}
                      </div>
                      {/* <div className="legend-total">{obj.total}</div> */}
                      <div className="legend-total-percent">{obj.value}%</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}


export default (translate('global')(EmpStatus));
