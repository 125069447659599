import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { CSVReader } from 'react-papaparse';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import swal from 'sweetalert2';
import moment from 'moment';
import { SleepIcon } from 'mdi-react';
import { Link } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/fontawesome-free-solid';
// import csv from 'csv-validator';

// eslint-disable-next-line react/prefer-stateless-function
const buttonRef = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class HorizontalForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      message: [],
      token: '',
      apiws: '',
      arrdatacsv: [],
      dynAdjustment: [],
      comp_adj: [],
      codecomp: [],
      head: [],
      emp_code: [],
      displayLoad: 'none',
      displayButton: '',
      file: '',
      headerexcel: [],
      component: [],
      is_backpay: [],
      is_effective: [],
      headercomponent: ['Component', 'Code'],
      headeris_backpay: ['is_backpay', 'Backpay'],
      headeris_effective: ['is_effective', 'Effective'],
      templatevalue: [],
    };
    this.handleOnError = this.handleOnError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getComponent = this.getComponent.bind(this);
    this.downloadTemplate = this.downloadTemplate(this);
    this.downloadComponent = this.downloadComponent(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.getComponent();
    this.getEmployee();
  };

  getComponent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/paycomponent/getAdjComponent/`;
    axios
      .post(url, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        res.map((data) => {
          arr.push({ comp: data.component_code });
          this.state.codecomp.push(data.component_code);
        });
        this.setState({ comp_adj: arr });
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getEmployee() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/employee/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        res.map((data) => {
          arr.push(data.emp_code);
        });
        this.setState({ emp_code: arr });
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleFirstChunk = (chunkc) => {
    const chunk = chunkc.split('\n');

    console.log(chunk[0]);
    console.log(chunk[0].split(','));
    if (chunk[0].split(',').length <= 1 && chunk[0].split(';').length <= 1) {
      setTimeout(() => {
        this.handleOnRemoveFile();
      }, 6000);
      showNotifications('Fail', 'Format Not Supported', 'danger');

      // const errormsg = [];
      // errormsg.push({ row: 'format', log: ['format not supported'] });
      // this.setState({ error: true });
      // this.setState({ message: errormsg, ...this.state.message });
      // return false;
    }
    // return true;
  };

  handleOnFileLoad = (data, e) => {
    const { comp_adj, emp_code, codecomp } = this.state;
    const array = [];
    const header = data[0].data;
    const errheader = [];
    const headers = [];
    header.map((item) => {
      headers.push(item.toLowerCase().replace(' ', '_'));
    });
    const headrequire = [
      'no',
      'employee_code',
      'start_date',
      'end_date',
      'backpay_date',
      'remark',
      'component_code',
      'value',
      'effective',
      'backpay',
    ];

    headrequire.some((v) => {
      if (headers.indexOf(v) === -1) {
        errheader.push(`${v.replace('_', ' ')} not found `);
      }
    });
    if (data.length > 0) {
      array.push({ header: data[0].data, value: data.slice(1) });
    }
    const datacsv = data.slice(1);

    const validdata = [];
    const errordata = [];
    const errormsg = [];
    const adjcom = [];
    const backpay = [];
    const effective = [];
    const value = [];
    const dateReg = /^\d{2}([/])\d{2}([/])\d{4}$/;
    const timeReg = /^\d{2}([:])\d{2}$/;

    if (errheader.length > 0) {
      errormsg.push({ row: 'header', log: errheader });
    } else {
      datacsv.map((item) => {
        item.data.map((hea, key) => {
          if (header[key].toLowerCase() === 'backpay') {
            console.log(hea);
            if (typeof hea === 'undefined' || hea === '') {
              backpay.push('0');
            } else {
              backpay.push(hea);
            }
          }
          if (header[key].toLowerCase().includes('effective')) {
            effective.push(hea);
          }
          if (header[key].toLowerCase().includes('value')) {
            value.push(hea);
          }
        });
      });

      datacsv.map((item, index) => {
        const errorlog = [];
        let comp;
        let stsva;

        // console.log(item);
        // const emp_code = ((item.data[index]).indexOf('emp') !== -1);
        item.data.map((hea, key) => {
          if (headers[key].toLowerCase().includes('no')) {
            if (Number.isNaN(hea) === null) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
          }
          if (headers[key].toLowerCase().includes('emp')) {
            if (hea === '') {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
            if (emp_code.indexOf(hea) === -1) {
              stsva = false;
              errorlog.push(`employee code ${hea} not found`);
            }
          }
          if (headers[key].toLowerCase().includes('date')) {
            if (hea !== '') {
              // yyyy-MM-dd
              const input = hea;
              const [day, month, year] = input.split('/');
              const actualdate = new Date(`${year}/${month}/${day}`);
              const stringdate = moment(actualdate).format('DD/MM/YYYY');
              if (stringdate !== hea) {
                stsva = false;
                errorlog.push(`${header[key]} out of date  `);
              }
              if (hea.match(dateReg) === null) {
                stsva = false;
                errorlog.push(`${header[key]} Invalid, `);
              }
            }
          }
          // if (headers[key].toLowerCase().includes('remark')) {
          //   if (hea === '') {
          //     stsva = false;
          //     errorlog.push(`${header[key]} Invalid, `);
          //   }
          // }
          if (headers[key].toLowerCase().includes('component')) {
            if (hea.includes(' ')) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
            if (codecomp.indexOf(hea) === -1) {
              stsva = false;
              errorlog.push(`component code ${hea} not found`);
            } else {
              comp_adj.map((adj) => {
                if (hea.toLowerCase().includes(adj.comp.toLowerCase())) {
                  if (hea !== '') {
                    comp = {
                      header: hea,
                      value: value[index],
                      backpay: backpay[index],
                      effective: effective[index],
                    };
                  }
                }
              });
            }
          }
          if (headers[key].toLowerCase().includes('value')) {
            if (Number.isNaN(hea) === null) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
          }
          if (headers[key].toLowerCase().includes('effective')) {
            if (Number.isNaN(hea) === null) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
          }
          if (header[key].toLowerCase().includes('backpay')) {
            if (Number.isNaN(hea) === null) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
          }
        });
        if (errorlog.length === 0) {
          stsva = true;
        }

        if (stsva) {
          console.log(comp);
          adjcom.push(comp);
          validdata.push(item);
          this.setState({
            arrdatacsv: validdata,
            dynAdjustment: adjcom,
            head: headers,
            file: e,
          });
        } else {
          errormsg.push({ row: index + 1, log: errorlog });
          errordata.push({ item });
        }
      });
    }

    if (errormsg.length > 0) {
      this.setState({ error: true });
      this.setState({ message: errormsg });
    }
  };

  handleOnError(err, file, inputElem, reason) {
    console.log(err);
    console.log(file);
    console.log(inputElem);
    console.log(reason);
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '' });
    this.setState({ displayButton: 'none' });
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws, arrdatacsv, head, dynAdjustment, file } = this.state;
    const error = true;
    const csvfile = file;
    const arr = [];
    let url;
    if (values.type.value === '1') {
      url = `${apiws}/payadjustment/saveItemArray`;
    } else if (values.type.value === '2') {
      url = `${apiws}/payadjustment/updateItemArray`;
    }
    arrdatacsv.map((elemet, key) => {
      let no;
      let emp_code;
      let start;
      let end;
      let back;
      let remark;
      let comp;
      elemet.data.map((value, index) => {
        if (head[index].toLowerCase().includes('no')) {
          no = { no: value };
        }
        if (head[index].toLowerCase().includes('emp')) {
          emp_code = { emp_code: value };
        }
        if (head[index].toLowerCase().includes('start_date')) {
          const date = value
            .split('/')
            .reverse()
            .join('-');
          start = { adjustment_start_date: moment(date).format('YYYY-MM-DD') };
        }
        if (head[index].toLowerCase().includes('end_date')) {
          if (value !== '') {
            const date = value
              .split('/')
              .reverse()
              .join('-');
            end = { adjustment_end_date: moment(date).format('YYYY-MM-DD') };
          }
        }
        if (head[index].toLowerCase().includes('backpay_date')) {
          if (value !== '') {
            const date = value
              .split('/')
              .reverse()
              .join('-');
            back = { backpay_date: moment(date).format('YYYY-MM-DD') };
          }
        }
        if (head[index].toLowerCase().includes('remark')) {
          remark = { remark: value };
        }
        if (head[index].toLowerCase().includes('component_code')) {
          comp = { component_code: value };
        }
      });
      const dat = Object.assign(
        {},
        no,
        emp_code,
        start,
        end,
        back,
        remark,
        comp,
      );
      arr.push(dat);
    });
    const data = JSON.stringify(arr);
    const adjcomponen = JSON.stringify(dynAdjustment);
    const convertdata = {
      payadj: data,
      dyn_adjustment: adjcomponen,
      file: csvfile,
    };
    const body = { ...convertdata, cuser_id: userid };
    const getFormData = Object.keys(body).reduce((formData, key) => {
      formData.append(key, body[key]);
      return formData;
    }, new FormData());
    // const body = [`payadj=${data}`, `cuser_id=${userid}`, `dyn_adjustment=${adjcomponen}`].join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    setTimeout(() => {
      axios
        .post(url, getFormData, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            this.setState({ displayLoad: 'none' });
            this.setState({ displayButton: '' });
            showNotifications('Success', 'Save Success', 'success');
            this.props.destroy();
            this.handleOnRemoveFile();
          } else {
            this.setState({ displayLoad: 'none' });
            this.setState({ displayButton: '' });
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
        });
    });
  }

  handleOnRemoveFile = (e) => {
    this.myFormRef.reset();
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    this.setState({ error: false });
    this.setState({ message: [] });
  };

  downloadTemplate() {
    const headrequire = [
      'No',
      'Employee Code',
      'Start Date',
      'End Date',
      'Backpay Date',
      'Remark',
      'Component Code',
      'Value',
      'Effective',
      'Backpay',
    ];
    const value = [
      {
        No: '1',
        'Employee Code': 'S1090190',
        'Start Date': '31/12/2021',
        'End Date': '31/12/2021',
        'Backpay Date': '31/12/2021',
        Remark: 'ada',
        'Component Code': 'Salary',
        Value: '9000000',
        Effective: '1',
        Backpay: '0',
      },
    ];
    setTimeout(() => {
      this.setState({ headerexcel: headrequire, templatevalue: value });
    }, 200);
  }

  downloadComponent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    axios
      .post(`${ws_ip}/paycomponent/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        res.map((data) => {
          arr.push({ Component: data.component, Code: data.component_code });
        });
        this.setState({ component: arr });
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/paycomponent/getBackpay`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        res.map((data) => {
          arr.push({ is_backpay: data.is_back_pay, Backpay: data.status });
        });
        this.setState({ is_backpay: arr });
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/paycomponent/getEffective`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        res.map((data) => {
          arr.push({ is_effective: data.is_recurring, Effective: data.status });
        });
        this.setState({ is_effective: arr });
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const ulstyle = { width: '300px', height: '200px', overflow: 'auto' };
    const notestyle = { 'column-count': '4', 'column-gap': '20px' };
    let button_action;
    if (this.state.error) {
      button_action = (
        <Button disabled color="primary" type="submit">
          {t('FRM.SUBMIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          type="submit"
          style={{ display: this.state.displayButton }}
        >
          {t('FRM.SUBMIT')}
        </Button>
      );
    }
    return (
      <Card>
        <CardBody>
          <Row className="m5">
            <Col sm="12" md={12} lg={12}>
              <form
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
                name="loaderatt"
                id="loaderatt"
                // eslint-disable-next-line no-return-assign
                ref={(el) => (this.myFormRef = el)}
              >
                <Container>
                  <Row>
                    <Col sm="12" md={6} lg={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.FILE')}
                        </span>
                        <div className="form__form-group-field">
                          <CSVReader
                            onDrop={this.handleOnFileLoad}
                            onError={this.handleOnError}
                            noDrag
                            addRemoveButton
                            onRemoveFile={this.handleOnRemoveFile}
                            config={{
                              beforeFirstChunk: this.handleFirstChunk,
                              delimitersToGuess: [';', ','],
                            }}
                          >
                            <span> {t('LBL.CLICK_TO_UPLOAD')}.</span>
                          </CSVReader>
                        </div>
                      </div>
                    </Col>
                    {this.state.error === true && (
                      <Col sm="12" md={6} lg={6}>
                        <span
                          className="form__form-group-label"
                          style={{ color: 'red' }}
                        >
                          {t('LBL.ERROR')}
                        </span>
                        <ul style={ulstyle}>
                          {this.state.message.map((obj) => (
                            <React.Fragment>
                              <li>
                                {' '}
                                {t('LBL.ROW')} {obj.row}
                              </li>
                              <ul>
                                {' '}
                                {obj.log.map((ob) => (
                                  <li>{ob}</li>
                                ))}{' '}
                              </ul>
                            </React.Fragment>
                          ))}
                        </ul>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.TYPE_OPERATION')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="type"
                            component={renderSelectField}
                            options={[
                              {
                                value: '1',
                                label: t('LBL.SKIP_EXISTING_RECORD'),
                              },
                              {
                                value: '2',
                                label: t('LBL.UPDATE_EXISTING_RECORD'),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: this.state.displayLoad,
                      textAlign: 'center',
                    }}
                    className="m5"
                  >
                    <Col xs={12} md={6} lg={6} xl={6}>
                      <Card>
                        <CardBody>
                          <div className="load__icon-wrap">
                            <svg className="load__icon">
                              <path
                                fill="#4ce1b6"
                                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                              />
                            </svg>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ButtonToolbar className="form__button-toolbar">
                  {button_action}
                  <Button
                    type="button"
                    onClick={this.handleOnRemoveFile}
                    style={{ display: this.state.displayButton }}
                  >
                    {t('FRM.CANCEL')}
                  </Button>
                </ButtonToolbar>
              </form>
            </Col>
            <Col sm="12" md="12" lg="12" xl="12">
              <div className="note-file-upload">
                <ExcelFile
                  filename="Salary Adjustment Template"
                  element={
                    <Link
                      to="#"
                      // type="button"
                      onClick={this.downloadTemplate}
                    >
                      {' '}
                      <FontAwesomeIcon icon={faDownload} />{' '}
                      <h5>
                        <b>
                          {t('LBL.DOWNLOAD')} {t('LBL.TEMPLATE')}
                        </b>
                      </h5>
                    </Link>
                  }
                >
                  <ExcelSheet data={this.state.templatevalue} name="Adjustment">
                    {this.state.headerexcel.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
                <ExcelFile
                  filename="Salary Adjustment References"
                  element={
                    <Link
                      to="#"
                      // type="button"
                      onClick={this.downloadComponent}
                    >
                      {' '}
                      <FontAwesomeIcon icon={faDownload} />{' '}
                      <h5>
                        <b>
                          {t('LBL.DOWNLOAD')} {t('LBL.SALARY_ADJUSTMENT')}{' '}
                          {t('LBL.REFERENCES')}
                        </b>
                      </h5>
                    </Link>
                  }
                >
                  <ExcelSheet data={this.state.component} name="Component Code">
                    {this.state.headercomponent.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.is_backpay} name="Backpay">
                    {this.state.headeris_backpay.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.is_effective} name="Effective">
                    {this.state.headeris_effective.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
                <br />
                <h5>
                  <b>NOTE:</b>
                </h5>
                <h5>Required file type (*.CSV)</h5>
                <h5>Field Order :</h5>
                <ol style={notestyle}>
                  <li>No</li>
                  <li>Employee Code</li>
                  <li>Start Date(Format(dd/mm/yyyy)) </li>
                  <li>End Date(Format(dd/mm/yyyy)) </li>
                  <li>Backpay Date(Format(dd/mm/yyyy)) </li>
                  <li>Remark</li>
                  <li>Component Code</li>
                  <li>Value(Only Number)</li>
                  <li>Effective(1: True, 0: false)</li>
                  <li>Backpay(1: True, 0: false)</li>
                </ol>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'loaderatt', // a unique identifier for this form
})(translate('global')(HorizontalForm));
