/* eslint-disable react/no-unused-state, react/no-unescaped-entities */
/* eslint-disable max-len, react/no-did-mount-set-state, no-unused-vars */
/* eslint-disable quote-props, camelcase, no-return-assign */
/* eslint-disable class-methods-use-this, prefer-template, prefer-const */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Container,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSync } from '@fortawesome/fontawesome-free-solid';
// import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
// import renderTextInput from '../../../../shared/components/form/TextInput';
// import EditTable from '../../../../shared/components/table/EditableTable';
// import Pagination from '../../../../shared/components/pagination/Pagination';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { Redirect } from 'react-router-dom';
import ls from 'local-storage';

const FunctionFormater = ({ value }) => (
  value === 'active' ? <span className="badge badge-success">Enabled</span> :
  <span className="badge badge-disabled">Disabled</span>
);

FunctionFormater.propTypes = {
  value: PropTypes.string.isRequired,
};

class PaymentListPage extends PureComponent {
  static propTypes = {
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      // t('LBL.LOAN_NUMBER'),
      t('LBL.PAYMENT_DATE'),
      t('LBL.TERM'),
      t('LBL.PAYMENT'),
      t('LBL.OUTSTANDING'),
      t('LBL.PAYMENT_METHOD'),
      t('LBL.PAYMENT_PERIOD'),
      // t('FRM.FUNCTION'),
    ];
    this.state = {
      redirect: false,
      modal: false,
      modalPembayaran: false,
      dataWidth: ['5%', '20%', '25%', '15%', '15%', '20%', '20%'],
    };
    this.linkBack = this.linkBack.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getTableData(); });
    this.setState({ urlData: `${ls.get('ws_ip')}/emploanpayment` });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      // t('LBL.LOAN_NUMBER'),
      t('LBL.PAYMENT_DATE'),
      t('LBL.TERM'),
      t('LBL.PAYMENT'),
      t('LBL.OUTSTANDING'),
      t('LBL.PAYMENT_METHOD'),
      t('LBL.PAYMENT_PERIOD'),
      // t('FRM.FUNCTION'),
    ];
  }

  getTableData() {
    this.child.reload();
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  render() {
    const { t } = this.props;
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (<Redirect to={{
        pathname: '/administration/loan',
        state: {
          usrid: this.props.location.state.usrId,
          name: this.props.location.state.name,
          code: this.props.location.state.code,
          men_id: this.props.location.state.men_id,
          fga_id: this.props.location.state.fga_id,
          men_employee: this.props.location.state.men_employee,
        },
      }}
      />);
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <Col md={12} style={{ 'paddingBottom': '10px' }}>
                  <h3 className="page-title">Payment History</h3>
                  <h3 className="page-subhead subhead">View Payment History</h3>
                </Col>
                <ViewListTable
                  url={this.state.urlData}
                  heads={this.heads}
                  // addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="elp_id"
                  widthTable={this.state.dataWidth}
                  onRef={ref => (this.child = ref)}
                  textHead={`${this.props.location.state.name} (${this.props.location.state.code}) `}
                  conds={{ 'emp_id': this.props.location.state.usrId, 'elh_id': this.props.location.state.elhId }}
                  displayStyleHead=""
                  buttonAction={[]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'LabelListForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  // asyncValidate,
  // asyncBlurFields: ['job_level'],
})(translate('global')(PaymentListPage));
