/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { Container } from 'reactstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ProfilePanel from '../../Dashboards/Crypto/components/ProfilePanel';
// import TaskList from '../../Dashboards/Crypto/components/TaskList';
import EmployeeList from './Components/viewEmployeeList';
import { deleteCryptoTableData } from '../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../shared/prop-types/TablesProps';
import ls from 'local-storage';

class Employees extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.state = {
      men_id: '',
      fga_id: '',
    };
  }

  componentWillMount() {
    const { location } = this.props;
    if (typeof location.men_id === 'undefined' && typeof location.fga_id === 'undefined') {
      this.setState({ men_id: ls.get('men_id'), fga_id: ls.get('fga_id') });
    } else {
      this.setState({ men_id: location.state.men_id, fga_id: location.state.fga_id });
    }

    // console.log(this.state.fga_id);
  }

  // componentDidMount() {
  //   console.log(this.props.location);
  //   // not null from ls
  //   console.log(ls.get('fga_id'));
  //   console.log(ls.get('men_id'));
  // }

  onDeleteCryptoTableData = (index, e) => {
    e.preventDefault();
    const arrayCopy = [...this.props.cryptoTable];
    arrayCopy.splice(index, 1);
    this.props.dispatch(deleteCryptoTableData(arrayCopy));
  };

  render() {
    // const { t } = this.props;
    return (
      <Container className="dashboard">
        <EmployeeList
          men_id={this.state.men_id}
          fga_id={this.state.fga_id}
          location={this.props.location}
        />
      </Container>
    );
  }
}


export default connect(state => ({
  cryptoTable: state.cryptoTable.items,
}))(translate('common')(Employees));
