import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import TaxCalculationViewTable from './components/TaxCalculationViewTable';
import TaxCalculationForm from './components/TaxCalculationForm';

class TaxCalculation extends PureComponent {
  constructor() {
    super();
    this.state = {
      dataUpdate: false,
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
  }

  setDefault = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = () => {
    this.setState({ dataUpdate: true });
  }

  render() {
    return (
      <Container>
        <Row>
          <TaxCalculationForm
            onProcess={this.loadAfterSubmit}
          />
        </Row>
        <Row>
          <TaxCalculationViewTable
            onProcess={this.state.dataUpdate}
            inProcess={this.setDefault}
          />
        </Row>
      </Container>
    );
  }
}

export default TaxCalculation;
