/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
// import NotificationSystem from 'rc-notification';
// import { FullWideNotification } from '../../shared/components/Notification';
// import SweetAlert from 'react-bootstrap-sweetalert';
// import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

// class SweetAlertNotification extends PureComponent {
//   static propTypes = {
//     msg: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//   }

//   constructor() {
//     super();
//     this.state = {
//       // eslint-disable-next-line react/no-unused-state
//       showalert: true,
//       timeout: 3000,
//     };
//     // this.deleteItem = this.deleteItem.bind(this);
//   }

//   hideAlert() {
//     this.setState({ showalert: false });
//   }

//   render() {
//     const { msg, type, title } = this.props;
//     const { showalert, timeout } = this.state;

//     alert('wtf');
//     return (
//       <SweetAlert show={showalert} type={type} title={title} onConfirm={this.hideAlert} timeout={timeout} >
//         {msg}
//       </SweetAlert >
//     );
//   }
// }

export default (function showNotifications(title, msg, type) {
  // let notification = null;
  // eslint-disable-next-line no-return-assign
  // NotificationSystem.newInstance({}, n => notification = n);
  // notification.notice({
  //   content: <FullWideNotification
  //     color={color}
  //     title={title}
  //     message={msg}
  //   />,
  //   duration: 2,
  //   closable: true,
  //   style: { top: 35, left: 'calc(100vw - 100%)' },
  //   className: 'right-up',
  // });
  // render() {
  // const mili = '3000';
  // let showalert = true;
  // function hideAlert() {
  //   showalert = false;
  // }
  // return (<SweetAlertNotification title={title} msg={msg} type={type} />);
  // alert(msg);
  let typestatus;
  if (type === 'danger') {
    typestatus = 'error';
  } else {
    typestatus = type;
  }

  Swal.fire({
    title,
    text: msg,
    type: typestatus,
    showConfirmButton: false,
    timer: 1800,
  });
  // };
});

