/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Card, Button, CardBody,
  CardTitle, Col, Row,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // UncontrolledDropdown,
} from 'reactstrap';
// import { faBars } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../../../../shared/components/table/viewListTable';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};

class DetailJobLevel extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
    getdetail: PropTypes.func.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    office: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.UNIT_ORGANIZATION') },
      { 2: t('LBL.TOTAL') },
      { 3: t('LBL.PERCENTAGE') },
      { 4: t('LBL.DETAIL') },
    ];
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      data01: [],
      dataWidth: ['10%', '40', '20%', '25%', '15%'],
      year: '',
      month: '',
      office: '',
    };
    this.getPercentageJoblevel = this.getPercentageJoblevel.bind(this);
    this.getTableData = this.getTableData.bind(this);
  }
  componentDidMount = () => {
    // alert(this.props.datan);
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
    }, () => {
      this.getPercentageJoblevel();
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/chart/getPaymentSalaryOrganization` });
  }

  getTableData() {
    this.child.reload();
  }

  getPercentageJoblevel() {
    const { token, apiws } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = { year: this.props.year, month: this.props.month, off_id: this.props.office };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/chart/getPaymentSalaryOrganizationChart/`,
      formBody,
      config,
    ).then((response) => {
      const labels = [];
      const datas = [];
      const colour = [];
      const len = response.data.data;
      if (typeof len !== 'undefined') {
        for (let i = 0; i < response.data.data.length; i += 1) {
          colour.push(response.data.data[i].fill);
          labels.push(response.data.data[i].name);
          datas.push(response.data.data[i].total);
        }
        const initialState = {
          labels,
          datasets: [
            {
              label: 'Employee Organization Level Data',
              backgroundColor: colour,
              // borderColor: '#ff0066',
              borderWidth: 1,
              hoverBackgroundColor: colour,
              // hoverBorderColor: '#cc0052',
              data: datas,
            },
          ],
        };
        this.setState({ data: initialState });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  render() {
    const { t } = this.props;
    const backBtn = (
      <Button
        color="primary"
        size="sm"
        onClick={() => this.props.getmenu(false, true, this.props.year, this.props.month, this.props.office)}
        outline
        style={{ marginBottom: '0px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.DETAIL')} {t('LBL.SALARY_DIVISION')}
              </div>
              <div className="w-100" style={{ textAlign: 'right' }}>
                {backBtn}
              </div>
            </div>
          </CardTitle>
          <Row>
            <Col md={12} lg={6} xl={6}>
              <div className="chart-detail">
                <Bar data={this.state.data} options={options} />
              </div>
            </Col>
            <Col md={12} lg={6} xl={6}>
              <ViewListTable
                url={this.state.urlData}
                heads={this.heads}
                primaryKey="org_id"
                widthTable={this.state.dataWidth}
                displayStyleHead="none"
                onRef={ref => (this.child = ref)}
                buttonAction="detailOnly"
                searching="none"
                detailFunc={this.props.getdetail}
                conds={{ year: this.props.year, month: this.props.month, office: this.props.office }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}


export default (translate('global')(DetailJobLevel));
