/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faPrint, faFileAlt, faBriefcase } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import { translate } from 'react-i18next';
import moment from 'moment';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// import { print } from 'react-html2pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import jsPDF from 'jspdf';
import Spinner from 'react-bootstrap/Spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import Printer, { print } from 'react-pdf-print';
import { renderToString } from 'react-dom/server';
// import htmlToPdfmake from 'html-to-pdfmake';


class ProfilePage extends PureComponent {
  static propTypes = {
    location: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      token: '',
      saveonthefly: '',
      redirect: false,
      cv: '',
      displaySpinner: 'none',
      displaySave: '',
      path_photo: '',
    };

    this.loadEmployee = this.loadEmployee.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.download = this.download.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.loadEmployee(this.props.location.state.usrid);
    });
  }

  loadEmployee(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemById/${id}`,
      '',
      config,
    ).then((response) => {
      // prompt('', JSON.stringify(response));
      const dataResponse = response.data.data;
      this.setState({path_photo: `${ls.get('ws_ip')}/public/uploads${dataResponse.user[0].path_photo}`})
      const identityEmployee = [];
      let ktp_no;
      if (dataResponse.identity.length !== 0) {
        dataResponse.identity.forEach((element) => {
          if (element.rti_id === 1) {
            ktp_no = element.identity_number;
          }
        });
      } else {
        identityEmployee.push({
          identity: '',
          identity_no: '',
          expired_date: null,
        });
      }

      if (dataResponse.superordinat.length !== 0) {
        this.setState({ superordinat: dataResponse.superordinat[0].emp_id });
      } else {
        this.setState({ superordinat: '' });
      }
      // const date = new Date().getDate(dataResponse.user[0].dob);
      // const month = new Date().getMonth(dataResponse.user[0].dob) + 1;
      // const year = newDate.getFullYear();
      setTimeout(() => {
        this.setState({
          saveonthefly: {
            ...dataResponse.user[0],
            emp_parent: this.state.superordinat,
            dob: moment(dataResponse.user[0].dob).format('DD-MM-YYYY'),
            join_date: moment(dataResponse.user[0].join_date).format('DD-MM-YYYY'),
            npwp_date: dataResponse.user[0].npwp_date === null ? dataResponse.user[0].npwp_date : moment(dataResponse.user[0].npwp_date).format('DD-MM-YYYY'),
            dyn_identity: identityEmployee,
            component_payroll: dataResponse.pay_component,
            cmp_id: dataResponse.company[0].cmp_id,
            ktp: ktp_no,
          },
          cv: dataResponse.user[0].emp_name,
        });
      }, 200);
    }).catch((error) => {
      console.log(error);
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  // nodeToString=(node) => {
  //   let tmpNode = document.createElement('div');
  //   tmpNode.appendChild(node.cloneNode(true));
  //   const str = tmpNode.innerHTML;
  //   // tmpNode = node = null; // prevent memory leaks in IE
  //   // // eslint-disable-next-line no-console
  //   console.log(str);
  // }

  // eslint-disable-next-line class-methods-use-this
  download() {
    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    // const { vfs } = vfsFonts.pdfMake;
    // pdfMake.vfs = vfs;
    const input = document.getElementById('htmltemplate');
    // console.log(input);
    window.scrollTo(0, 0);
    html2canvas(input, { scale: '1' })
      .then((canvas) => {
        const pdf = new jsPDF('p', 'pt', 'a4');
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const hratio = canvas.height / canvas.width;
        const widt = (pdf.internal.pageSize.width);
        const height = (hratio * widt);
        pdf.addImage(imgData, 'JPEG', 20, 20, (widt - 45), (height - 45));
        // pdf.output('dataurlnewwindow');
        pdf.save(`CV- ${this.state.cv}`);
        this.setState({
          displaySave: '',
          displaySpinner: 'none',
        });
      });
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/jpeg', 1.0);
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgData, 'JPEG', 0, 0);
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save('download.pdf');
    //   });
    // const val = htmlToPdfmake(`${input}`);
    // const dd = { content: val };
    // pdfMake.createPdf(dd).download();
  }


  render() {
    const getEmergencyFoundImg = (urlImg) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = urlImg;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      console.log(canvas.toDataURL('image/png'))
      return canvas.toDataURL('image/png');
    };
    const {
      // eslint-disable-next-line no-unused-vars
      saveonthefly, displaySave, displaySpinner,
    } = this.state;
    const { t } = this.props;
    if (this.state.redirect) {
      return (<Redirect to={{
        pathname: '/administration/employee',
        state: { men_id: this.props.location.state.men_employee, fga_id: this.props.location.state.fga_id },
      }}
      />);
    }
    return (
      <Container>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody >
                <Printer>
                  <div
                    id="htmltemplate"
                    style={{ width: '210mm', height: '297mm' }}
                  >
                    <div className="profile-header">
                      <div className="user-image">
                        <div className="user-photo-cv">
                          <img
                            src={this.props.location.state.photo?getEmergencyFoundImg(this.props.location.state.photo):getEmergencyFoundImg(this.state.path_photo)}
                            alt=""
                            id="p1img1"
                          />
                        </div>
                      </div>
                      <div className="user-identity">
                        <div className="name">
                          {saveonthefly.emp_name}
                        </div>
                        <div className="emp-id">
                          {saveonthefly.emp_code}
                        </div>
                        <div className="job-title">
                          {saveonthefly.position_name}
                        </div>
                      </div>
                    </div>
                    <div className="profile-identity">
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <div className="separator-cv">
                            <FontAwesomeIcon width={50} height={75} icon={faFileAlt} />
                            {t('FRM.EMPLOYEE_DATA')}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} lg={6} xl={6} xs={12} className="profile-data">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td width="200px">{t('LBL.NAME')}</td>
                                <td>:</td>
                                <td>{saveonthefly.emp_name}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.EMPLOYEE_CODE')}</td>
                                <td>:</td>
                                <td>{saveonthefly.emp_code}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.DATE_OF_BIRTH')}</td>
                                <td>:</td>
                                <td>{saveonthefly.dob}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.MARITAL_STATUS')}</td>
                                <td>:</td>
                                <td>{saveonthefly.marital_status}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.EMAIL')}</td>
                                <td>:</td>
                                <td>{saveonthefly.email}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.MOBILE')}</td>
                                <td>:</td>
                                <td>{saveonthefly.mobile}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.IDENTITY_NUMBER')}</td>
                                <td>:</td>
                                <td>{saveonthefly.ktp}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.NPWP_NO')}</td>
                                <td>:</td>
                                <td>{saveonthefly.npwp}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.LAST_EDUCATION')}</td>
                                <td>:</td>
                                <td>{saveonthefly.last_education_name}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                        <Col md={6} lg={6} xl={6} xs={12} className="profile-data">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td width="200px">{t('LBL.SEX')}</td>
                                <td>:</td>
                                <td>{saveonthefly.sex === '1' ? 'Male' : 'Female'}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.RELIGION')}</td>
                                <td>:</td>
                                <td>{saveonthefly.religion}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.BLOOD_TYPE')}</td>
                                <td>:</td>
                                <td>{saveonthefly.blood_type}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.NUMBER_BPJS_KETENAGAKERJAAN')}</td>
                                <td>:</td>
                                <td>{saveonthefly.bpjs_tk_no}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.ADDRESS')}</td>
                                <td>:</td>
                                <td>{saveonthefly.emp_address}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.EMERGENCY_CONTACT')}</td>
                                <td>:</td>
                                <td>{saveonthefly.relation_name} - {saveonthefly.emergency_contact}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.RELATION')}</td>
                                <td>:</td>
                                <td>{saveonthefly.family_relation}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.JOIN_DATE')}</td>
                                <td>:</td>
                                <td>{saveonthefly.join_date}</td>
                              </tr>
                              <tr>
                                <td width="200px">{t('LBL.BIOLOGICAL_MOTHER')}</td>
                                <td>:</td>
                                <td>{saveonthefly.biological_mother}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <div className="separator-cv">
                            <FontAwesomeIcon width={50} height={75} icon={faAngleDoubleUp} />
                            {t('LBL.PROMOTION_MUTATION')}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <ul className="list-promotion-mutation">
                            <li>
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td colSpan="3">{t('LBL.OFFICE')}: <br /> <b>{saveonthefly.office}</b></td>
                                  </tr>
                                  <tr>
                                    <td width="33.33%">{t('LBL.UNIT_ORGANIZATION')}: <br /> <b>{saveonthefly.unit_organization}</b></td>
                                    <td width="33.33%">{t('LBL.JOB_NAME')}: <br /> <b>{saveonthefly.job_name}</b></td>
                                    <td>{t('LBL.JOB_TITLE')}: <br /> <b>{saveonthefly.position_name}</b></td>
                                  </tr>
                                  <tr>
                                    <td width="33.33%">{t('LBL.JOB_BAND')}: <br /> <b>{saveonthefly.job_band}</b></td>
                                    <td width="33.33%">{t('LBL.GRADE')}: <br /> <b>{saveonthefly.job_grade}</b></td>
                                    <td>{t('LBL.EMPLOYEE_STATUS')}: <br /> <b>{saveonthefly.employeement_status}</b></td>
                                  </tr>
                                  <tr>
                                    <td width="33.33%">{t('LBL.START_DATE')}: <br /> <b>{saveonthefly.join_date}</b></td>
                                    <td colSpan="2">{t('LBL.END_DATE')}: <br /> <b>-</b></td>
                                  </tr>
                                </tbody>
                              </table>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Printer>
                <Row>
                  <Col md={12} lg={12} xl={12} xs={12} className="text-center">
                    <Button
                      color="default"
                      className="btn btn-secondary"
                      onClick={this.linkBack}
                      style={{ marginTop: '35px' }}
                    >
                      {t('FRM.BACK')}
                    </Button>
                    <Button color="primary" style={{ marginTop: '35px', display: displaySave }} onClick={this.download}>
                      <FontAwesomeIcon icon={faPrint} />
                      {t('LBL.PRINT')} PDF
                    </Button>
                    <Button color="primary" style={{ marginTop: '35px', display: displaySpinner }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(ProfilePage));
