/* eslint-disable no-unneeded-ternary */
/*
 * Page Grade
 * Notes: Master Data Grade
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus, no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderNumberFormat from 'react-number-format';
import moment from 'moment';

class AttendanceDayOffPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LEAVE_CODE') },
      { '2': t('LBL.LEAVE_NAME') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '30%', '20%', '20%', '15%'],
      urlData: '',
      statusEdit: false,
      arrGroupShift: [],
      arrShift: [],
      showofficeinput: false,
      arrOffice: [],
      parentLeave: false,
      arrEmpStatus: [],
      arrOrgJobBand: [],
      arrOrgJobGrade: [],
      arrOrgPosition: [],
      employmentStatus: [],
      workingTime: false,
      date: [],
      month: [],
      workflow: [],
      editcond: '',
      addcond: '',
      deletecond: '',
      leavecode: [],
      accumulate: false,
      renew: false,
      minus: false,
      arr: [],
      arrExpos: [],
      incPos: [],
      incJob: [],
      incEms: [],
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.deleteDayOff = this.deleteDayOff.bind(this);
    this.editAttLeave = this.editAttLeave.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getWorkflow = this.getWorkflow.bind(this);
    this.getEmploymentStatus = this.getEmploymentStatus.bind(this);
    this.dateAndMonth = this.dateAndMonth.bind(this);
    this.getButton = this.getButton.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getWorkflow();
      this.getLeave();
      this.getButton();
      this.loadSelectBox();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/attleave/getAllitem/` });
    this.dateAndMonth();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LEAVE_CODE') },
      { '2': t('LBL.LEAVE_NAME') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  toggle(item) {
    if (item === 'add') {
      this.hideInputOffice();
    }
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
    });
  }
  handlechange(val, value, newP, propsName) {
    if (propsName === 'exclude_pos_id') {
      const newarr = Object.values(val).slice(0, -1);
      this.setState({
        arrExpos: newarr,
      });
    } else if (propsName === 'include_pos_id') {
      const newarr = Object.values(val).slice(0, -1);
      this.setState({
        incPos: newarr,
      });
    } else if (propsName === 'include_job_id') {
      const newarr = Object.values(val).slice(0, -1);
      this.setState({
        incJob: newarr,
      });
    } else if (propsName === 'include_ems_id') {
      const newarr = Object.values(val).slice(0, -1);
      this.setState({
        incEms: newarr,
      });
    }
  }

  // handleSubmit(values) {
  //   console.log(values);
  //   const userid = ls.get('user_cred').usr_id;
  //   const { token, apiws } = this.state;
  //   const id = values.atl_id;
  //   const error = true;

  //   let wf;
  //   let ato_id;
  //   let lec_code;
  //   let gender;
  //   if (typeof values.workflow === 'undefined' || values.workflow === null) {
  //     wf = '';
  //   } else {
  //     wf = (typeof values.workflow === 'object') ?
  //       values.workflow.value : values.workflow;
  //   }
  //   if (typeof values.lec_code === 'undefined' || values.lec_code === null) {
  //     ato_id = '';
  //     lec_code = '';
  //   } else {
  //     ato_id = (typeof values.lec_code === 'object') ?
  //       values.lec_code.value : values.lec_code;
  //     // eslint-disable-next-line prefer-destructuring
  //     lec_code = (typeof values.lec_code === 'object') ?
  //       values.lec_code.lec_code : this.state.lec_code;
  //   }

  //   if (values.man === true && values.woman === false) {
  //     gender = 1;
  //   } else if (values.woman === true && values.man === false) {
  //     gender = 2;
  //   } else if (values.man === true && values.woman === true) {
  //     gender = 0;
  //   } else {
  //     gender = null;
  //   }
  //   const calc_Workingday = values.calcWorkingday === true ? '1' : '0';
  //   const calc_Holiday = values.calcHoliday === true ? '1' : '0';
  //   const calc_Massleave = values.calcMassleave === true ? '1' : '0';
  //   const termName = typeof values.term_name === 'string' ? values.term_name : '';
  //   const employee_term = ['all_employee', 'all_job_title', 'job_title', 'job_level', 'emp_status'].indexOf(termName);

  //   // const exclude_pos_id = typeof values.exclude_pos_id === 'object' ? JSON.stringify(values.exclude_pos_id) : '';
  //   // const include_pos_id = typeof values.include_pos_id === 'object' ? JSON.stringify(values.include_pos_id) : '';
  //   // const include_job_id = typeof values.include_job_id === 'object' ? JSON.stringify(values.include_job_id) : '';
  //   // const include_ems_id = typeof values.include_ems_id === 'object' ? JSON.stringify(values.include_ems_id) : '';

  //   const exclude_pos_id = JSON.stringify(this.state.arrExpos);
  //   const include_pos_id = JSON.stringify(this.state.incPos);
  //   const include_job_id = JSON.stringify(this.state.incJob);
  //   const include_ems_id = JSON.stringify(this.state.incEms);

  //   const ag = typeof values.leaveConditionAgeInput !== 'undefined' ? values.leaveConditionAgeInput : 0;

  //   let url;

  //   let data;
  //   if (id === undefined || id === '') {
  //     url = `${apiws}/attleave/saveItem`;
  //     data = {
  //       ...values,
  //       workflow: wf,
  //       lec_code,
  //       gender,
  //       calc_Workingday,
  //       calc_Holiday,
  //       calc_Massleave,
  //       ato_id,
  //       exclude_pos_id,
  //       include_pos_id,
  //       include_job_id,
  //       include_ems_id,
  //       employee_term,
  //       termName,
  //       age: ag,
  //       cuser_id: userid,
  //     };
  //   } else {
  //     url = `${apiws}/attleave/updateItem/${id}`;
  //     data = {
  //       ...values,
  //       workflow: wf,
  //       lec_code,
  //       gender,
  //       calc_Workingday,
  //       calc_Holiday,
  //       calc_Massleave,
  //       ato_id,
  //       employee_term,
  //       exclude_pos_id,
  //       include_pos_id,
  //       include_job_id,
  //       include_ems_id,
  //       termName,
  //       age: ag,
  //       muser_id: userid,
  //     };
  //   }
  //   const formBody = Object.keys(data).map(key =>
  //     `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
  //   const config = {
  //     headers: {
  //       'Authorization': `bearer ${token}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   if (error) {
  //     axios.post(
  //       url,
  //       formBody,
  //       config,
  //     ).then((response) => {
  //       if (response.data.status === 'ok') {
  //         showNotifications('Success', 'Save Success', 'success');
  //         this.child.reload('addedit');
  //         this.props.destroy();
  //         this.setState({ modal: false });
  //       } else {
  //         showNotifications('Fail', 'Save Failed', 'danger');
  //       }
  //     }).catch(() => {
  //       // console.log(error);
  //     });
  //   }
  // }
  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.atl_id;
    const error = true;

    let wf;
    let ato_id;
    let lec_code;
    let gender;
    let radioleavelimit;
    if (typeof values.workflow === 'undefined' || values.workflow === null) {
      wf = '';
    } else {
      wf =
        typeof values.workflow === 'object'
          ? values.workflow.value
          : values.workflow;
    }
    if (typeof values.lec_code === 'undefined' || values.lec_code === null) {
      ato_id = '';
      lec_code = '';
    } else {
      ato_id =
        typeof values.lec_code === 'object'
          ? values.lec_code.value
          : values.lec_code;
      // eslint-disable-next-line prefer-destructuring
      lec_code =
        typeof values.lec_code === 'object'
          ? values.lec_code.lec_code
          : this.state.lec_code;
    }

    if (values.radioleavelimit == null){
      radioleavelimit = 1
    } else {
      radioleavelimit = values.radioleavelimit
    }

    if (values.man === true && values.woman === false) {
      gender = 1;
    } else if (values.woman === true && values.man === false) {
      gender = 2;
    } else if (values.man === true && values.woman === true) {
      gender = 0;
    } else {
      gender = null;
    }
    const calc_Workingday = values.calcWorkingday === true ? '1' : '0';
    const calc_Holiday = values.calcHoliday === true ? '1' : '0';
    const calc_Massleave = values.calcMassleave === true ? '1' : '0';
    const termName =
      typeof values.term_name === 'string' ? values.term_name : '';
    const employee_term = [
      'all_employee',
      'all_job_title',
      'job_title',
      'job_level',
      'emp_status',
    ].indexOf(termName);

    // const exclude_pos_id = typeof values.exclude_pos_id === 'object' ? JSON.stringify(values.exclude_pos_id) : '';
    // const include_pos_id = typeof values.include_pos_id === 'object' ? JSON.stringify(values.include_pos_id) : '';
    // const include_job_id = typeof values.include_job_id === 'object' ? JSON.stringify(values.include_job_id) : '';
    // const include_ems_id = typeof values.include_ems_id === 'object' ? JSON.stringify(values.include_ems_id) : '';

    const exclude_pos_id = JSON.stringify(this.state.arrExpos);
    const include_pos_id = JSON.stringify(this.state.incPos);
    const include_job_id = JSON.stringify(this.state.incJob);
    const include_ems_id = JSON.stringify(this.state.incEms);

    const ag =
      typeof values.leaveConditionAgeInput !== 'undefined'
        ? values.leaveConditionAgeInput
        : 0;

    let url;

    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/attleave/saveItem`;
      data = {
        ...values,
        workflow: wf,
        lec_code,
        gender,
        calc_Workingday,
        calc_Holiday,
        calc_Massleave,
        ato_id,
        exclude_pos_id,
        include_pos_id,
        include_job_id,
        include_ems_id,
        employee_term,
        termName,
        age: ag,
        cuser_id: userid,
        radioleavelimit
      };
    } else {
      url = `${apiws}/attleave/updateItem/${id}`;
      data = {
        ...values,
        workflow: wf,
        lec_code,
        gender,
        calc_Workingday,
        calc_Holiday,
        calc_Massleave,
        ato_id,
        employee_term,
        exclude_pos_id,
        include_pos_id,
        include_job_id,
        include_ems_id,
        termName,
        age: ag,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.child.reload('addedit');
            this.props.destroy();
            this.setState({ modal: false });
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }
  dateAndMonth() {
    const dt = [];
    const mt = [];
    let i;
    let a;
    let b;
    for (i = 1; i <= 31; i++) {
      if (i <= 9) {
        i = '0'.concat(i);
      }
      dt.push({ value: String(i), label: String(i) });
    }
    this.setState({ date: dt });
    const mnth = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    for (a = 0; a <= 12; a++) {
      if (a < 9) {
        b = '0'.concat(a + 1);
      } else {
        b = a;
      }
      mt.push({ value: String(b), label: mnth[a] });
    }
    this.setState({ month: mt });
  }
  editAttLeave(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attleave/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data.leave;
          const term_item = response.data.data.item;
          let renewPeriodyear;
          let renewPeriodMonth;
          let radioAccumulateUntil;

          if (getResponseData[0].leave_period === '1') {
            renewPeriodyear = getResponseData[0].leave_period_year;
          } else if (getResponseData[0].leave_period === '2') {
            renewPeriodMonth = getResponseData[0].leave_period_month;
          }

          if (getResponseData[0].can_accumulate === '1') {
            if (getResponseData[0].acc_until_year > 0) {
              radioAccumulateUntil = '1';
            } else if (getResponseData[0].acc_until_month > 0) {
              radioAccumulateUntil = '2';
            } else {
              radioAccumulateUntil = '';
            }
          }

          const ex_pos_id = [];
          const in_pos_id = [];
          const in_job_id = [];
          const in_ems = [];
          let name = [];

          if (term_item.length !== 0) {
            if (getResponseData[0].employee_term === '1') {
              term_item.forEach((element) => {
                ex_pos_id.push(element.pos_id);
              });
              name = 'all_job_title';
            } else if (getResponseData[0].employee_term === '2') {
              term_item.forEach((element) => {
                in_pos_id.push(element.pos_id);
              });
              name = 'job_title';
            } else if (getResponseData[0].employee_term === '3') {
              term_item.forEach((element) => {
                in_job_id.push(element.job_id);
              });
              name = 'job_level';
            } else if (getResponseData[0].employee_term === '4') {
              term_item.forEach((element) => {
                in_ems.push(element.ems_id);
              });
              name = 'emp_status';
            }
          } else if (getResponseData[0].employee_term === '0') {
            name = 'all_employee';
          }
          const calculation_day =
            getResponseData[0].include_day_type === null
              ? ['', '', '']
              : getResponseData[0].include_day_type.split('|');

          const datamaping = {
            atl_id: getResponseData[0].atl_id,
            workflow: getResponseData[0].wfl_id,
            leaveName: getResponseData[0].leave_name,
            descInput: getResponseData[0].leave_description,
            leavelimitInput:
              getResponseData[0].max_day_value_periode_type === null
                ? ''
                : `${getResponseData[0].max_day_value_periode_type}`,
            radioleavelimit:
              getResponseData[0].max_day_periode_type === null
                ? ''
                : `${getResponseData[0].max_day_periode_type}`,
            radioIncludeDay:
              getResponseData[0].include_day_type === null
                ? ''
                : `${getResponseData[0].include_day_type}`,
            calcWorkingday: calculation_day[0] === '1' ? true : false,
            calcHoliday: calculation_day[1] === '1' ? true : false,
            calcMassleave: calculation_day[2] === '1' ? true : false,
            radioRenew: getResponseData[0].periodic === true ? '1' : '0',
            radioMinus: getResponseData[0].can_minus === true ? '1' : '0',
            // radioRenewPeriod: getResponseData[0].recurrence_type === null ? '' : `${getResponseData[0].recurrence_type}`,
            radioRenewPeriodic:
              getResponseData[0].leave_period === null
                ? ''
                : `${getResponseData[0].leave_period}`,
            renewInputYear: renewPeriodyear,
            renewInputMonth: renewPeriodMonth,
            radioJoinDate: getResponseData[0].based_on_join_date,
            radioAccumulate:
              getResponseData[0].can_accumulate === null
                ? ''
                : `${getResponseData[0].can_accumulate}`,
            radioaccumulateUntil: radioAccumulateUntil,
            accumulateInputYear:
              getResponseData[0].acc_until_year === 0
                ? ''
                : getResponseData[0].acc_until_year,
            accumulateInputMonth:
              getResponseData[0].acc_until_month === 0
                ? ''
                : getResponseData[0].acc_until_month,
            radioSexOption:
              getResponseData[0].sex === null
                ? ''
                : `${getResponseData[0].sex}`,
            man:
              getResponseData[0].gender === '0' ||
              getResponseData[0].gender === '1'
                ? true
                : false,
            woman:
              getResponseData[0].gender === '0' ||
              getResponseData[0].gender === '2'
                ? true
                : false,
            leaveConditionAgeInput:
              getResponseData[0].age === null ? '' : getResponseData[0].age,
            radioWorkingperiod:
              getResponseData[0].working_period === null
                ? ''
                : `${getResponseData[0].working_period}`,
            workingTimeInputYear:
              getResponseData[0].minimum_working_year === null
                ? ''
                : getResponseData[0].minimum_working_year,
            workingTimeInputMonth:
              getResponseData[0].minimum_working_month === null
                ? ''
                : getResponseData[0].minimum_working_month,
            term_name: name,
            lec_code: getResponseData[0].ato_id,
            code: getResponseData[0].lec_code,
            exclude_pos_id: ex_pos_id,
            include_pos_id: in_pos_id,
            include_job_id: in_job_id,
            include_ems_id: in_ems,
          };

          this.setState({ lec_code: datamaping.code });
          if (getResponseData[0].periodic === true) {
            this.setState({ renew: true });
          } else {
            this.setState({ renew: false });
          }
          if (getResponseData[0].can_accumulate === '1') {
            this.setState({ accumulate: true });
          } else {
            this.setState({ accumulate: false });
          }

          if (getResponseData[0].can_minus === true) {
            this.setState({ minus: true });
          } else {
            this.setState({ minus: false });
          }

          // this.setState({ maxdayLeave: datamaping.maxDayType });
          // this.setState({ repetitionPeriodic: datamaping.radioRecurrence });
          // this.setState({ repetitionJoinDate: datamaping.radioRecurrence });
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteDayOff(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attleave/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  generate() {
    const { apiws, iduser, token } = this.state;

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Generate leave balance? this may take a few minutes.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, generate!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/Empleavebalance/getBalanceCounting`,
            `usr_id=${iduser}`,
            config,
          )
          .then((res) => {
            if (res.data.data.length > 0) {
              showNotifications('Success', 'Generated', 'success');
            }
          });
      }
    });
  }
  // handleChange(value) {
  //   if (value.target === undefined) {
  //     this.setState({ parentLeave: true });
  //   } else if (value.target.checked === true) {
  //     this.setState({ parentLeave: false });
  //   } else if (value.target.checked === false) {
  //     this.setState({ parentLeave: true });
  //   }

  //   if (value.value === '1') {
  //     this.setState({ employmentStatus: this.state.arrEmpStatus });
  //     this.setState({ workingTime: false });
  //   } else if (value.value === '2') {
  //     this.setState({ employmentStatus: this.state.arrOrgPosition });
  //     this.setState({ workingTime: false });
  //   } else if (value.value === '3') {
  //     this.setState({ employmentStatus: this.state.arrOrgJobBand });
  //     this.setState({ workingTime: false });
  //   } else if (value.value === '4') {
  //     this.setState({ employmentStatus: this.state.arrOrgJobGrade });
  //     this.setState({ workingTime: false });
  //   } else if (value.value === '5') {
  //     this.setState({ workingTime: true });
  //   }
  // }
  getEmploymentStatus() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbemployeementstatus/getAll/`, '', config)
      .then((resEmpStatus) => {
        const emp = resEmpStatus.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmpStatus: array });
        } else {
          emp.map((obj) =>
            array.push({
              value: obj.ems_id,
              label: obj.ems_employeement_status,
            }),
          );
          this.setState({ arrEmpStatus: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getJobGrade() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgjobgrade/getAll/`, '', config)
      .then((resJobGrade) => {
        const jg = resJobGrade.data.data;
        const array = [];
        if (jg.length === 0) {
          this.setState({ arrOrgJobGrade: array });
        } else {
          jg.map((obj) =>
            array.push({ value: obj.jgd_id, label: obj.job_grade }),
          );
          this.setState({ arrOrgJobGrade: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getOrgPosition() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgposition/getAll/`, '', config)
      .then((resJobGrade) => {
        const jp = resJobGrade.data.data;
        const array = [];
        if (jp.length === 0) {
          this.setState({ arrOrgPosition: array });
        } else {
          jp.map((obj) =>
            array.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          this.setState({ arrOrgPosition: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getOrgJobBand() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgjobband/getAll/`, '', config)
      .then((resJobGrade) => {
        const jb = resJobGrade.data.data;
        const array = [];
        if (jb.length === 0) {
          this.setState({ arrOrgJobBand: array });
        } else {
          jb.map((obj) =>
            array.push({ value: obj.jbd_id, label: obj.job_band }),
          );
          this.setState({ arrOrgJobBand: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getWorkflow() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/workflow/getAll`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ arrOrgJobBand: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.wfl_id, label: obj.workflow }),
          );
          this.setState({ workflow: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getLeave() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/attcode/getleave`, '', config)
      .then((response) => {
        const attcode = response.data.data;
        const array = [];
        attcode.map((obj) =>
          array.push({
            value: obj.ato_id,
            label: obj.ato_value,
            lec_code: obj.ato_code,
          }),
        );
        this.setState({ leavecode: array });
      })
      .catch(() => {
        // console.log(error);
      });
  }
  showInputOffice() {
    this.setState({ showofficeinput: true });
  }

  hideInputOffice() {
    this.setState({ showofficeinput: false });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgjoblevel/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.job_id, label: obj.job_level }),
          );
          this.setState({ orgjoblevel: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/rtbemployeementstatus/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status }),
          );
          this.setState({ empstatus: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.post(
    //   `${apiws}/orgorganization/getAll/`,
    //   '',
    //   config,
    // ).then((response) => {
    //   if (response.data.status === 'ok') {
    //     const dataResponse = response.data.data;
    //     const Arr = [];
    //     dataResponse.map(obj => (
    //       Arr.push({ value: obj.org_id, label: obj.unit_organization })
    //     ));
    //     this.setState({ orgorganization: Arr });
    //   }
    // }).catch((error) => {
    //   console.log(error);
    // });

    axios
      .post(`${apiws}/orgposition/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          this.setState({ orgposition: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      statusEdit,
      urlData,
      arrOffice,
      showofficeinput,
      parentLeave,
      repetitionPeriodic,
      repetitionJoinDate,
      maxdayLeave,
      arrEmpStatus,
      arrOrgPosition,
      arrOrgJobBand,
      arrOrgJobGrade,
      employmentStatus,
      workflow,
      leavecode,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;
    const addBtn = (
      <div>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={() => this.toggle('add')}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            display: this.state.addcond === '1' ? '' : 'none',
          }}
        >
          {t('FRM.ADD')} {t('LBL.LEAVE_TYPE')}
        </Button>
        <Button
          color="secondary"
          className="btn_table_navbar"
          onClick={() => this.generate()}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            'margin-right': '5pt',
            display: this.state.addcond === '1' ? '' : '',
          }}
        >
          Generate
        </Button>
      </div>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addleaveform"
          disabled={pristine}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addleaveform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    let officeinput;
    if (showofficeinput) {
      officeinput = (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t('LBL.OFFICE')} {t('LBL.IF_REGIONAL')}
          </span>
          <div className="form__form-group-field">
            <Field
              name="off_id"
              component={renderSelectField}
              multi="true"
              className='custom-multi'
              type="text"
              options={arrOffice}
            />
          </div>
        </div>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="atl_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editAttLeave}
                  deleteFunc={this.deleteDayOff}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.LEAVE_TYPE')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addleaveform"
                  id="addleaveform"
                >
                  <Container>
                    <Row>
                      <Col md="12" lg="12" xl="12" sm="12">
                        <Container className="pl-pr-0">
                          <Row>
                            <Col md="6" lg="6" xl="6" sm="12">
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.LEAVE_NAME')}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="leaveName"
                                    component={renderTextInput}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="6" md="6" lg="6" xl="6">
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.LEAVE_CODE')}
                                </span>
                                <div className="form__form-group-field">
                                  <input type="hidden" name="atl_id" />
                                  <Field
                                    name="lec_code"
                                    component={renderSelectField}
                                    options={leavecode}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="6" md="6" lg="6" xl="6">
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.WORKFLOW')}
                                </span>
                                <div className="form__form-group-field">
                                  <input type="hidden" name="atl_id" />
                                  <Field
                                    name="workflow"
                                    component={renderSelectField}
                                    options={workflow}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" lg="12" xl="12" sm="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.LEAVE_LIMIT')}
                          </span>
                          <div className="form__form-group-field">
                            <Container className="pl-pr-0">
                              <Row>
                                <Col sm="12" md="4" lg="4" xl="4">
                                  <Container className="pl-pr-0">
                                    <Row>
                                      <Col sm="12" md="6" lg="6" xl="6">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="leavelimitInput"
                                              component="input"
                                              type="text"
                                              value="12"
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12" md="6" lg="6" xl="6">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioleavelimit"
                                              id="radioleavelimit"
                                              component={renderRadioButtonField}
                                              label={t('LBL.DAY')}
                                              radioValue="1"
                                            />
                                          </div>
                                        </div>
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioleavelimit"
                                              component={renderRadioButtonField}
                                              label={t('LBL.MONTH')}
                                              radioValue="2"
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" lg="12" xl="12" sm="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CALCULATION_DAY')}
                          </span>
                          <div className="form__form-group-field">
                            <Container className="pl-pr-0">
                              <Row>
                                <Col sm="12" md="12" lg="12" xl="12">
                                  <Container className="pl-pr-0">
                                    <Row>
                                      <Col sm="12" md="4" lg="4" xl="4">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="calcWorkingday"
                                              component={renderCheckBoxField}
                                              label={t('LBL.WORKING_DAY')}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12" md="4" lg="4" xl="4">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="calcHoliday"
                                              component={renderCheckBoxField}
                                              label={t('LBL.HOLIDAY')}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12" md="4" lg="4" xl="4">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="calcMassleave"
                                              component={renderCheckBoxField}
                                              label={t('LBL.MASS_LEAVE')}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12" xl="12" lg="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CAN_RENEW')}?
                          </span>
                          <div className="form__form-group-field">
                            <Container className="pl-pr-0">
                              <Row>
                                <Col sm="12" md="12" lg="12" xl="12">
                                  <Container className="pl-pr-0">
                                    <Row>
                                      <Col sm="12" md="2" lg="2" xl="2">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioRenew"
                                              component={renderRadioButtonField}
                                              label={t('LBL.YES')}
                                              radioValue="1"
                                              onChange={() => {
                                                this.setState({ renew: true });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12" md="2" lg="2" xl="2">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioRenew"
                                              component={renderRadioButtonField}
                                              label={t('LBL.NO')}
                                              radioValue="0"
                                              onChange={() => {
                                                this.setState({ renew: false });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.renew === true && (
                        <Col sm="12" md="12" xl="12" lg="12">
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.RENEW_PERIOD')}
                            </span>
                            <div className="form__form-group-field">
                              <Container className="pl-pr-0">
                                <Row>
                                  <Col sm="6" md="6" lg="6" xl="6">
                                    <div className="form__form-group">
                                      <div className="form__form-group-field form_custom_w100">
                                        <Field
                                          name="radioRenewPeriodic"
                                          component={renderRadioButtonField}
                                          radioValue="1"
                                        />
                                        <Field
                                          name="renewInputYear"
                                          component="input"
                                          type="text"
                                          className="w-70-input"
                                        />
                                        <span className="form__form-group-label">
                                          {t('LBL.YEAR')}
                                        </span>
                                      </div>
                                      <div className="form__form-group-field form_custom_w100">
                                        <Field
                                          name="radioRenewPeriodic"
                                          component={renderRadioButtonField}
                                          radioValue="2"
                                        />
                                        <Field
                                          name="renewInputMonth"
                                          component="input"
                                          type="text"
                                          className="w-70-input"
                                        />
                                        <span className="form__form-group-label">
                                          {t('LBL.MONTH')}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      {this.state.renew === true && (
                        <Col sm="12" md="12" xl="12" lg="12">
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.BASED_ON_JOIN_DATE')}?
                            </span>
                            <div className="form__form-group-field">
                              <Container className="pl-pr-0">
                                <Row>
                                  <Col sm="12" md="12" lg="12" xl="12">
                                    <Container className="pl-pr-0">
                                      <Row>
                                        <Col sm="12" md="2" lg="2" xl="2">
                                          <div className="form__form-group">
                                            <div className="form__form-group-field form_custom_w100">
                                              <Field
                                                name="radioJoinDate"
                                                component={
                                                  renderRadioButtonField
                                                }
                                                label={t('LBL.YES')}
                                                radioValue="1"
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col sm="12" md="2" lg="2" xl="2">
                                          <div className="form__form-group">
                                            <div className="form__form-group-field form_custom_w100">
                                              <Field
                                                name="radioJoinDate"
                                                component={
                                                  renderRadioButtonField
                                                }
                                                label={t('LBL.NO')}
                                                radioValue="0"
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm="12" md="12" xl="12" lg="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.CAN_ACCUMULATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Container className="pl-pr-0">
                              <Row>
                                <Col sm="12" md="12" lg="12" xl="12">
                                  <Container className="pl-pr-0">
                                    <Row>
                                      <Col sm="12" md="2" lg="2" xl="2">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioAccumulate"
                                              component={renderRadioButtonField}
                                              label={t('LBL.YES')}
                                              radioValue="1"
                                              onChange={(val) => {
                                                this.setState({
                                                  accumulate: true,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12" md="2" lg="2" xl="2">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioAccumulate"
                                              component={renderRadioButtonField}
                                              label={t('LBL.NO')}
                                              radioValue="0"
                                              onChange={(val) => {
                                                this.setState({
                                                  accumulate: false,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {this.state.accumulate === true && (
                                      <Row>
                                        <Col sm="12" md="12" lg="12" xl="12">
                                          <div className="form__form-group">
                                            <span className="form__form-group-label">
                                              {t('LBL.UNTIL')}
                                            </span>
                                            <div className="form__form-group-field form_custom_w100">
                                              <Field
                                                name="radioaccumulateUntil"
                                                component={
                                                  renderRadioButtonField
                                                }
                                                radioValue="1"
                                              />
                                              <Field
                                                name="accumulateInputYear"
                                                component="input"
                                                type="text"
                                                className="w-70-input"
                                              />
                                              <span className="form__form-group-label">
                                                {t('LBL.YEAR')}
                                              </span>
                                            </div>
                                            <div className="form__form-group-field form_custom_w100">
                                              <Field
                                                name="radioaccumulateUntil"
                                                component={
                                                  renderRadioButtonField
                                                }
                                                radioValue="2"
                                              />
                                              <Field
                                                name="accumulateInputMonth"
                                                component="input"
                                                type="text"
                                                className="w-70-input"
                                              />
                                              <span className="form__form-group-label">
                                                {t('LBL.MONTH')}
                                              </span>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    )}
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12" md="12" xl="12" lg="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.VALID_UNTIL')}</span>
                          <div className="form__form-group-field">

                            <Field
                              name="repetitionInputYear"
                              component="input"
                              type="text"
                              className="w-70-input"
                            />
                            <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                          </div>
                          <div className="form__form-group-field">
                            <Field
                              name="repetitionInputMonth"
                              component="input"
                              type="text"
                              className="w-70-input"
                            />
                            <span className="form__form-group-label">{t('LBL.MONTH')}</span>

                          </div>
                        </div>
                      </Col>
                    </Row> */}
                    <h5 className="bold-text mb-2">{t('LBL.TERMS')}</h5>
                    <hr className="devider-line" />
                    <Row>
                      <Col sm="12" md="12" lg="12" xl="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.SEX')}
                          </span>
                          <div className="form__form-group-field">
                            <Container className="pl-pr-0">
                              <Row>
                                <Col sm="12" md="4" lg="4" xl="4">
                                  <div className="form__form-group-field form_custom_w100">
                                    <Field
                                      name="man"
                                      component={renderCheckBoxField}
                                      label={t('LBL.MAN')}
                                    />
                                  </div>
                                </Col>
                                <Col sm="12" md="4" lg="4" xl="4">
                                  <div className="form__form-group-field form_custom_w100">
                                    <Field
                                      name="woman"
                                      component={renderCheckBoxField}
                                      label={t('LBL.WOMAN')}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12" lg="12" xl="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.AGE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="leaveConditionAgeInput"
                              component="input"
                              type="text"
                              className="w-70-input"
                            />
                            <span className="form__form-group-label">
                              {t('LBL.YEAR')}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12" lg="12" xl="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.WORKING_PERIOD')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="radioWorkingperiod"
                              component={renderRadioButtonField}
                              radioValue="1"
                            />
                            <Field
                              name="workingTimeInputYear"
                              component="input"
                              type="text"
                              className="w-70-input"
                            />
                            <span className="form__form-group-label">
                              {t('LBL.YEAR')}
                            </span>
                          </div>
                          <div className="form__form-group-field">
                            <Field
                              name="radioWorkingperiod"
                              component={renderRadioButtonField}
                              radioValue="2"
                            />
                            <Field
                              name="workingTimeInputMonth"
                              component="input"
                              type="text"
                              className="w-70-input"
                            />
                            <span className="form__form-group-label">
                              {t('LBL.MONTH')}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12" xl="12" lg="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Enable Minus?
                          </span>
                          <div className="form__form-group-field">
                            <Container className="pl-pr-0">
                              <Row>
                                <Col sm="12" md="12" lg="12" xl="12">
                                  <Container className="pl-pr-0">
                                    <Row>
                                      <Col sm="12" md="2" lg="2" xl="2">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioMinus"
                                              component={renderRadioButtonField}
                                              label={t('LBL.YES')}
                                              radioValue="1"
                                              onChange={() => {
                                                this.setState({ minus: true });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12" md="2" lg="2" xl="2">
                                        <div className="form__form-group">
                                          <div className="form__form-group-field form_custom_w100">
                                            <Field
                                              name="radioMinus"
                                              component={renderRadioButtonField}
                                              label={t('LBL.NO')}
                                              radioValue="0"
                                              onChange={() => {
                                                this.setState({ minus: false });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {this.state.minus && (
                      <Row>
                        <Col sm="12" md="12" lg="12" xl="12">
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              Minus Balance Limit
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="minusbalancelimit"
                                component="input"
                                type="text"
                                className="w-70-input"
                              />
                              <span className="form__form-group-label">
                                {t('LBL.DAY')}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={12} xs={12} lg={12} xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.EMPLOYEE_TERM')}
                          </span>
                          <div className="form__form-group-field products-list__search">
                            <Field
                              name="term_name"
                              component={renderRadioButtonField}
                              label={t('LBL.ALL_EMPLOYEE')}
                              radioValue="all_employee"
                              defaultChecked
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">&nbsp;</span>
                          <div className="form__form-group-field products-list__search">
                            <Row className="w-100">
                              <Col md={4}>
                                <Field
                                  name="term_name"
                                  component={renderRadioButtonField}
                                  label={t('LBL.ALL_JOB_TITLE')}
                                  radioValue="all_job_title"
                                />
                              </Col>
                              <Col md={2}>{t('LBL.EXCEPT')}: </Col>
                              <Col md={6}>
                                <Field
                                  name="exclude_pos_id"
                                  component={renderSelectField}
                                  multi="true"
                                  className='custom-multi'
                                  placeholder="----"
                                  options={this.state.orgposition}
                                  onChange={this.handlechange}
                                  // disabled={disabledinput}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">&nbsp;</span>
                          <div className="form__form-group-field products-list__search">
                            <Row className="w-100">
                              <Col md={4}>
                                <Field
                                  name="term_name"
                                  component={renderRadioButtonField}
                                  label={t('LBL.JOB_TITLE')}
                                  radioValue="job_title"
                                />
                              </Col>
                              <Col md={2}>{t('LBL.PICK')}: </Col>
                              <Col md={6}>
                                <Field
                                  name="include_pos_id"
                                  component={renderSelectField}
                                  multi="true"
                                  className='custom-multi'
                                  placeholder="----"
                                  options={this.state.orgposition}
                                  onChange={this.handlechange}
                                  // disabled={disabledinput}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">&nbsp;</span>
                          <div className="form__form-group-field products-list__search">
                            <Row className="w-100">
                              <Col md={4}>
                                <Field
                                  name="term_name"
                                  component={renderRadioButtonField}
                                  label={t('LBL.JOB_LEVEL')}
                                  radioValue="job_level"
                                />
                              </Col>
                              <Col md={2}>{t('LBL.PICK')}</Col>
                              <Col md={6}>
                                <Field
                                  name="include_job_id"
                                  component={renderSelectField}
                                  multi="true"
                                  className='custom-multi'
                                  placeholder="----"
                                  options={this.state.orgjoblevel}
                                  onChange={this.handlechange}
                                  // disabled={disabledinput}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">&nbsp;</span>
                          <div className="form__form-group-field products-list__search">
                            <Row className="w-100">
                              <Col md={4}>
                                <Field
                                  name="term_name"
                                  component={renderRadioButtonField}
                                  label={t('LBL.EMPLOYEE_STATUS')}
                                  radioValue="emp_status"
                                />
                              </Col>
                              <Col md={2}>{t('LBL.PICK')}: </Col>
                              <Col md={6}>
                                <Field
                                  name="include_ems_id"
                                  component={renderSelectField}
                                  multi="true"
                                  className='custom-multi'
                                  placeholder="----"
                                  options={this.state.empstatus}
                                  onChange={this.handlechange}
                                  // disabled={disabledinput}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {t('FRM.CANCEL')}
                </Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.leaveName) {
    errors.leaveName = 'Leave name field shouldn’t be empty';
  }
  if (!values.lec_code) {
    errors.lec_code = 'Leave code field shouldn’t be empty';
  }
  // if (!values.atd_applies_to) {
  //   errors.atd_applies_to = 'Day off applies to field shouldn’t be empty';
  // }
  // if (typeof values.atd_applies_to === 'object') {
  //   const atdApplies = values.atd_applies_to.value;
  //   if (atdApplies === 'regional') {
  //     if (!values.off_id) {
  //       errors.off_id = 'Day off applies to office field shouldn’t be empty';
  //     }
  //   }
  // }
  // if (!values.description) {
  //   errors.description = 'Description name field shouldn’t be empty';
  // }
  return errors;
};

export default reduxForm({
  form: 'leaveforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(AttendanceDayOffPage));
