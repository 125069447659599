/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../Preferences/notificationMessages';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import ReactExport from 'react-export-excel';
import { jsonToCSV } from 'react-papaparse';
import { CSVLink, CSVDownload } from 'react-csv';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import reactCSS from 'reactcss';
import '../../../../containers/Tables/DataTable/assets/css/style.css';
import Spinner from 'react-bootstrap/Spinner';

const buttonRef = React.createRef();
class ReportTaxRecap extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // onProcess: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { url, t } = props;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      arrPeriod: [],
      urlData: '',
      orgorganization: [],
      company: [],
      office: [],
      empcurrent: [],
      search: '',
      org: '',
      dataexcel: '',
      type: '',
      csvData: '',
      arrYear: [],
      download: false,
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
      condYear: '',
      condPypId: '',
      condEmpId: '',
      condTaxValue: '',
      npwp_comp: [],
      condOffice: '',
      type: '',
      dataexcel: [],
      headerexcel: [],
      month: [],
      component: [],
      pyplist: [],
      datarekap: [],
    };
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.TAX_PERIOD'),
      t('LBL.EMPLOYEE'),
      t('LBL.TAX_YEAR'),
      t('LBL.RECTIFICATION'),
      t('LBL.NPWP'),
      t('LBL.NAME'),
      t('LBL.TAX_CODE'),
      t('LBL.BRUTO_TOTAL'),
      t('LBL.PPH_TOTAL'),
      t('LBL.COUNTRY_CODE'),
    ];
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPeriodProcess = this.getPeriodProcess.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getReport = this.getReport.bind(this);
    this.downloadTemplate = this.downloadTemplate.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.loadSelectBox();
      this.getPeriodProcess(); this.getYear(); this.getMonth();
    });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.TAX_PERIOD'),
      t('LBL.EMPLOYEE'),
      t('LBL.TAX_YEAR'),
      t('LBL.RECTIFICATION'),
      t('LBL.NPWP'),
      t('LBL.NAME'),
      t('LBL.TAX_CODE'),
      t('LBL.BRUTO_TOTAL'),
      t('LBL.PPH_TOTAL'),
      t('LBL.COUNTRY_CODE'),
    ];
  }


  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getMonth() {
    const { t } = this.props;
    const monthNames = [`${t('LBL.JANUARY')}`, `${t('LBL.FEBRUARY')}`, `${t('LBL.MARCH')}`, `${t('LBL.APRIL')}`, `${t('LBL.MAY')}`, `${t('LBL.JUNE')}`,
      `${t('LBL.JULI')}`, `${t('LBL.AUGUST')}`, `${t('LBL.SEPTEMBER')}`, `${t('LBL.OCTOBER')}`, `${t('LBL.NOVEMBER')}`, `${t('LBL.DESEMBER')}`,
    ];
    const comp = ['Ph Bruto', 'Pajak Bulan ini', 'Ph Bruto Bulan ini', 'Pajak S/d Bulan ini', 'PPh DTP'];
    this.setState({ month: monthNames, component: comp });
  }

  getNpwpCompany(id) {
    console.log(id);
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios.get(
      `${apiws}/orgoffice/getAllItemById/${id}`,
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const arr = [];
        dataResponse.map(obj => (
          arr.push({ value: obj.off_id, label: obj.office_npwp })
        ));
        this.setState({ npwp_comp: arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getReport(url) {
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      'pyp_id': this.state.condPypId,
      'year': this.state.condYear,
      'emp_id': this.state.condEmpId,
      'tax_value': this.state.condTaxValue,
      'office': this.state.condOffice,
      'type': '2',
    };
    const array = [];
    const bodyParameters = {
      cond: conds,
    };

    axios.post(
      url,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const data = res.data.data;
        const conf = {
          quotes: false,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ';',
          header: true,
          dynamicTyping: true,
          skipEmptyLines: false,
          columns: null,
        };
        const results = jsonToCSV(data, conf);
        console.log(results);
        setTimeout(() => {
          this.setState({ csvData: results });
          this.setState({ download: true });
          this.inputElement.click();
        }, 2000);
      }
    });
    setTimeout(() => {
      showNotifications('Success', 'Load Success', 'success');
    }, 1000);
  }

  getRekap() {
    this.setState({ displayLoad: 'none', displaySave: '' });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const bodyParameters = {
      year: this.state.condYear,
      emp_id: this.state.condEmpId,
      office: this.state.condOffice,
    };

    axios.post(
      `${apiws}/paysalary/getAllTaxCalculation/`,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const data = res.data.data;
        this.setState({ datarekap: data });
        if (this.state.type === '2') {
          this.handleDownload();
        }
        showNotifications('Success', 'Load Success', 'success');
      } else {
        showNotifications('Failed', 'Load Failed', 'danger');
      }
    });
  }

  getPeriodProcess() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/payperiod/getAllItemTax`,
      '',
      config,
    ).then((response) => {
      const pcs = response.data.data;
      const array = [];
      if (pcs.length === 0) {
        this.setState({ arrPcs: array });
      } else {
        pcs.map(obj => (
          array.push({
            value: obj.pyp_id,
            label: obj.period_code,
            start: obj.period_start,
            end: obj.period_end,
            status: obj.is_process_tax,
          })
        ));
        pcs.map(obj => (
          this.state.pyplist.push(obj.pyp_id)
        ));
        this.setState({ arrPcs: array });
      }
    });
  }

  downloadTemplate() {
    const headrequire = ['No', 'Employee Code', 'Employee Name', 'Country Code', 'No NPWP', 'NPWP Date',
      'Join Date', 'Resign Date ', 'January', 'February'];
    const value = [{
      No: '1',
      'Employee Code': 'S1090190',
      'Employee Name': 'Ajeng Ff',
      'Country Code': 'INA',
      'No NPWP': '28931238',
      'NPWP Date': '29-10-2020',
      'Join Date': '29-10-2020',
      'Resign Date': '10-10-2020',
      'January': 'Ph Bruto',
    }];
    setTimeout(() => {
      this.setState({ headerexcel: headrequire, templatevalue: value });
    }, 200);
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios.get(
      `${apiws}/orgoffice/getAll/`,
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ office: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/employee/getAllItemOrg/0`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ empcurrent: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ office: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ orgorganization: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  handleClick = (e) => {
    e.preventDefault();
  }

  handleDownload() {
    if (!document) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Failed to access document object');
      }

      return null;
    }

    if (document.getElementById('table-to-xls').nodeType !== 1 || document.getElementById('table-to-xls').nodeName !== 'TABLE') {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Provided table property is not html table element');
      }

      return null;
    }

    const table = document.getElementById('table-to-xls').outerHTML;
    const sheet = String('Rekap Pajak');
    const filename = `${String('Rekap Pajak')}.xls`;

    const uri = 'data:application/vnd.ms-excel;base64,';
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{table}</body></html>';

    const context = {
      worksheet: sheet || 'Worksheet',
      table,
    };

    // If IE11
    if (window.navigator.msSaveOrOpenBlob) {
      const fileData = [
        `${'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' + 'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' + 'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' + 'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' + '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' + 'xml><![endif]--></head><body>'}${table}</body></html>`,
      ];
      const blobObject = new Blob(fileData);
      document.getElementById('react-html-table-to-excel').click()(() => {
        window.navigator.msSaveOrOpenBlob(blobObject, filename);
      });

      return true;
    }

    const element = window.document.createElement('a');
    element.href =
      uri +
      ReactHTMLTableToExcel.base64(ReactHTMLTableToExcel.format(template, context));
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    return true;
  }


  handleSubmit(values) {
    this.setState({ displayLoad: '', displaySave: 'none' });
    console.log(values);
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const url = `${ls.get('ws_ip')}/reportpayroll/getReporttaxmonthly`;
    const period = (typeof values.period === 'undefined' || values.period === null) ? '' : values.period.value;
    const year = (typeof values.period_year === 'undefined' || values.period_year === null) ? '' : values.period_year.value;
    const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
    const type = values.type_report.value;
    const taxval = (typeof values.tax_value === 'undefined' || values.tax_value === null) ? '' : values.tax_value.value;
    const office = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
    const valperiod = (year === '' && period === '') ? false : true;
    if (valperiod) {
      const pypId = period;
      setTimeout(() => {
        this.setState({ condYear: year });
        this.setState({ condPypId: pypId });
        this.setState({ condEmpId: empId });
        this.setState({ condTaxValue: taxval });
        this.setState({ condOffice: office });
        this.setState({ urlData: url });
        if (values.type_report.value === '1') {
          this.setState({ type: '1' });
          this.setState({ displayTable: '' });
          // setTimeout(() => {
          //   this.getTableData();
          // }, 200);
          this.getRekap();
        } else if (values.type_report.value === '2') {
          this.setState({ type: '2' });
          this.setState({ displayTable: 'none' });
          this.getRekap();
        }
      }, 100);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      csvData, arrYear, office, arrPcs, empcurrent, urlData, condYear, condPypId,
      displayTable, displayLoad, condEmpId, condTaxValue, condOffice, npwp_comp, displaySave,
    } = this.state;
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: '#fff',
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          marginTop: '5px',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <Container>
        <Row style={{ paddingLeft: '20px' }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.TAX')} -  {t('MEN.REPORT')}</h3>
                    <h3 className="page-subhead subhead">{t('LBL.MONTHLY_RECAPITULATION_EXCEL')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form className="form form--horizontal mt15" name="taxmonthly" id="taxmonthly" onSubmit={handleSubmit(this.handleSubmit)}>
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={empcurrent}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={6} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="company"
                                  component={renderSelectField}
                                  type="text"
                                  options={office}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={6} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="type_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    // { value: '1', label: 'View' },
                                    { value: '2', label: 'Excel' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                          <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                          <Button color="primary" style={{ display: displayLoad }}>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {t('FRM.LOADING')}
                          </Button>
                        </ButtonToolbar>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayTable }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ paddingLeft: '20px' }}>
                <Row className="m5">
                  <div className="card__title" style={{ paddingLeft: '15px' }}>
                    <h5 className="bold-text">{t('LBL.RECAPITULATION_REPORT')}</h5>
                  </div>
                  <div >
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-info"
                      table="table-to-xls"
                      filename="Rekap_Pajak.xlsx"
                      sheet="Rekap Pajak"
                    />
                    <div className="table-responsive">
                      <table id="table-to-xls" className="table data-grid-tbl responsive no-wrap table-no-btn">
                        <tr >
                          <th rowSpan="2">No</th>
                          <th rowSpan="2">Employee Code</th>
                          <th rowSpan="2">Employee Name</th>
                          <th rowSpan="2">Country Code</th>
                          <th rowSpan="2">NPWP</th>
                          <th rowSpan="2">NPWP Date</th>
                          <th rowSpan="2">Join Date</th>
                          <th rowSpan="2">Resign Date</th>
                          {this.state.month.map(data =>
                            <th colSpan="5" >{data}</th>)}
                          <th colSpan="3">Total</th>
                          <th rowSpan="2">Penghasilan Bruto Setahun</th>
                          <th rowSpan="2">PPh21 Setahun</th>
                          <th rowSpan="2">Selisih Penghasilan Bruto</th>
                          <th rowSpan="2">Selisih PPh21</th>
                          <th rowSpan="2">Keterangan</th>
                        </tr>
                        <tr>
                          {this.state.month.map(data =>
                          (
                            this.state.component.map(a =>
                              <td>{a}</td>)
                          ))}
                          <td>Penghasilan Bruto</td>
                          <td>Pajak Setahun</td>
                          <td>PPh DTP</td>
                        </tr>
                        {this.state.datarekap.map((dr, index) =>
                        (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{dr.emp_code}</td>
                            <td>{dr.emp_name}</td>
                            <td>{dr.citizenid === '1' ? 'WNI' : 'WNA'}</td>
                            <td>{dr.npwp}</td>
                            <td>{dr.npwp_date}</td>
                            <td>{dr.join_date}</td>
                            <td>{dr.resign_date}</td>
                            <td>{dr.January.length !== 0 ? dr.January[0].total : '-'}</td>
                            <td>{dr.January.length !== 0 ? dr.January[0].tax_paid : '-'}</td>
                            <td>{dr.January.length !== 0 ? dr.January[0].next_total : '-'}</td>
                            <td>{dr.January.length !== 0 ? dr.January[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.February.length !== 0 ? dr.February[0].total : '-'}</td>
                            <td>{dr.February.length !== 0 ? dr.February[0].tax_paid : '-'}</td>
                            <td>{dr.February.length !== 0 ? dr.February[0].next_total : '-'}</td>
                            <td>{dr.February.length !== 0 ? dr.February[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.March.length !== 0 ? dr.March[0].total : '-'}</td>
                            <td>{dr.March.length !== 0 ? dr.March[0].tax_paid : '-'}</td>
                            <td>{dr.March.length !== 0 ? dr.March[0].next_total : '-'}</td>
                            <td>{dr.March.length !== 0 ? dr.March[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.April.length !== 0 ? dr.April[0].total : '-'}</td>
                            <td>{dr.April.length !== 0 ? dr.April[0].tax_paid : '-'}</td>
                            <td>{dr.April.length !== 0 ? dr.April[0].next_total : '-'}</td>
                            <td>{dr.April.length !== 0 ? dr.April[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.May.length !== 0 ? dr.May[0].total : '-'}</td>
                            <td>{dr.May.length !== 0 ? dr.May[0].tax_paid : '-'}</td>
                            <td>{dr.May.length !== 0 ? dr.May[0].next_total : '-'}</td>
                            <td>{dr.May.length !== 0 ? dr.May[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.June.length !== 0 ? dr.June[0].total : '-'}</td>
                            <td>{dr.June.length !== 0 ? dr.June[0].tax_paid : '-'}</td>
                            <td>{dr.June.length !== 0 ? dr.June[0].next_total : '-'}</td>
                            <td>{dr.June.length !== 0 ? dr.June[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.July.length !== 0 ? dr.July[0].total : '-'}</td>
                            <td>{dr.July.length !== 0 ? dr.July[0].tax_paid : '-'}</td>
                            <td>{dr.July.length !== 0 ? dr.July[0].next_total : '-'}</td>
                            <td>{dr.July.length !== 0 ? dr.July[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.August.length !== 0 ? dr.August[0].total : '-'}</td>
                            <td>{dr.August.length !== 0 ? dr.August[0].tax_paid : '-'}</td>
                            <td>{dr.August.length !== 0 ? dr.August[0].next_total : '-'}</td>
                            <td>{dr.August.length !== 0 ? dr.August[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.September.length !== 0 ? dr.September[0].total : '-'}</td>
                            <td>{dr.September.length !== 0 ? dr.September[0].tax_paid : '-'}</td>
                            <td>{dr.September.length !== 0 ? dr.September[0].next_total : '-'}</td>
                            <td>{dr.September.length !== 0 ? dr.September[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.October.length !== 0 ? dr.October[0].total : '-'}</td>
                            <td>{dr.October.length !== 0 ? dr.October[0].tax_paid : '-'}</td>
                            <td>{dr.October.length !== 0 ? dr.October[0].next_total : '-'}</td>
                            <td>{dr.October.length !== 0 ? dr.October[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.November.length !== 0 ? dr.November[0].total : '-'}</td>
                            <td>{dr.November.length !== 0 ? dr.November[0].tax_paid : '-'}</td>
                            <td>{dr.November.length !== 0 ? dr.November[0].next_total : '-'}</td>
                            <td>{dr.November.length !== 0 ? dr.November[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.December.length !== 0 ? dr.December[0].total : '-'}</td>
                            <td>{dr.December.length !== 0 ? dr.December[0].tax_paid : '-'}</td>
                            <td>{dr.December.length !== 0 ? dr.December[0].next_total : '-'}</td>
                            <td>{dr.December.length !== 0 ? dr.December[0].next_tax_paid : '-'}</td>
                            <td>-</td>
                            <td>{dr.total_bruto}</td>
                            <td>{dr.total_pph21}</td>
                            <td>-</td>
                            <td>{dr.Jumlah_Bruto === null ? 0 : dr.Jumlah_Bruto}</td>
                            <td>{dr.Jumlah_PPh21 === null ? 0 : dr.Jumlah_PPh21}</td>
                            <td>{(dr.Jumlah_Bruto === null ? 0 : dr.Jumlah_Bruto) - dr.total_bruto}</td>
                            <td>{(dr.Jumlah_PPh21 === null ? 0 : dr.Jumlah_PPh21) - dr.total_pph21}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  // if (!values.format_report) {
  //   errors.format_report = 'Format report field shouldn’t be empty';
  // }
  return errors;
};

export default reduxForm({
  form: 'taxrecapitulation', // a unique identifier for this form
  validate,
})(translate('global')(ReportTaxRecap));
