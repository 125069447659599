/* eslint-disable camelcase */
import global_ch from './ch/global_lang.json';
import common_en from './en/common.json';
import common_de from './de/common.json';
import global_id from './id/global_lang.json';
import global_en from './en/global_lang.json';
// import axios from 'axios';
// import ls from 'local-storage';

// axios.post()
// const global_lang = () => {
//   const datas = [];
//   const config = {
//     headers: {
//       Authorization: `bearer ${ls.get('token')}`,
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//   };
//   axios.post(
//     `http://${ls.get('ws_ip')}/applabel/getallitembylngid/INA`,
//     '',
//     config,
//   ).then((response) => {
//     if (response.data.status === 'ok') {
//       // datatree = unplet(response.data.data);
//       // this.setState({ dataTrees: datatree });
//       const dataValues = response.data.data;
//       console.log(dataValues);
//       // const setdefaultvalue = {
//       //   org_id: dataValues.org_id,
//       //   org_id_parent: dataValues.org_id_parent,
//       //   unit_organization: dataValues.unit_organization,
//       //   org_order: dataValues.org_order,
//       // };
//       // this.setState({
//       //   defaultValues: setdefaultvalue
//       // });
//       datas.push(dataValues);
//     }
//   }).catch((error) => {
//     console.log(error);
//   });
//   return datas;
// };

// axios fo
export default {
  gbr: {
    common: common_en,
    global: global_en,
  },
  idn: {
    global: global_id,
  },
  chn: {
    global: global_ch,
  },
  de: {
    common: common_de,
  },
};
