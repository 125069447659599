/* eslint-disable no-unused-vars, quotes, no-lonely-if, prefer-destructuring, react/no-unused-prop-types */
/* eslint-disable array-callback-return, brace-style, object-curly-newline, no-unneeded-ternary */
/* eslint-disable max-len, class-methods-use-this, react/prop-types, jsx-a11y/no-static-element-interactions */
/* eslint-disable no-mixed-operators, react/jsx-closing-bracket-location, no-loop-func */
/* eslint-disable react/no-unused-state, no-prototype-builtins, no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-for, no-restricted-syntax, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable quote-props, jsx-a11y/anchor-is-valid, operator-assignment */
/* eslint-disable camelcase, arrow-parens, no-plusplus, prefer-template, jsx-a11y/alt-text */
/* eslint-disable object-shorthand, react/jsx-curly-brace-presence, arrow-body-style */
/* eslint-disable react/no-did-mount-set-state, function-paren-newline, jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import {
  Container, Row, Col, Button, Card, CardBody, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import renderTextInput from '../../shared/components/form/TextInput';
import moment from 'moment';
import Swal from 'sweetalert2';
import showNotifications from '../Preferences/notificationMessages';

const UserDefault = '/img/user-default-photo.png';
// const photoIcon = '/img/page_header/icon-add-emp.png';

const arrCategoryKpi = [];

class KpiPlanDetail extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      empid: '',
      empname: '',
      empcode: '',
      modal: false,
      displaySpinner: false,
      dataTree: '',
      disabledButtonSave: true,
      redirectUrl: false,
      redirectUrlPlan: false,
      arrPhase: [],
      phaseName: '',
      periodPhaseId: '',
      periodId: '',
      phaseId: '',
      kpiPlanData: [],
      kpiPlanCategory: [],
      kpiPlanStrategic: [],
      totalWeight: 0,
      reviewerId: '',
      arrEmployee: [],
      reviewer: '',
      workflow: '',
      activity: '',
      showData: false,
      wflId: '',
      submitPlan: false,
      statusKpi: '',
      statusNameKpi: '',
      remarksKpi: '',
    };
    this.getKpiplan = this.getKpiplan.bind(this);
  }

  componentDidMount = () => {
    this.props.destroy();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    setTimeout(() => {
      this.getKpiCategory();
      this.getKpiplan();
      this.getFlow();
    }, 300);
    if (typeof this.props.location.state === 'undefined') {
      this.setState({ redirectUrl: true });
    } else {
      setTimeout(() => {
        this.setState({
          periodId: this.props.location.state.periodId,
          phaseId: this.props.location.state.phaseId,
          periodPhaseId: this.props.location.state.periodPhaseId,
          empid: this.props.location.state.empId,
          empname: this.props.location.state.empName,
          empcode: this.props.location.state.empCode,
          phaseName: this.props.location.state.phaseName,
        });
      }, 50);
    }
  }

  getKpiCategory() {
    const { periodId, empid, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${ls.get('ws_ip')}/pfmkpiplan/getAllitemcategorykpi/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const n = res.data.data.length;
        const d = res.data.data;
        for (let i = 0; i < n; i++) {
          arrCategoryKpi[d[i].ppi_id] = d[i].pcr_id;
        }
      }
    });
  }

  getKpiplan() {
    const { periodId, empid, phaseId, periodPhaseId } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${ls.get('ws_ip')}/pfmkpiplan/getAllitemcategorydetail/${empid}/${periodId}/${phaseId}/${periodPhaseId}`,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        if (res.data.data.category.length > 0) {
          const category = res.data.data.category;
          const strategic = res.data.data.strategic;
          const kpi = res.data.data.kpi;
          const flow = res.data.data.flow;
          this.setState({
            kpiPlanCategory: category,
            kpiPlanStrategic: strategic,
            kpiPlanData: kpi,
            showData: true,
            disabledButtonSave: false,
            statusKpi: flow[0].approval_status,
            statusNameKpi: flow[0].opc_status,
            remarksKpi: flow[0].remarks,
          });
        }
      }
    });
  }

  getFlow() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/workflow/getWorkflowPerformance`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data.performance[0];
        this.setState({
          wflId: dataResponse.wfl_id,
          workflow: dataResponse.workflow,
          activity: dataResponse.activity,
        });
      }
    });
  }

  gotoPlan() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will add new kpi plan request !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15DD96', // '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ redirectUrlPlan: true });
        showNotifications('Success', 'Data reloaded', 'success');
      } else {
        this.setState({ redirectUrlPlan: false });
      }
    });
  }

  render() {
    const { t } = this.props;
    const {
      redirectUrl,
      redirectUrlPlan,
      kpiPlanData,
      kpiPlanCategory,
      kpiPlanStrategic,
    } = this.state;

    if (redirectUrl) {
      const link = (<Redirect to={{ pathname: '/performance/information' }} />);
      return link;
    }

    if (redirectUrlPlan) {
      return (<Redirect to={{
        pathname: '/performance/kpi',
          state: {
            periodId: this.state.periodId,
            phaseId: this.state.phaseId,
            periodPhaseId: this.state.periodPhaseId,
            empId: this.state.empid,
            empName: this.state.empname,
            empCode: this.state.empcode,
            phaseName: this.state.phaseName,
          },
        }}
      />);
    }

    const arrDataKpiPlan = [];
    for (let i = 0; i < kpiPlanCategory.length; i++) {
      const category = kpiPlanCategory[i];
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.CATEGORY')}</span>
          <div className="form__form-group-field">
            <Col xs={5} md={5} lg={5} xl={5}>
              <input
                style={{ fontWeight: 'bold' }}
                name="category"
                disabled="true"
                value={category.category_name}
              />
            </Col>
          </div>
        </div>,
      );
      for (let j = 0; j < kpiPlanStrategic[`${category.pcr_id}`].length; j++) {
        const strategic = kpiPlanStrategic[`${category.pcr_id}`][j];
        arrDataKpiPlan.push(
          <div className="form__form-group">
            <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.STRATEGIC_OBJECTIVE')}</span>
            <div className="form__form-group-field">
              <Col xs={5} md={5} lg={5} xl={5}>
                <input
                  name="startegic"
                  disabled="true"
                  value={strategic.strategic_objective_name}
                />
              </Col>
            </div>
          </div>,
        );
        for (let k = 0; k < kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`].length; k++) {
          const kpi = kpiPlanData[`${category.pcr_id}`][`${strategic.pso_id}`][k];
          const satuan = (kpi.target === '%') ? '%' : '';
          arrDataKpiPlan.push(
            <div className="form__form-group">
              <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.KPI')}</span>
              <div className="form__form-group-field">
                <Col xs={3} md={3} lg={3} xl={3}>
                  <textarea
                    name="kpi"
                    disabled="true"
                    value={kpi.kpi_name}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} xl={1}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TARGET')}</span>
                    <div className="form__form-group-field number-form" style={{ width: '100%', padding: '0px' }}>
                      <input name="target" value={`${kpi.target_value} ${satuan}`} disabled />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.WEIGHT')}</span>
                    <div className="form__form-group-field number-form" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        name={`weight_${kpi.ppn_id}_${category.pcr_id}`}
                        component={renderTextInput}
                        type="text"
                        placeholder="%"
                        onChange={(x) => this.changeWeight(x)}
                        maxLength="3"
                        input={{ value: kpi.plan_weight }}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.DIDNT_REACH')}</span>
                    <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        component={renderTextInput}
                        name={`pto_1_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.didnt_reach }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.REACH')}</span>
                    <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        component={renderTextInput}
                        name={`pto_2_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.reach }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={2} md={2} lg={2} xl={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EXCEED')}</span>
                    <div className="form__form-group-field" style={{ width: '100%', padding: '0px' }}>
                      <Field
                        component={renderTextInput}
                        name={`pto_3_${kpi.ppn_id}`}
                        type="text"
                        placeholder=""
                        disabled
                        input={{ value: kpi.exceed }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            ,
          );
        }
      }
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.WEIGHT')} {category.category_name}</span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.weight_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <div className="form__form-group">
          <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.RESULT')} {category.category_name}</span>
          <div className="form__form-group-field number-form">
            <Col xs={3} md={3} lg={3} xl={3}>
              <Field
                name={`weightverify_${category.pcr_id}`}
                component={renderTextInput}
                type="text"
                disabled
                placeholder={category.plan_value}
              />
            </Col>
          </div>
        </div>,
      );
      arrDataKpiPlan.push(
        <hr style={{ width: '100%' }} />,
      );
    }
    const phaseNm = this.state.phaseName.toUpperCase();
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div className="header-no-bg">
                  <h4 className="title-label">{t('LBL.KPI')} {phaseNm} {t('LBL.DETAIL').toUpperCase()}</h4>
                  <p className="sub-label">Review your detail submission</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="card-login-info">
                        <div className="d-flex">
                          <div className="user-profile-login">
                            <img src={UserDefault} alt="user" />
                          </div>
                          <div className="w-100">
                            <h4 className="username-login">
                              {t('LBL.LOGIN_AS')} {`${ls.get('user_cred').first_name} ${ls.get('user_cred').last_name}`}
                            </h4>
                            <p className="caption-label">
                              {t('LBL.REQUEST_DATE')} : {moment(new Date()).format('DD/MM/YYYY')}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="sub-header-label">{t('LBL.SUBMISSION_DETAIL')}</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 20px 30px 20px' }}>
                {/* <Row style={{ margin: '0px !important', padding: '0px !important', textAlign: 'right' }}>
                  <Col style={{ margin: '0px !important', padding: '0px !important' }} />
                  <Col style={{ margin: '0px !important', padding: '0px !important' }} >
                    <Button
                      color="default"
                      className="btn btn-secondary btn_table_navbar"
                      onClick={() => this.setState({ redirectUrl: true })}
                      style={{ float: 'right', marginRight: '15px', marginBottom: '0px' }}
                    >
                      {t('FRM.BACK')}
                    </Button>
                  </Col>
                </Row> */}
                <div className="profile-identity" style={{ paddingLeft: '15px', paddingRight: '15px', marginTop: '0px', width: '100%' }}>
                  {/* <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t('LBL.INFORMATION')}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <table id="myTable" className="table data-grid-tbl responsive no-wrap">
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUESTER')}</td>
                            <td>: {this.state.empname} ({this.state.empcode})</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.REQUEST_DATE')}</td>
                            <td>: {moment(new Date()).format('YYYY-MM-DD')}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.STATUS')}</td>
                            <td>: Pengajuan Diterima</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold', width: '25%' }}>{t('LBL.EFFECTIVE_DATE')}</td>
                            <td>: {moment(new Date()).format('YYYY-MM-DD')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="separator-cv" style={{ textTransform: 'uppercase' }}>
                        <FontAwesomeIcon icon={faFileAlt} />
                        {t('LBL.KPI_PLAN')}
                      </div>
                    </Col>
                  </Row> */}
                  {this.state.showData &&
                    <Row style={{ padding: '0px' }}>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <form
                            className="form form--horizontal"
                            name="savedraftkpi"
                            id="savedraftkpi"
                          >
                            {arrDataKpiPlan}
                            <div className="form__form-group">
                              <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.STATUS')}</span>
                              <div className="form__form-group-field">
                                <Col xs={3} md={3} lg={3} xl={3}>
                                  <ButtonToolbar>
                                    {(this.state.statusKpi === '1') ?
                                      (
                                        <Button style={{ cursor: 'default' }} color="success" onClick="#" size="sm">
                                          {this.state.statusNameKpi}
                                        </Button>
                                      )
                                      :
                                      (
                                        <Button style={{ cursor: 'default' }} color="danger" onClick="#" size="sm">
                                          {this.state.statusNameKpi}
                                        </Button>
                                      )
                                    }
                                  </ButtonToolbar>
                                </Col>
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label" style={{ paddingLeft: '15px' }}>{t('LBL.DESCRIPTION')}</span>
                              <div className="form__form-group-field">
                                <Col xs={5} md={5} lg={5} xl={5}>
                                  <Field
                                    component={renderTextInput}
                                    type="textarea"
                                    input={{ value: this.state.remarksKpi }}
                                    disabled
                                  />
                                </Col>
                              </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <Button color="secondary" onClick={() => this.setState({ redirectUrl: true })}>{t('FRM.BACK')}</Button>
                              {(this.state.statusKpi === '2') && (
                                <Button
                                  color="primary"
                                  onClick={() => this.gotoPlan()}
                                  > {t('FRM.NEW_REQUEST')}
                                </Button>
                              )}
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'kpiplandetail',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KpiPlanDetail));
