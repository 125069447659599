/* eslint-disable camelcase */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable padded-blocks */

import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import ReportSurveyViewTable from './Components/ViewTableReportSurvey';
import ReportSurveyForm from './Components/FormReportSurvey';
import { translate } from 'react-i18next';

class ReportSurvey extends PureComponent {

  constructor() {
    super();
    this.state = {
      dataUpdate: false,
      url: '',
      dt: [],
      survey: [],
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
  }

  setDefault = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = (val, dt, distinc) => {
    this.setState({ dataUpdate: true });
    this.setState({ url: val, dt });
    this.setState({ survey: distinc });
  }

  render() {
    const {
      dataUpdate, url, dt, survey,
    } = this.state;
    return (
      <Container>
        <Row>
          <ReportSurveyForm
            onProcess={this.loadAfterSubmit}
          />
        </Row>
        <Row>
          <ReportSurveyViewTable
            onProcess={dataUpdate}
            inProcess={this.setDefault}
            urlData={url}
            conds={dt}
            surveydata={survey}
          />
        </Row>
      </Container>
    );
  }
}

export default translate('global')(ReportSurvey);
