/* eslint-disable react/prefer-stateless-function */
/* eslint-disable padded-blocks */

import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import ReportAttendanceViewTable from './Components/ViewTableReportAttendance';
import ReportAttendanceForm from './Components/FormReportAttendance';

class ReportAttendance extends PureComponent {
  constructor() {
    super();
    this.state = {
      dataUpdate: false,
      url: '',
    };
    this.loadAfterSubmit = this.loadAfterSubmit.bind(this);
  }

  setDefault = () => {
    this.setState({ dataUpdate: false });
  }

  loadAfterSubmit = (val) => {
    this.setState({ dataUpdate: true });
    this.setState({ url: val });
  }

  render() {
    const { dataUpdate, url } = this.state;
    return (
      <Container>
        <Row>
          <ReportAttendanceForm
            onProcess={this.loadAfterSubmit}
          />
        </Row>
        <Row>
          <ReportAttendanceViewTable
            onProcess={dataUpdate}
            inProcess={this.setDefault}
            urlData={url}
          />
        </Row>
      </Container>
    );
  }
}

export default ReportAttendance;
