/* eslint-disable no-mixed-operators */
/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable operator-assignment */
import React, { PureComponent } from 'react';
import { Card } from 'reactstrap';
// import { Bar } from 'react-chartjs-2';
import { PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import ls from 'local-storage';


// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({
//   cx, cy, midAngle, innerRadius, outerRadius, percent,
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

class AchievementRank extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
    pfp_id: PropTypes.string.isRequired,
    org_id: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      data: [],
      apiws: '',
      // idUser: '',
      token: '',
      pfp_id: '',
      org_id: '',
      // intervalId: null,
    };

    this.getDetail = this.getDetail.bind(this);
  }

  componentDidMount() {
    this.setState({ token: ls.get('token') });
    // this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.setState({ pfp_id: this.props.pfp_id });
      this.setState({ org_id: this.props.org_id });
      this.getPhasePercentage();
    });
  }

  componentWillReceiveProps(nextprops) {
    this.setState({ pfp_id: nextprops.pfp_id });
    this.setState({ org_id: nextprops.org_id });
    setTimeout(() => {
      this.getPhasePercentage();
    }, 200);
  }

  componentWillUnmount() {
    // clearInterval(this.state.intervalId);
  }

  getPhasePercentage() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const data = { pfp_id: this.state.pfp_id, org_id: this.state.org_id };
    console.log(data);
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/Pfmkpiplan/getPhasePercentage/`,
      formBody,
      config,
    ).then((response) => {
      const rpv = response.data.data;
      const arr = [];
      rpv.map(obj => (
        arr.push({
          name: obj.name, total: obj.jml, value: obj.percentage, fill: obj.fill,
        })
      ));
      this.setState({ data: arr });
    });
  }

  getDetail() {
    const detailjoblevel = true;
    const allmenu = false;
    setTimeout(() => {
    }, 200); this.props.getmenu(detailjoblevel, allmenu);
  }

  render() {
    // const { t } = this.props;

    return (
      <Card>
        <div className="dashboard__chart chart_border" style={{ height: '250px' }}>
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                Phase achievement
              </div>
              {/* <div className="w-100" style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => this.getDetail()}
                >{t('LBL.DETAIL')}
                </Button>
              </div> */}
            </div>
          </div>
          <div className="chart-body d-flex" style={{ height: '220px' }}>
            <div className="container-pie-chart-lg" >
              <ResponsiveContainer width={200} height={300}>
                <PieChart>
                  <Pie
                    data={this.state.data}
                    dataKey="value"
                    startAngle={0}
                    endAngle={360}
                    cx={70}
                    cy={60}
                    labelLine
                    // label={renderCustomizedLabel}
                    // innerRadius={50}
                    outerRadius={65}
                  // label
                  />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="w-100">
              <ul className="pie-chart-legend">
                {this.state.data.map(obj => (
                  <li>
                    <div className="d-flex">
                      <div
                        className="legend-name w-100"
                        style={{
                          width: 'auto',
                        }}
                      >
                        <span className="legend-color" style={{ backgroundColor: obj.fill }} />
                        {obj.name}
                      </div>
                      {/* <div className="legend-total">{obj.total}</div> */}
                      <div
                        className="legend-total-percent"
                      >{obj.value}%
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

      </Card>
    );
  }
}

export default translate('global')(AchievementRank);
