import React, { useState, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import './styleOkr.css';
import { translate } from 'react-i18next';
import DataTable from 'react-data-table-component';
import DataTablePagination from '../../../../shared/components/DataTable/DataTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faBullseye,
} from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';
import ls from 'local-storage';
import { OkrContext } from './OkrContext';

const ModalTotalObjective = ({ modal, toggle = () => {}, filter }) => {
  const apiws = ls.get('ws_ip');
  const token = ls.get('token');

  const [state, dispatch] = useContext(OkrContext);

  const [data, setData] = useState({
    data: state?.obj,
    loading: false,
    totalRows: state?.objTotal,
    perPage: 10,
    page: 1,
    column: '',
    sortDirection: '',
    query: '',
    trigger: false,
  });

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        width: '100px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        width: '100px',
      },
    },
  };

  const columns = [
    {
      name: 'Title',
      // selector: (row) => row.title,
      selector: (row) => (
        <div className="gap-2">
          <FontAwesomeIcon
            icon={faBullseye}
            className="text-primary mx-3"
          />
          {row.title}
        </div>
      ),
      sortable: true,
      sortField: 'title',
    },
    {
      name: 'Align To',
      selector: (row) => row.align_name ?? '-',
      sortable: true,
      sortField: 'align_name',
    },
    {
      name: 'Owner',
      selector: (row) => row.owner,
      sortable: true,
      sortField: 'owner',
    },
    {
      name: 'Team',
      selector: (row) => row.team,
      sortable: true,
      sortField: 'team',
    },
    {
      name: 'Progress (%)',
      selector: (row) => <span>{row.achievement_percentage ?? 0}%</span>,
      sortable: true,
      sortField: 'achievement_percentage',
    },
    {
      name: 'Status',
      selector: (row) => row.status_name,
      sortable: true,
      sortField: 'status_name',
    },
  ];

  const search = (val) => {
    const form = new FormData();

    form.append('query', val);
    form.append('page', data.page);
    form.append('per_page', data.perPage);
    form.append('sort', data.column || 'title');
    form.append('order', data.sortDirection || 'asc');
    form.append('filter', JSON.stringify(filter));

    axios
      .post(`${apiws}/okrobjective/getOkrObjective`, form, {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        setData((prev) => ({
          ...prev,
          data: response.data.data,
          totalRows: response.data.total,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'to',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    pagination: true,
  };


  return (
    <Modal
      className="modal-dahboard-okr"
      isOpen={modal}
      toggle={() => {
        toggle('totalObjective');
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle('totalObjective');
        }}
      >
        Detail Total Objective
      </ModalHeader>
      <ModalBody className="py-4 px-3">
        <Col md={4} className="search-table">
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  onInput={(query) =>
                    setData({ ...data, query: query.target.value })
                  }
                  onChange={(e) => search(e.target.value)}
                  type="text"
                  className="form__form-group-input-wrap form__form-group-input-wrap--error-above"
                  placeholder="Search Title..."
                />
                <div className="form__form-group-icon">
                  <span class="pl-1 pr-1">
                    <FontAwesomeIcon color="black" icon={faSearch} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <DataTable
          columns={columns}
          data={data.data}
          direction="auto"
          responsive
          striped
          customStyles={customStyles}
          paginationComponent={DataTablePagination}
          paginationServer
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={data.totalRows}
          onChangeRowsPerPage={(newPerPage, page) => {
            const form = new FormData();

            form.append('query', data.query);
            form.append('page', page);
            form.append('per_page', newPerPage);
            form.append('sort', data.column || 'title');
            form.append('order', data.sortDirection || 'asc');
            form.append('filter', JSON.stringify(filter));

            axios
              .post(`${ls.get('ws_ip')}/okrobjective/getOkrObjective`, form, {
                headers: {
                  Authorization: `bearer ${ls.get('token')}`,
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then((res) => {
                setData({
                  ...data,
                  data: res.data.data,
                  perPage: newPerPage,
                  page,
                  totalRows: res.data.total,
                });
              });
          }}
          onChangePage={(page) => {
            const form = new FormData();

            form.append('query', data.query);
            form.append('page', page);
            form.append('per_page', data.perPage);
            form.append('sort', data.column || 'title');
            form.append('order', data.sortDirection || 'asc');
            form.append('filter', JSON.stringify(filter));

            axios
              .post(`${ls.get('ws_ip')}/okrobjective/getOkrObjective`, form, {
                headers: {
                  Authorization: `bearer ${ls.get('token')}`,
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then((res) => {
                setData({
                  ...data,
                  data: res.data.data,
                  page,
                  totalRows: res.data.total,
                });
              });
          }}
          sortServer
          onSort={(col, sort) => {
            const form = new FormData();

            form.append('query', data.query);
            form.append('page', data.page);
            form.append('per_page', data.perPage);
            form.append('sort', col.sortField);
            form.append('order', sort);
            form.append('filter', JSON.stringify(filter));

            axios
              .post(`${ls.get('ws_ip')}/okrobjective/getOkrObjective`, form, {
                headers: {
                  Authorization: `bearer ${ls.get('token')}`,
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then((res) => {
                setData({
                  ...data,
                  data: res.data.data,
                  column: col.sortField,
                  sortDirection: sort,
                  totalRows: res.data.total,
                });
              });
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            toggle('totalObjective');
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTotalObjective;
