/* eslint-disable max-len, object-curly-newline */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus, no-unneeded-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase, arrow-parens */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props, array-callback-return */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderSelectField from '../../../../shared/components/form/Select';
import renderNumberFormat from '../../../../shared/components/form/NumberFormat';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import Swal from 'sweetalert2';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';

class Attribut extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgoffice: [],
      hideButton: false,
      statusEdit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '30%', '10%', '20%', '20%', '15%'],
      urlData: '',
      editcond: '',
      addcond: '',
      deletecond: '',
      modal: false,
      dataVal: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.OFFICE') },
      { '2': t('LBL.VALUE') },
      { '3': t('LBL.START_DATE') },
      { '4': t('LBL.END_DATE') },
      { '5': t('FRM.FUNCTION') },
    ];

    this.toggle = this.toggle.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.editattribut = this.editattribut.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deleteattribut = this.deleteattribut.bind(this);
    this.getOffice = this.getOffice.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getOffice();
      this.getButton();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/payump/getAllitem/` });
    this.getTableData();
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.OFFICE') },
      { '2': t('LBL.VALUE') },
      { '3': t('LBL.START_DATE') },
      { '4': t('LBL.END_DATE') },
      { '5': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getOffice() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/orgoffice/getAll`, '', config).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.off_id, label: obj.office }),
        );
        this.setState({ orgoffice: Arr });
      }
    });
  }

  toggle() {
    this.props.destroy();
    this.setState({ statusEdit: false });
    this.setState({ modal: !this.state.modal });
  }

  handleSubmitInput(values) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const id = values.pup_id;
    const office =
      typeof values.off_id.value === 'undefined'
        ? values.off_id
        : values.off_id.value;
    const start = values.start_date.format('YYYY-MM-DD');
    const end =
      typeof values.end_date === 'undefined' || values.end_date === null
        ? ''
        : values.end_date.format('YYYY-MM-DD');
    const userid = ls.get('user_cred').usr_id;
    const dtvalue = values.value.floatValue;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/payump/saveItem`;
      data = {
        ...values,
        start_date: start,
        end_date: end,
        off_id: office,
        value: dtvalue,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/payump/updateItem/${id}`;
      data = {
        ...values,
        off_id: office,
        start_date: start,
        end_date: end,
        value: dtvalue,
        muser_id: userid,
      };
    }
    this.setState({ hideButton: true });
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios.post(url, formBody, config).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
        this.setState({ modal: false });
        this.setState({ hideButton: false });
        setTimeout(() => {
          this.child.reload();
        }, 2000);
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    });
  }

  async editattribut(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/payump/getAllItemById/${id}`, '', config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const datamaping = {
            pup_id: getResponseData[0].pup_id,
            off_id: getResponseData[0].off_id,
            value: {
              value: `${getResponseData[0].ump_value}`,
              floatValue: getResponseData[0].ump_value,
            },
            start_date:
              getResponseData[0].start_date === null
                ? getResponseData[0].start_date
                : moment(getResponseData[0].start_date),
            end_date:
              getResponseData[0].end_date === null
                ? getResponseData[0].end_date
                : moment(getResponseData[0].end_date),
          };
          this.setState({ statusEdit: true });
          this.setState({ dataVal: getResponseData[0].ump_value });
          this.setState({ modal: true }, () => {
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      });
  }

  deleteattribut(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/payump/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const { urlData, hideButton, dataVal } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.PAY_UMP')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pup_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editattribut}
                  deleteFunc={this.deleteattribut}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.PAY_UMP')}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="payattform"
                  id="payattform"
                >
                  <Container>
                    <Row>
                      <Col xs={8} md={8} lg={8} xl={8}>
                        <input type="hidden" name="pup_id" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.VALUE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="value"
                              component={renderNumberFormat}
                              other={{
                                defaultValue: dataVal,
                                thousandSeparator: '.',
                                decimalSeparator: ',',
                                maxLength: '19',
                              }}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.OFFICE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="off_id"
                              component={renderSelectField}
                              options={this.state.orgoffice}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.START_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="start_date"
                              component={renderDatePickerField}
                              placeholder="Start Date"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.END_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="end_date"
                              component={renderDatePickerField}
                              placeholder="Start Date"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {hideButton ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    form="payattform"
                    disabled={pristine}
                  >
                    {' '}
                    {t('FRM.SAVE')}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.end_date !== null) {
    if (
      typeof values.start_date === 'object' &&
      values.start_date.isAfter(values.end_date)
    ) {
      errors.end_date = 'End date shouldn’t less than Start date';
    }
  }
  if (!values.off_id) {
    errors.off_id = 'Office field shouldnt be empty';
  }
  if (!values.start_date) {
    errors.start_date = 'Start date shouldnt be empty';
  }
  if (!values.value) {
    errors.value = 'Value field shouldnt be empty';
  } else {
    if (values.value.value === '') {
      errors.value = 'Value field shouldnt be empty';
    }
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'payumpform',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Attribut));
