import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from 'react-router';
import axios from 'axios';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

// import TopbarWithNavigation from '../Layout/topbar_with_navigation/TopbarWithNavigation';
// import { CustomizerProps } from '../../shared/prop-types/ReducerProps';
import EmployeeList from '../Administration/Employee/index';
import OrgStructure from '../Administration/OrgStructure';
import AddEmployee from '../Administration/AddEmployee/indexProgressive';
import EditEmployee from '../Administration/AddEmployee/index';
import AddAdjustment from '../Administration/Adjustment/index';
import Leave from '../Administration/Leave/index';
import LeaveRequest from '../Administration/LeaveRequest/index';
import PromotionMutation from '../Administration/PromotionMutation/index';
import PtkpHistory from '../Administration/PtkpHistory/index';
import OvertimeHistory from '../Administration/OvertimeHistory/index';
import AttendanceHistory from '../Administration/AttendanceHistory/index';
import LoanPage from '../Administration/Loan/index';
import LoanHistoryPage from '../Administration/Loan/Components/loanHistory';
import ClaimHistoryPage from '../Administration/Claim/index';
import ProfilePage from '../Administration/Profile/index';

import kpiInformation from '../Performance/Kpiinformation';
import kpiPlan from '../Performance/Kpiplan';
import kpiTracking from '../Performance/Kpitracking';
import kpiEvaluation from '../Performance/Kpievaluation';
import kpiPlanDetail from '../Performance/Kpiplandetail';
import kpiTrackingDetail from '../Performance/Kpitrackingdetail';
import kpiEvaluationDetail from '../Performance/Kpievaluationdetail';
import kpiTask from '../Performance/Kpitask';

import PayrollMainMenu from '../Payroll/PayrollMainMenu/index';
import RunProcess from '../Payroll/RunProcess/index';
import RunProcessEmployee from '../Payroll/RunProcess/components/index';
import RunProcessTaxMonthly from '../Payroll/TaxCalculation/TaxMonthly/components/index';

// import RunProcessTaxEmployee from '../Payroll/TaxCalculation/TaxMonthly/components/index';
import AttendanceProcess from '../Payroll/AttendanceProcess/index';
import PayrollAttendance from '../Payroll/AttendanceProcess/TransfertoPayroll/components/index';
import TaxCalculation from '../Payroll/TaxCalculation/index';
import SettingPeriod from '../Payroll/SettingPeriod/index';
import ComponentPayroll from '../Payroll/ComponentPayroll/index';
import AttendanceMonitoring from '../Payroll/AttendanceMonitoring/index';

import OrganizationSettingPage from '../Preferences/OrganizationSetting/index';
import ProductivitySetting from '../Preferences/Productivity/index';
import CompanySettings from '../Preferences/OrganizationSetting/Company/index';
import OfficeSetting from '../Preferences/OrganizationSetting/Office/index';
import UnitOrganiztionSetting from '../Preferences/OrganizationSetting/UnitOrganization/index';
import JobLevel from '../Preferences/OrganizationSetting/JobLevel';
import PositionSetting from '../Preferences/OrganizationSetting/Position/index';
import LoaderSettings from '../Preferences/LoaderSetting/index';
import PerformanceManagement from '../Preferences/PerformanceManagement/index';
// import InformationPfm from '../Performance/Information';
import PayrollSettingPage from '../Preferences/PayrollSetting/index';
import PayrollPreferences from '../Preferences/TaxSetting/Preferences/index';
import PayrollComponent from '../Preferences/PayrollSetting/Component/index';
import AddPayrollComponent from '../Preferences/PayrollSetting/Component/AddPayrollComponent/index';
import ThrSetting from '../Preferences/PayrollSetting/THRSetting/index';

import TaxSettingPage from '../Preferences/TaxSetting/index';

import AttendanceSettingPage from '../Preferences/AttendanceSetting/index';
import ShiftSetting from '../Preferences/AttendanceSetting/Shift/index';
import GroupShiftSetting from '../Preferences/AttendanceSetting/GroupShift/index';

// import AttendSettings from '../Preferences/AttendanceSetting/AttendanceSetting/index';
import AttendanceSettings from '../Preferences/AttendanceSetting/AttendanceSetting/index';

import SettingPage from '../Preferences/Setting/index';
import UserAccountSetting from '../Preferences/Setting/UserAccount';
import NewsAndUpdates from '../Preferences/NewsAndUpdates/index';

import BenefitsPage from '../Preferences/Benefits/index';
// import UserAccountSetting from '../Preferences/Setting/UserAccount';
// import NewsAndUpdates from '../Preferences/NewsAndUpdates/index';
import WorkflowSettingPage from '../Preferences/WorkflowSetting/index';
import SecuritySettingPage from '../Preferences/SecuritySetting/index';
import Leavesubmission from '../Preferences/WorkflowSetting/Workflow/SP_File/leavesubmission';
import ApprovalPage from '../Preferences/WorkflowSetting/Workflow/Component/Approval/viewlistapproval';
import SubmissionPage from '../Preferences/WorkflowSetting/Workflow/Component/Submission/submission';
import WorkflowMonitorPage from '../Preferences/WorkflowSetting/Workflow/Monitoring';

import ReportPayrollMonthly from '../Report/Payroll/PayrollMonthly/index';
import ReportPayrollBankTransfer from '../Report/Payroll/PayrollBankTransfer/index';
import ReportPayrollPayslip from '../Report/Payroll/PayrollPaySlip/index';
import ReportTaxDetailCalc from '../Report/BpjsReport/TaxDetailCalc/index';
import ReportTax1721a1 from '../Report/BpjsReport/Tax1721A1/index';
import EssReport from '../Report/ESSreport/index';
import PerformanceReport from '../Report/PerformanceReport/index';

import ReportPayroll from '../Report/Payroll/index';
import ReportPayslip from '../Administration/Payslip/index';
import ReportAttendanceMonthly from '../Report/AttendanceReport/index';
import TaxReport from '../Report/Tax/index';
import BpjsReport from '../Report/BpjsReport/index';
import ChartReport from '../Report/ChartReport/index';
import LeaveReport from '../Report/ESSreport/LeaveReport/index';
import PromotionMutationReport from '../Report/PromotionMutation/index';
import EmpReport from '../Report/EmpReport/index';
// import PeopleDashboard from '../Report/PeopleDashboard/index';
import AttendanceSummaryReport from '../Report/ESSreport/AttendanceSummary/index';
import ReportSurvey from '../Report/SurveyReport/index';

import AllTaskListPage from '../Dashboards/AllTask/index';

import MenuManagement from '../Utilities/Menu/index';
// -----------------------------------------------------------------------------------------------------------
import Alerts from '../UI/Alerts/index';
import Buttons from '../UI/Buttons/index';
import Carousel from '../UI/Carousel/index';
import Collapse from '../UI/Collapse/index';
import Grids from '../UI/Grids';
import Modals from '../UI/Modals/index';
// import Notifications from '../UI/Notification/index';
import Panels from '../UI/Panels/index';
import ProgressBars from '../UI/ProgressBars/index';
import RangeSliders from '../UI/RangeSliders/index';
import Tabs from '../UI/Tabs/index';
import Timeline from '../UI/Timeline/index';
import Tooltips from '../UI/Tooltips/index';
import Typography from '../UI/Typography/index';

import BasicTables from '../Tables/BasicTables/index';
import DataTable from '../Tables/DataTable/index';
import EditableTable from '../Tables/EditableTable/index';
import MaterialTable from '../Tables/MaterialTable/index';

import GoogleMap from '../Maps/GoogleMap/index';
import VectorMap from '../Maps/VectorMap/index';

import NotFound404 from '../DefaultPage/404/index';
import Calendar from '../DefaultPage/Calendar/index';
import FAQ from '../DefaultPage/Faq/index';
import Gallery from '../DefaultPage/Gallery/index';
import Chat from '../Chat/index';
import PricingCards from '../DefaultPage/PricingCards/index';
import TextEditor from '../DefaultPage/TextEditor/index';
import InvoiceTemplate from '../DefaultPage/InvoiceTemplate/index';
import SearchResults from '../DefaultPage/SearchResults/index';
import ProjectSummary from '../DefaultPage/ProjectSummary/index';

import Catalog from '../ECommerce/Catalog/index';
import ProductPage from '../ECommerce/product_page/index';

import Profile from '../Account/Profile/index';
import EmailConfirmation from '../Account/EmailConfimation/index';
import ChangePassword from '../Account/ChangePassword/index';
import Membership from '../Account/Membership/index';
import Package from '../Account/Package/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import ResetPassword from '../Account/ResetPassword/index';
import LockScreen from '../Account/LockScreen/index';
import LogIn from '../Account/LogIn/index';
import LogOut from '../Account/LogOut/index';
// import LogOut from '../Account/LogOut/index';
import LogInPhoto from '../Account/log_in_photo/index';
import Register from '../Account/Register/index';
import RegisterPhoto from '../Account/RegisterPhoto/index';

// import BasicForm from '../Form/BasicForm/index';
import BasicForm from '../Form/BasicForm/standar_form';
import FormDropzone from '../Form/FormDropzone/index';
import FileUpload from '../Form/FileUpload/index';
import FormLayouts from '../Form/FormLayouts/index';
import CheckFormControls from '../Form/CheckFormControls/index';
import FormValidation from '../Form/FormValidation/index';
import MaskForm from '../Form/MaskForm/index';
import WizardForm from '../Form/WizardForm/index';
import MaterialForm from '../Form/MaterialForm/index';
import FloatingLabelsForm from '../Form/FloatingLabelsForm/index';
import FormPicker from '../Form/FormPicker/index';

import Cart from '../ECommerce/Cart/index';
import OrdersList from '../ECommerce/OrdersList/index';
import Payment from '../ECommerce/Payment/index';
import ProductEdit from '../ECommerce/ProductEdit/index';
import ProductsList from '../ECommerce/ProductsList/index';

import ChartsJS from '../Charts/ChartJs/index';
import ReactVis from '../Charts/ReactVis/index';
import Recharts from '../Charts/Recharts/index';

import FitnessDashboard from '../Dashboards/Fitness/index';
import DefaultDashboard from '../Dashboards/Default/index';
import ECommerceDashboard from '../Dashboards/ECommerce/index';
import CryptoDashboard from '../Dashboards/Crypto/index';

import Mail from '../Mail/index';

import Introduction from '../Documentation/01_introduction/index';
import Installation from '../Documentation/02_installation/index';
import FileStructure from '../Documentation/03_files_structure/index';
import Components from '../Documentation/04_components/index';
import Form from '../Documentation/05_forms/index';
import ColorThemes from '../Documentation/06_change_and_add_color_themes/index';
import NavigationItem from '../Documentation/07_new_navigation_item/index';
import Resources from '../Documentation/08_resources/index';
import Changelog from '../Documentation/09_changelog/index';

import CryptoDashboardEdit from '../Dashboards/CryptoTableEdit/index';
import ECommerceDashboardEdit from '../Dashboards/ECommerceTableEdit/index';

// Notification
import OutOfContract from '../Notifications/OutOfContract/index';

// Field Activity
import FieldActivity from '../Activity/FieldActivity';

// Field Report
import FieldReport from '../Activity/FieldReport';

// Menu
import BuildMenu from '../Menu/BuildMenu/index';
import Menu from '../Menu/AddMenu/index';

import ls from 'local-storage';
import { store } from './store';

// OKR
import ObjectivePlan from '../OKR/ObjectivePlan';

// OKR Check IN
import CheckInKeyResult from '../OKR/CheckInKeyResult';

// OKR Report
import ObjectiveReport from '../OKR/ObjectiveReport';

// OKR Structure
import ObjectiveStructure from '../OKR/ObjectiveStructure';

// OKR Detail Objective Plan
import DetailObjective from '../OKR/DetailObjective';

// Task Management
import TaskManagement from '../TaskManagement';

// Task Management Empty
import TaskManagementEmpty from '../TaskManagement/TaskManagementEmpty';
import { clearUserMenu } from '../../redux/actions/sidebarActions';

// import Landing from '../Landing/index';

const Notification = () => (
  <Switch>
    <Route path="/notifications/outofcontract" component={OutOfContract} />
  </Switch>
);

const Administration = () => (
  <Switch>
    <Route path="/administration/employee" component={EmployeeList} />
    <Route path="/administration/orgstructure" component={OrgStructure} />
    <Route path="/administration/addemployee" component={AddEmployee} />
    <Route path="/administration/editemployee" component={EditEmployee} />
    <Route path="/administration/adjustment" component={AddAdjustment} />
    <Route path="/administration/leave" component={Leave} />
    <Route path="/administration/leaverequest" component={LeaveRequest} />
    <Route
      path="/administration/promotionmutation"
      component={PromotionMutation}
    />
    <Route path="/administration/ptkphistory" component={PtkpHistory} />
    <Route path="/administration/overtimehistory" component={OvertimeHistory} />
    <Route path="/administration/atthistory" component={AttendanceHistory} />
    <Route path="/administration/loan" component={LoanPage} />
    <Route path="/administration/loanhistory" component={LoanHistoryPage} />
    <Route path="/administration/claim" component={ClaimHistoryPage} />
    <Route path="/administration/cv" component={ProfilePage} />
  </Switch>
);

const OrganizationSettings = () => (
  <Switch>
    <Route
      path="/preferences/organizationsetting"
      component={OrganizationSettingPage}
    />
    <Route
      path="/preferences/organizationsetting/company"
      component={CompanySettings}
    />
    <Route
      path="/preferences/organizationsetting/office"
      component={OfficeSetting}
    />
    <Route
      path="/preferences/organizationsetting/unitorganization"
      component={UnitOrganiztionSetting}
    />
    <Route
      path="/preferences/organizationsetting/joblevel"
      component={JobLevel}
    />
    <Route
      path="/preferences/organizationsetting/position"
      component={PositionSetting}
    />
  </Switch>
);

const PayrollSetting = () => (
  <Switch>
    <Route path="/preferences/payrollsetting" component={PayrollSettingPage} />
    <Route
      path="/preferences/payrollsetting/preferences"
      component={PayrollPreferences}
    />
    <Route
      path="/preferences/payrollsetting/components"
      component={PayrollComponent}
    />
    <Route
      path="/preferences/payrollsetting/component/addpayrollcomponent"
      component={AddPayrollComponent}
    />
    <Route
      path="/preferences/payrollsetting/thrsetting"
      component={ThrSetting}
    />
  </Switch>
);

const TaxSetting = () => (
  <Switch>
    <Route path="/preferences/taxsetting" component={TaxSettingPage} />
  </Switch>
);

const AttendanceSetting = () => (
  <Switch>
    <Route
      path="/preferences/attendancesetting"
      component={AttendanceSettingPage}
    />
    <Route
      path="/preferences/attendancesetting/shift"
      component={ShiftSetting}
    />
    <Route
      path="/preferences/attendancesetting/groupshift"
      component={GroupShiftSetting}
    />
    <Route
      path="/preferences/attendancesetting/attendsetting"
      component={AttendanceSettings}
    />
  </Switch>
);

const LoaderSetting = () => (
  <Switch>
    <Route path="/preferences/loadersetting" component={LoaderSettings} />
  </Switch>
);

const PerformanceSetting = () => (
  <Switch>
    <Route
      path="/preferences/performancemanagement"
      component={PerformanceManagement}
    />
  </Switch>
);

const Performance = () => (
  <Switch>
    <Route path="/performance/information" component={kpiInformation} />
    <Route path="/performance/kpi" component={kpiPlan} />
    <Route path="/performance/kpitracking" component={kpiTracking} />
    <Route path="/performance/kpievaluation" component={kpiEvaluation} />
    <Route path="/performance/plandetail" component={kpiPlanDetail} />
    <Route path="/performance/trackingdetail" component={kpiTrackingDetail} />
    <Route
      path="/performance/evaluationdetail"
      component={kpiEvaluationDetail}
    />
    <Route path="/performance/taskkpi" component={kpiTask} />
  </Switch>
);

const SettingPageMenu = () => (
  <Switch>
    <Route path="/preferences/setting" component={SettingPage} />
    <Route
      path="/preferences/setting/useraccount"
      component={UserAccountSetting}
    />
  </Switch>
);

const BenefitsPageMenu = () => (
  <Switch>
    <Route path="/preferences/benefits" component={BenefitsPage} />
  </Switch>
);

const NewsAndUpdatesPageMenu = () => (
  <Switch>
    <Route path="/preferences/newsandupdates" component={NewsAndUpdates} />
  </Switch>
);

const WorkflowPageMenu = () => (
  <Switch>
    <Route
      path="/preferences/workflowsetting"
      component={WorkflowSettingPage}
    />
  </Switch>
);

const SubmissionPageMenu = () => (
  <Switch>
    <Route path="/workflow/leavesubmission" component={Leavesubmission} />
    {/* <Route path="/workflow/overtimesubmission" component={Overtimesubmission} /> */}
    <Route path="/workflow/approval" component={ApprovalPage} />
    <Route path="/workflow/submission" component={SubmissionPage} />
    <Route path="/workflow/monitoring" component={WorkflowMonitorPage} />
  </Switch>
);

const FieldActivityMenu = () => {
  <Switch>
    <Route path="/fieldactivity" component={FieldActivity} />
  </Switch>;
};

const ObjectivePlanMenu = () => {
  <Switch>
    <Route path="/okr/plan" component={ObjectivePlan} />
  </Switch>;
};

const DetailObjectiveMenu = () => {
  <Switch>
    <Route path="/okr/detail-objective" component={DetailObjective} />
  </Switch>;
};

const SecurityPageMenu = () => (
  <Switch>
    <Route
      path="/preferences/securitysetting"
      component={SecuritySettingPage}
    />
  </Switch>
);

const TaskManagementMenu = () => {
  <Switch>
    <Route path="/task-management" component={TaskManagement} />
    <Route path="/task-management-empty" component={TaskManagementEmpty} />
  </Switch>;
};

const Payroll = () => (
  <Switch>
    <Route path="/payroll/payrollmainmenu" component={PayrollMainMenu} />
    <Route path="/payroll/runprocess" component={RunProcess} />
    <Route
      path="/payroll/attendancemonitoring"
      component={AttendanceMonitoring}
    />
    <Route path="/payroll/employeelist" component={RunProcessEmployee} />
    <Route path="/payroll/settingperiod" component={SettingPeriod} />
    <Route path="/payroll/componentpayroll" component={ComponentPayroll} />
    <Route path="/payroll/attendanceprocess" component={AttendanceProcess} />
    <Route path="/payroll/attendancelist" component={PayrollAttendance} />
    <Route path="/payroll/taxcalculation" component={TaxCalculation} />
    <Route path="/payroll/taxemployeelist" component={RunProcessTaxMonthly} />
  </Switch>
);

const Report = () => (
  <Switch>
    <Route
      path="/report/payroll/payrollmonthly"
      component={ReportPayrollMonthly}
    />
    {/* <Route path="/report/attendance" component={ReportAttendance} /> */}
    <Route path="/report/payroll" component={ReportPayroll} />
    <Route
      path="/report/attendance-monthly"
      component={ReportAttendanceMonthly}
    />
    <Route path="/report/tax" component={TaxReport} />
    <Route path="/report/bpjs" component={BpjsReport} />
    <Route path="/report/dashboard" component={ChartReport} />
    <Route
      path="/report/payroll/payrollbanktransfer"
      component={ReportPayrollBankTransfer}
    />
    <Route
      path="/report/payroll/payrollpayslip"
      component={ReportPayrollPayslip}
    />
    <Route
      path="/report/bpjsreport/taxdetailcalc"
      component={ReportTaxDetailCalc}
    />
    <Route path="/report/bpjsreport/tax1721a1" component={ReportTax1721a1} />
    <Route path="/report/leave" component={LeaveReport} />
    <Route
      path="/report/promotionmutationreport"
      component={PromotionMutationReport}
    />
    <Route path="/report/employeereport" component={EmpReport} />
    <Route path="/report/essreport" component={EssReport} />
    <Route path="/report/performance" component={PerformanceReport} />
    <Route
      path="/report/attendancesummary"
      component={AttendanceSummaryReport}
    />
    {/* <Route path="/report/peopledashboard" component={PeopleDashboard} /> */}
    <Route path="/report/survey" component={ReportSurvey} />
    <Route path="/report/payslip" component={ReportPayslip} />
  </Switch>
);

const UI = () => (
  <Switch>
    <Route path="/ui/alerts" component={Alerts} />
    <Route path="/ui/buttons" component={Buttons} />
    <Route path="/ui/carousel" component={Carousel} />
    <Route path="/ui/collapse" component={Collapse} />
    <Route path="/ui/grids" component={Grids} />
    <Route path="/ui/modals" component={Modals} />
    {/* <Route path="/ui/notifications" component={Notifications} /> */}
    <Route path="/ui/panels" component={Panels} />
    <Route path="/ui/progress_bars" component={ProgressBars} />
    <Route path="/ui/range_sliders" component={RangeSliders} />
    <Route path="/ui/tabs" component={Tabs} />
    <Route path="/ui/timeline" component={Timeline} />
    <Route path="/ui/tooltips" component={Tooltips} />
    <Route path="/ui/typography" component={Typography} />
  </Switch>
);

const Forms = () => (
  <Switch>
    <Route path="/forms/basic_form" component={BasicForm} />
    <Route path="/forms/check_form_controls" component={CheckFormControls} />
    <Route path="/forms/file_upload" component={FileUpload} />
    <Route path="/forms/floating_labels_form" component={FloatingLabelsForm} />
    <Route path="/forms/form_dropzone" component={FormDropzone} />
    <Route path="/forms/form_layouts" component={FormLayouts} />
    <Route path="/forms/form_picker" component={FormPicker} />
    <Route path="/forms/form_validation" component={FormValidation} />
    <Route path="/forms/mask_form" component={MaskForm} />
    <Route path="/forms/material_form" component={MaterialForm} />
    <Route path="/forms/wizard_form" component={WizardForm} />
  </Switch>
);

const Tables = () => (
  <Switch>
    <Route path="/tables/basic_tables" component={BasicTables} />
    <Route path="/tables/data_table" component={DataTable} />
    <Route path="/tables/editable_table" component={EditableTable} />
    <Route path="/tables/material_table" component={MaterialTable} />
  </Switch>
);

const Charts = () => (
  <Switch>
    <Route path="/charts/charts_js" component={ChartsJS} />
    <Route path="/charts/react_vis" component={ReactVis} />
    <Route path="/charts/recharts" component={Recharts} />
  </Switch>
);

const Maps = () => (
  <Switch>
    <Route path="/maps/google_map" component={GoogleMap} />
    <Route path="/maps/vector_map" component={VectorMap} />
  </Switch>
);

const Account = () => (
  <Switch>
    <Route path="/account/profile" component={Profile} />
    <Route path="/account/email_confirmation" component={EmailConfirmation} />
    <Route path="/account/change_password" component={ChangePassword} />
    <Route path="/account/package" component={Package} />
  </Switch>
);

const ECommerce = () => (
  <Switch>
    <Route path="/e-commerce/cart" component={Cart} />
    <Route path="/e-commerce/catalog" component={Catalog} />
    <Route path="/e-commerce/orders_list" component={OrdersList} />
    <Route path="/e-commerce/payment" component={Payment} />
    <Route path="/e-commerce/product_edit" component={ProductEdit} />
    <Route path="/e-commerce/product_page" component={ProductPage} />
    <Route path="/e-commerce/products_list" component={ProductsList} />
  </Switch>
);

const DefaultPages = () => (
  <Switch>
    <Route path="/default_pages/calendar" component={Calendar} />
    <Route path="/default_pages/faq" component={FAQ} />
    <Route path="/default_pages/gallery" component={Gallery} />
    <Route path="/default_pages/invoice_template" component={InvoiceTemplate} />
    <Route path="/default_pages/pricing_cards" component={PricingCards} />
    <Route path="/default_pages/project_summary" component={ProjectSummary} />
    <Route path="/default_pages/search_results" component={SearchResults} />
    <Route path="/default_pages/text_editor" component={TextEditor} />
  </Switch>
);

const Documentation = () => (
  <Switch>
    <Route path="/documentation/introduction" component={Introduction} />
    <Route path="/documentation/installation" component={Installation} />
    <Route path="/documentation/file_structure" component={FileStructure} />
    <Route path="/documentation/components" component={Components} />
    <Route path="/documentation/form" component={Form} />
    <Route path="/documentation/color_themes" component={ColorThemes} />
    <Route path="/documentation/navigation_item" component={NavigationItem} />
    <Route path="/documentation/resources" component={Resources} />
    <Route path="/documentation/changelog" component={Changelog} />
  </Switch>
);

const Crypto = () => (
  <Switch>
    <Route exact path="/dashboard" component={CryptoDashboard} />
    <Route path="/dashboard/edit/:index" component={CryptoDashboardEdit} />
  </Switch>
);

const Commerce = () => (
  <Switch>
    <Route exact path="/dashboard_e_commerce" component={ECommerceDashboard} />
    <Route
      path="/dashboard_e_commerce/edit/:index"
      component={ECommerceDashboardEdit}
    />
  </Switch>
);

const wrappedNotification = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/notifications" component={Notification} />
    </div>
  </div>
);

const wrappedAdministrator = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/administration" component={Administration} />
    </div>
  </div>
);

const wrappedPerformance = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/performance" component={Performance} />
    </div>
  </div>
);

const wrappedPreference = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route
        path="/preferences/organizationsetting"
        component={OrganizationSettings}
      />
      <Route path="/preferences/payrollsetting" component={PayrollSetting} />
      <Route path="/preferences/taxsetting" component={TaxSetting} />
      <Route
        path="/preferences/attendancesetting"
        component={AttendanceSetting}
      />
      <Route path="/preferences/setting" component={SettingPageMenu} />
      <Route path="/preferences/workflowsetting" component={WorkflowPageMenu} />
      <Route path="/preferences/securitysetting" component={SecurityPageMenu} />
      <Route path="/preferences/loadersetting" component={LoaderSetting} />
      <Route
        path="/preferences/performancemanagement"
        component={PerformanceSetting}
      />
      <Route
        path="/preferences/newsandupdates"
        component={NewsAndUpdatesPageMenu}
      />
      <Route path="/preferences/benefits" component={BenefitsPageMenu} />
      <Route path="/preferences/productivity" component={ProductivitySetting} />
    </div>
  </div>
);

const wrappedSubmission = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/workflow" component={SubmissionPageMenu} />
    </div>
  </div>
);

const wrappedPayroll = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/payroll" component={Payroll} />
    </div>
  </div>
);

const wrappedReport = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/report" component={Report} />
    </div>
  </div>
);

const wrappedAllTask = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/alltask_list" component={AllTaskListPage} />
    </div>
  </div>
);

const wrappedMenuManagement = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/menu_management" component={MenuManagement} />
    </div>
  </div>
);

const wrappedDashboardDefault = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard_default" component={DefaultDashboard} />
    </div>
  </div>
);

const wrappedDashboardECommerce = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard_e_commerce" component={Commerce} />
    </div>
  </div>
);

const wrappedDashboardFitness = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard_fitness" component={FitnessDashboard} />
    </div>
  </div>
);

const wrappedDashboard = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={Crypto} />
    </div>
  </div>
);

const wrappedFieldActivity = () => {
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/fieldactivity" component={FieldActivity} />
    </div>
  </div>;
};
const wrappedUi = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/ui" component={UI} />
    </div>
  </div>
);

const wrappedMail = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/mail" component={Mail} />
    </div>
  </div>
);

const wrappedChat = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/chat" component={Chat} />
    </div>
  </div>
);

const wrappedForms = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/forms" component={Forms} />
    </div>
  </div>
);

const wrappedTables = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/tables" component={Tables} />
    </div>
  </div>
);

const wrappedCharts = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/charts" component={Charts} />
    </div>
  </div>
);

const wrappedMaps = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/maps" component={Maps} />
    </div>
  </div>
);

const wrappedAccount = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/account" component={Account} />
    </div>
  </div>
);

const wrappedEcommerce = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/e-commerce" component={ECommerce} />
    </div>
  </div>
);

const wrappedDefaultPages = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/default_pages" component={DefaultPages} />
    </div>
  </div>
);

const wrappedDocumentation = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/documentation" component={Documentation} />
    </div>
  </div>
);

const wrappedMenu = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/menu/build_menu" component={BuildMenu} />
      <Route path="/menu/menu_setting" component={Menu} />
    </div>
  </div>
);

const backToDashboard = () => <Redirect to="/dashboard" />;

function logout() {
  ls.clear();
  store.dispatch(clearUserMenu());
  store.dispatch({ type: 'CLEAR_USER' });
  store.dispatch({ type: 'SET_USER_RELOGIN', data: false });
  window.location.href = '/logout';
}

function AppRoute() {
  const handleOnIdle = () => store.dispatch({ type: 'SET_USER_RELOGIN', data: true });
  const updateSession = () => {
    const { user } = store.getState();
    if (user.isLocked) return false;
    if (!ls.get('token')) logout();

    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/token/sessionCheck`, '', config)
      .then((response) => {
        if (response.data.status === 'idle') {
          handleOnIdle();
        }
      });
  };

  if (ls.get('token')) {
    updateSession();

    if (ls.get('membership')) {
      setInterval(() => {
        updateSession();
      }, 60000);

      return (
        <Switch>
          <Route path="/payroll" component={wrappedPayroll} />
          <Route path="/report" component={wrappedReport} />
          <Route path="/workflow" component={wrappedSubmission} />
          <Route
            path="/dashboard_default"
            component={wrappedDashboardDefault}
          />
          <Route
            path="/dashboard_e_commerce"
            component={wrappedDashboardECommerce}
          />
          <Route
            path="/dashboard_fitness"
            component={wrappedDashboardFitness}
          />
          <Route path="/dashboard" component={wrappedDashboard} />
          <Route path="/alltask_list" component={wrappedAllTask} />
          <Route path="/menu_management" component={wrappedMenuManagement} />
          <Route path="/ui" component={wrappedUi} />
          <Route path="/mail" component={wrappedMail} />
          <Route path="/chat" component={wrappedChat} />
          <Route path="/forms" component={wrappedForms} />
          <Route path="/tables" component={wrappedTables} />
          <Route path="/charts" component={wrappedCharts} />
          <Route path="/maps" component={wrappedMaps} />
          <Route path="/account" component={wrappedAccount} />
          <Route path="/e-commerce" component={wrappedEcommerce} />
          <Route path="/default_pages" component={wrappedDefaultPages} />
          <Route path="/documentation" component={wrappedDocumentation} />
          <Route path="/preferences" component={wrappedPreference} />
          <Route path="/administration" component={wrappedAdministrator} />
          <Route path="/notifications" component={wrappedNotification} />
          <Route path="/performance" component={wrappedPerformance} />
          <Route path="/fieldactivity" component={FieldActivity} />
          <Route path="/fieldreport" component={FieldReport} />
          <Route path="/okr/plan" component={ObjectivePlan} />
          <Route path="/okr/detail-objective" component={DetailObjective} />
          <Route path="/task-management" component={TaskManagement} />
          <Route
            path="/task-management-empty"
            component={TaskManagementEmpty}
          />
          <Route path="/okr/checkin" component={CheckInKeyResult} />
          <Route path="/okr/report" component={ObjectiveReport} />
          <Route path="/okr/structure" component={ObjectiveStructure} />
          <Route path="/404" component={NotFound404} />
          <Route path="/lock_screen" component={LockScreen} />
          <Route path="/log_in_photo" component={LogInPhoto} />
          <Route path="/register" component={Register} />
          <Route path="/register_photo" component={RegisterPhoto} />
          <Route path="/logout" component={LogOut} />
          <Route path="/demo/upload" component={FileUpload} />
          <Route path="/menu" component={wrappedMenu} />
          <Route path="*" exact component={backToDashboard} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route path="/membership" component={Membership} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/reset_password/:token" component={ResetPassword} />
        <Route path="/" component={LogIn} />
      </Switch>
    );
  } else {
    if (!['/logout','/', '/forgot_password'].includes(window.location.pathname)) updateSession();
  }
  return (
    <Switch>
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/reset_password/:token" component={ResetPassword} />
      <Route path="/" component={LogIn} />
    </Switch>
  );
}

const RouterApp = () => {
  const { user } = store.getState();

  if (user?.isLocked) logout();

  return (
    <MainWrapper>
      <>
        <LockScreen>
          <AppRoute />
        </LockScreen>
      </>
    </MainWrapper>
  );
};

export default RouterApp;
