import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/fontawesome-free-solid';
import Scrollbar from 'react-smooth-scrollbar';

const AlertNotifTab = () => (
  <Scrollbar className="taskAlertPanel-scroll">
    <div className="taskAlertPanel">
      <ul className="taskTab-list">
        <li className="notif-panel">
          <span className="alert-date"><i><FontAwesomeIcon icon={faClock} /></i> 11/11/2018 11:00</span>
          <h4 className="title-alert">Delegation</h4>
          <p className="desc-alert">
            Budi give delegation to you to approve task while he on leave in 11/11/2018 until 12/10/2018, ...
          </p>
        </li>
        <li className="notif-panel">
          <span className="alert-date"><i><FontAwesomeIcon icon={faClock} /></i> 11/11/2018 11:00</span>
          <h4 className="title-alert">End Contract Notification</h4>
          <p className="desc-alert">
            Budiantos contract will expire on 10/10/2018, please review
          </p>
        </li>
        <li className="notif-panel">
          <span className="alert-date"><i><FontAwesomeIcon icon={faClock} /></i> 11/11/2018 11:00</span>
          <h4 className="title-alert">End Contract Notification</h4>
          <p className="desc-alert">
            Budiantos contract will expire on 10/10/2018, please review
          </p>
        </li>
        <li className="notif-panel">
          <span className="alert-date"><i><FontAwesomeIcon icon={faClock} /></i> 11/11/2018 11:00</span>
          <h4 className="title-alert">End Contract Notification</h4>
          <p className="desc-alert">
            Budiantos contract will expire on 10/10/2018, please review
          </p>
        </li>
        <li className="notif-panel">
          <span className="alert-date"><i><FontAwesomeIcon icon={faClock} /></i> 11/11/2018 11:00</span>
          <h4 className="title-alert">End Contract Notification</h4>
          <p className="desc-alert">
            Budiantos contract will expire on 10/10/2018, please review
          </p>
        </li>
      </ul>
    </div>
  </Scrollbar>
);

export default AlertNotifTab;
