/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from 'mdi-react';
import {
  Container,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from 'reactstrap';
import ws from '../../../../shared/components/config/config';
import ls from 'local-storage';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { store } from '../../../App/store';
import utils from '../../../../utils';
import { connect } from 'react-redux';

const selectedMenu = { backgroundColor: 'rgba(0, 0, 0, .1)' };

class TopbarNav extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      current_menu: '',
      tree_menu: [],
      lang: null,
      fga_id: ls.get('function_access')[0].fga_id ?? '',
    };
    this.state.current_menu = window.location.pathname.split('/')[1];
    const { t } = this.props;
    this.state.lang = t;
    this.getMenu.bind(this);
  }

  componentWillMount() {
    this.initMenuData();
  }

  componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    if (this.state.lang !== t) {
      this.setState({
        lang: t,
      });
    }
  }

  /**
   * get menu data for current user
   */
  initMenuData = () => {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let idUser = ls.get('user_cred').usr_id;

    if (idUser === 0) {
      axios
        .post(`${ws.ws.ip}/menu/getMenuSysId/`, '', config)
        .then((response) => {
          idUser = response.data.data[0].usr_id;
          fetch(`${ws.ws.ip}/menu/getTree/${idUser}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              Authorization: `bearer ${ls.get('token')}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'search=',
          })
            .then((res) => res.json())
            .then((result) => {
              if (!utils.deepEqual(this.state.tree_menu, result.data)) {
                store.dispatch({ type: 'SET_MENU_TREE', data: result.data });
                ls.set('function_access', result.function_access);
                this.setState({
                  fga_id: response.data.data[0].fga_id,
                  // tree_menu: result.data,
                });
              }
            });
        });
    } else {
      fetch(`${ws.ws.ip}/menu/getTree/${idUser}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'search=',
      })
        .then((res) => res.json())
        .then((result) => {
          if (result?.idle) return false;
          if (!utils.deepEqual(this.state.tree_menu, result.data)) {
            store.dispatch({ type: 'SET_MENU_TREE', data: result.data });
            ls.set('function_access', result.function_access);
            // this.setState({
            //   tree_menu: result.data,
            // });
          }
        });
    }
  };

  setLocalStorage(men_id) {
    ls.set('men_id', men_id);
    ls.set('fga_id', this.state.fga_id);
  }

  // eslint-disable-next-line class-methods-use-this
  setcss(menu) {
    // this.setState({
    //   current_menu: menu,
    // });
  }

  getMenu = (menu, dropdownItem, icon, selectedStyle, index) => (
    <UncontrolledDropdown
      key={`menuDropDown_${index}`}
      style={selectedStyle}
      className="topbar__nav-dropdown"
    >
      <DropdownToggle className="topbar__nav-dropdown-toggle">
        {menu} <ChevronDownIcon />
      </DropdownToggle>
      <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
        {dropdownItem}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  createMenu = (arrayMenu, parent) => {
    const { t } = this.props;
    const result = [];
    for (let i = 0; i < arrayMenu.length; i += 1) {
      const isActiveMenu =
        arrayMenu[i].menu.toLowerCase() ===
          window.location.pathname.split('/')[1] ||
        (window.location.pathname.split('/')[1] === 'dashboard' &&
          arrayMenu[i].menu === 'Home');
      const setStyle = isActiveMenu ? selectedMenu : {};
      if (arrayMenu[i].submenu.length > 0 && arrayMenu[i].level < 2) {
        result.push(
          this.getMenu(
            this.state.lang(arrayMenu[i].lbl_code),
            this.createMenu(arrayMenu[i].submenu, 1),
            arrayMenu[i].icon,
            setStyle,
            i,
          ),
        );
      } else {
        if (parent === 0) {
          result.push(
            <Link
              key={`menu_${arrayMenu[i].id}`}
              style={setStyle}
              className="topbar__nav-link"
              to={{
                pathname: arrayMenu[i].to,
                state: { men_id: arrayMenu[i].id, fga_id: this.state.fga_id },
              }}
            >
              {this.state.lang(arrayMenu[i].lbl_code)}
            </Link>,
          );
        }
        if (parent === 1) {
          result.push(
            <>
              <DropdownItem
                key={`subMenu_${arrayMenu[i].id}`}
                className="topbar__link"
                tag={Link}
                to={{
                  pathname: arrayMenu[i].to,
                  state: { men_id: arrayMenu[i].id, fga_id: this.state.fga_id },
                }}
                onClick={() => this.setLocalStorage(arrayMenu[i].id)}
                onContextMenu={() => this.setLocalStorage(arrayMenu[i].id)}
              >
                {this.state.lang(arrayMenu[i].lbl_code)}
              </DropdownItem>
            </>,
          );
        }
      }
    }
    return result;
  };

  render() {
    return (
      <nav className="topbar__nav">
        <Container className="container-nav">
          {this.createMenu(this.props.sidebar.menuTree, 0)}
        </Container>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
});

export default translate('global')(connect(mapStateToProps)(TopbarNav));
