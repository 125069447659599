/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable operator-assignment */
/* eslint-disable space-infix-ops */
/* eslint-disable camelcase */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Container, Col, Row, Card, CardBody, Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import ls from 'local-storage';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

class PayrollEmployeeList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NO'),
      t('LBL.CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.POSITION'),
      t('FRM.FUNCTION'),
    ];

    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '20%', '30%', '20%', '28%'],
      urlData: [],
      modal: false,
      empCode: '',
      empName: '',
      unitOrganization: '',
      office: '',
      jobName: '',
      positionName: '',
      jobBand: '',
      jobGrade: '',
      jobLevel: '',
      joinDate: '',
      employeementStatus: '',
      bank: '',
      bankAccount: '',
      bankAccountHolder: '',
      arrAllowance: [],
      arrDeducution: [],
      takeHomePay: '',
      totalAllowance: '',
      totalDeduction: '',
      redirectUrl: false,
      pypId: '',
    };
    this.toggle = this.toggle.bind(this);
    this.detailRows = this.detailRows.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getTableData(); });
    this.setState({ urlData: `${ls.get('ws_ip')}/paysalary/getAllEmpByPeriod/` });
    this.setState({ pypId: this.props.location.state.pypId });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NO'),
      t('LBL.CODE'),
      t('LBL.EMPLOYEE'),
      t('LBL.POSITION'),
      t('FRM.FUNCTION'),
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  detailRows(id) {
    this.toggle();
    const { apiws, token } = this.state;
    const { t } = this.props;
    // const pypId = this.props.location.state.pypId;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/payemployeecurrent/getAllItemById/${id}`,
      `pyp_id=${this.props.location.state.pypId}`,
      config,
    ).then((res) => {
      const dataResponse = res.data.data[0];
      // alert(JSON.stringify(dataResponse));
      this.setState({
        empCode: dataResponse.emp_code,
        empName: dataResponse.emp_name,
        unitOrganization: dataResponse.unit_organization,
        office: dataResponse.office,
        employeementStatus: dataResponse.employeement_status,
        bank: dataResponse.bank,
        bankAccount: dataResponse.bank_account,
        bankAccountHolder: dataResponse.bank_account_holder,
        positionName: dataResponse.position_name,
        jobName: dataResponse.job_name,
        jobBand: dataResponse.job_band,
        jobGrade: dataResponse.job_grade,
        jobLevel: dataResponse.job_level,
        joinDate: moment(dataResponse.join_date).format('DD/MM/YYYY'),
      });
    });
    axios.post(
      `${apiws}/paysalary/getAllComponentById/${id}`,
      `pyp_id=${this.props.location.state.pypId}`,
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      const arrayA = [];
      const arrayD = [];
      let val = 0; // Total
      let valA = 0; // Alowance
      let valB = 0; // Deduction
      for (let i = 0; i < dataResponse.length; i++) {
        // const prefix = (parseInt(dataResponse[i].pes_value, 0) === 0) ? '':'Rp ';
        if (dataResponse[i].pyt_id === 1) {
          arrayA.push(
            <tr>
              <td style={{ width: '50%' }}>{dataResponse[i].component}</td>
              <td style={{ width: '1%' }}>:</td>
              <td style={{ width: '1%' }}> Rp </td>
              <td style={{ width: '40%', textAlign: 'right' }}> <NumberFormat value={Math.round(dataResponse[i].pes_value)} displayType={'text'} thousandSeparator="." decimalSeparator="," renderText={value => <div>{value} </div>} /> </td>
            </tr>,
          );
          val = Math.round(dataResponse[i].pes_value) + Math.round(val); // parseInt(dataResponse[i].pes_value, 0) + parseInt(val, 0);
          valA = Math.round(dataResponse[i].pes_value) + Math.round(valA); // parseInt(dataResponse[i].pes_value, 0) + parseInt(valA, 0);
        } else if (dataResponse[i].pyt_id === 2) {
          arrayD.push(
            <tr>
              <td style={{ width: '65%' }}>{dataResponse[i].component}</td>
              <td style={{ width: '1%' }}>:</td>
              <td style={{ width: '1%' }}> Rp </td>
              <td style={{ width: '40%', textAlign: 'right' }}> <NumberFormat value={Math.round(dataResponse[i].pes_value)} displayType={'text'} thousandSeparator="." decimalSeparator="," renderText={value => <div>{value} </div>} /></td>
            </tr>,
          );
          val = Math.round(val) - Math.round(dataResponse[i].pes_value); // parseInt(val, 0) - parseInt(dataResponse[i].pes_value, 0);
          valB = Math.round(dataResponse[i].pes_value) + Math.round(valB); // parseInt(dataResponse[i].pes_value, 0) + parseInt(valB, 0);
        }
      }
      arrayA.push(<tr><td style={{ width: '60%' }}><b>{t('LBL.TOTAL')} {t('LBL.ALLOWANCE')}</b></td><td style={{ width: '1%' }}>:</td><td style={{ width: '1%' }}> Rp </td><td style={{ width: '40%', textAlign: 'right' }}><NumberFormat value={parseInt(valA, 0)} displayType={'text'} thousandSeparator="." decimalSeparator="," renderText={value => <b><div>{value} </div></b>} /></td></tr>);
      arrayD.push(<tr><td style={{ width: '60%' }}><b>{t('LBL.TOTAL')} {t('LBL.DEDUCTION')}</b></td><td style={{ width: '1%' }}>:</td><td style={{ width: '1%' }}> Rp </td><td style={{ width: '40%', textAlign: 'right' }}><NumberFormat value={parseInt(valB, 0)} displayType={'text'} thousandSeparator="." decimalSeparator="," renderText={value => <b><div>{value} </div></b>} /></td></tr>);
      this.setState({
        arrAllowance: arrayA,
        arrDeducution: arrayD,
        // totalAllowance: ),
        // totalDeduction: parseInt(valB).toFixed(2),
        takeHomePay: parseInt(val, 0),
      });
    });
  }

  render() {
    const { urlData, redirectUrl } = this.state;
    const { t } = this.props;
    const addBtn = '';
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn_table_navbar"
        onClick={() => this.setState({ redirectUrl: true })}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '900px',
      marginTop: '95px',
      textAlign: 'center',
    };
    if (redirectUrl) {
      return (<Redirect to={{
        pathname: '/payroll/runprocess',
            state: { men_id: this.props.location.state.men_employee, fga_id: this.props.location.state.fga_id },
        }}
      />);
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ 'paddingBottom': '10px' }}>
                  <h3 className="page-title">{t('LBL.PAYROLL')} - {t('MEN.LIST_PAYROLL_PROCESS')}</h3>
                  <h3 className="page-subhead subhead">{t('MEN.EMPLOYEE_LIST')} {t('FRM.FROM')} {t('LBL.PROCESS_PAYROLL')}</h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="emp_id"
                  conds={this.state.pypId}
                  widthTable={this.state.dataWidth}
                  detailFunc={this.detailRows}
                  buttonAction="detailOnly"
                  onRef={ref => (this.child = ref)}
                  displayStyleHead="none"
                />
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className="modal-dialog modal-input"
                  style={modalStyle}
                >
                  <ModalHeader toggle={this.toggle}>{t('LBL.SALARY_INFORMATION')}</ModalHeader>
                  <ModalBody>
                    <Container>
                      <Card>
                        <CardBody style={{ padding: '5px 5px 5px 5px' }}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th colSpan="4">{t('LBL.EMPLOYEE_INFORMATION')}</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{t('LBL.EMPLOYEE_CODE')}</td>
                                  <td>: {this.state.empCode}</td>
                                  <td>{t('LBL.OFFICE')}</td>
                                  <td>: {this.state.office} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.EMPLOYEE')}</td>
                                  <td>: {this.state.empName}</td>
                                  <td>{t('LBL.UNIT_ORGANIZATION')}</td>
                                  <td>: {this.state.unitOrganization}</td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.STATUS')}</td>
                                  <td>: {this.state.employeementStatus} </td>
                                  <td>{t('LBL.JOB_NAME')}</td>
                                  <td>: {this.state.jobName} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.JOIN_DATE')}</td>
                                  <td>: {this.state.joinDate}</td>
                                  <td>{t('LBL.POSITION')}</td>
                                  <td>: {this.state.positionName}</td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.BANK')}</td>
                                  <td>: {this.state.bank} </td>
                                  <td>{t('LBL.JOB_LEVEL')}</td>
                                  <td>: {this.state.jobLevel} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.BANK_ACCOUNT')}</td>
                                  <td>: {this.state.bankAccount} </td>
                                  <td>{t('LBL.JOB_BAND')}</td>
                                  <td>: {this.state.jobBand} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.ACCOUNT_HOLDER')}</td>
                                  <td>: {this.state.bankAccountHolder} </td>
                                  <td>{t('LBL.JOB_GRADE')}</td>
                                  <td>: {this.state.jobGrade} </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody style={{ padding: '5px 5px 5px 5px' }}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th colSpan="3">{t('LBL.COMPONENT_PAYROLL')}</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ width: '50%', verticalAlign: 'top' }}>
                                    <table
                                      id="myTable"
                                      className="table data-grid-tbl responsive no-wrap"
                                      style={{ verticalAlign: 'top' }}
                                    >
                                      {this.state.arrAllowance}
                                    </table>
                                  </td>
                                  <td style={{ width: '50%', verticalAlign: 'top' }}>
                                    <table
                                      id="myTable"
                                      className="table data-grid-tbl responsive no-wrap"
                                      style={{ verticalAlign: 'top' }}
                                    >
                                      {this.state.arrDeducution}
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th style={{ width: '50%', textAlign: 'right' }}>{t('LBL.TAKE_HOME_PAY')}</th>
                                <th style={{ width: '5%' }}>:</th>
                                <th style={{ textAlign: 'left', fontSize: '14px' }}>
                                  <NumberFormat value={this.state.takeHomePay} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} renderText={value => <div>{value} </div>} />
                                </th>
                              </thead>
                            </table>
                          </Col>
                        </CardBody>
                      </Card>
                    </Container>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(PayrollEmployeeList));
