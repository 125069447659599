/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign, camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len, jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faSuitcase,
  faChartBar,
  faWallet,
  faDesktop,
} from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import conf from '../../../../shared/components/config/config';

class SelfServicePanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      loaded: 0,
      subdomain: conf.subdomain,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        apiws: ls.get('ws_ip'),
        token: ls.get('token'),
      },
        this.setState((state) => ({ loaded: 1 }))
    );
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ fga_id: ls.get('function_access')[0].fga_id });
  };



  render() {
    const { t } = this.props;
    const leaveStr = t('MEN.LEAVE').length;
    const overtimeStr = t('MEN.OVERTIME').length;
    const attendanceStr = t('MEN.ATTENDANCE_DISPENSATION').length;
    const claimStr = t('MEN.CLAIM').length;
    const payslipStr = t('LBL.PAYSLIP').length;
    const performanceStr = t('MEN.PERFORMANCE').length;
    const pfm_allow = ['dev', 'devhotfix'];
    const showpfm = pfm_allow.includes(`${this.state.subdomain}`)
      ? true
      : false;

    return (
      <div className="self-service-panel">
        <div className="title-panel">{t('MEN.SELF_SERVICE')}:</div>
        <div className="link-panel">
          <Link to={{ pathname: '/workflow/monitoring' }}>
            <div id="monitoring" className="link-self-service monitor-btn">
              <FontAwesomeIcon icon={faDesktop} />
            </div>
            <UncontrolledTooltip placement="bottom" target="monitoring">
              {t('LBL.MONITORING_SELF_SERVICE')}
            </UncontrolledTooltip>
          </Link>
          <Link
            to={{
              pathname: '/workflow/submission',
              state: {
                sp_file: '/leavesubmission',
              },
            }}
          >
            <div id="leave" className="link-self-service">
              <FontAwesomeIcon icon={faSuitcase} />
              {t('MEN.LEAVE')}
            </div>
            {leaveStr > 8 && (
              <UncontrolledTooltip placement="bottom" target="leave">
                {t('MEN.LEAVE')}
              </UncontrolledTooltip>
            )}
          </Link>
          <Link
            to={{
              pathname: '/workflow/submission',
              state: {
                sp_file: '/overtimesubmission',
              },
            }}
          >
            <div id="overtime" className="link-self-service">
              <FontAwesomeIcon icon={faBusinessTime} />
              {t('MEN.OVERTIME')}
            </div>
            {overtimeStr > 8 && (
              <UncontrolledTooltip placement="bottom" target="overtime">
                {t('MEN.OVERTIME')}
              </UncontrolledTooltip>
            )}
          </Link>
          <Link
            to={{
              pathname: '/workflow/submission',
              state: {
                sp_file: '/attendancesubmission',
              },
            }}
          >
            <div id="attendance" className="link-self-service">
              <FontAwesomeIcon icon={faClock} />
              {t('MEN.ATTENDANCE_DISPENSATION')}
            </div>
            {attendanceStr > 8 && (
              <UncontrolledTooltip placement="bottom" target="attendance">
                {t('MEN.ATTENDANCE_DISPENSATION')}
              </UncontrolledTooltip>
            )}
          </Link>
          <Link
            to={{
              pathname: '/workflow/submission',
              state: {
                sp_file: '/claimsubmission',
              },
            }}
          >
            <div id="claim" className="link-self-service">
              <FontAwesomeIcon icon={faWallet} />
              {t('MEN.CLAIM')}
            </div>
            {claimStr > 8 && (
              <UncontrolledTooltip placement="bottom" target="claim">
                {t('MEN.CLAIM')}
              </UncontrolledTooltip>
            )}
          </Link>
          <Link
            to={{
              pathname: '/report/payslip',
              state: {
                sp_file: '/claimsubmission',
                dt_workflow: this.state.wfl_claim,
                dt_checklist: this.state.wfl_checklistatt,
              },
            }}
          >
            <div id="payslip" className="link-self-service">
              <FontAwesomeIcon icon={faWallet} />
              {t('LBL.PAYSLIP')}
            </div>
            {payslipStr > 8 && (
              <UncontrolledTooltip placement="bottom" target="payslip">
                {t('LBL.PAYSLIP')}
              </UncontrolledTooltip>
            )}
          </Link>
          {/* {showpfm && (
            <Link to="/performance/information">
              <div id="performance" className="link-self-service">
                <FontAwesomeIcon icon={faChartBar} />
                {t('MEN.PERFORMANCE')}
              </div>
              {performanceStr > 8 && (
                <UncontrolledTooltip placement="bottom" target="performance">
                  {t('MEN.PERFORMANCE')}
                </UncontrolledTooltip>
              )}
            </Link>
          )} */}
          {/* <Link to="/fieldactivity">
            <div id="activity" className="link-self-service">
              <img
                src="/img/loc-act-icon.svg"
                alt="loc action"
                style={{
                  width: '13px',
                  height: '13px',
                  marginRight: '5px',
                  marginBottom: '3px',
                }}
              />
              Field Activity
            </div>
          </Link> */}

          {/* <ButtonToolbar>
              <UncontrolledDropdown>
                <DropdownToggle className="icon icon--right">
                  <p>More <ChevronDownIcon /></p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem>
                    <Link to={{
                      pathname: '/workflow/submission',
                      state: {
                        sp_file: '/claimsubmission',
                        dt_workflow: this.state.wfl_claim,
                        dt_checklist: this.state.wfl_checklistatt,
                      },
                    }}
                    >
                      {t('LBL.CLAIM')}
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonToolbar> */}
        </div>
      </div>
    );
  }
}

export default translate('global')(SelfServicePanel);
