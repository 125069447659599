export function connectWs(wsUrl, onMessage) {
  let socket = new WebSocket(wsUrl);
  let socketTimeout = null;

  socket.onopen = function(e) {
    // console.log({open: e});
    // console.info('[open] Connection established');
  };

  socket.onmessage = function(event) {
    // console.log(event);
    onMessage(event);
  };

  socket.onclose = function(event) {
    if (event.wasClean) {
      // console.info(
      //   `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
      // );
    } else {
      // console.info('[close] Connection died');
      if (socketTimeout) clearTimeout(socketTimeout);
      socketTimeout = setTimeout(() => {
        connectWs(wsUrl, onMessage);
      }, 3000);
    }
  };

  socket.onerror = function(error) {
    // console.info(`[error] ${error.message}`);
    socket.close();
  };

  return { socket, socketTimeout };
}
