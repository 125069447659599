/* eslint-disable no-return-assign, no-undef */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus, array-callback-return */
/* eslint-disable no-lonely-if, brace-style */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars, no-prototype-builtins */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase, prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props, no-restricted-syntax */
/* eslint-disable react/no-did-mount-set-state */

import axios from 'axios';
import ls from 'local-storage';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { reduxForm, reset } from 'redux-form';
import showNotifications from '../../notificationMessages';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faPencilAlt,
  faTimes,
} from '@fortawesome/fontawesome-free-solid';
import SweetAlert from 'react-bootstrap-sweetalert';
import Addkpi from './addkpi';

function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.pvm_id] = arrElem;
    mappedArr[arrElem.pvm_id].children = [];
  }
  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      if (mappedElem.pvm_ref === '0' || mappedElem.pvm_ref === 0) {
        tree.push({
          id: mappedElem.pvm_id,
          title: mappedElem.pvm_name,
          level: mappedElem.pvm_level,
          expanded: true,
          children: mappedElem.children,
        });
      } else {
        if (mappedElem.children.length === 0) {
          mappedArr[mappedElem.pvm_ref].children.push({
            id: mappedElem.pvm_id,
            title: mappedElem.pvm_name,
            level: mappedElem.pvm_level,
            expanded: true,
            children: mappedElem.children,
          });
        } else {
          mappedArr[mappedElem.pvm_ref].children.push({
            id: mappedElem.pvm_id,
            title: mappedElem.pvm_name,
            level: mappedElem.pvm_level,
            expanded: true,
            children: mappedElem.children,
          });
        }
      }
    }
  }
  return tree;
}

class KPI extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      iduser: '',
      token: '',
      dataTree: '',
      modal: false,
      pvm_id: '',
      ppr_id: '',
      pso_id: '',
      addStrategicOjb: '',
      deleteConfirm: false,
      statusEdit: false,
    };
    const { t } = props;
    this.getButton = this.getButton.bind(this);
    this.getKpi = this.getKpi.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
    this.loadaftersubmit = this.loadaftersubmit.bind(this);
    this.deleteMode = this.deleteMode.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getButton();
      this.getKpi();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  };

  componentDidUpdate = () => {
    this.getButton();
    this.getKpi();
  };

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getKpi() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/pfmkpi/getAlltree`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const data = unplet(res.data.data);
          setTimeout(() => {
            this.setState({ dataTree: data });
          }, 500);
        }
      });
  }

  addMode(id, edit) {
    this.setState({ statusEdit: false });
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .get(`${ls.get('ws_ip')}/pfmkpi/getAllitemByIdkpi/${id}`, config)
      .then((res) => {
        if (res.data.status === 'ok') {
          this.setState({
            ppr_id: res.data.data[0].ppr_id,
            pso_id: res.data.data[0].pso_id,
          });
          if (edit) {
            this.setState({ statusEdit: true });
            this.setState({ pvm_id: id });
          }
          setTimeout(() => {
            this.setState({ modal: true });
          }, 100);
        }
      });
  }

  deleteMode(id) {
    this.setState({
      deleteConfirm: true,
      deleteId: id,
    });
  }

  deleteItem() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .delete(
        `${ls.get('ws_ip')}/pfmkpi/${this.state.deleteId}/${this.state.iduser}`,
        config,
        `duser_id=${this.state.iduser}`,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          this.setState({ deleteConfirm: false });
          showNotifications('Success', 'Delete Success', 'success');
          setTimeout(() => {
            this.getKpi();
          }, 100);
        } else {
          if (res.data.status === 'obj') {
            this.setState({ deleteConfirm: false });
            showNotifications(
              'Fail',
              'this KPI is still using by user',
              'danger',
            );
            setTimeout(() => {
              this.getKpi();
            }, 100);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }
      });
  }

  cancelButton() {
    this.setState({ modal: false });
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
    console.log('a');
  };

  loadaftersubmit(result, dispatch, props) {
    setTimeout(() => {
      this.getKpi();
    }, 100);
    dispatch(reset('kpiform'));
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <Card
              className="treeMenu_card"
              style={{ padding: '0px 15px 0px 15px' }}
            >
              <CardBody style={{ height: '500px' }}>
                <SweetAlert
                  warning
                  show={this.state.deleteConfirm}
                  showCancel
                  confirmBtnText="Yes, delete it!"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="default"
                  title="Are you sure?"
                  onConfirm={() => this.deleteItem()}
                  onCancel={() => this.setState({ deleteConfirm: false })}
                >
                  You will delete this item !
                </SweetAlert>
                <SortableTree
                  treeData={this.state.dataTree}
                  rowHeight={50}
                  generateNodeProps={(rowInfo) => ({
                    buttons: [
                      <div>
                        {rowInfo.node.level === 3 && (
                          <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                              verticalAlign: 'middle',
                              padding: '0px 8px',
                              marginBottom: '0px',
                              marginRight: '5px',
                              paddingRight: '3px',
                            }}
                            onClick={() => this.addMode(rowInfo.node.id, false)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        )}
                        {rowInfo.node.level > 3 && (
                          <button
                            className="btn btn-outline-success btn-sm"
                            style={{
                              verticalAlign: 'middle',
                              padding: '0px 8px',
                              marginBottom: '0px',
                              marginRight: '5px',
                              paddingRight: '3px',
                            }}
                            onClick={() => this.addMode(rowInfo.node.id, true)}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        )}
                        {rowInfo.node.level > 3 && (
                          <button
                            className="btn btn-outline-danger btn-sm"
                            style={{
                              verticalAlign: 'middle',
                              padding: '0px 8px',
                              marginBottom: '0px',
                              marginRight: '5px',
                              paddingRight: '3px',
                            }}
                            onClick={() => this.deleteMode(rowInfo.node.id)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        )}
                      </div>,
                    ],
                  })}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '1050px' }}
        >
          <Addkpi
            pvm_id={this.state.pvm_id}
            ppr_id={this.state.ppr_id}
            pso_id={this.state.pso_id}
            addStrategicOjb={this.state.addStrategicOjb}
            statusEdit={this.state.statusEdit}
            cancel={this.cancelButton}
            onSubmitSuccess={this.loadaftersubmit}
            toggle={this.toggle}
          />
        </Modal>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'kpi',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(KPI));
