const initialState = {
    company: [],
    office: [],
    geofence: [],
    organization: [],
    jobBand: [],
    attShift: [],
    blood: [],
    religion: [],
    maritalStatus: [],
    country: [],
    payComponentGroup : [],
    familyRelation: [],
    taxStatus: [],
    bank: [],
    payProrate: [],
    identityType: [],
    payComponentGroupRel: [],
    payTaxMethod: [],
    payTaxClass: [],
    itemRecurring: [],
    jobLevel: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "LOAD_GET_COMPANY":
            return { ...state, company: action.data };
        case "LOAD_GET_OFFICE":
            return { ...state, office: action.data };
        case "LOAD_GET_GEOFENCE":
            return { ...state, geofence: action.data };
        case "LOAD_GET_ORGANIZATION":
            return { ...state, organization: action.data };
        case "LOAD_GET_JOB_BAND":
            return { ...state, jobBand: action.data };
        case "LOAD_GET_ATT_SHIFT":
            return { ...state, attShift: action.data };
        case "LOAD_GET_BLOOD":
            return { ...state, blood: action.data };
        case "LOAD_GET_RELIGION":
            return { ...state, religion: action.data };
        case "LOAD_GET_MARITAL_STATUS":
            return { ...state, maritalStatus: action.data };
        case "LOAD_GET_COUNTRY":
            return { ...state, country: action.data };
        case "LOAD_GET_PAY_COMPONENT_GROUP":
            return { ...state, payComponentGroup: action.data };
        case "LOAD_GET_FAMILY_RELATION":
            return { ...state, familyRelation: action.data };
        case "LOAD_GET_TAX_STATUS":
            return { ...state, taxStatus: action.data };
        case "LOAD_GET_BANK":
            return { ...state, bank: action.data };
        case "LOAD_GET_PAY_PRORATE":
            return { ...state, payProrate: action.data };
        case "LOAD_GET_INDENTITY_TYPE":
            return { ...state, identityType: action.data };
        case "LOAD_GET_PAY_COMPONENT_GROUP_REL":
            return { ...state, payComponentGroupRel: action.data };
        case "LOAD_GET_PAY_TAX_METHOD":
            return { ...state, payTaxMethod: action.data };
        case "LOAD_GET_PAY_TAX_CLASS":
            return { ...state, payTaxClass: action.data };
        case "LOAD_GET_ITEM_RECURRING":
            return { ...state, itemRecurring: action.data };
        case "LOAD_GET_JOB_LEVEL":
            return { ...state, jobLevel: action.data };
        default:
            return state;
    }
}