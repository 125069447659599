/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable react/no-did-mount-set-state */
/* eslint no-lonely-if: "error" */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  ButtonToolbar,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faTrashAlt,
  faPencilAlt,
  faTimes,
  faSearch,
  faRedoAlt,
  faBook,
  faAsterisk,
  faArrowsAltV,
} from '@fortawesome/fontawesome-free-solid';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../notificationMessages';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../../Tables/DataTable/assets/css/style.css';
import { Link } from 'react-router-dom';

class WorkflowSetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.WORKFLOW')} ${t('LBL.NAME')}`,
      //  t('LBL.DOCUMENT'),
      t('LBL.ACTIVITY'),
      t('LBL.SEQUENCE'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      officeData: [],
      office: '',
      offid: '',
      address: '',
      city: '',
      province: '',
      postal_code: '',
      token: '',
      modal: false,
      modal_doc: false,
      button_disabled: false,
      status_edit: false,
      iduser: '',
      tax_person_name: '',
      tax_person_npwp: '',
      titleModal: '',
      titleModalDoc: '',
      buttontext: 'Save',
      buttoncolor: 'primary',
      buttontype: 'submit',
      buttonclicked: '',
      disabled_form: '',
      buttondisabled: 'disabled',
      apiws: '',
      prvarr: [],
      ctarr: [],
      sbdarr: [],
      vlgarr: [],
      provinces: '',
      cities: '',
      passworddisabled: '',
      rows: [],
      rowDocs: [],
      limit: 10, // default is 5 rows
      offset: 0,
      page: 1,
      disable_preview: '',
      disable_next: '',
      modal_show: false,
      search: '',
      total: 0,
      total_pagination: 0,
      current_pagination: 0,
      label_idx_start: 0,
      label_idx_end: 0,
      spanerror: 'none',
      pass: '',
      repass: '',
      statususer: '',
      disabledreset: 'none',
      savedata: [],
      disabledbutton: true,
      checked: false,
      wfl_doc: '',
    };
    this.reload = this.reload.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.changePage = this.changePage.bind(this);
    this.search = this.search.bind(this);
    this.createButtonPagination = this.createButtonPagination.bind(this);
    this.goto = this.goto.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleDocument = this.toggleDocument.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitDocument = this.handleSubmitDocument.bind(this);
    this.changeallowSave = this.changeallowSave.bind(this);
  }

  componentWillMount() {
    this.timer = null;
    this.next();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') }); // , () => { this.next(); }
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.getButton();
  };

  // menerima jika ada props dari manapun yang di update
  // akan diproses disini
  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.WORKFLOW'),
      // t('LBL.DOCUMENT'),
      t('LBL.ACTIVITY'),
      t('LBL.SEQUENCE'),
      t('FRM.FUNCTION'),
    ];
    this.reload();
    this.getButton();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  paginationNumber() {
    const total_pagination = this.state.total / this.state.limit;
    this.setState({
      total_pagination: Math.ceil(total_pagination),
    });

    const page_start = Number.parseInt(this.state.offset, 10) + 1;
    const page_end =
      Number.parseInt(this.state.limit, 10) +
      Number.parseInt(this.state.offset, 10);
    this.setState({
      label_idx_start: page_start,
      label_idx_end: page_end > this.state.total ? this.state.total : page_end,
    });
  }

  // eslint-disable-next-line react/sort-comp
  changePage(event) {
    this.setState(
      {
        limit: event.target.value,
      },
      () => {
        setTimeout(() => {
          this.reload();
        }, 50);
      },
    );
  }

  search(event) {
    this.setState({ search: event.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  previous() {
    let state_offset = this.state.offset - this.state.limit;
    this.setState({ modal_show: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ls.get('ws_ip')}/workflow/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              code: response.data.data[i].wfl_id,
              name: response.data.data[i].workflow,
              total_activity: response.data.data[i].total_activity,
              total_order: response.data.data[i].total_order,
              total_document: response.data.data[i].total_document,
            });
          }

          // simpan state limit offset
          nextState.setState({
            rows,
            total: response.data.total,
            page: this.state.page - 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: response.data.total > no_index ? '' : 'disabled',
          });

          this.paginationNumber();
        } else {
          // sembunyikan modal loading data jika data kosong
          this.setState({ modal_show: false });
        }
      })
      .catch(() => {
        // sembunyikan modal loading data
        this.setState({ modal_show: false });
        // console.log(error);
      });
  }

  getStatus(x) {
    let y = '';
    if (x === '1') {
      y = 'Active';
    } else if (x === '2') {
      y = 'Non-Active';
    } else if (x === '3') {
      y = 'Block';
    }
    return y;
  }

  reload() {
    // eslint-disable-next-line no-underscore-dangle
    const reloadState = this;
    // eslint-disable-next-line no-underscore-dangle
    let state_offset = this.state.offset;
    this.setState({ modal_show: true });
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ls.get('ws_ip')}/workflow/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        this.setState({ modal_show: false });
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              code: response.data.data[i].wfl_id,
              name: response.data.data[i].workflow,
              total_activity: response.data.data[i].total_activity,
              total_order: response.data.data[i].total_order,
              total_document: response.data.data[i].total_document,
            });
          }
          reloadState.setState({
            rows,
            total: response.data.total,
            disable_next: no_index >= response.data.total ? 'disabled' : '',
            page: 1,
            total_pagination: 0,
            current_pagination: 0,
          });

          this.paginationNumber();
        }
      })
      .catch(() => {
        this.setState({ modal_show: false });
        // console.log(error);
      });
  }

  next() {
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (this.state.page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios
      .post(
        `${ls.get('ws_ip')}/workflow/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              code: response.data.data[i].wfl_id,
              name: response.data.data[i].workflow,
              total_activity: response.data.data[i].total_activity,
              total_order: response.data.data[i].total_order,
              total_document: response.data.data[i].total_document,
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: this.state.page + 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch(() => {
        this.setState({ modal_show: false });
        // console.log(error);
      });
  }

  goto(e) {
    e.preventDefault();
    const page = parseInt(e.target.innerHTML, 10);
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios
      .post(
        `${ls.get('ws_ip')}/workflow/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              code: response.data.data[i].wfl_id,
              name: response.data.data[i].workflow,
              total_activity: response.data.data[i].total_activity,
              total_order: response.data.data[i].total_order,
              total_document: response.data.data[i].total_document,
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: page + 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch(() => {
        this.setState({ modal_show: false });
        // console.log(error);
      });
  }

  createButtonPagination() {
    const total_button = 5;
    const child_pagination = [];
    const state_page = this.state.page - 1;
    // set minimum index
    if (
      this.state.current_pagination < 1 ||
      state_page >= this.state.current_pagination
    ) {
      this.setState({
        current_pagination: state_page + total_button,
      });
    }
    // set maximum index
    if (state_page < this.state.current_pagination - total_button) {
      this.setState({
        current_pagination: this.state.current_pagination - total_button,
      });
    }

    for (let i = 1; i <= this.state.total_pagination; i++) {
      let selected = '';
      if (state_page === i) {
        selected = 'active';
      }
      if (
        i >= this.state.current_pagination - total_button - 1 &&
        i <= this.state.current_pagination
      ) {
        child_pagination.push(
          <li className={`page-item ${selected}`}>
            <a onClick={this.goto} className="page-link" href="#">
              {i}
            </a>
          </li>,
        );
      }
    }
    return child_pagination;
  }

  getUser() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/getAllItemCustom/10/0`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          dataResponse.sort((a, b) => a.off_id - b.off_id);
          const ofcList = [];
          for (let i = 0; i < dataResponse.length; i += 1) {
            no_index += 1;
            ofcList.push({
              no: no_index,
              username: response.data.data[i].user_name,
              realname: response.data.data[i].realname,
              status: this.getStatus(response.data.data[i].status),
            });
          }
          this.setState({ officeData: ofcList });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  toggle(id) {
    const { t } = this.props;
    if (id === 'add') {
      this.props.destroy();
      this.setState({ titleModal: `${t('FRM.ADD')} ${t('LBL.WORKFLOW')}` });
      this.setState({ status_edit: false });
      this.setState({ passworddisabled: '' });
      this.setState({ savedata: [] });
      this.setState({ disabledbutton: true });
    } else {
      this.setState({ passworddisabled: 'none' });
      this.setState({ status_edit: true });
      this.setState({ titleModal: `${t('FRM.EDIT')} ${t('LBL.WORKFLOW')}` });
      this.setState({ disabledbutton: true });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleDocument(x) {
    const { t } = this.props;
    this.setState({ rowDocs: [] });
    this.setState({ wfl_doc: x });
    const rowsD = [];
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/workflow/getAllItembyId/${x}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          if (res.data.data.length > 0) {
            this.setState({
              titleModalDoc: `${t('FRM.ADD')} ${t('LBL.DOCUMENT')} ${t(
                'LBL.WORKFLOW',
              )} ${res.data.data[0].workflow}`,
            });
          }
        }
      });
    axios
      .post(`${ls.get('ws_ip')}/wflworkflowdoc/getAllItemDoc/${x}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.data.length > 0) {
            let no_index = 0;
            for (let i = 0; i < response.data.data.length; i += 1) {
              no_index += 1;
              if (response.data.data[i].val === '1') {
                response.data.data[i].val = true;
              } else if (response.data.data[i].val === '0') {
                response.data.data[i].val = false;
              }
              rowsD.push({
                no: no_index,
                id: response.data.data[i].dct_id,
                doc: response.data.data[i].document,
                val: response.data.data[i].val,
              });
            }
            this.setState({ rowDocs: rowsD });
          }
        }
      });
    this.setState({
      modalDoc: !this.state.modalDoc,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  // eslint-disable-next-line class-methods-use-this
  setValueForm(id, text) {
    document.getElementById(id).value = text;
  }

  // eslint-disable-next-line class-methods-use-this
  setForm(id) {
    document.getElementById(id).reset();
  }

  updateValues(e, a) {
    const { status_edit, savedata } = this.state;
    if (status_edit === true) {
      if (typeof a === 'undefined') {
        if (e.target.name === 'user_name') {
          usrnm = e.target.value;
          this.setState({ disabledbutton: false });
        } else if (e.target.name === 'first_name') {
          firstnm = e.target.value;
          this.setState({ disabledbutton: false });
        } else if (e.target.name === 'last_name') {
          lastnm = e.target.value;
          this.setState({ disabledbutton: false });
        } else if (e.target.name === 'email') {
          mail = e.target.value;
          this.setState({ disabledbutton: false });
        } else if (e.target.name === 'remark') {
          remak = e.target.value;
          this.setState({ disabledbutton: false });
        }
      } else if (a === 'status') {
        sts = e.value;
        this.setState({ disabledbutton: false });
      } else if (a === 'fua_id') {
        fuaid = e.value;
        this.setState({ disabledbutton: false });
      } else if (a === 'wia_id') {
        wiaid = e.value;
        this.setState({ disabledbutton: false });
      }
    }
    if (status_edit === false) {
      if (e.target.name === 'user_name') {
        if (e.target.value === '') {
          this.setState({ disabledbutton: true });
        } else {
          this.setState({ disabledbutton: false });
        }
      }
    }
  }

  mapFormData(b, a) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (b === null) {
          val_b = b.value;
        } else {
          val_b = null;
        }
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  handleSubmit(values) {
    const { apiws, token, iduser } = this.state;
    let add;
    if (!values.wfl_id) {
      add = 0;
    } else {
      add = 1;
    }
    const formBody = `workflow=${values.workflow}&description=${values.description}&wfl_code=${values.wfl_code}&limit_request=${values.limit_request}`;
    const cuserBody = `cuser_id=${iduser}`;
    const muserBody = `muser_id=${iduser}`;
    let Body;
    if (!values.wfl_id) {
      Body = [formBody, cuserBody].join('&');
    } else {
      Body = [formBody, muserBody].join('&');
    }
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (add === 0) {
      axios
        .post(`${apiws}/workflow/saveItem`, Body, config)
        .then((resinput) => {
          if (resinput.data.status === 'ok') {
            this.toggle();
            this.reload();
            this.props.destroy();
            showNotifications('Success', 'Save Success', 'success');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    } else {
      axios
        .post(`${apiws}/workflow/updateItem/${values.wfl_id}`, Body, config)
        .then((resupdate) => {
          if (resupdate.data.status === 'ok') {
            this.toggle();
            this.reload();
            this.props.destroy();
            showNotifications('Success', 'Updated Success', 'success');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  async handleSubmitDocument(values) {
    const { apiws, token, iduser, wfl_doc } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let fcheck;
    await axios
      .post(`${apiws}/wflworkflowdoc/getAllItemId/${wfl_doc}`, '', config)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i += 1) {
            axios.post(
              `${apiws}/wflworkflowdoc/deleteItem/${res.data.data[i].wfd_id}`,
              `duser_id=${iduser}`,
              config,
            );
            if (i + 1 === res.data.data.length) {
              fcheck = 1;
            }
          }
        } else {
          fcheck = 1;
        }
      })
      .catch(() => {
        // console.log(error);
        fcheck = 1;
      });
    if (fcheck === 1) {
      if (values.dct.length > 0) {
        for (let i = 0; i < values.dct.length; i += 1) {
          // let x;
          if (typeof values.dct[i] === 'boolean' && values.dct[i] === true) {
            axios
              .post(
                `${apiws}/wflworkflowdoc/saveItem`,
                `wfl_id=${wfl_doc}&dct_id=${i}&cuser_id=${iduser}`,
                config,
              )
              .then((resinput) => {
                if (resinput.data.status === 'ok') {
                  this.setState({ modalDoc: false });
                  this.props.destroy();
                  this.reload();
                }
              })
              .catch(() => {
                // console.log(error);
              });
          }
          if (i + 1 === values.dct.length) {
            showNotifications('Success', 'Save Success', 'success');
          }
        }
      }
    }
  }

  editRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/getAllItembyid/${id}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        this.toggle();
        this.setState({
          status_edit: true,
        });
        let descript = dataResponse[0].description;
        if (descript === 'undefined') {
          descript = '';
        }
        this.props.initialize({
          wfl_code: dataResponse[0].workflow_code,
          workflow: dataResponse[0].workflow,
          description: descript,
          limit_request: dataResponse[0].limit_request,
          wfl_id: dataResponse[0].wfl_id,
        });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteRows(id) {
    const { apiws, token, iduser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/workflow/deleteItem/${id}`,
            `duser_id=${iduser}`,
            config,
          )
          .then(() => {
            this.props.destroy();
            this.reload();
            showNotifications('Success', 'Delete Success', 'success');
          })
          .catch(() => {
            // console.log(error);
            this.setState({ button_disabled: false });
          });
      }
    });
  }
  changeallowSave(e) {
    if (e.target.value.length === 0) {
      this.setState({ buttondisabled: 'disabled' });
    } else {
      this.setState({ buttondisabled: '' });
    }
  }
  render() {
    const {
      status_edit,
      titleModal,
      titleModalDoc,
      checked,
      editcond,
      deletecond,
      addcond,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    const modalStyle = {
      maxWidth: '500px',
      textAlign: 'center',
    };
    const fontStyle = {
      color: '#fff',
    };
    let button_action;
    let field_wflid;
    let button_color;
    if (status_edit) {
      field_wflid = (
        <Field name="wfl_id" component={renderTextInput} type="hidden" />
      );
      button_color = 'success';
      button_action = t('FRM.EDIT');
    } else {
      button_color = 'primary';
      button_action = t('FRM.SAVE');
    }
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding: '20px 10px 30px 10px' }}>
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <input
                        value={this.state.search}
                        onChange={this.search}
                        type="text"
                        className="form-control"
                        placeholder={t('LBL.SEARCHING_BY_KEYWORDS')}
                        style={{ 'font-size': '0.8rem !important' }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Button
                      color="primary"
                      className="btn_table_navbar"
                      onClick={() => this.toggle('add')}
                      style={{
                        float: 'right',
                        'margin-bottom': '20px',
                        display: addcond === '1' ? '' : 'none',
                      }}
                    >
                      {t('FRM.ADD')} {t('LBL.WORKFLOW')}
                    </Button>
                  </div>
                </div>
                <table
                  id="myTable"
                  className="table data-grid-tbl responsive no-wrap"
                >
                  <thead>
                    {this.heads.map((data) => (
                      <th>{data}</th>
                    ))}
                  </thead>
                  <tbody>
                    {this.state.rows.map((data) => (
                      <tr>
                        <td style={{ width: '8%' }}>{data.no}</td>
                        <td style={{ width: '30%' }}>{data.name}</td>
                        {/* <td style={{ width: '15%' }}>
                          <Link
                            to="#"
                            // type="button"
                            onClick={() => this.toggleDocument(data.code)}
                          > <FontAwesomeIcon icon={faBook} /> {t('LBL.DESCRIPTION')} [ {data.total_document} ]
                          </Link>
                        </td> */}
                        <td style={{ width: '20%' }}>
                          <Link
                            to="#"
                            // type="button"
                            onClick={() =>
                              this.props.onparam(data.code, 'activity')
                            }
                          >
                            {' '}
                            <FontAwesomeIcon icon={faAsterisk} />{' '}
                            {t('LBL.ACTIVITY')} [ {data.total_activity} ]
                          </Link>
                        </td>
                        <td style={{ width: '20%' }}>
                          <Link
                            to="#"
                            // type="button"
                            onClick={() =>
                              this.props.onparam(data.code, 'order')
                            }
                          >
                            {' '}
                            <FontAwesomeIcon icon={faArrowsAltV} />{' '}
                            {t('LBL.SEQUENCE')}[ {data.total_order} ]
                          </Link>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => this.editRows(data.code)}
                            className="btn btn-sm btn-outline-primary btn-data-table"
                            style={{ display: editcond === '1' ? '' : 'none' }}
                          >
                            {' '}
                            <i className="fas fa-pencil-alt" />
                          </button>
                          <button
                            type="button"
                            onClick={() => this.deleteRows(data.code)}
                            className="btn btn-sm btn-outline-danger btn-data-table"
                            style={{
                              display: deletecond === '1' ? '' : 'none',
                            }}
                          >
                            {' '}
                            <i className="fas fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-12">
                    <div className="paging-panel-bottom">
                      <div className="row">
                        <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li
                                className={`page-item ${this.state.disable_preview}`}
                              >
                                <a
                                  onClick={this.previous}
                                  className="disabled page-link"
                                  href="#"
                                  tabIndex="-1"
                                  aria-disabled="true"
                                >
                                  {' '}
                                  {t('FRM.PREVIOUS')}{' '}
                                </a>
                              </li>
                              {this.createButtonPagination()}
                              <li
                                className={`page-item ${this.state.disable_next}`}
                              >
                                <a
                                  onClick={this.next}
                                  className="page-link"
                                  href="#"
                                >
                                  {t('FRM.NEXT')}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-md-6 ">
                          <form className="form-inline float-right">
                            <div className="form-group mb-2">
                              <label className="mr-3">
                                {t('FRM.PER_PAGE')}
                              </label>
                              <select
                                onChange={this.changePage}
                                className="form-control"
                              >
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                              </select>
                            </div>
                            <div className="form-group mx-sm-3 mb-2">
                              <label>
                                {t('FRM.SHOWING')} {this.state.label_idx_start}{' '}
                                {t('FRM.TO')} {this.state.label_idx_end}{' '}
                                {t('FRM.OF')} {this.state.total}
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={modalStyle}
        >
          <ModalHeader toggle={this.toggle}> {titleModal} </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="workflowform"
              id="workflowform"
            >
              {field_wflid}
              <Container>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.WORKFLOW')} {t('LBL.NAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="workflow"
                          id="workflow"
                          component={renderTextInput}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.DESCRIPTION')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          id="description"
                          component={renderTextInput}
                          type="textarea"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.LIMIT')} {t('LBL.SUBMISSION')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="limit_request"
                          id="limit_request"
                          component={renderTextInput}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button
              color={button_color}
              form="workflowform"
              type="submit"
              disabled={pristine}
            >
              {' '}
              {button_action}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalDoc}
          toggle={this.toggleDocument}
          className="modal-dialog modal-input"
          style={modalStyle}
        >
          <ModalHeader toggle={this.toggleDocument}>
            {' '}
            {titleModalDoc}{' '}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmitDocument)}
              name="wfldocumentform"
              id="wfldocumentform"
            >
              <Container>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <table
                      id="myTable"
                      className="table data-grid-tbl responsive no-wrap"
                    >
                      <tbody>
                        {this.state.rowDocs.map((data) => (
                          <tr>
                            <td style={{ height: '40px' }}>
                              <Field
                                name={`dct[${data.id}]`}
                                id={data.id}
                                component={renderCheckBoxField}
                                defaultChecked={data.val}
                              />
                            </td>
                            <td style={{ width: '2%' }}>{data.no}.</td>
                            <td>{data.doc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleDocument}>
              Cancel
            </Button>
            <Button
              color={button_color}
              form="wfldocumentform"
              type="submit"
              disabled={pristine}
            >
              {' '}
              {button_action}
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.workflow) {
    errors.workflow = 'Workflow field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'workflowforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(WorkflowSetting));
