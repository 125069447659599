/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import { Field, reduxForm } from 'redux-form';
import Swal from 'sweetalert2';
import showNotifications from '../../../notificationMessages';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import parse from 'html-react-parser';

class WorkflowMonitoring extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.userHeads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.EMPLOYEE') },
      { '2': t('LBL.POSITION') },
      { '3': t('LBL.REQUEST_DATE') },
      { '4': t('LBL.DETAIL') },
      { '5': t('LBL.STATUS') },
    ];
    this.adminHeads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.EMPLOYEE') },
      { '2': t('LBL.POSITION') },
      { '3': t('LBL.REQUEST_DATE') },
      { '4': t('LBL.DETAIL') },
      { '5': t('LBL.STATUS') },
      { '6': t('FRM.FUNCTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      userDataWidth: ['5%', '15%', '20%', '10%', '25%', '25%'],
      adminDataWidth: ['5%', '15%', '20%', '10%', '15%', '20%', '15%'],
      isAdmin: false,
      reason: '',
      action: null,
      approvalData: {},
    };

    this.getUserLevel = this.getUserLevel.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getButton = this.getButton.bind(this);
    this.checkApprove = this.checkApprove.bind(this);
    this.checkReject = this.checkReject.bind(this);
    this.approveData = this.approveData.bind(this);
    this.rejectData = this.rejectData.bind(this);
  }

  componentWillMount() {
    this.setState(
      {
        token: ls.get('token'),
        apiws: ls.get('ws_ip'),
        idUser: ls.get('user_cred').usr_id,
        emp_id: ls.get('user_cred').emp_id,
        men_id: ls.get('men_id'),
        fga_id: ls.get('fga_id'),
        urlData: `${ls.get('ws_ip')}/workflow/getMonitoringData`,
      },
      () => {
        this.getUserLevel();
        this.getTableData();
        this.getButton();
      },
    );
    this.props.destroy();
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.userHeads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.EMPLOYEE') },
      { '2': t('LBL.POSITION') },
      { '3': t('LBL.REQUEST_DATE') },
      { '4': t('LBL.DETAIL') },
      { '5': t('LBL.STATUS') },
    ];
    this.adminHeads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.EMPLOYEE') },
      { '2': t('LBL.POSITION') },
      { '3': t('LBL.REQUEST_DATE') },
      { '4': t('LBL.DETAIL') },
      { '5': t('LBL.STATUS') },
      { '6': t('FRM.FUNCTION') },
    ];
  }

  getUserLevel() {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/appuser/getAllItemById/${idUser}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (
            response.data.data[0].is_all_emp_access === '0' ||
            response.data.data[0].is_all_emp_access === 0
          ) {
            this.setState({ isAdmin: true });
            this.getTableData();
          }
        }
      })
      .catch((error) => {
        // showNotifications('Fail', 'Delete Failed', 'danger');
      });
  }

  getTableData() {
    this.child.reload();
  }

  toggle = () => {
    this.props.destroy();
    this.setState({ modal: !this.state.modal, reason: '' });
  };

  getButton() {
    const men = this.state.men_id;
    const fga = this.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  checkApprove(id) {
    this.setState({ reason: '' });
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/adminApproval/${id}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          if (res.data.data === 'Finish') {
            Swal.fire({
              title: 'Info',
              html: `Request already closed. (${res.data.message[0].opc_status})<br/>${res.data.message[0].remarks}`,
              type: 'info',
              showConfirmButton: false,
              timer: 1800,
            });
          } else {
            const response = res.data.data;
            this.setState({
              action: 1,
              modal: true,
              approvalData: {
                wfl_id: response[0].wfl_id,
                wfa_id: response[0].wfa_id,
                sp_file: response[0].sp_file,
                emp_req: response[0].emp_id,
                wfo_id: id,
                action: 1,
                cuser_id: idUser,
              },
            });
          }
        } else {
          showNotifications('Fail', 'Approve Failed', 'danger');
        }
      })
      .catch((error) => {
        showNotifications('Fail', 'Approve Failed', 'danger');
      });
  }

  approveData() {
    const { token, apiws, approvalData, reason } = this.state;
    const data = {
      ...approvalData,
      desc: reason,
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will approve this workflow !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, approve it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const url = `${apiws}/workflow/submitapproval`;
        const formBody = Object.keys(data)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
          )
          .join('&');
        const config = {
          headers: {
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        axios
          .post(url, formBody, config)
          .then((response) => {
            this.setState({ reason: '', modal: false, approvalData: {} });
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Approve Success', 'success');
              this.props.destroy();
              setTimeout(() => {
                this.getTableData();
              }, 200);
            } else {
              this.props.destroy();
              showNotifications('Fail', 'Approve Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
          });
      }
    });
  }

  checkReject(id) {
    this.setState({ reason: '' });
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/workflow/adminApproval/${id}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          if (res.data.data === 'Finish') {
            Swal.fire({
              title: 'Info',
              html: `Request already closed. (${res.data.message[0].opc_status})<br/>${res.data.message[0].remarks}`,
              type: 'info',
              showConfirmButton: false,
              timer: 1800,
            });
          } else {
            const response = res.data.data;
            this.setState({
              action: 0,
              reason: '',
              modal: true,
              approvalData: {
                wfl_id: response[0].wfl_id,
                wfa_id: response[0].wfa_id,
                sp_file: response[0].sp_file,
                emp_req: response[0].emp_id,
                wfo_id: id,
                approver: ls.get('user_cred').emp_id,
                action: 0,
                desc: this.state.reason,
                cuser_id: idUser,
              },
            });
          }
        } else {
          showNotifications('Fail', 'Reject Failed', 'danger');
        }
      })
      .catch((error) => {
        showNotifications('Fail', 'Reject Failed', 'danger');
      });
  }

  rejectData() {
    const { token, apiws, approvalData, reason } = this.state;
    const data = {
      ...approvalData,
      desc: reason,
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will reject this workflow !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, reject it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const url = `${apiws}/workflow/submitapproval`;
        const formBody = Object.keys(data)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
          )
          .join('&');
        const config = {
          headers: {
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        axios
          .post(url, formBody, config)
          .then((response) => {
            this.setState({ reason: '', modal: false, approvalData: {} });
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Reject Success', 'success');
              setTimeout(() => {
                this.getTableData();
              }, 200);
            } else {
              showNotifications('Fail', 'Reject Failed', 'danger');
            }
          })
          .catch(() => {
            // console.log(error);
          });
      }
    });
  }

  render() {
    const { t } = this.props;
    const { urlData, isAdmin, emp_id } = this.state;
    return (
      <Container>
        <Row className="m0" style={{ padding: '0px' }}>
          <Col md={12} style={{ padding: '0px' }}>
            <div className="header-page-panel mt-0">
              {/* <img className="header-icon" src={HeaderIcon} alt="attendance" /> */}
              <h3 className="page-title">{t('LBL.REQUEST_MONITORING')}</h3>
              <h3 className="page-subhead subhead">
                {t('LBL.WORKFLOW_REQUEST_MONITORING')}
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                {isAdmin === true ? (
                  <ViewListTable
                    url={`${urlData}/0`}
                    heads={this.adminHeads}
                    buttonAction={['approve', 'reject']}
                    primaryKey="wfo_id"
                    widthTable={this.state.adminDataWidth}
                    approveFunc={this.checkApprove}
                    rejectFunc={this.checkReject}
                    onRef={(ref) => (this.child = ref)}
                    workflowFilter
                    yearFilter
                    monthFilter
                    essMonitoringFilter
                  />
                ) : (
                  <ViewListTable
                    url={`${urlData}/${emp_id}`}
                    heads={this.userHeads}
                    buttonAction={[]}
                    primaryKey="wfo_id"
                    widthTable={this.state.userDataWidth}
                    onRef={(ref) => (this.child = ref)}
                    workflowFilter
                    yearFilter
                    monthFilter
                    essMonitoringFilter
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalBody>
            <div className="form form--horizontal">
              <Container>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.DESCRIPTION')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          id="description"
                          component={renderTextInput}
                          type="textarea"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({ reason: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                if (this.state.action === 1) {
                  this.approveData();
                } else {
                  this.rejectData();
                }
              }}
            >
              {' '}
              {t('FRM.SUBMIT')}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'monitoringworkflow',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  // validate,
})(translate('global')(WorkflowMonitoring));
