/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Card, Button, CardBody,
  CardTitle, Col, Row,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // UncontrolledDropdown,
} from 'reactstrap';
// import { faBars } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
// import ViewListTable from '../../../../../../shared/components/table/viewListTable';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};

class EmpStatus extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
    pfp_id: PropTypes.string.isRequired,
    org_id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.AGE_RANGE') },
      { 2: t('LBL.TOTAL') },
      { 3: t('LBL.PERCENTAGE') },
    ];
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      data01: [],
      dataWidth: ['5%', '40%', '25%'],
      pfp_id: '',
      org_id: '',
    };
    this.getPercentageOrg = this.getPercentageOrg.bind(this);
    // this.getTableData = this.getTableData.bind(this);
  }
  componentDidMount = () => {
    // alert(this.props.datan);
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
    }, () => {
      this.getPercentageOrg();
      // this.getTableData();
    });
    // this.setState({ urlData: `${ls.get('ws_ip')}/pfmkpiplan/getalldivisionpercentage/` });
  }

  componentWillReceiveProps(nextprops) {
    console.log(nextprops.pfp_id);
    this.setState({ pfp_id: nextprops.pfp_id });
    this.setState({ org_id: nextprops.org_id });
    setTimeout(() => {
      this.getPercentageOrg();
    }, 200);
  }


  getPercentageOrg() {
    const { token, apiws } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const data = { pfp_id: this.props.pfp_id, org_id: this.props.org_id };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      `${apiws}/Pfmkpiplan/getalldivisionpercentage`,
      formBody,
      config,
    ).then((response) => {
      const labels = [];
      const datas = [];
      const colour = [];
      for (let i = 0; i < response.data.data.length; i += 1) {
        colour.push(response.data.data[i].fill);
        labels.push(response.data.data[i].name);
        datas.push(response.data.data[i].value);
      }
      const initialState = {
        labels,
        datasets: [
          {
            label: 'Division',
            backgroundColor: colour,
            // borderColor: '#ff0066',
            borderWidth: 1,
            hoverBackgroundColor: colour,
            // hoverBorderColor: '#cc0052',
            data: datas,
          },
        ],
      };
      this.setState({ data: initialState, pfp_id: this.props.pfp_id, org_id: this.props.org_id });
    }).catch(() => {
      // console.log(error);
    });
  }

  render() {
    const { t } = this.props;
    const backBtn = (
      <Button
        color="primary"
        size="sm"
        outline
        // eslint-disable-next-line max-len
        onClick={() => { this.props.getmenu(false, true, this.props.pfp_id, this.props.org_id); }}
        style={{ marginBottom: '0px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.DETAIL')} Achievement All division
              </div>
              <div className="w-100" style={{ textAlign: 'right' }}>
                {backBtn}
              </div>
            </div>
          </CardTitle>
          <Row>
            <Col md={9} lg={9} xl={9}>
              <div className="chart-body">
                <Bar data={this.state.data} options={options} />
              </div>
            </Col>
            {/* <Col md={12} lg={6} xl={6}>
              <ViewListTable
                url={this.state.urlData}
                heads={this.heads}
                primaryKey="fill"
                widthTable={this.state.dataWidth}
                displayStyleHead="none"
                onRef={ref => (this.child = ref)}
                buttonAction="noButton"
                searching="none"
              />
            </Col> */}
          </Row>
        </CardBody>
      </Card>
    );
  }
}


export default (translate('global')(EmpStatus));
