/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import { Container, Row, Col, ButtonToolbar, Button, CardBody, Card } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

class PayslipPasswordPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;

    this.months = [
      { value: '01', label: `${t('LBL.JANUARY')}`, short: 'Jan' },
      { value: '02', label: `${t('LBL.FEBRUARY')}`, short: 'Feb' },
      { value: '03', label: `${t('LBL.MARCH')}`, short: 'Mar' },
      { value: '04', label: `${t('LBL.APRIL')}`, short: 'Apr' },
      { value: '05', label: `${t('LBL.MAY')}`, short: 'May' },
      { value: '06', label: `${t('LBL.JUNE')}`, short: 'Jun' },
      { value: '07', label: `${t('LBL.JULI')}`, short: 'Jul' },
      { value: '08', label: `${t('LBL.AUGUST')}`, short: 'Aug' },
      { value: '09', label: `${t('LBL.SEPTEMBER')}`, short: 'Sep' },
      { value: '10', label: `${t('LBL.OCTOBER')}`, short: 'Oct' },
      { value: '11', label: `${t('LBL.NOVEMBER')}`, short: 'Nov' },
      { value: '12', label: `${t('LBL.DESEMBER')}`, short: 'Dec' },
    ];
    this.state = {
      displayLoad: 'none',
      displaySave: '',
    };
  }

  render() {
    const { handleSubmit, reset, t } = this.props;
    const { displayLoad, displaySave } = this.state;
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">{t('LBL.PAYSLIP_PASSWORD')}</h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form--horizontal"
                      onSubmit={handleSubmit}
                    >
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="select"
                                  component={renderSelectField}
                                  placeholder="Select Company"
                                  options={[
                                    { value: 'one', label: '...' },
                                    { value: 'two', label: '...' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="select"
                                  component={renderSelectField}
                                  placeholder="Select Branch"
                                  options={[
                                    { value: 'one', label: '...' },
                                    { value: 'two', label: '...' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="select"
                                  component={renderSelectField}
                                  placeholder="Select Unit Organization"
                                  options={[
                                    { value: 'one', label: '...' },
                                    { value: 'two', label: '...' },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="select"
                                  component={renderSelectField}
                                  placeholder="Select Employee Name"
                                  options={[
                                    { value: 'one', label: '...' },
                                    { value: 'two', label: '...' },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.PAYROLL_PERIODE')}</span>
                              <div className="form__form-group-field">
                                <Row>
                                  <Col md={4} xs={12} lg={4} xl={4}>
                                    <Field
                                      name="select"
                                      component={renderSelectField}
                                      placeholder="Year"
                                      options={[
                                        { value: 'one', label: '...' },
                                        { value: 'two', label: '...' },
                                      ]}
                                    />
                                  </Col>
                                  <Col md={4} xs={12} lg={4} xl={4}>
                                    <Field
                                      name="select"
                                      component={renderSelectField}
                                      placeholder="Month"
                                      options={[
                                        { value: 'one', label: '...' },
                                        { value: 'two', label: '...' },
                                      ]}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="select"
                                  component={renderSelectField}
                                  placeholder="Select Output"
                                  options={[
                                    { value: 'one', label: '...' },
                                    { value: 'two', label: '...' },
                                  ]}
                                />
                              </div>
                            </div>
                            <ButtonToolbar className="form__button-toolbar">
                              <Button type="button" onClick={reset}>
                                Cancel
                              </Button>
                              <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                              <Button color="primary" style={{ display: displayLoad }}>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: '6px' }}
                                />
                                {t('FRM.LOADING')}
                              </Button>
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'payslip_pasword_form', // a unique identifier for this form
})(translate('global')(PayslipPasswordPage));
