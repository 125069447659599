/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Modal,
  ModalBody, ModalHeader, ModalFooter, Table,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

// let compthr = '';
class ReportAttendance extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    prp_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      buttondisabled: true,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '20%', '20%', '20%', '10%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      payatt: [],
      ordernumber: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DESCRIPTION') },
      { '2': t('LBL.FORMULA_DESCRIPTION') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    // this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.DESCRIPTION') },
      { '2': t('LBL.FORMULA_DESCRIPTION') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle(x) {
    this.props.destroy();
    this.setState({ ordernumber: '' });
    this.setState({ statusEdit: false });
    this.setState({ buttondisabled: true });
    this.setState({ modal: !this.state.modal });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.loadSelectBox();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/payreportattribute/getAllItemPrp/${this.props.prp_id}` });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ orgoffice: Arr });
      }
    }).catch(() => {
      // console.log(error);
    });

    axios.post(
      `${apiws}/orgcompany/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.cmp_id, label: obj.company })
        ));
        this.setState({ company: Arr });
      }
    }).catch(() => {
      // console.log(error);
    });

    axios.post(
      `${apiws}/payattribut/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.label_keterangan, label: obj.keterangan })
        ));
        this.setState({ payatt: Arr });
      }
    }).catch(() => {
      // console.log(error);
    });

    axios.post(
      `${apiws}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ orgorganization: Arr });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  // search(values) {
  //   console.log(values);
  // }

  editDetail(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/payreportattribute/getAllItemById/${id}`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const getResponseData = response.data.data;
        const datamaping = {
          pat_id: getResponseData[0].pat_id,
          ordernumber: getResponseData[0].pat_order,
          name: getResponseData[0].pat_name,
          attribute: getResponseData[0].pat_column,
        };
        this.setState({ buttondisabled: true });
        this.setState({ statusEdit: true });
        this.setState({ ordernumber: datamaping.ordernumber });
        this.setState({ modal: true }, () => {
          this.props.initialize(datamaping);
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  deleteDetail(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payreportattribute/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.child.reload('delete');
            }, 2000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }


  handleSubmitInput(values) {
    // console.log(values);
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const error = true;
    const id = values.pat_id;
    const prp = this.props.prp_id;
    const attribute = (typeof values.attribute === 'object') ? values.attribute.value : values.attribute;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/payreportattribute/saveItem`;
      data = {
        ...values,
        ordernumber: this.state.ordernumber,
        prp,
        attribute,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/payreportattribute/updateItem/${id}`;
      data = {
        ...values,
        ordernumber: this.state.ordernumber,
        prp,
        attribute,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
          setTimeout(() => {
            this.child.reload();
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  render() {
    const {
      handleSubmit, t, pristine, formatFunction,
    } = this.props;
    const {
      orgorganization,
      orgoffice,
      urlData,
      company,
      statusEdit,
      payatt,
      buttondisabled,
    } = this.state;
    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('LBL.DETAIL_REPORT')}
      </Button>
    );
    const backBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={formatFunction}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    let buttonStatus = true;
    if (buttondisabled === false && pristine === true) buttonStatus = false;
    if (buttondisabled === true && pristine === false) buttonStatus = false;
    if (buttondisabled === false && pristine === false) buttonStatus = false;
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addattmonitoring"
          disabled={buttonStatus}
        > {t('FRM.EDIT')}
        </Button>);
    } else {
      button_action = (
        <Button
          color="primary"
          form="addattmonitoring"
          type="submit"
          disabled={buttonStatus}
        >{t('FRM.SAVE')}
        </Button>);
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            {/* <div style={{ float: 'left', 'margin-left': '25px' }}>
              <h3>Format: </h3>
              <h5>Group Code: </h5>
              <h5>Group Name: </h5>
            </div> */}
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="pat_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  onRef={ref => (this.child = ref)}
                  updateFunc={this.editDetail}
                  deleteFunc={this.deleteDetail}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>{t('FRM.ADD')} {t('LBL.DETAIL_REPORT')}</ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="addattmonitoring"
                  id="addattmonitoring"
                >
                  <Container>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ORDER')} {t('LBL.NUMBER')}</span>
                          <div className="form__form-group-field">
                            <NumberFormat
                              maxLength={2}
                              style={{ width: '100%', textAlign: 'right' }}
                              type="text"
                              allowNegative={false}
                              value={this.state.ordernumber}
                              onValueChange={(val) => {
                                this.setState({ ordernumber: val.value });
                                this.setState({ buttondisabled: false });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6} style={{ marginTop: '10px' }}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.LABEL')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="name"
                              component={renderTextInput}
                              type="text"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ATTRIBUTE')}</span>
                          <div className="form__form-group-field products-list__search">
                            <Field
                              name="attribute"
                              component={renderSelectField}
                              options={payatt}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => { this.toggle('cancel'); }}>{t('FRM.CANCEL')}</Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'reportadj',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(ReportAttendance));
