import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalculator, faNewspaper, faChartLine, faTasks } from '@fortawesome/fontawesome-free-solid';
import { translate } from 'react-i18next';
// import PropTypes from 'prop-types';

const Payroll = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Payroll</h3>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="tile bg-default">
          <div className="tile-content icon">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
          <div className="tile-status">
            <span className="name">Setting Period</span>
          </div>
        </div>
        <div className="tile">
          <div className="tile-content icon">
            <FontAwesomeIcon icon={faCalculator} />
          </div>
          <div className="tile-status">
            <span className="name">Run Payroll</span>
          </div>
        </div>
        <div className="tile">
          <div className="tile-content icon">
            <FontAwesomeIcon icon={faNewspaper} />
          </div>
          <div className="tile-status">
            <span className="name">Run Tax Process</span>
          </div>
        </div>
        <div className="tile">
          <div className="tile-content icon">
            <FontAwesomeIcon icon={faChartLine} />
          </div>
          <div className="tile-status">
            <span className="name">Report</span>
          </div>
        </div>
        <div className="tile">
          <div className="tile-content icon">
            <FontAwesomeIcon icon={faTasks} />
          </div>
          <div className="tile-status">
            <span className="name">Payroll Simulation</span>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default translate('common')(Payroll);
