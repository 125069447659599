import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TaskItemChild from './TaskItemChild';

const TaskItem = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <TaskItemChild
        t={props.t}
        id={props.id}
        tab={props.tab}
        data={props.data}
        toggleOffCanvas={props.toggleOffCanvas}
        toggleNotification={props.toggleNotification}
        getDetailTask={props.getDetailTask}
        getDataSection={props.getDataSection}
        user={props.user}
      />
    </div>
  );
};

export default TaskItem;
