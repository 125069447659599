import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { Progress } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChartBar, faCheck } from '@fortawesome/fontawesome-free-solid';

export default class MyTeamMember extends PureComponent {
  static propTypes = {
    img: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    post: PropTypes.string,
    // value: PropTypes.number.isRequired,
    // link: PropTypes.string.isRequired,
    // linkStatus: PropTypes.string.isRequired,
  };

  static defaultProps = {
    post: '',
  };

  render() {
    const {
      img, name, post,
      // img, name, post, link, linkStatus, value,
    } = this.props;

    return (
      <div className="project-member">
        <div className="project-member__avatar-wrap">
          <img src={img} alt="" />
        </div>
        <div>
          <p className="project-member__name">{name}</p>
          <p className="project-member__post">{post}</p>
        </div>
        {/* <div className="project-member__attribute">
          <div className="progress-wrap progress-wrap--small">
            <Progress value={value} />
          </div>
        </div> */}
      </div>
    );
  }
}
