/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Card, CardBody, Container, Row, Col, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { CSVReader } from 'react-papaparse';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import swal from 'sweetalert2';
import { faDownload } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SleepIcon } from 'mdi-react';
import ReactExport from 'react-export-excel';
// import csv from 'csv-validator';


// eslint-disable-next-line react/prefer-stateless-function
const buttonRef = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class HorizontalForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      message: [],
      token: '',
      apiws: '',
      arrdatacsv: [],
      file: '',
      emp_code: '',
      head: [],
      displayLoad: 'none',
      displayButton: '',
      headerexcel: [],
      templatevalue: [],
      period_code: [],
      codecomp: [],
      headercomponent: ['Component', 'Code'],
    };
    this.handleOnError = this.handleOnError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.downloadTemplate = this.downloadTemplate(this);
    this.downloadComponent = this.downloadComponent(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getPeriode();
      this.getComponent();
    });
    this.getEmployee();
  }

  getEmployee() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/employee/getAllFilter/`;
    axios.post(
      url,
      '',
      config,
    ).then((response) => {
      console.log(response);
      const res = response.data.data;
      const arr = [];
      res.map((data) => {
        arr.push(data.emp_code);
      });
      this.setState({ emp_code: arr });
    }).catch((error) => {
      // this.setState({ button_disabled: false });
    });
  }
  getPeriode() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.get(
      `${apiws}/payperiod`,
      config,
    ).then((res) => {
      const period = res.data.data;
      const array = [];
      if (period.length === 0) {
        this.setState({ period_code: array });
      } else {
        period.map(obj => (
          array.push({ value: obj.pyp_id, label: obj.period_code })
        ));
        this.setState({ period_code: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }
  getComponent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/paycomponent/getAll/`;
    axios.post(
      url,
      '',
      config,
    ).then((response) => {
      const res = response.data.data;
      const arr = [];
      res.map((data) => {
        arr.push({ comp: data.component_code });
        this.state.codecomp.push(data.component_code);
      });
      // console.log(this.state.codecomp);
    }).catch((error) => {
      // this.setState({ button_disabled: false });
    });
  }
  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  }

  fileLoad = (data, e) => {
    console.log(data);
    const array = [];
    const header = data[0].data;
    const headers = [];
    const errheader = [];
    header.map((item) => {
      headers.push(item.toLowerCase().replace(/\s/g, '_'));
    });
    const { emp_code, codecomp } = this.state;
    if (data.length > 0) {
      array.push({ header: data[0].data, value: data.slice(1) });
      // console.log(array);
    }
    const datacsv = data.slice(1);

    const validdata = [];
    const errordata = [];
    const errormsg = [];
    const invalcomp = [];
    datacsv.map((item, index) => {
      const errorlog = [];
      let stsva;
      if (item.data.length > 1) {
        item.data.map((hea, key) => {
          if (header[key].toLowerCase().includes('no')) {
            if (Number.isNaN(hea) === null) {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
          } else if (header[key].toLowerCase().includes('employee code')) {
            if (hea === '') {
              stsva = false;
              errorlog.push(`${header[key]} Invalid, `);
            }
            if (emp_code.indexOf(hea) === -1) {
              stsva = false;
              errorlog.push(`employee code ${hea} not found`);
            }
          } else {
            if (hea.length > 0) {
              const val = parseInt(hea, 10);
              if (Number.isNaN(val) === true && hea != '-') {
                errorlog.push(`value '${hea}' invalid`);
              }
            } else {
              // eslint-disable-next-line no-param-reassign
              item.data[key] = '0';
            }
            if (codecomp.indexOf(header[key]) === -1) {
              invalcomp.push(header[key]);
            }
          }
        });
      }
      if (errorlog.length === 0) {
        stsva = true;
      }

      if (stsva) {
        validdata.push(item);
        this.setState({ arrdatacsv: validdata, head: headers, file: e });
      } else {
        errormsg.push({ row: index + 1, log: errorlog });
        errordata.push({ item });
      }
    });
    // console.log([...new Set(invalcomp)]);
    const invalid = [...new Set(invalcomp)];
    invalid.map((obj) => {
      errheader.push(`component code ${obj} not found`);
    });
    if (errheader.length > 0) {
      errormsg.push({ row: 'header', log: errheader });
    }

    if (errormsg.length > 0) {
      this.setState({ error: true });
      this.setState({ message: errormsg, ...this.state.message });
    }
  }

  handleOnFileLoad = (data, e) => {
    const array = [];
    const header = data[0].data;
    const headers = [];
    const errheader = [];
    header.map((item) => {
      headers.push(item.toLowerCase().replace(' ', '_'));
    });
    const headrequire =
      ['no', 'employee_code', 'first_name', 'last_name', 'email', 'mobile', 'phone_number', 'address', 'postal_code', 'date_of_birth', 'place_of_birth',
        'sex', 'blood_type', 'marital_status', 'religion', 'citizenship', 'country', 'identity', 'identity_number', 'expired_date', 'emergency_contact', 'relation', 'relation_name',
        'attendance_type_code', 'tax_status', 'dependant', 'overtime', 'bpjstk_registered', 'bpjstk_number', 'bpjsks_registered', 'bpjsks_number',
        'npwp', 'npwp_date', 'bank_name', 'bank_account', 'account_holder', 'office', 'organizational_unit', 'job_name', 'position', 'job_band',
        'grade', 'superordinat', 'employeement_status', 'join_date', 'attendance_setting', 'default_tax_method',
      ];

    headrequire.some((v) => {
      if (headers.indexOf(v) === -1) {
        errheader.push(`${v.replace('_', ' ')} not found `);
      }
    });

    const { emp_code } = this.state;
    if (data.length > 0) {
      array.push({ header: data[0].data, value: data.slice(1) });
      // console.log(array);
    }
    const datacsv = data.slice(1);
    // console.log(data);
    // console.log(datacsv);
    const validdata = [];
    const errordata = [];
    const errormsg = [];

    if (errheader.length > 0) {
      errormsg.push({ row: 'header', log: errheader });
    } else {
      datacsv.map((item, index) => {
        const errorlog = [];
        let stsva;
        if (item.data.length > 1) {
          item.data.map((hea, key) => {
            if (header[key].toLowerCase().includes('no')) {
              if (Number.isNaN(hea) === null) {
                stsva = false;
                errorlog.push(`${header[key]} Invalid, `);
              }
            }
            if (header[key].toLowerCase().includes('emp')) {
              if (hea === '') {
                stsva = false;
                errorlog.push(`${header[key]} Invalid, `);
              }
              if (emp_code.indexOf(hea) === -1) {
                stsva = false;
                errorlog.push(`employee code ${hea} not found`);
              }
            }
            if (header[key].toLowerCase().includes('component')) {
              if (this.state.codecomp.indexOf(hea) === -1) {
                stsva = false;
                errorlog.push(`component code ${hea} not found`);
              }
            }
            if (headers[key].toLowerCase().includes('value')) {
              if (Number.isNaN(hea) === null) {
                stsva = false;
                errorlog.push(`${header[key]} Invalid, `);
              }
            }
          });
        }
        if (errorlog.length === 0) {
          stsva = true;
        }

        if (stsva) {
          validdata.push(item);
          this.setState({ arrdatacsv: validdata, head: headers, file: e });
        } else {
          errormsg.push({ row: index + 1, log: errorlog });
          errordata.push({ item });
        }
      });
    }

    if (errormsg.length > 0) {
      this.setState({ error: true });
      this.setState({ message: errormsg, ...this.state.message });
    }
  }

  handleFirstChunk = (chunkc) => {
    const chunk = chunkc.split('\n');

    if (chunk[0].split(',').length <= 1 && chunk[0].split(';').length <= 1) {
      setTimeout(() => {
        this.handleOnRemoveFile();
      }, 2000);
      showNotifications('Fail', 'Format Not Supported', 'danger');
    }
    // return true;
  }

  handleOnError(err, file, inputElem, reason) {
    console.log(err);
    console.log(file);
    console.log(inputElem);
    console.log(reason);
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '' });
    this.setState({ displayButton: 'none' });
    const userid = ls.get('user_cred').usr_id;
    const period_code = values.period.value;
    const {
      token, apiws, arrdatacsv, head, file,
    } = this.state;
    const error = true;
    const csvfile = file;

    const arr = [];
    arrdatacsv.map((elemet, key) => {
      if (elemet.data.length > 1) {
        const obj = {};
        elemet.data.map((value, index) => {
          if (value == '-') {
            obj[head[index]] = null;
          } else {
            obj[head[index]] = value;
          }
        });
        arr.push(obj);
      }
    });
    const array = (JSON.stringify(arr));    

    const url = `${apiws}/payempcomponent/saveItemArray`;
    const convertdata = { data: array, csvfile };

    // const body = [`data=${data}`, `file=${csvfile}`, `cuser_id=${userid}`].join('&');
    const body = { ...convertdata, cuser_id: userid, period: period_code };
    const getFormData = Object.keys(body).reduce((formData, key) => {
      formData.append(key, body[key]);
      return formData;
    }, new FormData());

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    setTimeout(() => {
      axios.post(
        url,
        getFormData,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.handleOnRemoveFile();
        } else {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
          this.handleOnRemoveFile();
        }
      }).catch(() => {
        this.setState({ displayLoad: 'none' });
        this.setState({ displayButton: '' });
        showNotifications('Fail', 'Save Failed', 'danger');
        this.handleOnRemoveFile();
      });
    });
  }

  handleOnRemoveFile = (e) => {
    this.myFormRef.reset();
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    this.setState({ error: false });
    this.setState({ message: [] });
  }

  downloadTemplate() {
    const headrequire = ['No', 'Employee code', 'MA', 'TA', 'IJPA'];
    const value =
      [{
        No: '1',
        'Employee code': 'S1090190',
        MA: '500000',
        TA: '500000',
        IJPA: '88000',
      },
      {
        No: '2',
        'Employee code': 'S10901912',
        MA: '700000',
        TA: '500000',
        IJPA: '180000',
      },
      ];
    setTimeout(() => {
      this.setState({ headerexcel: headrequire, templatevalue: value });
    }, 200);
  }

  downloadComponent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/paycomponent/getAll/`;
    axios.post(
      url,
      '',
      config,
    ).then((response) => {
      const res = response.data.data;
      const arr = [];
      res.map((data) => {
        arr.push({ Component: data.component, Code: data.component_code });
      });
      this.setState({ component: arr });
    }).catch((error) => {
      // this.setState({ button_disabled: false });
    });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const ulstyle = { width: '300px', height: '200px', overflow: 'auto' };
    const notestyle = { 'column-count': '3', 'column-gap': '20px' };
    let button_action;
    if (this.state.error) {
      button_action = (
        <Button disabled color="primary" type="submit">{t('FRM.SUBMIT')}</Button>);
    } else {
      button_action = (
        <Button color="primary" type="submit" style={{ display: this.state.displayButton }}>{t('FRM.SUBMIT')}</Button>);
    }
    return (
      <Card>
        <CardBody>
          <Row className="m5">
            <Col sm="12" md={12} lg={12}>
              <form
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
                // eslint-disable-next-line no-return-assign
                ref={el => this.myFormRef = el}
                name="loadercomponent"
                id="loadercomponent"
              >
                <Container>
                  <Row>
                    <Col sm="12" md={6} lg={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.FILE')}</span>
                        <div className="form__form-group-field">
                          <CSVReader
                            onDrop={this.fileLoad}
                            onError={this.handleOnError}
                            noDrag
                            addRemoveButton
                            config={{
                              beforeFirstChunk: this.handleFirstChunk,
                              delimitersToGuess: [';', ','],
                            }}
                            onRemoveFile={this.handleOnRemoveFile}
                          >
                            <span> {t('LBL.CLICK_TO_UPLOAD')}</span>
                          </CSVReader>
                        </div>
                      </div>
                    </Col>
                    {(this.state.error === true) && (
                      <Col sm="12" md={6} lg={6}>
                        <span className="form__form-group-label" style={{ color: 'red' }}>{t('LBL.ERROR')}</span>
                        <ul style={ulstyle}>
                          {this.state.message.map(obj => (
                            <React.Fragment>
                              <li>  {t('LBL.ROW')} {obj.row}</li>
                              <ul> {obj.log.map(ob => <li>{ob}</li>)} </ul>
                            </React.Fragment>
                          ))
                          }
                        </ul>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={12} md={6} >
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="period"
                            component={renderSelectField}
                            options={this.state.period_code}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ display: this.state.displayLoad, textAlign: 'center' }} className="m5">
                    <Col xs={12} md={6} lg={6} xl={6}>
                      <Card>
                        <CardBody>
                          <div className="load__icon-wrap">
                            <svg className="load__icon">
                              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ButtonToolbar className="form__button-toolbar">
                  {button_action}
                  <Button type="button" onClick={() => this.handleOnRemoveFile()} style={{ display: this.state.displayButton }}>
                    {t('FRM.CANCEL')}
                  </Button>
                </ButtonToolbar>
              </form>
            </Col>
            <Col sm="12" md="12" lg="12" xl="12">
              <div className="note-file-upload">
                <ExcelFile
                  filename="Template Component loader"
                  element={
                    <Link
                      to="#"
                      // type="button"
                      onClick={this.downloadTemplate}
                    > <FontAwesomeIcon icon={faDownload} /> <h5><b>{t('LBL.DOWNLOAD')} {t('LBL.TEMPLATE')}</b></h5>
                    </Link>
                  }
                >
                  <ExcelSheet data={this.state.templatevalue} name="Component">
                    {
                      this.state.headerexcel.map(obj => (
                        <ExcelColumn label={obj} value={obj} />
                      ))
                    }
                  </ExcelSheet>
                </ExcelFile>
                <ExcelFile
                  filename="Component Code"
                  element={
                    <Link
                      to="#"
                      // type="button"
                      onClick={this.downloadComponent}
                    > <FontAwesomeIcon icon={faDownload} /> <h5><b>{t('LBL.DOWNLOAD')} {t('LBL.COMPONENT')} {t('LBL.CODE')}</b></h5>
                    </Link>
                  }
                >
                  <ExcelSheet data={this.state.component} name="Component Code">
                    {
                      this.state.headercomponent.map(obj => (
                        <ExcelColumn label={obj} value={obj} />
                      ))
                    }
                  </ExcelSheet>
                </ExcelFile>
                <br />
                <h5><b>NOTE:</b></h5>
                <h5>Required file type (*.CSV)</h5>
                <h5>Field Order :</h5>
                <ol style={notestyle}>
                  <li>No</li>
                  <li>Employee Code</li>
                  <li>Component Code List</li>
                </ol>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card >
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.period) {
    errors.period = 'period shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'loadercomponent', // a unique identifier for this form
})(translate('global')(HorizontalForm));
