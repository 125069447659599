import {createContext, useReducer} from 'react';

const initalState = {
	activity: [],
};

const ACTIONS = {
	SET_ACTIVITY: 'SET_ACTIVITY',
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.SET_ACTIVITY:
			return {...state, activity: action.data};

		default:
			return state;
	}
}

const ActivityContext = createContext();
const getReducer = () => useReducer(reducer, initalState);

export {ACTIONS, ActivityContext, getReducer};
