/* eslint-disable import/no-named-default */
/* eslint-disable quotes */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */

import React from "react";
import PropTypes from "prop-types";
import { default as NumberFormatORI } from "react-number-format";

const NumberFormat = props => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <NumberFormatORI
      style={{ width: "100%" }}
      type="text"
      {...props.input}
      {...props.props}
    />
    {(props.meta.error && props.meta.touched) && (
      <span className="form__form-group-error">{props.meta.error}</span>
    )}
  </div>
);

NumberFormat.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  props: PropTypes.shape({
    maxLength: PropTypes.number,
    allowNegative: PropTypes.bool,
  }),
};

NumberFormat.defaultProps = {
  props: {},
};

export default NumberFormat;
