import React from 'react';
// import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
// import UserIcon from 'mdi-react/UserIcon';
// import FacebookBoxIcon from 'mdi-react/FacebookBoxIcon';
// import InstagramIcon from 'mdi-react/InstagramIcon';
// import LinkedinIcon from 'mdi-react/LinkedinBoxIcon';
// import TwitterIcon from 'mdi-react/TwitterIcon';
import AccountHierarchy from './AccountHierarchy';
import ProfilePanelData from './ProfilePanelData';

const ProfilePanel = () => (
  <Card className="card--not-full-height">
    <CardBody className="profile__card no_bottom_border_radius">
      <ProfilePanelData />
    </CardBody>
    <AccountHierarchy />
  </Card>
);

export default ProfilePanel;
