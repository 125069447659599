/*
 * Page Add Employee Current in Period Setting
 * Notes:
 * @author Gerdi
 * @date 02/11/2019
 * Modification (please note below)
*/
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../Preferences/notificationMessages';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../../shared/components/table/viewListTable';

class PeriodAddEmployee extends PureComponent {
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    empidParam: PropTypes.func.isRequired,
    pypidParam: PropTypes.func.isRequired,
    onreload: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.COMPONENT') },
      { '2': t('LBL.VALUE') },
      { '3': t('LBL.ADJUSTMENT') },
      { '4': t('LBL.BACKPAY') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      pypid: '',
      modal: false,
      urlData: '',
      dataWidth: ['5%', '30%', '20%', '20%', '20%'],
    };

    // this.actionAddEmpPeriod = this.actionAddEmpPeriod.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.props.initialize({});
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/payempcomponent/getAllComponentById` });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.getTableData();
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.COMPONENT') },
      { '2': t('LBL.VALUE') },
      { '3': t('LBL.ADJUSTMENT') },
      { '4': t('LBL.BACKPAY') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  actionAddEmpPeriod(id) {
    // const pypid = this.props.addidparam;
    const {
      apiws, token, idUser,
    } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will add this employee !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15DD96',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, add it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}//employee/getallitembyidinsert/${id}/${pypid}/${idUser}`,
          '',
          config,
        ).then((res) => {
          if (res.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.getTableData();
            this.props.onreload();
          }
        });
      }
    });
  }

  render() {
    const {
      urlData,
    } = this.state;
    return (
      <ViewListTable
        url={urlData}
        heads={this.heads}
        primaryKey="pec_id"
        // flagKey="flags"
        buttonAction="noButton"
        conds={{ 'empid': this.props.empidParam, 'pypid': this.props.pypidParam }}
        widthTable={this.state.dataWidth}
        onRef={ref => (this.child = ref)}
        displayStyleHead="none"
        // actionFunc={this.actionAddEmpPeriod}
      />
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'FormEmployeePeriode',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(PeriodAddEmployee));
