/* eslint-disable no-plusplus, react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func, prefer-template */
/* eslint-disable no-redeclare, arrow-parens */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len, no-unneeded-ternary */
/* eslint-disable no-alert, object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Row, Card, CardBody, Col, Button,
  ModalFooter, ModalBody, ModalHeader,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import '../../../Tables/DataTable/assets/css/style.css';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import NumberFormat from 'react-number-format';

class FunctionAccessPageX extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    empname: PropTypes.string.isRequired,
    userid: PropTypes.string.isRequired,
    toggle: PropTypes.string.isRequired,
    stateEdit: PropTypes.string,
    initEdit: PropTypes.string,
    statusEdit: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.WORKFLOW')} ${t('LBL.NAME')}`,
      t('LBL.DOCUMENT'),
      t('LBL.ACTIVITY'),
      t('LBL.ORDER'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      installment: '',
      loan: '',
      totalLoan: '',
      interest: '',
      payment: '',
      loanFormatted: '',
      spanErrorLoanDate: 'none',
      spanErrorinstallement: 'none',
      spanErrorloan: 'none',
      spanErrorinterest: 'none',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeLoanDate = this.changeLoanDate.bind(this);
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    setTimeout(() => {
      this.getData();
    }, 100);
  }

  // menerima jika ada props dari manapun yang di update
  // akan diproses disini
  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      `${t('LBL.WORKFLOW')} ${t('LBL.NAME')}`,
      t('LBL.DOCUMENT'),
      t('LBL.ACTIVITY'),
      t('LBL.ORDER'),
      t('FRM.FUNCTION'),
    ];
  }

  getnoData() {
    setTimeout(() => {
      this.setState({
        loan: '',
        interest: '',
        totalLoan: '',
        installment: '',
        loanFormatted: '',
      });
    }, 100);
  }

  getData() {
    setTimeout(() => {
      if (this.props.statusEdit) {
        this.setState({
          loan: this.props.stateEdit.loan,
          loanFormatted: this.props.stateEdit.loan,
          interest: this.props.stateEdit.interest,
          totalLoan: this.props.stateEdit.totalLoan,
          installment: this.props.stateEdit.installment,
          payment: this.props.stateEdit.payment,
        });
        this.props.initialize(this.props.initEdit);
      }
    }, 100);
  }

  convertDate(str) {
    const date = new Date(str);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }

  mapFormData(b, a) {
    let val_b;
    if (b !== '') {
      if (a.indexOf('_date') !== -1) {
        val_b = this.convertDate(b);
      } else if (typeof b === 'object') {
        if (b === 'null' || b === null) {
          val_b = '';
        } else {
          val_b = b.value;
        }
      } else {
        val_b = b;
      }
    } else {
      val_b = '';
    }
    return val_b;
  }

  changeLoanDate(x) {
    if (typeof x === 'object') {
      this.setState({ spanErrorLoanDate: 'none' });
    }
  }

  handleInstallmentMonthPeriod(period) {
    // get loan amount from form input
    const { totalLoan } = this.state;
    const installmentInMonthPeriode = Math.round(((parseInt(totalLoan, 10) / period) + Number.EPSILON) * 100) / 100;
    this.setState({ installment: installmentInMonthPeriode.toFixed(0) });
  }

  handleSubmit(values) {
    const empId = this.props.userid;
    const {
      apiws,
      token,
      idUser,
      loan,
      interest,
      totalLoan,
      installment,
    } = this.state;
    const formBody = Object.keys(values).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(this.mapFormData(values[key], key))}`).join('&');
    const config = {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let add;
    let action;
    let link;
    let body;
    let error = 0;
    const xloan = (loan === null || loan === 'null' || typeof loan === 'undefined') ? '' : loan;
    const xtotalLoan = (totalLoan === null || totalLoan === 'null' || typeof totalLoan === 'undefined') ? '' : totalLoan;
    const xinterest = (interest === null || interest === 'null' || typeof interest === 'undefined') ? '' : interest;
    const xinstallment = (installment === null || installment === 'null' || typeof installment === 'undefined') ? '' : installment;
    if (typeof values.elh_id === 'undefined') {
      add = 1;
      action = 'POST';
      link = `${apiws}/emploanhist`;
      body = [
        formBody,
        `loan=${xloan}`,
        `total_loan=${xtotalLoan}`,
        `interest=${xinterest}`,
        `installment=${xinstallment}`,
        `cuser_id=${idUser}`,
        `emp_id=${empId}`,
      ].join('&');
    } else {
      add = 0;
      action = 'PUT';
      link = `${apiws}/emploanhist/${values.elh_id}`;
      body = [
        formBody,
        `loan=${xloan}`,
        `total_loan=${xtotalLoan}`,
        `interest=${xinterest}`,
        `installment=${xinstallment}`,
        `muser_id=${idUser}`,
        `emp_id=${empId}`,
      ].join('&');
    }
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    if (typeof values.start_date === 'undefined' || values.start_date === null || values.start_date === 'null') {
      error = 1;
    }
    if (typeof installment === 'undefined' || installment === null || installment === 'null' || installment === '') {
      error = 1;
    }
    if (typeof loan === 'undefined' || loan === null || loan === 'null' || loan === '') {
      error = 1;
    }
    if (typeof interest === 'undefined' || interest === null || interest === 'null' || interest === '') {
      error = 1;
    }
    if (error === 0) {
      axios(options).then((response) => {
        if (response.data.status === 'ok') {
          this.props.toggle('close');
          // this.toggle('close');
          // this.getTableData();
          showNotifications('Success', 'Save Success', 'success');
          // this.props.destroy();
        }
      });
    } else {
      if (typeof values.start_date === 'undefined' || values.start_date === null || values.start_date === 'null') {
        this.setState({ spanErrorLoanDate: '' });
      }
      if (typeof installment === 'undefined' || installment === null || installment === 'null' || installment === '') {
        this.setState({ spanErrorinstallement: '' });
      }
      if (typeof loan === 'undefined' || loan === null || loan === 'null' || loan === '') {
        this.setState({ spanErrorloan: '' });
      }
      if (typeof interest === 'undefined' || interest === null || interest === 'null' || interest === '') {
        this.setState({ spanErrorinterest: '' });
      }
    }
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const { payment } = this.state;
    const disabledButton = (payment) ? true : false;
    const disabledInput = (payment) ? true : false;
    return (
      <Col md={12} lg={12}>
        <ModalHeader toggle={this.toggle}>{t('FRM.ADD')} {t('LBL.LOAN')}</ModalHeader>
        <ModalBody>
          <form
            className="form form--horizontal"
            onSubmit={handleSubmit(this.handleSubmit)}
            name="addloan"
            id="addloan"
          >
            <CardBody>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE_NAME')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        component={renderTextInput}
                        type="text"
                        placeholder={this.props.empname}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.LOAN_TYPE')}</span>
                    <div className="form__form-group-field">
                      <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <Col md={6} xs={12} className="input_select_form_loan">
                          <Field
                            name="lot_id"
                            component={renderSelectField}
                            options={[
                              { value: 1, label: 'Internal' },
                              { value: 2, label: 'Eksternal' },
                            ]}
                            disabled={disabledInput}
                          />
                        </Col>
                        <Col md={6} xs={12} className="input_select_form_loan">
                          <Field
                            maxLength="50"
                            name="loan_name"
                            component={renderTextInput}
                            type="text"
                            placeholder="Loan Name"
                            disabled={disabledInput}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.LOAN')}</span>
                    <div className="form__form-group-field number-form">
                      <Row>
                        <Col md={12} xs={12}>
                          <Row>
                            <Col md={6} xs={12}>
                              <span
                                className="form__form-group-error-custom"
                                style={{ display: this.state.spanErrorloan }}
                              >
                                loan field shouldn’t be empty
                              </span>
                              <NumberFormat
                                maxLength="19"
                                style={{ textAlign: 'right' }}
                                component={NumberFormat}
                                thousandSeparator="."
                                decimalSeparator=","
                                allowedDecimalSeparators={[',']}
                                type="text"
                                prefix="Rp "
                                value={this.state.loanFormatted}
                                disabled={disabledInput}
                                placeholder="Loan (Rp)"
                                onValueChange={(val) => {
                                  const a = val.formattedValue;
                                  const aa = val.value;
                                  const b = this.state.interest;
                                  this.setState({ loan: aa });
                                  this.setState({ loanFormatted: a });
                                  if (!(b === '')) {
                                    const c = (aa * b) / 100;
                                    const d = parseFloat(aa) + parseFloat(c);
                                    this.setState({ totalLoan: d });
                                  } else {
                                    this.setState({ totalLoan: a });
                                  }
                                  this.setState({ spanErrorloan: 'none' });
                                }}
                              />
                            </Col>
                            <Col md={3} xs={12}>
                              <span
                                className="form__form-group-error-custom"
                                style={{ display: this.state.spanErrorinterest }}
                              >
                                interest is empty
                              </span>
                              <NumberFormat
                                maxLength="5"
                                style={{ textAlign: 'right' }}
                                component={NumberFormat}
                                thousandSeparator="."
                                decimalSeparator=","
                                allowedDecimalSeparators={[',']}
                                type="text"
                                value={this.state.interest}
                                placeholder="Interest"
                                disabled={disabledInput}
                                onValueChange={(val) => {
                                  const a = val.formattedValue;
                                  const aa = val.value;
                                  const b = this.state.loan;
                                  const c = (b * aa) / 100;
                                  const d = parseFloat(b) + parseFloat(c);
                                  this.setState({ interest: a });
                                  this.setState({ totalLoan: d });
                                  this.setState({ spanErrorinterest: 'none' });
                                }}
                              />
                            </Col>
                            <Col md={3} xs={12}>
                              <span className="form__form-group-label" style={{ 'margin-left': '-20px' }}>%</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} xs={12}>
                          <div style={{ marginTop: '10px' }}>
                            <NumberFormat
                              maxLength="19"
                              style={{ textAlign: 'right' }}
                              component={NumberFormat}
                              thousandSeparator="."
                              decimalSeparator=","
                              allowedDecimalSeparators={[',']}
                              type="text"
                              prefix="Rp "
                              disabled
                              value={this.state.totalLoan}
                              onValueChange={(val) => {
                                const a = val.value;
                                this.setState({ totalLoan: a });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={8} lg={8} sm={12} xs={12}>
                  <div className="form__form-group" style={{ marginTop: '15px' }}>
                    <span className="form__form-group-label">{t('LBL.START_DATE')}</span>
                    <div className="form__form-group-field">
                      <span
                        className="form__form-group-error-custom"
                        style={{ display: this.state.spanErrorLoanDate }}
                      >
                        loan date field shouldn’t be empty
                      </span>
                      <Field
                        style={{ paddingRight: '20px !important' }}
                        name="start_date"
                        component={renderDatePickerField}
                        placeholder="Start Date"
                        onChange={(x) => this.changeLoanDate(x)}
                        disabled={disabledInput}
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.START_PAYMENT')}</span>
                    <div className="form__form-group-field">
                      <Row className="w-100">
                        <Col lg={6} md={6} xs={12}>
                          <Field
                            name="loa_id"
                            component={renderRadioButtonField}
                            label={t('LBL.CURRENT_PERIOD')}
                            radioValue="1"
                            defaultChecked
                            disabled={disabledInput}
                          />
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                          <Field
                            name="loa_id"
                            component={renderRadioButtonField}
                            label={t('LBL.NEXT_PERIOD')}
                            disabled={disabledInput}
                            radioValue="2"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.INSTALLMENT')}</span>
                    <div className="form__form-group-field number-form">
                      <Row>
                        <Col md={4} xs={12}>
                          <div className="d-flex align-items-center">
                            <div className="w-100 number-format">
                              <Field
                                maxLength="3"
                                name="term"
                                component={renderTextInput}
                                type="text"
                                disabled={disabledInput}
                                onChange={(val) => this.handleInstallmentMonthPeriod(val.target.value)}
                              />
                            </div>
                            <div className="label-form-loan" style={{ marginBottom: '15px' }}>
                              {t('LBL.MONTH')}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} xs={12}>
                          <div className="d-flex align-items-center">
                            <div className="w-100">
                              <span
                                className="form__form-group-error-custom"
                                style={{ display: this.state.spanErrorinstallement }}
                              >
                                installment field shouldn’t be empty
                              </span>
                              <NumberFormat
                                maxLength="16"
                                style={{ textAlign: 'right' }}
                                thousandSeparator="."
                                decimalSeparator=","
                                allowedDecimalSeparators={[',']}
                                disabled
                                type="text"
                                prefix="Rp "
                                value={this.state.installment}
                                onValueChange={(val) => {
                                  const a = val.value;
                                  this.setState({ installment: a });
                                  this.setState({ spanErrorinstallement: 'none' });
                                }}
                              />
                            </div>
                            <div className="label-form-loan">
                              /{t('LBL.MONTH')}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.REMARK')}</span>
                    <div className="form__form-group-field">
                      <Field
                        maxLength="200"
                        disabled={disabledInput}
                        name="remarks"
                        component="textarea"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>{t('FRM.CANCEL')}</Button>
          <Button
            color="primary"
            type="submit"
            form="addloan"
            // disabled={pristine}
            disabled={disabledButton}
            onClick={this.toggleAndRefresh}
          > {t('FRM.SAVE')}
          </Button>{' '}
        </ModalFooter>
      </Col>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.lot_id) {
    errors.lot_id = 'Type field shouldn’t be empty';
  }
  if (!values.loan_name) {
    errors.loan_name = 'Loan name field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'reduxFunctionAcc', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(FunctionAccessPageX));
