/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
// import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // UncontrolledDropdown,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../../../../shared/components/table/viewListTable';

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);


renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    vslue: PropTypes.string,
  })).isRequired,
};

// eslint-disable-next-line no-unused-vars
// const data01 = [{ name: 'Permanent', value: 70, fill: '#2C96F8' },
// { name: 'Contract', value: 20, fill: '#f6da6e' },
// { name: 'Probation', value: 10, fill: '#4ce1b6' }];

class EmpStatus extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    getmenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('LBL.TOTAL') },
      { 3: t('LBL.PERCENTAGE') },
    ];
    this.state = {
      token: '',
      apiws: '',
      iduser: '',
      data01: [],
      dataWidth: ['5%', '40%', '25%', '30%'],
      urlData: '',
    };
    this.getTableData = this.getTableData.bind(this);
    this.getDetail = this.getDetail.bind(this);
  }
  componentDidMount = () => {
    // alert(this.props.datan);
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({
      iduser: ls.get('user_cred').usr_id,
    }, () => {
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/employee/getempstatus/` });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('LBL.TOTAL') },
      { 3: t('LBL.PERCENTAGE') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getPercentageEms() {
    const { token, apiws } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post(
      `${apiws}/employee/getjoblevelpercentage/`,
      '',
      config,
    ).then((response) => {
      const rpv = response.data.data;
      const arr = [];
      rpv.map(obj => (
        arr.push({
          name: obj.name, total: obj.total, value: obj.value, fill: obj.fill,
        })
      ));
      this.setState({ data01: arr });
      console.log(this.state.data01);
    }).catch(() => {
      // console.log(error);
    });
  }

  getDetail() {
    const detailems = true;
    const allmenu = false;
    setTimeout(() => {
    }, 200); this.props.getmenu(detailems, allmenu);
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <div className="dashboard__chart chart_border">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">
                {t('LBL.EMPLOYEE_STATUS')}
              </div>
              <div className="w-100" style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => this.getDetail()}
                >{t('LBL.DETAIL')}
                </Button>
              </div>
              {/* <div className="other-chart-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle className="icon" outline>
                        <FontAwesomeIcon icon={faBars} />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown__menu">
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Another Action</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div> */}
            </div>
          </div>
          <div style={{ padding: '0px 0px 10px 0px' }}>
            <ViewListTable
              url={this.state.urlData}
              heads={this.heads}
              primaryKey="ems_id"
              widthTable={this.state.dataWidth}
              displayStyleHead="none"
              onRef={ref => (this.child = ref)}
              buttonAction="noButton"
              searching="none"
            />
          </div>
        </div>
      </Card>
    );
  }
}


export default (translate('global')(EmpStatus));
