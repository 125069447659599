import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPassword = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card" style={{ padding: '30px' }}>
        <Container>
          <Row>
            <Col xs={12} md={12} xl={12} lg={12}>
              <ForgotPasswordForm onSubmit />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>
);

export default ForgotPassword;
