import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';

const BgImg = `${process.env.PUBLIC_URL}/img/bg-login-honorica.png`;

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <Container>
          <Row>
            <Col xs={12} md={8} xl={8} lg={8}>
              <img className="bg-login-card" src={BgImg} alt="bg-honorica" />
            </Col>
            <Col xs={12} md={4} xl={4} lg={4}>
              <LogInForm onSubmit />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>
);

export default LogIn;
