/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable brace-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { translate } from 'react-i18next';
import { reset } from 'redux-form';
import FormAddMenu from './Formadd';
import Treemenu from './TreeMenu';
import axios from 'axios';
import ls from 'local-storage';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/fontawesome-free-solid';
import PropTypes from 'prop-types';

function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.men_id] = arrElem;
    mappedArr[arrElem.men_id].children = [];
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const id in mappedArr) {
    // eslint-disable-next-line no-prototype-builtins
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.reference) {
        if (mappedElem.children.length === 0) {
          mappedArr[mappedElem.reference].children.push({
            men_id: mappedElem.men_id,
            title: mappedElem.menu,
            expanded: true,
            children: mappedElem.children,
          });
        } else {
          mappedArr[mappedElem.reference].children.push({
            men_id: mappedElem.men_id,
            title: mappedElem.menu,
            expanded: true,
            children: mappedElem.children,
          });
        }
        // eslint-disable-next-line brace-style
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push({
          men_id: mappedElem.men_id,
          title: mappedElem.menu,
          expanded: true,
          children: mappedElem.children,
        });
      }
    }
  }

  return tree;
}

class MenuSetting extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    // onDelete: PropTypes.func.isRequired,
    inUpdate: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor() {
    super();
    this.state = {
      dataTrees: [],
      apiws: '',
      token: '',
      editMode: false,
      arrMenu: [],
      defaultValues: {},
      modalFullScreen: false,
      nameUnitOrg: '',
    };
    this.dataTreeLoad = this.dataTreeLoad.bind(this);
    this.loadaftersubmit = this.loadaftersubmit.bind(this);
    this.editTreeModal = this.editTreeModal.bind(this);
    this.updateTree = this.updateTree.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglefullmode = this.togglefullmode.bind(this);
    this.fulltree = this.fulltree.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillMount() {
    const setdefaultvalue = {
      men_id: '',
      level_menu: '',
      parent_menu: '',
      menu: '',
      weight: '',
      url: '',
      file: '',
      is_show: '',
      is_favorite: '',
      lbl_code: '',
    };
    this.setState({ defaultValues: setdefaultvalue });
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.dataTreeLoad();
      this.getButton();
    });
  }

  componentWillReceiveProps() {
    const { onUpdate } = this.props;
    if (onUpdate) {
      this.loadSelect();
      this.props.inUpdate();
    }
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              // this.setState({ addcond: dt.fua_add });
              // this.setState({ editcond: dt.fua_edit });
              // this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }
  getMenu(val) {
    console.log(val);
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const body = { level: parseInt(val, 10) - 1 };
    axios
      .post(`${apiws}/menu/getMenuByLevel/`, body, config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.men_id, label: obj.menu }),
          );

          this.setState({ arrMenu: Arr });
        }
        console.log(this.state.arrMenu);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  dataTreeLoad() {
    const { token, apiws, slcunit } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let datatree = [];
    axios
      .post(`${apiws}/menu/getAllitem/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          datatree = unplet(response.data.data);
          this.setState({ dataTrees: datatree });
        }

        const dataResponse = response.data.data;
        const Arr = slcunit;
        if (dataResponse.length === 0) {
          Arr.push({ value: 0, label: 'Menu' });
          this.setState({ slcunit: Arr });
        } else {
          Arr.push({ value: 0, label: 'Menu' });
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ slcunit: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  fulltree() {
    this.setState({ modalFullScreen: true });
  }

  loadaftersubmit(result, dispatch, props) {
    this.setState({
      slcunit: [],
      editMode: false,
      defaultValues: {},
      nameUnitOrg: '',
    });
    setTimeout(() => {
      this.dataTreeLoad();
    }, 500);
    // this.props.onUpdate();
    // dispatch(reset('form_add_menu'));
  }

  editTreeModal(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    // let datatree = [];
    axios
      .post(`${apiws}/menu/getallitembyid/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataValues = response.data.data[0];
          this.setState({ nameUnitOrg: dataValues.menu });
          this.getMenu(dataValues.level);

          const setdefaultvalue = {
            men_id: dataValues.men_id,
            level_menu: dataValues.level,
            parent_menu: dataValues.reference,
            menu: dataValues.menu,
            weight: dataValues.weight,
            url: dataValues.url,
            default_icon: dataValues.default_icon,
            is_show: dataValues.is_show === '1' ? true : false,
            // is_favorite: dataValues.is_favorite === '1' ? true : false,
            lbl_code: dataValues.lbl_code,
          };

          this.setState({ defaultValues: setdefaultvalue });
        }
      })
      .catch(() => {
        // console.log(error);
      });
    this.setState({ editMode: true });
  }

  updateTree(datatree) {
    this.setState({ dataTrees: datatree });
  }

  toggle() {
    this.setState(
      {
        defaultValues: {},
        editMode: !this.state.editMode,
      },
      () => {},
    );
  }

  togglefullmode() {
    this.setState(
      {
        modalFullScreen: !this.state.modalFullScreen,
      },
      () => {},
    );
  }

  //   deleteItem = () => {
  //     this.props.onDelete();
  //   }

  render() {
    const {
      dataTrees,
      defaultValues,
      editMode,
      arrMenu,
      modalFullScreen,
      orglvl,
    } = this.state;
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col md={6}>
            <FormAddMenu
              initialValues={defaultValues}
              editMode={editMode}
              arrMenu={arrMenu}
              editModal={this.toggle}
              onSubmitSuccess={this.loadaftersubmit}
              editUnitOrg={this.state.nameUnitOrg}
              addcond="1"
            />
          </Col>
          <Col md={6} style={{ height: '100%' }}>
            <button
              className="btn btn-secondary btn-sm"
              style={{
                verticalAlign: 'middle',
                padding: '4px 8px',
                marginBottom: '0px',
                marginTop: '15px',
                marginRight: '40px',
                paddingRight: '3px',
                position: 'absolute',
                top: '5px',
                right: '15px',
                zIndex: '100',
              }}
              onClick={() => this.fulltree()}
            >
              <FontAwesomeIcon icon={faExpandArrowsAlt} />
            </button>
            <Treemenu
              dataTree={dataTrees}
              editMode={this.editTreeModal}
              loadTree={this.dataTreeLoad}
              updateTree={this.updateTree}
              editcond="1"
              deletecond="1"
              // deleteMode={this.deleteItem}
              // deleteToggle={this.state.deleteConfirm}
            />
            <Modal
              isOpen={modalFullScreen}
              toggle={this.togglefullmode}
              className="modal-dialog modal-input"
              style={{ maxWidth: '1050px' }}
            >
              <ModalHeader toggle={this.togglefullmode}>
                {t('FRM.MENU')}
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: '450px',
                  height: '400px',
                  overflowY: 'auto',
                }}
              >
                <Treemenu
                  fullMode
                  dataTree={dataTrees}
                  editMode={this.editTreeModal}
                  loadTree={this.dataTreeLoad}
                  updateTree={this.updateTree}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.togglefullmode}>
                  {t('FRM.CANCEL')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('global')(MenuSetting);
