/*
 * Page News & Updates Preferences
 * Notes: Master Data News & Updates
 * @author Fakhrurrozi
 * @date 12/04/2021
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable no-const-assign */
/* eslint-disable max-len, array-callback-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars, no-unneeded-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../../Preferences/notificationMessages';
// import showNotifications from '../show';
import PropTypes from 'prop-types';
import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderPhoneInput from '../../../../shared/components/form/InputPhone';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderMaskInput from '../../../../shared/components/form/InputMask';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import utils from '../../../../utils';
import moment from 'moment';

const noXMargin = { marginLeft: '0', marginRight: '0' };
let exfingerbyemp = [];
let empcodes = [];
const exallfingerid = [];

class EmployeeDataPage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    parmid: PropTypes.array.isRequired,
    rtbreligion: PropTypes.array.isRequired,
    rtbidentitytype: PropTypes.array.isRequired,
    rtbmaritalstatus: PropTypes.array.isRequired,
    rtbfamilyrelation: PropTypes.array.isRequired,
    rtbbloodtype: PropTypes.array.isRequired,
    rtblasteducation: PropTypes.array.isRequired,
    rtbnationality: PropTypes.array.isRequired,
    fingerid: PropTypes.array.isRequired,
    tempform: PropTypes.array.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    empCode: PropTypes.func.isRequired,
    allEmpCode: PropTypes.func.isRequired,
    url_ktp: PropTypes.string.isRequired,
    url_sim: PropTypes.string.isRequired,
    url_passport: PropTypes.string.isRequired,
    typeFileKTP: PropTypes.string.isRequired,
    typeFileSIM: PropTypes.string.isRequired,
    typeFilePassport: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      displaySpinner: 'none',
      displaySave: '',
      // tempForm: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { tempform } = props;
    if (props.tempform.length === undefined && state.tempForm === undefined) {
      props.initialize({
        ...tempform,
        dob: moment(tempform.dob),
      });
      exfingerbyemp = props.fingerid;
      empcodes = props.allEmpCode;
      return {
        tempForm: props.tempform,
      };
    }
    return null;
  }

  componentDidMount() {
    this.setState({
      token: ls.get('token'),
      apiws: ls.get('ws_ip'),
      idUser: ls.get('user_cred').usr_id,
    });
    // , async () => {
    //   if (this.props.parmid.usr_id !== undefined) {
    //     await this.getFingerId(this.props.parmid.usr_id);
    //   } else {
    //     await this.getallfinger();
    //   }
    // });
  }

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async handleSubmit(values) {
    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    await this.sleep(1000);
    const idnarr = [];
    let ctr_id;

    if (typeof values.identity_no_ktp !== 'undefined' && values.identity_no_ktp !== '') {
      idnarr.push({
        identity: values.identity_ktp,
        identity_no: values.identity_no_ktp,
        expired_date:
          typeof values.expired_date_ktp === 'undefined' ||
          values.expired_date_ktp === null
            ? ''
            : moment(values.expired_date_ktp).format('YYYY-MM-DD'),
        file_ktp: values.file_ktp,
        file_name_ktp: values.file_name_ktp,
      });
    }

    if (
      typeof values.identity_no_sim !== 'undefined' &&
      values.identity_no_sim !== ''
    ) {
      idnarr.push({
        identity: values.identity_sim,
        identity_no: values.identity_no_sim,
        expired_date:
          typeof values.expired_date_sim === 'undefined' ||
          values.expired_date_sim === null
            ? null
            : moment(values.expired_date_sim).format('YYYY-MM-DD'),
        file_sim: values.file_sim,
        file_name_sim: values.file_name_sim,
      });
    }

    if (
      typeof values.identity_no_paspor !== 'undefined' &&
      values.identity_no_paspor !== ''
    ) {
      idnarr.push({
        identity: values.identity_paspor,
        identity_no: values.identity_no_paspor,
        expired_date:
          typeof values.expired_date_paspor === 'undefined' ||
          values.expired_date_paspor === null
            ? null
            : moment(values.expired_date_paspor).format('YYYY-MM-DD'),
        file_passport: values.file_passport,
        file_name_passport: values.file_name_passport,
      });
    }

    const dynIdentity = JSON.stringify(idnarr);
    const rtb_id =
      typeof values.rtb_id === 'object' ? values.rtb_id.value : values.rtb_id;
    let rtf_id = '';
    if (values.rtf_id !== null) {
      rtf_id =
        typeof values.rtf_id === 'object' ? values.rtf_id.value : values.rtf_id;
    }
    const last_education =
      typeof values.last_education === 'object'
        ? values.last_education.value
        : values.last_education;
    const rtg_id =
      typeof values.rtg_id === 'object' ? values.rtg_id.value : values.rtg_id;
    const rtm_id =
      typeof values.rtm_id === 'object' ? values.rtm_id.value : values.rtm_id;
    const citizen =
      typeof values.citizen === 'object'
        ? values.citizen.value
        : values.citizen;
    if (citizen === 2) {
      ctr_id =
        typeof values.ctr_id === 'object' ? values.ctr_id.value : values.ctr_id;
    } else {
      ctr_id = '';
    }
    const sex = typeof values.sex === 'object' ? values.sex.value : values.sex;
    let path_photo;
    let file_ktp;
    let file_sim;
    let file_passport;
    let file_npwp;
    if (values.path_photo !== null) {
      path_photo =
        typeof values.path_photo === 'object'
          ? values.path_photo.file
          : values.path_photo;
    } else {
      path_photo = '';
    }
    if (values.file_ktp !== null) {
      file_ktp =
        typeof values.file_ktp === 'object'
          ? values.file_ktp.file
          : values.file_ktp;
    } else {
      file_ktp = '';
    }
    if (values.file_sim !== null) {
      file_sim =
        typeof values.file_sim === 'object'
          ? values.file_sim.file
          : values.file_sim;
    } else {
      file_sim = '';
    }
    if (values.file_passport !== null) {
      file_passport =
        typeof values.file_passport === 'object'
          ? values.file_passport.file
          : values.file_passport;
    } else {
      file_passport = '';
    }
    if (values.file_npwp !== null) {
      file_npwp =
        typeof values.file_npwp === 'object'
          ? values.file_npwp.file
          : values.file_npwp;
    } else {
      file_npwp = '';
    }
    let npwp_date;
    if (
      typeof values.npwp_date === 'undefined' ||
      values.npwp_date === null ||
      values.npwp_date === ''
    ) {
      npwp_date = '';
    } else {
      npwp_date = moment(values.npwp_date).format('YYYY-MM-DD');
    }
    let ogf_id;
    if (typeof values.ogf_id !== 'undefined') {
      if (values.ogf_id !== null) {
        ogf_id =
          typeof values.ogf_id === 'object'
            ? values.ogf_id.value
            : values.ogf_id;
      } else {
        ogf_id = '';
      }
    } else {
      ogf_id = '';
    }
    const fingerId = values.finger_id;
    const convertdata = {
      dyn_identity: dynIdentity,
      rtb_id,
      rtf_id,
      rtg_id,
      rtm_id,
      last_education,
      finger_id: fingerId,
      sex,
      dob: moment(values.dob).format('YYYY-MM-DD'),
      join_date: moment(values.join_date).format('YYYY-MM-DD'),
      path_photo,
      file_ktp,
      file_sim,
      file_passport,
      file_npwp,
      npwp_date,
      citizen,
      ogf_id,
      ctr_id,
    };
    const alldata = { ...values, ...convertdata };
    const config = {
      headers: {
        // eslint-disable-next-line quote-props
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    const { idUser, apiws } = this.state;

    const id = values.emp_id;
    const data = { ...alldata, muser_id: idUser };
    const url = `${apiws}/employee/updateEmployee/${id}`;

    // eslint-disable-next-line guard-for-in
    const getFormData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    axios
      .post(url, getFormData, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          setTimeout(() => {
            this.loadEmployee(id);
          }, 200);
          this.setState({ displaySpinner: 'none', displaySave: '' });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
          this.setState({ displaySpinner: 'none', displaySave: '' });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  async loadEmployee(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(`${apiws}/employee/getAllItemById/${id}`, '', config)
        .then((response) => {
          const dataResponse = response.data.data;
          let identityEmployee = {
            idty_ktp: 'KTP',
            idty_sim: 'SIM',
            idty_paspor: 'PASPORT',
          };
          if (dataResponse.identity.length !== 0) {
            dataResponse.identity.forEach((element) => {
              if (element.rti_id === 1) {
                identityEmployee = {
                  ...identityEmployee,
                  identity_ktp: element.rti_id,
                  identity_no_ktp: element.identity_number,
                  file_ktp: element.file_path,
                  file_name_ktp: element.file_name,
                };
              }
              if (element.rti_id === 2) {
                identityEmployee = {
                  ...identityEmployee,
                  identity_sim: element.rti_id,
                  identity_no_sim: element.identity_number,
                  expired_date_sim: element.expired_date === '' ? null : moment(element.expired_date),
                  file_sim: element.file_path,
                  file_name_sim: element.file_name,
                };
              }
              if (element.rti_id === 3) {
                identityEmployee = {
                  ...identityEmployee,
                  identity_paspor: element.rti_id,
                  identity_no_paspor: element.identity_number,
                  expired_date_paspor: element.expired_date === '' ? null : moment(element.expired_date),
                  file_passport: element.file_path,
                  file_name_passport: element.file_name,
                };
              }
            });
          }
          const datamaping = {
            ...dataResponse.user[0],
            ...identityEmployee,
            dob: moment(dataResponse.user[0].dob),
          };
          this.setState({ tempForm: datamaping });
          this.props.initialize(datamaping);
        })
        .catch((e) => {

        });
    });
  }


  downloadIdentity(url_ktp) {
    fetch(
      url_ktp,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      },
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        const randomSTR = Math.random().toString(36).substring(7, 20);
        const format = randomSTR.concat('.pdf');
        link.href = url;
        link.setAttribute('download', format);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  async getallfinger() {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const datatree = [];

      const usrd = ls.get('user_cred').usr_id;
      axios
        .post(`${apiws}/employee/getAll/`, '', config)
        .then((response) => {
          const res = response.data;
          const finger = [];
          // eslint-disable-next-line array-callback-return
          res.map((data) => {
            exallfingerid.push(data.finger_id);
          });
          resolve();
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    });
  }

  identity = () => {
    // if (val.value === 1) {
    //   this.setState({ expired: true });
    // } else {
    //   this.setState({ expired: false });
    // }
  };

  renderIdentity = ({
                      fields, meta: { error }, rtbidentitytype, t,
                    }) => (
    <div>
      {fields.map((identity, index) => (
        <Row style={noXMargin}>
          <div className='identity_form_group'>
            <Col md={4} sm={12}>
              <div className='form__form-group'>
                <span className='form__form-group-label'>
                  {t('LBL.IDENTITY')}{' '}
                </span>
                <div className='form__form-group-field'>
                  <Field
                    name={`${identity}.identity`}
                    component={renderSelectField}
                    type='textemployee'
                    options={rtbidentitytype}
                    placeholder='Identity'
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className='form__form-group'>
                <span className='form__form-group-label'>
                  {t('LBL.IDENTITY_NUMBER')}
                </span>
                <div className='form__form-group-field'>
                  <Field
                    name={`${identity}.identity_no`}
                    component={renderTextInput}
                    type='number'
                    placeholder='Your Identity No'
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <Row>
                <Col md={8} sm={8} xs={8}>
                  <div className='form__form-group'>
                    <span className='form__form-group-label'>
                      {t('LBL.EXPIRED_DATE')}
                    </span>
                    <div className='form__form-group-field'>
                      <Field
                        name={`${identity}.expired_date`}
                        component={renderDatePickerField}
                      />
                      <div className='form__form-group-icon'>
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </Col>
                {index === 0 ? (
                  <Col md={4} sm={4}>
                    <Button
                      className='add_btn_identity'
                      onClick={() => fields.push()}
                      color='primary'
                      size='sm'
                      disabled={fields.length > 2 ? true : false}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                ) : (
                  <Col md={4} sm={4}>
                    <Button
                      className='add_btn_identity'
                      onClick={() => fields.remove(index)}
                      color='danger'
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </div>
        </Row>
      ))}
      {error && <li className='error'>{error}</li>}
    </div>
  );

  render() {
    const {
      handleSubmit,
      rtbreligion,
      rtbidentitytype,
      rtbmaritalstatus,
      rtbfamilyrelation,
      rtbbloodtype,
      rtblasteducation,
      rtbnationality,
      t,
      url_ktp,
      url_sim,
      url_passport,
    } = this.props;
    const { statusEdit, displaySave, displaySpinner } = this.state;

    let labelButton;
    let colorButton;
    if (statusEdit) {
      labelButton = t('FRM.EDIT');
      colorButton = 'success';
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
    }

    // if (this.state.statusEdit) {
    //   return (<Redirect to={{
    //     pathname: '/administration/employee',
    //     state: { men_id: this.props.parmid.men_employee, fga_id: this.props.parmid.fga_id },
    //   }}
    //   />);
    // }

    return (
      <form
        className='form wizard__form'
        onSubmit={handleSubmit(this.handleSubmit)}
        name='newsform'
        id='newsform'
      >
        <input type='hidden' name='anu_id' value='' />
        <Container>
          <div style={{ padding: '15px' }}>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.EMPLOYEE_CODE')}
                  </span>
                  <div className='form__form-group-field'>
                    <input type='hidden' name='emp_id' />
                    <Field
                      name='emp_code'
                      component={renderTextInput}
                      type='text'
                      placeholder={t('LBL.EMPLOYEE_CODE')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.FIRST_NAME')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='first_name'
                      component={renderTextInput}
                      type='text'
                      placeholder='First Name'
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.LAST_NAME')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='last_name'
                      component={renderTextInput}
                      type='text'
                      placeholder='Last Name'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.EMAIL')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='email'
                      component={renderTextInput}
                      type='text'
                      placeholder='username@domain'
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.MOBILE')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='mobile'
                      component={renderPhoneInput}
                      placeholder='+__ ____ ____'
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.PHONE')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='phone'
                      component={renderMaskInput}
                      type='textemployee'
                      mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      placeholder='+___ ____ ____'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.ADDRESS')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='emp_address'
                      component={renderTextInput}
                      type='textareaemp'
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.POSTAL_CODE')}
                  </span>
                  <div className='form__form-group-field number-form'>
                    <Field
                      name='postal_code'
                      component={renderTextInput}
                      type='number'
                      placeholder='Postal Code'
                      maxLength={6}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.DATE_OF_BIRTH')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field name='dob' component={renderDatePickerField} />
                    <div className='form__form-group-icon'>
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.PLACE_OF_BIRTH')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='pob'
                      component={renderTextInput}
                      type='text'
                      placeholder='Your Place of Birth'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>{t('LBL.SEX')}</span>
                  <div className='form__form-group-field'>
                    <Field
                      name='sex'
                      component={renderSelectField}
                      type='text'
                      options={[
                        { value: '1', label: 'Male' },
                        { value: '2', label: 'Female' },
                      ]}
                      placeholder='Select Gender'
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.BLOOD_TYPE')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='rtb_id'
                      component={renderSelectField}
                      type='text'
                      options={rtbbloodtype}
                      placeholder={t('LBL.SELECT_BLOOD_TYPE')}
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.LAST_EDUCATION')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      // value={1}
                      name='last_education'
                      component={renderSelectField}
                      type='textemployee'
                      props={{ disableTouched: true }}
                      options={[
                        { value: 1, label: 'SD' },
                        { value: 2, label: 'SMP' },
                        { value: 3, label: 'SMA / SMK' },
                        { value: 4, label: 'D1' },
                        { value: 5, label: 'D2' },
                        { value: 6, label: 'D3' },
                        { value: 7, label: 'D4' },
                        { value: 8, label: 'S1' },
                        { value: 9, label: 'S2' },
                        { value: 10, label: 'S3' },
                      ]}
                      placeholder='Last Education'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.MARITAL_STATUS')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='rtm_id'
                      component={renderSelectField}
                      type='text'
                      options={rtbmaritalstatus}
                      placeholder='Select Marital Status'
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.RELIGION')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='rtg_id'
                      component={renderSelectField}
                      type='textemployee'
                      options={rtbreligion}
                      placeholder='Religion'
                    />
                  </div>
                </div>
              </Col>
              <Col md={2} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.CITIZENSHIP')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='citizen'
                      component={renderSelectField}
                      type='text'
                      options={[
                        { value: 1, label: 'WNI' },
                        { value: 2, label: 'WNA' },
                      ]}
                      placeholder='Citizenship'
                    />
                  </div>
                </div>
              </Col>
              {this.props.tempform.displaycountry === '2' && (
                <Col md={2} sm={12}>
                  <div className='form__form-group'>
                    <span className='form__form-group-label'>
                      {t('LBL.COUNTRY')}
                    </span>
                    <div className='form__form-group-field'>
                      <Field
                        name='ctr_id'
                        component={renderSelectField}
                        type='textemployee'
                        options={rtbnationality}
                        placeholder='Nationality'
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <Row style={noXMargin}>
              <div className='identity_form_group'>
                <Col md={4} sm={12}>
                  <input type='hidden' name='identity_ktp' value='1' />
                  <input type='hidden' name='file_name_ktp' />
                  <div className='form__form-group'>
                    <span className='form__form-group-label'>
                      {t('LBL.IDENTITY')}{' '}
                    </span>
                    <div className='form__form-group-field'>
                      <Field
                        name='idty_ktp'
                        component={renderTextInput}
                        type='textemployee'
                        placeholder='Identity'
                        disabled='true'
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={12}>
                  <div className='form__form-group'>
                    <span className='form__form-group-label'>
                      {t('LBL.IDENTITY_NUMBER')}
                    </span>
                    <div className='form__form-group-field'>
                      <Field
                        name='identity_no_ktp'
                        component={renderTextInput}
                        type='number'
                        placeholder='Your Identity No'
                        maxLength={16}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={2} sm={12}>
                  <Row>
                    <Col md={8} sm={8} xs={8}>
                      <div className='form__form-group'>
                        <span className='form__form-group-label'>
                          {t('LBL.EXPIRED_DATE')}
                        </span>
                        <div className='form__form-group-field'>
                          <Field
                            name='expired_date'
                            component={renderDatePickerField}
                            disabled='true'
                          />
                          <div className='form__form-group-icon'>
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} sm={12}>
                  <div className='form__form-group'>
                    <span className='form__form-group-label'>
                      {t('LBL.UPLOAD_PHOTO')}
                    </span>
                    <div className='form__form-group-field'>
                      <Field
                        name='file_ktp'
                        allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                        maxSize={1000}
                        component={renderFileInputField}
                        type='textemployee'
                      />
                    </div>
                    {this.props.typeFileKTP === 'pdf' && (
                      <button
                        type='button'
                        onClick={() => this.downloadIdentity(this.props.url_ktp)}
                        className='btn btn-block btn-success btn-data-table'
                      >
                        {t('FRM.DOWNLOAD')}
                      </button>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
            <Row style={noXMargin}>
              <div className='identity_form_group'>
                <Col md={4} sm={12}>
                  <input type='hidden' name='identity_sim' value='2' />
                  <input type='hidden' name='file_name_sim' />
                  <div className='form__form-group'>
                    <div className='form__form-group-field'>
                      <input type='hidden' name='identity_sim' value='2' />
                      <Field
                        name='idty_sim'
                        component={renderTextInput}
                        type='textemployee'
                        placeholder='Identity'
                        disabled='true'
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={12}>
                  <div className='form__form-group'>
                    <div className='form__form-group-field'>
                      <Field
                        name='identity_no_sim'
                        component={renderTextInput}
                        type='number'
                        placeholder='Your Identity No'
                      />
                    </div>
                  </div>
                </Col>
                <Col md={2} sm={12}>
                  <Row>
                    <Col md={8} sm={8} xs={8}>
                      <div className='form__form-group'>
                        <div className='form__form-group-field'>
                          <Field
                            name='expired_date_sim'
                            component={renderDatePickerField}
                          />
                          <div className='form__form-group-icon'>
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} sm={12}>
                  <div className='form__form-group'>
                    <div className='form__form-group-field'>
                      <Field
                        name='file_sim'
                        allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                        maxSize={1000}
                        component={renderFileInputField}
                        type='textemployee'
                      />
                    </div>
                    {this.props.typeFileSIM === 'pdf' && (
                      <button
                        type='button'
                        onClick={() => this.downloadIdentity(this.props.url_sim)}
                        className='btn btn-block btn-success btn-data-table'
                      >
                        {t('FRM.DOWNLOAD')}
                      </button>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
            <Row style={noXMargin}>
              <div className='identity_form_group'>
                <Col md={4} sm={12}>
                  <input type='hidden' name='identity_paspor' value='3' />
                  <input type='hidden' name='file_name_passport' />
                  <div className='form__form-group'>
                    <div className='form__form-group-field'>
                      <input type='hidden' name='identity_paspor' value='3' />
                      <Field
                        name='idty_paspor'
                        component={renderTextInput}
                        type='textemployee'
                        placeholder='Identity'
                        disabled='true'
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={12}>
                  <div className='form__form-group'>
                    <div className='form__form-group-field'>
                      <Field
                        name='identity_no_paspor'
                        component={renderTextInput}
                        type='number'
                        placeholder='Your Identity No'
                      />
                    </div>
                  </div>
                </Col>
                <Col md={2} sm={12}>
                  <Row>
                    <Col md={8} sm={8} xs={8}>
                      <div className='form__form-group'>
                        <div className='form__form-group-field'>
                          <Field
                            name='expired_date_paspor'
                            component={renderDatePickerField}
                          />
                          <div className='form__form-group-icon'>
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} sm={12}>
                  <div className='form__form-group'>
                    <div className='form__form-group-field'>
                      <Field
                        name='file_passport'
                        allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                        maxSize={1000}
                        component={renderFileInputField}
                        type='textemployee'
                      />
                    </div>
                    {this.props.typeFilePassport === 'pdf' && (
                      <button
                        type='button'
                        onClick={() => this.downloadIdentity(this.props.url_passport)}
                        className='btn btn-block btn-success btn-data-table'
                      >
                        {t('FRM.DOWNLOAD')}
                      </button>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
            <Row style={noXMargin}>
              <Col md={12}>
                <div className='adj-panel-profile'>
                  <div className='title-adj'>{t('LBL.EMERGENCY_CONTACT')}</div>
                  <div className='body-adj'>
                    <Row>
                      <Col md={4} sm={12}>
                        <div className='form__form-group'>
                          <span className='form__form-group-label'>
                            {t('LBL.NAME')}
                          </span>
                          <div className='form__form-group-field'>
                            <Field
                              name='relation_name'
                              component={renderTextInput}
                              props={{ disableTouched: true }}
                              type='textemployee'
                              placeholder='Relation Name'
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className='form__form-group form__form-group--address'>
                          <span className='form__form-group-label'>
                            {t('LBL.RELATION')}
                          </span>
                          <div className='form__form-group-field'>
                            <Field
                              name='rtf_id'
                              component={renderSelectField}
                              type='textemployee'
                              options={rtbfamilyrelation}
                              placeholder='Select Relation'
                            />
                            {/* <Field
                              name="relation_name"
                              component={renderTextInput}
                              type="textemployee"
                              placeholder="Name"
                            /> */}
                          </div>
                        </div>
                      </Col>
                      <Col md={4} sm={12}>
                        <div className='form__form-group'>
                          <span className='form__form-group-label'>
                            {t('LBL.PHONE')}
                          </span>
                          <div className='form__form-group-field'>
                            <Field
                              name='emergency_contact'
                              component={renderPhoneInput}
                              placeholder='+__ ____ ____'
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={4} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.ATTENDANCE_TYPE_CODE')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='finger_id'
                      component={renderTextInput}
                      type='text'
                      props={{ disableTouched: true }}
                      placeholder='Fingerprint ID or Attendance Type Code'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col md={2} sm={12}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>
                    {t('LBL.UPLOAD_PHOTO')}
                  </span>
                  <div className='form__form-group-field'>
                    <Field
                      name='path_photo'
                      allowedType={['png', 'jpg', 'gif']}
                      maxSize={1000}
                      component={renderFileInputField}
                      type='textemployee'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={noXMargin}>
              <Col
                md='12'
                lg='12'
                sm='12'
                xs='12'
                style={{ textAlign: 'center' }}
              >
                <div>
                  <Link
                    className='link_action_tbl'
                    id='Edit'
                    to={{
                      pathname: '/administration/employee',
                    }}
                    style={{ 'margin-right': '10px', display: displaySave }}
                  >
                    <Button color='secondary'>{t('FRM.BACK')}</Button>
                  </Link>
                  <Button
                    color={colorButton}
                    form='newsform'
                    type='submit'
                    style={{ display: displaySave }}
                  >
                    {' '}
                    {labelButton}
                  </Button>
                  <Button
                    color='primary'
                    style={{ display: displaySpinner }}
                    type='button'
                  >
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      style={{ marginRight: '6px' }}
                    />
                    {t('FRM.LOADING')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </form>
      // <Container>
      //   <Row>
      //     <Col md={12} xs={12} lg={12} xl={12}>
      //     </Col>
      //   </Row>
      // </Container>
    );
  }
}

const validate = (values) => {
  let validation = {
    citizen: {
      fieldLabel: 'Citizenship',
      required: true,
    },
    emp_code: {
      fieldLabel: 'Employee code',
      required: true,
    },
    first_name: {
      fieldLabel: 'Employee name',
      required: true,
    },
    last_education: {
      fieldLabel: 'Last Education',
      required: true,
    },
    email: {
      fieldLabel: 'Email',
      required: true,
      isEmail: true,
    },
    mobile: {
      fieldLabel: 'Mobile Phone',
      required: true,
    },
    emp_address: {
      fieldLabel: 'Address',
      required: true,
    },
    postal_code: {
      fieldLabel: 'Postal code',
      required: true,
    },
    dob: {
      fieldLabel: 'Date of birth',
      required: true,
    },
    pob: {
      fieldLabel: 'Place of birth',
      required: true,
    },
    sex: {
      fieldLabel: 'Sex',
      required: true,
    },
    rtb_id: {
      fieldLabel: 'Blood type',
      required: true,
    },
    rtm_id: {
      fieldLabel: 'Marital status',
      required: true,
    },
    rtg_id: {
      fieldLabel: 'Religion',
      required: true,
    },
    finger_id: {
      fieldLabel: 'Finger ID',
      required: true,
    },
    jbd_id: {
      fieldLabel: 'Job field',
      required: true,
    },
    join_date: {
      fieldLabel: 'Join date',
      required: true,
    },
  };
  if (values?.identity_no_sim) {
    validation = {
      ...validation, expired_date_sim: {
        fieldLabel: 'Expired Date',
        required: true,
      },
    };
  }
  if (values?.identity_no_paspor) {
    validation = {
      ...validation,
      expired_date_paspor: {
        fieldLabel: 'Expired Date',
        required: true,
      },
    };
  }
  if (!values?.identity_no_ktp && !values?.identity_no_sim && !values?.identity_no_paspor) {
    validation = {
      ...validation, identity_no_ktp: {
        fieldLabel: 'Identity Number',
        required: true,
      },
      identity_no_sim: {
        fieldLabel: 'Identity Number',
        required: true,
      },
      identity_no_paspor: {
        fieldLabel: 'Identity Number',
        required: true,
      },
    };
  }

  const errors = utils.validate(validation, values);
  if (typeof values.citizen === 'object') {
    if (values.citizen.value === 2) {
      if (!values.ctr_id) {
        errors.ctr_id = 'Country shouldn`t be empty';
      }
    }
  }
  if (empcodes.length > 0) {
    if (empcodes.includes(values.emp_code)) {
      errors.emp_code = 'Employee code already exist';
    }
  }
  if (exfingerbyemp.length !== 0) {
    if (exfingerbyemp.indexOf(values.finger_id) !== -1) {
      errors.finger_id = 'Fingerid is available';
    }
  }
  return errors;
};

export default reduxForm({
  form: 'reduxEmployeeDataPage', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(EmployeeDataPage));
