import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ResetPasswordForm from './components/ResetPasswordForm';
import { withRouter } from 'react-router-dom';

const ResetPassword = withRouter(({ match }) => {
  const { token } = match.params;
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card" style={{ padding: '30px', maxWidth: '500px' }}>
          <Container>
            <Row>
              <Col xs={12} md={12} xl={12} lg={12}>
                <ResetPasswordForm onSubmit token={token} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
});

export default ResetPassword;
