/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Card, Alert } from 'reactstrap';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import PropTypes from 'prop-types';
import { store } from '../../../App/store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const LaptopIcon = `${process.env.PUBLIC_URL}/img/icon-laptop.png`;
const InfoIcon = `${process.env.PUBLIC_URL}/img/icon-info.png`;

class LoginActivity extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // location: PropTypes.func.isRequired,
    // datan: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      token: ls.get('token'),
      apiws: ls.get('ws_ip'),
      iduser: ls.get('user_cred').usr_id,
      totaldays: [],
      emp_id: ls.get('user_cred').emp_id,
      men_id: ls.get('men_id'),
      fga_id: ls.get('user_cred').fga_id,
    };

    this.getDataLog = this.getDataLog.bind(this);
  }

  componentDidMount = () => {
    if (this.props.user.userLog?.length == 0) {
      this.getDataLog();
    } else {
      // get latest data with delay
      setTimeout(() => {
        this.getDataLog();
      }, 2000);
    }

    ls.get('function_access').forEach((key) => {
      if (key.lbl_code === 'MEN.EMPLOYEE') {
        this.setState({ men_id: key.men_id });
      }
    });
  };

  getDataLog() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/Userlog/getDataLogActivity/${
          this.state.iduser
        }/5/0`,
        '',
        config,
      )
      .then((res) => {
        const response = res.data.data;
        console.log(response, '<<<');
        if (response.length > 0) {
          store.dispatch({ type: 'SET_LOG_ACTIVITY', data: response });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <div className="leave-balance-panel">
          <div className="leave-balance-panel-header">
            <div className="d-flex">
              <div className="w-100 mb-2">{t('MEN.USER_LOG')}</div>
            </div>
            <div className="d-flex">
              <Alert
                color="primary"
                className="p-1"
                style={{ borderRadius: 5 }}
              >
                <div className="icon-info mr-1">
                  <img src={InfoIcon} alt="leave-icon" width={20} />
                </div>
                <small className="mt-1">
                  {t('LBL.USER_LOG')}{' '}
                  <Link
                    to={{
                      pathname: '/account/change_password',
                    }}
                  >
                    {t('LBL.RESET_PASSWORD')}
                  </Link>
                </small>
              </Alert>
            </div>
          </div>
          <div className="leave-balance-panel-body">
            <ul className="leave-balance-list">
              {this.props.user.userLog?.length !== 0 && (
                <div>
                  {this.props.user.userLog?.map((value, index) => (
                    <div
                      key={`leaveBal_${index}`}
                      style={{ marginBottom: '15px' }}
                    >
                      <div className="d-flex" style={{ marginBottom: '10px' }}>
                        <div className="icon-leave">
                          <img src={LaptopIcon} alt="leave-icon" />
                        </div>
                        <div className="w-100">
                          <p className="title-balance">{value.user_agent}</p>
                          <p className="data-log">{value.ip}</p>
                          <p className="data-log">{value.last_access}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ul>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(LoginActivity));
