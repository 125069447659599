import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  SET_MENU_TREE,
  CLEAR_SIDEBAR,
} from '../actions/sidebarActions';

const initialState = {
  show: false,
  collapse: true,
  menuTree: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case SET_MENU_TREE:
      return { ...state, menuTree: action.data };
    case CLEAR_SIDEBAR:
      return initialState;
    default:
      return state;
  }
}
