/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { PureComponent } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/fontawesome-free-solid';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { store } from '../../../App/store';
import { connect } from 'react-redux';

const nodataimg = `${process.env.PUBLIC_URL}/img/notif-empty-illustration.png`;

class AlertNotifTab extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      redirect: false,
      redirectUrl: '',
      rows: [],
      notification: this?.props.user?.notification?.length > 0,
      trigger: false,
    };
    this.getNearlyExpiredContract = this.getNearlyExpiredContract.bind(this);
    this.goto = this.goto.bind(this);
  }

  componentDidMount = () => {
    this.setState(
      {
        token: ls.get('token'),
        apiws: ls.get('ws_ip'),
        idUser: ls.get('user_cred').usr_id,
      },
      () => {
      },
    );
    if (this.props.user.notification.length == 0) {
      this.getNearlyExpiredContract();
    } else {
      setTimeout(() => {
        this.getNearlyExpiredContract();
      }, 200);
    }
  };

  getNearlyExpiredContract = () => {
    const { t } = this.props;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/appNotification/getNearlyExpiredContract`,
        '',
        config,
      )
      .then((res) => {
        if (res?.data?.total > 0) {
          let arr = [
            {
              label: t('LBL.EMPLOYEE_OUT_OF_CONTRACT'),
              total: res.data.total,
            },
          ];
          this.setState({
            trigger: !this.state.trigger,
          });
          store.dispatch({ type: 'SET_USER_NOTIFICATION', data: arr });
            if (!this.state.notification) {
              this.setState({
                notification: true,
              });
            }
        } else {
          store.dispatch({ type: 'SET_USER_NOTIFICATION', data: [] });
            if (this.state.notification) {
              this.setState({
                notification: false,
              });
            }
        }
      });
  };

  goto = (location) => {
    this.setState({
      redirect: true,
      redirectUrl: location,
    });
  };

  render() {
    const { notification, redirect, redirectUrl } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: redirectUrl,
            state: {
              data: notification.data,
            },
          }}
        />
      );
    }

    let output;
    if (this?.props.user?.notification?.length === 0) {
      output = (
        <div className="taskAlertPanel">
          <div className="d-flex align-items-center no-data-box mt-0">
            <div className="no-data-img-tasklist notif-empty">
              <img src={nodataimg} alt="notif-empty" />
            </div>
            <div className="w-100 text-center">
              <p>
                There is no <b>Notification</b> today.
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this?.props.user?.notification?.length > 0) {
      output = (
        <div className="taskAlertPanel">
          <ul className="taskTab-list">
            {this?.props.user?.notification?.map((notif) => (
              <li>
                <i className="icon__task-list">
                  <FontAwesomeIcon icon={faBriefcase} />
                </i>{' '}
                {notif.label}
                <div className="task-detail">
                  <span
                    className="task-detail-badge bg-red"
                    onClick={() => {
                      this.goto('/notifications/outofcontract');
                    }}
                  >
                    {notif.total}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return output;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(AlertNotifTab));
