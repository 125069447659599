/*
 * Page User Account
 * Notes: Setting User Account Application
 * @author Gerdi
 * @date 06/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus, prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert, object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable-next-line class-methods-use-this */
/* eslint-disable-next-line react/sort-comp */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-lonely-if */
/* eslint-disable no-throw-literal */
/* eslint-disable no-cond-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faRedoAlt } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import renderMaskInput from '../../../../shared/components/form/InputMask';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import { Link } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import Spinner from 'react-bootstrap/Spinner';

const userExist = [];
const empExist = [];
let minLenPass;
let allowChar;
let allowUpp;
let allowLow;
let allowNum;
let usrid;
let usrnm;
let firstnm;
let lastnm;
let mail;
let remak;
let fgaid;
let is_all_emp_access;
let wiaid;
let sts;
class UserAccountSetting extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.ACCOUNT'),
      t('LBL.NAME'),
      t('LBL.STATUS'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      token: '',
      idUser: '',
      apiws: '',
      modal: false,
      statusEdit: false,
      titleModal: '',
      disablePassword: '',
      disableReset: 'none',
      disableButton: true,
      arrSaveData: [],
      arrFunctionAccess: [],
      arrEmp: [],
      arrHide: '',
      rows: [],
      limit: 10,
      offset: 0,
      page: 1,
      disablePreview: '',
      disableNext: '',
      modalShow: false,
      search: '',
      total: 0,
      totalPagination: 0,
      currentPagination: 0,
      labelIdxStart: 0,
      labelIdxEnd: 0,
      disableEmp: false,
      disableFirstName: false,
      disableFamilyName: false,
      disableEmail: false,
      disableUsername: false,
      showPassword: false,
      showRePassword: false,
      displayName: '',
      displayUserName: '',
      displayEmail: '',
      editcond: '',
      addcond: '',
      deletecond: '',
      superuser: false,
      submit: false,
    };
    this.reload = this.reload.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.changePage = this.changePage.bind(this);
    this.search = this.search.bind(this);
    this.createButtonPagination = this.createButtonPagination.bind(this);
    this.goto = this.goto.bind(this);

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.ChangeStatus = this.ChangeStatus.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.showRePassword = this.showRePassword.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentWillMount() {
    this.timer = null;
    this.next();
    this.getUser();
    this.getEmployee();
    this.getSettingPassword();
    // this.getFunctionAccess();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.ACCOUNT'),
      t('LBL.NAME'),
      t('LBL.STATUS'),
      t('FRM.FUNCTION'),
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          // console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  paginationNumber() {
    const totalPagination = this.state.total / this.state.limit;
    this.setState({
      totalPagination: Math.ceil(totalPagination),
    });

    const page_start = Number.parseInt(this.state.offset, 10) + 1;
    const page_end =
      Number.parseInt(this.state.limit, 10) +
      Number.parseInt(this.state.offset, 10);
    this.setState({
      labelIdxStart: page_start,
      labelIdxEnd: page_end > this.state.total ? this.state.total : page_end,
    });
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  showRePassword(e) {
    e.preventDefault();
    this.setState({
      showRePassword: !this.state.showRePassword,
    });
  }

  changePage(event) {
    this.setState(
      {
        limit: event.target.value,
      },
      () => {
        setTimeout(() => {
          this.reload();
        }, 50);
      },
    );
  }

  search(event) {
    this.setState({ search: event.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  previous() {
    let state_offset = this.state.offset - this.state.limit;
    this.setState({ modalShow: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ls.get('ws_ip')}/appuser/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              username: response.data.data[i].user_name,
              realname: response.data.data[i].realname,
              status: response.data.data[i].status,
              id: response.data.data[i].usr_id,
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: this.state.page - 1,
            offset: state_offset,
            modalShow: false,
            disablePreview: state_offset < 1 ? 'disabled' : '',
            disableNext: response.data.total > no_index ? '' : 'disabled',
          });

          this.paginationNumber();
        } else {
          this.setState({ modalShow: false });
        }
      })
      .catch(() => {
        this.setState({ modalShow: false });
      });
  }

  getStatus(x) {
    let y = '';
    if (x === '1') {
      y = 'Active';
    } else if (x === '2') {
      y = 'Non-Active';
    } else if (x === '3') {
      y = 'Block';
    }
    return y;
  }

  reload() {
    const reloadState = this;
    let state_offset = this.state.offset;
    this.setState({ modalShow: true });
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ls.get('ws_ip')}/appuser/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        this.setState({ modalShow: false });
        if (response.data.data.length > 0) {
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              username: response.data.data[i].user_name,
              realname: response.data.data[i].realname,
              status: response.data.data[i].status,
              id: response.data.data[i].usr_id,
            });
          }
          reloadState.setState({
            rows,
            total: response.data.total,
            disableNext: no_index >= response.data.total ? 'disabled' : '',
            page: 1,
            totalPagination: 0,
            currentPagination: 0,
          });

          this.paginationNumber();
        } else {
          reloadState.setState({
            rows: [],
            total: 0,
            disableNext: 'disabled',
            page: 1,
            totalPagination: 0,
            currentPagination: 0,
          });
          this.paginationNumber();
        }
      })
      .catch(() => {
        this.setState({ modalShow: false });
      });
  }

  next() {
    const state_offset = (this.state.page - 1) * this.state.limit;
    this.setState({ modalShow: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios
      .post(
        `${ls.get('ws_ip')}/appuser/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              username: response.data.data[i].user_name,
              realname: response.data.data[i].realname,
              status: response.data.data[i].status,
              id: response.data.data[i].usr_id,
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: this.state.page + 1,
            offset: state_offset,
            modalShow: false,
            disablePreview: state_offset < 1 ? 'disabled' : '',
            disableNext: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch(() => {
        this.setState({ modalShow: false });
      });
  }

  goto(e) {
    e.preventDefault();
    const page = parseInt(e.target.innerHTML, 10);
    const state_offset = (page - 1) * this.state.limit;
    this.setState({ modalShow: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
    };
    axios
      .post(
        `${ls.get('ws_ip')}/appuser/getAllItemCustom/${
          this.state.limit
        }/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            rows.push({
              no: no_index,
              username: response.data.data[i].user_name,
              realname: response.data.data[i].realname,
              status: response.data.data[i].status,
              id: response.data.data[i].usr_id,
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: page + 1,
            offset: state_offset,
            modalShow: false,
            disablePreview: state_offset < 1 ? 'disabled' : '',
            disableNext: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch(() => {
        this.setState({ modalShow: false });
      });
  }

  createButtonPagination() {
    const total_button = 5;
    const child_pagination = [];
    const state_page = this.state.page - 1;
    if (
      this.state.currentPagination < 1 ||
      state_page >= this.state.currentPagination
    ) {
      this.setState({
        currentPagination: state_page + total_button,
      });
    }
    if (state_page < this.state.currentPagination - total_button) {
      this.setState({
        currentPagination: this.state.currentPagination - total_button,
      });
    }

    for (let i = 1; i <= this.state.totalPagination; i++) {
      let selected = '';
      if (state_page === i) {
        selected = 'active';
      }
      if (
        i >= this.state.currentPagination - total_button - 1 &&
        i <= this.state.currentPagination
      ) {
        child_pagination.push(
          <li className={`page-item ${selected}`}>
            <a onClick={this.goto} className="page-link" href="#">
              {i}
            </a>
          </li>,
        );
      }
    }
    return child_pagination;
  }

  getSettingPassword() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/apppasswordsetting/getAll`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        minLenPass = dataResponse[0].min_len_password;
        allowChar = dataResponse[0].allow_char;
        allowUpp = dataResponse[0].allow_uppercase;
        allowLow = dataResponse[0].allow_lowercase;
        allowNum = dataResponse[0].allow_number;
      });
  }

  getUser() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${ls.get('ws_ip')}/appuser/getAll`, '', config).then((res) => {
      const dataResponse = res.data.data;
      for (let i = 0; i < dataResponse.length; i += 1) {
        userExist[i] = dataResponse[i].user_name;
      }
      for (let i = 0; i < dataResponse.length; i += 1) {
        empExist[i] = dataResponse[i].emp_id;
      }
    });
  }

  getEmployee() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/employee/getAll`, '', config)
      .then((response) => {
        const remp = response.data.data;
        const array = [];
        if ((response.data.status = 'ok')) {
          remp.map((obj) =>
            array.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: array });
        }
      });
  }

  toggle(id) {
    // console.log(id);
    this.props.destroy();
    this.setState({ submit: false });
    this.getFunctionAccess();
    const { t } = this.props;
    this.setState({ superuser: false });
    if (id === 'add') {
      this.props.destroy();
      this.setState({ titleModal: t('FRM.ADD') });
      this.setState({ statusEdit: false });
      this.setState({ disablePassword: '' });
      this.setState({ arrSaveData: [] });
      this.setState({ disableButton: true });
      this.setState({ disableReset: 'none' });
      this.setState({ disableFirstName: false });
      this.setState({ disableFamilyName: false });
      this.setState({ disableEmail: false });
      this.setState({ disableUsername: false });
      this.setState({ displayName: '' });
      this.setState({ displayEmail: '' });
      this.getUser();
    } else if (id === 'cancel') {
      this.getUser();
    } else {
      this.setState({ disablePassword: 'none' });
      this.setState({ statusEdit: true });
      this.setState({ titleModal: t('FRM.EDIT') });
      this.setState({ disableButton: true });
    }
    this.setState({
      modal: !this.state.modal,
    });
    this.setState({ disableEmp: false });
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  setValueForm(id, text) {
    document.getElementById(id).value = text;
  }
  setForm(id) {
    document.getElementById(id).reset();
  }

  updateValues(e, a) {
    const { statusEdit, arrSaveData } = this.state;
    // else if (a === 'wia_id') {
    //   wiaid = e.value;
    //   const data = { ...arrSaveData, wia_id: e.value };
    //   this.setState({ disableButton: false, arrSaveData: data });
    // }
    // console.log(statusEdit);
    if (statusEdit === true) {
      if (typeof a === 'undefined') {
        if (e.target.name === 'user_name') {
          usrnm = e.target.value;
          this.setState({ disableButton: false });
          const data = { ...arrSaveData, user_name: usrnm };
          this.setState({ disableButton: false, arrSaveData: data });
        } else if (e.target.name === 'first_name') {
          firstnm = e.target.value;
          const data = { ...arrSaveData, first_name: firstnm };
          this.setState({ disableButton: false, arrSaveData: data });
        } else if (e.target.name === 'last_name') {
          lastnm = e.target.value;
          const data = { ...arrSaveData, last_name: lastnm };
          this.setState({ disableButton: false, arrSaveData: data });
        } else if (e.target.name === 'email') {
          mail = e.target.value;
          const data = { ...arrSaveData, email: mail };
          this.setState({ disableButton: false, arrSaveData: data });
        } else if (e.target.name === 'remark') {
          remak = e.target.value;
          const data = { ...arrSaveData, remark: remak };
          this.setState({ disableButton: false, arrSaveData: data });
        } else if (e.target.name === 'phone_number') {
          remak = e.target.value;
          const data = { ...arrSaveData, phone_number: remak };
          this.setState({ disableButton: false, arrSaveData: data });
        }
      } else if (a === 'phone_number') {
        sts = e.value;
        const data = { ...arrSaveData, phone_number: e.value };
        this.setState({ disableButton: false, arrSaveData: data });
      } else if (a === 'status') {
        sts = e.value;
        const data = { ...arrSaveData, status: e.value };
        this.setState({ disableButton: false, arrSaveData: data });
      } else if (a === 'fga_id') {
        fgaid = e.value;
        const data = { ...arrSaveData, fgaid: e.value };
        this.setState({ disableButton: false, arrSaveData: data });
      } else if (a === 'is_all_emp_access') {
        is_all_emp_access = e.value;
        const data = { ...arrSaveData, is_all_emp_access: e.value };
        this.setState({ disableButton: false, arrSaveData: data });
      } else if (a === 'emp_id') {
        const config = {
          headers: {
            Authorization: `bearer ${ls.get('token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        axios
          .post(
            `${ls.get('ws_ip')}/employee/getAllitemById/${e.value}`,
            '',
            config,
          )
          .then((response) => {
            const code = response.data.data.user[0].emp_code;
            const mobile = response.data.data.user[0].mobile;
            const email = response.data.data.user[0].email;
            const data = {
              ...arrSaveData,
              user_name: code,
              emp_id: e.value,
              phone_number: mobile,
              email,
            };
            if (!empExist.includes(e.value)) {
              this.setState({ displayUserName: '' });
              this.props.initialize(data);
            } else {
              showNotifications('Fail', 'Employee has already taken', 'danger');
              this.setState({ displayUserName: 'none' });
            }
            this.setState({ disableButton: false });
          });
      }
    }
    if (statusEdit === false) {
      if (typeof a === 'undefined') {
        if (e.target.name === 'user_name') {
          if (e.target.value === '') {
            this.setState({ disableButton: true });
            this.setState({ disableEmp: false });
          } else {
            this.setState({ disableButton: false });
            this.setState({ disableEmp: true });
          }
        }
        if (e.target.name === 'phone_number') {
          if (e.target.value === '') {
            this.setState({ disableButton: true });
            this.setState({ disableEmp: false });
          } else {
            this.setState({ disableButton: false });
            this.setState({ disableEmp: true });
          }
        }
        if (e.target.name === 'status') {
          if (e.target.value === '') {
            this.setState({ disableButton: true });
            this.setState({ disableEmp: false });
          } else {
            this.setState({ disableButton: false });
            this.setState({ disableEmp: true });
          }
        }
        if (e.target.name === 'password') {
          if (e.target.value === '') {
            this.setState({ disableButton: true });
            this.setState({ disableEmp: false });
          } else {
            this.setState({ disableButton: false });
            this.setState({ disableEmp: true });
          }
        }
        if (e.target.name === 'first_name') {
          if (e.target.value === '') {
            this.setState({ disableButton: true });
            this.setState({ disableEmp: false });
          } else {
            this.setState({ disableButton: false });
            this.setState({ disableEmp: true });
          }
        }
        if (e.target.name === 'email') {
          if (e.target.value === '') {
            this.setState({ disableButton: true });
            this.setState({ disableEmp: false });
          } else {
            this.setState({ disableButton: false });
            this.setState({ disableEmp: true });
          }
        }
      }
      if (a === 'status') {
        if (typeof e.value === 'undefined') {
          this.setState({ disableButton: true });
        } else {
          this.setState({ disableButton: false });
        }
      }
      if (a === 'phone_number') {
        if (typeof e.value === 'undefined') {
          this.setState({ disableButton: true });
        } else {
          this.setState({ disableButton: false });
        }
      }
      if (a === 'emp_id') {
        // const config = {
        //   headers: {
        //     'Authorization': `bearer ${ls.get('token')}`,
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //   },
        // };
        // axios.post(
        //   `${ls.get('ws_ip')}/employee/getAllitemById/${e.value}`,
        //   '',
        //   config,
        // ).then((response) => {
        //   if (response.data.status === 'ok') {
        //     const code = response.data.data.user[0].emp_code;
        //     const mobile = response.data.data.user[0].mobile;
        //     const data = { ...arrSaveData, user_name: code, emp_id: e.value, phone_number: mobile };
        //     if (!(empExist.includes(e.value))) {
        //       this.setState({ displayUserName: '' });
        //       this.props.initialize(data);
        //     } else {
        //       showNotifications('Fail', 'Employee has already taken', 'danger');
        //       this.setState({ displayUserName: 'none' });
        //     }
        //     this.setState({ disableButton: false });
        //   }
        // });
        if (typeof e.value === 'undefined') {
          this.setState({ disableFirstName: false });
          this.setState({ disableFamilyName: false });
          this.setState({ disableEmail: false });
          this.setState({ disableUsername: false });
          this.setState({ disableButton: false });
          this.setState({ displayName: '' });
          this.setState({ displayEmail: '' });
        } else {
          this.setState({ disableButton: false });
          this.setState({ disableUsername: true });
          this.setState({ disableFirstName: true });
          this.setState({ disableFamilyName: true });
          this.setState({ disableEmail: true });
          this.setState({ displayName: 'none' });
          this.setState({ displayEmail: 'none' });
        }
      }
    }
  }

  mapFormData(b, a) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (b === null) {
          val_b = b.value;
        } else {
          val_b = null;
        }
      } else {
        val_b = b;
      }
    }
    return val_b;
  }

  handleSubmit(values) {
    // console.log(values);
    this.setState({ submit: true });
    const { apiws, token, idUser } = this.state;
    const dtfgaid =
      typeof values.fga_id === 'object' ? values.fga_id.value : values.fga_id;
    // const dtwiaid = (typeof values.wia_id === 'object') ? values.wia_id.value : values.wia_id;
    let phone_number;
    if (
      values.phone_number === '' ||
      values.phone_number === 'null' ||
      values.phone_number === null ||
      typeof values.phone_number === 'undefined'
    ) {
      phone_number = '';
    } else {
      phone_number = values.phone_number;
      phone_number = phone_number.replace(/_/g, '');
    }
    const stat =
      typeof values.status === 'object' ? values.status.value : values.status;
    const emp_access =
      typeof values.is_all_emp_access === 'object' &&
      values.is_all_emp_access !== null
        ? values.is_all_emp_access.value
        : values.is_all_emp_access;
    let dtempid;
    if (typeof values.emp_id === 'undefined' || values.emp_id === null) {
      dtempid = '';
    } else {
      if (typeof values.emp_id === 'object') {
        dtempid = values.emp_id.value;
      } else {
        dtempid = values.emp_id;
      }
    }
    let userBody;
    if (values.emp_id) {
      userBody = `emp_id=${dtempid}`;
    } else {
      userBody = `user_name=${values.user_name}&first_name=${values.first_name}&last_name=${values.last_name}&email=${values.email}&phone_number=${phone_number}`;
    }
    const remarKs =
      typeof values.remark === 'undefined' || values.remark === null
        ? ''
        : values.remark;
    // &wia_id=${dtwiaid}
    const formBody = `remark=${remarKs}&status=${stat}&fga_id=${dtfgaid}&emp_access=${emp_access}`;
    const passBody = `password=${values.password}`;
    const cuserBody = `cuser_id=${idUser}`;
    const muserBody = `muser_id=${idUser}`;
    let Body;
    let url;
    if (!values.usr_id) {
      Body = [userBody, formBody, passBody, cuserBody].join('&');
      url = `${apiws}/appuser/saveItem`;
    } else {
      Body = [userBody, formBody, muserBody].join('&');
      url = `${apiws}/appuser/updateItem/${values.usr_id}`;
    }
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, Body, config)
      .then((resinput) => {
        if (resinput.data.status === 'ok') {
          this.toggle();
          this.reload();
          this.getUser();
          this.props.destroy();
          showNotifications('Success', 'Save Success', 'success');
          this.setState({ disablePassword: '' });
          this.setState({ submit: false });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  resetRows(id) {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${apiws}/appuser/resetPassword/${id}`,
        `muser_id=${idUser}`,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          console.log('datareset', res.data.data);
          Swal.fire({
            title: 'Are you sure?',
            text: 'You will reset this user !',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff4861',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, reset it!',
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              showNotifications('Success', 'Reset Password Success', 'success');
            }
          });
        }
      });
  }

  editRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/appuser/getItemById/${id}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (dataResponse[0].emp_id === null) {
          this.setState({ disableFirstName: false });
          this.setState({ disableFamilyName: false });
          this.setState({ disableEmail: false });
          this.setState({ disableUsername: false });
          this.setState({ disableButton: false });
          this.setState({ displayName: '' });
          this.setState({ displayEmail: '' });
        } else {
          this.setState({ disableButton: false });
          this.setState({ disableUsername: true });
          this.setState({ disableFirstName: true });
          this.setState({ disableFamilyName: true });
          this.setState({ disableEmail: true });
          this.setState({ displayName: 'none' });
          this.setState({ displayEmail: 'none' });
          const indexEmp = empExist.indexOf(dataResponse[0].emp_id);
          if (indexEmp !== -1) {
            empExist.splice(indexEmp, 1);
          }
        }
        this.toggle();
        this.setState({
          statusEdit: true,
          disablePassword: 'none',
          disableReset: '',
        });
        if (dataResponse[0].usr_id === 1) {
          this.setState({ superuser: true });
        }
        const indexName = userExist.indexOf(dataResponse[0].user_name);
        if (indexName !== -1) {
          userExist.splice(indexName, 1);
          // this.setState({ arrHide: dataResponse[0].user_name });
        }
        this.setState({ arrSaveData: dataResponse[0] });
        this.props.initialize(dataResponse[0]);
      })
      .catch(() => {
        //
      });
  }

  deleteRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(`${apiws}/appuser/deleteItem/${id}`, '', config)
          .then((res) => {
            if (res.data.status !== 'failed') {
              this.props.destroy();
              this.reload();
              showNotifications('Success', 'Delete Success', 'success');
            } else {
              showNotifications(
                'Fail',
                'Cannot Delete Active User Access!',
                'danger',
              );
            }
          });
      }
    });
  }
  ChangeStatus() {
    const { statusEdit, arrSaveData } = this.state;
    sts = '1';
    if (statusEdit) {
      const data = { ...arrSaveData, status: sts };
      this.props.initialize(data);
    } else {
      this.props.initialize({
        usr_id: usrid,
        user_name: usrnm,
        first_name: firstnm,
        last_name: lastnm,
        email: mail,
        remark: remak,
        status: sts,
        fga_id: fgaid,
        // 'wia_id': wiaid,
      });
    }
    this.setState({ disableButton: false });
  }

  getFunctionAccess() {
    const { idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/appgroupaccess/getAll/${idUser}`, '', config)
      .then((resFunctionAccess) => {
        const rct = resFunctionAccess.data.data;
        const array = [];
        if (rct.length > 0) {
          rct.map((obj) =>
            array.push({ value: obj.fga_id, label: obj.fga_name }),
          );
          this.setState({
            arrFunctionAccess: array,
          });
        }
      });
  }

  render() {
    const {
      statusEdit,
      titleModal,
      disablePassword,
      disableReset,
      disableButton,
      arrFunctionAccess,
      arrEmp,
      disableEmp,
      disableFirstName,
      disableFamilyName,
      disableEmail,
      disableUsername,
      displayName,
      displayUserName,
      displayEmail,
      idUser,
      submit,
      arrSaveData,
    } = this.state;
    const { handleSubmit, t } = this.props;
    const modalStyle = {
      maxWidth: '800px',
      marginTop: '30px',
      textAlign: 'center',
    };
    const fontStyle = {
      color: '#fff',
    };
    let button_action;
    let field_usrid;
    let button_color;
    if (statusEdit) {
      field_usrid = (
        <Field name="usr_id" component={renderTextInput} type="hidden" />
      );
      button_color = 'success';
      button_action = t('FRM.EDIT');
    } else {
      button_color = 'primary';
      button_action = t('FRM.SAVE');
    }
    // console.log(disableButton);
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody style={{ padding: '20px 5px 30px 10px' }}>
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <input
                        value={this.state.search}
                        onChange={this.search}
                        type="text"
                        className="form-control"
                        placeholder={t('LBL.SEARCHING_BY_KEYWORDS')}
                        style={{ 'font-size': '0.8rem !important' }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Button
                      color="primary"
                      className="btn_table_navbar"
                      onClick={() => this.toggle('add')}
                      style={{ float: 'right', 'margin-bottom': '20px' }}
                    >
                      {t('FRM.ADD')} {t('LBL.ACCOUNT')}
                    </Button>
                  </div>
                </div>
                <table
                  id="myTable"
                  className="table data-grid-tbl responsive no-wrap"
                >
                  <thead>
                    {this.heads.map((data) => (
                      <th>{data}</th>
                    ))}
                  </thead>
                  <tbody>
                    {this.state.rows.map((data) => (
                      <tr>
                        <td style={{ width: '8%' }}>{data.no}</td>
                        <td style={{ width: '20%' }}>{data.username}</td>
                        <td style={{ width: '40%' }}>{data.realname}</td>
                        <td style={{ width: '10%' }}>{data.status}</td>
                        <td style={{ width: '15%' }}>
                          <button
                            type="button"
                            id={`edit${data.id}`}
                            onClick={() => this.editRows(data.id)}
                            className="btn btn-sm btn-outline-primary btn-data-table"
                          >
                            {' '}
                            <i className="fas fa-pencil-alt" />
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`edit${data.id}`}
                          >
                            Update
                          </UncontrolledTooltip>
                          <button
                            type="button"
                            id={`delete${data.id}`}
                            onClick={() => this.deleteRows(data.id)}
                            className="btn btn-sm btn-outline-danger btn-data-table"
                            disabled={data.id !== idUser ? '' : 'disabled'}
                          >
                            {' '}
                            <i className="fas fa-trash-alt" />
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`delete${data.id}`}
                          >
                            Delete
                          </UncontrolledTooltip>
                          <button
                            type="button"
                            id={`reset${data.id}`}
                            onClick={() => this.resetRows(data.id)}
                            className="btn btn-sm btn-outline-primary btn-data-table"
                          >
                            {' '}
                            <i className="fas fa-history" />
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`reset${data.id}`}
                          >
                            Reset Password
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-12">
                    <div className="paging-panel-bottom">
                      <div className="row">
                        <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li
                                className={`page-item ${this.state.disablePreview}`}
                              >
                                <a
                                  onClick={this.previous}
                                  className="disabled page-link"
                                  href="#"
                                  tabIndex="-1"
                                  aria-disabled="true"
                                >
                                  {' '}
                                  {t('FRM.PREVIOUS')}{' '}
                                </a>
                              </li>
                              {this.createButtonPagination()}
                              <li
                                className={`page-item ${this.state.disableNext}`}
                              >
                                <a
                                  onClick={this.next}
                                  className="page-link"
                                  href="#"
                                >
                                  {t('FRM.NEXT')}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-md-6 ">
                          <form className="form-inline float-right">
                            <div className="form-group mb-2">
                              <label className="mr-3">
                                {t('FRM.PER_PAGE')}
                              </label>
                              <select
                                onChange={this.changePage}
                                className="form-control"
                              >
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                              </select>
                            </div>
                            <div className="form-group mx-sm-3 mb-2">
                              <label>
                                {t('FRM.SHOWING')} {this.state.labelIdxStart}{' '}
                                {t('FRM.TO')} {this.state.labelIdxEnd}{' '}
                                {t('FRM.OF')} {this.state.total}
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={modalStyle}
        >
          <ModalHeader toggle={this.toggle}> {titleModal} </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="useraccountform"
              id="useraccountform"
            >
              {field_usrid}
              <Container>
                <Row>
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    style={{ display: displayUserName }}
                  >
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.USERNAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="user_name"
                          id="user_name"
                          component={renderTextInput}
                          type="text"
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                          disabled={disableUsername}
                        />
                      </div>
                    </div>
                  </Col>
                  {!this.state.superuser && (
                    <Col md={6} lg={6} sm={12} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('MEN.EMPLOYEE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="emp_id"
                            id="emp_id"
                            component={renderSelectField}
                            props={{ value: arrSaveData?.emp_id }}
                            options={arrEmp}
                            onChange={(e) => {
                              this.updateValues(e, 'emp_id');
                            }}
                            disabled={disableEmp}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row style={{ display: disablePassword }}>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.PASSWORD')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="password"
                          id="password"
                          component={renderTextInput}
                          type={this.state.showPassword ? 'text' : 'password'}
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                        />
                        <button
                          className={`form__form-group-button${
                            this.state.showPassword ? ' active' : ''
                          }`}
                          onClick={(e) => this.showPassword(e)}
                        >
                          <EyeIcon />
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.RETYPE')} {t('LBL.PASSWORD')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="retype_password"
                          id="retype_password"
                          component={renderTextInput}
                          type={this.state.showRePassword ? 'text' : 'password'}
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                        />
                        <button
                          className={`form__form-group-button${
                            this.state.showRePassword ? ' active' : ''
                          }`}
                          onClick={(e) => this.showRePassword(e)}
                        >
                          <EyeIcon />
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ display: displayName }}>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.FIRST_NAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="first_name"
                          component={renderTextInput}
                          type="text"
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                          disabled={disableFirstName}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.FAMILY_NAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="last_name"
                          component={renderTextInput}
                          type="text"
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                          disabled={disableFamilyName}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ display: displayEmail }}>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.EMAIL')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="email"
                          id="email"
                          component={renderTextInput}
                          type="email"
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                          disabled={disableEmail}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.MOBILE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="phone_number"
                          id="phone_number"
                          component={renderMaskInput}
                          type="text"
                          mask={[
                            '+',
                            '6',
                            '2',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                          disabled={disableUsername}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.REMARK')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="remark"
                          id="remark"
                          component={renderTextInput}
                          type="textarea"
                          onChange={(e) => {
                            this.updateValues(e);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.STATUS')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="status"
                          props={{ value: arrSaveData?.status }}
                          id="status"
                          component={renderSelectField}
                          options={[
                            { value: '1', label: 'Active' },
                            { value: '2', label: 'Non Active' },
                            { value: '3', label: 'Blocked' },
                            { value: '4', label: 'Temporary Blocked' },
                          ]}
                          onChange={(e) => {
                            this.updateValues(e, 'status');
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    style={{ display: disableReset }}
                  >
                    <div
                      className="form__form-group"
                      style={{ padding: '2px 0px 0px 0px' }}
                    >
                      <Link
                        to="#"
                        color="default"
                        style={{ padding: '5px 5px 5px 5px' }}
                        onClick={this.ChangeStatus}
                      >
                        <FontAwesomeIcon icon={faRedoAlt} /> {t('FRM.RESET')}
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.FUNCTION_ACCESS')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="fga_id"
                          id="fga_id"
                          props={{ value: arrSaveData?.fga_id }}
                          component={renderSelectField}
                          options={arrFunctionAccess}
                          onChange={(e) => {
                            this.updateValues(e, 'fga_id');
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('LBL.INQUIRY_ACCESS')}</span>
                      <div className="form__form-group-field">
                        <Field
                          name="wia_id"
                          id="wia_id"
                          component={renderSelectField}
                          options={[
                            { value: '1', label: 'Employee' },
                            { value: '2', label: 'HR Admin' },
                            { value: '3', label: 'Superuser' },
                          ]}
                          onChange={(e) => { this.updateValues(e, 'wia_id'); }}
                        />
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.EMPLOYEE_ACCESS')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="is_all_emp_access"
                          id="is_all_emp_access"
                          props={{ value: arrSaveData?.is_all_emp_access }}
                          component={renderSelectField}
                          options={[
                            { value: '0', label: 'Admin' },
                            { value: '1', label: 'User' },
                          ]}
                          onChange={(e) => {
                            this.updateValues(e, 'is_all_emp_access');
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle('cancel')}>
              {t('FRM.CANCEL')}
            </Button>
            {submit ? (
              <Button color="primary">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: '6px' }}
                />
                Loading...
              </Button>
            ) : (
              <Button
                color={button_color}
                form="useraccountform"
                type="submit"
                disabled={disableButton}
              >
                {' '}
                {button_action}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Col>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorsUserExist = userExist;
  const errorsEmpExist = empExist;
  if (typeof values.emp_id === 'undefined' || values.emp_id === null) {
    if (!values.user_name) {
      errors.user_name = 'Username field shouldn’t be empty';
    }
    if (errorsUserExist.includes(values.user_name)) {
      errors.user_name = 'Username is already taken';
    }
    if (!values.first_name) {
      errors.first_name = 'Firstname field shouldn’t be empty';
    }
    if (!values.last_name) {
      errors.last_name = 'Family name field shouldn’t be empty';
    }
    if (!values.email) {
      errors.email = 'Email field shouldn’t be empty';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
  } else {
    if (errorsEmpExist.includes(values.emp_id.value)) {
      errors.emp_id = 'Employee is already taken';
    }
  }
  if (!values.usr_id) {
    if (!values.password) {
      errors.password = 'Password field shouldn’t be empty';
    } else {
      const errorsMinLenPass = minLenPass;
      if (values.password.length < errorsMinLenPass) {
        errors.password = `Password field more than ${errorsMinLenPass} character`;
      }
      // if (allowChar !== '') {
      //   const regexSC = new RegExp(`([${allowChar}])`, 'g');
      //   if (!regexSC.test(values.password)) {
      //     errors.password = 'Password field must have a special character';
      //   }
      // }
      if (allowChar !== '') {
        const regexSC = new RegExp(`([${allowChar}])`, 'g');
        if (regexSC.test(values.password)) {
          errors.password = 'Password field must have a special character';
        }
      }

      const errorsAllowUpp = allowUpp;
      if (errorsAllowUpp === 'true') {
        if (!/([A-Z]+)/g.test(values.password)) {
          errors.password = 'Password field must have a Capital letter';
        }
      }
      const errorsAllowLow = allowLow;
      if (errorsAllowLow === 'true') {
        if (!/([a-z]+)/g.test(values.password)) {
          errors.password = 'Password field must have a Lower letter';
        }
      }
      const errorsAllowNum = allowNum;
      if (errorsAllowNum === 'true') {
        if (!/([1-9]+)/g.test(values.password)) {
          errors.password = 'Password field must have a Number';
        }
      }
    }
    if (!values.retype_password) {
      errors.retype_password = 'Retype password field shouldn’t be empty';
    }
    if (values.password !== values.retype_password) {
      errors.retype_password = 'Password Not Match';
    }
  }
  if (!values.status) {
    errors.status = 'Status field shouldn’t be empty';
  }
  if (!values.fga_id) {
    errors.fga_id = 'Function access field shouldn’t be empty';
  }
  if (!values.is_all_emp_access) {
    errors.is_all_emp_access = 'Employee access field shouldn’t be empty';
  }
  // if (!values.wia_id) {
  //   errors.wia_id = 'Function access field shouldn’t be empty';
  // }
  return errors;
};

export default reduxForm({
  form: 'useraccountform',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(UserAccountSetting));
