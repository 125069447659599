/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Col, ButtonToolbar, Card, Button, CardBody } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderSelectField from '../../../shared/components/form/Select';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import ViewListTable from '../../../shared/components/table/viewListTable';
import showNotifications from '../../Preferences/notificationMessages';
import moment from 'moment';
import ReactExport from 'react-export-excel';
import Spinner from 'react-bootstrap/Spinner';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const HeaderIcon = '/img/preference_icon/icon-attendance-setting.png';

class LeaveReport extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      iduser: '',
      token: '',
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      dataWidth: ['5%', '20%', '20%', '20%', '10%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      type: '',
      arrEmployee: [],
      arrEmpStatus: [],
      rtbreligion: [],
      orgjoblevel: [],
      rtbmaritalstatus: [],
      condStartage: '',
      condEndage: '',
      condPeriodStart: '',
      condPeriodEnd: '',
      condBpjsKes: '',
      condBpjsTk: '',
      // condCmpId: '',
      condEmployee: '',
      condJobId: '',
      condSex: '',
      condOrganization: '',
      condOffice: '',
      condEmployeeStatus: '',
      condActive: '',
      maritalstatus: '',
      npwpstatus: '',
      condReligion: '',
      headerexcel: [],
      dataexcel: [],
      displayLoad: 'none',
      displaySave: '',
      filename: 'Employee Report',
      condType: '',
    };
    const { t } = props;

    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.EMPLOYEE_NAME') },
      { 2: t('LBL.EMPLOYEE_CODE') },
      { 3: t('LBL.UNIT_ORGANIZATION') },
      { 4: t('LBL.JOB_NAME') },
      { 5: t('LBL.JOB_TITLE') },
      { 6: t('LBL.JOB_BAND') },
      { 7: t('LBL.OFFICE') },
      { 8: t('LBL.SEX') },
      { 9: t('LBL.MARITAL_STATUS') },
      { 10: t('LBL.DATE_OF_BIRTH') },
      { 11: t('LBL.JOIN_DATE') },
      { 12: t('LBL.NUMBER_BPJS_KETENAGAKERJAAN') },
      { 13: t('LBL.NUMBER_BPJS_KESEHATAN') },
      { 14: t('LBL.NPWP_NO') },
      { 15: t('LBL.EMPLOYEE_STATUS') },
      { 16: t('LBL.RESIGN_DATE') },
      { 17: t('LBL.TAX_METHOD') },
      { 18: t('LBL.TAX_STATUS') },
      { 19: t('LBL.TAX_RECEPIENT') },
    ];

    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.loadSelectBox();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.EMPLOYEE_NAME') },
      { 2: t('LBL.EMPLOYEE_CODE') },
      { 3: t('LBL.UNIT_ORGANIZATION') },
      { 4: t('LBL.JOB_NAME') },
      { 5: t('LBL.JOB_TITLE') },
      { 6: t('LBL.JOB_BAND') },
      { 7: t('LBL.OFFICE') },
      { 8: t('LBL.SEX') },
      { 9: t('LBL.MARITAL_STATUS') },
      { 10: t('LBL.DATE_OF_BIRTH') },
      { 11: t('LBL.JOIN_DATE') },
      { 12: t('LBL.NUMBER_BPJS_KETENAGAKERJAAN') },
      { 13: t('LBL.NUMBER_BPJS_KESEHATAN') },
      { 14: t('LBL.NPWP_NO') },
      { 15: t('LBL.EMPLOYEE_STATUS') },
      { 16: t('LBL.RESIGN_DATE') },
      { 17: t('LBL.TAX_METHOD') },
      { 18: t('LBL.TAX_STATUS') },
      { 19: t('LBL.TAX_RECEPIENT') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getReport() {
    const {
      condStartage,
      condEndage,
      condPeriodStart,
      condPeriodEnd,
      condBpjsKes,
      condBpjsTk,
      // condCmpId,
      condEmployee,
      condJobId,
      condSex,
      condOrganization,
      condOffice,
      condEmployeeStatus,
      maritalstatus,
      npwpstatus,
      condReligion,
      condActive,
    } = this.state;
    this.setState({ displayTable: 'none', displayLoad: 'none', displaySave: '' });
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      start_age: condStartage,
      end_age: condEndage,
      period_start: condPeriodStart,
      period_end: condPeriodEnd,
      bpjs_kes: condBpjsKes,
      bpjs_tk: condBpjsTk,
      // cmp_id: condCmpId,
      emp_id: condEmployee,
      job_id: condJobId,
      sex: condSex,
      org_id: condOrganization,
      off_id: condOffice,
      ems_id: condEmployeeStatus,
      marital_stat: maritalstatus,
      npwp_stat: npwpstatus,
      religion: condReligion,
      active: condActive,
      type: '2',
    };
    const array = [];
    const bodyParameters = {
      cond: conds,
    };
    axios.post(
      `${ls.get('ws_ip')}/employee/getreportemployee`,
      bodyParameters,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const dt = res.data.data;
        const hd = res.data.header;
        dt.map((obj, index) => (
          array.push({ No: (index + 1), ...obj })
        ));
        this.setState({ dataexcel: array, headerexcel: hd });
        setTimeout(() => {
          showNotifications('Success', 'Load Success', 'success');
          this.setState({ displayLoad: 'none', displaySave: '' });
          this.setState({ type: '2' });
          this.inputElement.click();
        }, 1000);
      }
    });
  }

  getEmployee() {
    const { token, apiws, org } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/employee/getAllItemOrg/${org}`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  handleSubmit(values) {
    const { apiws } = this.state;
    const date = new Date();
    const year = date.getFullYear();
    this.setState({ displayLoad: '', displaySave: 'none' });
    const start_age = (typeof values.age_start === 'undefined' || values.age_start === null) ? '' : `${year - parseInt(values.age_start, 10)}-01-01`;
    const end_age = (typeof values.age_end === 'undefined' || values.age_end === null) ? '' : `${year - parseInt(values.age_end, 10)}-01-01`;
    // const start_age = moment(st_age, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
    // const end_age = moment(ed_age, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
    const period_start = (typeof values.period_start === 'undefined' || values.period_start === null) ? '' : moment(values.period_start).format('YYYY-MM-DD');
    const period_end = (typeof values.period_end === 'undefined' || values.period_end === null) ? '' : moment(values.period_end).format('YYYY-MM-DD');
    const bpjskes = (typeof values.bpjskes === 'undefined' || values.bpjskes === null) ? '' : values.bpjskes;
    const bpjstk = (typeof values.bpjsket === 'undefined' || values.bpjsket === null) ? '' : values.bpjsket;
    // const cmpId = (typeof values.company === 'undefined' || values.company === null) ? '' : values.company.value;
    const empId = (typeof values.employee === 'undefined' || values.employee === null) ? '' : values.employee.value;
    const emsId = (typeof values.employee_status === 'undefined' || values.employee_status === null) ? '' : values.employee_status.value;
    const jobId = (typeof values.job_level === 'undefined' || values.job_level === null) ? '' : values.job_level.value;
    const maritalstatus = (typeof values.maritalstatus === 'undefined' || values.maritalstatus === null) ? '' : values.maritalstatus;
    const npwpstatus = (typeof values.npwpRadio === 'undefined' || values.npwpRadio === null) ? '' : values.npwpRadio;
    const sex = (typeof values.sex === 'undefined' || values.sex === null) ? '' : parseInt(values.sex.value, 10);
    const orgId = (typeof values.unit_organization === 'undefined' || values.unit_organization === null) ? '' : values.unit_organization.value;
    const offId = (typeof values.office === 'undefined' || values.office === null) ? '' : values.office.value;
    const religion = (typeof values.religion === 'undefined' || values.religion === null) ? '' : values.religion.value;
    const active = (typeof values.is_active === 'undefined' || values.is_active === null) ? '' : values.is_active;

    this.setState({ condStartage: start_age });
    this.setState({ condEndage: end_age });
    this.setState({ condPeriodStart: period_start });
    this.setState({ condPeriodEnd: period_end });
    this.setState({ condBpjsKes: bpjskes });
    this.setState({ condBpjsTk: bpjstk });
    // this.setState({ condCmpId: cmpId });
    this.setState({ condEmployee: empId });
    this.setState({ condJobId: jobId });
    this.setState({ maritalstatus });
    this.setState({ npwpstatus });
    this.setState({ condSex: sex });
    this.setState({ condOrganization: orgId });
    this.setState({ condOffice: offId });
    this.setState({ condEmployeeStatus: emsId });
    this.setState({ condReligion: religion });
    this.setState({ condActive: active });

    if (values.type_report.value === '2') {
      setTimeout(() => {
        this.getReport();
      }, 1000);
    } else if (values.type_report.value === '1') {
      this.setState({ urlData: `${apiws}/employee/getreportemployee` });
      setTimeout(() => {
        this.getTableData();
        this.setState({ displayLoad: 'none', displaySave: '' });
        showNotifications('Success', 'Load Success', 'success');
      }, 200);
    }
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/orgoffice/getAll/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ orgoffice: Arr });
      }
    });

    // axios.post(
    //   `${apiws}/orgcompany/getAll/`,
    //   '',
    //   config,
    // ).then((res) => {
    //   const dataResponse = res.data.data;
    //   if (res.data.status === 'ok') {
    //     const Arr = [];
    //     dataResponse.map(obj => (
    //       Arr.push({ value: obj.cmp_id, label: obj.company })
    //     ));
    //     this.setState({ company: Arr });
    //   }
    // });

    axios.post(
      `${apiws}/employee/getAllItemOrg/0`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.employee, label2: obj.emp_code })
        ));
        this.setState({ arrEmployee: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });

    axios.post(
      `${apiws}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ orgorganization: Arr });
      }
    });

    axios.get(
      `${apiws}/rtbemployeementstatus/getAll`,
      config,
    ).then((res) => {
      const emp = res.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmpStatus: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.ems_id, label: obj.ems_employeement_status })
        ));
        this.setState({ arrEmpStatus: array });
      }
    });

    axios.post(
      `${apiws}/orgjoblevel/getAll/`,
      '',
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.job_id, label: obj.job_level })
        ));
        this.setState({ orgjoblevel: Arr });
      }
    });

    axios.post(
      `${apiws}/rtbreligion/getAll/`,
      '',
      config,
    ).then((response) => {
      const dataResponse = response.data.data;
      const Arr = [];
      dataResponse.map(obj => (
        Arr.push({ value: obj.rtg_id, label: obj.religion })
      ));
      //  this.setState({ slcunit: Arr });
      this.setState({ rtbreligion: Arr });
    });

    axios.post(
      `${apiws}/rtbmaritalstatus/getAll/`,
      '',
      config,
    ).then((response) => {
      const dataResponse = response.data.data;
      const Arr = [];
      dataResponse.map(obj => (
        Arr.push({ value: obj.rtm_id, label: obj.marital_status })
      ));
      //  this.setState({ slcunit: Arr });
      this.setState({ rtbmaritalstatus: Arr });
    });
  }

  render() {
    const { handleSubmit, reset, t } = this.props;
    const {
      displayLoad,
      displaySave,
      urlData, orgorganization, arrEmployee, orgoffice, arrEmpStatus, rtbreligion,
      orgjoblevel, rtbmaritalstatus,
      condStartage,
      condEndage,
      condPeriodStart,
      condPeriodEnd,
      condBpjsKes,
      condBpjsTk,
      // condCmpId,
      condEmployee,
      condJobId,
      condSex,
      condOrganization,
      condOffice,
      condEmployeeStatus,
      maritalstatus,
      npwpstatus,
      condReligion,
      condActive,
    } = this.state;
    return (
      <Container>
        <Card>
          <div className="p0 tab-body-panel">
            <Row className="m0">
              <Col md={12} style={{ padding: '0px' }}>
                <div className="header-page-panel">
                  <img className="header-icon" src={HeaderIcon} alt="leave" />
                  <h3 className="page-title">{t('MEN.REPORT')} {t('LBL.EMPLOYEE')}</h3>
                  <h3 className="page-subhead subhead">{t('LBL.VIEW_EMPLOYEE_REPORT')}</h3>
                </div>
              </Col>
              <Col md={12} lg={6} style={{ marginTop: '25px' }}>
                <form className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="company"
                        component={renderSelectField}
                        placeholder="Select Company"
                        options={company}
                      />
                    </div>
                  </div> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.UNIT_ORGANIZATION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="unit_organization"
                        component={renderSelectField}
                        placeholder="Select Organization"
                        options={orgorganization}
                        onChange={(val) => {
                          this.setState({ org: val.value }); setTimeout(() => {
                            this.getEmployee();
                          }, 200);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee"
                        component={renderSelectField}
                        placeholder="Name"
                        options={arrEmployee}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.JOIN_DATE')}</span>
                    <div className="form__form-group-field range-select-form">
                      <Field
                        name="period_start"
                        component={renderDatePickerField}
                        placeholder="Start Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                      <span className="form__form-group-label" style={{ 'margin-left': '10px' }}>{t('LBL.UNTIL')}</span>
                      <Field
                        name="period_end"
                        component={renderDatePickerField}
                        type="text"
                        placeholder="End Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="office"
                        component={renderSelectField}
                        placeholder="Select Office"
                        options={orgoffice}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee_status"
                        component={renderSelectField}
                        placeholder="Select employee status"
                        options={arrEmpStatus}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.STATUS')}</span>
                    <div className="form__form-group-field">
                      <Row className="w-100">
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="is_active"
                            component={renderRadioButtonField}
                            label={t('LBL.ACTIVE')}
                            radioValue="0"
                          />
                        </Col>
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="is_active"
                            component={renderRadioButtonField}
                            label={t('LBL.NOT_ACTIVE')}
                            radioValue="1"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.EMPLOYEE_STATUS')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee_status"
                        component={renderSelectField}
                        placeholder="Select employee status"
                        options={arrEmpStatus}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.SEX')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="sex"
                        component={renderSelectField}
                        placeholder="Select gender"
                        options={[
                          { value: '1', label: 'Male' },
                          { value: '2', label: 'Female' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.RELIGION')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="religion"
                        component={renderSelectField}
                        placeholder="Select religion"
                        options={rtbreligion}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.MARITAL_STATUS')}</span>
                    <div className="form__form-group-field">
                      <Row className="w-100">
                        {rtbmaritalstatus.map(status => (
                          <Col md="6" lg="6" xs="12">
                            <Field
                              name="maritalstatus"
                              component={renderRadioButtonField}
                              label={status.label}
                              radioValue={status.value}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                  <div className="form__form-group" style={{ 'margin-bottom': '20px', 'margin-top': '10px' }}>
                    <span className="form__form-group-label">{t('LBL.AGE')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="age_start"
                        component="input"
                        type="text"
                      />
                      <span
                        className="form__form-group-label"
                        style={{ 'margin-left': '12px', 'margin-right': '-10px' }}
                      >{t('LBL.UNTIL')}
                      </span>
                      <Field
                        name="age_end"
                        component="input"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.JOB_LEVEL')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="job_level"
                        component={renderSelectField}
                        placeholder="Select job level"
                        options={orgjoblevel}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.BPJS_KETENAGAKERJAAN')}</span>
                    <div className="form__form-group-field">
                      <Row className="w-100">
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="bpjsket"
                            component={renderRadioButtonField}
                            label={t('LBL.REGISTERED')}
                            radioValue="1"
                          />
                        </Col>
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="bpjsket"
                            component={renderRadioButtonField}
                            label={t('LBL.NOT_REGISTERED')}
                            radioValue="2"
                          />
                        </Col>
                      </Row>
                      {/* <Field
                        name="bpjsket"
                        component={renderSelectField}
                        placeholder="bpjsket"
                        options={[
                          { value: '1', label: 'Registered' },
                          { value: '2', label: 'Not Registered' },
                        ]}
                      /> */}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.BPJS_KESEHATAN')}</span>
                    <div className="form__form-group-field">
                      <Row className="w-100">
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="bpjskes"
                            component={renderRadioButtonField}
                            label={t('LBL.REGISTERED')}
                            radioValue="1"
                          />
                        </Col>
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="bpjskes"
                            component={renderRadioButtonField}
                            label={t('LBL.NOT_REGISTERED')}
                            radioValue="2"
                          />
                        </Col>
                      </Row>
                      {/* <Field
                        name="bpjskes"
                        component={renderSelectField}
                        placeholder="Select gender"
                        options={[
                          { value: '1', label: 'Registered' },
                          { value: '2', label: 'Not Registered' },
                        ]}
                      /> */}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">NPWP</span>
                    <div className="form__form-group-field">
                      <Row className="w-100">
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="npwpRadio"
                            component={renderRadioButtonField}
                            label={t('LBL.REGISTERED')}
                            radioValue="1"
                          />
                        </Col>
                        <Col md="6" lg="6" xs="12">
                          <Field
                            name="npwpRadio"
                            component={renderRadioButtonField}
                            label={t('LBL.NOT_REGISTERED')}
                            radioValue="2"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.TYPE_REPORT')}</span>
                    <div className="form__form-group-field">
                      <Field
                        id="typeAttdReport"
                        name="type_report"
                        component={renderSelectField}
                        type="text"
                        options={[
                          { value: '1', label: 'View' },
                          { value: '2', label: 'Excel' },
                        ]}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button type="button" onClick={reset} style={{ display: displaySave }}>
                      {t('FRM.CANCEL')}
                    </Button>
                    <Button color="primary" type="submit" style={{ display: displaySave }} >{t('FRM.SUBMIT')} </Button>
                    <Button color="primary" style={{ display: displayLoad }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {t('FRM.LOADING')}
                    </Button>
                  </ButtonToolbar>
                  {(this.state.type === '2') && (
                    <ExcelFile
                      element={
                        <div
                          onClick={this.handleClick}
                          onKeyPress={this.handleClick}
                          role="button"
                          tabIndex="0"
                        ><input type="hidden" ref={input => this.inputElement = input} />
                        </div>}
                      filename={this.state.filename}
                    >
                      <ExcelSheet data={this.state.dataexcel} name="Employees">
                        {
                          this.state.headerexcel.map(obj => (
                            <ExcelColumn label={obj} value={obj} />
                          ))
                        }
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </form>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} lg={12} xl={12}>
                <Card>
                  <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                    <div className="card__title">
                      <h5 className="bold-text">{t('MEN.REPORT')} {t('LBL.EMPLOYEE')}</h5>
                    </div>
                    <ViewListTable
                      url={urlData}
                      heads={this.heads}
                      primaryKey="emp_id"
                      widthTable={this.state.dataWidth}
                      displayStyleHead="none"
                      buttonAction="noButton"
                      onRef={ref => (this.child = ref)}
                      conds={{
                        start_age: condStartage,
                        end_age: condEndage,
                        period_start: condPeriodStart,
                        period_end: condPeriodEnd,
                        bpjs_kes: condBpjsKes,
                        bpjs_tk: condBpjsTk,
                        // cmp_id: condCmpId,
                        emp_id: condEmployee,
                        job_id: condJobId,
                        sex: condSex,
                        org_id: condOrganization,
                        off_id: condOffice,
                        ems_id: condEmployeeStatus,
                        marital_stat: maritalstatus,
                        npwp_stat: npwpstatus,
                        religion: condReligion,
                        active: condActive,
                        type: '1',
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Card>
      </Container>
    );
  }
}


const validate = (values) => {
  const errors = {};
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'employeereport', // a unique identifier for this form
})(translate('global')(LeaveReport));
