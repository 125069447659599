/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable operator-assignment */
/* eslint-disable space-infix-ops */
/* eslint-disable camelcase */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Container, Col, Row, Card, CardBody, Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import ls from 'local-storage';
import axios from 'axios';
// import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';

class AttendanceEmployeeList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    // location: PropTypes.func.isRequired,
    period: PropTypes.func.isRequired,
    attEmployee: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.OFFICE'),
      t('LBL.POSITION'),
      t('LBL.JOB_LEVEL'),
      t('FRM.FUNCTION'),
    ];

    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '20%', '30%', '20%', '28%'],
      urlData: [],
      modal: false,
      attendances: '',
      empCode: '',
      empName: '',
      unitOrganization: '',
      office: '',
      jobName: '',
      positionName: '',
      jobBand: '',
      jobGrade: '',
      jobLevel: '',
      employeementStatus: '',
      bank: '',
      bankAccount: '',
      bankAccountHolder: '',
      redirectUrl: false,
      joinDate: '',
    };
    this.toggle = this.toggle.bind(this);
    this.detailRows = this.detailRows.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getTableData(); });
    this.setState({ urlData: `${ls.get('ws_ip')}/paysalary/getAllEmpByPeriodAttendance/` });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE'),
      t('LBL.UNIT_ORGANIZATION'),
      t('LBL.OFFICE'),
      t('LBL.POSITION'),
      t('LBL.JOB_LEVEL'),
      t('FRM.FUNCTION'),
    ];
  }

  getTableData() {
    this.child.reload();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  detailRows(id) {
    this.toggle();
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/payemployeecurrent/getAllItemById/${id}`,
      `pyp_id=${this.props.period}`,
      config,
    ).then((res) => {
      const dataResponse = res.data.data[0];
      this.setState({
        empCode: dataResponse.emp_code,
        empName: dataResponse.emp_name,
        unitOrganization: dataResponse.unit_organization,
        office: dataResponse.office,
        employeementStatus: dataResponse.employeement_status,
        bank: dataResponse.bank,
        bankAccount: dataResponse.bank_account,
        bankAccountHolder: dataResponse.bank_account_holder,
        positionName: dataResponse.position_name,
        jobName: dataResponse.job_name,
        jobBand: dataResponse.job_band,
        jobGrade: dataResponse.job_grade,
        jobLevel: dataResponse.job_level,
        joinDate: dataResponse.join_date,
      });
    });
    axios.post(
      `${apiws}/payattendance/getAllItemReport/${id}`,
      `pyp_id=${this.props.period}`,
      config,
    ).then((res) => {
      if (res.data.status === 'ok') {
        const datas = [];
        const att = res.data.data;
        if (att.length === 0) {
          this.setState({ attendances: datas });
        } else {
          for (let i = 0; i < att.length; i += 1) {
            datas.push(<tr><td style={{ width: '10px' }}>{i + 1}.</td><td style={{ width: '200px' }}>{att[i].ato_value} [{att[i].ato_code}]</td><td style={{ width: '30px' }}> : </td><td>{att[i].pat_total}</td></tr>);
          }
          this.setState({ attendances: datas });
        }
        // res.data.data.map(obj => (
        //   html.push('<tr><td>'+{ obj.ato_id }+'</td><td>'+ {obj.pat_total} +'</td></tr>')
        // ));
      }
    });
  }

  render() {
    const { urlData, attendances, redirectUrl } = this.state;
    const { t } = this.props;
    const addBtn = '';
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn_table_navbar"
        onClick={() => this.props.attEmployee('')}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      marginTop: '95px',
      textAlign: 'center',
    };
    if (redirectUrl) {
      return <Redirect to={{ pathname: '/payroll/attendanceprocess' }} />;
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ 'paddingBottom': '10px' }}>
                  <h3 className="page-title">{t('LBL.PAYROLL_ATTENDANCE')} - {t('MEN.LIST_ATT_PROCESS')}</h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="emp_id"
                  // conds={this.props.location.state.pypId}
                  conds={this.props.period}
                  widthTable={this.state.dataWidth}
                  detailFunc={this.detailRows}
                  buttonAction="detailOnly"
                  onRef={ref => (this.child = ref)}
                  displayStyleHead="none"
                />
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className="modal-dialog modal-input"
                  style={modalStyle}
                >
                  <ModalHeader toggle={this.toggle}>{t('LBL.ATTENDANCE_TO_PAYROLL_REPORT')}</ModalHeader>
                  <ModalBody>
                    <Container>
                      <Card>
                        <CardBody style={{ padding: '5px 5px 5px 5px' }}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th colSpan="4">{t('LBL.EMPLOYEE_INFORMATION')}</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{t('LBL.EMPLOYEE_CODE')}</td>
                                  <td>: {this.state.empCode}</td>
                                  <td>{t('LBL.OFFICE')}</td>
                                  <td>: {this.state.office} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.EMPLOYEE')}</td>
                                  <td>: {this.state.empName}</td>
                                  <td>{t('LBL.ORGANIZATION')}</td>
                                  <td>: {this.state.unitOrganization}</td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.STATUS')}</td>
                                  <td>: {this.state.employeementStatus} </td>
                                  <td>{t('LBL.JOB_NAME')}</td>
                                  <td>: {this.state.jobName} </td>
                                </tr>
                                <tr>
                                  <td>{t('LBL.JOIN_DATE')}</td>
                                  <td>: {this.state.joinDate}</td>
                                  <td>{t('LBL.POSITION')}</td>
                                  <td>: {this.state.positionName}</td>
                                </tr>
                                {/* <tr>
                                  <td>Bank</td>
                                  <td>: {this.state.bank} </td>
                                  <td>Job Level</td>
                                  <td>: {this.state.jobLevel} </td>
                                </tr>
                                <tr>
                                  <td>Bank Account</td>
                                  <td>: {this.state.bankAccount} </td>
                                  <td>Job Band</td>
                                  <td>: {this.state.jobBand} </td>
                                </tr>
                                <tr>
                                  <td>Nama Pemilik</td>
                                  <td>: {this.state.bankAccountHolder} </td>
                                  <td>Job Grade</td>
                                  <td>: {this.state.jobGrade} </td>
                                </tr> */}
                              </tbody>
                            </table>
                          </Col>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody style={{ padding: '5px 5px 5px 5px' }}>
                          <Col xs={12} md={12} lg={12} xl={12}>
                            <table
                              id="myTable"
                              className="table data-grid-tbl responsive no-wrap"
                            >
                              <thead>
                                <th colSpan="4">{t('LBL.INFORMATION')} {t('LBL.ATTENDANCE')}</th>
                              </thead>
                              <tbody>
                                {attendances}
                              </tbody>
                            </table>
                          </Col>
                        </CardBody>
                      </Card>
                    </Container>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>{t('FRM.CANCEL')}</Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(AttendanceEmployeeList));
