import React from 'react';
import { Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
// import PropTypes from 'prop-types';
import SurveyGroup from './Component/groups';
import SurveyQuestion from './Component/questions';
// import FormList from './Component/form';

class Workflow extends React.Component {
  // static propTypes = {
  //   men_id: PropTypes.func.isRequired,
  //   fga_id: PropTypes.func.isRequired,
  // };
  constructor() {
    super();
    this.state = {
      disp_group: 'block',
      disp_question: 'none',
      param_survey: '',
    };
    this.OpenActivity = this.OpenActivity.bind(this);
  }

  OpenActivity(x, txt) {
    if (txt === 'question') {
      this.setState({
        disp_group: 'none',
        disp_question: 'block',
        param_survey: x,
      });
    } else {
      this.setState({
        disp_group: 'block',
        disp_question: 'none',
      });
    }
  }

  render() {
    return (
      <Container>
        <Row style={{ display: this.state.disp_group }}>
          <SurveyGroup
            onparam={this.OpenActivity}
          />
        </Row>
        <Row style={{ display: this.state.disp_question }}>
          <SurveyQuestion
            onparam={this.OpenActivity}
            setparam={this.state.param_survey}
          />
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(Workflow));
