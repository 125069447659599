import React, { useRef, useState } from 'react';
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Col,
} from 'reactstrap';
import DragIcon from 'mdi-react/DragIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderTextInput from '../../../../shared/components/form/TextInput';
import showNotifications from '../../../Preferences/notificationMessages';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './styles/taskCollapse.scss';
import Swal from 'sweetalert2';
import ls from 'local-storage';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import withReactContent from 'sweetalert2-react-content';
import { CSS } from '@dnd-kit/utilities';
import { useDraggable, useDroppable } from '@dnd-kit/core';

const validate = (values) => {
  const errors = {};
  if (!values.section_name) {
    errors.section_name = "Section name shouldn't be empty";
  }
  return errors;
};

const STATUS_IMAGE = {
  closed: `${process.env.PUBLIC_URL}/img/misc/arrow-right.svg`,
  opened: `${process.env.PUBLIC_URL}/img/misc/arrow-down.svg`,
  closing: `${process.env.PUBLIC_URL}/img/misc/arrow-right.svg`,
  opening: `${process.env.PUBLIC_URL}/img/misc/arrow-down.svg`,
};

const ELIPSIS_IMAGE = {
  opened: '',
  opening: '',
  closed: (
    <DotsHorizontalIcon
      style={{ position: 'unset', color: '#646777', width: 14, height: 14 }}
    />
  ),
  closing: (
    <DotsHorizontalIcon
      style={{ position: 'unset', color: '#646777', width: 14, height: 14 }}
    />
  ),
};

const TaskTableCollapse = ({
  t,
  className,
  title,
  section,
  sectionId,
  handleRenameSection,
  children,
  idActiveSection,
  reloadSection,
  reset,
}) => {
  const typeConfirmDelete = useRef(null);
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  const [collapse, setCollapse] = useState(true);
  const [status, setStatus] = useState('opened');
  const [touched, setTouched] = useState(false);
  const [sectionData, setSection] = useState({
    pse_id: section?.pse_id,
    pps_id: section?.pps_id,
    section_name: section?.section_name,
    rename_section: section?.rename_section,
    tasks: section?.tasks,
    has_edit: section?.has_edit,
    default_section: section?.default_section,
  });
  const [sectionName, setSectionName] = useState(section?.section_name);

  const onEntering = () => setStatus('opening');
  const onEntered = () => setStatus('opened');
  const onExiting = () => setStatus('closing');
  const onExited = () => setStatus('closed');
  const toggle = () => setCollapse(!collapse);

  const deleteSection = (id) => {
    let typeSectionDelete = '';
    let idUser = ls.get('user_cred').usr_id;

    if (id?.pse_id) {
      id = id?.pse_id;
      typeSectionDelete = 1;
    } else {
      id = id?.pps_id;
      typeSectionDelete = 2;
    }

    const MySwal = withReactContent(Swal);

    function deleteItemSection() {
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(
          `${ls.get('ws_ip')}/section/deleteItem/${id}`,
          {
            type: typeSectionDelete,
            emp_id: ls.get('user_cred').emp_id,
            duser_id: idUser,
            confirm: typeConfirmDelete.current,
          },
          config,
        )
        .then((res) => {
          if (res.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            reloadSection();
          }
        });
    }

    if (typeSectionDelete == 1) {
      Swal.fire({
        title: t('FRM.CONFIRM_ASK'),
        text: t('LBL.DELETESECTIONOPT1'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: t('LBL.CANCEL'),
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        confirmButtonText: t('LBL.YES_DELETE'),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) deleteItemSection();
      });
    } else {
      MySwal.fire({
        input: 'radio',
        html: `<h3>${t('FRM.CONFIRM_ASK')}<span/>`,
        inputOptions: {
          1: `<span>${t('LBL.DELETESECTIONOPT1')}</span>`,
          2: `<span class="mt-3">${t('LBL.DELETESECTIONOPT2')}</span>`,
        },
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        cancelButtonText: t('LBL.CANCEL'),
        confirmButtonText: t('LBL.YES_DELETE'),
        confirmBtnBsStyle: 'danger',
        reverseButtons: true,
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading();
        },
        preConfirm: () => {
          if (Swal.getInput()?.value == 1) {
            typeConfirmDelete.current = 1;
          } else {
            typeConfirmDelete.current = 2;
          }
        },
      }).then((result) => {
        if (result.value) deleteItemSection();
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let url = `${auth.apiws}/section/renameSection`;

    const form = e.target;
    const formData = new FormData(form);
    formData.append('section_name', sectionData?.section_name);
    section?.personal
      ? formData.append(
          'pps_id',
          idActiveSection?.current || sectionData?.pps_id,
        )
      : formData.append(
          'pse_id',
          idActiveSection?.current || sectionData?.pse_id,
        );
    formData.append('muser_id', ls.get('user_cred').usr_id);
    formData.append('emp_id', ls.get('user_cred').emp_id);

    if (!sectionName) {
      setTouched(true);
      error = true;
    }

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    if (!error) {
      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data.status == 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            setTouched(false);
            setSection((prev) => ({
              ...prev,
              section_name: '',
              rename_section: false,
            }));
            handleRenameSection('all');
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDraggable({
    id: section.section_name,
    data: 'section',
    disabled: section?.default_section,
  });

  const {
    setNodeRef: setSectionNodeRef,
    attributes: droppableAttributes,
    listeners: droppableListeners,
  } = useDroppable({
    id: `SectionDroppable-${sectionName}`,
    data: 'section',
    disabled: collapse,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (
    section?.pse_id == idActiveSection.current ||
    section?.pps_id == idActiveSection.current
  ) {
    return (
      <Col md={6} className="px-0 mt-2">
        <form
          className="form"
          id="formRenameSection"
          name="formRenameSection"
          onSubmit={handleSubmit}
        >
          <Field
            id="section_name"
            name="section_name"
            type="text"
            component={renderTextInput}
            placeholder={t('LBL.ADDSECTION')}
            disableTouched={touched}
            onChange={(e) => {
              if (e?.target?.value != undefined) {
                setSectionName(e.target.value);
                setSection((prev) => ({
                  ...prev,
                  section_name: e.target.value,
                }));
              }
            }}
            props={{ value: sectionData?.section_name ?? '' }}
          />
          <div className="mt-3">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setSection((prev) => ({
                  ...prev,
                  rename_section: false,
                }));

                handleRenameSection('all');
              }}
            >
              {t('LBL.CANCEL')}
            </button>
            <button className="btn btn-primary" type="submit">
              {t('FRM.SAVE')}
            </button>
          </div>
        </form>
      </Col>
    );
  } else {
    // archive detector
    let isArchive = section?.section_name == 'Archive' ? 'archive' : '';

    return (
      <div
        ref={setSectionNodeRef}
        {...droppableAttributes}
        {...droppableListeners}
      >
        <div
          className={`collapse__wrapper ${status} ${className} ${isArchive}`}
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
        >
          <div className="d-flex align-items-center">
            <button onClick={toggle} className="collapse__title w-auto pr-2">
              {section.default_section ? null : <DragIcon />}
              <div className="d-flex align-items-center">
                <img className="mr-2" src={STATUS_IMAGE[status]} />
                <p>{title}</p>
                <span className="section-count">{section?.tasks.length}</span>
              </div>
            </button>
            {!section.default_section && (
              <UncontrolledDropdown>
                <DropdownToggle className="small-dropdown">
                  {ELIPSIS_IMAGE[status]}
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem
                    onClick={() => {
                      setSection((prev) => ({
                        ...prev,
                        section_name: section?.section_name,
                        rename_section: true,
                      }));
                      handleRenameSection({
                        pse_id: section?.pse_id,
                        pps_id: section?.pps_id,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className={`text-success fa-fw`}
                    />
                    <span className="ml-2">{t('LBL.RENAME')}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => deleteSection(sectionId)}>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className={`text-danger fa-fw`}
                    />
                    <span className="ml-2">{t('FRM.DELETE')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
          <Collapse
            isOpen={collapse}
            className="collapse__content"
            onEntering={onEntering}
            onEntered={onEntered}
            onExiting={onExiting}
            onExited={onExited}
          >
            <div>{children}</div>
          </Collapse>
        </div>
      </div>
    );
  }
};

export default reduxForm({
  form: 'FormRenameSection',
  destroyOnUnmount: false,
  validate,
})(translate('global')(TaskTableCollapse));
