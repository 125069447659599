/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase, no-unused-vars */
/* eslint-disable no-plusplus, react/no-unused-state */
/* eslint-disable react/no-will-mount-set-state */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { translate } from 'react-i18next';
import PositionForm from './components/positionInputForm';
import PositionTreeMenu from './components/treeMenuPosition';
import axios from 'axios';
import ls from 'local-storage';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/fontawesome-free-solid';
import showNotifications from '../../../Preferences/notificationMessages';
import Swal from 'sweetalert2';

// eslint-disable-next-line no-unused-vars
function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.pos_id] = arrElem;
    mappedArr[arrElem.pos_id].children = [];
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const id in mappedArr) {
    // eslint-disable-next-line no-prototype-builtins
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.pos_id_parent) {
        if (mappedElem.children.length === 0) {
          mappedArr[mappedElem.pos_id_parent].children.push({
            pos_id: mappedElem.pos_id,
            title: mappedElem.position_nm,
            expanded: true,
            children: mappedElem.children,
          });
        } else {
          mappedArr[mappedElem.pos_id_parent].children.push({
            pos_id: mappedElem.pos_id,
            title: mappedElem.position_nm,
            expanded: true,
            children: mappedElem.children,
          });
        }
        // eslint-disable-next-line brace-style
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push({
          pos_id: mappedElem.pos_id,
          org_id: mappedElem.pos_id,
          title: mappedElem.position_nm,
          expanded: true,
          children: mappedElem.children,
        });
      }
    }
  }

  return tree;
}

class positionSetting extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    inUpdate: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };
  constructor(props) {
    super();
    this.state = {
      dataTrees: [],
      apiws: '',
      token: '',
      positionslc: [],
      editMode: false,
      defaultValues: {},
      modalFullScreen: false,
      unitorgslc: [],
      jobnm: [],
      posid_root: '',
      disable_root: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      dataWidth: ['5%', '25%', '20%', '20%', '20%', '10%'],
    };
    const { t } = props;

    this.heads = [
      { 0: t('LBL.NO') },
      { 1: t('LBL.POSITION') },
      { 2: t('LBL.UNIT_ORGANIZATION') },
      { 3: t('LBL.JOB_NAME') },
      { 4: t('LBL.MAIN_JOB_TITLE') },
      { 5: t('FRM.FUNCTION') },
    ];

    this.dataTreeLoad = this.dataTreeLoad.bind(this);
    this.loadaftersubmit = this.loadaftersubmit.bind(this);
    this.updateTree = this.updateTree.bind(this);
    this.editTreeModal = this.editTreeModal.bind(this);
    this.fulltree = this.fulltree.bind(this);
    this.togglefullmode = this.togglefullmode.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getButton = this.getButton.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.deletePos = this.deletePos.bind(this);
  }

  componentWillMount() {
    const setdefaultvalue = {
      pos_id: '',
      pos_id_parent: '',
      position_nm: '',
      org_id: '',
      jbn_id: '',
    };
    this.setState({ defaultValues: setdefaultvalue });
    this.setState({ token: ls.get('token') });
    this.setState({ urlData: `${ls.get('ws_ip')}/orgposition/getAllitem/` });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.dataTreeLoad();
      this.loadSelect();
      this.getButton();
      this.getTableData();
    });
  }

  componentWillReceiveProps() {
    const { onUpdate } = this.props;
    if (onUpdate) {
      this.loadSelect();
      this.props.inUpdate();
    }
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deletePos(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(`${apiws}/orgposition/deleteitem/${id}`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
                this.dataTreeLoad();
              }, 200);
            } else if (response.data.status === 'child') {
              showNotifications(
                'Fail',
                'Data is a parent, delete the child first!',
                'danger',
              );
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  loadSelect() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ unitorgslc: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/orgjobname/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.jbn_id, label: obj.job_name }),
          );
          this.setState({ jobnm: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  dataTreeLoad() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let datatree = [];
    axios
      .post(`${apiws}/orgposition/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          if (dataResponse.length === 0) {
            Arr.push({ value: 0, label: '---' });
            this.setState({ positionslc: Arr });
          } else {
            datatree = unplet(dataResponse);
            // console.log(datatree);
            this.setState({ dataTrees: datatree });
            this.setState({ posid_root: datatree[0].pos_id });
            Arr.push({ value: 0, label: '---' });
            dataResponse.map((obj) =>
              Arr.push({ value: obj.pos_id, label: obj.position_nm }),
            );
            this.setState({ positionslc: Arr });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  togglefullmode() {
    this.setState({
      modalFullScreen: !this.state.modalFullScreen,
    });
  }

  updateTree(datatree) {
    this.setState({ dataTrees: datatree });
  }

  editTreeModal(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    // let datatree = [];
    axios
      .post(`${apiws}/orgposition/getallitembyid/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataValues = response.data.data[0];
          const setdefaultvalue = {
            pos_id: dataValues.pos_id,
            pos_id_parent: dataValues.pos_id_parent,
            position_nm: dataValues.position_nm,
            org_id: dataValues.org_id,
            jbn_id: dataValues.jbn_id,
          };
          setTimeout(() => {
            this.setState({ defaultValues: setdefaultvalue });
            const dir = this.state.posid_root === dataValues.pos_id;
            this.setState({ disable_root: dir });
          }, 200);
        }
      })
      .catch(() => {
        // console.log(error);
      });
    this.setState({ editMode: true });
  }

  toggle() {
    this.setState(
      {
        defaultValues: {},
        editMode: !this.state.editMode,
      },
      () => {},
    );
  }

  fulltree() {
    this.setState({ modalFullScreen: true });
  }

  // eslint-disable-next-line no-unused-vars
  loadaftersubmit(result, dispatch, props) {
    setTimeout(() => {
      this.dataTreeLoad();
    }, 2000);
    this.setState({ editMode: false, defaultValues: {} });
    dispatch(reset('positioninput'));
  }

  render() {
    const {
      dataTrees,
      positionslc,
      defaultValues,
      editMode,
      modalFullScreen,
      disable_root,
    } = this.state;
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col md={6}>
            <PositionForm
              ref={(ref) => {
                this.positionFormRef = ref;
              }}
              initialValues={defaultValues}
              onSubmitSuccess={this.loadaftersubmit}
              positionslc={positionslc}
              editMode={editMode}
              editModal={this.toggle}
              rtbunitorg={this.state.unitorgslc}
              rtbjobname={this.state.jobnm}
              pos_root={disable_root}
              addcond={this.state.addcond}
              getTableData={this.getTableData}
            />
          </Col>
          <Col md={6}>
            <button
              className="btn btn-secondary btn-sm"
              style={{
                verticalAlign: 'middle',
                padding: '4px 8px',
                marginBottom: '0px',
                marginRight: '5px',
                paddingRight: '3px',
                position: 'absolute',
                top: '5px',
                right: '15px',
                zIndex: '100',
              }}
              onClick={() => this.fulltree()}
            >
              <FontAwesomeIcon icon={faExpandArrowsAlt} />
            </button>
            <PositionTreeMenu
              dataTree={dataTrees}
              editMode={this.editTreeModal}
              loadTree={this.dataTreeLoad}
              updateTree={this.updateTree}
              editcond={this.state.editcond}
              deletecond={this.state.deletecond}
            />
            <Modal
              isOpen={modalFullScreen}
              toggle={this.togglefullmode}
              className="modal-dialog modal-input"
              style={{ maxWidth: '1050px' }}
            >
              <ModalHeader toggle={this.togglefullmode}>
                {t('LBL.POSITION')}
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: '450px',
                  height: '400px',
                  overflowY: 'auto',
                }}
              >
                <PositionTreeMenu
                  fullMode
                  dataTree={dataTrees}
                  editMode={this.editTreeModal}
                  loadTree={this.dataTreeLoad}
                  updateTree={this.updateTree}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.togglefullmode}>
                  {t('FRM.CANCEL')}
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={this.state.urlData}
                  heads={this.heads}
                  primaryKey="pos_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editTreeModal}
                  deleteFunc={this.deletePos}
                  // getTableData={this.getTableData}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('global')(positionSetting);
