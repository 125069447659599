/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container, Row, Card, CardBody, Col, Button, Modal,
  ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import NumberFormat from 'react-number-format';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import Swal from 'sweetalert2';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import moment from 'moment';
import utils from '../../../../utils';

// let compthr = '';
class BpjsRatePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    pbt_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiws: '',
      iduser: '',
      token: '',
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      dataWidth: ['5%', '15%', '15%', '15%', '10%', '10%', '10%', '20%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      minVarChar: '',
      maxVarChar: '',
      bpjsRate: '',
      disabledMin: false,
      disabledMax: false,
      pbt_name: '',
      pbt_type: '',
    };
    const { t } = props;

    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERCENTAGE') },
      { '2': t('LBL.MINIMUM') },
      { '3': t('LBL.MAXIMUM') },
      { '4': `${t('LBL.MAXIMUM')} ${t('LBL.AGE')}` },
      { '5': t('LBL.START_DATE') },
      { '6': t('LBL.END_DATE') },
      { '7': t('FRM.FUNCTION') },
    ];

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERCENTAGE') },
      { '2': t('LBL.MINIMUM') },
      { '3': t('LBL.MAXIMUM') },
      { '4': `${t('LBL.MAXIMUM')} ${t('LBL.AGE')}` },
      { '5': t('LBL.START_DATE') },
      { '6': t('LBL.END_DATE') },
      { '7': t('FRM.FUNCTION') },
    ];
  }

  getBpjsType() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/payketenagakerjaantype/getAllItemById/${this.props.pbt_id}`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const res = response.data.data;
        this.setState({
          pbt_name: res.type[0].pbt_name,
          pbt_type: res.type[0].pbt_type,
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getTableData() {
    this.child.reload();
  }

  toggle(x) {
    this.props.destroy();
    this.setState({ statusEdit: false });
    this.setState({ maxVarChar: '' });
    this.setState({ minVarChar: '' });
    this.setState({ bpjsRate: '' });
    this.setState({ modal: !this.state.modal });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getBpjsType();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({
      urlData: `${ls.get('ws_ip')}/payketenagakerjaanrate/getAllItemByPbtId/${this.props.pbt_id}`,
    });
  }

  handleChange(group, obj) {
    if (group === 1) {
      if (obj.value === '1') {
        this.setState({ disabledMin: true });
        this.setState({ minVarChar: '' });
      } else {
        this.setState({ disabledMin: false });
      }
    } else if (group === 2) {
      if (obj.value === '3') {
        this.setState({ disabledMax: true });
        this.setState({ maxVarChar: '' });
      } else {
        this.setState({ disabledMax: false });
      }
    }
  }

  editDetail(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/payketenagakerjaanrate/getAllItemById/${id}`,
      '',
      config,
    ).then(async (response) => {
      if (response.data.status === 'ok') {
        const res = response.data.data;
        const datamaping = {
          pbr_id: res[0].pbr_id,
          bpjs_min_factor_type: res[0].bpjs_min_factor_type,
          bpjs_max_factor_type: res[0].bpjs_max_factor_type,
          bpjs_max_age: res[0].bpjs_max_age,
          start_date: res[0].start_date === null ? res[0].start_date : moment(res[0].start_date),
          end_date: res[0].end_date === null ? res[0].end_date : moment(res[0].end_date),
        };
        const dec = (res[0].bpjs_rate % 1 !== 0);
        const rate = (dec) ? (res[0].bpjs_rate * 100).toFixed(2).replace(/(\.0+|0+)$/, '') : res[0].bpjs_rate * 100;
        this.setState({
          bpjsRate: (res[0].bpjs_rate === null) ? '' : rate.toString().replace('.', ','),
          minVarChar: res[0].bpjs_min_factor_value,
          maxVarChar: res[0].bpjs_max_factor_value,
        });
        this.setState({ statusEdit: true });
        this.setState({ modal: true }, () => {
          this.props.initialize(datamaping);
        });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  deleteDetail(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/payketenagakerjaanrate/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.child.reload('delete');
            }, 2000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }


  handleSubmitInput(values) {
    const userid = ls.get('user_cred').usr_id;
    const {
      token,
      apiws,
      maxVarChar,
      minVarChar,
      bpjsRate,
    } = this.state;
    let error = true;
    const id = values.pbr_id;
    const pbtId = this.props.pbt_id;
    const start = (typeof values.start_date === 'undefined' || values.start_date === null) ? '' : values.start_date.format('YYYY-MM-DD');
    const end = (typeof values.end_date === 'undefined' || values.end_date === null) ? '' : values.end_date.format('YYYY-MM-DD');
    const min_type = (typeof values.bpjs_min_factor_type === 'object') ? values.bpjs_min_factor_type.value : values.bpjs_min_factor_type;
    const max_type = (typeof values.bpjs_max_factor_type === 'object') ? values.bpjs_max_factor_type.value : values.bpjs_max_factor_type;
    const max_age = (typeof values.bpjs_max_age === 'undefined' || values.bpjs_max_age === null) ? '' : values.bpjs_max_age;
    if (!values.start_date || !typeof values.start_date === 'object') { error = false; }
    if (typeof values.start_date === 'object' && values.start_date.isAfter(values.end_date)) { error = false; }
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/payketenagakerjaanrate/saveItem`;
      data = {
        pbt_id: pbtId,
        bpjs_min_factor_value: minVarChar,
        bpjs_min_factor_type: min_type,
        bpjs_max_factor_value: maxVarChar,
        bpjs_max_factor_type: max_type,
        bpjs_rate: bpjsRate,
        bpjs_max_age: max_age,
        start_date: start,
        end_date: end,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/payketenagakerjaanrate/updateItem/${id}`;
      data = {
        pbt_id: pbtId,
        bpjs_min_factor_value: minVarChar,
        bpjs_min_factor_type: min_type,
        bpjs_max_factor_value: maxVarChar,
        bpjs_max_factor_type: max_type,
        bpjs_rate: bpjsRate,
        bpjs_max_age: max_age,
        start_date: start,
        end_date: end,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
          setTimeout(() => {
            this.child.reload();
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        console.log(error);
      });
    }
  }

  render() {
    const {
      handleSubmit, t, pristine, formatFunction,
    } = this.props;
    const {
      urlData,
      statusEdit,
      disabledMax,
      disabledMin,
      pbt_name,
      pbt_type,
    } = this.state;
    let pbt_group = '';
    if (pbt_type === 1) {
      pbt_group = 'BPJS Ketenagakerjaan';
    } else if (pbt_type === 1) {
      pbt_group = 'BPJS Kesehatan';
    }

    let button_action;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('LBL.BPJS_RATE')}
      </Button>
    );
    const backBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={formatFunction}
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="bpjsrateform"
          // disabled={pristine}
        > {t('FRM.EDIT')}
        </Button>);
    } else {
      button_action = (
        <Button
          color="primary"
          form="bpjsrateform"
          type="submit"
          // disabled={pristine}
        >{t('FRM.SAVE')}
        </Button>);
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            {/* <div style={{ float: 'left', 'margin-left': '25px' }}>
              <h3 className="page-title">{pbt_group}</h3>
              <h3 className="page-subhead subhead">{pbt_name}</h3>
            </div> */}
            <div className="card__title" style={{ marginLeft: '25px', marginBottom: '0px' }}>
              <h5 className="bold-text">{pbt_group}</h5>
              <h5 className="subhead">{pbt_name}</h5>
            </div>
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="pbr_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  onRef={ref => (this.child = ref)}
                  updateFunc={this.editDetail}
                  deleteFunc={this.deleteDetail}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>{t('FRM.ADD')} {t('LBL.ATTRIBUTE')}</ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="bpjsrateform"
                  id="bpjsrateform"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        <input type="hidden" name="pbr_id" />
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.PERCENTAGE')}</span>
                            <div
                              className="form__form-group-field form__group-width-90 number-form"
                              style={{ paddingBottom: '15px', margin: '0px' }}
                            >
                              <NumberFormat
                                style={{ textAlign: 'right' }}
                                component={NumberFormat}
                                thousandSeparator="."
                                decimalSeparator=","
                                allowedDecimalSeparators={[',']}
                                type="text"
                                value={this.state.bpjsRate}
                                onValueChange={(val) => {
                                  let a;
                                  if (this.state.bpjsRate !== null) {
                                    a = this.state.bpjsRate.slice();
                                  }
                                  a = val.formattedValue;
                                  this.setState({ bpjsRate: a });
                                }}
                              />
                            </div>
                            <span className="form__form-group-label" style={{ 'margin-left': '0px' }}>%</span>
                          </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.MINIMUM')}</span>
                            <div className="form__form-group-field form-flex">
                              <Field
                                name="bpjs_min_factor_type"
                                component={renderSelectField}
                                options={[
                                  { value: '1', label: 'UMP ' },
                                  { value: '2', label: 'Value' },
                                ]}
                                onChange={(val) => { this.handleChange(1, val); }}
                              />
                              <NumberFormat
                                name="bpjs_min_factor_value"
                                component={NumberFormat}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="Rp "
                                value={this.state.minVarChar}
                                type="text"
                                disabled={disabledMin}
                                style={{
                                  textAlign: 'right',
                                }}
                                onValueChange={(val) => {
                                  let a;
                                  if (this.state.minVarChar !== null) {
                                    a = this.state.minVarChar.slice();
                                  }
                                  a = val.value;
                                  this.setState({ minVarChar: a });
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('LBL.MAXIMUM')}</span>
                            <div className="form__form-group-field form-flex">
                              <Field
                                name="bpjs_max_factor_type"
                                component={renderSelectField}
                                options={[
                                  { value: '2', label: 'Value' },
                                  { value: '3', label: 'Unlimited' },
                                ]}
                                onChange={(val) => { this.handleChange(2, val); }}
                              />
                              <NumberFormat
                                name="bpjs_max_factor_value"
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="Rp "
                                type="text"
                                value={this.state.maxVarChar}
                                disabled={disabledMax}
                                style={{
                                  textAlign: 'right',
                                }}
                                onValueChange={(val) => {
                                  let a;
                                  if (this.state.maxVarChar !== null) {
                                    a = this.state.maxVarChar.slice();
                                  }
                                  a = val.value;
                                  this.setState({ maxVarChar: a });
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div className="form_form-group-field">
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.MAXIMUM')} {t('LBL.AGE')}</span>
                              <div className="form__form-group-field form__group-width-90 number-form">
                                <Field
                                  name="bpjs_max_age"
                                  component="input"
                                  type="text"
                                  maxLength="3"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div className="form_form-group-field" style={{ marginTop: '20px' }}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.EFFECTIVE_DATE')}</span>
                              <div className="form__form-group-field range-select-form">
                                <Field
                                  name="start_date"
                                  component={renderDatePickerField}
                                  placeholder="Start Date"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                                <span className="form__form-group-label" style={{ 'margin-left': '10px' }}> To</span>
                                <Field
                                  name="end_date"
                                  component={renderDatePickerField}
                                  type="text"
                                  placeholder="End Date"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => { this.toggle('cancel'); }}>{t('FRM.CANCEL')}</Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    start_date: {
      fieldLabel: 'Start date',
      required: true,
    },
    end_date: {
      fieldLabel: 'End date',
      required: false,
      lockDateAfter: 'start_date',
      fieldLabelBefore: 'Start date',
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'reportadj',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(BpjsRatePage));
