/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable operator-assignment */
/* eslint-disable no-plusplus */

import React, { PureComponent } from 'react';
import {
  Row, Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
// import AdvanceAttendanceProcess from './AdvanceAttendanceProcess';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';

class TaxCalculationYearlyForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // onProcess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      arrPcs: [],
      periodId: '',
      startdate: '',
      enddate: '',
      status: '',
      apiws: '',
      token: '',
      idUser: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => { this.getYearPeriod(); });
  }

  getYearPeriod() {
    const startYear = new Date().getFullYear();
    let modifyYear = new Date().getFullYear() - 2;
    const year = [];
    while (modifyYear <= startYear) {
      year.push({
        value: modifyYear,
        label: modifyYear,
      });
      modifyYear = modifyYear + 1;
    }
    console.log(year);
    this.setState({ arrPcs: year });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleSubmit(values) {
    const {
      apiws,
      token,
      idUser,
    } = this.state;
    const periodId = values.selectyear.value;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const link = `${apiws}/paysalary/processTaxYearly`;
    const body = [`period_id=${periodId}`, `muser_id=${idUser}`].join('&');
    axios.post(
      link,
      body,
      config,
    ).then((res) => {
      // alert(JSON.stringify(res));
      // this.props.onProcess();
      if (res.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
      }
    });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { periodId } = this.state;

    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col>
                <h3 className="page-title">{t('MEN.TAX_CALCULATION')}</h3>
                <h3 className="page-subhead subhead">{t('MEN.TAX_CALCULATION')}</h3>
              </Col>
            </Row>
            <Row className="m5">
              <Col md={6} xs={12} lg={6} xl={6}>
                <form className="form form--horizontal" onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="selectyear"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Year"
                        options={this.state.arrPcs}
                        onChange={(val) => {
                          this.setState({ periodId: val.value });
                        }}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    {/* <Button color="secondary" onClick={this.toggle}>{t('LBL.ADVANCE')}</Button> */}
                    <Button color="primary" type="submit">{t('LBL.PROCESS')}</Button>
                  </ButtonToolbar>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col >
    );
  }
}

export default reduxForm({
  form: 'apr_form', // a unique identifier for this form
})(translate('global')(TaxCalculationYearlyForm));
