import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBriefcase, faClock } from '@fortawesome/fontawesome-free-solid';
// import Scrollbar from 'react-smooth-scrollbar';

class TaskListFormApproval extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <div className="TaskBox__header">
              <div className="TaskBox__header-list">
                <div className="title">Task Type</div>
                <div className="description">Leave Approval</div>
              </div>
              <div className="TaskBox__header-list">
                <div className="title">Create Date</div>
                <div className="description">11/11/2018 11:00:00 (1 Week Ago)</div>
              </div>
              <div className="TaskBox__header-list">
                <div className="title">Employee</div>
                <div className="description">Budi Antonius (E01234) - GM Services</div>
              </div>
            </div>
            <div className="TaskBox__body">
              <h3>Task Information</h3>
              <hr />
              <form className="form form-tasklist" onSubmit={handleSubmit}>
                <div className="task_panel_list">
                  <div className="tasklist-detail">
                    <div className="title">Task Type</div>
                    <div className="description">Annual Leave</div>
                  </div>
                  <div className="tasklist-detail">
                    <div className="title">Date Period</div>
                    <div className="description">11/11/2018 - 13/11/2018 (3 day working days)</div>
                  </div>
                  <div className="tasklist-detail">
                    <div className="title">Reason/Comment</div>
                    <div className="description">Vacation with family</div>
                  </div>
                  <div className="tasklist-detail">
                    <div className="title">Delegation</div>
                    <div className="description">Tjokro (E234234) - VP Product</div>
                  </div>
                  <div className="tasklist-detail">
                    <div className="title">Leave Balance</div>
                    <div className="description">3 days (12 day, 9 days used) <Link to="/">History</Link></div>
                  </div>
                  <div className="tasklist-detail">
                    <div className="title">Reason Approval</div>
                    <div className="description">
                      <Field
                        name="textarea"
                        component="textarea"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'task_approval_form',
})(translate('common')(TaskListFormApproval));
