import React, { useCallback, useEffect, useState } from 'react';
import ls from 'local-storage';
import OrgChartComponent from './OrgChartComponent';
import axios from 'axios';
import { Card, CardBody, Container } from 'reactstrap';
// import { faProjectDiagram } from '@fortawesome/fontawesome-free-solid';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './OrgStructure.css';

const OrgStructure = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // vars
  const apiws = ls.get('ws_ip');
  const token = ls.get('token');

  useEffect(() => {
    initData();
  }, []);

  // handling fetch
  const initData = useCallback(() => {
    if (!isLoading) setLoading(true);
    axios
      .post(`${apiws}/orgorganization/structurediagram`, '', {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        setLoading(false);
        const { data } = response;
        if (data.success) {
          setData(data.data);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        // this.setState({ button_disabled: false });
      });
  }, []);

  // component
  const LoadingComponent = useCallback(() => {
    if (isLoading) {
      return (
        <div
          className="load__icon-wrap text-center align-self-center"
          style={{ marginTop: '30vh' }}
        >
          <svg className="load__icon">
            <path
              fill="#4ce1b6"
              d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
            />
          </svg>
        </div>
      );
    } else {
      return <></>;
    }
  }, [isLoading]);

  return (
    <Container>
      <Card>
        <CardBody>
          <h5 className="page-title">Organization Structure</h5>
          {/* <button type="button" class="btn btn-primary btn-circle"><FontAwesomeIcon icon={faProjectDiagram} />
                            </button> */}
          <LoadingComponent />

          {!isLoading && <OrgChartComponent data={data} />}
        </CardBody>
      </Card>
    </Container>
  );
};

export default OrgStructure;
