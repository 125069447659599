/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderIntervalDatePickerField from '../../../../../shared/components/form/IntervalDatePicker';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import ls from 'local-storage';
import axios from 'axios';
// import renderDatePickerField from '../../../../shared/components/form/DatePicker';
// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import moment from 'moment';

class AttendanceProcess extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    periodId: PropTypes.func.isRequired,
    start: PropTypes.func.isRequired,
    end: PropTypes.func.isRequired,
    status: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      arrEmp: [],
      arrOffice: [],
      arrOrganization: [],
      disabledOffice: false,
      disabledEmp: false,
      disabledOrg: false,
      filter: '',
      displayLoad: 'none',
      displayApprov: '',
      arrPcs: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getOffice = this.getOffice.bind(this);
    this.getOrganization = this.getOrganization.bind(this);
    this.getPeriodProcess = this.getPeriodProcess.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getEmployee(); this.getOffice(); this.getOrganization(); this.getPeriodProcess();
    });
  }

  getTableData() {
    this.child.reload();
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/employee/getAll/`,
      '',
      config,
    ).then((resemp) => {
      const emp = resemp.data.data;
      const array = [];
      if (emp.length === 0) {
        this.setState({ arrEmp: array });
      } else {
        emp.map(obj => (
          array.push({ value: obj.emp_id, label: obj.emp_name })
        ));
        this.setState({ arrEmp: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getOffice() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/orgoffice/getAllItem/`,
      '',
      config,
    ).then((response) => {
      const office = response.data.data;
      const array = [];
      if (office.length === 0) {
        this.setState({ arrOffice: array });
      } else {
        office.map(obj => (
          array.push({ value: obj.off_id, label: obj.office })
        ));
        this.setState({ arrOffice: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  getPeriodProcess() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/payperiod/getAllRunAttendance`,
      '',
      config,
    ).then((response) => {
      const pcs = response.data.data;
      const array = [];

      console.log(this.response);
      if (pcs.length === 0) {
        this.setState({ arrPcs: array });
      } else {
        pcs.map(obj => (
          array.push({
            value: obj.pyp_id,
            label: obj.period_code,
            start: obj.attendance_start,
            end: obj.attendance_end,
            status: obj.is_process_attendance,
          })
        ));
        setTimeout(() => {
          this.setState({ arrPcs: array });
        }, 200);
      }
    });
  }

  getOrganization() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(
      `${ls.get('ws_ip')}/orgorganization/getAll/`,
      '',
      config,
    ).then((response) => {
      const organization = response.data.data;
      const array = [];
      if (organization.length === 0) {
        this.setState({ arrOrganization: array });
      } else {
        organization.map(obj => (
          array.push({ value: obj.org_id, label: obj.unit_organization })
        ));
        this.setState({ arrOrganization: array });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  handleChange(value) {
    switch (value) {
      case '1':
        this.setState({ disabledOffice: false });
        this.setState({ disabledOrg: true });
        this.setState({ disabledEmp: true });
        this.setState({ filter: value });
        break;
      case '2':
        this.setState({ disabledOffice: true });
        this.setState({ disabledOrg: false });
        this.setState({ disabledEmp: true });
        this.setState({ filter: value });
        break;
      case '3':
        this.setState({ disabledOffice: true });
        this.setState({ disabledOrg: true });
        this.setState({ disabledEmp: false });
        this.setState({ filter: value });
        break;
      default:
    }
  }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    this.setState({ displayLoad: '', displayApprov: 'none' });
    const error = true;
    const id = values.atm_id;
    const start_date = this.state.startdate;
    const end_date = this.state.enddate;
    const pyp = this.state.pypid;
    const st = this.state.status;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/attprocess/saveItem`;
      data = {
        ...values,
        start_date,
        end_date,
        pypid: pyp,
        apr_status: st,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/attprocess/updateItem/${id}`;
      data = {
        ...values, start_date, end_date, muser_id: userid,
      };
    }
    data = {
      data: JSON.stringify(data),
    };
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          // this.props.destroy();
          setTimeout(() => {
            this.setState({ displayLoad: 'none', displayApprov: '' });
            showNotifications('Success', 'Save Success', 'success');
          }, 1000);
        } else {
          this.setState({ displayLoad: 'none', displayApprov: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      arrEmp, arrOffice, arrOrganization, disabledEmp, disabledOffice, disabledOrg, filter, displayApprov, displayLoad,
    } = this.state;
    let filter_action;
    if (filter === '1') {
      filter_action = (
        <div className="form__form-group">
          <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
          <div className="form__form-group-field">
            <Field
              name="officeAutoComplete"
              component={renderSelectField}
              options={arrOffice}
              type="text"
              placeholder="Search Office"
              disabled={disabledOffice}
              clearable="true"
            />
            <div className="form__form-group-icon">
              <AccountSearchIcon />
            </div>
          </div>
        </div>);
    } else if (filter === '2') {
      filter_action = (
        <div className="form__form-group">
          <span className="form__form-group-label">{t('LBL.ORGANIZATION')}</span>
          <div className="form__form-group-field">
            <Field
              name="organizationAutoComplete"
              component={renderSelectField}
              options={arrOrganization}
              type="text"
              placeholder="Search Organization"
              disabled={disabledOrg}
            />
            <div className="form__form-group-icon">
              <AccountSearchIcon />
            </div>
          </div>
        </div>);
    } else if (filter === '3') {
      filter_action = (
        <div className="form__form-group">
          <span className="form__form-group-label">{t('LBL.EMPLOYEE')}</span>
          <div className="form__form-group-field">
            <Field
              name="employeeAutoComplete"
              component={renderSelectField}
              options={arrEmp}
              type="text"
              placeholder="Search Employee"
              disabled={disabledEmp}
            />
            <div className="form__form-group-icon">
              <AccountSearchIcon />
            </div>
          </div>
        </div>);
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col>
                    <h3 className="page-title">{t('MEN.ATTENDANCE_PROCESS')}</h3>
                    {/* <h3 className="page-subhead subhead">View & Process Attendance</h3> */}
                  </Col>
                </Row>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addadvanceattprocess"
                  id="addadvanceattprocess"
                >
                  <Container>
                    <Row className="m5">
                      <Col md={6} xs={12} lg={6} xl={6}>
                        {/* <div className="form__form-group form__form-group--address">
                          <span className="form__form-group-label">{t('LBL.DATE')}  </span>
                          <div className="form__form-group-field range-select-form">
                            <Field
                              name="start_date"
                              component={renderDatePickerField}
                              placeholder="Start Date"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                            <span className="form__form-group-label" style={{ 'margin-left': '10px' }}> {t('LBL.TO')}</span>
                            <Field
                              name="end_date"
                              component={renderDatePickerField}
                              type="text"
                              placeholder="End Date"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.PERIOD')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="period"
                              component={renderSelectField}
                              type="text"
                              placeholder={t('LBL.SELECT_PERIOD')}
                              options={this.state.arrPcs}
                              onChange={(val) => {
                                this.setState({ pypid: val.value });
                                this.setState({ startdate: val.start });
                                this.setState({ enddate: val.end });
                                this.setState({ status: val.status });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="m5">
                      <Col md={6} xs={12} lg={6} xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.FILTER_BY')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="apr_filter"
                              component={renderSelectField}
                              type="text"
                              options={[
                                { value: '1', label: t('LBL.OFFICE') },
                                { value: '2', label: t('LBL.UNIT_ORGANIZATION') },
                                { value: '3', label: t('LBL.EMPLOYEE') },
                              ]}
                              onChange={(val) => { this.handleChange(val.value); }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="m5">
                      <Col md={6} xs={12} lg={6} xl={6}>
                        {filter_action}
                      </Col>
                    </Row>
                    <ButtonToolbar className="form__button-toolbar" style={{ marginLeft: '188px' }}>
                      <Button color="secondary" onClick={() => this.props.toggleModal()}>{t('FRM.CANCEL')}</Button>
                      <Button type="submit" color="primary" form="addadvanceattprocess" style={{ display: displayApprov }}>{t('LBL.PROCESS')}</Button>
                      <Button color="primary" style={{ display: displayLoad }}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '6px' }}
                        />
                        {t('FRM.LOADING')}
                      </Button>
                    </ButtonToolbar>
                  </Container>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'advance_apr_form', // a unique identifier for this form
})(translate('global')(AttendanceProcess));
