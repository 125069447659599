/* eslint-disable array-callback-return */
/*
 * Page Group Component
 * Notes: Master Data Group Component
 * @author Gerdi
 * @date 02/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus, react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func, object-curly-newline */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-parens */
/* eslint-disable operator-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign, no-lonely-if */

import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip,
  Table,
} from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faExpandArrowsAlt,
  faEraser,
  faTimes,
  faSearch,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/fontawesome-free-solid';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import showNotifications from '../../../notificationMessages';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../../shared/components/form/Select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2';
import SortableTree from 'react-sortable-tree';
import '../../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import NumberFormat from 'react-number-format';

function unplet(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.pcs_order] = arrElem;
    mappedArr[arrElem.pcs_order].children = [];
  }
  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      if (mappedElem.pcs_parent === null || mappedElem.pcs_parent === 'null') {
        tree.push({
          id: mappedElem.pcs_order,
          title: mappedElem.label,
          level: mappedElem.pcs_level,
          treeId: mappedElem.pcs_id,
          parent: mappedElem.pcs_count,
          expanded: true,
          children: mappedElem.children,
        });
      } else {
        if (mappedElem.children.length === 0) {
          mappedArr[mappedElem.pcs_parent].children.push({
            id: mappedElem.pcs_order,
            title: mappedElem.label,
            level: mappedElem.pcs_level,
            treeId: mappedElem.pcs_id,
            parent: mappedElem.pcs_count,
            expanded: true,
            children: mappedElem.children,
          });
        } else {
          mappedArr[mappedElem.pcs_parent].children.push({
            id: mappedElem.pcs_order,
            title: mappedElem.label,
            level: mappedElem.pcs_level,
            treeId: mappedElem.pcs_id,
            parent: mappedElem.pcs_count,
            expanded: true,
            children: mappedElem.children,
          });
        }
      }
    }
  }
  return tree;
}

class ComponentFormulasFormz extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
    groupId: PropTypes.func.isRequired,
    groupComponent: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.GROUP_COMPONENT') },
      { '2': t('LBL.CODE') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['5%', '50%', '25%', '20%'],
      buttonAction: ['edit', 'delete', 'component', 'duplicate'],
      defaultId: 1,
      urlData: [],
      statusEdit: false,
      onSaving: false,
      rows: [],
      totalGroup: '',
      modal: false,
      arrComponent: [],
      arrFormulaFilter: [],
      arrFormulaFilterVal: [],
      arrParent: [],
      disabledref: false,
      styleParamComponent: 'none',
      dataTree: [],
      componentId: '',
      pcsValue: '',
      pcsValueFormatted: '',
      search: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getComponent = this.getComponent.bind(this);
    this.getButton = this.getButton.bind(this);
    this.search = this.search.bind(this);
    this.handleChangeCondition = this.handleChangeCondition.bind(this);
    this.handleChangeLevel = this.handleChangeLevel.bind(this);
    this.handleChangeParamValue = this.handleChangeParamValue.bind(this);
    this.deleteFormula = this.deleteFormula.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ idUser: ls.get('user_cred').usr_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getComponent();
      this.getButton();
      this.getFormulaFilter();
      this.getComponentOption();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/paycomponentgroup/getAllItem`,
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NO') },
      { '1': t('LBL.GROUP_COMPONENT') },
      { '2': t('LBL.CODE') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getTree(id) {
    const groupId = this.props.groupId;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${ls.get(
          'ws_ip',
        )}/paycomponentgrouprelstructure/getalltree/${groupId}/${id}`,
        '',
        config,
      )
      .then((res) => {
        const dataResponse = res.data.data;
        if (dataResponse.length > 0) {
          const Arr = unplet(dataResponse);
          this.setState({ dataTree: Arr });
        } else {
          this.setState({ dataTree: [] });
        }
      });
  }

  search(event) {
    if (typeof event !== 'undefined') {
      this.setState({ search: event.target.value });
      setTimeout(() => {
        this.getComponent();
      }, 100);
    }
  }

  deleteFormula(id) {
    const { componentId } = this.state;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      confirmBtnBsStyle: 'danger',
      cancelBtnBsStyle: 'default',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const config = {
          headers: {
            Authorization: `bearer ${ls.get('token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        axios
          .post(
            `${ls.get('ws_ip')}/paycomponentgrouprelstructure/deleteItem/${id}`,
            '',
            config,
          )
          .then((res) => {
            if (res.data.status === 'ok') {
              showNotifications('Success', 'Save Success', 'success');
              this.getTree(componentId);
            }
          });
      }
    });
  }

  getComponent() {
    const reloadState = this;
    const rows = [];
    const data = [];
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const pyg = this.props.groupId;
    const bodyParameters = { id: pyg, query: this.state.search };
    axios
      .post(
        `${apiws}/paycomponentgroup/getAllItemGrouping`,
        bodyParameters,
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.data.data.length > 0) {
          for (let i = 0; i < response.data.data.length; i += 1) {
            rows.push({
              pyc_id: response.data.data[i].pyc_id,
              component: response.data.data[i].component,
              code: response.data.data[i].component_code,
              default_icon: 'laptop',
              checked: response.data.data[i].checked,
              order_no: i + 1,
            });
            if (response.data.data[i].checked === true) {
              data.push({
                pyc_id: response.data.data[i].pyc_id,
                usr_id: this.state.idUser,
                cuser_id: this.state.idUser,
              });
            }
          }
          reloadState.setState({ totalGroup: response.data.total });
          // reloadState.setState({ datacomponent: data });
          reloadState.setState({ rows });
        }
      });
  }

  toggle(id, tree = '') {
    this.props.destroy();
    this.setState({
      modal: !this.state.modal,
      componentId: id,
    });
    if (tree === 'show') {
      this.getTree(id);
    }
  }

  getComponentOption() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paycomponent/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        const Arr = [];
        if (dataResponse.length > 0) {
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pyc_id, label: obj.component }),
          );
          this.setState({ arrComponent: Arr });
        }
      });
  }

  getFormulaFilter() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payformulafilter/getAll`, '', config)
      .then((resformula) => {
        const rff = resformula.data.data;
        const Arr = [];
        if (rff.length > 0) {
          Arr.push({ value: 0, label: 'All' });
          rff.map((obj) =>
            Arr.push({
              value: obj.pff_id,
              label: obj.label,
              url: obj.url,
              column_id: obj.column_id,
              column_name: obj.column_name,
            }),
          );
          this.setState({ arrFormulaFilter: Arr });
        } else {
          this.setState({ arrFormulaFilter: [] });
        }
      });
  }

  handleChangeCondition(e) {
    let error = true;
    if (typeof e.url === 'undefined') {
      error = false;
      this.setState({ arrFormulaFilterVal: [] });
    }
    if (error) {
      const controller = e.url;
      const columnId = e.column_id;
      const columnName = e.column_name;
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios.post(`${apiws}/${controller}/getAll`, '', config).then((res) => {
        const rct = res.data.data;
        const Arr = [];
        if (rct.length > 0) {
          rct.map((obj) =>
            Arr.push({ value: obj[columnId], label: obj[columnName] }),
          );
          this.setState({ arrFormulaFilterVal: Arr });
        } else {
          this.setState({ arrFormulaFilterVal: [] });
        }
      });
    }
  }

  handleChangeLevel(e) {
    if (e.value === 1) {
      this.setState({ disabledref: 'disabled' });
    } else {
      this.setState({ disabledref: '' });
      const { componentId, token, apiws } = this.state;
      const groupId = this.props.groupId;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(
          `${apiws}/paycomponentgrouprelstructure/getAlllevel/${groupId}/${componentId}/${e.value}`,
          '',
          config,
        )
        .then((res) => {
          const datas = res.data.data;
          const Arr = [];
          if (datas.length > 0) {
            datas.map((obj) =>
              Arr.push({
                value: obj.pcs_order,
                label: `${obj.label} - ${obj.pff_label}`,
              }),
            );
            this.setState({ arrParent: Arr });
          } else {
            this.setState({ arrParent: [] });
          }
        });
    }
  }

  handleChangeComponent(e, index) {
    const { dynComponent, arrSelectedComponent } = this.state;
    const dt_comptype = { component_type: e.value };
    const dt_pgc = e.value;
    if (typeof dynComponent[index] === 'object') {
      dynComponent[index] = [];
      dynComponent[index] = dt_comptype;
      arrSelectedComponent[index] = [];
      arrSelectedComponent[index] = dt_pgc;
    } else {
      dynComponent.push(dt_comptype);
      arrSelectedComponent.push(dt_pgc);
    }
  }

  handleChangeParamValue(e) {
    if (e.value === 2 || e.value === 5) {
      this.setState({ styleParamComponent: '' });
    } else {
      this.setState({ styleParamComponent: 'none' });
    }
  }

  handleChangeGroupComp(e) {
    const group = e.target.value;
    this.setState({ inputGroupComponent: group });
  }

  handleChangeGroupCode(e) {
    const code = e.target.value;
    this.setState({ inputGroupCode: code });
  }

  removeForm(i) {
    const dynForm = [...this.state.dynForm];
    dynForm.splice(i, 1);
    this.setState({ dynForm });
  }

  handleSubmit(values) {
    this.setState({ onSaving: true });
    const pff_id = values.pff_id.value;
    const pff_value =
      typeof values.pff_value === 'undefined' || values.pff_value.value === null
        ? ''
        : values.pff_value.value;
    const pff_label =
      typeof values.pff_value === 'undefined' || values.pff_value.value === null
        ? ''
        : values.pff_value.label;
    const pcs_level = values.pcs_level.value;
    const pcs_type =
      typeof values.pcs_type === 'undefined' || values.pcs_type.value === null
        ? ''
        : values.pcs_type.value;
    const pcs_value = this.state.pcsValue;
    const pcs_parent =
      typeof values.pcs_parent === 'undefined' ||
      values.pcs_parent.value === null
        ? ''
        : values.pcs_parent.value;
    const pcs_component = JSON.stringify(values.pcs_component);
    const { apiws, token, idUser, componentId } = this.state;
    const groupId = this.props.groupId;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const addBody = [
      `pyg_id=${groupId}`,
      `pyc_id=${componentId}`,
      `pff_id=${pff_id}`,
      `pff_value=${pff_value}`,
      `pff_label=${pff_label}`,
      `pcs_parent=${pcs_parent}`,
      `pcs_level=${pcs_level}`,
      `pcs_type=${pcs_type}`,
      `pcs_value=${pcs_value}`,
      `pcs_component=${pcs_component}`,
      `cuser_id=${idUser}`,
    ].join('&');
    const url = `${apiws}/paycomponentgrouprelstructure/saveItem`;
    axios.post(url, addBody, config).then(() => {
      showNotifications('Success', 'Save Success', 'success');
      this.setState({
        onSaving: false,
        // modal: !this.state.modal,
        pcsValueFormatted: '',
        pcsValue: '',
        styleParamComponent: 'none',
        arrParent: [],
        arrFormulaFilterVal: [],
      });
      this.getTree(componentId);
      this.props.initialize({});
    });
  }

  onMoveUp(key, order) {
    const { apiws, token, idUser } = this.state;
    const url = `${apiws}/paycomponentgrouprel/moveUpItem`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const pyg = this.props.groupId;
    const data = `pyg_id=${pyg}&pyc_id=${key}&order_no=${order}&user_id=${idUser}`;
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        this.getComponent();
      }
    });
  }

  onMoveDown(key, order) {
    const { apiws, token, idUser } = this.state;
    const url = `${apiws}/paycomponentgrouprel/moveDownItem`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const pyg = this.props.groupId;
    const data = `pyg_id=${pyg}&pyc_id=${key}&order_no=${order}&user_id=${idUser}`;
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        this.getComponent();
      }
    });
  }

  updateToggle(id) {
    const { apiws, token, idUser } = this.state;
    const url = `${apiws}/paycomponentgrouprel/saveItem`;
    const pyg = this.props.groupId;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = `pyg_id=${pyg}&pyc_id=${id}&user_id=${idUser}`;
    axios.post(url, data, config).then((response) => {
      if (response.data.status === 'ok') {
        this.getComponent();
      }
    });
  }

  render() {
    const {
      onSaving,
      totalGroup,
      arrComponent,
      arrFormulaFilter,
      arrFormulaFilterVal,
      arrParent,
      disabledref,
      styleParamComponent,
      dataTree,
    } = this.state;
    const { t, handleSubmit, pristine } = this.props;
    const modalStyle = {
      maxWidth: '900px',
      marginTop: '95px',
      textAlign: 'center',
    };
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn_table_navbar"
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
        onClick={() => this.props.groupComponent('')}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const labelPlus = (
      <span>
        {' '}
        <i className="fas fa-plus" /> Plus{' '}
      </span>
    );
    const labelMinus = (
      <span>
        {' '}
        <i className="fas fa-minus" /> Minus{' '}
      </span>
    );
    const labelTimes = (
      <span>
        {' '}
        <i className="fas fa-times" /> Times{' '}
      </span>
    );
    const labelDivide = (
      <span>
        {' '}
        <i className="fas fa-divide" /> Divide{' '}
      </span>
    );
    const labelEquals = (
      <span>
        {' '}
        <i className="fas fa-equals" /> Equals{' '}
      </span>
    );
    const labelPercentages = (
      <span>
        {' '}
        <i className="fas fa-percentage" /> Percentage{' '}
      </span>
    );
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="input-group mb-3">
                      <input
                        value={this.state.search}
                        onChange={this.search}
                        type="text"
                        className="form-control"
                        placeholder={t('LBL.SEARCHING_BY_KEYWORDS')}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">{backBtn}</div>
                </div>
                <div
                  style={{
                    overflowY: 'auto',
                    height: '390px',
                    maxHeight: '390px',
                  }}
                >
                  <Table className="table data-grid-tbl responsive no-wrap">
                    <thead>
                      <th>{t('LBL.NUMBER')}</th>
                      <th>{t('LBL.COMPONENT')}</th>
                      <th>{t('LBL.CODE')}</th>
                      <th colSpan="3">{t('FRM.FUNCTION')}</th>
                    </thead>
                    <tbody>
                      {this.state.rows.map((data, key) => (
                        <tr key={key}>
                          <td width="6%">
                            <span className="shortcutlist_icon">
                              {' '}
                              {data.order_no}.{' '}
                            </span>
                          </td>
                          <td width="54%">{data.component}</td>
                          <td width="10%">{data.code}</td>
                          <td
                            width="10%"
                            className="nav-shortcutlist"
                            style={{ float: 'left' }}
                          >
                            {data.checked && this.state.search === '' && (
                              <div className="btn-group-shortcutlist">
                                {data.order_no !== 1 && data.order_no !== 2 ? (
                                  <Button
                                    className="btn-nav-shortcut"
                                    color="secondary"
                                    size="sm"
                                    onClick={() =>
                                      this.onMoveUp(data.pyc_id, data.order_no)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faAngleUp} />
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-nav-shortcut"
                                    color="secondary"
                                    size="sm"
                                    disabled
                                  >
                                    <FontAwesomeIcon icon={faAngleUp} />
                                  </Button>
                                )}
                                {data.order_no !== totalGroup &&
                                data.order_no !== 1 ? (
                                  <Button
                                    className="btn-nav-shortcut"
                                    color="secondary"
                                    size="sm"
                                    onClick={() =>
                                      this.onMoveDown(
                                        data.pyc_id,
                                        data.order_no,
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faAngleDown} />
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-nav-shortcut"
                                    color="secondary"
                                    size="sm"
                                    disabled
                                  >
                                    <FontAwesomeIcon icon={faAngleDown} />
                                  </Button>
                                )}
                              </div>
                            )}
                          </td>
                          <td width="10%">
                            {data.checked && (
                              <Button
                                style={{
                                  margin: '0px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                                color="primary"
                                size="sm"
                                onClick={() => this.toggle(data.pyc_id, 'show')}
                              >
                                {' '}
                                {this.props.t('LBL.FORMULA')}
                              </Button>
                            )}
                          </td>
                          <td width="10%">
                            {/* {(data.code === 'Salary') ?
                                (
                                  <BootstrapSwitchButton
                                    checked={data.checked}
                                    onlabel=" "
                                    offlabel=" "
                                    size="sm"
                                    onstyle="light"
                                    width={40}
                                    disabled
                                  />
                                ) : ( */}
                            {data.code === 'Salary' ? (
                              ''
                            ) : (
                              <BootstrapSwitchButton
                                checked={data.checked}
                                onlabel=" "
                                offlabel=" "
                                size="sm"
                                onstyle="success"
                                width={40}
                                onChange={(e) =>
                                  this.updateToggle(data.pyc_id, e, key)
                                }
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal-dialog modal-input"
            style={modalStyle}
          >
            <ModalHeader toggle={this.toggle}>
              {' '}
              {t('FRM.ADD')} {t('LBL.GROUP_COMPONENT')}{' '}
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col md={5}>
                    <form
                      className="form"
                      onSubmit={handleSubmit(this.handleSubmit)}
                      name="groupcompformformulas"
                      id="groupcompformformulas"
                    >
                      <Container>
                        <Row>
                          <Col md={12} sm={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CONDITION')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="pff_id"
                                  component={renderSelectField}
                                  options={arrFormulaFilter}
                                  onChange={this.handleChangeCondition}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.SUB_CONDITION')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="pff_value"
                                  component={renderSelectField}
                                  options={arrFormulaFilterVal}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.LEVEL')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="pcs_level"
                                  component={renderSelectField}
                                  options={[
                                    { value: 1, label: 'Level 1' },
                                    { value: 2, label: 'Level 2' },
                                    { value: 3, label: 'Level 3' },
                                  ]}
                                  onChange={this.handleChangeLevel}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.PARENT')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="pcs_parent"
                                  component={renderSelectField}
                                  options={arrParent}
                                  disabled={disabledref}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.VALUE')}
                              </span>
                              <div className="form__form-group-field number-form">
                                <Col md={5} style={{ padding: '0 0 0 0' }}>
                                  <Field
                                    name="pcs_type"
                                    component={renderSelectField}
                                    options={[
                                      { value: 0, label: labelPlus },
                                      { value: 1, label: labelMinus },
                                      { value: 2, label: labelTimes },
                                      // { value: 3, label: labelDivide },
                                      { value: 4, label: labelEquals },
                                      { value: 5, label: labelPercentages },
                                    ]}
                                    onChange={this.handleChangeParamValue}
                                  />
                                </Col>
                                <Col md={7} style={{ padding: '0 0 0 0' }}>
                                  <NumberFormat
                                    style={{
                                      textAlign: 'right',
                                      height: '36px',
                                    }}
                                    component={NumberFormat}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    allowedDecimalSeparators={[',']}
                                    type="text"
                                    name="pcs_value"
                                    value={this.state.pcsValueFormatted}
                                    onValueChange={(val) => {
                                      const a = val.value;
                                      const b = val.formattedValue;
                                      this.setState({ pcsValue: a });
                                      this.setState({ pcsValueFormatted: b });
                                    }}
                                  />
                                </Col>
                              </div>
                            </div>
                            <div
                              className="form__form-group"
                              style={{ display: styleParamComponent }}
                            >
                              <span className="form__form-group-label">
                                {t('LBL.COMPONENT')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="pcs_component"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrComponent}
                                  multi="true"
                                  className='custom-multi'
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </Col>
                  <Col md={7}>
                    <Card className="treeMenu_card">
                      <CardBody style={{ height: '370px' }}>
                        <SortableTree
                          treeData={dataTree}
                          rowHeight={50}
                          generateNodeProps={(rowInfo) => ({
                            buttons: [
                              (rowInfo.node.parent === '0' ||
                                rowInfo.node.parent === 0) && (
                                <div>
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    style={{
                                      verticalAlign: 'middle',
                                      padding: '0px 8px',
                                      marginBottom: '0px',
                                      marginRight: '5px',
                                      paddingRight: '3px',
                                    }}
                                    onClick={() =>
                                      this.deleteFormula(rowInfo.node.treeId)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              ),
                            ],
                          })}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.toggle()}>
                Cancel
              </Button>
              {onSaving ? (
                <Button color="primary">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '6px' }}
                  />
                  Loading...
                </Button>
              ) : (
                <Button
                  color="primary"
                  type="submit"
                  form="groupcompformformulas"
                  disabled={pristine}
                >
                  {' '}
                  Save
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.pff_id) {
    errors.pff_id = 'Condition component field shouldn’t be empty';
  }
  if (values.pff_id === null) {
    errors.pff_id = 'Condition component field shouldn’t be empty';
  }
  if (typeof values.pff_id !== 'undefined') {
    if (values.pff_id !== null) {
      if (values.pff_id.value !== 0) {
        if (!values.pff_value) {
          errors.pff_value = 'Sub condition component field shouldn’t be empty';
        }
      }
    }
  }
  if (!values.pcs_level) {
    errors.pcs_level = 'Level field shouldn’t be empty';
  }
  if (values.pcs_level === null) {
    errors.pcs_level = 'Level field shouldn’t be empty';
  }
  if (typeof values.pcs_level === 'undefined') {
    errors.pcs_level = 'Level field shouldn’t be empty';
  }
  if (typeof values.pcs_level !== 'undefined') {
    if (values.pcs_level !== null) {
      if (values.pcs_level.value !== 1) {
        if (!values.pcs_parent) {
          errors.pcs_parent = 'Parent field shouldn’t be empty';
        }
      }
    }
  }
  if (typeof values.pcs_type !== 'undefined') {
    if (typeof values.pcs_type.value === 'number') {
      if (!values.pcs_value) {
        errors.pcs_value = 'Value field shouldn’t be empty';
      }
    }
  }
  return errors;
};

export default reduxForm({
  form: 'formformulaComponents',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(ComponentFormulasFormz));
