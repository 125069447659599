/*
 * Page Label Preferences
 * Notes: Master Data Function Access
 * @author Gerdi
 * @date 22/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { translate } from 'react-i18next';
import showNotifications from '../../../notificationMessages';
import PropTypes from 'prop-types';
import { deleteCryptoTableData } from '../../../../../redux/actions/cryptoTableActions';
import { CryptoTableProps } from '../../../../../shared/prop-types/TablesProps';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import renderRadioButtonField from '../../../../../shared/components/form/RadioButton';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { CalendarBlankIcon } from 'mdi-react';
import moment from 'moment';

class SurveyGroup extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    cryptoTable: CryptoTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.GROUP_NAME') },
      { '2': t('LBL.START_DATE') },
      { '3': t('LBL.END_DATE') },
      { '4': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      urlData: '',
      dataWidth: ['5%', '50%', '15%', '15%', '15%'],
      isQuestion: false,
      statusEdit: false,
      disableButton: false,
      action: '',
      modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getButton = this.getButton.bind(this);
    this.showQuestion = this.showQuestion.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/empsurvey/getAllGroup` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.GROUP_NAME') },
      { '2': t('LBL.START_DATE') },
      { '3': t('LBL.END_DATE') },
      { '4': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  showQuestion(id) {
    this.props.onparam(id, 'question');
  }

  async handleSubmit(values) {
    this.setState({ disableButton: true });
    const { apiws, token, idUser } = this.state;
    const {
      esg_id,
      group_name,
      description,
      is_mobile,
      period,
      start_date,
      end_date,
    } = values;

    let action;
    let url;
    let data = {
      group_name,
      description,
      is_mobile,
      period,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: !end_date ? '' : moment(end_date).format('YYYY-MM-DD'),
    };

    if (esg_id === undefined || esg_id === '') {
      action = 'add';
      data = { ...data, cuser_id: idUser };
      url = `${apiws}/empsurvey/saveGroup`;
    } else {
      action = 'edit';
      data = { ...data, muser_id: idUser };
      url = `${apiws}/empsurvey/updateGroup/${esg_id}`;
    }
    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios
      .post(url, formBody, config)
      .then(async (response) => {
        if (response.data.status === 'ok') {
          this.props.destroy();
          showNotifications('Success', 'Save Success', 'success');
          this.getTableData();
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
        this.setState({
          modal: false,
          statusEdit: false,
          disableButton: false,
        });
      })
      .catch(() => {
        showNotifications('Fail', 'Save Failed', 'danger');
      });
  }

  addGroup() {
    this.setState({ action: 'add' });
    this.toggle();
    this.props.initialize({
      esg_id: '',
      group_name: '',
      description: '',
      is_mobile: '0',
      period: 'once',
      start_date: '',
      end_date: '',
    });
  }

  async editGroup(id, e, param) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios
      .post(`${apiws}/empsurvey/getGroupById/${id}`, '', config)
      .then(async (response) => {
        if (parseInt(response.data.data.length, 10) > 0) {
          if (id === undefined || id === '' || typeof id === 'undefined') {
            this.props.initialize({
              esg_id: '',
              group_name: '',
              description: '',
              is_mobile: '0',
              period: 'once',
              start_date: '',
              end_date: '',
            });
          } else {
            if (response.data.status === 'ok') {
              this.setState({
                statusEdit: true,
                disableButton: false,
                modal: true,
                action: 'edit',
              });
              this.props.initialize({
                esg_id: response.data.data[0].esg_id,
                group_name: response.data.data[0].group_name,
                description: response.data.data[0].description,
                is_mobile: response.data.data[0].is_mobile
                  ? response.data.data[0].is_mobile
                  : '0',
                period: response.data.data[0].period
                  ? response.data.data[0].period
                  : 'once',
                start_date: !response.data.data[0].start_date
                  ? ''
                  : moment(response.data.data[0].start_date),
                end_date: !response.data.data[0].end_date
                  ? ''
                  : moment(response.data.data[0].end_date),
              });
            }
          }
        }
      });
  }

  deleteGroup(id) {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/empsurvey/deleteGroup/${id}`,
            `duser_id=${idUser}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              this.getTableData();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch((error) => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { urlData, disableButton, statusEdit } = this.state;
    const addBtn = (
      <Col>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={this.addGroup}
          style={{ float: 'right', 'margin-bottom': '20px' }}
        >
          {t('FRM.ADD')} {t('MEN.SURVEY')}
        </Button>
      </Col>
    );

    let labelButton;
    let colorButton;
    if (statusEdit) {
      labelButton = t('FRM.EDIT');
      colorButton = 'success';
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="esg_id"
                  widthTable={this.state.dataWidth}
                  buttonAction={['survey', 'edit', 'delete']}
                  surveyFunc={this.showQuestion}
                  updateFunc={this.editGroup}
                  deleteFunc={this.deleteGroup}
                  onRef={(ref) => (this.child = ref)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {' '}
            {this.state.action === 'add' ? t('FRM.ADD') : t('FRM.EDIT')}{' '}
            {t('MEN.SURVEY')}{' '}
          </ModalHeader>
          <ModalBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(this.handleSubmit)}
              name="reduxSurveyGroup"
              id="reduxSurveyGroup"
            >
              <input type="hidden" name="esg_id" value="" />
              <Container>
                <Row>
                  <Col md="8" lg="8" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.GROUP_NAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="group_name"
                          id="group_name"
                          component={renderTextInput}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.DESCRIPTION')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          component="textarea"
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.MOBILE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="is_mobile"
                          component={renderRadioButtonField}
                          label={t('LBL.YES')}
                          radioValue="1"
                        />
                        <Field
                          name="is_mobile"
                          component={renderRadioButtonField}
                          label={t('LBL.NO')}
                          radioValue="0"
                          defaultChecked
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.PERIOD')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="period"
                          component={renderRadioButtonField}
                          label="Once"
                          radioValue="once"
                          defaultChecked
                        />
                        <Field
                          name="period"
                          component={renderRadioButtonField}
                          label="Daily"
                          radioValue="daily"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.START_DATE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="start_date"
                          id="start_date"
                          component={renderDatePickerField}
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" sm="12" xs="12">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.END_DATE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="end_date"
                          id="end_date"
                          component={renderDatePickerField}
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle('cancel')}>
              {t('FRM.CANCEL')}
            </Button>
            <Button
              color={colorButton}
              form="reduxSurveyGroup"
              type="submit"
              disabled={disableButton}
            >
              {' '}
              {labelButton}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.group_name) {
    errors.group_name = "Group name shouldn't be empty";
  }
  if (!values.start_date) {
    errors.start_date = "Start date shouldn't be empty";
  }
  console.log(errors);
  return errors;
};

export default reduxForm({
  form: 'reduxSurveyGroup',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(SurveyGroup));
