/* eslint-disable array-callback-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable object-shorthand */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef, no-use-before-define */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderTextInput from '../../../../shared/components/form/TextInput';
import { faCheck } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import moment from 'moment';
import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import Swal from 'sweetalert2';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import EmployeeList from './components/PeriodEmployee';
import utils from '../../../../utils';
import MinusIcon from 'mdi-react/MinusIcon';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';

let idata = '';
let periodCodeExist;
let translator = () => {};
class SettingPeriod extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERIOD_CODE') },
      { '2': t('LBL.PAYROLL_PERIODE') },
      { '3': t('LBL.ATTENDANCE_PERIODE') },
      { '4': t('LBL.OVERTIME_PERIODE') },
      { '5': t('LBL.PAYMENT_DATE') },
      { '6': t('LBL.NUMBER_OF_EMPLOYEES') },
      { '7': t('FRM.FUNCTION') },
    ];
    this.state = {
      token: '',
      apiws: '',
      idUser: '',
      checkLm: '',
      PeriodeData: [],
      pyPeriodeList: [],
      pypid: '',
      period_start: '',
      period_end: '',
      attendance_start: '',
      attendance_end: '',
      overtime_start: '',
      overtime_end: '',
      period_pay_lm: '',
      overtime_pay_lm: '',
      periodeList: [],
      isChecked_opl: true,
      modal: false,
      cuser: '',
      statusedit: false,
      statusadd: false,
      data: [],
      urlData: '',
      dataWidth: ['2%', '10%', '20%', '20%', '20%', '10%', '5%', '25%'],
      spanerrorPeriodStart: 'none',
      spanerrorPeriodEnd: 'none',
      spanerrorPeriodEnd2: 'none',
      spanerrorAttendanceStart: 'none',
      spanerrorAttendanceEnd: 'none',
      spanerrorAttendanceEnd2: 'none',
      spanerrorOvertimeStart: 'none',
      spanerrorOvertimeEnd: 'none',
      spanerrorOvertimeEnd2: 'none',
      idPeriod: [],
      paramPeriod: '',
      dispEmp: 'none',
      dispPeriod: '',
      payment_date: '',
      disabledStart: false,
      disabledEnd: false,
      pypDisabled: [],
      buttonAction: ['edit', 'delete', 'detail'],
      displaySpinner: false,
      disabledCancel: false,
      detail: '',
      addcond: '',
      editcond: '',
      deletecond: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editperiodRows = this.editperiodRows.bind(this);
    this.deleteperiodRows = this.deleteperiodRows.bind(this);
    this.detailperiodRows = this.detailperiodRows.bind(this);
    this.backPeriodRows = this.backPeriodRows.bind(this);
    this.getPeriodSkipLast = this.getPeriodSkipLast.bind(this);
    this.getButton = this.getButton.bind(this);
    translator = t;
  }

  componentDidMount = () => {
    this.setState(
      {
        token: ls.get('token'),
        apiws: ls.get('ws_ip'),
        idUser: ls.get('user_cred').usr_id,
        urlData: `${ls.get('ws_ip')}/payperiod/getAllItem`,
      },
      () => {
        this.getTableData();
        this.getPeriodSkipLast();
        this.getButton();
        this.getPeriodeAll();
      },
    );
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.PERIOD_CODE') },
      { '2': t('LBL.PAYROLL_PERIODE') },
      { '3': t('LBL.ATTENDANCE_PERIODE') },
      { '4': t('LBL.OVERTIME_PERIODE') },
      { '5': t('LBL.PAYMENT_DATE') },
      { '6': t('LBL.NUMBER_OF_EMPLOYEES') },
      { '7': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          console.log(array);
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  getPeriodeAll() {
    periodCodeExist = [];
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/payperiod/getAll`, '', config).then((res) => {
      if (res.data.status === 'ok') {
        for (let i = 0; i < res.data.data.length; i += 1) {
          periodCodeExist[i] = res.data.data[i].period_code;
        }
      }
    });
  }

  getPeriodSkipLast() {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payperiod/getAllItemMinusLast/`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const pypId = [];
          for (let i = 0; i < res.data.data.length; i += 1) {
            pypId.push(res.data.data[i].pyp_id);
          }
          this.setState({ pypDisabled: pypId });
        }
      });
  }

  getPeriode() {
    let checkLm = '';
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${apiws}/payperiod/getAllItem/10/0`, '', config).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        dataResponse.sort((a, b) => a.pyp_id - b.pyp_id);
        const pyPeriodeList = [];
        for (let i = 0; i < dataResponse.length; i += 1) {
          if (dataResponse[i].period_pay_lm === '1') {
            checkLm = (
              <span className="badge badge-success">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            );
          } else {
            checkLm = <span className="badge badge-success" />;
          }
          pyPeriodeList.push({
            no: i + 1,
            id: dataResponse[i].pyp_id,
            period_start: dataResponse[i].period_start,
            period_end: dataResponse[i].period_end,
            attendance_start: dataResponse[i].attendance_start,
            attendance_end: dataResponse[i].attendance_end,
            overtime_start: dataResponse[i].overtime_start,
            overtime_end: dataResponse[i].overtime_end,
            overtime_pay_lm: dataResponse[i].overtime_pay_lm,
          });
        }
        this.setState({ PeriodeData: pyPeriodeList });
      }
    });
  }

  // setbuttonModal(x) {
  //   if (x !== '') {
  //     if (x === 'edit') {
  //       this.setState({ statusedit: true });
  //     }
  //   } else {
  //     if (this.status.statusedit !== false) {
  //       this.setState({ statusedit: false });
  //     }
  //   }
  //   this.toggle();
  // }

  toggle(x) {
    this.props.initialize({});
    this.setState({
      modal: !this.state.modal,
      displaySpinner: false,
      disabledCancel: false,
      disabledStart: false,
      disabledEnd: false,
    });
    if (x === 'edit') {
      this.setState({ statusedit: true });
      this.setState({ statusadd: false });
    } else {
      this.setState({ statusadd: true });
      this.setState({ statusedit: false });
      this.getPeriodeAll();
    }
  }

  deleteperiodRows(id) {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      closeOnConfirm: false,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/payperiod/deleteItem/${id}`,
            `duser_id=${idUser}`,
            config,
          )
          .then((res) => {
            if (res.data.status === 'ok') {
              this.getTableData();
              showNotifications('Success', 'Delete Success', 'success');
            }
          })
          .catch(() => {
            this.setState({ button_disabled: false });
          });
      }
    });
  }

  editperiodRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payperiod/getallitembyid/${id}`, '', config)
      .then((res) => {
        this.toggle('edit');
        const dataResponse = res.data.data;
        const indexPeriodCode = periodCodeExist.indexOf(
          dataResponse[0].period_code,
        );
        if (indexPeriodCode !== -1) {
          periodCodeExist.splice(indexPeriodCode, 1);
        }
        this.props.initialize({
          pyp_id: id,
          payroll_date: {
            start: moment(dataResponse[0].period_start),
            end: moment(dataResponse[0].period_end),
          },
          attendance_date: {
            start: moment(dataResponse[0].attendance_start),
            end: moment(dataResponse[0].attendance_end),
          },
          overtime_date: {
            start: moment(dataResponse[0].overtime_start),
            end: moment(dataResponse[0].overtime_end),
          },
          payment_date: moment(dataResponse[0].payment_date),
          payroll_date_start: moment(dataResponse[0].period_start),
          payroll_date_end: moment(dataResponse[0].period_end),
        });
        this.setState({
          disabledStart: true,
          disabledEnd: true,
        });
      });
  }

  handleChange(date, x) {
    if (!date) {
      this.setState({ [x]: '' });
    } else {
      this.setState({ [x]: moment(date) });
      if (x === 'period_start') {
        this.setState({ spanerrorPeriodStart: 'none' });
      } else if (x === 'period_end') {
        this.setState({ spanerrorPeriodEnd: 'none' });
      } else if (x === 'attendance_start') {
        this.setState({ spanerrorAttendanceStart: 'none' });
      } else if (x === 'attendance_end') {
        this.setState({ spanerrorAttendanceEnd: 'none' });
      } else if (x === 'overtime_start') {
        this.setState({ spanerrorOvertimeStart: 'none' });
      } else if (x === 'overtime_end') {
        this.setState({ spanerrorOvertimeEnd: 'none' });
      }
    }
  }

  setDate(x, y) {
    if (y !== '') {
      if (x.includes('start') || x.includes('end') || x.includes('date')) {
        const val_y = moment(y).format('YYYY-MM-DD');
        idata += `${x}=${val_y}&`;
      }
    }
  }

  handleSubmitUpdate(values) {
    const { apiws, token, idUser, overtime_pay_lm } = this.state;

    const id = values.pyp_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const error = utils.isObjectExist(validate(values));
    const data = {
      period_start: values.payroll_date.start,
      period_end: values.payroll_date.end,
      attendance_start: values.attendance_date.start,
      attendance_end: values.attendance_date.end,
      overtime_start: values.overtime_date.start,
      overtime_end: values.overtime_date.end,
      payment_date: values.payment_date,
      overtime_pay_lm: overtime_pay_lm,
      pyp_id: values.pyp_id,
    };
    Object.keys(data).map((key) => this.mapFormData(key, data[key]));
    idata += `muser_id=${idUser}`;
    if (!error) {
      this.setState({
        displaySpinner: true,
        disabledCancel: true,
      });
      axios
        .post(`${apiws}/payperiod/updateItem/${id}`, idata, config)
        .then(() => {
          idata = '';
          this.toggle();
          this.getTableData();
          this.getPeriodeAll();
          showNotifications('Success', 'Save Success', 'success');
          this.setState({
            displaySpinner: false,
            disabledCancel: false,
          });
        });
    }
  }

  mapFormData(a, b) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        const cvt_b = b.format('YYYY-MM-DD');
        idata += `${a}=${cvt_b}&`;
      } else {
        if (b === true) {
          val_b = 1;
        } else {
          val_b = 0;
        }
        idata += `${a}=${val_b}&`;
      }
    }
  }

  handleSubmitInput(values) {
    idata = '';
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const error = utils.isObjectExist(validate(values));
    if (!error) {
      this.setState({
        displaySpinner: true,
        disabledCancel: true,
      });
      const data = {
        period_start: values.payroll_date.start,
        period_end: values.payroll_date.end,
        attendance_start: values.attendance_date.start,
        attendance_end: values.attendance_date.end,
        overtime_start: values.overtime_date.start,
        overtime_end: values.overtime_date.end,
        payment_date: values.payment_date,
      };
      Object.keys(data).map((key) => this.mapFormData(key, data[key]));
      idata += `cuser=${idUser}`;
      axios
        .post(`${apiws}/payperiod/saveItem/`, idata, config)
        .then((response) => {
          idata = '';
          if (response.data.status === 'ok') {
            this.getTableData();
            this.props.initialize({});
            this.props.destroy();
            this.toggle();
            this.getPeriodSkipLast();
            this.getPeriodeAll();
            showNotifications('Success', 'Save Success', 'success');
            this.setState({
              displaySpinner: false,
              disabledCancel: false,
            });
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        });
    }
  }

  backPeriodRows() {
    this.setState({
      dispEmp: 'none',
      dispPeriod: '',
      paramPeriod: '',
      detail: '',
    });
  }

  detailperiodRows(id) {
    this.setState({
      dispEmp: '',
      dispPeriod: 'none',
    });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/payperiod/getAllItembyid/${id}`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const periodId = res.data.data[0].pyp_id;
          const periodCode = res.data.data[0].period_code;
          const periodText = `Period Code : ${periodCode}`;
          setTimeout(() => {
            this.setState({ idPeriod: periodId });
            this.setState({ paramPeriod: periodText });
            this.setState({ detail: '1' });
          }, 200);
        }
      });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      statusedit,
      statusadd,
      urlData,
      displaySpinner,
      disabledCancel,
      deletecond,
      editcond,
    } = this.state;
    const modalStyle = {
      maxWidth: '800px',
      marginTop: '95px',
      textAlign: 'center',
    };
    let on_submit;
    let pypIdForm;
    if (statusedit) {
      pypIdForm = (
        <Field name="pyp_id" component={renderTextInput} type="hidden" />
      );
      on_submit = handleSubmit(this.handleSubmitUpdate);
    } else {
      on_submit = handleSubmit(this.handleSubmitInput);
    }
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.toggle}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.PERIOD')}
      </Button>
    );
    return (
      <Container>
        <Row style={{ display: this.state.dispPeriod }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <Col md={12} style={{ paddingBottom: '10px' }}>
                  <h3 className="page-title">{t('MEN.SETTING_PERIOD')}</h3>
                  <h3 className="page-subhead subhead">
                    {t('MEN.SETTING_PERIOD')}
                  </h3>
                </Col>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="pyp_id"
                  // customKey="payroll_pay_lm"
                  flagKey={this.state.pypDisabled}
                  deleteFunc={this.deleteperiodRows}
                  updateFunc={this.editperiodRows}
                  detailFunc={this.detailperiodRows}
                  editCond={editcond}
                  deleteCond={deletecond}
                  buttonAction={this.state.buttonAction}
                  widthTable={this.state.dataWidth}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
              backdrop="static"
              keyboard={false}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('LBL.FORM')} {t('LBL.PERIOD')}{' '}
              </ModalHeader>
              <ModalBody>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <Card style={{ padding: '0px 0px 0px 0px' }}>
                    <CardBody className="p0">
                      {/* <Row className="m5" style={{ display: displayLoad }}>
                        <div className="load__icon-wrap">
                          <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                          </svg>
                        </div>
                      </Row> */}
                      <Row className="m5">
                        <Col
                          md={12}
                          xs={12}
                          lg={12}
                          xl={12}
                          className="mt15"
                          style={{ marginTop: '-10px' }}
                        >
                          <form
                            className="form form--horizontal"
                            name="FormUpdatePeriod"
                            id="FormUpdatePeriod"
                            onSubmit={on_submit}
                          >
                            {pypIdForm}

                            {statusedit && (
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.PAYROLL_DATE')}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="payroll_date_start"
                                    component={renderDatePickerField}
                                    disabled="true"
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                  <MinusIcon className="date-picker__svg" />
                                  <Field
                                    name="payroll_date_end"
                                    component={renderDatePickerField}
                                    disabled="true"
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            )}
                            {statusadd && (
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t('LBL.PAYROLL_DATE')}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="payroll_date"
                                    component={renderIntervalDatePickerField}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.ATTENDANCE_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="attendance_date"
                                  component={renderIntervalDatePickerField}
                                />
                              </div>
                            </div>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.OVERTIME')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="overtime_date"
                                  component={renderIntervalDatePickerField}
                                />
                              </div>
                            </div>
                            <div
                              className="form__form-group"
                              style={{ width: '55%' }}
                            >
                              <span className="form__form-group-label">
                                {t('LBL.PAYMENT_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="payment_date"
                                  component={renderDatePickerField}
                                  placeholder="dd-mm-yyyy"
                                />
                              </div>
                            </div>
                          </form>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.toggle}
                  disabled={disabledCancel}
                >
                  {t('FRM.CANCEL')}
                </Button>
                {displaySpinner ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    {t('FRM.LOADING')}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    form="FormUpdatePeriod"
                    type="submit"
                    disabled={pristine}
                  >
                    {' '}
                    {t('FRM.SAVE')}
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        {/* <Row style={{ display: this.state.dispEmp }}>
          <EmployeeList
            idparam={this.state.idPeriod}
            onparam={this.backPeriodRows}
            setparam={this.state.paramPeriod}
          />
        </Row> */}
        {this.state.detail === '1' && (
          <EmployeeList
            idparam={this.state.idPeriod}
            onparam={this.backPeriodRows}
            setparam={this.state.paramPeriod}
          />
        )}
      </Container>
    );
  }
}

const validate = (values) => {
  let data_validation;
  const validation = {
    payroll_date: {
      intervalDateTime: true,
      fieldLabel: translator('LBL.PAYROLL_DATE'),
    },
    attendance_date: {
      intervalDateTime: true,
      fieldLabel: translator('LBL.ATTENDANCE_DATE'),
    },
    overtime_date: {
      intervalDateTime: true,
      fieldLabel: translator('LBL.OVERTIME'),
    },
    payment_date: {
      fieldLabel: translator('LBL.PAYMENT_DATE'),
      required: true,
    },
  };
  data_validation = validation;
  if (typeof values.payroll_date !== 'undefined') {
    const periodChoose = parseInt(
      moment(values.payroll_date.end).format('YMM'),
      0,
    );
    const errorPeriodCode = periodCodeExist;
    if (errorPeriodCode.includes(periodChoose)) {
      data_validation = {
        ...validation,
        payroll_date: {
          intervalDateTime: true,
          fieldLabel: translator('LBL.PAYROLL_DATE'),
          fieldExist: true,
        },
      };
    }
  }
  const errors = utils.validate(data_validation, values);
  return errors;
};

export default reduxForm({
  form: 'FormSettingPeriode',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(SettingPeriod));
