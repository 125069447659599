import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import cryptoTableReducer from './cryptoTableReducer';
import newOrderTableReducer from './newOrderTableReducer';
import customizerReducer from './customizerReducer';
import userReducer from './userReducer';
import loadBoxEmployeeReducer from './loadBoxEmployeeReducer';

export {
  themeReducer,
  sidebarReducer,
  cryptoTableReducer,
  newOrderTableReducer,
  customizerReducer,
  userReducer,
  loadBoxEmployeeReducer
};
