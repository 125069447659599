/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/extensions */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unused-state,object-shorthand,arrow-parens,comma-dangle,jsx-a11y/anchor-is-valid,no-script-url,react/style-prop-object,no-console,no-trailing-spaces,space-in-parens,space-infix-ops,react/no-unescaped-entities,camelcase,no-plusplus,max-len,quote-props,no-unused-vars */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-underscore-dangle
// eslint-disable-next-line react/sort-comp
/* eslint-disable no-const-assign */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-sequences */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */


import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar, UncontrolledTooltip, Row, } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/fontawesome-free-solid';
import '../../../containers/Tables/DataTable/assets/css/style.css';
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';
import { reduxForm, Field } from 'redux-form';
import renderSelectField from '../../../shared/components/form/Select';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { chunk } from 'lodash';

export default translate('global')(class ViewSlip extends PureComponent {
  static propTypes = {
    conds: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    onRef: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.months = [
      { value: '01', label: `${t('LBL.JANUARY')}`, short: 'Jan' },
      { value: '02', label: `${t('LBL.FEBRUARY')}`, short: 'Feb' },
      { value: '03', label: `${t('LBL.MARCH')}`, short: 'Mar' },
      { value: '04', label: `${t('LBL.APRIL')}`, short: 'Apr' },
      { value: '05', label: `${t('LBL.MAY')}`, short: 'May' },
      { value: '06', label: `${t('LBL.JUNE')}`, short: 'Jun' },
      { value: '07', label: `${t('LBL.JULI')}`, short: 'Jul' },
      { value: '08', label: `${t('LBL.AUGUST')}`, short: 'Aug' },
      { value: '09', label: `${t('LBL.SEPTEMBER')}`, short: 'Sep' },
      { value: '10', label: `${t('LBL.OCTOBER')}`, short: 'Oct' },
      { value: '11', label: `${t('LBL.NOVEMBER')}`, short: 'Nov' },
      { value: '12', label: `${t('LBL.DESEMBER')}`, short: 'Dec' },
    ];
    this.state = {
      modal_show: false,
      codecomp: [],
      rows: [],
      limit: 10, // default rows
      offset: 0,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  exportPdfTable_default = () => {
    const { t } = this.props;
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let data = this.state.rows[0];
    // periode format
    let year = data.periode.toString().substring(0, 4);
    let m = data.periode.toString().substring(4, 6);
    let monthArr = this.months.filter(obj => {
      return obj.value === m;
    });
    let month = monthArr[0].label;
    // chunk data employee
    let emp = [];
    let length = 0;
    const size = Object.entries(data.employee).length;
    if (size % 2 === 1) {
      length = size + 1;
    } else {
      length = size;
    }
    const chunkSize = length / 2;
    const arrayFromObject = Object.entries(data.employee).map(([key, value]) => ({ [key]: value }));
    emp = chunk(arrayFromObject, chunkSize);
    const employeeLeft = [];
    Object.keys(emp[0]).map((item, i) => (
      Object.entries(emp[0][item]).map(([name, value]) => {
        employeeLeft.push(
          [{
            text: name,
            border: [false],
          },
          {
            text: ':',
            border: [false],
          },
          {
            text: value,
            border: [false],
          }],
        );
      })
    ));
    const employeeRight = [];
    Object.keys(emp[1]).map((item, i) => (
      Object.entries(emp[1][item]).map(([name, value]) => {
        employeeRight.push(
          [{
            text: name,
            border: [false],
          },
          {
            text: ':',
            border: [false],
          },
          {
            text: value,
            border: [false],
          }],
        );
      })
    ));
    // Income
    let dataIncome = [];
    Object.entries(data.income).map(([name, value]) => {
      dataIncome.push(
        [{
          text: name,
          border: [false],
        },
        {
          text: ':',
          border: [false],
        },
        {
          text: 'Rp',
          border: [false],
        },
        {
          text: value,
          border: [false],
          alignment: 'right'
        }]
      );
    });
    dataIncome.push([
      {
        text: '',
        border: [false],
      },
      {
        text: '',
        border: [false],
      },
      {
        text: 'Rp',
        border: [false, true, false, false],
        bold: true,
      },
      {
        text: data.total.total_income,
        bold: true,
        border: [false, true, false, false],
        alignment: 'right'
      }
    ]);
    // Deduction
    let dataDeduction = [];
    Object.entries(data.deduction).map(([name, value]) => {
      dataDeduction.push(
        [{
          text: name,
          border: [false],
        },
        {
          text: ':',
          border: [false],
        },
        {
          text: 'Rp',
          border: [false],
        },
        {
          text: value,
          border: [false],
          alignment: 'right'
        }]
      );
    });
    dataDeduction.push([
      {
        text: '',
        border: [false],
      },
      {
        text: '',
        border: [false],
      },
      {
        text: 'Rp',
        border: [false, true, false, false],
        bold: true,
      },
      {
        text: data.total.total_deduction,
        bold: true,
        border: [false, true, false, false],
        alignment: 'right'
      }
    ]);
    // border
    const borderTop = {
      table: {
        widths: ['*'],
        body: [
          [{
            border: [false, true, false, false],
            text: '',
          },
          ],
        ]
      }
    };
    const borderBottom = {
      table: {
        widths: ['*'],
        body: [
          [{
            border: [false, false, false, true],
            text: '',
          },
          ],
        ]
      }
    };
    // document definition
    const documentDefinition = {
      userPassword: data.emp_code,
      ownerPassword: '123456',
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: [
        {
          alignment: 'justify',
          columns: [
            // [
            //   {
            //     style: 'tableSlip',
            //     table: {
            //       widths: ['*'],
            //       headerRows: 1,
            //       body: [
            //         [{
            //           text: 'Pribadi & Rahasia',
            //           border: [true, true, true, true],
            //           alignment: 'center',
            //           borderColor: ['#FF0000', '#FF0000', '#FF0000', '#FF0000'],
            //           fontSize: 13,
            //         }],
            //       ],
            //     },
            //   },
            // ],
            [
              {
                image: data.img_logo,
                width: 150,
                alignment: 'right'
              },
            ],
          ],
        },
        // data employee
        {
          style: 'tableEmployee',
          table: {
            widths: [80, 1, 100, 10, 70, 1, '*'],
            headerRows: 1,
            body: [
              [
                {
                  colSpan: 7,
                  text: data.company,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 0, 8],
                  border: [false, false, false, false]
                },
                '', '', '', '', '', '',
              ],
              [
                {
                  border: [false],
                  text: `${t('LBL.PAYSLIP_PERIOD')}`,
                },
                {
                  border: [false],
                  text: ':'
                },
                {
                  border: [false, false, false, false],
                  text: `${month} ${year}`,
                  colSpan: 5
                },
                '', '', '', '',
              ],
              // [
              //   {
              //     border: [false, true, false, false],
              //     text: `${t('LBL.EMPLOYEE_NAME')}`
              //   },
              //   {
              //     border: [false, true, false, false],
              //     text: ':'
              //   },
              //   {
              //     border: [false, true, false, false],
              //     text: data.employee.emp_name,
              //     colSpan: 2
              //   },
              //   '',
              //   {
              //     border: [false, true, false, false],
              //     text: `${t('LBL.BRANCH')}`
              //   },
              //   {
              //     border: [false, true, false, false],
              //     text: ':'
              //   },
              //   {
              //     border: [false, true, false, false],
              //     text: data.employee.office
              //   }
              // ],
              // [
              //   {
              //     border: [false],
              //     text: 'NIP'
              //   },
              //   {
              //     border: [false],
              //     text: ':'
              //   },
              //   {
              //     border: [false, false, false, false],
              //     text: data.employee.emp_code,
              //     colSpan: 2
              //   },
              //   '',
              //   {
              //     border: [false],
              //     text: `${t('LBL.UNIT_ORGANIZATION')}`
              //   },
              //   {
              //     border: [false],
              //     text: ':'
              //   },
              //   {
              //     border: [false],
              //     text: data.employee.unit_organization
              //   }
              // ],
              // [
              //   {
              //     border: [false],
              //     text: `${t('LBL.JOB_NAME')}`
              //   },
              //   {
              //     border: [false],
              //     text: ':'
              //   },
              //   {
              //     border: [false, false, false, false],
              //     text: data.employee.position_name,
              //     colSpan: 2
              //   },
              //   '',
              //   {
              //     border: [false],
              //     text: `${t('LBL.BANK')}`
              //   },
              //   {
              //     border: [false],
              //     text: ':'
              //   },
              //   {
              //     border: [false],
              //     text: data.employee.bank
              //   }
              // ],
              // [
              //   {
              //     border: [false, false, false, true],
              //     text: `${t('LBL.PTKP_STATUS')}`
              //   },
              //   {
              //     border: [false, false, false, true],
              //     text: ':'
              //   },
              //   {
              //     border: [false, false, false, true],
              //     text: data.employee.tax_status,
              //     colSpan: 2,
              //   },
              //   '',
              //   {
              //     border: [false, false, false, true],
              //     text: `${t('LBL.BANK_ACCOUNT')}`
              //   },
              //   {
              //     border: [false, false, false, true],
              //     text: ':'
              //   },
              //   {
              //     border: [false, false, false, true],
              //     text: data.employee.bank_account,
              //   },
              // ],
            ]
          },
        },
        borderBottom,
        {
          alignment: 'justify',
          columns: [
            [
              {
                style: 'tableEmployee',
                table: {
                  widths: [80, 1, '*'],
                  body: employeeLeft,
                },

              },
            ],
            [
              {
                style: 'tableEmployee',
                table: {
                  widths: [80, 1, '*'],
                  body: employeeRight,
                },
              },
            ],
          ]
        },
        borderTop,
        // data payslip
        {
          alignment: 'justify',
          columns: [
            [
              {
                text: `${t('LBL.INCOME')}`,
                fontSize: 10,
                bold: true,
                decoration: 'underline'
              },
              {
                style: 'tableSlip',
                table: {
                  widths: [140, 1, 15, '*'],
                  headerRows: 1,
                  body: dataIncome
                },

              },
            ],
            [
              {
                text: `${t('LBL.DEDUCTION')}`,
                fontSize: 10,
                bold: true,
                decoration: 'underline'
              },
              {
                style: 'tableSlip',
                table: {
                  widths: [140, 1, 15, '*'],
                  headerRows: 1,
                  body: dataDeduction
                },
              },
            ],
          ]
        },
        // THP
        {
          style: 'tableSlip',
          table: {
            widths: [140, 1, 15, 50, '*'],
            headerRows: 1,
            body: [
              [
                {
                  text: 'THP',
                  border: [false, true, false, true],
                  bold: true,
                  alignment: 'center',
                  fillColor: '#eeeeee',
                },
                {
                  text: '',
                  border: [false, true, false, true],
                  fillColor: '#eeeeee',
                },
                {
                  text: 'Rp',
                  bold: true,
                  border: [false, true, false, true],
                  fillColor: '#eeeeee',
                },
                {
                  text: data.total.total,
                  bold: true,
                  border: [false, true, false, true],
                  alignment: 'right',
                  fillColor: '#eeeeee',
                },
                {
                  text: '',
                  border: [false, true, false, true],
                  fillColor: '#eeeeee',
                },
              ],
            ],
          },
        },
        {
          alignment: 'justify',
          columns: [
            [
              {
                style: 'tableSlip',
                table: {
                  widths: [80, 1, '*'],
                  headerRows: 1,
                  body: [
                    [{
                      text: `${t('LBL.BANK')}`,
                      border: [false],
                    },
                    {
                      text: ':',
                      border: [false],
                    },
                    {
                      text: data.bank,
                      border: [false],
                    }],
                    [{
                      text: `${t('LBL.BANK_ACCOUNT')}`,
                      border: [false],
                    },
                    {
                      text: ':',
                      border: [false],
                    },
                    {
                      text: data.bank_account,
                      border: [false],
                    }],
                    [{
                      text: `${t('LBL.ACCOUNT_HOLDER')}`,
                      border: [false],
                    },
                    {
                      text: ':',
                      border: [false],
                    },
                    {
                      text: data.bank_account_holder,
                      border: [false],
                    }],
                  ],
                },
              },
            ],
            [
              {
                style: 'tableSlip',
                table: {
                  widths: ['*'],
                  body: [''],
                },
              },
            ],
          ],
        },
        // {
        //   style: 'tableSlip',
        //   table: {
        //     widths: ['*', '*'],
        //     heights: ['auto', 30, 'auto', 'auto', 'auto'],
        //     body: [
        //       [
        //         '',
        //         {
        //           text: 'Diterima Oleh : ',
        //           alignment: 'center'

        //         }
        //       ],
        //       ['', ''],
        //       [
        //         '',
        //         {
        //           text: data.employee.emp_name,
        //           alignment: 'center'

        //         }
        //       ],
        //       [
        //         '',
        //         {
        //           text: data.company,
        //           bold: true,
        //           alignment: 'center'

        //         }
        //       ],
        //       [
        //         '',
        //         {
        //           text: `Divisi : ${data.employee.unit_organization}`,
        //           alignment: 'center'

        //         }
        //       ]
        //     ]
        //   },
        //   layout: 'noBorders'
        // },
      ],
      styles: {
        tableEmployee: {
          margin: [0, 5, 0, 5],
          fontSize: 9
        },
        tableSlip: {
          margin: [0, 5, 0, 5],
          fontSize: 9
        },
      },
      defaultStyle: {
        columnGap: 30
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  }

  exportPdfTable = () => {
    const { t } = this.props;
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let data = this.state.rows[0];
    let logo;
    if (data.img_logo) {
      logo = {
        alignment: 'justify',
        columns: [
          [
            {
              image: data.img_logo,
              width: 150,
              alignment: 'left'
            },
          ],
        ],
      };
    }
    // periode format
    const year = data.periode.toString().substring(0, 4);
    const m = data.periode.toString().substring(4, 6);
    const monthArr = this.months.filter(obj => obj.value === m);
    const month = monthArr[0].label;
    const mmm = monthArr[0].short;
    // chunk data employee
    let emp = [];
    let length = 0;
    const size = Object.entries(data.employee).length;
    if (size % 2 === 1) {
      length = size + 1;
    } else {
      length = size;
    }
    const chunkSize = length / 2;
    const arrayFromObject = Object.entries(data.employee).map(([key, value]) => ({ [key]: value }));
    emp = chunk(arrayFromObject, chunkSize);
    const employeeLeft = [];
    Object.keys(emp[0]).map((item, i) => (
      Object.entries(emp[0][item]).map(([name, value]) => {
        let val;
        if (value === null || value === 'null') {
          val = '-';
        } else {
          val = value;
        }
        employeeLeft.push(
          [{
            text: name,
            border: [false],
          },
          {
            text: ':',
            border: [false],
          },
          {
            text: val,
            border: [false],
          }],
        );
      })
    ));
    const employeeRight = [];
    Object.keys(emp[1]).map((item, i) => (
      Object.entries(emp[1][item]).map(([name, value]) => {
        let val;
        if (value === null || value === 'null') {
          val = '-';
        } else {
          val = value;
        }
        employeeRight.push(
          [{
            text: name,
            border: [false],
          },
          {
            text: ':',
            border: [false],
          },
          {
            text: val,
            border: [false],
          }],
        );
      })
    ));
    // Income
    let dataIncome = [];
    dataIncome.push([
      {
        text: 'Earnings',
        border: [false, false, false, false],
        colSpan: 2,
        bold: true,
      }, 
      {
        text: '',
        border: [false, false, false, false],
      },
      {
        text: 'Amount',
        border: [false, false, false, false],
        bold: true,
        colSpan: 2,
        alignment: 'center',
      }, 
      {
        text: '',
        border: [false, false, false, false],
      },
    ]);
    if (typeof data.income !== 'undefined') {
      Object.entries(data.income).map(([name, value]) => {
        dataIncome.push(
          [{
            text: name,
            border: [false],
          },
          {
            text: ':',
            border: [false],
          },
          {
            text: 'Rp',
            border: [false],
          },
          {
            text: value,
            border: [false],
            alignment: 'right'
          }]
        );
      });  
    } 
    // Deduction
    let dataDeduction = [];
    dataDeduction.push([
      {
        text: 'Deduction',
        border: [false, false, false, false],
        colSpan: 2,
        bold: true,
      }, 
      {
        text: '',
        border: [false, false, false, false],
      },
      {
        text: 'Amount',
        border: [false, false, false, false],
        bold: true,
        colSpan: 2,
        alignment: 'center',
      }, 
      {
        text: '',
        border: [false, false, false, false],
      },
    ]);
    if (typeof data.deduction !== 'undefined') {
      Object.entries(data.deduction).map(([name, value]) => {
        dataDeduction.push(
          [{
            text: name,
            border: [false],
          },
          {
            text: ':',
            border: [false],
          },
          {
            text: 'Rp',
            border: [false],
          },
          {
            text: value,
            border: [false],
            alignment: 'right'
          }]
        );
      });  
    }

    let total_income = '0';
    let total_deduction = '0';
    let total = '0';
    if (typeof data.total !== 'undefined') {
      if (typeof data.total.total_income !== 'undefined') {
        total_income = data.total.total_income;
      }
      if (typeof data.total.total_deduction !== 'undefined') {
        total_deduction = data.total.total_deduction;
      }
      if (typeof data.total.total !== 'undefined') {
        total = data.total.total;
      }
    }
   
    // border
    const borderTop = {
      table: {
        widths: ['*'],
        body: [
          [{
            border: [false, true, false, false],
            text: '',
          },
          ],
        ]
      }
    };
    const borderBottom = {
      table: {
        widths: ['*'],
        body: [
          [{
            border: [false, false, false, true],
            text: '',
          },
          ],
        ]
      }
    };

    const cellPadding3 = {
      paddingTop: function (i, node) { return 3; },
      paddingBottom: function (i, node) { return 3; },
    };
    const cellPadding5 = {
      paddingTop: function (i, node) { return 5; },
      paddingBottom: function (i, node) { return 5; },
    };
    // document definition
    const documentDefinition = {
      userPassword: data.emp_code,
      ownerPassword: data.emp_code,
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: [
        logo,
        // data employee
        {
          style: 'tableEmployee',
          table: {
            widths: [80, 1, 100, 10, 70, 1, '*'],
            headerRows: 1,
            body: [
              [
                {
                  colSpan: 7,
                  text: 'Private and Confidential',
                  fontSize: 10,
                  margin: [0, 0, 0, 0],
                  border: [false, false, false, false],
                },
                '', '', '', '', '', '',
              ],
              [
                {
                  colSpan: 6,
                  text: data.company,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 0, 0],
                  border: [false, false, false, false],
                },
                '', '', '', '', '', 
                {
                  border: [false],
                  text: `Payroll Statement for ${mmm} ${year}`,
                  alignment: 'right',
                },
              ],
            ],
          },
        },
        borderTop,
        {
          alignment: 'justify',
          columns: [
            [
              {
                style: 'tableEmployee',
                table: {
                  widths: [80, 1, '*'],
                  body: employeeLeft,
                },
                layout: cellPadding5,
              },
            ],
            [
              {
                style: 'tableEmployee',
                table: {
                  widths: [80, 1, '*'],
                  body: employeeRight,
                },
                layout: cellPadding5,
              },
            ],
          ],     
        },
        {
          alignment: 'justify',
          columns: [
            [
              {
                style: 'tableEmployee',
                table: {
                  widths: [140, 1, 15, '*'],
                  body: [
                    [
                      {
                        text: 'Next Pay After Deduction',
                        fontSize: 10,
                        bold: true,
                        alignment: 'center',
                        colSpan: 2,
                        fillColor: '#eeeeee',
                      }, 
                      {
                        text: '',
                        fillColor: '#eeeeee',
                      },
                      {
                        text: 'Amount',
                        fontSize: 10,
                        bold: true,
                        alignment: 'center',
                        colSpan: 2,
                        fillColor: '#eeeeee',
                      }, 
                      {
                        text: '',
                        fillColor: '#eeeeee',
                      },
                    ],
                    [
                      {
                        text: 'Take Home Pay',
                        fontSize: 10,
                        colSpan: 2,
                      }, '',
                      {
                        text: 'Rp',
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [true, true, false, true],
                      }, 
                      {
                        text: total,
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, true, true],
                      },
                    ],
                  ]
                },
                layout: cellPadding3,
              },
            ],
            [],
          ],     
        },
        // Payment Information
        {
          style: 'tableEmployee',
          table: {
            widths: [80, 1, 100, 10, 70, 1, '*'],
            headerRows: 1,
            body: [
              [
                {
                  colSpan: 7,
                  text: 'Payment Information',
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 0, 0],
                  border: [false, false, false, false],
                },
                '', '', '', '', '', '',
              ],
            ],
          },
        },
        borderTop,
        // data payslip
        {
          alignment: 'justify',
          columns: [
            [
              {
                style: 'tableSlip',
                table: {
                  widths: [140, 1, 15, '*'],
                  headerRows: 1,
                  body: dataIncome,
                },
                layout: cellPadding3,
              },
            ],
            [
              {
                style: 'tableSlip',
                table: {
                  widths: [140, 1, 15, '*'],
                  headerRows: 1,
                  body: dataDeduction,
                },
                layout: cellPadding3,
              },
            ],
          ],
        },
        borderBottom,
        {
          alignment: 'justify',
          columns: [
            [
              {
                style: 'tableSlip',
                table: {
                  widths: [140, 1, 15, '*'],
                  headerRows: 1,
                  body: [
                    [
                      {
                        text: '',
                        border: [false],
                      },
                      {
                        text: '',
                        border: [false],
                      },
                      {
                        text: 'Rp',
                        border: [false],
                        bold: true,
                      },
                      {
                        text: total_income,
                        border: [false],
                        alignment: 'right',
                        bold: true,
                      }
                    ],
                  ],
                },
                layout: cellPadding3,
              },
            ],
            [
              {
                style: 'tableSlip',
                table: {
                  widths: [140, 1, 15, '*'],
                  headerRows: 1,
                  body: [
                    [
                      {
                        text: '',
                        border: [false],
                      },
                      {
                        text: '',
                        border: [false],
                      },
                      {
                        text: 'Rp',
                        border: [false],
                        bold: true,
                      },
                      {
                        text: total_deduction,
                        border: [false],
                        alignment: 'right',
                        bold: true,
                      }
                    ],
                  ],
                },
                layout: cellPadding3,
              },
            ],
          ],
        },
        borderTop,
      ],
      styles: {
        tableEmployee: {
          margin: [0, 5, 0, 5],
          fontSize: 9,
        },
        tableSlip: {
          margin: [0, 5, 0, 5],
          fontSize: 9,
        },
      },
      defaultStyle: {
        columnGap: 30,
      },
    };

    pdfMake.createPdf(documentDefinition).open();
  }
  reload(item) {
    const { url, conds } = this.props;
    const reloadState = this;
    let state_offset = this.state.offset;
    this.setState({ modal_show: true });
    const rows = [];
    let bodyParameters;
    const config = {
      headers: { 'Authorization': `Bearer ${ls.get('token')}` },
    };
    if (typeof conds === 'undefined') {
      bodyParameters = {
        query: this.state.search,
      };
    } else {
      bodyParameters = {
        cond: conds,
        query: this.state.search,
      };
    }
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios.post(
      `${url}/${this.state.limit}/${state_offset}`,
      bodyParameters,
      config,
    ).then((response) => {
      if (typeof response.data.header === 'undefined') {
        reloadState.setState({
          headerBackend: false,
          arrHeaderBackend: [],
        });
      } else {
        reloadState.setState({
          headerBackend: true,
          arrHeaderBackend: response.data.header,
        });
      }
      const numPage = (typeof item !== 'undefined' ? this.state.page : 2);
      const nulPage = (response.data.total / state_offset) % 1;
      const numPages = (nulPage === 0 ? numPage - 1 : numPage);
      this.setState({ modal_show: false });
      if (response.data.data.length === 0) {
        if (nulPage === 0) {
          axios.post(
            `${url}/${this.state.limit}/${state_offset - this.state.limit}`,
            bodyParameters,
            config,
          ).then((res) => {
            if (res.data.data.length > 0) {
              this.setState({ rowHead: res.data.data.length + 1 });
              // let no_index = state_offset - this.state.limit;
              for (let i = 0; i < res.data.data.length; i += 1) {
                // no_index += 1;
                rows.push({
                  // no: no_index,
                  ...res.data.data[i]
                });
              }
              reloadState.setState({
                rows: rows,
                total: res.data.total,
                page: numPages,
                offset: state_offset - this.state.limit,
                modal_show: false,
              });
            }
          });
        } else {
          reloadState.setState({
            rows: [],
            total: 0,
            disable_next: 'disabled',
            disable_preview: 'disabled',
            page: 1,
            total_pagination: 0,
            current_pagination: 0,
          });
        }
      }
      if (response.data.data.length > 0) {
        this.setState({ rowHead: response.data.data.length + 1 });
        for (let i = 0; i < response.data.data.length; i += 1) {
          rows.push({
            ...response.data.data[i]
          });
        }
        reloadState.setState({
          rows: rows,
          total: response.data.total,
          page: numPage,
          offset: state_offset,
          modal_show: false,
        });
      }
    }).catch(() => {
      this.setState({ modal_show: false });
    });
  }

  render() {
    let emp = [];
    const { t } = this.props;
    let data = this.state.rows[0];
    let year = '';
    let month = '';
    let total_income = '0';
    let total_deduction = '0';
    let total_thp = '0';
    if (data !== undefined) {
      // chunk data employee
      let length = 0;
      const size = Object.entries(data.employee).length;
      if (size % 2 === 1) {
        length = size + 1;
      } else {
        length = size;
      }
      const chunkSize = length / 2;
      const arrayFromObject = Object.entries(data.employee).map(([key, value]) => ({ [key]: value }));
      emp = chunk(arrayFromObject, chunkSize);
      // periode format
      year = data.periode.toString().substring(0, 4);
      let m = data.periode.toString().substring(4, 6);
      let monthArr = this.months.filter(obj => {
        return obj.value === m;
      });
      month = monthArr[0].label;
      // total
      if (typeof data.total !== 'undefined') {
        if (typeof data.total.total_income !== 'undefined') {
          total_income = data.total.total_income;
        }
        if (typeof data.total.total_deduction !== 'undefined') {
          total_deduction = data.total.total_deduction;
        }
        if (typeof data.total.total !== 'undefined') {
          total_thp = data.total.total;
        }
      }
    }
    return (
      ((data !== undefined) ? (
        <Row >
          <Col sm={12} xs={12} md={12}>
            <div className="slip-output">
              <div className="header-box">
                <div className="d-flex align-items-center">
                  <div className="w-100 title-payslip" style={{ padding: '15px' }}>
                    Payslip Output
                  </div>
                  {/* <div className="w-100 act-payslip">
                    <Button color="primary" size="sm" onClick={this.exportPdfTable}>
                      <FontAwesomeIcon icon={faFilePdf} />
                      Export PDF
                    </Button>
                  </div> */}
                </div>
              </div>
              <div className="paylip-view">
                <table className="table">
                  <tbody>
                    <tr>
                      <td colSpan="6"><b>{data.company}</b></td>
                    </tr>
                    <tr>
                      <td width="200px">{t('LBL.PAYSLIP_PERIOD')}</td>
                      <td width="10px">:</td>
                      <td colSpan="3">{`${month} ${year}`}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-6">
                    <table className="table">
                      <tbody>
                        {Object.keys(emp[0]).map((item, i) => (
                          <tr key={i}>
                            {Object.entries(emp[0][item]).map(([name, value]) => {
                              return (
                                <>
                                <td width="200px">{name}</td>
                                <td width="10px">:</td>
                                <td>{(value !== 'null' || value !== null) ? value: '-'}</td>
                                </>
                              );
                            })
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <tbody>
                        {Object.keys(emp[1]).map((item, i) => (
                          <tr key={i}>
                            {Object.entries(emp[1][item]).map(([name, value]) =>
                              <>
                                <td width="200px">{name}</td>
                                <td width="10px">:</td>
                                <td>{(value !== 'null' || value !== null) ? value: '-'}</td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="4">
                            <b>{t('LBL.INCOME')}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(typeof data.income !== 'undefined') ? (
                          Object.entries(data.income).map(([name, value]) => (
                            <tr>
                              <td>{name}</td>
                              <td width="10px">:</td>
                              <td width="10px">Rp</td>
                              <td className="text-right" width="110px">{value}</td>
                            </tr>
                          ))
                        ) : ''}
                        <tr>
                          <td colSpan="2" />
                          <td width="10px">Rp</td>
                          <td
                            className="text-right"
                            width="110px"
                            style={{ borderTop: '1px solid #a2a2a2' }}
                          >
                            <b>{total_income}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="4">
                            <b>{t('LBL.DEDUCTION')}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(typeof data.deduction !== 'undefined') ? (
                          Object.entries(data.deduction).map(([name, value]) => (
                            <tr>
                              <td>{name}</td>
                              <td width="10px">:</td>
                              <td width="10px">Rp</td>
                              <td className="text-right" width="110px">{value}</td>
                            </tr>
                          ))
                        ) : ''}
                        <tr>
                          <td colSpan="2" />
                          <td width="10px">Rp</td>
                          <td
                            className="text-right"
                            width="110px"
                            style={{ borderTop: '1px solid #a2a2a2' }}
                          >
                            <b>{total_deduction}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr style={{ marginBottom: '0px' }} />
                <div className="row">
                  <div className="col-md-6">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td colSpan="2" className="text-center"><b>THP</b></td>
                          <td width="10px">Rp</td>
                          <td
                            className="text-right"
                            width="110px"
                          >
                            <b>{total_thp}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr style={{ marginTop: '0px' }} />
                {/* <div className="row">
                <div className="offset-md-9 col-md-3 text-center">
                  <div className="ttd-payslip">
                    <span>Diterima Oleh:</span>
                    <br />
                    <br />
                    <br />
                    <span>Joko</span>
                    <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
                    <span><b>PT.Spasi Indonesia</b></span><br />
                    <span>Divisi: -</span>
                  </div>
                </div>
              </div> */}
                {/* <div className="row">
                  <div className="col-md-6">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td width="200px">{t('LBL.BANK_NAME')}</td>
                          <td width="10px">:</td>
                          <td>{`${data.bank}`}</td>
                        </tr>
                        <tr>
                          <td width="200px">{t('LBL.BANK_ACCOUNT')}</td>
                          <td width="10px">:</td>
                          <td>{`${data.bank_account}`}</td>
                        </tr>
                        <tr>
                          <td width="200px">{t('LBL.ACCOUNT_HOLDER')}</td>
                          <td width="10px">:</td>
                          <td>{`${data.bank_account_holder}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
        </Row >
      ) : '')
    );
  }
});
