/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container, Row, Card, CardBody, Col, Button, ButtonToolbar, Modal,
  ModalBody, ModalHeader, ModalFooter, Table,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderTimePickerIntervalField from '../../../shared/components/form/TimePickerInterval';
import renderSelectField from '../../../../../../shared/components/form/Select';
import renderTextInput from '../../../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';
import ls from 'local-storage';
import axios from 'axios';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import showNotifications from '../../../../notificationMessages';
import ViewListTable from '../../../../../../shared/components/table/viewListTable';
import renderRadioButtonField from '../../../../../../shared/components/form/RadioButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import MinusIcon from 'mdi-react/MinusIcon';
import Swal from 'sweetalert2';

// let compthr = '';
class Workflowactivity extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    setparam: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,
    getChecklist: PropTypes.func.isRequired,
    wfl_id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparray: [],
      yearmultiply: [],
      timesmultiply: [],
      apiws: '',
      iduser: '',
      token: '',
      dynForm: [],
      component: [],
      arrname: [],
      orgorganization: [],
      orgoffice: [],
      buttondisabled: false,
      disabledinput: false,
      statusedit: false,
      getcomponent: [],
      pycArr: [],
      multiplierArr: [],
      dataWidth: ['5%', '20%', '20%', '20%', '10%', '10%', '10%'],
      statusEdit: false,
      urlData: '',
      rows: [],
      arrEmp: [],
      displayPos: false,
      wfa_actor: false,
      slcunit: [],
    };
    const { t } = props;

    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.CHECKLIST_ITEM'),
      t('FRM.FUNCTION'),
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.handleSubmitInput = this.handleSubmitInput.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
    this.handleChangedistribution = this.handleChangedistribution.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { t, setparam } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.CHECKLIST_ITEM'),
      t('FRM.FUNCTION'),
    ];
    this.setState({ urlData: `${ls.get('ws_ip')}/wflchecklist/getallitem/${setparam}` });
    this.timeout = setTimeout(() => {
      this.getTableData();
    }, 300);
  }

  getTableData() {
    this.child.reload();
  }

  toggle(id) {
    const { t } = this.props;
    if (id === 'add') {
      this.props.destroy();
      this.setState({ titleModal: t('FRM.ADD') });
      this.setState({ status_edit: false });
    } else {
      this.setState({ status_edit: true });
      this.setState({ titleModal: t('FRM.EDIT') });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.loadSelectBox();
    });
    this.setState({ iduser: ls.get('user_cred').usr_id });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${ls.get('ws_ip')}/orgposition/getAll/`,
      '',
      config,
    ).then((response) => {
      const dataResponse = response.data.data;
      const Arr = [];
      if (dataResponse.length === 0) {
        // Arr.push({ value: 0, label: 'Unit Organization' });
        this.setState({ slcunit: Arr });
      } else {
        // Arr.push({ value: 0, label: 'Unit Organization' });
        dataResponse.map(obj => (
          Arr.push({ value: obj.pos_id, label: obj.position_nm })
        ));
        this.setState({ slcunit: Arr });
      }
    }).catch(() => {
      // console.log(error);
    });

    axios.post(
      `${apiws}/employee/getAllitem/`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.emp_id, label: obj.emp_name })
        ));
        this.setState({ arrEmp: Arr });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  search(values) {
    console.log(values);
  }

  editDetail(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(
      `${apiws}/wflchecklist/getAllItembyid/${id}`,
      '',
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      this.toggle();
      const val = [];
      setTimeout(() => {
        this.props.initialize(dataResponse[0]);
      }, 200);
    }).catch(() => {
      // console.log(error);
    });
  }

  deleteDetail(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${apiws}/wflchecklist/deleteItem/${id}`,
          `duser_id=${userid}`,
          config,
        ).then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Delete Success', 'success');
            setTimeout(() => {
              this.child.reload('delete');
            }, 2000);
          } else {
            showNotifications('Fail', 'Delete Failed', 'danger');
          }
        }).catch(() => {
          showNotifications('Fail', 'Delete Failed', 'danger');
        });
      }
    });
  }

  handleChangedistribution(id) {
    if (id.value === '3') {
      this.setState({ displayPos: true });
      this.setState({ wfa_actor: false });
    } else if (id.value === '4') {
      this.setState({ displayPos: false });
      this.setState({ wfa_actor: true });
    } else {
      this.setState({ wfa_actor: false });
      this.setState({ displayPos: false });
    }
  }

  handleSubmitInput(values) {
    console.log(values);
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const error = true;
    const id = values.wfc_id;
    const wfa_id = this.props.setparam;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/wflchecklist/saveItem`;
      data = {
        wfa_id,
        ...values,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/wflchecklist/updateItem/${id}`;
      data = {
        wfa_id,
        ...values,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    const config = {
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios.post(
        url,
        formBody,
        config,
      ).then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.props.destroy();
          this.setState({ modal: false });
          setTimeout(() => {
            this.child.reload();
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      }).catch(() => {
        // console.log(error);
      });
    }
  }

  render() {
    const {
      handleSubmit, t, pristine, formatFunction, getChecklist,
    } = this.props;
    const {
      status_edit,
      titleModal,
      passworddisabled,
      wf,
      wf_text,
      slcunit,
      jobnm,
      urlData,
    } = this.state;
    let button_action;
    const addBtn = (
      <Button
        className="btn_table_navbar"
        color="primary"
        style={{ float: 'right', 'margin-bottom': '20px' }}
        onClick={() => this.toggle('add')}
      > {t('FRM.ADD')} {t('LBL.CHECKLIST')}
      </Button>

    );
    const backBtn = (
      <Button
        color="secondary"
        className="btn_table_navbar"
        style={{ float: 'right', 'margin-bottom': '20px', 'margin-right': '10px' }}
        onClick={() => this.props.onparam(this.props.wfl_id, 'activity')}
      > {t('FRM.BACK')} {t('LBL.ACTIVITY')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    if (status_edit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="checklistform"
          disabled={pristine}
        > {t('FRM.EDIT')}
        </Button>);
    } else {
      button_action = (
        <Button
          color="primary"
          form="checklistform"
          type="submit"
          disabled={pristine}
        >{t('FRM.SAVE')}
        </Button>);
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            {/* <div style={{ float: 'left', 'margin-left': '25px' }}>
              <h3>Format: </h3>
              <h5>Group Code: </h5>
              <h5>Group Name: </h5>
            </div> */}
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="wfc_id"
                  widthTable={this.state.dataWidth}
                  displayStyleHead="none"
                  onRef={ref => (this.child = ref)}
                  updateFunc={this.editDetail}
                  deleteFunc={this.deleteDetail}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}> { titleModal } </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmitInput)}
                  name="checklistform"
                  id="checklistform"
                >
                  <Field
                    name="wfa_id"
                    component={renderTextInput}
                    type="hidden"
                  />
                  <Field
                    name="wfc_id"
                    id="fua_id"
                // component={renderSelectField}
                    component={renderTextInput}
                    type="hidden"
                  />
                  <Container>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.ORDER')} {t('LBL.NUMBER')}</span>
                          <div className="form__form-group-field">
                            <Col md={4} xs={12} style={{ 'padding': '0' }}>
                              <Field
                                name="checklist_order"
                                component={renderTextInput}
                                type="number"
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ITEM_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="checklist_name"
                              component={renderTextInput}
                              type="text"
                              placeholder="Item Name"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row >
                        <div className="form__form-group">
                          <span className="form__form-group-label">{t('LBL.DESCRIPTION')}</span>
                          <div className="form__form-group-field">
                            <Field
                              name="checklist_description"
                              id="activity_description"
                              component={renderTextInput}
                              type="textarea"
                              placeholder="Description"
                            />
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => { this.toggle('cancel'); }}>{t('FRM.CANCEL')}</Button>
                {button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}


const validate = (values) => {
  const errors = {};

  if (!values.checklist_order) {
    errors.checklist_order = 'Checklist order field shouldn’t be empty';
  }

  if (!values.checklist_name) {
    errors.checklist_name = 'Checklist name field shouldn’t be empty';
  }

  return errors;
};
export default reduxForm({
  validate,
  form: 'wflchecklist',
  // onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(Workflowactivity));
