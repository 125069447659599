/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import ls from 'local-storage';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class RunPayrollProcessView extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
    inProcess: PropTypes.func.isRequired,
    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.PERIOD_CODE'),
      t('LBL.PERIOD_DATE'),
      t('LBL.PROCESS_DATE'),
      t('LBL.GROUP_COMPONENT'),
      t('FRM.FUNCTION'),
    ];

    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '20%', '30%', '20%', '28%'],
      urlData: [],
      redirectUrl: false,
      detailId: '',
      buttonAction: ['detail'],
      editcond: '',
      deletecond: '',
    };
    this.detailRows = this.detailRows.bind(this);
    this.processRows = this.processRows.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getButton();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/payperiod/getAllItemPayroll/`,
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.PERIOD_CODE'),
      t('LBL.PERIOD_DATE'),
      t('LBL.PROCESS_DATE'),
      t('LBL.GROUP_COMPONENT'),
      t('FRM.FUNCTION'),
    ];
    setTimeout(() => {
      const { onProcess } = this.props;
      if (onProcess) {
        this.getTableData();
        this.props.inProcess();
      }
    });
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          console.log(array);
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
              // this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  detailRows(id) {
    this.setState({
      redirectUrl: true,
      detailId: id,
    });
  }

  processRows() {
    // alert(id);
  }

  render() {
    const { t } = this.props;
    const { urlData, redirectUrl, detailId } = this.state;
    if (redirectUrl) {
      return (
        <Redirect
          to={{
            pathname: '/payroll/employeelist',
            state: {
              pypId: detailId,
              men_id: this.props.men_id,
              fga_id: this.props.fga_id,
            },
          }}
        />
      );
    }
    const addBtn = '';
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody style={{ padding: '20px 5px 30px 10px' }}>
            <Col md={12} style={{ paddingBottom: '10px' }}>
              <h3 className="page-title">
                {t('LBL.PAYROLL')} - {t('MEN.LIST_PAYROLL_PROCESS')}
              </h3>
              <h3 className="page-subhead subhead">
                {t('LBL.PAYROLL_MANAGEMENT')}
              </h3>
            </Col>
            <ViewListTable
              url={urlData}
              heads={this.heads}
              addBtn={addBtn}
              primaryKey="pyp_id"
              widthTable={this.state.dataWidth}
              deleteFunc={this.deleteRows}
              updateFunc={this.editRows}
              detailFunc={this.detailRows}
              processFunc={this.processRows}
              buttonAction={this.state.buttonAction}
              onRef={(ref) => (this.child = ref)}
              displayStyleHead="none"
              editCond={this.state.editcond}
              deleteCond={this.state.deletecond}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}
/*
const CartCard = () => (
<Col md={12} lg={12}>
  <Card className="cart">
    <CardBody>
      <div className="card__title">
        <h5 className="bold-text">Historical Process Period</h5>
      </div>
      <Table className="table--bordered cart__table" responsive>
        <thead>
          <tr>
            <th>Period</th>
            <th>Date</th>
            <th width="15%">Status</th>
            <th width="15%" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01/12/2018-30/12/2018</td>
            <td>
              25/12/2018
            </td>
            <td><span style={{ color: '#15DD96' }}><FontAwesomeIcon icon={faCheckCircle} /> Success</span></td>
            <td>
              <Link className="btn btn-primary btn-sm btn-payroll mt-min15" to="/dashboard_default">
                <FontAwesomeIcon icon={faForward} /> Re-run
              </Link>
            </td>
          </tr>
          <tr>
            <td>01/12/2018-30/12/2018</td>
            <td>
              25/12/2018
            </td>
            <td><span style={{ color: '#dc3545' }}><FontAwesomeIcon icon={faTimesCircle} /> Failed</span></td>
            <td>
              <Link className="btn btn-primary btn-sm btn-payroll mt-min15" to="/dashboard_default">
                <FontAwesomeIcon icon={faForward} /> Re-run
              </Link>
            </td>
          </tr>
          <tr>
            <td>01/12/2018-30/12/2018</td>
            <td>
              25/12/2018
            </td>
            <td><span style={{ color: '#15DD96' }}><FontAwesomeIcon icon={faCheckCircle} /> Success</span></td>
            <td>
              <Link className="btn btn-primary btn-sm btn-payroll mt-min15" to="/dashboard_default">
                <FontAwesomeIcon icon={faForward} /> Re-run
              </Link>
            </td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
  </Card>
</Col>
); */

export default translate('global')(RunPayrollProcessView);
