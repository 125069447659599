/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Card, Progress } from 'reactstrap';
import { translate } from 'react-i18next';
import ls from 'local-storage';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/fontawesome-free-solid';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { store } from '../../../App/store';
import { connect } from 'react-redux';

const LeaveIcon = `${process.env.PUBLIC_URL}/img/leave-icon.png`;
const LeaveNotFoundIcon = `${process.env.PUBLIC_URL}/img/data-not-found-alt.png`;

class InfoLeaveBalance extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // location: PropTypes.func.isRequired,
    // datan: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      token: ls.get('token'),
      apiws: ls.get('ws_ip'),
      iduser: ls.get('user_cred').usr_id,
      totaldays: [],
      emp_id: ls.get('user_cred').emp_id,
      men_id: ls.get('men_id'),
      fga_id: ls.get('user_cred').fga_id,
    };

    this.getDaysUsed = this.getDaysUsed.bind(this);
    this.getBalanceInfo = this.getBalanceInfo.bind(this);
  }

  componentDidMount = () => {
    if (this.props.user.leaveBalance.length == 0) {
      this.getBalanceInfo();
    } else {
      // get latest data with delay
      setTimeout(() => {
        this.getBalanceInfo();
      }, 2000);
    }

    ls.get('function_access').forEach((key) => {
      if (key.lbl_code === 'MEN.EMPLOYEE') {
        this.setState({ men_id: key.men_id });
      }
    });
  };

  getDaysUsed() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/empleavehistory/countnoofdays/${this.state.iduser}`,
        '',
        config,
      )
      .then((response) => {
        const rpv = response.data.data;
        rpv.map((obj) =>
          this.setState({
            totaldays: obj.total_days === null ? 0 : obj.total_days,
          }),
        );
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getBalanceInfo() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/empleavebalance/getInfoBalance/${
          this.state.emp_id
        }`,
        '',
        config,
      )
      .then((res) => {
        const response = res.data.data;
        if (response.length > 0) {
          store.dispatch({ type: 'SET_USER_LEAVE_BALANCE', data: response });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <div className="leave-balance-panel">
          <div className="leave-balance-panel-header">
            <div className="d-flex">
              <div className="w-100">{t('LBL.LEAVE_BALANCE')}</div>
              <div className="act-more">
                {/* <a href={this.linkToLeave} className="btn-more-panel" >
                </a> */}
                <Link
                  to={{
                    pathname: '/administration/leave',
                    state: {
                      // parmid: this.state.location,
                      usrid: this.state.emp_id,
                      men_id: this.state.men_id,
                      fga_id: this.state.fga_id,
                      men_employee: this.state.men_id,
                    },
                  }}
                >
                  <div id="performance" className="btn-more-panel">
                    <FontAwesomeIcon icon={faShare} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="leave-balance-panel-body">
            <ul className="leave-balance-list">
              {this.props.user.leaveBalance.length !== 0 && (
                <div>
                  {this.props.user.leaveBalance.map((value, index) => (
                    <li key={`leaveBal_${index}`} style={{marginBottom: '15px'}}>
                      <div className="d-flex" style={{ marginBottom: '10px' }}>
                        <div className="icon-leave">
                          <img src={LeaveIcon} alt="leave-icon" />
                        </div>
                        <div className="w-100">
                          <p className="title-balance">{value.leave_name}</p>
                          {value.balance_end_date !== '-' && (
                            <p className="exp-date">
                              {t('LBL.EXPIRED_ON')}{' '}
                              {moment(value.balance_end_date).format(
                                'DD/MM/YYYY',
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="progress-wrap progress-wrap--small">
                        {value.balance_type === 1 && (
                          <div className="d-flex">
                            <div className="w-100 text-left">
                              <p className="total-used">
                                {value.total_used} {t('FRM.OF')}{' '}
                                {parseInt(value.total_used, 10) +
                                  parseInt(value.leave_balance, 10)}{' '}
                                {t('LBL.DAYS_USED')}
                              </p>
                            </div>
                            <div className="w-100 text-right">
                              <p className="total-balance">
                                {value.leave_balance} {t('LBL.DAYS_REMAINING')}
                              </p>
                            </div>
                          </div>
                        )}
                        {value.balance_type === 2 && (
                          <div className="d-flex">
                            <div className="w-100 text-left">
                              <p className="total-used">
                                {value.total_used} {t('FRM.OF')}{' '}
                                {parseInt(value.total_used, 10) +
                                  parseInt(value.leave_balance, 10)}{' '}
                                {t('LBL.DAYS_USED')}
                              </p>
                              {/* <p className="total-used">{value.total_used} {t('LBL.MONTH_USED')}</p> */}
                            </div>
                            <div className="w-100 text-right">
                              <p className="total-balance">
                                {value.leave_balance} {t('LBL.MONTH_REMAINING')}
                              </p>
                            </div>
                          </div>
                        )}
                        <Progress
                          value={value.leave_balance}
                          max={
                            parseInt(value.total_used, 10) +
                            parseInt(value.leave_balance, 10)
                          }
                        />
                        <div className="range-date">
                          {value.balance_end_date !== '-' && (
                            <p>
                              {moment(value.balance_start_date).format(
                                'DD/MM/YYYY',
                              )}{' '}
                              -{' '}
                              {moment(value.balance_end_date).format(
                                'DD/MM/YYYY',
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </div>
              )}
            </ul>
            {this.props.user.leaveBalance.length === 0 && (
              <div className="leave-balance-info">
                <div className="d-flex align-items-center">
                  <div className="balance-info-label w-100">
                    {/* <p>Look like you dont have any leave submission before.</p> */}
                    <p>{t('LBL.DONT_HAVE_SUBMISSION')}</p>
                  </div>
                  <div className="icon-notfound-leave">
                    <img
                      src={LeaveNotFoundIcon}
                      alt="leave balance not found"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default translate('global')(connect(mapStateToProps)(InfoLeaveBalance));
