/*
 * Page Log User
 * Notes: Log User
 * @author Ajeng
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 28/11/2019
 * Note Update : Formating datatable
*/
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Col, Row, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';

const gardeExist = [];
class GradePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.ACTIVITY') },
      { '3': t('LBL.TIME') },
      { '4': t('LBL.IP') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '20%', '20%', '20%', '15%'],
      urlData: [],
      statusEdit: false,
    };

    this.getTableData = this.getTableData.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => { this.getTableData(); });
    this.setState({ urlData: `${ls.get('ws_ip')}/apploguser/getAllItem` });
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.ACTIVITY') },
      { '3': t('LBL.TIME') },
      { '4': t('LBL.IP') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  render() {
    const {
      urlData,
    } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ 'padding': '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  primaryKey="lsr_id"
                  widthTable={this.state.dataWidth}
                  onRef={ref => (this.child = ref)}
                  displayStyleHead="none"
                  buttonAction="noButton"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default (translate('global')(GradePage));
