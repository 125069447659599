/* eslint-disable indent */
/* eslint-disable no-tabs, no-lonely-if */
/* eslint-disable consistent-return */
/* eslint-disable quotes, no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import moment from 'moment';

/**
 *
 * @param {Object} obj Object var to check properties
 * @returns
 */
function isObjectExist(obj) {
  return obj && Object.getOwnPropertyNames(obj).length > 0;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

/**
 *
 * @param {Object} object1
 * @param {Object} object2
 * @return {Boolean} it's deeply different or not
 */
function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

/**
 *
 * @param {Object} validation
 * @param {Object} values
 */
function validate(validation, values) {
  // contoh penggunaan
  /* const validation = {
		job_name: {
			fieldLabel: 'Job name',
			required: true,
			maxLength: 10,
		},
		job_id: {
			fieldLabel: 'Job level',
			required: true,
		},
		start_date: {
			fieldLabel: 'Start date',
			required: true,
		},
		end_date: {
			fieldLabel: 'End date',
			required: true,
			lockDateAfter: 'start_date',
			fieldLabelAfter: 'Start date',
		},
	}; */
  /**
   * Validation for required field
   * @param {String} val
   * @param {String} fieldLabel
   */
  function isRequired(val, fieldLabel) {
    if (!val || typeof val === 'undefined' || val === null) {
      return `${fieldLabel} shouldn't be empty`;
    }
  }

  /**
   * Validation for email format check
   * @param {String} val
   * @param {String} fieldLabel
   */
  function isEmailValid(val, fieldLabel) {
    if (!isRequired(val, fieldLabel)) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) {
        return `This isn't correct email format`;
      }
    }
  }

  /**
   * Validation for max length
   * @param {String} val
   * @param {Number} length
   * @param {String} fieldLabel
   */
  function maxLength(val, length, fieldLabel) {
    // passed required first
    if (!isRequired(val, fieldLabel)) {
      if (val.length > length) {
        return `${fieldLabel} cant be more than ${length} character`;
      }
    }
  }

  /**
   * Validation for date comparing cant be lower than valBefore
   * @param {moment} val
   * @param {moment} valBefore
   * @param {String} fieldLabel
   * @param {String} fieldLabelBefore
   */
  function lockDateTimeAfter(val, valBefore, fieldLabel, fieldLabelBefore) {
    if (
      !isRequired(val, fieldLabel) &&
      !isRequired(valBefore, fieldLabelBefore)
    ) {
      if (val.isBefore(valBefore)) {
        return `${fieldLabel} shouldn’t less than ${fieldLabelBefore}`;
      }
    }
  }

  /**
   * parser validation for dynamic field
   * @param {String} fieldName
   * @param {Array} childItems
   * @param {any} fieldValues
   * @returns
   */
  function dynamicFieldParser(fieldName, childItems, fieldValues) {
    /* json structure for use this function */
    // var validation = {
    // 	dyn_identity: {
    // 		dynamicField: true,
    // 		children: [
    // 			{ name: 'identity', label: 'Identity', required: true },
    // 			{ name: 'identity_no', label: 'Identity no', required: true },
    // 			{ name: 'expired_date', label: 'Expired Date', required: true },
    // 		]
    // 	}
    // };

    if (Array.isArray(fieldValues[fieldName])) {
      const dynErrors = [];
      fieldValues[fieldName].map((item, index) => {
        const error = {};
        // loop children properties
        childItems.map((child) => {
          // find key name for validation

          Object.keys(child).map((childProp) => {
            let childValue;
            if (typeof item[child.name] === 'object') {
              childValue = item[child.name]?.value ?? null;
            } else {
              childValue = item[child.name];
            }
            const childLabel = child.label;

            if (childProp.match(/required/i)) {
              const errMsg = isRequired(childValue, childLabel);
              if (errMsg) error[child.name] = errMsg;
            }

            if (childProp.match(/maxLength/i)) {
              const length = child.maxLength;
              const errMsg = maxLength(childValue, length, childLabel);
              if (errMsg) error[child.name] = errMsg;
            }

            if (
              childProp.match(/lockDateTimeAfter|lockDateAfter|lockTimeAfter/i)
            ) {
              const { fieldLabelBefore } = child;
              const valBefore = item[child[childProp]];
              const errMsg = lockDateTimeAfter(
                childValue,
                valBefore,
                childLabel,
                fieldLabelBefore,
              );
              if (errMsg) error[child.name] = errMsg;
            }
          });
          if (isObjectExist(error)) dynErrors[index] = error;
        });
      });

      if (dynErrors.length > 0) return dynErrors;
    }
  }

  /**
   *
   * @param {String} fieldLabel
   * @param {Array} fieldValues
   * @param {Boolean} fieldExist
   * @returns
   */
  function intervalDateTimeValidation(fieldLabel, fieldValues, fieldExist) {
    const error = {};
    const startDateTime = isObjectExist(fieldValues) ? fieldValues.start : null;
    const endDateTime = isObjectExist(fieldValues) ? fieldValues.end : null;
    const isStartPassRequired = isRequired(
      startDateTime,
      `${fieldLabel} Start`,
    );
    const isEndPassRequired = isRequired(endDateTime, `${fieldLabel} End`);
    if (fieldExist) {
      error.start = `${fieldLabel} is already taken`;
      return error;
    } else {
      if (isStartPassRequired || isEndPassRequired) {
        if (isStartPassRequired) error.start = isStartPassRequired;
        if (isEndPassRequired) error.end = isEndPassRequired;
        return error;
      }
    }

    // pass the required field
    if (endDateTime.isBefore(startDateTime)) {
      error.end = `${fieldLabel} End shouldn’t less than ${fieldLabel} Start`;
      return error;
    }
  }

  // parsing validation object
  const errors = {};
  // field name
  Object.keys(validation).map((key) => {
    // properties validation for field
    Object.keys(validation[key]).map((prop) => {
      let val = values[key]; // redux form field name
      const { fieldLabel } = validation[key];

      if (prop.match(/required/i)) {
        const errMsg = isRequired(val, fieldLabel);
        if (errMsg) errors[key] = errMsg;
      }

      if (prop.match(/isEmail/i)) {
        const errMsg = isEmailValid(val, fieldLabel);
        if (errMsg) errors[key] = errMsg;
      }

      if (prop.match(/maxLength/i)) {
        const length = validation[key].maxLength;
        const errMsg = maxLength(val, length, fieldLabel);
        if (errMsg) errors[key] = errMsg;
      }

      if (prop.match(/dynamicField/i)) {
        const childItems = validation[key].children;
        const errMsg = dynamicFieldParser(key, childItems, values);
        if (errMsg) errors[key] = errMsg;
      }

      if (prop.match(/lockDateTimeAfter|lockDateAfter|lockTimeAfter/i)) {
        const { fieldLabelBefore } = validation[key];
        let valBefore = values[validation[key][prop]];
        if (prop === 'lockTimeAfter') {
          const nowDate = moment().format('YYY-MM-DD');
          val = moment(`${nowDate} ${moment(val).format('HH:MM')}`);
          valBefore = moment(`${nowDate} ${moment(valBefore).format('HH:MM')}`);
        }
        const errMsg = lockDateTimeAfter(
          val,
          valBefore,
          fieldLabel,
          fieldLabelBefore,
        );
        if (errMsg) errors[key] = errMsg;
      }

      if (prop.match(/intervalDateTime/i)) {
        const fieldValues = values[key];
        const exist = validation[key].fieldExist;
        const errMsg = intervalDateTimeValidation(
          fieldLabel,
          fieldValues,
          exist,
        );
        if (errMsg) errors[key] = errMsg;
      }
    });
  });

  return errors;
}

const utils = {
  isObjectExist,
  deepEqual,
  validate,
};

export default utils;
