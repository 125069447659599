/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/prop-types */
/* eslint-disable max-len, no-nested-ternary */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
// import SearchWebIcon from 'mdi-react/SearchWebIcon';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderCheckboxField from '../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
// import Select from 'react-select';
// import ws from '../../../../shared/components/config/config';
import ls from 'local-storage';
// import Swal from 'sweetalert2';
// import Select2 from 'react-select2-wrapper';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
// import ViewListTable from '../../../../shared/components/table/viewListTable';
// import { ArrowBottomLeftBoldOutlineIcon } from 'mdi-react';
import Spinner from 'react-bootstrap/Spinner';

// const stdStyle = {
//   width: '400px',
// };
// const selectStyle = {
//   width: '300px',
// };

class AddFormMenu extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    editModal: PropTypes.func.isRequired,
    addcond: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.JOB_BAND') },
      { '2': t('LBL.JOB_GRADE') },
      { '3': t('LBL.NAME') },
      { '4': t('FRM.FUNCTION') },
    ];
    this.state = {
      hideButton: false,
      arrayMenu: [],
      arrMenu: [],
      urlData: '',
      modal: false,
      dataWidth: ['10%', '50%', '20%', '30%', '10'],
      hideButtonSave: false,
      disableCancel: false,
    };

    this.mySubmitHandler = this.mySubmitHandler.bind(this);
    this.getMenu = this.getMenu.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { reset } = this.props;
    reset();
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ iduser: ls.get('user_cred').usr_id });
    this.setState({ urlData: `${ls.get('ws_ip')}/menu/getallitem` });
  }

  componentWillReceiveProps(nextProps) {
    const { t } = nextProps;
    if (nextProps.arrMenu !== this.state.arrMenu) {
      this.setState({ arrMenu: nextProps.arrMenu });
    }
    if (nextProps.editMode !== this.state.modal) {
      this.setState({ modal: nextProps.editMode });
    }
  }

  getMenu(val) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const body = { level: parseInt(val.value, 10) - 1 };
    axios.post(
      `${apiws}/menu/getMenuByLevel/`,
      body,
      config,
    ).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map(obj => (
          Arr.push({ value: obj.men_id, label: obj.menu })
        ));
        setTimeout(() => {
          this.setState({ arrayMenu: Arr });
          this.setState({ arrMenu: Arr });
        }, 200);
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  toggle(x) {
    const { reset } = this.props;
    if (x === 'cancel') {
      setTimeout(() => {
        reset();
      }, 200);
    }
    this.props.editModal();
  }

  mySubmitHandler(values) {
    // console.log(values);
    const { reset } = this.props;
    /* upload using file */
    const ouser = ls.get('user_cred').usr_id;
    // eslint-disable-next-line no-prototype-builtins
    const file = (typeof values.file === 'undefined') || (values.file === null) ? '' : values.file.file;
    const parent = (typeof values.parent_menu === 'undefined' || values.parent_menu === null) ? '' : values.parent_menu.value;
    const level = (typeof values.level_menu === 'undefined') ? '' : values.level_menu.value;
    const prt = (parent !== '') ? (typeof values.parent_menu === 'object') ? values.parent_menu.value : values.parent_menu : '';
    const lv = (typeof values.level_menu === 'object') ? '' : values.level_menu;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let url;
    let data;
    const id = values.men_id;
    if (id === undefined || id === '') {
      url = `${this.state.apiws}/menu/saveItem`;
      data = {
        ...values,
        file,
        parent,
        level,
        cuser_id: ouser,
      };
    } else {
      url = `${this.state.apiws}/menu/updateItem/${id}`;
      data = {
        ...values,
        file,
        prt,
        lv,
        muser_id: ouser,
      };
    }
    this.setState({ hideButtonSave: true });
    this.setState({ disableCancel: true });
    const formBody = Object.keys(data).map(key =>
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
    axios.post(
      url,
      formBody,
      config,
    ).then((response) => {
      if (response.data.status === 'ok') {
        showNotifications('Success', 'Save Success', 'success');
        setTimeout(() => {
          this.setState({ arrMenu: [] });
          reset();
        }, 200);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        this.setState({ hideButtonSave: false });
        this.setState({ disableCancel: false });
      } else {
        showNotifications('Fail', 'Save Failed', 'danger');
        this.setState({ hideButtonSave: false });
        this.setState({ disableCancel: false });
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  render() {
    const {
      handleSubmit, reset, t, pristine,
    } = this.props;
    const { hideButtonSave, disableCancel, modal } = this.state;

    if (modal) {
      return (
        <Card>
          <Modal
            isOpen={this.state.modal}
            // toggle={this.toggle}
            className="modal-dialog modal-input"
            style={{ marginTop: '100px' }}
          >
            <ModalHeader>
              {t('FRM.EDIT')} {t('FRM.MENU')}
            </ModalHeader>
            <ModalBody>
              <form name="form_add_menu" id="form_add_menu" className="form form--horizontal" onSubmit={handleSubmit(this.mySubmitHandler)}>
                <div className="form__form-group">
                  <input type="hidden" value="" name="men_id" />
                  <span className="form__form-group-label">{t('LBL.LEVEL')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="level_menu"
                        component={renderSelectField}
                        placeholder="Select Level Menu"
                        options={[{ value: 1, label: '1' }, { value: 2, label: '2' },
                        { value: 3, label: '3' }]}
                        onChange={(val) => { this.getMenu(val); }}
                      />
                    </Col>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.PARENT_MENU')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="parent_menu"
                        component={renderSelectField}
                        placeholder="Select Parent Menu"
                        options={this.state.arrMenu}
                      />
                    </Col>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.MENU_NAME')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="menu"
                        component={renderTextInput}
                        type="text"
                        placeholder="Ex. Home, Settings ..."
                      />
                    </Col>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.WEIGHT')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="weight"
                        component={renderTextInput}
                        type="text"
                        placeholder="Ex. 1,2 etc.."
                        required
                      />
                    </Col>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">URL</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="url"
                        component={renderTextInput}
                        type="text"
                        placeholder="Ex. /path/...."
                      />
                    </Col>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.ICON')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="default_icon"
                        component={renderTextInput}
                        type="text"
                        placeholder="Fontawesome: home, laptop, book-open"
                      />
                    </Col>
                  </div>
                </div>
                {/* <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.ICON')}
                  </span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="file"
                        component={renderFileInputField}
                      />
                    </Col>
                  </div>
                </div> */}
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.SHOW')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="is_show"
                        component={renderCheckboxField}
                        type="text"
                        placeholder="Disabled Input"
                      />
                    </Col>
                  </div>
                </div>
                {/* <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.FAVORITE')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="is_favorite"
                        component={renderCheckboxField}
                        type="text"
                        placeholder="Disabled Input"
                      />
                    </Col>
                  </div>
                </div> */}
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('LBL.LABEL_MENU')}</span>
                  <div className="form__form-group-field">
                    <Col xs={12} md={12}>
                      <Field
                        name="lbl_code"
                        component={renderTextInput}
                        type="text"
                        placeholder="Ex. HOME.TOP_LABEL, HOME.SIDEBAR_LABEL"
                      />
                    </Col>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <ButtonToolbar className="form__button-toolbar">
                <Button type="button" onClick={() => this.toggle('cancel')} disabled={disableCancel}>
                  Cancel
                </Button>
                {(hideButtonSave) ? (
                  <Button color="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '6px' }}
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    disabled={pristine}
                    color="primary"
                    type="submit"
                    form="form_add_menu"
                    style={{ display: this.props.addcond === '1' ? '' : 'none' }}
                  >{t('FRM.SAVE')}
                  </Button>
                )}
              </ButtonToolbar>
            </ModalFooter>
          </Modal>
        </Card>
      );
    }

    return (
      <Card>
        <CardBody style={{ padding: '10px 20px 30px 30px' }}>
          <div className="row">
            <div className="col-md-12">
              <h3 className="page-title">{t('FRM.MENU')}</h3>
              <h3 className="page-subhead subhead">{t('LBL.FOR_ADD_MENU')}</h3>
            </div>
          </div>
          {/* <div className="card__title">
            <h5 className="bold-text">{t('FRM.ADD')} {t('FRM.MENU')}</h5>
            <h5 className="subhead">{t('LBL.FOR_ADD_MENU')}</h5>
          </div> */}
          <form id="form_add_menu" name="form_add_menu" className="form form--horizontal" onSubmit={handleSubmit(this.mySubmitHandler)}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.LEVEL')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="level_menu"
                    component={renderSelectField}
                    placeholder="Select Level Menu"
                    options={[{ value: 1, label: '1' }, { value: 2, label: '2' },
                    { value: '3', label: '3' }]}
                    onChange={(val) => { this.getMenu(val); }}
                  />
                </Col>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.PARENT_MENU')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="parent_menu"
                    component={renderSelectField}
                    placeholder="Select Parent Menu"
                    options={this.state.arrayMenu}
                  />
                </Col>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.MENU_NAME')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="menu"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. Home, Settings ..."
                  />
                </Col>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.WEIGHT')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="weight"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. 1,2 etc ..."
                    required
                  />
                </Col>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">URL</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="url"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. /path/..."
                  />
                </Col>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.ICON')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="default_icon"
                    component={renderTextInput}
                    type="text"
                    placeholder="Fontawesome: home, laptop, book-open ..."
                  />
                </Col>
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">
                {t('LBL.ICON')}
              </span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="file"
                    component={renderFileInputField}
                  />
                </Col>
              </div>
            </div> */}
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.SHOW')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12} style={{ marginTop: '-15px' }}>
                  <Field
                    name="is_show"
                    component={renderCheckboxField}
                    type="text"
                    placeholder="Disabled Input"
                  />
                </Col>
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.FAVORITE')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="is_favorite"
                    component={renderCheckboxField}
                    type="text"
                    placeholder="Disabled Input"
                  />
                </Col>
              </div>
            </div> */}
            <div className="form__form-group">
              <span className="form__form-group-label">{t('LBL.LABEL_MENU')}</span>
              <div className="form__form-group-field">
                <Col xs={12} md={12}>
                  <Field
                    name="lbl_code"
                    component={renderTextInput}
                    type="text"
                    placeholder="Ex. HOME.TOP_LABEL, HOME.SIDEBAR_LABEL"
                  />
                </Col>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button type="button" onClick={reset} disabled={disableCancel}>
                {t('FRM.CANCEL')}
              </Button>
              {(hideButtonSave) ? (
                <Button color="primary">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '6px' }}
                  />
                  Loading...
                </Button>
              ) : (
                <Button
                  disabled={pristine}
                  color="primary"
                  type="submit"
                  style={{ display: this.props.addcond === '1' ? '' : 'none' }}
                >{t('FRM.SAVE')}
                </Button>
              )}
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.level_menu) {
    errors.level_menu = 'Level Menu field shouldn’t be empty';
  }
  if (!values.weight) {
    errors.weight = 'Weight field shouldn’t be empty';
  }
  if (!values.url) {
    errors.url = 'Url field shouldn’t be empty';
  }
  if (!values.lbl_code) {
    errors.lbl_code = 'Label code menu field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'form_add_menu', // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(translate('global')(AddFormMenu));

