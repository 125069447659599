/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import {
  Card,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  // UncontrolledDropdown,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import { store } from '../../../App/store';
import { connect } from 'react-redux';

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {payload.map((entry, index) => (
      <li key={`item-${index}`}>
        <span style={{ backgroundColor: entry.color }} />
        {entry.value}
      </li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      vslue: PropTypes.string,
    }),
  ).isRequired,
};

// eslint-disable-next-line no-unused-vars
// const data01 = [{ name: 'Permanent', value: 70, fill: '#2C96F8' },
// { name: 'Contract', value: 20, fill: '#f6da6e' },
// { name: 'Probation', value: 10, fill: '#4ce1b6' }];

class EmpJobLevel extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // datan: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      token: ls.get('token'),
      apiws: ls.get('ws_ip'),
      iduser: ls.get('user_cred').usr_id,
    };
    this.getPercentageJobLevel = this.getPercentageJobLevel.bind(this);
  }

  componentDidMount = () => {
    this.getPercentageJobLevel();
  };

  getPercentageJobLevel() {
    const { token, apiws } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(`${apiws}/employee/getjoblevelpercentage/`, '', config)
      .then((response) => {
        const rpv = response.data.data;
        const arr = [];
        rpv.map((obj) =>
          arr.push({
            name: obj.name,
            total: obj.total,
            value: obj.value,
            fill: obj.fill,
          }),
        );

        store.dispatch({ type: 'SET_USER_JOB_LEVEL', data: arr });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <div className="dashboard__chart chart-home">
          <div className="chart-title">
            <div className="d-flex align-items-center">
              <div className="w-100">{t('LBL.JOB_LEVEL')}</div>
            </div>
          </div>
          <div className="chart-body d-flex">
            <div className="container-pie-chart">
              <ResponsiveContainer width={100} height={100}>
                <PieChart>
                  <Pie
                    data={this?.props?.user?.jobLevel}
                    dataKey="value"
                    innerRadius={20}
                    outerRadius={40}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="w-100">
              <ul className="pie-chart-legend">
                {this?.props?.user?.jobLevel?.map((obj, index) => (
                  <li key={`joblevel_${index}`}>
                    <div className="d-flex">
                      <div className="legend-name">
                        <span
                          className="legend-color"
                          style={{ backgroundColor: obj.fill }}
                        />
                        {obj.name}
                      </div>
                      <div className="legend-total">{obj.total}</div>
                      <div className="legend-total-percent">{obj.value}%</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(translate('global')(EmpJobLevel));
