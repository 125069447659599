/*
 * Notes: Master Data Function Access
 * @author Gerdi
 * @date 22/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus, react/jsx-boolean-value */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Container, Row, Card, CardBody, Col, Button } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCircle,
  faCheck,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import Spinner from 'react-bootstrap/Spinner';

let fuaRead = [];
let fuaAdd = [];
let fuaEdit = [];
let fuaDelete = [];
const functionAccessExist = [];

class FunctionAccessPage extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    onparam: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.FUNCTION_ACCESS') },
      { '2': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      idUser: '',
      apiws: '',
      token: '',
      functionAccessName: '',
      errorFunctionAccessName: 'none',
      urlData: '',
      dataWidth: ['10%', '70%', '20%'],
      tableInput: 'none',
      tableData: '',
      rowDataMenu: [],
      rowMenuId: [],
      statusEdit: false,
      disableButton: false,
      editcond: '',
      addcond: '',
      deletecond: '',
      showLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.addFunctionAccess = this.addFunctionAccess.bind(this);
    this.backListFunctionAccess = this.backListFunctionAccess.bind(this);
    this.deleteFunctionAccess = this.deleteFunctionAccess.bind(this);
    this.editFunctionAccess = this.editFunctionAccess.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getMenus();
      this.getFunctionAccess();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/appgroupaccess/getAllItem` });
    this.getButton();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.FUNCTION_ACCESS') },
      { '2': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  getFunctionAccess() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/appgroupaccess/getAll`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        for (let i = 0; i < dataResponse.length; i += 1) {
          functionAccessExist[i] = dataResponse[i].fga_name;
        }
      });
  }

  async editFunctionAccess(id, e, param) {
    const { token, apiws, functionAccessName, rowDataMenu } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (param === 'Y') {
      fuaRead[e] = true;
      fuaAdd[e] = true;
      fuaEdit[e] = true;
      fuaDelete[e] = true;
    } else if (param === 'N') {
      fuaRead[e] = false;
      fuaAdd[e] = false;
      fuaEdit[e] = false;
      fuaDelete[e] = false;
    } else {
      await axios
        .post(`${apiws}/appfunctionaccess/getItemById/${id}`, '', config)
        .then(async (response) => {
          if (parseInt(response.data.data.length, 10) === 0) {
            //
          } else {
            for (let i = 0; i < rowDataMenu.length; i += 1) {
              let fRead = '';
              let fAdd = '';
              let fEdit = '';
              let fDelete = '';
              const fAccess = response.data.data.filter(
                (fua) => fua.men_id === rowDataMenu[i].id,
              );
              if (fAccess.length !== 0) {
                if (
                  typeof fAccess[0].fua_read_priv === 'undefined' ||
                  fAccess[0].fua_read_priv === '0' ||
                  fAccess[0].fua_read_priv === null
                ) {
                  fRead = false;
                } else {
                  fRead = true;
                }
                if (
                  typeof fAccess[0].fua_add === 'undefined' ||
                  fAccess[0].fua_add === '0' ||
                  fAccess[0].fua_add === null
                ) {
                  fAdd = false;
                } else {
                  fAdd = true;
                }
                if (
                  typeof fAccess[0].fua_edit === 'undefined' ||
                  fAccess[0].fua_edit === '0' ||
                  fAccess[0].fua_edit === null
                ) {
                  fEdit = false;
                } else {
                  fEdit = true;
                }
                if (
                  typeof fAccess[0].fua_delete === 'undefined' ||
                  fAccess[0].fua_delete === '0' ||
                  fAccess[0].fua_delete === null
                ) {
                  fDelete = false;
                } else {
                  fDelete = true;
                }
                if (fAccess[0].men_id === 1) {
                  fRead = true;
                  fAdd = true;
                  fEdit = true;
                  fDelete = true;
                }
                fuaRead[i] = fRead;
                fuaAdd[i] = fAdd;
                fuaEdit[i] = fEdit;
                fuaDelete[i] = fDelete;
              }
            }
          }
        });
    }
    if (id === undefined || id === '' || typeof id === 'undefined') {
      this.props.initialize({
        fga_id: '',
        fga_name: functionAccessName,
        fua_read_priv: fuaRead,
        fua_add: fuaAdd,
        fua_edit: fuaEdit,
        fua_delete: fuaDelete,
      });
      // this.getMenus();
    } else {
      axios
        .post(`${apiws}/appgroupaccess/getAllItemById/${id}`, '', config)
        .then((response) => {
          if (response.data.status === 'ok') {
            const getResponseData = response.data.data;
            const idxFunctionAccess = functionAccessExist.indexOf(
              getResponseData[0].fga_name,
            );
            if (idxFunctionAccess !== -1) {
              functionAccessExist.splice(idxFunctionAccess, 1);
            }
            this.setState({ statusEdit: true });
            this.setState({ disableButton: false });
            // this.setState({ fgaName: response.data.data[0].fga_name });
            this.setState({
              tableData: 'none',
              tableInput: '',
            });
            this.props.initialize({
              fga_id: response.data.data[0].fga_id,
              fga_name: response.data.data[0].fga_name,
              fua_read_priv: fuaRead,
              fua_add: fuaAdd,
              fua_edit: fuaEdit,
              fua_delete: fuaDelete,
            });
            this.getMenus(id);
          }
        });
    }
  }

  deleteFunctionAccess(id) {
    const { token, apiws, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/appgroupaccess/deleteitem/${id}`,
            `duser_id=${idUser}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              this.getTableData();
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch((error) => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
        axios
          .post(`${apiws}/appfunctionaccess/getItemById/${id}`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              for (let i = 0; i < response.data.data.length; i += 1) {
                axios.post(
                  `${apiws}/appfunctionaccess/deleteItem/${response.data.data[i].fua_id}`,
                  `duser_id=${idUser}`,
                  config,
                );
              }
            }
          });
      }
    });
  }

  getMenus(id) {
    if (typeof id === 'undefined' || id === '') {
      this.props.destroy();
    } else {
      //
    }
    const rowsMenus = [];
    const rowsId = [];
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/menu/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.data.length > 0) {
            let no_index = 0;
            for (let i = 0; i < response.data.data.length; i += 1) {
              no_index += 1;
              let dataIcon;
              let dataPaddingIcon;
              let dataPaddingMenu;
              if (response.data.data[i].level === 1) {
                dataIcon = faCircle;
                dataPaddingIcon = '';
                dataPaddingMenu = '20px';
              } else if (response.data.data[i].level === 2) {
                dataIcon = faAngleRight;
                dataPaddingIcon = '30px';
                dataPaddingMenu = '20px';
              } else {
                dataIcon = faAngleDoubleRight;
                dataPaddingIcon = '60px';
                dataPaddingMenu = '40px';
              }
              rowsMenus.push({
                iterate: i,
                no: no_index,
                id: response.data.data[i].men_id,
                menu: response.data.data[i].menu,
                level: response.data.data[i].level,
                icon: dataIcon,
                paddingIcon: dataPaddingIcon,
                paddingMenu: dataPaddingMenu,
                fgaid: id,
              });
              rowsId[i] = response.data.data[i].men_id;
            }
            this.setState({ rowDataMenu: rowsMenus });
            this.setState({ rowMenuId: rowsId });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  async handleSubmit(values) {
    this.setState({ showLoading: true });
    const { apiws, token, idUser, rowMenuId, rowDataMenu } = this.state;
    const id = values.fga_id;
    const nm = values.fga_name;
    const errorsFunctionAccessExist = functionAccessExist;
    let action;
    let url;
    let dataInput;
    let error;
    if (id === undefined || id === '') {
      action = 'add';
      dataInput = `fga_name=${nm}&cuser_id=${idUser}`;
      url = `${apiws}/appgroupaccess/saveItem`;
    } else {
      action = 'edit';
      dataInput = `fga_name=${nm}&muser_id=${idUser}`;
      url = `${apiws}/appgroupaccess/updateItem/${id}`;
    }
    if (errorsFunctionAccessExist.includes(nm)) {
      error = 1;
    } else {
      error = 0;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (nm !== '' && error === 0) {
      let fgaId;
      let fuaReadInput;
      let fuaAddInput;
      let fuaEditInput;
      let fuaDeleteInput;
      if (typeof values.fua_read_priv === 'undefined') {
        fuaReadInput = [];
      } else {
        fuaReadInput = values.fua_read_priv;
      }
      if (typeof values.fua_add === 'undefined') {
        fuaAddInput = [];
      } else {
        fuaAddInput = values.fua_add;
      }
      if (typeof values.fua_edit === 'undefined') {
        fuaEditInput = [];
      } else {
        fuaEditInput = values.fua_edit;
      }
      if (typeof values.fua_delete === 'undefined') {
        fuaDeleteInput = [];
      } else {
        fuaDeleteInput = values.fua_delete;
      }
      const maxFua = Math.max(
        parseInt(fuaReadInput.length, 10),
        parseInt(fuaAddInput.length, 10),
        parseInt(fuaEditInput.length, 10),
        parseInt(fuaDeleteInput.length, 10),
      );

      let i;
      const postData = [];
      for (i = 0; i < maxFua; i++) {
        let fRead;
        let fAdd;
        let fEdit;
        let fDelete;
        if (fuaReadInput[i] === true) {
          fRead = 1;
        } else {
          fRead = 0;
        }
        if (fuaAddInput[i] === true) {
          fAdd = 1;
        } else {
          fAdd = 0;
        }
        if (fuaEditInput[i] === true) {
          fEdit = 1;
        } else {
          fEdit = 0;
        }
        if (fuaDeleteInput[i] === true) {
          fDelete = 1;
        } else {
          fDelete = 0;
        }
        if (rowMenuId[i] !== undefined) {
          postData.push({
            fgaId: rowDataMenu[i].fgaid,
            menId: rowMenuId[i],
            fuaRead: fRead,
            fuaAdd: fAdd,
            fuaEdit: fEdit,
            fuaDelete: fDelete,
            userId: idUser,
          });
        }
      }

      if (maxFua > 0) {
        if (action === 'edit') {
          await axios
            .post(url, dataInput, config)
            .then(async (response) => {
              if (response.data.status === 'ok') {
                fgaId = values.fga_id;
                await axios
                  .post(
                    `${apiws}/appfunctionaccess/deleteItemByGroup/${fgaId}`,
                    `duser_id=${idUser}`,
                    config,
                  )
                  .then(() => {
                    //
                  });
              }
            })
            .catch(() => {
              // console.log(error);
            });
        } else {
          await axios
            .post(url, dataInput, config)
            .then(async (response) => {
              if (response.data.status === 'ok') {
                fgaId = response.data.id;
              }
            })
            .catch(() => {
              // console.log(error);
            });
        }

        await axios
          .post(
            `${apiws}/appfunctionaccess/saveItem`,
            `postData=${btoa(JSON.stringify(postData))}&fga_id=${fgaId}`,
            config,
          )
          .then(() => {
            this.props.destroy();
            showNotifications('Success', 'Save Success', 'success');
            this.setState({
              tableData: '',
              tableInput: 'none',
              statusEdit: false,
              showLoading: false,
            });
            this.getTableData();
            this.getFunctionAccess();
          })
          .catch(() => {
            // console.log(error);
          });
      }
    } else {
      // this.setState({ errorFunctionAccessName: '' });
    }
  }

  addFunctionAccess() {
    this.setState({
      tableData: 'none',
      tableInput: '',
      disableButton: true,
      statusEdit: false,
    });
    fuaAdd = [true];
    fuaRead = [true];
    fuaEdit = [true];
    fuaDelete = [true];
    // this.props.destroy().;
    this.getMenus();
    this.props.initialize({
      fga_id: '',
      fga_name: '',
      fua_read_priv: fuaRead,
      fua_add: fuaAdd,
      fua_edit: fuaEdit,
      fua_delete: fuaDelete,
    });
  }

  backListFunctionAccess() {
    this.setState({
      tableData: '',
      tableInput: 'none',
    });
    this.getTableData();
    fuaAdd = [];
    fuaRead = [];
    fuaEdit = [];
    fuaDelete = [];
  }

  changeFunctionAccess(e) {
    const errorsFunctionAccessExist = functionAccessExist;
    if (errorsFunctionAccessExist.includes(e.target.value)) {
      this.setState({ errorFunctionAccessName: '' });
    } else {
      this.setState({ errorFunctionAccessName: 'none' });
    }
    if (!e.target.value) {
      this.setState({ disableButton: true });
      this.setState({ functionAccessName: '' });
    } else {
      this.setState({ disableButton: false });
      this.setState({ functionAccessName: e.target.value });
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      urlData,
      tableData,
      tableInput,
      disableButton,
      statusEdit,
      errorFunctionAccessName,
      idUser,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={this.addFunctionAccess}
        style={{ float: 'right', 'margin-bottom': '20px' }}
      >
        {t('FRM.ADD')} {t('LBL.FUNCTION_ACCESS')}
      </Button>
    );
    let formId;
    let labelButton;
    let colorButton;
    if (statusEdit) {
      labelButton = t('FRM.EDIT');
      colorButton = 'success';
    } else {
      colorButton = 'primary';
      labelButton = t('FRM.SAVE');
    }
    const pad = { padding: '10px !important' };
    return (
      <Container>
        <Row style={{ display: tableData }}>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="fga_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteFunctionAccess}
                  updateFunc={this.editFunctionAccess}
                  onRef={(ref) => (this.child = ref)}
                  conds={idUser}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: tableInput }}>
          <Col md={12} lg={12} sm={12} xs={12}>
            <Card>
              <CardBody>
                <Container>
                  <Row style={{ marginTop: '-10px' }}>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div style={{ float: 'right', paddingRight: '10px' }}>
                        <Button
                          color="secondary"
                          onClick={this.backListFunctionAccess}
                        >
                          {' '}
                          {t('FRM.BACK')}
                        </Button>
                        {this.state.showLoading ? (
                          <Button color={colorButton} disabled={disableButton}>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: '6px' }}
                            />
                            {t('FRM.LOADING')}
                          </Button>
                        ) : (
                          <Button
                            color={colorButton}
                            form="workflowform"
                            type="submit"
                            disabled={disableButton}
                          >
                            {' '}
                            {labelButton}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="workflowform"
                  id="workflowform"
                >
                  <input type="hidden" value="" name="fga_id" />
                  <Container>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span
                            className="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              borderRadius: '3px',
                              position: 'absolute',
                              padding: '5px 10px 5px 10px',
                              float: 'right',
                              margin: '-28px 0px 0px 1007px',
                              display: errorFunctionAccessName,
                            }}
                          >
                            Function access name is already taken
                          </span>
                          <span
                            className="form__form-group-label"
                            style={{ paddingLeft: '10px' }}
                          >
                            {t('LBL.FUNCTION_ACCESS')}
                          </span>
                          <div
                            className="form__form-group-field"
                            style={{ paddingRight: '10px' }}
                          >
                            <Field
                              name="fga_name"
                              component={renderTextInput}
                              type="text"
                              onChange={(e) => {
                                this.changeFunctionAccess(e);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Card>
                        <CardBody style={{ padding: '20px 10px 10px 10px' }}>
                          <div className="container-fluid">
                            <div className="wrapper">
                              <table
                                id="myTable"
                                className="table data-grid-tbl responsive no-wrap"
                              >
                                <thead>
                                  <tr>
                                    <th width="30%">{t('FRM.MENU')}</th>
                                    <th className="center-text">
                                      {t('FRM.READ')}
                                    </th>
                                    <th className="center-text">
                                      {t('FRM.ADD')}
                                    </th>
                                    <th className="center-text">
                                      {t('FRM.EDIT')}
                                    </th>
                                    <th className="center-text">
                                      {t('FRM.DELETE')}
                                    </th>
                                    <th width="15%" />
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rowDataMenu.map((data) => (
                                    <tr>
                                      <td>
                                        <span
                                          style={{
                                            paddingLeft: data.paddingIcon,
                                          }}
                                        >
                                          <FontAwesomeIcon icon={data.icon} />
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft: data.paddingMenu,
                                          }}
                                        >
                                          {data.menu}
                                        </span>
                                      </td>
                                      <td align="center">
                                        <div className="form__form-group mb0 mt4 padding-l4 disp-inherit">
                                          <div className="tx-center">
                                            {data.menu === 'Home' ? (
                                              <Field
                                                name={`fua_read_priv[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className={`colored-click ${pad}`}
                                                defaultChecked={true}
                                                disabled={true}
                                              />
                                            ) : (
                                              <Field
                                                name={`fua_read_priv[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className="colored-click"
                                                defaultChecked
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td align="center">
                                        <div className="form__form-group mb0 mt4 padding-l4 disp-inherit">
                                          <div className="tx-center">
                                            {data.menu === 'Home' ? (
                                              <Field
                                                name={`fua_add[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className={`colored-click ${pad}`}
                                                defaultChecked={true}
                                                disabled={true}
                                              />
                                            ) : (
                                              <Field
                                                name={`fua_add[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className="colored-click"
                                                defaultChecked
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td align="center">
                                        <div className="form__form-group mb0 mt4 padding-l4 disp-inherit">
                                          <div className="tx-center">
                                            {data.menu === 'Home' ? (
                                              <Field
                                                name={`fua_edit[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className={`colored-click ${pad}`}
                                                defaultChecked={true}
                                                disabled={true}
                                              />
                                            ) : (
                                              <Field
                                                name={`fua_edit[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className="colored-click"
                                                defaultChecked
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td align="center">
                                        <div className="form__form-group mb0 mt4 padding-l4 disp-inherit">
                                          <div className="tx-center">
                                            {data.menu === 'Home' ? (
                                              <Field
                                                name={`fua_delete[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className={`colored-click ${pad}`}
                                                defaultChecked={true}
                                                disabled={true}
                                              />
                                            ) : (
                                              <Field
                                                name={`fua_delete[${data.iterate}]`}
                                                component={renderCheckBoxField}
                                                className="colored-click"
                                                defaultChecked
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {data.menu === 'Home' ? (
                                          <Button
                                            color="success"
                                            className="btn-small-icon"
                                            disabled
                                          >
                                            {' '}
                                            <FontAwesomeIcon icon={faCheck} />
                                          </Button>
                                        ) : (
                                          <Button
                                            color="success"
                                            className="btn-small-icon"
                                            onClick={() => {
                                              this.editFunctionAccess(
                                                data.fgaid,
                                                data.iterate,
                                                'Y',
                                              );
                                            }}
                                          >
                                            {' '}
                                            <FontAwesomeIcon icon={faCheck} />
                                          </Button>
                                        )}
                                        {data.menu === 'Home' ? (
                                          <Button
                                            color="danger"
                                            className="btn-small-icon"
                                            disabled
                                          >
                                            {' '}
                                            <FontAwesomeIcon icon={faTimes} />
                                          </Button>
                                        ) : (
                                          <Button
                                            color="danger"
                                            className="btn-small-icon"
                                            onClick={() => {
                                              this.editFunctionAccess(
                                                data.fgaid,
                                                data.iterate,
                                                'N',
                                              );
                                            }}
                                          >
                                            {' '}
                                            <FontAwesomeIcon icon={faTimes} />
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Row>
                  </Container>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const errorsFunctionAccessExist = functionAccessExist;
  if (!values.fga_name) {
    errors.fga_name = 'Function access field shouldn’t be empty';
  }
  if (errorsFunctionAccessExist.includes(values.fga_name)) {
    errors.fga_name = 'Function access name is already taken';
  }
  if (!values.description) {
    errors.description = 'Function access field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'reduxFunctionAccess', // must identik
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(FunctionAccessPage));
