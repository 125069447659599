import React, { useRef, useState } from 'react';
import TaskKanbanItem from './TaskKanbanItem';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { Field } from 'redux-form';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEllipsisH,
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import showNotifications from '../../../Preferences/notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';
import ls from 'local-storage';
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const TaskKanbanContainer = ({
  t,
  tab,
  data,
  tasks,
  reloadSection,
  toggleOffCanvas,
  toggleNotification,
  getDataSection,
  providedSection,
  snapshotSection,
}) => {
  const [auth] = useState({
    token: ls.get('token'),
    apiws: ls.get('ws_ip'),
  });

  // init state
  const [isEditNameSection, setIsEditNameSection] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [sectionName, setSectionName] = useState(data.section_name);

  // ref
  const inputRef = useRef(null);
  const typeConfirmDelete = useRef(null);

  const renameSection = () => {
    let error = false;
    let url = `${auth.apiws}/section/renameSection`;

    const formData = new FormData();
    formData.append('section_name', sectionName);
    formData.append('muser_id', ls.get('user_cred').usr_id);
    formData.append('emp_id', ls.get('user_cred').emp_id);
    if (tab == 1) {
      formData.append('pps_id', data?.pps_id);
    } else {
      formData.append('pse_id', data?.pse_id);
    }

    if (!sectionName) error = true;

    const config = {
      headers: {
        Authorization: `bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    if (!error) {
      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data.status == 'ok') {
            showNotifications('Success', 'Submit Submission', 'success');
            getDataSection();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteSection = (id) => {
    let typeSectionDelete = '';
    let idUser = ls.get('user_cred').usr_id;

    if (data?.pse_id) {
      id = data?.pse_id;
      typeSectionDelete = 1;
    } else {
      id = data?.pps_id;
      typeSectionDelete = 2;
    }

    const MySwal = withReactContent(Swal);

    function deleteItemSection() {
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(
          `${auth.apiws}/section/deleteItem/${id}`,
          {
            type: typeSectionDelete,
            emp_id: ls.get('user_cred').emp_id,
            duser_id: idUser,
            confirm: typeConfirmDelete.current,
          },
          config,
        )
        .then((res) => {
          if (res.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            reloadSection();
          }
        });
    }

    if (typeSectionDelete == 1) {
      Swal.fire({
        title: t('FRM.CONFIRM_ASK'),
        text: t('LBL.DELETESECTIONOPT1'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: t('LBL.CANCEL'),
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        confirmButtonText: t('LBL.YES_DELETE'),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) deleteItemSection();
      });
    } else {
      MySwal.fire({
        input: 'radio',
        html: `<h3>${t('FRM.CONFIRM_ASK')}<span/>`,
        inputOptions: {
          1: `<span>${t('LBL.DELETESECTIONOPT1')}</span>`,
          2: `<span class="mt-3">${t('LBL.DELETESECTIONOPT2')}</span>`,
        },
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff4861',
        cancelButtonColor: '#3085d6',
        cancelButtonText: t('LBL.CANCEL'),
        confirmButtonText: t('LBL.YES_DELETE'),
        confirmBtnBsStyle: 'danger',
        reverseButtons: true,
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading();
        },
        preConfirm: () => {
          if (Swal.getInput()?.value == 1) {
            typeConfirmDelete.current = 1;
          } else {
            typeConfirmDelete.current = 2;
          }
        },
      }).then((result) => {
        if (result.value) deleteItemSection();
      });
    }
  };

  // archive detector
  let archive = data.section_name == 'Archive' ? 'archive' : null;

  return (
    <div
      ref={providedSection.innerRef}
      {...providedSection.draggableProps}
      {...providedSection.dragHandleProps}
    >
      <Droppable droppableId={data.pps_id || data.pse_id} type="task">
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="h-100"
            >
              <div className={`task-container-wrapper ${archive}`}>
                <div className="task-wrapper-header">
                  <div className="d-flex align-items-center">
                    <div className="form task-container-rename">
                      {isEditNameSection ? (
                        <div className="form task-container-rename">
                          <Field
                            name={`section-${data.pps_id}`}
                            type="text"
                            component={renderTextInput}
                            props={{
                              meta: { touched: true },
                              input: {
                                ref: inputRef,
                                value: sectionName,
                                onChange: (e) => setSectionName(e.target.value),
                                onBlur: () => {
                                  setSectionName(data.section_name);
                                  setIsEditNameSection(!isEditNameSection);
                                },
                              },
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <span className="section-name">
                            {data.section_name || sectionName}
                          </span>
                          <span className="section-count">
                            {data?.tasks.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {isEditNameSection ? (
                      <>
                        <button
                          className="btn btn-danger section-cancel px-1"
                          onClick={() => {
                            setSectionName(data.section_name);
                            setIsEditNameSection(!isEditNameSection);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ width: 10, height: 10 }}
                          />
                        </button>
                        <button
                          disabled={!sectionName}
                          className={`btn section-save px-1 ${
                            sectionName ? 'btn-success' : 'btn-secondary'
                          }`}
                          onClick={() => {
                            // save
                            renameSection();
                            setIsEditNameSection(!isEditNameSection);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ width: 10, height: 10 }}
                          />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary section-plus px-1"
                          onClick={() => {
                            toggleOffCanvas(
                              'new',
                              data?.pps_id || data?.pse_id,
                              tab,
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ width: 10, height: 10 }}
                          />
                        </button>
                        {data.has_edit && !data.default_section && (
                          <UncontrolledDropdown>
                            <DropdownToggle className="section-more px-1">
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                style={{ width: 10, height: 10 }}
                              />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                              <DropdownItem
                                onClick={() => {
                                  setIsEditNameSection(!isEditNameSection);
                                  setTimeout(() => {
                                    inputRef.current.focus();
                                  }, 100);
                                  // setSectionActive(data.pps_id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  className={`text-success fa-fw`}
                                />
                                <span className="ml-2">Rename</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  deleteSection(data?.pps_id ?? data?.pse_id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className={`text-danger fa-fw`}
                                />
                                <span className="ml-2">Delete</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="task-container"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tasks.length !== 0 ? (
                    <>
                      {tasks.map((item, index) => (
                        <Draggable
                          draggableId={`${item.pta_id}`}
                          key={`task-${item.pta_id}`}
                          id={`task-${item.pta_id}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <TaskKanbanItem
                                t={t}
                                payload={item}
                                provided={provided}
                                snapshot={snapshot}
                                toggleNotification={toggleNotification}
                                getDataSection={getDataSection}
                              />
                            </>
                          )}
                        </Draggable>
                      ))}
                    </>
                  ) : (
                    <Draggable
                      draggableId={`${data.pps_id}-blank`}
                      index={0}
                      isDragDisabled={true}
                    >
                      {(provided) => (
                        <>
                          <div
                            className="task-item blank-task"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="task-content">
                              <p>No task yet</p>
                            </div>
                          </div>
                        </>
                      )}
                    </Draggable>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

export default TaskKanbanContainer;
