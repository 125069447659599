/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len, array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import React, { PureComponent, useEffect } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ls from 'local-storage';
import axios from 'axios';
import moment from 'moment';
import { store } from '../../App/store';
import { connect } from 'react-redux';

// import showResults from './show';
import EmployeeData from './tabs/Employee';
import PayrollData from './tabs/Payroll';
import CompanyData from './tabs/Company';
import Authenticator from '../../Authenticator';

const HeaderIcon = '/img/page_header/icon-add-emp.png';

// const WizardForm = (props) => {
class WizardForm extends PureComponent {
  static propTypes = {
    location: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: 0,
      activeTab: '1',
      validateToken: false,
      idUser: '',
      empId: '',
      token: '',
      apiws: '',
      taxstatus: this?.props.loadBoxEmployee?.taxStatus,
      rtbbank: this?.props.loadBoxEmployee?.bank,
      payproratesetting: this?.props.loadBoxEmployee?.payProrate,
      rtbemployeementstatus: [],
      rtbreligion: this?.props.loadBoxEmployee?.religion,
      rtbidentitytype: this?.props.loadBoxEmployee?.identityType,
      rtbmaritalstatus: this?.props.loadBoxEmployee?.maritalStatus,
      paytaxmethod: this?.props.loadBoxEmployee?.payTaxMethod,
      rtbfamilyrelation: this?.props.loadBoxEmployee?.familyRelation,
      rtbbloodtype: this?.props.loadBoxEmployee?.blood,
      rtblasteducation: [],
      rtbcompany: this?.props.loadBoxEmployee?.company,
      rtbnationality: this?.props.loadBoxEmployee?.country,
      paycomponentgroup: this?.props.loadBoxEmployee?.payComponentGroup,
      paycomponentgrouprel: this?.props.loadBoxEmployee?.payComponentGroupRel,
      orgoffice: this?.props.loadBoxEmployee?.office,
      orggeofence: this?.props.loadBoxEmployee?.geofence,
      orgjobgrade: [],
      orgjobbandgrade: [],
      orgjoblevel: this?.props.loadBoxEmployee?.jobLevel,
      orgjobband: this?.props.loadBoxEmployee?.jobBand,
      orgjobname: [],
      orgposition: [],
      orgorganization: this?.props.loadBoxEmployee?.organization,
      attshift: this?.props.loadBoxEmployee?.attShift,
      paytaxclass: this?.props.loadBoxEmployee?.payTaxClass,
      saveonthefly: [],
      emphierarchy: [],
      valuecomp: [],
      oldvaluecomp: [],
      empCode: '',
      allEmpCode: [],
      url_ktp: '',
      url_sim: '',
      url_passport: '',
      typeFileKTP: '',
      typeFileSIM: '',
      typeFilePassport: '',
      paycomponentrecur: this?.props.loadBoxEmployee?.itemRecurring,
    };

    // const dispatcher = useDispatch()
    // const companies = useSelector((state)=>LoadBoxEmployee.arrCompany)
    // console.log(companies);
    // useEffect(()=>{
    //   dispatcher(getCompanyRedux())
    // }, [])
    this.getJobName = this.getJobName.bind(this);
    this.getJobTitle = this.getJobTitle.bind(this);
    this.getJobGrade = this.getJobGrade.bind(this);
    this.getSuperordinat = this.getSuperordinat.bind(this);
    this.loadEmployee = this.loadEmployee.bind(this);
    this.getFingerId = this.getFingerId.bind(this);
    this.toggle = this.toggle.bind();
    this.updateValues = this.updateValues.bind();
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentWillMount = () => {
    if (typeof this.props.location.state.usr_id === 'number') {
      this.setState(
        {
          empId: this.props.location.state.usr_id,
          token: ls.get('token'),
          apiws: ls.get('ws_ip'),
          idUser: ls.get('user_cred').usr_id,
        },
        async () => {
          await this.loadSelectBox();
          this.setState((state) => ({ loaded: state.loaded + 1 }));
          if (this.props.location.state.usr_id !== undefined) {
            await this.loadEmployee(this.props.location.state.usr_id);
            this.setState((state) => ({ loaded: state.loaded + 1 }));
          } 
          
        },
      );
    }
    this.getFingerId(this.props.location.state.usr_id);
    this.checkAuth();
  };


  async getFingerId(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${ls.get('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .get(`${ls.get('ws_ip')}/employee/getallitemfingerid/${id}`, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            const finger = [];
            const res = response.data.data;
            if (res !== null) {
              // eslint-disable-next-line array-callback-return
              res.map((data) => {
                finger.push(data.finger_id);
              });
              resolve();
              this.setState({ allfingerid: finger });
            } else {
              this.setState({ allfingerid: finger });
            }
          }
        })
        .catch(() => {
          // console.log(error);
        });
    });
  }

  async getallfinger() {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const datatree = [];

      const usrd = ls.get('user_cred').usr_id;
      axios
        .post(`${apiws}/employee/getAll/`, '', config)
        .then((response) => {
          if (response.data.status === 'ok') {
            const finger = [];
            const res = response.data.data;
            if (res !== null) {
              // eslint-disable-next-line array-callback-return
              res.map((data) => {
                finger.push(data.finger_id);
              });
              resolve();
              this.setState({ allfingerid: finger });
            } else {
              this.setState({ allfingerid: finger });
            }
          }
        })
        .catch((error) => {
          // this.setState({ button_disabled: false });
        });
    });
  }

  getJobName(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobname/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const jbn = response.data.data;
          const arr = [];
          jbn.map((obj) =>
            arr.push({ value: obj.jbn_id, label: obj.job_name }),
          );
          setTimeout(() => {
            this.setState({
              orgjobname: arr,
              orgId: id.value,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobTitle(id) {
    const { orgId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgposition/getAllHierarchy/${orgId}/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const pos = response.data.data;
          const arr = [];
          pos.map((obj) =>
            arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          setTimeout(() => {
            this.setState({
              orgposition: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobGrade(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobbandgrade/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const grade = response.data.data;
          const arr = [];
          grade.map((obj) =>
            arr.push({ value: obj.jgd_id, label: obj.job_grade }),
          );
          setTimeout(() => {
            this.setState({
              orgjobgrade: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getSuperordinat(pos_id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const datatree = [];

    const usrd = ls.get('user_cred').usr_id;
    axios
      .post(
        `${apiws}/employee/getAllItemByPosition/${pos_id.value}`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          setTimeout(() => {
            this.setState({ emphierarchy: Arr });
          }, 500);
        } else {
          this.setState({ button_disabled: false });
          // showNotifications('Fail', 'Failed Check', 'danger');
        }
      })
      .catch((error) => {
        // console.log(error);
        this.setState({ button_disabled: false });
      });
  }

  updateValues = (value) => {
    let citizen;
    let displaycountry;
    if (value.citizen !== null) {
      citizen =
        typeof value.citizen === 'object' ? value.citizen.value : value.citizen;
    }
    if (citizen === 2) {
      displaycountry = '2';
    } else {
      displaycountry = '1';
    }
    this.setState({
      saveonthefly: {
        ...this.state.saveonthefly,
        ...value,
        displaycountry,
      },
    });
  };

  async loadEmployee(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(`${apiws}/employee/getAllItemById/${id}`, '', config)
        .then((response) => {
          const dataResponse = response.data.data;
          this.setState({ empCode: dataResponse.user[0].emp_code });
          // console.log(JSON.stringify(dataResponse.user[0].emp_code));
          // eslint-disable-next-line object-curly-newline
          let identityEmployee = {
            identity_ktp: 1,
            identity_sim: 2,
            identity_paspor: 3,
            idty_ktp: 'KTP',
            idty_sim: 'SIM',
            idty_paspor: 'PASPORT',
          };
          if (dataResponse.identity.length !== 0) {
            dataResponse.identity.forEach((element) => {
              if (element.rti_id === 1) {
                console.log(element.file_name !== null);
                if (element.file_name !== null) {
                  const fileType = element.file_name.split('.');
                  this.setState({ typeFileKTP: fileType.pop() });
                  this.setState({
                    url_ktp: `${ls.get('ws_ip')}/public/uploads${
                      element.file_path
                    }`,
                  });
                }
                // this.setState({ attachment: files });
                identityEmployee = {
                  ...identityEmployee,
                  identity_ktp: element.rti_id,
                  identity_no_ktp: element.identity_number,
                  file_ktp: element.file_path,
                  file_name_ktp: element.file_name,
                };
              }
              if (element.rti_id === 2) {
                if (element.file_name !== null) {
                  const fileType = element.file_name.split('.');
                  this.setState({ typeFileSIM: fileType.pop() });
                  this.setState({
                    url_sim: `${ls.get('ws_ip')}/public/uploads${
                      element.file_path
                    }`,
                  });
                }
                identityEmployee = {
                  ...identityEmployee,
                  identity_sim: element.rti_id,
                  identity_no_sim: element.identity_number,
                  expired_date_sim:
                    element.expired_date === ''
                      ? null
                      : moment(element.expired_date),
                  file_sim: element.file_path,
                  file_name_sim: element.file_name,
                };
              }
              if (element.rti_id === 3) {
                if (element.file_name !== null) {
                  const fileType = element.file_name.split('.');
                  this.setState({ typeFilePassport: fileType.pop() });
                  this.setState({
                    url_passport: `${ls.get('ws_ip')}/public/uploads${
                      element.file_path
                    }`,
                  });
                }
                identityEmployee = {
                  ...identityEmployee,
                  identity_paspor: element.rti_id,
                  identity_no_paspor: element.identity_number,
                  expired_date_paspor:
                    element.expired_date === ''
                      ? null
                      : moment(element.expired_date),
                  file_passport: element.file_path,
                  file_name_passport: element.file_name,
                };
              }
            });
          }
          const adjustment = [];
          if (dataResponse.adjustment.length !== 0) {
            adjustment.push({
              pad_id: dataResponse.adjustment[0].pad_id,
              adjustment_start_date:
                dataResponse.adjustment[0].adjustment_start_date,
              adjustment_end_date:
                dataResponse.adjustment[0].adjustment_end_date,
              backpay_date: dataResponse.adjustment[0].backpay_date,
            });
          } else {
            adjustment.push({
              pad_id: '',
              adjustment_start_date: null,
              adjustment_end_date: null,
              backpay_date: null,
            });
          }
          dataResponse.pay_component.map((obj) =>
            this.state.valuecomp.push(obj.pec_value),
          );

          dataResponse.pay_component.map((obj) =>
            this.state.oldvaluecomp.push(obj.old_value),
          );

          if (dataResponse.superordinat.length !== 0) {
            this.setState({
              superordinat: dataResponse.superordinat[0].emp_id,
            });
          } else {
            this.setState({ superordinat: '' });
          }
          this.setState({
            saveonthefly: {
              ...dataResponse.user[0],
              ...identityEmployee,
              emp_parent: this.state.superordinat,
              dob: moment(dataResponse.user[0].dob),
              join_date: moment(dataResponse.user[0].join_date),
              npwp_date:
                dataResponse.user[0].npwp_date === null
                  ? dataResponse.user[0].npwp_date
                  : moment(dataResponse.user[0].npwp_date),
              dyn_identity: identityEmployee,
              component_payroll: dataResponse.pay_component,
              // off_id: dataResponse.his_office[0].off_id,
              // jbd_id: dataResponse.his_job_band[0].jbd_id,
              // pos_id: dataResponse.his_position[0].pos_id,
              pad_id: adjustment[0].pad_id,
              adjustment_start_date:
                adjustment[0].adjustment_start_date === null
                  ? adjustment[0].adjustment_start_date
                  : moment(adjustment[0].adjustment_start_date),
              adjustment_end_date:
                adjustment[0].adjustment_end_date === null
                  ? adjustment[0].adjustment_end_date
                  : moment(adjustment[0].adjustment_end_date),
              backpay_date:
                adjustment[0].backpay_date === null
                  ? adjustment[0].backpay_date
                  : moment(adjustment[0].backpay_date),
              cmp_id: dataResponse.company[0].cmp_id,
              ogf_id: dataResponse.geofence.map((item)=>{
                return item.ogf_id
              })
            },
          });
          this.getJobName({ value: `${dataResponse.user[0].org_id}` });
          this.setState({ orgId: dataResponse.user[0].org_id });
          this.getJobTitle({ value: `${dataResponse.user[0].jbn_id}` });
          this.getJobGrade({ value: `${dataResponse.user[0].jbd_id}` });
          this.getSuperordinat({ value: `${dataResponse.user[0].pos_id}` });
        })
        .catch(() => {
          // console.log(error);
        });

      // Update dynamic update tax 10/05/2021
      // if (this.props.parmid.state.statusEdit) {
      //   // console.log(new Date().getMonth() + 1);
      //   if (new Date().getMonth() + 1 !== 1) {
      //     this.setState({ disabledTax: true });
      //     this.setState({ disabledTaxMethod: true });
      //   }
      // }
    });
  }

  async loadSelectBox() {
    /* eslint-disable semi */
    /* eslint-disable padded-blocks */
    const emp = this.props.location.state.usr_id;
    const me = this;
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    async function getEmployeementStatus() {
      return new Promise((resolve2, reject) => {
        let url_ems;
        if (me.props.location.statusEdit) {
          url_ems = `${apiws}/rtbemployeementstatus/getAll`;
        } else {
          url_ems = `${apiws}/rtbemployeementstatus/getAllItemByIsActive`;
        }

        axios
          .post(url_ems, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({
                  value: obj.ems_id,
                  label: obj.ems_employeement_status,
                }),
              );
              me.setState({ rtbemployeementstatus: Arr });
            }
            resolve2();
          })
          .catch(() => {
            // console.log(error);
          });
      });
    }

    async function getEmlpoyeeCode() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiws}/employee/getAllCode`, `emp=${emp}`, config)
          .then((response) => {
            const res = response.data.data;
            const Arr = [];
            res.map((data) => {
              Arr.push(data.emp_code);
            });
            me.setState({ allEmpCode: Arr });
            resolve();
          });
      });
    }

    await getEmployeementStatus();
    await getEmlpoyeeCode();

    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/token/isOtp`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.otp) {
            this.setState({ validateToken: true });
          }
        }
      })
      .catch((error) => {
        // showNotifications('Fail', 'Delete Failed', 'danger');
      });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { t } = this.props;
    // console.log(this?.props.loadBoxEmployee?.jobBand);
    const {
      loaded,
      taxstatus,
      rtbbank,
      payproratesetting,
      rtbemployeementstatus,
      rtbreligion,
      rtbidentitytype,
      rtbmaritalstatus,
      paytaxmethod,
      rtbbloodtype,
      rtblasteducation,
      rtbfamilyrelation,
      rtbcompany,
      paycomponentgroup,
      paycomponentgrouprel,
      paycomponentrecur,
      orgjobbandgrade,
      orgjobname,
      orgoffice,
      orgjoblevel,
      orgjobband,
      orgposition,
      orgorganization,
      orgjobgrade,
      attshift,
      saveonthefly,
      emphierarchy,
      rtbnationality,
      disabledTax,
      disabledTaxMethod,
      valuecomp,
      oldvaluecomp,
      paytaxclass,
      validateToken,
      allfingerid,
      orggeofence,
      empCode,
      allEmpCode,
      url_ktp,
      url_sim,
      url_passport,
      typeFileKTP,
      typeFileSIM,
      typeFilePassport,
    } = this.state;
    // console.log(loaded);

    return (
      <>
        {loaded !== 1 && (
          <Row>
            <Col>
              <Card>
                <div className={`load${loaded === 1 ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path
                        fill="#4ce1b6"
                        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        )}
        {loaded === 1 && (
          <Container>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody className="p0">
                    <Row className="m0">
                      <Col md={12} style={{ padding: '0px' }}>
                        <div className="header-page-panel">
                          <img
                            className="header-icon"
                            src={HeaderIcon}
                            alt="Add Employee"
                          />
                          <h3 className="page-title">
                            {this.state.empId === ''
                              ? t('FRM.ADD')
                              : t('FRM.EDIT')}{' '}
                            {t('LBL.EMPLOYEE')}
                          </h3>
                          <h3 className="page-subhead subhead">
                            {this.state.empId === ''
                              ? t('FRM.ADD')
                              : t('FRM.EDIT')}{' '}
                            {t('LBL.NEW_EMPLOYEE')}
                          </h3>
                        </div>
                      </Col>
                      {/* <div className="btn_action_table_navbar">
                        <ButtonToolbar>
                          <Link className="btn btn-primary btn_table_navbar" to="/administration/employee">
                            <FontAwesomeIcon icon={faArrowLeft} /> Cancel
                          </Link>
                        </ButtonToolbar>
                      </div> */}
                    </Row>
                    <div className="tabs tabs--bordered-top">
                      <div className="tabs__wrap">
                        <Nav tabs className="tab-preferences">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === '1',
                              })}
                              onClick={() => {
                                this.toggle('1');
                              }}
                            >
                              {t('FRM.EMPLOYEE_DATA')}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === '2',
                              })}
                              onClick={() => {
                                this.toggle('2');
                              }}
                            >
                              {t('LBL.PAYROLL')}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === '3',
                              })}
                              onClick={() => {
                                this.toggle('3');
                              }}
                            >
                              {t('LBL.COMPANY')}
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          {(this.state.activeTab === '1' && (
                            <TabPane tabId="1">
                              <EmployeeData
                                parmid={this.props.location.state}
                                tempform={saveonthefly}
                                rtbbloodtype={rtbbloodtype}
                                rtblasteducation={rtblasteducation}
                                rtbreligion={rtbreligion}
                                rtbidentitytype={rtbidentitytype}
                                rtbmaritalstatus={rtbmaritalstatus}
                                rtbfamilyrelation={rtbfamilyrelation}
                                rtbnationality={rtbnationality}
                                fingerid={allfingerid}
                                empCode={empCode}
                                allEmpCode={allEmpCode}
                                onChange={this.updateValues}
                                url_ktp={url_ktp}
                                url_sim={url_sim}
                                url_passport={url_passport}
                                typeFileKTP={typeFileKTP}
                                typeFileSIM={typeFileSIM}
                                typeFilePassport={typeFilePassport}
                                // initialValues={{ dyn_identity: [{}] }}
                              />
                            </TabPane>
                          )) ||
                            (this.state.activeTab === '2' && validateToken && (
                              <TabPane tabId="2">
                                <PayrollData
                                  parmid={this.props.location.state}
                                  rtbtaxstatus={taxstatus}
                                  paytaxmethod={paytaxmethod}
                                  rtbpayproratesetting={payproratesetting}
                                  paycomponentgroup={paycomponentgroup}
                                  paycomponentgrouprel={paycomponentgrouprel}
                                  paycomponentrecur={paycomponentrecur}
                                  rtbbank={rtbbank}
                                  valuecomp={valuecomp}
                                  oldvaluecomp={oldvaluecomp}
                                  paytaxclass={paytaxclass}
                                  disabledTaxStatus={disabledTax}
                                  disabledTaxMethod={disabledTaxMethod}
                                  tempform={saveonthefly}
                                  loadEmployee={this.loadEmployee}
                                  onChange={this.updateValues}
                                />
                              </TabPane>
                            )) ||
                            (this.state.activeTab === '2' &&
                              validateToken === false && (
                                <TabPane tabId="2">
                                  <Authenticator checkAuth={this.checkAuth} />
                                </TabPane>
                            ))   ||
                            (this.state.activeTab === '3' && (
                              <TabPane tabId="3">
                                <CompanyData
                                  parmid={this.props.location.state}
                                  rtbcompany={rtbcompany}
                                  rtboffice={orgoffice}
                                  rtbgeofence={orggeofence}
                                  rtborgjobband={orgjobband}
                                  rtborgjoblevel={orgjoblevel}
                                  rtborgjobname={orgjobname}
                                  rtborgposition={orgposition}
                                  rtborgjobgrade={orgjobgrade}
                                  rtborgjobbandgrade={orgjobbandgrade}
                                  rtbattshift={attshift}
                                  rtborgorganization={orgorganization}
                                  emphierarchy={emphierarchy}
                                  tempform={saveonthefly}
                                  rtbemployeementstatus={rtbemployeementstatus}
                                  getJobName={this.getJobName}
                                  getJobTitle={this.getJobTitle}
                                  getJobGrade={this.getJobGrade}
                                  getSuperordinat={this.getSuperordinat}
                                  loadEmployee={this.loadEmployee}
                                  onChange={this.updateValues}
                                />
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loadBoxEmployee: state.loadBoxEmployee,
});

export default connect(mapStateToProps)(translate('global')(WizardForm));
